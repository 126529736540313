const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const PaymentTarget = require("parse/PaymentTarget");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const PaymentTargetModal = ({paymentTarget, handleSave, desc, buttonStyle}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code={"modal.payment.header"} /></TextCenter>}
            buttonStyle={buttonStyle}
            buttonChildren={<FontAwesome icon="money-bill-wave"><I18n code={"profile.account.business.paymentTarget"} /></FontAwesome>}>
            {desc ? desc: null}
            <PaymentTargetForm paymentTarget={paymentTarget} handleSave={handleSave}/>
        </ModalBasic>
    )
};
PaymentTargetModal.propTypes = {
    handleSave: PropTypes.func.isRequired,
    paymentTarget: PropTypes.object,
    desc: PropTypes.object,
    buttonStyle: PropTypes.string.isRequired
};
class PaymentTargetForm extends React.Component {
    constructor(props) {
        super(props);
        this.refCardName = React.createRef();
        this.refCardBIC = React.createRef();
        this.refCardIBAN = React.createRef();
        this.refPayConditon = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.getDefault = this.getDefault.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refCardName.current.getValue(),
            this.refCardIBAN.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let editPaymentTarget = this.props.paymentTarget;
        if (editPaymentTarget == null) {
            editPaymentTarget = new PaymentTarget();
            editPaymentTarget.setStatus("A")
        }

        editPaymentTarget.setCardName(this.refCardName.current.getValue().trim());
        editPaymentTarget.setCardBIC(this.refCardBIC.current.getValue().trim());
        editPaymentTarget.setCardIBAN(this.refCardIBAN.current.getValue().trim());
        editPaymentTarget.setInvoicePaymentCondition(this.refPayConditon.current.getValue().trim());
        this.props.handleSave(editPaymentTarget);
        disableSubmitFct(true);
        closeFct();
    }
    getDefault(column) {
        if (this.props.paymentTarget != null) {
            return this.props.paymentTarget.get(column);
        }
        return "";
    }
    render() {
        const {error, submitDisabled} = this.context;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refCardName}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("payTarget.cardName")}
                                    label={messages.get("payTarget.cardName")}
                                    default={this.getDefault(PaymentTarget.prototype.col.cardName)}/>
                        <ValidInput ref={this.refCardIBAN}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("payTarget.cardIBAN")}
                                    label={messages.get("payTarget.cardIBAN")}
                                    default={this.getDefault(PaymentTarget.prototype.col.cardIBAN)}/>
                        <ValidInput ref={this.refCardBIC}
                                    valid={{maxLength: 50}}
                                    placeholder={messages.get("payTarget.cardBIC")}
                                    label={messages.get("payTarget.cardBIC")}
                                    default={this.getDefault(PaymentTarget.prototype.col.cardBIC)}/>
                        <ValidInput ref={this.refPayConditon} componentClass="textarea"
                                    valid={{maxLength: 1500}}
                                    placeholder={messages.get("payTarget.payCondition")}
                                    label={messages.get("payTarget.payCondition")}
                                    default={this.getDefault(PaymentTarget.prototype.col.invPayCondition)}/>
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
PaymentTargetForm.propTypes = {
    handleSave: PropTypes.func.isRequired,
    paymentTarget:PropTypes.object
};
PaymentTargetForm.contextType = ModalBasicContext;
module.exports = PaymentTargetModal;
