const alt = require("AltInit");
const action = require("actions/CountTypeActions");
const ParseListUtility = require("util/ParseListUtility");
class CountTypeStore {
    constructor() {
        this.displayName = "CountTypeStore";
        this.loading = true;
        this.countTypeGroups = null;
        this.countTypes = null;
        this.bindListeners({
            updateLoading: action.updateLoading,
            updateCountTypes: action.updateCountTypes,
            updateCountType: action.updateCountType,
            updateCountTypeGrp: action.updateCountTypeGrp
        });
    }

    updateLoading(loading) {
        this.loading = loading;
    }

    updateCountTypes(pCountTypes) {
        this.loading = false;
        this.countTypes = pCountTypes;
    }

    updateCountTypeGrp(pCountTypeGrp) {
        this.countTypeGroups = pCountTypeGrp;
    }

    updateCountType(pCountType) {
        if (this.countTypes == null) {
            let tmp = [];
            tmp.push(pCountType);
            this.updateCountTypes(tmp);
        } else {
            this.updateCountTypes(ParseListUtility.updateOrPush(this.countTypes, pCountType));
        }
    }
}

module.exports = alt.createStore(CountTypeStore);
