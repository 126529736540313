const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert} = require("react-bootstrap");

const Title = require("components/widgets/Title"),
    Loading = require("components/widgets/Loading"),
    {RoutedTabNav, RoutedTabs, RoutedTab} = require("components/widgets/RoutedTabNav");

const userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions");

class AdminTabPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);

        this.state = {
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        userActions.getIsAdmin();
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state
        });
    }
    render() {
        const {path, type} = this.props;
        if (this.state.userStore.isAdmin == null) {
            return <Loading/>
        }
        if (this.state.userStore.isAdmin !== true) {
            return <Alert bsStyle="danger">Du kommst da ned rein. Nur für Admins!</Alert>;
        }
        return (
            <Grid>
                <Title code="Admin Tools"/>
                <Row>
                    {
                        type === "data" ? <RoutedTabData path={path} /> : null
                    }
                    {
                        type === "business" ? <RoutedTabBusiness path={path} /> : null
                    }
                    <Col xs={12}>
                        {this.props.children}
                    </Col>
                </Row>
            </Grid>
        )
    }
}
AdminTabPage.propTypes = {
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
};
const RoutedTabData = ({path}) => {
    return <Col xs={12}>
        <RoutedTabNav>
            <RoutedTabs tabs pathname={path}>
                <RoutedTab linkCode="Change logs"
                           linkPath="/admind/changelog"/>
                <RoutedTab linkCode="User Search"
                           linkPath="/admind/user"/>
                <RoutedTab linkCode="User Exchanger"
                           linkPath="/admind/exchangeuser"/>
                <RoutedTab linkCode="Parcours Exchanger"
                           linkPath="/admind/exchangeparcours"/>
                <RoutedTab linkCode="Statistics"
                           linkPath="/admind/statistics"/>
                <RoutedTab linkCode="Create Release entry"
                           linkPath="/admind/release"/>
                <RoutedTab linkCode="Data Handler"
                           linkPath="/admind/data"/>
                <RoutedTab linkCode="Parse Logs"
                           linkPath="/admind/logs"/>
            </RoutedTabs>
        </RoutedTabNav>
    </Col>
};
const RoutedTabBusiness = ({path}) => {
    return  <Col xs={12}>
        <RoutedTabNav>
            <RoutedTabs tabs pathname={path}>
                <RoutedTab linkCode="Business Accounts"
                           linkPath="/adminb/business"/>
                <RoutedTab linkCode="Business Request"
                           linkPath="/adminb/request"/>
                <RoutedTab linkCode="Month settle"
                           linkPath="/adminb/settle"/>
                <RoutedTab linkCode="Point of Interests"
                           linkPath="/adminb/poi"/>
                <RoutedTab linkCode="Tournament options"
                           linkPath="/adminb/toptions"/>
                <RoutedTab linkCode="SKill Board Voucher"
                           linkPath="/adminb/voucher"/>
                <RoutedTab linkCode="Business Data Handler"
                           linkPath="/adminb/handler"/>
            </RoutedTabs>
        </RoutedTabNav>
    </Col>
};
module.exports = AdminTabPage;
