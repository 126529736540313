function EventPlayerYearStat(year) {
    this.countTypeEventPlayers = {};
    this.startDate = new Date(year,0,1).getTime();
    this.endDate = new Date(year,11,31,23,59,59,0).getTime();
    this.lastEventID = "a";
    this.lastTUserId = "a";
    // TODO think who to fix time problem via query

    this.addEventPlayer = function(evPlayer) {
        var ev = evPlayer.getEventID();
        if (ev != null && ev.getInsstmp() > this.startDate && ev.getInsstmp() < this.endDate) {
            if (ev.id == this.lastEventID) {
                console.log("doubled event players for event " + ev.id);
                return;
            }
            if (evPlayer.getTournamentUserID()) {
                if (evPlayer.getTournamentUserID().id === this.lastTUserId) {
                    console.log("doubled getTournamentUserID for tournamentUser " + this.lastTUserId);
                    return;
                }
                this.lastTUserId = evPlayer.getTournamentUserID().id
            }

            this.lastEventID = ev.id;
            // time is okay - split in lists for each count type
            //console.log(evPlayer.id);
            var countType = evPlayer.getCountTypeOnlineID();
            if (countType == null) {
                countType = "own"
            }
            var ctEVPObj = this.countTypeEventPlayers[countType];
            if (ctEVPObj == null) {
                ctEVPObj = {};
                ctEVPObj.list = [];
                ctEVPObj.sumPoints = 0;
                ctEVPObj.maxPoints = 0;
                ctEVPObj.events = 0;
                ctEVPObj.killCountsstr = "t,";
                ctEVPObj.killCounts = {};
            }
            ctEVPObj.events += 1;
            ctEVPObj.list.push(evPlayer);
            ctEVPObj.sumPoints += evPlayer.getSumPoints();
            //var trgAmount = evPlayer.getEventResults().length;
            let shootCounter = evPlayer.getShootCounter();
            if (countType.substring(0,1) === "D") {
                if ("D4Z_5_t" === countType) {
                    ctEVPObj.maxPoints += (shootCounter * (evPlayer.getMaxTargetPoints() / 4));
                } else {
                    ctEVPObj.maxPoints += (shootCounter * (evPlayer.getMaxTargetPoints() / 3));
                }
            } else {
                let arrow2Target = evPlayer.getArrowToTarget();
                ctEVPObj.maxPoints += ((shootCounter / arrow2Target) * evPlayer.getMaxTargetPoints());
            }
            // if (countType == "3P3Z_20_f") {
            //     console.log(ev.id + "/" + evPlayer.id + " / "  + trgAmount + " / " + evPlayer.getKillCounts());
            // }
            this.sumUpKillCounts(ctEVPObj, evPlayer.getKillCounts());
            this.countTypeEventPlayers[countType] = ctEVPObj;
        }
    };

    this.sumUpKillCounts = function(ctEVPObj, addKillCounts) {
        if (addKillCounts != null) {
            var rkillCounts = addKillCounts.split(",");
            for (var k = 0; k < rkillCounts.length; k++) {
                if (k == 0) {
                    ctEVPObj.killCountsstr = rkillCounts[k] + ",";
                } else {
                    var killCnt = rkillCounts[k].split(":");
                    if (killCnt.length = 2) {
                        var amount = ctEVPObj.killCounts[killCnt[0]];
                        if (amount) {
                            amount = parseInt(amount) + parseInt(killCnt[1]);
                        } else {
                            amount = parseInt(killCnt[1]);
                        }
                        ctEVPObj.killCounts[killCnt[0]] = amount;
                    }
                }
            }
        }
    };

    this.getCountTypes = function () {
        return Object.keys(this.countTypeEventPlayers);
    };

    this.getEventPlayerEntries = function(countType) {
        return this.countTypeEventPlayers[countType];
    };
    this.analyseKills= function (countType) {
        let eventPlayerObject = this.getEventPlayerEntries(countType);
        let arrowSumUp = eventPlayerObject.killCountsstr.trim().startsWith("t,");
        let killCountDict = eventPlayerObject.killCounts;
        let result = {};
        result.arrows = 0;
        result.hit = 0;
        result.miss = 0;
        result.z1 = 0;
        result.z2 = 0;
        result.z3 = 0;
        Object.keys(killCountDict).map(arrZo => {
            let amount = parseInt(killCountDict[arrZo]);
            if (arrZo % 1000 != 0) {
                // hit a zone
                if (arrowSumUp) {
                    let zone = arrZo.slice(-1);
                    if (1 == zone) {
                        result.z1 += parseInt(amount);
                    } else if (2 == zone) {
                        result.z2 += parseInt(amount);
                    } else if (3 == zone) {
                        result.z3 += parseInt(amount);
                    }
                    result.arrows += amount;
                    result.hit += amount;
                } else {
                    if (1001 == arrZo) {
                        result.z1 += parseInt(amount);
                    } else if (1002 == arrZo) {
                        result.z2 += parseInt(amount);
                    } else if (1003 == arrZo) {
                        result.z3 += parseInt(amount);
                    }
                    let arrow = arrZo.substring(0,1);
                    result.arrows += (parseInt(arrow) * amount);
                    result.hit += amount;
                }
            } else {
                // miss
                result.miss += amount;
                if (arrowSumUp) {
                    result.arrows += amount;
                } else {
                    let arrow = parseInt(arrZo.substring(0,1)) - 1;
                    if (arrow === 0) {
                        arrow = 1;
                    }
                    result.arrows += (arrow * amount);
                }
            }
        });
        return result;
    }
}
module.exports = EventPlayerYearStat;