const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    LoginButton = require("components/widgets/LoginButton"),
    Loading = require("components/widgets/Loading");

const clubStore = require("stores/ClubStore"),
    clubActions = require("actions/ClubActions"),
    notificationActions = require("components/notification/NotificationActions"),
    userStore = require("stores/UserStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubLoader extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleClubChange = this.handleClubChange.bind(this);

        this.state = {
            clubStore: clubStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        clubStore.listen(this.handleClubChange);
        notificationActions.reset();
        clubActions.loadClub(this.props.clubID, this.state.userStore.user, this.props.clubUserRole, this.props.clubUserId);
    }
    componentWillUnmount() {
        clubActions.resetClubDetails();
        userStore.unlisten(this.handleUserChange);
        clubStore.unlisten(this.handleClubChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleClubChange(state) {
        this.setState({clubStore: state});
    }
    render() {
        if (this.props.loginMandatory && this.state.userStore.user == null) {
            return (
                <Grid>
                    <br/><br/>
                    <Alert bsStyle="warning"><I18n code="parcours.edit.message.noLogin"/>: <LoginButton/></Alert>
                </Grid>)
        }
        if (this.state.clubStore.invalidUser == true) {
            return (<Grid>
                <Row>
                    <Col lg={12}>
                        <br/><br/>
                        <Alert bsStyle="danger"><I18n code="club.detail.invalidUser"/></Alert>
                    </Col>
                </Row>
            </Grid>)
        }
        if (this.state.clubStore.loading == true) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (this.state.clubStore.clubDetail != null && this.state.clubStore.clubDetail.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/club" code="header.nav.clubs"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        } else if (this.state.clubStore.clubDetail != null) {
            return <Grid>
                {React.cloneElement(this.props.children,
                    { club: this.state.clubStore.clubDetail,
                        clubUsers:this.state.clubStore.clubUsers,
                        isSKillBoardClub: this.state.clubStore.isSKillBoardClub,
                        ownClubUser:this.state.clubStore.ownClubUser,
                        clubUser: this.state.clubStore.editClubUser,
                        searchParams:  this.state.clubStore.searchParams,
                        user: this.state.userStore.user,
                        isAdmin: this.state.userStore.isAdmin
                    } )}
            </Grid>
        }
        return null;
    }
}
ClubLoader.propTypes = {
    clubID:PropTypes.string.isRequired,
    clubUserRole:PropTypes.string,
    loginMandatory: PropTypes.bool,
    clubUserId: PropTypes.string
};
module.exports = ClubLoader;
