const React = require("react"),
    PropTypes = require('prop-types');
const {Col, Panel, Image} = require("react-bootstrap");
const FileUploadArea = require("components/widgets/FileUploadArea");

const ImageSelector = ({colName, object, user, header, allowedFileTypes, savedImageCallback, showBGParams}) => {
        let panelStlye = "default";
        let preview = null;
        var image = object.get(colName);
        if (image != null && image.getImage() != null) {
            panelStlye = "success";
            if (image.isTypeImage()) {
                preview = <Image responsive src={image.getImageUrl()}/>
            } else if (image.isTypePDF()){
                // TODO think about rendering pdf
                preview = <a target="_BLANK" href={image.getImageUrl()}>
                    <Image rounded src="/img/ico/icon-download.png" width="48" height="48"/>&nbsp;{image.getImageName()}
                </a>
            } else {
                // TODO think about rendering documents
                preview = <a target="_BLANK" href={image.getImageUrl()}>
                    <Image rounded src="/img/ico/icon-download.png" width="48" height="48"/>&nbsp;{image.getImageName()}
                </a>
            }
        }
        return (
            <Panel bsStyle={panelStlye}>
                <Panel.Heading><h2>{header}</h2></Panel.Heading>
                <Panel.Body>
                    { preview != null ?
                        <Col sm={8}>
                            {preview}
                        </Col>
                        : null}
                    <Col sm={4}>
                        <FileUploadArea allowedFileTypes={allowedFileTypes}
                                        object={object}
                                        colName={colName}
                                        savedImageCallback={savedImageCallback}
                                        showBGParams={showBGParams}
                                        user={user}/>
                    </Col>
                </Panel.Body>
            </Panel>
        )
};
ImageSelector.propTypes = {
    colName: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    object: PropTypes.object.isRequired,
    allowedFileTypes: PropTypes.array.isRequired,
    savedImageCallback: PropTypes.func.isRequired,
    showBGParams: PropTypes.bool,
    header: PropTypes.any
};
module.exports = ImageSelector;
