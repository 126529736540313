const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic");


const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const DeleteDecisionModal = ({titleCode, delButtonCode, messageCode, warningCode, entryName, handleDelete, disabled, tooltip}) =>  {
    return (
        <ModalBasic
            title={<TextCenter>{titleCode ? <I18n code={titleCode}/> : <I18n code="modal.button.delete"/>} </TextCenter>}
            buttonStyle={"default"}
            buttonDisabled={disabled}
            tooltip={tooltip}
            buttonChildren={<FontAwesome icon="trash" />}>
            <DeleteDecisionForm messageCode={messageCode}  warningCode={warningCode} delButtonCode={delButtonCode}
                                handleDelete={handleDelete} entryName={entryName}/>
        </ModalBasic>
    )
};
DeleteDecisionModal.propTypes = {
    titleCode: PropTypes.string,
    messageCode: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired,
    warningCode: PropTypes.string,
    tooltip: PropTypes.string,
    entryName: PropTypes.string
};
class DeleteDecisionForm extends React.Component {
    constructor(props) {
        super(props);
        this.clickedOK = this.clickedOK.bind(this);
        this.clickedCancel = this.clickedCancel.bind(this);
    }
    clickedOK() {
        const {closeFct} = this.context;
        if (this.props.handleDelete) {
            this.props.handleDelete(true);
        }
        closeFct();
    }
    clickedCancel() {
        const {closeFct} = this.context;
        if (this.props.handleDelete) {
            this.props.handleDelete(false);
        }
        closeFct();
    }
    render() {
        const {messageCode, entryName, warningCode, delButtonCode } = this.props;
        return (
            <form action="#">
                <Row>
                    <Col xs={12}>
                        <I18n code={messageCode}/><br/><br/>
                        {entryName != null ? <p><strong>{entryName}</strong></p> : null}
                        {warningCode != null ? <Alert bsStyle="warning"><strong><I18n code={warningCode}/></strong></Alert> : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <Button onClick={this.clickedOK} block bsStyle="primary">
                            {delButtonCode ? <I18n code={delButtonCode}/> : <I18n code="modal.button.delete"/>}
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <Button onClick={this.clickedCancel} block bsStyle="default"><I18n code="modal.button.cancel"/></Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
DeleteDecisionForm.propTypes = {
    entryName: PropTypes.string,
    warningCode: PropTypes.string,
    messageCode: PropTypes.string.isRequired,
    handleDelete: PropTypes.func.isRequired
};
DeleteDecisionForm.contextType = ModalBasicContext;
module.exports = DeleteDecisionModal;