const Parse = require("ParseInit");
const ReactGA = require("react-ga");
const {ParcoursStat} = require("parse/_Domain");

const parseDao = require("actdao/ParseBaseDao");
const notificationActions = require("components/notification/NotificationActions");

class AnalyticsDao {

    gaEvent(category, action, label) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }

    queryPointInterest(interestID, action, callbackSuccess) {
        const callbackError = function(error) {
            console.log("Query PointInterest ERROR ", error);
            // notificationActions.parseError(error);
        };
        const notify = function(info) {
            console.log("Query PointInterest INFO ", info)
            notificationActions.info(info);
        };
        const startDate = new Date();
        startDate.setFullYear(startDate.getFullYear() -1)
        const endDate = new Date();
        const poQuery = new Parse.Query(ParcoursStat);
        poQuery.equalTo(ParcoursStat.prototype.col.pointInterestID, interestID);
        if (action) {
            poQuery.equalTo(ParcoursStat.prototype.col.action, action);
        }
        poQuery.greaterThanOrEqualTo(ParcoursStat.prototype.col.createdAt, startDate);
        poQuery.lessThanOrEqualTo(ParcoursStat.prototype.col.createdAt, endDate);
        poQuery.descending(ParcoursStat.prototype.col.createdAt);
        parseDao.queryMoreEntryLists(poQuery, notify, callbackError, callbackSuccess);
    }

    queryParcoursStats(onlineID, year, callbackSuccess) {
        const callbackError = function(error) {
            console.log("Query PointStats ERROR ", error);
        };
        const startDate = new Date(year,0,1);
        const endDate = new Date(year,12,0,23,59,59,0);
        const query = new Parse.Query(ParcoursStat);
        query.equalTo(ParcoursStat.prototype.col.parcoursOnlineID, onlineID);
        query.greaterThanOrEqualTo(ParcoursStat.prototype.col.createdAt, startDate);
        query.lessThanOrEqualTo(ParcoursStat.prototype.col.createdAt, endDate);
        query.ascending(ParcoursStat.prototype.col.createdAt);
        parseDao.queryMoreEntryLists(query, null, callbackError, callbackSuccess);
    }
}
module.exports = new AnalyticsDao();
