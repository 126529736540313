const Parse = require("ParseInit");

const TournamentRound = Parse.Object.extend("TournamentRound", {
    col: {
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        tournamentID: "tournamentID",
        parcoursID: "parcoursID",
        targetAmounts: "targetAmounts",
        memberAmount: "memberAmount",
        sortIndex: "sortIndex",
        type: "type",
        hidePoints:"hidePoints",
        challengeMode: "challengeMode",
        bowConfigManipulator: "bowConfigManipulator",
        status: "status",
        name: "name",
        confirmUserID: "confirmUserID"
    },
    status: {
        present: "A",
        inactive: "I",
        running: "S",
        closed: "C",
        deleted: "D"
    },
    type: {
        simple: "S",
        bestOf: "B",
        lippek: "L",
        final_zero: "F0",
        final_add: "F1",
        lippek_group: "GL",
        team_group: "GT",
        ranking_group: "GR",
        inklusion: "EI"
    },
    typeGroups: {
        group_simple: ["S", "B", "L"],
        group_simple_best: ["S", "B"],
        group_simple_lippek: ["L"],
        group_finale: ["F0","F1"],
        group_groups: ["GR","GT","GL"],
        group_extra: ["EI"]
    },
    getSelectString() {
        return this.getName();
    },
    getName() {
        return this.get(this.col.name);
    },
    setName(name) {
        return this.set(this.col.name, name);
    },
    getTargetAmounts() {
        return this.get(this.col.targetAmounts);
    },
    setTargetAmounts(amount) {
        return this.set(this.col.targetAmounts, amount);
    },
    getMemberAmount() {
        return this.get(this.col.memberAmount);
    },
    setMemberAmount(amount) {
        return this.set(this.col.memberAmount, amount);
    },
    isRunning() {
        return this.getStatus() == this.status.running;
    },
    isClosed() {
        return this.getStatus() === this.status.closed || this.getStatus() === this.status.deleted;
    },
    isPresent() {
        return this.getStatus() === this.status.present;
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getStatusTranslKey() {
        if (this.isPresent()) {
            return "tournament.status.present";
        } else if (this.isRunning()) {
            return "tournament.status.running";
        } else if (this.isClosed()) {
            return "tournament.status.finished";
        }
    },
    isTypeOf(typeArray) {
        return typeArray.indexOf(this.getType()) > -1
    },
    isSimple() {
        return this.getType() === this.type.simple;
    },
    isBestOf() {
        return this.getType() === this.type.bestOf;
    },
    isFinalZero() {
        return this.getType() === this.type.final_zero;
    },
    isFinalAdd() {
        return this.getType() === this.type.final_add;
    },
    isLippek() {
        return this.getType() === this.type.lippek_group || this.getType() === this.type.lippek;
    },
    isCombineAge() {
        return this.getType() === this.type.inklusion;
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    getParcoursID() {
        return this.get(this.col.parcoursID)
    },
    setParcoursID(parcoursID) {
        return this.set(this.col.parcoursID, parcoursID)
    },
    getType() {
        return this.get(this.col.type);
    },
    setType(type) {
        return this.set(this.col.type, type);
    },
    isHidePoints() {
        return this.get(this.col.hidePoints);
    },
    setHidePoints(hide) {
        return this.set(this.col.hidePoints, hide);
    },
    setSortIndex(index) {
        return this.set(this.col.sortIndex, index);
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    isChallengeModeEnabled() {
        if (this.isRunning()) {
            return this.get(this.col.challengeMode);
        } else {
            return false;
        }
    },
    getBowConfigManipulator() {
        return this.get(this.col.bowConfigManipulator);
    },
    setBowConfigManipulator(bowConfigManipulatorObject) {
        return this.set(this.col.bowConfigManipulator, bowConfigManipulatorObject);
    },
    getConfirmUserID() {
        return this.get(this.col.confirmUserID);
    },
    setConfirmUserID(user) {
        return this.set(this.col.confirmUserID, user);
    },
});

module.exports = TournamentRound;
