const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages");

const ClubAccountingStat = ({invoiceStat, loading}) => {
    return (<Row>
        <Col xs={12} sm={6}>
            <br/><br/>
            <Table responsive striped hover>
                <thead>
                    <h3>{messages.get("club.invoice.month.header")}</h3>
                    <tr>
                        <th className="col-xs-3"><I18n code="monthPager.month"/></th>
                        <th className="text-right col-xs-3"><I18n code="club.invoice.amount.in.true"/></th>
                        <th className="text-right col-xs-3"><I18n code="club.invoice.amount.in.false"/></th>
                        <th className="text-right col-xs-3"><I18n code="club.invoice.balance"/></th>
                    </tr>
                </thead>
                {
                    loading ? <tbody><tr><td colSpan="4"><Loading/></td></tr></tbody> : <TableBody invoiceStat={invoiceStat}/>
                }
            </Table>
        </Col>
        <Col xs={12} sm={6}>
            <br/><br/>
            {
                loading ? null : <TypesTable invoiceStat={invoiceStat}/>
            }
        </Col>
    </Row>);
};
ClubAccountingStat.propTypes = {
    loading: PropTypes.bool.isRequired,
    invoiceStat: PropTypes.array.isRequired
};
const TableBody = ({invoiceStat}) => {
    return ( <tbody>
    {
        invoiceStat.months.map(month => {
            return (<TableEntry key={"MON" + month}
                                name={messages.get("montPager.month." + (month + 1))}
                                amountIn={invoiceStat.getMonthIn(month)}
                                amountOut={invoiceStat.getMonthOut(month)}
                                balance={invoiceStat.getMonthBalance(month)}/>)
        })
    }
    <tr>
        <td><b>{messages.get("tournament.table.column.sum")}</b></td>
        <td className="text-right"><b>{(Math.round(invoiceStat.yearIn * 100) / 100).toFixed(2)}</b></td>
        <td className="text-right"><b>{(Math.round(invoiceStat.yearOut * 100) / 100).toFixed(2)}</b></td>
        <td className="text-right"><b>{(Math.round((invoiceStat.yearIn - invoiceStat.yearOut) * 100) / 100).toFixed(2)}</b></td>
    </tr>
    </tbody>)
};
TableBody.propTypes = {
    invoiceStat: PropTypes.array.isRequired
};
const TableEntry = ({name, amountIn, amountOut, balance}) => {
    return (<tr>
        <td>{name}</td>
        <td className="text-right">{(Math.round(amountIn * 100) / 100).toFixed(2)}</td>
        <td className="text-right">{(Math.round(amountOut * 100) / 100).toFixed(2)}</td>
        <td className="text-right">{(Math.round(balance * 100) / 100).toFixed(2)}</td>
    </tr>)
};
TableEntry.propTypes = {
    name: PropTypes.string.isRequired,
    amountIn: PropTypes.number.isRequired,
    amountOut: PropTypes.number.isRequired,
    balance: PropTypes.number.isRequired
};
const TypesTable = ({invoiceStat}) => {
    let typeAmountDict = invoiceStat.typeAmountDict;
    let types = Object.keys(typeAmountDict);
    if (types != null && types.length > 0 ) {
        return (<Table responsive striped hover>
            <thead>
            <h3>{messages.get("club.invoice.type.header")}</h3>
            <tr>
                <th className="col-xs-3"><I18n code="tournament.table.column.type"/></th>
                <th className="text-right col-xs-3"><I18n code="club.invoice.amount.in.true"/></th>
                <th className="text-right col-xs-3"><I18n code="club.invoice.amount.in.false"/></th>
                <th className="text-right col-xs-3"><I18n code="club.invoice.balance"/></th>
            </tr>
            </thead>
            <tbody>
            {
                types.map(type => {
                    let typeAmount = typeAmountDict[type];
                    return (<TableEntry key={"TYP" + type}
                                        name={type}
                                        amountIn={typeAmount["IN"]}
                                        amountOut={typeAmount["OUT"]}
                                        balance={typeAmount["IN"] - typeAmount["OUT"]}/>)
                })
            }
            </tbody>
        </Table>)
    } else {
        return null;
    }
};
TypesTable.propTypes = {
    invoiceStat: PropTypes.array.isRequired
};
module.exports = ClubAccountingStat;
