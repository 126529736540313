const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TooltipButton  = require("components/form/TooltipButton"),
    ModalBasic = require ("components/modals/ModalBasic"),
    SelectInputAsyncTypeAhead = require("components/form/SelectInputAsyncTypeAhead"),
    TournamentSexSelect = require("components/tournamentregister/TournamentSexSelect"),
    TournamentConfigAgeSelect = require("components/tournamentregister/TournamentConfigAgeSelect"),
    TournamentConfigBowSelect = require("components/tournamentregister/TournamentConfigBowSelect"),
    TournamentRegSlotSelect = require("components/tournamentregister/TournamentRegSlotSelect"),
    TournamentOptionSelect = require("components/tournamentregister/TournamentOptionSelect"),
    ValidInput = require("components/form/ValidInput");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentUser = require("parse/TournamentUser"),
    ParseError = require("parse/ParseError");
const tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");

class TournamentMMTUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.addTUser = this.addTUser.bind(this);
        this.editTUser = this.editTUser.bind(this);
        this.refModal = React.createRef();
        this.state = {
            showSearch: false,
            modalTitle: "",
            editTUser: null
        }
    }
    addTUser() {
        this.setState({showSearch: this.props.tournament.getManagementID() != null, editTUser: null, modalTitle: messages.get("tournament.mmtuser.tooltip.new")});
        this.refModal.current.open();
    }
    editTUser() {
        this.setState({showSearch: false, editTUser: this.props.selectedObjectList[0], modalTitle: messages.get("tournament.mmtuser.tooltip.edit")});
        this.refModal.current.open();
    }
    render() {
        const {tournament, tournamentRegSlots, selTUserLength} = this.props;
        const {showSearch, editTUser, modalTitle} = this.state;
        return <ModalBasic ref={this.refModal}
                           title={<TextCenter>{modalTitle}</TextCenter>}
                           customButton={<React.Fragment>
                                            <TooltipButton onClick={this.addTUser} disabled={tournament.isTypeCupMaster()}
                                                           icon="plus" tooltip={messages.get("tournament.mmtuser.tooltip.new")} />
                                            <TooltipButton onClick={this.editTUser}  disabled={selTUserLength !== 1}
                                                           icon="pencil-alt" tooltip={messages.get("tournament.mmtuser.tooltip.edit")} />
                                        </React.Fragment>}>
                <TournamentMMTUserForm tournament={tournament} tournamentRegSlots={tournamentRegSlots}
                                       bowUnion={tournament.getBowUnionID()} showSearch={showSearch} editTUser={editTUser}/>
            </ModalBasic>
    }
}
TournamentMMTUserModal.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentRegSlots: PropTypes.array,
    selectedObjectList: PropTypes.array.isRequired,
    selTUserLength: PropTypes.number.isRequired
};
class TournamentMMTUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refUnion = React.createRef();
        this.refSex = React.createRef();
        this.refLicense = React.createRef();
        this.refBowSelect = React.createRef();
        this.refAgeSelect = React.createRef();

        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.configBowSelected = this.configBowSelected.bind(this);
        this.configAgeSelected = this.configAgeSelected.bind(this);
        this.configSexSelected = this.configSexSelected.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.slotSelected = this.slotSelected.bind(this);
        this.buildTournamentUserInit = this.buildTournamentUserInit.bind(this);
        this.fillFormWithTournamentUser = this.fillFormWithTournamentUser.bind(this);
        this.queryMMTUser = this.queryMMTUser.bind(this);
        this.genCupNumber = this.genCupNumber.bind(this);
        let configBow = null;
        let configAge = null;
        let option = null;
        let slotId = null;
        let startSlotId = null;
        let sex = 0;
        if (this.props.editTUser) {
            // preSelect from user
            configBow = this.props.editTUser.getTournamentConfigBow();
            configAge = this.props.editTUser.getTournamentConfigAge();
            option = this.props.editTUser.getOption();
            sex = this.props.editTUser.getSex();
            if (this.props.editTUser.getTournamentRegSlotID()) {
                slotId = this.props.editTUser.getTournamentRegSlotID().id;
                startSlotId = this.props.editTUser.getTournamentRegSlotID().id;
            }
        } else {
            // preSelect first
            if (this.props.tournament.getTournamentConfigBow() != null && this.props.tournament.getTournamentConfigBow().length > 0) {
                configBow = this.props.tournament.getTournamentConfigBow()[0]
            }
            if (this.props.tournament.getTournamentConfigAge() != null && this.props.tournament.getTournamentConfigAge().length > 0) {
                configAge = this.props.tournament.getTournamentConfigAge()[0]
            }
            if (this.props.tournament.getTournamentOptionID() && this.props.tournament.getTournamentOptionID().hasOptions()) {
                option = this.props.tournament.getTournamentOptionID().getOptions()[0];
            }
            if (this.props.tournamentRegSlots != null && this.props.tournamentRegSlots.length > 0) {
                slotId = this.props.tournamentRegSlots[0].id
            }
        }
        this.state = {
            selectedBowType: configBow,
            selectedAgeType: configAge,
            selectedOption: option,
            selectedSlotId: slotId,
            startSlotId: startSlotId,
            sex: sex,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), configAge, configBow, option)
        }
    }
    componentDidMount() {
        if (this.props.editTUser != null) {
            this.fillFormWithTournamentUser(this.props.editTUser);
        }
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refName.current.getValue(),
            this.refSurname.current.getValue()
        ];
        if (this.props.bowUnion != null && this.props.bowUnion.isLicenseNumberMandatory()) {
            required.push(this.refLicense.current.getValue())
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        if (this.state.selectedBowType === null || this.state.selectedAgeType === null) {
            setErrorFct({message: messages.get("tournament.create.error.required")});
            valid = false;
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, setErrorFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let tuMail = this.refMail.current.getValue().trim().toLowerCase();
        if (tuMail == null || tuMail.length <= 0) {
            // set to name.surname@noemail.com
            tuMail = Utility.buildNoEmail(this.refName.current.getValue().trim(), this.refSurname.current.getValue().trim());
        }
        // validate email
        this.needToValidate(this.props.tournament, this.props.editTUser, tuMail, (result) => {
            if (result.valid) {
                let regTUserObject = this.buildTournamentUserInit(tuMail);
                tournamentUserManagerActions.handleTournamentUserRegistration(this.props.tournament, this.props.editTUser, regTUserObject, error => {
                    if (error) {
                        setErrorFct({message: messages.get("parse.error.unexpected")  + ParseError.getTranslatedErrorMessage(error)});
                    } else {
                        closeFct();
                    }
                });
            } else if (result.error) {
                setErrorFct({message: messages.get("parse.error.unexpected") + ParseError.getTranslatedErrorMessage(result.error)});
            } else {
                setErrorFct({message: messages.get("tournament.error.emailInUse") + " " + tuMail + ". " + messages.get("tournament.register.placeHolder.noemail")});
            }
        });
    }
    needToValidate(tournament, editTUser, tuMail, result) {
        if (editTUser != null) {
            result({valid: true, error: null})
        } else {
            tournamentRegisterModalActions.validateTUserEmail(tournament, tuMail, result);
        }
    }
    buildTournamentUserInit(tuMail) {
        console.log("handleTournamentRegistration:" + tuMail);
        let regTUserObject = {};
        regTUserObject.name = this.refName.current.getValue().trim();
        regTUserObject.surname = this.refSurname.current.getValue().trim();
        regTUserObject.tuMail = tuMail;
        regTUserObject.sex = this.state.sex;
        regTUserObject.tConfigAge = this.state.selectedAgeType.id;
        regTUserObject.tConfigBow = this.state.selectedBowType.id;
        if (this.props.editTUser != null) {
            // set here than otherwise only the id is saved
            this.props.editTUser.setTournamentConfigBow(this.state.selectedBowType);
            this.props.editTUser.setTournamentConfigAge(this.state.selectedAgeType);
        } else {
            // can be ignored if editTUser is set
            regTUserObject.parcoursID = this.props.tournament.getParcoursID().id;
        }
        if (this.refUnion.current.getValue() && this.refUnion.current.getValue().length > 0) {
            regTUserObject.union = this.refUnion.current.getValue().trim();
        }
        if (this.state.selectedOption != null) {
            regTUserObject.option = this.state.selectedOption;
        }
        if (this.state.selectedSlotId != null) {
            regTUserObject.regSlotId = this.state.selectedSlotId;
            regTUserObject.regSlotIdOverride = true
            if (this.state.startSlotId != null) {
                regTUserObject.startSlotId = this.state.startSlotId;
            }
        }
        if (this.refLicense.current && this.refLicense.current.getValue()) {
            regTUserObject.licNumber = this.refLicense.current.getValue().trim();
        }
        if (this.props.bowUnion != null
            && this.props.bowUnion.isLicenseNumberMandatory()) {
            regTUserObject.licUnionCode = this.props.bowUnion.getCode();
        }
        if (this.props.tournament.getCupGroup()) {
            regTUserObject.cupGroup = this.props.tournament.getCupGroup();
        }
        regTUserObject.price = this.state.price;
        regTUserObject.tournamentID = this.props.tournament.id;
        regTUserObject.status = this.props.tournament.isRunning() ? TournamentUser.prototype.status.present : TournamentUser.prototype.status.registered;
        regTUserObject.selfRegistration = false;
        return regTUserObject;
    }
    queryMMTUser(searchTxt, callback) {
        let buCode = this.props.tournament.isTypeCupChild() ?  this.props.bowUnion.getCode() : null;
        tournamentUserManagerActions.queryMMTournamentUser(searchTxt, buCode, callback);
    }
    fillFormWithTournamentUser(tUser) {
        console.log("fill form with tUser" + tUser.id);
        if (tUser.getSurname() != null) {
            this.refName.current.setValue(tUser.getName());
            this.refSurname.current.setValue(tUser.getSurname());
        } else {
            // handling for older entries without one name column
            const fullname = tUser.getName().trim();
            const fullNames = fullname.split(' ');
            if (fullNames != null && fullNames.length > 1) {
                const name = fullNames[0];
                let surname = null;
                if (fullNames.length > 2) {
                    fullNames.shift();
                    surname = fullNames.join(' ');
                } else {
                    surname = fullNames[1];
                }
                this.refName.current.setValue(name);
                this.refSurname.current.setValue(surname);
            } else {
                // very unlikely
                this.refName.current.setValue(fullname);
            }
        }
        this.refMail.current.setValue(tUser.getPlayerEmail());
        ReactDOM.findDOMNode(this.refSex.current).value = tUser.getSex();
        if (tUser.getUnion() != null) {
            this.refUnion.current.setValue(tUser.getUnion());
        }
        if (this.props.bowUnion != null && this.props.bowUnion.isLicenseNumberMandatory()) {
            if (tUser.getLicenseUnionCode() == this.props.bowUnion.getCode()) {
                this.refLicense.current.setValue(tUser.getLicenseNumber());
            }
        }
        // no need to set with editTUser - will already be done in the constructor
        if (this.props.editTUser == null) {
            // sets bow and age config for found user and recalcs the price
            let fittingTConfigBow = this.props.tournament.findFittingTournamentConfigBow(tUser);
            if (fittingTConfigBow != null) {
                ReactDOM.findDOMNode(this.refBowSelect.current).value = fittingTConfigBow.id;
            } else {
                fittingTConfigBow = this.state.selectedBowType;
            }
            let fittingTConfigAge = this.props.tournament.findFittingTournamentConfigAge(tUser);
            if (fittingTConfigAge != null) {
                ReactDOM.findDOMNode(this.refAgeSelect.current).value = fittingTConfigAge.id;
            } else {
                fittingTConfigAge = this.state.selectedAgeType
            }
            this.setState({
                selectedAgeType: fittingTConfigAge,
                selectedBowType: fittingTConfigBow,
                sex: tUser.getSex(),
                price: this.calcPrice(this.props.tournament.getTournamentPriceID(), fittingTConfigAge, fittingTConfigBow, this.state.selectedOption)
            })
        }
    }
    genCupNumber() {
        tournamentUserManagerActions.getNextBowUnionNumber(this.props.bowUnion.getCode(), licNumber => {
            this.refLicense.current.setValue(licNumber);
        });
    }
    configSexSelected(sex) {
        this.setState({
            sex: sex
        })
    }
    configBowSelected(configBowType) {
        this.setState({
            selectedBowType: configBowType,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), this.state.selectedAgeType, configBowType,  this.state.selectedOption)})
    }
    configAgeSelected(configAgeType) {
        this.setState({
            selectedAgeType: configAgeType,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), configAgeType, this.state.selectedBowType, this.state.selectedOption)
        })
    }
    optionSelected(option) {
        this.setState({
            selectedOption: option,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), this.state.selectedAgeType, this.state.selectedBowType, option)
        })
    }
    slotSelected(slotId) {
        this.setState({selectedSlotId: slotId});
    }
    calcPrice(tPrice, ageType, bowType, option) {
        let sum = 0;
        if (tPrice != null && tPrice.getPrice()) {
            sum += tPrice.getPrice();
        }
        if (ageType) {
            if ( ageType.getPrice()) {
                sum += ageType.getPrice();
            }
            let tOptionObject = ageType.getTOptionSurcharge();
            if (tOptionObject && option) {
                if (tOptionObject[this.props.tournament.id + "_" + option]) {
                    sum += parseFloat(tOptionObject[this.props.tournament.id + "_" + option])
                } else if (tOptionObject[option]) {
                    sum += parseFloat(tOptionObject[option]) // for options without tournamentId
                }
            }
            if (tOptionObject && this.props.tournament.isTypeCupChild() && tOptionObject[this.props.tournament.id]) {
                sum += parseFloat(tOptionObject[this.props.tournament.id])
            }
        }
        if (bowType && bowType.getPrice()) {
            sum += bowType.getPrice();
        }
        return sum;
    }
    render() {
        const {error, submitDisabled} = this.context;
        const validateNow = error && (error.message != null || error.length > 0);
        const {selectedBowType, selectedAgeType, selectedOption, selectedSlotId, price, sex} = this.state;
        const {tournament, tournamentRegSlots, bowUnion, showSearch} = this.props;
        const denyEdit = tournament.isClosed() && !showSearch;
        return (
            <form action="#">
                <Row>
                    {
                        showSearch && tournament.hasManagementID() ? <Col  sm={12}>
                            <SelectInputAsyncTypeAhead
                                onSearch={this.queryMMTUser}
                                handleSelectedObject={this.fillFormWithTournamentUser}
                                placeholder={messages.get("tournament.mmtuser.search4name")}/>
                            <br/><br/>

                        </Col> : null
                    }
                    <Col sm={6}>
                        <ValidInput ref={this.refName}
                                    valid={{maxLength: 30, check: ['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.name")}/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refSurname}
                                    valid={{maxLength: 30, check: ['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.surname")}/>
                    </Col>
                    <Col sm={12}>
                        <ValidInput ref={this.refMail}
                                    valid={{maxLength: 50, check: ['isRequired','isMail']}}
                                    // validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.email.unique")}
                                    addonBefore={<FontAwesome icon="envelope"/>}/>
                        <TournamentSexSelect inputRef={this.refSex} defaultValue={sex} disabled={denyEdit}
                                             selectCallback={this.configSexSelected} />

                        <TournamentConfigBowSelect label={messages.get("tournament.class.bow")} inputRef={this.refBowSelect}
                                                   defaultValue={selectedBowType ? selectedBowType.id : null} disabled={denyEdit}
                                                   tournament={tournament} selectCallback={this.configBowSelected}/>
                        <TournamentConfigAgeSelect label={messages.get("tournament.class.age")} inputRef={this.refAgeSelect}
                                                   defaultValue={selectedAgeType ? selectedAgeType.id : null} disabled={denyEdit}
                                                   tournament={tournament}  selectCallback={this.configAgeSelected}/>
                        {
                            tournament.getTournamentOptionID() != null ?
                                <TournamentOptionSelect tOption={tournament.getTournamentOptionID()}
                                                        tournament={tournament}
                                                        selectedAgeType={selectedAgeType}
                                                        defaultValue={selectedOption}
                                                        selectCallback={this.optionSelected} /> : null
                        }
                        {
                            tournamentRegSlots != null && tournamentRegSlots.length > 0 ?
                                <TournamentRegSlotSelect tournamentRegSlots={tournamentRegSlots}  showAll={true}
                                                         selectedSlotId={selectedSlotId}
                                                         selectCallback={this.slotSelected}/> : null
                        }
                        <ValidInput ref={this.refUnion}
                                    valid={{maxLength: 40}}
                                    placeholder={messages.get("tournament.register.union")}
                                    addonBefore={<FontAwesome icon="university"/>}/>

                        {
                            bowUnion && bowUnion.isLicenseNumberMandatory() ?
                                <ValidInput ref={this.refLicense}
                                            valid={{maxLength: 30, check: ['isRequired']}}
                                            label={bowUnion.getName() + " #"}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.mandatory.license")}
                                            buttonAfter={bowUnion.isAutoLicenseNumber() ? <Button onClick={this.genCupNumber}><FontAwesome icon="plus"/>NEU</Button> : null}
                                            addonBefore={<FontAwesome icon="id-card"/>}
                                /> : null
                        }
                        <br/><label>{messages.get("tournament.register.label.price") + ": "} </label>{price}
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"tournament.register.submit"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentMMTUserForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentRegSlots: PropTypes.array,
    bowUnion:PropTypes.object.isRequired,
    showSearch: PropTypes.bool.isRequired,
    editTUser: PropTypes.object
};
TournamentMMTUserForm.contextType = ModalBasicContext;
module.exports = TournamentMMTUserModal;
