var Parse = require("ParseInit");

var TransactionEvent = Parse.Object.extend("transactionEvent", {
    col: {
          ACL: "ACL",
          action: "action",
          createdAt: "createdAt",
          externalTransactionId: "externalTransactionId",
          objectId: "objectId",
          response: "response",
          transactionID: "transactionID",
          updatedAt: "updatedAt"
    },
    getACL: function() {
        return this.get(this.col.ACL)
    },
    setACL: function(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAction: function() {
        return this.get(this.col.action)
    },
    setAction: function(action) {
        return this.set(this.col.action, action)
    },
    getCreatedAt: function() {
        return this.get(this.col.createdAt)
    },
    getExternalTransactionId: function() {
        return this.get(this.col.externalTransactionId)
    },
    setExternalTransactionId: function(externalTransactionId) {
        return this.set(this.col.externalTransactionId, externalTransactionId)
    },
    getObjectId: function() {
        return this.get(this.col.objectId)
    },
    setObjectId: function(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getResponse: function() {
        return this.get(this.col.response)
    },
    setResponse: function(response) {
        return this.set(this.col.response, response)
    },
    getTransactionID: function() {
        return this.get(this.col.transactionID)
    },
    setTransactionID: function(transactionID) {
        return this.set(this.col.transactionID, transactionID)
    },
    getUpdatedAt: function() {
        return this.get(this.col.updatedAt)
    }
});

module.exports = TransactionEvent;
