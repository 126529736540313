const React = require("react"),
    PropTypes = require('prop-types');

const StrikeOutText = ({strikeOut, children}) => {
    if (strikeOut) {
        return <del>{children}</del>
    } else {
        return  children
    }
};
StrikeOutText.propTpyes = {
    strikeOut: PropTypes.bool.isRequired
}
module.exports = StrikeOutText;