const React = require("react"),
    PropTypes = require('prop-types');
const {InputGroup, Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");
const {Typeahead, Menu, MenuItem} = require('react-bootstrap-typeahead');

class DateGroupInputTypeAhead extends React.Component {
    constructor(props) {
        super(props);
        this.searchElement = null;
        this.setSearchElementRef = element => {
            this.searchElement = element;
        };
        this.handleChange = this.handleChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleSubmitE = this.handleSubmitE.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.state = {
            value: null
        };
    }

    handleChange(selObjectsArr) {
        if (selObjectsArr.length == 1) {
            this.props.handleSelectedObject(selObjectsArr[0]);
        }
    }
    onInputChange(event) {
        // TODO to know on enter was has been entered
        this.setState({value: event})
    }
    handleSubmitE(event) {
        event.preventDefault();
        if (this.props.handleNoObjectFound) {
            console.log("Didnt found " + this.state.value);
            if (this.state.value) {
                this.props.handleNoObjectFound(this.state.value.trim());
            } else {
                this.props.handleNoObjectFound("");
            }

        }
    }
    clearInput() {
        if (this.searchElement) {
            this.searchElement.instanceRef.clear();
        }
    }
    render() {
        const {objectList, defaultValue, placeholder, disabled, handleNoObjectFound} = this.props;
        let month = 0;
        return  (
            <form onSubmit={this.handleSubmitE}>
                <InputGroup>
                    <InputGroup.Addon>
                        <FontAwesome icon="search"/>
                    </InputGroup.Addon>
                    <Typeahead ref={this.setSearchElementRef}
                               defaultInputValue={defaultValue}
                               disabled={disabled || objectList == null}
                               minLength={2}
                               labelKey={object => object.getDatalistText ? object.getDatalistText() : ""}
                               renderMenu={(options, menuProps) => (
                                   <Menu {...menuProps}>
                                       {
                                           options.map((option, index) => {
                                               if (typeof option.getDatalistDate !== "undefined") {
                                                   const date = option.getDatalistDate();
                                                   if (month !== (date.getMonth() + 1)) {
                                                       month = date.getMonth() + 1;
                                                       return (
                                                           <React.Fragment key={"div" + option.id}>
                                                               <h4>&nbsp;{messages.get("montPager.month." + month) + " " + date.getFullYear()}</h4>
                                                               <MenuItem option={option} position={index}>
                                                                   {option.getDatalistText()}
                                                               </MenuItem>
                                                           </React.Fragment>);
                                                   } else {
                                                       return (<MenuItem option={option} position={index}>
                                                           {option.getDatalistText()}
                                                       </MenuItem>);
                                                   }
                                               }
                                           })
                                       }
                                   </Menu>
                               )}
                               emptyLabel={false}
                               multiple={false}
                               options={objectList}
                               placeholder={placeholder}
                               onChange={this.handleChange}
                               onInputChange={this.onInputChange}
                               submitFormOnEnter={handleNoObjectFound != null}
                    />
                    {
                        handleNoObjectFound != null ?
                            <InputGroup.Button>
                                <Button bsStyle="primary" type="submit">
                                    <FontAwesome icon="level-down-alt" rotation={90}/>
                                </Button>
                            </InputGroup.Button> : null
                    }

                </InputGroup>
            </form>)
    }
}
DateGroupInputTypeAhead.propTypes = {
    placeholder:PropTypes.string,
    defaultValue: PropTypes.string,
    disabled:PropTypes.bool,
    handleNoObjectFound:PropTypes.func.isRequired,
    handleSelectedObject:PropTypes.func.isRequired,
    objectList:PropTypes.array.isRequired
};
module.exports = DateGroupInputTypeAhead;
