const alt = require("AltInit");
const parseDao = require("actdao/ParseBaseDao");
const notificationActions = require("components/notification/NotificationActions");

class CalendarActions {

    updateCalendarBoundaries(date) {
        return date
    }

    updateSearchParams(searchParams) {
        return searchParams;
    }

    updateEventLoading(loading) {
        return loading;
    };

    updateCalendarEvents(calEvents) {
        return calEvents;
    }

    updateCalendarEvent(calEvent) {
        return calEvent;
    }

    removeCalendarEvent(calEvent) {
        return calEvent;
    }

    deleteCalendarEvent(clubEvent) {
        parseDao.deleteObject(clubEvent)
            .then(clubEvent => {
                this.removeCalendarEvent(clubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.deleteEvent";
                notificationActions.parseError(error);
            });
    }


    saveClubEvent(clubEvent, mailAddresses) {
        parseDao.saveObject(clubEvent)
            .then(clubEvent => {
                this.sentEmailClubEvent(clubEvent, mailAddresses);
                this.updateClubEvent(clubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.saveClubEvent";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteEvent(clubEvent) {
        parseDao.deleteObject(clubEvent)
            .then(clubEvent => {
                this.removeClubEvent(clubEvent);
            })
            .catch(error => {
                error.trace = "CLBA.deleteEvent";
                notificationActions.parseError(error);
            });
    }
    setSelectedCalEvent(calEvent) {
        return calEvent
    }

    setInvalidUser() {
        console.log("INVALID USER ACTION");
        return{};
    };
}

module.exports = alt.createActions(CalendarActions);
