const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Button, ButtonGroup, DropdownButton, MenuItem, FormControl, Tab, Tabs} = require("react-bootstrap");
const {ParcoursOwner, Parcours} = require("parse/_Domain");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    ParcoursOwnerHomeLink = require("components/parcours/ParcoursOwnerHomeLink"),
    ParcoursAddModal = require("components/parcours/ParcoursAddModal");
    FontAwesome = require("components/widgets/FontAwesome"),
    ImageSelector = require("components/form/ImageSelector"),
    Loading = require("components/widgets/Loading"),
    TournamentCreateModal = require("components/tournamentmodal/TournamentCreateModal"),
    ClubCreateModal = require("components/clubmodal/ClubCreateModal");

const ParcoursRankingButton = require("components/links/ParcoursRankingButton"),
    ParcoursArticlesButton = require("components/links/ParcoursArticlesButton"),
    ParcoursVisitListButton = require("components/links/ParcoursVisitListButton"),
    ParcoursTargetButton = require("components/links/ParcoursTargetButton"),
    ParcoursTargetTracksButton = require("components/links/ParcoursTargetTracksButton"),
    ParcoursTransactionButton = require("components/links/ParcoursTransactionButton"),
    ParcoursStatisticButton = require("components/links/ParcoursStatisticButton");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const userStore = require("stores/UserStore"),
    parcoursMgmtActions = require("actions/ParcoursMgmtActions"),
    parcoursMgmtStore = require("stores/ParcoursMgmtStore"),
    notificationActions = require("components/notification/NotificationActions");

class ParcoursDashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleParcoursMgmtChange = this.handleParcoursMgmtChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            parcoursMgmtStore: parcoursMgmtStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("profile.managedParcours.header");
        userStore.listen(this.handleUserChange);
        parcoursMgmtStore.listen(this.handleParcoursMgmtChange);
        notificationActions.reset();
        parcoursMgmtActions.queryManagedParcours(this.state.userStore.user);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        parcoursMgmtStore.unlisten(this.handleParcoursMgmtChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state
        });
    }
    handleParcoursMgmtChange(state) {
        this.setState({
            parcoursMgmtStore: state
        });
    }
    render() {
        return (
            <Grid>
                <Row>
                    <Col xs={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/account" code="profile.managedParcours.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                {this.state.userStore.user == null ?
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="danger">
                                <FontAwesome icon="exclamation-circle"/>
                                <I18n code="tournament.register.description.noaccount"/>
                            </Alert>
                        </Col>
                    </Row>
                    :
                    <React.Fragment>
                        {
                            this.state.parcoursMgmtStore.loading === true ?
                                <Loading />
                                :
                                this.state.parcoursMgmtStore.ownerParcoursDic != null && this.state.parcoursMgmtStore.managements != null ?
                                    <ParcoursOwnersDicTab
                                        user={this.state.userStore.user}
                                        managements={this.state.parcoursMgmtStore.managements}
                                        ownerParcoursDic={this.state.parcoursMgmtStore.ownerParcoursDic}
                                    />
                                    :
                                    <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert>
                        }
                    </React.Fragment>
                }
            </Grid>

        )
    }
}
const ParcoursOwnersDicTab = ({user, managements, ownerParcoursDic}) => {
    let index = 0;
    const clubList = [];
    return (
        <Row>
            <Col xs={12}>
                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                    {
                        managements.map(management => {
                            const owners = management.getParcoursOwnerID();
                            if (owners && owners.length > 0) {
                                // owner has at least one parcours
                                return owners.map(parcoursOwner => {
                                    index++;
                                    if (parcoursOwner.getClubID()) {
                                        clubList.push(parcoursOwner.getClubID().id)
                                    }
                                    return (<Tab key={parcoursOwner.id} eventKey={index} title={parcoursOwner.getName()}>

                                        <ManagementOwnerTabContent user={user}
                                                                    management={management}
                                                                    club={parcoursOwner.getClubID()}
                                                                    parcoursList={ownerParcoursDic[parcoursOwner.id]}
                                                                    parcoursOwner={parcoursOwner}/>
                                    </Tab>)
                                })
                            }
                        })
                    }
                    {
                        managements.map(management => {
                            const clubs = management.getClubIDs();
                            if (clubs && clubs.length > 0) {
                                // owner has at least one club
                                return clubs.map(club => {
                                    index++;
                                    if (clubList.indexOf(club.id) == -1) {
                                        //only add if club is not part of an parcours owner
                                        return (<Tab key={club.id} eventKey={index} title={club.getName()}>
                                            <ManagementOwnerTabContent user={user}
                                                                       management={management}
                                                                       club={club}
                                                                       parcoursList={null}
                                                                       parcoursOwner={null}/>
                                        </Tab>)
                                    }

                                })
                            }
                        })
                    }
                </Tabs>
            </Col>
        </Row>
    )
};
ParcoursOwnersDicTab.propTypes = {
    user: PropTypes.object.isRequired,
    managements: PropTypes.array.isRequired,
    ownerParcoursDic: PropTypes.any.isRequired
};

class ManagementOwnerTabContent extends React.Component {
    constructor(props) {
        super(props);
        this.refClubCreateModal = React.createRef();
        this.refParcoursAddModal = React.createRef();
        this.openClubCreateModal = this.openClubCreateModal.bind(this);
        this.handleAddParcours = this.handleAddParcours.bind(this);
        this.handleRulesUpdated = this.handleRulesUpdated.bind(this);

        this.state = {}
    }
    openClubCreateModal() {
        this.refClubCreateModal.current.open();
    }
    handleAddParcours() {
        if (this.props.user !== null) {
            this.refParcoursAddModal.current.open();
        }
    }
    handleRulesUpdated(image) {
        // save it to owner
        parcoursMgmtActions.updateParcoursCC(this.props.parcoursOwner, ParcoursOwner.prototype.col.ruleImageID, image != null ? image.id: null)
    }
    render() {
        const {user, management, club, parcoursOwner, parcoursList} = this.props;
        const paymentDisabled = !management.hasPaymentFeature();
        const parcoursDisabled = !management.hasParcoursFeature();
        return (
            <Row>
                <br/><br/>
                {
                    parcoursList ?
                        <Col sm={12}>
                            <h3><ParcoursOwnerHomeLink parcoursOwner={parcoursOwner} label="-> Landing Page" /></h3>
                            <h3><I18n code="dashboard.parcours.title"/></h3>
                            <ParcoursAddModal ref={this.refParcoursAddModal} parcoursOwner={parcoursOwner}/>
                            <Button bsStyle="primary" onClick={this.handleAddParcours}>
                                <FontAwesome icon="plus"/><I18n code="parcours.create.addMyParcours"/>
                            </Button>
                            {parcoursList.map(parcours => {
                                return (<ManagedParcoursEntry key={"MP" + parcours.id}
                                                              user={user}
                                                              parcours={parcours}
                                                              parcoursDisabled={parcoursDisabled}
                                                              paymentDisabled={paymentDisabled}/>);
                            })}
                        </Col> : null

                }

                <Col sm={12}>
                    <h3><I18n code="tournament.table.column.union"/></h3><br/>
                    {
                        club != null ?
                            <ButtonGroup>
                                <Button bsStyle="success" href={linkUtil.getLink('/club/' + club.id)} target="_BLANK">{club.getName()}</Button>
                            </ButtonGroup>
                            :
                            <Button bsStyle="primary" onClick={this.openClubCreateModal}>
                                <FontAwesome icon="plus"/><I18n code="club.button.create"/>
                            </Button>
                    }
                    <ClubCreateModal ref={this.refClubCreateModal} parcoursOwner={parcoursOwner} user={user}/>
                    <br/><br/>
                </Col>
                {
                    parcoursOwner ? <React.Fragment>
                        <Col xs={6}>
                            <h3><I18n code="management.feature.PP"/></h3><br/>
                            <ButtonGroup>
                                <ParcoursArticlesButton disabled={paymentDisabled} bsStyle={"success"} parcoursOwner={parcoursOwner}/>
                                <ParcoursTransactionButton disabled={paymentDisabled} bsStyle={"success"} management={management}/>
                            </ButtonGroup>
                        </Col>
                        <Col xs={6}>
                            <h3><I18n code="parcours.visit.header"/></h3><br/>
                            <ButtonGroup>
                                <ParcoursVisitListButton bsStyle={"success"} parcoursOwner={parcoursOwner}/>
                            </ButtonGroup>
                        </Col>
                        <Col xs={12}>
                            <br/><br/>
                                <ImageSelector object={parcoursOwner}
                                               user={user}
                                               colName={ParcoursOwner.prototype.col.ruleImageID}
                                               header={messages.get("profile.managedParcours.fileupload.description")}
                                               showBGParams={false}
                                               savedImageCallback={this.handleRulesUpdated}
                                               allowedFileTypes={["P","D"]} />
                        </Col></React.Fragment> : null
                }

            </Row>
        )
    }
}
ManagementOwnerTabContent.propTypes = {
    user: PropTypes.object.isRequired,
    management: PropTypes.object.isRequired,
    club: PropTypes.object,
    parcoursOwner: PropTypes.object,
    parcoursList: PropTypes.array
};
class ManagedParcoursEntry extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentCreateModal = React.createRef();

        this.handlePayStatusChanged = this.handlePayStatusChanged.bind(this);
        this.handleParcoursBookChanged = this.handleParcoursBookChanged.bind(this);
        this.openTournamentCreateModal = this.openTournamentCreateModal.bind(this);

        this.state = {}
    }
    handlePayStatusChanged(event) {
        parcoursMgmtActions.updateParcoursCC(this.props.parcours, Parcours.prototype.col.payStatus, event.target.value);
    }
    handleParcoursBookChanged(event) {
        parcoursMgmtActions.updateParcoursCC(this.props.parcours, Parcours.prototype.col.visitStatus, event.target.value);
    }
    openTournamentCreateModal() {
        this.refTournamentCreateModal.current.open();
    }
    render() {
        const {user, parcours, parcoursDisabled, paymentDisabled} = this.props;
        return (
        <Row>
            <hr/>
            <Col xs={12} sm={3}>
                <a href={linkUtil.getLink('/parcours/' + parcours.getOnlineID())} target="_BLANK"><strong>{parcours.getName() != null ? parcours.getName() : "Parcours"}</strong></a>
                <br/><br/>
            </Col>
            <Col xs={6} sm={3}>
                <FormControl disabled={paymentDisabled} componentClass="select" defaultValue={parcours.getNonNullPayStatus()} onChange={this.handlePayStatusChanged}>
                    {["A", "I"].map(payStatus => {
                        return <option key={payStatus}
                                       value={payStatus}>{messages.get("profile.managedParcours.payment." + payStatus)}</option>
                    })}
                </FormControl>
                <br/>
                <FormControl disabled={parcoursDisabled} componentClass="select" defaultValue={parcours.getNonNullVisitStatus()} onChange={this.handleParcoursBookChanged}>
                    {["F","P", "A", "I"].map(visitStatus => {
                        return <option key={visitStatus}
                                       value={visitStatus}>{messages.get("profile.managedParcours.visit." + visitStatus)}</option>
                    })}
                </FormControl>
            </Col>
            <Col xs={6} sm={6}>
                <Button bsStyle="primary" onClick={this.openTournamentCreateModal}>
                    <FontAwesome icon="plus"/><I18n code="tournament.button.create"/>
                </Button>
                <TournamentCreateModal ref={this.refTournamentCreateModal} parcours={parcours} user={user}/>
                <br/><br/>
                <DropdownButton bsStyle="success" title={messages.get("profile.managedParcours.parcours.function")}>
                    <ParcoursRankingButton bsStyle="success" parcours={parcours} />
                    <ParcoursTargetButton bsStyle="success" parcours={parcours} disabled={parcoursDisabled} edit={true}/>
                    <ParcoursTargetTracksButton bsStyle="success" parcours={parcours} disabled={parcoursDisabled}/>
                    <ParcoursStatisticButton bsStyle="success" parcours={parcours} />
                </DropdownButton>
            </Col>
        </Row>);
    }
}
ManagedParcoursEntry.propTypes = {
    user: PropTypes.object.isRequired,
    parcours:PropTypes.object.isRequired,
    paymentDisabled:PropTypes.bool.isRequired
};
module.exports = ParcoursDashboardPage;
