const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const poiDao = require("actdao/PointInterestDao");
const parseDao = require("actdao/ParseBaseDao");

class PointInterestActions {
    queryPOI(activeOnly) {
        poiDao.query(activeOnly)
            .then(poiList => {
                this.updatePoiList(poiList);
            })
            .catch(error => {
                error.trace = "POIA.queryPOI";
                notificationActions.parseError(error);
            });
        return {};
    };

    updateStatus(poi, status) {
        poi.setStatus(status);
        parseDao.saveObject(poi)
            .then(sPoi => {
                this.updatePoi(sPoi);
            })
            .catch(error => {
                error.trace = "POIA.updateStatus";
                notificationActions.parseError(error);
            });
        return {};
    };
    savePOI(poi) {
        parseDao.saveObject(poi)
            .then(sPoi => {
                this.updatePoi(sPoi);
            })
            .catch(error => {
                error.trace = "POIA.savePOI";
                notificationActions.parseError(error);
            });
        return {};
    }

    updatePoiList(poiList) {
        return poiList
    };
    updatePoi(poi) {
        return poi;
    };
    updateEditPoi(poi) {
        return poi;
    };

}

module.exports = alt.createActions(PointInterestActions);
