const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, Modal} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    linkUtil = require("linkUtil"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    SubmitButton = require("components/form/SubmitButton"),
    GroupPlaces = require("components/widgets/GroupPlaces");

const ParcoursTypeGroup = require("components/parcours/ParcoursTypeGroup");

const Parcours = require("parse/Parcours");
const parcoursActions = require("actions/ParcoursActions");

class ParcoursCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refName = React.createRef();
        this.refZip = React.createRef();

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.valid = this.valid.bind(this);
        this.validationError = this.validationError.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.add2Owner = this.add2Owner.bind(this);
        this.createParcours = this.createParcours.bind(this);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.setTypeTarget = this.setTypeTarget.bind(this);

        let tMainGroupPlace= null;
        let tRegionGroupPlace= null;
        if (this.props.parcours) {
            tMainGroupPlace = this.props.parcours.getMainGroupPlaceID();
            tRegionGroupPlace = this.props.parcours.getGroupPlaceID();
        }
        this.state = {
            mainGroupPlace: tMainGroupPlace,
            regionGroupPlace: tRegionGroupPlace,
            showModal: false,
            type: Parcours.prototype.type.parcours,
            similarOwnersDic: null,
            newName: "",
            newZip: "",
            missingRegion: false
        };
    }
    close() {
        this.validationError(null);
        this.setState({showModal: false, similarOwnersDic: null, newName:"", newZip:""});
    }
    open() {
        this.setState({showModal: true});
    }
    valid() {
        let valid = true;
        if (this.state.mainGroupPlace == null || this.state.regionGroupPlace == null) {
            this.setState({missingRegion: true});
            this.refSubmitButton.current.resetLoading();
            return false;
        }
        const required = [
            this.refName.current.getValue(),
            this.refZip.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.validationError({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    validationError(stateError) {
        this.refSubmitButton.current.resetLoading();
        this.setState(stateError);
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        const zip = this.refZip.current.getValue().toString().trim();
        const name = this.refName.current.getValue().trim();
        parcoursActions.findSimilarParcours(zip, ownerDic => {
            if (ownerDic != null && Object.keys(ownerDic).length > 0) {
                //console.log("Found similar parcours");
                this.validationError(null);
                this.setState({
                    similarOwnersDic: ownerDic,
                    newName: name,
                    newZip: zip
                });
            } else {
                // create parcours
                this.createParcours(zip, name);
            }
        });
    }
    createParcours(zip, name) {
        parcoursActions.createParcours(name, zip, null, this.state.type, this.state.regionGroupPlace, this.state.mainGroupPlace);
    }
    add2Owner(owner) {
        parcoursActions.createParcours(this.state.newName, null, owner.id, this.state.type, owner.getGroupPlaceID().id, owner.getMainGroupPlaceID().id);
    }
    handleGroupPlaceChange(mainGroupPlace, regionGroupPlace) {
        this.setState({
            mainGroupPlace: mainGroupPlace,
            regionGroupPlace: regionGroupPlace
        });
    }
    setTypeTarget(type) {
        this.setState({
            type: type
        });
    }
    render() {
        const {error, showModal, similarOwnersDic, mainGroupPlace, regionGroupPlace, missingRegion, type, newName, newZip} = this.state
        const validateNow = error && error.length > 0;
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <form action="#">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="parcours.create.title"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            error ? <Alert bsStyle="warning">{error}</Alert> : ""
                        }
                        {
                            similarOwnersDic != null ?
                                <ChooseSimilarParcours
                                    add2Owner={this.add2Owner}
                                    similarOwnersDic={similarOwnersDic} />
                                :
                                <Row>
                                    <Col sm={12}>
                                        <p>{messages.get("parcours.create.description")}</p>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 500, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("parcours.create.name")}/>
                                        <ValidInput ref={this.refZip}
                                                    valid={{maxLength: 6, check:['isRequired', 'isNumber']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.create.zip")}/>
                                        <ParcoursTypeGroup parcoursType={type} setTypeTarget={this.setTypeTarget}/>
                                        <br/><br/>
                                        <GroupPlaces handleChange={this.handleGroupPlaceChange}
                                                     mainGroupPlace={mainGroupPlace}
                                                     regionGroupPlace={regionGroupPlace}
                                                     bsStyle={missingRegion?"warning":undefined}/>

                                        <br/>
                                    </Col>
                                </Row>

                        }

                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        {
                            similarOwnersDic != null ?
                                <SubmitButton ref={this.refSubmitButton}
                                              onClick={() => this.createParcours(newZip, newName)}
                                              icon={<FontAwesome icon="plus"/>}
                                              text={newName + " - " + messages.get("parcours.create.submit")}
                                              bsStyle="primary"/>
                                :
                                <SubmitButton ref={this.refSubmitButton}
                                              onClick={this.handleSubmit} disabled={false}
                                              icon={<FontAwesome icon="plus"/>}
                                              text={messages.get("parcours.create.submit")}
                                              bsStyle="primary"/>
                        }
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
const TypeButton = ({selType, type, label, setTypeTarget}) => {
    function handleClick() {
        setTypeTarget(type)
    }
    return <Button onClick={handleClick} active={selType === type}>{label}</Button>
};
const ChooseSimilarParcours = ({add2Owner, similarOwnersDic}) => {
        return <Row>
            <Col sm={12}>
                <p>{messages.get("Dialog_Header_createReuseParcours")}</p>
                {
                    Object.keys(similarOwnersDic).map(ownerID => {
                        const owner = similarOwnersDic[ownerID]
                        return <p key={"SEL" + owner.id} >
                            <a href={linkUtil.getLink("parcours/" + owner.id + "/home")}>{owner.getName() + " - " + owner.getZipPlace()}</a>
                            <Button onClick={()=> {add2Owner(owner)}}><FontAwesome icon="plus"/>{messages.get("parcours.create.addParcours")}</Button>
                        </p>
                    })
                }
            </Col>
        </Row>
};
ChooseSimilarParcours.propTypes = {
    add2Owner:PropTypes.func.isRequired,
    similarOwnersDic:PropTypes.array.isRequired
};
module.exports = ParcoursCreateModal;
