const alt = require("AltInit");
const parseDao = require("actdao/ParseBaseDao");
const changeLogDao = require("actdao/ChangeLogDao");
const parcoursDao = require("actdao/ParcoursDao");
const userDao = require("actdao/UserDao");
const msgActions = require("actions/MessageActions");
const {ChangeLog} = require("parse/_Domain");
const notificationActions = require("components/notification/NotificationActions");
class ChangeLogActions {

    addTournamentUserDeleteCL(tournament, tournamentUser, user, oldValue) {
        const cl = new ChangeLog();
        cl.setEditor(user);
        cl.setColumnName("TUstatus");
        cl.setOldValue(oldValue);
        cl.setNewValue("D");
        cl.setTournamentID(tournament)
        cl.setTournamentUserID(tournamentUser);
        cl.setAdminComment(tournamentUser.getFullName())
        parseDao.saveObject(cl)
            .then(sCl => {
                console.log("saved changelog delete user")
            })
            .catch(error => {
                error.trace = "CHLA.tUserDelete";
                notificationActions.parseError(error);
            });
        return {};
    };

    addTournamentChange(tournament, user,column, oldValue, newValue) {
        const cl = new ChangeLog();
        cl.setEditor(user);
        cl.setColumnName(column);
        if (oldValue != null) {
            cl.setOldValue(oldValue);
        }
        cl.setNewValue(newValue);
        cl.setTournamentID(tournament)
        parseDao.saveObject(cl)
            .then(sCl => {
                console.log("saved changelog tournament")
            })
            .catch(error => {
                error.trace = "CHLA.tUserDelete";
                notificationActions.parseError(error);
            });
        return {};
    }

    addLinkTournament2BusinessCL(tournament, management, user) {
        const cl = new ChangeLog();
        cl.setEditor(user);
        cl.setColumnName("manageStatus");
        cl.setManagementID(management);
        cl.setNewValue("A");
        cl.setOldValue("D");
        cl.setTournamentID(tournament);
        parseDao.saveObject(cl)
            .then(sCl => {
                console.log("saved changelog")
            })
            .catch(error => {
                error.trace = "CHLA.LiTB";
                notificationActions.parseError(error);
            });
        return {};
    };

    unlinkBusinessAccount(tournament, management, user) {
        const cl = new ChangeLog();
        cl.setEditor(user);
        cl.setColumnName("manageStatus");
        cl.setManagementID(management);
        cl.setNewValue("D");
        cl.setOldValue("A");
        cl.setTournamentID(tournament);
        parseDao.saveObject(cl)
            .then(sCl => {
                console.log("saved changelog")
            })
            .catch(error => {
                error.trace = "CHLA.LiTB";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteParcours(editor, parcours, callback) {
        parcoursDao.deleteParcours(parcours)
            .then(result => {
                // back to parcours wiki
                callback(result);
                this.sendInfoMail(editor, parcours)
            })
            .catch(error => {
                error.trace = "CHLA.delParcours";
                notificationActions.parseError(error);
            });
        return{}
    }

    handleDeleteParseObjectForCL(object, changelog, editor, objCallback) {
        this.handleParseObjectFromChangeLog(object, "D", changelog, editor, objCallback);
        return {};
    }

    handleAcceptParseObjectForCL(object, changelog, editor, objCallback) {
        this.handleParseObjectFromChangeLog(object, "A", changelog, editor, objCallback);
        return {};
    }

    handleParseObjectFromChangeLog(object, status, changelog, editor, objCallback) {
        const comment = status === "D" ? "deleted" : "accepted";
        object.set("status", status);
        object.save(null)
            .then(sObject => {
                if (objCallback) {
                    objCallback(sObject)
                } else {
                    notificationActions.success("Changed " + object.className + " to status: " + status);
                }
                if (editor) {
                    this.sendInfoMail(editor, sObject)
                }
                if (changelog == null && object.className === "countType") {
                    // try to load changeLog
                    changeLogDao.findChangeLog(object, ChangeLog.prototype.col.countTypeID)
                        .then(pChangeLog => {
                            this.markChangLogAdminComment(pChangeLog, comment)
                        })
                } else {
                    this.markChangLogAdminComment(changelog, comment)
                }
            })
            .catch(error => {
                notificationActions.warning("Could not change " + object.className + " to status: " + status + " -> " + error.message);
            })
        return {};
    }

    markChangLogAdminComment(changelog, comment) {
        if (changelog) {
            changelog.set("adminComment", comment);
            changelog.save(null, null);
        }
        return {}
    }

    sendInfoMail(editor, object) {
        if (editor) {
            userDao.queryUserMail(editor)
                .then(mail => {
                    if (object.className === "countType") {
                        // send mail about accepted or delete count type
                        this.sentCountTypeDetailEmail(object, editor, mail);
                    } else if (object.className === "parcours") {
                        // send mail about deleted parcours
                        this.sentParcoursDetailEmail(object, editor, mail);
                    }

                })
                .catch(error => {
                    error.trace="CHLA.qUserMail";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    sentParcoursDetailEmail(parcours, user, mail) {
        const sender = "support@3dturnier.com";
        const subject = "Parcours wurde gelöscht - Course has been deleted";
        const receiver = mail;
        let htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<p>Der von Ihnen erstellte Parcours ' + parcours.buildNames() + ' wurde von uns wieder gelöscht. </p>';
        htmlMessage += '<a href="https://3dturnier.freshdesk.com/support/solutions/articles/42000020287"> Mehr zu dem Thema finden sie hier ..</a>';
        htmlMessage += '<hr>';
        htmlMessage += '<p>We have deleted the course ' + parcours.buildNames() + ' created by you.</p>';
        htmlMessage += '<a href="https://3dturnier.freshdesk.com/support/solutions/articles/42000020287"> More information here ..</a>';
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, null, htmlMessage, null);
        return {};
    }

    sentCountTypeDetailEmail(countType, user, mail) {
        const sender = "support@3dturnier.com";
        const receiver = mail;
        let subject = "";
        let htmlMessage = '<html><body>';
        if (countType.isDeleted()) {
            subject = "Wertung wurde gelöscht - Score has been deleted";
            htmlMessage += '<h1>'+subject+'</h1>';
            htmlMessage += '<p>Die von Ihnen erstellte Wertung ' + countType.getName() + ' wurde von uns wieder gelöscht. </p>';
            htmlMessage += '<hr>';
            htmlMessage += '<p>We have deleted the course ' + countType.getName() + ' created by you.</p>';
            htmlMessage += '<p><a href="https://3dturnier.freshdesk.com/a/solutions/articles/42000104490">... more</a></p>';
        } else {
            subject = "Wertung wurde akzeptiert - Score has been accepted";
            htmlMessage += '<h1>'+subject+'</h1>';
            htmlMessage += '<p>Die von Ihnen erstellte Wertung ' + countType.getName() + ' wurde von uns akzeptiert. </p>';
            htmlMessage += '<p>Drücken sie in der 3D SKill Board App auf Synchronisieren um ihre Wertung zu erhalten und verwenden zu können. </p>';
            htmlMessage += '<hr>';
            htmlMessage += '<p>We have accepted the score ' +  countType.getName() + ' created by you.</p>';
            htmlMessage += '<p>Kindly press synchronize in the 3D SKill Board App to download your score and afterwards you can use it. </p>';
        }

        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, null, htmlMessage, null);
        return {};
    }

    queryParseLog(searchParams, callback) {
        changeLogDao.queryParseLogs(searchParams)
            .then(logs => {
                callback(logs)
            })
            .catch(error => {
                error.trace = "CHLA.qPL";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryChangeLog(parcours, searchParams, callback) {
        changeLogDao.queryChangeLog(parcours, searchParams)
            .then(changeLogs => {
                callback(changeLogs);
            })
            .catch(error => {
                error.trace = "CHLA.qCL";
                notificationActions.parseError(error);
            });
        return {};
    }
}
module.exports = alt.createActions(ChangeLogActions);
