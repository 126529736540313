const Parse = require("ParseInit");
const Utility = require("util/Utility");
const UserBowUnionLic = Parse.Object.extend("userBowUnionLic", {
    col: {
        ACL: "ACL",
        bowUnionID: "bowUnionID",
        ageTypeID: "ageTypeID",
        bowTypeID: "bowTypeID",
        licenseNumber: "licenseNumber",
        union: "union",
        status: "status",
        validEnd: "validEnd",
        createdAt: "createdAt",
        objectId: "objectId",
        updatedAt: "updatedAt",
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAgeTypeName() {
        let age = this.getAgeTypeID();
        if (age != null) {
            return age.getName();
        }
        return "---";
    },
    getAgeTypeID() {
        return this.get(this.col.ageTypeID)
    },
    setAgeTypeID(ageType) {
        return this.set(this.col.ageTypeID, ageType)
    },
    getBowUnionCode() {
        let bow = this.getBowUnionID();
        if (bow != null) {
            return bow.getCode();
        }
        return "---";
    },
    getBowUnionID() {
        return this.get(this.col.bowUnionID)
    },
    setBowUnionID(bowUnion) {
        return this.set(this.col.bowUnionID, bowUnion)
    },
    getBowTypeName() {
        let type = this.getBowTypeID();
        if (type != null) {
            return type.getName();
        }
        return "---";
    },
    getBowTypeID() {
        return this.get(this.col.bowTypeID)
    },
    setBowTypeID(bowType) {
        return this.set(this.col.bowTypeID, bowType)
    },
    getLicenseNumber() {
        return this.get(this.col.licenseNumber)
    },
    setLicenseNumber(licenseNumber) {
        return this.set(this.col.licenseNumber, licenseNumber)
    },
    getUnion() {
        return this.get(this.col.union)
    },
    setUnion(union) {
        return this.set(this.col.union, union)
    },
    getValidEndDate() {
        return this.get(this.col.validEnd)
    },
    getReadableValidEndDate() {
        let tDate = this.getValidEndDate();
        if (tDate != null) {
            return Utility.getReadableDate(tDate);
        }
        return null;
    },
    setValidEndDate(validEndDate) {
        return this.set(this.col.validEnd, validEndDate)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = UserBowUnionLic;
