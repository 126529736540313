const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const dao = require("actdao/ImageGalleryDao");
const parseDao = require("actdao/ParseBaseDao");
const msgActions = require("actions/MessageActions");
const {ImageGallery} = require("parse/_Domain");
class ImageGalleryActions {
    queryImages(parcoursID, tournament) {
        dao.queryImages(parcoursID, tournament)
            .then(imageQueryList => {
                this.updateImageGalleryList(imageQueryList);
            })
            .catch(error => {
                error.trace = "IMGA.queryIG";
                notificationActions.parseError(error);
            });
        return {};
    };

    markImage(image) {
        image.incrementReportCounter();
        parseDao.saveObject(image)
            .then(sImage => {
                msgActions.slackNotify("Unangebrachtes Foto gemeldet (" + sImage.getReportCounter()+ " mal): " + sImage.id
                    + "\n<" + sImage.getImageUrl() + ">", "de/parcours/" + sImage.getParcoursOnlineID(), null)
            })
            .catch(error =>{
                error.trace = "IMGA.saveIGmark";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteImage(image) {
        // var image = this.state.imageGallery;
        image.setStatus("D");
        parseDao.saveObject(image)
            .then(sImage => {
               // remove from list
                this.removeImage(sImage);
            })
            .catch(error =>{
                error.trace = "IMGA.delIG";
                notificationActions.parseError(error);
            });
        return {};
    }

    createImageGallery(parseFile, parcours, tournament, user) {
        let imgGal = new ImageGallery();
        imgGal.setCreator(user);
        imgGal.setStatus("A");
        imgGal.setParcoursID(parcours);
        if (tournament) {
            imgGal.setTournamentID(tournament);
        }
        imgGal.setImage(parseFile);
        parseDao.saveObject(imgGal)
            .then(sImgGal => {
                this.addImage(sImgGal);
            })
            .catch(error =>{
                error.trace = "IMGA.creIG";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateImageGalleryList(imageGallList) {
        return imageGallList;
    }

    removeImage(imageGallery) {
        return imageGallery;
    }

    addImage(imageGallery) {
        return imageGallery;
    }
}

module.exports = alt.createActions(ImageGalleryActions);
