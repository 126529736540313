const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const linkUtil = require("linkUtil");

const ParcoursArticlesButton = ({parcoursOwner, block, disabled, bsStyle}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            label="profile.managedParcours.article"
            href={linkUtil.getLink("parcours/" + parcoursOwner.id + "/articles")}/>
    )
};
ParcoursArticlesButton.propTypes = {
    parcoursOwner:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string
};
module.exports = ParcoursArticlesButton;