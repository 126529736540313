const React = require("react"),
    PropTypes = require('prop-types'),
    messages = require("i18n/messages");
const {FormGroup, InputGroup, ControlLabel, FormControl} = require("react-bootstrap");

class DropDownString extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.state = {
            value: this.props.default,
            style: null,
            help: ""
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(event) {
        let value = event.currentTarget.selectedOptions[0].value;
        if (value == "null") {
            value = null;
        }
        this.setState({
            value:value
        });
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(value);
        }
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    render(){
        let addonBefore = null;
        if (this.props.addonBefore != null) {
            addonBefore = <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
        }
        return (
            <FormGroup>
                {
                    this.props.label != null ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }
                <InputGroup>
                    {addonBefore}
                    <FormControl id="selector" componentClass="select" block={this.props.block}
                                 disabled={this.props.disabled}
                                 defaultValue={this.props.default ? this.props.default : ""}
                                 onChange={this.handleChange}>
                        {
                            this.props.placeholder ? this.props.placeholder : null
                        }
                        {
                            this.props.addonOption ? this.props.addonOption : null
                        }
                        {this.props.objectList.map(function(objString) {
                            return <option key={objString} value={objString}>{messages.get(objString)}</option>
                        })}
                    </FormControl>
                </InputGroup>
            </FormGroup>);
    }
}
DropDownString.propTypes = {
    objectList:PropTypes.array.isRequired,
    default:PropTypes.object,
    label: PropTypes.any,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object,
    placeholder:PropTypes.object,
};
module.exports = DropDownString;
