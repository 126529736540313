/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');

const BaseMap = require("components/map/BaseMap");
const ParcoursMapMarker = require("components/map/ParcoursMapMarker");
const BasicMapMarker = require("components/map/BasicMapMarker");
const MapUtil = require("components/map/MapUtil");
const ErrorBoundary = require("components/widgets/ErrorBoundary");

const TournamentMapFrame = ({tournaments, ownPosition}) => {
    let mapUtil = new MapUtil();
    const tournamentList = mapUtil.buildTournaments(tournaments);
    return (
        <div>
            <BaseMap mapOptions={{ center: {lat: 48.249863, lng: 14.635051}, mapID:"TMF_MAP",
                    containerStyle: {width: "100%", height: '500px'},
                    zoom: 8, mapTypeId:"roadmap"
                }} elements={tournamentList}>
                {
                    tournamentList.map(tournament => {
                        return <ErrorBoundary key={tournament.id} identifier={"OwnerId:" + tournament.id}>
                            <ParcoursMapMarker mapElement={tournament} clusterer={null}/>
                        </ErrorBoundary>
                    })
                }
                {
                    ownPosition != null ? <BasicMapMarker coordinate={ownPosition} /> : null
                }
            </BaseMap>
        </div>
    )
};
TournamentMapFrame.propTypes = {
    tournaments:PropTypes.array.isRequired,
    ownPosition:PropTypes.object
};
module.exports = TournamentMapFrame;