const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Well, Alert, Table, Button} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    InActiveButton = require("components/form/InActiveButton"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    TournamentRegSlotModal = require("components/tournamentmodal/TournamentRegSlotModal");

const {TournamentRegSlot} = require("parse/_Domain");

const tournamentActions = require("actions/TournamentManagerActions");

class TournamentMMConfigSlots extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {tournament, tournamentSlots} = this.props;
        return (<Col xs={12}>
            <Panel bsStyle={"default"}>
                <Panel.Heading>
                    <h2><FontAwesome icon="bars"><I18n code="tournament.manager.config.slots"/></FontAwesome></h2>
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <TournamentSlotDetails
                            tournament={tournament}
                            tournamentSlots={tournamentSlots}/>
                    </Row>
                </Panel.Body>
            </Panel>
        </Col>);
    }
}
TournamentMMConfigSlots.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentSlots:PropTypes.array.isRequired
};
class TournamentSlotDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refTRoundCreateModal = React.createRef();

        this.openTSlotModal = this.openTSlotModal.bind(this);
        this.state = {}
    }
    openTSlotModal() {
        this.refTRoundCreateModal.current.open();
    }
    render() {
        const {tournament, tournamentSlots} = this.props;
        return (
            <Col lg={12}>
                <Well><I18n code="tournament.manager.config.slots.desc"/> </Well>
                <Table>
                    <thead>
                    <tr>
                        <th className="col-md-2"><I18n code="tournament.table.column.name"/></th>
                        <th className="col-md-2"><I18n code="tournament.table.column.amount"/></th>
                        <th className="col-md-3"><I18n code="tournament.attendees.panel.subtitle"/></th>
                        <th className="col-md-2"></th>
                    </tr>
                    </thead>
                    <tbody id="trounds-table">
                    {
                        tournamentSlots.map(tournamentSlot => {
                            if (TournamentRegSlot.prototype.status.deleted != tournamentSlot.getStatus()) {
                                return <TournamentSlotPanel key={tournamentSlot.id}
                                                             tournamentSlot={tournamentSlot}
                                                             tournament={tournament} />
                            }
                        })
                    }
                    </tbody>
                </Table>
                <Button bsStyle="success" onClick={this.openTSlotModal}>
                    <FontAwesome icon="plus"/><I18n code="tournament.manager.config.slots.add"/>
                </Button>
                <TournamentRegSlotModal ref={this.refTRoundCreateModal} tournament={tournament} />
            </Col>)
    }
}
TournamentSlotDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentSlots:PropTypes.array.isRequired,
};
class TournamentSlotPanel extends React.Component {
    constructor(props) {
        super(props);
        this.refTSlotEditModal = React.createRef();
        this.onInActiveClicked = this.onInActiveClicked.bind(this);
        this.editTRoundModal = this.editTRoundModal.bind(this);
        this.deleteSlot = this.deleteSlot.bind(this);
        this.state = {

        }
    }
    onInActiveClicked(active) {
        this.props.tournamentSlot.setStatus(active ? "A" : "C")
        tournamentActions.saveTournamentSlot(this.props.tournamentSlot);
    }
    editTRoundModal() {
        this.refTSlotEditModal.current.open();
    }
    deleteSlot(doDelete) {
        if (doDelete) {
            this.props.tournamentSlot.setStatus(TournamentRegSlot.prototype.status.deleted);
            tournamentActions.saveTournamentSlot(this.props.tournamentSlot);
        }
    }
    render() {
        const {tournamentSlot} = this.props;
        return (
            <tr>
                <td><StrikeOutText strikeOut={tournamentSlot.isClosed()}>{tournamentSlot.getName()}</StrikeOutText></td>
                <td>{tournamentSlot.getMaxAmount()}</td>
                <td>{tournamentSlot.getActualAmount()}</td>
                <td>
                    <InActiveButton active={!tournamentSlot.isClosed()} onClick={this.onInActiveClicked}/>
                    <Button onClick={this.editTRoundModal}><FontAwesome icon="pencil-alt"/></Button>
                    <DeleteDecisionModal messageCode="tournament.manager.config.slots.button.delete.desc"
                                         entryName={tournamentSlot.getName()} handleDelete={this.deleteSlot}   />
                </td>
                <TournamentRegSlotModal ref={this.refTSlotEditModal} tournamentSlot={tournamentSlot} />
            </tr>
        )
    }
}
TournamentSlotPanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentSlot: PropTypes.object.isRequired,
};
module.exports = TournamentMMConfigSlots;
