const linkUtil = require("linkUtil");
const {PointOfInterest, Parcours} = require("parse/_Domain");
const Utility = require("util/Utility");
const messages = require("i18n/messages");

function MapUtil() {
    this.buildOwnerDic = function(parcours) {
        if (parcours == null) {
            return {};
        }
        let ownerDic = {};
        parcours.map(element => {
            if (element.className === 'parcours') {
                let pOwner = element.getParcoursOwnerID();
                if (pOwner == null) {
                    console.log("NULL OWNER for Parcours" + element.id);
                    return;
                }
                const coordinates = pOwner.getCoordinates();
                if (coordinates != null && !element.isConstraintClosed()) {
                    let mapElement = ownerDic[pOwner.id];
                    if (mapElement == null) {
                        let pOwnerCat = pOwner.getCategory();
                        mapElement = {
                            id: pOwner.id,
                            name: pOwner.getName(),
                            date: null,
                            mainLink: linkUtil.getLink("parcours/" + pOwner.id + "/home"),
                            coordinate: coordinates,
                            weblink: pOwner.getWeblink(),
                            markerIcon: pOwner.hasActiveManagementID() ? '/img/ico/archery_partner.png' : '/img/ico/archery.png',
                            pOnlineIDs: [],
                            pNames: [],
                            privParcours: 0,
                            pubParcours: 0,
                            stars: 0,
                            isRecurve: Utility.containsArray(pOwnerCat, "cat_type_traditional"),
                            isCompound: Utility.containsArray(pOwnerCat, "cat_type_compound"),
                            isCrossbow: Utility.containsArray(pOwnerCat, "cat_type_crossbow"),
                            isBlowgun: Utility.containsArray(pOwnerCat, "cat_type_blowgun"),
                            hasBowSim: false,
                            hasTarget: false,
                            hasField: false,
                            hasSummer: false,
                            hasWinter: false
                        };


                    }
                    if (element.hasTargetEnabled(true)) {
                        mapElement.pOnlineIDs.push(element.getOnlineID());
                        mapElement.pNames.push(element.getName())
                    }
                    if (element.isConstraintPrivate()) {
                        mapElement.privParcours += 1;
                    } else {
                        mapElement.pubParcours += 1;
                    }
                    if (element.hasConstraint("type_summer")) {
                        mapElement.hasSummer = true;
                    }
                    if (element.hasConstraint("type_winter")) {
                        mapElement.hasWinter = true;
                    }
                    if (element.getRatingAv() > mapElement.stars) {
                        mapElement.stars = element.getRatingAv();
                    }
                    if (element.isType(Parcours.prototype.type.cinema4D)) {
                        mapElement.hasBowSim = true;
                    } else if (element.isType(Parcours.prototype.type.field)) {
                        mapElement.hasField = true;
                    }  else if (element.isType(Parcours.prototype.type.target)) {
                        mapElement.hasTarget = true;
                    }
                    ownerDic[pOwner.id] = mapElement;
                }
            } else {
                let coords = element.getCoordinates();
                if (coords !=  null) {
                    // handle point of interest
                    let mapPOIElement = {
                        id: element.id,
                        name: element.getName(),
                        date: null,
                        mainLink: linkUtil.getExternalLink(element.getWeblink()),
                        coordinate: element.getCoordinates(),
                        weblink: null,
                        markerIcon: null,
                        privParcours: 0,
                        pubParcours: 0,
                        stars: 0,
                        isRecurve: false,
                        isCompound: false,
                        isCrossbow: false,
                        isBlowgun: false,
                        hasBowSim: false,
                        hasTarget: false,
                        hasField: false
                    };
                    if (element.isType(PointOfInterest.prototype.type.shop)) {
                        mapPOIElement.markerIcon = '/img/ico/shop.png';
                    } else if (element.isType(PointOfInterest.prototype.type.hotel)) {
                        mapPOIElement.markerIcon = '/img/ico/hotel.png';
                    } else if (element.isType(PointOfInterest.prototype.type.webshop)) {
                        mapPOIElement.markerIcon = '/img/ico/webshop.png';
                    }
                    ownerDic["POI_" + element.id] = mapPOIElement;
                }
            }
        });
        // check icons
        Object.keys(ownerDic).map(ownerId => {
            let mapElement = ownerDic[ownerId];
            if (mapElement.privParcours > 0 && mapElement.pubParcours === 0) {
                // only private parcours
                mapElement.markerIcon = '/img/ico/archery_private.png'
            } else if(mapElement.pubParcours == 1 && mapElement.hasBowSim) {
                mapElement.markerIcon = '/img/ico/4d_kino.png';
            }
        });
        return ownerDic;
    };
    this.buildTargetBounds = function (targets) {
        if (targets == null) {
            return [];
        }
        let targetList = [];
        targets.map(target => {
            if (target != null && target.getCoordinates() != null) {
                let mapPOIElement = {
                    id: target.id,
                    coordinate: target.getCoordinates()
                };
                targetList.push(mapPOIElement)
            }
        })
        return targetList
    }
    this.buildTournaments = function(tournaments) {
        if (tournaments == null) {
            return [];
        }
        let tournamentList = [];
        tournaments.map(tournament => {
            let parcours = tournament.getParcoursID();
            if (parcours != null && parcours.getOwnerCoordinates() != null) {
                let pOwner = parcours.getParcoursOwnerID();
                let mapPOIElement = {
                    id: tournament.id,
                    name: tournament.getName(),
                    date: tournament.getTournamentDate(),
                    mainLink: linkUtil.getLink("tournament/" + tournament.id),
                    coordinate: parcours.getOwnerCoordinates(),
                    weblink: pOwner.getWeblink(),
                    markerIcon: '/img/ico/archery.png',
                    privParcours: 0,
                    pubParcours: 0,
                    stars: 0,
                    isRecurve: false,
                    isCompound: false,
                    isCrossbow: false,
                    isBlowgun: false,
                    hasBowSim: false,
                    hasTarget: false,
                    hasField: false
                };
                tournamentList.push(mapPOIElement)
            }
        })
        return tournamentList
    }
    this.generateMarker = function(mapElement) {
        let marker = "<h4><a href='" + mapElement.mainLink + "' target='_blank'>" + mapElement.name + "</a></h4>";
        // parcours Icons
        if (mapElement.privParcours > 0 || mapElement.pubParcours > 0) {
            if (mapElement.stars > 0) {
                marker += this.getStarsRating(mapElement.stars);
            }
            // add show parcours icons
            marker += "<p>";
            if (mapElement.privParcours > 0 && mapElement.pubParcours === 0) {
                // only private parcours
                //mapElement.markerIcon = '/img/ico/archery_private.png'
            } else if(mapElement.pubParcours == 1 && mapElement.hasBowSim) {
                //mapElement.markerIcon = '/img/ico/4d_kino.png';
            } else if(mapElement.pubParcours > 1) {
                marker += "<img src='/img/ico/archery.png' alt='" + messages.get("ParcoursInfo_type_P_parcours") + "' height='37' width='32' >"
            }
            if (mapElement.hasTarget) {
                marker += "<img src='/img/ico/target.png' alt='" + messages.get("ParcoursInfo_type_T_target") + "' height='37' width='32' >"
            }
            if (mapElement.hasField) {
                marker += "<img src='/img/ico/field.png' alt='" + messages.get("ParcoursInfo_type_F_field") + "' height='37' width='32' >"
            }
            if (mapElement.hasBowSim) {
                marker += "<img src='/img/ico/4d_kino.png' alt='" + messages.get("ParcoursInfo_type_C_cinema") + "' height='37' width='32' >"
            }
            marker += "</p>";
            marker += "<p>";
            if (mapElement.isRecurve) {
                marker += "<img src='/img/ico/recurve.png' alt='" + messages.get("cat_type_traditional") + "' height='32' width='32' >"
            }
            if (mapElement.isCompound) {
                marker += "<img src='/img/ico/compound.png' alt='" + messages.get("cat_type_compound") + "' height='32' width='32' >"
            }
            if (mapElement.isCrossbow) {
                marker += "<img src='/img/ico/crossbow.png' alt='" + messages.get("cat_type_crossbow") + "' height='32' width='32' >"
            }
            if (mapElement.hasWinter) {
                marker += "<img src='/img/ico/winter.png' alt='" + messages.get("type_winter") + "' height='32' width='32' >"
            }
            if (mapElement.hasSummer) {
                marker += "<img src='/img/ico/summer.png' alt='" + messages.get("type_summer") + "' height='32' width='32' >"
            }
            if (mapElement.isBlowgun) {
                marker += "<img src='/img/ico/blowgun.png' alt='" + messages.get("cat_type_blowgun") + "' height='32' width='32' >"
            }
            marker += "</p>";

            // if (element.hasPaymentEnabled()) {
            //     marker += "<br><br><i class='glyphicon glyphicon-euro'></i>&nbsp;<a href='" + linkUtil.getLink("checkout.html?parcours=" + element.getOnlineID()) + "' target='_blank'>" + messages.get("home.parcours.payBtn") + "</a>";
            // }
            // marker += "<br><br><i class='glyphicon glyphicon-map-marker'></i>&nbsp;<a href='https://www.google.com/maps/dir//" + coordinates._latitude + "," + coordinates._longitude + "/@" + coordinates._latitude + "," + coordinates._longitude + "z?hl=de' target='_blank'>" + messages.get("home.parcours.navBtn") + "</a>";
        }
        if (mapElement.date != null) {
            marker += "<p><i class='glyphicon glyphicon-calendar'></i>&nbsp;" + mapElement.date +  "</p>"
        }
        if (mapElement.weblink) {
            marker += "<br><i class='glyphicon glyphicon-home'></i>&nbsp;<a href='" + this.fixLink(mapElement.weblink) + "' target='_blank'>" + mapElement.weblink + "</a>";
        }
        // addMarkerToMap(map, mapElement.coordinate._latitude, mapElement.coordinate._longitude, marker, mapElement.markerIcon);
        return marker
    };
    this.generateTargetMarker = function(parcoursTarget) {
        let tMarker = "<h4>" + messages.get("tournament.table.column.target") + " " + parcoursTarget.getIndex() + "</h4>";
        tMarker += "<p>" + parcoursTarget.getName() + "</p>";
        if (parcoursTarget.getImageUrl()) {
            tMarker += "<a href='" + parcoursTarget.getImageUrl() + "' target='_blank'><img src='" + parcoursTarget.getImageUrl() + "' height='100'/></a>";
        }
        return tMarker;
    };
    this.fixLink = function(url) {
        if (url) {
            if (url.indexOf("http") == -1) {
                return "http://" + url;
            }
            return url;
        }
        return "";
    };
    this.getStarsRating = function(average) {
        let rating = "";
        const fullStar = Math.floor(average);
        for (let i = 0; i < fullStar; i++) {
            rating += "<i class='glyphicon glyphicon-star'></i>";
        }
        const avMod = average % 1;
        if (avMod > 0.25) {
            if (avMod < 0.76) {
                rating += "<small><i class='glyphicon glyphicon-star'></i></small>";
            } else {
                rating += "<i class='glyphicon glyphicon-star'></i>";
            }
        }
        return rating;
    };
    this.buildTrackObject = function (trackArr, bounds, eventPlayers, eventResults, targetIndex) {
        const evpLength = eventPlayers.length;
        const trackElements=[];
        trackArr.map(track => {
            let jsTrack = JSON.parse(track);
            let targetNumber = Object.keys(jsTrack)[0];
            if (targetIndex === 0 || targetNumber == targetIndex) {
                let coordArray = jsTrack[targetNumber];
                let posMarker = this.getLastCoordinate(coordArray);
                if (posMarker != null) {
                    let targetName = null;
                    let targetPoints = []
                    for (let i = 0; i < evpLength; i++) {
                        let evPlayer = eventPlayers[i];
                        const evpResults = eventResults[evPlayer.id];
                        if (evpResults && evpResults.length >= targetNumber) {
                            const evpResult = evpResults[targetNumber - 1];
                            if (evpResult.getTargetName()) {
                                targetName = evpResult.getTargetName();
                            }
                            targetPoints.push(evPlayer.getPlayerName() + ": " + evpResult.getPoints())
                        }
                    }
                    trackElements.push({
                        jsTrack:jsTrack,
                        targetNumber:targetNumber,
                        targetName:targetName,
                        targetPoints:targetPoints,
                        coordArray:coordArray,
                        posMarker:posMarker})
                    if (bounds != null && posMarker != null) {
                        bounds.push({
                            id: targetNumber,
                            coordinate: {_latitude: posMarker.la, _longitude: posMarker.lo}
                        })
                    }
                }
            }
        });
        return trackElements;
    };
    this.getLastCoordinate = function(coordArray) {
        const length = coordArray.length;
        for (let i = length; i > 0; i--) {
            let coord = coordArray[i-1];
            if (coord) {
                return coord;
            }
        }
        return null;
    };
    this.getCoordPosition = function(mapCoordinateBean) {
        let coordinates = mapCoordinateBean.coord.trim().split(",");
        if (coordinates.length === 2) {
            return {latitude: parseFloat(coordinates[0]), longitude:parseFloat(coordinates[1])}
        }
    }
}
module.exports = MapUtil;
