const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    EventPlayerList = require("components/profile/EventPlayerList");

var userStore = require("stores/UserStore"),
    eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");

class TabEventList extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            eventStore: eventStore.getState(),
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        eventStore.listen(this.handleEventChange);
        notificationActions.reset();
        eventActions.reset();
        eventActions.queryEventList(this.state.userStore.user, this.props.year);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        eventStore.unlisten(this.handleEventChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        if (this.state.eventStore.loading === true || this.state.eventStore.eventPlayers == null) {
            return (<Row><Col xs={12}><Loading/></Col></Row>)
        } else if (this.state.eventStore.loading === false && this.state.eventStore.eventPlayers.length <= 0){
            return (<Row><Col xs={12}><Alert bsStyle="warning"><I18n code="profile.events.empty"/></Alert></Col></Row>);
        } else if (this.state.eventStore.loading === false && this.state.eventStore.eventPlayers.length > 0) {
            return (<EventPlayerList eventPlayers={this.state.eventStore.eventPlayers} adminPage={false} user={this.state.userStore.user}/>);
        } else {
            console.log("WTF - never should be here");
            return null;
        }
    }
}
TabEventList.propTypes = {
    year: PropTypes.number.isRequired
};
module.exports = TabEventList;
