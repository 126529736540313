/**
 * Created by Daniel on 02.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Pager} = require("react-bootstrap");
const messages = require("i18n/messages");

class YearPager extends React.Component {
    constructor(props) {
        super(props);
        this.nextYear = this.nextYear.bind(this);
        this.isActualYear = this.isActualYear.bind(this);
        this.previousYear = this.previousYear.bind(this);
        this.updateDate = this.updateDate.bind(this);

        let year = parseInt(this.props.year);
        if (!(year > 0)) {
            const today = new Date();
            year = today.getFullYear();
        }
        this.state = {
            year: year
        }
    }
    nextYear() {
        if (this.props.href == null) {
            const today = new Date();
            if (!this.props.allowFuture && today.getFullYear() == this.state.year) {
                // can't go further than today :-)
                return;
            } else {
                this.updateDate(this.state.year + 1);
            }
        }
    }
    isActualYear() {
        if (this.props.allowFuture) {
            return false;
        }
        const today = new Date();
        return today.getFullYear() == this.state.year;
    }
    previousYear() {
        if (this.props.href == null) {
            this.updateDate(this.state.year - 1);
        }

    }
    updateDate(year) {
        this.setState({
            year: year
        });
        if (this.props.handleChangedDate) {
            this.props.handleChangedDate(year);
        }

    }
    render() {
        let headerText = "";
        if (this.props.headerText) {
            headerText = this.props.headerText + " ";
        }
        let nextHref = null;
        let prevHref = null;
        if (this.props.href != null) {
            nextHref = linkUtil.getLink(this.props.href + (this.state.year + 1));
            prevHref = linkUtil.getLink(this.props.href + (this.state.year - 1));
        }

        return (
            <div>
                <h1 className="text-center">{headerText + this.state.year}</h1>
            <Pager>
                <Pager.Item previous
                            href={prevHref}
                            onClick={this.previousYear} >&larr;
                    {messages.get("yearPager.previous")}
                    </Pager.Item>
                <Pager.Item next disabled={this.isActualYear()}
                            href={nextHref}
                            onClick={this.nextYear} >
                    {messages.get("yearPager.next")} &rarr;
                </Pager.Item>
            </Pager>
            </div>)
    }
}
YearPager.propTypes = {
    headerText:PropTypes.string,
    handleChangedDate:PropTypes.func,
    allowFuture: PropTypes.bool
};
module.exports = YearPager;