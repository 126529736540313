const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const analyticsActions = require("actions/AnalyticsActions");


const parseDao = require("actdao/ParseBaseDao");
const parcoursDao = require("actdao/ParcoursDao");
const poiDao = require("actdao/PointInterestDao");
const eventDao = require("actdao/EventDao");

const linkUtil = require("linkUtil");
const messages = require("i18n/messages");

const {ParcoursVisit} = require("parse/_Domain");
const Utility = require("util/Utility");

const EventPlayerRankingList = require("util/EventPlayerRankingList");

class ParcoursActions {

    startUp(searchParams) {
        poiDao.search(searchParams)
            .then(poiList => {
                this.updatePOIList(poiList);
            })
            .catch(error => {
                error.trace = "PARA.startup.POI";
                notificationActions.parseError(error);
            });
        parcoursDao.queryParcourList(searchParams.pageStart, 0, searchParams)
            .then(aparcours => {
                if (aparcours.length === searchParams.pageStart) {
                    // there will be more parcours to search for
                    parcoursDao.queryParcourList(searchParams.pageMax, searchParams.pageStart, searchParams)
                        .then(bparcours => {
                            this.addParcoursList(bparcours);
                        })
                }
                this.updateParcoursList(aparcours);
            })
            .catch(error => {
                error.trace = "PARA.startup.Parcours";
                notificationActions.parseError(error);
            });
        return {};
    }



    search(searchParams) {
        this.onSearch(searchParams);
        return {};
    }

    onSearch(searchParams) {
        poiDao.search(searchParams)
            .then(poiList => {
                parcoursDao
                    .search(searchParams)
                    .then(parcours => {
                        this.updateParcoursList(parcours);
                    })
                    .catch(error => {
                        error.trace = "PARA.search.Parcours";
                        notificationActions.parseError(error);
                    });
                this.updatePOIList(poiList);
            })
            .catch(error => {
                error.trace = "PARA.search.POI";
                notificationActions.parseError(error);
            });
        return {};
    }

    startRanking(parcoursOnlineID, month, year) {
        this.resetParcoursRanking();
        parcoursDao.queryParcours(parcoursOnlineID)
            .then(parcours => {
                if (parcours != null){
                    this.queryRankingOfParcours(parcours, month, year);
                    this.updateParcours(parcours);
                } else {
                    this.updateParcours(null);
                }
            })
            .catch(error => {
                error.trace = "PARA.start.query.Parcours";
                notificationActions.parseError(error);
            });
        return {}
    }

    countEvents(parcoursOnlineID, callback) {
        eventDao.queryEventAmount(parcoursOnlineID)
            .then(amount => {
                if (callback) {
                    callback(amount)
                }
            })
            .catch(error => {
                error.trace = "PARA.countEvents";
                notificationActions.parseError(error);
            });
        return {}
    }

    saveParcours(parcours) {
        parseDao.saveObject(parcours)
            .then(sParcours => {
                this.updateParcours(sParcours)
                notificationActions.success("modal.login.save.success");
            })
            .catch(error => {
                error.trace = "PARA.saveParcours";
                notificationActions.parseError(error);
            });
        return {}
    }

    queryParcours(onlineID, ratingUser, editUser, allowAllUser) {
        this.onStartParcoursLoading();
        parcoursDao.queryParcours(onlineID)
            .then(parcours => {
                if (parcours != null && ratingUser != null) {
                    // also query his own parcours rating
                    parcoursDao.queryParcoursRatings(parcours, ratingUser)
                        .then(rating => {
                            this.updateOwnParcoursRating(rating);
                        })
                        .catch(error => {
                            notificationActions.parseError(error);
                        });
                    this.updateParcours(parcours);
                } else if (parcours != null && editUser != null) {
                    // edit parcours targets - check for editor
                    this.checkParcoursEditor(parcours, editUser, allowAllUser);
                } else if (parcours != null){
                    this.updateParcours(parcours);
                } else {
                    this.updateParcours(null);
                }
            })
            .catch(error => {
                error.trace = "PARA.query.Parcours";
                notificationActions.parseError(error);
            });
        return {}
    }

    checkParcoursEditor(parcours, user, allowAllUser) {
        if (parcours.getParcoursOwnerID().hasActiveManagementID) {
            // parcours is managed - check if user is editor
            parcoursDao.isUserParcoursEditor(parcours)
                .then(bResult => {
                    if (!bResult) {
                        this.setInvalidUser.defer();
                    } else {
                        this.updateParcours(parcours);
                    }
                })
                .catch(error => {
                    error.trace = "PARA.cParcoursEditor";
                    notificationActions.parseError(error);
                    this.setInvalidUser.defer();
                });
        } else if (allowAllUser) {
            // allow user to edit if parcours is not managed
            this.updateParcours(parcours);
        } else {
            this.setInvalidUser();
        }
        return {};
    }

    queryParcoursForGroupPlace(mainGroupPlace, regionGroupPlace, callback) {
        parcoursDao.queryParcoursForGroupPlace(mainGroupPlace, regionGroupPlace)
            .then(parcours => {
                callback(parcours)
            })
            .catch(error => {
                error.trace = "PARA.qParcoursGRP";
                notificationActions.parseError(error);
            });
        return {}
    }

    queryParcoursCheckout(onlineID) {
        this.onStartParcoursLoading();
        parcoursDao.queryParcoursCheckout(onlineID)
            .then(parcours => {
                this.updateParcours(parcours);
            })
            .catch(error => {
                error.trace = "PARA.query.Checkout";
                notificationActions.parseError(error);
            });
        return {}
    }

    saveUserRating(userRating) {
        userRating.save()
            .then(sRating => {
                this.updateOwnParcoursRating(sRating)
            })
            .catch(error => {
                error.trace = "PARA.save.Rating";
                notificationActions.parseError(error);
            });
        return {};
    }

    onStartParcoursLoading() {
        return {}
    }

    getParcoursOwner(parcoursOwnerId) {
        parcoursDao
            .getParcoursOwner(parcoursOwnerId)
            .then(
                parcoursOwner => this.getParcoursOwnerSuccess(parcoursOwner),
                error => notificationActions.parseError(error)
            );
        return parcoursOwnerId;
    }

    queryParcoursRatings(parcours){
        parcoursDao.queryParcoursRatings(parcours)
            .then(ratings => {
                this.updateParcoursRating(ratings);
            })
            .catch(error => {
                error.trace = "PARA.query.Ratings";
                notificationActions.parseError(error)
            });
        return {}
    }

    queryParcoursOwnerTeaser(parcoursOwnerCallback) {
        parcoursDao.queryParcoursOwnerTeaser()
            .then(parcoursOwners => {
                parcoursOwnerCallback(parcoursOwners)
            })
            .catch(error => {
                error.trace = "PARA.qParOwnTeaser";
                notificationActions.parseError(error)
            });
        return {}
    }

    queryRankingOfParcours(parcours, month, year) {
         eventDao.queryRankingOfParcours(parcours.getOnlineID(), month, year)
            .then(fEventPlayers => {
                const evRankingList = new EventPlayerRankingList(parcours.getTargetAmounts());
                fEventPlayers.map(evPlayer => {
                    // split in lists for each count type
                    evRankingList.pushSorted(evPlayer)
                });
                this.updateParcoursRanking(evRankingList.getEventPlayerDic());
            })
            .catch(error => {
                error.trace = "EVEA.ranking.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    createParcours(name, zip, ownerID, type, regionGroupPlace, mainGroupPlace) {
        parcoursDao.createParcoursCC(name, zip, ownerID, type, regionGroupPlace, mainGroupPlace)
            .then(parcours => {
                window.location.href = linkUtil.getLink("parcours/" + parcours.getOnlineID());
            })
            .catch(error => {
                error.trace = "PARA.create.Parcours";
                notificationActions.parseError(error);
            });
    }

    findSimilarParcours(zip, callbackOwners) {
        const searchParams = {};
        searchParams.searchText = zip;
        searchParams.pageMax = 100;
        parcoursDao.searchSimilar(searchParams)
            .then(similarParcours => {
                // map to owners
                const ownerDic = {}
                similarParcours.map(parcours => {
                    const ownerID = parcours.getParcoursOwnerID()
                    ownerDic[ownerID.id] = ownerID
                })
                callbackOwners(ownerDic);
            })
            .catch(error => {
                error.trace = "PARA.similar.Parours";
                notificationActions.parseError(error)
            });
        return {};
    }

    checkOnlinePayment(parcours) {
        if (parcours.hasParcoursManager()) {
            // ask cloud code
            const onlineID = parcours.getOnlineID();
            analyticsActions.trackParcoursPayment(onlineID);
            parcoursDao.checkParcoursFeatures(onlineID)
                .then(features => {
                    if (features && features.pay == "A") {
                        if (Utility.isIOS()) {
                            window.location = linkUtil.getLink("parcours/" + onlineID + "/checkout");
                        } else {
                            window.open(linkUtil.getLink("parcours/" + onlineID + "/checkout"), '_blank');
                        }
                    } else {
                        notificationActions.warning(messages.get("profile.managedParcours.payment.info.disabled"));
                    }
                })
                .catch(error => {
                    error.trace = "PARA.hasOPay";
                    notificationActions.warning(messages.get("profile.managedParcours.payment.info.disabled"))
                });
        } else {
            notificationActions.warning(messages.get("profile.managedParcours.payment.info.noPartner"))
        }
        return {};
    }

    deleteParcours(parcours) {
        parcoursDao.deleteParcours(parcours)
            .then(result => {
                // back to parcours wiki
                window.location = linkUtil.getLink("parcours");
            })
            .catch(error => {
                if (error) {
                    error.trace = "PARA.delParcours";
                    console.log("error " + error.description);
                }
                window.location = linkUtil.getLink("parcours");
            });
        return {};
    }

    registerParcoursVisit(visitObject, callback, callbackError) {
        parcoursDao.registerParcoursVisit(visitObject)
            .then(parcoursVisit => {
                // return object
                callback(parcoursVisit)
            })
            .catch(error => {
                error.trace = "PARA.regPaVi";
                callbackError(error)
            });
        return {};
    }

    loadParcoursVisit(visitID, callback) {
        parcoursDao.loadParcoursVisit(visitID)
            .then(parcoursVisit => {
                // return object
                callback(parcoursVisit)
            })
            .catch(error => {
                error.trace = "PARA.loadPaVi";
                notificationActions.parseError(error)
            });
        return {};
    }

    loadLiveParcoursVisit(parcours, callback) {
        parcoursDao.loadLiveParcoursVisit(parcours)
            .then(parcoursVisits => {
                // return visits
                callback(parcoursVisits)
            })
            .catch(error => {
                error.trace = "PARA.loadPaVisits";
                notificationActions.parseError(error)
            });
        return {};
    }


    queryForVisitors(searchText, parcoursOwner, visitsCallback) {
        const searchParams = {
            searchText: searchText,
            ownerID: parcoursOwner.id
        };
        parcoursDao.queryParcoursVisit(searchParams)
            .then(parcoursVisits => {
                let searchRes = [];
                let foundMails = [];
                parcoursVisits.map(visit => {
                    let email = visit.getMail().trim().toLocaleLowerCase();
                    if (email != null && email.length > 0) {
                        if (foundMails.indexOf(email) == -1) {
                            // new user
                            foundMails.push(email);
                            searchRes.push(visit);
                        }
                    }
                });
                visitsCallback(searchRes);
            })
            .catch(error => {
                error.trace = "TRMA.searchMMTUsers";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateParcoursVisitStatus(parcoursVisit, changeObject, callback) {
        parcoursDao.updateParcoursVisit(parcoursVisit.id, changeObject)
            .then(sParcoursVisit => {
                // return object
                callback(parcoursVisit)
            })
            .catch(error => {
                error.trace = "PARA.savePaViStat";
                notificationActions.parseError(error)
            });
        return {};
    }

    getParcoursOwnerSuccess(parcoursOwner) {
        return parcoursOwner;
    }

    updateParcoursRating(ratings) {
        return ratings;
    }
    updateOwnParcoursRating(ownRating) {
        return ownRating;
    }

    updateParcours(parcours) {
        return parcours
    }

    updateParcoursList(parcoursList) {
        return parcoursList
    }

    updatePOIList(poiList) {
        return poiList
    }

    addParcoursList(parcoursList) {
        return parcoursList
    }

    updateParcoursCC(object, dirtyKey, newValue) {
        parcoursDao.parseEditParcoursCC(object, dirtyKey, newValue)
            .then(savedObject => {
                this.updateSavedObject(savedObject);
            })
            .catch(error => {
                this.setParseError(error);
            });
    }

    updateListRenderAmount(amount) {
        return amount;
    }

    searchParcoursList(searchParams) {
        return searchParams;
    }

    updateParcoursRanking(eventPlayerRankingDic) {
        return eventPlayerRankingDic;
    }

    resetParcoursRanking() {
        return {}
    }

    resetParcours() {
        return {};
    }

    updateSavedObject(savedObject) {
        return savedObject;
    }
    setParseError(error) {
        return error;
    };

    setInvalidUser() {
        return {};
    }
}

module.exports = alt.createActions(ParcoursActions);
