const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TransactionDetailTable= require("components/payment/TransactionDetailTable"),
    messages = require("i18n/messages");

const userStore = require("stores/UserStore"),
    transactionStore = require("stores/TransactionStore"),
    transactionActions = require("actions/TransactionActions");

class SettlementDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            transactionStore: transactionStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        transactionStore.listen(this.handleTransactionChange);
        transactionActions.loadSettlement(this.props.id);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        transactionStore.unlisten(this.handleTransactionChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state
        });
    }
    handleTransactionChange(state) {
        this.setState({
            transactionStore: state,
        });
    }
    render() {
        return (
                <Grid>
                    <Row>
                        <Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
                            <h3>{messages.get("parcours.transactions.settlement.mail.monthsettle") + ": " + this.props.tstat}</h3>
                        </Col>
                    </Row>
                    {this.state.transactionStore.loadingS ? <Loading/> : ""}
                    {!this.state.transactionStore.loadingS && this.state.transactionStore.transactionSettlement == null ?
                        <Alert bsStyle="warning"><I18n code="transaction.detail.notFound"/></Alert> : ""
                    }
                    {!this.state.transactionStore.loadingS && this.state.transactionStore.transactionSettlement != null ?
                        <SettlementDetail settlement={this.state.transactionStore.transactionSettlement}/>: ""
                    }
                </Grid>
        )
    }
}
SettlementDetailPage.propTypes = {
    id: PropTypes.string.isRequired,
    tstat: PropTypes.string
};
const SettlementDetail = ({settlement}) => {
    function onClick() {
        transactionActions.generateInvoice(settlement.id);
    }
    function getInvoice() {
        if (settlement.getInvoiceNumber()) {
            if (settlement.getInvoice()) {
                return (<a target="_BLANK" href={settlement.getInvoice()._url}>
                    <FontAwesome icon="download"/>
                    {messages.get("transaction.detail.link.invoice")}: {settlement.getInvoiceNumber()}
                </a>);
            } else {
                return <Button onClick={onClick}>
                    <FontAwesome icon="plus-circle"/>{settlement.getInvoiceNumber()}
                </Button>;
            }
        }
        return "-";
    }
    const positions = settlement.getSettlementPositions();
    return (
        <Row>
            <Col xs={12} md={8} mdOffset={2} style={{"textAlign": "center"}}>
                <p>{getInvoice()}</p>
                <hr/>
            </Col>
            {
                positions != null ?
                        <TransactionDetailTable transaction={settlement} positions={positions}/>
                    : null
            }
        </Row>)
};
SettlementDetail.propTypes = {
    settlement:PropTypes.object.isRequired
};

module.exports = SettlementDetailPage;
