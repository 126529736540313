const alt = require("AltInit");
const tournamentActions = require("actions/TournamentManagerActions");

const TournamentRound = require("parse/TournamentRound");
const PLUtil = require("util/ParseListUtility");

class TournamentManagerStore {
    constructor() {
        this.displayName = "TournamentManagerStore";
        this.tournamentDetail = null;
        this.tournamentRoundMap = {
            qualify:[], finale:[], group:[], extra:[]
        }
        this.tournamentRegSlots = [];
        this.runningTournamentRounds = [];
        this.childTournaments = [];
        this.tChangeLogs = null;
        this.loading = true;
        this.invalidUser = false;
        this.bindListeners({
            updateTournamentDetail: tournamentActions.updateTournamentDetail,
            updateTournamentRounds : tournamentActions.updateTournamentRounds,
            updateTournamentRound: tournamentActions.updateTournamentRound,
            updateTournamentSlots: tournamentActions.updateTournamentSlots,
            updateTournamentSlot: tournamentActions.updateTournamentSlot,
            updateTournamentChangeLogs: tournamentActions.updateTournamentChangeLogs,
            updateCupChildTournaments: tournamentActions.updateCupChildTournaments,
            setInvalidUser: tournamentActions.setInvalidUser,
            showProgress: tournamentActions.showProgress
        });
    }
    showProgress() {
        this.loading = true;
    }

    updateTournamentSlots(tSlots) {
        this.tournamentRegSlots = tSlots;
    };

    updateTournamentSlot(tSlot) {
        this.updateTournamentSlots(PLUtil.updateOrPush(this.tournamentRegSlots, tSlot));
    };

    updateTournamentRound(tournamentRound) {
        if (tournamentRound.isTypeOf(TournamentRound.prototype.typeGroups.group_finale)) {
            this.updateRound(this.tournamentRoundMap.finale, tournamentRound)
        } else if (tournamentRound.isTypeOf(TournamentRound.prototype.typeGroups.group_groups)) {
            this.updateRound(this.tournamentRoundMap.group, tournamentRound)
        } else if (tournamentRound.isTypeOf(TournamentRound.prototype.typeGroups.group_extra)) {
            this.updateRound(this.tournamentRoundMap.extra, tournamentRound)
        } else {
            this.updateRound(this.tournamentRoundMap.qualify, tournamentRound)
        }
        if (tournamentRound.isRunning()) {
            PLUtil.updateOrPush(this.runningTournamentRounds, tournamentRound)
        }
    }

    updateRound(tRounds, tournamentRound) {
        var updated = false;
        for (var i = 0; i < tRounds.length; i++) {
            if (tRounds[i].id == tournamentRound.id) {
                tRounds[i] = tournamentRound;
                updated = true;
            }
        }
        if (!updated) {
            tRounds.push(tournamentRound);
        }
    }

    updateTournamentDetail(tournament) {
        this.tournamentDetail = tournament;
        this.loading = false;
    }

    updateTournamentRounds(tRounds) {
        let pRunningTournamentRounds = []
        tRounds.map(tRound => {
            if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_simple)) {
                this.updateRound(this.tournamentRoundMap.qualify, tRound)
            } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_groups)) {
                this.updateRound(this.tournamentRoundMap.group, tRound)
            } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_finale)) {
                this.updateRound(this.tournamentRoundMap.finale, tRound)
            } else if (tRound.isTypeOf(TournamentRound.prototype.typeGroups.group_extra)) {
                this.updateRound(this.tournamentRoundMap.extra, tRound)
            }
            if (tRound.isRunning()) {
                pRunningTournamentRounds.push(tRound)
            }
        });
        this.runningTournamentRounds = pRunningTournamentRounds;
    }

    updateCupChildTournaments(pChildTournaments) {
        this.childTournaments = pChildTournaments;
    };

    updateTournamentChangeLogs(changeLogs) {
        this.tChangeLogs=changeLogs;
    }

    setInvalidUser() {
        this.tournamentDetail = null;
        this.tournamentRoundMap.qualify = [];
        this.tournamentRoundMap.finale = [];
        this.tournamentRoundMap.group = [];
        this.tournamentRoundMap.extra = [];
        this.runningTournamentRounds = [];
        this.childTournaments = [];
        this.loading = false;
        this.invalidUser = true;
    }
}
module.exports = alt.createStore(TournamentManagerStore);
