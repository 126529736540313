const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, ControlLabel, InputGroup, FormControl} = require("react-bootstrap");
const timeoutLockFactory = require("util/TimeoutLockFactory");
const FontAwesome = require("components/widgets/FontAwesome");

class DelayedTextField extends React.Component {
    constructor(props) {
        super(props);
        this.timeoutLock = timeoutLockFactory.createLock(1000);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }
    componentDidMount() {

    }
    onChange(event) {
        let text = event.target.value;
        if (text != null) {
            text = text.trim();
        }
        this.timeoutLock.executeDelayed(
            _=>this.props.onChange(text)
        );
    }
    onKeyPress(event) {
        let text = event.target.value;
        if (text != null) {
            text = text.trim();
        }
        if (event.key === 'Enter') {
            this.timeoutLock.stop();
            this.props.onChange(text)
        }
    }
    render() {
        const {disabled, defaultValue, placeholder, custIcon, label} = this.props;
        let icon = <InputGroup.Addon><FontAwesome icon="search"/></InputGroup.Addon>;
        if (custIcon != null) {
            if ("NONE" == custIcon) {
                icon = null;
            } else {
                icon = <InputGroup.Addon><FontAwesome icon={custIcon}/></InputGroup.Addon>;
            }
        }
        return (<FormGroup>
            {
                label != null ? <ControlLabel>{label}</ControlLabel> : null
            }
            <InputGroup>
                {icon}
                <FormControl type="text"
                             disabled={disabled}
                             defaultValue={defaultValue}
                             placeholder={placeholder || ""}
                             onKeyPress={this.onKeyPress}
                             onChange={this.onChange}/>
            </InputGroup>
        </FormGroup>)
    }
}
DelayedTextField.propTypes = {
    onChange:PropTypes.func.isRequired,
    placeholder:PropTypes.string,
    disabled:PropTypes.bool,
    label: PropTypes.string,
    custIcon: PropTypes.string,
    defaultValue:PropTypes.any
};
module.exports = DelayedTextField;
