const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, ControlLabel, FormControl} = require("react-bootstrap");
const messages = require("i18n/messages");

class DropDownParseObject extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.state = {
            value: this.props.default,
            style: null,
            help: ""
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(value) {
        const objID = value.currentTarget.selectedOptions[0].value;
        const length = this.props.objectList.length;
        let selObject = null;
        for (let i = 0; i < length; i++) {
            if (objID == this.props.objectList[i].id) {
                selObject = this.props.objectList[i];
                break;
            }
        }
        this.setState({
            value:selObject
        });
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(selObject);

        }
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    render(){
        const {objectList, label, placeholder, disabled, addonBefore, buttonAfter} = this.props
        return (<FormGroup>
            {
                label != null ? <ControlLabel>{label}{"(" + objectList.length + ")"}</ControlLabel> : ""
            }
            <InputGroup>
                {
                    addonBefore != null ? <InputGroup.Addon>{addonBefore}</InputGroup.Addon> : null
                }
                <FormControl id="selector" componentClass="select" block={this.props.block}
                 disabled={disabled}
                 defaultValue={this.props.default ? this.props.default.id : ""}
                 onChange={this.handleChange}>
                    {
                        placeholder ? placeholder : null
                    }
                    {objectList.map(function(parseObject) {
                        return <option key={parseObject.id} value={parseObject.id}>{messages.get(parseObject.getSelectString())}</option>
                    })}
                 </FormControl>
                 {
                     buttonAfter != null ? <InputGroup.Button>{buttonAfter}</InputGroup.Button> : null
                 }
            </InputGroup>
        </FormGroup>);
    }
}
DropDownParseObject.propTypes = {
    objectList:PropTypes.array.isRequired,
    default:PropTypes.object,
    label: PropTypes.any,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object,
    placeholder:PropTypes.object,
};
module.exports = DropDownParseObject;
