var Parse = require("ParseInit");

var Union = Parse.Object.extend("union", {
    col: {
        ACL: "ACL",
        addressID: "addressID",
        createdAt: "createdAt",
        managementID: "managementID",
        name: "name",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt",
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAddressID: function () {
        return this.get(this.col.addressID)
    },
    setAddressID: function (addressID) {
        return this.set(this.col.addressID, addressID)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getManagementID: function () {
        return this.get(this.col.managementID)
    },
    setManagementID: function (managementID) {
        return this.set(this.col.managementID, managementID)
    },
    getName: function () {
        return this.get(this.col.name)
    },
    setName: function (name) {
        return this.set(this.col.name, name)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus: function () {
        return this.get(this.col.status)
    },
    setStatus: function (status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
});

module.exports = Union;
