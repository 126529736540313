function PointInterestYearStat() {
    this.months = [0,1,2,3,4,5,6,7,8,9,10,11];
    this.monthList = new Array(12);

    this.addVisit = function (eventStat) {
        const type = eventStat.getDeviceType();
        const date = eventStat.getCreatedAt();
        let month = date.getMonth();
        // add to list
        let deviceDict = this.monthList[month];
        if (deviceDict == null) {
            deviceDict = {ANDR: 0, iOS: 0, WEB:0}
        }
        deviceDict[type]++;
        this.monthList[month] = deviceDict;
    }
    this.getMonths = function() {
        return this.months;
    };
    this.getMonthStats = function(month) {
        return this.monthList[month];
    };
    this.getYearStat= function () {
        let result = {ANDR: 0, iOS: 0, WEB:0}
        this.months.forEach(month => {
            let deviceDict = this.monthList[month];
            if (deviceDict != null) {
                result.ANDR += deviceDict.ANDR;
                result.iOS += deviceDict.iOS;
                result.WEB += deviceDict.WEB;
            }
        })
        let resString = "";
        Object.keys(result).map(key =>{
            resString += key + ": " + result[key] + "\n";
        });
        return resString;
    }
}
module.exports = PointInterestYearStat;