const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");
const TournamentRegStatus = require("components/tournament/TournamentRegStatus");
function TournamentStatus ({tournament}) {
    const status = tournament.status;
    let code = "code.unknown";
    let style = "default";
    switch (tournament.getStatus()) {
        case status.published:
        {
            return <TournamentRegStatus tournament={tournament} />;
        }
        case status.running:
        {
            code = "tournament.status.running";
            style = "success";
            break;
        }
        case status.canceled:
        {
            code = "tournament.status.canceled";
            style = "danger";
            break;
        }
        case status.closed:
        {
            code = "tournament.status.closed";
            style = "primary";
            break;
        }
    }
    return (<Label bsStyle={style}><I18n code={code}/></Label>)
}
TournamentStatus.propTypes = {
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentStatus;
