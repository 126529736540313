const React = require("react"),
    PropTypes = require('prop-types');
const {Table, Col, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n");

function buildPSPObject(pspObject) {
    const message = analyseDict(pspObject, "");
    return "<dl>" + message + "</dl>";
}
function analyseDict(pspObject, parentKey) {
    let msg = "";
    Object.keys(pspObject).map(function(key) {
        const value = pspObject[key];
        if (value == null || typeof value == "string" || typeof value == "boolean" || typeof value == "number") {
            msg += "<dt>"+ parentKey + key + ":</dt>";
            msg += "<dd>"+ value + "</dd>";
        } else {
            // add further elements
            msg += analyseDict(value, key +".");
        }
    });
    return msg;
}

class CCLogTable extends React.Component {
    constructor(props) {
        super(props);
        this.handleBodyChange = this.handleBodyChange.bind(this);
        this.state = {
            selBody: null
        }
    }
    handleBodyChange(body) {
        this.setState({selBody: body});
    }
    render() {
        const {ccLogs, header, showTrace, showError, bodyKey} = this.props;
        const {selBody} = this.state;
        return <CCTable header={header} showTrace={showTrace} showError={showError} ccLogs={ccLogs} logBody={selBody} bodyKey={bodyKey} setLogBody={this.handleBodyChange} />
    }
}
CCLogTable.propTypes = {
    ccLogs:PropTypes.array.isRequired,
    header: PropTypes.string,
    showTrace:PropTypes.bool,
    showError:PropTypes.bool,
    bodyKey: PropTypes.string
};
const CCTable = ({ccLogs, logBody, setLogBody, header, showTrace, showError, bodyKey}) => {
    return (<Col xs={12}>
        {
            header != null ? <h3>{header}</h3> : null
        }
        <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.text"/></th>
                {
                    showTrace ? <th>trace</th> : null
                }
                {
                    showError ? <th>error</th> : null
                }
                <th> </th>
            </tr>
            </thead>
            <tbody>
                {ccLogs.map(ccLog => {
                    if (logBody != null && logBody.id === ccLog.id) {
                        return (<React.Fragment>
                            <CCRow key={ccLog.id} position={ccLog} setLogBody={setLogBody} showTrace={showTrace} showError={showError} bodyKey={bodyKey}/>
                            <tr>
                                <td colSpan="4">
                                    <p><Button className="pull-right" onClick={() => setLogBody(null)}>x</Button></p>
                                    <div style={{maxWidth:"550px" }} dangerouslySetInnerHTML={{__html:buildPSPObject(logBody.getBody())}}></div>
                                </td>
                            </tr>

                        </React.Fragment>)
                    } else {
                        return (<CCRow key={ccLog.id} position={ccLog} setLogBody={setLogBody} showTrace={showTrace} showError={showError} bodyKey={bodyKey}/>);
                    }
                })}
            </tbody>
        </Table>

    </Col>)
};
CCTable.propTypes = {
    ccLogs:PropTypes.array.isRequired,
    logBody: PropTypes.object,
    setLogBody:PropTypes.func.isRequired,
    header: PropTypes.string,
    showTrace:PropTypes.bool,
    showError:PropTypes.bool,
    bodyKey: PropTypes.string
};
const CCRow = ({position, setLogBody, showTrace, showError, bodyKey})=> {
    function clicked() {
        setLogBody(position)
    }
    return (
        <tr>
            <td>{position.getLogDate()}</td>
            <td>{position.getTableText(bodyKey)}</td>
            {
                showTrace ? <td>{position.getTrace()}</td> : null
            }
            {
                showError ? <td>{position.getErrorText()}</td> : null
            }
            <td>{position.getBody() ? <Button onClick={clicked}><I18n code="tournament.table.column.details"/></Button> : "-"}</td>
        </tr>
    )
};
CCRow.propTypes = {
    position:PropTypes.object.isRequired,
    setLogBody:PropTypes.func.isRequired,
    showTrace:PropTypes.bool,
    showError:PropTypes.bool,
    bodyKey: PropTypes.string
};
module.exports = CCLogTable;
