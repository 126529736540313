const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, Grid, DropdownButton, MenuItem, ButtonGroup, FormGroup, FormControl, Well} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    Title = require("components/widgets/Title"),
    FontAwesome = require("components/widgets/FontAwesome"),
    CheckboxInput = require("components/form/CheckboxInput"),
    SearchTable = require("components/form/SearchTable");

const messages = require("i18n/messages");
const Utility = require("util/Utility");

const TranslationCl = require("parse/TranslationCl");

const languageStore = require("stores/LanguageStore"),
    userStore = require("stores/UserStore"),
    languageActions = require("actions/LanguageActions"),
    tableStore = require("stores/TableStore"),
    tableAction = require("actions/TableActions");

class TranslatorPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.state = {
            languageStore: languageStore.getState(),
            userStore: userStore.getState(),
        }
    }
    componentDidMount() {
        document.title = messages.get("translator.title");
        languageStore.listen(this.handleLanguageChange);
        userStore.listen(this.handleUserChange);
        languageActions.loadTranslations(this.state.userStore.user)
    }
    componentWillUnmount() {
        languageStore.unlisten(this.handleLanguageChange);
        userStore.unlisten(this.handleUserChange);
    }
    handleLanguageChange(state) {
        this.setState({languageStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    render() {
        return (
            <Grid>
                <Title code="translator.title"/>
                {
                    this.state.languageStore.userParams == null ?
                        <Loading />
                        :
                        <TranslationTable userParams={this.state.languageStore.userParams} user={this.state.userStore.user}/>
                }
            </Grid>)
    }
};
class TranslationTable extends React.Component {
    constructor(props) {
        super(props);
        const languages = {};
        languages[TranslationCl.prototype.lngCols.de] = false;
        languages[TranslationCl.prototype.lngCols.en] = true;
        languages[TranslationCl.prototype.lngCols.es] = false;
        languages[TranslationCl.prototype.lngCols.fr] = false;
        languages[TranslationCl.prototype.lngCols.it] = false;
        languages[TranslationCl.prototype.lngCols.ru] = false;
        languages[TranslationCl.prototype.lngCols.pl] = false;
        languages[TranslationCl.prototype.lngCols.da] = false;
        languages[TranslationCl.prototype.lngCols.sv] = false;
        languages[TranslationCl.prototype.lngCols.nl] = false;
        languages[TranslationCl.prototype.lngCols.cs] = false;
        if (this.props.userParams.editLanguages) {
            for (let i = 0; i < this.props.userParams.editLanguages.length; i++) {
                languages[this.props.userParams.editLanguages[i]] = true;
            }

        }
        this.changedVisibility = this.changedVisibility.bind(this);
        this.changedFilledEntries = this.changedFilledEntries.bind(this);
        this.translationSearchFunction = this.translationSearchFunction.bind(this);
        this.buildRow = this.buildRow.bind(this);
        this.handleTRowClicked = this.handleTRowClicked.bind(this);
        this.buildHeaderLeft = this.buildHeaderLeft.bind(this);
        this.state = {
            visLanguages: languages,
            toggleTranslEnv: null
        }
    }
    changedVisibility(lngCode, value) {
        let vLng = this.state.visLanguages;
        vLng[lngCode] = value;
        this.setState({visLanguages: vLng});
    }
    changedFilledEntries(searchParams, editLanguage, value) {
        console.log("HideFilled for " + editLanguage + " val: " + value);
        searchParams.editLanguage = value ? editLanguage : null;
        searchParams.disableSearch = value ? true : false;
        this.translationSearchFunction(searchParams, tableStore.state.allObjectList);
    }
    translationSearchFunction(searchParams, allObjectList) {
        console.log("search " + allObjectList.length, searchParams);
        if (searchParams.text.length > 0 || searchParams.editLanguage != null) {
            const searchString = searchParams.text.toLowerCase();
            const editLng = searchParams.editLanguage;
            let objects = allObjectList.filter(function (u) {
                if (editLng != null) {
                    const translation = u.getTranslation(editLng);
                    return translation == null || translation.length == 0;
                }
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
            searchParams.filterInfo = objects.length + "/" + allObjectList.length;
            tableAction.finishSearch(objects, searchParams);
        } else {
            searchParams.filterInfo = allObjectList.length;
            tableAction.finishSearch(allObjectList, searchParams);
        }
    }
    buildRow(translation) {
        return <TranslationRow key={translation.id}
                               translation={translation}
                               visLanguages={this.state.visLanguages}
                               userParams={this.props.userParams}/>
    }
    handleTRowClicked(event, entry, selArray) {
        if (this.props.userParams.isAdmin) {
            this.setState({toggleTranslEnv: entry});
        }
    }
    buildHeaderLeft(searchParams) {
        if (this.props.userParams.editLanguages) {
            // show checkbox to hide filled entries
            return (<React.Fragment>
                {
                    this.props.userParams.editLanguages.map(editLang => {
                        return <CheckboxInput key={"CBI_EL" + editLang}
                            onAfterChange={value => {
                                this.changedFilledEntries(searchParams, editLang, value);
                            }}
                            message={messages.get("translator.info.hideFilled") + ": " + editLang}/>
                    })
                }


            </React.Fragment>)
        } else {
            return null;
        }
    }
    render() {
        // TODO - why noftification area is not shown
        // TODO - ADMIN show changed languages counter
        // Changed keys since last Build: de(14); en(18); it(53); pl(2); es(1);
        return (
        <Row>
            <Col xs={12}>
                <Alert bsStyle="info"><I18n code="translator.info.save"/></Alert>
                <Well>
                    <Row>
                        <Col xs={12}>
                            <h4><I18n code="translator.change.column"/></h4>

                        </Col>
                        {
                           Object.keys(this.state.visLanguages).map(lngCode => {
                               return <Col xs={4} md={2} key={lngCode}>
                                            <CheckboxInput
                                                     default={this.state.visLanguages[lngCode]}
                                                     onAfterChange={value => {
                                                         this.changedVisibility(lngCode, value);
                                                     }}
                                                     message={messages.get("language." + lngCode)}
                               /></Col>
                           })
                        }
                    </Row>
                </Well>
                {
                    this.props.userParams.isAdmin ?
                        <AdminElements user={this.props.user}
                                       visLanguages={this.state.visLanguages}
                                       toggleTranslEnv={this.state.toggleTranslEnv}/> : null

                }
            </Col>
            <Col xs={12}>
                <SearchTable
                    tHead={
                        <thead>
                        <tr>
                            <th className="col-md-1"><I18n code="tournament.table.column.date"/></th>
                            <th className="col-md-2"><I18n code="key"/></th>
                            {
                                this.props.userParams.editLanguages ?
                                   this.props.userParams.editLanguages.map(editLang => {
                                       if (this.state.visLanguages[editLang]) {
                                           return <th  key={"TH_EL" + editLang} className="col-md-3">{messages.get("language." + editLang)}</th>
                                       }

                                   }): null
                            }
                            {
                                Object.keys(this.state.visLanguages).map(lngCode => {
                                    if (!Utility.containsArray(this.props.userParams.editLanguages, lngCode) && this.state.visLanguages[lngCode]) {
                                        return <th className="col-md-2" key={"col_" + lngCode}><I18n code={"language." + lngCode}/></th>
                                    }
                                })
                            }
                        </tr>
                        </thead>

                    }
                    tRow={this.buildRow}
                    tRowClicked={this.handleTRowClicked}
                    custSearchFunction={this.translationSearchFunction}
                    custHeaderLeftFunction={this.buildHeaderLeft}/>

            </Col>
        </Row>)
    }
};
TranslationTable.propTypes = {
    userParams: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};
const TranslationRow = ({userParams, translation, visLanguages}) => {
    const isEditable = (lngCode) => {
        if (userParams.isAdmin) {
            return true;
        } else {
            return Utility.containsArray(userParams.editLanguages, lngCode);
        }
    };
    const buildEnvString = () => {
        let environment = "";
        if (userParams.isAdmin) {
            if (translation.getForAndroid()) {
                environment +=" #andr ";
            }
            if (translation.getForIos()) {
                environment +=" #ios ";
            }
            if (translation.getForWeb()) {
                environment +=" #web ";
            }
            if (translation.getForReactN()) {
                environment +=" #ReactN ";
            }
            if (environment.length <= 0) {
                environment = "#NotUsed"
            }
        }
        return environment;
    };
    return (
        <React.Fragment>
            <td>{translation.getCreatedAt().toLocaleDateString()}<br/>{buildEnvString()}</td>
            <td>{translation.getKey()}<br/>{translation.getKeyIOS()}</td>
            {
                userParams.editLanguages ?
                    userParams.editLanguages.map(editLang => {
                        if (visLanguages[editLang]) {
                            return  <TranslationInputElement  key={"TIE_EL" + editLang} translation={translation}
                                                              lngCode={editLang}/>
                        }
                    }): null
            }
            {
                Object.keys(visLanguages).map(lngCode => {
                    if (!Utility.containsArray(userParams.editLanguages, lngCode) && visLanguages[lngCode]) {
                        if (isEditable(lngCode)) {
                            return <TranslationInputElement key={lngCode + "_" + translation.id}
                                                            translation={translation}
                                                            lngCode={lngCode}/>
                        } else {
                            return <td key={lngCode + "_" + translation.id}>
                                {translation.getTranslation(lngCode)}
                                </td>
                        }
                    }
                })
            }
        </React.Fragment>
    )
};
TranslationRow.propTypes = {
    translation: PropTypes.object.isRequired,
    visLanguages: PropTypes.object.isRequired,
    userParams: PropTypes.object.isRequired
};
class TranslationInputElement extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.state = {
            valState: null
        }
    }
    handleChange(event, lngCode) {
        if (event.target.value.indexOf("\"") > -1) {
            // remove invalid chars
            event.target.value = event.target.value.replace("\"","");
        }
        // show user that field is not saved!
        let nValState = this.state.valState;
        if (event.target.value != event.target.defaultValue) {
            this.setState({valState: "warning"})
        } else {
            this.setState({valState: null})
        }
    }
    handleKeyPress(event, lngCode) {
        // save on Enter
        if (event.key === 'Enter') {
            let nValue = event.target.value;
            let oldValue = event.target.defaultValue;
            languageActions.updateTranslationObj(this.props.translation, lngCode, nValue, () => {
                this.setState({valState: "success"})
            });
        }
    }
    render() {
        return (
            <td>
                <FormGroup controlId="formValidationWarning" validationState={this.state.valState}>
                    <FormControl
                        defaultValue={this.props.translation.getTranslation(this.props.lngCode)}
                        onKeyPress={(event) => this.handleKeyPress(event, this.props.lngCode)}
                        onChange={(event) => this.handleChange(event, this.props.lngCode)}/>
                    <FormControl.Feedback />
                </FormGroup>
            </td>
        )
    }
};
TranslationInputElement.propTypes = {
    translation: PropTypes.object.isRequired,
    lngCode: PropTypes.string.isRequired
};
const AdminElements = ({user, toggleTranslEnv, visLanguages}) => {
    const handleKeyPress = (event) => {
        // save on Enter
        if (event.key === 'Enter') {
            let nValue = event.target.value;
            const translation = new TranslationCl();
            translation.setKey(nValue);
            translation.setEditor(user);
            languageActions.createTranslationObj(translation)
        }
    };
    // TODO - ADMIN use resetTranslationChangedArray
    return (
        <Well>
            <Row>
                <Col md={4}>
                    <p><I18n code="translator.admin.toggle"/>
                        {toggleTranslEnv ? " " + toggleTranslEnv.getKey() : null}
                    </p>
                    {
                        toggleTranslEnv ?
                            <ButtonGroup>
                                <Button onClick={() => languageActions.handleToggleEnvironment(TranslationCl.prototype.col.forAndroid,toggleTranslEnv)}>
                                    <FontAwesome icon={["fab","android"]}/>
                                </Button>
                                <Button onClick={() => languageActions.handleToggleEnvironment(TranslationCl.prototype.col.forIos,toggleTranslEnv)}>
                                    <FontAwesome icon={["fab","apple"]}/>
                                </Button>
                                <Button onClick={() => languageActions.handleToggleEnvironment(TranslationCl.prototype.col.forWeb,toggleTranslEnv)}>
                                    <FontAwesome icon={["fab","html5"]}/>
                                </Button>
                                <Button onClick={() => languageActions.handleToggleEnvironment(TranslationCl.prototype.col.forReactN,toggleTranslEnv)}>
                                    <FontAwesome icon="mobile-alt"/>
                                </Button>
                            </ButtonGroup>
                            :
                            <p><I18n code="translator.admin.toggle.info"/></p>

                    }
                </Col>
                <Col md={4}>
                    <p><I18n code="translator.admin.expFile"/></p>
                    <DropdownButton id="expFile" bsStyle="primary" title={messages.get("translator.admin.chooseType")}>
                        <MenuItem onClick={() => languageActions.buildTypeFiles(TranslationCl.prototype.col.forAndroid, visLanguages)}>
                            <FontAwesome icon={["fab","android"]}/>Android XML
                        </MenuItem>
                        <MenuItem onClick={() => languageActions.buildTypeFiles(TranslationCl.prototype.col.forIos, visLanguages)}>
                            <FontAwesome icon={["fab","apple"]}/>iOS
                        </MenuItem>
                        <MenuItem onClick={() => languageActions.buildTypeFiles(TranslationCl.prototype.col.forWeb, visLanguages)}>
                            <FontAwesome icon={["fab","html5"]}/>Web
                        </MenuItem>
                        <MenuItem onClick={() => languageActions.buildTypeFiles(TranslationCl.prototype.col.forReactN, visLanguages)}>
                            <FontAwesome icon="mobile-alt"/>ReactNative
                        </MenuItem>
                    </DropdownButton>
                </Col>
                <Col md={4}>
                    <p><I18n code="translator.admin.addKey"/></p>
                    <FormControl
                        placeholder={"key"}
                        onKeyPress={handleKeyPress}/>
                </Col>
            </Row>
        </Well>
    )
};
AdminElements.propTypes = {
    user: PropTypes.object.isRequired,
    visLanguages: PropTypes.object.isRequired,
    toggleTranslEnv: PropTypes.object
};
module.exports = TranslatorPage;
