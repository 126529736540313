const alt = require("AltInit"),
    {ClubUser, User} = require("parse/_Domain");

const clubDao = require("actdao/ClubDao");
const notificationActions = require("components/notification/NotificationActions");
const ParseListUtility = require("util/ParseListUtility");

class ClubEventActions {


    showEventDetails(eventID, user, secret) {
        clubDao
            .loadClubEventDetails(eventID)
            .then(clubEvent => {
                if (clubEvent != null) {
                    if (clubEvent.hasLinkSecret(secret)) {
                        this.loadClubUserCC(clubEvent, user, secret)
                    } else {
                        this.loadClubUser(clubEvent.getClubID(), user);
                    }
                }
                this.updateClubEvent(clubEvent);

            })
            .catch(error => {
                error.trace = "CEVA.showDetails";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadClubUser(club, user) {
        if (user != null) {
            clubDao.loadClubUserList(club, null, null)
                .then(clubUsers => {
                    let ownClubUser = this.getOwnClubUser(clubUsers, user);
                    this.updateOwnClubUser(ownClubUser);
                    this.updateClubUsersList(clubUsers);
                })
                .catch(error => {
                    error.trace = "CEVA.loadClubUserList";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateClubUsersList(null);
        }
        return {};
    }

    loadClubUserCC(clubEvent, user, secret) {
        clubDao.loadClubUserListCC(clubEvent, secret)
            .then(clubUsers => {
                let ownClubUser = this.getOwnClubUser(clubUsers, user);
                this.updateOwnClubUser(ownClubUser);
                this.updateClubUsersList(clubUsers);
            })
            .catch(error => {
                error.trace = "CEVA.loadClubUserList";
                notificationActions.parseError(error);
            });
        return {};
    }

    sortClubUsers(clubUsers, sortParams) {
        this.updateClubUsersList(ParseListUtility.sortList(clubUsers, sortParams));
        return sortParams;
    }

    getOwnClubUser(clubUsers, user) {
        if (user != null && clubUsers != null) {
            var length = clubUsers.length;
            for (var i = 0; i<length; i++) {
                var clUUser = clubUsers[i].getUserID();
                if (clUUser != null && clUUser.id == user.id) {
                    return clubUsers[i];
                }
            }
        }
        return null;
    }

    updateClubEvent(clubEvent) {
        return clubEvent;
    }

    updateOwnClubUser(pClubUser) {
        return pClubUser;
    }

    updateClubUsersList(pClubUserList) {
        return pClubUserList;
    }

    handleClubEventOption(clubEvent, clubUser, option) {
        clubDao.updateClubEventOption(clubEvent, clubUser, option)
            .then (uClubEvent => {
                this.updateClubEvent(uClubEvent);
            })
            .catch(error => {
                error.trace = "CEVA.handleClubEventOption";
                notificationActions.parseError(error);
            });
        return {};
    }

    removeClubEventOption(clubEvent, clubUserID, option) {
        clubDao.removeClubEventOption(clubEvent, clubUserID, option)
            .then (uClubEvent => {
                this.updateClubEvent(uClubEvent);
            })
            .catch(error => {
                error.trace = "CEVA.removeClubEventOption";
                notificationActions.parseError(error);
            });
        return {};
    }
}
module.exports = alt.createActions(ClubEventActions);
