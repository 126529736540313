/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const messages = require("i18n/messages");
const I18n = require("components/widgets/I18n"),
    ModalBasic = require ("components/modals/ModalBasic"),
    FontAwesome = require("components/widgets/FontAwesome"),
    CheckboxInput = require("components/form/CheckboxInput"),
    ValidInput = require("components/form/ValidInput");

const Management = require("parse/Management");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const configTypeDefs = {
    "mgmt_invTaxConfig": {
        header:"Invoice tax",
        desc:"tax Config for invoice document",
        objColName: Management.prototype.col.invoiceTaxConfig,
        configEntries: [
            {name: Management.prototype.invTaxConfig.positionTax, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"20"},
            {name: Management.prototype.invTaxConfig.surchargeTax, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"20"},
            {name: Management.prototype.invTaxConfig.byPass, type:"bool", valid:{check:['isRequired']}},
            {name: Management.prototype.invTaxConfig.igErwerb, type:"bool", valid:{}},
        ]
    },
    "mgmt_settleTaxConfig": {
        header:"Settlement tax",
        desc:"tax Config for settlement document",
        objColName: Management.prototype.col.settlementTaxConfig,
        configEntries: [
            {name: Management.prototype.settleTaxConfig.positionTax, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"20"},
            {name: Management.prototype.settleTaxConfig.provisionTax, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"20"}]
    },
    "mgmt_percentage" : {
        header:"Settlement percentage",
        desc:"Provision for each payment transaction",
        objColName: Management.prototype.col.percentage,
        configEntries: [
            {name: Management.prototype.brand.visa, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"5"},
            {name: Management.prototype.brand.master, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"5"},
            {name: Management.prototype.brand.sofort, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"5"},
            {name: Management.prototype.brand.paypal, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"5"},
            {name: Management.prototype.brand.bluecode, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"5"},
            {name: Management.prototype.minPrice, type:"int", valid:{check:['isRequired', 'isNumber']}, placeholder:"1"},
        ]
    },
    "mgmt_mailconfig" : {
        header:"E-mail config",
        desc:"E-mail confirmation after invoice payment",
        objColName: Management.prototype.col.mailConfig,
        configEntries: [
            {name: "mailParcours", type:"text", valid:{check:['isRequired', 'isMail']}, placeholder:"Mail address"},
            {name:"isMailParcours", type:"bool", valid:{check:['isRequired']}}]
    }
};
const ObjectConfigModal = ({pObject, configType, cbUpdatedObject}) =>  {
    const handleSaveConfig = (newConfig) => {
        console.log("Save new config", newConfig);
        if (pObject != null) {
            pObject.set(configTypeDef.objColName, newConfig);
            cbUpdatedObject(pObject)
        } else {
            cbUpdatedObject(newConfig)
        }

    };
    const configTypeDef = configTypeDefs[configType];
    let actConfigObject = pObject != null ? pObject.get(configTypeDef.objColName) : null;
    let btnStyle = "success";
    if (actConfigObject == null) {
        btnStyle = "warning";
        actConfigObject = {};
    }
    return (
            <ModalBasic
                title={<I18n code={configTypeDef.header}/>}
                desc={<I18n code={configTypeDef.desc}/>}
                buttonStyle={btnStyle}
                buttonChildren={<FontAwesome icon="pencil-alt">{configTypeDef.header}</FontAwesome>}>
                <ObjectConfigForm actConfigObject={actConfigObject}
                                  configEntries={configTypeDef.configEntries}
                                  callbackConfig={handleSaveConfig}/>
            </ModalBasic>
    )
};
ObjectConfigModal.propTypes = {
    pObject: PropTypes.object.isRequired,
    configType: PropTypes.string.isRequired,
    cbUpdatedObject: PropTypes.func.isRequired
};

class ObjectConfigForm extends React.Component {
    constructor(props) {
        super(props);
        this.refDic = {};
        this.props.configEntries.map(configEntry => {
            this.refDic[configEntry.name] = React.createRef();
        });
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [];
        this.props.configEntries.map(configEntry => {
            if (configEntry.valid && configEntry.valid.check && configEntry.valid.check.length > 0) {
                // if this contains isRequired
                if ('isRequired' == configEntry.valid.check[0]) {
                    required.push(this.refDic[configEntry.name].current.getValue());
                }
            }
        });
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, setErrorFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let config = {};
        this.props.configEntries.map(configEntry => {
            if ("int" === configEntry.type) {
                config[configEntry.name] = parseInt(this.refDic[configEntry.name].current.getValue());
            } else {
                // as string
                config[configEntry.name] = this.refDic[configEntry.name].current.getValue()
            }

        });
        if (this.props.callbackConfig) {
            this.props.callbackConfig(config);
            closeFct();
        } else {
            setErrorFct({message: "You need to assign callbackConfig"});
        }
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {actConfigObject, configEntries} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col xs={12}>
                        {
                            configEntries.map(configEntry => {
                                if ("int" === configEntry.type || "text" === configEntry.type) {
                                    return <ValidInput ref={this.refDic[configEntry.name] }
                                                       valid={configEntry.valid}
                                                       label={configEntry.name}
                                                       placeholder={configEntry.placeholder}
                                                       default={actConfigObject[configEntry.name]}/>
                                } else if ("bool") {
                                    return <CheckboxInput ref={this.refDic[configEntry.name] }
                                                   default={actConfigObject[configEntry.name]}
                                                   message={configEntry.name} />
                                }

                            })
                        }
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
ObjectConfigForm.propTypes = {
    actConfigObject:PropTypes.object.isRequired,
    configEntries: PropTypes.array.isRequired,
    callbackConfig: PropTypes.func.isRequired
};
ObjectConfigForm.contextType = ModalBasicContext;
module.exports = ObjectConfigModal;