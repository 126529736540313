const React = require("react"),
    PropTypes = require('prop-types');

const MapSmall = ({mapCoordinates}) =>{
        return (
            <iframe width="100%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                    src={"https://maps.google.com/maps?hl=de&ie=UTF8&q="+ mapCoordinates +"&t=&z=15&output=embed"}></iframe>
        )
};
MapSmall.propTypes = {
    mapCoordinates: PropTypes.string.isRequired
};
module.exports = MapSmall;