const React = require("react"),
    PropTypes = require('prop-types');
const {Col,Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");

const TournamentTeamResultTable = ({tournamentUserDic, killColumnName, primeType, secType, tuSex}) => {
        const key = tournamentUserDic.generateDicKey(primeType, secType, tuSex);
        const groupedList = tournamentUserDic.getList(key);
        if (!groupedList) {
            // nothing to show
            return null;
        }
        return (<Col md={12} id={key}>
                    <br/>
                    <TeamResultTable groupedTournamentTeamList={groupedList}
                                     killColumnName={killColumnName}/>
                </Col>)
};
TournamentTeamResultTable.propTypes = {
    tournamentUserDic:PropTypes.object.isRequired,
    killColumnName:PropTypes.string.isRequired,
    primeType:PropTypes.object.isRequired,
    secType:PropTypes.object,
    tuSex:PropTypes.string.isRequired
};
const TeamResultTable = ({groupedTournamentTeamList, killColumnName}) => {
    let counter = 0;
    return (
        <Table striped>
            <thead className="bowage-head">
            <tr>
                <th className="col-md-1"><I18n code="tournament.table.column.position"/></th>
                <th className="col-md-3 printCol-100"><I18n code="tournament.table.column.name"/></th>
                <th className="col-md-2 printCol-95"><I18n code="tournament.table.column.archers"/></th>
                <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.points"/></th>
                <th className="col-md-1 printCol-55">{killColumnName}</th>
                <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.points"/></th>
                <th className="col-md-1 printCol-55">{killColumnName}</th>
            </tr>
            </thead>
            <tbody className="bowage-table">
            {
                groupedTournamentTeamList.map(tournamentTeam => {
                    counter++;
                    return (<TournamentTeamResultTableRow key={tournamentTeam.id}
                                                           position = {counter}
                                                           sumPoints = {tournamentTeam.getSumPoints()}
                                                           killCounts = {tournamentTeam.getKillCounts2String()}
                                                           tournamentTeam={tournamentTeam}/>);
                })}
            </tbody>
        </Table>
    )
};
TeamResultTable.propTypes = {
    groupedTournamentTeamList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
};
const TournamentTeamResultTableRow = ({position, tournamentTeam, sumPoints, killCounts}) => {
        const tUsers = tournamentTeam.getTournamentUserIDs();
        return (
            <tr>
                <td>{sumPoints >= 0 ? position : ""}</td>
                <td>{tournamentTeam.getFullName()}</td>
                <td>{"\n"}<br/>
                    {
                        tUsers.map(tUser => {
                            return <div>{tUser.getFullName() + "\n"}</div>
                        })
                    }
                </td>
                <td>{"\n"}<br/>
                    {
                        tUsers.map(tUser => {
                            return <div key={tUser.id + "_p"}>
                                {tUser.getSumPoints() >= 0 ? Math.round(tUser.getSumPoints() * 100) / 100 : "--- " }{"\n"}
                            </div>
                        })
                    }
                </td>
                <td>{"\n"}<br/>
                    {
                        tUsers.map(tUser => {
                            return <div key={tUser.id + "_k"}>
                                {tUser.getKillCounts2String() + "\n"}
                            </div>
                        })
                    }
                </td>
                <td>{sumPoints}</td>
                <td>{killCounts}</td>
            </tr>)
};
TournamentTeamResultTableRow.propTypes = {
    tournamentTeam:PropTypes.object.isRequired,
    position:PropTypes.number.isRequired,
    sumPoints:PropTypes.any.isRequired,
    killCounts:PropTypes.string.isRequired
};
module.exports = TournamentTeamResultTable;