const alt = require("AltInit");
const Parse = require("parse");
const notificationActions = require("components/notification/NotificationActions");
const userActions = require("actions/UserActions");
const messages = require("i18n/messages");

class NotificationStore {
    constructor() {
        this.code = [];
        this.trace = [];
        this.style = [];
        this.newVersion = false;
        this.invalidSession = false;
        this.parseOffline = false;
        this.userAuhenticate = null;
        this.lockMessage = null;
        this.bindListeners({
            onReset: notificationActions.reset,
            parseError: notificationActions.parseError,
            notifyNewVersion: notificationActions.notifyNewVersion,
            notifyInvalidSession: notificationActions.notifyInvalidSession,
            notifyParseOffline: notificationActions.notifyParseOffline,
            verifyUserEmail: userActions.updateUser,
            onNotification: [
                notificationActions.success,
                notificationActions.info,
                notificationActions.warning,
                notificationActions.error
            ]
        })
    }


    parseError(error) {
        console.dir(error);
        let code = 0;
        if (error.code) {
            code = error.code;
        }
        let trace = "";
        if (error.trace != null) {
            trace = " (" + error.trace + ")";
        }
        if (Object.keys(messages).length == 0) {
            this.error(error.message + "(Code:" + code + ")", trace);
        } else {
            switch (code) {
                case Parse.Error.INVALID_SESSION_TOKEN:
                    if (Parse.User.current() == null) {
                        console.log("WTF NO_USER");
                        this.notifyInvalidSession();
                    } else if (Parse.User.current().id == "rlQmAhyRA7") {
                        console.log("RO_USER_REFRESH");
                        // woah readOnlyUser
                        Parse.User.logOut();
                        window.location.reload();
                    } else {
                        this.notifyInvalidSession();
                    }
                    break;
                case 100:
                    if (!this.parseOffline) {
                        this.error(messages.get("parse.error." + code), trace);
                    }
                    break;
                case 119:
                    this.error(messages.get("parse.error." + code), trace);
                    break;
                case 141: // eg throw in cloud code
                    this.error(messages.get("parse.error." + code) + messages.get(error.message), trace);
                    break;
                default:
                    this.error(code, error.message + "(Code:" + code + ")", trace);
            }
        }
        return {};
    }

    error(code, trace) {
        this.onNotification({code: code, style: "danger", trace: trace});
    }

    onReset() {
        this.code = [];
        this.style = [];
        this.newVersion = false;
        this.invalidSession = false;
        this.parseOffline = false;
        this.userAuhenticate = null;
        this.lockMessage = null;
    }
    notifyNewVersion() {
        this.newVersion = true;
    }
    notifyInvalidSession() {
        this.invalidSession = true;
    }

    notifyParseOffline() {
        this.parseOffline = true;
    }

    verifyUserEmail(user) {
        let isMailValid = user ? user.get("emailVerified") : true;
        if (!isMailValid) {
            this.userAuhenticate = user;
        } else {
            this.userAuhenticate = null;
        }
        this.lockMessage = user ? user.get("lockMessage") : null;
    }

    onNotification(ntfctn) {
        if (!this.alreadyPushed(ntfctn)) {
            this.code.push(ntfctn.code);
            this.trace.push(ntfctn.trace);
            this.style.push(ntfctn.style);
        }
    }

    alreadyPushed(ntfctn) {
        for (let i = 0; i < this.code.length; i++) {
            if (this.code[i] === ntfctn.code) {
                return true;
            }
        }
        return false;
    }
}
module.exports = alt.createStore(NotificationStore);
