const React = require("react"),
    PropTypes = require('prop-types'),
    Parse = require("ParseInit");
const {FormGroup, Alert, ControlLabel, FormControl, Button} = require("react-bootstrap");
const messages = require("i18n/messages"),
    Utility = require("util/Utility"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome");

class FileUploadInput extends React.Component {
    constructor(props) {
        super(props);
        this.fileSelected = this.fileSelected.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.checkValidFileSizeType = this.checkValidFileSizeType.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.checkFileExtenstion = this.checkFileExtenstion.bind(this);
        this.getDocumentName = this.getDocumentName.bind(this);
        this.state = {
            help: null,
            loading: false,
            style: "success"
        }
    }
    fileSelected(e) {
        console.log("File selected");
        var files = e.target.files;
        this.handleFileUpload(files);
    }
    setLoading(loading) {
        this.setState({
            loading: loading
        });
    }
    handleFileUpload(files) {
        var that = this;
        if (files != null && files.length == 1) {
            // upload file
            var file = files[0];
            var imageType = this.checkValidFileSizeType(file);
            if (imageType != null) {
                // file valid
                var parseFile = new Parse.File(Utility.cleanInvalidChars(file.name), file);
                parseFile.save()
                    .then(sParseFile => {
                        that.setLoading(false);
                        that.props.savedParseFileCallback(sParseFile, imageType);
                    })
                    .catch(error => {
                        console.log("BASE_sFile "+ error.message);
                        that.showHelpMessage("Could not store File " + error.message, "danger");
                    });
            }
        } else {
            this.showHelpMessage(messages.get("fileupload.selectFile"),"danger");
        }
    }
    checkValidFileSizeType(file) {
        console.log("validate " + file.name +  " size: " + file.size);
        this.setLoading(true);
        // check file size
        if (file.size > 5242880) {
            this.showHelpMessage(file.name + " - " + messages.get("fileupload.selectFileToLarge"),"danger");
            return null;
        }
        // check file extenstion
        if (this.props.allowedFileTypes) {
            var validImageExtensions = [".jpg", ".jpeg", ".bmp", ".gif", ".png"];
            var validDocExtenstions =[".doc", ".docx", ".xls", ".xlsx", ".txt"];
            var validPDFExtenstions =[".pdf"];
            var validMsg = [];
            for (var i= 0; i < this.props.allowedFileTypes.length; i++) {
                if ("I" == this.props.allowedFileTypes[i]) {
                    // only images allowed
                    if (this.checkFileExtenstion(validImageExtensions, file.name)) {
                        return "I";
                    } else {
                        validMsg.push(validImageExtensions)
                    }
                } else if ("D" ==  this.props.allowedFileTypes[i]) {
                    // only documents and PDFs allowed
                    if (this.checkFileExtenstion(validDocExtenstions, file.name)) {
                        return "D";
                    } else {
                        validMsg.push(validDocExtenstions)
                    }
                } else if ("P"  == this.props.allowedFileTypes[i]) {
                    //only PDF allowed
                    if (this.checkFileExtenstion(validPDFExtenstions, file.name)) {
                        return "P";
                    } else {
                        validMsg.push(validPDFExtenstions)
                    }
                }
            }
            this.showHelpMessage(file.name + " - " + messages.get("fileupload.selectFileInvalidExt") + validMsg.join(", "), "danger");
            return null;
        }
    }
    showHelpMessage(message, style) {
        var that = this;
        this.setState({
            help: message,
            loading: false,
            style: style
        });
        setTimeout(function () {
            that.showHelpMessage(null);
        }, 5000);
    }
    checkFileExtenstion(validFileExtensions, sFileName) {
        for (var j = 0; j < validFileExtensions.length; j++) {
            var sCurExtension = validFileExtensions[j];
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                return true;
            }
        }
        return false;
    }
    getDocumentName() {
        var res = "";
        if (this.props.parseFile != null) {
            var name = this.props.parseFile.name();
            var nameparts = name.split("_");
            for (var i = 1; i < nameparts.length; i++) {
                res += nameparts[i];
            }
        }
        return res;
    }
    render() {
            return (
                <div>
                    {
                        this.props.parseFile == null ?
                            <FormGroup>
                                <ControlLabel>{this.props.label}</ControlLabel>
                                <FormControl bsStyle="primary" type='file'  ref='fileUpload' onChange={this.fileSelected}/><br/>
                                {
                                    this.state.loading === true ? <Loading /> : null
                                }
                            </FormGroup>
                            :
                            <div>
                                <a href={this.props.parseFile.url()} target="_blank">{this.getDocumentName()}</a><br/>
                                {
                                    this.props.deleteParseFileCallback != null ?
                                        <Button onClick={this.props.deleteParseFileCallback}>
                                            <FontAwesome icon="trash" />{messages.get("modal.button.delete")}
                                        </Button> : null
                                }
                            </div>

                    }
                    <br/><br/>
                    {
                        this.state.help == null ? "" :
                            <Alert bsStyle={this.state.style}>
                                {this.state.help}
                            </Alert>
                    }
                </div>
            )
    }
}
FileUploadInput.propTypes = {
    parseFile: PropTypes.object,
    label: PropTypes.string,
    deleteParseFileCallback: PropTypes.func,
    savedParseFileCallback:PropTypes.func.isRequired,
    allowedFileTypes:PropTypes.array.isRequired
};

module.exports = FileUploadInput;