const React = require("react"),
    PropTypes = require('prop-types');
const messages = require("i18n/messages");

const I18nHtml = ({code, inline}) => {
    const label = messages.get(code);
    return (<div style={inline? {display:"inline"}:{}} dangerouslySetInnerHTML={{__html:label}}></div>);
};
I18nHtml.propTypes = {
    code: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};
module.exports = I18nHtml;
