const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Table, Well, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TooltipButton  = require("components/form/TooltipButton"),
    CheckboxInput = require("components/form/CheckboxInput"),
    TextCenter = require("components/widgets/TextCenter"),
    ValidInput = require("components/form/ValidInput"),
    SubmitButton = require("components/form/SubmitButton"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const ParseListUtility = require("util/ParseListUtility");
const tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

const GroupGenTypes=[
    {id: "MC", getSelectString:() => { return "tournament.modal.group.maxClub"}, status:"A"},
    {id: "RC", getSelectString:() => { return "tournament.modal.group.rankingCup"}, status:"A"},
]

class TournamentUserGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.refModal = React.createRef();
    }
    open() {
        this.refModal.current.open();
    }
    render() {
        const {tournament, actTournamentRound, tournamentUserGroups} = this.props;
        return (
            <ModalBasic ref={this.refModal}
                        desc={<I18n code="tournament.mmtuser.tooltip.group.autogen.desc"/>}
                        title={<TextCenter><I18n code="tournament.mmtuser.tooltip.group.autogen"/></TextCenter>}
                        customButton={<TooltipButton onClick={this.open} icon="users-cog"
                                                     tooltip={messages.get("tournament.mmtuser.tooltip.group.autogen")} />}>
                <TournamentUserGroupForm tournament={tournament} actTournamentRound={actTournamentRound} tournamentUserGroups={tournamentUserGroups}/>
            </ModalBasic>
        )
    }
}
TournamentUserGroupModal.propTypes = {
    tournament:PropTypes.object.isRequired,
    actTournamentRound: PropTypes.object,
    tournamentUserGroups: PropTypes.array
};
class TournamentUserGroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.refMaxTUser = React.createRef();
        this.refMaxTUserClub = React.createRef();
        this.refRankingTUser = React.createRef();
        this.refPaidOnly = React.createRef();
        this.refSubmitButton = React.createRef();
        this.generate = this.generate.bind(this);
        this.generateMaxClub = this.generateMaxClub.bind(this);
        this.generateCupRanking = this.generateCupRanking.bind(this);
        this.createTUGroup = this.createTUGroup.bind(this);
        this.handleGenTypeChange = this.handleGenTypeChange.bind(this);
        this.state = {
            tuGroups: [],
            groupGenType: GroupGenTypes[0]
        }
    }
    generate() {
        this.setState({tuGroups: []})
        const tuGroupCallback = (tuGroups) => {
            this.refSubmitButton.current.resetLoading();
            this.setState({tuGroups: tuGroups})
        }
        if (this.state.groupGenType.id === GroupGenTypes[0].id) {
            this.generateMaxClub(tuGroupCallback)
        } else if (this.state.groupGenType.id === GroupGenTypes[1].id) {
            this.generateCupRanking(tuGroupCallback)
        }

    }
    generateMaxClub(tuGroupCallback) {
        const params = {
            ranking: false,
            maxGroupSize: this.refMaxTUser.current.getValue(),
            maxTUserUnion: this.refMaxTUserClub.current.getValue(),
            rankingTUser: null,
            cupMaster: null,
            paidOnly: this.refPaidOnly.current.getValue(),
            mixSexes: true, // men and female can be in same group
            mixAgeGroups : true // different age groups in same groups
        }
        // if separate unions - grouping will only add matTUserUnion amount to one group
        params.separateUnions = params.maxTUserUnion < params.maxGroupSize
        tournamentUserManagerActions.generateGroups(this.props.tournament, params, tuGroupCallback)
    }
    generateCupRanking(tuGroupCallback) {
        const params = {
            ranking: true, // switch to suggestRankingGroups
            maxGroupSize: this.refMaxTUser.current.getValue(),
            rankingTUser: this.refRankingTUser.current.getValue(),
            // load tUser from cup master to setup ranking
            cupMaster: this.props.tournament.getCupMasterTournamentID(),
            paidOnly: this.refPaidOnly.current.getValue(),
            mixSexes: false,
            mixAgeGroups: false,
            separateUnions: false
        }
        if (params.rankingTUser === '') {
            params.rankingTUser = 0
        }
        if (params.rankingTUser > 0 && params.maxGroupSize > params.rankingTUser) {
            // maxGroup can max be rankingTUser
            params.maxGroupSize = params.rankingTUser
        }
        tournamentUserManagerActions.generateGroups(this.props.tournament, params, tuGroupCallback)
    }
    createTUGroup(tuGroup) {
        // create group and add to group panel
        tournamentUserManagerActions.createTournamentGroup(this.props.tournament, tuGroup.tuList,
            this.props.actTournamentRound, this.props.tournamentUserGroups)
        // remove from local tuGroups List
        const nTuGroups = ParseListUtility.removeObj(this.state.tuGroups, tuGroup)
        this.setState({tuGroups: nTuGroups})
    }
    handleGenTypeChange(nGroupGenType) {
        const {disableSubmitFct} = this.context;
        this.setState({groupGenType: nGroupGenType, tuGroups: []})
        if (nGroupGenType.id === GroupGenTypes[0].id) {
            disableSubmitFct(false)
        } else if (nGroupGenType.id === GroupGenTypes[1].id) {
            disableSubmitFct(this.props.tournament.getCupMasterTournamentID() == null)
        }
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {tuGroups, groupGenType} = this.state;
        return (
            <form action="#">
                <Row>
                    <Col xs={12}>
                        <DropDownParseObject default={groupGenType}
                                             onAfterChange={this.handleGenTypeChange}
                                             objectList={GroupGenTypes}/>
                    </Col>
                </Row>
                <br/>
                {
                    groupGenType.id === GroupGenTypes[0].id ? <Row>
                        <Col sm={6}>
                            <ValidInput ref={this.refMaxTUser} type="number"
                                        default={6}
                                        label={messages.get("tournament.modal.group.maxTUser")}
                                        valid={{maxLength: 2, check:['isNumber', 'greater0']}}/>
                        </Col>
                        <Col sm={6}>
                            <ValidInput ref={this.refMaxTUserClub} type="number"
                                        default={3}
                                        label={messages.get("tournament.modal.group.maxTUserClub")}
                                        valid={{maxLength: 2, check:['isNumber', 'greater0']}}/>
                        </Col>
                        <Col sm={12}>
                            <CheckboxInput ref={this.refPaidOnly} default={false}
                                           message={messages.get("tournament.modal.group.paidonly")} />
                        </Col>
                    </Row> : null
                }
                {
                    groupGenType.id === GroupGenTypes[1].id ? <Row>
                        <Col sm={6}>
                            <ValidInput ref={this.refMaxTUser} type="number"
                                        default={4}
                                        label={messages.get("tournament.modal.group.maxTUser")}
                                        valid={{maxLength: 2, check:['isNumber', 'greater0']}}/>
                        </Col>
                        <Col sm={5}>
                            <ValidInput ref={this.refRankingTUser} type="number"
                                        default={0}
                                        label={messages.get("tournament.modal.group.rankingTUser")}
                                        valid={{maxLength: 2, check:['isNumber']}}/>
                        </Col>
                        <Col sm={12}>
                            <CheckboxInput ref={this.refPaidOnly} default={false}
                                           message={messages.get("tournament.modal.group.paidonly")} />
                        </Col>
                    </Row> : null
                }
                <Row>
                    <Col xs={12} style={{"textAlign": "center"}}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <SubmitButton ref={this.refSubmitButton} bsSize="large"
                                      onClick={this.generate} disabled={submitDisabled}
                                      text={messages.get("tournament.modal.group.generate")}/>
                    </Col>
                </Row>
                {
                    tuGroups.length > 0 ? <TUGroupTable tuGroups={tuGroups} createTUGroup={this.createTUGroup}/> : null
                }
            </form>
        );
    }
}
TournamentUserGroupForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserGroups: PropTypes.array,
    actTournamentRound: PropTypes.object
};
TournamentUserGroupForm.contextType = ModalBasicContext;

const TUGroupTable = ({tuGroups, createTUGroup}) => {
    return <Row>
        <hr/>
        <Col xs={12}>
            {
                tuGroups.map(tuGroup => {
                    return <TUGroupEntry key={"TUG" + tuGroup.id} tuGroup={tuGroup} createTUGroup={createTUGroup}/>
                })
            }
        </Col>
    </Row>
}
TUGroupTable.propTypes = {
    tuGroups: PropTypes.array.isRequired,
    createTUGroup: PropTypes.func.isRequired
}
const TUGroupEntry = ({tuGroup, createTUGroup}) => {
    return <Well><Table>
        <thead>
        <tr>
            <th><I18n code="tournament.table.column.group"/>: {tuGroup.id}</th>
            <th></th>
            <th></th>
            <th><Button bsStyle="success" onClick={() => createTUGroup(tuGroup)}>
                <I18n code="modal.button.take"/></Button>
            </th>
        </tr>
        </thead>
        <tbody>
        {
            tuGroup.tuList.map(tUser => {
                return <TUserEntry key={"TU" + tUser.id} tUser={tUser}/>
            })
        }
        </tbody>
    </Table></Well>
}
TUGroupEntry.propTypes = {
    tuGroup: PropTypes.object.isRequired,
    createTUGroup: PropTypes.func.isRequired
}
const TUserEntry = ({tUser}) => {
    return <tr>
        <td>{tUser.getFullName()}</td>
        <td>{tUser.getUnion()}</td>
        <td>{tUser.getTournamentConfigBowCode()}</td>
        <td>{tUser.getTournamentConfigAgeCodeName()}</td>
    </tr>
}
module.exports = TournamentUserGroupModal;
