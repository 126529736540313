const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");

const messages = require("i18n/messages");

const ImageSelector = require("components/form/ImageSelector");
const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");
const SolutionHelpLink = require("components/links/SolutionHelpLink");
const tournamentActions = require("actions/TournamentManagerActions");

const TournamentMMFilePage  = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId}>
        <TournamentFileDetails />
    </TournamentMMLoader>
};
TournamentMMFilePage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
const TournamentFileDetails = ({tournament, user}) => {
    return (
        <div>
            <Row>
                <Col lg={12}>
                    <h1>{tournament.getName() + ", " + tournament.getTournamentDate()}</h1>
                    <h3>{messages.get("tournament.manager.file.title")}</h3>
                    <SolutionHelpLink articleID="42000051442" />
                    <br/>
                </Col>
            </Row>
            <Row>
                <TournamentImageSelector tournament={tournament} user={user}
                                         colName={"bannerImageID"}
                                         allowedFileTypes={["I"]} />
            </Row>
            <Row>
                <TournamentImageSelector tournament={tournament} user={user}
                                         colName={"imageID"}
                                         allowedFileTypes={["P","D","I"]} />
            </Row>
            <Row>
                <TournamentImageSelector tournament={tournament} user={user}
                                         colName={"resultImageID"}
                                         allowedFileTypes={["P","D","I"]} />
            </Row>
            <Row>
                <TournamentImageSelector tournament={tournament} user={user}
                                         colName={"sponsorImageID"}
                                         allowedFileTypes={["I"]} />
            </Row>
        </div>)
};
TournamentFileDetails.propTypes = {
    user:PropTypes.object,
    tournament:PropTypes.object.isRequired
};
const TournamentImageSelector = ({user, tournament, colName, allowedFileTypes}) => {
    function handleImageUpdated(image) {
        tournamentActions.updateTournamentImage(tournament, colName, image);
    }
    return <ImageSelector object={tournament}
                          user={user}
                          colName={colName}
                          header={messages.get("tournament.manager." + colName)}
                          showBGParams={false}
                          savedImageCallback={handleImageUpdated}
                          allowedFileTypes={allowedFileTypes} />
};
TournamentImageSelector.propTypes = {
    user:PropTypes.object,
    tournament:PropTypes.object.isRequired,
    colName:PropTypes.string.isRequired,
    allowedFileTypes:PropTypes.array.isRequired
};
module.exports = TournamentMMFilePage;
