const alt = require("AltInit");

const managementActions = require("actions/ManagementActions");
const notificationActions = require("components/notification/NotificationActions");
const parcoursActions = require("actions/ParcoursActions");
const {Parcours} = require("parse/_Domain");

class ParcoursStore {
    constructor() {
        this.parcoursList = null;
        this.allParcoursList = null;
        this.listRenderAmount = 20;
        this.poiList = null;
        this.parcoursOwner = null;
        this.ratings = null;
        this.ownRating = null;
        this.eventPlayerRanking = null;
        this.invalidUser = false;
        this.countTypes = null;
        this.searchParams = {
            pageMax: 2000,
            pageStart: 20,
            coords: null,
            searchText: ""
        };
        this.parcours = null;
        this.parcoursArticles = [];
        this.loading = false;
        this.rankLoading = true;
        this.parseError = null;
        this.bindListeners({
            onStartListSearch: parcoursActions.search,
            searchParcoursList: parcoursActions.searchParcoursList,
            onStartParcoursLoading:parcoursActions.onStartParcoursLoading,
            updateSavedObject:parcoursActions.updateSavedObject,
            updateParcours: parcoursActions.updateParcours,
            updateParcoursList: parcoursActions.updateParcoursList,
            updateListRenderAmount: parcoursActions.updateListRenderAmount,
            updatePOIList: parcoursActions.updatePOIList,
            addParcoursList: parcoursActions.addParcoursList,
            updateParcoursRating: parcoursActions.updateParcoursRating,
            updateParcoursRanking: parcoursActions.updateParcoursRanking,
            updateOwnParcoursRating: parcoursActions.updateOwnParcoursRating,
            resetParcoursRanking: parcoursActions.resetParcoursRanking,
            resetParcours: parcoursActions.resetParcours,
            setParseError:parcoursActions.setParseError,
            onGetParcoursOwner: parcoursActions.getParcoursOwner,
            onGetParcoursOwnerSuccess: parcoursActions.getParcoursOwnerSuccess,
            onInitRequestParcoursManagePage: managementActions.initRequestParcoursManagePage,
            setInvalidUser: parcoursActions.setInvalidUser,
        });
    }

    setParseError(error) {
        console.dir(error);
        this.loading = false;
        this.parseError = error;
        notificationActions.parseError(error);
        this.emitChange();
    };

    onStartParcoursLoading() {
        this.loading = true;
        this.resetParcours();
    }

    onStartListSearch() {
        this.parcoursList = null;
    }

    onGetParcoursOwner(parcoursOwnerId) {
        this.parcoursOwner = null;
    }

    onGetParcoursOwnerSuccess(parcoursOwner) {
        this.parcoursOwner = parcoursOwner;
    }

    onInitRequestParcoursManagePage(data) {
        this.parcoursOwner = null;
    }

    updateSavedObject(savedObject) {
        if ("Parcours" == savedObject.className) {
            this.parcours = savedObject;
        } else {
            this.parcours.set(Parcours.prototype.col.parcoursOwnerID, savedObject);
        }
        this.emitChange();
    }

    updateParcoursRating(pRatings) {
        this.ratings = pRatings;
    }
    updateOwnParcoursRating(pOwnRating) {
        this.ownRating = pOwnRating;
    }

    updateParcours(pParcours) {
        if (pParcours) {
            let articles=[];
            if (pParcours.getArticles()) {
                articles = articles.concat(pParcours.getArticles());
            }
            if (pParcours.getParcoursOwnerID().getArticles()) {
                articles = articles.concat(pParcours.getParcoursOwnerID().getArticles());
            }
            this.parcoursArticles = articles.filter((art) => {return art.isActive()});
        } else {
            this.parcoursArticles = []
        }
        this.parcours = pParcours;
        this.loading = false;
    }

    setInvalidUser() {
        this.loading = false;
        this.invalidUser = true;
    }

    updateParcoursList(pParcoursList) {
        if (this.poiList != null && this.poiList.length > 0) {
            const editList = pParcoursList;
            let poiLength = this.poiList.length;
            if (poiLength > 5) {
                console.log("Handle more than 5 elements - have to add the others in the add element");
                poiLength = 5;
            }
            // combine poiList into parcoursList
            const poiFiller = 4;
            for (let i = 0; i < poiLength; i++) {
                editList.splice(poiFiller * (i + 1) + i, 0, this.poiList[i]);
            }
            this.parcoursList = editList;
            this.allParcoursList = editList;
        } else {
            this.parcoursList = pParcoursList;
            this.allParcoursList = pParcoursList;
        }
    }
    searchParcoursList(searchParam) {
        let foundParcours;
        // has text to search for
        if (searchParam.searchText.length > 0) {
            var searchString = searchParam.searchText.toLowerCase();
            foundParcours = this.allParcoursList.filter(u => {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            foundParcours = this.allParcoursList;
        }
        this.parcoursList = foundParcours;
    }
    updatePOIList(pPoiList) {
        this.poiList = pPoiList;
    }
    addParcoursList(pParcoursList) {
        // parcours list could be null if first query dies
        let existingList = this.parcoursList ? this.parcoursList : [];
        if (this.poiList != null && this.poiList.length > 5) {
            const editList = pParcoursList;
            let poiLength = this.poiList.length;
            // combine poiList into parcoursList
            const poiFiller = 4;
            for (let i = 5; i < poiLength; i++) {
                const j = i - 5;
                editList.splice(poiFiller * (j + 1) + j, 0, this.poiList[i]);
            }
            this.parcoursList = existingList.concat(editList);
            this.allParcoursList = this.parcoursList;
        } else {
            this.parcoursList = existingList.concat(pParcoursList);
            this.allParcoursList = this.parcoursList;
        }

    }

    updateListRenderAmount(amount) {
        this.listRenderAmount = amount
    }

    updateParcoursRanking(eventPlayerRankingDic) {
        this.rankLoading = false;
        this.eventPlayerRanking = eventPlayerRankingDic;
        this.countTypes = Object.keys(eventPlayerRankingDic);
    }

    resetParcoursRanking() {
        this.rankLoading = true;
        this.countTypes = null;
        this.eventPlayerRanking = null;
    }
    resetParcours() {
        this.ownRating = null;
        this.ratings = null;
        this.parcours = null;
        this.parcoursArticles = []
    }
}

module.exports = alt.createStore(ParcoursStore);
