const alt = require("AltInit");
const dao = require("actdao/GroupPlaceDao");
const notificationActions = require("components/notification/NotificationActions");

/**
 * main & region
 *
 */
class GroupPlaceActions {

    initMainGroupPlaces(defaultMainGroupPlace, defaultRegionGroupPlace) {
        dao.queryGroupPlace(null)
            .then(groupPlaces => {
                this.initMainGroupPlacesSuccess(groupPlaces);
                if (defaultMainGroupPlace) {
                    this.selectMainGroupPlace(defaultMainGroupPlace.id, defaultRegionGroupPlace);
                }
            })
            .catch(error => {
                error.trace = "GPLA.mainGP";
                notificationActions.parseError(error)
            });
        return {};
    }

    loadAllGroupPlaces() {
        dao.queryGroupPlace(null)
            .then(mGroupPlaces => {
                this.updateMainGroupPlaces(mGroupPlaces);
            })
            .catch(error => {
                error.trace = "GPLA.mainGP";
                notificationActions.parseError(error)
            });
        dao.queryRegionGroupPlace(null)
            .then(rGroupPlaces => {
                this.updateAllRegionGroupPlaces(rGroupPlaces);
            })
            .catch(error => {
                error.trace = "GPLA.regGP";
                notificationActions.parseError(error)
            });
        return {};
    }

    initMainGroupPlacesSuccess(groupPlaceList) {
        return {mainGroupPlaceList: groupPlaceList};
    }

    selectMainGroupPlace(groupPlaceId, defaultRegionGroupPlace) {
        if (groupPlaceId == null) {
            this.selectMainGroupPlaceSuccess({
                regionGroupPlaceList: []
            });
            return {selectedMainGroupPlace: null};
        }
        dao.queryGroupPlace(groupPlaceId)
            .then(groupPlaces => {
                this.selectMainGroupPlaceSuccess({
                    regionGroupPlaceList: groupPlaces
                });
                if (defaultRegionGroupPlace) {
                    this.selectRegionGroupPlace(defaultRegionGroupPlace.id);
                }
            })
            .catch(error => {
                error.trace = "GPLA.regionGP";
                notificationActions.parseError(error)
            });
        return {selectedMainGroupPlace: groupPlaceId};
    }

    updateMainGroupPlaces(mainGroupPlaces) {
        return mainGroupPlaces;
    }


    updateAllRegionGroupPlaces(regionGroupPlaceList) {
       return regionGroupPlaceList;
    }

    selectMainGroupPlaceSuccess(selectedPlaces) {
        return selectedPlaces;
    }

    selectRegionGroupPlace(groupPlaceId) {
        return groupPlaceId;
    }

    reset() {
        return {};
    }

}


module.exports = alt.createActions(GroupPlaceActions);
