const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Table} = require("react-bootstrap");
const {Tournament, TournamentPrice} = require("parse/_Domain");

const I18n = require("components/widgets/I18n"),
    Utility = require("util/Utility");

const TournamentChangeLogTable = ({tChangeLogs}) => {
    return <Row>
        <Col xs={12}>
            <Table striped responsive>
                <thead>
                <tr>
                    <th><I18n code="tournament.table.column.date"/></th>
                    <th>Editor</th>
                    <th></th>
                    <th><I18n code="tournament.table.column.updatedAt"/></th>
                </tr>
                </thead>
                <tbody>
                {
                    tChangeLogs.map(changeLog => {
                        return <TournamentChangeLogEntry changeLog={changeLog}/>
                    })
                }
                </tbody>
            </Table>
        </Col>
    </Row>
}
const TournamentChangeLogEntry = ({changeLog}) => {
    let info = "";
    let name = <I18n code="training_mode_tournament"/>;
    if (changeLog.isDeregistration()) {
        info = <I18n code="tournament.register.mail.deRegistrationSubject"/>
        name = changeLog.getAdminComment();
    } else if (changeLog.isDeregistrationTUser()) {
        info = <I18n code="tournament.register.mail.deRegistrationSubject"/>
        name = changeLog.getTournamentUserID().getFullName();
    } else if (changeLog.isPayChange()) {
        if (changeLog.getNewValue() === TournamentPrice.prototype.payStatus.inactive) {
            info = <I18n code="tournament.paystatus.inactive"/>
        } else {
            info = <I18n code="tournament.paystatus.active"/>
        }
    } else if (changeLog.isTournamentStatusChange()) {
        if (changeLog.getNewValue() === Tournament.prototype.status.running) {
            info = <I18n code="tournament.manager.startTournament"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.status.published) {
            info = <I18n code="tournament.manager.prepareTournament"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.status.closed) {
                info = <I18n code="tournament.manager.closeTournament"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.status.deleted) {
            info = <I18n code="status.D"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.status.canceled) {
            info = <I18n code="tournament.status.canceled"/>
        } else {
            info = changeLog.getNewValue();
        }
    } else if (changeLog.isTournamentRegStatusChange()) {
        if (changeLog.getNewValue() === Tournament.prototype.registrationStatus.open) {
            info = <I18n code="tournament.status.register.open"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.registrationStatus.full) {
            info = <I18n code="tournament.status.register.full"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.registrationStatus.closed) {
            info = <I18n code="tournament.status.register.closed"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.registrationStatus.published) {
            info = <I18n code="tournament.status.published"/>
        } else if (changeLog.getNewValue() === Tournament.prototype.registrationStatus.activatedCupMaster) {
            info = "acceptCupMaster"
        } else {
            info = changeLog.getNewValue();
        }
    } else {
        info = <React.Fragment>{changeLog.getColumnName()}:{changeLog.getOldValue()}->{changeLog.getNewValue()}</React.Fragment>
    }
    return <tr>
        <td>{Utility.getReadableDate( changeLog.getCreatedAt())} {Utility.getReadableTime(changeLog.getCreatedAt())}</td>
        <td>{changeLog.getEditorName()}</td>
        <td>{name}</td>
        <td>{info}</td>
    </tr>
}
module.exports = TournamentChangeLogTable;
