/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button, ListGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    EntryRemovable  = require("components/form/EntryRemovable"),
    ValidInput = require("components/form/ValidInput");

class TypeListDefinitionModal extends React.Component {
    constructor(props) {
        super(props);
        this.refGroupName = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.clickedOK = this.clickedOK.bind(this);
        this.clickedAdd = this.clickedAdd.bind(this);
        this.clickedRemove = this.clickedRemove.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            error: null
        }
    }
    open() {
        this.setState({
            showModal: true
        });
    }
    close() {
        this.setState({submitDisabled: false, showModal: false});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    clickedOK() {
        this.close();
    }
    clickedAdd(e) {
        e.preventDefault();
        this.setState({submitDisabled: true});
        let type = this.refGroupName.current.getValue().trim();
        if (type != null && type.length > 0) {
            // add group
            this.props.addTypeFunction(type);
        }
        this.setState({submitDisabled: false});
    }
    clickedRemove(type) {
        this.props.removeTypeFunction(type);
    }
    render() {
        const {list, header, desc, inputText} = this.props;
        const typeList = list == null ? [] : list;
        let saveButton = (<Button disabled={this.state.submitDisabled} type="submit"><FontAwesome icon="save"/></Button>);
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code={header}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <Row>
                                <Col xs={12}>
                                    <form action="#" onSubmit={this.clickedAdd}>
                                        <ValidInput ref={this.refGroupName}
                                                    valid={{maxLength: 20, check:['isRequired']}}
                                                    placeholder={inputText}
                                                    buttonAfter={saveButton}/>
                                    </form>
                                    {this.state.error == null ? null : <Alert bsStyle="danger">{this.state.error}</Alert>}
                                </Col>
                                <Col xs={12}>
                                    <h4><I18n code={"modal.typelist.subHeader"}/></h4>
                                    <ListGroup>
                                        {
                                            typeList.map(type => {
                                                    return <EntryRemovable key={"EDT" + type} group={type} onRemove={this.clickedRemove}/>
                                                })
                                    }</ListGroup>
                                    {
                                        desc ? <p>{desc}</p> : null
                                    }
                                </Col>
                            </Row> : null
                    }
                    <br/><br/>
                    <Row>
                        <Col xs={12}>
                            <Button onClick={this.clickedOK} block bsStyle="success"><I18n code={"modal.button.close"}/></Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }
}
TypeListDefinitionModal.propTypes = {
    header: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    addTypeFunction: PropTypes.func.isRequired,
    removeTypeFunction: PropTypes.func.isRequired,
    desc: PropTypes.string,
    list:PropTypes.array,
};
module.exports = TypeListDefinitionModal;