const React = require("react"),
    PropTypes = require('prop-types'),
    messages = require("i18n/messages");
const {Row, Col, Alert, Button} = require("react-bootstrap");
const ValidInput = require("components/form/ValidInput"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome");

const {Address} = require("parse/_Domain");

const transactionActions = require("actions/TransactionActions");

class PaymentAddressForm extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refStreet = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();
        this.refPhone = React.createRef();
        this.refSubmitButton = React.createRef();
        
        this.getAddressDefault = this.getAddressDefault.bind(this);
        this.getMail = this.getMail.bind(this);
        this.changeState = this.changeState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.restore = this.restore.bind(this);
        this.setFormValues = this.setFormValues.bind(this);
        this.valid = this.valid.bind(this);
        this.validationError = this.validationError.bind(this);
        this.saveAddress = this.saveAddress.bind(this);
        this.buildTransactionAddress = this.buildTransactionAddress.bind(this);

        this.state = {
            changed: false,
            error: null
        }
    }
    componentDidMount() {
        let email = this.getMail()
        const mailReg = /^\S+@\S+\.\S+$/;
        if (email == null || !email.match(mailReg)) {
            this.setState({error: messages.get("form.valid.not.email"), changed: true})
            if (this.props.changedCallback) {
                this.props.changedCallback(true)
            }
        }
    }
    getAddressDefault(col) {
        if (this.props.userDetail) {
            const address = this.props.userDetail.getAddressID();
            if (address) {
                return address.get(col);
            }
        }
        return null;
    }
    getMail() {
        if (this.props.user) {
            return this.props.user.get('email');
        } else if (this.props.userDetail) {
            const address = this.props.userDetail.getAddressID();
            if (address) {
                return address.getMail();
            }
        }
        return null;
    }
    changeState(changed) {
        if (this.props.user) {
            if (this.props.changedCallback) {
                this.props.changedCallback(changed)
            }
        }

        this.setState({changed: changed});
    }
    handleChange(event, col) {
        if (!this.state.changed) {
            this.changeState(true);
        }
        console.log("changed " + col + " from " + event.target.defaultValue + " to " + event.target.value);
    }
    restore() {
        this.refSubmitButton.current.resetLoading();
        this.changeState(false);
        this.setState({error: null});
        this.refMail.current.setValue(this.getMail());
        this.refName.current.setValue(this.getAddressDefault(Address.prototype.col.name));
        this.refSurname.current.setValue(this.getAddressDefault(Address.prototype.col.surname));
        this.refStreet.current.setValue(this.getAddressDefault(Address.prototype.col.street));
        this.refZip.current.setValue(this.getAddressDefault(Address.prototype.col.zip));
        this.refPlace.current.setValue(this.getAddressDefault(Address.prototype.col.place));
        if (this.refPhone && this.refPhone.current) {
            this.refPhone.current.setValue(this.getAddressDefault(Address.prototype.col.phone));
        }
    }
    setFormValues(addressObj) {
        this.refMail.current.setValue(addressObj["mail"]);
        this.refName.current.setValue(addressObj[Address.prototype.col.name]);
        this.refSurname.current.setValue(addressObj[Address.prototype.col.surname]);
        this.refStreet.current.setValue(addressObj[Address.prototype.col.street]);
        this.refZip.current.setValue(addressObj[Address.prototype.col.zip]);
        this.refPlace.current.setValue(addressObj[Address.prototype.col.place]);
        if (this.refPhone && this.refPhone.current) {
            this.refPhone.current.setValue(addressObj[Address.prototype.col.phone]);
        }
    }
    valid() {
        let valid = true;
        let required = [
            this.refMail.current.getValue(),
            this.refName.current.getValue(),
            this.refSurname.current.getValue(),
            this.refStreet.current.getValue(),
            this.refZip.current.getValue(),
            this.refPlace.current.getValue(),
        ];
        if (this.refPhone && this.refPhone.current) {
            required.push(this.refPhone.current.getValue());
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.validationError({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    validationError(stateError) {
        if (this.refSubmitButton.current) {
            this.refSubmitButton.current.resetLoading();
        }
        this.setState(stateError);
    }
    saveAddress(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({error: null});
        transactionActions.createEmptyUserDetail(this.props.user, this.props.userDetail, (cbUserDetail) => {
            // handle userDetail
            let address = cbUserDetail.getAddressID();
            address.fillObject(this.buildTransactionAddress());
            // SAVE ONLY
            // if address is changed - unset HP Customer ID
            address.unset(Address.prototype.col.hpIdentUniqueID)
            transactionActions.saveUserDetail(cbUserDetail, (scbUserDetail) => {
                // save success
                this.validationError({error: null});
                this.changeState(false);
            })
        }, (cbError) => {
            this.validationError({error: cbError.message});
        });
    }
    buildTransactionAddress() {
        const address = {};
        address[Address.prototype.col.name] = this.refName.current.getValue().trim();
        address[Address.prototype.col.surname] = this.refSurname.current.getValue().trim();
        address[Address.prototype.col.street] = this.refStreet.current.getValue().trim();
        address[Address.prototype.col.zip] = this.refZip.current.getValue().toString().trim();
        address[Address.prototype.col.place] = this.refPlace.current.getValue().trim();
        address[Address.prototype.col.mail] = this.refMail.current.getValue().trim();
        if (this.refPhone && this.refPhone.current) {
            address[Address.prototype.col.phone] = this.refPhone.current.getValue().trim();
        }
        let hpIdentUniqueID = this.getAddressDefault(Address.prototype.col.hpIdentUniqueID);
        if (hpIdentUniqueID) {
            address[Address.prototype.col.hpIdentUniqueID] = hpIdentUniqueID
        }
        return address;
    }
    render() {
        const {showPhone} = this.props;
        var validateNow = this.state.error && this.state.error.length > 0;
        return (
            <form>
                <Row>
                    <Col sm={6}>
                        <ValidInput ref={this.refName}
                                    autoComplete="fname"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.name")}
                                    addonBefore={showPhone ? <FontAwesome icon="hashtag">1</FontAwesome> : null}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.name)}
                                    default={this.getAddressDefault(Address.prototype.col.name)}/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refSurname}
                                    autoComplete="lname"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.surname")}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.surname)}
                                    default={this.getAddressDefault(Address.prototype.col.surname)}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refStreet}
                                    autoComplete="street-address"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.street")}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.street)}
                                    default={this.getAddressDefault(Address.prototype.col.street)}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <ValidInput ref={this.refZip}
                                    autoComplete="postal-code"
                                    valid={{maxLength: 6, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.zip")}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.zip)}
                                    default={this.getAddressDefault(Address.prototype.col.zip)}/>
                    </Col>
                    <Col xs={8}>
                        <ValidInput ref={this.refPlace}
                                    autoComplete="locality"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.place")}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.place)}
                                    default={this.getAddressDefault(Address.prototype.col.place)}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refMail}
                                    autoComplete="email"
                                    valid={{maxLength: 50, check:['isRequired', 'isMail']}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("address.mail")}
                                    addonBefore={<FontAwesome icon="envelope" />}
                                    onChange={(event) => this.handleChange(event, Address.prototype.col.mail)}
                                    default={this.getMail()}/>
                    </Col>
                    {
                        showPhone ? <Col sm={12}>
                            <ValidInput ref={this.refPhone}
                                        autoComplete="tel"
                                        valid={{maxLength: 50, check:['isRequired','isPhone']}}
                                        placeholder={messages.get("address.phone")}
                                        addonBefore={<FontAwesome icon="phone"/>}
                                        onChange={(event) => this.handleChange(event, Address.prototype.col.phone)}
                                        default={this.getAddressDefault(Address.prototype.col.phone)}/>
                        </Col> : null
                    }

                </Row>
                {
                    this.state.changed && this.props.user ?
                        <Row>
                            <Col sm={12}>
                                {this.state.error ?
                                    <Alert bsStyle="warning">{this.state.error}</Alert>
                                    : ""}
                            </Col>
                            <Col sm={6}>
                                <Button block bsStyle="primary"
                                    onClick={this.restore}>
                                    {messages.get("modal.button.restore")}
                                    </Button>
                            </Col>
                            <Col sm={6}>
                                <SubmitButton ref={this.refSubmitButton} block
                                              onClick={this.saveAddress}
                                              text={messages.get("modal.button.save")}/>
                            </Col>

                        </Row> : null
                }

            </form>)
    }
}
PaymentAddressForm.propTypes = {
    user:PropTypes.object,
    userDetail:PropTypes.object,
    changedCallback:PropTypes.func,
    showPhone: PropTypes.bool.isRequired
};
module.exports = PaymentAddressForm;
