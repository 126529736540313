const alt = require("AltInit");
const tournamentResultActions = require("actions/TournamentResultActions");

class TournamentResultStore {
    constructor() {
        this.displayName = "TournamentResultStore";
        this.displayState = "S";    // S.. tournament all, SF.. finals only, G.. Groups only, , GF.. Groups finals only
        this.tournamentRoundMap = {
            qualify:[], finale:[], group:[], extra:[]
        }
        this.tournamentUserResult = null;
        this.params = {
            needFinalAdd: false,
            optionString: null,
            tConfigCombinedAge: null
        };
        this.loading = true;
        this.bindListeners({
            updateTournamentUserResult: tournamentResultActions.updateTournamentUserResult,
            updateTournamentRoundMap : tournamentResultActions.updateTournamentRoundMap,
            refreshCompetitorTUser : tournamentResultActions.refreshCompetitorTUser,
            resetTournamentResults: tournamentResultActions.resetTournamentResults,
            updateParams: tournamentResultActions.updateParams,
            updateDisplayState: tournamentResultActions.updateDisplayState
        });
    }
    needFinalAdd() {
        let bNeedFinalAdd = false;
        this.tournamentRoundMap.finale.map(tRound => {
            if (tRound.isFinalAdd()) {
                bNeedFinalAdd = true;
            }
        });
        return bNeedFinalAdd;
    }
    updateTournamentUserResult(tournamentUsers) {
        this.loading = false;
        this.tournamentUserResult = tournamentUsers;
    }
    updateTournamentRoundMap(tournamentRoundMap) {
        this.tournamentRoundMap = tournamentRoundMap;
    }
    updateDisplayState(updObject) {
        this.displayState = updObject.displayState;
        this.loading = updObject.loading;
    }

    refreshCompetitorTUser() {
        console.log("refresh result");
    }
    updateParams(params) {
        this.loading = true;
        this.params = params
    }

    resetTournamentResults() {
        this.tournamentUserResult = null;
        this.tournamentRoundMap.qualify = [];
        this.tournamentRoundMap.finale = [];
        this.tournamentRoundMap.group = [];
        this.tournamentRoundMap.extra = [];
        this.loading = true;
    }
}
module.exports = alt.createStore(TournamentResultStore);