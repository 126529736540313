const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Table, PageHeader, Button, Label} = require("react-bootstrap");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const I18n = require("components/widgets/I18n"),
    EventMapFrame = require("components/map/EventMapFrame"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    Utility = require("util/Utility");

const EventPlayersTable = require("components/profile/EventPlayersTable"),
    EventPlayerBAProfileChange = require("components/profile/EventPlayerBAProfileChange"),
    EventDurationChange = require("components/profile/EventDurationChange");

const userStore = require("stores/UserStore"),
    eventDetailStore = require("stores/EventDetailStore"),
    eventDetailActions = require("actions/EventDetailActions"),
    notificationActions = require("components/notification/NotificationActions");

const messages = require("i18n/messages");

class EventDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            eventDetailStore: eventDetailStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.profile");
        userStore.listen(this.handleUserChange);
        eventDetailStore.listen(this.handleEventChange);
        eventDetailActions.queryEvent(this.props.eventID);
        eventDetailActions.queryTStat(this.props.tstat);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        eventDetailStore.unlisten(this.handleEventChange);
    }
    handleEventChange(state) {
        this.setState({eventDetailStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    render() {
        const {eventDetailStore, userStore} = this.state
        if (eventDetailStore.loading === true || eventDetailStore.eventPlayers == null) {
            return (<Grid><Loading/></Grid>)
        } else if (eventDetailStore.loading === false && eventDetailStore.eventPlayers.length <= 0){
            return <EventNoPlayers event={eventDetailStore.event} adminPage={eventDetailStore.isAdmin}/>;
        } else if (eventDetailStore.loading === false && eventDetailStore.eventPlayers.length > 0) {
            return (<EventDetail event={eventDetailStore.event}
                                 eventPlayers={eventDetailStore.eventPlayers}
                                 user={userStore.user}
                                 userFeatures={userStore.userFeatures}
                                 eventTrack={eventDetailStore.eventTrack}
                                 tuGroupLives={eventDetailStore.tuGroupLives}
                                 eventResults={eventDetailStore.eventResults}
                                 eventResultsEvpID={eventDetailStore.eventResultsEvpID}
                                 eventComments={eventDetailStore.eventComments}
                                 adminPage={eventDetailStore.isAdmin}/>);
        } else {
            console.log("WTF - never should be here");
            return null;
        }
    }
}
EventDetailPage.propTypes = {
    eventID: PropTypes.string.isRequired,
    tstat: PropTypes.string
};
const EventNoPlayers = ({event, adminPage}) => {
    if (event != null) {
        return <Grid>
            <EventHeader event={event} adminPage={adminPage}/>
            <hr/>
            <Row>
                <Col xs={12}>
                    <Alert bsStyle="warning"><I18n code="list.entry.empty"/></Alert>
                </Col>
            </Row>
        </Grid>
    }
    return (<Grid>
        <Row>
            <Col lg={12}>
                <Breadcrumbs>
                    <Breadcrumb link="/" code="header.nav.home"/>
                    <Breadcrumb link="/profile" code="header.nav.profile"/>
                </Breadcrumbs>
            </Col>
        </Row>
        <Alert bsStyle="warning"><I18n code="profile.events.empty"/></Alert>
    </Grid>);
}
const EventDetail = ({user, userFeatures, event, eventPlayers, eventResults, eventResultsEvpID, eventTrack, tuGroupLives, eventComments, adminPage}) => {
    function getOwnEventPlayer() {
        if (user && eventPlayers) {
            for (let i = 0; i < eventPlayers.length; i++) {
                let evPlayer = eventPlayers[i];
                if (evPlayer.getPlayerEmail().toLowerCase() == user.get("email").toLowerCase()) {
                    return evPlayer;
                }
            }
        }
        return null;
    }
    function filterComments() {
        if (eventComments) {
            return eventComments.filter(comment => {
                return adminPage || comment.isCommentFromUser(user);
            })
        }
        return [];
    }
    function saveEventPlayer(eventPlayer) {
        eventDetailActions.saveEventPlayer(eventPlayer);
    }
    const ownEventPlayer = getOwnEventPlayer();
    const userEventComments = filterComments();
    let allowEdit = event.isEditor(user) && event.isNormalEvent();
    return (<Grid>
        <EventHeader user={user} event={event} adminPage={adminPage} ownEventPlayer={ownEventPlayer} saveEventPlayer={saveEventPlayer}/>
        {
            eventTrack ? <EventTrack event={event} eventTrack={eventTrack} isUserInEvent={ownEventPlayer != null}
                                     hasGPSFeature={userFeatures.isGPSFeatureActive()}
                                     eventPlayers={eventPlayers} eventResults={eventResults}
                                     user={user} adminPage={adminPage}/> : null
        }
        <hr/>
        <Row>
            <Col xs={12}>
                <EventPlayersTable eventPlayers={eventPlayers} allowEdit={allowEdit}
                                   ownEventPlayer={ownEventPlayer} adminPage={adminPage}/>
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col xs={12}>
                <h3>{messages.get("profile.event.targetDetails")}</h3>
                <EventPlayersDetailsTable
                    userEventComments={userEventComments}
                    tuGroupLives={tuGroupLives}
                    eventPlayers={eventPlayers}
                    eventResults={eventResults}
                    eventResultsEvpID={eventResultsEvpID}/>
            </Col>
        </Row>
        </Grid>)
};
EventDetail.propTypes = {
    event:PropTypes.object.isRequired,
    eventPlayers:PropTypes.array.isRequired,
    eventResults:PropTypes.object.isRequired,
    eventResultsEvpID:PropTypes.array.isRequired,
    adminPage:PropTypes.bool.isRequired,
    user:PropTypes.object.isRequired,
    userFeatures: PropTypes.object.isRequired,
    eventTrack: PropTypes.object,
    eventComments: PropTypes.array
};
const EventHeader = ({user, event, saveEventPlayer, ownEventPlayer, adminPage}) => {
    const date = new Date(event.getInsstmp());
    return <React.Fragment>
        <Row>
            <Col lg={12}>
                <Breadcrumbs>
                    <Breadcrumb link="/" code="header.nav.home"/>
                    <Breadcrumb link="/profile" code="header.nav.profile"/>
                    <Breadcrumb link={"/profile/events/"+ date.getFullYear()} code="header.nav.events"/>
                    <Breadcrumb code={event.getName()} active/>
                </Breadcrumbs>
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                <PageHeader>{event.getName()}</PageHeader>
                {adminPage === true ? <p>{event.id + " / " + event.getInsstmp() + " / " + event.getParcoursOnlineID()  + " / Type:" + event.getType()}</p>: null}
                {adminPage === true && event.isTournament() ? <p>TUGroup: {event.getTournamentGroupID().id} TRound: {event.getTournamentRoundID().id}</p> : null}
                <h4><FontAwesome icon="calendar-alt">{event.getDate()}</FontAwesome>&nbsp;&nbsp;
                    {
                        event.getDuration() > 0 ? <FontAwesome icon="clock">{Utility.convertMinutesToHours(event.getDuration())}&nbsp;&nbsp;</FontAwesome> : null
                    }
                    <Label bsStyle="info"><I18n code={event.getTypeTranslationCode()}/></Label>
                </h4>
            </Col>
            {
                event.getDuration() > 0 || ownEventPlayer == null ? null : <EventDurationChange event={event}/>
            }
            {
                ownEventPlayer != null && ownEventPlayer.getBAProfileID() == null && user != null ?
                    <EventPlayerBAProfileChange user={user} eventPlayer={ownEventPlayer} onAfterChange={saveEventPlayer}/>: null
            }
        </Row>
    </React.Fragment>
}
const EventPlayersDetailsTable = ({eventResultsEvpID, eventResults, eventPlayers, tuGroupLives, userEventComments}) => {
    function getMaxTargetAmount(evPlayer) {
        let plMaxTarget = 0;
        let evResult = eventResults[evPlayer.id]
        if (evResult != null && evResult.length > 0) {
            // check if first entry is targetNumber = 1
            if (evResult[0].getTargetIndex() === 1) {
                // round started with first target
                // get maxTargets from last targetIndex of eventDetailResults
                return evResult[evResult.length-1].getTargetIndex();
            } else {
                // round does not start with target 1 - iterate
                for (let i = 0; i <evResult.length; i++) {
                    if (plMaxTarget < evResult[i].getTargetIndex()) {
                        plMaxTarget = evResult[i].getTargetIndex();
                    } else if (evResult[i].getTargetIndex() === 1) {
                        // reached first entry
                        return plMaxTarget;
                    }
                }
            }
        }
        return plMaxTarget;
    }
    let maxTargets = 0;
    const arr2Target = {};
    const evpId2tUserId = {};
    return (
            <Table striped condensed hover responsive>
                <thead>
                <tr>
                    <th className="col-sm-2">{messages.get("parcours.targets.header")}</th>
                    {
                        userEventComments.length > 0 ? <th className="col-sm-2">{messages.get("club.detail.comment")}</th> : null
                    }
                    {
                        eventPlayers == null ? "" :
                            eventPlayers.map(function (evPlayer) {
                                if (evPlayer.getTournamentUserID() != null) {
                                    evpId2tUserId[evPlayer.id] = evPlayer.getTournamentUserID().id
                                }
                                if (!evPlayer.isDeleted()) {
                                    let arrow2Target = evPlayer.getAmountArrowToTarget();
                                    let plMaxTarget = getMaxTargetAmount(evPlayer);
                                    arr2Target[evPlayer.id] = arrow2Target;
                                    if (maxTargets < plMaxTarget) {
                                        maxTargets = plMaxTarget;
                                    }
                                    return <th style={{"textAlign": "center"}} key={"hdr_"+evPlayer.id}>{evPlayer.getPlayerName()}</th>
                                }
                            })
                    }
                </tr>
                </thead>
                <EventPlayersTableBody lines={maxTargets} arr2Target={arr2Target} tuGroupLives={tuGroupLives} evpId2tUserId={evpId2tUserId}
                                       eventResultsEvpID={eventResultsEvpID} eventResults={eventResults} userEventComments={userEventComments} />
            </Table>
    )
};
EventPlayersDetailsTable.propTypes = {
    eventPlayers:PropTypes.array.isRequired,
    eventResults:PropTypes.object.isRequired,
    eventResultsEvpID:PropTypes.array.isRequired,
    userEventComments: PropTypes.array.isRequired
};
const EventPlayersTableBody = ({lines, arr2Target, eventResultsEvpID, evpId2tUserId, eventResults, tuGroupLives, userEventComments}) => {
    const evLines = new Array(lines);
    for (let i = 0; i < lines; i++) {
        evLines[i] = (i+1);
    }
    return (<tbody>
        {
            evLines.map(index => {
                return <EventPlayersDetailRow key={index} index={index} arr2Target={arr2Target} tuGroupLives={tuGroupLives} evpId2tUserId={evpId2tUserId}
                                              eventResultsEvpID={eventResultsEvpID} eventResults={eventResults} userEventComments={userEventComments}/>
            })
        }

        </tbody>
    )
};
EventPlayersTableBody.propTypes = {
    lines:PropTypes.number.isRequired,
    eventResultsEvpID:PropTypes.array.isRequired,
    arr2Target:PropTypes.any.isRequired,
    eventResults:PropTypes.any.isRequired,
    userEventComments: PropTypes.array.isRequired
};
const EventPlayersDetailRow = ({index, eventResultsEvpID, evpId2tUserId, eventResults, arr2Target, userEventComments, tuGroupLives}) => {
    function getFittingEventDetailResult(evpResults, arrow2Target, cIndex, arrowNr) {
        // first access by calculated index and check for targetIndex and (arrowNr or nullScore)
        let fEvpResult = null
        if (evpResults.length >= cIndex) {
            fEvpResult = evpResults[cIndex]; // first fitting result
            if (fEvpResult && arrow2Target === 1 && fEvpResult.getTargetIndex() === index) {
                return fEvpResult
            } else if (fEvpResult && fEvpResult.getTargetIndex() === index
                && (fEvpResult.getArrowNr() === arrowNr || fEvpResult.isNullScore())) {
                return fEvpResult
            }
        }
        // due to skipped target cIndex is too high for arr2Target >= 2
        // go back the list until targetIndex and arrowNr fits
        let runner = cIndex < evpResults.length ? cIndex : evpResults.length
        for (let i = runner; i > 0; i--) {
            const evpResult = evpResults[i];
            if (evpResult && arrow2Target === 1 && evpResult.getTargetIndex() === index) {
                return evpResult
            } else if (evpResult && evpResult.getTargetIndex() === index
                && (evpResult.getArrowNr() === arrowNr || evpResult.isNullScore())) {
                return evpResult
            }
        }
        // return first fitting result for legacy reasons
        return fEvpResult;
    }
    function getTargetIndexName() {
        if (eventResultsEvpID.length > 1) {
            const evpArr2Target = arr2Target[eventResultsEvpID[1]];
            const evpResults =  eventResults[eventResultsEvpID[1]];
            if (evpResults) {
                const cIndex = evpArr2Target * index;
                // cIndex - evpArr2Target should be the first eventDetail for the target
                const evpResult = getFittingEventDetailResult(evpResults, evpArr2Target,cIndex - evpArr2Target, 1)
                if (evpResult) {
                    return index + " " + evpResult.getTargetName()
                }
            }
        }
        return index
    }
    function getTargetComment() {
        for (let i = 0; i <userEventComments.length; i++) {
            let eventComment = userEventComments[i];
            if (eventComment.getTargetIndex() === index) {
                return eventComment.commentEmojiText()
            }
        }
        return ""
    }
    function getTUGroupLive() {
        for (let i = 0; i < tuGroupLives.length;  i++) {
            if (index === tuGroupLives[i].getActTargetPosition()) {
                // console.log("Found ", targetIndex, tuGroupLives[i].getActResult() )
                return tuGroupLives[i];
            }
        }
        return null;
    }
    function getCellPoints(evpPoints, evPlayerID) {
        if (tuGroupLive && evpId2tUserId[evPlayerID] != null) {
            let livePoints = tuGroupLive.getTournamentUserPoints(evpId2tUserId[evPlayerID]);
            return <span>{evpPoints.join("/") + " (" + livePoints + ")"}</span>
            // console.log("Points for: ", evpId2tUserId[evPlayerID], tuGroupLive.getTournamentUserPoints(evpId2tUserId[evPlayerID]))
        }
        return evpPoints.join("/")
    }
    const details = [];
    let tuGroupLive = null;
    eventResultsEvpID.map(evPlayerID => {
        if (evPlayerID === "trg") {
            details.push(<td style={{"textAlign": "left"}}>{getTargetIndexName()}</td>);
            if (userEventComments.length > 0) {
                details.push(<td style={{"textAlign": "left"}}>{getTargetComment()}</td>);
            }
        } else {
            const arrow2Target = arr2Target[evPlayerID];
            const evpResults = eventResults[evPlayerID];
            if (tuGroupLive == null && tuGroupLives != null) {
                tuGroupLive = getTUGroupLive()
            }
            let evpPoints = [];
            if (evpResults) {
                for (let c = 0; c < arrow2Target; c++) {
                    const cIndex = index * arrow2Target - arrow2Target + c;
                    const evpDetail = getFittingEventDetailResult(evpResults, arrow2Target, cIndex, c + 1);
                    if (evpDetail != null && !evpDetail.isNullScore()) {
                        // console.log("Detail for " + evPlayerID, evpDetail.getPoints(), evpDetail.getTargetIndex(), evpDetail.getTargetName())
                        let points = evpDetail.getPoints();
                        if (points > 9) {
                            evpPoints.push(points);
                        } else {
                            evpPoints.push(" " + points + " ");
                        }
                    } else {
                        // add line for each arrow2Target on null score
                        for (let iNS = 0; iNS < arrow2Target; iNS ++) {
                            evpPoints.push("-");
                        }
                        break;
                    }
                }
            } else {
                evpPoints.push("x/x");
            }
            details.push(<td style={{"textAlign": "center"}}>{getCellPoints(evpPoints, evPlayerID)}</td>);
        }
    });

    return (
        <tr>
            {details}
        </tr>
    )
};
EventPlayersDetailRow.propTypes = {
    index:PropTypes.number.isRequired,
    eventResultsEvpID:PropTypes.array.isRequired,
    arr2Target:PropTypes.any.isRequired,
    eventResults:PropTypes.any.isRequired,
    userEventComments: PropTypes.array.isRequired
};
const EventTrack = ({ user, isUserInEvent, hasGPSFeature, event, eventResults, eventPlayers, eventTrack, adminPage} ) => {
    function generateGPX() {
        if (user) {
            // only allow members or logged in admin
            if (isUserInEvent || adminPage) {
                eventDetailActions.generateGPX(event, eventTrack, user)
            } else {
                notificationActions.info("profile.event.notInEvent");
            }
        } else {
            notificationActions.info("tournament.register.description.noaccount")
        }
    }
    function reduceTrack() {
        eventDetailActions.reduceTrack(eventTrack);
    }
    return (<React.Fragment>
            {
                hasGPSFeature ? null :
                    <Alert bsStyle="info">No Abo</Alert>
            }
            {
                adminPage ? <Alert bsStyle="info">
                    Track({eventTrack.id}) Reduction(L{eventTrack.getTrackReworkCounter()}) <Button onClick={reduceTrack}>Reduce Track</Button>
                </Alert> :null
            }
            <EventMapFrame eventTrack={eventTrack} eventPlayers={eventPlayers} eventResults={eventResults}
                           showTrackTable={isUserInEvent || adminPage} exportClicked={generateGPX}/>
        </React.Fragment>
    )
};
EventTrack.propTypes = {
    eventTrack:PropTypes.object.isRequired,
    event:PropTypes.object.isRequired,
    eventResults:PropTypes.object.isRequired,
    eventPlayers:PropTypes.array.isRequired,
    user:PropTypes.object.isRequired,
    adminPage:PropTypes.bool.isRequired,
    isUserInEvent: PropTypes.bool.isRequired,
    hasGPSFeature: PropTypes.bool.isRequired
};
module.exports = EventDetailPage;
