const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, FormControl, ControlLabel} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const TournamentConfigAgeSelect = ({inputRef, tournament, label, defaultValue, value, preSelect,  disabled, selectCallback}) =>{
    const changeTournamentConfig = (event) => {
        const configAgeTypes = tournament.getTournamentConfigAge();
        if (configAgeTypes) {
            for (let i = 0 ; i < configAgeTypes.length; i++) {
                if (configAgeTypes[i].id == event.target.value) {
                    selectCallback(configAgeTypes[i]);
                    return;
                }
            }
            selectCallback(null);
        }
    };
    const addTournamentConfigOptions = () => {
        const configTypes = tournament.getTournamentConfigAge();
        if (!configTypes) {
            return (<option>Ups, keine Klassen gefunden</option>);
        } else {
            return configTypes.map(configType => {
                return <option key={configType.id} id={configType.id} value={configType.id}>{configType.getCodeName()}</option>;
            })
        }
    };
    return (<FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <InputGroup>
            <InputGroup.Addon><FontAwesome icon="book"/></InputGroup.Addon>
            <FormControl ref={inputRef} componentClass="select" placeholder="select" disabled={disabled}
                         defaultValue={defaultValue} value={value}
                         onChange={changeTournamentConfig}>
                {
                    preSelect == false ? <option value={null}>{messages.get("modal.license.chooseAge")}</option> : null
                }
                {addTournamentConfigOptions()}
            </FormControl>
        </InputGroup>
    </FormGroup>)
};
TournamentConfigAgeSelect.propTypes = {
    inputRef: PropTypes.object,
    tournament: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    preSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentConfigAgeSelect;
