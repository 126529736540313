const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Panel, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ImageBanner = require("components/widgets/ImageBanner"),
    TournamentUserRegistration = require("components/tournamentregister/TournamentUserRegistration");

const tournamentStore = require("stores/TournamentStore"),
    tournamentActions = require("actions/TournamentActions"),
    tournamentUserStore = require("stores/TournamentUserStore"),
    notificationActions = require("components/notification/NotificationActions"),
    userStore = require("stores/UserStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentRegistrationPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);

        this.state = {
            tournamentStore: tournamentStore.getState(),
            tournamentUserStore: tournamentUserStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        tournamentStore.listen(this.handleTournamentChange);
        tournamentUserStore.listen(this.handleTournamentUserChange);
        notificationActions.reset();
        tournamentActions.showProgress();
        tournamentActions.showDetails(this.props.tournamentId);
    }
    componentWillUnmount() {
        tournamentActions.resetTournamentDetails();
        userStore.unlisten(this.handleUserChange);
        tournamentStore.unlisten(this.handleTournamentChange);
        tournamentUserStore.unlisten(this.handleTournamentUserChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
        if (state.tournamentDetail) {
            document.title = state.tournamentDetail.getName();
        }
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserStore: state});
    }
    render() {
        if (this.state.tournamentStore.tournamentDetail == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}><Loading/></Col>
                    </Row>
                </Grid>)
        } else if (this.state.tournamentStore.tournamentDetail != null && this.state.tournamentStore.tournamentDetail.isDeleted()) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>)
        }
        return (<TournamentDetails
            user={this.state.userStore.user}
            tournament={this.state.tournamentStore.tournamentDetail}
            tournamentUsers={this.state.tournamentUserStore.tournamentUsers}
            allTournamentUsers={this.state.tournamentUserStore.allTournamentUsers}
            tournamentUserTeams={this.state.tournamentUserStore.tournamentUserTeams}
            allTournamentUserTeams={this.state.tournamentUserStore.allTournamentUserTeams}
            tournamentUserSearchParams={this.state.tournamentUserStore.searchParams}/>)
    }
}

const TournamentDetails = ({tournament, allTournamentUsers, user}) => {
    const isPairRegistration = tournament.isTypePairRegistration();
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                            <Breadcrumb link={"/tournament/" + tournament.id + "/"} code={tournament.getName()}/>
                            <Breadcrumb link="" code="tournament.button.register" active/>
                        </Breadcrumbs>
                </Col>
                <Col lg={12}>
                    <PageHeader>{tournament.getName()}</PageHeader>
                    <h4><FontAwesome icon="calendar-alt"/>{tournament.getTournamentDate()}</h4>
                </Col>
            </Row>
            <Row>
                <ImageBanner imageObject={tournament.getBannerImageID()}/>
            </Row>
            <br/><br/>
           {tournament.isRegistrationOpened() ?
                <Row>
                    <Col lg={12}>
                        {
                            isPairRegistration ? null :
                            <Panel>
                                <Panel.Heading>
                                    <Row>
                                        <Col xs={12}>
                                            <h3><I18n code="tournament.button.register"/></h3>
                                        </Col>
                                    </Row>
                                </Panel.Heading>
                                <Panel.Body>
                                    <TournamentUserRegistration user={user}
                                                                tournament={tournament}
                                                                allTournamentUsers={allTournamentUsers}/>
                                </Panel.Body>
                            </Panel>

                        }
                    </Col>
                </Row>
                : "TODO Anmeldung geschlossen"
            }
        </Grid>)
};
TournamentDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object,
};
module.exports = TournamentRegistrationPage;
