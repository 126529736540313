/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput");
var messages = require("i18n/messages");


var {Images} = require("parse/_Domain");

class ImageBackgroundParamsModal extends React.Component {
    constructor(props) {
        super(props);
        this.refBgSize = React.createRef();
        this.refBgRepeat = React.createRef();
        this.refBgPosition = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.remove = this.remove.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            error: null
        }
    }
    open() {
        this.setState({
            showModal: true,
        });
    }
    close() {
        this.setState({submitDisabled: false,showModal: false, error:null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    valid() {
        var valid = true;
        var required = [
            this.refBgSize.current.getValue().trim(),
            this.refBgRepeat.current.getValue().trim(),
            this.refBgPosition.current.getValue().trim(),
        ];
        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        var params = {};
        params[Images.prototype.bgParams.backgroundSize] = this.refBgSize.current.getValue().trim();
        params[Images.prototype.bgParams.backgroundRepeat] = this.refBgRepeat.current.getValue().trim();
        params[Images.prototype.bgParams.backgroundPosition] = this.refBgPosition.current.getValue().trim();
        this.props.callbackBGParamsSave(params);
        this.close();
    }
    remove() {
        this.props.callbackBGParamsSave(null);
        this.close();
    }
    render() {
        var that = this;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code={"modal.bgParams.header"}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    <Col xs={12}>

                                        <ValidInput ref={this.refBgSize}
                                                    valid={{maxLength: 100, check:['isRequired']}}
                                                    label="backgroundSize (contain, cover)"
                                                    list="bgs"
                                                    default={this.props.image.getBackgroundParam(Images.prototype.bgParams.backgroundSize)}/>
                                        <datalist id="bgs">
                                            <option id="contain">contain</option>
                                            <option id="cover">cover</option>
                                        </datalist>
                                        <ValidInput ref={this.refBgRepeat}
                                                    valid={{maxLength: 100, check:['isRequired']}}
                                                    label="backgroundRepeat: (no-repeat, repeat)"
                                                    list="bgr"
                                                    default={this.props.image.getBackgroundParam(Images.prototype.bgParams.backgroundRepeat)}/>
                                        <datalist id="bgr">
                                            <option id="no-repeat">no-repeat</option>
                                            <option id="repeat">repeat</option>
                                        </datalist>
                                        <ValidInput ref={this.refBgPosition}
                                                    valid={{maxLength: 100, check:['isRequired']}}
                                                    label="backgroundPosition: (center center, center right, ..)"
                                                    list="bgp"
                                                    default={this.props.image.getBackgroundParam(Images.prototype.bgParams.backgroundPosition)}/>
                                        <datalist id="bgp">
                                            <option id="a">center center</option>
                                            <option id="b">center right</option>
                                        </datalist>
                                        {that.state.error == null ? null : <Alert bsStyle="danger">{that.state.error}</Alert>}
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={this.clickedSave} disabled={this.state.submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={this.close} block bsStyle="default"><I18n code={"modal.button.cancel"}/></Button>
                                    </Col>
                                    <Col xs={4}>
                                        <Button onClick={this.remove} block bsStyle="default"><I18n code={"modal.button.delete"}/></Button>
                                    </Col>
                                </Row>

                            </form> : null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
ImageBackgroundParamsModal.propTypes = {
    image: PropTypes.object.isRequired
};
module.exports = ImageBackgroundParamsModal;