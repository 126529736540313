const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Table} = require("react-bootstrap");
const Utility = require("util/Utility");
const FontAwesome = require("components/widgets/FontAwesome");
const I18n = require("components/widgets/I18n");

const ClubInvoiceTable = ({invoices}) => {
    const entries = invoices != null ? invoices : [];
    return (<Table>
        <thead>
        <tr>
            <th><I18n code="tournament.table.column.date"/></th>
            <th><I18n code="tournament.table.column.text"/></th>
            <th><I18n code="management.feature.enddate"/>&nbsp;<I18n code="management.feature.yearsub"/></th>
            <th><I18n code="tournament.table.column.sum"/></th>
            <th><I18n code="tournament.table.column.status"/></th>
        </tr>
        </thead>
        <tbody>
            {
                entries.map(invoice => {
                    if (invoice != null) {
                        return <ClubInvoiceRow key={invoice.id} invoice={invoice} />
                    }
                })
            }
        </tbody>
    </Table>)
};
ClubInvoiceTable.propTypes = {
    invoices:PropTypes.array.isRequired,
};

function invoiceStatus(invoice) {
    if (invoice.isDeleted()) {
        return <I18n code="tournamentUser.status.D"/>
    } else if (invoice.isPaid()) {
        return <I18n code="tournament.attendee.paid"/>
    } else {
        if (invoice.isOpenInvoice()) {
            return <I18n code="club.invoice.open.waiting"/>
        } else {
            return <strong>
                <a href={linkUtil.getLink("club/" + invoice.getClubID().id + "/payinvoice/" + invoice.id)}><I18n code="club.invoice.checkout"/></a>
            </strong>
        }
    }
}

const ClubInvoiceRow = ({invoice})=> {
    return (
        <tr>
            <td>{invoice.getInvoiceDate()}</td>
            {
                invoice.getDocument() != null ?
                    <td>{<a target="_BLANK" href={invoice.getDocument()._url}><FontAwesome icon="download"/>{invoice.getInvoiceNumber()}</a>}<br/>{invoice.getName()}</td> :
                    <td>{invoice.getInvoiceNumber()}<br/>{invoice.getName()}</td>
            }
            <td>{invoice.getAfterPayment() != null && invoice.getAfterPayment().endDate != null ? Utility.getReadableDate(new Date(invoice.getAfterPayment().endDate)) : "-"}</td>
            <td>{invoice.getFormattedPrice()}</td>
            <td>{invoiceStatus(invoice)}</td>
        </tr>
    )
};
ClubInvoiceRow.propTypes = {
    invoice:PropTypes.object.isRequired
};
module.exports = ClubInvoiceTable;
