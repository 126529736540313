const Parse = require("ParseInit");
const UserToken = Parse.Object.extend("userToken", {
    col: {
        ACL: "ACL",
        userID: "userID",
        status: "status",
        action: "action",
        clubID: "clubID",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        active: "A",
        deleted: "D"
    },
    action: {
        password: "PWreset",
        userMail: "UserMailAuthent",
        clubMail: "ClubMailAuthent"
    },
    isTokenTimeout() {
        const created = this.getCreatedAt();
        const validUntil = new Date(created.getFullYear(), created.getMonth(), created.getDate() + 1, created.getHours(), created.getMinutes());
        return new Date() > validUntil;
    },
    isActionPassword() {
        return this.getAction() === this.action.password
    },
    isActionUserEMail() {
        return this.getAction() === this.action.userMail
    },
    isActionClubEmail() {
        return this.getAction() === this.action.clubMail
    },
    getTokenMail() {
        let club = this.getClubID();
        if (club != null) {
            return club.getEmail();
        }
        let user = this.getUserID();
        if (user) {
            return user.get("email")
        }
        return "";
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    getClubID() {
        return this.get(this.col.clubID)
    },
    getAction() {
        return this.get(this.col.action)
    },
    setAction(action) {
        return this.set(this.col.action, action)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = UserToken;
