const ParseListUtility = require("util/ParseListUtility"),
    ParseListCompareUtility = require("util/ParseListCompareUtility");

function EventPlayerRankingList(parcoursTargetAmounts) {
    this.eventPlayerDic = {};
    this.minTargetAmounts = parcoursTargetAmounts > 10 ? parseInt(parcoursTargetAmounts - (parcoursTargetAmounts / 10)) : 10;

    this.pushSorted= function(eventPlayer) {
        this.addUserToGroup(this.eventPlayerDic, eventPlayer.getCountTypeOnlineID(), eventPlayer);
    };

    this.addUserToGroup = function(aGroupedResults, key, eventPlayer) {
        if (eventPlayer.getTargetAmounts() > this.minTargetAmounts) {
            // only add if min target amount reached
            var groupedTUList = aGroupedResults[key];
            if (groupedTUList) {
                ParseListUtility.pushObjSortedDESC(groupedTUList, eventPlayer, ParseListCompareUtility.compareEventPlayerDPSFct);
            } else {
                var newList = [];
                newList.push(eventPlayer);
                aGroupedResults[key] = newList;
            }
        }

    };
    this.getEventPlayerDic = function() {
        return this.eventPlayerDic;
    };
}
module.exports = EventPlayerRankingList;