const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Panel, Badge} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    SubmitButton = require("components/form/SubmitButton"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome");


const tournamentActions = require("actions/TournamentManagerActions");

const BusinessManagementInfo = ({management, tournament, user}) => {
    if (management == null) {
        return null;
    }
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={8} sm={10}>
                        <h2><I18n code="profile.account.business.header"/></h2>
                    </Col>
                    <Col xs={4} sm={2}>
                        <SubmitButton onClick={() => tournamentActions.unlinkBusinessAccount(tournament, management, user)}
                                      confirm={true}
                                      icon={<FontAwesome icon="times"/>}
                                      text={messages.get("modal.button.remove")}
                                      bsStyle="default" bsSize="small"/>
                    </Col>
                    <Col xs={12}>
                        <h3>{management.getName()}</h3>
                        <React.Fragment>
                        {management.getFeatures() != null ? management.getFeatures().map(f => {
                            return <Badge key={"mgmt.feat." + f}><I18n code={"management.feature." + f}/></Badge>
                        }) : null}
                        </React.Fragment>
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>
    );
};
BusinessManagementInfo.propTypes = {
    management:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
};
module.exports = BusinessManagementInfo;
