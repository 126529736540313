const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require('react-router-dom'),
    linkUtil = require("linkUtil");

const { Button, Table} = require("react-bootstrap");
const TransactionDetailLink = require("components/links/TransactionDetailLink");

const I18n = require("components/widgets/I18n");
const messages = require("i18n/messages"),
    CheckboxInput = require("components/form/CheckboxInput"),
    FontAwesome = require("components/widgets/FontAwesome");

const transactionActions = require("actions/TransactionActions");

class TransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.updateInvalidPayments = this.updateInvalidPayments.bind(this);
        this.exportClicked = this.exportClicked.bind(this);
        this.state = {
            showInvalidPayments: this.props.showInvalidPayments ? this.props.showInvalidPayments : false
        }
    }
    updateInvalidPayments(visible) {
        this.setState({showInvalidPayments: visible})
    }
    exportClicked() {
        transactionActions.exportTransactionsCSV(this.props.transactions, this.props.transactionsPositions, this.state.showInvalidPayments)
    }
    render() {
        const {transactions, transactionsPositions, adminPage} = this.props;
        const {showInvalidPayments} = this.state;
        return (
            <div>
                <h3>{messages.get("parcours.transactions.table.header")}</h3>
                <CheckboxInput onAfterChange={this.updateInvalidPayments}
                               message={messages.get("parcours.transactions.table.flag.invalid")}/>
                <Button onClick={this.exportClicked}><FontAwesome icon="download"/><I18n code="parcours.transactions.table.download"/></Button>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th><I18n code="tournament.table.column.date"/></th>
                        <th><I18n code="tournament.table.column.time"/></th>
                        <th><I18n code="tournament.table.column.name"/></th>
                        <th><I18n code="address.place"/></th>
                        <th><I18n code="parcours.articles.form.price"/></th>
                        <th><I18n code="tournament.table.column.positions"/></th>
                        <th><I18n code="parcours.articles.form.brand"/></th>
                        <th><I18n code="skillboard.faq.parcour"/> </th>
                        <th><I18n code="training_mode_tournament"/> </th>
                        <th><I18n code="tournament.table.column.status"/></th>
                        <th>TransactionID</th>
                        {
                            adminPage == true ?
                                <th><I18n code="tournament.table.column.invoice"/></th> : null
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {transactions.map(transaction => {
                        if (showInvalidPayments) {
                            return (<TransactionRow key={transaction.id} transaction={transaction} transactionsPositions={transactionsPositions} adminPage={adminPage}/>);
                        } else {
                            if (transaction.showInTransaction()) {
                                return (<TransactionRow key={transaction.id} transaction={transaction} transactionsPositions={transactionsPositions} adminPage={adminPage}/>);
                            }
                        }

                    })}
                    </tbody>
                </Table>
            </div>
        )
    }
};
TransactionTable.propTypes = {
    transactions:PropTypes.array.isRequired,
    transactionsPositions: PropTypes.object,
    adminPage:PropTypes.bool.isRequired
};
const TransactionRow = ({transaction, transactionsPositions, adminPage}) =>  {
    const buildStatus =() => {
        if (transaction.isStatusACK()) {
            return messages.get("transaction.status." + transaction.getStatus());
        } else if (transaction.isStatusPending() && transaction.getClubInvoiceID()) {
            return messages.get("club.invoice.open.waiting");
        } else {
            // error happened
            return messages.get("transaction.status." + transaction.getStatus()) + " (" + transaction.getProcessingReturnCode() + ")";
        }
    };
    const onClickGenInvoice = () => {
        transactionActions.generateInvoice(transaction.id);
    };
    const onClickManCheckout = () => {
        transactionActions.manualCheckout(transaction.id);
    };
    const queryTransactionPositions = function () {
        transactionActions.queryTransactionPositions(transaction.getManagementID().id, transaction.id)
    }
    const getInvoice = () => {
        if (transaction.getInvoiceNumber()) {
            if (transaction.getInvoice()) {
                return (<a target="_BLANK" href={transaction.getInvoice()._url}>
                    <FontAwesome icon="download"/>
                    {transaction.getInvoiceNumber()}
                </a>);
            } else {
                // NO invoice generated
                return <Button onClick={onClickGenInvoice}>
                    <FontAwesome icon="plus-circle"/>{transaction.getInvoiceNumber()}
                </Button>;
            }

        } else if (transaction.isStatusPending()) {
            return <Button bsStyle="danger" onClick={onClickManCheckout}>
                <FontAwesome icon="plus-circle"/>Override Paid
            </Button>;
        }
        return "-";
    };
    let positions = transactionsPositions != null ? transactionsPositions[transaction.id] : null;
    return (<React.Fragment>
        <tr>
            <td>{transaction.getTransactionDate()}</td>
            <td>{transaction.getTransactionTime()}</td>
            <td>{transaction.getFullName()}</td>
            <td>{transaction.getZipPlace()}</td>
            <td>{transaction.getFormattedPrice()}</td>
            {
                transactionsPositions != null ?
                    <td><Button onClick={queryTransactionPositions}><I18n code="modal.button.show"/> {transaction.getArticlesAmount()}</Button></td>
                    :
                    <td>{transaction.getArticlesAmount()}</td>
            }
            <td>{transaction.getBrand() == null ? transaction.getPaymentCode() : transaction.getBrand()}</td>
            <td>{transaction.getParcours() ? transaction.getParcours().getOnlineID() : "-"}</td>
            <td>{transaction.getTournamentID() ? <Link to={linkUtil.getLink("/tournament/" + transaction.getTournamentID().id)} target="_blank">link</Link> : "-"}</td>
            <td>{buildStatus()}</td>

            {
                adminPage === true ?
                    <td><TransactionDetailLink transactionID={transaction.id}
                                               status={transaction.getStatus()}/></td> :
                    <td>{transaction.id}</td>
            }
            {
                adminPage === true ?
                    <td>{getInvoice()}</td> : null
            }
        </tr>
        {
            positions != null ? positions.map(position => {
                return <TransactionPositionRow key={position.id} position={position} transaction={transaction} adminPage={adminPage}/>
            }) : null
        }
    </React.Fragment>)
};
TransactionRow.propTypes = {
    transaction:PropTypes.object.isRequired,
    adminPage:PropTypes.bool.isRequired
};

const TransactionPositionRow = ({position, transaction, adminPage}) =>  {
    return <tr>
        <td></td>
        <td></td>
        <td colSpan="2">{position.getName()}</td>
        <td>{position.getFormattedSum(transaction.getCurrency())}</td>
        <td>{position.getAmount()}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{position.id}</td>
        {adminPage === true ? <td></td> : null}
    </tr>;
}

TransactionPositionRow.propTypes = {
    position: PropTypes.object.isRequired,
    adminPage:PropTypes.bool.isRequired
};
module.exports = TransactionTable;
