const alt = require("AltInit");
const dao = require("actdao/EventDao");
const ctDao = require("actdao/CountTypeDao");
const parseDao = require("actdao/ParseBaseDao");
const accountDao = require("actdao/AccountDao");
const notificationActions = require("components/notification/NotificationActions");
const EventTrainingSortedList = require("util/EventTrainingSortedList");
const PLUtil = require("util/ParseListUtility");
const linkUtil = require("linkUtil");

const {EventTrainingFilter} = require("parse/_Domain");

class TrainingActions {

    queryTrainingTypes() {
        dao.queryEventTrainingTypes()
            .then(trainingTypes => {
                this.updateTrainingTypes(trainingTypes)
            })
            .catch(error => {
                error.trace = "ETRA.trainType.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTrainingCountTypes() {
        ctDao.queryTrainingCountTypes()
            .then(countTypes => {
                this.updateTrainingCountTypes(countTypes)
            })
            .catch(error => {
                error.trace = "ETRA.trainCountType.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryMyTrainingTeams(user, teamID) {
        dao.queryMyTrainingTeams(user)
            .then(trainingTeams => {
                this.updateTrainingTeams(trainingTeams)
                if (teamID != null && PLUtil.containsID(trainingTeams, teamID)) {
                    // load team only if its one of your teams
                    this.queryTrainingTeam(teamID, user);
                } else {
                    this.updateIsTeamMember(false);
                }
            })
            .catch(error => {
                error.trace = "ETRA.trainMyTeams.load";
                notificationActions.parseError(error);
            });
        dao.queryMyTrainingTeamInvitations(user)
            .then(memberInvitations => {
                this.updateTeamInvitation(memberInvitations)
            })
            .catch(error => {
                error.trace = "ETRA.trainMyInvi.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    setTeamMemberStatus(member, status) {
        member.setStatus(status);
        parseDao.saveObject(member)
            .then(sMember => {
                // remove from invitation
                this.removeTeamInvitation(sMember);
                if (sMember.isDeleted()) {
                    // reload my training teams
                    this.updateTrainingTeams([])
                    this.queryMyTrainingTeams(member.getUserID())
                }
            })
            .catch(error => {
                error.trace = "ETRA.member.save";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTrainingTeam(teamID, user) {
        this.updateTrainingTeam(null);
        this.updateTeamMembers([]);
        dao.queryTrainingTeam(teamID)
            .then(trainingTeam => {
                this.updateIsTeamMember(true);
                this.updateTrainingTeam(trainingTeam);
                this.queryTrainingTeamMembers(trainingTeam);
            })
            .catch(error => {
                error.trace = "ETRA.trainTeam.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTrainingTeamMembers(teamID) {
        dao.queryTrainingTeamMembers(teamID)
            .then(members => {
                this.updateTeamMembers(members);
                let date = new Date();
                let searchParams = {
                    year: date.getFullYear()
                };
                members.forEach(member => {
                    this.queryTrainingTeamResult(searchParams, member)
                })
            })
            .catch(error => {
                error.trace = "ETRA.trainTeamMem.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTrainingTeamResult(searchParams, member) {
        if (!member.isInvited()) {
            // only if member has accepted
            // load members result
            let eventTrainingList = new EventTrainingSortedList(searchParams.year);
            dao.queryEventPlayerForTrainingList(member.getMail(), searchParams.year)
                .then(fEventPlayers => {
                    fEventPlayers.map(fEventTraining => {
                        eventTrainingList.pushTrainingSorted(fEventTraining);
                    });
                    // query eventTraining
                    dao.queryEventTrainingList(member.getUserID(), searchParams.year, true)
                        .then(fEventTrainings => {
                            fEventTrainings.map(fEventTraining => {
                                eventTrainingList.pushTrainingSorted(fEventTraining);
                            });
                            this.updateTrainingTeamResult(member, eventTrainingList)
                        })
                })
                .catch(error => {
                    error.trace = "ETRA.eventList.load";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    queryRemoteTraining(user, memberID) {
        this.updateTrainingTeams(null);
        dao.queryMyTrainingTeams(user)
            .then(trainingTeams => {
                dao.queryTrainingTeamMember(memberID)
                    .then(member => {
                        this.updateTrainingTeams(trainingTeams);
                        if (member != null && PLUtil.contains(trainingTeams, member.getTrainingTeam())) {
                            // load only if you are allowed to see team
                            this.updateRemoteMember(member);
                        }
                    })
                    .catch(error => {
                        error.trace = "ETRA.remoteMember.load";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                error.trace = "ETRA.trainMyTeams.load";
                notificationActions.parseError(error);
            });
        return {}
    }


    queryTrainingScatterEventList(user, searchParams, callback) {
        this.createEventList(searchParams);
        dao.queryEventPlayerForTrainingListSearchParams(user.getEmail(), searchParams)
            .then(fEventPlayers => {
                this.addEventTrainings(fEventPlayers)
                if (callback) {
                    callback()
                }
            })
            .catch(error => {
                error.trace = "ETRA.eventList.load";
                notificationActions.parseError(error);
            });
        return {};
    }
    async queryTrainingEventComment(evpList) {
        const allEventCommentDic = {}
        for (let i = 0; i < evpList.length; i++) {
            const event = evpList[i].getEventID()
            const evComments = await dao.queryEventComment(event, null);
            // console.log("Add comments to " + event.id, evComments)
            const evCommentsDic = {};
            evComments.map(evComment=> {
                evCommentsDic[evComment.getTargetIndex()] = evComment
            })
            allEventCommentDic[event.id] = evCommentsDic
        }
        this.updateTrainingEventComment(allEventCommentDic)
        return {}
    }
    async queryTrainingEventTarget(evpList) {
        const allEventTargetDic = {}
        for (let i = 0; i < evpList.length; i++) {
            const event = evpList[i].getEventID()
            const evTargets = await dao.queryEventTarget(event, null);
            //console.log("Add Targets to " + event.id, evTargets)
            const evTargetsDic = {};
            evTargets.map(evTarget=> {
                evTargetsDic[evTarget.getTargetIndex()] = evTarget
            })
            allEventTargetDic[event.id] = evTargetsDic
        }
        this.updateTrainingEventTarget(allEventTargetDic)
        return {};
    }

    queryTrainingEventList(user, remoteMember, searchParams) {
        const email = remoteMember ? remoteMember.getMail() : user.getEmail();
        this.createEventList(searchParams);
        dao.queryEventPlayerForTrainingList(email, searchParams.year)
            .then(fEventPlayers => {
                this.addEventTrainings(fEventPlayers)
            })
            .catch(error => {
                error.trace = "ETRA.eventList.load";
                notificationActions.parseError(error);
            });
        // query eventTraining
        dao.queryEventTrainingList(user, searchParams.year, remoteMember != null)
            .then(fEventTrainings => {
                this.addEventTrainings(fEventTrainings)
            })
            .catch(error => {
                error.trace = "ETRA.eventTraining.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    createEventTraining(eventTraining, callback) {
        parseDao.saveObject(eventTraining)
            .then(sEventTraining => {
                callback(true);
                this.addEventTrainings([sEventTraining])
            })
            .catch(error => {
                callback(false);
                error.trace = "ETRA.eventTraining.create";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveEventTrainingTeam(trainingTeam, callback) {
        parseDao.saveObject(trainingTeam)
            .then(sTrainingTeam => {
                callback(true);
                // go to team
                window.location.href = linkUtil.getLink("trainingteam/" + sTrainingTeam.id);
            })
            .catch(error => {
                callback(false);
                error.trace = "ETRA.eventTraining.create";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveEventMember(ttMember) {
        parseDao.saveObject(ttMember)
            .then(sTtMember => {
                // go to team
                this.updateTeamMember(sTtMember)
            })
            .catch(error => {
                error.trace = "ETRA.eventMember.save";
                notificationActions.parseError(error);
            });
        return {};
    }

    addToTeamUsers(trainingTeam, mail) {
        if (trainingTeam != null && mail != null && mail.length > 0) {
            accountDao.addToAddEditor("EventTrainingTeams", trainingTeam.id, mail.toLowerCase(), true)
                .then(member => {
                    // update store member list
                    this.addTeamMember(member)
                })
                .catch(error => {
                    error.trace = "CLBA.addUser";
                    notificationActions.parseError(error);
                });
        } else {
            console.error("parameter missing", mail)
        }
        return {};
    }

    deleteEventTraining(eventTraining) {
        parseDao.deleteObject(eventTraining)
            .then(sEventTraining => {
                this.addEventTrainings([sEventTraining])
            })
            .catch(error => {
                error.trace = "CLBA.deleteEvent";
                notificationActions.parseError(error);
            });
        return {}
    }

    queryMyTrainingFilters(user, filterID) {
        dao.queryMyTrainingFilters(user)
            .then(trainingFilters => {
                this.updateTrainingFilters(trainingFilters)
                if (filterID != null) {
                    // set selected Filter with searchParams
                    // TODO handle load Filter from someone else
                    const trainingFilter = PLUtil.getObjectbyID(trainingFilters, filterID)
                    if (trainingFilter != null) {
                        this.selectEventFilter(trainingFilter);
                        this.updateEventSearchParams(trainingFilter.getSearchParams())
                        this.updateTargetSearchParams(trainingFilter.getTargetSearchParams())
                    }
                }
            })
            .catch(error => {
                error.trace = "ETRA.trainMyTeams.load";
                notificationActions.parseError(error);
            });
        return {}
    }

    setEventTrainingFilter(trainingFilter) {
        this.selectEventFilter(trainingFilter);
        this.updateEventSearchParams(trainingFilter.getSearchParams())
        this.updateTargetSearchParams(trainingFilter.getTargetSearchParams())
    }

    updateOrCreateFilter(user, filterName, searchParams, targetSearchParams, pEventFilterID) {
        let eventFilter = pEventFilterID;
        if (eventFilter == null) {
            eventFilter = new EventTrainingFilter()
            eventFilter.setMainEditor(user)
            eventFilter.setStatus(EventTrainingFilter.prototype.status.active)
        }
        eventFilter.setName(filterName)
        eventFilter.setSearchParams(searchParams)
        eventFilter.setTargetSearchParams(targetSearchParams)

        parseDao.saveObject(eventFilter)
            .then(sEventFilter => {
                this.updateFilterChanged(false)
                this.selectEventFilter(sEventFilter);
                this.updateEventFilter(sEventFilter)
            })
            .catch(error => {
                error.trace = "ETRA.createTrainFilter";
                notificationActions.parseError(error);
            });
        return {}
    }

    deleteFilter(eventTrainingFilter) {
        parseDao.deleteObject(eventTrainingFilter)
            .then(dEventTrainingFilter => {
                window.location.href = linkUtil.getLink("trainingscatter");
            })
            .catch(error => {
                error.trace = "ETRA.delTrainFilter";
                notificationActions.parseError(error);
            });
        return {}
    }

    addEventTrainings(pEventTrainings) {
        return pEventTrainings;
    };

    updateTrainingEventComment(evCommentDic) {
        return evCommentDic
    }
    updateTrainingEventTarget(evTargetsDic) {
        return evTargetsDic
    }

    updateTrainingTypes(types) {
        return types;
    };
    updateTrainingCountTypes(countTypes) {
        return countTypes
    }
    updateTrainingTeams(teams) {
        return teams;
    };
    updateTrainingTeam(team) {
        return team;
    };
    updateIsTeamMember(isMember) {
        return isMember
    }
    updateTeamMembers(members) {
        return members;
    }
    updateTeamMember(member) {
        return member;
    }
    updateTrainingTeamResult(member, eventPlayerList) {
        return {member, eventPlayerList}
    }
    updateTeamInvitation(memberInvitations) {
        return memberInvitations
    }
    removeTeamInvitation(memberInvitations) {
        return memberInvitations
    }
    addTeamMember(member) {
        return member;
    }
    updateRemoteMember(member) {
        return member
    }
    updateSearchParams(searchParams) {
        return searchParams;
    }
    createEventList(searchParams) {
        return searchParams
    }
    updateTrainingDetailLoading(active) {
        return active
    }
    updateEventFilter(eventFilter) {
        return eventFilter
    }
    updateTrainingFilters(trainingFilters) {
        return trainingFilters
    }
    reset() {
        return {};
    }
    resetTeam() {
        return {}
    }
    // goes to trainingFilterStore ---------------------
    selectEventFilter(eventFilter) {
        return eventFilter
    }
    updateFilterChanged(changed) {
        return changed
    }
    updateEventSearchParams(searchParams) {
        return searchParams
    }

    updateTargetSearchParams(searchParams) {
        return searchParams
    }
    resetFilter() {
        return {}
    }
    updateInFilterList(inList) {
        return inList
    }
}

module.exports = alt.createActions(TrainingActions);
