var Parse = require("ParseInit");

var EventResult = Parse.Object.extend("EventResult", {
    col: {
        ACL: "ACL",
        arrowNr: "arrowNr",
        createdAt: "createdAt",
        killNr: "killNr",
        objectId: "objectId",
        points: "points",
        potPoints: "potPoints",
        resultType: "resultType",
        targetName: "targetName",
        status: "status",
        coordX: "coordX",
        coordY: "coordY",
        killImage:"killImg",
        targetIndex: "targetIndex",
        updatedAt: "updatedAt",
    },
    type: {
        normal_3D: "N",
        normal_disc: "C",
        special: "S",
        null_score: "X",
        miss_xy: "M",
        invalid: "I"
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    isNullScore() {
        return this.type.null_score == this.getResultType();
    },
    isResultTypeValid() {
        return this.type.miss_xy !== this.getResultType() && this.type.invalid !== this.getResultType()
    },
    getArrowNr() {
        return this.get(this.col.arrowNr)
    },
    setArrowNr(arrowNr) {
        return this.set(this.col.arrowNr, arrowNr)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCoordX() {
        return this.get(this.col.coordX)
    },
    getCoordY() {
        return this.get(this.col.coordY)
    },
    getKillImageNr() {
        return this.get(this.col.killImage)
    },
    getKillNr() {
        return this.get(this.col.killNr)
    },
    setKillNr(killNr) {
        return this.set(this.col.killNr, killNr)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPoints() {
        return this.get(this.col.points)
    },
    setPoints(points) {
        return this.set(this.col.points, points)
    },
    getPotentialPoints() {
        return this.get(this.col.potPoints)
    },
    getResultType() {
        return this.get(this.col.resultType)
    },
    setResultType(resultType) {
        return this.set(this.col.resultType, resultType)
    },
    getTargetName() {
        return this.get(this.col.targetName)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getTargetIndex() {
        return this.get(this.col.targetIndex)
    },
    setTargetIndex(targetIndex) {
        return this.set(this.col.targetIndex, targetIndex)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = EventResult;
