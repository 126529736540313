const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,  Table, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentGroupResultCompareErrorsModal = ({tUserGroup, compareErrors}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="tournament.modal.score.compareErrors"/></TextCenter>}
            buttonStyle="warning"
            buttonChildren={<FontAwesome icon="exclamation-triangle"><I18n code="tournament.modal.score.compareErrors"/></FontAwesome>}>
            <TournamentGroupErrorList compareErrors={compareErrors} tUserGroup={tUserGroup}/>
        </ModalBasic>
    )
};
TournamentGroupResultCompareErrorsModal.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    compareErrors: PropTypes.array,
};
class TournamentGroupErrorList extends React.Component {
    constructor(props) {
        super(props);
    }
    clickedSave(e) {
        const {closeFct} = this.context;
        e.preventDefault();
        // check result parseInt able
        closeFct();
    }
    getTUserName(tUserId, tUserGroup) {
        let tUsers = tUserGroup.getTournamentUserIDs();
        for (let i = 0; i < tUsers.length; i++) {
            if (tUserId === tUsers[i].id) {
                return tUsers[i].getFullName()
            }
        }
        return tUserId
    }
    render() {
        const {tUserGroup, compareErrors} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <p><I18n code="tournament.modal.score.compareErrors.desc"/></p>
                        <Table>
                            <thead>
                            <tr>
                                <th><I18n code="tournament.table.column.name" /></th>
                                <th><I18n code="tournament.table.column.status" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                compareErrors.map(errorArr => {
                                    const tUserId = errorArr[1];
                                    const tUserName = this.getTUserName(tUserId, tUserGroup);
                                    return <tr key={"CompErr_" + tUserId}>
                                        <td>{tUserName}</td>
                                        <td><I18n code={errorArr[2]}/></td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <Button onClick={this.clickedSave} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentGroupErrorList.propTypes = {
    tUserGroup:PropTypes.object.isRequired,
    changeLogs:PropTypes.array.isRequired
};
TournamentGroupErrorList.contextType = ModalBasicContext;
module.exports = TournamentGroupResultCompareErrorsModal;
