const ClubUtility = {
    getEmailListForGroups: function (clubUsers, groups, fillIfGroupsEmpty) {
        let mailAddresses = null;
        const length = clubUsers.length;
        if (groups && groups.length > 0) {
            // limited to some groups
            mailAddresses = [];
            for (let ii = 0; ii < length; ii++) {
                for (let ig = 0; ig < groups.length; ig++) {
                    if (clubUsers[ii].isActiveMember() && // send only to members and owner (not for exMembers and addressOnly)
                        (clubUsers[ii].hasGroup(groups[ig]) || clubUsers[ii].isArticle(groups[ig]))) {
                        const mail = clubUsers[ii].getEmail();
                        if (mail && mail.length > 0 && mail.indexOf("noemail") === -1) {
                            // add real email
                            mailAddresses.push(mail)
                        }
                        const mail2 = clubUsers[ii].getEmail2();
                        if (mail2) {
                            // add real email
                            mailAddresses.push(mail2)
                        }
                    }
                }
            }
        } else if (fillIfGroupsEmpty) {
            // mail to all
            mailAddresses = [];
            for (let i = 0; i < length; i++) {
                if (clubUsers[i].isActiveMember()) {
                    const mail = clubUsers[i].getEmail();
                    if (mail && mail.length > 0 && mail.indexOf("noemail") === -1) {
                        // add real email
                        mailAddresses.push(mail)
                    }
                    const mail2 = clubUsers[i].getEmail2();
                    if (mail2 && mail2.length > 0 && mail2.indexOf("noemail") === -1) {
                        // add real email
                        mailAddresses.push(mail2)
                    }
                }
            }
        }
        return mailAddresses
    }
};
module.exports = ClubUtility;
