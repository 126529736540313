module.exports = {
    "form.validation.errors": "Not all fields are filled out correctly",
    "not.available.geolocation": "Your browser does not support geo locations. Update your browser.",
    "link.customer.register": "Customer Registration",
    "parse.error.141": "An error occurred: ",
    "management.feature.header": "Available packages",
    "management.feature.setting.denyWiki":"Only editors may change the course wiki entries",
    "management.feature.setting.extendAbo":"No editor check for extending the subscription",
    "management.feature.package": "Package",
    "management.feature.pricetax": "All prices are in EUR and includes taxes.",
    "management.feature.pricebase":"*) For a subscription you need in addition the basic charge for € 55, except for the full feature premium sub",
    "management.feature.test":" You can't decide for a subscription immediately? - Test our tournament and payment solution within a single tournament:",
    "management.feature.startdate": "Start",
    "management.feature.enddate": "End",
    "management.feature.yearsub": "Yearly sub",
    "management.feature.yearsub.renew": "Renew yearly sub",
    "management.feature.yearsub.payopen":"Kindly settle the open invoices!",
    "management.feature.yearsub.noEditor":"Only the editors of the business account are allowed to extend the subscription",
    "management.feature.base": "Basic charge",
    "management.feature.ST": "Single tournament",
    "management.feature.VA": "AboVouchers",
    "management.feature.PI": "Course wiki Adds",
    "management.feature.TM": "Tournament management",
    "management.feature.TM.desc": "The tournament management is a ready to use solution for almost all tournament. For mass sport or competitive sport, one or several rounds, Shotgun or trickle start - everything is easy and fast to handle and can be extend in short time if needed.",
    "management.feature.TM.online": "Online registration and administration system",
    "management.feature.TM.payment": "Online-payment of the registration",
    "management.feature.TM.app": "3D SKill Board support - no more score cards",
    "management.feature.TM.live": "Live-tournament progress for the audience",
    "management.feature.TM.start": "Shotgun and trickle start",
    "management.feature.TM.unlimited": "No tournaments or attendees limits with a subscription",
    "management.feature.TM.classes": "Many bow and age styles preconfigured",
    "management.feature.TM.basic":"Simple basis configuration ",
    "management.feature.TM.rounds":"Individual tournaments by combining different rounds",
    "management.feature.TM.mmtu":"Only one table for attendees",
    "management.feature.TM.groups":"Create groups for attendees and start point manually or automatically",
    "management.feature.CT": "Cup/Trophy tournament series",
    "management.feature.CT.desc": "The tournament management for cup/trophy tournament series",
    "management.feature.CT.licNumber":"Registration with a personal cup/trophy number",
    "management.feature.CT.points":"Cup points and scratch result are configurable",
    "management.feature.CT.result":"Cup result is calculated with the cup/trophy number",
    "management.feature.CT.qrscan":"QR scanner for attendance check",
    "management.feature.CT.price":"For all tournament hosts including the Mobile Payment in one package price",
    "management.feature.PP": "Mobile payment",
    "management.feature.PP.desc": "Integrated mobiles payment system for the course and tournament registration fee. Is included in each package",
    "management.feature.PP.transaction": "Detailed transaction overview and settlement every month",
    "management.feature.PP.nocash": "No issues with unfitting cash at the cash point",
    "management.feature.PP.brands": "Visa, Mastercard, Sofortüberweisung, PayPal ...",
    "management.feature.PP.demo": "Video: app parcours payment",
    "management.feature.PA": "Course",
    "management.feature.PA.desc": "The course ranking list shows the best archers of the month and motivates other archers to compete against each other.",
    "management.feature.PA.ranking": "Daily updated archers ranking for the course",
    "management.feature.PA.payment": "Online-payment of a single round, daily or yearly subscription ",
    "management.feature.PA.stats":"Course statistics",
    "management.feature.PA.targets":"Map of targets on the course as well as synchronizing them to the app",
    "management.feature.UM": "Club management",
    "management.feature.UM.desc": "The easy online club management as a central information platform.",
    "management.feature.UM.member": "Manage addresses, licences and contact information of members",
    "management.feature.UM.msg": "Send emails and notification to members or groups",
    "management.feature.UM.events": "Create events for members or groups - also including a poll function",
    "management.feature.UM.inout": "Revenue and expense statement including year-end closing",
    "management.feature.PREM": "Full-Abo",
    "management.feature.PREM.desc": "The full functional range of 3DTurnier in one sub.",
    "management.feature.PREM.all":"All function without any limitation",
    "management.feature.PREM.save": "You save € 55 compared to the single prices",
    "management.feature.ADV": "Advertising",
    "management.feature.ADV.desc": "There a many options for advertisement with us to reach archers. We will be glad to explain our portfolio to you in a personal call.",
    "management.request.form.submit": "Registration",
    "management.request.form.header.host": "Are you the owner of this course?",
    "management.request.form.info.host": "This pages is for requesting the management for this course. This enables you to use online payment system as well as the tournament management without any restrictions!",
    "management.request.form.info.solutions": "Here you will find an overview about our solutions and prices.",
    "management.request.form.info.contact": "We will get in contact with you in the next few days.",
    "management.request.form.info.login": "Please login to request management of this course",
    "management.request.form.info.already": "You have already the management for this course.",
    "management.request.form.info.other": "This course is already managed by someone else.",
    "management.request.form.header.Adress": "Club or company address",
    "management.request.form.header.Contact": "Contact",
    "management.request.form.header.features":"Select interesting packages",
    "management.request.form.header.fillForm":"Kindly fill the form.",
    "management.request.form.fillOwner":"Use course date to fill the form",
    "management.request.business.header":"Business account",
    "address.userNr": "Member number",
    "address.union": "Club or company name",
    "address.street": "Street",
    "address.zip": "ZIP",
    "address.place": "Place",
    "address.contactName": "Contact person",
    "address.phone": "telephone number",
    "address.extRefID":"Reference number",
    "address.uid" : "UID",
    "address.mail": "E-mail",
    "address.birthday":"Birthday",
    "address.birthYear":"Year of birth",
    "address.sex":"Sex",
    "address.entryDate": "Entry date",
    "address.contact.primary":"First contact",
    "address.contact.secondary":"Alternative contact",
    "payTarget.cardName": "Name of account holder",
    "payTarget.cardBIC": "BIC",
    "payTarget.cardIBAN": "IBAN",
    "payTarget.payCondition": "Terms of payment",
    "parcours.management.already.requested":"You have already requested the management.",
    "parcours.management.request.cancel":"Cancel",
    "parcours.create.title":"Create new wiki entry",
    "parcours.create.submit":"Create entry",
    "parcours.create.description":"Kindly enter a name for the course club as well as the zip code of the course in the region.",
    "parcours.create.name":"Name for course club",
    "parcours.create.addParcours":"Add entry to this existing club",
    "parcours.create.addMyParcours":"Add course",
    "Dialog_Header_createReuseParcours":"Create a new entry or switch to an existing one?",
    "parcours.add.title":"Add a new wiki entry to",
    "parcours.add.description":"Kindly enter a name for the new course. (Just rearranging is not considered as new :-)",
    "parcours.add.name":"Name of the course",
    "parcours.result.empty":" No courses found",
    "parcours.search.nearby":"Search nearby parcours",
    "parcours.search.nearby.label": "Geo Location",
    "parcours.search.noHttps": "The access to your position is only available with a HTTPS connection - check also if GPS is activated on your phone",
    "parcours.search.noPosition":"Sorry, but your browser did not provided your position - check also if GPS is activated on your phone.",
    "parcours.promo.coords.title":"Help improve the Parcours Wiki!",
    "parcours.promo.coords.subline":"Enter missing coordinates for this parcours:",
    "list.entry.empty": "No entries were found",
    "list.entry.deleted": "Entry is deleted",
    "list.entry.hdr.delete":"Delete entry?",
    "list.entry.delete":"Do you want to delete the selected entry?",
    "status.A" : "active",
    "status.I" : "inactive",
    "status.R" : "registered",
    "status.U" : "used",
    "status.D" : "deleted",
    "status.EXP" : "expired",
    "code.unknown": "Unknown",
    "domain.user.detail.sex.0": "Male",
    "domain.user.detail.sex.1": "Female",
    "userAbo.type.T":"Tester",
    "userAbo.type.D":"Donator",
    "userAbo.type.P":"Paid",
    "userAbo.type.V":"VIP",
    "userAbo.type.A":"Admin",
    "userAbo.feature.S":"Statistics",
    "userAbo.feature.S.desc":"A graphic is worth 1000 figures",
    "userAbo.feature.R":"Parcours ranking",
    "userAbo.feature.R.desc":"Best archers on the course",
    "userAbo.feature.G":"GPS track",
    "userAbo.feature.G.desc":"Walked routes with scores and targets",
    "userAbo.feature.Z":"Parcours targets",
    "userAbo.feature.Z.desc":"Over there is the Kill",
    "userAbo.feature.T":"Training plans",
    "userAbo.feature.T.desc":"A lot helps a lot",
    "userAbo.feature.K":"Kill image scoring",
    "userAbo.feature.K.desc":"",
    "userAbo.faIcon.S":"chart-line",
    "userAbo.faIcon.R":"cubes",
    "userAbo.faIcon.G":"map",
    "userAbo.faIcon.Z":"bullseye",
    "userAbo.faIcon.T":"user-clock",
    "Dialog_Body_premium_needed_for_gps":"An active sub is needed for recording a gps track",
    "parse.error.101": "Username or password incorrect",
    "modal.login.title": "Login for 3D Turnier and 3D SKill Board app",
    "modal.button.ok":"OK",
    "modal.button.close": "Close",
    "modal.button.cancel":"Cancel",
    "modal.button.take":"Take it",
    "modal.button.save": "Save",
    "modal.button.delete": "Delete",
    "modal.button.restore": "Restore",
    "modal.button.remove": "Remove",
    "modal.button.edit": "Edit",
    "modal.button.activate": "Activate",
    "modal.button.deactivate": "Deactivate",
    "modal.button.challenge": "Challenge",
    "modal.button.attach": "Upload and attach",
    "modal.button.send":"Send",
    "modal.button.newEntry": "New entry",
    "modal.button.search": "Search",
    "modal.button.next": "Next",
    "modal.button.download": "Download",
    "modal.button.add2cup":"Add to cup",
    "modal.button.show":"Show",
    "modal.decision.title.default":"Attention",
    "modal.decision.body.becameMainEditor":"Do you like to take the tournament management for yourself?",
    "modal.login.username": "Login und user name",
    "modal.login.email": "E-mail",
    "modal.login.password": "Password",
    "modal.login.password.repeat": "Repeat password",
    "modal.login.password.change":"Change password",
    "modal.login.password.enterNew":"Kindly enter a new password",
    "modal.login.button.login": "Login",
    "modal.login.button.forgot": "Forgot password",
    "modal.login.button.register": "Sign up",
    "modal.login.button.login.back": "back to Login",
    "modal.login.error.password.match": "The passwords do not match",
    "modal.login.error.missing.required": "Please fill out all required fields",
    "modal.login.error.missing.username": "Enter username or email",
    "modal.login.error.missing.password": "Enter password",
    "modal.login.error.userToken.used" : "The link is not valid any more. Kindly request a new link if still necessary!",
    "modal.login.save.success":"Your change was saved successfully",
    "modal.login.save.pwd.success":"Your password was saved successfully",
    "modal.password.reset.header":"Reset password",
    "modal.password.reset.body":"You will receive a link at the following email inbox which will reset your password:",
    "modal.password.reset.mail.success":"Successfully requested a password reset  - You will receive an e-mail containing the link where you can enter your new password",
    "modal.password.reset.invalidLink":"The used link is invalid or expired - Kindly try again",
    "modal.password.reset.mailACK" : "Thank you, your e-mail was successfully verified.",
    "modal.password.reset.success" : "Your password was changed successfully.",
    "modal.addEditor.header": "Editor overview",
    "modal.addEditor.addMail": "Enter the e-mail of the new editor",
    "modal.addEditor.editorlist":" Current editors",
    "modal.addEditor.mainEditor": "Main editor",
    "modal.address.header" :" Edit address",
    "modal.mail.enterMailList": "Enter e-mails separated by comma",
    "modal.mail.invitation.header": "Send invitation to the club to this mails",
    "modal.mail.mailList.header": "E mail list of the members",
    "modal.message.placeholder.message": "Enter a message",
    "modal.message.type": "Send message as:",
    "modal.message.subject": "Subject",
    "modal.message.sender": "Sender:",
    "modal.message.receiver": "Receiver:",
    "modal.message.notarget": "There is no target group for this message.",
    "modal.bgParams.header":"Parameter for background images",
    "msg.type.mail":"Mail",
    "msg.type.notify":"Notification",
    "modal.payment.header": "Payment target data",
    "modal.percentage.header": "Enter fee for each brand in percentage",
    "modal.license": "License nr",
    "modal.license.header": "Choose association and classes and also enter licence number.",
    "modal.license.chooseUnion":"Choose association ...",
    "modal.license.validUntil": "Valid until",
    "modal.license.chooseBow": "Choose bow style ...",
    "modal.license.chooseAge": "Choose age class ...",
    "modal.license.choose":"Choose ...",
    "modal.license.chooseCountType": "Choose scoring ...",
    "modal.license.add": "Add association licence",
    "modal.license.empty":"No association licences found",
    "modal.license.noUserDetail":"You have to enter some private data first before adding association licences.",
    "modal.typelist.subHeader":"Existing entries:",
    "modal.typelist.entries" : "Entries:",
    "modal.entry.mandatory":"Mandatory",
    "modal.entry.optional":"Optional",
    "modal.entry.attachment":"Attachment",
    "token.panel.header.loading":"Load and validate token",
    "token.panel.header.PWreset":"Change password",
    "token.panel.header.UserMailAuthent":"E-mail validation",
    "token.panel.header.ClubMailAuthent":"E-mail validation for clubs",
    "token.button.UserMailAuthent":"Confirm e-mail: ",
    "countType.3P3Z_20_f" : "Field round (3A 3Z)",
    "countType.3P2Z_20_f" : "General (3A 2Z)",
    "countType.3P2Z_15_f" : "Field round (3A 2Z))",
    "countType.2P4Z_11_f" : "2 Arrows - 4 Zones",
    "countType.1P3Z_15_f" : "Hunter round (1A 3Z)",
    "countType.2P4Z_11_t" : "WA 2014 (1+1A 4Z)",
    "countType.1P4Z_11_f" : "IBO (1A 4Z)",
    "countType.1P5Z_14_f" : "ASA (1A 5Z)",
    "countType.1P6Z_14_f" : "Universal (1A 6Z)",
    "countType.1P3Z_20_f" : "IFAA 3D hunter (1A 3Z)",
    "countType.2P3Z_10_f" : "IFAA 3D double hunter (2A 3Z)",
    "countType.3P2Z_20_f_1" : "IFAA 3D standard (3A 2Z)",
    "countType.1P3Z_10_f" : "Compound 1 arrow",
    "countType.2P3Z_10_f_1" : "Compound 2 arrows",
    "countType.D6Z_6_t" : "WA Field target",
    "countType.D10Z_10_t" : "Target 10ring (1-10)",
    "countType.D6Z_10_t" : "Target 5Ring (6-10)",
    "countType.D7Z_10_t" : "Target 6Ring (5-10)",
    "countType.C4Z_20_f" : "Bogenkino (5-20)",
    "countType.D4Z_5_t" : "IFAA Field and Hunter",
    "countType.disc": "Target",
    "countType.singleArrow": "1 Arrow",
    "countType.multiArrow": "More arrows",
    "countType.arrowSumUp.true" : "The points of all arrows on the target will be scored as sum",
    "countType.arrowSumUp.false" : "Only the points of the first hit (1st, 2nd or 3rd arrow) on the target will be scored",
    "countType.button.create3D" : "Create 3D score",
    "countType.button.createDisc" : "Create target score",
    "countType.desc.arrowZones":"Choose the amount of arrows and zones. Afterwards enter the points for each arrow zone combination for scoring:",
    "countType.error.arrowZonesToLess":"You need more than just one arrow and one zone!",
    "countType.error.duplicate":"This score is already available",
    "countType.error.duplicateName":"There is already a score with the given name",
    "countType.error.duplicateZone":"You have to select different zone names in the drop downs for zones",
    "countType.save.info":"Kindly enter a decent name and description for a new score. The created score will be reviewed by us before releasing it for all 3D SKill Board Apps (synchronize). Scores CANNOT be changed afterwards by yourself. If you have any question - do not hesitate to contact us.",
    "countType.save.success" : "Your score was saved as draft successfully and will be reviewed by us within the next days. You will receive an email after the review is finished.",
    "countType.search.placeholder":"Search for name, description and points of the first arrow (eg 20,18,16)",
    "ResultStatBean_arrow":"Arrow",
    "ResultStatBean_zone":"Zone",
    "Count_type_name_10Disc" : "Target 10ring (1-10)",
    "Count_type_name_10Disc5RingCenter" : "Target 5Ring (6-10)",
    "Count_type_name_10Disc6RingCenter" : "Target 6Ring (5-10)",
    "Count_type_name_1Arrow" : "Hunter round (1A 3Z)",
    "Count_type_name_1Arrow_3" : "IFAA 3D hunter (1A 3Z)",
    "Count_type_name_1Arrow_4" : "IBO (1A 4Z)",
    "Count_type_name_1Arrow_5" : "ASA (1A 5Z)",
    "Count_type_name_1Arrow_6" : "Universal (1A 6Z)",
    "Count_type_name_1add1Arrow_4" : "WA 2014 (1+1A 4Z)",
    "Count_type_name_2Arrow" : "2 Arrows - 4 Zones",
    "Count_type_name_2Arrow_3" : "IFAA 3D double hunter (2A 3Z)",
    "Count_type_name_3Arrow_2_IFAA" : "IFAA 3D standard (3A 2Z)",
    "Count_type_name_3Arrow_2h" : "General (3A 2Z))",
    "Count_type_name_3Arrow_2l" : "Field round (3A 2Z))",
    "Count_type_name_3Arrow_3" : "Field round (3A 3Z))",
    "Count_type_name_4Disc" : "IFAA Field and Hunter",
    "Count_type_name_6DiscField" : "WA Field target",
    "Count_type_checkBox_arrowSumUp" : "Sum results of all arrows for each target",
    "Count_type_miss_points" : "Score for missed targets",
    "Count_type_grp_private" : "Own score",
    "Count_type_grp_ring" : "Disc score",
    "Count_type_grp_system" : "Standard score",
    "global_archers_scores":"Shared custom score",
    "Frag_event_hit_10ring" : "10 ring",
    "Frag_event_hit_11ring" : "11 ring",
    "Frag_event_hit_12ring" : "12 ring",
    "Frag_event_hit_14ring" : "14 ring",
    "Frag_event_hit_1ring" : "1 ring",
    "Frag_event_hit_2ring" : "2 ring",
    "Frag_event_hit_3ring" : "3 ring",
    "Frag_event_hit_4ring" : "4 ring",
    "Frag_event_hit_5ring" : "5 ring",
    "Frag_event_hit_6ring" : "6 ring",
    "Frag_event_hit_7ring" : "7 ring",
    "Frag_event_hit_8ring" : "8 ring",
    "Frag_event_hit_9ring" : "9 ring",
    "Frag_event_hit_Kill" : "Kill",
    "Frag_event_hit_Xring" : "X ring",
    "Frag_event_hit_body" : "Body",
    "Frag_event_hit_centerKill" : "Center Kill",
    "Frag_event_hit_live" : "Vital",
    "Frag_event_hit_wound" : "Wound",
    "yearPager.previous":"Previous year",
    "yearPager.next":"Next year",
    "yearPager.year":"Year",
    "yearPager.years":"Years",
    "monthPager.previous":"Previous month",
    "monthPager.next":"Next month",
    "monthPager.month": "Month",
    "montPager.month.1":"January",
    "montPager.month.2":"February",
    "montPager.month.3":"March",
    "montPager.month.4":"April",
    "montPager.month.5":"May",
    "montPager.month.6":"June",
    "montPager.month.7":"July",
    "montPager.month.8":"August",
    "montPager.month.9":"September",
    "montPager.month.10":"October",
    "montPager.month.11":"November",
    "montPager.month.12":"December",
    "parse.error.202": "There is already an account in use with that name",
    "parse.error.203": "There is already an account in use with that e-mail ",
    "parse.error.205": "Password reset not possible. There is no such user with that e-mail",
    "parse.error.119": "You do not have the correct access rights for this data",
    "parse.error.125": "You have entered an invalid e-mail",
    "parse.error.100": "The connection to background system could not be established.",
    "parse.error.offline":"We are sorry - but our database is not reachable at the moment. Please retry in some minutes again.",
    "parse.error.unexpected" : "Unexpected error",
    "parse.error.expected": "Error",
    "Dialog_Body_Error_invalid_session" : "Your session is not valid any more. Kindly log off an on again. This is for your own safety.",
    "form.valid.not.number": "Not a number",
    "form.valid.not.negNumber" :" Number must not be negative",
    "form.valid.not.greater0" :" Number has to be larger than zero",
    "form.valid.not.url": "Invalid URL - start with http",
    "form.valid.not.date": "Invalid date (dd.mm.yyyy)",
    "form.valid.not.time": "Invalid time (hh:mm)",
    "form.valid.not.email":"Kindly enter a valid e-mail",
    "form.valid.not.phone":"Invalid telephone number",
    "form.valid.not.point4comma": "Kindly use a point as comma",
    "form.valid.not.noSpecSign":"Kindly use no special signs",
    "form.valid.not.coordinates":"Enter coordinates in this form (Latitude,Longitude  eg 48.175829,14.741720)",
    "fileupload.description":"Choose a file (max 5 MB)",
    "fileupload.selectFile":"Kindly choose one file for saving",
    "fileupload.selectFileToLarge":"The file may not be larger than 5 MB",
    "fileupload.selectFileInvalidExt":"Only following file types are allowed:",
    "fileupload.edit.bgParams": "Background image parameter",
    "group.place.main.title": "Country",
    "group.place.region.title": "State",
    "group.place.main.all": "All countries",
    "group.place.region.all": "All states",
    "header.nav.empty": "Choose topic",
    "header.nav.home": "Home",
    "header.nav.dashboard": "Dashboard",
    "header.nav.tournaments": "Tournaments",
    "header.nav.tournamentRules": "Tournament rules",
    "header.nav.clubs": "Clubs",
    "header.nav.3dskillboard": "3D SKill Board",
    "header.nav.3dskillboard.premium": "3D SKill Board Premium",
    "header.nav.premium": "PREMIUM",
    "header.nav.premium.next": "Renew PREMIUM sub",
    "header.nav.parcours": "Parcours Wiki",
    "header.nav.contact": "Contact",
    "header.nav.more": "More",
    "header.nav.solutions":"Solutions",
    "header.nav.smarttournament": "Smart Tournament",
    "header.nav.policy": "Privacy Agreement",
    "header.nav.profile": "My profile",
    "header.nav.training": "My training",
    "header.nav.events" : "Event history",
    "header.nav.scatter": "Hit pattern",
    "header.nav.account":"Account data",
    "header.nav.login": "Login",
    "header.nav.logout": "Logout",
    "header.nav.agb":"Legal Terms",
    "header.nav.news":"News",
    "header.nav.translator": "Translations",
    "header.nav.shop" : "Shop",
    "header.nav.invoices":"Invoices",
    "header.nav.mailNotVerified":"The mail address of your account ist not verified yet. You can not use all function of 3D SKill Board without this verification. Kindly search the mail in your inbox or spam folder or request the resend to this mail address here",
    "header.nav.accountLocked":"This account has been locked and therefore you can not access all features. Kindly contact an office@3dturnier.com",
    "news.title": "3D Turnier News",
    "news.empty.result" : "Could not find any news.",
    "news.button.search":"Search for news",
    "news.button.search.placeholder":"Message, place oder ZIP",
    "news.new_tournament":"New tournament",
    "news.new_parcours":"New 3D-course",
    "news.new_field":"New field course",
    "news.new_target":"New target range",
    "news.new_cinema":"New BowSim 4D",
    "news.new_android_release":"New Android app",
    "news.new_ios_release":"New iOS app",
    "news.all":"all news ...",
    "home.title": "Welcome<span class='hidden-xs hidden-sm'> to <b>3D Turnier</b> and <b>3D SKill Board</b></span>",
    "home.carousel.skillboard": "3D SKill Board - The 3D archery app",
    "home.carousel.skillboard2": "3D SKill Board - Easy entering of hit points",
    "home.carousel.app": "3D SKill Board - The number one digital helper on the course",
    "home.carousel.tournament": "3D Turnier - Score cards are from yesterday",
    "home.carousel.parcours": "Parcours Wiki - all together",
    "home.carousel.shop" :"3D SKill Board - online shop",
    "home.carousel.shop.link":"To the shop ...",
    "home.tournaments.title": "Tournaments up next",
    "home.parcours.title": "Partner courses",
    "home.parcours.payBtn": "Pay course",
    "home.parcours.navBtn": "Navigate",
    "home.panel.tournament.header": "3D Tournaments",
    "home.panel.tournament.html": "\
            <p>The tournament platform offers a yearly calendar and each registered archer can easily publish a tournament. </p>\
            <p>The administration tools for a tournament handles the complete operation of a 3D tournament..</p>",
    "home.panel.tournament.link": "Show tournaments ...",
    "home.panel.tournament.all": "all tournaments ...",
    "home.panel.skillboard.header": "3D SKill Board",
    "home.panel.skillboard.html": "\
            <p>The smart 3D archery app - scorecards are from yesterday! <br><br>Easily entering of hit points for each archer and always see the current score. </p>\
            <ul>\
                <li>Store archers and courses locally on device</li>\
                <li>Enter hit for each archer and target</li>\
                <li>View the stored events and their results</li>\
                <li>Different score types, in one single event</li>\
            </ul>",
    "home.panel.skillboard.link": "more...",
    "home.panel.skillboard.download": "App download...",
    "home.panel.parcours.header": "Parcours Wiki",
    "home.panel.parcours.html": "\
            <p>All important information about the courses shall be found or gathered here. All registered 3D SKill Board user can contribute to keep the information up to date and also add missing ones.</p>\
            <ul>\
                <li>385 courses are listed:</li>\
                <li>202 in Austria</li>\
                <li>145 in Germany</li>\
                <li>16 in Italy</li>\
            </ul>",
    "dashboard.title":"Dashboard",
    "dashboard.parcours.title": "My Parcours",
    "home.panel.parcours.link": "Show courses ...",
    "home.panel.parcours.all": "all courses ...",
    "home.panel.solutions.header":"Smart solutions",
    "home.panel.solutions.html":"<p>for course operator and tournament hosts as club or business:</p>\
                                    <ul>\
                                        <li>Overall course mobile payment</li>\
                                        <li>Tournament management with real time result</li>\
                                        <li>Club management</li>\
                                        <li>Target-oriented advertising</li>\
                                    </ul>",
    "home.panel.solutions.link":"More ...",
    "promo.link.contact":"Get in contact ...",
    "promo.subheader.solution": "We offer a wide range of solutions for a simple and modern handling of archery IT challenges for clubs and commercial business.",
    "promo.subheader.consult": "We would be glad to advise you for your increased success.",
    "club.mailNotVerified":"The mail address of your club ist not verified yet. You can not send mails to your club members from here without this verification. Kindly search the mail in your inbox or spam folder or request the resend to this mail address here",
    "club.button.create": "Create club",
    "club.button.search": "Search clubs",
    "club.button.join": "Join club",
    "club.button.exportCU": "Export members",
    "club.button.newEntry": "New club event",
    "club.button.newMessage": "New message",
    "club.button.mail.log": "E-mail logs",
    "club.create.title" : "Create new club",
    "club.edit.title": "Club main data",
    "club.create.name": "Name of the club",
    "club.empty.result": "Could not find any clubs.",
    "club.detail.actual": "Current events",
    "club.detail.calendar": "Event calendar",
    "club.detail.invalidUser": "You are not allowed to edit this club or the members!",
    "club.detail.noUserDetail": "No address",
    "club.detail.comment":"Comment",
    "club.member.header": "Club members",
    "club.member.modal.create.header": "Create new member without a 3D Turnier account?",
    "club.member.modal.create.description": "Notifications and interactions with club events need an active 3D Turnier account of the member",
    "club.member.modal.edit.header": "Edit private data of the member",
    "club.member.modal.delete.header": "Delete member?",
    "club.member.modal.delete.ask": "Do you want to delete the selected member?",
    "club.member.button.search": "Search for members (name, mail, zip, place)",
    "club.member.button.invite":"Invite members",
    "club.member.button.create":"Create member",
    "club.member.type.member":"Membership types",
    "club.member.type.invoice":"Invoice articles",
    "club.group.modal.title":"Group definition",
    "club.group.modal.input":"Enter a new group name",
    "club.group.modal.desc":"Deleting a group will also remove the mapping of the members to this group!",
    "club.group.modal.existing":"Available groups",
    "club.group.modal.functions":"Functions",
    "club.group.modal.role": "Role",
    "club.group.modal.groups": "Groups",
    "club.group.modal.chooseArticle": "Choose membership",
    "club.mail.clubLink":"To the club page ...",
    "club.mail.clubEventLink":"To the event page ...",
    "club.mail.clubuser.request":"Request to join club",
    "club.mail.invitation": ".. wants you to be a member of the club. Kindly follow the below link to the club page and press there join club.",
    "club.mail.ending":"Kind regards",
    "club.need.account": "For this action you will need a 3D SKill Board/3D Turnier account.",
    "club.event.create.title": "Create club event",
    "club.event.create.visibility.title":"Visibility",
    "club.event.create.visibility.desc":"Event is visible for:",
    "club.event.create.visibility.group.desc": "Whole club or just the selected groups:",
    "event.vis.public":"Public",
    "event.vis.club":"Club only",
    "club.event.create.interaction.title":"Interaction",
    "club.event.create.place.title":"Location",
    "club.event.create.place.place":"Enter a location (optional)",
    "club.event.create.message.title":"Message",
    "club.event.create.message.name":"Event name",
    "club.event.create.message.note":"Description",
    "club.event.create.draft":"Draft",
    "club.event.create.publish":"Publish",
    "club.event.create.opt.linksecret":"Code for internal link - allows answer without login",
    "club.event.create.mail":"Notify club members by mail",
    "club.event.create.opt.new":"Enter a new answer option",
    "club.event.create.opt.existing":"Available answers",
    "club.event.modal.delete.header":"Delete club event?",
    "club.event.modal.delete.ask":"Do you  want to delete the selected club event?",
    "club.event.column.answer":"Answers",
    "club.event.column.missing.answer":"No answers given",
    "club.event.filter.current":"Current",
    "club.event.filter.past":"Past",
    "club.event.foreign.notVisible": "This event is only visible for club members",
    "club.event.intern.notVisible": "This event is only visible for defined groups of the club",
    "club.invoice.header":"Overview of receipts",
    "club.invoice.month.header":"Month overview",
    "club.invoice.type.header":"Type overview",
    "club.invoice.button.create":"Add receipt",
    "club.invoice.button.search":"Search for receipt (number, text, description, amount)",
    "club.invoice.type.modal.title":"Define type of receipts",
    "club.invoice.type.modal.input":"Enter a new type of receipt",
    "club.invoice.button.exportInv":"Receipt export",
    "club.invoice.amount.in.true":"Income",
    "club.invoice.amount.in.false":"Outgoings",
    "club.invoice.balance":"Balance",
    "club.invoice.button.tooltip.paid":"Mark receipt as settled",
    "club.invoice.modal.create.header":"Add new receipt",
    "club.invoice.modal.edit.header":"Edit receipt",
    "club.invoice.modal.chooseInvType":"Choose a receipt type",
    "club.invoice.modal.delete.ask":"Do you want to delete the selected receipt?",
    "club.invoice.modal.paid.header":"Mark receipt as settled",
    "club.invoice.modal.paid.ask":"Do you want to mark the selected receipt as settled",
    "club.invoice.checkout":"Settle the invoice",
    "club.invoice.creation":"Create the invoice",
    "club.invoice.customer.club":"Customer from club members",
    "club.invoice.customer.business":"Customer from business accounts",
    "club.invoice.noCustomer":"No customer selected yet!",
    "club.invoice.addNote":"Additional info on the invoice",
    "club.invoice.open":"Pay per invoice",
    "club.invoice.open.pay":"Order now",
    "club.invoice.open.waiting":"Waiting for settlement",
    "club.invoice.paymentTarget.hint":"Need to allow paying per invoice",
    "club.business.newAccount.desc1": "To use the club management without any restrictions you need a confirmed business account.",
    "club.business.link2BusinessAccount.desc" :"Linking the club to your business account with active club management package enables you to use the club management incl appointments, polls and messaging for unlimited members as well as revenue and expense statement. You can only manage 10 members free of charge!",
    "club.business.noClubFeature.desc" :"The club management is not activated in your business account, kindly mail to office@3dturnier.com for more information!",
    "mail.sent.success": "Mail sent successfully",
    "mail.sent.failed": "Mail could not be sent",
    "push.sent.success": "Notification sent successfully",
    "push.sent.failed": "Notification could not be sent",
    "cl_role_owner":"Owner",
    "cl_role_user":"Member management",
    "cl_role_msg":"Messaging",
    "cl_role_invoice":"Revenue and expense statement",
    "cl_role_address":"Address only",
    "cl_role_invcreation":"Invoice management",
    "cl_role_invstat":"Revenue and expense statistic",
    "cl_role_member":"Member",
    "cl_role_exMember":"Member inactive",
    "cl_req_status.R": "Request open",
    "cl_req_status.I": "Invitation open",
    "cl_req_status.A": "accepted",
    "cl_req_status.X": "declined",
    "tournament.title": "Tournaments",
    "tournament.invitation": "Tournament invitation",
    "tournament.info.title": "Tournament info",
    "tournament.info.regStart" : "Tournament registration starts on",
    "tournament.info.wikiLink": "Show course information in Wiki",
    "tournament.info.important": "Important:",
    "tournament.info.app": "Use 3D SKill Board at the tournament",
    "tournament.info.isCupChild":"This tournament is part of the ",
    "tournament.info.isCupChild.link":"Cup results ...",
    "tournament.info.cupChilds":"Single tournaments",
    "tournament.live.groupin": "Groups in ",
    "tournament.live.groups": "Tournament groups",
    "tournament.live.tuserin": "Attendees of ",
    "tournament.live.empty": "Found no live results yet from today for ",
    "tournament.live.noGroupInfo" : "No group info",
    "tournament.live.groupStarted": "Group just started",
    "tournament.live.noStartedRounds" : "There is no tournament round started at the moment",
    "tournament.live.createGroup":"Create group suggestion",
    "tournament.live.draft":"Draft",
    "tournament.live.addTuser":"Add attendee to group (max 6)",
    "Frag_Prep_startposition":"Start target",
    "tournament.sendToApp": "Send to 3D SKill Board",
    "tournament.status.published": "Open",
    "tournament.status.regStart": "Registration starts",
    "tournament.status.published.tooltip":"Prepare tournament - Registration will be shown and tournament is not visible in 3D SKill Board app",
    "tournament.status.register.open" : "registration open",
    "tournament.status.register.closed" : "registration closed",
    "tournament.status.register.full" : "registrations full",
    "tournament.status.register.impossible" : "tournament need to be configured before start registration",
    "tournament.paystatus.active" : "Mobile payment activated",
    "tournament.paystatus.inactive" : "Mobile payment deactivated",
    "tournament.status.running": "Running",
    "tournament.status.running.tooltip":"Start tournament - Intermediate result will be shown and attendees within a started group can also start the tournament on the app",
    "tournament.status.canceled":"Canceled!",
    "tournament.status.closed": "Results online",
    "tournament.status.closed.tooltip":"Finish tournament - Final result will be shown and no changes by apps will be applied any more",
    "tournament.status.present" : "Waiting",
    "tournament.status.cupMasterActive" : "Accepted",
    "tournament.status.finished" : "Finished",
    "tournament.button.noCupLicense": "First cup registration - I don't have the number yet",
    "tournament.button.register": "Tournament registration",
    "tournament.button.unregister": "Unregister",
    "tournament.button.more": "More...",
    "tournament.button.show.more": "more entries ...",
    "tournament.button.menu.manager": "Manager",
    "tournament.button.menu.mmc": "Tournament config",
    "tournament.button.menu.mmtu": "Tournament attendee",
    "tournament.button.menu.scan": "QR scan",
    "tournament.button.menu.files": "Files",
    "tournament.button.menu.rounds": "Round config",
    "tournament.button.becameMainEditor": "take tournament management",
    "tournament.button.manage": "Manage",
    "tournament.button.gotoTournamentDashboard": "tournament management",
    "tournamentUser.button.search": "Search for name or club",
    "tournamentUser.status.registered":"registered",
    "tournamentUser.status.A":"present",
    "tournamentUser.status.S":"started",
    "tournamentUser.status.F":"finished",
    "tournamentUser.status.proof":"1 result",
    "tournamentUser.status.waiting":"waiting",
    "tournamentUser.status.disqualified":"disqualified",
    "tournamentUser.status.D":"deleted",
    "tournament.button.search": "Search tournaments",
    "tournament.button.register.more": "Register other archers",
    "tournament.button.register.groups":"Make group assignment",
    "tournament.status.registration.closed": "Registration closed",
    "tournament.status.registration.notregistered": "Not registered",
    "tournament.status.registration.registered": "Registered",
    "tournament.status.registration.waitinglist": "On waiting list",
    "tournament.attendee.status.paid": "Registration was approved by host.",
    "tournament.attendee.status.pending": "Not yet approved. After payment, the host will approve your registration.",
    "tournament.attendee.status.legend":"Legend",
    "tournament.attendee.status.legend.paid":"Registered, paid",
    "tournament.attendee.status.legend.pending":"Registered, not paid",
    "tournament.attendee.paid": "paid",
    "tournament.attendee.notpaid": "not paid",
    "tournament.attendee.status.legend.exceed": "On the wait list",
    "tournament.empty.result": "Could not find any tournaments.",
    "tournament.button.create": "Create tournament",
    "tournament.filter.current": "Current",
    "tournament.filter.results": "Results",
    "tournament.filter.region": "Region",
    "filter.button.list": "List",
    "filter.button.map": "Map",
    "filter.header.display":"Show in",
    "checkout.info.accept.rules" : "I am confirming the correctness of the information I have given and also accepting the",
    "checkout.info.rules" : "course rules",
    "checkout.info.choose.brand": "Choose payment method:",
    "checkout.info.mail": "After the payment you will receive an email from 3DTurnier based in Austria with the invoice attached as payment confirmation.",
    "checkout.info.forward": "For the payment you will be forwarded to the secure page of our payment provider Stripe Inc. . Your personal credit card or bank account information will only be processed there. But not forwarded to 3D Turnier itself.",
    "checkout.info.parcours.cart": "Course basket",
    "checkout.info.parcours.cart.empty": "There are no articles for payment",
    "checkout.info.asklogin":"Kindly log in to find your transactions also in your profile.",
    "checkout.info.noLogin":"You may proceed without login.",
    "checkout.info.profile":"Your address is needed to create the invoice for you.",
    "checkout.info.need.account.VA.DB":"You need an active PayPal account for the payment!",
    "checkout.info.weblink.VA.DB":"https://www.paypal.com/",
    "checkout.button.payment" : "Pay",
    "checkout.error.articles":"Kindly select at least one article!",
    "checkout.error.paycode":"Kindly select one payment method!",
    "checkout.error.address":"Kindly enter your private data!",
    "tournament.register.panel.title": "Own registrations",
    "tournament.register.panel.pay": "pay registrations",
    "tournament.register.panel.selectedTU": "attendees chosen",
    "tournament.register.panel.selectTUser": "Choose the attendees you want to pay for from let list above",
    "tournament.register.panel.cartTU": "Basket of tournament registrations",
    "tournament.register.panel.info.payInfo": "Just choose the archers your want to pay for! We offer payment via VISA, Mastercard, Google and Apple Pay as well as PayPal.",
    "tournament.register.panel.info.payDesc": "Pay online and save your start number!",
    "tournament.register.panel.info.payInvoice": "After the purchase you will receive an e-mail with the invoice as confirmation.",
    "tournament.register.panel.info.nologin": "You are not logged in. You would find here the registered attendees from you if you log in or decide for an account during the registration for this tournament.",
    "tournament.register.panel.info.noregistration": "No registrations for this tournament from you.",
    "tournament.register.panel.info.nopayableregistration": "No registrations found for payment.",
    "tournament.register.panel.info.paymentDisabled": "Sorry, but the tournament host has not enabled the online payment function.",
    "tournament.register.full": "You can not register for that tournament as all start slots are already assigned",
    "tournament.register.private.info":"You can only register for this tournament with the link of the private invitation!",
    "tournament.register.title": "Confirm tournament registration",
    "tournament.register.fullName": "First and family name",
    "tournament.register.name": "First name",
    "tournament.register.surname": "Family name",
    "tournament.register.search4name": "Search by name in your registrations",
    "tournament.register.email": "e-mail",
    "tournament.register.email.unique": "E-mail (An unique e-mail is needed for each person)",
    "tournament.register.union": "Club",
    "tournament.register.license": "License number if available",
    "tournament.register.mandatory.license": "License number (0000 if not available yet)",
    "tournament.register.password": "Choose password to create an account",
    "tournament.register.note": "Additional notes - eg wish how to group",
    "tournament.register.sex.female": "female",
    "tournament.register.sex.male": "male",
    "tournament.register.cupNo.with":"Tournament registration with ",
    "tournament.register.cupNo.where":"You will find the number of the attendees on older tournament confirmation mails or on the previous tournament results",
    "tournament.register.cupNo.why":"Only tournament results with the same number will be calculated for the overall result",
    "tournament.error.emailInUse": "You registration can not be accepted. There is already a registration to this tournament with the following email:",
    "tournament.error.licenseInUse":"You registration can not be accepted. There is already a registration to this tournament with the following number:",
    "tournament.error.emaildoubled": "You registration can not be accepted. You have to use different e-mails!",
    "tournament.error.loginOrOtherName": "Kindly login or choose another name for the registration.",
    "tournament.error.loginOrOtherEMail": "Kindly login or choose another e-mail for the registration.",
    "tournament.error.noCupTUser":"There is no registration in the older cup tournaments with this number.",
    "tournament.error.noRegForEmail":"There is no registration for you with your account email",
    "tournament.register.placeHolder.noemail": "If you have no email for this person use: ",
    "tournament.class.union": "Association",
    "tournament.class.bow": "Bow style",
    "tournament.class.age": "Age class",
    "tournament.register.description.ageCalc":"Determine your age class",
    "tournament.register.description.ageClassNull":"Could not determine your age class. Kindly choose the age class depending on your tournament age",
    "tournament.register.description.noaccount": "Nobody is logged in at the moment!",
    "tournament.register.description.self": "You register yourself for this tournament!",
    "tournament.register.description.foreign": "You register another archer for this tournament.",
    "tournament.register.description.without":"Register for tournament without account",
    "tournament.register.description.getAccount":"With an 3D SKill Board and 3D Turnier account you can simplify your upcoming tournament registrations.",
    "tournament.register.description.fastRegister":"Fast register and deregister of tournaments",
    "tournament.register.description.reUse":"Reuse your own registration data as well as those of your family and friends",
    "tournament.register.description.useApp":"Using 3D SKill Board App during the tournament instead of a score sheet",
    "tournament.register.label.price": "Total",
    "tournament.register.label.payCondition": "Payment condition",
    "tournament.register.label.payReference": "Payment reference",
    "tournament.register.submit": "Confirm",
    "tournament.register.mail.deRegistrationSubject":"Deregister",
    "tournament.register.mail.registrationConfirm":"Confirmation of registration for",
    "tournament.register.mail.deRegistrationConfirm":"Deregister for",
    "tournament.register.mail.tournamentLink":"To the tournament page ...",
    "tournament.register.mail.bestWished":"Good look for the tournament - your 3D SKill Board team",
    "tournament.register.mail.anyQuestions":"Do you have any questions to this tournament?",
    "tournament.register.mail.askHost":"Feel free to ask the host",
    "tournament.register.mail.addCalendar":"Add to my calendar",
    "tournament.register.mail.findTournaments":"Discover tournaments",
    "tournament.register.mail.features": "3D Turnier enables anybody to create tournaments, manage tournament attendees, and execute the tournament with 3D SKillBoard App support.",
    "tournament.result.panel.title.endresult": "Final result",
    "tournament.result.panel.title.intermediateresult": "Intermediate result",
    "tournament.result.panel.print.full":"Print result PDF",
    "tournament.result.panel.print.third":"Print medal ceremony PDF",
    "tournament.result.panel.medalcount":"Medal count",
    "tournament.result.link.live":"Live overview",
    "tournament.result.panel.title.stat": "Statistics of attendees",
    "gallery.title":"Picture gallery",
    "gallery.show.all":"all pictures ...",
    "gallery.picture.report":"Report picture",
    "gallery.picture.reported":"Picture reported",
    "tournament.attendees.panel.title": "Table of attendees",
    "tournament.attendees.panel.subtitle": "Registrations",
    "tournament.table.column.position":"Position",
    "tournament.table.column.bow":"Bow",
    "tournament.table.column.name":"Name",
    "tournament.table.column.teamname": "Team name",
    "tournament.table.column.archer": "Archer",
    "tournament.table.column.archers": "Archers",
    "tournament.table.column.date": "Date",
    "tournament.table.column.time": "Time",
    "tournament.table.column.union":"Club",
    "tournament.table.column.status":"Status",
    "tournament.table.column.points":"Points",
    "tournament.table.column.sumPoints": "Points",
    "tournament.table.column.arrows": "Amount of arrows",
    "tournament.table.column.cupPoints": "Cup points",
    "tournament.table.column.target": "Target",
    "tournament.table.column.amount": "Quantity",
    "tournament.table.column.sum": "Sum",
    "tournament.table.column.article.sum": "Article sum",
    "tournament.table.column.discount": "Discount",
    "tournament.table.column.correction": "Correction",
    "tournament.table.column.profit": "Profit",
    "tournament.table.column.loss": "Loss",
    "tournament.table.column.provision":"Charge",
    "tournament.table.column.income":"Income",
    "tournament.table.column.invoice" : "Invoice",
    "tournament.table.column.type":"Type",
    "tournament.table.column.address":"Address",
    "tournament.table.column.number":"Nr",
    "tournament.table.column.inout":"Amount",
    "tournament.table.column.text":"Text",
    "tournament.table.column.article":"Article",
    "tournament.table.column.surcharge":"Surcharge",
    "tournament.table.column.page":"Page",
    "tournament.table.column.grpCode":"Grp code",
    "tournament.table.column.active":"Active",
    "tournament.table.column.image":"Picture",
    "tournament.table.column.color":"Color",
    "tournament.table.column.of":"of",
    "tournament.table.column.group": "Group",
    "tournament.table.column.note": "Additional notes",
    "tournament.table.column.note.visit": "Additional notes (bon-, member-, Jahreskartennummer)",
    "tournament.table.column.memberamount": "Team size",
    "tournament.table.column.positions":"Positions",
    "tournament.table.column.paid":"Paid, amount",
    "tournament.table.column.licenseNumber":"License#",
    "tournament.table.column.dps":"DPS",
    "tournament.table.column.tax":"Tax",
    "tournament.table.column.details":"Detail",
    "tournament.table.column.arrPasse":"Arrows per end",
    "tournament.table.column.result":"Result",
    "tournament.table.column.updatedAt":"Change",
    "tournament.manager.bowage.conflict":"Bow/Age class conflict",
    "tournament.create.title": "Create new tournament",
    "tournament.create.name": "Title of tournament",
    "tournament.create.union": "Name of host or club",
    "tournament.create.union.link": "Link to home page",
    "tournament.create.zip": "Zip",
    "tournament.create.place": "Location",
    "tournament.create.date": "Start date (dd.mm.yyyy)",
    "tournament.create.enddate": "Finishing date (dd.mm.yyyy)",
    "tournament.create.days": "Days",
    "tournament.create.submit": "Create tournament",
    "tournament.create.error.required": "Missing required fields",
    "tournament.create.date.before":"The end date have to be after the start date",
    "tournament.create.time" :"Start time",
    "tournament.create.endtime" :"End time",
    "tournament.manager.bannerImageID": "Tournament banner",
    "tournament.manager.imageID": "Tournament invitation",
    "tournament.manager.resultImageID": "Tournament result",
    "tournament.manager.sponsorImageID":"Banner of the sponsor",
    "tournament.manager.status":"Tournament status",
    "tournament.manager.NotFound":"Tournament could not be found",
    "tournament.manager.file.title": "Upload of tournament banner, invitation and result pdf",
    "tournament.manager.config.title": "Configuration of the tournament, parcours, prices, age- and bow classes",
    "tournament.manager.config.parcours":"Choose parcours",
    "tournament.manager.config.slots":"Registration slots",
    "tournament.manager.config.slots.desc":"At the registration an attendee need to select a registration slot. If a slot is full, no further registrations are possible. Without slots, attendees can register as long as the registration remains open.",
    "tournament.manager.config.slots.add":"Add slot",
    "tournament.manager.config.slots.button.delete.desc":"Do you want to delete the selected slot?",
    "tournament.manager.config.slots.full":"Registration not possible - no free time frames or slots are free any more",
    "tournament.manager.slot.modal.title":"Create or edit registration slot",
    "tournament.manager.slot.modal.desc":"Kindly enter a name and the max amount of registration for this slot",
    "Dialog_Body_tournament_error_slot":"No registration is possible for the registration slot chosen",
    "tournament.manager.config.advanced":"Advanced tournament configuration",
    "tournament.manager.config.bowUnion":"Choose archery association, Cup or Trophy or your own rules.",
    "tournament.manager.config.cupMaster":"Kindly enter the url to the cup master tournament:",
    "tournament.manager.config.cupMaster.notActive":"The cup master tournament is not activated yet - the configuration is still not finished",
    "tournament.manager.config.cupMaster.wrongBowUnion":"The tournament belongs to another association or cup or trophy",
    "tournament.manager.config.cupMaster.NotMaster":"The tournament is a cup/trophy child - a cup master tournament is needed",
    "tournament.manager.config.bowAgeConfig.desc":"You can edit the selected bow style and age class further more in the next step.",
    "tournament.manager.config.maxAttendees":"Attendees limit",
    "tournament.manager.config.desc.label":"Important information about tournament procedure",
    "tournament.manager.config.desc.placeholder":"Start as you come or start at the target, registration and starting times,...",
    "tournament.manager.config.cupOptions":"Cup/Trophy settings",
    "tournament.manager.config.minCupResults":"Min amount of attended tournament for the cup result",
    "tournament.manager.config.baseTargets":"Cup order by using score based to this target amount",
    "tournament.manager.config.cupPoints":"Cup points for the standing - starts with 1st, 2nd, 3rd,..(CSV)",
    "tournament.manager.config.doNotEdit": "Attention, this tournament is already active - changing the configuration now could be fatal!",
    "tournament.manager.config.startReg":"Release tournament registration",
    "tournament.manager.config.attendFinale":"Participate on last tournament is mandatory for cup result",
    "tournament.manager.config.combineAge":"Combine age groups for cup result",
    "tournament.manager.config.combineAge.tConfig":"Master age group for combined groups",
    "tournament.manager.tConfig.pairReg":"Registration with pair archers",
    "tournament.manager.tConfig.selCupGroup":"Select a group for the cup sub-tournament",
    "tournament.manager.tConfig.agebowmodal.desc":"Please enter a name for the group at least. You can also enter a code and a surcharge which will be added to the base price.",
    "tournament.manager.tConfig.agebowmodal.countType":"Choose scoring",
    "tournament.manager.tConfig.agebowmodal.sexSplitHead":"Gender split the group",
    "tournament.manager.tConfig.agebowmodal.sexSplitBody":"split",
    "tournament.manager.tConfig.agebowmodal.ages":"Age constraints (<=, >=)",
    "tournament.manager.tConfig.payment.desccondition":"Cash, bank transfer, banking information ...",
    "tournament.manager.tConfig.payment.price":"Base prise",
    "tournament.manager.tConfig.payment.surprice":"Surcharge cash",
    "tournament.manager.tConfig.payment.pay4start":"Start point accepted by pre-payment",
    "tournament.manager.tConfig.payment.regMailCC":"Receive copy of registration mails",
    "tournament.manager.tConfig.complexPriceHandling":"Extended price calculation",
    "tournament.manager.tConfig.complexPriceHandling.desc":"The price for the attendee is the sum of the base price and the surcharge for age class. Surcharge cash counts only on the tournament day itself",
    "tournament.manager.tConfig.payment.paymail":"Mail address for questions and target for registration confirmation mail",
    "tournament.manager.tConfig.parcoursSelection":"The tournament needs a parcours from the parcours wiki. If your parcours is still missing - you need to add it to the parcours wiki before.",
    "tournament.manager.tConfig.parcours.manPoints":"Allow the entering of manual points in the app (eg for special targets)",
    "tournament.manager.tConfig.parcours.manTUGroups":"Tournament attendees can create their group in app/web and start themselves",
    "tournament.manager.tConfig.parcours.chooseTUGroups":"Tournament attendee can select a predefined group at the start manually(Rental device)",
    "tournament.manager.tConfig.optionSelection":"Additional selection field - one option have to be chosen at the registration process",
    "tournament.manager.tconfig.option":"Additional selection field for registration of an archer",
    "tournament.manager.tconfig.option.surcharge":"Surcharge for selection",
    "tournament.manager.tconfig.cup.surcharge":"surcharge for cup/trophy child tournament",
    "tournament.manager.tconfig.warning.noPrice":"Attention - No prices are stated in the age classes!",
    "tournament.manager.wait4SubTournaments":"Handle the cup/trophy child tournaments now",
    "tournament.manager.startConfig" : "Configuration of the tournament",
    "tournament.manager.openRegistration" :"Registration open",
    "tournament.manager.closeRegistration" :"close",
    "tournament.manager.fullRegistration" : "full",
    "tournament.manager.sortBy":"Sort by",
    "tournament.manager.group.round.start":"Start the group for round:",
    "tournament.manager.group.round.selection":"Set the round for group",
    "tournament.manager.public.groups":"Show attendees groups to the public",
    "tournament.manager.fixedABCD.groups":"Hold attendees group position(ABCD..)",
    "tournament.manager.hide.points":"Hide attendees points during round",
    "tournament.manager.prepareTournament":"prepare",
    "tournament.manager.startTournament":"start tournament",
    "tournament.manager.startRound":"start round",
    "tournament.manager.closeTournament":"finish",
    "tournament.manager.notAllRoundsClosed":"You need to finish all rounds before you finish the tournament itself.",
    "tournament.manager.takeManagement": "Take the management for the tournament to access the management and configuration area.",
    "tournament.manager.free": "Registration and administration of the tournament attendees ist free of charge.",
    "tournament.manager.chargeable": " The execution of the tournament via 3DTurnier and 3D SKill Board will be charged.",
    "tournament.manager.chargeable.tournament": "The tournament manage package enables you to host tournaments without limitations. Otherwise each tournament can be charged separate.",
    "tournament.manager.chargeable.payment": "The mobile payment package enables you to let your attendees pay the tournament fee directly on 3Dturnier.com",
    "tournament.manager.invalidUser": "You are not allowed to edit this tournament!",
    "tournament.manager.nextStep": "Next step",
    "tournament.manager.delete": "Delete tournament",
    "tournament.manager.delete.desc": "Do you want to delete this tournament?",
    "tournament.manager.editors": "Tournament editors",
    "tournament.manager.editMaster": "Tournament master data",
    "tournament.manager.canceled" : "Tournament canceled!",
    "tournament.manager.link2BusinessAccount": "Manage with existing business account?",
    "tournament.manager.link2BusinessAccount.desc": "Linking the tournament to your business account enables you to use online payment, attendee grouping and 3D SKill Board App support. Without a business account you can only use the free of charge tournament registration portal!",
    "tournament.manager.newAccount.desc1": "To host a complete tournament you need a confirmed business account.",
    "tournament.manager.newAccount.desc2": "As soon as the tournament is linked with a course (Config -  3D SKill Board Configuration) you might create an account from here.",
    "tournament.manager.newAccount.button": "Create account and request management",
    "tournament.manager.populateResultImage": "Export the result as PDF in the tournament view and upload it in the file config in the tournament result section.",
    "tournament.manager.round.button.add":"Add tournament round",
    "tournament.manager.round.button.delete.desc":"Do you want to delete the selected round?",
    "tournament.manager.round.button.delete.warning":"ATTENTION! You must not delete started rounds - otherwise the started groups cannot confirm their result!",
    "tournament.manager.round.modal.title":"Create or edit tournament round",
    "tournament.manager.round.modal.desc":"Kindly state the name, quantity of targets as well as the type of the round.",
    "tournament.manager.round.modal.alert":"Archers can not enter more target results in the 3D SKill Board App as configured quantity of targets in each round!",
    "tournament.manager.round.noSingleRounds":"For a group tournament (Registration type pair) or a lippek tournament (union lippek) you can not use these rounds.",
    "tournament.manager.round.qualify.title": "A) Simple and qualification rounds",
    "tournament.manager.round.qualify.desc":"These rounds will be executed one after another and the archers results of each round will be summed up. You can configure three rounds max.",
    "tournament.manager.round.final.title": "B) Finale and elimination rounds",
    "tournament.manager.round.final.desc":"These rounds will be executed after group A rounds and the finale archers will be chosen by their group A results.  The archers results of each final round will be summed up. You can configure two rounds max. The type of the first final round will be used for calculation and the type of the second will be ignored.",
    "tournament.manager.round.groups.title": "C) Group rounds",
    "tournament.manager.round.groups.desc": "A group of archers will be combined to one common result.",
    "tournament.manager.round.extra.title": "D) Special",
    "tournament.manager.round.extra.desc": "No separate round - shows additional result on base of the qualification rounds.",
    "tournament.manager.round.parcoursSelection":"Choose this parcours for this round. (Instead of the parcours from the Tournament Config)",
    "tournament.manager.cup.result": "Cup result",
    "tournament.manager.cup.intermediate": "Cup intermediate",
    "bowUnion.type.S":"Single tournament",
    "bowUnion.type.C":"Cup/Trophy tournament",
    "bowUnion.type.CM":"Cup-master",
    "bowUnion.type.CC":"Cup-child",
    "tournament.round.type.S":"Simple",
    "tournament.round.type.S.defRoundName":"Tournament round",
    "tournament.round.type.S.desc":"The archer can only compete once in this round and this result will be assessed.",
    "tournament.round.type.B":"BestOf",
    "tournament.round.type.B.defRoundName":"BestOf round",
    "tournament.round.type.B.desc":"The archer can compete several times in this round and his best result will be assessed.",
    "tournament.round.type.F0":"Finale (start 0)",
    "tournament.round.type.F0.defRoundName":"Final round",
    "tournament.round.type.F0.desc":"The finale starts for all competing archers with zero points.",
    "tournament.round.type.F1":"Finale (additive)",
    "tournament.round.type.F1.defRoundName":"Final round",
    "tournament.round.type.F1.desc":"The finale starts for all competing archers with the points from group-A rounds.",
    "tournament.round.type.L":"Lippek",
    "tournament.round.type.L.defRoundName":"Lippek round",
    "tournament.round.type.L.desc":"The result will be weighted with a factor depending on the bow style. * Button to configure the factor. Needs union type lippek in the tournament config",
    "tournament.round.type.GL":"Lippek-Team",
    "tournament.round.type.GL.defRoundName":"Lippek round",
    "tournament.round.type.GL.desc":"The result of each archer will be weighted with a factor depending on the bow style and accumulated finally. * Button to configure the factor." ,
    "tournament.round.type.GT":"Team",
    "tournament.round.type.GT.defRoundName":"Team round",
    "tournament.round.type.GT.desc":"The result of each archer in will be accumulated to one team result.",
    "tournament.round.type.GR":"Team ranking",
    "tournament.round.type.GR.defRoundName":"Team ranking",
    "tournament.round.type.GR.desc":"No separate round. The result of each archer from the group-A rounds will be accumulated to one team result..",
    "tournament.round.type.EI":"Inklusion ranking",
    "tournament.round.type.EI.defRoundName":"Inklusion ranking",
    "tournament.round.type.EI.desc":"A result which ignores the sex and age but ranks only by bow types.",
    "tournament.manager.tConfig.Manipulator.modal.title":"Configure the factor",
    "tournament.manager.tConfig.Manipulator.modal.desc":"Enter for each type a factor, the round result will be weighted with it",
    "tournament.mmtuser.action.single":"Single action",
    "tournament.mmtuser.action.several":"Several action",
    "tournament.mmtuser.action.all":"List action",
    "tournament.mmtuser.tooltip.new":"New attendee",
    "tournament.mmtuser.tooltip.edit":"Edit attendee data",
    "tournament.mmtuser.tooltip.editScore":"Edit attendee score",
    "tournament.mmtuser.tooltip.paid":"Attendee has paid",
    "tournament.mmtuser.tooltip.regpaid":"Attendee paid & present",
    "tournament.mmtuser.tooltip.sppaid":"Attendee paid with cash surcharge",
    "tournament.mmtuser.tooltip.spregpaid":"Attendee paid with cash surcharge & present",
    "tournament.mmtuser.tooltip.freepaid":"Attendee free of charge",
    "tournament.mmtuser.tooltip.unpaid":"Attendee has NOT paid",
    "tournament.mmtuser.tooltip.R":"Attendee status registered",
    "tournament.mmtuser.tooltip.A":"Attendee status present",
    "tournament.mmtuser.tooltip.S":"Attendee status started",
    "tournament.mmtuser.tooltip.P":"Attendee status finished with single result only",
    "tournament.mmtuser.tooltip.F":"Attendee status finished",
    "tournament.mmtuser.tooltip.W":"Attendee status waiting",
    "tournament.mmtuser.tooltip.X":"Attendee status disqualified",
    "tournament.mmtuser.tooltip.D":"Delete attendee",
    "tournament.mmtuser.tooltip.download":"Download attendee list as csv",
    "tournament.mmtuser.tooltip.sync":"Reload attendees",
    "tournament.mmtuser.tooltip.mail":"Send mail to the filtered attendees",
    "tournament.mmtuser.tooltip.group.new":"Create new group with attendees",
    "tournament.mmtuser.tooltip.team.new":"Create new team with attendees",
    "tournament.mmtuser.tooltip.group.sync":"Reload groups",
    "tournament.mmtuser.tooltip.team.sync":"Reload teams",
    "tournament.mmtuser.tooltip.group.autogen":"Group generator",
    "tournament.mmtuser.tooltip.group.autogen.desc":"Attendees without a group will be grouped according to these settings ...",
    "tournament.mmtuser.tooltip.group.startAll":"Start all groups AT round: ",
    "tournament.mmtuser.tooltip.group.startRound":"Start present groups of round: ",
    "tournament.mmtuser.tooltip.group.start":"Start group",
    "tournament.mmtuser.tooltip.group.reUse":"Set group present again",
    "tournament.mmtuser.tooltip.group.pause":"Reset groups for changing the round",
    "tournament.mmtuser.tooltip.group.addTuser":"Add selected attendees to group",
    "tournament.mmtuser.tooltip.group.finish":"Mark group as finished",
    "tournament.mmtuser.tooltip.team.addTuser":"Add selected attendees to team",
    "tournament.mmtuser.tooltip.group.del":"Delete group",
    "tournament.mmtuser.tooltip.team.del":"Delete team",
    "tournament.mmtuser.tooltip.team.present":"Mark team as present",
    "tournament.mmtuser.tooltip.group.delUser":", remove from group",
    "tournament.mmtuser.tooltip.group.delEmpty":"Remove placeholder",
    "tournament.mmtuser.tooltip.group.draft":"Allow creator change group",
    "tournament.mmtuser.tooltip.group.remUser":"Remove selected from their groups",
    "tournament.mmtuser.tooltip.team.delUser":", remove from team",
    "tournament.mmtuser.icon.R":"registered",
    "tournament.mmtuser.icon.A":"check-circle",
    "tournament.mmtuser.icon.S":"play-circle",
    "tournament.mmtuser.icon.P":"bullhorn",
    "tournament.mmtuser.icon.F":"flag",
    "tournament.mmtuser.icon.W":"pause",
    "tournament.mmtuser.icon.X":"ban",
    "tournament.mmtuser.icon.D":"trash",
    "tournament.mmtuser.showdeleted":"Show deleted attendees and groups",
    "tournament.mmtuser.search4name": "Search by name or cup# in all registrations",
    "tournament.mmtuser.config.warning":"The configuration is missing: ",
    "tournament.mmtuser.group.noStartedRound":"For starting groups, a started round is needed",
    "tournament.mmtuser.team.noStartedRound":"For marking a team as present, a started round is needed",
    "tournament.mmtuser.group.round.confirm":"Confirm the amount of targets",
    "tournament.mmtuser.mode.R":"Registration",
    "tournament.mmtuser.mode.G":"Grouping",
    "tournament.mmtuser.mode.A":"Scoring",
    "tournament.mmtuser.teams":"Tournament teams",
    "tournament.modal.score.tround":"Choose tournament round",
    "tournament.modal.score.troundUser":"Choose result",
    "tournament.modal.score.enterNewResult":"Enter a new result",
    "tournament.modal.score.calc.points":"Calculated points",
    "tournament.modal.score.calc.hitmiss":"Targets / hits / miss",
    "tournament.modal.score.man.correction":"Correction +/-",
    "tournament.modal.score.nan.points":"The calculated points are not a valid number",
    "tournament.modal.tuser.filter":"Filter attendees",
    "tournament.modal.tuser.filter.reset":"Reset filter",
    "tournament.modal.score.changes":"Changes",
    "tournament.modal.score.changes.desc":"Following changes were made:",
    "tournament.modal.score.compareErrors":"Score validation",
    "tournament.modal.score.compareErrors.desc":"The results entered through the apps do not match",
    "Dialog_Body_tournament_invalidKills":"The different amounts of kills do not match!",
    "Dialog_Body_tournament_invalidPoints":"The entered scores do not match!",
    "tournament.modal.group.maxTUser":"Max size of the group",
    "tournament.modal.group.maxTUserClub":"Max attendee of the same club",
    "tournament.modal.group.rankingTUser":"Only the first # of each class",
    "tournament.modal.group.paidonly":"Group paid attendees only",
    "tournament.modal.group.generate":"Generate groups",
    "tournament.modal.group.maxClub":"Max amount of the same club members",
    "tournament.modal.group.rankingCup":"Cup ranking of the best",
    "tournament.modal.cupcard":"Create your ID-card",
    "tournament.rules.shoot": "Shooting",
    "tournament.rules.back": "Recoil und shot through",
    "tournament.rules.count": "Scoring",
    "parcours.articles.form.name":"Name",
    "parcours.articles.form.group":"Group",
    "parcours.articles.form.currency":"Currency",
    "parcours.articles.form.price":"Price",
    "parcours.articles.form.brand": "Brand",
    "parcours.articles.form.create":"Add new article",
    "parcours.articles.info.allCourses":"Articles for all your courses:",
    "parcours.articles.info.singleCourse":"Articles for:",
    "parcours.articles.link":"Go to payment page",
    "parcours.article.input.invalid":"You entered invalid values for an article",
    "parcours.title": "Course Wiki",
    "parcours.button.create": "Create entry",
    "parcours.button.search": "Search for courses",
    "parcours.button.search.placeholder": "Name, place or ZIP",
    "parcours.button.sendToApp": "Transfer to app",
    "parcours.transactions.header": "Transactions",
    "parcours.transactions.table.header": "Transaction list",
    "parcours.transactions.table.flag.invalid":"Show also invalid transactions",
    "parcours.transactions.table.download": "Download as csv",
    "parcours.transactions.stats.header": "Statistic",
    "parcours.transaction.empty":"No transactions were found",
    "parcours.transactions.settlement.header":"Transaction settlement",
    "parcours.transactions.settlement.button.calc":"Run settlement settlement",
    "parcours.transactions.settlement.button.invoice":"Create invoice",
    "parcours.transactions.settlement.invoice.send":"Send invoice to host",
    "parcours.transactions.settlement.empty":"No settlement done yet",
    "parcours.transactions.settlement.createdAt":"Created at",
    "parcours.transactions.settlement.invoice.missing":"Invoice is not available, yet",
    "parcours.transactions.settlement.mail.monthsettle":"Month settlement",
    "parcours.transactions.settlement.mail.invoiceLinkDesc":"You can download the settlement by following this link:",
    "parcours.transactions.settlement.mail.transactionLinkDesc":"More details about the settlement you will find in the transaction overview:",
    "parcours.transactions.settlement.mail.mfg":"Kind regards",
    "parcours.transactions.settlement.mail.skbteam":"3D SKill Board Team",
    "parcours.tracks.header":"Course tracks",
    "parcours.targets.header":"Course targets",
    "parcours.targets.edit.header":"Course targets editing",
    "parcours.targets.catalog.loading":"Loading target catalog",
    "parcours.targets.catalog.done":"Target catalog ready - entries:",
    "parcours.targets.action.delete":"Do you want to delete the selected target",
    "parcours.targets.input.image":"Image url",
    "parcours.targets.input.log":"Change log of target from app only",
    "parcours.targets.input.peg":"Peg",
    "parcours.targets.input.edit":"Change target/peg",
    "parcours.targets.input.name":"add a new target:",
    "parcours.targets.input.name.target":"Target name",
    "parcours.targets.input.name.search":"Search catalog with name, art# or company",
    "parcours.targets.map.edit.header":"Click edit at the particular target or add a new one",
    "parcours.targets.action.renumber":"ReNumber targets",
    "parcours.targets.action.renumber.notneeded":"The targets have still correct position numbers",
    "parcours.targets.action.invert":"Rotate course direction",
    "parcours.targets.action.replace":"Overwrite current target with these values",
    "parcours.targetStatus.P":"Ziele Sync&Map sowie Änderungen(App) nur für 3D SKill Board Premium",
    "parcours.targetStatus.E":"Targets sync&map active for all - changeable only by course admin",
    "parcours.targetStatus.A":"Targets sync&map and changeable(App) active for all users",
    "parcours.targetStatus.P":"Targets sync&Map and changeable(App) only for 3D SKill Board Premium",
    "parcours.targetStatus.I":"Targets sync&map inactive",
    "parcours.invalidUser": "You are not allowed to edit the course.",
    "parcours.invalidUser.desc":"Either you are not among the editors or the course package has not been acquired.",
    "parcours.invalidUser.editor": "Only editors of the course are allowed to make changes.",
    "parcours.ranking.header": "Archers ranking",
    "parcours.ranking.empty":"No entries were found",
    "parcours.ranking.noParcoursFeature": "The ranking as well as the synchronizable course targets are not available for this course as the course package has not been acquired",
    "parcours.visit.header": "Course book",
    "parcours.visit.live":"On the course yet",
    "parcours.visit.amount": "Amount of archers",
    "parcours.visit.moreNames":"Kindly enter the names of the further archers",
    "parcours.visit.button.close": "Enter finish time",
    "parcours.visit.button.delete":"Do you want to delete this course book entry?",
    "parcours.visit.button.newEntry":"New entry for course ...",
    "parcours.visit.disabled":"On this course the digital course book is not activated!",
    "parcours.visit.pay.online": "Pay online with PayPal, credit card or Sofortüberweisung!",
    "parcours.visit.not.closed":"You have not left the course. Kindly close the book entry as soon as you leave the course.",
    "parcours.visit.description.getAccount":"With an 3D SKill Board and 3D Turnier account you can simplify your upcoming registrations.",
    "parcours.visit.description.reUse":"Your registration data will be filled automatically ",
    "parcours.visit.description.amount":"The amount of entries in the given time frame: ",
    "parcours.visit.description.scan1": "1) Scan QR with smart phone",
    "parcours.visit.description.scan2": "2) Enter your data with 3D SKill Board App or on the web 3DTurnier.com",
    "parcours.visit.description.empty":"You need to enable the course book in the course management for your courses!",
    "parcours.visit.enter.data":"Kindly enter your data for this course visit in the given form:",
    "parcours.visit.list.header": "Course book history",
    "parcours.visit.accept":"I accept to publish my name for monitoring by the host during my stay.",
    "parcours.visit.pay.header":"Payment method",
    "parcours.visit.pay.cash":"Pay cash",
    "parcours.visit.pay.mobile":"Pay online mobile",
    "parcours.visit.pay.annual":"Annual ticket, member",
    "parcours.visit.pay.cash.hint":"Enter voucher id",
    "parcours.filter.header":"Course filter",
    "parcours.filter.placeholder":"Choose course",
    "statistics.column.EventArrow":"Course arrows",
    "statistics.column.TArrow":"Tournament arrows",
    "statistics.column.TrainingArrow":"Training arrows",
    "statistics.column.EventDuration":"Course duration",
    "statistics.column.TDuration":"Tournament duration",
    "statistics.column.TrainingDuration":"Training duration",
    "statistics.header": "Statistic of the year",
    "statistics.column.event.amount": "Events",
    "statistics.column.hit":"Hits",
    "statistics.column.miss":"Miss",
    "statistics.column.arrows":"Arrows",
    "statistics.column.duration":"Duration",
    "statistics.column.event.percent": "Percentage",
    "statistics.column.iOS":"iOS",
    "statistics.column.ANDR":"Android",
    "statistics.column.WEB":"Web",
    "statistics.column.EvDPS":"Course DPS",
    "statistics.column.TDPS":"Tournament DPS",
    "statistics.header.wikiclick":"Opened wiki page",
    "statistics.header.wikieditclick":"Opened wiki edit page",
    "statistics.header.payclick":"Opened online payment",
    "statistics.header.finishedEvents":"Finished events",
    "statistics.header.finishedArchers":"Amount of archers within finished events",
    "statistics.header.finishedTargets":"Amount of targets within finished events",
    "ParcoursArticle_Group_single":"Single",
    "ParcoursArticle_Group_multi":"Bunch",
    "ParcoursArticle_Group_member":"Membership",
    "ParcoursArticle_Group_voucher": "Voucher",
    "ParcoursArticle_Group_invoice": "Invoice",
    "parcours.edit.message.noValue":"Saving is not possible - You entered no value!",
    "parcours.edit.message.valueNotChanged":"Saving is not possible - The value has not changed!",
    "parcours.edit.message.saved":"Saved and published successfully",
    "parcours.edit.message.noLogin":"You need to login before you are allowed to apply changes",
    "parcours.edit.poBanner":"Club banner Image",
    "parcours.details.targets": "targets",
    "parcours.details.area.parcours":"Course:",
    "parcours.details.area.constraints":"Constraints:",
    "parcours.details.area.infra":"Infrastructure:",
    "parcours.details.area.association":"Association:",
    "parcours.details.area.rating": "Rating",
    "parcours.details.area.rateEntry": "Rate this entry",
    "parcours.details.area.rating.comment": "Your comment about this entry",
    "parcours.details.area.allratings": "All ratings",
    "parcours.details.area.prices":"Prices",
    "parcours.details.area.openTimes":"Opening hours",
    "parcours.details.area.supportTimes":"Support times",
    "parcours.details.area.dayprice":"Single round or day fee",
    "parcours.details.area.specialprice":"Special fee",
    "parcours.details.area.yearprice":"Membership or year fee",
    "parcours.details.area.coordinates":"Coordinates",
    "parcours.details.area.webpage":"Web page",
    "parcours.details.area.description.owner":"Additional description of the place",
    "parcours.details.area.description.parcours":"Additional description of the course",
    "parcours.details.area.targetamount":"Quantity of targets",
    "parcours.details.area.contact":"Contact",
    "parcours.details.area.coordinate.missing":"The coordinate of the course is missing in wiki",
    "parcours.details.area.pricesAtimes":"Prices and opening times",
    "parcours.details.area.ownerInfo":"Club information - valid for all courses of this club",
    "parcours.details.area.parcoursInfo":"Course information",
    "parcours.details.button.actions":"Actions",
    "parcours.details.button.editParcours":"Edit course",
    "parcours.details.button.addParcours":"Add new wiki entry here",
    "parcours.details.button.takeManagement":"Request course management",
    "parcours.details.button.deleteParcours":"Delete course",
    "parcours.details.ask.deleteParcours": "Do you want to delete the selected course?",
    "parcours.details.button.seeChangeLog":"See change log",
    "parcours.details.edit.description":"Each change will be saved and published immediately by pressing the Save Button or de/activate the checkboxes!",
    "parcours.details.imageGallery.add":"Add a new image to the course gallery",
    "parcours.details.add.moreInfo":"We have only limited information about this course - kindly add some more details if you know them well.",
    "parcours.details.last.change":"Last change",
    "parcours.checkout.title": "Parcours checkout",
    "cat_equip" : "Borrow equipment",
    "cat_equip_request" : "Borrow equipment only on request",
    "cat_shop" : "Archery shop",
    "cat_dog" : "Dogs allowed",
    "cat_rooms" : "Guest room",
    "cat_hotel" : "Hotel",
    "cat_webshop": "Online shop",
    "cat_shootcinema" : "Archery cinema",
    "cat_diskus" : "Sporting clays",
    "cat_toilet" : "Toilet",
    "cat_camping" : "Camping",
    "cat_indoor" : "Indoor shooting range",
    "cat_cantina" : "Cantina",
    "cat_type_traditional" : "Traditional bow usage",
    "cat_type_compound" : "Compound bow usage",
    "cat_type_crossbow" : "Crossbow usage",
    "cat_type_blowgun" : "Blow gun usage",
    "cat_nightparcours" : "Night course also",
    "cat_buggy" : "Suitable for buggy",
    "cat_wheel_chair" :"Suitable for wheel chair",
    "cat_refreshment": "Refreshment",
    "cat_area_flat" : "Course area is flat",
    "cat_area_steep" : "Course area is steep",
    "cat_area_damp" : "Course is often damp",
    "cat_area_forest" : "Course mostly in the forest",
    "cat_area_lawn" : "Course mostly on grass",
    "type_private" : "For member only",
    "type_request" : "Opens only on request",
    "type_summer" : "Opens only in summer",
    "type_winter" : "Opens only in winter",
    "type_closed" : "closed",
    "type_oneHitOnly_PP" : "Only one hit scoring",
    "ass_oebsv":"Ö-Bogensportverband",
    "ass_oebst":"Ö-Bogensporttag",
    "ass_oebrsv":"Ö-Blasrohrsportverband",
    "ass_dsb":"D-Schützenbund",
    "ass_dfbv":"D-Feldbogenverband",
    "ass_dbsv":"D-Bogensportverband",
    "ParcoursInfo_private_parcours" : "Private od club only",
    "ParcoursInfo_type_P_parcours" : "3D course",
    "ParcoursInfo_type_F_field" : "Field course",
    "ParcoursInfo_type_T_target" : "Target range",
    "ParcoursInfo_type_C_cinema" : "BowSim 4D",
    "ParcoursInfo_parown_openDays":"Opening days:",
    "day_mo" : "Monday",
    "day_tu" : "Tuesday",
    "day_we" : "Wednesday",
    "day_th" : "Thursday",
    "day_fr" : "Friday",
    "day_sa" : "Saturday",
    "day_su" : "Sunday",
    "day_ph" : "Public holiday",
    "businessDetail.request.title":"Request management of parcours",
    "businessDetail.request.new.header":"Request management with a new business account",
    "businessDetail.request.exists.header":"Request management with an existing business account",
    "businessDetail.request.enterNewContact":"Add new contact information",
    "businessDetail.request.form.header":"Enter your contact information",
    "businessDetail.request.form.union":"Company or club name",
    "businessDetail.request.form.contactName":"Contact person name",
    "businessDetail.request.form.street":"Street",
    "businessDetail.request.form.zip":"Zip",
    "businessDetail.request.form.place":"Place",
    "businessDetail.request.form.phone":"Telephone number",
    "businessDetail.request.form.mail":"E mail",
    "businessDetail.request.form.cardName":"Name of account holder",
    "businessDetail.request.form.cardBIC":"BIC",
    "businessDetail.request.form.cardIBAN":"IBAN",
    "businessDetail.request.form.submit":"Request",
    "translator.title": "3D SKill Board translation",
    "translator.change.column": "Show or hide columns:",
    "translator.info.save":"Press ENTER in the input field to save the change",
    "translator.admin.addKey":"Name for the KEY for a new entry:",
    "translator.admin.expFile":"Download language files for:",
    "translator.admin.chooseType":"Choose type",
    "translator.admin.toggle":"Activate entry:",
    "translator.admin.toggle.info":"Choose entry",
    "translator.admin.search":"Press ENTER to search for key and translations",
    "translator.info.hideFilled":"Hide filled entries",
    "profile.account.user.header": "User account",
    "profile.account.user.login.header": "Account data",
    "profile.account.user.data.header": "Private data",
    "profile.account.user.edit": "Edit address",
    "profile.account.user.createDetail": "add address",
    "profile.account.user.image":"Image",
    "profile.account.clubuser.data.header": "Club data",
    "profile.account.business.header": "Business account",
    "profile.account.business.subheader": "Managed courses",
    "profile.account.business.editors": "Manage editors",
    "profile.account.business.settings": "Business account settings",
    "profile.account.business.paymentTarget": "Edit payment target",
    "profile.account.business.paymentTarget.header":"Payment information",
    "profile.tournament.waiting.header": "Tournament registrations",
    "profile.tournament.finished.header": "Tournament results",
    "profile.managedParcours.home": "Landing page",
    "profile.managedParcours.header": "Course management",
    "profile.managedParcours.article": "Article data",
    "profile.managedParcours.parcours.function": "Parcours features",
    "profile.managedParcours.transaction": "transaction",
    "profile.managedParcours.payment.A" : "payment active",
    "profile.managedParcours.payment.I" : "payment inactive",
    "profile.managedParcours.visit.A" : "Course book active",
    "profile.managedParcours.visit.P" : "Course book with payment select active",
    "profile.managedParcours.visit.F" : "Course book with address active",
    "profile.managedParcours.visit.I" : "Course book inactive",
    "profile.managedParcours.payment.info.noPartner" : "This course does not offer payment via 3D SKill Board partners - kindly tell them you'd like paying with 3D SKill Board.",
    "profile.managedParcours.payment.info.disabled" : "The payment for this course with 3D SKill Board is temporarily disabled, please try again later",
    "profile.managedParcours.fileupload.description":"Select a document with course rules which have to be accepted at the online payment and at the course book",
    "profile.transactions.header":"Paid course rounds",
    "profile.events.empty": "No events found",
    "profile.events.delete": "Delete event",
    "profile.events.restore": "Restore event",
    "profile.events.showInRanking" : "Press to show names in the ranking",
    "profile.events.hideInRanking" : "Press to hide names in the ranking",
    "profile.event.eventDetails": "To the event details ...",
    "profile.event.targetDetails": "Target details",
    "profile.event.notInEvent":"You were not part of this event or group",
    "profile.event.baProfile.label":"You can update the event with your equipment",
    "profile.event.duration.label":"You can update the duration of the event",
    "profile.event.duration.placeholder":"time in minutes",
    "profile.events.noEventDetails" : "Result can not be shown. If this is a duplicated event you can delete it. Otherwise kindly re-upload the event to the background system via synchronizing in the 3D SKill Board app.",
    "profile.fromMyImages": "Choose from my elements",
    "profile.addToMyImages": "Add new element (max 5MB)",
    "profile.abo.asklogin":"Kindly log in for adding an subscription to your account",
    "profile.abo.header":"Account subscriptions and features",
    "profile.abo.null":"There is no sub for this account - more information about ",
    "profile.abo.button.deactivate":"Pause this sub",
    "profile.abo.button.message.deactivate": "You may pause your sub once and resume it whenever you like in the future. The end of the abo will be shifted by the duration of the single pause.",
    "profile.abo.button.reactivate":"Resume this sub",
    "profile.abo.button.message.reactivate": "Your sub will be resumed and is active until the following date:",
    "profile.abo.newEndDate": "After renewing your sub - it will end on:",
    "profile.abo.ownAccount":" For my account",
    "profile.abo.redeem.voucher":" Redeem a voucher",
    "profile.abo.redeem.voucher.for":"Redeem a voucher for",
    "profile.training.noAbo":"The module training is not active on this account.",
    "profile.training.button.enter":"Enter training",
    "profile.training.ext.link":"External link",
    "profile.training.content":"Content",
    "profile.training.choose.type":"Choose category and type of the training",
    "profile.training.main.type":"Category",
    "profile.training.sub.type":"Type",
    "profile.training.team.trainer":"Trainer for the team",
    "profile.training.button.trainer":"Manage trainers",
    "profile.training.team.add.user":"Enter the e-mail of the new archer",
    "profile.training.team.invitation":"Open invitations for following training teams",
    "profile.training.team.noMember":"You are not a member with access to the training progress nor a trainer of this team",
    "profile.training.team.alreadyMember":"Archer is already part of the trainings team",
    "profile.training.team.newTeam":"Create new team",
    "profile.training.team.editTeam":"Enter team details",
    "profile.training.team.userVisible":"Member can also see the progress of the others.",
    "profile.training.button.change.name":"Edit name",
    "profile.training.filter.header":"Filter your events",
    "profile.training.filter.placeholder":"Enter Name for Filter ...",
    "profile.training.filter.event":"Filter for events",
    "profile.training.filter.details":"Filter for target",
    "profile.training.filter.comment":"Filter for target parameter",
    "profile.training.baProfile":"Gear",
    "profile.training.training.mode":"Typ of training",
    "profile.training.tags":"Tags",
    "profile.training.events":"Events",
    "profile.training.dist_est":"Distance estimated",
    "profile.training.dist_meter":"Distance measured",
    "profile.training.target_size":"Size of target",
    "profile.training.target_size.1002" : "small",
    "profile.training.target_size.1003" : "medium",
    "profile.training.target_size.1004" : "large",
    "profile.training.target_group":"Group (kill size)",
    "profile.training.posture":"Stand",
    "profile.training.posture.1001":"steep down",
    "profile.training.posture.1002":"downhill",
    "profile.training.posture.1003":"flat",
    "profile.training.posture.1004":"uphill",
    "profile.training.posture.1005":"steep up",
    "profile.training.postureHill":"Stand on the slope",
    "profile.training.postureHill.1001":"to the slope",
    "profile.training.postureHill.1005":"from the slope",
    "profile.training.level":"Target is ...",
    "profile.training.level.1001":"steep down",
    "profile.training.level.1002":"below",
    "profile.training.level.1003":"flat",
    "profile.training.level.1004":"above",
    "profile.training.level.1005":"steep up",
    "profile.training.levelNum":"Target is angle",
    "profile.training.arrowNr":"Arrow number",
    "profile.training.zones":"Zones",
    "profile.training.resTargetIndex":"Target number",
    "profile.training.passe":"Passe",
    "profile.training.discSize":"Faces",
    "profile.training.target.load":"Load target parameter",
    "profile.training.target.comment":"Filter the targets",
    "profile.training.mapKillImage" :"Show only hits for current kill image",
    "profile.training.potPoints":"Show only hits(*) near higher zone",
    "profile.training.noCountTypeWarn":"ATTENTION, dps from different scores",
    "training_mode_no":"Event",
    "training_mode_tournament":"Tournament",
    "training_mode_3d":"Training 3D",
    "training_mode_field":"Training field",
    "training_mode_outdoor":"Training outdoor",
    "training_mode_indoor":"Training indoor",
    "contact.form.header" : "Send us a message",
    "contact.form.message" : "Message",
    "contact.form.send" : "Send",
    "contact.form.sendError" : "Sorry it seems that my mail server is not responding. Could you please email me directly to support@3dturnier.com?",
    "contact.form.sendSuccess": "Your message has been sent.",
    "transaction.status.INIT":"started(I)",
    "transaction.status.ACK":"confirmed",
    "transaction.status.PENDING":"started(P)",
    "transaction.status.STORNO":"reversed",
    "transaction.status.NOK":"error",
    "transaction.detail.notFound":"Transaction not found",
    "transaction.detail.header.pendingInvoice":"Invoice created successfully",
    "transaction.detail.header.checkoutSuccess":"Payment successfully",
    "transaction.detail.header.checkoutPending":"Payment is still pending",
    "transaction.detail.header.checkoutError":"Payment was not performed",
    "transaction.detail.link.freshdesk":"Issues with online payment - here you get more information ...",
    "transaction.detail.link.parcours":"To course page",
    "transaction.detail.link.tournament":"To tournament page",
    "transaction.detail.link.invoice":"Download invoice",
    "transaction.detail.link.voucher":"Download voucher",
    "transaction.detail.mail.invoice":"You will receive the payment confirmation per email.",
    "transaction.detail.restart" : "Proceed to the payment form",
    "transaction.detail.error.INIT":"An error occurred at the communication to the payment service. Sorry but at this time we cannot handle further payments.",
    "transaction.detail.error.PENDING":"The payment process has been started but user did not proceed nor cancel",
    "transaction.detail.error.NOK":"Payment could not be processed. Please check the date you have entered and try again.",
    "transaction.detail.error.":"An unexpected error has occurred, kindly contact us via mail to office@3dturnier.com",
    "transaction.detail.pending.info":"Waiting for information of the payment service",
    "card_declined":"Your card has been declined",
    "payment_intent_authentication_failure":"The authentication of your payment method has failed",
    "payment_method_customer_decline":"You did not approve the payment",
    "importer.warning":"import - Only use if you know what you are doing!",
    "importer.selectJson":"Choose import JSON file",
    "skillboard.premium.header":"All for your SKill",
    "skillboard.premium.next":"Feature work ongoing",
    "skillboard.premium.buynow":"Buy now ...",
    "skillboard.premium.planned":"Planned",
    "skillboard.premium.available":"Available for",
    "skillboard.premium.prices":"Get your 3D SKill Board PREMIUM or TRAINING",
    "skillboard.premium.training":"Get your 3D SKill Board TRAINING",
    "skillboard.premium.aboAccountInfo1":"Thank you for having a subscription already!",
    "skillboard.premium.aboAccountInfo2":"You will find the status about your existing subs in your",
    "skillboard.premium.aboAccountInfo3":"The next subscription that you are buying starts on",
    "skillboard.premium.prices.desc1":"Premium users will receive new features first",
    "skillboard.premium.prices.desc2":"Pause your sub once, eg in the winter",
    "skillboard.premium.prices.desc3":"Is valid for your account and not depending on your devices",
    "skillboard.premium.prices.desc4":"No typical in-app banner advertisement",
    "skillboard.premium.prices.desc5":"No auto-renew sub or charge",
    "skillboard.feature.desc1":"Scoring for single/group",
    "skillboard.feature.desc2":"Compare and share results",
    "skillboard.feature.desc3":"Gear",
    "skillboard.feature.desc4":"Course Wiki",
    "skillboard.feature.desc5":"Many scores",
    "skillboard.feature.desc6":"Statistics",
    "skillboard.feature.desc7":"GPS-track",
    "skillboard.feature.desc8":"Course-ranking",
    "skillboard.feature.desc9":"Score on kill image",
    "skillboard.feature.desc10":"3D-animal images",
    "skillboard.feature.desc11":"Training log",
    "skillboard.feature.desc12":"Training groups",
    "skillboard.feature.desc13":"Target characteristic",
    "skillboard.feature.desc14":"Hit pattern analyse",
    "skillboard.premium.S.desc1":"Compare points and DPS/BHS of your group",
    "skillboard.premium.S.desc2":"Detailed points and DPS/BHS of an archer",
    "skillboard.premium.S.desc3":"DPS/BHS change over time on your courses and scores",
    "skillboard.premium.S.desc4":"DPS/BHS change over time one a single course",
    "skillboard.premium.G.desc1":"Record GPS track incl pause function",
    "skillboard.premium.G.desc2":"Map view of the track with targets",
    "skillboard.premium.G.desc3":"Export track as gpx",
    "skillboard.premium.G.desc4":"Duration and distance traveled",
    "skillboard.premium.G.desc5":"Planned: Altitude profile of the tracks",
    "skillboard.premium.G.desc6":"Planned: Navigation and warning for going into the wrong direction",
    "skillboard.premium.R.desc1":"See your ranking position on all courses",
    "skillboard.premium.Z.desc1":"Set targets for the course from a catalog of the target manufacturer",
    "skillboard.premium.Z.desc2":"Manufacturer catalog of targets with kill zone images",
    "skillboard.premium.Z.desc3":"Exchange of the target data with all others premium users",
    "skillboard.premium.K.desc1":"Enter hits on different kill images",
    "skillboard.premium.K.desc2":"Show and filter all hits of an event",
    "skillboard.premium.voucher.activate":"Activate voucher",
    "skillboard.premium.voucher.success.activate":"This voucher was activated and is ready to be redeemed by any customer",
    "skillboard.premium.voucher.error.used":"This voucher is already redeemed.",
    "skillboard.premium.voucher.error.registered":"This voucher needs to be activated by the shop where you bought the voucher",
    "skillboard.premium.voucher.error.invalid":"The code of the voucher is not valid.",
    "skillboard.premium.voucher.success":"Voucher redeemed successfully",
    "skillboard.premium.voucher.entercode":"Kindly enter the code of the voucher",
    "skillboard.premium.voucher.uplowcase":"Mind upper and lower case of the code!",
    "skillboard.premium.noAbo":"You need an active 3D SKill Board Premium subscription for these functionality",
    "skillboard.title": "3D SKill Board",
    "skillboard.download": "\
            The smart 3D archery app - score cards are from yesterday!<br/>\
            Free of charge in the Google Play and Apple App store",
    "skillboard.screenshot":"Screenshots for ...",
    "skillboard.tab.gettingstarted": "Getting started",
    "skillboard.tab.joinTournament": "Join tournament",
    "skillboard.tab.faq": "FAQs",
    "skillboard.tab.videos": "Videos",
    "skillboard.tab.scores": "Scores",
    "skillboard.tab.releasenotes": "Release notes",
    "skillboard.knowledge.base":"Knowledge base",
    "releasenotes.result.empty":"No release notes found",
    "releasenotes.newVersion":"A new version of 3DTurnier is out. Refresh the page with STRG+F5 to get the latest version!",
    "skillboard.gettingstarted.newevent.title": "Start a new event",
    "skillboard.gettingstarted.newevent.left": "\
            <p>\
                1) Select a course.<br/>\
                For Partner-courses we offer additional features like mobile payment, ranking of the archers and a map with the target.\
                <br/><br />\
                2) Select several archers for the event.<br /> <br />\
                3) Press on the button \"Start\" which appears after selecting course and archers.\
                <br /> <br />\
                 <strong>3D Turnier</strong><br/>\
                Join tournaments which are hosted on this platform. <br/>\
                <a href='./skillboard/tab/jointournament'>See join tournament</a>\
            </p>",
    "skillboard.gettingstarted.newevent.right": "\
            <p>\
                <strong>Choose a course</strong>\
                <br/>\
                Here you see a list with all your stored courses. You can mark them as favorite too.\
                A long click on the entry reveals the context menu for editing.\
                <br/><br/>If you haven't stored one yet - you will find them in the course wiki.\
                All users can add missing courses to the course wiki. But you can also use one of our default courses. \
            </p>",
    "skillboard.gettingstarted.newevent2.title": "Archers and options",
    "skillboard.gettingstarted.newevent2.left": "\
            <p>\
                <strong>Choose archers</strong><br/>\
                Here you see a list with all your stored archers. You can mark them as favorite too.\
                A long click on the entry reveals the context menu for editing.\
                <br/><br/>\
                For each archer you shall enter a name and a score. \
                The email is optional, but only if you enter one the result of the archer will be uploaded. See synchronisation\
                <br/><br/>\
                Select the archers for the event and accept the screen on the top right\
            </p>",
    "skillboard.gettingstarted.newevent2.right": "\
            <p>\
                <strong>Event options</strong>\
                <br/>\
                1) You can define a custom name for the event.\
                (The date will be added automatically to the name - you do not need to put it manually.<br/><br/> \
                2) It is possible to use one score for all archers in the event or the stay with their individual configured scores.<br/><br/>\
                3) You can overwrite the starting peg eg for tournaments<br/><br/>\
                4) The <a href='./skillboard/premium'>Premium subscription</a> enables you to track the GPS route of the event\
            </p>",
    "skillboard.gettingstarted.wiki.title": "Transfer course into App",
    "skillboard.gettingstarted.wiki.left":"\
            <p>\
                <strong>Search for course</strong><br/>\
                In our course wiki we have already listed the majority of courses. \
                Enter a name, place or ZIP in the search field and start the search.\
                <br/><br/>\
                Click on the course you are interested in to find more details about.\
            </p>",
    "skillboard.gettingstarted.wiki.right":"\
            <p>\
                <strong>Course details</strong>\
                <br/>\
                 In this detail view you will find many handy information:\
                 \<ul>\
                    <li>The rating of other archers</li>\
                    <li>Contact data and links</li>\
                    <li>Open times and prices</li>\
                </ul>\
                 Click on \"transfer\" to transfer the course to your list and select it for the event.\
            </p>",
    "skillboard.gettingstarted.enter1.title": "Entering the hits 1",
    "skillboard.gettingstarted.enter1.left": "\
            <p>\
                In the upper part you will see the number and the name of a target if already saved.\
                A long press on the target name allows entering more details.<br />\
                <br /> You will find all archers and their actual scores. If you can read \"+???\" for an archer - there were just no points entered for this target.\
                Simply press on the name of the archer and the hit pop up will appear. <br />\
                <br /> Using the \"&gt;&gt;\" button will save the points for archers and the app proceeds with the next target. <br />\
                <br /> Using the \"<<\" button will reload the previous target and the entered points can be edited.<br />\
                <br /> The \"finish flag\" in the upper right corner will store the last points and finish the event<br /> <br />\
            </p>",
    "skillboard.gettingstarted.enter1.right": "\
            <p>\
                <strong>One assessed arrow per target</strong><br />\
                First check if the archer has hit the target with the first (1.) or second (2.) or third (3.) arrow or not at all (M).\
                The pop up shows the zones with their points - kindly check them too.\
                After that, the pop up disappears and the points will be added and shown in the field of the archer. <br />\
                <br /> Use the left \"circle\" button to show an image of the score or the right \"pencil\" button to switch to manual point entry mode.\
            </p>",
    "skillboard.gettingstarted.enter2.title": "Entering the hits 2",
    "skillboard.gettingstarted.enter2.left": "\
            <p>\
                <strong>Two or more assessed arrows per target</strong><br />\
                For scores where the points for each target hit will be summed up, all arrows for one target need to be entered at once in the pop up. <br />\
                <br /> Use the left \"circle\" button to show an image of the score or the right \"pencil\" button to switch to manual point entry mode. <br /> <br />\
            </p>",
    "skillboard.gettingstarted.enter2.right": "\
            <p>\
                <strong>Manual point entry</strong> <br />\
                If there is a target which does not fit the usual score - you can enter any points. But you can only use three arrows per target at the max.\
            </p>",
    "skillboard.gettingstarted.enter3.title":"Entering the hits 3",
    "skillboard.gettingstarted.enter3.left": "\
            <p>\
                <strong>3er Passe on a disc target</strong><br>\
                Scoring on a disc target can be selected as all other scores for a single archer as well as a full event.\
                Just press the scored rings one after another. For each arrow you will find the points in the section above.\
                Pressing the third and last ring will close the pop up automatically.\
                <br><br>You can choose between following scores:\
                <ul>\
                    <li>Disc 10 to 1 (left)</li>\
                    <li>Disc central 10 to 6</li>\
                    <li>Disc central 10 to 5</li>\
                    <li>Field score 6 bis 1 (right) </li>\
                </ul>\
            </p>",
    "skillboard.gettingstarted.result.title": "Result overview",
    "skillboard.gettingstarted.result.left": "\
            <p>\
                The table shows for each target the achieved points from the archer.\
                A long press on the points will show up the hit pop up again for changing the points. \
                <br /><br /> \
                The menu button gives you following options:\
                <ul>\
                    <li>Delete an event only locally or also in background system</li>\
                    <li>Move an event to another course</li>\
                    <li>Reopen an event - if it was closed too early</li>\
                    <li>Change name or date of an event</li>\
                </ul>\
                The \"share\" button is useful to send your result to the other archers.\
                <br/><br/> \
                The <a href='./skillboard/premium'>Premium subscriptions</a> adds the \"graph\" symbol which shows a graphical representation of the event.\
            </p>",
    "skillboard.gettingstarted.result.right": "\
            <p>\
        	    The event history shows all events which are stored sorted by event date. Using the drop down narrows the result to a special course.\
                In the context menu of an event you will find the same options as in the event menu.\
            </p>",
    "skillboard.gettingstarted.scores.title": "Scores",
    "skillboard.gettingstarted.scores.left": "\
            <p>\
                You will find all stored scoring methods in the list. The app is shipping with a lot of standard ones.\
                For the ones which you are using very often - just activate the tickmark. Only these will be shown in\
                the drop down menus.\
                <br/><br/>\
                There is already a long list on scoring methods vom other arches. But you can create new ones \
                <a href='./skillboard/tab/scores'>here</a> anyway. Swipe Down does refesh the local list\
            </p>",
    "skillboard.jointournament.info":"ATTENTION - for joining the tournament as a scorer your tournament registration e-mail need to conform \
                to your account e-mail.",
    "skillboard.jointournament.join.title": "1. Join and start a tournament",
    "skillboard.jointournament.join.left": "<p>\
                 1) Change to <strong>3D tournament</strong> via the bottom tab bar.(Android only)</p>",
    "skillboard.jointournament.join.right": "<p>\
                 2) Press <strong>search for group</strong> and the app will find your group depending on the e-mail \
                 of the logged in archer. As well as all other members of your group and the start point.\
                 <br/><br/>\
                 3) Just press on <strong>START</strong> and you can begin with entering the scores.</p>",
    "skillboard.jointournament.enter.title": "2. Enter the score",
    "skillboard.jointournament.enter.left": "<p>\
                You will find the actual target and the list of all group members. \
                Press on an member for entering their scores.\
                <br><br>\
                <strong>Hint</strong>: Use the more menu to activate the edit-mode to remove archers from the group (Android only)</p>",
    "skillboard.jointournament.enter.right": "<p>\
                The result table presents the current achieved points - so you can find errors and correct them very fast. \
                After entering the score for the last target you have to press <strong>finish event</strong>.\
                <br><br>\
                <strong>Hint</strong>: A long press on the points in the table enables you to correct the score</p>",
    "skillboard.jointournament.commit.title": "3. Confirm the result",
    "skillboard.jointournament.commit.left": "<p>\
                Compare your results with all other scorers. \
                If the result match - press <strong>Confirm result</strong>. \
                The background system now match the score also and commit it to the tournament result.</p>",
    "skillboard.faq.archers": "Archers",
    "skillboard.faq.parcour": "Course",
    "skillboard.faq.event": "During an event",
    "skillboard.faq.synchronization": "Synchronization",
    "skillboard.faq.q1.title": "What is the reason for grouping archers with or without e-mail?",
    "skillboard.faq.q1.content": "\
            The e-mail will be used for identification of the other archers in an event and is therefore crucial for the distribution of the events to them.\
            The synchronisation process will download all events where the logged in users email is found. Results for archers without an entered e-mail will not be synchronized or distributed.",
    "skillboard.faq.q2.title": "Where can I change the details of an archer?",
    "skillboard.faq.q2.content": "\
            Go to the start screen and press on \"archers\". In the appearing archers list just long press on the archer in question. You will find a context menu offering following possibilities:\
            <ul>\
                <li>Change: name, e-mail or default score can be changed for the archer.</li>\
                <li>Delete: the chosen archer will be removed from local list - but the local event results will not be changed</li>\
                <li>Replace: the chosen archer will be removed from local list - but he will be replaced in the local event results with the replacement archer. This is mainly to reunion doubled archers with\
                different e-mails.\
                </li>\
            </ul>",
    "skillboard.faq.q3.title": "I see doubled archers in my list - why?",
    "skillboard.faq.q3.content": "\
            Since version 2.6: At downloading the events the system try to map existing archers by their e-mails. If there is no archer with the given e-mail he will be created automatically. You can reunion\
            such doubled archers by \"replace\" one with the other (context menu).",
    "skillboard.faq.q4.title": "The score i am used to is not available?",
    "skillboard.faq.q4.content": "\
            1) May be the score is not activated?\
            <br>\
            In the side menu you will find \"Arrow score\". You will find here all local available scores. Here you de/activate them to hide/show them in the drop down menus.\
            <br>\
            2) Download scores\
            <br>\
            Press \"Synchronisation\" in the side menu. All available new scores will be downloaded and stored. So maybe the one you were looking for is already available.\
            <br>\
            3) Create own score \
            <br>\
            If you are still missing your needed score - you can create it yourself. Take a look to tab - \"Scores\" :-)",
    "skillboard.faq.q5.title": "What is the reason for grouping courses from wiki or local ones?",
    "skillboard.faq.q5.content": "\
            The internal course ID from parcours wiki enables the synchronization process to assign the event to the same course for the others archers. So only events for courses from wiki will be uploaded.",
    "skillboard.faq.q6.title": "Where can I change the details of a course?",
    "skillboard.faq.q6.content": "\
            Go to the start screen and press on \"courses\". In the appearing course list just long press on the course in question. You will find a context menu offering following possibilities:\
            <ul>\
                <li>Change: name or place can be changed for the course.</li>\
                <li>Delete: the chosen course will be removed from local list - Attention! all stored events will be deleted for that course.</li>\
                <li>Replace: the chosen course will be removed from local list - all stored events will be assigned to the replacement course. This is mainly to move events to courses from wiki.</li>\
            </ul>",
    "skillboard.faq.q7.title": "How can I enter points manually in the app for a target?",
    "skillboard.faq.q7.content": "\
            Since version 2.4: In the pop up dialog for entering the points you will find a pencil button in the upper right corner. The dialog changes and you can enter points manually for a target. But also\
            \"no score\" can be chosen -> this skips the current target for the current archer and has no influence on the score statistic..",
    "skillboard.faq.q8.title": "Is it possible to remove an archer from the event",
    "skillboard.faq.q8.content": "\
            Klick on the more menu with the three dots and activate the edit archers mode:<br/>\
            Now you can change the order of the archers and also drag an archer out of the group - the already entered results will stay visible <br/>\
            Restarting the event will undo this step and the archer is again in the group",
    "skillboard.faq.q9.title": "What is meant by DPS and BHS?",
    "skillboard.faq.q9.content": "\
            DPS stands acually for \"damage per second\" and is well known in MMORPGs for the average damage on a target per second. In the app its \"damage per shot\" -> average points per arrow on the target. A\
            missed target counts only as \"one\" miss in the calculation. BHS stands for BowHunter Score  its average points per arrow on the target - but does only counts first arrow hits and if you hit the target\
            with your second arrow this counts as missed",
    "skillboard.faq.q10.title": "I want to change already entered points, how?",
    "skillboard.faq.q10.content": "\
            On the one hand you can use the button back on top to reload the points from the target before and changing them. On the other hand you can click on the points of a target in the result table. \
            A black info box appears on the bottom of teh screen which shows a button to open the dialog to change the points.",
    "skillboard.faq.q11.title": "How works the event synchronization - save events in the backend?",
    "skillboard.faq.q11.content": "\
            Since version 2.6: Prerequisite for the synchronization is a logged in user with a validated e-mail. \
            To upload an event in the background system it is crucial that the course was imported from the \
            course wiki for assigning the event to the same course for the others archers. \
            Additionally only archers with a entered e-mail will be synchronized. You can change parcours and archer mail also later (Replace in context menu). \
            The synchronisation process will be started by press synchronize in the side menu.",
    "skillboard.faq.q12.title": "Can I add a course to the Parcours Wiki?",
    "skillboard.faq.q12.content": "\
             A logged in user with verified e-mail is necessary to contribute to the wiki. Just press \"new course\" and enter the ZIP and a name for the course to be created. \
             You can add a lot more information afterwards. Kindly ensure if the course is not already available. Changing the targets or target amounts does not qualify for a new course! Only if the position is changing we consider this as new one.\
             Also if the course offers different target amounts to go - this is just one course. Only if there are different prices therefore and is listed separately we consider this as TWO courses.\
             To add an new course to an existing one - kindly use \"New course on existing place\". So the system will use the same club and address for the new course.",
    "skillboard.faq.q13.title": "New mobile phone - How I transfer the events to my new one?",
    "skillboard.faq.q13.content": "\
            This best way is using the event synchronization - all events for courses from the wiki and archers with mails will be stored in our background system.\
            As soons as you log in on your new phone and press synchronize all your events will be downloaded.",
    "skillboard.faq.q14.title": "How works the event synchronization - load events from backend?",
    "skillboard.faq.q14.content": "\
            You will receive only the events where there was an archer with your account mail. \
            Therefore you should take care that friends always use your correct email for you archer.",
    "skillboard.faq.q15.title": "Is it possible to skip a target?",
    "skillboard.faq.q15.content": "\
            Before you enter any points for the current target you need to press on the next target button. \
            The appearing message allows you to skip or stay on the current target.",
    "smarttournament.text.html": "\
        You can start a smart tournament during the course at any time which enables you to use more devices \
        and combine them in one event.  <br><br>It will be started by pressing the cloud button. \
        As a basic requirement the course needs to be linked from the course wiki and also only archers with \
        given e-mails will be taken into consideration. The e-mail will be used for identification.\
        <br><br> \
        By choosing an access code only welcome archers can join the smart tournament. The code needs to be entered \
        if the archer joins via the tournament search on the mains screen. After confirming the dialog you will get \
        the possibility to share an invitation for the event - this skips entering the code! \
        <br><br> \
        You are only allowed to edit the points you have entered yourself. To define a final winner it is necessary to \
        confirm the entered points on each device. Clearly after that it is not possible to edit the points any more. \
        <br><br> \
        Hint: Opening the invitation link with a browser will show the confirmed results below.",
    "smarttournament.text.alert":"Open the invitation link with the 3D SKill Board app and it will join the correct smart tournament. Or just search for open tournaments on the given course directly on the main screen.",
    "impressum.title": "Legal notice",
    "label.help":"Help",
    "language.de":"German",
    "language.en":"English",
    "language.es":"Spanish",
    "language.fr":"French",
    "language.it":"Italian",
    "language.ru":"Russian",
    "language.pl":"Polish",
    "language.da":"Danish",
    "language.sv":"Swedish",
    "language.nl":"Dutch",
    "language.cs":"Czech",
    "language.keyIOS":"keyIOS",
    "donate.beer": "A beer for our work",
    "footer.impressum": "Legal notice",
    "footer.language": "Languages"
};

