module.exports = {
    CountTypeDtl: require("parse/CountTypeDtl"),
    ZoneNames: require("parse/ZoneNames"),
    ParcoursRating: require("parse/ParcoursRating"),
    CountTypeGrp: require("parse/CountTypeGrp"),
    CountType: require("parse/CountType"),
    GroupPlace: require("parse/GroupPlace"),
    Event: require("parse/Event"),
    EventPlayer: require("parse/EventPlayer"),
    EventComment: require("parse/EventComment"),
    EventTarget: require("parse/EventTarget"),
    EventTraining: require("parse/EventTraining"),
    EventTrainingMember: require("parse/EventTrainingMember"),
    EventTrainingTeams: require("parse/EventTrainingTeams"),
    EventTrainingFilter: require("parse/EventTrainingFilter"),
    EventTrainingTypes: require("parse/EventTrainingTypes"),
    ParseLog: require("parse/ParseLog"),
    Parcours: require("parse/Parcours"),
    ChangeLog: require("parse/ChangeLog"),
    ImageGallery: require("parse/ImageGallery"),
    News: require("parse/News"),
    EventResult: require("parse/EventResult"),
    EventTrack: require("parse/EventTrack"),
    EventParcoursStat: require("parse/EventParcoursStat"),
    AgeType: require("parse/AgeType"),
    Images: require("parse/Images"),
    BowType: require("parse/BowType"),
    BowUnion: require("parse/BowUnion"),
    ParcoursOwner: require("parse/ParcoursOwner"),
    PointOfInterest: require("parse/PointOfInterest"),
    CCLog: require("parse/CCLog"),
    Club: require("parse/Club"),
    ClubUser: require("parse/ClubUser"),
    ClubEvent: require("parse/ClubEvent"),
    ClubInvoice: require("parse/ClubInvoice"),
    Tournament: require("parse/Tournament"),
    TournamentPrice: require("parse/TournamentPrice"),
    UserDetail: require("parse/UserDetail"),
    UserFavorite: require("parse/UserFavorite"),
    UserBowUnionLic: require("parse/UserBowUnionLic"),
    UserBowArrowProfile: require("parse/UserBowArrowProfile"),
    User: require("parse/User"),
    UserAbo: require("parse/UserAbo"),
    UserToken: require("parse/UserToken"),
    Voucher: require("parse/Voucher"),
    TournamentUser: require("parse/TournamentUser"),
    TournamentUserTeam: require("parse/TournamentUserTeam"),
    TournamentConfig: require("parse/TournamentConfig"),
    TournamentUserGroup: require("parse/TournamentUserGroup"),
    TournamentUserGroupLive: require("parse/TournamentUserGroupLive"),
    TournamentUserGroupResult: require("parse/TournamentUserGroupResult"),
    TournamentOption: require("parse/TournamentOption"),
    TournamentRegSlot: require("parse/TournamentRegSlot"),
    TournamentCupOption: require("parse/TournamentCupOption"),
    TournamentRound: require("parse/TournamentRound"),
    TranslationCl: require("parse/TranslationCl"),
    ParcoursArticle: require("parse/ParcoursArticle"),
    ParcoursTarget: require("parse/ParcoursTarget"),
    ParcoursTargetLog: require("parse/ParcoursTargetLog"),
    ParcoursVisit: require("parse/ParcoursVisit"),
    ParcoursStat: require("parse/ParcoursStat"),
    TransactionEvent: require("parse/TransactionEvent"),
    Transaction: require("parse/Transaction"),
    TransactionPosition: require("parse/TransactionPosition"),
    TransactionSettlement: require("parse/TransactionSettlement"),
    TransactionSettlementPosition: require("parse/TransactionSettlementPosition"),
    TargetCatalog: require("parse/TargetCatalog"),
    TargetBrand: require("parse/TargetBrand"),
    Address: require("parse/Address"),
    BusinessPartner: require("parse/BusinessPartner"),
    PaymentTarget: require("parse/PaymentTarget"),
    Management: require("parse/Management"),
    Union: require("parse/Union"),
    Releases: require("parse/Releases"),
    TournamentRoundUser: require("parse/TournamentRoundUser"),
    ManagementRequest: require("parse/ManagementRequest")
};
