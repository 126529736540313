const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, InputGroup, ControlLabel} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const TournamentSexSelect = ({inputRef, label, defaultValue, preSelect, disabled, selectCallback}) =>{
    const changeOption = (event) => {
        selectCallback(parseInt(event.target.value))
    };
    return (<FormGroup>
        {
            label ? <ControlLabel>{label}</ControlLabel> : null
        }
        <InputGroup>
            <InputGroup.Addon><FontAwesome icon="transgender"/></InputGroup.Addon>
            <FormControl ref={inputRef} componentClass="select" placeholder="select" disabled={disabled}
                         defaultValue={defaultValue}
                         onChange={changeOption}>
                {
                    preSelect == false ? <option value={-1}>{messages.get("modal.license.choose")}</option> : null
                }
                <option value={0}>{messages.get("domain.user.detail.sex.0")}</option>
                <option value={1}>{messages.get("domain.user.detail.sex.1")}</option>
            </FormControl>
        </InputGroup>
    </FormGroup>);
};
TournamentSexSelect.propTypes = {
    inputRef: PropTypes.object,
    defaultValue: PropTypes.string,
    preSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentSexSelect;