const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Table, ButtonGroup, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome");

const clubStore = require("stores/ClubStore"),
    clubActions = require("actions/ClubActions");

class ClubPendingTable extends React.Component {
    constructor(props) {
        super(props);
        this.handleClubChange = this.handleClubChange.bind(this);

        this.state = {
            clubStore: clubStore.getState()
        }
    }
    componentDidMount() {
        clubStore.listen(this.handleClubChange);
    }
    componentWillUnmount() {
        clubStore.unlisten(this.handleClubChange);
    }
    handleClubChange(state) {
        this.setState({clubStore: state});
    }
    render() {
        return <ClubPending club={this.props.club}
                                   clubUsers={this.props.clubUsers}
                                   ownClubUser={this.props.ownClubUser}
                                   clubEvents={this.state.clubStore.clubEvents}/>
    }
}
ClubPendingTable.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object.isRequired,
    clubUsers: PropTypes.array   
};
const ClubPending = ({club, ownClubUser, clubUsers, clubEvents}) => {
        let pendingClubUser = [];
        let pendingEvents = [];
        const userLimitReached = !club.hasClubManagement() && clubUsers != null && clubUsers.length > 10;
        if (clubUsers != null && ownClubUser.hasUserHandling()) {
            // member handling allowed - show all pending request
            clubUsers.map(clubUser => {
               if (clubUser.isReqStatusRequested()) {
                   pendingClubUser.push(clubUser);
               }
            });
        }
        if (clubEvents != null) {
            clubEvents.map(clubEvent => {
                if (clubEvent.hasOptions() && !clubEvent.isDraft() && !clubEvent.hasOptionResult(ownClubUser)) {
                    pendingEvents.push(clubEvent);
                }
            });
        }
        return (<React.Fragment>
            <h4><I18n code="club.detail.actual"/></h4>
            <Table>
                <thead>
                    <tr>
                        <th><I18n code="tournament.table.column.date"/></th>
                        <th><I18n code="tournament.table.column.name"/></th>
                        <th><I18n code="parcours.details.button.actions"/></th>
                    </tr>
                </thead>
                <ClubUserPendingTableBody club={club} ownClubUser={ownClubUser} userLimitReached={userLimitReached}
                                          pendingClubUser={pendingClubUser} events={pendingEvents}/>
            </Table>
        </React.Fragment>);
};
ClubPending.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object.isRequired,
    clubUsers: PropTypes.array,
    clubEvents: PropTypes.array
};

const ClubUserPendingTableBody = ( {club, ownClubUser, events, pendingClubUser, userLimitReached}) => {
    if (pendingClubUser.length == 0 && events.length == 0) {
        return (
            <tbody>
                <tr>
                    <td></td>
                    <td><I18n code="list.entry.empty"/></td>
                    <td></td>
                </tr>
            </tbody>
        );
    } else {
        return (
            <tbody>
            {
                pendingClubUser.map(clubUser => {
                    return (<ClubUserPendingTableEntry key={"CUPTE_" + clubUser.id} clubUser={clubUser} club={club} userLimitReached={userLimitReached}/> )
                })
            }
            {
                events.map(clubEvent => {
                    return (<ClubEventPendingTableEntry key={"CEPTE_" + clubEvent.id} ownClubUser={ownClubUser} clubEvent={clubEvent} />)
                })
            }
            </tbody>
        );
    }
};
ClubUserPendingTableBody.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object.isRequired,
    events: PropTypes.array.isRequired,
    pendingClubUser: PropTypes.array.isRequired,
    userLimitReached: PropTypes.bool.isRequired
};
const ClubUserPendingTableEntry = ({club, clubUser, userLimitReached}) => {
    function handleAcceptRequest() {
        clubActions.acceptClubUser(clubUser, club);
    }
    function handleDeclineRequest() {
        clubActions.declineClubUser(clubUser, club);
    }
    return (<tr>
        <td>{clubUser.getDate()}</td>
        <td>{clubUser.getName() + " - " + messages.get("cl_req_status.R")}</td>
        <td><ButtonGroup>
            <Button disabled={userLimitReached} bsStyle="primary" onClick={handleAcceptRequest}><FontAwesome icon="check"/></Button>
            <Button bsStyle="primary" onClick={handleDeclineRequest}><FontAwesome icon="times"/></Button>
        </ButtonGroup></td>
    </tr>)
};
ClubUserPendingTableEntry.propTypes = {
    club:PropTypes.object.isRequired,
    clubUser:PropTypes.object.isRequired,
    userLimitReached: PropTypes.bool.isRequired
};
const ClubEventPendingTableEntry = ({clubEvent, ownClubUser}) => {
    function handleOptionClicked(option) {
        clubActions.handleClubEventOption(clubEvent, ownClubUser, option);
    }
    return (<tr>
        <td>{clubEvent.getEventStartDate()}<br/>{clubEvent.getEventEndDate()}</td>
        <td><a href={linkUtil.getLink("/club/event/" + clubEvent.id)} target="_blank">{clubEvent.getName()}</a></td>
        <td><ButtonGroup>
            {
                clubEvent.getOptions().map(option => {
                    return (<Button bsStyle="primary" key={"opt_"+option}
                            onClick={()=> {handleOptionClicked(option)}}>
                        {option}
                    </Button>)
                })
            }
        </ButtonGroup></td>
    </tr>)
};
ClubEventPendingTableEntry.propTypes = {
    ownClubUser:PropTypes.object.isRequired,
    clubEvent:PropTypes.object.isRequired
};
module.exports = ClubPendingTable;
