const React = require("react"),
    PropTypes = require('prop-types');

const { Marker, Polyline, OverlayView} = require ('@react-google-maps/api');
const messages = require("i18n/messages");

class TargetTrackLine extends React.Component {
    constructor(props) {
        super(props)
        this.toggleInfoVisible = this.toggleInfoVisible.bind(this);
        this.generateMarkerContent = this.generateMarkerContent.bind(this);
        this.state = {
            infoVisible: false,
        }
    }
    buildCoordPath = function(coordArray) {
        let path = [];
        coordArray.map(coordinate => {
            if (coordinate) {
                path.push({lat: coordinate.la, lng: coordinate.lo})
            }
        });
        return path
    };
    toggleInfoVisible() {
        let visible = this.state.infoVisible;
        this.setState({infoVisible: !visible})
    }
    generateMarkerContent() {
        const {targetNumber, targetName, targetPoints} = this.props;
        let tMarker = "<h4>" + messages.get("tournament.table.column.target") + " " + targetNumber + "</h4>";
        if (targetName != null) {
            tMarker += "<p>" + targetName + "</p>";
        }
        if (targetPoints != null) {
            targetPoints.map(entry => {
                tMarker += "<p>" + entry + "</p>";
            })
        }
        return tMarker;
    }
    render() {
        const {infoVisible} = this.state;
        const {coordArray, targetNumber, posMarker} = this.props;
        let pCoordPath = this.buildCoordPath(coordArray);
        return <React.Fragment>
            {
                posMarker ? <Marker position={{lat: posMarker.la, lng: posMarker.lo}} icon={"/img/ico/frame.png"} onClick={this.toggleInfoVisible}
                                    label={{text: targetNumber,
                                            fontWeight: 'bold',
                                              color: 'white'}}/> : null
            }
            {
                infoVisible ?
                    <OverlayView position={posMarker} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                        <div style={{
                            background: '#FFFFFF',
                            padding: 10,
                            fontSize: 12,
                            borderRadius: 5
                        }}
                             onClick={this.toggleInfoVisible}
                             dangerouslySetInnerHTML={{__html:this.generateMarkerContent()}}/>
                    </OverlayView> : null
            }
            {
                pCoordPath ? <Polyline path={pCoordPath}
                                       options={{
                                           strokeColor:"#FF0000",
                                           strokeOpacity:1,
                                           strokeWeight:3,
                                           icons: [{
                                               icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                                               offset: '100%'
                                           }]}}
                /> : null
            }
        </React.Fragment>
    }
}
TargetTrackLine.propTypes = {
    parcoursTarget: PropTypes.object.isRequired
};
module.exports = TargetTrackLine;