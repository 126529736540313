/**
 * Created by Daniel on 03.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types'),
    Parse = require("ParseInit");
const {Row, Col, Alert, Panel, PageHeader} = require("react-bootstrap");
const ParcoursLoader = require("components/parcours/ParcoursLoader"),
    I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    CoordinateInput = require("components/form/CoordinateInput"),
    ImageBanner = require("components/widgets/ImageBanner"),
    FileUploadArea = require("components/widgets/FileUploadArea");
const messages = require("i18n/messages");

const {ParcoursOwner, Parcours} = require("parse/_Domain");

const ParcoursCategoryEditor = require("components/parcours/ParcoursCategoryEditor"),
    ParcoursArticlesButton = require("components/links/ParcoursArticlesButton"),
    ParcoursOpenDays = require("components/parcours/ParcoursOpenDays");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const parcoursActions = require("actions/ParcoursActions");

const parCatArr = ["cat_nightparcours","cat_buggy", "cat_wheel_chair", "cat_refreshment", "cat_area_flat","cat_area_steep","cat_area_damp","cat_area_forest","cat_area_lawn"];
const parOwnCatArr = ["cat_equip","cat_equip_request","cat_shop","cat_toilet","cat_cantina","cat_rooms","cat_camping","cat_indoor","cat_type_traditional","cat_type_compound","cat_type_crossbow","cat_type_blowgun","cat_dog","cat_shootcinema","cat_diskus"];
const constraintsArr = ["type_private","type_request","type_summer","type_winter","type_closed", "type_oneHitOnly_PP"];
const associationArr = ["ass_oebsv","ass_oebst", "ass_oebrsv", "ass_dsb", "ass_dfbv", "ass_dbsv"];

const ParcoursEditPage = ({onlineID, tstat}) => {
    return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"A"} loginMandatory={true} tstat={tstat}
                           invalidUserDesc={<I18n code="parcours.invalidUser.editor"/>} >
        <ParcoursDetails />
    </ParcoursLoader>
};
ParcoursEditPage.propTypes = {
    onlineID: PropTypes.string.isRequired
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleCoordinateUpdate = this.handleCoordinateUpdate.bind(this);
        this.parseSaveParcours = this.parseSaveParcours.bind(this);
        this.handleBannerUpdated = this.handleBannerUpdated.bind(this);
        this.showTargetHelpMessage = this.showTargetHelpMessage.bind(this);
        this.state = {}
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
    }
    componentWillUnmount() {
        parcoursActions.resetParcours();
    }
    pressedSave(text, viElement, colName, object) {
        let newValue = text.trim();
        let oldValue = object.get(colName);
        console.log("Change " + object.id + " column: " + colName + " from (" + oldValue + ") to (" + newValue + ")");
        this.parseSaveParcours(object, colName, oldValue, newValue, viElement)
    }
    handleCoordinateUpdate(viElement, newValue) {
        let pOnwer = this.props.parcours.getParcoursOwnerID();
        let oldValue = pOnwer.getReadableCoordinates();
        let colName = ParcoursOwner.prototype.col.coordinates;
        console.log("Change coordinates: " + colName + " from (" + oldValue + ") to (" + newValue + ")");
        this.parseSaveParcours(pOnwer, colName, oldValue, newValue, viElement)
    }
    parseSaveParcours(object, colName, oldValue, pNewValue, target) {
        if (!pNewValue) {
            this.showTargetHelpMessage(target, messages.get("parcours.edit.message.noValue"), "error")
        } else if (oldValue == pNewValue) {
            this.showTargetHelpMessage(target, messages.get("parcours.edit.message.valueNotChanged"), "error")
        } else if (object) {
            var newValueGen = pNewValue;
            if ("coordinates" === colName) {
                // handle coordinates
                var reg = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
                //var reg = new RegExp("^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$");
                if (reg.exec(pNewValue)) {
                    var coordinates = pNewValue.split(",");
                    newValueGen = new Parse.GeoPoint({latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])});
                } else {
                    this.showTargetHelpMessage(target, messages.get("form.valid.not.coordinates"), "error")
                    return;
                }
            } else if ("targetAmounts" === colName){
                newValueGen = parseInt(pNewValue);
                target.setValue(newValueGen)
            }
            // run cloud code change
            Parse.Cloud.run('editParcours', { objectId: object.id, objectClass: object.className, dirtyKey: colName, newValue: newValueGen})
                .then(savedObject => {
                    if (target) {
                        this.showTargetHelpMessage(target, messages.get("parcours.edit.message.saved"), "success")
                    }
                    // TODO ?? update state with object
                    if ("parcours" == object.className) {
                        parcoursActions.updateParcours(savedObject);
                    } else if ("parcoursowner" == object.className) {
                        this.props.parcours.setParcoursOwnerID(savedObject);
                        parcoursActions.updateParcours(this.props.parcours);
                    }
                })
                .catch(error => {
                    console.log("PEDIT_sPObj" +  error.message);
                });
        }
    }
    showTargetHelpMessage(target, message, style) {
        target.showHelpMessage(message, style);
    }
    handleBannerUpdated(image) {
        if (image != null) {
            // TODO - how to unset image!
            parcoursActions.updateParcoursCC(this.props.parcours.getParcoursOwnerID(), "bannerImageID", image.id);
        }
    }
    render() {
        const {parcours, user} = this.props;
        return (
            <div>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb link={"/parcours/" + parcours.getOnlineID() } code={parcours.buildNames()}/>
                            <Breadcrumb code="parcours.details.button.editParcours" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <ImageBanner imageObject={parcours.getParcoursOwnerID().getBannerImageID()}/>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{parcours.buildNames()}</PageHeader>
                        <Alert bsStyle="info">{messages.get("parcours.details.edit.description")}</Alert>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Panel>
                            <Panel.Heading>{messages.get("parcours.details.area.parcoursInfo") + " - ID: " + parcours.getOnlineID()}</Panel.Heading>
                            <Panel.Body>
                                <Col sm={4} lg={3}>
                                    <ValidInput label={messages.get("parcours.add.name")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, Parcours.prototype.col.name, parcours)}
                                                default={parcours.getName()}/>
                                    <ValidInput label={messages.get("parcours.details.area.targetamount")}
                                                valid={{maxLength: 3, check:['greater0', 'isNumber']}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, Parcours.prototype.col.targetAmounts, parcours)}
                                                default={parcours.getTargetAmounts()}/>
                                    <ValidInput label={messages.get("parcours.details.area.description.parcours")}
                                                componentClass="textarea"
                                                valid={{maxLength: 500}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, Parcours.prototype.col.description, parcours)}
                                                default={parcours.getDescription()}/>
                                </Col>
                                <Col sm={4}>
                                    <ParcoursCategoryEditor
                                        header={messages.get("parcours.details.area.parcours")}
                                        object={parcours} colName={"category"} array={parCatArr}
                                        parseSaveParcours={this.parseSaveParcours}/>
                                </Col>
                                <Col sm={4}>
                                    <ParcoursCategoryEditor ppCheckObject={{ranking: parcours.hasRankingEnabled(), suffix:"PP"}}
                                        header={messages.get("parcours.details.area.constraints")}
                                        object={parcours} colName={"constraints"} array={constraintsArr}
                                        parseSaveParcours={this.parseSaveParcours}/>
                                </Col>
                            </Panel.Body>
                        </Panel>
                        <Panel>
                            <Panel.Heading>{messages.get("parcours.details.area.ownerInfo")}</Panel.Heading>
                            <Panel.Body>
                                <Col sm={4}>
                                    <ValidInput label={messages.get("address.union")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.name, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getName()}/>
                                    <ValidInput label={messages.get("address.street")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.street, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getStreet()}/>
                                    <ValidInput label={messages.get("address.zip")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.zip, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getZip()}/>
                                    <ValidInput label={messages.get("address.place")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.place, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getPlace()}/>
                                    <ValidInput label={messages.get("parcours.details.area.description.owner")}
                                                componentClass="textarea"
                                                valid={{maxLength: 500}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.description, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getDescription()}/>
                                    <FileUploadArea header={"parcours.edit.poBanner"}
                                                    allowedFileTypes={["I"]}
                                                    object={parcours.getParcoursOwnerID()}
                                                    colName={"bannerImageID"}
                                                    savedImageCallback ={this.handleBannerUpdated}
                                                    user={user}/>
                                </Col>
                                <Col sm={4}>
                                    <ParcoursCategoryEditor
                                        header={messages.get("parcours.details.area.infra")}
                                        object={parcours.getParcoursOwnerID()} colName={"category"} array={parOwnCatArr}
                                        parseSaveParcours={this.parseSaveParcours}/>
                                        <br/>
                                    <ParcoursCategoryEditor
                                        header={messages.get("parcours.details.area.association")}
                                        object={parcours.getParcoursOwnerID()} colName={"association"} array={associationArr}
                                        parseSaveParcours={this.parseSaveParcours}/>
                                </Col>
                                <Col sm={4}>
                                    <ValidInput label={messages.get("address.contactName")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.contactName, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getContactName()}/>
                                    <ValidInput label={messages.get("address.phone")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.contactPhone, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getContactPhone()}/>
                                    <ValidInput label={messages.get("address.mail")}
                                                valid={{maxLength: 50, check:['isMail']}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.contactMail, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getContactMail()}/>
                                    <ValidInput label={messages.get("parcours.details.area.webpage")}
                                                valid={{maxLength: 50}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.weblink, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getWeblink()}/>
                                    <CoordinateInput objectWithCoords={parcours.getParcoursOwnerID()} onSubmit={this.handleCoordinateUpdate}/>
                                </Col>
                            </Panel.Body>
                        </Panel>
                        <Panel>
                            <Panel.Heading>
                                {messages.get("parcours.details.area.pricesAtimes")}
                            </Panel.Heading>
                            <Panel.Body>
                                {
                                    parcours.hasPaymentEnabled() ? <Col sm={6}>
                                        <ParcoursArticlesButton disabled={!parcours.isParcoursManager(user.id)} bsStyle={"success"} parcoursOwner={parcours.getParcoursOwnerID()}/>
                                        </Col> :
                                        <Col sm={6}>
                                            <ValidInput label={messages.get("parcours.details.area.dayprice")}
                                                        componentClass="textarea"
                                                        valid={{maxLength: 500}}
                                                        savePressed={(text, viElement) => this.pressedSave(text, viElement, Parcours.prototype.col.dayprice, parcours)}
                                                        default={parcours.getDayprice()}/>
                                            <ValidInput label={messages.get("parcours.details.area.specialprice")}
                                                        componentClass="textarea"
                                                        valid={{maxLength: 500}}
                                                        savePressed={(text, viElement) => this.pressedSave(text, viElement, Parcours.prototype.col.specialprice, parcours)}
                                                        default={parcours.getSpecialprice()}/>
                                            <ValidInput label={messages.get("parcours.details.area.yearprice")}
                                                        componentClass="textarea"
                                                        valid={{maxLength: 500}}
                                                        savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.yearprice, parcours.getParcoursOwnerID())}
                                                        default={parcours.getParcoursOwnerID().getYearprice()}/>
                                        </Col>
                                }
                                <Col sm={6}>
                                    <ValidInput label={messages.get("parcours.details.area.openTimes")}
                                                componentClass="textarea"
                                                valid={{maxLength: 500}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.opentimes, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getDescription()}/>
                                    <ValidInput label={messages.get("parcours.details.area.supportTimes")}
                                                componentClass="textarea"
                                                valid={{maxLength: 500}}
                                                savePressed={(text, viElement) => this.pressedSave(text, viElement, ParcoursOwner.prototype.col.supporttimes, parcours.getParcoursOwnerID())}
                                                default={parcours.getParcoursOwnerID().getSupporttimes()}/>


                                    <ParcoursOpenDays items={parcours.getParcoursOwnerID().getOpenDays()}
                                                      object={parcours.getParcoursOwnerID()} colName={"openDays"}
                                                      parseSaveParcours={this.parseSaveParcours}/>
                                </Col>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object
};
module.exports = ParcoursEditPage;