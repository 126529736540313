const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const TournamentUserManagerStatus = ({tournamentUser, addText}) => {
    let font = null;
    let code = "code.unknown";
    let style = "default";
    const status = tournamentUser.status;
    switch (tournamentUser.getStatus()) {
        case status.registered:
        {
            font = "registered";
            code = "tournamentUser.status.registered";
            style = "warning";
            break;
        }
        case status.present:
        {
            font = "check-circle";
            code = "tournamentUser.status.A";
            style = "success";
            break;
        }
        case status.started:
        {
            font = "play-circle";
            code = "tournamentUser.status.S";
            style = "info";
            break;
        }
        case status.scoreSubmitted:
        {
            code = "tournamentUser.status.S";
            break;
        }
        case status.proofSingleResult:
        {
            font = "bullhorn";
            code = "tournamentUser.status.proof";
            style = "primary";
            break;
        }
        case status.waiting:
        {
            font = "pause";
            code = "tournamentUser.status.waiting";
            style = "warning";
            break;
        }
        case status.finished:
        {
            font = "flag";
            code = "tournament.status.finished";
            break;
        }
        case status.finishedRound:
        {
            font = "flag";
            code = "tournament.status.finished";
            break;
        }
        case status.disqualified:
        {
            font = "ban";
            code = "tournamentUser.status.disqualified";
            style = "danger";
            break;
        }
        case status.deleted:
        {
            font = "trash";
            code = "tournamentUser.status.D";
            style = "danger";
            break;
        }
    }
    return (<Label bsStyle={style}><FontAwesome icon={font}/>
        {addText ? <I18n code={code}/> : null }
    </Label>)
};
TournamentUserManagerStatus.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    addText: PropTypes.bool.isRequired
}
module.exports = TournamentUserManagerStatus;
