const {isBefore, addDays, startOfMonth, endOfMonth, getISOWeek, addWeeks, startOfWeek, endOfWeek} = require('date-fns');

const CalendarUtility = {
    calcCalendarBoundaries: function(date) {
        let weekIt = {};
        let weekItKeys = [];
        let monthStart = startOfMonth(date);
        let monthEnd = endOfMonth(date);
        let weekRunner = startOfMonth(date);
        //let weeks = differenceInCalendarWeeks(monthStart, monthEnd);
        //let firstWeekStart = startOfWeek(monthStart);
        let lastWeekEnd =  endOfWeek(monthEnd, {weekStartsOn: 1});
        // run through the weeks as long as last weekday of this month is reached
        while (isBefore(weekRunner,lastWeekEnd)) {
            let weekStart = startOfWeek(weekRunner, {weekStartsOn: 1});
            weekItKeys.push(getISOWeek(weekStart));
            weekIt[getISOWeek(weekStart)] = {
                wd1: weekStart,
                wd2: addDays(weekStart, 1),
                wd3: addDays(weekStart, 2),
                wd4: addDays(weekStart, 3),
                wd5: addDays(weekStart, 4),
                wd6: addDays(weekStart, 5),
                wd0: addDays(weekStart, 6)};
            // check next week
            weekRunner = addWeeks(weekRunner, 1)
        }
        return {
            year: date.getFullYear(),
            firstDay: startOfWeek(monthStart, {weekStartsOn: 1}),
            lastDay: lastWeekEnd,
            weekKeys: weekItKeys,
            weekDict: weekIt,
            monthStart: monthStart,
            monthEnd: monthEnd
        };
    }
};

module.exports = CalendarUtility;
