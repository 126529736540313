const Parse = require("ParseInit");
const EventTrack = Parse.Object.extend("EventTrack", {
    col: {
        track: "track",
        trackSum: "tracksum",
        parcoursOnlineID: "parcoursOnlineID",
        status: "status",
        reworkCounter: "reworkCounter",
        createdAt: "createdAt"
    },
    status: {
        published: "A",
        deleted: "D"
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getParcoursOnlineID() {
        return this.get(this.col.parcoursOnlineID)
    },
    getTrack() {
        return this.get(this.col.track)
    },
    getTrackSum() {
        return this.get(this.col.trackSum)
    },
    getTrackReworkCounter() {
        return this.get(this.col.reworkCounter)
    },
    getTrackDuration()  {
        let trackSumArr = this.getTrackSum();
        if (trackSumArr && trackSumArr.length > 0) {
            let diffS = 0;
            trackSumArr.map(trackSum => {
                let jsTrackSum = JSON.parse(trackSum);
                diffS += jsTrackSum[Object.keys(jsTrackSum)[0]]["du"];
            });
            let preMin = "";
            let preH = "";
            // time calculation
            let minutes = Math.round((diffS % 3600) / 60);
            if (minutes <= 9) {
                preMin = "0";
            }
            let hours = Math.round(diffS / 3600);
            if (hours <= 9) {
                preH = "0";
            }
            return preH + hours + ":" + preMin + minutes + " h";
        }
        return null;
    },
    getTrackDistance() {
        let trackSumArr = this.getTrackSum();
        if (trackSumArr && trackSumArr.length > 0) {
            let distance = 0.0;
            trackSumArr.map(trackSum => {
                let jsTrackSum = JSON.parse(trackSum);
                distance += jsTrackSum[Object.keys(jsTrackSum)[0]]["di"];
            });
            if (distance < 1) {
                return null;
            } else if (distance > 1000) {
                return (distance / 1000).toFixed(2) + " km";
            } else {
                return distance.toFixed(0) + " m";
            }
        }
        return null;
    }
});

module.exports = EventTrack;
