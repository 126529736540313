const React = require("react");
const {Grid, Row, Col, Jumbotron} = require("react-bootstrap");


const NotFound = ({path}) => {
    React.useEffect(() => {
        document.title = "3D Turnier - Not Found";
    }, [path]);
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Jumbotron>
                        <h1><span className="error-404">404</span></h1>
                        <p>The page you're looking for could not be found.</p>
                    </Jumbotron>
                </Col>
            </Row>
        </Grid>)
};
module.exports = NotFound;
