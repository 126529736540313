const React = require("react"),
    PropTypes = require('prop-types');
const {Nav, Navbar, NavDropdown, MenuItem} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TrainingTeamModal = require("components/training/TrainingTeamModal");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const MenuNavItemWrap = require('components/MenuNavItemWrap');

const trainingActions = require("actions/TrainingActions");



const TrainingMenu = ({trainingTeams, trainingTeam, trainingFilters, user}) => {
    return <TrainingTeamModal user={user}>
        <NavTrainingMenu user={user}
                         trainingFilters={trainingFilters}
                         trainingTeams={trainingTeams}
                         trainingTeam={trainingTeam}/>
    </TrainingTeamModal>
}

const NavTrainingMenu = ({trainingTeams, trainingTeam, trainingFilters, user}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function handleCreateTeam() {
        modalBasics.openObjectFct(null, "create")
    }
    function handleEditTeam() {
        modalBasics.openObjectFct(trainingTeam, "create")
    }
    function resetTeam() {
        trainingActions.resetTeam()
    }
    function resetScatter() {
        trainingActions.resetTeam()
        trainingActions.resetFilter()
    }
    return <Navbar>
            <Nav>
                <MenuNavItemWrap eventKey={1} route={"training"} label="header.nav.training" onSelect={resetTeam}/>
                <MenuNavItemWrap eventKey={2} route={"trainingscatter"} label="header.nav.scatter" onSelect={resetScatter}/>
                {
                    trainingFilters != null && trainingFilters.length > 0 ?
                        <NavDropdown eventKey={3} title="+Filter" id="filter-nav-dropdown">
                            {
                                trainingFilters.map(trainingFilter => {
                                    return <MenuNavItemWrap key={trainingFilter.id} eventKey={trainingFilter.id}
                                                            onSelect={resetScatter}
                                                            route={"trainingscatter/" + trainingFilter.id} label={trainingFilter.getName()}/>
                                })
                            }
                        </NavDropdown> : null
                }

                <NavDropdown eventKey={3} title={"Training-Teams (" + trainingTeams.length + ")"} id="basic-nav-dropdown">
                    {
                        trainingTeams.map(team => {
                            if (trainingTeam != null && trainingTeam.id === team.id && trainingTeam.isEditor(user)) {
                                return <MenuItem key={team.id} eventKey={team.id} onClick={handleEditTeam}>
                                    <FontAwesome icon="pencil-alt">{trainingTeam.getName()}</FontAwesome>
                                </MenuItem>
                            } else {
                                return <MenuNavItemWrap key={team.id} eventKey={team.id} route={"trainingteam/" + team.id} label={team.getName()}/>
                            }
                        })
                    }
                    <MenuItem divider />
                    <MenuItem eventKey={3.4} onClick={handleCreateTeam}><I18n code="profile.training.team.newTeam"/></MenuItem>
                </NavDropdown>
            </Nav>
        </Navbar>;
}
module.exports = TrainingMenu;
