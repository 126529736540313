const React = require("react"),
    PropTypes = require('prop-types');
const FontAwesome = require("components/widgets/FontAwesome");

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.log("ComponentDidCatch(" + this.props.identifier + "): ", error, errorInfo);
    }
    render() {
        const {error} = this.state;
        if (error) {
            // You can render any custom fallback UI
            return <FontAwesome icon="bug">{error.toString()}</FontAwesome>;
        }
        return this.props.children;
    }
}
ErrorBoundary.propTypes = {
    identifier: PropTypes.string,
    children:PropTypes.any.isRequired
};
module.exports = ErrorBoundary;
