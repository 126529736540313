const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    CheckboxInput = require("components/form/CheckboxInput"),
    ModalBasic = require ("components/modals/ModalBasic"),
    DecisionForm = require("components/form/DecisionForm"),
    ValidInput = require("components/form/ValidInput");

const {EventTrainingTeams} = require("parse/_Domain");
const trainingActions = require("actions/TrainingActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TrainingTeamModal = ({children, user}) =>  {
    return (
        <ModalBasic
            contextChildren={children}
            title={<TextCenter><I18n code="profile.training.team.editTeam"/></TextCenter>}
            customButton={" "}>
            <TrainingForm user={user} />
        </ModalBasic>
    )
};
TrainingTeamModal.propTypes = {
    user:PropTypes.object.isRequired
};
class TrainingForm extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.refName = React.createRef();
        this.refVisible = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.getDefault = this.getDefault.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refName.current.getValue(),
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {object, closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let editTrainingTeam = object;
        if (editTrainingTeam == null) {
            editTrainingTeam = new EventTrainingTeams();
            editTrainingTeam.setStatus(EventTrainingTeams.prototype.status.active);
            editTrainingTeam.setMainEditor(this.props.user);
        }
        editTrainingTeam.setName(this.refName.current.getValue());
        editTrainingTeam.setUserVisible(this.refVisible.current.getValue() === true);

        trainingActions.saveEventTrainingTeam(editTrainingTeam, success => {
            disableSubmitFct(true);
            if (success) {
                closeFct();
            }
        });
    }
    getDefault(column) {
        const {object} = this.context;
        if (object != null) {
            return object.get(column);
        }
        return "";
    }
    handleDelete() {
        const {object, closeFct} = this.context;
        //trainingActions.deleteEventTraining(object);
        closeFct();
    }
    render() {
        const {object, objectType, error, submitDisabled, closeFct} = this.context;
        if (objectType === "create") {
            // form for creating new entry
            return (
                <form action="#">
                    <Row>
                        <Col sm={12}>
                            <ValidInput ref={this.refName}
                                        valid={{maxLength: 50, check:['isRequired']}}
                                        placeholder={messages.get("tournament.table.column.name")}
                                        default={this.getDefault(EventTrainingTeams.prototype.col.name)}/>

                            <CheckboxInput ref={this.refVisible}
                                           default={this.getDefault(EventTrainingTeams.prototype.col.userVisible)}
                                           message={messages.get("profile.training.team.userVisible")} />
                            <hr/>


                        </Col>
                        <Col sm={12}>
                            {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                            <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                                <I18n code={"modal.button.save"}/>
                            </Button>
                        </Col>
                    </Row>
                </form>
            );
        } else if (objectType == "delete" && object != null) {
            // delete object
            return (
                <DecisionForm
                    name={object.getCalName() + " - " + object.getReadableDate()}
                    messageCode={"list.entry.delete"}
                    okButtonCode={"modal.button.delete"}
                    cancelButtonCode={"modal.button.cancel"}
                    handleOK={this.handleDelete}
                    handleCancel={closeFct}
                />
            )
        } else {
            return <p>WTF - should not happen</p>
        }

    }
}
TrainingForm.propTypes = {
    user:PropTypes.object.isRequired
};
TrainingForm.contextType = ModalBasicContext;
module.exports = TrainingTeamModal;
