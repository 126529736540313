/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome");

const ParcoursCategory = ({header, list, faIcon}) => {
    return (<React.Fragment>
        {
            header == null ? "" : <strong>{header}</strong>
        }
        <ul>
            {
                list.map(category => {
                    return (<li className="none" key={category}><FontAwesome icon={faIcon}>{messages.get(category)}</FontAwesome></li>)
                })
            }
        </ul>
    </React.Fragment>)
};
ParcoursCategory.propTypes = {
    header: PropTypes.string,
    faIcon: PropTypes.string,
    list: PropTypes.array.isRequired
};
module.exports = ParcoursCategory;