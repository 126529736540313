const React = require("react"),
    PropTypes = require('prop-types');
const { ControlLabel, InputGroup, FormGroup, FormControl, Button} = require("react-bootstrap");

class SearchInputField extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onButtonPressed = this.onButtonPressed.bind(this);
        this.state = {
            value: this.props.default || "",
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(e) {
        const value = e.target.value;
        this.setState({value: value});
    }
    onKeyPress(event) {
        if (event.key === 'Enter' && this.props.startSearch) {
            this.props.startSearch(this.state.value)
        }
    }
    onButtonPressed(event) {
        if (this.props.startSearch) {
            this.props.startSearch(this.state.value)
        }
    }
    render() {
        const {label, disabled, placeholder, componentClass, type, addonBefore, addonAfter} = this.props;
        return (
            <FormGroup>
                {
                    label != null ? <ControlLabel>{label}</ControlLabel> : null
                }
                <InputGroup>
                    {addonBefore != null ? <InputGroup.Addon>{addonBefore}</InputGroup.Addon> : null}
                    <FormControl type={type}
                                 disabled={disabled}
                                 componentClass={componentClass}
                                 value={this.state.value}
                                 placeholder={placeholder}
                                 onChange={this.handleChange}
                                 onKeyPress={this.onKeyPress}
                                 ref="input"/>
                    {addonAfter != null ? <InputGroup.Addon>{addonAfter}</InputGroup.Addon> : null}
                    <InputGroup.Button>
                        <Button disabled={disabled} onClick={this.onButtonPressed}><FontAwesome icon="check"/></Button>
                    </InputGroup.Button>
                </InputGroup>
            </FormGroup>)
    }
}
SearchInputField.propTypes = {
    placeholder: PropTypes.string,
    default: PropTypes.any,
    startSearch: PropTypes.func,
    addonAfter: PropTypes.any,
    addonBefore: PropTypes.any,
    label: PropTypes.object,
    componentClass: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string
};
module.exports = SearchInputField;
