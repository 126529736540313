const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Label} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

const TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentUserStatus = require("components/tournament/TournamentUserStatus"),
    TournamentLink = require("components/tournament/TournamentLink");

const TournamentCupTable = ({childTournaments, childTournamentUsers, tournament, refTournamentUser}) => {
    function getChildTournamentUser(childTournament) {
        if (childTournamentUsers) {
            for (let i = 0; i < childTournamentUsers.length; i++) {
                if (childTournament.id == childTournamentUsers[i].getTournamentStringID()) {
                    return childTournamentUsers[i];
                }
            }
        }
        return null;
    }
    const hasCupGroups = tournament.getTournamentCupOptionID() != null && tournament.getTournamentCupOptionID().getCupGroups() != null;
    return (
            <Row>
                <Col xs={12}>
                    <Table responsive striped hover>
                        <thead>
                        <tr>
                            <th><I18n code="tournament.table.column.name"/></th>
                            <th><I18n code="tournament.table.column.date"/></th>
                            {
                                hasCupGroups ? <th><I18n code="tournament.table.column.group"/></th> : null
                            }
                            <th><I18n code="tournament.create.place"/></th>
                            <th><I18n code="tournament.table.column.status"/></th>
                            {
                                refTournamentUser ? <th>Pos / <I18n code="tournament.table.column.points"/></th>: null
                            }
                            {
                                refTournamentUser ? <th><I18n code="tournament.table.column.cupPoints"/></th>: null
                            }
                            {
                                refTournamentUser ? <th>Kills</th>: null
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            childTournaments.map(childTournament => {
                                return <TournamentCupChild key={childTournament.id} hasCupGroups={hasCupGroups}
                                                           refTournamentUser={refTournamentUser}
                                                           childTournament={childTournament}
                                                           childTournamentUser={getChildTournamentUser(childTournament)}/>
                            })
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
    );
};
TournamentCupTable.propTypes = {
    tournament: PropTypes.object.isRequired,
    refTournamentUser: PropTypes.object,
    childTournaments: PropTypes.array.isRequired,
    childTournamentUsers: PropTypes.array,
};
const TournamentCupChild = ({childTournament, childTournamentUser, hasCupGroups, refTournamentUser}) => {
    function isNameMisMatch(refTournamentUser, childTournamentUser) {
        let name1 = refTournamentUser.getFullName().toLocaleLowerCase();
        name1 = name1.replace(/ /g , "");
        let name2 = childTournamentUser.getFullName().toLocaleLowerCase();
        name2 = name2.replace(/ /g , "");
        return name1 != name2;
    }
    function isConfigMatch(refTournamentUser, childTournamentUser) {
        return refTournamentUser.getTournamentConfigAge().id != childTournamentUser.getTournamentConfigAge().id ||
            refTournamentUser.getTournamentConfigBow().id != childTournamentUser.getTournamentConfigBow().id
    }
    let resultCol1 = null;
    let resultCol2 = null;
    let resultCol3 = null;
    let refConfigMisMatch = null;
    let nameMisMatch = null;
    if (refTournamentUser) {
        if (childTournamentUser && childTournamentUser.getResultPosition()) {
            resultCol1 = <td>{childTournamentUser.getResultPosition() + " / " + childTournamentUser.getSumPoints()}</td>
            resultCol2 = <td>{childTournamentUser.getCupPoints()}</td>
            resultCol3 = <td>{childTournamentUser.getKillCounts2String()}</td>
        } else {
            resultCol1 = <td></td>
            resultCol2 = <td></td>
            resultCol3 = <td></td>
        }
        if (childTournamentUser && isConfigMatch(refTournamentUser, childTournamentUser)) {
            refConfigMisMatch = <Label bsStyle="danger">{messages.get("tournament.manager.bowage.conflict")}</Label>
        }
        if (childTournamentUser && isNameMisMatch(refTournamentUser, childTournamentUser)) {
            nameMisMatch = <Label bsStyle="warning">{childTournamentUser.getFullName()}</Label>
        }
    }
    return (<tr>
        <td><TournamentLink tournament={childTournament}
                            label={childTournament.getName()}/></td>
        <td>{childTournament.getTournamentDate()}</td>
        {
            hasCupGroups ? <td>{childTournament.getCupGroup()}</td> : null
        }
        <td>{childTournament.getZipPlace()}</td>
        <td>
            {refConfigMisMatch}{nameMisMatch}
            {
                childTournamentUser ?
                    <TournamentUserStatus tournamentUser={childTournamentUser} />
                    :
                    <TournamentStatus tournament={childTournament}/>
            }
           </td>
        {resultCol1}{resultCol2}{resultCol3}
    </tr>);
};
TournamentCupChild.propTypes = {
    childTournament:PropTypes.object.isRequired,
    childTournamentUser:PropTypes.object,
    refTournamentUser:PropTypes.object,
    hasCupGroups: PropTypes.bool.isRequired
};
module.exports = TournamentCupTable;
