const React = require("react"),
    PropTypes = require('prop-types');
const {Col} = require("react-bootstrap");

const SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead"),
    SubmitButton = require("components/form/SubmitButton"),
    messages = require("i18n/messages");

const parcoursTargetActions = require("actions/ParcoursTargetActions"),
    parcoursTargetStore = require("stores/ParcoursTargetStore");

class TargetCatalogArticles extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursTargetChange = this.handleParcoursTargetChange.bind(this);
        this.state = {
            parcoursTargetStore: parcoursTargetStore.getState(),
        };
    }
    componentDidMount() {
        parcoursTargetStore.listen(this.handleParcoursTargetChange);
    }
    componentWillUnmount() {
        parcoursTargetStore.unlisten(this.handleParcoursTargetChange);
    }
    handleParcoursTargetChange(state) {
        this.setState({parcoursTargetStore: state});
    }
    loadTargets() {
        parcoursTargetActions.loadTargetBrandCatalog();
    }
    render() {
        const {targetCatalog} = this.state.parcoursTargetStore;
        const {addTargetToArticles} = this.props;
        return (
            <Col xs={12} md={6}>
                {
                    targetCatalog != null && targetCatalog.length > 0 ?
                        <AddParcoursTarget  targetCatalog={targetCatalog} addTargetToArticles={addTargetToArticles}/> :
                        <SubmitButton onClick={this.loadTargets} text={"Load 3D TargetCatalog"}/>
                }
                <br/>
            </Col>
        );
    }
}
TargetCatalogArticles.propTypes = {
    addTargetToArticles: PropTypes.func.isRequired,
}
class AddParcoursTarget extends React.Component {
    constructor(props) {
        super(props);
        this.handleTargetSelected = this.handleTargetSelected.bind(this);
    }
    handleTargetSelected(target) {
        this.props.addTargetToArticles(target);
    }
    render() {
        return (<React.Fragment>
                <SelectInputTypeAhead disabled={false}
                                      objectList={this.props.targetCatalog}
                                      handleSelectedObject={this.handleTargetSelected}
                                      handleNoObjectFound={(name) => console.log(name)}
                                      placeholder={messages.get("parcours.targets.input.name.target") + " / " + messages.get("parcours.targets.input.name.search")}/>
            </React.Fragment>)
    }
}
AddParcoursTarget.propTypes = {
    addTargetToArticles: PropTypes.func.isRequired,
    targetCatalog: PropTypes.array.isRequired
}
module.exports = TargetCatalogArticles;
