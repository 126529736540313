const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Alert, ButtonGroup, MenuItem, ListGroupItem, ListGroup} = require("react-bootstrap");

const {TournamentUserTeam} = require("parse/_Domain");

const LoadingCircle = require("components/widgets/LoadingCircle"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    I18n = require("components/widgets/I18n"),
    DelayedTextField = require("components/form/DelayedTextField"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TooltipButton  = require("components/form/TooltipButton"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const TournamentUserManagerStatus = require("components/tournamentmanage/TournamentUserManagerStatus");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

const tableAction = require("actions/TableActions"),
    tableStore = require("stores/TableStore");

function getGroupFromArray(index, tournamentUserTeams) {
    if (index < tournamentUserTeams.length) {
        return tournamentUserTeams[index];
    }
    return null;
}

class TournamentUserTeamPanel extends React.Component {
    constructor(props) {
        super(props);
        this.addSelectedTUserToTeam = this.addSelectedTUserToTeam.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.state = {
            tableStore: tableStore.state
        }
    }
    componentDidMount() {
        tableStore.listen(this.handleTableChange);
    }
    componentWillUnmount() {
        tableStore.unlisten(this.handleTableChange);
    }
    handleTableChange(state) {
        this.setState({tableStore: state});
    }
    addSelectedTUserToTeam(tUserTeam) {
        const {selectedObjectList} = this.state.tableStore;
        // console.log("Add " + selectedObjectList.length +" TUsers to team " + tUserTeam.getName());
        tournamentUserManagerActions.addTUserToTournamentTeam(tUserTeam, selectedObjectList);
        tableAction.resetSelectedObjectList()
    }
    render() {
        const {tournament, tournamentUserStore, groupingMode, runningTRounds, singleStartedTRound, user} = this.props;
        const pnlStyle = groupingMode === true ? {maxHeight: "1200px", overflow: "auto"} :  {};
        const colStyle = groupingMode === true ? {paddingLeft: "0"} :  {};
        return <Col xs={12} md={groupingMode ? 4 : 12} style={colStyle}>
                <Panel style={pnlStyle}>
                    <Panel.Heading>
                        <TopPanelArea tournament={tournament} tournamentUserStore={tournamentUserStore} user={user}
                                      runningTRounds={runningTRounds} handleStartGroups={this.startAllGroups}
                                      updateTournamentGroupStatus={this.updateTournamentGroupStatus}
                                      tUserGroupAmount={tournamentUserStore.tournamentUserTeams.length} groupingMode={groupingMode}/>
                    </Panel.Heading>
                    <Panel.Body>
                        <TournamentGroupPanelBody tournamentUserTeams={tournamentUserStore.tournamentUserTeams}
                                                  tournament={tournament}
                                                  singleStartedTRound={singleStartedTRound}
                                                  runningTRounds={runningTRounds}
                                                  teamChangeId={tournamentUserStore.teamChangeId}
                                                  add2Team={this.addSelectedTUserToTeam} groupingMode={groupingMode} />
                    </Panel.Body>
                </Panel>
            </Col>
    }
}
TournamentUserTeamPanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object,
    user: PropTypes.object.isRequired,
    groupingMode: PropTypes.bool.isRequired
};
const TopPanelArea = ({tournament, user, tournamentUserStore, tUserGroupAmount, groupingMode, handleStartGroups, runningTRounds, updateTournamentGroupStatus}) => {
    const reloadTUTeams = () => {
        tournamentUserManagerActions.loadTournamentUserTeams(tournament, tournamentUserStore.hideDeleted);
    };
    return (<React.Fragment>
        <Row>
            <Col xs={9} md={groupingMode ? 7 : 5}>
                <h4><strong><FontAwesome icon={"user-friends"}/><I18n code="tournament.mmtuser.teams"/></strong></h4>
            </Col>
            <Col xs={3} md={groupingMode ? 5 : 2}>
                {
                    tournamentUserStore.loadingTeam ? <LoadingCircle/> : <p>{"Teams: " + tUserGroupAmount}</p>
                }
            </Col>
        </Row>

        <Row>
            <Col xs={12} md={groupingMode ? 12 : 7}>
                <ButtonGroup>
                    <TooltipButton onClick={reloadTUTeams} icon="sync" tooltip={messages.get("tournament.mmtuser.tooltip.team.sync")} />
                </ButtonGroup>
            </Col>
            <Col xs={12} md={groupingMode ? 12 : 5}>
                {
                    runningTRounds.length > 0 ? null :
                        <Alert style={{marginBottom:"0"}} bsStyle="warning">{messages.get("tournament.mmtuser.team.noStartedRound")}</Alert>
                }
            </Col>
        </Row>
    </React.Fragment>)
};
TopPanelArea.propTypes = {
    tournament: PropTypes.object.isRequired,
    tUserGroupAmount: PropTypes.number.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    groupingMode: PropTypes.bool.isRequired,
    handleStartGroups: PropTypes.func.isRequired,
    updateTournamentGroupStatus: PropTypes.func.isRequired,
    runningTRounds :PropTypes.array.isRequired
};

const TournamentGroupPanelBody = React.memo(
    function TournamentGroupPanelBody({tournament, tournamentUserTeams, teamChangeId, groupingMode, add2Team,
                                          singleStartedTRound, runningTRounds}) {
        // only rerenders if props change
        const grpStyle = groupingMode ? {paddingLeft: "0", paddingRight: "0"} :  {};
        const modCol = 3;
        let rowArray = Utility.buildModArray(tournamentUserTeams.length, modCol);
        // groupChangeId -> Used to trigger reRender
        console.log("Render TournamentGroupPanelBody ID: " + teamChangeId + " /rows: " + rowArray) ;

        return <React.Fragment>
            {
                rowArray.map(index => {
                    return <TournamentGroupPanelGrpRow key={"TGPB_" + index} index={index * modCol}
                                                       runningTRounds={runningTRounds}
                                                       singleStartedTRound={singleStartedTRound}
                                                       groupingMode={groupingMode}
                                                       tournamentUserTeams={tournamentUserTeams} add2Team={add2Team} grpStyle={grpStyle} tournament={tournament}/>
                })
            }
        </React.Fragment>
});
TournamentGroupPanelBody.propTypes = {
    tournamentUserTeams: PropTypes.array.isRequired,
    teamChangeId: PropTypes.number.isRequired,
    groupingMode: PropTypes.bool.isRequired,
    add2Team: PropTypes.func.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object
};
const TournamentGroupPanelGrpRow = ({index, groupingMode, grpStyle, tournamentUserTeams, tournament, singleStartedTRound,
                                        runningTRounds, add2Team}) => {
    const tUserTeam1 = getGroupFromArray(index, tournamentUserTeams);
    const tUserTeam2 = getGroupFromArray(index + 1, tournamentUserTeams);
    const tUserTeam3 = getGroupFromArray(index + 2, tournamentUserTeams);
    return <Row >
        {
            tUserTeam1 ?
                <Col key={tUserTeam1.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentTeamPanel tUserTeam={tUserTeam1} tournament={tournament}
                                         runningTRounds={runningTRounds}
                                         singleStartedTRound={singleStartedTRound}
                                         add2Team={add2Team} />
                </Col> : null
        }
        {
            tUserTeam2 ?
                <Col key={tUserTeam2.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentTeamPanel tUserTeam={tUserTeam2} tournament={tournament}
                                         runningTRounds={runningTRounds}
                                         singleStartedTRound={singleStartedTRound}
                                         add2Team={add2Team}/>
                </Col> : null
        }
        {
            tUserTeam3 ?
                <Col key={tUserTeam3.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentTeamPanel tUserTeam={tUserTeam3} tournament={tournament}
                                         runningTRounds={runningTRounds}
                                         singleStartedTRound={singleStartedTRound}
                                         add2Team={add2Team}/>
                </Col> : null
        }
    </Row>
};


const TournamentTeamPanel = ({tUserTeam, tournament, add2Team, singleStartedTRound, runningTRounds}) => {
    const teamIsPresent = (tRound) => {
        tournamentUserManagerActions.updateTournamentUserTeamStatus(tUserTeam, tRound, TournamentUserTeam.prototype.status.present, true);
    };
    const deleteTeam = (doDelete) => {
        if (doDelete) {
            tournamentUserManagerActions.deleteTournamentUserTeam(tUserTeam);
        }
    };
    const clickedAddTUser = () => {
        add2Team(tUserTeam);
    };
    const changeTeamName = (name) => {
        console.log("Change to name " + name);
        tUserTeam.setName(name);
        tournamentUserManagerActions.saveTournamentUserTeam(tUserTeam)
    };
    const reUseTeam = (tRound) => {
        tournamentUserManagerActions.updateTournamentUserTeamStatus(tUserTeam, tRound, TournamentUserTeam.prototype.status.present, false);
    };
    const removeFromTeam = (tUser) => {
        tournamentUserManagerActions.removeTUserFromTUserTeam(tUserTeam, tUser);
    };
    const handleChangeAgeClass = (tConfigAge) => {
        if (tConfigAge != null) {
            tUserTeam.setTournamentConfigAge(tConfigAge)
        } else {
            tUserTeam.unset(TournamentUserTeam.prototype.col.tournamentConfigAge)
        }
        tournamentUserManagerActions.saveTournamentUserTeam(tUserTeam)
    };
    // TODO refactor singleStartedTRound to runningRounds
    return <ListGroup>
            <ListGroupItem bsStyle={tUserTeam.isDeleted() ? "danger" : "info"}>
                <Row>
                <Col xs={6}>
                    <DelayedTextField custIcon="NONE" defaultValue={tUserTeam.getName()} onChange={changeTeamName}/>
                </Col>
                <Col xs={6}>
                    {
                        tUserTeam.isDeleted() ?
                            <TooltipButton onClick={() => reUseTeam(singleStartedTRound)} icon="registered" tooltip={messages.get("tournament.mmtuser.tooltip.team.present")} />
                            :
                            <ButtonGroup>
                                <TooltipButton disabled={singleStartedTRound == null}
                                               onClick={() => teamIsPresent(singleStartedTRound)} icon="check-circle" tooltip={messages.get("tournament.mmtuser.tooltip.team.present")} />
                                <TooltipButton onClick={clickedAddTUser} icon="user-plus" tooltip={messages.get("tournament.mmtuser.tooltip.team.addTuser")} />
                                <DeleteDecisionModal messageCode="list.entry.delete" tooltip={messages.get("tournament.mmtuser.tooltip.team.del")}
                                                     entryName={tUserTeam.getName()} handleDelete={deleteTeam}/>

                            </ButtonGroup>
                    }
                </Col>
                </Row>
            </ListGroupItem >
        <ListGroupItem  style={{paddingBottom :"0"}}>
            <DropDownParseObject default={tUserTeam.getTournamentConfigAge()}
                                 label={<FontAwesome icon="book">{messages.get("tournament.class.age")}</FontAwesome>}
                                 placeholder={<option key={"nullkey"} value={"null"}>----</option>}
                                 onAfterChange={handleChangeAgeClass}
                                 objectList={tournament.getTournamentConfigAge()}/>
        </ListGroupItem>
            {
                tUserTeam.getTournamentUserIDs().map(tUser => {
                    return <ListGroupItem key={"LGI_" + tUser.id}>
                            <TournamentUserManagerStatus tournamentUser={tUser} addText={false}/>&nbsp;
                            {tUser.getNote() ? <FontAwesome icon="info-circle" title={tUser.getNote()}/> : null }
                            {tUser.getFullName() + ", " + tUser.getTournamentConfigBowCode() + ", " + tUser.getTournamentConfigAgeCode()}
                            <TooltipButton onClick={() => removeFromTeam(tUser)} icon="minus"
                                           custClassName="pull-right" bsSize="small"
                                           tooltip={tUser.getFullName() + messages.get("tournament.mmtuser.tooltip.team.delUser")} />
                    </ListGroupItem>
                })
            }
        </ListGroup>
};
TournamentTeamPanel.propTypes = {
    tUserTeam: PropTypes.object.isRequired,
    add2Team: PropTypes.func.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object
};
module.exports = TournamentUserTeamPanel;
