const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert} = require("react-bootstrap");

const Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n");

const {MenuContext} = require("context/MenuContext");

const tournamentStore = require("stores/TournamentManagerStore"),
    tournamentMgmtActions = require("actions/TournamentManagerActions"),
    notificationActions = require("components/notification/NotificationActions"),
    userStore = require("stores/UserStore");

class TournamentMMLoader extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);

        this.state = {
            tournamentStore: tournamentStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        const { setTournamentIDFct } = this.context;
        userStore.listen(this.handleUserChange);
        tournamentStore.listen(this.handleTournamentChange);
        notificationActions.reset();
        tournamentMgmtActions.showDetails(this.props.tournamentId, this.state.userStore.user, this.props.adminCheck);
        if (this.props.showTournament) {
            setTournamentIDFct(this.props.tournamentId)
        }
    }
    componentWillUnmount() {
        const { setTournamentIDFct } = this.context;
        setTournamentIDFct(null)
        userStore.unlisten(this.handleUserChange);
        tournamentStore.unlisten(this.handleTournamentChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTournamentChange(state) {
        this.setState({tournamentStore: state});
    }
    render() {
        if (this.state.tournamentStore.loading == true) {
            return (<Grid>
                <Row>
                    <Col lg={12}><Loading/></Col>
                </Row>
            </Grid>)
        } else if(this.state.tournamentStore.invalidUser == true) {
            return (<Grid>
                <Row>
                    <Col lg={12}>
                        <Alert bsStyle="danger"><I18n code="tournament.manager.invalidUser"/></Alert>
                    </Col>
                </Row>
            </Grid>)
        } else if (this.state.tournamentStore.tournamentDetail && this.state.tournamentStore.tournamentDetail.isDeleted()) {
            return (<Grid>
                <Row>
                    <Col lg={12}>
                        <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                    </Col>
                </Row>
            </Grid>)
        }
        return <Grid>
            {React.cloneElement(this.props.children,
                { tournament: this.state.tournamentStore.tournamentDetail,
                    user: this.state.userStore.user,
                    runningTRounds: this.state.tournamentStore.runningTournamentRounds,
                    tournamentRoundMap: this.state.tournamentStore.tournamentRoundMap,
                    tournamentSlots: this.state.tournamentStore.tournamentRegSlots,
                    childTournaments: this.state.tournamentStore.childTournaments,
                    tChangeLogs: this.state.tournamentStore.tChangeLogs
                } )}
        </Grid>
    }
}
TournamentMMLoader.propTypes = {
    tournamentId:PropTypes.string.isRequired,
    showTournament: PropTypes.bool,
    adminCheck: PropTypes.bool
};
TournamentMMLoader.defaultProps = {
    showTournament: true
};
TournamentMMLoader.contextType = MenuContext;
module.exports = TournamentMMLoader;
