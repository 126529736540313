const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Link} = require ('react-router-dom');
const analyticsActions = require("actions/AnalyticsActions");

const ParcoursLink = ({parcours, label}) =>  {
    const handleClick = () => {
        analyticsActions.trackParcoursClick(parcours.getOnlineID(), false);
    };
    return <Link onClick={handleClick} to={linkUtil.getLink("parcours/" + parcours.getOnlineID())}>
        {label ? label : parcours.buildNames()}
        </Link>
};
ParcoursLink.propTypes = {
    parcours: PropTypes.object.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
};
module.exports = ParcoursLink;
