const alt = require("AltInit");

const actions = require("actions/SKillBoardActions");
const ParseListUtility = require("util/ParseListUtility");
class SKillBoardStore {
    constructor() {
        this.displayName = "SKillBoardStore";
        this.allCountTypes = null;
        this.countTypes = null;
        this.releases = null;
        this.countTypeGroups = null;
        this.zoneNames = null;
        this.countTypeDetails = null;
        this.showForm = false;
        this.searchParams = {
            text: ""
        };
        this.bindListeners({
            updateShowForm: actions.updateShowForm,
            updateReleases: actions.updateReleases,
            updateCountTypes: actions.updateCountTypes,
            addCountType: actions.addCountType,
            updateCountTypeDetails: actions.updateCountTypeDetails,
            updateCountTypeGroups: actions.updateCountTypeGroups,
            updateZoneNames: actions.updateZoneNames,
            searchCountTypes: actions.searchCountTypes
        });
    }

    updateShowForm(pShowForm) {
        this.showForm = pShowForm;
    }

    updateReleases(pReleases) {
        this.releases = pReleases
    }

    updateCountTypes(pCountTypes) {
        this.allCountTypes = pCountTypes;
        this.filterCountTypes();
    }

    addCountType(countType) {
        ParseListUtility.updateOrPush(this.allCountTypes, countType)
        this.filterCountTypes();
        this.showForm = false;
    }

    updateCountTypeDetails(pCountTypeDetails) {
        this.countTypeDetails = pCountTypeDetails
    }

    updateCountTypeGroups(pCountTypeGroups) {
        this.countTypeGroups = pCountTypeGroups;
    }

    updateZoneNames(pZoneNames) {
        this.zoneNames = pZoneNames;
    }

    searchCountTypes(searchParams) {
        this.searchParams = searchParams;
        this.filterCountTypes();
    }

    filterCountTypes() {
        if (this.searchParams.text.length > 0) {
            var searchString = this.searchParams.text.toLowerCase();
            this.countTypes = this.allCountTypes.filter(u => {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            this.countTypes = this.allCountTypes;
        }
    }
}
module.exports = alt.createStore(SKillBoardStore);
