const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const linkUtil = require("linkUtil");

const ParcoursVisitListButton = ({parcoursOwner, block, disabled, bsStyle}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            label="parcours.visit.list.header"
            href={linkUtil.getLink("parcours/" + parcoursOwner.id + "/visits")}/>
    )
};
ParcoursVisitListButton.propTypes = {
    parcoursOwner:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string
};
module.exports = ParcoursVisitListButton;