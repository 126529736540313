const Parse = require("ParseInit");
const Utility = require("util/Utility");

const CCLog = Parse.Object.extend("ccLog", {
    col: {
        userID: "userID",
        tournamentID: "tournamentID",
        tournamentGroupID: "tournamentGroupID",
        transactionID: "transactionID",
        trace: "trace",
        error: "error",
        text: "text",
        type: "type",
        body: "body",
        objectId: "objectId",
        updatedAt: "updatedAt",
        createdAt: "createdAt"
    },
    getLogDate() {
        let startDate = this.get(this.col.createdAt);
        return Utility.getReadableDate(startDate) + " - " + Utility.getReadableTimeSeconds(startDate)
    },
    getText() {
        return this.get(this.col.text);
    },
    getTableText(bodyKey) {
        if (bodyKey != null) {
            let body = this.getBody();
            if (body != null) {
                return body[bodyKey];
            }
        }
        return this.getText()
    },
    getTrace() {
        return this.get(this.col.trace);
    },
    getBody() {
        return this.get(this.col.body);
    },
    getErrorText() {
        return this.get(this.col.error);
    }
});

module.exports = CCLog;
