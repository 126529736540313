/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');

const BaseMap = require("components/map/BaseMap");
const TargetMapMarker = require("components/map/TargetMapMarker");
const BasicMapMarker = require("components/map/BasicMapMarker");
const TargetTrackLine = require("components/map/TargetTrackLine");
const MapUtil = require("components/map/MapUtil");
const ErrorBoundary = require("components/widgets/ErrorBoundary");

const TargetMapFrame = ({targets, parcours, mapEventTracks}) => {
    let parcoursCoords = null;
    let center = {lat: 48.249863, lng: 14.635051}
    let mapUtil = new MapUtil();
    const targetBounds = mapUtil.buildTargetBounds(targets);
    if (parcours && parcours.getParcoursOwnerID()) {
        parcoursCoords = parcours.getParcoursOwnerID().getCoordinates();
        if (parcoursCoords) {
            center = {lat: parcoursCoords._latitude, lng: parcoursCoords._longitude}
            targetBounds.push({
                id: parcours.id,
                coordinate: parcoursCoords
            })
        }
    }
    return (
        <BaseMap mapOptions={{ center: center, mapID:"TMF_MAP",
                containerStyle: {width: "100%", height: '500px'},
                zoom: 8, mapTypeId:"satellite"
            }} elements={targetBounds}>
            {
                targets.map(target => {
                    if (target.getCoordinates()) {
                        return <ErrorBoundary key={target.id} identifier={"Target:" + target.id}>
                            <TargetMapMarker key={target.id} parcoursTarget={target} />
                        </ErrorBoundary>
                    }
                })
            }
            {
                mapEventTracks != null ? mapEventTracks.map(eventTrack => {
                    const trackArr = eventTrack.getTrack();
                    if (trackArr != null) {
                        const trackElements = mapUtil.buildTrackObject(trackArr, null, [], null, 0)
                        return trackElements.map( trackElement => {
                            return <ErrorBoundary key={eventTrack.id + trackElement.targetNumber} identifier={"Target:" + trackElement.targetNumber}>
                                <TargetTrackLine coordArray={trackElement.coordArray}
                                                 targetName={trackElement.targetName}
                                                 targetPoints={trackElement.targetPoints}
                                                 targetNumber={trackElement.targetNumber}
                                                 posMarker={trackElement.posMarker}/>
                            </ErrorBoundary>
                        })
                    }
                }) : null
            }
            {
                parcoursCoords != null ? <BasicMapMarker coordinate={parcoursCoords} icon="/img/ico/archery.png" /> : null
            }
        </BaseMap>
    )
};
TargetMapFrame.propTypes = {
    targets:PropTypes.array.isRequired,
    mapEventTracks:PropTypes.array,
    parcours:PropTypes.object
};
module.exports = TargetMapFrame;