const Parse = require("ParseInit");

const Images = Parse.Object.extend("images", {

    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        editor: "editor",
        image: "image",
        type: "type",
        bgParams: "bgParams",
        objectId: "objectId",
        weblink: "weblink",
        status: "status",
        updatedAt: "updatedAt",
    },
    types: {
        image: "I",
        document: "D",
        pdf : "P"
    },
    bgParams: {
        backgroundSize : "bgSize",
        backgroundRepeat : "bgRepeat",
        backgroundPosition : "bgPosition"
    },
    getImageDate() {
        let startDate = this.get("createdAt");
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    getImageUrl() {
        let image = this.getImage();
        if (image) {
            return image.url();
        }
        return "";
    },
    getImageName() {
        var image = this.getImage();
        if (image) {
            return image.name();
        }
        return "";
    },
    isTypeImage() {
        return this.getType() == this.types.image;
    },
    isTypePDF() {
        return this.getType() == this.types.pdf;
    },
    isTypeDocument() {
        return this.getType() == this.types.document;
    },
    setBackgroundParams(backgroundParams) {
        return this.set(this.col.bgParams, backgroundParams)
    },
    getBackgroundParam(param) {
        var bgParams = this.get(this.col.bgParams);
        if (bgParams) {
            return bgParams[param];
        }
        return null;
    },
    getBackgroundParams() {
        return this.get(this.col.bgParams)
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getImage() {
        return this.get(this.col.image)
    },
    setImage(image) {
        return this.set(this.col.image, image)
    },
    getType() {
        return this.get(this.col.type)
    },
    setType(type) {
        return this.set(this.col.type, type)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = Images;
