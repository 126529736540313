const React = require("react"),
    PropTypes = require('prop-types');
const {Button, Image } = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    AvatarFileUploadInput = require("components/form/AvatarFileUploadInput"),
    FontAwesome = require("components/widgets/FontAwesome");

const userActions = require("actions/UserActions");

const UserImageHandler = ({userDetail, allowEdit, onAfterChange}) => {
    function saveUserImageFile(parseFile) {
        userActions.saveUserImageFile(userDetail, parseFile, (sUserDetail) => {
            if (sUserDetail && onAfterChange) {
                onAfterChange(sUserDetail)
            }
        });
    }
    const usrImgUrl = userDetail.getUserImageUrl();
    return (
        <React.Fragment>
            {
                usrImgUrl ?
                    <React.Fragment>
                        <Image src={usrImgUrl} responsive rounded />
                        <Button onClick={() => saveUserImageFile(null)}><FontAwesome icon="trash"/></Button>
                    </React.Fragment>
                    : null
            }
            {
                usrImgUrl == null && allowEdit ?
                    <AvatarFileUploadInput savedParseFileCallback={saveUserImageFile} fileName={userDetail.getFullName()}/> : null
            }
        </React.Fragment>
    )
};
UserImageHandler.propTypes = {
    userDetail:PropTypes.object.isRequired,
    allowEdit: PropTypes.bool.isRequired,
    onAfterChange: PropTypes.func.isRequired
};
module.exports = UserImageHandler;
