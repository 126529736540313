const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    Loading = require("components/widgets/Loading"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    CCLogTable= require("components/payment/CCLogTable"),
    ValidInput = require("components/form/ValidInput"),
    DateInput = require("components/form/DateInput");

const msgActions = require("actions/MessageActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const MailLogModal = ({sender, buttonStyle}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="club.button.mail.log"/></TextCenter>}
            buttonStyle={buttonStyle}
            buttonChildren={<I18n code="club.button.mail.log"/>}>
            <MailLogForm sender={sender}/>
        </ModalBasic>
    )
};
MailLogModal.propTypes = {
    sender: PropTypes.string.isRequired,
    buttonStyle: PropTypes.string.isRequired
};
class MailLogForm extends React.Component {
    constructor(props) {
        super(props);
        this.clickedSave = this.clickedSave.bind(this);
        this.updateCCLogList = this.updateCCLogList.bind(this);
        this.state = {
            loading: true,
            ccLogs: null
        }
    }
    componentDidMount() {
        //load email cclogs for sender
        msgActions.loadMailLogs(this.props.sender, ccLogs => {
            this.updateCCLogList(ccLogs)
        })
    }
    updateCCLogList(ccLogs) {
        this.setState({
            loading: false,
            ccLogs: ccLogs
        });
    }
    clickedSave(e) {
        const {closeFct} = this.context;
        e.preventDefault();
        closeFct();
    }
    render() {
        const {loading, ccLogs} = this.state;
        return (
            <form action="#">
                <Row>
                    {
                        loading ? <Col xs={12}><Loading/></Col> : null
                    }
                    {
                        ccLogs != null ? <CCLogTable ccLogs={ccLogs} bodyKey="subject"/> : null
                    }

                    <Col xs={12}>
                        <Button onClick={this.clickedSave} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
MailLogForm.propTypes = {
    sender: PropTypes.string.isRequired,
};
MailLogForm.contextType = ModalBasicContext;
module.exports = MailLogModal;
