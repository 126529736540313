const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button} = require("react-bootstrap");

const trainingActions = require("actions/TrainingActions");

const SVG_ID = "KillImage"
const base_width = 1000;
const base_half = base_width / 2
const vb_width = 750;
const touch_scale = 1 / vb_width * base_width
let lastEventTimestamp = 0

const scaleCoordinate = function (xy) {
    return base_half + xy
}

const scaleTouch = function (xy, dimXY) {
    return (xy - dimXY) * touch_scale
}

const pointInRect = function (x, y, selectionXY, radiusXY) {
    return x > selectionXY.x && x < selectionXY.x + radiusXY.x &&
        y > selectionXY.y && y < selectionXY.y + radiusXY.y
}
const pointInEllipse = function (x, y, selectionXY, radiusXY) {
    const diffX = Math.abs(x - selectionXY.x)
    const diffY = Math.abs(y - selectionXY.y)
    return diffX < radiusXY.x && diffY < radiusXY.y
}
const pointInCircle = function (x, y, selectionXY, radiusXY) {
    const diffX = Math.abs(x - selectionXY.x)
    const diffY = Math.abs(y - selectionXY.y)
    return diffX < radiusXY.c && diffY < radiusXY.c
}

const findPoints = function (evResultList, eventList, selectionXY, radiusXY) {
    if (selectionXY != null && radiusXY != null && evResultList != null) {
        // has selection frame with evResults
        let inList = []
        let outList = []
        evResultList.map(evResObject => {
            if (evResObject.killImage != null) {
                const scaleX = scaleCoordinate(evResObject.x)
                const scaleY = scaleCoordinate(evResObject.y)
                if (pointInRect(scaleX, scaleY, selectionXY, radiusXY)) {
                    inList.push(evResObject)
                } else {
                    outList.push(evResObject)
                }
            }
        })
        return {in: inList, out: outList, events: []}
    } else if (evResultList != null){
        // no selection but evResults
        return {in: evResultList, out: [], events: []}
    } else if (eventList != null) {
        // ignore selection, no evResults but events
        return {in: [], out: [], events: eventList}
    } else {
        return {in: [], out: [], events: []}
    }

}
const getKillImageTypes = function (countTypes) {
    if (countTypes != null && countTypes.length === 1) {
        if (countTypes[0].id === "D6Z_6_t") {
            // TODO -> WA_ALL, WA_TOP, WA_MID, WA_BOTTOM
            return [{id: 9, image: "url(/img/scores/score_9_wafield.jpg)"}]
        } else if (countTypes[0].isDisc) {
            if (countTypes[0].id === "D10Z_10_t") {
                return [{id: 6, image: "url(/img/scores/score_6_disc10.jpg)"}]
            } else if (countTypes[0].id === "D7Z_10_t") {
                return [{id: 7, image: "url(/img/scores/score_7_disc6.png)"}]
            } else if (countTypes[0].id === "D6Z_10_t") {
                return [{id: 8, image: "url(/img/scores/score_8_disc5.png)"}]
            } else if (countTypes[0].id === "D4Z_5_t_1000") {
                return [{id: 10, image: "url(/img/scores/score_10_ifaa_5_3.png)"}]
            } else if (countTypes[0].id === "D6Z_5_t") {
                return [{id: 11, image: "url(/img/scores/score_11_ifaa_5_1.png)"}]
            } else if (countTypes[0].id === "D4Z_5_t") {
                return [{id: 12, image: "url(/img/scores/score_12_ifaa_fieldhunter.png)"}]
            } else {
                // unsure - return all disc
                return [{id: 6, image: "url(/img/scores/score_6_disc10.jpg)"},
                    {id: 7, image: "url(/img/scores/score_7_disc6.png)"},
                    {id: 8, image: "url(/img/scores/score_8_disc5.png)"},
                    {id: 10, image: "url(/img/scores/score_10_ifaa_5_3.png)"},
                    {id: 11, image: "url(/img/scores/score_11_ifaa_5_1.png)"},
                    {id: 12, image: "url(/img/scores/score_12_ifaa_fieldhunter.png)"}]
            }
        } else {
            // set 3D
            return killImages3d
        }
    } else {
        // set all
        return killImagesAll
    }
}
const killImagesAll = [
    {id: 1, image: "url(/img/scores/score_1_right.png)"},
    {id: 2, image: "url(/img/scores/score_2_left.png)"},
    {id: 3, image: "url(/img/scores/score_3_round.png)"},
    {id: 4, image: "url(/img/scores/score_4_oval.png)"},
    {id: 5, image: "url(/img/scores/score_5_eye.png)"},
    {id: 6, image: "url(/img/scores/score_6_disc10.jpg)"},
    {id: 7, image: "url(/img/scores/score_7_disc6.png)"},
    {id: 8, image: "url(/img/scores/score_8_disc5.png)"},
    {id: 9, image: "url(/img/scores/score_9_wafield.jpg)"},
    {id: 10, image: "url(/img/scores/score_10_ifaa_5_3.png)"},
    {id: 11, image: "url(/img/scores/score_11_ifaa_5_1.png)"},
    {id: 12, image: "url(/img/scores/score_12_ifaa_fieldhunter.png)"}
]
const killImages3d = [
    {id: 1, image: "url(/img/scores/score_1_right.png)"},
    {id: 2, image: "url(/img/scores/score_2_left.png)"},
    {id: 3, image: "url(/img/scores/score_3_round.png)"},
    {id: 4, image: "url(/img/scores/score_4_oval.png)"},
    {id: 5, image: "url(/img/scores/score_5_eye.png)"},
]
class TrainingScatterImage extends React.Component {
    constructor(props) {
        super(props);
        this.killImageDecr = this.killImageDecr.bind(this);
        this.killImageIncr = this.killImageIncr.bind(this);
        this.getKillImageId = this.getKillImageId.bind(this);
        this.mouseDown = this.mouseDown.bind(this);
        this.mouseMove = this.mouseMove.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
        this.state = {
            killImageId: 0,
            killImagesRes: killImagesAll,
            dragging: false,
            selectionXY: null,
            radiusXY: null,
            inOutPointLists: {in: [], out: [], events: []}
        }
    }
    getXYFromEvent(event) {
        let eventXY = null
        if (event.clientX != null && event.clientY != null) {
            eventXY = {x: event.clientX, y: event.clientY}
        } else if (event.touches != null && event.touches.length > 0) {
            eventXY = {x: event.touches[0].clientX, y: event.touches[0].clientY}
        }
        return eventXY;
    }
    mouseDown(event) {
        if (this.props.evResultList != null) {
            // only if eventResults are available
            const eventXY = this.getXYFromEvent(event)
            if (eventXY != null && event.button === 0) {
                // left click
                const dim = event.target.getBoundingClientRect();
                // get x and y and fix in svg position with dim and scale touch to base width
                const x = scaleTouch(eventXY.x, dim.x)
                const y = scaleTouch(eventXY.y, dim.y)
                //console.log("MAINXY", x, y, dim, event.target.id)
                this.setState({dragging: true, radiusXY:null, selectionXY: {x:x, y:y}})
            } else if (event.button === 2) {
                // right click
                this.setState({dragging: false, radiusXY:null, selectionXY: null})
            }
            // reset inFilterList
            trainingActions.updateInFilterList(null)
        }
    }
    mouseUp(event) {
        if (this.state.dragging) {
            this.setState({dragging: false})
            if (this.state.selectionXY != null && this.state.radiusXY != null) {
                trainingActions.updateInFilterList.defer(this.state.inOutPointLists.in)
            }
        } else if (event.button === 0 && this.props.allowEventTargets && this.props.updateTargetSearchParams){
            this.props.updateTargetSearchParams("enabled", true, true)
        }
    }
    mouseMove(event) {
        if (this.state.dragging) {
            const timeDiff = event.timeStamp - lastEventTimestamp;
            let target = event.target.farthestViewportElement != null ? event.target.farthestViewportElement : event.target
            //console.log("target", target)
            const eventXY = this.getXYFromEvent(event)
            if (eventXY != null && timeDiff > 100 && target.id === SVG_ID) {
                // get x and y and fix in svg position with dim and scale touch to base width
                const dim = target.getBoundingClientRect();
                //console.log("CL_sXY", event.clientX, event.clientY, dim, event.target)
                const x = scaleTouch(eventXY.x, dim.x)
                const y = scaleTouch(eventXY.y, dim.y)
                //console.log("XY", x, y, event.timeStamp)
                // calc difference between start and actual xy
                const a = Math.abs(x - this.state.selectionXY.x);
                const b = Math.abs(y - this.state.selectionXY.y);
                const c = (a + b) / 2 //1//Math.sqrt( a*a + b*b )
                //console.log("abc", a, b, c)
                lastEventTimestamp = event.timeStamp
                this.setState({radiusXY:{x:a, y:b, c:c}})
            }
        }
    }
    killImageDecr() {
        let curKillImage = this.state.killImageId
        if (curKillImage > 0) {
            curKillImage--
        } else {
            curKillImage = Object.keys(this.state.killImagesRes).length - 1
        }
        this.setState({killImageId: curKillImage})
        if (this.props.killImageChanged != null) {
            this.props.killImageChanged(this.state.killImagesRes[curKillImage].id)
        }
    }
    killImageIncr() {
        let curKillImage = this.state.killImageId
        if (curKillImage < Object.keys(this.state.killImagesRes).length - 1) {
            curKillImage++
        } else {
            curKillImage = 0
        }
        this.setState({killImageId: curKillImage})
        if (this.props.killImageChanged != null) {
            this.props.killImageChanged(this.state.killImagesRes[curKillImage].id)
        }
    }
    getKillImageId() {
        return this.state.killImagesRes[this.state.killImageId].id
    }
    static getDerivedStateFromProps(props, state) {
        // used to get the props eventList or evResultList into in/out Point List into the state
        const inOutPointLists = findPoints(props.evResultList, props.eventList, state.selectionXY, state.radiusXY)
        state.inOutPointLists = inOutPointLists
        // update killImageRes depending on props.countTypes
        const killImageTemp = getKillImageTypes(props.countTypes)
        //console.log("getKillImageTypes", props.countTypes, killImageTemp)
        if (state.killImageId >= killImageTemp.length) {
            state.killImageId = 0
        }
        state.killImagesRes = killImageTemp
    }
    render() {
        const {killImageId, killImagesRes, selectionXY, radiusXY, inOutPointLists} = this.state
        return <Row>
            <Col xs={12}>
                <svg xmlns="http://www.w3.org/2000/svg" height={vb_width} width={vb_width} viewBox="0 0 1000 1000" id={SVG_ID}
                     onMouseDown={this.mouseDown} onMouseUp={this.mouseUp} onMouseMove={this.mouseMove}
                     style={{backgroundImage: killImagesRes[killImageId].image, backgroundSize: "cover",
                        borderColor: "black", borderStyle: "solid", borderWidth: "3px"}}>
                    {
                        selectionXY != null && radiusXY != null?
                            <rect  key={"EVR_SEL_RECT"} x={selectionXY.x} y={selectionXY.y} width={radiusXY.x} height={radiusXY.y}
                                      stroke="blue" strokeWidth="3" strokeDasharray="10 5"
                                      fill="transparent"/> : null
                    }
                    {/*{*/}
                    {/*    selectionXY != null && radiusXY != null?*/}
                    {/*        <ellipse  key={"EVR_SEL_EL"} cx={selectionXY.x} cy={selectionXY.y} rx={radiusXY.x} ry={radiusXY.y}*/}
                    {/*                  stroke="black" strokeWidth="3" strokeDasharray="10 5"*/}
                    {/*                  fill="transparent"/> : null*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    selectionXY != null && radiusXY != null?*/}
                    {/*        <circle  key={"EVR_SEL_CI"} cx={selectionXY.x} cy={selectionXY.y} r={radiusXY.c}*/}
                    {/*                  stroke="black" strokeWidth="3" strokeDasharray="10 5"*/}
                    {/*                  fill="transparent"/> : null*/}
                    {/*}*/}
                    {
                        inOutPointLists.in.map(evResObject => {
                            return <EventResultHits key={"EVR_" + evResObject.id} id={evResObject.id} fill="blue"
                                                    x={evResObject.x} y={evResObject.y} killImageNr={evResObject.killImage} />
                        })
                    }
                    {
                        inOutPointLists.out.map(evResObject => {
                            return <EventResultHits key={"EVR_" + evResObject.id} id={evResObject.id} fill="grey"
                                                    x={evResObject.x} y={evResObject.y} killImageNr={evResObject.killImage} />
                        })
                    }
                    {
                        inOutPointLists.events.map(ev => {
                            const eventResults = ev.getEventResults()
                            return eventResults.map(evResult => {
                                return <EventResultHits key={"EVR_" + evResult.id} id={evResult.id} fill="blue"
                                                        x={evResult.getCoordX()} y={evResult.getCoordY()} killImageNr={evResult.getKillImageNr()}
                                />
                            })
                        })
                    }
                </svg>
            </Col>
            <Col xs={6}>
                <Button bsSize="large" block onClick={this.killImageDecr}><strong> &larr;</strong></Button>
            </Col>
            <Col xs={6}>
                <Button bsSize="large" block onClick={this.killImageIncr}><strong> &rarr;</strong></Button>
            </Col>
        </Row>
    }
}
TrainingScatterImage.propTypes = {
    user:PropTypes.object.isRequired,
    countTypes:PropTypes.array,
    eventList:PropTypes.array,
    evResultList: PropTypes.array,
    killImageChanged: PropTypes.func
};
const EventResultHits = ({id, x, y, killImageNr, fill}) => {
    if (killImageNr != null) {
        const scaleX = scaleCoordinate(x)
        const scaleY = scaleCoordinate(y)
        return <circle key={"EVR_" + id} cx={scaleX} cy={scaleY} r="5" fill={fill} stroke="grey" strokeWidth="1"  />
    }
    return null
}
EventResultHits.propTypes = {
    id: PropTypes.string.isRequired,
    x: PropTypes.number,
    y: PropTypes.number,
    killImageNr: PropTypes.number
};
module.exports = TrainingScatterImage;
