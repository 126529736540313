const React = require("react"),
    PropTypes = require('prop-types');

const Loading = require("components/widgets/Loading"),
    ExtLink = require("components/links/ExtLink");

const ParcoursOwnerInfo = ({parcoursOwner}) => {
    if (parcoursOwner == null) {
        return <Loading/>
    }
    return (<div style={{float: "left"}}>
                <h4>{parcoursOwner.getName()}</h4>
                <p>{parcoursOwner.getContactName()}</p>
                <p><ExtLink href={parcoursOwner.getWeblink()}>{parcoursOwner.getWeblink()}</ExtLink></p>
                <p>{parcoursOwner.getStreet()}</p>
                <p>{parcoursOwner.getZipPlace()}</p>
            </div>)
};
ParcoursOwnerInfo.propTypes = {
    parcoursOwner: PropTypes.object
};
module.exports = ParcoursOwnerInfo;