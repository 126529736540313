var Alt = require("AltInit");

var GroupPlaceActions = require("actions/GroupPlaceActions");

class GroupPlaceStore {
    constructor() {
        this.displayName = "GroupPlaceStore";
        this.mainGroupPlace = null;
        this.regionGroupPlace = null;
        this.mainGroupPlaceList = [];
        this.regionGroupPlaceList = [];
        this.allRegionGroupPlaceList = null;
        this.bindListeners({
            initMainGroupPlaces: GroupPlaceActions.initMainGroupPlaces,
            initMainGroupPlacesSuccess: GroupPlaceActions.initMainGroupPlacesSuccess,
            updateMainGroupPlaces: GroupPlaceActions.updateMainGroupPlaces,
            updateAllRegionGroupPlaces: GroupPlaceActions.updateAllRegionGroupPlaces,
            onSelectMainGroupPlace: GroupPlaceActions.selectMainGroupPlace,
            onSelectMainGroupPlaceSuccess: GroupPlaceActions.selectMainGroupPlaceSuccess,
            onSelectRegionGroupPlace: GroupPlaceActions.selectRegionGroupPlace,
            onReset: GroupPlaceActions.reset
        });
    }

    initMainGroupPlaces() {
        this.mainGroupPlaceList = [];
        this.mainGroupPlace = null;
        this.regionGroupPlaceList = [];
        this.regionGroupPlace = null;
    }

    initMainGroupPlacesSuccess(mainGroupPlaces) {
        this.initialized = true;
        this.mainGroupPlaceList = mainGroupPlaces.mainGroupPlaceList;
        this.mainGroupPlace = null;
        this.regionGroupPlaceList = [];
        this.regionGroupPlace = null;
    }

    updateMainGroupPlaces(mainGroupPlaces) {
        this.mainGroupPlaceList = mainGroupPlaces;
    }


    updateAllRegionGroupPlaces(regionGroupPlaceList) {
        this.allRegionGroupPlaceList = regionGroupPlaceList;
    }


    onSelectMainGroupPlace(selectedPlaces) {
        this.regionGroupPlaceList = [];
        this.regionGroupPlace = null;
        if (selectedPlaces.selectedMainGroupPlace != null) {
            this.mainGroupPlace = this.findMainGroupPlaceById(selectedPlaces.selectedMainGroupPlace);
        } else {
            this.mainGroupPlace = null;
        }
    }


    onSelectMainGroupPlaceSuccess(selectedPlaces) {
        this.regionGroupPlaceList = selectedPlaces.regionGroupPlaceList;
    }

    onSelectRegionGroupPlace(groupPlaceId) {
        if (groupPlaceId != null) {
            this.regionGroupPlace = this.findRegionGroupPlaceById(groupPlaceId)
        } else {
            this.regionGroupPlace = null;
        }
    }

    findMainGroupPlaceById(id) {
        var list = this.mainGroupPlaceList.filter(p => p.id == id);
        return list.length == 1 ? list[0] : null;
    }

    findRegionGroupPlaceById(id) {
        var list = this.regionGroupPlaceList.filter(p => p.id == id);
        return list.length == 1 ? list[0] : null;
    }

    onReset(_) {
        this.mainGroupPlace = null;
        this.regionGroupPlace = null;
    }
}


module.exports = Alt.createStore(GroupPlaceStore);
