const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, Alert} = require("react-bootstrap");

const DateInput = require("components/form/DateInput"),
    ValidInput = require("components/form/ValidInput"),
    CheckboxInput = require("components/form/CheckboxInput"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    PanelCollapsed = require("components/widgets/PanelCollapsed"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    PLUtil = require("util/ParseListUtility");

const TrainingScatterImage = require("components/training/TrainingScatterImage"),
      TrainingInFilterStats = require("components/training/TrainingInFilterStats"),
      TrainingFilterNumberInput = require("components/training/TrainingFilterNumberInput"),
      TrainingFilterRangeSelect = require("components/training/TrainingFilterRangeSelect"),
      TrainingFilterButtonGroup = require("components/training/TrainingFilterButtonGroup"),
      TrainingFilterDropDown = require("components/training/TrainingFilterDropDown");

const trainingFilterStore = require("stores/TrainingFilterStore"),
    trainingActions = require("actions/TrainingActions"),
    notificationActions = require("components/notification/NotificationActions");

const lowest = 1001
const low = 1002
const medium = 1003
const high = 1004
const highest = 1005

const sizeObjects = [{value: low, text: "profile.training.target_size.1002"},
    {value: medium, text: "profile.training.target_size.1003"},
    {value: high, text: "profile.training.target_size.1004"}]
const levelObjects = [{value: lowest, text: "profile.training.level.1001", icon:null},
    {value: low, text: "profile.training.level.1002", icon:null},
    {value: medium, text: "profile.training.level.1003", icon:null},
    {value: high, text: "profile.training.level.1004", icon:null},
    {value: highest, text: "profile.training.level.1005", icon:null}]
const postureObjects = [{value: lowest, text: "profile.training.posture.1001", icon:null},
    {value: low, text: "profile.training.posture.1002", icon:null},
    {value: medium, text: "profile.training.posture.1003", icon:null},
    {value: high, text: "profile.training.posture.1004", icon:null},
    {value: highest, text: "profile.training.posture.1005", icon:null}]
const postureHillObjects = [{value: lowest, text: "profile.training.postureHill.1001"}, {value: highest, text: "profile.training.postureHill.1005"}]
const targetGroupObjects = [{value: 1, text: "1"}, {value: 2, text: "2"}, {value: 3, text: "3"}, {value: 4, text: "4"}]
const arrowNrObjects = [{value: 1, text: "1"}, {value: 2, text: "2"}, {value: 3, text: "3"}, {value: 4, text: "4"}, {value: 5, text: "5"}, {value: 6, text: "6"}]
const defDistance = [[0,10], [10,15], [15,20], [20,25], [25,30], [30,40]]

const discSizes = [{value: "20", text: "20"}, {value: "40", text: "40"}, {value: "60", text: "60"}, {value: "80", text: "80"}, {value: "92", text: "92"}, {value: "122", text: "122"}]
// TODO -> WA_ALL, WA_TOP, WA_MID, WA_BOTTOM
const discSizesField = [{value: "20", text: "20"}, {value: "40", text: "40"}, {value: "60", text: "60"}, {value: "80", text: "80"}] // only for D6Z_6_t

// TODO Mobile Layouting ??
// TODO - Filter last Miss arrows index 3 instead of 4
// NICE TO HAVE
// TODO create Snapshot - share the link

class TrainingScatterFilter extends React.Component {
    constructor(props) {
        super(props);
        this.refFilterName = React.createRef()
        this.refKillImage = React.createRef()
        this.handleTrainingChange = this.handleTrainingChange.bind(this);
        this.loadingEventFct = this.loadingEventFct.bind(this);
        this.updateSearchParams = this.updateSearchParams.bind(this);
        this.updateTargetSearchParams = this.updateTargetSearchParams.bind(this);
        this.loadingEventTargetComment = this.loadingEventTargetComment.bind(this);
        this.filterTargets = this.filterTargets.bind(this);
        this.saveCurrentFilter = this.saveCurrentFilter.bind(this)
        this.deleteCurrentFilter = this.deleteCurrentFilter.bind(this)
        this.state = {
            filterStore: trainingFilterStore.getState(),
        }
    }
    componentDidMount() {
        trainingFilterStore.listen(this.handleTrainingChange);
        this.loadingEventFct(this.state.filterStore.eventSearchParams, callback => {
            // initial load for filter
            if (this.props.filterID) {
                this.loadingEventTargetComment()
            }
        })
    }
    componentWillUnmount() {
        trainingFilterStore.unlisten(this.handleTrainingChange);
    }
    handleTrainingChange(state) {
        this.setState({filterStore: state});
    }
    loadingEventFct(eventSearchParams, callback) {
        // load events
        trainingActions.updateTrainingDetailLoading.defer(true)
        trainingActions.queryTrainingScatterEventList.defer(this.props.user, eventSearchParams, callback)
    }
    loadingEventTargetComment() {
        let evpList = this.props.eventTrainingList.getEventTrainingList();
        trainingActions.updateTrainingDetailLoading(true)
        trainingActions.queryTrainingEventComment(evpList)
        trainingActions.queryTrainingEventTarget(evpList)
    }
    updateSearchParams(key, value, reloadEvents) {
        //console.log("updateSearchParams: " + key, value)
        if (key !== "events" && this.props.eventTrainingList != null) {
            this.props.eventTrainingList.resetEventList()
        }
        let nSearchParams = this.state.filterStore.eventSearchParams;
        nSearchParams[key] = value
        if (key === "countTypes") {
            // if only one count type is selected -> set it in own state
            if (value != null && value.length === 1) {
                // find correct countType
                nSearchParams["countType"] = PLUtil.getObjectbyID(this.props.trainingCountTypes, value[0].objectid)
            } else {
                nSearchParams["countType"] = null
            }
        }
        trainingActions.updateFilterChanged(true)
        trainingActions.updateEventSearchParams(nSearchParams)
        if (reloadEvents) {
            this.loadingEventFct(nSearchParams, null)
        }
    }
    updateTargetSearchParams(key, value, loadTargets) {
        //console.log("updateTargetSearchParams: " + key, value)
        let nSearchParams = this.state.filterStore.targetSearchParams;
        nSearchParams[key] = value
        if (key === "killImageFilter" && this.refKillImage.current != null) {
            // killImageFilter -> update killImage from real image
            nSearchParams["killImage"] = this.refKillImage.current.getKillImageId()
        }
        trainingActions.updateFilterChanged(true)
        trainingActions.updateTargetSearchParams(nSearchParams)
        if (loadTargets) {
            this.loadingEventTargetComment()
        }
    }
    saveCurrentFilter() {
        const filterName = this.refFilterName != null ? this.refFilterName.current.getValue() : ""
        if (filterName != null && filterName.length > 0) {
            const {eventTrainingFilter, eventSearchParams, targetSearchParams} = this.state.filterStore
            // save Filter with name
            trainingActions.updateOrCreateFilter(this.props.user, filterName, eventSearchParams, targetSearchParams, eventTrainingFilter)
        } else {
            notificationActions.warning("Name is missing!") // TODO translate
        }
    }
    deleteCurrentFilter(doDelete, eventTrainingFilter) {
        if (doDelete) {
            trainingActions.deleteFilter(eventTrainingFilter)
        }
    }
    filterEvents(eventTrainingList, searchParams) {
        if (eventTrainingList != null) {
            //console.log("filterEvents", searchParams)
            let evpList = eventTrainingList.getEventTrainingList();
            // filter parcours
            if (searchParams.parcours != null && searchParams.parcours.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    let event = evp.getEventID();
                    let parcoursID = event.getParcoursID();
                    return PLUtil.contains(searchParams.parcours, parcoursID)
                })
            }
            // filter equipment
            if (searchParams.baProfile != null && searchParams.baProfile.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    let baProfile = evp.getBAProfileID();
                    return PLUtil.contains(searchParams.baProfile, baProfile)
                })
            }
            // filter trainings type
            if (searchParams.eventType != null && searchParams.eventType.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    let event = evp.getEventID();
                    let ev_type = event.getType();
                    return PLUtil.containsID(searchParams.eventType, ev_type)
                })
            }
            // filter count type
            if (searchParams.countTypes != null && searchParams.countTypes.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    return PLUtil.containsID(searchParams.countTypes, evp.getCountTypeOnlineID())
                })
            }
            // filter tags
            if (searchParams.tag != null && searchParams.tag.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    let event = evp.getEventID();
                    return PLUtil.containsList(searchParams.tag, event.getTagArray())
                })
            }
            // filter events
            if (searchParams.events != null && searchParams.events.length > 0) {
                // filter to parcours
                evpList = evpList.filter(evp => {
                    return PLUtil.containsID(searchParams.events, evp.id)
                })
            }
            return evpList
        }
        return []
    }
    buildEvResultCommentTargetObject(evp, evResult, evComment, evTarget, allowEventTargets) {
        return {
            id: evResult.id,
            killNr: evResult.getKillNr(),
            arrowNr: evResult.getArrowNr(),
            points: evResult.getPoints(),
            potPoints: allowEventTargets && evResult.getPotentialPoints() ? evResult.getPotentialPoints() : 0,
            x: evResult.getCoordX(),
            y: evResult.getCoordY(),
            killImage: evResult.getKillImageNr(),
            dist_est: evComment ? evComment.getDistanceEstimated() : -1,
            dist_meter: evComment ? evComment.getDistanceMeasured() : -1,
            target_size: evComment ? evComment.getSize() : -1,
            level: evComment ? evComment.getLevel() : -1,
            posture: evComment ? evComment.getPosture() : -1,
            postureHill: evComment ? evComment.getPostureHill() : -1,
            discSize: evComment ? evComment.getDiscSize() : -1,
            target_group: evTarget ? evTarget.getGroup() : -1
        }
    }
    filterTargets(filteredEventList, searchParams) {
        const {eventCommentsDic, eventTargetsDic, allowEventTargets} = this.props;
        //console.log("filterTargets", searchParams)
        const {dist_est, dist_meter, target_size, level, level_num, posture, postureHill, target_group,
            arrowNr, zones, resTargetIndex, discSize, killImageFilter, killImage, potPoints, ecTag} = searchParams
        const needEvComment = dist_est || dist_meter || target_size || level || level_num || posture || postureHill || ecTag || discSize;
        const needEvTarget = target_group != null
        const evResultList = []
        filteredEventList.map(evp => {
            // go trough already filter events
            const event = evp.getEventID();
            const evCommentsDic = eventCommentsDic != null ? eventCommentsDic[event.id] : {}
            const evTargetsDic = eventTargetsDic != null ? eventTargetsDic[event.id] : {}
            const eventResults = evp.getEventResults()
            eventResults.forEach(evResult => {
                // go through the single target result
                if (evResult.getKillImageNr() == null) {
                    // no need to check without kill image
                    return false
                }
                if (killImageFilter) {
                    // filter also to kill image
                    // TODO filter wa DISC 9 - also to 91,92,93
                    if (evResult.getKillImageNr() !== killImage) {
                        return false
                    }
                }
                if (potPoints) {
                    // only arrows with potential points
                    if (!evResult.getPotentialPoints() > 0) {
                        return false
                    }
                }
                // TODO find maxArrows depending on countType - to reduce miss arrowNr4 to arrowNr 3
                if (arrowNr != null && arrowNr.length > 0 && !arrowNr.includes(evResult.getArrowNr())) {
                    return false
                }
                if (zones != null && zones.length > 0 && !PLUtil.containsID(zones, evResult.getKillNr())) {
                    return false
                }
                const targetIndex = evResult.getTargetIndex()
                if (resTargetIndex != null && resTargetIndex.length > 0 && !resTargetIndex.includes(targetIndex)) {
                    return false
                }
                const evTarget = evTargetsDic[targetIndex]
                const evComment = evCommentsDic[targetIndex]
                // get targetInfo for targetIndex
                if (needEvTarget && evTarget != null) {
                    // filter for target info
                    if (target_group != null && target_group.length > 0 && !target_group.includes(evTarget.getGroup())) {
                        return false
                    }
                } else if(needEvTarget && evTarget == null) {
                    // needEvTarget but object is null
                    return false
                }
                if (needEvComment && evComment != null) {
                    // filter for distance estimated and measured
                    // [min,max]
                    if (dist_est != null && dist_est.length === 2 && !evComment.isDistanceEstimatedWithin(dist_est[0], dist_est[1])) {
                        return false
                    }
                    if (dist_meter != null && dist_meter.length === 2 && !evComment.isDistanceMeasuredWithin(dist_meter[0], dist_meter[1])) {
                        return false
                    }
                    if (target_size != null && target_size.length > 0 && !target_size.includes(evComment.getSize())) {
                        return false
                    }
                    if (level != null && level.length > 0 && !level.includes(evComment.getLevel())) {
                        return false
                    }
                    if (level_num != null && level_num.length === 2 && !evComment.isLevelWithin(level_num[0], level_num[1])) {
                        return false
                    }
                    if (posture != null && posture.length > 0 && !posture.includes(evComment.getPosture())) {
                        return false
                    }
                    if (postureHill != null && postureHill.length > 0 && !postureHill.includes(evComment.getPostureHill())) {
                        return false
                    }
                    if (discSize != null && discSize.length > 0 && !discSize.includes(evComment.getDiscSize())) {
                        return false
                    }
                    // filter tags
                    if (ecTag != null && ecTag.length > 0 && !PLUtil.containsList(ecTag, evComment.getTagArray())) {
                        return false
                    }
                } else if(needEvComment && evComment == null) {
                    // needEvComment but object is null
                    return false
                }
                // was not stopped by filtering - add to result
                evResultList.push(this.buildEvResultCommentTargetObject(evp, evResult, evComment, evTarget, allowEventTargets))
            })
        })
        return evResultList
    }
    needFiltering(targetSearchParams) {
        return targetSearchParams.enabled || targetSearchParams.killImageFilter || targetSearchParams.arrowNr != null
            || targetSearchParams.zones != null || targetSearchParams.resTargetIndex != null || targetSearchParams.potPoints
    }
    render() {
        const {user, trainingCountTypes, eventTrainingList, eventTrainingLoading, allowEventTargets, eventCommentsDic} = this.props
        const {filterChanged, eventTrainingFilter, eventSearchParams, targetSearchParams, hitsInFilterDict} = this.state.filterStore
        const {startDate, endDate, parcours, baProfile, eventType, countTypes, tag, events} = eventSearchParams
        const eventList = this.filterEvents(eventTrainingList, eventSearchParams)
        let evResultList = null
        if (this.needFiltering(targetSearchParams)) {
            evResultList = this.filterTargets(eventList, targetSearchParams)
        }
        return <Row>
            <Col lg={8}>
                <TrainingScatterImage ref={this.refKillImage} user={user} allowEventTargets={allowEventTargets}
                                      updateTargetSearchParams={this.updateTargetSearchParams}
                                      eventList={eventList} evResultList={evResultList} countTypes={countTypes}
                                      killImageChanged={(killImage) => this.updateTargetSearchParams("killImage", killImage, false)}/>
            </Col>
            <Col lg={4} style={{maxHeight: "801px", overflow: "auto"}}>
                {
                    hitsInFilterDict != null ? <TrainingInFilterStats hitsInFilterDict={hitsInFilterDict} /> :
                        <React.Fragment>
                            <h3><I18n code="profile.training.filter.header"/> ({eventList.length})</h3>
                            {
                                allowEventTargets ? <React.Fragment>
                                    {
                                        eventTrainingFilter != null && !filterChanged ?
                                            <h4>
                                                <FontAwesome icon="filter">{eventTrainingFilter.getName()}</FontAwesome>&nbsp;
                                                <DeleteDecisionModal messageCode="list.entry.delete"  titleCode="list.entry.hdr.delete"
                                                                     entryName={eventTrainingFilter.getName()}
                                                                     handleDelete={(doDelete) => this.deleteCurrentFilter(doDelete, eventTrainingFilter)}/>
                                            </h4>
                                            :
                                            <ValidInput ref={this.refFilterName} disabled={eventTrainingLoading}
                                                        placeholder={messages.get("profile.training.filter.placeholder")}
                                                        onAfterChange={() => trainingActions.updateFilterChanged(true)}
                                                        default={eventTrainingFilter != null ? eventTrainingFilter.getName() : null}
                                                        addonBefore={<FontAwesome icon="filter"/> }
                                                        buttonAfter={filterChanged ? <Button onClick={this.saveCurrentFilter} disabled={eventTrainingLoading}>
                                                            <FontAwesome icon="save"/>
                                                        </Button> : null}/>
                                    }
                                </React.Fragment> : null
                            }
                            { eventTrainingLoading === true ? <Loading/> : null }
                            <EventStats eventList={eventList} evResultList={evResultList} countType={eventSearchParams.countType}/>
                            <br/>
                            <Row>
                                <Col xs={6}>
                                    <DateInput default={startDate} disabled={eventTrainingLoading}
                                               onAfterChange={(value) => this.updateSearchParams("startDate", new Date(value), true)}
                                               label={<I18n code="management.feature.startdate"/>}/>
                                </Col>
                                <Col xs={6}>
                                    <DateInput default={endDate} disabled={eventTrainingLoading}
                                               onAfterChange={(value) => this.updateSearchParams("endDate", new Date(value), true)}
                                               label={<I18n code="management.feature.enddate"/>}/>
                                </Col>
                            </Row>
                            <PanelCollapsed header={messages.get("profile.training.filter.event")}>
                                <TrainingFilterDropDown filterState={eventType} stateKey="eventType" text={messages.get("profile.training.training.mode")}
                                                        disabled={eventTrainingLoading}
                                                        eventTrainingList={eventTrainingList} needReloadEvents={false}
                                                        updateSearchParams={this.updateSearchParams}/><br/>

                                <TrainingFilterDropDown filterState={parcours} stateKey="parcours" text={messages.get("management.feature.PA")}
                                                        eventTrainingList={eventTrainingList} needReloadEvents={true} disabled={eventTrainingLoading}
                                                        updateSearchParams={this.updateSearchParams}/><br/>

                                <TrainingFilterDropDown filterState={countTypes} stateKey="countTypes" text={messages.get("skillboard.tab.scores")}
                                                        objectList={trainingCountTypes}
                                                        eventTrainingList={eventTrainingList} needReloadEvents={false}
                                                        disabled={eventTrainingLoading || trainingCountTypes == null}
                                                        updateSearchParams={this.updateSearchParams}/><br/>

                                <TrainingFilterDropDown filterState={baProfile} stateKey="baProfile" text={messages.get("profile.training.baProfile")}
                                                        eventTrainingList={eventTrainingList} needReloadEvents={false} disabled={eventTrainingLoading}
                                                        updateSearchParams={this.updateSearchParams}/><br/>

                                <TrainingFilterDropDown filterState={tag} stateKey="tag" text={messages.get("profile.training.tags")}
                                                        eventTrainingList={eventTrainingList} needReloadEvents={false} disabled={eventTrainingLoading}
                                                        updateSearchParams={this.updateSearchParams}/><br/>
                                <TrainingFilterDropDown filterState={events} stateKey="events" text={messages.get("profile.training.events")}
                                                        disabled={eventTrainingLoading} needReloadEvents={false}
                                                        eventTrainingList={eventTrainingList} objectList={eventList}
                                                        updateSearchParams={this.updateSearchParams}/>
                            </PanelCollapsed>
                            <PanelCollapsed
                                header={messages.get("profile.training.filter.details")}>
                                <TrainingEventResultFilter searchParams={targetSearchParams}
                                                           eventTrainingList={eventTrainingList}
                                                           eventTrainingLoading={eventTrainingLoading}
                                                           countType={eventSearchParams.countType}
                                                           allowEventTargets={allowEventTargets}
                                                           updateTargetSearchParams={this.updateTargetSearchParams}/>
                            </PanelCollapsed>
                            {allowEventTargets ? <PanelCollapsed
                                    header={<FilterPanelHeader searchParams={targetSearchParams}
                                                               eventTrainingLoading={eventTrainingLoading}
                                                               updateTargetSearchParams={this.updateTargetSearchParams}
                                                               title="profile.training.filter.comment" />}>
                                    <TrainingTargetCommentFilter targetSearchParams={targetSearchParams}
                                                          eventTrainingList={eventTrainingList}
                                                          eventCommentsDic={eventCommentsDic}
                                                          eventTrainingLoading={eventTrainingLoading}
                                                          countType={eventSearchParams.countType}
                                                          updateTargetSearchParams={this.updateTargetSearchParams}/>
                                </PanelCollapsed>
                                : null
                            }
                        </React.Fragment>
                }
            </Col>
        </Row>
    }
}
TrainingScatterFilter.propTypes = {
    user:PropTypes.object.isRequired,
    eventTrainingList:PropTypes.object,
    trainingCountTypes: PropTypes.array,
    eventTrainingFilter: PropTypes.object,
    year: PropTypes.number,
    filterID: PropTypes.string
};
const TrainingEventResultFilter = ({searchParams, eventTrainingList, eventTrainingLoading, countType, allowEventTargets, updateTargetSearchParams}) => {
    const {arrowNr, zones, resTargetIndex, killImageFilter, potPoints} = searchParams
    const isTypeDisc = countType != null && countType.isTypeDisc();
    let resTargetIndexTxt = isTypeDisc ? "profile.training.passe" : "profile.training.resTargetIndex"
    return <React.Fragment>
        { eventTrainingLoading === true ? <Loading/> : null }
        <CheckboxInput onAfterChange={value => updateTargetSearchParams("killImageFilter", value, false)}
                       default={killImageFilter} disabled={eventTrainingLoading}
                       message={messages.get("profile.training.mapKillImage")}/>
        {
            allowEventTargets ? <CheckboxInput onAfterChange={value => updateTargetSearchParams("potPoints", value, false)}
                                               default={potPoints} disabled={eventTrainingLoading}
                                               message={messages.get("profile.training.potPoints")}/> : null
        }

        <TrainingFilterButtonGroup filterState={arrowNr} stateKey="arrowNr" text={messages.get("profile.training.arrowNr")}
                                   objectList={arrowNrObjects}
                                   disabled={eventTrainingLoading}
                                   updateSearchParams={updateTargetSearchParams}/><br/>
        <TrainingFilterNumberInput filterState={resTargetIndex} stateKey="resTargetIndex" text={messages.get(resTargetIndexTxt)}
                                   disabled={eventTrainingLoading}
                                   updateSearchParams={updateTargetSearchParams}/><br/>
        {
            countType != null ? <React.Fragment>
                <TrainingFilterDropDown filterState={zones} stateKey="zones" text={messages.get("profile.training.zones")}
                                        disabled={eventTrainingLoading} needReloadEvents={false}
                                        eventTrainingList={eventTrainingList} objectList={countType.getCountTypeDtls()}
                                        updateSearchParams={updateTargetSearchParams}/><br/>
            </React.Fragment>: null
        }
    </React.Fragment>
}
const TrainingTargetCommentFilter = ({targetSearchParams, eventTrainingList, eventTrainingLoading, countType, updateTargetSearchParams, eventCommentsDic}) => {
    if (!targetSearchParams.enabled) {
        return <Button disabled={eventTrainingLoading} onClick={() => updateTargetSearchParams("enabled", true, true)}>
            <FontAwesome icon="sync"/><I18n code="profile.training.target.load"/>
        </Button>
    } else {
        const {dist_est, dist_meter, target_size, discSize, level, level_num, posture, postureHill, target_group, ecTag} = targetSearchParams
        const isTypeDisc = countType != null && countType.isTypeDisc();
        return <React.Fragment>
            { eventTrainingLoading === true ? <Loading/> : null }
            <TrainingFilterRangeSelect filterState={dist_est} stateKey="dist_est" text={messages.get("profile.training.dist_est")}
                                       min={0} max={100} step={1} disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterRangeSelect filterState={dist_meter} stateKey="dist_meter" text={messages.get("profile.training.dist_meter")}
                                       min={0} max={100} step={1} defRangeGroups={defDistance} disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            {
                isTypeDisc ? <React.Fragment>
                    <TrainingFilterButtonGroup filterState={discSize} stateKey="discSize" text={messages.get("profile.training.discSize")}
                                                        objectList={countType.isTypeWADisc() ? discSizesField : discSizes}
                                                        disabled={eventTrainingLoading}
                                                        updateSearchParams={updateTargetSearchParams}/><br/>
                </React.Fragment>: null
            }
            <TrainingFilterButtonGroup filterState={target_size} stateKey="target_size" text={messages.get("profile.training.target_size")}
                                       objectList={sizeObjects}
                                       disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterButtonGroup filterState={target_group} stateKey="target_group" text={messages.get("profile.training.target_group")}
                                       objectList={targetGroupObjects}
                                       disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterButtonGroup filterState={posture} stateKey="posture" text={messages.get("profile.training.posture")}
                                       objectList={postureObjects}
                                       disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterButtonGroup filterState={level} stateKey="level" text={messages.get("profile.training.level")}
                                       objectList={levelObjects}
                                       disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterRangeSelect filterState={level_num} stateKey="level_num" text={messages.get("profile.training.levelNum")}
                                       min={-90} max={90} step={1} disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterButtonGroup filterState={postureHill} stateKey="postureHill" text={messages.get("profile.training.postureHill")}
                                       objectList={postureHillObjects}
                                       disabled={eventTrainingLoading}
                                       updateSearchParams={updateTargetSearchParams}/><br/>
            <TrainingFilterDropDown filterState={ecTag} stateKey="ecTag" text={messages.get("profile.training.tags")}
                                    eventTrainingList={eventTrainingList} objectList={eventCommentsDic}
                                    needReloadEvents={false} disabled={eventTrainingLoading}
                                    updateSearchParams={updateTargetSearchParams}/><br/>
        </React.Fragment>

    }
}
const FilterPanelHeader = ({searchParams, title, eventTrainingLoading, updateTargetSearchParams}) => {
    if (!searchParams.enabled) {
        return <React.Fragment><I18n code={title}/></React.Fragment>
    } else {
        return <React.Fragment><I18n code={title}/>&nbsp;
            <Button className="pull-right" style={{marginTop:"-5px"}} disabled={eventTrainingLoading}
                    onClick={() => updateTargetSearchParams("enabled", null, false)}>X</Button>
        </React.Fragment>
    }
}
const EventStats = ({eventList, evResultList, countType}) => {
    function calculateDPS(eventList, evResultList, countTypeStats) {
        let ev_points = 0
        let ev_pot_points = 0
        let ev_counter = 0
        if (evResultList != null) {
            evResultList.map(evResObject => {
                if (countTypeStats != null && countTypeStats[evResObject.killNr] != null) {
                    countTypeStats[evResObject.killNr].counter += 1
                }
                ev_points += evResObject.points
                ev_pot_points += evResObject.potPoints
                ev_counter+= 1
            })
        } else {
            eventList.map(evp => {
                if (evp.getSumPoints != null) {
                    const sumPoints = evp.getSumPoints();
                    const shootCounter = evp.getShootCounter();
                    if (shootCounter > 0 && sumPoints > 0 ) {
                        ev_points += sumPoints
                        ev_counter+= shootCounter
                    }
                }
            })
        }
        if (ev_points > 0 && ev_counter > 0 ) {
            let dps = ev_points / ev_counter;
            dps = dps.toFixed(2); // needed to return as number
            let potDPS = 0
            if (ev_pot_points > 0 && ev_counter > 0 ) {
                potDPS = (ev_pot_points + ev_points) / ev_counter;
                potDPS = potDPS.toFixed(2);
            }
            return [dps, ev_points, ev_counter, potDPS, ev_pot_points];
        } else {
            return [0, 0, 0, 0, 0]
        }
    }
    let countTypeStats = null
    if (countType != null) {
        countTypeStats = {}
        countType.getCountTypeDtls().map(countTypeDetail => {
            let zone = countTypeDetail.getKillNr();
            if (countTypeStats[zone] == null) {
                countTypeStats[zone] = {
                    zoneName: countTypeDetail.getZoneName(),
                    counter: 0
                }
            }
        })
    }
    const eventHitStat = calculateDPS(eventList, evResultList, countTypeStats)
    return <Row>
        <Col sm={6}>
            <h4><table>
                <tr>
                    <th>DPS</th>
                    <td className="text-right">{eventHitStat[0]}</td>
                </tr>
                <tr>
                    <th><I18n code="tournament.table.column.points"/>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    <td className="text-right">{eventHitStat[1]}</td>
                </tr>
                <tr>
                    <th><I18n code="statistics.column.hit"/></th>
                    <td className="text-right">{eventHitStat[2]}</td>
                </tr><br/>
                {
                    eventHitStat[3] > 0 ? <tr>
                        <th>DPS*</th>
                        <td className="text-right">{eventHitStat[3]}</td>
                    </tr> : null
                }
                {
                    eventHitStat[4] > 0 ? <tr>
                        <th><I18n code="tournament.table.column.points"/>*&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <td className="text-right">{eventHitStat[1] + eventHitStat[4]}</td>
                    </tr> : null
                }
            </table></h4>
        </Col>
        {
            countTypeStats != null && evResultList != null ? <Col sm={6}>
                <h4><table>
                {
                    Object.keys(countTypeStats).map(zoneNr => {
                        const zoneObj = countTypeStats[zoneNr]
                        return (<tr key={"CTS_Z_" + zoneNr}>
                            <th><I18n code={zoneObj.zoneName}/>&nbsp;&nbsp;&nbsp;&nbsp;</th>
                            <td className="text-right">{zoneObj.counter}</td>
                        </tr>)
                    })
                }
                </table></h4>
            </Col> : null
        }
        {
            countType == null ? <Col xs={12}><Alert bsStyle="info"><I18n code="profile.training.noCountTypeWarn"/> </Alert></Col>: null
        }
    </Row>
}
module.exports = TrainingScatterFilter;
