const React = require("react"),
    PropTypes = require('prop-types');
const {Row} = require("react-bootstrap");
const TournamentResultTable = require("components/tournament/TournamentResultTable"),
    TournamentCupResultTable = require("components/tournament/TournamentCupResultTable"),
    TournamentUserStatTable = require("components/tournament/TournamentUserStatTable"),
    PanelCollapse = require("components/widgets/PanelCollapse");

const TournamentResultGrouping  = ({tournament, tournamentUserResult, tournamentRoundMap, liveModus, showHighestZoneCounter}) => {
    const primeTypes = tournamentUserResult.getTournamentPrimeGroup();
    const secTypes = tournamentUserResult.getTournamentSecondGroup();
    if (tournament.isTypeCupMaster()) {
        // cup tables
        return (
            <div>
                {
                    primeTypes.map(primeType => {
                        return (
                            <PanelCollapse key={primeType.id} id={primeType.id}
                                           header={<h3 className="bowTitle">{primeType.getCodeName()}</h3>}>
                                <Row>
                                    {
                                        secTypes.map(secType => {
                                            if (secType.hasNoSexSplit()) {
                                                // male and female in one table
                                                const key = tournamentUserResult.generateDicKey(primeType, secType, "x");
                                                return (
                                                    <TournamentCupResultTable key={key} showHighestZoneCounter={showHighestZoneCounter}
                                                                              tournament={tournament}
                                                                              tournamentUserDic={tournamentUserResult}
                                                                              ageType={secType} bowType={primeType}
                                                                              tuSex="x"
                                                    />
                                                )
                                            } else {
                                                // two tables
                                                const maleKey = tournamentUserResult.generateDicKey(primeType, secType, "0");
                                                const femaleKey = tournamentUserResult.generateDicKey(primeType, secType, "1");
                                                return (<div key={maleKey + femaleKey}>
                                                        <TournamentCupResultTable key={maleKey} showHighestZoneCounter={showHighestZoneCounter}
                                                                                  tournament={tournament}
                                                                                  tournamentUserDic={tournamentUserResult}
                                                                                  ageType={secType}
                                                                                  bowType={primeType} tuSex="0"
                                                        />
                                                        <TournamentCupResultTable key={femaleKey} showHighestZoneCounter={showHighestZoneCounter}
                                                                                  tournament={tournament}
                                                                                  tournamentUserDic={tournamentUserResult}
                                                                                  ageType={secType}
                                                                                  bowType={primeType} tuSex="1"
                                                        />

                                                    </div>
                                                );
                                            }
                                        })
                                    }
                                </Row>
                            </PanelCollapse>
                        );
                    })
                }

            </div>
        )
    } else if (secTypes == null) {
        // no secType tables
        return (
            <React.Fragment>
                {
                    primeTypes.map(function(primeType) {
                        const killTextHeader = primeType.generateKillTextHeader(primeType);
                        const secType = null;
                        if (primeType.hasNoSexSplit()) {
                            // male and female in one table
                            const key = tournamentUserResult.generateDicKey(primeType, secType, "x");
                            return (
                                <PanelCollapse key={primeType.id} id={primeType.id} header={<h3 className="bowTitle">{primeType.getCodeName()}</h3>}>
                                    <Row>
                                        <TournamentResultTable key={key} killColumnName={killTextHeader}
                                                               tournament={tournament}
                                                               tournamentUserDic={tournamentUserResult}
                                                               tournamentRoundMap={tournamentRoundMap}
                                                               ageType={secType} bowType={primeType} tuSex="x"
                                                               liveModus={liveModus}/>
                                    </Row>
                                </PanelCollapse>
                            )
                        } else {
                            const maleKey = tournamentUserResult.generateDicKey(primeType, secType, "0");
                            const femaleKey = tournamentUserResult.generateDicKey(primeType, secType, "1");
                            // two tables
                            return (
                                <PanelCollapse key={primeType.id} id={primeType.id} header={<h3 className="bowTitle">{primeType.getCodeName()}</h3>}>
                                    <Row>
                                        <div key={maleKey + femaleKey}>
                                            <TournamentResultTable key={maleKey} killColumnName={killTextHeader}
                                                                   tournament={tournament}
                                                                   tournamentUserDic={tournamentUserResult}
                                                                   tournamentRoundMap={tournamentRoundMap}
                                                                   ageType={secType} bowType={primeType} tuSex="0"
                                                                   liveModus={liveModus}/>
                                            <TournamentResultTable key={femaleKey} killColumnName={killTextHeader}
                                                                   tournament={tournament}
                                                                   tournamentUserDic={tournamentUserResult}
                                                                   tournamentRoundMap={tournamentRoundMap}
                                                                   ageType={secType} bowType={primeType} tuSex="1"
                                                                   liveModus={liveModus}/>

                                        </div>
                                    </Row>
                                </PanelCollapse>
                            )
                        }
                    })
                }
            </React.Fragment>
        )
    } else {
        // normal tables
        return (
            <div>
                {
                    primeTypes.map(function(primeType) {
                        const killTextHeader = primeType.generateKillTextHeader(primeType);
                        return (
                            <PanelCollapse key={primeType.id} id={primeType.id} header={<h3 className="bowTitle">{primeType.getCodeName()}</h3>}>
                                <Row>
                                    {
                                        secTypes.map(function(secType){
                                            if (secType.hasNoSexSplit()) {
                                                // male and female in one table
                                                const key = tournamentUserResult.generateDicKey(primeType, secType, "x");
                                                return (
                                                    <TournamentResultTable key={key} killColumnName={killTextHeader}
                                                                           tournament={tournament}
                                                                           tournamentUserDic={tournamentUserResult}
                                                                           tournamentRoundMap={tournamentRoundMap}
                                                                           ageType={secType} bowType={primeType} tuSex="x"
                                                                           liveModus={liveModus}/>
                                                )
                                            } else {
                                                // two tables
                                                const maleKey = tournamentUserResult.generateDicKey(primeType, secType, "0");
                                                const femaleKey = tournamentUserResult.generateDicKey(primeType, secType, "1");
                                                return ( <div key={maleKey + femaleKey}>
                                                        <TournamentResultTable key={maleKey} killColumnName={killTextHeader}
                                                                               tournament={tournament}
                                                                               tournamentUserDic={tournamentUserResult}
                                                                               tournamentRoundMap={tournamentRoundMap}
                                                                               ageType={secType} bowType={primeType} tuSex="0"
                                                                               liveModus={liveModus}/>
                                                        <TournamentResultTable key={femaleKey} killColumnName={killTextHeader}
                                                                               tournament={tournament}
                                                                               tournamentUserDic={tournamentUserResult}
                                                                               tournamentRoundMap={tournamentRoundMap}
                                                                               ageType={secType} bowType={primeType} tuSex="1"
                                                                               liveModus={liveModus}/>

                                                    </div>
                                                );
                                            }
                                        })}
                                </Row>
                            </PanelCollapse>
                        );
                    })
                }
                {
                    liveModus ? null :
                        <TournamentUserStatTable
                            primeTypes={primeTypes}
                            secTypes={secTypes}
                            tournamentUserResult={tournamentUserResult}/>
                }
            </div>
        )
    }
};
module.exports = TournamentResultGrouping;