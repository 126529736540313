const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Table, Button, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    AboFeatureDesc = require("components/widgets/AboFeatureDesc"),
    Title = require("components/widgets/Title");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const imagesTM = [
    {url:"/img/features/tournament_manager.png", desc:""},
    {url:"/img/features/tournament_mmc1.png", desc:"management.feature.TM.basic"},
    {url:"/img/features/tournament_mmc2.png", desc:"management.feature.TM.classes"},
    {url:"/img/features/tournament_mmround.png", desc:"management.feature.TM.rounds"},
    {url:"/img/features/tournament_mmt1.png", desc:"management.feature.TM.mmtu"},
    {url:"/img/features/tournament_mmt2.png", desc:"management.feature.TM.groups"}];

const imagesCT = [
    {url:"/img/features/tournament_mmc3.png", desc:"management.feature.CT.points"},
    {url:"/img/features/tournament_qr.png", desc:"management.feature.CT.qrscan"}];

const imagesPA = [
    {url:"/img/features/parcours_target.png", desc:"management.feature.PA.targets"},
    {url:"/img/features/parcours_ranking.png", desc:"management.feature.PA.ranking"}];

const imagesPP = [
    {url:"/img/features/parcours_payment1.png", desc:""},
    {url:"/img/features/parcours_payment2.png", desc:""}];

const imagesUM = [
    {url:"/img/features/union_main.png", desc:"management.feature.UM.events"},
    {url:"/img/features/union_member.png", desc:"management.feature.UM.member"},
    {url:"/img/features/union_inout1.png", desc:"management.feature.UM.inout"}];

class BusinessPromoPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        document.title = messages.get("header.nav.solutions");
    }
    render() {
        return (<Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/solutions" code="header.nav.solutions" active/>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                    <Title code="home.panel.solutions.header"/>
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="info">
                                <strong><I18n code="promo.subheader.solution"/><br/><I18n code="promo.subheader.consult"/></strong>
                                <br/><br/>
                                <a href={linkUtil.getLink("contact")}>
                                    <Button bsStyle="primary"><I18n code="promo.link.contact"/></Button>
                                </a>

                            </Alert>
                        </Col>
                    </Row>
                    <hr/>
                    <AboFeatureDesc feature="TM" imgSrc={imagesTM} dir="H" price={messages.get("management.feature.yearsub") + "*: € 185"}
                                    descArray={["management.feature.TM.online",
                                        "management.feature.TM.payment",
                                        "management.feature.TM.app",
                                        "management.feature.TM.live",
                                        "management.feature.TM.start",
                                        "management.feature.TM.unlimited"]}
                                    links={[{url: "https://3dturnier.freshdesk.com/support/solutions/42000007125", desc:"FAQ & Wiki"}]}
                    />
                    <hr/>
                    <AboFeatureDesc feature="CT" imgSrc={imagesCT} dir="H" price={"Pauschale: € 550"}
                                    descArray={["management.feature.CT.licNumber",
                                        "management.feature.CT.result",
                                        "management.feature.CT.points",
                                        "management.feature.CT.price"]}/>
                    <hr/>
                    <AboFeatureDesc feature="PA" imgSrc={imagesPA} dir="H" price={messages.get("management.feature.yearsub") + "*: € 135"}
                                    descArray={["management.feature.PA.ranking",
                                        "management.feature.PA.payment",
                                        "management.feature.PA.stats",
                                        "management.feature.PA.targets"]}
                                    links={[{url: "https://3dturnier.freshdesk.com/a/solutions/articles/42000073490", desc:"Digitales Parcoursbuch"},
                                        {url: "https://3dturnier.freshdesk.com/a/solutions/articles/42000107231", desc:"Sperren von Mehrfach-Treffer Wertungen"},
                                        {url: "https://3dturnier.freshdesk.com/support/solutions/42000097160", desc:"FAQ & Wiki"}]}
                    />
                    <hr/>
                    <AboFeatureDesc feature="UM" imgSrc={imagesUM} dir="H" price={messages.get("management.feature.yearsub") + "*: € 215"}
                                    descArray={["management.feature.UM.member",
                                        "management.feature.UM.msg",
                                        "management.feature.UM.events",
                                        "management.feature.UM.inout"]}/>
                    <hr/>
                    <AboFeatureDesc feature="PP" imgSrc={imagesPP} dir="H"
                                    descArray={["management.feature.PA.payment",
                                        "management.feature.PP.transaction",
                                        "management.feature.PP.nocash",
                                        "management.feature.PP.brands"]}
                                    links={[{url: "https://www.youtube.com/watch?v=MpM3WOQnUT8", desc:"management.feature.PP.demo"}]}
                    />


            <AboFeatureDesc feature="PREM"  dir="H" price={messages.get("management.feature.yearsub") + ": € 590"}
                            descArray={["management.feature.PREM.all"]}/>

                    <Row>
                        <Col xs={12}>
                            <h3><I18n code="management.feature.ADV"/></h3>
                            <p><I18n code="management.feature.ADV.desc"/></p>
                        </Col>
                        <hr/>
                        <Col xs={12}>
                            <h3><I18n code="parcours.details.area.prices"/></h3>
                            <p><I18n code="management.feature.pricebase"/></p>
                            <p><I18n code="management.feature.pricetax"/></p>

                            <br/><br/>
                            <p><I18n code="management.feature.test"/></p>
                            <Table>
                                <thead>
                                <tr>
                                    <th className="col-md-9"><I18n code="management.feature.ST"/></th>
                                    <th className="col-md-3"><I18n code="parcours.details.area.prices"/></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{"0 - 100 "}<I18n code="tournament.table.column.archers"/></td>
                                    <td>&euro; 100</td>
                                </tr>
                                <tr>
                                    <td>{"101 - 150 "}<I18n code="tournament.table.column.archers"/></td>
                                    <td>&euro; 150</td>
                                </tr>
                                <tr>
                                    <td>{"151 - 200 "}<I18n code="tournament.table.column.archers"/></td>
                                    <td>&euro; 200</td>
                                </tr>
                                <tr>
                                    <td>{"201 - 250 "}<I18n code="tournament.table.column.archers"/></td>
                                    <td>&euro; 250</td>
                                </tr>
                                </tbody>
                            </Table>
                            <br/><br/>
                        </Col>
                    </Row>
                </Grid>)
    }
}
module.exports = BusinessPromoPage;
