const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Row, Col,  Alert, Well, Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    Loading = require("components/widgets/Loading"),
    SubmitButton = require("components/form/SubmitButton"),
    NumberInput = require("components/form/NumberInput"),
    DateInput = require("components/form/DateInput"),
    CheckboxInput = require("components/form/CheckboxInput"),
    FontAwesome = require("components/widgets/FontAwesome"),
    PaymentAddressForm = require("components/payment/PaymentAddressForm"),
    SelectInputAsyncTypeAhead = require("components/form/SelectInputAsyncTypeAhead"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const {Address, ParcoursVisit} = require("parse/_Domain");

const parcoursActions = require("actions/ParcoursActions");

class ParcoursVisitForm extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refPaymentAddress = React.createRef();
        this.refNote = React.createRef();
        this.refVoucherId = React.createRef();
        this.refStartDate = React.createRef();
        this.refStartTime = React.createRef();
        this.refEndTime = React.createRef();

        this.setError = this.setError.bind(this);
        this.validRegistration = this.validRegistration.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildVisitorObject = this.buildVisitorObject.bind(this);
        this.fillFormWithVisit = this.fillFormWithVisit.bind(this);
        this.handleRuleSwitch = this.handleRuleSwitch.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handlePayAmountChanged = this.handlePayAmountChanged.bind(this);
        this.handleAddArcherName = this.handleAddArcherName.bind(this);
        this.handleDateChanged = this.handleDateChanged.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.getUserMailDefault = this.getUserMailDefault.bind(this);
        this.queryForVisitor = this.queryForVisitor.bind(this);

        let ruleLink = null;
        // editor not null - means entering from backend
        const ruleImageID = this.props.editor == null ? this.props.parcoursOwner.getRulesImage() : null;
        if (ruleImageID != null && ruleImageID.getImage() != null) {
            ruleLink = <a href={ruleImageID.getImage()._url} target="_blank">{" " + messages.get("checkout.info.rules")}</a>
        }
        const paymentAmounts = this.props.parcours.hasVisitPaymentAmounts();
        this.state = {
            error: null,
            aAmount: paymentAmounts ? 0 : 1,
            payAmounts: {
                mobile: 0, cash: 0, annual: 0
            },
            addArchersNames: {},
            visitDate: new Date(),
            rSwitch: false,
            ruleLink: ruleLink,
            submitDisabled: false
        }
    }
    setError(message) {
        this.refSubmitButton.current.resetLoading();
        this.setState({
            submitDisabled: false,
            error: message
        });
    }
    validRegistration() {
        let valid = true;
        const fullAddress = this.props.parcours.hasVisitAddressEnabled();
        const allNames = this.props.parcours.hasVisitAddressEnabled();
        if (fullAddress) {
            // check address
            if (this.refPaymentAddress.current === null || !this.refPaymentAddress.current.valid()) {
                this.setError(messages.get("tournament.create.error.required"));
                return false;
            }
        } else {
            const required = [
                this.refName.current.getValue(),
                this.refSurname.current.getValue(),
                this.refMail.current.getValue()
            ];
            for (let i = 0; i < required.length; i++) {
                if (required[i] == null || required[i].toString().trim().length == 0) {
                    this.setError(messages.get("tournament.create.error.required"));
                    return false;
                }
            }
        }

        if (allNames) {
            // check if correct amount of names is given
            console.log("Add Archers", this.state.addArchersNames);
            if (Object.keys(this.state.addArchersNames).length !== (this.state.aAmount - 1)) {
                this.setError(messages.get("parcours.visit.moreNames"));
                return false;
            }
        }
        // validate time
        const startTime = this.refStartTime.current.getValue();
        if (startTime == null || startTime.split(":").length !== 2) {
            this.setError(messages.get("form.valid.not.time"));
            return false;
        }

        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.validRegistration()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        this.setState({submitDisabled: true});
        let visitObject = this.buildVisitorObject();
        console.log("VISIT:", visitObject);
        parcoursActions.registerParcoursVisit(visitObject, parcoursVisit => {
            if (this.props.callbackParcoursVisit) {
                this.props.callbackParcoursVisit(parcoursVisit)
            }

        }, error => {
            this.setError(error.message)
        });
    }
    buildVisitorObject() {
        const {visitDate} = this.state;
        const fullAddress = this.props.parcours.hasVisitAddressEnabled();
        const allNames = this.props.parcours.hasVisitAddressEnabled();
        let visitObject = {};
        visitObject.status = ParcoursVisit.prototype.status.started;
        visitObject.sendMail = true;
        if (fullAddress) {
            // take data from payment address form
            if (this.refPaymentAddress.current && this.refPaymentAddress.current.valid()) {
                let address = this.refPaymentAddress.current.buildTransactionAddress();
                visitObject.name = address[Address.prototype.col.name];
                visitObject.surname = address[Address.prototype.col.surname];
                visitObject.mail = address[Address.prototype.col.mail];
                visitObject.street = address[Address.prototype.col.street];
                visitObject.zip = address[Address.prototype.col.zip];
                visitObject.place = address[Address.prototype.col.place];
                visitObject.phone = address[Address.prototype.col.phone];
            } else {
                // payment address form bugs - try to take info from given props
                if (this.props.user) {
                    visitObject.mail = this.props.user.get("email");
                    visitObject.name = this.props.user.get("username")
                }
                if (this.props.userDetail) {
                    visitObject.name = this.props.userDetail.getName();
                    visitObject.surname = this.props.userDetail.getSurname();
                }
                if (this.props.address) {
                    visitObject.street = this.props.address.getStreet();
                    visitObject.zip = this.props.address.getZip();
                    visitObject.place = this.props.address.getPlace();
                    visitObject.phone = this.props.address.getPhone();
                }
            }
        } else {
            const tuMail = this.refMail.current.getValue().trim().toLowerCase();
            visitObject.name = this.refName.current.getValue().trim();
            visitObject.surname = this.refSurname.current.getValue().trim();
            visitObject.mail = tuMail;
        }

        const startTimeParts = this.refStartTime.current.getValue().split(":");
        let visitDateTime = new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), parseInt(startTimeParts[0]), parseInt(startTimeParts[1]),0,0);
        visitObject.startTime = visitDateTime;

        if (this.refEndTime.current && this.refEndTime.current.getValue()) {
            const endTimeParts = this.refEndTime.current.getValue().split(":");
            let visitEndTime = new Date(visitDate.getFullYear(), visitDate.getMonth(), visitDate.getDate(), parseInt(endTimeParts[0]), parseInt(endTimeParts[1]),0,0);
            visitObject.endTime = visitEndTime;
            visitObject.status = ParcoursVisit.prototype.status.finished;
            visitObject.sendMail = false;
        }
        visitObject.deviceType="WEB";
        visitObject.amount = this.state.aAmount;
        if (this.state.payAmounts.mobile > 0) {
            visitObject.payMobile = this.state.payAmounts.mobile;
        }
        if (this.state.payAmounts.cash > 0) {
            visitObject.payCash = this.state.payAmounts.cash;
        }
        if (this.state.payAmounts.annual > 0) {
            visitObject.payAnnual = this.state.payAmounts.annual;
        }
        if (this.refVoucherId.current && this.refVoucherId.current.getValue() && this.refVoucherId.current.getValue().length > 0) {
            visitObject.payCashComment = this.refVoucherId.current.getValue();
        }
        if (this.refNote.current.getValue() && this.refNote.current.getValue().length > 0) {
            visitObject.note = this.refNote.current.getValue().trim();
        }
        if (allNames && Object.keys(this.state.addArchersNames).length > 0) {
            visitObject.addVisitors = this.state.addArchersNames;
        }
        visitObject.parcoursID = this.props.parcours.id;
        visitObject.parcoursOwnerID = this.props.parcoursOwner.id;
        visitObject.parcoursOnlineID = this.props.parcours.getOnlineID();
        return visitObject;
    }
    queryForVisitor(searchTxt, callback) {
        parcoursActions.queryForVisitors(searchTxt, this.props.parcoursOwner, callback);
    }
    fillFormWithVisit(pVisit) {
        console.log("fill form with visit", pVisit);
        const fullAddress = this.props.parcours.hasVisitAddressEnabled();
        if (fullAddress) {
            if (this.refPaymentAddress.current) {
                const addressObj = {};
                addressObj["mail"] = pVisit.getMail();
                addressObj[Address.prototype.col.name] = pVisit.getName();
                addressObj[Address.prototype.col.surname] = pVisit.getSurname();
                addressObj[Address.prototype.col.street] = pVisit.getStreet();
                addressObj[Address.prototype.col.zip] = pVisit.getZip();
                addressObj[Address.prototype.col.place] = pVisit.getPlace();
                addressObj[Address.prototype.col.phone] = pVisit.getPhone();
                this.refPaymentAddress.current.setFormValues(addressObj)
            }
        } else {
                this.refName.current.setValue(pVisit.getName());
                this.refSurname.current.setValue(pVisit.getSurname());
                this.refMail.current.setValue(pVisit.getMail());
        }
    }
    addressFormChanged(changed) {
    }
    handleRuleSwitch(value) {
        this.setState({rSwitch: value})
    }
    handleAmountChanged(value) {
        this.setState({aAmount: value})
    }
    handlePayAmountChanged(type, value) {
        let tmpPayAmounts = this.state.payAmounts;
        tmpPayAmounts[type] = value;
        let aAmount = tmpPayAmounts.mobile + tmpPayAmounts.cash + tmpPayAmounts.annual;
        this.setState({
            payAmounts: tmpPayAmounts,
            aAmount: aAmount
        })
    }
    handleDateChanged(value) {
        let dateVal = new Date(value);
        this.setState({visitDate: dateVal});
    }
    handleAddArcherName(id, name) {
        console.log("Add archer " + id + " name: " + name);
        if (name != null && name.trim().length > 0) {
            this.state.addArchersNames[id] = name.trim();
        } else {
            // remove from dict
            delete this.state.addArchersNames[id];
        }
    }
    getDefault(col) {
        if (this.props.address) {
            return this.props.address.get(col)
        }
        return null;
    }
    getUserMailDefault() {
            if (this.props.user) {
                return this.props.user.get("email");
            }
        return "";
    }
    render() {
        const {user, editor, userDetail, address, parcours, parcoursOwner} = this.props;
        const {error, visitDate, visitTime, ruleLink, rSwitch, aAmount, payAmounts} = this.state;
        const fullAddress = parcours.hasVisitAddressEnabled();
        const paymentAmounts = parcours.hasVisitPaymentAmounts();
        const onlinePayment = parcours.hasPaymentEnabled();
        const allNames = parcours.hasVisitAddressEnabled();
        const mmgmtEnterMode = editor != null;
        let acceptText = messages.get("parcours.visit.accept");
        if (ruleLink) {
            acceptText += " " + messages.get("checkout.info.accept.rules");
        }
        let validateNow = error && error.length > 0;
        let moreNames = Utility.int2Array(aAmount - 1);
        // editor not null - means entering from backend
        return (<React.Fragment>
                <Row>
                    <Col xs={12} sm={10} smOffset={1}>
                        {
                            mmgmtEnterMode ?
                                <SelectInputAsyncTypeAhead
                                    onSearch={this.queryForVisitor}
                                    handleSelectedObject={this.fillFormWithVisit}
                                    placeholder={messages.get("tournament.register.search4name")}/> :
                                <Alert bsStyle="info"><p><strong>{messages.get("parcours.visit.enter.data")}</strong></p></Alert>
                        }

                    </Col>
                    <Col xs={12}>
                        <h3><FontAwesome icon="user"/><I18n code="profile.account.user.data.header"/></h3>
                    </Col>
                </Row>
                        <form action="#">
                            {
                                fullAddress ?
                                    <PaymentAddressForm ref={this.refPaymentAddress} showPhone={true}
                                                      userDetail={userDetail}
                                                      user={user}
                                                      changedCallback={this.addressFormChanged}/>
                                : <Row>
                                        <Col sm={6}>
                                            <ValidInput ref={this.refName}
                                                        valid={{maxLength: 30, check: ['isRequired']}}
                                                        validateNow={validateNow}
                                                        placeholder={messages.get("tournament.register.name")}
                                                        default={this.getDefault(Address.prototype.col.name)}/>
                                        </Col>
                                        <Col sm={6}>
                                            <ValidInput ref={this.refSurname}
                                                        valid={{maxLength: 30, check: ['isRequired']}}
                                                        validateNow={validateNow}
                                                        placeholder={messages.get("tournament.register.surname")}
                                                        default={this.getDefault(Address.prototype.col.surname)}/>
                                        </Col>
                                        <Col sm={12}>
                                            <ValidInput ref={this.refMail}
                                                        valid={{maxLength: 50, check: ['isRequired','isMail']}}
                                                        validateNow={validateNow}
                                                        placeholder={messages.get("tournament.register.email")}
                                                        addonBefore={<FontAwesome icon="envelope"/>}
                                                        default={this.getUserMailDefault()}/>
                                        </Col>


                                </Row>
                            }
                            {
                                paymentAmounts ? <Row>
                                    <Col sm={12}>
                                        <h3><FontAwesome icon="euro-sign"/><I18n code="parcours.visit.pay.header"/></h3>
                                    </Col>
                                    {
                                        onlinePayment ?
                                            <Col xs={6} sm={4}>
                                                <NumberInput default={0} minAmout={0} maxAmount={10} label={messages.get("parcours.visit.pay.mobile")}
                                                             onAfterChange={(value) => this.handlePayAmountChanged("mobile", value)}/>
                                            </Col> : null
                                    }
                                    <Col xs={6} smOffset={onlinePayment ? 0: 4} sm={4}>
                                        <NumberInput default={0} minAmout={0} maxAmount={10} label={messages.get("parcours.visit.pay.cash")}
                                                     onAfterChange={(value) => this.handlePayAmountChanged("cash", value)}/>
                                    </Col>
                                    <Col xs={6} sm={4}>
                                        <NumberInput default={0} minAmout={0} maxAmount={10} label={messages.get("parcours.visit.pay.annual")}
                                                     onAfterChange={(value) => this.handlePayAmountChanged("annual", value)}/>
                                    </Col>
                                    {
                                        payAmounts.cash > 0 ? <Col xs={12} smOffset={4} sm={4}>
                                            <ValidInput ref={this.refVoucherId}
                                                        valid={{maxLength: 30}}
                                                        addonBefore={<FontAwesome icon="money-bill-wave"/>}
                                                        placeholder={messages.get("parcours.visit.pay.cash.hint")}/>
                                        </Col> : null
                                    }
                                </Row> : null
                            }
                            <Row>
                                <Col sm={12}>
                                    <h3><FontAwesome icon="users"/><I18n code="tournament.table.column.group"/></h3>
                                </Col>
                                <Col sm={4}>
                                    {
                                        paymentAmounts ? <strong>{messages.get("parcours.visit.amount")}: {aAmount}<br/><br/></strong> :
                                            <NumberInput default={1} minAmout={1} maxAmount={12} label={messages.get("parcours.visit.amount")}
                                                                                onAfterChange={this.handleAmountChanged}/>
                                    }
                                </Col>
                                <Col sm={4}>
                                    <DateInput ref={this.refStartDate} default={visitDate} disabled={!mmgmtEnterMode}
                                               onAfterChange={this.handleDateChanged}
                                               label={messages.get("tournament.table.column.date")}/>
                                </Col>
                                <Col sm={4}>
                                    <ValidInput ref={this.refStartTime} default={Utility.getReadableTime(visitDate)} type="text"
                                                label={messages.get("tournament.create.time")}
                                                addonBefore={<FontAwesome icon="clock"/>}
                                                valid={{check:['isTime', 'isRequired']}}/>
                                </Col>
                                {
                                    mmgmtEnterMode ? <Col sm={4} smOffset={8}>
                                        <ValidInput ref={this.refEndTime} default={null} type="text"
                                                    label={messages.get("tournament.create.endtime")}
                                                    addonBefore={<FontAwesome icon="clock"/>}
                                                    valid={{check:['isTime', 'isRequired']}}/>
                                    </Col> : null
                                }
                                <Col md={10} mdOffset={1} >
                                {
                                    allNames && aAmount > 1 ? <React.Fragment>
                                        <h3>{messages.get("parcours.visit.moreNames")}</h3>
                                            {
                                                moreNames.map(number => {
                                                    return <MoreArchersName key={"MARN"+number} number={number} handleAddArcherName={this.handleAddArcherName}/>
                                                })
                                            }
                                        </React.Fragment> : null
                                }
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ValidInput ref={this.refNote}
                                                componentClass="textarea"
                                                valid={{maxLength: 1500}}
                                                placeholder={messages.get("tournament.table.column.note.visit")}/>
                                </Col>
                                <Col sm={12}>
                                    <br/>
                                    <Well>
                                        <CheckboxInput default={this.state.rSwitch}
                                                       onAfterChange={value => {
                                                           this.handleRuleSwitch(value);
                                                       }}
                                                       message={acceptText}
                                                       addonAfter={ruleLink}
                                        />
                                    </Well>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8} xsOffset={2} style={{"textAlign": "center"}}>
                                    {
                                        error == null ? null :
                                            <Alert bsStyle="warning">
                                                {error}
                                            </Alert>
                                    }
                                    <SubmitButton ref={this.refSubmitButton} block bsSize="large"
                                                  disabled={this.state.submitDisabled || rSwitch === false || aAmount === 0}
                                                  icon={<FontAwesome icon="check"/>}
                                                  onClick={this.handleSubmit}
                                                  text={messages.get("tournament.register.submit")}/>
                                </Col>
                            </Row>
                        </form>
            </React.Fragment>
        )
    }
}
ParcoursVisitForm.propTypes = {
    user: PropTypes.object,
    editor: PropTypes.object,
    parcours: PropTypes.object.isRequired,
    parcoursOwner: PropTypes.object.isRequired,
    userDetail: PropTypes.object,
    address: PropTypes.object,
    callbackParcoursVisit: PropTypes.func.isRequired
};
const MoreArchersName = ({number, handleAddArcherName}) => {
    function onValueChanged(value) {
        handleAddArcherName(number, value)
    }
    return <ValidInput onAfterChange={onValueChanged}
        addonBefore={<FontAwesome icon="hashtag">{number+1}</FontAwesome>}
        valid={{maxLength: 30, check: ['isRequired']}}
        placeholder={messages.get("tournament.register.fullName")}/>
};
module.exports = ParcoursVisitForm;
