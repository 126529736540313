/**
 * Timeout lock.
 * A submitted callback gets executed after a specific timeout
 * only if no other callback was submitted in between.
 *
 * Can be used for input fields that trigger a request automatically.
 *
 * Usage:
 * var timeoutLock = timeoutLockFactory.createLock(1000);
 * timeoutLock.executeDelayed(fun)
 *
 * @type {{createLock}}
 */
var TimeoutLockFactory = (function () {

    function TimeoutLock(delay) {
        if (!delay) {
            throw "missing parameter: delay in ms"
        }
        this.delay = delay;
        this.num = 0;
        this.stopped = false;
        this.executeDelayed = function (callback) {
            this.stopped = false;
            this.num++;
            var num = this.num;
            setTimeout(
                (function () {
                    // console.log("callback", this.num, this.stopped)
                    if (num == this.num && !this.stopped) {
                        callback();
                    }
                }).bind(this),
                this.delay
            )
        }
        this.stop = function () {
            this.stopped = true;
        }
    }

    return {
        createLock: function (delay) {
            return new TimeoutLock(delay)
        }
    }
})();


module.exports = TimeoutLockFactory;

