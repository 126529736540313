const Parse = require("ParseInit");

const CountTypeDtl = Parse.Object.extend("countTypeDtl", {
    col: {
        ACL: "ACL",
        arrowNr: "arrowNr",
        createdAt: "createdAt",
        killNr: "killNr",
        objectId: "objectId",
        points: "points",
        updatedAt: "updatedAt",
        zoneName: "zoneName",
    },
    equalsValues: function (points, arrowNr, zoneNr, zoneName) {
        return this.getPoints() == points && this.getArrowNr() == arrowNr && this.getKillNr() == zoneNr && this.getZoneName() == zoneName;
    },
    buildCountTypeDetail: function(points, arrowNr, zoneNr, zoneName) {
        const obj = new CountTypeDtl();
        obj.setPoints(points);
        obj.setArrowNr(arrowNr);
        obj.setKillNr(zoneNr);
        obj.setZoneName(zoneName);
        return obj;
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getArrowNr: function () {
        return this.get(this.col.arrowNr)
    },
    setArrowNr: function (arrowNr) {
        return this.set(this.col.arrowNr, arrowNr)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getKillNr: function () {
        return this.get(this.col.killNr)
    },
    setKillNr: function (killNr) {
        return this.set(this.col.killNr, killNr)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPoints: function () {
        return this.get(this.col.points)
    },
    setPoints: function (points) {
        return this.set(this.col.points, points)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
    getZoneName: function () {
        return this.get(this.col.zoneName)
    },
    setZoneName: function (zoneName) {
        return this.set(this.col.zoneName, zoneName)
    },
});

module.exports = CountTypeDtl;
