const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, ControlLabel} = require("react-bootstrap");
const messages = require("i18n/messages");

const TournamentOptionSelect = ({tournament, tOption, defaultValue, preSelect, selectedAgeType, selectCallback}) =>{
    const changeOption = (event) => {
        selectCallback(event.target.value)
    };
    let tOptionObject = selectedAgeType ? selectedAgeType.getTOptionSurcharge() : null;
    return (<FormGroup>
        <ControlLabel>{tOption.getOptionLabel()}</ControlLabel>
        <FormControl componentClass="select" placeholder="select"
                     defaultValue={defaultValue}
                     onChange={changeOption}>
            {
                preSelect == false ? <option value={null}>{messages.get("modal.license.choose")}</option> : null
            }
            {
                tOption.getOptions().map(function (opt) {
                    let optionSum = 0;
                    if (tOptionObject != null && tOptionObject[tournament.id + "_" + opt]) {
                        optionSum += parseInt(tOptionObject[tournament.id + "_" + opt])
                    } else if (tOptionObject != null && tOptionObject[opt]) {
                        optionSum += parseInt(tOptionObject[opt])
                    }
                    return <option key={opt} value={opt}>{opt}{optionSum > 0 ? ";  € " + optionSum : null}</option>
                })
            }
        </FormControl>
    </FormGroup>);
};
TournamentOptionSelect.propTypes = {
    tournament: PropTypes.object.isRequired,
    tOption: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    preSelect: PropTypes.bool,
    selectedAgeType: PropTypes.object,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentOptionSelect;
