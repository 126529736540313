const Parse = require("ParseInit");
const Utility = require("util/Utility");

const ParcoursVisit = Parse.Object.extend("parcoursVisit", {
    col: {
        ACL: "ACL",
        objectId: "objectId",
        parcoursID: "parcoursID",
        parcoursOwnerID: "parcoursOwnerID",
        parcoursOnlineID: "parcoursOnlineID",
        editor: "editor",
        name: "name",
        surname: "surname",
        address: "address",
        addVisitors: "addVisitors",
        contactEmail: "contactEmail",
        status: "status",
        insstmp: "insstmp",
        visitorAmount: "visitorAmount",
        payMobile: "payMobile",
        payCash: "payCash",
        payAnnual: "payAnnual",
        deviceType: "deviceType",
        startTime: "startTime",
        endTime: "endTime",
        note: "note",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        registered: "R",
        started: "S",
        finished: "C",
        deleted: "D"
    },
    getZipPlace() {
        const addressObj = this.getAddress();
        if (addressObj != null) {
            let tPlace = "";
            if (addressObj.zip) {
                tPlace = addressObj.zip + " ";
            }
            if (addressObj.place) {
                tPlace += addressObj.place;
            }
            return tPlace;
        }
        return "";
    },
    getVisitDate() {
        let tDate = this.getStartTime();
        return Utility.getReadableDate(tDate);
    },
    getVisitStart() {
        let tDate = this.getStartTime();
        return Utility.getReadableTime(tDate);
    },
    getVisitEnd() {
        let tDate = this.getEndTime();
        if (tDate) {
            return Utility.getReadableTime(tDate);
        }
        return null;
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getAddress() {
        return this.get(this.col.address)
    },
    getAddVisitorNames() {
        return this.get(this.col.addVisitors)
    },
    getParcoursID() {
        return this.get(this.col.parcoursID)
    },
    getParcoursOwnerID() {
        return this.get(this.col.parcoursOwnerID)
    },
    getParcoursOnlineID() {
        return this.get(this.col.parcoursOnlineID)
    },
    getMail() {
        return this.get(this.col.contactEmail)
    },
    getInsstmp() {
        return this.get(this.col.insstmp)
    },
    getVisitorAmount() {
        return this.get(this.col.visitorAmount)
    },
    getPayMobileAmount() {
        return this.get(this.col.payMobile)
    },
    getPayCashAmount() {
        return this.get(this.col.payCash)
    },
    getPayAnnualAmount() {
        return this.get(this.col.payAnnual)
    },
    getName() {
        return this.get(this.col.name)
    },
    getSurname() {
        return this.get(this.col.surname)
    },
    getFullName() {
        let surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return this.getName() + " " + surname;
        } else {
            return this.getName();
        }
    },
    getFullSurName() {
        let surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return  surname + " " + this.getName();
        } else {
            return this.getName();
        }
    },
    getDatalistText() {
        let text = this.getFullName() + " (" + this.getMail() + ")";
        if (this.getPlace() != null) {
            text += ", " + this.getPlace()
        }
        return text;
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    getPhone() {
        const addressObj = this.getAddress();
        if (addressObj != null && addressObj.phone) {
            return addressObj.phone
        }
        return "";
    },
    getPlace() {
        const addressObj = this.getAddress();
        if (addressObj != null && addressObj.place) {
            return addressObj.place
        }
        return null;
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getStreet() {
        const addressObj = this.getAddress();
        if (addressObj != null && addressObj.street) {
            return addressObj.street
        }
        return "";
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getZip() {
        const addressObj = this.getAddress();
        if (addressObj != null && addressObj.zip) {
            return addressObj.zip
        }
        return null;
    },
    getStartTime() {
        return this.get(this.col.startTime)
    },
    getEndTime() {
        return this.get(this.col.endTime)
    },
    setEndTime(endDate) {
        this.set(this.col.endTime, endDate)
    },
    getNote() {
        return this.get(this.col.note)
    }
});

module.exports = ParcoursVisit;
