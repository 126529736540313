const Parse = require("ParseInit");
const Utility = require("util/Utility");
const Voucher = Parse.Object.extend("voucher", {
    col: {
        ACL: "ACL",
        status: "status",
        text: "text",
        transactionID: "transactionID",
        expireDate: "expDate",
        redeem: "reedem",
        voucher: "voucher",
        redeemDate: "redeemDate",
        redeemUser:"redeemUser",
        managementID: "managementID",
        actDate: "actDate",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        active: "A",
        used: "U",
        register: "R",
        deleted: "D"
    },
    //
    // customn
    getTransaction() {
        return this.get(this.col.transactionID);
    },
    getExpireDateReadable() {
        const date =  this.getExpireDate();
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getRedeemDateReadable() {
        const date =  this.getRedeemDate();
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getCreatedDateReadable() {
        const date =  this.getCreatedAt();
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getActivatedDateReadable() {
        const date =  this.getActivatedDate();
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    //
    //
    //
    //
    getExpireDate() {
        return this.get(this.col.expireDate);
    },
    getRedeemDate() {
        return this.get(this.col.redeemDate);
    },
    getText() {
        return this.get(this.col.text)
    },
    getVoucherFile() {
        return this.get(this.col.voucher);
    },
    getRedeemUser() {
        return this.get(this.col.redeemUser)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isUsed() {
        return this.status.used === this.getStatus();
    },
    isRegistered() {
        return this.status.register === this.getStatus();
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    getActivateBusinessAccount() {
        let management = this.getManagementID();
        if (management) {
            return management.getName();
        }
        return "";
    },
    getActivatedDate() {
        return this.get(this.col.actDate)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = Voucher;
