var alt = require("AltInit");
var newsDao = require("actdao/NewsDao");
var notificationActions = require("components/notification/NotificationActions");

class NewsActions {

    showProgress() {
        this.updateNewsList(null);
        return {};
    };

    searchNews(searchParams) {
        newsDao.searchNews(searchParams)
            .then(news => {
                this.updateNewsList(news);
            })
            .catch(error => {
                error.trace = "NWSA.search";
                notificationActions.parseError(error);
            });
        return {};
    };

    initTeaser(callback) {
        newsDao.initTeaser()
            .then(news => {
                callback(news);
            })
            .catch(error => {
                callback([]);
                error.trace = "NWSA.init";
                notificationActions.parseError(error);
            });
        return {};
    };
    updateNewsList(newsList) {
        return newsList;
    }
}

module.exports = alt.createActions(NewsActions);
