const alt = require("AltInit");
const actions = require("actions/EventDetailActions");

class EventDetailStore {
    constructor() {
        this.displayName = "EventDetailStore";
        this.eventPlayers = null;
        this.eventResultsEvpID = null;
        this.eventResults = null;
        this.event = null;
        this.eventTrack = null;
        this.eventComments = null;
        this.tuGroupLives = null;
        this.isAdmin = false;
        this.loading = true;
        this.bindListeners({
            updateEventPlayerList: actions.updateEventPlayerList,
            updateAdmin: actions.updateAdmin,
            updateEvent: actions.updateEvent,
            updateEventTrack: actions.updateEventTrack,
            updateEventComments: actions.updateEventComments,
            updateEventGroupLives: actions.updateEventGroupLives,
            reset: actions.reset
        });
    }

    updateEventPlayerList(pEventPlayers) {
        if (pEventPlayers) {
            let evPlId = ["trg"];
            const eventResults = {};
            eventResults["trg"] = null;
            pEventPlayers.map(evPlayer => {
                if (!evPlayer.isDeleted()) {
                    evPlId.push(evPlayer.id);
                    eventResults[evPlayer.id] = evPlayer.getValidEventResults();
                }
            });
            this.eventResultsEvpID = evPlId;
            this.eventResults = eventResults;
        } else {
            this.eventResultsEvpID = null;
            this.eventResults = null;
        }
        this.eventPlayers = pEventPlayers;
        this.loading = false;
    }

    updateEvent(pEvent) {
        this.loading = false;
        this.event = pEvent;
    }

    updateEventTrack(pTrack) {
        this.eventTrack = pTrack;
    }
    updateEventGroupLives(tuGroupLives) {
        this.tuGroupLives = tuGroupLives;
    }
    updateEventComments(eventComments) {
        this.eventComments = eventComments
    }

    updateAdmin(isAdmin) {
        this.isAdmin = isAdmin;
    }

    reset() {
        this.loading = true;
        this.eventPlayers = null;
        this.eventResultsEvpID = null;
        this.eventResults = null;
        this.event = null;
        this.eventTrack = null;
        this.isAdmin = false;
    }
}
module.exports = alt.createStore(EventDetailStore);
