const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    DecisionModal= require("components/modals/DecisionModal");

class TooltipConfirmButton extends React.Component {
    constructor(props) {
        super(props);
        this.refDecModal = React.createRef();
        this.handleAsk = this.handleAsk.bind(this);
    }
    handleAsk() {
        this.refDecModal.current.open();
    }
    render() {
        const {icon, bsStyle, bsSize, onClick, disabled, tooltip, children, custClassName} = this.props
        return (<React.Fragment>
            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                <Button bsStyle={bsStyle} bsSize={bsSize} onClick={this.handleAsk} disabled={disabled}
                        className={custClassName}>
                    { icon ? <FontAwesome icon={icon}/> : null}
                    { children }
                </Button>
            </OverlayTrigger>
                <DecisionModal ref={this.refDecModal}
                               titleCode={"tournament.register.submit"}
                               messageCode={tooltip}
                               okButtonCode={"modal.button.ok"}
                               cancelButtonCode={"modal.button.cancel"}
                               handleOK={onClick}/>
            </React.Fragment>
        )
    }
}
TooltipConfirmButton.propTypes = {
    tooltip: PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    bsStyle: PropTypes.string,
    bsSize: PropTypes.string,
    icon: PropTypes.string,
    custClassName: PropTypes.string,
    children: PropTypes.any
};
module.exports = TooltipConfirmButton;
