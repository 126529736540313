const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Button} = require("react-bootstrap");
const {Link} = require ('react-router-dom');
const I18n = require("components/widgets/I18n"),
    FileDownloadArea = require("components/widgets/FileDownloadArea"),
    ShareButton = require("components/widgets/ShareButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MailModal = require("components/modals/MailModal"),
    PanelCollapse = require("components/widgets/PanelCollapse"),
    TournamentInfoHeader = require("components/tournament/TournamentInfoHeader"),
    TournamentDetailBowUnion = require("components/tournament/TournamentDetailBowUnion"),
    TournamentCupTable = require("components/tournament/TournamentCupTable"),
    TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentMapFrame = require("components/map/TournamentMapFrame"),
    LinkButton = require("components/links/LinkButton"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility");

const TournamentGroupsButton = require("components/links/TournamentGroupsButton");

const TournamentDetailPanel = ({user, tournament, childTournaments}) => {
    return <PanelCollapse header={<TournamentInfoHeader user={user} tournament={tournament}/>}>
        {
            tournament.isTypeCupMaster() ? <TournamentCupMasterInfoBody childTournaments={childTournaments} tournament={tournament} /> :
                <TournamentInfoBody user={user} tournament={tournament}/>
        }
    </PanelCollapse>
}
TournamentDetailPanel.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired
};
const TournamentInfoBody = ({user, tournament}) => {
    function showRegistrationDate() {
        if (tournament.getRegStartDate() != null) {
            if (tournament.getRegStartDate() > new Date()) {
                return <Alert><h4><FontAwesome icon="bell"/>{messages.get("tournament.info.regStart")}&nbsp;{Utility.getReadableDate(tournament.getRegStartDate())}</h4></Alert>
            }
        }
        return null;
    }
    function buildEmail(user, tEmail, subject) {
        if (Utility.isValidEmail(tEmail)) {
            if (user) {
                return <MailModal senderMail={user.getEmail()} receiverMail={tEmail} subject={subject}/>
            } else {
                return <p><FontAwesome icon="envelope"/><a href={"mailto:" + tEmail}>{tEmail}</a></p>
            }
        }
        return null;
    }
    let paymentCondition = "";
    let email = "";
    const tPrice = tournament.getTournamentPriceID();
    if (tPrice) {
        if (tPrice.getPaymentCondition()) {
            email = tPrice.getEmail();
            paymentCondition = (<p><FontAwesome icon="euro-sign"/>{tPrice.getPaymentCondition()}</p>);
        }
    }
    // coords
    let coordinates = "";
    const parcours = tournament.getParcoursID();
    if (parcours) {
        coordinates =
            <p><FontAwesome icon="map-marker-alt"/><a href={linkUtil.getLink("parcours/"+parcours.getOnlineID())}><I18n code="tournament.info.wikiLink"/></a></p>
    }
    let description = "";
    const desc = tournament.getDescription();
    if (desc) {
        description = (<p><FontAwesome icon="exclamation-triangle"/><label><I18n code="tournament.info.important"/></label> {desc}</p>);
    }
    return <Row>
        <Col md={6}>
            {/*<TournamentStatus tournament={tournament}/>*/}
            {coordinates}
            <TournamentDetailBowUnion bowUnion={tournament.getBowUnionID()} addRules={false}/>
            {buildEmail(user, email, tournament.getName())}
            <hr/>
            {paymentCondition}
            {description}
            <ShareButton url={linkUtil.getFullLink("tournament/" + tournament.id)}/>

        </Col>
        <Col md={6} className="text-center">
            {
                showRegistrationDate()
            }
            {
                tournament.getImageID() ?
                    <FileDownloadArea image={tournament.getImageID()}/>
                    :
                    ""
            }
            {
                tournament.isOnlinePaymentEnabled() && tournament.hasInitialRegistration()
                && !tournament.isTypeCupMaster() && !tournament.isClosed()?
                    <Alert bsStyle="info">
                        <h4>{messages.get("tournament.register.panel.info.payDesc")}</h4>
                        <Button bsStyle="primary" href={linkUtil.getLink("tournament/" + tournament.id+"/checkout")}>
                            <FontAwesome icon="euro-sign"/><I18n code="tournament.register.panel.pay"/>
                        </Button>
                    </Alert> : null
            }
            <Alert bsStyle="info">
                <p><Link to={linkUtil.getLink("/skillboard/tab/jointournament")}>
                    <FontAwesome icon="question-circle"/><I18n code="tournament.info.app"/>
                </Link></p>
            </Alert>
            {
                tournament.isTypeCupChild() ?
                    <Alert><I18n code="tournament.info.isCupChild"/>{tournament.getBowUnionID().getName()}<br/><br/>
                        <LinkButton label="tournament.info.isCupChild.link" bsStyle="primary" symbol={<FontAwesome icon="medal"/>}
                                    href={linkUtil.getLink("tournament/"+ tournament.getCupMasterTournamentID().id)}/>
                    </Alert> : null
            }
            {
                tournament.showTUGroupButton() ?
                    <TournamentGroupsButton block bsSize="large" tournament={tournament}/>
                    : null
            }
        </Col>
    </Row>
}
const TournamentCupMasterInfoBody = ({childTournaments, tournament}) => {
    return <React.Fragment>
        <Row>
            <Col md={4}>
                <TournamentDetailBowUnion bowUnion={tournament.getBowUnionID()} addRules={true}/>
                <ShareButton url={linkUtil.getFullLink("tournament/" + tournament.id)}/>
            </Col>
            <Col md={8}>
                {
                    childTournaments != null && tournament.getBowUnionID() != null && tournament.getBowUnionID().showCupMap()?
                        <TournamentMapFrame tournaments={childTournaments} ownPosition={null}/> : null
                }
            </Col>
        </Row>
        <hr/>
        <Row>
            <Col xs={12}>
                <h3><I18n code="tournament.info.cupChilds"/></h3>
                {
                    childTournaments != null ?
                        <TournamentCupTable showActions={false} tournament={tournament} childTournaments={childTournaments}/> : null
                }
            </Col>
        </Row>
    </React.Fragment>
}
module.exports = TournamentDetailPanel;