const ParseListUtility = require("util/ParseListUtility"),
    ParseListCompareUtility = require("util/ParseListCompareUtility");

function TournamentUserTeamResultDic() {
    this.groupedResults = [];
    this.primeGroup = [];
    this.secondGroup = [null];

    this.addSortTournamentUserTeam = function (tournamentUserTeam) {
        const ageGroup = tournamentUserTeam.getTournamentConfigAge();
        const bowGroup = tournamentUserTeam.getTournamentConfigBow();
        const key = this.generateDicKey(ageGroup, bowGroup, "x");
        // check for bow
        if (ageGroup) {
            this.checkForGroup(this.primeGroup, ageGroup);
        }
        this.addUserToGroup(this.groupedResults, key, tournamentUserTeam);
    };
    this.getTournamentPrimeGroup = function() {
        return this.primeGroup
    };
    this.getTournamentSecondGroup = function() {
        return this.secondGroup
    };
    this.setTournamentSecondGroup = function(configGroups) {
        this.secondGroup = configGroups;
    };
    this.generateDicKey = function(primeConfig, secConfig, tuSex) {
        let sec = "";
        if (secConfig != null) {
            sec = secConfig.id;
        }
        let prim = "";
        if (primeConfig) {
            prim =  primeConfig.id
        }
        return prim + "z" + tuSex + "z" + sec;
    };
    this.getList = function(key) {
        const groupedTUList = this.groupedResults[key];
        if (groupedTUList && groupedTUList.length > 0) {
            return groupedTUList;
        } else {
            return null;
        }

    };
    this.addUserToGroup = function(aGroupedResults, key, tournamentUserTeam) {
        this.calcTeamSum(tournamentUserTeam);
        const groupedTUList = aGroupedResults[key];
        if (groupedTUList) {
            ParseListUtility.pushObjSortedDESC(groupedTUList, tournamentUserTeam, ParseListCompareUtility.compareUserTeamFct);
        } else {
            const newList = [];
            newList.push(tournamentUserTeam);
            aGroupedResults[key] = newList;
        }
    };
    this.calcTeamSum = function(tournamentUserTeam) {
        const tUsers = tournamentUserTeam.getTournamentUserIDs();
        let sum = 0;
        let killValue = 0;
        let killCounts = {};
        for (let i = 0; i < tUsers.length; i++) {
            sum += parseFloat(tUsers[i].getSortableSumPoints());
            killValue += tUsers[i].getKillValue();
            this.sumUpKillCounts(killCounts, tUsers[i].getKillCounts());
        }
        if (sum > 0) {
            tournamentUserTeam.setSumPoints(Math.round(sum * 100) / 100);
            tournamentUserTeam.setKillValue(killValue);
            let killCountsstr ="f,";
            Object.keys(killCounts).map(i => {
                killCountsstr += (i + ":" + killCounts[i] + ",");
            });
            tournamentUserTeam.setKillCounts(killCountsstr);
        }
    };
    this.sumUpKillCounts = function(killCounts, addKillCounts) {
        if (addKillCounts != null) {
            var rkillCounts = addKillCounts.split(",");
            for (var k = 0; k < rkillCounts.length; k++) {
                if (k != 0) {
                    var killCnt = rkillCounts[k].split(":");
                    if (killCnt.length = 2) {
                        var amount = killCounts[killCnt[0]];
                        if (amount) {
                            amount = parseInt(amount) + parseInt(killCnt[1]);
                        } else {
                            amount = parseInt(killCnt[1]);
                        }
                        killCounts[killCnt[0]] = amount;
                    }
                }
            }
        }
    };
    this.checkForGroup = function (aGroups, group) {
        let found = false;
        for(let i = 0; i < aGroups.length; i++) {
            if (aGroups[i].id == group.id) {
                found = true;
                break;
            }
        }
        if (!found) {
            aGroups.push(group);
        }
    };
    this.getGroupRoundAmount = function (key) {
        return 1;
    };
    this.getFinalGroupRoundAmount = function (key) {
        return 0;
    }
}

module.exports = TournamentUserTeamResultDic;