const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    LoginButton = require("components/widgets/LoginButton"),
    Loading = require("components/widgets/Loading");

const userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions"),
    notificationActions = require("components/notification/NotificationActions"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursLoader extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.state = {
            userStore: userStore.getState(),
            parcoursStore: parcoursStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        parcoursStore.listen(this.handleParcoursChange);
        notificationActions.reset();
        const ratingUser = this.props.rating ? this.state.userStore.user : null;
        // edit check: F -> no check, T - mandatory check, A = check but only for managed courses
        const editUser = this.props.editCheck !== "F" ? this.state.userStore.user : null;
        parcoursActions.queryParcours(this.props.onlineID, ratingUser, editUser, this.props.editCheck === "A");
        if (ratingUser) {
            userActions.fetchUserFavorite(this.state.userStore.user);
        }
        if (this.props.tstat === "admin") {
            userActions.getIsAdmin();
        }
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        parcoursStore.unlisten(this.handleParcoursChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    render() {
        if (this.props.loginMandatory && this.state.userStore.user == null) {
            return (
                <Grid>
                    <br/><br/>
                    <Alert bsStyle="warning"><I18n code="parcours.edit.message.noLogin"/>: <LoginButton/></Alert>
                </Grid>)
        }
        if (this.state.userStore.isAdmin !== true && this.state.parcoursStore.invalidUser == true) {
            return (
                <Grid>
                    <br/><br/>
                    <Alert bsStyle="danger"><I18n code="parcours.invalidUser"/><br/>{this.props.invalidUserDesc}</Alert>
                </Grid>)
        }
        if (this.state.parcoursStore.loading === true) {
            return (
                <Grid>
                    <Loading/>
                </Grid>)
        }
        if (this.state.parcoursStore.parcours == null && this.state.parcoursStore.parseError == null) {
            return (
                <Grid>
                    <Row>
                        <Col lg={12}>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            </Breadcrumbs>
                        </Col>
                        <Col xs={12}>
                            <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                        </Col>
                    </Row>
                </Grid>
            )
        }
        return <Grid>
            {React.cloneElement(this.props.children,
                { parcours: this.state.parcoursStore.parcours,
                    user: this.state.userStore.user,
                    userFeatures: this.state.userStore.userFeatures,
                    userDetail: this.state.userStore.userDetail,
                    userDetailLoading: this.state.userStore.userDetailLoading,
                    ownRating: this.state.parcoursStore.ownRating,
                    parcoursArticles: this.state.parcoursStore.parcoursArticles,
                    showEventLink: this.state.userStore.isAdmin
                } )}
            </Grid>
    }
}
ParcoursLoader.propTypes = {
    onlineID:PropTypes.string.isRequired,
    editCheck:PropTypes.string.isRequired,
    loginMandatory: PropTypes.bool,
    rating:PropTypes.bool,
    invalidUserDesc:PropTypes.object,
    tstat: PropTypes.string
};
module.exports = ParcoursLoader;
