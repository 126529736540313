const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Alert, Image} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    Title = require("components/widgets/Title"),
    EventPlayersTable = require("components/profile/EventPlayersTable");

const messages = require("i18n/messages");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const languageStore = require("stores/LanguageStore"),
    eventActions = require("actions/EventActions");

class SmartTournamentPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            languageStore: languageStore.getState(),
            eventPlayers: null
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.smarttournament");
        languageStore.listen(this.handleLanguageChange);
        if (this.props.eventID) {
            this.showDetails(this.props.eventID);
        }
    }
    componentWillUnmount() {
        languageStore.unlisten(this.handleLanguageChange);
    }
    handleLanguageChange(state) {
        this.setState({languageStore: state});
    }
    showDetails(eventID) {
        if (eventID != null) {
            eventActions.queryEventPlayer4SmartTournament(eventID, eventPlayers => {
                this.handleChange(eventPlayers);
            });
        }
    }
    handleChange(pEventPlayers) {
        this.setState({
            eventPlayers: pEventPlayers
        });
    }
    render() {
        const {imageDirPrefix} = this.state.languageStore;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/smarttournament" code="header.nav.smarttournament" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="header.nav.smarttournament"/>
                <SmartTournamentTable eventPlayers={this.state.eventPlayers} />
                <Row>
                    <Col sm={3}>
                        <Image responsive src={imageDirPrefix + "/img/skillboard/smartevent_start.png"}/>
                    </Col>
                    <Col sm={6}>
                        {<I18nHtml code="smarttournament.text.html"/>}
                    </Col>
                    <Col sm={3}>
                        <Image responsive src={imageDirPrefix + "/img/skillboard/smartevent_code.png"}/>
                    </Col>
                </Row>
            </Grid>)
    }
}
SmartTournamentPage.propTypes = {
    eventID: PropTypes.string
};
const SmartTournamentTable = ({eventPlayers}) => {
    if (eventPlayers != null && eventPlayers.length > 0) {
        var event = eventPlayers[0].getEventID();
        var eventName = event.getDate() + " - " + event.getName();
        return (
            <Row>
                <Col xs={12}>
                    <br/>
                    <h3>{eventName}</h3>
                    <EventPlayersTable eventPlayers={eventPlayers} />
                    <Alert><I18n code="smarttournament.text.alert" /></Alert>
                    <br/><hr/>
                </Col>
            </Row>)
    } else {
        return null;
    }
};
module.exports = SmartTournamentPage;
