const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
const SolutionHelpLink = require("components/links/SolutionHelpLink");
const Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n");
const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader"),
    TournamentUserScanner  = require("components/tournamentmanage/TournamentUserScanner");

const tournamentUserManagerStore = require("stores/TournamentUserManagerStore"),
    tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

const TournamentMMScanPage  = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId}>
        <TournamentTUserHierarchy />
    </TournamentMMLoader>
};
TournamentMMScanPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
class TournamentTUserHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);

        this.state = {
            tournamentUserManagerStore: tournamentUserManagerStore.getState(),
            showDeletedUsers: false
        }
    }
    componentDidMount() {
        tournamentUserManagerStore.listen(this.handleTournamentUserChange);
        tournamentUserManagerActions.loadTournamentUsers.defer(this.props.tournament, this.state.showDeletedUsers)
    }
    componentWillUnmount() {
        tournamentUserManagerActions.resetTournamentUsers();
        tournamentUserManagerStore.unlisten(this.handleTournamentUserChange);
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserManagerStore: state});
    }
    render() {
        const {user, tournament} = this.props;
        return (<React.Fragment>
            <Row>
                <Col lg={12}>
                    <h3>{tournament.getName() + ", " + tournament.getTournamentDate()}</h3>
                    <SolutionHelpLink articleID="42000051445" />
                </Col>
            </Row>
            {
                this.state.tournamentUserManagerStore.loading ? <Loading/> :
                    this.state.tournamentUserManagerStore.tournamentUsers != null
                        && this.state.tournamentUserManagerStore.tournamentUsers.length > 0 ?
                    <TournamentUserScanner user={user} tournament={tournament} tournamentUsers={this.state.tournamentUserManagerStore.tournamentUsers}/> :
                    <Alert bsStyle="warning"><I18n code="list.entry.empty"/></Alert>
            }
        </React.Fragment>)}
}
TournamentTUserHierarchy.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentMMScanPage;