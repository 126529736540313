const React = require("react"),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    SkillBoardFeatureCompare = require("components/skillboard/SkillBoardFeatureCompare"),
    AboFeatureDesc = require("components/widgets/AboFeatureDesc"),
    Title = require("components/widgets/Title");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const imagesStat = [
        {url:"/img/premium/stat_1.png", desc:"skillboard.premium.S.desc1"},
        {url:"/img/premium/stat_2.png", desc:"skillboard.premium.S.desc4"},
        {url:"/img/premium/stat_3.png", desc:"skillboard.premium.S.desc4"},
        {url:"/img/premium/stat_4.png", desc:"skillboard.premium.S.desc2"}];

const imagesGPS = [
    {url:"/img/premium/gps_1.png", desc:"skillboard.premium.G.desc2"},
    {url:"/img/premium/gps_2.png", desc:"skillboard.premium.G.desc4"}];

const imagesTarget = [
    {url:"/img/premium/target_1.png", desc:""},
    {url:"/img/premium/target_2.png", desc:""}];

const imagesKillImage = [
    {url:"/img/premium/kimage_1.png", desc:"skillboard.premium.K.desc1"},
    {url:"/img/premium/kimage_2.png", desc:"skillboard.premium.K.desc2"}];

const SkillBoardProPage = () => {
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/skillboard" code="header.nav.3dskillboard"/>
                            <Breadcrumb code="header.nav.3dskillboard.premium" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="header.nav.3dskillboard.premium"/>
                <Row>
                    <Col sm={6}>
                        <h2><I18n code="skillboard.premium.prices"/></h2>
                        <ul>
                            <li><I18n code="skillboard.premium.prices.desc1"/></li>
                            <li><I18n code="skillboard.premium.prices.desc2"/></li>
                            <li><I18n code="skillboard.premium.prices.desc5"/></li>
                            <li><I18n code="skillboard.premium.prices.desc3"/></li>
                            <li><I18n code="skillboard.premium.prices.desc4"/></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col smOffset={3} sm={6}>
                        <Button block bsSize="large" bsStyle="success" href={linkUtil.getLink("skillboard/premium/buy")}>
                            <I18n code="skillboard.premium.buynow" />
                        </Button>
                    </Col>
                </Row>
                <hr/><br/>
                <SkillBoardFeatureCompare />
                <br/><hr/>
                <Row>
                    <Col xs={12}>
                        <h2><I18n code="skillboard.premium.header"/></h2>
                    </Col>
                </Row>

                <AboFeatureDesc type="S" imgSrc={imagesStat} apple={"true"} android={"true"} dir="H"
                                descArray={["skillboard.premium.S.desc1",
                                 "skillboard.premium.S.desc2",
                                 "skillboard.premium.S.desc3"]}
                                links={[{url: "https://3dturnier.freshdesk.com/support/solutions/articles/42000060537", desc:"home.panel.skillboard.link"}]}/>
                <hr/>
                <AboFeatureDesc type="G" imgSrc={imagesGPS} apple={"true"} android={"true"} web={"true"} dir="V"
                                descArray={["skillboard.premium.G.desc1",
                                 "skillboard.premium.G.desc2",
                                 "skillboard.premium.G.desc3",
                                 "skillboard.premium.G.desc4",
                                 "skillboard.premium.G.desc5",
                                 "skillboard.premium.G.desc6"]}
                                links={[{url: "https://3dturnier.freshdesk.com/support/solutions/articles/42000060536", desc:"home.panel.skillboard.link"}]}/>
                <hr/>
                <AboFeatureDesc type="K" imgSrc={imagesKillImage} apple={"true"} android={"true"} dir="V"
                                descArray={["skillboard.premium.K.desc1",
                                    "skillboard.premium.K.desc2"]}
                                links={[{url: "https://3dturnier.freshdesk.com/support/solutions/articles/42000091439", desc:"home.panel.skillboard.link"}]}/>
                <hr/>
                <AboFeatureDesc type="R" imgSrc={null} apple={"true"} android={"true"}  dir="V" web={"true"}
                             descArray={["skillboard.premium.R.desc1"]}/>
                <hr/>
                <h3><I18n code="skillboard.premium.next"/></h3>
                <AboFeatureDesc type="Z" imgSrc={imagesTarget} apple={"Q2 2022"} android={"true"} dir="V"
                                descArray={["skillboard.premium.Z.desc1",
                                 "skillboard.premium.Z.desc2",
                                 "skillboard.premium.Z.desc3"]}
                                links={[{url: "https://3dturnier.freshdesk.com/support/solutions/articles/42000060534", desc:"home.panel.skillboard.link"}]}/>
                <hr/>
                <Row>
                    <Col smOffset={3} sm={6}>
                        <Button block bsSize="large" bsStyle="success" href={linkUtil.getLink("skillboard/premium/buy")}>
                            <I18n code="skillboard.premium.buynow" />
                        </Button>
                    </Col>
                </Row>
            </Grid>)
};
module.exports = SkillBoardProPage;
