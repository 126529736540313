const React = require("react"),
    PropTypes = require('prop-types');
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const AboFeatureLabel = ({type}) => {
    return <FontAwesome icon={messages.get("userAbo.faIcon." + type)}>{messages.get("userAbo.feature." + type)}</FontAwesome>
};
AboFeatureLabel.propTypes = {
    type:PropTypes.string.isRequired
};
module.exports = AboFeatureLabel;