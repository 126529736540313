const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, ControlLabel} = require("react-bootstrap");
const messages = require("i18n/messages");

const _tugStatusList = ["A", "S", "F", "D"];

const TournamentUserGroupStatusSelect = ({defaultValue, preSelect, selectCallback}) =>{
    const changeOption = (event) => {
        selectCallback(event.target.value)
    };
    return (<FormGroup>
        <FormControl componentClass="select" placeholder="select"
                     defaultValue={defaultValue}
                     onChange={changeOption}>
            {
                preSelect == false ? <option value={null}>{messages.get("modal.license.choose")}</option> : null
            }
            {
                _tugStatusList.map(status => {
                    // let icon = "fas fa-" + messages.get("tournament.mmtuser.icon." + status);
                    return <option key={"tugsStat" + status} value={status}>
                            {messages.get("tournamentUser.status." + status)}
                        </option>
                })
            }
        </FormControl>
    </FormGroup>);
};
TournamentUserGroupStatusSelect.propTypes = {
    defaultValue: PropTypes.string,
    preSelect: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentUserGroupStatusSelect;
