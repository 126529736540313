const React = require("react"),
    PropTypes = require('prop-types');
const {Col,Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextBlur = require("components/widgets/TextBlur"),
	messages = require("i18n/messages");

const getRoundName = (tournamentRounds, index) => {
    if (tournamentRounds && tournamentRounds.length > index) {
        return tournamentRounds[index].get("name");
    } else {
        // TODO translate
        return "Runde " + (index + 1);
    }
};
const getRoundResult = (tournamentUser, index, tournamentRounds) => {
    const tRoundUsers = tournamentUser.getTournamentRoundUserIDs();
    const tRound = tournamentRounds != null && tournamentRounds[index] != null ? tournamentRounds[index] : null;
    if (tRoundUsers != null && tRound != null) {
        for (let i = 0; i < tRoundUsers.length; i++) {
            if (tRoundUsers[i] != null && tRoundUsers[i].getTournamentRoundID().id == tRound.id) {
                return tRoundUsers[i].getRoundPoints() + " (" + tRoundUsers[i].getRoundKillCounts2String() + ")";
            }
        }
    }
    return "---";
};
const TournamentResultTable = ({tournament, tournamentUserDic, killColumnName, tournamentRoundMap, ageType, bowType, tuSex, liveModus }) => {
    const getTableName = (tRounds) => {
        let header = "";
        if (tRounds != null && tRounds.length == 1) {
            header = tRounds[0].getName() + ": ";
        }
        if (bowType.get("code") && !liveModus) {
            header += bowType.get("code") + " - ";
        }
        if (ageType != null) {
            header +=  ageType.getCodeName() + " - ";
        }
        if ("0" == tuSex) {
            header += messages.get("domain.user.detail.sex.0");
        } else if ("1" == tuSex) {
            header += messages.get("domain.user.detail.sex.1");
        }
        return header;
    };
    const needShowDPS = tournament.getBowUnionID() != null ? tournament.getBowUnionID().isShowDPS() : false;
    const licColName = tournament.isTypeCupChild() ? tournament.getBowUnionID().getCode() + "#" : <I18n code="modal.license"/>;
    const key = tournamentUserDic.generateDicKey(bowType, ageType, tuSex);
    const groupedList = tournamentUserDic.getList(key);
    if (!groupedList) {
        // nothing to show
        return null;
    }
    const groupRoundSize = tournamentUserDic.getGroupRoundAmount(key);
    const finalGroupRoundSize = tournamentUserDic.getFinalGroupRoundAmount(key);
    let table;
    let finalTable;
    let tableName;
    let finalTableName;
    if (finalGroupRoundSize > 0) {
        // need final table
        tableName = getTableName(tournamentRoundMap.qualify);
        finalTableName = getTableName(tournamentRoundMap.finale);
    } else {
        tableName = getTableName();
    }
    let colSize = 12;
    // TODO handle different round types
    if (liveModus) {
        table =
            <LiveResultTable groupedTournamentUserList={groupedList} killColumnName={killColumnName} tournamentRounds={tournamentRoundMap.qualify}/>;
        colSize = 4;
    } else {
        // final and elimination tables
        if (finalGroupRoundSize == 1) {
            // handle final one round
            if (tournamentRoundMap.finale[0].isFinalAdd()) {
                // add final result to quali
                finalTable = <FinalAddRoundResultTable groupedTournamentUserList={tournamentUserDic.getFinalList(key)}
                                                       killColumnName={killColumnName}
                                                       finalTournamentRounds={tournamentRoundMap.finale}
                                                       tournamentRounds={tournamentRoundMap.qualify}/>
            } else {
                // only final result counts
                finalTable = <FinalZeroRoundResultTable groupedTournamentUserList={tournamentUserDic.getFinalList(key)}
                                                        killColumnName={killColumnName}/>
            }
        } else if (finalGroupRoundSize == 2) {
            // handle final two rounds
            if (tournamentRoundMap.finale[0].isFinalAdd()) {
                // add final result to quali
                finalTable = <FinalAddTwoRoundResultTable groupedTournamentUserList={tournamentUserDic.getFinalList(key)}
                                                       killColumnName={killColumnName}
                                                       finalTournamentRounds={tournamentRoundMap.finale}
                                                       tournamentRounds={tournamentRoundMap.qualify}/>
            } else {
                // only final result counts
                finalTable = <FinalZeroTwoRoundResultTable groupedTournamentUserList={tournamentUserDic.getFinalList(key)}
                                                        killColumnName={killColumnName}
                                                        finalTournamentRounds={tournamentRoundMap.finale}/>
            }
        }
        // simple and qualification tables
        if (groupRoundSize <= 1) {
            table = <SingleRoundResultTable groupedTournamentUserList={groupedList} killColumnName={killColumnName}
                                            licColName={licColName} needShowDPS={needShowDPS}
                                            tournamentRounds={tournamentRoundMap.qualify}/>
        } else if (groupRoundSize == 2) {
            table = <DoubleRoundResultTable groupedTournamentUserList={groupedList}
                                            killColumnName={killColumnName}
                                            tournamentRounds={tournamentRoundMap.qualify}/>
        } else {
            table = <TribleRoundResultTable groupedTournamentUserList={groupedList}
                                            killColumnName={killColumnName}
                                            tournamentRounds={tournamentRoundMap.qualify}/>
        }
    }
    return (<React.Fragment>
            {
                finalTable != null ?
                    <Col md={colSize} id={"fin_"+key}>
                        <br/>
                        <h4 className="ageTitle" >{finalTableName}</h4>
                        {finalTable}
                    </Col> : null
            }
            <Col md={colSize} id={key}>
                <br/>
                <h4 className="ageTitle" >{tableName}</h4>
                {table}
            </Col>
        </React.Fragment>
    )
};
TournamentResultTable.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserDic:PropTypes.object.isRequired,
    killColumnName:PropTypes.string.isRequired,
    tournamentRoundMap:PropTypes.object,
    ageType:PropTypes.object.isRequired,
    bowType:PropTypes.object,
    tuSex:PropTypes.string.isRequired,
    liveModus:PropTypes.bool
};
const LiveResultTable = ({groupedTournamentUserList, tournamentRounds, killColumnName}) => {
    let counter = 0;
    let hidePoints = null;
    if (tournamentRounds && tournamentRounds.length > 0) {
        hidePoints = tournamentRounds[0].isHidePoints();
    }
    return (
        <Table striped>
            <TableHeaderLiveView killColumnName={killColumnName}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    if (counter <= 5) {
                        return (<LiveTableRow key={"LTR_" + tournamentUser.id}
                                              position = {counter} hidePoints={hidePoints}
                                              indexArray={[]}
                                              tournamentUser={tournamentUser}/>);
                    }
                })}
            </tbody>
        </Table>
    )
};
LiveResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    tournamentRounds:PropTypes.array.isRequired
};
const LiveTableRow = ({position, tournamentUser, hidePoints}) => {
    const dps = tournamentUser.getTUserDPS();
    const sumPoints = tournamentUser.getSumPoints();
    return (
        <tr>
            <td>{sumPoints >= 0 ? position : ""}</td>
            <td>{tournamentUser.getFullName()}<br/>{tournamentUser.getUnion() ? tournamentUser.getUnion() : null}</td>
            <td><TextBlur blur={hidePoints} text={dps + " / " + sumPoints}/></td>
            <td><TextBlur blur={hidePoints} text={tournamentUser.getKillCounts2String()}/></td>
        </tr>)
};
LiveTableRow.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    position:PropTypes.number.isRequired,
    hidePoints: PropTypes.bool
};
const FinalZeroRoundResultTable = ({groupedTournamentUserList, killColumnName}) => {
    let counter = 0;
    return (
        <Table striped>
            <TableHeaderSingleRound killColumnName={killColumnName} licColName={null} needShowDPS={false}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    return (<TournamentFinalResultTableRow key={"TFRTR_" + tournamentUser.id}
                                                           addFinale={false}
                                                           position = {counter} indexArray={[]}
                                                           tournamentUser={tournamentUser}/>);
                })}
            </tbody>
        </Table>)
};
FinalZeroRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired
};
const FinalZeroTwoRoundResultTable = ({groupedTournamentUserList, killColumnName, finalTournamentRounds}) => {
        let counter = 0;
        return (
            <Table striped>
                <TableHeaderDoubleRounds
                    tournamentRounds={finalTournamentRounds}
                    killColumnName={killColumnName}/>
                <tbody className="bowage-table">
                {
                    groupedTournamentUserList.map(tournamentUser => {
                        counter++;
                        return (<TournamentFinalResultTableRow key={"TFRTR_" + tournamentUser.id}
                                                               addFinale={false}
                                                               position = {counter} indexArray={[0,1]}
                                                               tournamentUser={tournamentUser}/>);
                    })}
                </tbody>
            </Table>
        )
};
FinalZeroTwoRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    finalTournamentRounds:PropTypes.array.isRequired
};
const FinalAddRoundResultTable = ({groupedTournamentUserList, killColumnName, tournamentRounds, finalTournamentRounds}) => {
    let counter = 0;
    const tRounds = [tournamentRounds[0],finalTournamentRounds[0]];
    return (
        <Table striped>
            <TableHeaderDoubleRounds
                tournamentRounds={tRounds}
                killColumnName={killColumnName}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                        counter++;
                        return (<TournamentFinalResultTableRow key={"TFRTR_" + tournamentUser.id}
                                                               addFinale={true}
                                                               position = {counter} indexArray={[0]}
                                                               tournamentUser={tournamentUser}/>);
                })}
            </tbody>
        </Table>
    )
};
FinalAddRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    finalTournamentRounds:PropTypes.array.isRequired,
    tournamentRounds:PropTypes.array.isRequired
};
const FinalAddTwoRoundResultTable = ({groupedTournamentUserList, killColumnName, tournamentRounds, finalTournamentRounds}) => {
    // TODO handle add two rounds
    let counter = 0;
    const tRounds = [tournamentRounds[0],finalTournamentRounds[0], finalTournamentRounds[1]];
    return (
        <Table striped>
            <TableHeaderTribleRounds tournamentRounds={tRounds} killColumnName={killColumnName}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    return (<TournamentFinalResultTableRow key={"TFRTR_" + tournamentUser.id}
                                                           addFinale={true}
                                                           position = {counter} indexArray={[0,1]}
                                                           tournamentUser={tournamentUser}/>);
                })}
            </tbody>
        </Table>
    )
};
FinalAddTwoRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    finalTournamentRounds:PropTypes.array.isRequired,
    tournamentRounds:PropTypes.array.isRequired
};
const SingleRoundResultTable = ({groupedTournamentUserList, killColumnName, tournamentRounds, licColName, needShowDPS}) => {
    const needLicense = licColName != null
    let counter = 0;
    let hidePoints = false;
    if (tournamentRounds && tournamentRounds.length > 0) {
        hidePoints = tournamentRounds[0].isHidePoints();
    }
    return (
        <Table striped>
            <TableHeaderSingleRound killColumnName={killColumnName} licColName={licColName} needShowDPS={needShowDPS}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    const tUserDPS = needShowDPS ? " / " + tournamentUser.getTUserDPS() : null;
                    return (<TournamentMultiResultTableRow key={"TMRTR_" + tournamentUser.id} position = {counter} indexArray={[]}
                                                           needLicense={needLicense} hidePoints={hidePoints}
                                                           sumPoints = {tournamentUser.getSumPoints()} dps={tUserDPS}
                                                           killCounts = {tournamentUser.getKillCounts2String()}
                                                           tournamentRounds={tournamentRounds}
                                                           tournamentUser={tournamentUser}/> );
                })}
            </tbody>
        </Table>
    )
};
SingleRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    tournamentRounds:PropTypes.array.isRequired,
    licColName: PropTypes.any,
    needShowDPS: PropTypes.bool
};
const DoubleRoundResultTable = ({groupedTournamentUserList, killColumnName, tournamentRounds} ) => {
    let counter = 0;
    let hidePoints = false;
    let hidePointsArray = [false, false];
    if (tournamentRounds && tournamentRounds.length > 1) {
        hidePointsArray[0] = tournamentRounds[0].isHidePoints();
        hidePointsArray[1] = tournamentRounds[1].isHidePoints();
        hidePoints = hidePointsArray[0] || hidePointsArray[1];
    }
    return (
        <Table striped>
            <TableHeaderDoubleRounds
                tournamentRounds={tournamentRounds}
                killColumnName={killColumnName}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    return (<TournamentMultiResultTableRow key={"TMRTR_" + tournamentUser.id} position = {counter} indexArray={[0,1]}
                                                           hidePointsArray={hidePointsArray} hidePoints={hidePoints}
                                                           tournamentRounds={tournamentRounds}
                                                           needLicense={false}
                                                           sumPoints = {tournamentUser.getSumPoints()}
                                                           killCounts = {tournamentUser.getKillCounts2String()}
                                                           tournamentUser={tournamentUser}/>);
                })}
            </tbody>
        </Table>
    )
};
DoubleRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    tournamentRounds:PropTypes.array.isRequired,
};
const TribleRoundResultTable = ({groupedTournamentUserList, killColumnName, tournamentRounds}) => {
        let counter = 0;
        let hidePoints = false;
        let hidePointsArray = [false, false, false];
        if (tournamentRounds && tournamentRounds.length > 2) {
            hidePointsArray[0] = tournamentRounds[0].isHidePoints();
            hidePointsArray[1] = tournamentRounds[1].isHidePoints();
            hidePointsArray[2] = tournamentRounds[2].isHidePoints();
            hidePoints = hidePointsArray[0] || hidePointsArray[1] || hidePointsArray[2];
        }
        return (
            <Table striped>
                <TableHeaderTribleRounds
                    tournamentRounds={tournamentRounds}
                    killColumnName={killColumnName}/>
                <tbody className="bowage-table">
                {
                    groupedTournamentUserList.map(tournamentUser => {
                        counter++;
                        return (<TournamentMultiResultTableRow key={"TMRTR_" + tournamentUser.id} position = {counter} indexArray={[0,1,2]}
                                                               hidePointsArray={hidePointsArray} hidePoints={hidePoints}
                                                               tournamentRounds={tournamentRounds}
                                                               needLicense={false}
                                                               sumPoints = {tournamentUser.getSumPoints()}
                                                               killCounts = {tournamentUser.getKillCounts2String()}
                                                               tournamentUser={tournamentUser}/>);
                    })}
                </tbody>
            </Table>
        )
};
TribleRoundResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired,
    tournamentRounds:PropTypes.array.isRequired,
};
const TournamentMultiResultTableRow = ({tournamentUser, tournamentRounds, sumPoints, dps, hidePoints, killCounts, position, indexArray, hidePointsArray, needLicense }) => {
    return (
        <tr>
            <td>
                {sumPoints >= 0 ? position : ""}
            </td>
            {
                needLicense ? <td>{tournamentUser.getLicenseNumber()}</td> : null
            }
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getUnion()}</td>
            {
                indexArray.map(index => {
                    return <td><TextBlur blur={hidePoints} text={getRoundResult(tournamentUser, index, tournamentRounds)}/></td>
                })
            }
            <td><TextBlur blur={hidePoints} text={sumPoints}>{dps}</TextBlur></td>
            <td><TextBlur blur={hidePoints} text={killCounts}/></td>
        </tr>)
};
TournamentMultiResultTableRow.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    tournamentRounds:PropTypes.array,
    position:PropTypes.number.isRequired,
    sumPoints:PropTypes.any.isRequired,
    killCounts:PropTypes.string.isRequired,
    indexArray:PropTypes.array.isRequired,
    needLicense: PropTypes.bool,
    hidePoints: PropTypes.bool,
    hidePointsArray: PropTypes.array
};
const TournamentFinalResultTableRow = ({tournamentUser, position, indexArray, addFinale }) => {
    const getPreRoundResult = () => {
        return tournamentUser.getSumPoints() + " (" + tournamentUser.getKillCounts2String() + ")";
    };
    const getFinalRoundResult = (index) => {
        const tRoundUsers = tournamentUser.getFinalTournamentRoundUserIDs();
        if (tRoundUsers && tRoundUsers[index]) {
            return tRoundUsers[index].getRoundPoints() + " (" + tRoundUsers[index].getRoundKillCounts2String() + ")";
        } else {
            return "---";
        }
    };
    const sumPoints = tournamentUser.getUserDictSortSum(true, addFinale);
    return (
        <tr>
            <td>
                {sumPoints >= 0 ? position : ""}
            </td>
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getUnion()}</td>
            {
                addFinale ?
                    <td>{getPreRoundResult()}</td> : null
            }

            {
                indexArray.map(index =>{
                    return <td>{getFinalRoundResult(index)}</td>
                })
            }
            <td>{sumPoints}</td>
            {
                addFinale ?
                    <td>{tournamentUser.getFinalAddKillCount2String()}</td>
                    :
                    <td>{tournamentUser.getFinalKillCounts2String()}</td>
            }
        </tr>)
};
TournamentFinalResultTableRow.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    position:PropTypes.number.isRequired,
    addFinale:PropTypes.bool.isRequired,
    indexArray:PropTypes.array.isRequired
};
const TableHeaderSingleRound = ({killColumnName, licColName, needShowDPS}) => {
    return (
        <thead className="bowage-head">
        <tr>
            <th className="col-md-1"><I18n code="tournament.table.column.position"/></th>
            {
                licColName != null ?
                    [
                        <th key="COL_TR_LIC" className="col-md-1 printCol-40">{licColName}</th>,
                        <th key="COL_TR_NAME" className="col-md-4 printCol-110"><I18n code="tournament.table.column.name"/></th>
                    ]
                    :
                    <th className="col-md-4 printCol-155"><I18n code="tournament.table.column.name"/></th>
            }
            <th className="col-md-3 printCol-150"><I18n code="tournament.table.column.union"/></th>
            <th className="col-md-2 printCol-45">
                <I18n code="tournament.table.column.points"/>
                { needShowDPS ?  <span>{" / "}<I18n code="tournament.table.column.dps"/></span> : null}

            </th>
            <th className="col-md-2 printCol-55">{killColumnName}</th>
        </tr>
        </thead>
    )
};
TableHeaderSingleRound.propTypes = {
    killColumnName:PropTypes.string.isRequired,
    licColName: PropTypes.any,
    needShowDPS: PropTypes.bool
};
const TableHeaderDoubleRounds = ({tournamentRounds, killColumnName}) => {
    return (
        <thead className="bowage-head">
        <tr>
            <th className="col-md-1"><I18n code="tournament.table.column.position"/></th>
            <th className="col-md-3 printCol-85"><I18n code="tournament.table.column.name"/></th>
            <th className="col-md-2 printCol-90"><I18n code="tournament.table.column.union"/></th>
            <th className="col-md-2 printCol-65">{getRoundName(tournamentRounds,0)}</th>
            <th className="col-md-2 printCol-65">{getRoundName(tournamentRounds,1)}</th>
            <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.points"/></th>
            <th className="col-md-1 printCol-55">{killColumnName}</th>
        </tr>
        </thead>
    )
};
TableHeaderDoubleRounds.propTypes = {
    tournamentRounds:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired
};
const TableHeaderTribleRounds = ({tournamentRounds, killColumnName}) => {
    return (
        <thead className="bowage-head">
        <tr>
            <th className="col-md-1"><I18n code="tournament.table.column.position"/></th>
            <th className="col-md-2 printCol-55"><I18n code="tournament.table.column.name"/></th>
            <th className="col-md-1 printCol-55"><I18n code="tournament.table.column.union"/></th>
            <th className="col-md-2 printCol-65">{getRoundName(tournamentRounds, 0)}</th>
            <th className="col-md-2 printCol-65">{getRoundName(tournamentRounds, 1)}</th>
            <th className="col-md-2 printCol-65">{getRoundName(tournamentRounds, 2)}</th>
            <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.points"/></th>
            <th className="col-md-1 printCol-55">{killColumnName}</th>
        </tr>
        </thead>
    )
};
TableHeaderTribleRounds.propTypes = {
    tournamentRounds:PropTypes.array.isRequired,
    killColumnName:PropTypes.string.isRequired
};
const TableHeaderLiveView = ({killColumnName}) => {
    return (
        // TODO test killColumnName in Live View
        <thead className="bowage-head">
        <tr>
            <th className="col-md-1">Pos</th>
            <th className="col-md-7"><I18n code="tournament.table.column.name"/></th>
            <th className="col-md-2">DPS/<I18n code="tournament.table.column.points"/></th>
            <th className="col-md-2">{}</th>
        </tr>
        </thead>
    )
};
TableHeaderLiveView.propTypes = {
    killColumnName:PropTypes.string.isRequired
};
module.exports = TournamentResultTable;