const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip} = require("react-bootstrap");
const LoadingCircle = require("components/widgets/LoadingCircle");
const FontAwesome = require("components/widgets/FontAwesome");

const SubmitButton = React.forwardRef(({reference, bsSize, bsStyle, block, disabled, icon, text, tooltip, onClick, confirm, autoClickMS}, ref) => {
    const [loading, setLoading] = React.useState(false);
    const [confirmButtons, setConfirmButtons] = React.useState(false);

    React.useImperativeHandle(ref, () => ({
        resetLoading() {
            setLoading(false);
        }
    }))

    function handleClick(event) {
        if (confirm) {
            setConfirmButtons(true)
        } else {
            handlePropClick(event);
        }
    }
    function handlePropClick(event) {
        if (!loading) {
            setLoading(true)
            if (onClick) {
                onClick(event);
            }
        }
    }
    function handleCancel() {
        setConfirmButtons(false)
    }
    React.useEffect(() => {
        if (autoClickMS > 0) {
            setTimeout(() =>  {
                handleClick(null)
            }, autoClickMS);
        }
    }, []) // run only once
    let buttonBody = loading ? <LoadingCircle/> : <React.Fragment>{icon}{text} </React.Fragment>
    if (confirmButtons) {
        return (<span>
                <Button onClick={handlePropClick}><FontAwesome icon="check-circle"/>{text}</Button>
                <Button onClick={handleCancel}><FontAwesome icon="times-circle"/></Button>
            </span>)
    } else if (tooltip != null) {
        return(<OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
            <Button bsSize={bsSize}
                    bsStyle={bsStyle ? bsStyle : "primary"}
                    block={block}
                    disabled={disabled}
                    onClick={handleClick}>
                {buttonBody}
            </Button></OverlayTrigger>)
    } else {
        return(
            <Button bsSize={bsSize}
                    bsStyle={bsStyle ? bsStyle : "primary"}
                    block={block}
                    disabled={disabled}
                    onClick={handleClick}>
                {buttonBody}
            </Button>)
    }
})
SubmitButton.propTypes = {
    onClick:PropTypes.func.isRequired,
    autoClickMS: PropTypes.number,
    disabled:PropTypes.bool,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    tooltip:PropTypes.string,
    bsSize:PropTypes.string,
    bsStyle:PropTypes.string,
    block:PropTypes.bool,
    confirm: PropTypes.bool
};
module.exports = SubmitButton;
