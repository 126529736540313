const React = require("react"),
    PropTypes = require('prop-types');
const {InputGroup} = require("react-bootstrap");

const Typeahead = require('react-bootstrap-typeahead').Typeahead;

class DropDownSearchParseObject extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: this.props.default
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(value) {
        const objID = value[0].id;
        const length = this.props.objectList.length;
        let selObject = null;
        for (let i = 0; i < length; i++) {
            if (objID == this.props.objectList[i].id) {
                selObject = this.props.objectList[i];
                break;
            }
        }
        this.setState({
            value:selObject
        });
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(selObject);
        }
    }
    render(){
        const {addonBefore, objectList, placeholder, disabled} = this.props;
        return (
            <InputGroup style={{width: "350px"}}>
                {
                    addonBefore != null ?  <InputGroup.Addon>{addonBefore}</InputGroup.Addon> : null
                }
                <Typeahead disabled={disabled}
                           labelKey={object => object.getSelectString ? object.getSelectString() : ""}
                           emptyLabel={false}
                           multiple={false}
                           options={objectList}
                           placeholder={placeholder}
                           onChange={this.handleChange}
                />
            </InputGroup>);
    }
}
DropDownSearchParseObject.propTypes = {
    objectList:PropTypes.array.isRequired,
    default:PropTypes.object,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object,
    placeholder:PropTypes.object,
};
module.exports = DropDownSearchParseObject;
