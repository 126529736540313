const Parse = require("ParseInit");

const {Management, Transaction, TransactionSettlement, BusinessPartner, CCLog} = require("parse/_Domain");
const ConfigKeys = require("ConfigKeys");

class TransactionDao {

    startTransaction(params, ccFunctionName) {
        params.payVersion = ConfigKeys.payVersion;
        return Parse.Cloud.run(ccFunctionName, params);
    }

    loadTransaction(transactionId) {
        const query = new Parse.Query(Transaction);
        query.include(Transaction.prototype.col.parcoursID);
        query.include(Transaction.prototype.col.voucherID);
        query.include(Transaction.prototype.col.parcoursArticles);
        return query.get(transactionId);
    }

    loadTransactionCCLog(transaction) {
        const query = new Parse.Query(CCLog);
        query.equalTo(CCLog.prototype.col.transactionID, transaction);
        query.ascending(CCLog.prototype.col.createdAt);
        return query.find();
    }

    loadSettlement(id) {
        const query = new Parse.Query(TransactionSettlement);
        query.include(TransactionSettlement.prototype.col.settlementPositions);
        return query.get(id);
    }

    queryTransactions(managementID, month, year) {
        const startDate = new Date(year, month -1,1);
        const endDate = new Date(year, month ,0,23,59,59,0);
        return Parse.Cloud.run("queryTransaction",{
                managementID: managementID,
                startDate: startDate,
                endDate: endDate});
    }

    queryTransactionPositions(managementID, transactionID) {
        return Parse.Cloud.run("queryTransactionPositions", {managementID: managementID, transactionID: transactionID})
    }

    generateInvoice(transactionID) {
        return Parse.Cloud.run("generateInvoice",{
            transactionID: transactionID});
    }

    openInvoiceCheckout(transactionID) {
        return Parse.Cloud.run("openInvoiceCheckout",{
            transactionID: transactionID});
    }

    manualCheckout(transactionID) {
        return Parse.Cloud.run("manualCheckout",{
            transactionID: transactionID});
    }

    generateSettlement(settlementID) {
        return Parse.Cloud.run("generateSettlement",{
            settlementID: settlementID});
    }

    startMonthSettlement(managementID, year, month) {
        return Parse.Cloud.run("startMonthSettlement",{ payVersion: ConfigKeys.payVersion,
            managementID: managementID, year: year, month:month});
    }

    initCheckout(params) {
        return Parse.Cloud.run("initCheckout", params);
    }

    stornoTransaction(transactionId) {
        return Parse.Cloud.run("stornoTransaction",{
            transactionId: transactionId});
    }
    notifyPaymentPending(transactionID) {
        return Parse.Cloud.run("notifyPaymentPending",{
            transactionID: transactionID});
    }

    startSettlement(year, month) {
        return Parse.Cloud.run("startSettlementFCT", {
            month: month,
            year: year});
    }

    startInvoices(year, month) {
        return Parse.Cloud.run("startSettlementInvoices", {
            month: month,
            year: year});
    }

    setInvoiceNumberNewYear(year) {
        return Parse.Cloud.run("resetInvNumToNewYear", {year: year});
    }

    queryTransactionSettlements(managementID, month, year) {
        return Parse.Cloud.run("querySettlement", {
            managementID: managementID,
            month: month,
            year: year});
    }

    queryManagement(managementID) {
        const query = new Parse.Query(Management);
        query.equalTo(Management.prototype.col.status, "A");
        query.include(Management.prototype.col.businessPartnerID);
        query.include(Management.prototype.col.businessPartnerID + "." + BusinessPartner.prototype.col.addressID);
        return query.get(managementID);
    }

    querySalesTransactions(managementID, year, bClub) {
        const startDate = new Date(year,0,1);
        const endDate = new Date(year,12,0,23,59,59,0);
        const query = new Parse.Query(Transaction);
        query.exists(Transaction.prototype.col.afterPayment)
        query.exists(Transaction.prototype.col.invoiceNumber)
        query.exists(Transaction.prototype.col.invoiceDate)
        if (bClub) {
            // club abos have no parcoursOwner
            query.doesNotExist(Transaction.prototype.col.parcoursOwnerID)
        } else {
            // userAbos have fake parcoursOwner
            query.exists(Transaction.prototype.col.parcoursOwnerID)
        }

        query.equalTo(Transaction.prototype.col.managementID, managementID);
        query.descending(Transaction.prototype.col.invoiceDate);
        query.greaterThanOrEqualTo(Transaction.prototype.col.invoiceDate, startDate);
        query.lessThanOrEqualTo(Transaction.prototype.col.invoiceDate, endDate);
        query.limit(10000);
        return query.find();
    }

    queryOwnTransactions(user, year) {
        const startDate = new Date(year,0,1);
        const endDate = new Date(year,12,0,23,59,59,0);
        const query = new Parse.Query(Transaction);
        query.equalTo(Transaction.prototype.col.userID, user);
        query.include(Transaction.prototype.col.parcoursOwnerID);
        query.include(Transaction.prototype.col.parcoursID);
        query.include(Transaction.prototype.col.tournamentID);
        query.include(Transaction.prototype.col.parcoursArticles);
        query.descending(Transaction.prototype.col.createdAt);
        query.greaterThanOrEqualTo(Transaction.prototype.col.createdAt, startDate);
        query.lessThanOrEqualTo(Transaction.prototype.col.createdAt, endDate);
        query.limit(500);
        return query.find();
    }
}

module.exports = new TransactionDao();
