const alt = require("AltInit"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages");
const parseDao = require("actdao/ParseBaseDao");
const clubDao = require("actdao/ClubDao");
const managementDao = require("actdao/ManagementDao");
const notificationActions = require("components/notification/NotificationActions"),
paymentElementsActions = require("actions/PaymentElementsActions");
const FileDownloadUtility =  require("util/FileDownloadUtility");

class ClubInvoiceActions {

    loadClubInvoices(club, year) {
        clubDao.loadClubInvoices(club, year)
            .then(invoices => {
                this.updateYear(year);
                this.updateClubInvoices(invoices);
            })
            .catch(error => {
                error.trace = "CINA.loadClubInvoices";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveClubInvoice(clubInvoice) {
        parseDao.saveObject(clubInvoice)
            .then(sClubInvoice => {
                this.updateClubInvoice(sClubInvoice);
            })
            .catch(error => {
                error.trace = "CINA.saveClubInvoice";
                notificationActions.parseError(error);
            });
        return {};
    }

    createClubInvoice(editInvoice, management) {
        parseDao.saveObject(editInvoice)
            .then(sInvoice => {
                console.log("Created " + sInvoice.id);
                if (management != null) {
                    managementDao.addClubInvoice(management, sInvoice)
                        .finally(_=> {
                            // redirect to club payment page
                            window.location = linkUtil.getLink("club/" + editInvoice.getClubID().id + "/payinvoice/" + sInvoice.id);
                        })
                } else {
                    // redirect to club payment page
                    window.location = linkUtil.getLink("club/" + editInvoice.getClubID().id + "/payinvoice/" + sInvoice.id);
                }

            })
            .catch(error => {
                error.trace ="CINA.createCLInv";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryInvoiceCheckout(clubInvoiceID) {
        clubDao.loadClubInvoice(clubInvoiceID)
            .then(invoice => {
                this.updateCheckoutInvoice(invoice);
                paymentElementsActions.updateCheckoutInvoice(invoice);
            })
            .catch(error => {
                error.trace = "CINA.loadCheckoutInvoice";
                notificationActions.parseError(error);
            });
        return {};

    }

    deleteInvoice(clubInvoice) {
        if (clubInvoice.getParcoursArticles()) {
            parseDao.deleteAll(clubInvoice.getParcoursArticles())
        }
        parseDao.deleteObject(clubInvoice)
            .then(clubEvent => {
                this.removeClubInvoice(clubInvoice);
            })
            .catch(error => {
                error.trace = "CINA.deleteInvoice";
                notificationActions.parseError(error);
            });
        return {};
    }
    queryAllManagments(callback) {
        managementDao.find()
            .then(managements => {
                callback(managements)
            })
            .catch(error => {
                error.trace = "CINA.queryM";
                notificationActions.parseError(error);
            });
        return {}
    }
    markInvoicePaid(clubInvoice) {
        clubDao.markClubInvoiceAsPaid(clubInvoice)
            .then(_ => {
                // wait 2 seconds -> afterHandling of transaction
                setTimeout(_=> {
                    parseDao.fetchObject(clubInvoice)
                        .then(fClubInvoice => {
                            this.updateClubInvoice(fClubInvoice);
                        });
                }, 2000);
            })
            .catch(error => {
                error.trace = "CINA.mInvPaid";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchClubInvoices(searchParams) {
        return searchParams;
    }

    exportAllInvoices(invoices) {
        // export
        var csvData = [];
        var header = [];
        header.push("objectId");
        header.push(messages.get("tournament.table.column.date"));
        header.push(messages.get("tournament.table.column.number"));
        header.push(messages.get("tournament.table.column.text"));
        header.push(messages.get("club.event.create.message.note"));
        header.push(messages.get("tournament.table.column.type"));
        header.push(messages.get("club.invoice.amount.in.true"));
        header.push(messages.get("club.invoice.amount.in.false"));
        header.push(messages.get("modal.entry.attachment"));
        csvData.push(header.join(';'));

        invoices.map(invoice => {
            var row = [];
            row.push(invoice.id);
            row.push(invoice.getInvoiceDate());
            row.push(invoice.getInvoiceNumber());
            row.push(invoice.getName());
            row.push(invoice.getDescription());
            row.push(invoice.getType());
            if (invoice.getInOrOut() == true) {
                row.push(invoice.getAmount().toFixed(2));
                row.push("");
            } else {
                row.push("");
                row.push(invoice.getAmount().toFixed(2));
            }
            if (invoice.getDocument()) {
                row.push(invoice.getDocument().url());
            } else {
                row.push("");
            }
            csvData.push(row.join(';'));
        });
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "invoice_export.csv" );
        return {};
    }

    reloadInvoices() {
        return {}
    }

    updateYear(year) {
        return year;
    }

    removeClubInvoice(clubInvoice) {
        return clubInvoice;
    }

    updateClubInvoices(invoices) {
        return invoices;
    }
    updateClubInvoice(invoice) {
        return invoice;
    }
    updateCheckoutInvoice(invoice) {
        return invoice;
    }
}
module.exports = alt.createActions(ClubInvoiceActions);
