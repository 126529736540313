const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");
const HRMargin = ({needRow}) => {
    const hr = (<Col xs={12}>
        <br/><br/>
        <hr/>
        <br/><br/>
    </Col>)
    if (needRow) {
        return <Row>
            {hr}
        </Row>
    } else {
        return hr
    }
};
HRMargin.propTypes = {
    needRow: PropTypes.bool
};
module.exports = HRMargin;
