var Parse = require("ParseInit");

var ParcoursTargetLog = Parse.Object.extend("parcoursTargetLog", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        index: "index",
        onlineID: "onlineID",
        group: "group",
        coordinates: "coordinates",
        coordPegs: "coordPegs",
        imageUrl: "imageUrl",
        targetCatID: "targetCatID",
        editor: "editor",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt"
    },
    getName() {
        return this.get(this.col.name)
    },
    getGroup() {
        return this.get(this.col.group)
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    getIndex() {
        return this.get(this.col.index)
    },
    getOnlineID() {
        return this.get(this.col.onlineID)
    },
    getTargetCatalogID() {
        return this.get(this.col.targetCatID)
    },
    getCoordinates() {
        return this.get(this.col.coordinates)
    },
    getReadableCoordinates() {
        const coords = this.getCoordinates();
        if (coords) {
            return coords._latitude + "," + coords._longitude;
        } else {
            return null;
        }
    },
    getCoordinatesPegs() {
        return this.get(this.col.coordPegs)
    },
    getReadableCoordinatesPegs() {
        let coordArray = null;
        const coords = this.getCoordinatesPegs();
        if (coords) {
            coordArray = [];
            for (let i = 0; i < coords.length; i++) {
                coordArray.push(coords[i]._latitude + "," + coords[i]._longitude)
            }
        }
        return coordArray;
    },
    getImageUrl() {
        return this.get(this.col.imageUrl)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = ParcoursTargetLog;