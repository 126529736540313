var alt = require("AltInit");

var actions = require("actions/ParcoursMgmtActions");

class ParcoursMgmtStore {
    constructor() {
        this.ownerParcoursDic = null;
        this.loading = true;
        this.managements = null;
        this.bindListeners({
            updateParcoursDic: actions.updateParcoursDic,
            updateManagements: actions.updateManagements,
            updateSavedObject: actions.updateSavedObject
        });
    }

    updateParcoursDic(pOwnerParcoursDic) {
        this.loading = false;
        this.ownerParcoursDic = pOwnerParcoursDic;
    }


    updateManagements(pManagements) {
        this.managements = pManagements;
    }

    updateSavedObject(savedObject) {

    }
}

module.exports = alt.createStore(ParcoursMgmtStore);
