const Parse = require("ParseInit");

const TournamentCupOption = Parse.Object.extend("TournamentCupOption", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        objectId: "objectId",
        status: "status",
        minCupResults: "minCupResults",
        cupPoints: "cupPoints",
        baseTargets: "baseTargets",
        combineTConfigAgeIDs: "combineTConfigAgeIDs",
        tConfigCombinedAgeID: "tConfigCombinedAgeID",
        attendFinal: "attendFinal",
        cupGroups:"cupGroups", // Array of string to group cup children together
        updatedAt: "updatedAt",
    },
    payStatus: {
        active: "A",
        inactive: "I"
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    hasAttendFinal() {
        return this.get(this.col.attendFinal)
    },
    setAttendFinal(attend) {
        this.set(this.col.attendFinal, attend)
    },
    hasCombineAge() {
        return this.getCombineTConfigAgeIDs() != null && this.getTConfigCombinedAgeID() != null;
    },
    getTConfigCombinedAgeID() {
        return this.get(this.col.tConfigCombinedAgeID)
    },
    setTConfigCombinedAgeID(tConfigAge) {
        return this.set(this.col.tConfigCombinedAgeID, tConfigAge)
    },
    getCombineTConfigAgeIDs() {
        return this.get(this.col.combineTConfigAgeIDs)
    },
    setCombineTConfigAgeIDs(fullTypeList, selAgeTypes) {
        let typeArray = [];
        fullTypeList.map(type => {
            if (selAgeTypes[type.id].selected) {
                typeArray.push(type);
            }
        });
        if (typeArray.length > 0) {
            this.set(this.col.combineTConfigAgeIDs, typeArray)
        } else {
            this.unset(this.col.combineTConfigAgeIDs)
        }
    },
    getMinCupResults() {
        return this.get(this.col.minCupResults)
    },
    setMinCupResults(minCupResults) {
        return this.set(this.col.minCupResults, minCupResults)
    },
    getBaseTargets() {
        return this.get(this.col.baseTargets);
    },
    setBaseTargets(targetAmount) {
        return this.set(this.col.baseTargets, targetAmount);
    },
    getCupGroups() {
        return this.get(this.col.cupGroups)
    },
    setCupGroups(cupGroupArray) {
        return this.set(this.col.cupGroups, cupGroupArray)
    },
    getCupPoints() {
        return this.get(this.col.cupPoints)
    },
    getCupPointsString() {
        if (this.getCupPoints()) {
            return this.getCupPoints().join();
        }
        return null
    },
    setCupPoints(cupPointArray) {
        return this.set(this.col.cupPoints, cupPointArray)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TournamentCupOption;
