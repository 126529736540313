const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton"),
    FontAwesome = require("components/widgets/FontAwesome");
const linkUtil = require("linkUtil");

const TournamentMMTUserButton  = ({tournament, block, disabled, style, bsStyle, bsSize}) => {
    return (
        <LinkButton
            style={style}
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            bsSize={bsSize}
            label="tournament.button.menu.mmtu"
            symbol={<FontAwesome icon="address-book"/>}
            href={linkUtil.getLink("/tournament/" + tournament.id + "/mmtuser")}/>
    )
};
TournamentMMTUserButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
    bsSize:PropTypes.string,
    style:PropTypes.object
};
module.exports = TournamentMMTUserButton;