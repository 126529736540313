const React = require("react"),
    PropTypes = require('prop-types');
const ListUnstyled = require("components/widgets/ListUnstyled"),
    FontAwesome = require("components/widgets/FontAwesome");

const ShareButton = ({url}) => {
    return (
        <ListUnstyled inline>
            <li>
                <a onClick={function() {
                    window.open('https://www.facebook.com/dialog/share?app_id=199127313825608&display=popup&href=' + url + '&redirect_uri=' + url)
                }}><FontAwesome icon={["fab", "facebook"]} size={"2x"}/></a>
            </li>
            <li>
                <a onClick={function () {
                    window.open('https://www.linkedin.com/cws/share?url=' + url)
                }}><FontAwesome icon={["fab", "linkedin"]} size={"2x"}/></a>
            </li>
        </ListUnstyled>)
};
ShareButton.propTypes = {
    url:PropTypes.string.isRequired
};
module.exports = ShareButton;
