const alt = require("AltInit"),
    linkUtil = require("linkUtil");
const dao = require("actdao/TransactionDao");
const {TransactionSettlement} = require("parse/_Domain");
const parseDao = require("actdao/ParseBaseDao");
const userDao = require("actdao/UserDao"),
    userActions = require("actions/UserActions");
const notificationActions = require("components/notification/NotificationActions");
const FileDownloadUtility =  require("util/FileDownloadUtility");
class TransactionActions {

    startTransaction(params, ccFunctionName) {
        dao.startTransaction(params, ccFunctionName)
            .then(transactionId => {
                console.log("initialized transaction " + ccFunctionName);
                params.transactionID = transactionId
                this.initTransactionCheckout(params);
            })
            .catch(error => {
                console.log("error transaction " + error.message);
                error.trace = "TRAA.startTrans";
                notificationActions.parseError(error);
            });
        return {};
    }

    startPendingTransaction(params, ccFunctionName) {
        dao.startTransaction(params, ccFunctionName)
            .then(transactionId => {
                console.log("pending transaction " + ccFunctionName);
                // create invoice
                dao.openInvoiceCheckout(transactionId)
                    .then(responseUrl => {
                        // goto status page
                        window.location = responseUrl;
                    })
                    .catch(error => {
                        error.trace = "TRAA.genPendingInv";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                console.log("error pending transaction " + error.message);
                error.trace = "TRAA.startPendTrans";
                notificationActions.parseError(error);
            });
        return {};
    }

    initTransactionCheckout(params) {
        dao.initCheckout(params)
            .then(responseUrl => {
                window.location = responseUrl;
            })
            .catch(error => {
                error.trace = "TRAA.initCheckout";
                notificationActions.parseError(error);
            });
        return {};
    }

    stornoTransaction(transactionId) {
        dao.stornoTransaction(transactionId)
            .then(stornoTransactionID => {
                notificationActions.success("Storno transaction(" + stornoTransactionID + ") created. Generating pdf invoice ... ")
                // window.location = responseUrl;
                dao.generateInvoice(stornoTransactionID)
                    .then(responseUrl => {
                        window.open(responseUrl,'_blank');
                    })
                    .catch(error => {
                        error.trace = "TRAA.genInv";
                        notificationActions.parseError(error);
                    });
            })
            .catch(error => {
                error.trace = "TRAA.storno";
                notificationActions.parseError(error);
            });
        return {};
    }

    notifyPaymentPending(transactionID) {
        dao.notifyPaymentPending(transactionID)
            .then(responseUrl => {
                window.open(responseUrl,'_self');
            })
            .catch(error => {
                error.trace = "TRAA.notifyPending";
                notificationActions.parseError(error);
            });
    }

    loadTransaction(transactionId, loadCCLog) {
        dao.loadTransaction(transactionId)
            .then(transaction => {
                this.updateTransaction(transaction);
                if (loadCCLog) {
                    this.loadTransactionCCLog(transaction);
                }
            })
            .catch(error => {
                error.trace = "TRAA.qryTransaction";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadTransactionCCLog(transaction) {
        dao.loadTransactionCCLog(transaction)
            .then(ccLogs => {
                this.updateCCLogs(ccLogs);
            })
            .catch(error => {
                error.trace = "TRAA.qryTransactionCCLog";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadSettlement(id) {
        dao.loadSettlement(id)
            .then(settlement => {
                this.updateSettlement(settlement);
            })
            .catch(error => {
                error.trace = "TRAA.qrySettlement";
                notificationActions.parseError(error);
            });
        return {}
    }

    generateInvoice(transactionID) {
        dao.generateInvoice(transactionID)
            .then(responseUrl => {
                window.open(responseUrl,'_blank');
            })
            .catch(error => {
                error.trace = "TRAA.genInv";
                notificationActions.parseError(error);
            });
        return {};
    }

    manualCheckout(transactionID) {
        dao.manualCheckout(transactionID)
            .then(responseUrl => {
                window.open(responseUrl,'_blank');
            })
            .catch(error => {
                error.trace = "TRAA.genInv";
                notificationActions.parseError(error);
            });
        return {};
    }

    createEmptyUserDetail(user, userDetail, callback, cbError) {
        if (user) {
            if (userDetail != null) {
                if (userDetail.getAddressID() != null) {
                    callback(userDetail);
                } else {
                    userDao.createEmptyUserAddress(user, userDetail)
                        .then(usrDetail => {
                            callback(usrDetail);
                        })
                        .catch(error => {
                            error.trace = "TRAA.createEmptyUserAddress";
                            cbError(error);
                        });
                }
            } else {
                // create userDetail and address
                userDao.createEmptyUserDetail(user)
                    .then(usrDetail => {
                        user.set("userDetailID", usrDetail);
                        parseDao.saveObject(user)
                            .then(sUser => {
                                callback(usrDetail);
                                userActions.updateUser(sUser);
                            })
                            .catch(error => {
                                error.trace = "TRAA.createEmptyUserDetail.saveObject";
                                cbError(error);
                            });
                    })
                    .catch(error => {
                        error.trace = "TRAA.createEmptyUserDetail";
                        cbError(error);
                    });
            }
        }
        return {};
    }

    exportTransactionsCSV(transactions, transactionsPositions, addInvalidPayments) {
        const csvData = [];
        const header = [];
        header.push("ID");
        header.push("Datum");
        header.push("Uhrzeit");
        header.push("Name");
        header.push("Ort");
        header.push("Preis");
        header.push("Art");
        header.push("Parcours");
        header.push("TurnierId");
        header.push("RechnungsNr");
        header.push("Status");
        csvData.push(header.join(';'));
        transactions.map(transaction => {
            if (transaction.isStatusACK() || addInvalidPayments) {
                const rowTr = [];
                rowTr.push(transaction.id)
                rowTr.push(transaction.getTransactionDate());
                rowTr.push(transaction.getTransactionTime());
                rowTr.push(transaction.getFullName());
                rowTr.push(transaction.getZipPlace());
                rowTr.push(transaction.getPrice());
                rowTr.push(transaction.getBrand());
                if (transaction.getParcours()) {
                    rowTr.push(transaction.getParcours().getOnlineID());
                } else {
                    rowTr.push("-");
                }
                if (transaction.getTournamentID()) {
                    rowTr.push(transaction.getTournamentID().id);
                } else {
                    rowTr.push("-");
                }
                if (transaction.getInvoiceNumber()) {
                    rowTr.push(transaction.getInvoiceNumber())
                } else {
                    rowTr.push("-");
                }
                rowTr.push(transaction.getStatus());
                csvData.push(rowTr.join(';'));
            }
        })
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "transaction_export.csv" );
        return {};
    }

    saveUserDetail(userDetail, callback) {
        parseDao.saveObject(userDetail)
            .then(sUserDetail => {
                callback(sUserDetail);
                userActions.updateUserDetail(sUserDetail);
            })
            .catch(error => {
                error.trace = "TRAA.saveUserDetail";
                notificationActions.parseError(error);
            });
        return {};
    }

    showTransactionPage(managementID, month, year) {
        this.queryManagement(managementID);
        this.queryTransactions(managementID, month, year);
        this.queryTransactionSettlements(managementID, month, year);
        return {};
    }

    showTransactionMonth(managementID, month, year) {
        this.queryTransactions(managementID, month, year);
        this.queryTransactionSettlements(managementID, month, year);
        return {};
    }


    queryTransactions(managementID, month, year) {
        dao.queryTransactions(managementID, month, year)
            .then(transResult => {
                this.updateTransactions(transResult);
            })
            .catch(error => {
                error.trace = "TRAA.queryTrans";
                notificationActions.parseError(error)
            });
        return {};
    }


    queryTransactionPositions(managementID, transactionID) {
        dao.queryTransactionPositions(managementID, transactionID)
            .then(positions => {
                this.updateTransactionPositions(transactionID, positions)
            })
            .catch(error => {
                error.trace = "TRAA.queryTransPosi";
                notificationActions.parseError(error)
            });
        return {};
    }

    queryTransactionSettlements(managementID, month, year) {
        dao.queryTransactionSettlements(managementID, month, year)
            .then(settlement => {
                if (settlement instanceof TransactionSettlement) {
                    this.updateSettlement(settlement);
                } else {
                    this.updateSettlement(null);
                }
            })
            .catch(error => {
                error.trace = "TRAA.querySettle";
                notificationActions.parseError(error)
            });
        return {};
    }

    queryManagement(managementID) {
        dao.queryManagement(managementID)
            .then(management => {
                this.updateManagement(management);
            })
            .catch(error => {
                error.trace = "TRAA.queryMgmt";
                notificationActions.parseError(error)
            });
        return {};
    }

    queryMainEditorManagement(user) {
        dao.queryMainEditorManagement(user)
            .then(pManagements => {
                this.updateManagements(pManagements);
            })
            .catch(error => {
                error.trace = "TRAA.queryMainEditMgmt";
                notificationActions.parseError(error);
            });
        return {};
    };

    queryInvolvedManagement(user) {
        dao.queryInvolvedManagement(user)
            .then(pManagements => {
                this.updateManagements(pManagements);
            })
            .catch(error => {
                error.trace = "TRAA.queryInvMgmt";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTransaction(transaction) {
        return transaction;
    };
    updateCCLogs(ccLogs) {
        return ccLogs;
    };
    updateTransactions(transaction) {
        return transaction;
    };
    updateTransactionPositions(transactionID, positions) {
        return {transactionID, positions}
    }
    updateManagement(management) {
        return management;
    };
    updateSettlement(settlement) {
        return settlement;
    };

}

module.exports = alt.createActions(TransactionActions);
