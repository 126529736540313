const React = require("react"),
    PropTypes = require('prop-types');
const { Row, Col, Alert, Modal, Button, InputGroup, FormGroup, FormControl} = require("react-bootstrap");
const DropDownParseObject = require("components/form/DropDownParseObject"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TextCenter = require("components/widgets/TextCenter"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading");
const messages = require("i18n/messages");
const tournamentActions = require("actions/TournamentManagerActions");

class TournamentOptionSelection extends React.Component {
    constructor(props) {
        super(props);
        this.refTOptelModal = React.createRef();

        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClear = this.onClear.bind(this);

        this.state = {
            optionList: null
        }
    }
    getDefaultValue() {
        if (this.props.tournamentOption) {
            return this.props.tournamentOption.getSelectString();
        }
        return messages.get("tournament.manager.tconfig.option")
    }
    onSearch() {
        if (this.state.optionList == null) {
            tournamentActions.queryTournamentOptions((optionList) => {
                this.setState({optionList: optionList})
            });
        }
        this.refTOptelModal.current.open();
    }
    onClear() {
        if (this.props.onAfterChange) {
            this.props.onAfterChange(null);
        }
    }
    render() {
        const {tournamentOption, onAfterChange} = this.props;
        return (
            <ErrorBoundary>
                <FormGroup>
                    <InputGroup>
                        <SelectionModal ref={this.refTOptelModal}
                                        tournamentOption={tournamentOption}
                                        onAfterChange={onAfterChange}
                                        optionList={this.state.optionList}/>
                        <FormControl disabled={true} value={this.getDefaultValue()}/>
                        <InputGroup.Button>
                            {
                                tournamentOption ? <Button onClick={this.onClear}>
                                    <FontAwesome icon="times"/>
                                </Button> : null
                            }

                            <Button onClick={this.onSearch}>
                                <FontAwesome icon="search"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </ErrorBoundary>)

    }
}
TournamentOptionSelection.propTypes = {
    onAfterChange:PropTypes.func.isRequired,
    tournamentOption: PropTypes.object
};
class SelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error:null});
    }
    open() {
        this.setState({showModal: true});
    }
    handleChange(option) {
        if (option && this.props.onAfterChange) {
            this.props.onAfterChange(option);
            this.close();
        }
    }
    render() {
        const {tournamentOption, optionList} = this.props;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.tconfig.option"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            {
                                this.state.showModal ?
                                    <Col sm={12}>
                                        <I18n code="tournament.manager.tConfig.optionSelection"/>
                                        <br/><br/>
                                        {
                                            optionList == null ?
                                                <Loading/>
                                                :
                                                <DropDownParseObject default={tournamentOption}
                                                                     placeholder={<option key={"nullkey"}
                                                                                          value={"null"}>{messages.get("tournament.manager.tconfig.option")}</option>}
                                                                     onAfterChange={this.handleChange}
                                                                     objectList={optionList}/>
                                        }
                                    </Col> : null
                            }
                        </Row>
                    </Modal.Body>
                </form>
            </Modal>
        )
    }
}
SelectionModal.propTypes = {
    onAfterChange:PropTypes.func.isRequired,
    tournamentOption: PropTypes.object,
    optionList: PropTypes.array
};
module.exports = TournamentOptionSelection;
