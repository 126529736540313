var Parse = require("ParseInit");
var Utility = require("util/Utility");

var ParcoursRating = Parse.Object.extend("parcoursrating", {
    col: {
        ACL: "ACL",
        comment: "comment",
        createdAt: "createdAt",
        editor: "editor",
        objectId: "objectId",
        oldRating: "oldRating",
        parcoursID: "parcoursID",
        rating: "rating",
        status: "status",
        title: "title",
        updatedAt: "updatedAt",
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getComment: function () {
        return this.get(this.col.comment)
    },
    setComment: function (comment) {
        return this.set(this.col.comment, comment)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getEditor: function () {
        return this.get(this.col.editor)
    },
    setEditor: function (editor) {
        return this.set(this.col.editor, editor)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getOldRating: function () {
        return this.get(this.col.oldRating)
    },
    setOldRating: function (oldRating) {
        return this.set(this.col.oldRating, oldRating)
    },
    getParcoursID: function () {
        return this.get(this.col.parcoursID)
    },
    setParcoursID: function (parcoursID) {
        return this.set(this.col.parcoursID, parcoursID)
    },
    getRating: function () {
        return this.get(this.col.rating)
    },
    setRating: function (rating) {
        return this.set(this.col.rating, rating)
    },
    getStatus: function () {
        return this.get(this.col.status)
    },
    setStatus: function (status) {
        return this.set(this.col.status, status)
    },
    getTitle: function () {
        return this.get(this.col.title)
    },
    setTitle: function (title) {
        return this.set(this.col.title, title)
    },
    getRatingDate() {
        var date = this.getUpdatedAt();
        return Utility.getReadableDate(date);
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ParcoursRating;
