const Parse = require("ParseInit");
const UserBowArrowProfile = Parse.Object.extend("userBAProfile", {
    col: {
        name: "name",
        userID: "userID",
        status: "status",
        bowType:"bowType"
    },
    getName() {
        return this.get(this.col.name)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    getBowTypeId() {
        return this.get(this.col.bowType)
    },
    getSelectString() {
        return this.getName()
    },
});

module.exports = UserBowArrowProfile;
