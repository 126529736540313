const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const NumberInputModal = ({header, number, saveChange}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code={header}/></TextCenter>}
            buttonStyle={"default"}
            tooltip={header}
            buttonChildren={<FontAwesome icon="pencil-alt"/>}>
            <NumberInputForm number={number} saveChange={saveChange} />
        </ModalBasic>
    )
};
NumberInputModal.propTypes = {
    number: PropTypes.number,
    header: PropTypes.string,
    saveChange: PropTypes.func.isRequired
};
class NumberInputForm extends React.Component {
    constructor(props) {
        super(props);
        this.refNumber = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refNumber.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let text = this.refNumber.current.getValue();
        if (this.props.saveChange != null) {
            this.props.saveChange(parseInt(text), parseFloat(text));
        }
        closeFct();
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {number} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refNumber} default={number}
                                    valid={{maxLength: 10, check:['point4comma']}}
                                    placeholder={"Enter a number (point as comma)"}/>

                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
NumberInputForm.propTypes = {
    text: PropTypes.string,
    saveChange: PropTypes.func.isRequired
};
NumberInputForm.contextType = ModalBasicContext;
module.exports = NumberInputModal;
