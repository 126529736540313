const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    DecisionForm = require("components/form/DecisionForm"),
    DateInput = require("components/form/DateInput"),
    TimeInput = require("components/form/TimeInput"),
    ValidInput = require("components/form/ValidInput");

const Utility = require("util/Utility");
const TrainingTypeSelector = require("components/training/TrainingTypeSelector");

const {EventTraining} = require("parse/_Domain");
const trainingActions = require("actions/TrainingActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const AddTrainingModal = ({children, trainingTypes, trainingCountTypes, user}) =>  {
    return (
        <ModalBasic
            contextChildren={children}
            title={<TextCenter><I18n code="profile.training.button.enter"/></TextCenter>}
            customButton={" "}>
            <TrainingForm trainingTypes={trainingTypes} trainingCountTypes={trainingCountTypes} user={user} />
        </ModalBasic>
    )
};
AddTrainingModal.propTypes = {
    user:PropTypes.object.isRequired,
    trainingTypes:PropTypes.array,
    trainingCountTypes:PropTypes.array
};
class TrainingForm extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.refLink = React.createRef();
        this.refNote = React.createRef();
        this.refDate = React.createRef();
        this.refTime = React.createRef();
        this.refArrowAmount = React.createRef();
        this.refPoints = React.createRef();
        this.refDuration = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleCountType = this.handleCountType.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.getDefaultDate = this.getDefaultDate.bind(this);
        this.getDefaultTime = this.getDefaultTime.bind(this);
        const {object} = this.context;
        this.state = {
            mainTypeID: object ? object.getMainTrainingType() : null,
            subTypeID: object ? object.getSubTrainingType() : null,
            countType: object ? object.getCountTypeID() : null,
        }
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refDate.current.getValue(),
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {object, closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let editEventTraining = object;
        if (editEventTraining == null) {
            editEventTraining = new EventTraining();
        }
        editEventTraining.setStatus(EventTraining.prototype.status.active);
        editEventTraining.setUserID(this.props.user);
        // trainingTypes
        if (this.state.mainTypeID) {
            editEventTraining.setMainTrainingType(this.state.mainTypeID);
        } else {
            editEventTraining.unset(EventTraining.prototype.col.mainTrainingType);
        }
        if (this.state.subTypeID) {
            editEventTraining.setSubTrainingType(this.state.subTypeID);
        } else {
            editEventTraining.unset(EventTraining.prototype.col.subTrainingType);
        }
        // date and time
        const startDateParts = new Date(this.refDate.current.getValue());
        const startTimeParts = this.refTime.current.getValue().split(":");
        editEventTraining.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), startTimeParts[0], startTimeParts[1], 0, 0));

        // training duration and time
        if (this.refDuration.current.getValue()) {
            editEventTraining.setDuration(parseFloat(this.refDuration.current.getValue()));
        } else {
            editEventTraining.unset(EventTraining.prototype.col.duration);
        }
        if (this.refArrowAmount.current.getValue()) {
            editEventTraining.setArrows(parseInt(this.refArrowAmount.current.getValue()));
        } else {
            editEventTraining.unset(EventTraining.prototype.col.arrows);
        }
        if (this.state.countType != null) {
            editEventTraining.setCountTypeID(this.state.countType)
            editEventTraining.setCountTypeOnlineID(this.state.countType.getOnlineID())

            if (this.refPoints.current.getValue()) {
                editEventTraining.setSumPoints(parseInt(this.refPoints.current.getValue()));
            } else {
                editEventTraining.unset(EventTraining.prototype.col.sumPoints);
            }
        }

        if (this.refNote.current.getValue()) {
            editEventTraining.setDescription(this.refNote.current.getValue());
        } else {
            editEventTraining.unset(EventTraining.prototype.col.description);
        }
        if (this.refLink.current.getValue()) {
            editEventTraining.setWeblink(this.refLink.current.getValue());
        } else {
            editEventTraining.unset(EventTraining.prototype.col.weblink);
        }
        trainingActions.createEventTraining(editEventTraining, success => {
            disableSubmitFct(true);
            if (success) {
                closeFct();
            }
        });
    }
    getDefault(column) {
        const {object} = this.context;
        if (object != null) {
            return object.get(column);
        }
        return "";
    }
    getDefaultDate(column) {
        const {object} = this.context;
        if (object != null) {
            return object.get(column);
        } else {
            return new Date();
        }
    }
    getDefaultTime(column) {
        const {object} = this.context;
        if (object != null) {
            return Utility.getReadableTime(object.get(column));
        } else {
            return Utility.getReadableTime(new Date());
        }
    }
    handleCountType(value) {
        this.setState({countType: value})
    }
    handleTypeChange(pMainType, pSubType) {
        this.setState({mainTypeID: pMainType, subTypeID: pSubType})
    }
    handleDelete() {
        const {object, closeFct} = this.context;
        trainingActions.deleteEventTraining(object);
        closeFct();
    }
    render() {
        const {mainTypeID, subTypeID, countType} = this.state;
        const {trainingTypes, trainingCountTypes} = this.props;
        const {object, objectType, error, submitDisabled, closeFct} = this.context;
        const showArrow = mainTypeID != null ? mainTypeID.showArrow() : false;
        const showDuration = mainTypeID != null ? mainTypeID.showDuration() : false;
        const showDPS = mainTypeID != null ? mainTypeID.isTypTournament() || mainTypeID.isTypParcours() : false;
        if (objectType === "create") {
            // form for creating new entry
            return (
                <form action="#">
                    <Row>
                        <Col xs={12}>
                            <p><strong><I18n code="profile.training.choose.type"/></strong></p>
                            <TrainingTypeSelector trainingTypes={trainingTypes} mainTypeID={mainTypeID} subTypeID={subTypeID}
                                                  handleChange={this.handleTypeChange}/>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <DateInput ref={this.refDate} default={this.getDefaultDate(EventTraining.prototype.col.date)}
                                       label={messages.get("tournament.create.date")}/>

                        </Col>
                        <Col sm={2}>
                            <TimeInput ref={this.refTime} default={this.getDefaultTime(EventTraining.prototype.col.date)}
                                        type="text"
                                        label={messages.get("tournament.create.time")}
                                        valid={{check:['isTime', 'isRequired']}}/>
                        </Col>

                        <Col sm={6}>
                            <ValidInput ref={this.refDuration} type="number" default={this.getDefault(EventTraining.prototype.col.duration)}
                                        valid={{maxLength: 10, check:['isRequired', 'isNumber', 'posNumber', 'point4comma']}}
                                        disabled={!showDuration}
                                        label={<I18n code="statistics.column.duration"> (min)</I18n>}/>
                        </Col>
                        {
                            showDPS ? <Col sm={12}>
                                <DropDownParseObject onAfterChange={this.handleCountType} label={messages.get("skillboard.tab.scores")}
                                                     default={countType}
                                                     placeholder={<option key={"nullkey"}
                                                                          value={"null"}>{messages.get("modal.license.chooseCountType")}</option>}
                                                     objectList={trainingCountTypes}/>
                            </Col> : null
                        }


                        <Col sm={6}>
                            <ValidInput ref={this.refArrowAmount} type="number" default={this.getDefault(EventTraining.prototype.col.arrows)}
                                        valid={{maxLength: 10, check:['isRequired', 'isNumber', 'posNumber']}}
                                        disabled={!showArrow}
                                        label={messages.get("tournament.table.column.arrows")}/>
                        </Col>
                        {
                            showDPS && countType != null ? <Col sm={6}>
                                <ValidInput ref={this.refPoints} type="number" default={this.getDefault(EventTraining.prototype.col.sumPoints)}
                                            valid={{maxLength: 10, check:['isRequired', 'isNumber', 'posNumber']}}
                                            label={messages.get("tournament.table.column.sumPoints")}/>
                            </Col> : null
                        }
                        <Col sm={12}>
                            <hr/>

                            <ValidInput ref={this.refNote} default={this.getDefault(EventTraining.prototype.col.description)}
                                        componentClass="textarea"
                                        valid={{maxLength: 1500}}
                                        placeholder={messages.get("profile.training.content") + " - " + messages.get("modal.entry.optional")}/>

                            <ValidInput ref={this.refLink} default={this.getDefault(EventTraining.prototype.col.weblink)}
                                        valid={{maxLength: 50, check: ['isUrl']}}
                                        placeholder={messages.get("profile.training.ext.link")}
                                        addonBefore={<FontAwesome icon="globe"/>}/>

                        </Col>
                        <Col sm={12}>
                            {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                            <Button onClick={this.clickedSave} disabled={submitDisabled || subTypeID == null} block bsStyle="success">
                                <I18n code={"modal.button.save"}/>
                            </Button>
                        </Col>
                    </Row>
                </form>
            );
        } else if (objectType == "delete" && object != null) {
            // delete object
            return (
                <DecisionForm
                    name={object.getCalName() + " - " + object.getReadableDate()}
                    messageCode={"list.entry.delete"}
                    okButtonCode={"modal.button.delete"}
                    cancelButtonCode={"modal.button.cancel"}
                    handleOK={this.handleDelete}
                    handleCancel={closeFct}
                />
            )
        } else {
            return <p>WTF - should not happen</p>
        }

    }
}
TrainingForm.propTypes = {
    user:PropTypes.object.isRequired,
    trainingTypes:PropTypes.array,
    trainingCountTypes:PropTypes.array
};
TrainingForm.contextType = ModalBasicContext;
module.exports = AddTrainingModal;
