var Parse = require("ParseInit");

var TransactionPosition = Parse.Object.extend("transactionPosition", {
    col: {
        ACL: "ACL",
        amount: "amount",
        article: "article",
        targetID: "targetID",
        name: "name",
        createdAt: "createdAt",
        objectId: "objectId",
        singlePrice: "singlePrice",
        extRefID: "extRefID",
        status: "status",
        sum: "sum",
        updatedAt: "updatedAt",
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAmount() {
        return this.get(this.col.amount)
    },
    setAmount(amount) {
        return this.set(this.col.amount, amount)
    },
    getName: function() {
        return this.get(this.col.name)
    },
    setName: function(name) {
        return this.set(this.col.name, name)
    },
    getArticle() {
        return this.get(this.col.article)
    },
    setArticle(article) {
        return this.set(this.col.article, article)
    },
    setTarget(target) {
        return this.set(this.col.targetID, target)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getSinglePrice() {
        return this.get(this.col.singlePrice)
    },
    setSinglePrice(singlePrice) {
        return this.set(this.col.singlePrice, singlePrice)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getSum() {
        return this.get(this.col.sum)
    },
    setSum(sum) {
        return this.set(this.col.sum, sum)
    },
    getFormattedSum: function(currency) {
        return currency + " " + parseFloat(this.getSum()).toFixed(2);
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TransactionPosition;
