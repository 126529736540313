const Parse = require("ParseInit");
const {CountType, CountTypeDtl, CountTypeGrp, ZoneNames} = require("parse/_Domain");

class CountTypeDao {

    queryCountTypesGroups() {
        const query = new Parse.Query(CountTypeGrp);
        query.notEqualTo(CountTypeGrp.prototype.col.status, CountTypeGrp.prototype.status.deleted);
        query.notEqualTo(CountTypeGrp.prototype.col.typeID, 1); // do not show private entries
        query.ascending(CountTypeGrp.prototype.col.sortIndex);
        return query.find();
    }

    queryCountTypes() {
        const query = new Parse.Query(CountType);
        query.notEqualTo(CountType.prototype.col.status, CountType.prototype.status.deleted);
        query.include(CountType.prototype.col.countTypeDtls);
        query.ascending(CountType.prototype.col.createdAt);
        query.limit(10000);
        return query.find();
    }

    queryTrainingCountTypes() {
        const query = new Parse.Query(CountType);
        query.notEqualTo(CountType.prototype.col.status, CountType.prototype.status.deleted);
        query.exists(CountType.prototype.col.missColors)
        query.include(CountType.prototype.col.countTypeDtls);
        query.ascending(CountType.prototype.col.createdAt);
        return query.find();
    }

    queryCountType(countType) {
        const query = new Parse.Query(CountType);
        query.include(CountType.prototype.col.countTypeDtls);
        return query.get(countType.id);
    }

    queryCountTypeByOnlineID(onlineID, status) {
        const query = new Parse.Query(CountType);
        query.equalTo(CountType.prototype.col.onlineID, onlineID);
        query.include(CountType.prototype.col.countTypeDtls);
        if (status != null) {
            query.equalTo(CountType.prototype.col.status, status);
        }
        return query.find();
    }

    queryCountTypesDetails() {
        const query = new Parse.Query(CountTypeDtl);
        query.ascending(CountTypeDtl.prototype.col.points + "," + CountTypeDtl.prototype.col.arrowNr + "," + CountTypeDtl.prototype.col.killNr);
        query.limit(10000);
        query.ascending(CountTypeDtl.prototype.col.createdAt);
        return query.find();
    }

    queryZoneNames() {
        const query = new Parse.Query(ZoneNames);
        query.notEqualTo(ZoneNames.prototype.col.status, ZoneNames.prototype.status.deleted);
        query.ascending(ZoneNames.prototype.col.sortIndex);
        return query.find();
    }
}

module.exports = new CountTypeDao();
