const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, FormControl, ControlLabel} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const TournamentConfigBowSelect = ({inputRef, tournament, label, defaultValue, preSelect,  disabled, selectCallback}) =>{
    const changeTournamentConfig = (event) => {
        const configBowTypes = tournament.getTournamentConfigBow();
        if (configBowTypes) {
            for (let i = 0 ; i < configBowTypes.length; i++) {
                if (configBowTypes[i].id == event.target.value) {
                    selectCallback(configBowTypes[i]);
                    return;
                }
            }
            selectCallback(null);
        }
    };
    const addTournamentConfigOptions = () => {
        const configTypes = tournament.getTournamentConfigBow();
        if (!configTypes) {
            return (<option>Ups, keine Klassen gefunden</option>);
        } else {
            return configTypes.map(configType => {
                return <option key={configType.id} id={configType.id} value={configType.id}>{configType.getCodeName()}</option>;
            })
        }
    };
    return (<FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <InputGroup>
            <InputGroup.Addon><FontAwesome icon="bullseye"/></InputGroup.Addon>
            <FormControl ref={inputRef} componentClass="select" placeholder="select"  disabled={disabled}
                         defaultValue={defaultValue}
                         onChange={changeTournamentConfig}>
                {
                    preSelect == false ? <option value={null}>{messages.get("modal.license.chooseBow")}</option> : null
                }
                {addTournamentConfigOptions()}
            </FormControl>
        </InputGroup>
    </FormGroup>);
};
TournamentConfigBowSelect.propTypes = {
    inputRef: PropTypes.object,
    tournament: PropTypes.object.isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string.isRequired,
    preSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentConfigBowSelect;
