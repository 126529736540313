var alt = require("AltInit");
var newsActions = require("actions/NewsActions");

class NewsStore {
    constructor() {
        this.displayName = "NewsStore";
        this.newsList = null;
        this.searchParams = {
            text: ""
        };
        this.bindListeners({
            updateNewsList: newsActions.updateNewsList
        });
    }

    updateNewsList(pNewsList) {
        this.newsList = pNewsList;
    }
}
module.exports = alt.createStore(NewsStore);
