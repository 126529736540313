const React = require("react"),
    PropTypes = require('prop-types');

const { FontAwesomeIcon } = require ('@fortawesome/react-fontawesome');

const FontAwesome = ({icon, children, spin, size, rotation, color, inverse, title}) => {
    if (icon) {
        return (<React.Fragment>
            <FontAwesomeIcon icon={icon} title={title}
                 spin={spin}
                 size={size}
                 rotation={rotation}
                 color={color}
                 inverse={inverse}/>&nbsp;{children}</React.Fragment>)
    }
    return null
};
FontAwesome.propTypes = {
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    title: PropTypes.string,
    spin: PropTypes.bool,
    inverse: PropTypes.bool,
    size: PropTypes.string,
    color: PropTypes.string,
    rotation: PropTypes.number,
    children: PropTypes.any
};
module.exports = FontAwesome;
