const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const TournamentUserGroupManagerStatus = ({tournamentUserGroup, addText}) => {
    if (tournamentUserGroup == null) {
        return <I18n code={addText}/>;
    }
    let font = null;
    let style = "default";
    const status = tournamentUserGroup.status;
    switch (tournamentUserGroup.getStatus()) {
        case status.present:
        {
            font = "check-circle";
            style = "success";
            break;
        }
        case status.started:
        {
            font = "play-circle";
            style = "info";
            break;
        }
        case status.finished:
        {
            font = "flag";
            break;
        }
        case status.deleted:
        {
            font = "trash";
            style = "danger";
            break;
        }
    }
    return (<Label bsStyle={style}><FontAwesome icon={font}/>
        {addText ? <I18n code={addText}/> : null }
    </Label>)
};
TournamentUserGroupManagerStatus.propTypes = {
    tournamentUserGroup:PropTypes.object.isRequired,
    addText: PropTypes.bool.isRequired
};
module.exports = TournamentUserGroupManagerStatus;
