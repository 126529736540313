const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Panel, Label, Button, ButtonGroup, ListGroupItem, ListGroup} = require("react-bootstrap");

const LoadingCircle = require("components/widgets/LoadingCircle"),
    I18n = require("components/widgets/I18n"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    TournamentGroupLogModal = require("components/tournamentmodal/TournamentGroupLogModal"),
    TournamentGroupResultChangesModal = require("components/tournamentmodal/TournamentGroupResultChangesModal"),
    TournamentGroupResultCompareErrorsModal= require("components/tournamentmodal/TournamentGroupResultCompareErrorsModal"),
    TooltipButton  = require("components/form/TooltipButton"),
    SortTableText = require("components/form/SortTableText"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const TournamentUserManagerStatus = require("components/tournamentmanage/TournamentUserManagerStatus"),
    TournamentUserGroupManagerStatus = require("components/tournamentmanage/TournamentUserGroupManagerStatus");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    notificationActions = require("components/notification/NotificationActions");

function buildHeaderText(group) {
    let headerText;
    if (group.getActTargetPosition() != null && group.getActTargetNumber() != null) {
        headerText = messages.get("tournament.table.column.target") + ": " + group.getActTargetPosition() + " - " +
            messages.get("tournament.table.column.amount") + ": " + group.getActTargetNumber();
    } else if (group.isStarted()) {
        headerText = messages.get("tournament.live.groupStarted");
    } else {
        headerText = messages.get("tournament.live.noGroupInfo");
    }
    return headerText
}

function getGroupFromArray(index, tournamentUserGroups) {
    if (index < tournamentUserGroups.length) {
        return tournamentUserGroups[index];
    }
    return null;
}

class TournamentUserGroupResultPanel extends React.Component {
    constructor(props) {
        super(props);
        this.updateSelRound = this.updateSelRound.bind(this);
        let startRound = null;
        if (this.props.runningTRounds.length > 0) {
            startRound = this.props.runningTRounds[0];
        } else if (this.props.tournamentRoundsAll != null && this.props.tournamentRoundsAll.length > 0) {
            startRound = this.props.tournamentRoundsAll[0]
        }
        this.state = {
            selRound: startRound
        }
    }
    componentDidMount() {
        if (this.props.runningTRounds.length === 1) {
            tournamentUserManagerActions.loadTournamentUserGroupsResults(this.props.tournament, this.props.runningTRounds[0]);
        }
    }
    updateSelRound(round) {
        this.setState({selRound: round});
    }
    render() {
        const {tournament, tournamentUserStore, tournamentRoundsAll, user} = this.props;
        const {selRound} = this.state;
        return <Col xs={12}>
                <Panel>
                    <Panel.Heading>
                        <TopPanelGrpArea tournament={tournament} tournamentUserStore={tournamentUserStore} user={user}
                                         selRound={selRound} tournamentRoundsAll={tournamentRoundsAll}
                                         sortParams={tournamentUserStore.tuGroupSortParams}
                                         updateSelRound={this.updateSelRound}
                                         tUserGroupAmount={tournamentUserStore.tournamentUserGroups.length} />
                    </Panel.Heading>
                    <Panel.Body>
                        <TournamentGroupPanelGrpBody tournamentUserGroups={tournamentUserStore.tournamentUserGroups}
                                                     selRound={selRound}
                                                     tournamentUserGroupsResult={tournamentUserStore.tournamentUserGroupsResult}
                                                     groupChangeId={tournamentUserStore.groupChangeId}/>
                    </Panel.Body>
                </Panel>
            </Col>
    }
}
TournamentUserGroupResultPanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    tournamentRoundsAll: PropTypes.array,
    user: PropTypes.object.isRequired,
};
const TopPanelGrpArea = ({tournament, user, tournamentUserStore, tUserGroupAmount, tournamentRoundsAll,
                             selRound, updateSelRound, sortParams}) => {
    function reloadTUGroups() {
        tournamentUserManagerActions.loadTournamentUserGroups(tournament, true);
        if (selRound != null) {
            tournamentUserManagerActions.loadTournamentUserGroupsResults(tournament, selRound);
        }
    }
    function changeSelectedRound(round) {
        updateSelRound(round);
        tournamentUserManagerActions.loadTournamentUserGroupsResults(tournament, round);
    }
    function handleTableSort(sortKey, direction) {
        tournamentUserManagerActions.updateTUGroupSortParams(sortKey, direction)
    }
    return (<React.Fragment>
        <Row>
            <Col xs={3}>
                <h4><strong><I18n code="tournament.live.groups"/></strong></h4>
                <p><strong><FontAwesome icon="sort"><I18n code="tournament.manager.sortBy"/>:</FontAwesome></strong><br/>
                    <SortTableText sortKey="startPosition" sortParams={sortParams} handleSort={handleTableSort}><I18n code="Frag_Prep_startposition"/></SortTableText>
                    &nbsp;-&nbsp;
                    <SortTableText sortKey="name" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.name"/></SortTableText>
                    &nbsp;-&nbsp;
                    <SortTableText sortKey="actTargetAmount" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.amount"/></SortTableText>
                    &nbsp;-&nbsp;
                    <SortTableText sortKey="actTargetNumber" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.target"/></SortTableText>
                </p>
            </Col>
            <Col xs={3}>
                {
                    tournamentUserStore.loadingGroup ? <LoadingCircle/> : <p>{"Gruppen: " + tUserGroupAmount}</p>
                }
            </Col>
            <Col xs={6}>
                <DropDownParseObject label={messages.get("tournament.modal.score.tround")}
                                     default={selRound}  onAfterChange={changeSelectedRound}
                                     objectList={tournamentRoundsAll}/>
            </Col>
        </Row>

        <Row>
            <Col xs={12}>
                <ButtonGroup>
                    <TooltipButton onClick={reloadTUGroups} icon="sync" tooltip={messages.get("tournament.mmtuser.tooltip.group.sync")} />
                </ButtonGroup>
            </Col>
        </Row>
    </React.Fragment>)
};
TopPanelGrpArea.propTypes = {
    tournament: PropTypes.object.isRequired,
    tUserGroupAmount: PropTypes.number.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    tournamentRoundsAll:PropTypes.array,
    selRound :PropTypes.object,
    updateSelRound: PropTypes.func.isRequired
};

const TournamentGroupPanelGrpBody = React.memo(
    function TournamentGroupPanelGrpBody({tournamentUserGroups, selRound, tournamentUserGroupsResult, groupChangeId}) {
        // only rerenders if props change
        // groupChangeId -> Used to trigger reRender
        const modCol = 3;
        let rowArray = Utility.buildModArray(tournamentUserGroups.length, modCol);
        console.log("Render TournamentGroupPanelGrpBody ID: " + groupChangeId + " /rows: " + rowArray) ;
        return <React.Fragment>
            {
                rowArray.map(index => {
                    return <TournamentGroupPanelGrpRow key={"TGPGB_" + index} index={index * modCol} selRound={selRound}
                                                       tournamentUserGroups={tournamentUserGroups} tournamentUserGroupsResult={tournamentUserGroupsResult}/>
                })
            }
        </React.Fragment>
});
TournamentGroupPanelGrpBody.propTypes = {
    tournamentUserGroups: PropTypes.array.isRequired,
    selRound :PropTypes.object,
    groupChangeId: PropTypes.number.isRequired
};
const TournamentGroupPanelGrpRow = ({index, tournamentUserGroups, tournamentUserGroupsResult, selRound}) => {
    const tUserGroup1 = getGroupFromArray(index, tournamentUserGroups);
    let groupResults1 = tUserGroup1 != null ? tournamentUserGroupsResult.filter(groupResult => {
        return groupResult.getTournamentGroupID().id == tUserGroup1.id
    }) : null;
    const tUserGroup2 = getGroupFromArray(index + 1, tournamentUserGroups);
    let groupResults2 = tUserGroup2 != null ? tournamentUserGroupsResult.filter(groupResult => {
        return groupResult.getTournamentGroupID().id == tUserGroup2.id
    }) : null;
    const tUserGroup3 = getGroupFromArray(index + 2, tournamentUserGroups);
    let groupResults3 = tUserGroup3 != null ? tournamentUserGroupsResult.filter(groupResult => {
        return groupResult.getTournamentGroupID().id == tUserGroup3.id
    }): null;
    return <Row >
        {
            tUserGroup1 ?
                <Col key={tUserGroup1.id}  xs={12} md={4}>
                    <TournamentGroupPanel tUserGroup={tUserGroup1} selRound={selRound} groupResults={groupResults1}/>
                </Col> : null
        }
        {
            tUserGroup2 ?
                <Col key={tUserGroup2.id}  xs={12} md={4}>
                    <TournamentGroupPanel tUserGroup={tUserGroup2} selRound={selRound} groupResults={groupResults2}/>
                </Col> : null
        }
        {
            tUserGroup3 ?
                <Col key={tUserGroup3.id}  xs={12} md={4}>
                    <TournamentGroupPanel tUserGroup={tUserGroup3} selRound={selRound} groupResults={groupResults3}/>
                </Col> : null
        }
    </Row>
};
const TournamentGroupPanel = ({tUserGroup, selRound, groupResults}) => {
    function removeResult(groupResult) {
        tournamentUserManagerActions.deleteTournamentUserGroupsResult(groupResult);
    }
    function finishTUserGroup() {
        tUserGroup.setStatusFinished();
        tournamentUserManagerActions.saveTournamentUserGroup(tUserGroup)
    }
    function linkToEvent(groupResult) {
        tournamentUserManagerActions.findEventsToGroup(tUserGroup, selRound, groupResult.getUserID(), events => {
            if (events.length > 0) {
                events.forEach(ev => {
                    window.open(linkUtil.getLink("profile/eventdetail/" + ev.id), '_blank');
                })
            } else {
                notificationActions.info("profile.events.empty")
            }
        })
    }
    return <ListGroup>
        <ListGroupItem bsStyle={tUserGroup.isDeleted() ? "danger" : "info"}>
            <Row>
            <Col xs={6}>
                <TournamentUserGroupManagerStatus tournamentUserGroup={tUserGroup} addText={tUserGroup.getName()} />
            </Col>
            <Col xs={6}>
                <Label>{buildHeaderText(tUserGroup)}</Label>
            </Col>
            </Row>
        </ListGroupItem >
        {
            !tUserGroup.isFinished() && tUserGroup.isTUserAllFinished() ? <ListGroupItem>
                <Button onClick={finishTUserGroup}>
                    <FontAwesome icon="flag"><I18n code="tournament.mmtuser.tooltip.group.finish"/></FontAwesome>
                </Button>
            </ListGroupItem> : null
        }
            {
                tUserGroup.getTournamentUserIDs().map(tUser => {
                    return <ListGroupItem key={"LGI_" + tUser.id}>
                            <TournamentUserManagerStatus tournamentUser={tUser} addText={false}/>&nbsp;
                            {tUser.getFullName() + ", " + tUser.getTournamentConfigBowCode() + ", " + tUser.getTournamentConfigAgeCode()}
                        {
                            tUserGroup.isFinished() ? <span className="pull-right">{tUser.getSumPoints()}</span> :
                                <span className="pull-right">{tUserGroup.getTournamentUserPoints(tUser.id)}</span>
                        }

                    </ListGroupItem>
                })
            }
            {
                groupResults.map(groupResult => {
                    const user = groupResult.getUserID();
                    return <ErrorBoundary key={"LGI_EB_" + groupResult.id}><ListGroupItem key={"LGI_" + groupResult.id}>
                        <strong><FontAwesome icon="mobile-alt">{user ? user.getUsername() : "???"}</FontAwesome></strong>
                        &nbsp;Status:{groupResult.getStatus()}&nbsp;&nbsp;
                        <TooltipButton onClick={() => linkToEvent(groupResult)} icon="link" bsSize="small"
                                       tooltip={messages.get("profile.event.eventDetails")} />
                        {
                            groupResult.isDeleted() ? null :
                                <TooltipButton onClick={() => removeResult(groupResult)} icon="trash"
                                               custClassName="pull-right" bsSize="small"
                                               tooltip={"Lösche GroupResult"} />
                        }
                        <TournamentGroupLogModal buttonStyle="default" tUserGroup={tUserGroup} selRound={selRound} />
                        <br/>
                        {
                            groupResult.getChangeLogs() ? <TournamentGroupResultChangesModal
                                tUserGroup={tUserGroup} changeLogs={groupResult.getChangeLogs()}/> : null
                        }
                        {
                            groupResult.getCompareErrors() ? <TournamentGroupResultCompareErrorsModal
                                tUserGroup={tUserGroup} compareErrors={groupResult.getCompareErrors()}/> : null
                        }
                    </ListGroupItem></ErrorBoundary>
                })
            }
        </ListGroup>
};
TournamentGroupPanel.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    selRound :PropTypes.object,
    groupResults :PropTypes.array
};
module.exports = TournamentUserGroupResultPanel;
