const React = require("react"),
    PropTypes = require('prop-types');
const FontAwesome = require("components/widgets/FontAwesome");

const IconTextEllipse = ({icon, text, ellipseLength}) => {
    let maxLength = ellipseLength > 0 ? ellipseLength : 20;
    if (text != null && icon != null) {
        if (text.length > maxLength) {
            return <FontAwesome icon={icon} title={text}>{text.substr(0,maxLength -1)}...</FontAwesome>
        } else {
            return <FontAwesome icon={icon}>{text}</FontAwesome>
        }
    }
    return null
};
IconTextEllipse.propTypes = {
    icon: PropTypes.string,
    text:PropTypes.string,
    ellipseLength: PropTypes.number
};
module.exports = IconTextEllipse;
