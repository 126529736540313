const React = require("react"),
    PropTypes = require('prop-types'),
    ParcoursLink= require("components/parcours/ParcoursLink");
const {Table} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

const messages = require("i18n/messages");

const UserTransactionTable = ({transactions}) => {
    return (
        <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.time"/></th>
                <th><I18n code="parcours.articles.form.price"/></th>
                <th><I18n code="parcours.articles.form.brand"/></th>
                <th>Parcours</th>
                <th><I18n code="tournament.table.column.status"/></th>
                <th><I18n code="tournament.table.column.invoice"/></th>
            </tr>
            </thead>
            <tbody>
            {transactions.map(function (transaction) {
                return (<TransactionRow key={transaction.id} transaction={transaction}/>);
            })}
            </tbody>
        </Table>
    )
};
const TransactionRow = ({transaction}) => {
    const buildStatus =() => {
        if (transaction.isStatusACK()) {
            return messages.get("transaction.status." + transaction.getStatus());
        } else if (transaction.isStatusPending() && transaction.getClubInvoiceID()) {
            return messages.get("club.invoice.open.waiting");
        } else {
            // error happened
            return messages.get("transaction.status." + transaction.getStatus()) + " (" + transaction.getProcessingReturnCode() + ")";
        }
    };
    function getInvoice() {
        if (transaction.getInvoiceNumber()) {
            if (transaction.getInvoice()) {
                return (<a target="_BLANK" href={transaction.getInvoice()._url}>
                    <FontAwesome icon="download"/>{transaction.getInvoiceNumber()}
                </a>);
            } else {
                return transaction.getInvoiceNumber()
            }
        }
        return "-";
    }
    return (
        <tr id={transaction.id}>
            <td>{transaction.getTransactionDate()}</td>
            <td>{transaction.getTransactionTime()}</td>
            <td>{transaction.getFormattedPrice()}</td>
            <td>{transaction.getBrand()}</td>
            <td>{transaction.getParcours() ?
                <ParcoursLink parcours={transaction.getParcours()}
                              label={transaction.getParcours().buildNames()}/> : "-"}</td>
            <td>{buildStatus()}</td>
            <td>{getInvoice()}</td>
        </tr>)
};
TransactionRow.propTypes = {
    transaction:PropTypes.object.isRequired
}
module.exports = UserTransactionTable;
