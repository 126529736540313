const React = require("react"),
    PropTypes = require('prop-types');

const messages = require("i18n/messages"),
    TooltipButton  = require("components/form/TooltipButton");

const {Event} = require("parse/_Domain");

const EventRankingVisibility = ({event, saveEvent}) => {
    function hideInRanking() {
        event.setVisibileStatus(Event.prototype.visible.invisible);
        saveEvent(event)
    }
    function showInRanking() {
        event.setVisibileStatus(Event.prototype.visible.visible);
        saveEvent(event)
    }

    if (event.isNormalEvent()) {
        if (event.isRankingVisible()) {
            return <TooltipButton onClick={hideInRanking} icon="eye-slash" tooltip={messages.get("profile.events.hideInRanking")}/>
        } else {
            return <TooltipButton onClick={showInRanking} icon="eye" tooltip={messages.get("profile.events.showInRanking")}/>
        }
    }
    return null
};
EventRankingVisibility.propTypes = {
    event:PropTypes.object.isRequired,
    saveEvent:PropTypes.func.isRequired
};
module.exports = EventRankingVisibility;
