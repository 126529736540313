const Parse = require("ParseInit");

const GroupPlace = Parse.Object.extend("groupPlace", {
    col: {
        parcoursAmount: "parcoursamount",
        code: "code",
        name: "name",
        parentGroupID: "parentGroupID",
        status: "status",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        A: "A"
    },
    //
    //
    //
    getCode: function() {
        return this.get(this.col.code)
    },
    setCode: function(code) {
        return this.set(this.col.code, code)
    },
    getName: function() {
        return this.get(this.col.name)
    },
    getSelectString: function() {
        return this.getName()
    },
    setName: function(name) {
        return this.set(this.col.name, name)
    },
    getParcoursAmount: function () {
        return this.get(this.col.parcoursAmount)
    },
    setParcoursAmount: function(parcoursAmount) {
        return this.set(this.col.parcoursAmount, parcoursAmount)
    },
    getParentGroupID: function() {
        return this.get(this.col.parentGroupID)
    },
    setParentGroupID: function(parentGroupID) {
        return this.set(this.col.parentGroupID, parentGroupID)
    },
    getStatus: function() {
        return this.get(this.col.status)
    },
    setStatus: function(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt: function() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt: function() {
        return this.get(this.col.createdAt)
    }
});

module.exports = GroupPlace;
