const Alt = require("alt");
const ConfigKeys = require("ConfigKeys");

const alt = new Alt();

if (!ConfigKeys.isProd) {
    Alt.debug('alt', alt);
    alt.dispatcher.register(console.log.bind(console));
}

module.exports = alt;
