const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton"),
    FontAwesome = require("components/widgets/FontAwesome");
const linkUtil = require("linkUtil");

const TournamentMMRoundButton  = ({tournament, block, disabled, style, bsStyle, bsSize}) => {
    return (
        <LinkButton
            style={style}
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            bsSize={bsSize}
            label="tournament.button.menu.rounds"
            symbol={<FontAwesome icon="cog"/>}
            href={linkUtil.getLink("/tournament/" + tournament.id + "/mmround")}/>
    )
};
TournamentMMRoundButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
    bsSize:PropTypes.string,
    style:PropTypes.object
};
module.exports = TournamentMMRoundButton;