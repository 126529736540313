const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");

const TournamentUserStatus = ({tournamentUser}) => {
    let code = "code.unknown";
    let style = "default";
    const status = tournamentUser.status;
    switch (tournamentUser.getStatus()) {
        case status.registered:
        {
            if (tournamentUser.isPaid()) {
                code = "tournament.attendee.status.legend.paid";
                style = "success";
            } else {
                code = "tournament.attendee.status.legend.pending";
                style = "info";
            }
            break;
        }
        case status.present:
        {
            code = "tournamentUser.status.A";
            style = "info";
            break;
        }
        case status.started:
        {
            code = "tournamentUser.status.S";
            style = "info";
            break;
        }
        case status.scoreSubmitted:
        {
            code = "tournamentUser.status.S";
            style = "info";
            break;
        }
        case status.finished:
        {
            code = "tournament.status.finished";
            style = "success";
            break;
        }
        case status.disqualified:
        {
            code = "tournamentUser.status.disqualified";
            style = "danger";
            break;
        }
        case status.deleted:
        {
            code = "tournamentUser.status.D";
            style = "danger";
            break;
        }
    }
    return (<Label bsStyle={style}><I18n code={code}/></Label>)
};
TournamentUserStatus.propTypes = {
    tournamentUser:PropTypes.object.isRequired
};
module.exports = TournamentUserStatus;
