const Parse = require("ParseInit");

const {BowUnion, BowType, AgeType} = require("parse/_Domain");

class LicenseDao {


    loadBowUnion(code) {
        const query = new Parse.Query(BowUnion);
        query.notEqualTo(BowUnion.prototype.col.status, "D");
        if (code != null) {
            query.equalTo(BowUnion.prototype.col.code, code)
        }
        query.ascending(BowUnion.prototype.col.sortIndex);
        return query.find();
    }
    loadBowTypes(code) {
        const query = new Parse.Query(BowType);
        query.notEqualTo(BowType.prototype.col.status, "D");
        if (code != null) {
            query.equalTo(BowType.prototype.col.unions, code)
        }
        query.ascending(BowType.prototype.col.sortIndex);
        return query.find();
    }
    loadAgeTypes(code) {
        const query = new Parse.Query(AgeType);
        query.notEqualTo(AgeType.prototype.col.status, "D");
        if (code != null) {
            query.equalTo(AgeType.prototype.col.unions, code)
        }
        query.ascending(AgeType.prototype.col.sortIndex);
        return query.find();
    }

    updateClubUserLicenseIDs(usrDetail, usrBowUnionLicIDs) {
        return Parse.Cloud.run('updateUserDetailLicIDs', {udID: usrDetail.id,
            usrBowUnionLicIDs: usrBowUnionLicIDs
        });
    };
}

module.exports = new LicenseDao();
