const alt = require("AltInit");
const tournamentUserActions = require("actions/TournamentUserManagerActions");
const PLUtil = require("util/ParseListUtility");

class TournamentUserManagerStore {
    constructor() {
        this.displayName = "TournamentUserManagerStore";
        this.hideDeleted = true;
        this.selTournament = null;
        this.tournamentUsers = [];
        this.tournamentUserGroupStatus = "A";
        this.tournamentUserGroups = [];
        this.filteredTournamentUserGroups = []
        this.tournamentUserGroupsResult = [];
        this.tournamentUserTeams = [];
        this.groupChangeId = 1;// Used to trigger reRender of GroupPanel
        this.teamChangeId = 1; // Used to trigger reRender of TeamPanel
        this.loadingGroup = true;
        this.loadingTeam = true;
        this.loading = true;
        this.parseError = null;
        this.tournamentUserCupResult = null;
        this.tuGroupSortParams= {
            sortKey: null,
            sortDir: "asc"
        }
        this.bindListeners({
            updateHideDeleted: tournamentUserActions.updateHideDeleted,
            updateTournamentUsers: tournamentUserActions.updateTournamentUsers,
            loadingTournamentUserGroups: [tournamentUserActions.loadTournamentUserGroups, tournamentUserActions.loadTournamentUserGroupsResults,
                tournamentUserActions.createTournamentGroup, tournamentUserActions.startTournamentGroups, tournamentUserActions.saveTournamentUserGroup],
            loadingTournamentUserTeams: [tournamentUserActions.loadTournamentUserTeams, tournamentUserActions.createTournamentTeam,
                tournamentUserActions.saveTournamentUserTeam],
            updateTournamentUserGroups: tournamentUserActions.updateTournamentUserGroups,
            updateTournamentGroupFilterStatus: tournamentUserActions.updateTournamentGroupFilterStatus,
            updateTournamentUserTeams: tournamentUserActions.updateTournamentUserTeams,
            updateTournamentUserGroupsResult: tournamentUserActions.updateTournamentUserGroupsResult,
            updateTournamentUserGroupsResults: tournamentUserActions.updateTournamentUserGroupsResults,
            updateTournamentUserGroup: tournamentUserActions.updateTournamentUserGroup,
            updateTUGroupSortParams: tournamentUserActions.updateTUGroupSortParams,
            updateTournamentUserTeam: tournamentUserActions.updateTournamentUserTeam,
            updateTournamentUserCupDic: tournamentUserActions.updateTournamentUserCupDic,
            resetTournamentUsers: tournamentUserActions.resetTournamentUsers,
            setSelectedTournament: tournamentUserActions.setSelectedTournament
        });
    }


    setSelectedTournament(tournament) {
        this.selTournament = tournament;
    }

    updateHideDeleted(hide) {
        this.hideDeleted = hide;
    }

    updateTournamentUsers(tournamentUsers) {
        this.loading = false;
        this.tournamentUsers = tournamentUsers;
    }

    updateTournamentUserGroups(groups) {
        this.loadingGroup = false;
        this.sortTUGroupsObjects(groups)
        this.filterTournamentUserGroups(this.tournamentUserGroupStatus)
    }
    sortTUGroupsObjects(groups) {
        this.tournamentUserGroups = PLUtil.sortList(groups, this.tuGroupSortParams);
        this.groupChangeId++;
        this.filterTournamentUserGroups(this.tournamentUserGroupStatus)
    }

    updateTournamentGroupFilterStatus(status) {
        this.tournamentUserGroupStatus = status
        this.filterTournamentUserGroups(status)
    }

    filterTournamentUserGroups(status) {
        this.filteredTournamentUserGroups = this.tournamentUserGroups.filter(tUserGroup => {
            return status == tUserGroup.getStatus();
        });
    }

    updateTournamentUserTeams(teams) {
        this.teamChangeId++;
        this.loadingTeam = false;
        this.tournamentUserTeams = teams;
    }


    updateTournamentUserGroupsResults(groupsResults) {
        this.groupChangeId++;
        this.loadingGroup = false;
        this.tournamentUserGroupsResult = groupsResults;
    }

    updateTournamentUserGroupsResult(groupsResult) {
        this.updateTournamentUserGroupsResults(PLUtil.updateOrPush(this.tournamentUserGroupsResult, groupsResult));
    }

    updateTournamentUserGroup(group) {
        this.updateTournamentUserGroups(PLUtil.updateOrPush(this.tournamentUserGroups, group));
    }

    updateTUGroupSortParams({sortKey, direction}) {
        console.log("Clicked sort by " + sortKey + " dir: " + direction);
        let sSortParams =this.tuGroupSortParams;
        sSortParams.sortKey = sortKey;
        sSortParams.sortDir = direction;
        this.tuGroupSortParams = sSortParams;
        this.sortTUGroupsObjects(this.tournamentUserGroups)
    }

    updateTournamentUserTeam(team) {
        this.updateTournamentUserTeams(PLUtil.updateOrPush(this.tournamentUserTeams, team));
    }

    loadingTournamentUserGroups() {
        this.loadingGroup = true;
    }

    loadingTournamentUserTeams() {
        this.loadingTeam = true;
    }

    resetTournamentUsers() {
        this.tournamentUsers = [];
        this.tournamentUserGroups = [];
        this.loading = true;
        this.loadingGroup = true;
    }
    //---------------------
    updateTournamentUserCupDic(userResultDic) {
        this.tournamentUserCupResult = userResultDic;
    }

}
module.exports = alt.createStore(TournamentUserManagerStore);