const React = require("react"),
    PropTypes = require('prop-types');
const {Image} = require("react-bootstrap");

const types = {
    1: "ic_bow_1_rec_tr.png",
    2: "ic_bow_2_rec_bb.png",
    3: "ic_bow_3_rec_ol.png",
    4: "ic_bow_4_compound.png",
    5: "ic_bow_5_longbow.png",
    6: "ic_bow_6_horse.png",
    7: "ic_bow_7_prim.png",
    8: "ic_bow_8_crossbow.png",
    9: "ic_bow_9_blowgun.png",
}
const BowTypeIcon = ({bowTypeId}) => {
    if (types[bowTypeId] != null) {
        return <Image className="bowImage" rounded src={"/img/bowtypes/" + types[bowTypeId]} width="32" height="32"/>
    }
    return null
}
BowTypeIcon.propTypes = {
    bowTypeId: PropTypes.number
};
module.exports = BowTypeIcon;
