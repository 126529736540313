const alt = require("AltInit");
const actions = require("actions/AccountActions");
const PLUtil = require("util/ParseListUtility");

class AccountStore {
    constructor() {
        this.displayName = "AccountStore";
        this.managements = [];
        this.bindListeners({
            updateManagements: actions.updateManagements,
            updateManagement: actions.updateManagement
        });
    }

    updateManagements(pManagements) {
        this.managements = pManagements
    }

    updateManagement(management) {
        this.updateManagements(PLUtil.updateOrPush(this.managements, management));
    }
}
module.exports = alt.createStore(AccountStore);
