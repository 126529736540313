const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert} = require("react-bootstrap");

const ParcoursStatistics = React.lazy(() =>
    import(/* webpackChunkName: "ChartComponents" */ "components/parcoursmanage/ParcoursStatistics")
);

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
    YearPager= require("components/widgets/YearPager");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");
const userStore = require("stores/UserStore"),
    analyticsStore = require("stores/AnalyticsStore"),
    analyticsActions = require("actions/AnalyticsActions");

const messages = require("i18n/messages");

class ParcoursStatisticPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleAnalyticsChange = this.handleAnalyticsChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        // start with year from url this year
        let year = this.props.year;
        if (year == null) {
            const today = new Date();
            year = today.getFullYear();
        }
        this.state = {
            userStore: userStore.getState(),
            analyticsStore: analyticsStore.getState(),
            year: year
        }
    }
    componentDidMount() {
        document.title = messages.get("statistics.header");
        userStore.listen(this.handleUserChange);
        analyticsStore.listen(this.handleAnalyticsChange);
        analyticsActions.startUpParcoursStats(this.state.userStore.user, this.state.analyticsStore.parcours, this.props.onlineID, this.state.year, this.props.tstat);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        analyticsStore.unlisten(this.handleAnalyticsChange);
    }
    handleAnalyticsChange(state) {
        this.setState({analyticsStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleChangedDate(year) {
        this.setState({year: year});
        analyticsActions.startUpParcoursStats.defer(this.state.userStore.user, this.state.analyticsStore.parcours, this.props.onlineID, year, this.props.tstat);
    }
    render() {
        return (
            <Grid>
                <Row>
                    <Col lg={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                            <Breadcrumb code="statistics.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                {
                    this.state.analyticsStore.invalidUser || this.state.analyticsStore.parcours == null ? null :
                        <YearPager year={this.state.year} headerText={this.state.analyticsStore.parcours.buildNames() + " " + messages.get("statistics.header")} handleChangedDate={this.handleChangedDate}/>
                }
                {
                    this.state.analyticsStore.loading ? <Loading /> :
                        this.state.analyticsStore.invalidUser ?
                            <Alert bsStyle="warning"><I18n code="parse.error.119"/></Alert>
                        :
                            this.state.analyticsStore.parcoursStatDic != null ?
                                <ErrorSuspenseBoundary>
                                    <ParcoursStatistics parcoursStatDic={this.state.analyticsStore.parcoursStatDic}/>
                                </ErrorSuspenseBoundary>
                            :
                                <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert>
                }

            </Grid>
        )
    }
}
ParcoursStatisticPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    tstat: PropTypes.string,
    year: PropTypes.number
};
module.exports = ParcoursStatisticPage;
