const Parse = require("ParseInit");

const TranslationCl = Parse.Object.extend("translation", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        editor: "editor",
        forAndroid: "forAndroid",
        forIos: "forIos",
        forWeb: "forWeb",
        forReactN: "forReactN",
        key: "key",
        keyIOS: "keyIOS",
        objectId: "objectId",
        updatedAt: "updatedAt",
        translationChangedArray: "translChangedFor"
    },
    lngCols: {
        de :"de",
        en :"en",
        es :"es",
        fr :"fr",
        it :"it",
        ru :"ru",
        pl :"pl",
        da :"da",
        sv :"sv",
        nl: "nl",
        cs: "cs",
        iOS: "keyIOS"
    },
    getDatalistText: function () {
        return this.getKey();
    },
    getDatalistImage() {
        return null;
    },
    getLabels: function () {
        const result = [this.getKey(), this.getKeyIOS(),
            this.getTranslation(this.lngCols.de), this.getTranslation(this.lngCols.en)
            , this.getTranslation(this.lngCols.it), this.getTranslation(this.lngCols.es)
            , this.getTranslation(this.lngCols.fr), this.getTranslation(this.lngCols.ru)
            , this.getTranslation(this.lngCols.pl), this.getTranslation(this.lngCols.da)
            , this.getTranslation(this.lngCols.sv), this.getTranslation(this.lngCols.nl)];
        return result.join(" ");
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getEditor: function () {
        return this.get(this.col.editor)
    },
    setEditor: function (editor) {
        return this.set(this.col.editor, editor)
    },
    getTranslation: function (language) {
        return this.get(language)
    },
    getDefaultTranslation: function () {
        return this.get(this.lngCols.en);
    },
    setTranslation: function (language, translation) {
        return this.set(language, translation)
    },
    getForAndroid: function () {
        return this.get(this.col.forAndroid)
    },
    setForAndroid: function (forAndroid) {
        return this.set(this.col.forAndroid, forAndroid)
    },
    getForIos: function () {
        return this.get(this.col.forIos)
    },
    setForIos: function (forIos) {
        return this.set(this.col.forIos, forIos)
    },
    getForWeb: function () {
        return this.get(this.col.forWeb)
    },
    setForWeb: function (forWeb) {
        return this.set(this.col.forWeb, forWeb)
    },
    getKey: function () {
        return this.get(this.col.key)
    },
    getForReactN: function () {
        return this.get(this.col.forReactN)
    },
    setKey: function (key) {
        return this.set(this.col.key, key)
    },
    getKeyIOS: function () {
        return this.get(this.col.keyIOS)
    },
    setKeyIOS: function (keyIOS) {
        return this.set(this.col.keyIOS, keyIOS)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
    addTranslationChanged: function (lngCode) {
        let changeArray = this.getTranslationChangedArray();
        if (changeArray == null) {
            changeArray = [];
        }
        if (changeArray.indexOf(lngCode) == -1) {
            // add to change array
            changeArray.push(lngCode);
            this.setTranslationChangedArray(changeArray);
        }
    },
    getTranslationChangedArray: function () {
        return this.get(this.col.translationChangedArray);
    },
    setTranslationChangedArray: function (translChangedArray) {
        return this.set(this.col.translationChangedArray, translChangedArray);
    }

});
module.exports = TranslationCl;