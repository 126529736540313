const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton"),
    FontAwesome = require("components/widgets/FontAwesome");
const linkUtil = require("linkUtil");

const TournamentLiveButton = ({tournament, block, disabled, bsStyle, bsSize}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            bsSize={bsSize}
            label="tournament.result.link.live"
            symbol={<FontAwesome icon="desktop"/>}
            href={linkUtil.getLink("/tournament/" + tournament.id + "/live")}/>
    )
};
TournamentLiveButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
    bsSize:PropTypes.string
};
module.exports = TournamentLiveButton;