var alt = require("AltInit");
var actions = require("actions/ClubEventActions");

class ClubEventStore {
    constructor() {
        this.displayName = "ClubEventStore";
        this.loading = true;
        this.clubEvent = null;
        this.clubDetail = null;
        this.ownClubUser = null;
        this.clubUsers = [];
        this.sortParams = {
            sortKey: null,
            sortDir: "asc"
        };
        this.bindListeners({
            updateClubUsersList: actions.updateClubUsersList,
            updateOwnClubUser: actions.updateOwnClubUser,
            updateClubEvent: actions.updateClubEvent,
            updateSortParams: actions.sortClubUsers
        });
    }

    updateClubEvent(pClubEvent) {
        this.clubEvent = pClubEvent;
        if (pClubEvent != null) {
            this.clubDetail = pClubEvent.getClubID();
        }
    }

    updateOwnClubUser(pClubUser) {
        this.ownClubUser = pClubUser;
    }

    updateClubUsersList(pClubUserList) {
        this.loading = false;
        this.clubUsers = pClubUserList;
    }

    updateSortParams(sortParams) {
        this.sortParams = sortParams;
    }
}
module.exports = alt.createStore(ClubEventStore);
