const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TooltipButton  = require("components/form/TooltipButton"),
    LoadingCircle = require("components/widgets/LoadingCircle"),
    ModalBasic = require ("components/modals/ModalBasic"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    Utility = require("util/Utility"),
    ParseListUtility = require("util/ParseListUtility"),
    ValidInput = require("components/form/ValidInput");

const {TournamentRoundUser} = require("parse/_Domain");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const changeLogActions = require("actions/ChangeLogActions");
const tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    countTypeStore = require("stores/CountTypeStore"),
    countTypeActions = require("actions/CountTypeActions");


class TournamentScoreModal extends React.Component {
    constructor(props) {
        super(props);
        this.editTUserScore = this.editTUserScore.bind(this);
        this.handleCountTypeStoreChange = this.handleCountTypeStoreChange.bind(this);
        this.refModal = React.createRef();
        this.state = {
            editTUser: null,
            countTypeStore: countTypeStore.getState(),
        }
    }
    componentDidMount() {
        countTypeStore.listen(this.handleCountTypeStoreChange);
    }
    componentWillUnmount() {
        countTypeStore.unlisten(this.handleCountTypeStoreChange);
    }
    handleCountTypeStoreChange(state) {
        this.setState({countTypeStore: state});
    }
    editTUserScore() {
        this.setState({editTUser: this.props.selectedObjectList[0]});
        this.refModal.current.open();
    }
    render() {
        const {user, tournament, selTUserLength, singleStartedTRound, tournamentRoundsAll} = this.props;
        const {editTUser, countTypeStore} = this.state;
        return <ModalBasic ref={this.refModal}
                           title={<TextCenter>{editTUser != null ? <FontAwesome icon={editTUser.getSexIcon()}>{editTUser.getFullName()}</FontAwesome> : ""}</TextCenter>}
                           desc={<TextCenter>{editTUser != null ? editTUser.getCupGroup() : null}</TextCenter>}
            customButton={<React.Fragment>
                <TooltipButton onClick={this.editTUserScore} disabled={selTUserLength !== 1} icon="edit" tooltip={messages.get("tournament.mmtuser.tooltip.editScore")} />
            </React.Fragment>}>
            <TournamentScoreForm tournament={tournament} user={user}
                                 editTUser={editTUser}
                                 tournamentRoundsAll={tournamentRoundsAll}
                                 singleStartedTRound={singleStartedTRound}
                                 countTypes={countTypeStore.countTypes}/>
        </ModalBasic>
    }
}
TournamentScoreModal.propTypes = {
    tournament: PropTypes.object.isRequired,
    singleStartedTRound :PropTypes.object,
    tournamentRoundsAll: PropTypes.array,
    selectedObjectList: PropTypes.array.isRequired,
    selTUserLength: PropTypes.number.isRequired,
};
class TournamentScoreForm extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refSumPoints = React.createRef();
        this.refHitMiss = React.createRef();
        this.refManCorr = React.createRef();
        this.clickedSave = this.clickedSave.bind(this);
        this.filterRoundCalcUsers = this.filterRoundCalcUsers.bind(this);
        this.changeSelectedRound = this.changeSelectedRound.bind(this);
        this.changeSelectedUserRound = this.changeSelectedUserRound.bind(this);
        this.handleUpdatePoints = this.handleUpdatePoints.bind(this);
        this.handleUpdateCupPoints = this.handleUpdateCupPoints.bind(this);
        this.fillPointsHitMissField = this.fillPointsHitMissField.bind(this);
        this.changeTUserRoundStatus = this.changeTUserRoundStatus.bind(this);
        this.handleSaveAction = this.handleSaveAction.bind(this);
        this.state = {
            selRound: this.props.singleStartedTRound != null ? this.props.singleStartedTRound : this.props.tournamentRoundsAll[0],
            selRoundUser: null,
            selRoundUserKillCounts: {},
            selCountType: null,
            tRoundCalcUsers: [],
            tFilteredRoundCalcUsers: null,
        }
    }
    componentDidMount() {
        console.log("mount", this.props, this.state)
        // load tRoundsUser from user
        if (this.props.editTUser != null) {
            const tRoundCalcUsers = this.props.editTUser.getCalcTournamentRoundUserIDs();
            if (tRoundCalcUsers != null) {
                tournamentUserManagerActions.fetchTRoundCalcUsers(tRoundCalcUsers, result => {
                    this.setState({tRoundCalcUsers: result});
                    this.filterRoundCalcUsers(result, this.state.selRound)
                })
            } else {
                // no rounds
                this.filterRoundCalcUsers([], this.state.selRound);
            }
        }
    }
    filterRoundCalcUsers(tRoundCalcUsers, selectedRound) {
        if (selectedRound == null) {
            return
        }
        // updates drop down for results
        let tFiltered = tRoundCalcUsers.filter(round => {
            return selectedRound.id == round.getTournamentRoundID().id
        });
        let roundUser = tFiltered.length > 0 ? tFiltered[0] : null;
        this.setState({
            tFilteredRoundCalcUsers: tFiltered,
            selRoundUser: roundUser
        });
        this.changeSelectedUserRound(roundUser);
    }
    changeSelectedRound(round) {
        this.setState({selRound: round});
        this.filterRoundCalcUsers(this.state.tRoundCalcUsers, round)
    }
    changeSelectedUserRound(round) {
        // handle selected tUserRound
        if (round != null) {
            this.setState({
                selCountType: null,
                selRoundUser: round,
                selRoundUserKillCounts: Utility.buildKillCountDict(round.getKillCounts())
            },() => {
                // called after render
                this.handleSetCountType(round.getCountTypeID(), () => {
                    // callback countType is set
                    let calcPoints = round.getCountTypeID().calcPoints(this.state.selRoundUserKillCounts);
                    this.refManCorr.current.setValue(round.getRoundPoints() - calcPoints);
                    this.fillPointsHitMissField(this.state.selRoundUserKillCounts, round.getRoundPoints());
                });
            });
        } else {
            // no round, use countType from basic bow config
            this.setState({
                selCountType: null,
                selRoundUser: null,
                selRoundUserKillCounts: {}
            },() => {
                // called after render
                this.handleSetCountType(this.props.editTUser.getTournamentConfigBow().getCountTypeID(), () => {
                    // callback countType is set
                    this.refManCorr.current.setValue("");
                    this.fillPointsHitMissField({},0);
                });
            });
        }
    }
    handleSetCountType(countType, callbackCountTypeSet) {
        // handle getting correct countType
        if (countType != null) {
            // check if countType is already in array
            if (ParseListUtility.contains(this.props.countTypes, countType)) {
                this.setState({selCountType: countType}, () => {
                    // after countType is set
                    callbackCountTypeSet()
                });
            } else {
                // load it
                countTypeActions.fetchCountType(countType, result => {
                    this.setState({selCountType: result}, () => {
                        // after countType is set
                        callbackCountTypeSet()
                    });
                })
            }
        } else {
            this.setState({selCountType: null});
        }
    }
    handleUpdatePoints(key, newValue) {
        this.context.setErrorFct(null);
        // update state with new killValues
        let tmpSelRoundManCorr = parseInt(this.refManCorr.current.getValue());
        let tmpRoundUserKillCounts = this.state.selRoundUserKillCounts;
        if (key === "MAN") {
            // update manual correction
            tmpSelRoundManCorr = parseInt(newValue);
        } else {
            // update kill count
            tmpRoundUserKillCounts[key] = parseInt(newValue);
        }
        this.setState({
            selRoundUserKillCounts: tmpRoundUserKillCounts,
        });
        // reCalc points
        let newPoints = this.state.selCountType.calcPoints(tmpRoundUserKillCounts);
        if (Number.isInteger(tmpSelRoundManCorr)) {
            newPoints += tmpSelRoundManCorr;
        }
        this.fillPointsHitMissField(tmpRoundUserKillCounts, newPoints);
    }
    fillPointsHitMissField(killCounts, sumPoints) {
        let targetAmount = this.state.selRound.getTargetAmounts();
        if (this.state.selCountType != null) {
            let maxHit = targetAmount;
            if (this.state.selCountType.getArrowSumUp()) {
                // higher targetAmount if countType has arrowSumUp
                let arrows = this.state.selCountType.getArrowAmount();
                maxHit = targetAmount * arrows;
            }
            let hits = this.state.selCountType.calcHits(killCounts);
            // handling miss with points
            let missPoints = this.state.selCountType.getMissPoints();
            let miss = maxHit - hits;
            let missSumPoints = miss * missPoints;
            this.refHitMiss.current.setValue(targetAmount + " / " + hits + " / " + miss);
            this.refSumPoints.current.setValue(sumPoints + missSumPoints)
        } else {
            this.refHitMiss.current.setValue(targetAmount + " / ? / ?");
            this.refSumPoints.current.setValue(sumPoints)
        }
    }
    changeTUserRoundStatus(status) {
        const {closeFct, setErrorFct} = this.context;
        const {tournament, editTUser, user} = this.props;
        let actTRoundUser = this.state.selRoundUser;
        if (actTRoundUser != null) {
            actTRoundUser.setStatus(status)
            // save
            this.handleSaveAction(actTRoundUser);
            changeLogActions.addTournamentChange(tournament, user, actTRoundUser.getTournamentRoundID().getName()  + " - " + editTUser.getFullName() + " - RoundResult", actTRoundUser.getRoundPoints() + "" , status)
        }
    }
    handleUpdateCupPoints(text, viElement) {
        this.props.editTUser.setCupPoints(parseInt(text))
        // save
        this.handleSaveAction(this.props.editTUser);
    }
    clickedSave(e) {
        const {setErrorFct, disableSubmitFct} = this.context;
        const {tournament, editTUser,  user} = this.props;
        e.preventDefault();
        // check result parseInt able
        const sumPoints = parseInt(this.refSumPoints.current.getValue());
        if (!Number.isInteger(sumPoints)) {
            setErrorFct({message: messages.get("tournament.modal.score.nan.points")});
            return
        }
        disableSubmitFct(true);
        let actTRoundUser = this.state.selRoundUser;
        let oldPoints = null;
        if (actTRoundUser == null) {
            // Create new actTRoundUser
            actTRoundUser = new TournamentRoundUser();
            actTRoundUser.setTournamentRoundID(this.state.selRound);
            actTRoundUser.setTournamentUserID(this.props.editTUser);
            actTRoundUser.setCountTypeID(this.state.selCountType);
        } else {
            oldPoints = actTRoundUser.getRoundPoints() + ""
        }
        // missValue
        let hits = this.state.selCountType.calcHits(this.state.selRoundUserKillCounts);
        let missValue = this.state.selRound.getTargetAmounts() - hits;
        let killValue = this.state.selCountType.calcKillValue(this.state.selRoundUserKillCounts);
        let killCountsStr = this.state.selCountType.buildKillCountStringFromDic(this.state.selRoundUserKillCounts, missValue);
        console.log("OLD: " + actTRoundUser.getRoundPoints() + " / " + actTRoundUser.getKillValue() + " / " + actTRoundUser.getKillCounts())
        console.log("NEW: " + sumPoints + " / " + killValue + " / " + killCountsStr);
        actTRoundUser.setRoundPoints(sumPoints);
        actTRoundUser.setKillValue(killValue);
        actTRoundUser.setKillCounts(killCountsStr);
        actTRoundUser.setStatusFinished();
        // save
        this.handleSaveAction(actTRoundUser);
        changeLogActions.addTournamentChange(tournament, user, actTRoundUser.getTournamentRoundID().getName()  + ", " + editTUser.getFullName() + ", Punkte", oldPoints , sumPoints + "")
    }
    handleSaveAction(saveObject) {
        const {closeFct, setErrorFct} = this.context;
        tournamentUserManagerActions.saveTournamentUserRound(saveObject, (success) => {
            if (success) {
                tournamentUserManagerActions.reloadTournamentUser(this.props.editTUser)
                closeFct();
            } else {
                // handle save error
                setErrorFct({message: messages.get("Ergebnis konnte nicht gespeichert werden")});
            }
        });
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {tournament, editTUser, tournamentRoundsAll, countTypes} = this.props;
        const {tFilteredRoundCalcUsers, selRound, selRoundUser, selRoundUserKillCounts, selCountType} = this.state;
        const disableInputFields = selRoundUser != null && !selRoundUser.isStatusFinished()
        // TODO - translations!!
        return (
            <form action="#">
                <Row>
                    <Col xs={6}>
                        <DropDownParseObject label={messages.get("tournament.modal.score.tround")}
                                             default={selRound}  onAfterChange={this.changeSelectedRound}
                                             objectList={tournamentRoundsAll}/>
                    </Col>
                    <Col xs={6}>
                        {
                            tFilteredRoundCalcUsers === null ? null:
                                <DropDownParseObject label={messages.get("tournament.modal.score.troundUser")}
                                                     onAfterChange={this.changeSelectedUserRound}
                                                     placeholder={(selRound == null && selRound.isBestOf()) || tFilteredRoundCalcUsers.length === 0 ?
                                                                  <option key={"nullkey"} value={"null"}>{messages.get("tournament.modal.score.enterNewResult")}</option> : null}
                                                     default={selRoundUser}
                                                     objectList={tFilteredRoundCalcUsers}/>
                        }

                    </Col>
                    <Col xs={6}>
                        <strong>Wertung: </strong>{selCountType === null ? <LoadingCircle /> : messages.get(selCountType.getName())}
                    </Col>
                        {
                            selRoundUser != null ? <Col xs={6}>
                                { selRoundUser.isStatusFinished() ?
                                    <Button bsStyle="warning" onClick={() => this.changeTUserRoundStatus(TournamentRoundUser.prototype.status.inactive)}>
                                        <FontAwesome icon="ban"><I18n code="tournament.table.column.result"/>&nbsp;<I18n code="modal.button.deactivate"/></FontAwesome>
                                    </Button>
                                    :
                                    <Button bsStyle="success" onClick={() => this.changeTUserRoundStatus(TournamentRoundUser.prototype.status.finished)}>
                                        <FontAwesome icon="check"><I18n code="tournament.table.column.result"/>&nbsp;<I18n code="modal.button.activate"/></FontAwesome>
                                    </Button>
                                }
                            </Col> : null
                        }
                </Row>
                <hr/>
                {
                    tournament.isTypeCupChild() && tournament.isClosed() ? <React.Fragment><Row>
                        <Col xs={6}>
                            <ValidInput label={messages.get("tournament.table.column.cupPoints")}
                                        valid={{maxLength: 4, check:['isNumber']}}
                                        savePressed={this.handleUpdateCupPoints}
                                        default={editTUser.getCupPoints()}/>
                        </Col>

                    </Row><hr/></React.Fragment> : null
                }

                <Row>
                    <Col xs={4} sm={4}>
                        <ValidInput ref={this.refSumPoints} disabled={true} label={messages.get("tournament.modal.score.calc.points")}/>
                    </Col>
                    <Col xs={4} sm={4}>
                        <ValidInput ref={this.refHitMiss} disabled={true}
                                    label={messages.get("tournament.modal.score.calc.hitmiss")}/>
                    </Col>
                    <Col xs={4} sm={4}>
                        <ValidInput ref={this.refManCorr} type="number" disabled={disableInputFields}
                                    label={messages.get("tournament.modal.score.man.correction")}
                                    valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                    onAfterChange={(value) => this.handleUpdatePoints("MAN", value)}/>
                    </Col>
                </Row>
                <Row>
                    {
                        selCountType == null ? null :
                            <TournamentCountTypeDetails selCountType={selCountType} disableInputFields={disableInputFields}
                                                        selRoundUserKillCounts={selRoundUserKillCounts}
                                                        updatePoints={this.handleUpdatePoints}
                            />
                    }
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled || selRound == null || selRound.isClosed()} block bsStyle="success">
                            <I18n code={"tournament.register.submit"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentScoreForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    editTUser: PropTypes.object,
    singleStartedTRound :PropTypes.object,
    tournamentRoundsAll: PropTypes.array,
    countTypes: PropTypes.array
};
TournamentCountTypeDetails = ({selCountType, disableInputFields, selRoundUserKillCounts, updatePoints}) => {
    function onChange(key, newValue) {
        updatePoints(key, newValue)
    };
    return <React.Fragment>
        {
            selCountType.getCountTypeDtls().map(countTypeDetail => {
                const points = countTypeDetail.getPoints();
                const tArrow = countTypeDetail.getArrowNr();
                const tZone = countTypeDetail.getKillNr();
                const key = tArrow * 1000 + tZone;
                const actCount = selRoundUserKillCounts[key];
                return <Col key={"COL" + key} xs={4} sm={4}>
                    <ValidInput default={actCount} label={points} type="number" disabled={disableInputFields}
                                valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                onAfterChange={(value) => onChange(key, value)}/>
                </Col>
            })
        }
    </React.Fragment>
};
TournamentCountTypeDetails.propTypes = {
    selCountType: PropTypes.object.isRequired,
    selRoundUserKillCounts: PropTypes.object.isRequired
};
TournamentScoreForm.contextType = ModalBasicContext;
module.exports = TournamentScoreModal;
