/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    DateInput = require("components/form/DateInput"),
    messages = require("i18n/messages");

const clubActions = require("actions/ClubActions");

class ClubUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.refNumber = React.createRef();
        this.refEntryDate = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.clickedOK = this.clickedOK.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            club: null,
            clubUser: null,
            error: null
        }
    }
    open(clubUser) {
        this.setState({
            showModal: true,
            club: this.props.club,
            clubUser: clubUser
        });
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    clickedOK() {
        this.close();
    }
    valid() {
        let valid = true;
        let required = [
            this.refNumber.current.getValue().trim()
        ];
        //
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        this.state.clubUser.setUserNumber(this.refNumber.current.getValue().trim());
        // handle optional birthday
        const actEntryDate = this.state.clubUser.getEntryDate();
        if (this.refEntryDate.current.getValue() != null && this.refEntryDate.current.getValue().trim().length > 0) {
            // birthday entered
            let newEntryDate = new Date(this.refEntryDate.current.getValue());
            if (actEntryDate != null) {
                // compare them
                if (newEntryDate.getTime() != actEntryDate.getTime()) {
                    // changed update it
                    this.state.clubUser.setEntryDate(newEntryDate);
                }
            } else {
                this.state.clubUser.setEntryDate(newEntryDate);
            }
        } else {
            // unset?
            if (actEntryDate != null) {
                this.state.clubUser.unset("entryDate");
            }
        }
        clubActions.saveClubUser(this.state.clubUser);
        this.close();
    }
    render() {
        let validateNow = this.state.error && this.state.error.length > 0;
        let name = "";
        if (this.state.clubUser != null) {
            name = this.state.clubUser.getFullName();
        }
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code={name}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    {
                                        <Col xs={12}>
                                            <ValidInput ref={this.refNumber}
                                                        valid={{maxLength: 10, check:['isRequired']}}
                                                        label={messages.get("address.userNr")}
                                                        default={this.state.clubUser.getUserNumber()}
                                                        validateNow={validateNow}/>

                                            <DateInput ref={this.refEntryDate} default={this.state.clubUser.getEntryDate()}
                                                       label={messages.get("address.entryDate")}
                                                       addonBefore={<FontAwesome icon="calendar-alt"/>}/>
                                        </Col>
                                    }
                                    {this.state.error == null ? null : <Alert bsStyle="danger">{this.state.error}</Alert>}
                                    <br/><br/>
                                    <Col xs={12}>
                                        <Button onClick={this.clickedSave} disabled={this.state.submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                </Row>
                            </form>: null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
ClubUserModal.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object,
};
module.exports = ClubUserModal;