const alt = require("AltInit");
const actions  = require("actions/ImageGalleryActions");

class ImageGalleryStore {
    constructor() {
        this.displayName = "ImageGalleryStore";
        this.loading = true;
        this.uploading = false;
        this.imageGalleryList = [];
        this.searchParams = {
            text: ""
        };
        this.bindListeners({
            updateImageGalleryList: actions.updateImageGalleryList,
            createImageGallery: actions.createImageGallery,
            removeImage: actions.removeImage,
            addImage: actions.addImage
        });
    }

    updateImageGalleryList(imageGallList) {
        this.imageGalleryList = imageGallList;
        this.loading = false;
    }

    removeImage(imageGallery) {
        this.updateImageGalleryList(
            this.imageGalleryList.filter(function (u) {
                return u.id != imageGallery.id;
            })
        );
    }

    addImage(imageGallery) {
        this.imageGalleryList.unshift(imageGallery);
        this.uploading = false;
    }

    createImageGallery() {
        this.uploading = true;
    }
}
module.exports = alt.createStore(ImageGalleryStore);
