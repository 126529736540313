/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, ButtonGroup, Modal, Image} = require("react-bootstrap");
const messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput");

const notificationActions = require("components/notification/NotificationActions");

class ImageSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.refWebLink = React.createRef();
        this.openModal = this.openModal.bind(this);
        this.close = this.close.bind(this);
        this.previousImage = this.previousImage.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.updateIndex = this.updateIndex.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.updateImageWebLink = this.updateImageWebLink.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            image:null,
            imageGalleryList: null,
            index : -1
        }
    }
    openModal(imageGalleryList, index) {
        this.setState({
            showModal: true,
            image: imageGalleryList[index],
            imageGalleryList: imageGalleryList,
            index: index
        });
    }
    close() {
        this.setState({ showModal: false, submitDisabled: false,
            imageGalleryList: null,
            image: null,
            index : -1
        });
    }
    previousImage() {
        let actIndex = this.state.index;
        if (actIndex > 0) {
            actIndex--;
            this.updateIndex(this.state.imageGalleryList, actIndex);
        }
    }
    nextImage() {
        let actIndex = this.state.index;
        if (actIndex < (this.state.imageGalleryList.length - 1)) {
            actIndex++;
            this.updateIndex(this.state.imageGalleryList, actIndex);
        }
    }
    updateIndex(imageGalleryList, newIndex) {
        this.setState({
            image: imageGalleryList[newIndex],
            index: newIndex
        }, () => {
            // update url of input field
            if (imageGalleryList[newIndex].getWeblink() != null) {
                this.refWebLink.current.setValue(imageGalleryList[newIndex].getWeblink())
            } else {
                this.refWebLink.current.setValue("")
            }
        });

    }
    selectImage() {
        let actIndex = this.state.index;
        if (this.props.callbackSelectedImage) {
            this.props.callbackSelectedImage(this.state.imageGalleryList[actIndex]);
            this.close();
        } else {
            console.log("No prop callbackSelectedImage ");
        }
    }
    updateImageWebLink() {
        if (this.refWebLink.current != null) {
            let weblink = this.refWebLink.current.getValue();
            let image = this.state.image;
            image.setWeblink(weblink);
            image.save()
                .then(sImage => {
                    this.setState({image: sImage});
                    notificationActions.success("Saved url: " + weblink);
                })
                .catch(error => {
                    console.log("UpdateWeblink", error)
                });
        }
    }
    render() {
        const {image} = this.state;
        const webLinkForm= <ValidInput ref={this.refWebLink}
                                       valid={{maxLength: 500, check: ['isUrl']}}
                                       placeholder={messages.get("tournament.create.union.link")}
                                       buttonAfter={<Button onClick={this.updateImageWebLink}><FontAwesome icon="save"/></Button>}/>
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header>
                    {
                        this.state.showModal ?
                            <Modal.Title>
                                <Col sm={5} xs={4}>
                                    {
                                        image == null ? "" :
                                            <ButtonGroup>
                                                <Button onClick={this.previousImage}><FontAwesome icon="arrow-left"/></Button>
                                                <Button onClick={this.nextImage}><FontAwesome icon="arrow-right"/></Button>
                                            </ButtonGroup>
                                    }
                                </Col>
                                <Col sm={6} xs={6}>
                                    <Button bsStyle="success" onClick={this.selectImage}>
                                        <FontAwesome icon="check"/>{messages.get("modal.button.take")}</Button>
                                </Col>
                                <Col sm={1} xs={2} >
                                    <Button onClick={this.close}><FontAwesome icon="times"/></Button>
                                </Col>
                                <br/>
                            </Modal.Title> : null
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <Row>
                                {
                                    image == null ? "" : <GalleryImage imageGallery={image}>{webLinkForm}</GalleryImage>
                                }
                            </Row> : null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
const GalleryImage = ({imageGallery, children}) => {
    var imageView = null;
    if (imageGallery.isTypeImage()) {
        imageView = <Image responsive  src={imageGallery.getImageUrl()}/>
    } else if (imageGallery.isTypePDF()){
        // TODO think about rendering pdf
        imageView = <a target="_BLANK" href={imageGallery.getImageUrl()}>
            <Image rounded src="/img/ico/icon-download.png" width="48" height="48"/>&nbsp;{imageGallery.getImageName()}
        </a>
    } else {
        // TODO think about rendering documents
        imageView = <a target="_BLANK" href={imageGallery.getImageUrl()}>
            <Image rounded src="/img/ico/icon-download.png" width="48" height="48"/>&nbsp;{imageGallery.getImageName()}
        </a>
    }
    return (
        <Col xs={12}>
            <p>{imageGallery.getImageDate()}</p>
            <p>{children}</p>
            {imageView}
            <br/>
        </Col>)
};
GalleryImage.propTypes = {
    imageGallery:PropTypes.object.isRequired
};
module.exports = ImageSelectionModal;