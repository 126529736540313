const Parse = require("ParseInit");
const TranslationCl = require("parse/TranslationCl");
class LanguageDao {

    loadTranslations() {
        const query = new Parse.Query(TranslationCl);
        // query.include(TranslationCl.prototype.col.editor);
        query.limit(1000);
        query.descending(TranslationCl.prototype.col.createdAt);
        query.notEqualTo("status", "D");
        return query.find();
    }
    loadTranslationsForEnvironment(envColumn) {
        const query = new Parse.Query(TranslationCl);
        query.equalTo(envColumn, true);
        query.limit(1000);
        query.ascending(TranslationCl.prototype.col.key);
        query.notEqualTo("status", "D");
        return query.find();
    }
}
module.exports = new LanguageDao();
