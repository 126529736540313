const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Link} = require ('react-router-dom');

const ParcoursOwnerHomeLink = ({parcoursOwner, label}) =>  {
    return <Link to={linkUtil.getLink("parcours/" + parcoursOwner.id + "/home")}>
        {label ? label : parcoursOwner.getName()}
        </Link>
};
ParcoursOwnerHomeLink.propTypes = {
    parcoursOwner: PropTypes.object.isRequired,
    label: PropTypes.string,
};
module.exports = ParcoursOwnerHomeLink;
