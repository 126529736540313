module.exports = {
    "form.validation.errors": "Nicht alle Felder sind korrekt befüllt",
    "not.available.geolocation": "Ihr Browser unterstützt keine Geo-Locations. Updaten Sie Ihren Browser.",
    "code.unknown": "Unbekannt",
    "parse.error.101": "Username oder Password falsch",
    "modal.button.ok": "OK",
    "modal.button.close": "Schließen",
    "modal.button.cancel": "Abbrechen",
    "modal.button.take": "Übernehmen",
    "modal.button.save": "Speichern",
    "modal.button.delete": "Löschen",
    "modal.button.remove": "Entfernen",
    "modal.button.restore": "Wiederherstellen",
    "modal.button.edit": "Editieren",
    "modal.button.activate": "Aktivieren",
    "modal.button.deactivate": "Deaktivieren",
    "modal.button.challenge": "Herausfordern",
    "modal.button.attach": "Hochladen und hinzufügen",
    "modal.button.send":"Senden",
    "modal.button.newEntry": "Neuer Eintrag",
    "modal.button.search": "Suche",
    "modal.button.next": "Weiter",
    "modal.button.download": "Herunterladen",
    "modal.button.add2cup":"Zum Cup hinzufügen",
    "modal.button.show":"Zeige",
    "domain.user.detail.sex.0": "Männlich",
    "domain.user.detail.sex.1": "Weiblich",
    "userAbo.type.T":"Tester",
    "userAbo.type.D":"Spender",
    "userAbo.type.P":"Bezahlt",
    "userAbo.type.V":"VIP",
    "userAbo.type.A":"Admin",
    "userAbo.feature.S":"Statistik",
    "userAbo.feature.S.desc":"Eine gute Grafik sagt mehr als 1000 Zahlen",
    "userAbo.feature.R":"Schützen Ranking",
    "userAbo.feature.R.desc":"Bestenliste der Schützen pro Parcours",
    "userAbo.feature.G":"GPS Track",
    "userAbo.feature.G.desc":"Gegangene Routen mit Treffern und Zielen",
    "userAbo.feature.Z":"Parcours Ziele",
    "userAbo.feature.Z.desc":"Ah, da ist das Kill",
    "userAbo.feature.T":"Trainingsmodul",
    "userAbo.feature.T.desc":"Viel hilft viel",
    "userAbo.feature.K":"Trefferbild-Eingabe",
    "userAbo.feature.K.desc":"",
    "userAbo.faIcon.S":"chart-line",
    "userAbo.faIcon.R":"cubes",
    "userAbo.faIcon.G":"map",
    "userAbo.faIcon.Z":"bullseye",
    "userAbo.faIcon.T":"user-clock",
    "Dialog_Body_premium_needed_for_gps":"Zum Aufzeichnen eines GPS Tracks benötigen sie ein aktives Premium Abo",
    "link.customer.register": "Kundenanmeldung",
    "modal.decision.title.default": "Achtung",
    "modal.decision.body.becameMainEditor": "Möchten sie die Verwaltung dieses Turniers übernehmen?",
    "modal.login.title": "Login für 3D Turnier und 3D SKill Board App",
    "modal.login.username": "Login und Benutzername",
    "modal.login.email": "E-Mail",
    "modal.login.password": "Passwort",
    "modal.login.password.repeat": "Passwort wiederholen",
    "modal.login.password.change":"Passwort ändern",
    "modal.login.password.enterNew":"Bitte geben sie ihr neues Passwort ein",
    "modal.login.button.login": "Login",
    "modal.login.button.forgot": "Passwort vergessen",
    "modal.login.button.register": "Neu erstellen",
    "modal.login.button.login.back": "zum Login",
    "modal.login.error.password.match": "Die Passwörter stimmen nicht überein",
    "modal.login.error.missing.required": "Bitte alle Felder ausfüllen",
    "modal.login.error.missing.username": "Bitte Username oder Email eingegeben",
    "modal.login.error.missing.password": "Bitte Passwort eingegeben",
    "modal.login.error.userToken.used" : "Der Link ist nicht mehr gültig. Wenn nötig fordern sie einen neuen Link an!",
    "modal.login.save.pwd.success":"Ihr Password wurde erfolgreich geändert",
    "modal.login.save.success":"Ihre Änderung wurde erfolgreich gespeichert",
    "modal.password.reset.header":"Passwort zurücksetzen",
    "modal.password.reset.body":"Es wird ein Link an folgende E-Mail Adresse zum Zurücksetzen des Passwort gesendet:",
    "modal.password.reset.mail.success":"Passwort reset erfolgreich angefordert - sie bekommen eine E-Mail mit dem Link um ein neues Passwort auszuwählen",
    "modal.password.reset.invalidLink":"Der verwendete Link ist ungültig oder inzwischen abgelaufen - bitte versuchen sie es erneut",
    "modal.password.reset.mailACK" : "Danke, Ihre E-Mail wurde erfolgreich verifiziert.",
    "modal.password.reset.success" : "Ihre Password wurde erfolgreich geändert.",
    "modal.addEditor.header": "Übersicht Editoren",
    "modal.addEditor.addMail": "E-Mail des neuen Editors eingeben",
    "modal.addEditor.editorlist": " Aktuelle Editoren",
    "modal.addEditor.mainEditor": " Haupteditor",
    "modal.payment.header": "Zielkonto angeben",
    "modal.percentage.header": "Gebühr je Zahlungsart in Prozent angeben",
    "modal.address.header": " Adresse editieren",
    "modal.mail.enterMailList": "E-Mails - Komma getrennt eingeben",
    "modal.mail.invitation.header": "Einladung zum Verein an diese E-Mails senden",
    "modal.mail.mailList.header": "E-Mail Liste der Mitglieder",
    "modal.message.placeholder.message": "Nachricht eingeben",
    "modal.message.type": "Nachricht senden als:",
    "modal.message.subject": "Betreff",
    "modal.message.sender": "Absender:",
    "modal.message.receiver": "Empfänger:",
    "modal.message.notarget": "Für die Nachricht konnte keine Zielgruppe ermittelt werden",
    "modal.bgParams.header":"Parameter für Hintergrundbilder",
    "modal.license": "Lizenz Nr",
    "modal.license.header": "Verband und Klassen wählen sowie Lizenznummer eingeben.",
    "modal.license.chooseUnion":"Bogensportverband auswählen ...",
    "modal.license.validUntil": "Gültig bis",
    "modal.license.chooseBow": "Bogenklasse auswählen ...",
    "modal.license.chooseAge": "Altersklasse auswählen ...",
    "modal.license.choose":"auswählen ...",
    "modal.license.chooseCountType": "Wertung auswählen ...",
    "modal.license.add": "Verbandslizenz hinzufügen",
    "modal.license.empty":"Keine Verbandslizenzen gefunden",
    "modal.license.noUserDetail":"Sie müssen zuerst die Persönlichen Daten eingeben bevor sie Verbandslizenzen hinzufügen können",
    "modal.typelist.subHeader":"Verfügbare Einträge:",
    "modal.typelist.entries" : "Einträge:",
    "modal.entry.mandatory":"Pflicht",
    "modal.entry.optional":"Optional",
    "modal.entry.attachment":"Anhang",
    "msg.type.mail":"E-Mail",
    "msg.type.notify":"Benachrichtigung",
    "parse.error.202": "Es existiert bereits ein Konto mit diesem Namen",
    "parse.error.203": "Es existiert bereits ein Konto mit dieser e-mail",
    "parse.error.205": "Password kann nicht zurückgesetzt werden. Es existiert kein Benutzer mit dieser E-Mail",
    "parse.error.119": "Sie haben keine Zugriffsberechtigung auf diese Daten",
    "parse.error.125": "Es wurde eine ungültige e-mail eingeben",
    "parse.error.100": "Die Verbindung zum Hintergrundsystem konnte nicht aufgebaut werden.",
    "parse.error.141": "Fehler bei der Verarbeitung: ",
    "parse.error.offline":"Entschuldigung - Unsere Datenbank ist aktuell nicht erreichbar. Bitte versuchen sie in ein paar Minuten wieder.",
    "parse.error.unexpected": "Unerwarteter Fehler",
    "parse.error.expected": "Fehler",
    "Dialog_Body_Error_invalid_session" : "Ihre Sitzung ist nicht mehr gültig. Bitte melden sie sich ab und danach wieder neu an. Dies dient Ihrer Sicherheit.",
    "token.panel.header.loading":"Lade und prüfe Token",
    "token.panel.header.PWreset":"Passwort ändern",
    "token.panel.header.UserMailAuthent":"E-Mail Validierung",
    "token.panel.header.ClubMailAuthent":"E-Mail Validierung für Vereine",
    "token.button.UserMailAuthent":"Bestätige E-Mail: ",
    "form.valid.not.number": "Zahl eingeben",
    "form.valid.not.negNumber" :" Zahl darf nicht negativ sein",
    "form.valid.not.greater0" :" Zahl muss größer als 0 sein",
    "form.valid.not.url": "Ungültige URL - starte mit http",
    "form.valid.not.date": "Ungültiges Datum (dd.mm.yyyy)",
    "form.valid.not.time": "Ungültige Uhrzeit (hh:mm)",
    "form.valid.not.email": "Bitte eine gültige E-Mail eingeben",
    "form.valid.not.phone": "Ungültige Telefonnummer",
    "form.valid.not.point4comma": "Bitte benutzen Sie einen Punkte als Komma",
    "form.valid.not.noSpecSign":"Bitte keine Sonderzeichen benutzen",
    "form.valid.not.coordinates": "Koordinaten im Format (Latitude,Longitude  zB 48.175829,14.741720) eingeben",
    "fileupload.description": "Datei auswählen (max 5 MB)",
    "fileupload.selectFile": "Bitte eine Datei zu Speicher auswählen",
    "fileupload.selectFileToLarge": "Die Datei darf nicht größer sein als 5 MB",
    "fileupload.selectFileInvalidExt": "Es sind nur von folgende Dateitypen erlaubt:",
    "fileupload.edit.bgParams": "Hintergrundbild Parameter",
    "group.place.main.title": "Land",
    "group.place.region.title": "Region",
    "group.place.main.all": "Alle Länder",
    "group.place.region.all": "Alle Regionen",
    "countType.3P3Z_20_f": "Jagdrunde (3P 3Z)",
    "countType.3P2Z_20_f": "Allgemein (3P 2Z)",
    "countType.3P2Z_15_f": "Waldrunde (3P 2Z)",
    "countType.2P4Z_11_f": "2 Pfeile - 4 Zonen",
    "countType.1P3Z_15_f": "Hunterrunde (1P 3Z)",
    "countType.2P4Z_11_t": "WA 2014 (1+1P 4Z)",
    "countType.1P4Z_11_f": "IBO (1P 4Z)",
    "countType.1P5Z_14_f": "ASA (1P 5Z)",
    "countType.1P6Z_14_f": "Universal (1P 6Z)",
    "countType.1P3Z_20_f": "IFAA 3D Hunter (1P 3Z)",
    "countType.2P3Z_10_f": "IFAA 3D Doppelhunter (2P 3Z)",
    "countType.3P2Z_20_f_1": "IFAA 3D Standard (3P 2Z)",
    "countType.1P3Z_10_f": "Compound 1 Pfeil",
    "countType.2P3Z_10_f_1": "Compound 2 Pfeil",
    "countType.D6Z_6_t": "WA Feldscheibe",
    "countType.D10Z_10_t": "Scheibe 10Ring (1-10)",
    "countType.D6Z_10_t": "Scheibe 5Ring (6-10)",
    "countType.D7Z_10_t": "Scheibe 6Ring (5-10)",
    "countType.C4Z_20_f": "Bogenkino (5-20)",
    "countType.D4Z_5_t": "IFAA Feld u Hunter",
    "countType.disc": "Scheibe",
    "countType.singleArrow": "1 Pfeil",
    "countType.multiArrow": "Mehr Pfeil",
    "countType.arrowSumUp.true" : "Die Punkte aller Pfeile am Ziel werden addiert gewertet",
    "countType.arrowSumUp.false" : "Nur die Punkte des ersten Treffers (1., 2. oder 3. Pfeil) am Ziel werden gewertet",
    "countType.button.create3D" : "3D Wertung hinzufügen",
    "countType.button.createDisc" : "Scheiben Wertung hinzufügen",
    "countType.desc.arrowZones":"Wählen sie die Anzahl der Pfeile und Zonen je Ziel aus. Danach für jede Pfeil-Zonen Kombination die zu scorenden Punkte eingeben:",
    "countType.error.arrowZonesToLess":"Sie benötigen mehr als nur einen Pfeil und eine Zone!",
    "countType.error.duplicate":" Es gibt diese Wertung bereits",
    "countType.error.duplicateName":"Es gibt bereits eine Wertung mit diesem Namen",
    "countType.error.duplicateZone":"Sie müssen unterschiedliche Zonennamen verwenden in den Drop Downs für die Zonen",
    "countType.save.info":"Bitte geben sie einen Namen und eine sinnvolle Beschreibung für eine neue Wertung ein. Erstellte Wertungen werden zuerst von uns überprüft bevor sie für alle 3D SKill Board Apps freigegeben (Synchronisieren) werden. Wertungen können von Ihnen NICHT nachträglich editiert werden. Bei Fragen kontaktieren sie uns - wir helfen Ihnen gerne weiter.",
    "countType.save.success" : "Die Wertung wurde als Entwurf gespeichert und wird innerhalb der nächsten Tagen von uns überprüft. Sie bekommen eine E-mail wenn die Überprüfung abgeschlossen ist.",
    "countType.search.placeholder":"Suche in Name, Beschreibung und Punkte des 1.Pfeils (zB 20,18,16)",
    "ResultStatBean_arrow":"Pfeil",
    "ResultStatBean_zone":"Zone",
    "Count_type_name_10Disc" : "Scheibe 10Ring (1-10)",
    "Count_type_name_10Disc5RingCenter" : "Scheibe 5Ring (6-10)",
    "Count_type_name_10Disc6RingCenter" : "Scheibe 6Ring (5-10)",
    "Count_type_name_1Arrow" : "Hunterrunde (1P 3Z)",
    "Count_type_name_1Arrow_3" : "IFAA 3D Hunter (1P 3Z)",
    "Count_type_name_1Arrow_4" : "IBO (1P 4Z)",
    "Count_type_name_1Arrow_5" : "ASA (1P 5Z)",
    "Count_type_name_1Arrow_6" : "Universal (1P 6Z)",
    "Count_type_name_1add1Arrow_4" : "WA 2014 (1+1P 4Z)",
    "Count_type_name_2Arrow" : "2 Pfeile - 4 Zonen",
    "Count_type_name_2Arrow_3" : "IFAA 3D Doppelhunter (2P 3Z)",
    "Count_type_name_3Arrow_2_IFAA" : "IFAA 3D Standard (3P 2Z)",
    "Count_type_name_3Arrow_2h" : "Allgemein (3P 2Z)",
    "Count_type_name_3Arrow_2l" : "Waldrunde (3P 2Z)",
    "Count_type_name_3Arrow_3" : "Jagdrunde (3P 3Z)",
    "Count_type_name_4Disc" : "IFAA Feld u Hunter",
    "Count_type_name_6DiscField" : "WA Feldscheibe",
    "Count_type_checkBox_arrowSumUp" : "Summiere die Ergebnisse aller Pfeile pro Ziel",
    "Count_type_miss_points" : "Punkte für verfehlte Ziele",
    "Count_type_grp_private" : "Eigene Wertung",
    "Count_type_grp_ring" : "Scheiben Wertung",
    "Count_type_grp_system" : "Standard Wertung",
    "global_archers_scores":"Geteilte Wertung",
    "Frag_event_hit_10ring" : "10 ring",
    "Frag_event_hit_11ring" : "11 ring",
    "Frag_event_hit_12ring" : "12 ring",
    "Frag_event_hit_14ring" : "14 ring",
    "Frag_event_hit_1ring" : "1 ring",
    "Frag_event_hit_2ring" : "2 ring",
    "Frag_event_hit_3ring" : "3 ring",
    "Frag_event_hit_4ring" : "4 ring",
    "Frag_event_hit_5ring" : "5 ring",
    "Frag_event_hit_6ring" : "6 ring",
    "Frag_event_hit_7ring" : "7 ring",
    "Frag_event_hit_8ring" : "8 ring",
    "Frag_event_hit_9ring" : "9 ring",
    "Frag_event_hit_Kill" : "Kill",
    "Frag_event_hit_Xring" : "X ring",
    "Frag_event_hit_body" : "Körper",
    "Frag_event_hit_centerKill" : "Center Kill",
    "Frag_event_hit_live" : "Leben",
    "Frag_event_hit_wound" : "Verwundet",
    "yearPager.previous":"Vorheriges Jahr",
    "yearPager.next":"Nächstes Jahr",
    "yearPager.year":"Jahr",
    "yearPager.years":"Jahre",
    "monthPager.previous": "Vorheriges Monat",
    "monthPager.next": "Nächstes Monat",
    "monthPager.month": "Monat",
    "montPager.month.1": "Jänner",
    "montPager.month.2": "Februar",
    "montPager.month.3": "März",
    "montPager.month.4": "April",
    "montPager.month.5": "Mai",
    "montPager.month.6": "Juni",
    "montPager.month.7": "Juli",
    "montPager.month.8": "August",
    "montPager.month.9": "September",
    "montPager.month.10": "Oktober",
    "montPager.month.11": "November",
    "montPager.month.12": "Dezember",
    "header.nav.empty": "Thema auswählen",
    "header.nav.home": "Home",
    "header.nav.dashboard": "Dashboard",
    "header.nav.tournaments": "Turniere",
    "header.nav.tournamentRules": "Turnierregeln",
    "header.nav.clubs": "Vereine",
    "header.nav.3dskillboard": "3D SKill Board",
    "header.nav.3dskillboard.premium": "3D SKill Board Premium",
    "header.nav.premium": "PREMIUM",
    "header.nav.premium.next": "PREMIUM erneuern",
    "header.nav.parcours": "Parcours Wiki",
    "header.nav.contact": "Kontakt",
    "header.nav.more": "Weitere",
    "header.nav.solutions": "Lösungen",
    "header.nav.smarttournament": "Smart Turnier",
    "header.nav.policy": "Datenschutzbestimmungen",
    "header.nav.profile": "Mein Profil",
    "header.nav.training": "Mein Training",
    "header.nav.events": "Eventhistorie",
    "header.nav.scatter": "Trefferbild",
    "header.nav.account": "Kontodaten",
    "header.nav.login": "Login",
    "header.nav.logout": "Logout",
    "header.nav.agb": "AGBs",
    "header.nav.news":"News",
    "header.nav.translator": "Übersetzungen",
    "header.nav.shop" : "Shop",
    "header.nav.invoices":"Rechnungen",
    "header.nav.mailNotVerified":"Die E-Mail Adresse ihres Kontos ist noch nicht bestätigt. Ohne der Bestätigung können sie manche Funktionen von 3D SKill Board nicht nutzen. Bitte suchen sie in ihrem Posteingang und Spam nach der E-Mail oder fordern sie diese für folgende E-Mail Adresse neu an",
    "header.nav.accountLocked":"Dieser Account wurde gelockt. Es stehen nicht mehr alle Funktionen zu Verfügung. Bitte wende dich an office@3dturnier.com",
    "news.title": "3D Turnier Neuigkeiten",
    "news.empty.result" : "Keine Neuigkeiten gefunden",
    "news.button.search":"Suche Neuigkeiten",
    "news.button.search.placeholder":"Nachricht, Ort oder PLZ",
    "news.new_tournament":"Neues Turnier",
    "news.new_parcours":"Neuer 3D-Parcours",
    "news.new_field":"Neuer Feld-Parcours",
    "news.new_target":"Neuer Scheibenplatz",
    "news.new_cinema":"Neues BowSim 4D",
    "news.new_android_release":"Neue Android App",
    "news.new_ios_release":"Neue iOS App",
    "news.all":"alle Neuigkeiten ...",
    "management.feature.header": "Verfügbare Pakete",
    "management.feature.setting.denyWiki":"Nur Parcours-Editoren dürfen die Parcours Wiki Einträge verändern",
    "management.feature.setting.extendAbo":"Kein Editoren-Check um das Abo zu verlängern",
    "management.feature.package": "Paket",
    "management.feature.pricetax": "Alle Preise verstehen sich in EUR und inklusive USt.",
    "management.feature.pricebase":"*) Für ein Jahresabo wird unabhängig von den gewählten Paketen noch zusätzlich die Grundgebühr von € 55 fällig, außer für das Premium-Abo das alle Funktionen beinhaltet",
    "management.feature.test":"Sie wollen sich nicht gleich für ein Abo entscheiden? - Testen sie unsere Turnier- und Bezahllösung in einem Einzelturnier:",
    "management.feature.startdate": "Start",
    "management.feature.enddate": "Ende",
    "management.feature.yearsub": "Jahresabo",
    "management.feature.yearsub.renew": "Jahresabo verlängern",
    "management.feature.yearsub.payopen":"Bitte offene Rechnung begleichen!",
    "management.feature.yearsub.noEditor":"Nur Editoren des Business Kontos können das Jahresabo verlängern",
    "management.feature.base": "Grundgebühr",
    "management.feature.ST": "Einzelturnier",
    "management.feature.VA": "AboGutscheine",
    "management.feature.PI": "ParcoursWiki Werbung",
    "management.feature.TM": "Turnierverwaltung",
    "management.feature.TM.desc": "Die Turnierverwaltung ist eine schlüsselfertige Lösung für jedes 3D Bogenturnier. Ob Breitensport oder Leistungssport, eine oder mehrere Runden, Start am Pflock oder Fliegender Start - es wird alles einfach abgebildet und bei Bedarf umgehend erweitert.",
    "management.feature.TM.online": "Online Anmelde- und Verwaltungssystem",
    "management.feature.TM.payment": "Online-Bezahlen der Anmeldung",
    "management.feature.TM.app": "3D SKill Board App - ersetzt den Score Zettel",
    "management.feature.TM.live": "Liveübersicht des Geschehens für Zuschauer",
    "management.feature.TM.start": "Start am Pflock und fliegender Start",
    "management.feature.TM.unlimited": "Im Abo sind Turniere und Teilnehmer nicht limitiert",
    "management.feature.TM.classes": "Viele Bogen- und Altersklassen vorkonfiguriert",
    "management.feature.TM.basic":"Einfache Basiskonfiguration",
    "management.feature.TM.rounds":"Individuelle Turniere durch verschiedene Runden",
    "management.feature.TM.mmtu":"Nur eine Teilnehmertabelle die alles steuert",
    "management.feature.TM.groups":"Teilnehmergruppen und Startpunkt manuell oder automatisch zusammenstellen",
    "management.feature.CT": "Cup/Trophy Turnierreihe",
    "management.feature.CT.desc": "Die Turnierverwaltung für Cup/Trophy Turnierreihen",
    "management.feature.CT.price":"Für alle teilnehmenden Vereine inklusive Bezahlsystem zum Pauschalpreis",
    "management.feature.CT.licNumber":"Anmeldung mit persönlichen Cup/Trophy Nummer",
    "management.feature.CT.points":"Cup-Punkte und Streichresultate sind konfigurierbar",
    "management.feature.CT.result":"Cup-Resultat wird anhand der Cup/Trophy Nummer berechnet",
    "management.feature.CT.qrscan":"QR Scanner zur Anwesenheitskontrolle",
    "management.feature.PP": "Mobiles Bezahlsystem",
    "management.feature.PP.desc": "Voll integriertes Mobiles Bezahlsystem für Parcoursrunden und Turnier-Startgelder - ist in jedem Paket inkludiert.",
    "management.feature.PP.transaction": "Detaillierte Transaktionsübersicht und monatliche Abrechnung",
    "management.feature.PP.nocash": "Keine Probleme mit unpassendem Bargeld und der Kasse",
    "management.feature.PP.brands": "Visa, Mastercard, Sofortüberweisung, PayPal ...",
    "management.feature.PP.demo": "Video: App Parcours Zahlung",
    "management.feature.PA": "Parcours",
    "management.feature.PA.desc": "Das Parcours-Ranking zeigt die besten Schützen des Monats und animiert die Schützen zu häufigeren Trainings. Aus den vielen App-Nutzern ergeben sich interessante Statistiken über die Nutzung ihres Parcours.",
    "management.feature.PA.ranking": "Tagesaktuelles Schützen-Ranking des Parcours",
    "management.feature.PA.payment": "Online-Bezahlen einer Runde, der Tages- oder Jahreskarten",
    "management.feature.PA.stats":"Parcoursstatistik",
    "management.feature.PA.targets":"Detailkarte der Ziele am Parcours sowie deren Synchronisierung zur App",
    "management.feature.UM": "Vereinsverwaltung",
    "management.feature.UM.desc": "Die Vereinsverwaltung bringt Ordnung in die Vereinsinterna.",
    "management.feature.UM.member": "Verwaltung von Adressen, Lizenzen und Kontaktinformation von Mitgliedern",
    "management.feature.UM.msg": "Sende Mails und Notification an Mitglieder oder Gruppen",
    "management.feature.UM.events": "Erstelle Termine für Mitglieder oder Gruppen mit Abstimmungsfunktion",
    "management.feature.UM.inout": "Einnahmen-Ausgaben Rechnung inkl Jahresabschluss",
    "management.feature.PREM": "Voll-Abo",
    "management.feature.PREM.desc": "Der volle Funktionsumfang von 3DTurnier in einer Mitgliedschaft",
    "management.feature.PREM.save": "Sie sparen € 55 gegenüber den Einzelpreisen",
    "management.feature.PREM.all":"Alle Funktionen werden ohne Einschränkungen bereitgestellt.",
    "management.feature.ADV": "Werbung",
    "management.feature.ADV.desc": "Wir bieten ein breites Portfolio an zielgerichteter Werbung für Bogenschützen. Welche wir ihnen in einem persönlichen Beratungsgespräch gerne näher erläutert.",
    "management.request.form.submit": "Anmelden",
    "management.request.form.header.host": "Sind sie der Betreiber dieses Parcours?",
    "management.request.form.info.host": "Auf dieser Seite können sie die Verwaltung dieses Parcours beantragen. Das ermöglicht ihnen unsere Funktionen wie zB Online-Bezahlsystem oder die Turnierverwaltung unbeschränkt zu benutzen.",
    "management.request.form.info.solutions": "Hier finden sie eine Übersicht über unsere Lösungen und Preise.",
    "management.request.form.info.contact": "Wir werden uns in den nächsten Tagen bei Ihnen melden",
    "management.request.form.info.login": "Bitte melden sie sich an um die Verwaltung eines Parcours beantragen zu können!",
    "management.request.form.info.already": "Sie sind bereits Verwalter dieses Parcours.",
    "management.request.form.info.other": "Der Parcours wird bereits von jemand anderen verwalten.",
    "management.request.form.header.Adress": "Vereins oder Firmenanschrift",
    "management.request.form.header.Contact": "Kontakt",
    "management.request.form.header.features":"Gewünschte Pakete auswählen",
    "management.request.form.header.fillForm":"Bitte füllen Sie das Formular aus.",
    "management.request.form.fillOwner":"Parcours-Daten ins Formular übernehmen",
    "management.request.business.header":"Business Konto",
    "address.userNr": "Mitgliedsnummer",
    "address.union": "Vereins- oder Firmenname",
    "address.street": "Strasse",
    "address.zip": "PLZ",
    "address.place": "Ort",
    "address.contactName": "Ansprechperson",
    "address.phone": "Telefonnummer",
    "address.extRefID":"Referenznummer",
    "address.uid" : "UID",
    "address.mail": "E-Mail",
    "address.birthday":"Geburtsdatum",
    "address.birthYear":"Geburtsjahr",
    "address.sex":"Geschlecht",
    "address.entryDate": "Eintrittsdatum",
    "address.contact.primary":"Primäre Kontaktdaten",
    "address.contact.secondary":"Alternative Kontaktdaten",
    "payTarget.cardName": "Name des Kontoinhabers",
    "payTarget.cardBIC": "BIC",
    "payTarget.cardIBAN": "IBAN",
    "payTarget.payCondition": "Zahlungskondition",
    "parcours.management.already.requested":"Sie haben das Parcoursmanagement angefordert.",
    "parcours.management.request.cancel":"Abbruch",
    "parcours.create.title":"Neuen Wiki-Eintrag erstellen",
    "parcours.create.description":"Bitte den Namen des Betreibers oder des Vereins sowie die PLZ des Ort eingeben in dem sich der Parcours befindet.",
    "parcours.create.submit":"Eintrag erstellen",
    "parcours.create.name":"Betreiber- oder Vereinsname",
    "parcours.create.addParcours":"Eintrag zu diesem Betreiber hinzufügen",
    "parcours.create.addMyParcours":"Parcours hinzufügen",
    "Dialog_Header_createReuseParcours":"Eintrag neu erstellen oder zu einem bestehenden wechseln?",
    "parcours.add.title":"Neuen Wiki Eintrag hinzufügen zu",
    "parcours.add.description":"Bitte den Namen des neuen Parcours eingeben. (Ein umgestellter Parcours ist nicht neu :-)",
    "parcours.add.name":"Name des Parcours",
    "parcours.result.empty":" Keine Parcours gefunden",
    "parcours.search.nearby": "Parcours in der Nähe",
    "parcours.search.nearby.label": "Umkreissuche",
    "parcours.search.noHttps": "Zugriff auf die Position kann nur mit einer HTTPS Verbindung gewährt werden - bitte prüfen sie am Smartphone ob GPS aktiviert ist.",
    "parcours.search.noPosition":"Ihr Browser liefert leider keine Position - bitte prüfen sie am Smartphone ob GPS aktiviert ist.",
    "parcours.promo.coords.title":"Hilf das Parcours Wiki zu verbessern!",
    "parcours.promo.coords.subline":"Trage fehlende Koordinaten bei diesem Parcours ein:",
    "home.title": "Willkommen<span class='hidden-xs hidden-sm'> bei <b>3D Turnier</b> und <b>3D SKill Board</b></span>",
    "home.carousel.skillboard": "3D SKill Board - Die Bogensport App",
    "home.carousel.skillboard2": "3D SKill Board - Schnelle und intuitive Treffer Erfassung",
    "home.carousel.app": "3D SKill Board - Die Nummer 1 der digitalen Parcourshilfen",
    "home.carousel.tournament": "3D Turnier - Scorezettel war gestern",
    "home.carousel.parcours": "Parcours Wiki - alle machen mit",
    "home.carousel.shop" :"3D SKill Board - Online Shop",
    "home.carousel.shop.link":"Zum Shop ...",
    "home.tournaments.title": "Aktuelle Turniere",
    "home.parcours.title": "Partner Parcours",
    "home.parcours.payBtn": "Runde bezahlen",
    "home.parcours.navBtn": "Navigiere",
    "home.panel.tournament.header": "3D Turniere",
    "home.panel.tournament.html": "<p>Die Turnierplattform bietet einen übersichtlichen \
                                            Turnierkalender und jede/r registrierte Schütze/in kann ein Turnier veröffentlichen. </p>\
                                        <p>Die Verwaltung eines Turniers ermöglicht die komplette Durchführung eines 3D Turniers..</p>",
    "home.panel.tournament.link": "Zu den Turnieren ...",
    "home.panel.tournament.all": "alle Turniere ...",
    "home.panel.skillboard.header": "3D SKill Board",
    "home.panel.skillboard.html": "<p>Die smarte 3D Bogensport App - Scorezettel war gestern!\
                                        <br/><br/>Schnelles und intuitives Erfassen der Treffer je Schütze und stets aktueller Punktestand beim 3D Bogenschießen. </p>\
                                        <ul>\
                                            <li>Schützen/innen und Parcours speichern</li>\
                                            <li>Treffer je Schütze und Ziel eingeben</li>\
                                            <li>Betrachten von gespeicherten Events</li>\
                                            <li>Verschiedene Wertungen, auch in einem Event</li>\
                                        </ul>",
    "home.panel.skillboard.link": "mehr...",
    "home.panel.skillboard.download": "App herunterladen...",
    "home.panel.parcours.header": "Parcours Wiki",
    "home.panel.parcours.html": "<p>Alle wesentlichen Informationen eines Parcours lassen sich hier sammeln und finden. Alle registrieren 3D SKill Board Benutzer können dazu beitragen, dass die Informationen aktuell bleiben und fehlende Informationen und Parcours hinzufügen oder Editieren.</p>\
                                    <ul>\
                                        <li>385 Parcours sind aktuell gelistet, davon:</li>\
                                        <li>202 in Österreich</li>\
                                        <li>145 in Deutschland</li>\
                                        <li>16 in Italien</li>\
                                    </ul>",
    "dashboard.title": "Dashboard",
    "dashboard.parcours.title": "Meine Parcours",
    "home.panel.parcours.link": "Zu den Parcours ...",
    "home.panel.parcours.all": "Alle Parcours ...",
    "home.panel.solutions.header": "Smarte Lösungen",
    "home.panel.solutions.html": "<p>Für Parcours-Betreiber und Turnierveranstalter als Verein oder Gewerbe:</p>\
                                    <ul>\
                                        <li>Parcours übergreifendes mobiles Bezahlen</li>\
                                        <li>Turnierverwaltung mit Echtzeit-Auswertung</li>\
                                        <li>Vereinsverwaltung</li>\
                                        <li>Zielgerichtete Werbung</li>\
                                    </ul>",
    "home.panel.solutions.link": "Mehr erfahren ...",
    "promo.link.contact": "Kontaktieren sie uns ...",
    "promo.subheader.solution": "Wir bieten einfache Lösungen für die IT Herausforderungen des Bogensports für Vereine und gewerbliche Betreiber.",
    "promo.subheader.consult": "Einzelne Pakete oder gleich die gesamte Lösung - Gerne beraten wir sie für ihren gesteigerten Erfolg.",
    "club.mailNotVerified":"Die E-Mail Adresse des Vereins ist noch nicht bestätigt. Ohne der Bestätigung können sie keine E-Mails von der Vereinsverwaltung and die Mitglieder schicken. Bitte suchen sie in ihrem Posteingang und Spam nach der E-Mail oder fordern sie diese für folgende E-Mail Adresse neu an",
    "club.button.create": "Verein hinzufügen",
    "club.button.search": "Suche Vereine",
    "club.button.join": "Verein beitreten",
    "club.button.exportCU": "Mitglieder Export",
    "club.button.newEntry": "Neuer Vereinstermin",
    "club.button.newMessage": "Neue Nachricht",
    "club.button.mail.log": "E-Mail Logs",
    "club.empty.result": "Keine Vereine gefunden",
    "club.create.title" : "Neuen Verein erstellen",
    "club.edit.title": "Verein-Stammdaten",
    "club.create.name": "Vereinsname",
    "club.detail.actual": "Aktuelles",
    "club.detail.calendar": "Termin Kalender",
    "club.detail.invalidUser": "Sie sind nicht berechtigt den Verein oder deren Mitglieder zu bearbeiten.",
    "club.detail.noUserDetail": "Keine Adresse",
    "club.detail.comment":"Kommentar",
    "club.member.header": "Vereinsmitglieder",
    "club.member.modal.create.header": "Neues Mitglied ohne 3D Turnier Konto erzeugen?",
    "club.member.modal.create.description": "Benachrichtigungen und Interaktion mit Vereinsterminen funktioniert nur mit aktiven 3D Turnier Konto des Mitglieds",
    "club.member.modal.edit.header": "Persönliche Daten des Mitglied editieren",
    "club.member.modal.delete.header": "Mitglied löschen?",
    "club.member.modal.delete.ask": "Möchten sie das ausgewählte Mitglied löschen?",
    "club.member.button.search": "Suche Mitglieder (Name, E-Mail, PLZ, Ort)",
    "club.member.button.invite":"Mitglieder einladen",
    "club.member.button.create":"Mitglied erstellen",
    "club.member.type.member":"Mitgliedschaftsarten",
    "club.member.type.invoice":"Rechnungsartikel",
    "club.group.modal.title":"Gruppendefinition",
    "club.group.modal.input":"Neuen Gruppennamen eingeben",
    "club.group.modal.desc":"Beim Löschen einer Gruppe wird auch die Zuordnung der Mitglieder zu dieser Gruppe entfernt!",
    "club.group.modal.existing":"Verfügbare Gruppen",
    "club.group.modal.functions":"Funktionen",
    "club.group.modal.role": "Rolle",
    "club.group.modal.groups": "Gruppen",
    "club.group.modal.chooseArticle": "Mitgliedschaft wählen",
    "club.mail.clubLink":"Zur Vereinsseite ...",
    "club.mail.clubEventLink":"Zum Termin ...",
    "club.mail.clubuser.request":"Anfrage für Vereinsbeitritt",
    "club.mail.invitation": ".. möchte Sie als Vereinsmitglied. Bitte folgen sie unten stehenden Link zur Vereinsseite und drücken dort Verein beitreten.",
    "club.mail.ending":"Liebe Grüße",
    "club.need.account": "Sie benötigen dafür ein 3D SKill Board/3Dturnier Benutzerkonto.",
    "club.event.create.title": "Vereinstermin erstellen",
    "club.event.create.visibility.title":"Sichtbarkeit",
    "club.event.create.visibility.desc":"Termin ist sichtbar für:",
    "club.event.create.visibility.group.desc": "Auf gewählte Gruppen einschränken(sonst an alle):",
    "club.event.create.draft":"Entwurf",
    "club.event.create.publish":"Veröffentlichen",
    "club.event.create.mail":"Vereinsmitglieder per E-mail benachrichtigen",
    "event.vis.public":"Öffentlich",
    "event.vis.club":"Nur Verein",
    "club.event.create.message.title":"Nachricht",
    "club.event.create.interaction.title":"Interaktion",
    "club.event.create.place.title":"Ort",
    "club.event.create.place.place":"Geben sie einen Standort ein (Optional)",
    "club.event.create.message.name":"Terminname",
    "club.event.create.message.note":"Beschreibung",
    "club.event.create.opt.linksecret":"Code für internen Link - Ermöglicht Antwort geben ohne Login",
    "club.event.create.opt.new":"Neue Antwortmöglichkeit eingeben",
    "club.event.create.opt.existing":"Verfügbare Antwortmöglichkeiten",
    "club.event.column.answer":"Antworten",
    "club.event.column.missing.answer":"Noch keine Antworten",
    "club.event.filter.current":"Aktuelle",
    "club.event.filter.past":"Vergangene",
    "club.event.modal.delete.header":"Vereinstermin löschen?",
    "club.event.modal.delete.ask":"Möchten sie den ausgewählten Vereinstermin löschen?",
    "club.event.foreign.notVisible": "Dieser Termin ist für Vereinsfremde nicht sichtbar",
    "club.event.intern.notVisible": "Dieser Termin ist nur für bestimmte Gruppen im Verein sichtbar",
    "club.invoice.header":"Belegübersicht",
    "club.invoice.month.header":"Monatsübersicht",
    "club.invoice.type.header":"Typ Übersicht",
    "club.invoice.button.create":"Beleg hinzufügen",
    "club.invoice.button.search":"Suche Belege (Nummer, Text, Beschreibung, Betrag)",
    "club.invoice.type.modal.title":"Belegtypen definieren",
    "club.invoice.type.modal.input":"Neuen Belegtyp eingeben",
    "club.invoice.button.exportInv":"Beleg Export",
    "club.invoice.amount.in.true":"Eingang",
    "club.invoice.amount.in.false":"Ausgang",
    "club.invoice.balance":"Saldo",
    "club.invoice.modal.create.header":"Neuen Beleg hinzufügen",
    "club.invoice.modal.edit.header":"Beleg editieren",
    "club.invoice.modal.chooseInvType":"Belegtyp auswählen",
    "club.invoice.modal.delete.header":"Beleg löschen?",
    "club.invoice.modal.delete.ask":"Möchten sie den ausgewählten Beleg löschen?",
    "club.invoice.modal.paid.header":"Beleg als bezahlt markieren",
    "club.invoice.modal.paid.ask":"Möchten sie den ausgewählten Beleg als bezahlt markieren?",
    "club.invoice.checkout":"Rechnung bezahlen",
    "club.invoice.creation":"Rechnung erstellen",
    "club.invoice.customer.club":"Kunde aus Vereinsmitglieder",
    "club.invoice.customer.business":"Kunde aus Business Accounts",
    "club.invoice.noCustomer":"Noch keine Kunde ausgewählt!",
    "club.invoice.addNote":"Zusätzliche Info auf der Rechnung",
    "club.invoice.open":"Per Rechnung zahlen",
    "club.invoice.open.pay":"Zahlungspflichtig bestellen",
    "club.invoice.open.waiting":"Warte auf Zahlungseingang",
    "club.invoice.paymentTarget.hint":"Wird benötigt um Bezahlung auf Rechnung zu erlauben",
    "club.business.newAccount.desc1": "Für die unlimitierte Benutzung der Vereinsverwaltung benötigen sie ein aktives Business Konto.",
    "club.business.link2BusinessAccount.desc" :"Die Verknüpfung zu ihrem Business Konto mit aktivierter Vereinsverwaltung erlaubt ihnen die Nutzung der Vereinsverwaltung inkl Termine, Abstimmungen und Benachrichtigungen bei unlimitierter Mitgliederanzahl sowie der Einnahmen-Ausgaben Rechnung. Gratis können sie lediglich 10 Mitglieder verwalten!",
    "club.business.noClubFeature.desc" :"In ihrem Business Konto ist die Vereinsverwaltung nicht aktiviert, bitte melden sie sich bei office@3dturnier.com!",
    "mail.sent.success": "Mail erfolgreich versendet",
    "mail.sent.failed": "Mail konnte nicht versendet werden",
    "push.sent.success": "Benachrichtigung erfolgreich versendet",
    "push.sent.failed": "Benachrichtigung konnte nicht versendet werden",
    "cl_role_owner":"Inhaber",
    "cl_role_user":"Mitgliederverwaltung",
    "cl_role_msg":"Nachrichtenverwaltung",
    "cl_role_invoice":"Ein- Ausgabenrechnung",
    "cl_role_address":"Nur Adresse",
    "cl_role_invcreation":"Rechnungsverwaltung",
    "cl_role_invstat":"Ein- Ausgabenstatistik",
    "cl_role_member":"Mitglied",
    "cl_role_exMember":"Mitglied inaktiv",
    "cl_req_status.R": "Anfrage offen",
    "cl_req_status.I": "Einladung offen",
    "cl_req_status.A": "akzeptiert",
    "cl_req_status.X": "abgelehnt",
    "tournament.empty.result": "Keine Turniere gefunden.",
    "tournament.title": "Turniere",
    "tournament.invitation": "Turnier Einladung",
    "tournament.info.title": "Turnier Info",
    "tournament.info.regStart" : "Turnieranmeldung startet am",
    "tournament.info.wikiLink": "Parcours Details im Wiki anzeigen",
    "tournament.info.important": "Wichtig:",
    "tournament.info.app": "Nutze 3D SKill Board beim Turnier",
    "tournament.info.isCupChild":"Dieses Turnier ist Teil d. ",
    "tournament.info.isCupChild.link":"Gesamtergebnis ...",
    "tournament.info.cupChilds":"Einzelturniere",
    "tournament.live.groupin": "Gruppen der ",
    "tournament.live.groups": "Turnier Gruppen",
    "tournament.live.tuserin": "Teilnehmer/innen der ",
    "tournament.live.empty": "Noch keine Live-Ergebnisse von heute gefunden für ",
    "tournament.live.noGroupInfo": "Keine Gruppeninfo",
    "tournament.live.groupStarted": "Gruppe soeben gestartet",
    "tournament.live.noStartedRounds": "Aktuell ist keine Turnierrunde gestartet",
    "tournament.live.createGroup":"Gruppenvorschlag erstellen",
    "tournament.live.draft":"Entwurf",
    "tournament.live.addTuser":"Teilnehmer zur Gruppe hinzufügen (max 6)",
    "Frag_Prep_startposition":"Startpflock",
    "tournament.sendToApp": "An 3D SKill Board senden",
    "tournament.status.published": "Veröffentlicht",
    "tournament.status.regStart": "Anmeldung ab",
    "tournament.status.published.tooltip":"Turnier vorbereiten - Turnieranmeldungen werden angezeigt und Turnier erscheint noch nicht in der App 3D SKill Board",
    "tournament.status.register.open": "Anmeldung offen",
    "tournament.status.register.closed": "Anmeldung geschlossen",
    "tournament.status.register.full" : "Anmeldungen voll",
    "tournament.status.register.impossible" : "Turnier muss für das Starten der Anmeldung noch konfiguriert werden",
    "tournament.paystatus.active" : "Mobiles Bezahlsystem aktiviert",
    "tournament.paystatus.inactive" : "Mobiles Bezahlsystem deaktiviert",
    "tournament.status.running": "Läuft gerade",
    "tournament.status.running.tooltip":"Turnier starten - Zwischenergebnis wird angezeigt und Teilnehmer einer gestarteten Gruppe können auch in er App das Turnier starten",
    "tournament.status.canceled":"Abgesagt!",
    "tournament.status.closed": "Ergebnisse online",
    "tournament.status.closed.tooltip":"Turnier beenden - Endergebnis wird angezeigt und es sind keine Änderungen durch Apps mehr möglich",
    "tournament.status.present" : "Wartend",
    "tournament.status.cupMasterActive" : "Bestätigt",
    "tournament.status.finished" : "Beendet",
    "tournament.button.noCupLicense": "Erste Cup Anmeldung - ich hab noch keine Nummer",
    "tournament.button.create": "Turnier hinzufügen",
    "tournament.button.register": "Turnieranmeldung",
    "tournament.button.unregister": "Abmelden",
    "tournament.button.more": "Mehr",
    "tournament.button.show.more": "weitere Einträge anzeigen ...",
    "tournament.button.menu.manager": "Manager",
    "tournament.button.menu.mmc": "Turnier Config",
    "tournament.button.menu.mmtu": "Turnier Teilnehmer",
    "tournament.button.menu.scan": "QR Scan",
    "tournament.button.menu.files": "Dateien",
    "tournament.button.menu.rounds": "Runden Config",
    "tournament.button.becameMainEditor": "Turnierverwaltung übernehmen",
    "tournament.button.manage": "Verwalten",
    "tournament.button.gotoTournamentDashboard": "Turnierverwaltung",
    "tournament.button.register.more": "Andere Schützen anmelden",
    "tournament.button.register.groups":"Gruppeneinteilung vornehmen",
    "tournamentUser.button.search": "Suche nach Teilnehmer (Name oder Verein)",
    "tournamentUser.status.registered":"angemeldet",
    "tournamentUser.status.A":"anwesend",
    "tournamentUser.status.S":"gestartet",
    "tournamentUser.status.F":"beendet",
    "tournamentUser.status.waiting":"wartend",
    "tournamentUser.status.disqualified":"disqualifiziert",
    "tournamentUser.status.D":"gelöscht",
    "tournamentUser.status.proof":"1 Ergebnis",
    "tournament.button.search": "Suche Turniere",
    "tournament.filter.current": "Aktuelle",
    "tournament.filter.results": "Ergebnisse",
    "tournament.filter.region": "Region",
    "filter.button.list": "Liste",
    "filter.button.map": "Karte",
    "filter.header.display": "Anzeigen in",
    "tournament.status.registration.closed": "Anmeldung geschlossen",
    "tournament.status.registration.notregistered": "nicht registriert",
    "tournament.status.registration.registered": "registriert",
    "tournament.status.registration.waitinglist": "auf Warteliste",
    "tournament.attendee.status.paid": "Registrierung wurde bestätigt.",
    "tournament.attendee.status.pending": "Registrierung noch nicht bestätigt. Sobald die Bezahlung eingegangen ist, wird der Veranstalter ihre Registrierung bestätigen.",
    "tournament.attendee.status.legend": "Legende",
    "tournament.attendee.status.legend.paid": "Angemeldet, bezahlt",
    "tournament.attendee.status.legend.pending": "Angemeldet, nicht bezahlt",
    "tournament.attendee.paid": "bezahlt",
    "tournament.attendee.notpaid": "nicht bezahlt",
    "tournament.attendee.status.legend.exceed": "Auf der Warteliste",
    "checkout.info.accept.rules" : "Ich bestätige die Richtigkeit meiner Angaben und akzeptiere die",
    "checkout.info.rules" : "Parcoursregeln",
    "checkout.info.choose.brand": "Bezahlmethode auswählen:",
    "checkout.info.mail": "Nach Kaufabschluss erhalten sie eine E-Mail von 3DTurnier mit Sitz in Österreich mit der Rechnung als Bestätigung",
    "checkout.info.forward": "Für den Bezahlvorgang werden Sie auf die sichere Seite unseres Bezahldienstes Stripe Inc. weitergeleitet. Ihre persönlichen Kreditkarten- oder Kontodaten die Sie eingeben werden nur dort verarbeitet. Aber nicht an 3DTurnier selbst weitergeleitet oder gespeichert.",
    "checkout.info.parcours.cart": "Warenkorb Parcours",
    "checkout.info.parcours.cart.empty": "Es gibt leider keine Artikel die bezahlt werden können",
    "checkout.info.asklogin":"Loggen Sie sich ein, um später ihre Transaktionen im Profil einsehen zu können.",
    "checkout.info.noLogin":"Sie können auch ohne Login fortfahren.",
    "checkout.info.profile":"Ihre Adresse wird für die Erstellung Ihrer Rechnung benötigt.",
    "checkout.info.need.account.VA.DB":"Sie benötigen einen aktiven PayPal Account um die Runde bezahlen zu können!",
    "checkout.info.weblink.VA.DB":"https://www.paypal.com/",
    "checkout.button.payment" : "Bezahlen",
    "checkout.error.articles":"Bitte zumindest einen Artikel auswählen!",
    "checkout.error.paycode":"Bitte eine Bezahlmethoden auswählen!",
    "checkout.error.address":"Bitte Persönlich Daten eingeben!",
    "tournament.register.panel.title": "Eigene Anmeldungen",
    "tournament.register.panel.pay": "Anmeldungen bezahlen",
    "tournament.register.panel.selectedTU": "Teilnehmer ausgewählt",
    "tournament.register.panel.selectTUser": "Wählen sie aus der Liste oben die Teilnehmer aus, für die sie bezahlen wollen",
    "tournament.register.panel.cartTU": "Warenkorb Turnieranmeldungen",
    "tournament.register.panel.info.payInfo": "Wähle einfach die Schützen/innen aus für die du bezahlen möchtest! Wir bieten die Zahlung mit VISA, Mastercard, EPS, Giropay, Google und Apple Pay sowie PayPal.",
    "tournament.register.panel.info.payInvoice": "Nach Kaufabschluss erhalten sie eine E-Mail mit der Rechnung als Bestätigung.",
    "tournament.register.panel.info.payDesc": "Bezahle online und sichere deinen Startplatz!",
    "tournament.register.panel.info.nologin": "Sie sind nicht eingeloggt. Hier würden die von ihnen angemeldeten Teilnehmer angezeigt wenn sie sich einloggen oder sich bei der Turnieranmeldung für ein Konto entscheiden.",
    "tournament.register.panel.info.noregistration": "Noch keine Anmeldungen von ihnen für dieses Turnier.",
    "tournament.register.panel.info.nopayableregistration": "Keine bezahlbaren Anmeldungen gefunden.",
    "tournament.register.panel.info.paymentDisabled": "Leider hat der Turnierveranstalter die Online Bezahlfunktion nicht aktiviert.",
    "tournament.register.full": "Weitere Anmeldung sind aktuell nicht möglich - es sind bereits alle Startplätze für das Turnier vergeben.",
    "tournament.register.private.info":"Bei diesem Turnier ist eine Anmeldung nur mit dem Link aus der Einladung möglich!",
    "tournament.register.title": "Anmeldung bestätigen",
    "tournament.register.fullName": "Vor- und Nachname",
    "tournament.register.name": "Vorname",
    "tournament.register.surname": "Nachname",
    "tournament.register.search4name": "Suche nach Namen in bisherigen Anmeldungen",
    "tournament.register.email": "e-mail",
    "tournament.register.email.unique": "E-mail (Für jede Person wird eine eigene E-Mail Adresse benötigt)",
    "tournament.register.union": "Verein",
    "tournament.register.license": "Lizenznummer falls vorhanden",
    "tournament.register.mandatory.license": "Lizenznummer (0000 wenn noch nicht vorhanden)",
    "tournament.register.password": "Passwort angeben um ein Konto zu erstellen",
    "tournament.register.note": "Anmerkungen - zB Gruppenwunsch",
    "tournament.register.cupNo.with":"Turnieranmeldung mit ",
    "tournament.register.cupNo.where":"Die Nummern der Teilnehmer finden sie zB bei einer älteren Anmeldebestätigung sowie auf den Turnierergebnissen",
    "tournament.register.cupNo.why":"Nur Turnierergebnisse mit gleicher Nummer werden für das Gesamtergebnis berücksichtigt",
    "tournament.error.emailInUse": "Ihre Anmeldung zu diesem Turnier kann nicht durchgeführt werden, denn es existiert bereits eine Anmeldung mit dieser E-Mail:",
    "tournament.error.licenseInUse":"Ihre Anmeldung zu diesem Turnier kann nicht durchgeführt werden, denn es existiert bereits eine Anmeldung mit dieser Nummer:",
    "tournament.error.noCupTUser":"Es wurde keine Anmeldung bei bisherigen Turnieren mit der Nummer gefunden",
    "tournament.error.emaildoubled": "Ihre Anmeldung zu diesem Turnier kann nicht durchgeführt werden, sie müssen unterschiedliche E-Mails verwenden!",
    "tournament.error.loginOrOtherName": "Bitte loggen sie sich ein oder wählen sie einen anderen Namen für die Registrierung.",
    "tournament.error.loginOrOtherEMail": "Bitte loggen sie sich ein oder wählen sie eine andere e-mail für die Registrierung.",
    "tournament.error.noRegForEmail":"Es gibt keine Turnieranmeldung für sie mit ihrer Account-Email",
    "tournament.register.placeHolder.noemail": "Wenn die anzumeldende Person keine eMail besitzt, bitte verwenden sie: ",
    "tournament.register.sex.female": "weiblich",
    "tournament.register.sex.male": "männlich",
    "tournament.class.union": "Verband",
    "tournament.class.bow": "Bogenklasse",
    "tournament.class.age": "Altersklasse",
    "tournament.register.description.ageCalc":"Bestimmung der Altersklasse",
    "tournament.register.description.ageClassNull":"Altersklasse konnte nicht übernommen werden. Wählen sie die Altersklasse die zu ihrem Turnieralter passt",
    "tournament.register.description.noaccount": "Aktuell ist niemand eingeloggt.",
    "tournament.register.description.self": "Sie melden sich selbst für dieses Turnier an!",
    "tournament.register.description.foreign": "Andere Schützen/innen für dieses Turnier anmelden.",
    "tournament.register.description.without":"Ohne Konto zum Turnier anmelden",
    "tournament.register.description.getAccount":"Mit einem Konto bei 3D SKill Board und 3D Turnier werden zukünftige Turnieranmeldungen einfacher.",
    "tournament.register.description.fastRegister":"Schnelleres An- und abmelden von Turnieren",
    "tournament.register.description.reUse":"Ihre Anmeldedaten sowie die von zB Partnern, Freunden können wieder verwendet werden",
    "tournament.register.description.useApp":"Sie können auf einen Scorezettel verzichten und mit der 3D SKill Board App mitschreiben",
    "tournament.register.label.price": "Gesamtpreis",
    "tournament.register.label.payCondition": "Zahlungskonditionen",
    "tournament.register.label.payReference": "Zahlungsreferenz",
    "tournament.register.submit": "Bestätigen",
    "tournament.register.mail.deRegistrationSubject":"Abmeldung",
    "tournament.register.mail.registrationConfirm":"Anmeldebestätigung für",
    "tournament.register.mail.deRegistrationConfirm":"Abmeldebestätigung für",
    "tournament.register.mail.tournamentLink":"Zum Turnier ...",
    "tournament.register.mail.bestWished":"Viel Erfolg beim Turnier wünscht das 3D SKill Board Team",
    "tournament.register.mail.anyQuestions":"Haben Sie Fragen zu diesem Turnier?",
    "tournament.register.mail.askHost":"Wenden Sie sich an den Veranstalter",
    "tournament.register.mail.addCalendar":"Zu meinem Kalender hinzufügen",
    "tournament.register.mail.findTournaments":"Turniere entdecken",
    "tournament.register.mail.features": "3D Turnier bietet jedem die Möglichkeit Turniere zu erstellen, Turnieranmeldungen zu verwalten und das Turniere mit 3D SKill Board App Unterstützung durchzuführen.",
    "tournament.result.panel.title.endresult": "Endergebnis",
    "tournament.result.panel.title.intermediateresult": "Zwischenergebnis",
    "tournament.result.panel.print.full":"Druck Ergebnis PDF",
    "tournament.result.panel.print.third":"Druck Siegerehrung PDF",
    "tournament.result.panel.medalcount":"Medaillenspiegel",
    "tournament.result.link.live": "Live Übersicht",
    "tournament.result.panel.title.stat": "Teilnehmerstatistik",
    "tournament.rules.shoot": "Schießen",
    "tournament.rules.back": "Rückpraller und Durchschuss",
    "tournament.rules.count": "Zählweise",
    "gallery.title": "Foto Galerie",
    "gallery.show.all": "alle Fotos ...",
    "gallery.picture.report": "Foto melden",
    "gallery.picture.reported": "Foto gemeldet",
    "tournament.attendees.panel.title": "Teilnehmerliste",
    "tournament.attendees.panel.subtitle": "Anmeldungen",
    "tournament.create.title": "Neues Turnier erstellen",
    "tournament.create.name": "Titel des Turniers",
    "tournament.create.union": "Veranstalter oder Verein",
    "tournament.create.union.link": "Link zur Homepage",
    "tournament.create.zip": "PLZ",
    "tournament.create.place": "Ort",
    "tournament.create.date": "Startdatum (dd.mm.yyyy)",
    "tournament.create.enddate": "Enddatum (dd.mm.yyyy)",
    "tournament.create.days": "Tage",
    "tournament.create.submit": "Turnier erstellen",
    "tournament.create.error.required": "Nicht alle Felder ausgefüllt",
    "tournament.create.date.before":"Enddatum muss nach dem Startdatum sein",
    "tournament.create.time" :"Startzeit",
    "tournament.create.endtime" :"Endzeit",
    "parcours.title": "Parcours Wiki",
    "parcours.articles.form.name": "Name",
    "parcours.articles.form.group": "Gruppe",
    "parcours.articles.form.currency": "Währung",
    "parcours.articles.form.price": "Preis",
    "parcours.articles.form.brand": "Art",
    "parcours.articles.form.create":"Neuen Artikel anlegen:",
    "parcours.articles.info.allCourses":"Artikel für alle Parcours an diesem Ort:",
    "parcours.articles.info.singleCourse":"Artikel für:",
    "parcours.articles.link":"Zur Bezahlseite wechseln",
    "parcours.article.input.invalid":"Ungültige Werte für einen Artikel angegeben",
    "parcours.button.create": "Eintrag hinzufügen",
    "parcours.button.search": "Suche Parcours",
    "parcours.button.sendToApp": "In App übernehmen",
    "parcours.button.search.placeholder": "Name, Ort oder PLZ ",
    "parcours.transactions.header": "Transaktionen",
    "parcours.transactions.table.header": "Transaktionsliste",
    "parcours.transactions.table.flag.invalid":"Ungültige Transaktionen ebenfalls anzeigen",
    "parcours.transactions.table.download": "Als csv herunterladen",
    "parcours.transactions.stats.header": "Statistik",
    "parcours.transaction.empty": "Keine Transaktionen gefunden",
    "parcours.transactions.settlement.header": "Transaktionsabrechnung",
    "parcours.transactions.settlement.button.calc": "Abrechnung durchführen",
    "parcours.transactions.settlement.button.invoice":"Rechnung generieren",
    "parcours.transactions.settlement.invoice.send":"Rechnung an Betreiber senden",
    "parcours.transactions.settlement.empty": "Noch keine Abrechnung durchgeführt",
    "parcours.transactions.settlement.createdAt": "Erstellt am",
    "parcours.transactions.settlement.invoice.missing": "Rechnung noch nicht verfügbar",
    "parcours.transactions.settlement.mail.monthsettle":"Monatsabrechnung",
    "parcours.transactions.settlement.mail.invoiceLinkDesc":"Hier können sie die aktuelle Abrechnung downloaden:",
    "parcours.transactions.settlement.mail.transactionLinkDesc":"Die Details zur Abrechnung finden sie in der Transaktionsübersicht:",
    "parcours.transactions.settlement.mail.mfg":"Mit freundlichen Grüßen",
    "parcours.transactions.settlement.mail.skbteam":"3D SKill Board Team",
    "parcours.tracks.header":"Parcours Tracks",
    "parcours.targets.header":"Parcours Ziele",
    "parcours.targets.edit.header":"Parcours Ziele editieren",
    "parcours.targets.catalog.loading":"Lade den 3D Ziele Katalog",
    "parcours.targets.catalog.done":"3D Ziele Katalog fertig - Einträge:",
    "parcours.targets.input.image":"Url des Bild",
    "parcours.targets.input.log":"Änderungshistorie des Ziels durch die App",
    "parcours.targets.input.peg":"Pflock",
    "parcours.targets.input.edit":"Ziel/Pflock ändern",
    "parcours.targets.input.name":"neues 3D Tier hinzufügen",
    "parcours.targets.input.name.target":"Zielname",
    "parcours.targets.input.name.search":"Katalogsuche mit Name, Art# oder Hersteller",
    "parcours.targets.map.edit.header":"Klicken sie ändern beim jeweiligen Ziel oder fügen sie ein neues hinzu",
    "parcours.targets.action.delete":"Möchten sie das ausgewählte Ziel löschen",
    "parcours.targets.action.renumber":"Ziele neu nummerieren",
    "parcours.targets.action.renumber.notneeded":"Ziele sind bereits richtig nummeriert",
    "parcours.targets.action.invert":"Parcours-Geh-Richtung drehen",
    "parcours.targets.action.replace" :"Aktuelles Ziel mit diesen Daten überschreiben",
    "parcours.targetStatus.E":"Ziele Sync&Karte aktiv für alle - Änderungen nur ParcoursAdmin",
    "parcours.targetStatus.A":"Ziele Sync&Karte sowie Änderungen(App) aktiv für alle Benutzer",
    "parcours.targetStatus.P":"Ziele Sync&Karte sowie Änderungen(App) nur für 3D SKill Board Premium",
    "parcours.targetStatus.I":"Ziele Sync&Karte inaktiv",
    "parcours.invalidUser": "Sie sind nicht berechtigt den Parcours zu bearbeiten.",
    "parcours.invalidUser.desc":"Entweder sie sind nicht als Editor hinterlegt oder es wurde das Parcours-Paket nicht erworben.",
    "parcours.invalidUser.editor": "Nur Parcours-Editoren können Änderungen vornehmen.",
    "parcours.ranking.header": "Schützen Ranking",
    "parcours.ranking.empty": "Keine Einträge gefunden",
    "parcours.ranking.noParcoursFeature": "Für diesen Parcours stehen das Ranking als auch die synchronisierbaren Parcours Ziele nicht zur Verfügung - das Parcours-Paket wurde nicht erworben",
    "parcours.visit.header": "Parcours Buch",
    "parcours.visit.live":"Schützen/innen am Gelände",
    "parcours.visit.amount": "Anzahl der Schützen/innen",
    "parcours.visit.button.close": "Endzeit eintragen",
    "parcours.visit.button.delete":"Diesen Parcours Buch Eintrag löschen?",
    "parcours.visit.button.newEntry":"Neuer Eintrag für Parcours ...",
    "parcours.visit.disabled":"Auf diesem Parcours ist das digitales Parcoursbuch nicht aktiviert!",
    "parcours.visit.moreNames":"Bitte geben sie die Namen der weiteren Schützen/innen ein",
    "parcours.visit.not.closed":"Sie haben den Parcours noch nicht verlassen. Bitte schließen sie den Eintrag ab sobald sie den Parcours verlassen.",
    "parcours.visit.pay.online": "Bezahle kontaktlos mit PayPal, Kreditkarte oder Sofortüberweisung!",
    "parcours.visit.description.getAccount":"Mit einem Konto bei 3D SKill Board und 3D Turnier werden zukünftige Eintragungen einfacher.",
    "parcours.visit.description.reUse":"Ihre Anmeldedaten werden geladen und müssen nicht noch einmal eingegeben werden",
    "parcours.visit.description.amount":"Anzahl der Einträge im ausgewählten Zeitraum: ",
    "parcours.visit.description.scan1": "1) QR Code mit Smartphone einscannen",
    "parcours.visit.description.scan2": "2) Anmeldedaten mit 3D SKill Board oder im Web auf 3DTurnier.com eintragen",
    "parcours.visit.description.empty":"Das Parcoursbuch muss in der Parcours Verwaltung für die jeweiligen Parcours aktiviert werden!",
    "parcours.visit.enter.data":"Bitte tragen sie ihre Daten für den Parcoursbesuch hier ein:",
    "parcours.visit.list.header": "Parcours Buch History",
    "parcours.visit.accept":"Ich akzeptiere die Veröffentlichung meines Namens für die Kontrolle durch den Betreiber während meines Aufenthaltes..",
    "parcours.visit.pay.header":"Zahlungsart",
    "parcours.visit.pay.cash":"Bar-Zahler",
    "parcours.visit.pay.mobile":"Online-Zahler",
    "parcours.visit.pay.annual":"Jahreskarte, Mitglied",
    "parcours.visit.pay.cash.hint":"Bon oder Belegnummer",
    "parcours.filter.header":"Parcours Filter",
    "parcours.filter.placeholder":"Parcours wählen",
    "statistics.column.EventArrow":"Parcourspfeile",
    "statistics.column.TArrow":"Turnierpfeile",
    "statistics.column.TrainingArrow":"Trainingspfeile",
    "statistics.column.EventDuration":"Parcoursdauer",
    "statistics.column.TDuration":"Turnierdauer",
    "statistics.column.TrainingDuration":"Trainingsdauer",
    "statistics.header": "Jahresstatistik",
    "statistics.column.event.amount": "Events",
    "statistics.column.hit":"Treffer",
    "statistics.column.miss":"Verfehlt",
    "statistics.column.arrows":"Pfeile",
    "statistics.column.duration":"Dauer",
    "statistics.column.event.percent": "Prozent",
    "statistics.column.iOS":"iOS",
    "statistics.column.ANDR":"Android",
    "statistics.column.WEB":"Web",
    "statistics.column.EvDPS":"Parcours DPS",
    "statistics.column.TDPS":"Turnier DPS",
    "statistics.header.wikiclick":"Wiki Seite geöffnet",
    "statistics.header.wikieditclick":"Editiere Wiki Seite geöffnet",
    "statistics.header.payclick":"Online Bezahlsystem geöffnet",
    "statistics.header.finishedEvents":"Abgeschlossene Events",
    "statistics.header.finishedArchers":"Anzahl Schützen der abgeschlossenen Events",
    "statistics.header.finishedTargets":"Anzahl Ziele der abgeschlossenen Events",
    "ParcoursArticle_Group_single": "Einzel",
    "ParcoursArticle_Group_multi": "Mehrfach",
    "ParcoursArticle_Group_member": "Mitglied",
    "ParcoursArticle_Group_voucher": "Gutschein",
    "ParcoursArticle_Group_invoice": "Rechnung",
    "parcours.edit.message.noValue": "Speichern nicht möglich - Es wurde kein Wert eingegeben!",
    "parcours.edit.message.valueNotChanged": "Speichern nicht möglich - Wert wurde nicht geändert!",
    "parcours.edit.message.saved": "Erfolgreich gespeichert und veröffentlicht",
    "parcours.edit.message.noLogin": "Bitte melden sie sich an um Änderungen durchführen zu können",
    "parcours.edit.poBanner": "Betreiber- od Vereinsbanner",
    "parcours.details.targets": "Ziele",
    "parcours.details.area.parcours": "Parcours:",
    "parcours.details.area.constraints": "Einschränkungen:",
    "parcours.details.area.infra": "Infrastruktur:",
    "parcours.details.area.association":"Verband, Gemeinschaft:",
    "parcours.details.area.rating": "Wertung",
    "parcours.details.area.rateEntry": "Eintrag bewerten",
    "parcours.details.area.rating.comment": "Ihren Kommentar über den Eintrag",
    "parcours.details.area.allratings": "Alle Bewertungen",
    "parcours.details.area.prices": "Preise",
    "parcours.details.area.openTimes": "Öffnungszeiten",
    "parcours.details.area.supportTimes": "Zusätzliche Betreuungszeiten",
    "parcours.details.area.dayprice": "Preis pro Runde oder Tag",
    "parcours.details.area.specialprice": "Sonderpreise zB Zehnerblock",
    "parcours.details.area.yearprice": "Jahrespreis bzw Vereinsmitgliedschaft",
    "parcours.details.area.coordinates": "Koordinaten",
    "parcours.details.area.webpage": "Homepage",
    "parcours.details.area.description.owner": "Zusätzliche Beschreibung des Ort",
    "parcours.details.area.description.parcours": "Zusätzliche Beschreibung des Parcours",
    "parcours.details.area.targetamount": "Anzahl der Ziele",
    "parcours.details.area.pricesAtimes": "Preise und Öffnungszeiten",
    "parcours.details.area.ownerInfo": "Betreiber Info - gilt für alle Parcours des Betreibers",
    "parcours.details.area.parcoursInfo": "Parcours Info",
    "parcours.details.area.contact": "Kontakt",
    "parcours.details.area.coordinate.missing":"Es fehlt die Koordinate des Parcours im Wiki",
    "parcours.details.button.actions": "Aktionen",
    "parcours.details.button.editParcours": "Parcours editieren",
    "parcours.details.button.addParcours": "Neuen Wiki Eintrag hier hinzufügen",
    "parcours.details.button.takeManagement": "Parcours Verwaltung übernehmen",
    "parcours.details.button.deleteParcours": "Parcours löschen",
    "parcours.details.ask.deleteParcours": "Möchten sie den ausgewählten Parcours wirklich löschen?",
    "parcours.details.button.seeChangeLog":"Change log ansehen",
    "parcours.details.edit.description":"Jede Änderung wird durch Drücken der Speicher-Buttons bzw an/abhaken sofort veröffentlicht!",
    "parcours.details.imageGallery.add":"Bild zur Parcours-Galerie hinzufügen",
    "parcours.details.add.moreInfo":"Es sind noch wenige Informationen über diesen Parcours bekannt - bitte fügen sie fehlende Daten hinzu wenn sie Ihnen bekannt sind.",
    "parcours.details.last.change":"Letzte Änderung",
    "parcours.checkout.title": "Parcours Kasse",
    "cat_equip" : "Leihausrüstung",
    "cat_equip_request" : "Leihausrüstung nur auf Anfrage",
    "cat_shop" : "Bogenshop",
    "cat_dog" : "Hunde erlaubt",
    "cat_rooms" : "Gästezimmer",
    "cat_hotel" : "Hotel",
    "cat_webshop": "Online Shop",
    "cat_shootcinema" : "Bogenschießkino",
    "cat_diskus" : "Wurfscheibenschießen",
    "cat_toilet" : "Toilette",
    "cat_camping" : "Campingplatz",
    "cat_indoor" : "Schießhalle",
    "cat_cantina" : "Gaststätte",
    "cat_type_traditional" : "Traditioneller Bogen ",
    "cat_type_compound" : "Compound Bogen erlaubt",
    "cat_type_crossbow" : "Armbrust erlaubt",
    "cat_type_blowgun" : "Blasrohr erlaubt",
    "cat_nightparcours" : "Parcours auch bei Nacht begehbar",
    "cat_buggy" : "Kinderwagentauglich",
    "cat_wheel_chair" :"Rollstuhltauglich",
    "cat_refreshment": "Labe",
    "cat_area_flat" : "Areal des Parcours eher flach",
    "cat_area_steep" : "Areal des Parcours eher steil",
    "cat_area_damp" : "Parcours häufig matschig",
    "cat_area_forest" : "Großteils ein Waldparcours",
    "cat_area_lawn" : "Großteils ein Wiesenparcours",
    "type_private" : "Nur für Mitglieder",
    "type_request" : "Begehbar nur auf Anfrage",
    "type_summer" : "Begehbar nur im Sommer",
    "type_winter" : "Begehbar nur im Winter",
    "type_closed" : "Geschlossen",
    "type_oneHitOnly_PP" : "Nur Ein-Treffer Wertungen",
    "ass_oebsv":"Ö-Bogensportverband",
    "ass_oebst":"Ö-Bogensporttag",
    "ass_oebrsv":"Ö-Blasrohrsportverband",
    "ass_dsb":"D-Schützenbund",
    "ass_dfbv":"D-Feldbogenverband",
    "ass_dbsv":"D-Bogensportverband",
    "ParcoursInfo_private_parcours" : "Private oder nur Verein",
    "ParcoursInfo_type_P_parcours" : "3D Parcours",
    "ParcoursInfo_type_F_field" : "Feld-Parcours",
    "ParcoursInfo_type_T_target" : "Scheibenplatz",
    "ParcoursInfo_type_C_cinema" : "BowSim 4D",
    "ParcoursInfo_parown_openDays":"Geöffnete Tage:",
    "day_mo" : "Montag",
    "day_tu" : "Dienstag",
    "day_we" : "Mittwoch",
    "day_th" : "Donnerstag",
    "day_fr" : "Freitag",
    "day_sa" : "Samstag",
    "day_su" : "Sonntag",
    "day_ph" : "Feiertags",
    "tournament.table.column.position": "Position",
    "tournament.table.column.bow":"Bogen",
    "tournament.table.column.name": "Name",
    "tournament.table.column.teamname": "Teamname",
    "tournament.table.column.archer": "Schütze/in",
    "tournament.table.column.archers": "Schützen/innen",
    "tournament.table.column.date": "Datum",
    "tournament.table.column.time": "Uhrzeit",
    "tournament.table.column.union": "Verein",
    "tournament.table.column.status": "Status",
    "tournament.table.column.points": "Punkte",
    "tournament.table.column.sumPoints": "Punkte",
    "tournament.table.column.arrows": "Anzahl der Pfeile",
    "tournament.table.column.cupPoints": "Cup-Punkte",
    "tournament.table.column.target": "Ziel",
    "tournament.table.column.amount": "Anzahl",
    "tournament.table.column.sum": "Summe",
    "tournament.table.column.article.sum": "Artikelsumme",
    "tournament.table.column.discount": "Rabatt",
    "tournament.table.column.correction": "Korrektur",
    "tournament.table.column.profit": "Gewinn",
    "tournament.table.column.loss": "Verlust",
    "tournament.table.column.provision":"Gebühr",
    "tournament.table.column.income":"Einnahmen",
    "tournament.table.column.invoice" : "Rechnung",
    "tournament.table.column.type":"Typ",
    "tournament.table.column.address":"Adresse",
    "tournament.table.column.number":"Nr",
    "tournament.table.column.text":"Text",
    "tournament.table.column.inout":"Betrag",
    "tournament.table.column.article":"Artikel",
    "tournament.table.column.surcharge":"Aufpreis",
    "tournament.table.column.page":"Seite",
    "tournament.table.column.grpCode":"Grp Code",
    "tournament.table.column.active":"Aktiv",
    "tournament.table.column.image":"Bild",
    "tournament.table.column.color":"Farbe",
    "tournament.table.column.of":"von",
    "tournament.table.column.group": "Gruppe",
    "tournament.table.column.note": "Anmerkungen",
    "tournament.table.column.note.visit": "Anmerkungen (Bon-, Mitglied-, Annual ticket number)",
    "tournament.table.column.memberamount": "Teamgröße",
    "tournament.table.column.positions":"Positionen",
    "tournament.table.column.paid":"Bezahlt, Betrag",
    "tournament.table.column.licenseNumber":"Lizenz#",
    "tournament.table.column.dps":"DPS",
    "tournament.table.column.tax":"Ust",
    "tournament.table.column.details":"Details",
    "tournament.table.column.arrPasse":"Pfeile je Passe",
    "tournament.table.column.result":"Ergebnis",
    "tournament.table.column.updatedAt":"Änderung",
    "tournament.manager.bowage.conflict":"Bogen/Altersklasse Inkonsistenz",
    "tournament.manager.NotFound":"Turnier nicht gefunden",
    "tournament.manager.bannerImageID": "Turnierbanner",
    "tournament.manager.imageID": "Turniereinladung",
    "tournament.manager.resultImageID": "Turnierergebnis",
    "tournament.manager.sponsorImageID":"Banner des Sponsors",
    "tournament.manager.status":"Turnierstatus",
    "tournament.manager.file.title": "Hochladen von Turnierbanner, Einladung und Ergebnis PDF",
    "tournament.manager.config.title": "Konfiguration des Turniers, Parcours, Preise, Alters- und Bogenklassen",
    "tournament.manager.config.parcours":"Parcours auswählen",
    "tournament.manager.config.slots":"Startzeitraum",
    "tournament.manager.config.slots.desc":"Bei der Anmeldung zum Turnier müssen Teilnehmer ein Anmeldekontingent auswählen. Auf volle Kontingente ist keine weitere Anmeldung möglich. Ohne Kontingente können sich Teilnehmer anmelden - so lange die Anmeldung geöffnet ist.",
    "tournament.manager.config.slots.add":"Kontingent hinzufügen",
    "tournament.manager.config.slots.button.delete.desc":"Möchten sie das folgende Kontingent löschen?",
    "tournament.manager.config.slots.full":"Anmeldung nicht mehr möglich - keine freien Zeiträume/Kontingente",
    "tournament.manager.slot.modal.title":"Anmeldekontingent erstellen oder editieren",
    "tournament.manager.slot.modal.desc":"Bitte einen Namen sowie die maximalen Anmeldungen für das Kontingent eingeben",
    "Dialog_Body_tournament_error_slot":"Anmeldung im gewählten Startzeitraum nicht mehr möglich",
    "tournament.manager.config.advanced":"Erweiterte Turnierkonfiguration",
    "tournament.manager.config.bowUnion":"Wählen sie Verband-, Cup/Trophy oder eigenes Reglement.",
    "tournament.manager.config.cupMaster":"Bitte geben sie die Url zum Cup/Trophy Haupt-Turnier ein:",
    "tournament.manager.config.cupMaster.notActive":"Das Cup/Trophy Haupt-Turnier ist noch nicht bestätigt - die Konfiguration ist noch nicht fertig",
    "tournament.manager.config.cupMaster.wrongBowUnion":"Das Turnier gehört zu einem anderem Verband oder Cup/Trophy",
    "tournament.manager.config.cupMaster.NotMaster":"Das Turnier ist ein Cup/Trophy Teil-Turnier - es wird das Haupt-Turnier benötigt",
    "tournament.manager.config.bowAgeConfig.desc":"Die Bogen- und Altersklassen können im nächsten Schritt noch bearbeitet werden",
    "tournament.manager.config.maxAttendees":"Teilnehmerlimit",
    "tournament.manager.config.desc.label":"Wichtige Informationen zum Turnierablauf",
    "tournament.manager.config.desc.placeholder":"Fliegender oder Scheibenstart, Anmelde- und Startzeit,...",
    "tournament.manager.config.cupOptions":"Cup/Trophy Einstellungen",
    "tournament.manager.config.minCupResults":"Min Anzahl von Turnier Ergebnisse für Cup Wertung",
    "tournament.manager.config.baseTargets":"Platzierung im Cup anhand des Scores auf diese Zielanzahl umgerechnet",
    "tournament.manager.config.cupPoints":"Cup Punkte für die Platzierung - beginnend mit Erster, Zweiter, Dritter,..(CSV) ",
    "tournament.manager.config.doNotEdit": "Achtung, das Turnier ist bereits aktiv - manche Änderungen können fatale Folgen haben!",
    "tournament.manager.config.startReg":"Turnier zur Anmeldung freigeben",
    "tournament.manager.config.attendFinale":"Teilnahme am letzten Turnier ist Pflicht für die Cup Wertung",
    "tournament.manager.config.combineAge":"Kombiniere Altersgruppen für die Cup Wertung",
    "tournament.manager.config.combineAge.tConfig":"Haupt-Altersgruppe für kombinierte Gruppen",
    "tournament.manager.tConfig.pairReg":"Anmeldung nur als Schützen-Paar",
    "tournament.manager.tConfig.selCupGroup":"Eine Gruppe für das Cup Sub-Turnier auswählen",
    "tournament.manager.tConfig.agebowmodal.desc":"Geben sie der Gruppe einen Namen und optional einen Code oder Aufpreis der zum Grundpreis addiert wird.",
    "tournament.manager.tConfig.agebowmodal.countType":"Auswahl der Wertung",
    "tournament.manager.tConfig.agebowmodal.sexSplitHead":"Gruppe nach Geschlecht",
    "tournament.manager.tConfig.agebowmodal.sexSplitBody":"trennen",
    "tournament.manager.tConfig.agebowmodal.ages":"Altersgrenzen (<=, >=)",
    "tournament.manager.tConfig.payment.desccondition":"Bar, Überweisung, Bankverbindung ...",
    "tournament.manager.tConfig.payment.price":"Basispreis",
    "tournament.manager.tConfig.payment.surprice":"Aufpreis Barzahlung",
    "tournament.manager.tConfig.payment.pay4start":"Startplatz fixiert bei Vorauszahlung (Warteliste)",
    "tournament.manager.tConfig.payment.regMailCC":"Kopie der Anmeldungen erhalten",
    "tournament.manager.tConfig.complexPriceHandling":"Erweiterte Preisberechnung",
    "tournament.manager.tConfig.complexPriceHandling.desc":"Der Preis für den Schützen ist die Summe aus Basispreis und Aufpreis Altersklasse. Aufpreis Barzahlung wird zusätzlich bei Bar-Zahlung am Turniertag fällig ",
    "tournament.manager.tConfig.payment.paymail":"E-Mail Adresse für Rückfragen und Anmeldebestätigungen",
    "tournament.manager.tConfig.parcoursSelection":"Für die Zuordnung muss das Turnier einem Parcours aus dem Parcours Wiki zugeordnet werden. Sollte es ihren Parcours noch nicht geben - muss dieser vorher angelegt werden.",
    "tournament.manager.tConfig.parcours.manPoints":"Manuelle Punkteeingabe in der App erlauben (zB für Sonderziele)",
    "tournament.manager.tConfig.parcours.manTUGroups":"Teilnehmer können Gruppe selbst in App/Web erstellen und starten",
    "tournament.manager.tConfig.parcours.chooseTUGroups":"Teilnehmer kann vorgegebene Gruppe beim Start manuell auswählen(Leihgeräte)",
    "tournament.manager.tConfig.optionSelection":"Zusätzliches Auswahlfeld bei der Anmeldung - Eine Option muss vom Schützen gewählt werden",
    "tournament.manager.tconfig.option":"Zusätzliches Auswahlfeld für Anmeldung je Schütze",
    "tournament.manager.tconfig.option.surcharge":"Aufpreis für Auswahl",
    "tournament.manager.tconfig.cup.surcharge":"Aufpreis für Cup/Trophy Sub Turnier",
    "tournament.manager.tconfig.warning.noPrice":"Achtung - es sind keine Preise(Startgelder) bei den Altersklassen definiert!",
    "tournament.manager.wait4SubTournaments":"Führen sie die Cup/Trophy Sub Turniere durch",
    "tournament.manager.startConfig" : "Konfiguration des Turniers",
    "tournament.manager.openRegistration" :"Anmeldung öffnen",
    "tournament.manager.closeRegistration" :"schließen",
    "tournament.manager.fullRegistration" : "voll",
    "tournament.manager.sortBy":"Sortiere nach",
    "tournament.manager.group.round.start":"Starte die Gruppe für Runde:",
    "tournament.manager.group.round.selection":"Gruppe einer Runde zuweisen",
    "tournament.manager.public.groups":"Teilnehmer Gruppen öffentlich anzeigen",
    "tournament.manager.fixedABCD.groups":"Teilnehmerposition in Gruppe fixieren (ABCD..)",
    "tournament.manager.hide.points":"Teilnehmer Punkte während Runde verstecken",
    "tournament.manager.prepareTournament":"Vorbereiten",
    "tournament.manager.startTournament":"Start Turnier",
    "tournament.manager.startRound":"Start Runde",
    "tournament.manager.closeTournament":"Beenden",
    "tournament.manager.notAllRoundsClosed":"Es müssen zuerst alle Runden beendet werden, bevor das Turnier beendet werden kann.",
    "tournament.manager.takeManagement": "Übernehmen sie die Verwaltung eines Turniers um in den Konfigurations- und Verwaltungsbereich zu gelangen.",
    "tournament.manager.free": "Die Anmeldung und Verwaltung der Turnierteilnehmer ist kostenlos.",
    "tournament.manager.chargeable": "Die Durchführung des Turniers mit 3DTurnier und 3D SKill Board ist kostenpflichtig.",
    "tournament.manager.chargeable.tournament": "Das Paket Turniermanagement erlaubt es ihnen unlimitiert Turnier durchzuführen. Alternativ kann jedes Turnier einzeln abgerechnet werden.",
    "tournament.manager.chargeable.payment": "Das Paket Mobile Bezahlsystem ermöglicht es ihnen die Startgebühr direkt über 3DTurnier.com von den Teilnehmern zu kassieren.",
    "tournament.manager.invalidUser": "Sie sind nicht berechtigt dieses Turnier zu bearbeiten.",
    "tournament.manager.nextStep": "Nächster Schritt",
    "tournament.manager.delete": "Turnier löschen",
    "tournament.manager.delete.desc": "Möchten sie dieses Turnier löschen?",
    "tournament.manager.editors": "Turnier Editoren",
    "tournament.manager.editMaster": "Turnier Stammdaten",
    "tournament.manager.canceled" : "Turnier abgesagt!",
    "tournament.manager.link2BusinessAccount": "Mit bestehendem Business Konto verwalten?",
    "tournament.manager.link2BusinessAccount.desc": "Die Verknüpfung zu ihrem Business Konto erlaubt es ihnen ein Turnier mit Onlinebezahlung, Gruppeneinteilung und 3D SKill Board App Unterstützung durchzuführen. Ohne Business Konto können sie lediglich das Gratis-Turnieranmeldeportal nutzen!",
    "tournament.manager.newAccount.desc1": "Für die vollständige Durchführung des Turniers benötigen sie ein aktives Business Konto.",
    "tournament.manager.newAccount.desc2": "Sobald das Turnier einem Parcours zugewiesen wurde (Config -  3D SKill Board Konfiguration) können sie hier das Konto erstellen.",
    "tournament.manager.newAccount.button": "Konto erstellen und Verwaltung beantragen",
    "tournament.manager.populateResultImage": "In der Turnierview das Ergebnis als PDF Exportieren und in der Dateien Tab als Turnierergebnis hochladen.",
    "tournament.manager.round.button.add":"Turnierrunde hinzufügen",
    "tournament.manager.round.button.delete.desc":"Möchten sie die folgende Runde löschen?",
    "tournament.manager.round.button.delete.warning":"ACHTUNG! Löschen sie keine gestarteten Runden - sonst können bereits gestartete Schützen das Turnierergebnis nicht bestätigen!",
    "tournament.manager.round.modal.title":"Turnierrunde erstellen oder editieren",
    "tournament.manager.round.modal.desc":"Bitte den Namen, Anzahl der Ziele sowie den Typ der Runde auswählen.",
    "tournament.manager.round.modal.alert":"Es können in der 3D SKill Board App nicht mehr Ziele eingegeben werden als die Anzahl der Ziele die für jede Runde konfiguriert wird!",
    "tournament.manager.round.noSingleRounds":"Bei einem reinen Gruppenturnier (AnmeldeTyp Paar) oder einem Lippek Turnier (Verband Lippek) können diese Runden nicht durchgeführt werden",
    "tournament.manager.round.qualify.title": "A) Einfach- und Qualifikationsrunden",
    "tournament.manager.round.qualify.desc":"Die Runden werden hintereinander durchgeführt und die Ergebnisse des Schützen aus jeder einzelnen Runden werden addiert. Es können maximal drei Runden konfiguriert werden.",
    "tournament.manager.round.final.title": "B) Final- und Eliminationsrunden",
    "tournament.manager.round.final.desc":"Diese Runden werden nach den Gruppe-A Runden durchgeführt und Finalschützen werden anhand deren Gruppe-A Ergebnisse ausgewählt.  Die Ergebnisse des Schützen aus jeder einzelnen Finalrunde werden addiert. Es können maximal zwei Finalrunden konfiguriert werden. Der Typ der ersten Finalrunde wird für die Berechnung verwendet der Typ der zweiten ignoriert.",
    "tournament.manager.round.groups.title": "C) Gruppenrunden",
    "tournament.manager.round.groups.desc": "Eine Gruppe von Schützen erarbeitet ein gemeinsames Ergebnis und wird danach gereiht.",
    "tournament.manager.round.extra.title": "D) Spezial",
    "tournament.manager.round.extra.desc": "Keine eigene Runde - zeigt zusätzliche Ergebnisse auf Basis der Qualifikation an.",
    "tournament.manager.round.parcoursSelection":"Für die gewählte Runde diesen Parcours verwenden. (Anstatt des Parcours aus der Turnier Config)",
    "tournament.manager.cup.result": "Cup-Ergebnis",
    "tournament.manager.cup.intermediate": "Cup-Zwischenergebnis",
    "tournament.round.type.S":"Einfach",
    "tournament.round.type.S.desc":"Schütze kann nur einmal an dieser Runde teilnehmen und dieses Ergebnis wird gewertet.",
    "tournament.round.type.S.defRoundName":"Turnierrunde",
    "tournament.round.type.B":"BestOf",
    "tournament.round.type.B.defRoundName":"BestOf-Runde",
    "tournament.round.type.B.desc":"Schütze kann mehrfach an dieser Runde teilnehmen und nur das Beste Ergebnis wird gewertet.",
    "tournament.round.type.F0":"Finale (start 0)",
    "tournament.round.type.F0.defRoundName":"Finalrunde",
    "tournament.round.type.F0.desc":"In das Finale starten alle teilnehmenden Schützen mit Null Punkten.",
    "tournament.round.type.F1":"Finale (additiv)",
    "tournament.round.type.F1.defRoundName":"Finalrunde",
    "tournament.round.type.F1.desc":"In das Finale starten alle teilnehmenden Schützen mit den Punkten aus den Gruppe-A Runden.",
    "tournament.round.type.L":"Lippek",
    "tournament.round.type.L.defRoundName":"Lippek Runde",
    "tournament.round.type.L.desc":"Das Ergebnis wird mit einem Faktor abhängig von der Bogenklasse gewichtet. * Button zum Konfigurieren des Faktors. Benötigt Verband Lippek in der Turnierconfig",
    "tournament.round.type.GL":"Mannschaft-Lippek",
    "tournament.round.type.GL.defRoundName":"Lippek Runde",
    "tournament.round.type.GL.desc":"Das Ergebnis aller Schützen wird mit einem Faktor abhängig von der Bogenklasse gewichtet und addiert. * Button zum Konfigurieren des Faktors.",
    "tournament.round.type.GT":"Mannschaft",
    "tournament.round.type.GT.defRoundName":"Mannschaftsrunde",
    "tournament.round.type.GT.desc":"Das Ergebnis aller Schützen wird zu einem Mannschaftsergebnis addiert.",
    "tournament.round.type.GR":"Mannschaft-Ranking",
    "tournament.round.type.GR.defRoundName":"Mannschaftsranking",
    "tournament.round.type.GR.desc":"Keine eigene Runde! Das Ergebnis aller Schützen aus den Typ A Runden wird zu einem Mannschaftsergebnis addiert.",
    "tournament.round.type.EI":"Inklusionsranking",
    "tournament.round.type.EI.defRoundName":"Inklusionsranking",
    "tournament.round.type.EI.desc":"Ein Ergebnis das Geschlecht und Altersgruppen ignoriert und nur nach Bogenklassen gruppiert.",
    "tournament.manager.tConfig.Manipulator.modal.title":"Faktor konfigurieren",
    "tournament.manager.tConfig.Manipulator.modal.desc":"Geben sie je Klasse den Faktor ein, mit dem das Rundenergebnis gewichtet wird",
    "tournament.mmtuser.action.single":"Einzelaktion",
    "tournament.mmtuser.action.several":"Mehrfachaktion",
    "tournament.mmtuser.action.all":"Listenaktion",
    "tournament.mmtuser.tooltip.new":"Neuer Teilnehmer",
    "tournament.mmtuser.tooltip.edit":"Teilnehmer Daten editieren",
    "tournament.mmtuser.tooltip.editScore":"Teilnehmer Score editieren",
    "tournament.mmtuser.tooltip.paid":"Teilnehmer hat bezahlt",
    "tournament.mmtuser.tooltip.regpaid":"Teilnehmer bezahlt & anwesend",
    "tournament.mmtuser.tooltip.sppaid":"Teilnehmer mit Baraufschlag bezahlt",
    "tournament.mmtuser.tooltip.spregpaid":"Teilnehmer mit Baraufschlag bezahlt & anwesend",
    "tournament.mmtuser.tooltip.freepaid":"Teilnehmer gratis",
    "tournament.mmtuser.tooltip.unpaid":"Teilnehmer hat nicht bezahlt",
    "tournament.mmtuser.tooltip.R":"Teilnehmer-Status angemeldet",
    "tournament.mmtuser.tooltip.A":"Teilnehmer-Status anwesend",
    "tournament.mmtuser.tooltip.S":"Teilnehmer-Status gestartet",
    "tournament.mmtuser.tooltip.P":"Teilnehmer-Status mit nur einem Ergebnis beendet",
    "tournament.mmtuser.tooltip.F":"Teilnehmer-Status beendet",
    "tournament.mmtuser.tooltip.W":"Teilnehmer-Status wartend",
    "tournament.mmtuser.tooltip.X":"Teilnehmer-Status disqualifiziert",
    "tournament.mmtuser.tooltip.D":"Teilnehmer löschen",
    "tournament.mmtuser.tooltip.download":"Teilnehmerliste als csv herunterladen",
    "tournament.mmtuser.tooltip.sync":"Teilnehmer neu laden",
    "tournament.mmtuser.tooltip.mail":"E-Mail an die gefilterten Teilnehmer senden",
    "tournament.mmtuser.tooltip.group.new":"Neue Gruppe mit Teilnehmer erstellen",
    "tournament.mmtuser.tooltip.team.new":"Neues Team mit Teilnehmer erstellen",
    "tournament.mmtuser.tooltip.group.sync":"Gruppen neu laden",
    "tournament.mmtuser.tooltip.team.sync":"Teams neu laden",
    "tournament.mmtuser.tooltip.group.autogen":"Gruppengenerator",
    "tournament.mmtuser.tooltip.group.autogen.desc":"Alle Teilnehmer ohne Gruppe werden nach den folgenden Parametern in Gruppen aufgeteilt ...",
    "tournament.mmtuser.tooltip.group.startAll":"Start alle Gruppen MIT der Runde:",
    "tournament.mmtuser.tooltip.group.startRound":"Starte anwesende Gruppen der Runde:",
    "tournament.mmtuser.tooltip.group.start":"Gruppe starten",
    "tournament.mmtuser.tooltip.group.reUse":"Gruppe wieder anwesend setzten",
    "tournament.mmtuser.tooltip.group.pause":"Gruppen für den Rundenwechsel zurücksetzen",
    "tournament.mmtuser.tooltip.group.addTuser":"Ausgewählte Teilnehmer zur Gruppe hinzufügen",
    "tournament.mmtuser.tooltip.group.finish":"Gruppe beendet markieren",
    "tournament.mmtuser.tooltip.team.addTuser":"Ausgewählte Teilnehmer zum Team hinzufügen",
    "tournament.mmtuser.tooltip.group.del":"Gruppe löschen",
    "tournament.mmtuser.tooltip.team.del":"Team löschen",
    "tournament.mmtuser.tooltip.team.present":"Team als anwesend markieren",
    "tournament.mmtuser.tooltip.group.delUser":", aus Gruppe entfernen",
    "tournament.mmtuser.tooltip.group.delEmpty":"Platzhalter entfernen",
    "tournament.mmtuser.tooltip.group.draft":"Ersteller darf Gruppe ändern",
    "tournament.mmtuser.tooltip.group.remUser":"Selektierte aus ihren Gruppe entfernen",
    "tournament.mmtuser.tooltip.team.delUser":", aus Team entfernen",
    "tournament.mmtuser.icon.R":"registered",
    "tournament.mmtuser.icon.A":"check-circle",
    "tournament.mmtuser.icon.S":"play-circle",
    "tournament.mmtuser.icon.P":"bullhorn",
    "tournament.mmtuser.icon.F":"flag",
    "tournament.mmtuser.icon.W":"pause",
    "tournament.mmtuser.icon.X":"ban",
    "tournament.mmtuser.icon.D":"trash",
    "tournament.mmtuser.showdeleted":"Gelöschte Teilnehmer und Gruppen einblenden",
    "tournament.mmtuser.search4name": "Suche mit Namen oder Cup# in bisherigen Anmeldungen",
    "tournament.mmtuser.config.warning":"Es fehlt noch die Konfiguration von:",
    "tournament.mmtuser.group.noStartedRound":"Zum Starten der Gruppen wird eine gestartete Runde benötigt",
    "tournament.mmtuser.team.noStartedRound":"Zum Anwesend setzten der Teams wird eine gestartete Runde benötigt",
    "tournament.mmtuser.group.round.confirm":"Anzahl der Ziele bestätigen",
    "tournament.mmtuser.mode.R":"Anmeldung",
    "tournament.mmtuser.mode.G":"Gruppeneinteilung",
    "tournament.mmtuser.mode.A":"Auswertung",
    "tournament.mmtuser.teams":"Turnier teams",
    "tournament.modal.tuser.filter":"Teilnehmer-Filter",
    "tournament.modal.tuser.filter.reset":"Filter zurücksetzen",
    "tournament.modal.score.tround":"Turnierrunde auswählen",
    "tournament.modal.score.troundUser":"Ergebnis auswählen",
    "tournament.modal.score.enterNewResult":"Neues Ergebnis eingeben",
    "tournament.modal.score.calc.points":"Berechnete Punkte",
    "tournament.modal.score.calc.hitmiss":"Ziele / Treffer / Verfehlt",
    "tournament.modal.score.man.correction":"Korrektur +/-",
    "tournament.modal.score.nan.points":"Die berechneten Punkte sind keine gültige Zahl",
    "tournament.modal.score.changes":"Änderungen",
    "tournament.modal.score.changes.desc":"Folgende Änderungen wurden durchgeführt:",
    "tournament.modal.score.compareErrors":"Punkte-Validierung",
    "tournament.modal.score.compareErrors.desc":"Das in den Apps eingegebene Ergebnis stimmt nicht überein",
    "Dialog_Body_tournament_invalidKills":"Die Anzahl der verschiedenen Kills stimmen nicht überein!",
    "Dialog_Body_tournament_invalidPoints":"Die eingegebenen Punkte stimmen nicht überein!",
    "tournament.modal.group.maxTUser":"Max Gruppengröße",
    "tournament.modal.group.maxTUserClub":"Max Teilnehmer selber Verein",
    "tournament.modal.group.rankingTUser":"Nur ersten # je Klasse",
    "tournament.modal.group.paidonly":"Nur bezahlte Teilnehmer gruppieren",
    "tournament.modal.group.generate":"Generiere Gruppen",
    "tournament.modal.group.maxClub":"Max Anzahl von Vereinsmitgliedern",
    "tournament.modal.group.rankingCup":"Cup-Ranking der Besten je Klasse",
    "tournament.modal.cupcard":"Generiere deine ID-Card",
    "bowUnion.type.S":"Einzelturnier",
    "bowUnion.type.C":"Cup/Trophyturnier",
    "bowUnion.type.CM":"Cup-Haupt-Turnier",
    "bowUnion.type.CC":"Cup-Teil-Turnier",
    "businessDetail.request.title": "Parcours Management beantragen ",
    "businessDetail.request.new.header": "Verwaltung mit einem neuem Business Account beantragen",
    "businessDetail.request.exists.header": "Verwaltung mit einem bestehenden Business Account beantragen",
    "businessDetail.request.enterNewContact": "Neue Kontaktdaten hinzufügen",
    "businessDetail.request.form.header": "Kontaktdaten eingeben",
    "businessDetail.request.form.union": "Firmen- oder Vereinsname",
    "businessDetail.request.form.contactName": "Name des Ansprechpartner",
    "businessDetail.request.form.street": "Strasse",
    "businessDetail.request.form.zip": "PLZ",
    "businessDetail.request.form.place": "Ort",
    "businessDetail.request.form.phone": "Telefonnummer",
    "businessDetail.request.form.mail": "E-Mail",
    "businessDetail.request.form.cardName": "Name des Kontoinhabers",
    "businessDetail.request.form.cardBIC": "BIC",
    "businessDetail.request.form.cardIBAN": "IBAN",
    "businessDetail.request.form.submit": "Beantragen",
    "translator.title": "3D SKill Board Übersetzung",
    "translator.change.column": "Spalten ein- oder ausblenden:",
    "translator.info.save":"Drücken sie ENTER im Eingabefeld um die Änderungen zu speichern!",
    "translator.admin.addKey":"Name des KEY für neuen Eintrag:",
    "translator.admin.expFile":"Sprachdateien herunterladen für:",
    "translator.admin.toggle":"Eintrag aktivieren:",
    "translator.admin.toggle.info":"Eintrag auswählen",
    "translator.admin.chooseType":"Wähle Typ",
    "translator.admin.search":"ENTER drücken für Key und Übersetzungssuche",
    "translator.info.hideFilled":"Befüllte Einträge verstecken",
    "profile.account.user.header": "Benutzer Konto",
    "profile.account.user.login.header": "Kontodaten",
    "profile.account.user.data.header": "Persönliche Daten",
    "profile.account.user.edit": "Adresse editieren",
    "profile.account.user.createDetail": "Adresse hinzufügen",
    "profile.account.user.image":"Profilbild",
    "profile.account.clubuser.data.header": "Vereinsdaten",
    "profile.account.business.header": "Business Konto ",
    "profile.account.business.subheader": "Verwaltete Parcours",
    "profile.account.business.editors": "Editoren verwalten",
    "profile.account.business.settings": "Business Konto Einstellungen",
    "profile.account.business.paymentTarget": "Zielkonto editieren",
    "profile.account.business.paymentTarget.header":"Kontoinformationen",
    "profile.tournament.waiting.header": "Turnieranmeldungen",
    "profile.tournament.finished.header": "Turnierergebnisse",
    "profile.managedParcours.home": "Landing page",
    "profile.managedParcours.header": "Parcours Verwaltung",
    "profile.managedParcours.article": "Artikelstamm",
    "profile.managedParcours.parcours.function": "Parcours Funktionen",
    "profile.managedParcours.transaction": "Transaktionen",
    "profile.managedParcours.payment.A": "Zahlung aktiv",
    "profile.managedParcours.payment.I": "Zahlung inaktiv",
    "profile.managedParcours.visit.A" : "Parcoursbuch aktiv",
    "profile.managedParcours.visit.P" : "Parcoursbuch incl Bezahlungsauswahl aktiv",
    "profile.managedParcours.visit.F" : "Parcoursbuch incl Adresse aktiv",
    "profile.managedParcours.visit.I" : "Parcoursbuch inaktiv",
    "profile.managedParcours.payment.info.noPartner": "Der Parcours bietet leider keine Zahlung via 3D SKill Board an - teilen Sie ihm doch mit das sie mit 3D SKill Board zahlen möchten.",
    "profile.managedParcours.payment.info.disabled": "Der Parcours hat die Zahlung mit 3D SKill Board vorübergehend nicht aktiviert, bitte versuchen sie es später",
    "profile.managedParcours.fileupload.description":"Datei für Parcoursregeln auswählen - muss bei der Onlinezahlung bzw beim Eintrag ins Parcoursbuch akzeptiert werden",
    "profile.transactions.header": "Bezahlte Parcours Runden",
    "profile.events.empty": "Keine Events gefunden",
    "profile.events.delete": "Event löschen",
    "profile.events.restore": "Event wiederherstellen",
    "profile.events.showInRanking" : "Drücken um Namen im Ranking an zu zeigen",
    "profile.events.hideInRanking" : "Drücken um Namen im Ranking aus zu blenden",
    "profile.event.eventDetails": "Zu den Eventdetails ...",
    "profile.event.targetDetails": "Zieldetails",
    "profile.event.notInEvent":"Sie waren nicht Teil dieses Events bzw Gruppe",
    "profile.event.baProfile.label":"Sie können nachträglich ihre Ausrüstung eintragen",
    "profile.event.duration.label":"Sie können für das Event nachträglich eine Dauer speichern",
    "profile.event.duration.placeholder":"Zeit in Minuten",
    "profile.events.noEventDetails" : "Ergebnis kann nicht angezeigt werden. Sollte dies ein Duplikat sein können sie es bedenkenlos löschen. Sonst laden sie bitte das betreffende Event nochmals hoch in dem sie in der 3D SKill Board App synchronisieren drücken.",
    "profile.fromMyImages": "Aus meinen Elementen wählen",
    "profile.addToMyImages": "Neues Element hinzufügen (max 5MB)",
    "profile.abo.asklogin":"Loggen sie sich ein um für ihren Account ein Abo abzuschließen",
    "profile.abo.header":"Konto-Abo und Features",
    "profile.abo.button.deactivate":"Pausiere Abo",
    "profile.abo.button.message.deactivate": "Sie können das Abo ab jetzt einmalig pausieren und zu einem beliebigen Zeitpunkt wieder reaktivieren. Das Abo-Ende wird um die einmalig pausierte Zeit nach hinten verschoben.",
    "profile.abo.button.reactivate":"Reaktiviere Abo",
    "profile.abo.button.message.reactivate": "Das Abo wird reaktiviert und ist bis zum folgendem Datum aktiv:",
    "profile.abo.null":"Für dieses Konto gibt es kein Abo - mehr Infos über ",
    "profile.abo.newEndDate": "Das Abo endet nach der Verlängerung am:",
    "profile.abo.ownAccount":" Für mein Konto",
    "profile.abo.redeem.voucher":" Gutschein einlösen",
    "profile.abo.redeem.voucher.for":"Gutschein einlösen für",
    "profile.training.noAbo":"Das Trainingsmodul ist für diesen Account nicht aktiviert.",
    "profile.training.button.enter":"Training eintragen",
    "profile.training.ext.link":"Externer Link",
    "profile.training.content":"Inhalte",
    "profile.training.choose.type":"Kategorie und Art des Trainings auswählen",
    "profile.training.main.type":"Kategorie",
    "profile.training.sub.type":"Art",
    "profile.training.team.trainer":"Trainer des Teams",
    "profile.training.button.trainer":"Trainer verwalten",
    "profile.training.team.add.user":"E-Mail des neuen Schützen/in eingeben",
    "profile.training.team.invitation":"Offene Einladungen in folgende Training Teams",
    "profile.training.team.noMember":"Sie sind kein Mitglied mit Einsicht in den Trainingsfortschritt oder Trainer dieses Teams.",
    "profile.training.team.alreadyMember":"Schütze/in ist bereits in dem Team",
    "profile.training.team.newTeam":"Neues Team erstellen",
    "profile.training.team.editTeam":"Teamdaten eingeben",
    "profile.training.team.userVisible":"Mitglieder können den Trainingsfortschritts der anderen ebenfalls sehen.",
    "profile.training.button.change.name":"Name editieren",
    "profile.training.filter.header":"Filtere deine Events",
    "profile.training.filter.placeholder":"Name für des Filter ...",
    "profile.training.filter.event":"Filter für Events",
    "profile.training.filter.details":"Filter für Ziele",
    "profile.training.filter.comment":"Filter für Zielparameter",
    "profile.training.baProfile":"Ausrüstung",
    "profile.training.training.mode":"Trainingsmodus",
    "profile.training.tags":"Tags",
    "profile.training.events":"Events",
    "profile.training.dist_est":"Entfernung geschätzt",
    "profile.training.dist_meter":"Entfernung gemessen",
    "profile.training.target_size":"Größe des Ziels",
    "profile.training.target_size.1002" : "klein",
    "profile.training.target_size.1003" : "mittel",
    "profile.training.target_size.1004" : "groß",
    "profile.training.target_group":"Gruppe (Killgröße)",
    "profile.training.posture":"Stand",
    "profile.training.posture.1001":"steil(u)",
    "profile.training.posture.1002":"bergab",
    "profile.training.posture.1003":"eben",
    "profile.training.posture.1004":"bergauf",
    "profile.training.posture.1005":"steil(o)",
    "profile.training.postureHill":"Stand im Hang",
    "profile.training.postureHill.1001":"zum Hang",
    "profile.training.postureHill.1005":"vom Hang",
    "profile.training.level":"Ziel steht ...",
    "profile.training.level.1001":"steil(u)",
    "profile.training.level.1002":"tiefer",
    "profile.training.level.1003":"eben",
    "profile.training.level.1004":"höher",
    "profile.training.level.1005":"steil(o)",
    "profile.training.levelNum":"Zielt steht im Winkel",
    "profile.training.arrowNr":"Pfeilnummer",
    "profile.training.zones":"Zonen",
    "profile.training.resTargetIndex":"Zielnummer",
    "profile.training.passe":"Passe",
    "profile.training.discSize":"Auflagen",
    "profile.training.target.load":"Lade Zielparameter",
    "profile.training.target.comment":"Filtere die Ziele",
    "profile.training.mapKillImage" :"Nur Treffer dieses Kill Bilds anzeigen",
    "profile.training.potPoints":"Nur Treffer(*) knapp an höheren Zone",
    "profile.training.noCountTypeWarn":"ACHTUNG, DPS aus unterschiedlichen Wertungen!",
    "training_mode_no":"Event",
    "training_mode_tournament":"Turnier",
    "training_mode_3d":"Training 3D",
    "training_mode_field":"Training Feld",
    "training_mode_outdoor":"Training Outdoor",
    "training_mode_indoor":"Training Indoor",
    "list.entry.empty": "Keine Einträge gefunden",
    "list.entry.deleted": "Eintrag wurde gelöscht",
    "list.entry.hdr.delete":"Eintrag löschen?",
    "list.entry.delete":"Möchten sie den ausgewählten Eintrag löschen?",
    "status.A" : "aktiv",
    "status.I" : "inaktiv",
    "status.R" : "registriert",
    "status.U" : "verwendet",
    "status.D" : "gelöscht",
    "status.EXP" : "abgelaufen",
    "transaction.status.INIT":"Gestartet(I)",
    "transaction.status.ACK":"Bestätigt",
    "transaction.status.PENDING":"Gestartet(P)",
    "transaction.status.STORNO":"Storniert",
    "transaction.status.NOK":"Fehlerhaft",
    "transaction.detail.notFound":"Transaktion nicht gefunden",
    "transaction.detail.header.pendingInvoice":"Rechnung erfolgreich erstellt",
    "transaction.detail.header.checkoutSuccess":"Bezahlvorgang erfolgreich",
    "transaction.detail.header.checkoutPending":"Bezahlvorgang noch in Arbeit",
    "transaction.detail.header.checkoutError":"Bezahlvorgang konnte nicht durchgeführt werden",
    "transaction.detail.link.freshdesk":"Probleme beim Online bezahlen - hier gibts es mehr Infos ...",
    "transaction.detail.link.parcours":"Zur Parcoursseite",
    "transaction.detail.link.tournament":"Zur Turnierseite",
    "transaction.detail.link.invoice":"Download Rechnung",
    "transaction.detail.link.voucher":"Download Gutschein",
    "transaction.detail.mail.invoice":"Sie erhalten in Kürze die Bezahlbestätigung per E-Mail.",
    "transaction.detail.restart" : "Weiter zum Bezahlformular",
    "transaction.detail.error.INIT":"Bei der Kommunikation mit dem Bezahlservice trat ein Fehler auf. Zur Zeit können keine Bezahlungen abgewickelt werden.",
    "transaction.detail.error.PENDING":"Der Bezahlvorgang wurde gestartet aber weder fortgeführt noch abgebrochen",
    "transaction.detail.error.NOK":"Bezahlung konnte nicht durchgeführt werden. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.",
    "transaction.detail.error.":"Ein unerwarteter Fehler ist aufgetreten. Bitte wenden Sie sich an office@3dturnier.com",
    "transaction.detail.pending.info":"Warte auf Nachricht vom Bezahldienst",
    "card_declined":"Ihre Karte wurde abgelehnt",
    "payment_intent_authentication_failure":"Die Authentifizierung ihrer Zahlung ist fehlgeschlagen",
    "payment_method_customer_decline":"Sie haben die Zahlung nicht bestätigt",
    "contact.form.header" : "Sende uns eine Nachricht",
    "contact.form.message" : "Nachricht",
    "contact.form.send" : "Senden",
    "contact.form.sendError" : "Ups, unser E-Mail Server scheint gerade nicht zu antworten. Könnten sie uns die Nachricht bitte direkt an support@3dturnier.com schicken?",
    "contact.form.sendSuccess": "Die Nachricht wurde erfolgreich versendet.",
    "importer.warning":"importieren - Nur verwenden wenn sie wissen was sie tun!!",
    "importer.selectJson":"Import JSON File auswählen",
    "skillboard.premium.header":"Alles für deinen SKill",
    "skillboard.premium.buynow":"Jetzt kaufen ...",
    "skillboard.premium.next":"Feature in Arbeit",
    "skillboard.premium.planned":"Geplant",
    "skillboard.premium.available":"Verfügbar in",
    "skillboard.premium.prices":"Hol dir 3D SKill Board PREMIUM oder TRAINING",
    "skillboard.premium.training":"Hol dir 3D SKill Board TRAINING",
    "skillboard.premium.aboAccountInfo1":"Danke das sie sich bereits für ein Abo entschieden haben",
    "skillboard.premium.aboAccountInfo2":"Den Status ihrer bestehenden Abos finden sie in ihren",
    "skillboard.premium.aboAccountInfo3":"Das nächste Abo startet beim Kauf am",
    "skillboard.premium.prices.desc1":"Premium Nutzer bekommen neue Features zuerst",
    "skillboard.premium.prices.desc2":"Einmaliges Pausieren des Abos zB über den Winter möglich",
    "skillboard.premium.prices.desc3":"Gilt pro Account unabhängig von der Geräteanzahl oder -typ",
    "skillboard.premium.prices.desc4":"Keine klassische In-App Banner Werbung",
    "skillboard.premium.prices.desc5":"Keine automatische Abo-Verlängerung oder Abbuchung",
    "skillboard.feature.desc1":"Punkteerfassung für Einzel/Gruppe",
    "skillboard.feature.desc2":"Ergebnis vergleichen und teilen",
    "skillboard.feature.desc3":"Ausrüstung",
    "skillboard.feature.desc4":"Parcours Wiki",
    "skillboard.feature.desc5":"Treffer-Wertungen",
    "skillboard.feature.desc6":"Statistik",
    "skillboard.feature.desc7":"GPS-Track",
    "skillboard.feature.desc8":"Parcours-Ranking",
    "skillboard.feature.desc9":"Trefferbild-Eingabe",
    "skillboard.feature.desc10":"3D-Tierbilder",
    "skillboard.feature.desc11":"Trainingstagebuch",
    "skillboard.feature.desc12":"Trainingsgruppen",
    "skillboard.feature.desc13":"Zielcharakteristik",
    "skillboard.feature.desc14":"Trefferbild-Analyse",
    "skillboard.premium.S.desc1":"Vergleiche Punkte und DPS/BHS der Gruppe",
    "skillboard.premium.S.desc2":"Detaillierte Punkte und DPS/BHS eines Schützen/in",
    "skillboard.premium.S.desc3":"DPS/BHS Verlauf über eure Parcours und Wertungen",
    "skillboard.premium.S.desc4":"DPS/BHS Verlauf über einen Parcours",
    "skillboard.premium.G.desc1":"GPS Track aufzeichnen inkl. Pausefunktion",
    "skillboard.premium.G.desc2":"Kartenansicht des Tracks mit Zielen",
    "skillboard.premium.G.desc3":"Track exportieren als gpx",
    "skillboard.premium.G.desc4":"Zeit und zurückgelegter Weg",
    "skillboard.premium.G.desc5":"Geplant: Höhenprofil des Tracks",
    "skillboard.premium.G.desc6":"Geplant: Navigation und falsche Richtung-Warnung",
    "skillboard.premium.R.desc1":"Platzierung im Ranking auf allen Parcours einsehen",
    "skillboard.premium.Z.desc1":"Eingabe der Ziele eines Parcours aus den Katalogen der Hersteller",
    "skillboard.premium.Z.desc2":"Tierkatalog mit Bildern inkl. Kill-Zonen vom Hersteller bereitgestellt",
    "skillboard.premium.Z.desc3":"Austausch der Zieldaten mit anderen Premium Nutzer",
    "skillboard.premium.K.desc1":"Treffereingabe auf verschiedene Kill-Bilder",
    "skillboard.premium.K.desc2":"Alle Treffer des Events anzeigen und filtern",
    "skillboard.premium.voucher.activate":"Gutschein aktivieren",
    "skillboard.premium.voucher.success.activate":"Gutschein wurde erfolgreich aktiviert und kann ab jetzt von einem Kunden eingelöst werden",
    "skillboard.premium.voucher.error.used":"Gutschein wurde bereits eingelöst.",
    "skillboard.premium.voucher.error.registered":"Der Gutschein muss noch von ihrem Händler aktiviert werden",
    "skillboard.premium.voucher.error.invalid":"Der Gutscheincode ist ungültig.",
    "skillboard.premium.voucher.success":"Gutschein wurde erfolgreich eingelöst",
    "skillboard.premium.voucher.entercode":"Geben sie hier den Gutscheincode ein",
    "skillboard.premium.voucher.uplowcase":"Achten sie auf die Groß- und Kleinschreibung des Codes!",
    "skillboard.premium.noAbo":"Für diese Funktion wird ein aktives 3D SKill Board Premium Abo benötigt",
    "skillboard.title": "3D SKill Board",
    "skillboard.download": "\
            Die smarte 3D Bogensport App - Scorezettel war gestern!<br/>\
            Kostenlos im Google Play und Apple App Store",

    "skillboard.screenshot":"Screenshots der ...",
    "skillboard.tab.gettingstarted": "Kurzanleitung",
    "skillboard.tab.faq": "FAQs",
    "skillboard.tab.videos": "Videos",
    "skillboard.tab.scores": "Wertungen",
    "skillboard.tab.releasenotes": "Release Notes",
    "releasenotes.result.empty":"Keine Release Notes gefunden",
    "releasenotes.newVersion":"Eine neue Version von 3DTurnier ist verfügbar - laden sie die Seite neu. Wenn der Browser nicht die neu Version lädt - hilft am Smartphone die Desktopseite ein/aus zuschalten oder am PC STRG+F5 drücken.",
    "skillboard.tab.joinTournament": "Turnierteilnahme",
    "skillboard.knowledge.base":"Hilfe - WissensDB",
    "skillboard.gettingstarted.newevent.title": "Starten eines neuen Events",
    "skillboard.gettingstarted.newevent.left": "\
            <p>\
                <strong>Event - eine Runde am Parcours</strong><br/>\
                1) Wählen sie zuerst einen Parcours aus. <br/>\
                Für Partner-Parcours werden zusätzliche Features wie Online-Zahlung, Schützenranking oder eine Karte der Ziele angeboten.\
                <br/><br/>\
                2) Wählen sie Schützen/innen aus die am Event teilnehmen.\
                <br/><br/>\
                3) Drücken sie auf den neu aufgetauchten \"Start\" Button.\
                <br/><br/>\
                <strong>3D Turnier</strong><br/>\
                Hier können sie an Turnieren die auf dieser Platform stattfinden teilnehmen. <br/>\
                <a href='./skillboard/tab/jointournament'>Siehe Turnierteilnahme</a>\
            </p>",
    "skillboard.gettingstarted.newevent.right": "\
            <p>\
                <strong>Parcours auswählen</strong>\
                <br/>\
                Sie sehen eine Liste ihrer übernommenen Parcours. Jene Parcours die sie häufig besuchen können sie als Favoriten markieren.\
                Mit einem long click auf den Eintrag erscheint ein Kontextmenü mit dem sie den Eintrag editieren können.\
                <br/><br/>Wenn sie noch keine oder den gewünschten Parcours noch nicht gespeichert haben - sollten diese aus dem Parcours Wiki übernommen werden.\
                Im Parcours Wiki kann jeder Benutzer fehlende Parcours selber hinzufügen. Alternativ können sie auch einen Standard Parcours verwenden. \
            </p>",
    "skillboard.gettingstarted.newevent2.title": "Schützen und Optionen",
    "skillboard.gettingstarted.newevent2.left": "\
            <p>\
                <strong>Schützen/innen auswählen</strong><br/>\
                Sie sehen eine Liste ihrer gespeicherten Schützen/innen. Jene mit denen sie häufig unterwegs sind können als Favoriten markiert werden.\
                Mit einem long click auf den Eintrag erscheint ein Kontextmenü mit dem sie den Eintrag editieren können.\
                <br/><br/>\
                Für jeden Schützen/in muss ein Name und eine Wertung ausgewählt werden. Die e-mail ist grundsätzlich optional, aber nur mit eingegebener \
                e-mail werden die Ergebnisse auch hochgeladen. Siehe Synchronisierung\
                <br/><br/>\
                Selektieren sie jene Schützen/innen die bei dem Event dabei sind und bestätigen sie mit dem Häckchen rechts oben ihre Auswahl\
            </p>",
    "skillboard.gettingstarted.newevent2.right": "\
            <p>\
                <strong>Eventoptionen</strong>\
                <br/>\
                1) Sie können einen anderen Namen für das Event vergeben.\
                (Tipp Das Datum wird später automatisch hinzugefügt und muss nicht manuell ergänzt werden :-).<br/><br/> \
                2) Optional kann noch eine Wertung für alle Schützen/innen ausgewählt werden oder wie\
                im Screenshot behält jeder die individuelle Wertung bei. <br/><br/>\
                3) Es gibt die Möglichkeit das Event auf einem anderen Startpflock starten zu lassen zb bei einem Turnier<br/><br/>\
                4) Mit dem <a href='./skillboard/premium'>Premium Abo</a> können sie noch zusätzlich den GPS Track des Events aufzeichnen\
            </p>",
    "skillboard.gettingstarted.wiki.title": "Parcours aus Wiki übernehmen",
    "skillboard.gettingstarted.wiki.left":"\
            <p>\
                <strong>Suche nach Parcours</strong><br/>\
                Im Parcours Wiki sind die meisten Parcours die es gibt bereits gelistet. \
                Geben sie in das Suchfeld den Namen oder Ort oder PLZ des gewünschten Parcours ein und starten sie die Suche.\
                <br/><br/>\
                Wenn der gewünschte Parcours in der Suche aufscheint - klicken sie drauf um mehr Infos zu bekommen.\
            </p>",
    "skillboard.gettingstarted.wiki.right":"\
            <p>\
                <strong>Parcoursdetails</strong>\
                <br/>\
                 In der Detailansicht finden sie viele nützliche Informationen wie:\
                 \<ul>\
                    <li>Bewertungen anderer Schützen</li>\
                    <li>Kontaktdaten und Links</li>\
                    <li>Öffnungszeiten und Preise</li>\
                </ul>\
                 Klicken sie auf \"übernehmen\" um den Parcours in ihrer Parcours-Liste zu speichern und für das Event auszuwählen.\
            </p>",
    "skillboard.gettingstarted.enter1.title": "Treffereingabe 1",
    "skillboard.gettingstarted.enter1.left": "\
            <p>\
                Im oberen Bereich wird die Nummer und der gespeicherte Name des Ziel angezeigt.\
                Drücken sie auf den Namen des Ziels, um noch genauere Zieldetails zu erfassen.\
                Wenn das Bild-Icon angezeigt wird, ist auch ein Bild des 3D Ziels verfügbar.\
                Mit dem <a href='./skillboard/premium'>Premium Abo</a> kann zum Event noch ein privater Kommentar für das jeweilige Ziel erfasst werden.\
                <br/><br/>\
                Es werden alle Schützen und der aktuelle Punktestand\
                angezeigt. Wenn sie \"+???\" lesen können - wurde noch kein\
                Treffer für den Schützen/in eingegeben. Drücken sie einfach auf den Namen\
                des Schützen und das Treffer Popup erscheint.\
                <br/><br/>\
                Mit dem \" >> \" Button werden die Treffer aller Schützen/innen gespeichert und die App springt zum nächsten Ziel.\
                <br/><br/>\
                Mit dem Button \" << \" wird das vorherige Ziel geladen und die Eingaben können nochmals editiert werden.\
                Wischen sie seitlich um zur Ergebnistabelle zu wechseln. Hier können sie auch ältere Punkte editieren.\
                <br/><br/>\
                Die Zielflagge rechts oben übernimmt die letzten Zieleingaben und beendet das Event.\
            </p>",
    "skillboard.gettingstarted.enter1.right": "\
            <p>\
                <strong>Ein gewerteter Pfeil je Ziel</strong>\
                <br/>\
                Zuerst markieren sie, ob der Schütze/in mit dem 1. od 2. od 3. Pfeil\
                oder \"M\" gar nicht getroffen hat. Es erscheinen die jeweiligen\
                Trefferzonen mit den dazu gewerteten Punkten - bitte auch\
                auswählen. Das Popup verschwindet und die Punkte werden im Feld\
                des jeweiligen Schützen angezeigt.\
                <br/><br/>\
                Der linke \"Kreis\" Button blendet das Bild der Wertung ein und der rechte\
                \"Bleistift\" wechselt zur manuellen Punkteeingabe.\
            </p>",
    "skillboard.gettingstarted.enter2.title": "Treffereingabe 2",
    "skillboard.gettingstarted.enter2.left": "\
            <p>\
                <strong>Zwei oder mehr gewertete Pfeile je Ziel</strong>\
                <br/>\
                Wertungen, bei denen die Punkte aller Treffer je Ziel addiert\
                werden, zeigen ein Popup bei dem alle Pfeile auf einmal\
                eingegeben werden müssen.\
                <br/><br/>\
                Der linke \"Kreis\"-Button blendet das Bild der Wertung ein und der rechte\
                \"Bleistift\" wechselt zur manuellen Punkteeingabe.\
            </p>",
    "skillboard.gettingstarted.enter2.right": "\
            <p>\
                <strong>Manuelle Punkteeingabe</strong>\
                <br/>\
                Sollte ein Ziel aus der Reihe tanzen - kann unabhängig von der gewählten\
                Wertung eine beliebige Punktezahl vergeben werden. Aktuell\
                können aber maximal drei Pfeile je Ziel gewertet werden.\
            </p>",
    "skillboard.gettingstarted.enter3.title": "Treffereingabe 3",
    "skillboard.gettingstarted.enter3.left": "\
    		<p>\
    			<strong>3er Passe auf ein Scheibenziel</strong><br/>\
    			Die Scheibenwertungen können wie alle anderen Wertungen für einen Schützen oder für ein gesamtes Event ausgewählt werden.\
    			Drücken sie nacheinander auf die getroffenen Ringe und die Punkte werden automatisch im Punktebereich eingetragen.\
                Nach Eingabe des dritten und letzten Rings wird das Popup automatisch geschlossen und die Wertung für die Passe übernommen.\
                Durch drücken auf die Punkte im Punktebereich des Popups können auch ein einzelner Ringe wieder geändert werden.\
                <br/><br/>Es stehen folgende Scheibenwertungen zur Verfügung:\
                <ul>\
                    <li>Scheibe 10 bis 1 Ring (siehe links)</li>\
                    <li>Zentrumsscheibe 10 bis 6</li>\
                    <li>Zentrumsscheibe 10 bis 5</li>\
                    <li>Feldscheibe 6 bis 1 (siehe rechts) </li>\
                </ul>\
    		</p>",
    "skillboard.gettingstarted.result.title": "Ergebnisübersicht",
    "skillboard.gettingstarted.result.left": "\
            <p>\
                In der Tabelle erscheint für jedes einzelne Ziel die vom Schützen\
                ereichten Punkte. Mit einem Klick auf die Punkte können diese nachträglich geändert werden. \
                <br/><br/>\
                Der \"Event Menü\"-Button erlaubt folgende Funktionen:\
                <ul>\
                    <li>Löschen eines Events</li>\
                    <li>Event wieder öffnen - falls es zu früh abgeschlossen wurde </li>\
                    <li>Verschieben des Events zu einem anderen Parcours </li>\
                    <li>Eventname oder Datum ändern </li>\
                    <li>Änderung der Trefferstatistik auf Trefferzonen (siehe Screenshot) oder auf Pfeile basierend</li>\
                </ul>\
                Der \"Share\" Button erlaubt das Versenden des Ergebnisses und die\
                Teilnehmer. Die Daten werden automatisch in die zu wählende Mail\
                App (zB Google Mail App) übernommen.\
                <br/><br/> \
                Mit dem <a href='./skillboard/premium'>Premium Abo</a> wird das \"Diagramm\" Symbol eingeblendet und es öffnet sich eine grafische Aufbereitung des Events.\
            </p>",
    "skillboard.gettingstarted.result.right": "\
            <p>\
                <strong>Event History</strong><br/>\
                Die Event History zeigt alle Events, die in der App gespeichert sind sortiert nach Datum an.\
                Mit dem Drop Down können sie die Liste auf einen bestimmten Parcours eingrenzen. Im Kontextmenü eines Events haben sie die selben Möglichkeiten wie im Event Menü.\
            </p>",
    "skillboard.gettingstarted.scores.title": "Wertungen",
    "skillboard.gettingstarted.scores.left": "\
            <p>\
                Alle gespeicherten Wertungen werden hier angezeigt. Die App\
                kommt bereits mit einer Liste von Standard Wertungen. Jene die\
                sie am häufigsten verwenden - sollten Sie das Häkchen aktivieren.\
                Es werden nur aktivierte Wertungen in den Drop Down Menüs zur Pfeil\
                Wertung angezeigt. Deaktivierte Wertungen bleiben natürlich\
                trotzdem gespeichert. \
                <br/><br/>\
                Es gibt bereits eine lange Liste an Wertungen die von Schützen erstellt wurden. Sollten sie dennoch ein weitere hinzufügen wollen\
                 können sie das <a href='./skillboard/tab/scores'>hier</a>. Wischen sie nach dem Erstellen nach unten um die Liste der Wertungen zu aktualisieren\
            </p>",
    "skillboard.jointournament.info": "WICHTIG - Für die Teilnahme als Schreiber beim Turnier muss die Turnier Anmeldung \
                auf die selbe E-Mail lauten wie ihr 3D SKill Board Konto!",
    "skillboard.jointournament.join.title": "1. Turnier beitreten und starten",
    "skillboard.jointournament.join.left": "<p>\
                 1) Wechseln sie in der untern Tab Bar auf <strong>3D Turnier</strong>. (Nur Android)</p>",
    "skillboard.jointournament.join.right": "<p>\
                 2) Drücken sie <strong>Turniergruppe suchen</strong> und die App findet ihre Turniergruppe anhand der \
                 E-Mail des eingeloggten Schützen und ebenso alle anderen Teilnehmer der Gruppe und den Startpflock.\
                 <br/><br/>\
                 3) Drücken sie auf <strong>START</strong> und sie können mit der Punkteeingabe beginnen.</p>",
    "skillboard.jointournament.enter.title": "2. Punkte erfassen",
    "skillboard.jointournament.enter.left": "<p>\
                Die Eingabe Maske zeigt das aktuelle Ziel und die Liste der Teilnehmer in der Gruppe. \
                Drücken sie auf einen Teilnehmer um dessen Punkte zu erfassen.\
                <br/><br/>\
                <strong>Tip</strong>: Im Menü, kann der Edit Modus aktiviert werden, um eine Person aus der Gruppe zu entfernen.(Nur Android)</p>",
    "skillboard.jointournament.enter.right": "<p>\
                Die Ergebnis Tabelle zeigt den aktuellen Stand, so können Fehleingaben schneller bemerkt und korrigiert werden. \
                Nach der letzten Zieleingabe muss das <strong>Event beendet</strong> werden.\
                <br/><br/>\
                <strong>Tip</strong>: Klicken sie auf ein Einzelergebnis in der Tabelle um es zu ändern</p>",
    "skillboard.jointournament.commit.title": "3. Turnierergebnis bestätigen",
    "skillboard.jointournament.commit.left": "<p>\
                Vergleichen sie das Ergebnis mit den anderen Schreibern. \
                Wenn das Ergebnis übereinstimmt - drücken sie <strong>Turnierergebnis bestätigen</strong>. \
                Das System gleicht jetzt die Ergebnisse aller Schreiber ab \
                (bei schlechter Internetverbindung kann es notwendig sein - diesen Schritt öfters zu wiederholen) \
                und übernimmt es in die Auswertung.\
                <br/><br/>\
                <strong>Zeige Turnierergebnis</strong> leitet sie auf www.3dturnier.com weiter und \
                zeigt den aktuellen Zwischenstand oder das Endergebnis des Turniers.</p>",
    "skillboard.faq.archers": "Schützen",
    "skillboard.faq.parcour": "Parcours",
    "skillboard.faq.event": "Während eines Event",
    "skillboard.faq.synchronization": "Synchronisierung",
    "skillboard.faq.q1.title": "Wieso gibt es die Aufteilung zwischen Schützen/innen \"Mit\" und \"Ohne\" E-Mail?",
    "skillboard.faq.q1.content": "\
            Für die Verteilung der eingegebenen Events an die anderen Schützen/innen wird die E-Mail zur Identifikation verwendet. Wenn diese in der App eingeloggt sind werden beim\
            Synchronisieren alle Events heruntergeladen, bei denen die Account E-Mail beteiligt war. Die Ergebnisse der Schützen/innen ohne E-Mail werden nicht im Hintergrundsystem gespeichert\
            und deswegen auch nicht verteilt.",
    "skillboard.faq.q2.title": "Wie kann ich die Daten eines Schützen/in ändern?",
    "skillboard.faq.q2.content": "\
            Am Startscreen auf \"Schützen\" klicken und \"lange\" auf den gewünschten Schützen/in drücken. Es öffnet sich ein Kontextmenü mit folgenden Möglichkeiten:\
            <ul>\
                <li>Ändern: Name, Email oder Wertung kann für den Schützen/in geändert werden.</li>\
                <li>Löschen: Der gewählte Schütze/in wird aus der lokalen Liste entfernt - in den Ergebnissen bleibt er/sie jedoch sichtbar</li>\
                <li>Ersetzten: Der gewählte Schütze/in wird aus der lokalen Liste entfernt - jedoch wird er/sie in den beteiligten Events gegen den neu zu wählenden Schützen/in ausgetauscht. Ist\
                    primär dazu gedacht, doppelte Schützen/innen durch verschiedene emails wieder zu vereinen.\
                </li>\
            </ul>",
    "skillboard.faq.q3.title": "Wieso tauchen Schützen doppelt auf?",
    "skillboard.faq.q3.content": "\
            Ab Version 2.6: Beim Herunterladen eines Events wird versucht die Teilnehmer mit einem \
            bereits vorhandenen Schützen/in anhand der E-Mail zuzuweisen. Sollte es keinen passenden Schützen/in mit dieser \
            E-Mail geben - wird ein neuer Eintrag angelegt. Über die Kontextmenü Aktion \"Ersetzten\" \
            können Schützen/innen wieder zusammengeführt werden.",
    "skillboard.faq.q4.title": "Meine Wertung ist nicht auswählbar?",
    "skillboard.faq.q4.content": "\
            1) Wertung ist nicht aktiviert? <br/>\
            Im Seitenmenü gibt es den Menüpunkt \"Pfeil Wertung\". Hier werden alle lokal verfügbaren Wertungen angezeigt. Sie können die Wertung de/aktivieren um diese in den Auswahlmenüs\
            aus/ein zu blenden.\
            <br/>\
            2) Wertungen synchronisieren\
            <br/>\
            Drücken sie im Seitenmenü den Punkt \"Synchronisieren\". Es werden alle neu verfügbaren Wertungen aus dem Hintergrundsystem geladen. Vielleicht ist ihre bereits dabei.\
            <br/>\
            3) Eigene anlegen \
            <br/>\
            Sollte ihre Wertung trotzdem nicht verfügbar sein - können sie auch selbst eine Wertung anlegen. Siehe \"Tab -> Wertungen\" :-)",

    "skillboard.faq.q5.title": "Wieso gibt es die Aufteilung zwischen Parcours \"aus Wiki\" und \"lokal erstellt\"?",
    "skillboard.faq.q5.content": "\
            Die interne ID aus dem Parcours Wiki ermöglicht beim Synchronisieren des Events dieses bei den anderen Teilnehmern auch dem selben Parcours zuweisen zu können.",
    "skillboard.faq.q6.title": "Wie kann ich die Daten eines Parcours ändern?",
    "skillboard.faq.q6.content": "\
            Am Startscreen auf \"Parcours\" klicken und \"lange\" auf den gewünschten Parcours drücken. Es öffnet sich ein Kontextmenü mit folgenden Möglichkeiten:\
            <ul>\
                <li>Ändern: Name oder Ort kann für den Parcours geändert werden.</li>\
                <li>Löschen: Der gewählte Parcours wird aus der lokalen Liste entfernt - Achtung! auch alle Ergebnisse für diesen Parcours werden gelöscht</li>\
                <li>Ersetzten: Der gewählte Parcours wird aus der lokalen Liste entfernt - jedoch werden die Events zu dem neu zu wählenden Parcours hinzugefügt. Ist primär dazu gedacht Events\
                    von \"lokalen\" zum Parcours aus dem Wiki zu übertragen.\
                </li>\
            </ul>",
    "skillboard.faq.q7.title": "Wie kann ich manuelle Punkte für ein Ziel in der App eingeben?",
    "skillboard.faq.q7.content": "\
            Ab Version 2.4: Bei dem PopUp für die Punkteauswahl gibt es rechts oben einen Bleistift. Wenn man auf diesen drückt kann man beinahe beliebige Punkte für ein Ziel eingeben.\
            Aber auch \"Keine Wertung\" klicken -> dann wird das Ziel für den jeweiligen Schützen übersprungen und fließt auch in die Wertung nicht ein..",
    "skillboard.faq.q8.title": "Wie kann ich einen Schützen/in aus dem Event entfernen",
    "skillboard.faq.q8.content": "\
        Auf das 3 Punkte Menü für mehr drücken und das Editieren Schützen zu aktivieren:<br/>\
        Jetzt kann die Reihenfolge der Schütze geändert werden aber auch ein Schütze aus dem Event gewischt werden. <br/>\
        Die bisherigen Ergebnisse bleiben sichtbar. Durch abschließen und wieder eröffnen eines Events ist der ausgeschlossene Schütze/in wieder im Event.",
    "skillboard.faq.q9.title": "Was sind DPS und BHS?",
    "skillboard.faq.q9.content": "\
            DPS heißt eigentlich \"damage per second\" und kommt eben aus der MMORPG Welt und ist der durchschnittliche Schaden der an einem Gegner pro Sekunde angerichtet wird. In der App ist\
            es \"damage per shot\" -> durchschnittliche Punkte je Pfeil auf dem Ziel. Ein nicht getroffenes Ziel zählt in der Standardwertung aber nur als einmal 0 getroffen.\
            BHS oder auch BowHunter Score sind die durchschnittliche Punkte je Pfeil auf dem Ziel - aber es werden nur die Punkte des ersten Pfeils berücksichtigt. Triffst du \
            das Ziel erst auf dem zweitem Pfeil, zählt das als Miss",
    "skillboard.faq.q10.title": "Wie kann ich bereits eingegeben Punkte ändern?",
    "skillboard.faq.q10.content": "\
            Einerseits kann mit dem Button \"zurück\" im Event das vorherige Ziel geladen und die Eingaben nochmals editiert werden. Andererseits wird mit einem Klick auf die Punkte eines Ziel in der\
            Ergebnistabelle eine schwarze Infobox angezeigt. Darin sind die Treffer-Info ersichtlich und der Button der das Treffer Popup öffnet um die Punkte zu ändern.",
    "skillboard.faq.q11.title": "Wie funktioniert die Event-Synchronisierung - Speichern im Hintergrundsystem?",
    "skillboard.faq.q11.content": "\
            Ab Version 2.6: Grundvoraussetzung für die Event-Synchronisation ist ein angemeldeter User mit verifizierter E-Mail. Um ein Event im Hintergrundsystem zu speichern muss der \
            Parcours für das betroffene Event aus dem Wiki übernommen worden sein. Das ist notwendig um später das Event bei den anderen Teilnehmern auch dem selben Parcours zuweisen zu \
            können. Zusätzlich werden nur Schützen mit eingegebener Email synchronisiert. Beides kann auch im nachhinein noch geändert werden (Ersetzen im Kontext Menü).\
            Drücken sie im Seitenmenü einfach auf Synchronisieren und den Vorgang zu starten.",
    "skillboard.faq.q12.title": "Wie kann ich einen Parcours ins Parcours Wiki hinzufügen?",
    "skillboard.faq.q12.content": "\
            Jeder angemeldete User mit verifizierter E-Mail darf im Parcours Wiki mitmachen. Im Wiki einfach \"Neuer Parcours\" drücken und mit der Eingabe der PLZ und des Namens wird dieser erzeugt. \
             Danach können sich noch viele weitere Details hinzufügen. Bitte vergewissern sie sich vorher ob der Parcours bereits existiert. Das Umstellen oder Verändern der Zielanzahl macht noch keinen neuen Parcours - erst wenn sich der Standort ändert ist dies ein neuer Parcours.\
             Wenn ein Parcours die Möglichkeit bietet mehr oder weniger Ziele zu gehen ist das nur EIN Parcours. Nur wenn der Betreiber dafür andere Preise anbieten bzw extra ausgeschildert ist - sind das ZWEI Parcours.\
             Wenn bei einem bereits existierenden Parcours ein weiterer hinzugefügt wird - bitte verwenden sie \"Neuer Parcours am bestehendem Ort\". Dann wird für den Parcours der selbe Verein sowie Adresse benutzt.",
    "skillboard.faq.q13.title": "Handywechsel - Wie bekomme ich meine Events auf das neue Handy?",
    "skillboard.faq.q13.content": "\
            Der bequemste Weg ist die Event-Synchronisierung - alle Events der Parcours aus dem Wiki sowie Schützen mit E-Mail werden in unserem Hintergrundsystem gespeichert.\
            Sobald sie sich am neuen Handy einloggen und Synchronisieren drücken werden alle Events wieder auf das Gerät heruntergeladen.",
    "skillboard.faq.q14.title": "Wie funktioniert die Event-Synchronisierung - Laden aus dem Hintergrundsystem?",
    "skillboard.faq.q14.content": "\
            Sie bekommen Events in ihre lokale Datenbank geladen - wenn die E-Mail ihres Accounts bei dem Schützen hinterlegt ist. \
            Daher sollten sie bei Freunden und Bekannten immer darauf achten das die E-Mail richtig hinterlegt wird.",
    "skillboard.faq.q15.title": "Wie kann ich Ziel überspringen?",
    "skillboard.faq.q15.content": "\
            Noch bevor sie Punkte für das Ziel eingeben drücken sie einfach auf weiter zum nächsten Ziel. \
            Eine Meldung erscheint mit der sie das Ziel überspringen können oder beim aktuellem bleiben.",
    "smarttournament.text.html": "\
        Ein Smart Turnier kann jederzeit während einer Parcoursrunde \
        gestartet werden. Es erlaubt die Teilnehmer über mehrere Apps \
        verteilt in einem Event zu vereinen. <br/><br/>Gestartet wird das Smart Event mit dem Wolkenbutton. \
        Grundvoraussetzung dafür ist, dass der Parcours aus dem Wiki übernommen wurde. Weiters werden nur \
        Teilnehmer mit gespeicherter E-Mail übertragen - diese dient als Teilnehmeridentifizierung. \
        <br/><br/> \
        Ein Zutrittscode erlaubt nur erwünschte Teilnehmer im Smart Turnier. Dieser muss eingegeben werden, \
        wenn die Teilnahme über die \"Turnier-Suche\" am Startscreen erfolgt. Nach dem Dialog gibt es die Option eine Einladung \
        inklusive Link zu versenden - hier entfällt die Codeeingabe! \
        <br/><br/> \
        Es können nur jene Punkte in der Ergebnistabelle editiert werden, die auch selbst eingegeben wurden. \
        Um einen Sieger/in festzustellen, sollten die eingegebenen Punkte nach der Runde bestätigt werden. \
        Danach lassen sich die erfassten Punkte nicht mehr ändern. Durch den Synchronisierungsprozess wird die Tabelle auch später \
        noch um Ergebnisse oder Spieler erweitert. \
        <br/><br/> \
        Tipp: Der versandte Link im Browser geöffnet führt hierher und unten erscheint das bestätigte Ergebnis.",
    "smarttournament.text.alert":"Öffnen sie den versendeten Link einfach mit der 3D SKill Board App diese wird automatisch am richtigen Smart Turnier teilnehmen. Oder suchen sie bei dem ausgewählten Parcours nach offenen Smart Turniers direkt in der App.",
    "impressum.title": "Impressum",
    "label.help":"Hilfe",
    "language.de":"Deutsch",
    "language.en":"Englisch",
    "language.es":"Spanisch",
    "language.fr":"Französisch",
    "language.it":"Italienisch",
    "language.ru":"Russisch",
    "language.pl":"Polnisch",
    "language.da":"Dänisch",
    "language.sv":"Schwedisch",
    "language.nl":"Niederländisch",
    "language.cs":"Tschechisch",
    "language.keyIOS":"keyIOS",
    "donate.beer": "Ein Bier für unsere Arbeit",
    "footer.language": "Sprachen",
    "footer.impressum": "Impressum"
};
