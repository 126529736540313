var Parse = require("ParseInit");

var TransactionSettlement = Parse.Object.extend("transactionSettlement", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        currency: "currency",
        tax: "tax",
        taxConfig: "taxConfig",
        objectId: "objectId",
        settlementPositions: "settlementPositions",
        discount: "discount",
        sum: "sum",
        income: "income",
        provision: "provision",
        status: "status",
        invoiceNumber: "invoiceNumber",
        invoice: "invoice",
        month: "month",
        year: "year",
        parcoursOwnerID: "parcoursOwnerID",
        managementID: "managementID",
        emailSuccess: "emailSuccess",
        updatedAt: "updatedAt"
    },
    getSettlementDate() {
        let tDate = this.getCreatedAt();
        return tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
    },
    getFormattedPrice() {
        return this.getCurrency() + " " + parseFloat(this.getIncome()).toFixed(2);
    },
    getFormattedProvision() {
        return this.getCurrency() + " " + parseFloat(this.getProvision()).toFixed(2);
    },
    getExtraRowPrice() {
        return this.getProvision()
    },
    getBrand() {
        // needed to export real transactions
        return ""
    },
    isStornoBrand() {
        return false;
    },
    getSurcharge() {
        return null;
    },
    getDiscount() {
        return this.get(this.col.discount);
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getSettlementPositions() {
        return this.get(this.col.settlementPositions)
    },
    setSettlementPositions(settlementPositions) {
        return this.set(this.col.settlementPositions, settlementPositions)
    },
    getSum() {
        return this.get(this.col.sum)
    },
    setSum(sum) {
        return this.set(this.col.sum, sum)
    },
    getBruttoPrice() {
        return this.getSum();
    },
    getFullPrice() {
        return this.getIncome();
    },
    getIncome() {
        return this.get(this.col.income)
    },
    setIncome(income) {
        return this.set(this.col.income, income)
    },
    getProvision() {
        return this.get(this.col.provision)
    },
    setProvision(provision) {
        return this.set(this.col.provision, provision)
    },
    setInvoiceNumber(invoiceNumber) {
        return this.set(this.col.invoiceNumber, invoiceNumber)
    },
    getInvoiceNumber() {
        return this.get(this.col.invoiceNumber)
    },
    getClubInvoiceID() {
        // only needed in transaction
        return null
    },
    getInvoiceDate() {
        return this.getCreatedAt();
    },
    getInvoice() {
        return this.get(this.col.invoice)
    },
    setInvoice(invoice) {
        return this.set(this.col.invoice, invoice)
    },
    getMonth() {
        return this.get(this.col.month)
    },
    setMonth(month) {
        return this.set(this.col.month, month)
    },
    getYear() {
        return this.get(this.col.year)
    },
    setYear(year) {
        return this.set(this.col.year, year)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getTax() {
        return this.get(this.col.tax)
    },
    getTaxConfig() {
        return this.get(this.col.taxConfig)
    },
    setTax(tax) {
        return this.set(this.col.tax, tax)
    },
    getParcoursOwnerID() {
        return this.get(this.col.parcoursOwnerID);
    },
    setParcoursOwnerID(parcoursOwnerID) {
        return this.set(this.col.parcoursOwnerID, parcoursOwnerID);
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    setManagementID(managementID) {
        this.set(this.col.managementID, managementID);
    },
    setMailSuccess(success) {
        this.set(this.col.emailSuccess, success);
    },
    getMailSuccess() {
        return this.get(this.col.emailSuccess);
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    }
});

module.exports = TransactionSettlement;
