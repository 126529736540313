const Parse = require("ParseInit");
const ConfigKeys = require("ConfigKeys");
const {ParcoursOwner, Parcours, ParcoursRating, ParcoursVisit, GroupPlace} = require("parse/_Domain");

class ParcoursDao {

    queryParcourList(pageSize, skipSize, searchParams) {
        const query = new Parse.Query(Parcours);
        query.notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted);
        const poSKillBoard = new ParcoursOwner()
        poSKillBoard.id = ConfigKeys.SKillBoardOwnerId
        query.notEqualTo(Parcours.prototype.col.parcoursOwnerID, poSKillBoard)
        query.include(Parcours.prototype.col.parcoursOwnerID);
        query.ascending(Parcours.prototype.col.sortIndex + "," + Parcours.prototype.col.onlineID);
        if (searchParams.mainGroupPlaceID != null) {
            const mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            query.equalTo(Parcours.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            const regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            query.equalTo(Parcours.prototype.col.groupPlaceID, regionGroupPlace);
        }
        if (searchParams.payment) {
            query.equalTo(Parcours.prototype.col.payStatus, Parcours.prototype.payStatus.enabled);
        }
        query.limit(pageSize);
        query.skip(skipSize);
        return query.find();
    }
    /**
     * @param searchParams
     * @returns Promise
     */
    search(searchParams) {
        const parcoursQuery = new Parse.Query(Parcours);
        const poSKillBoard = new ParcoursOwner()
        poSKillBoard.id = ConfigKeys.SKillBoardOwnerId
        parcoursQuery.notEqualTo(Parcours.prototype.col.parcoursOwnerID, poSKillBoard)
        parcoursQuery.include(Parcours.prototype.col.parcoursOwnerID);
        const ownerQuery = new Parse.Query(ParcoursOwner);
        this.applyAndParcoursFilter(searchParams, parcoursQuery, ownerQuery);
        if (searchParams.coords) {
            const userLocation =  new Parse.GeoPoint({latitude: searchParams.coords.latitude, longitude: searchParams.coords.longitude});
            ownerQuery.withinKilometers(ParcoursOwner.prototype.col.coordinates, userLocation, searchParams.withinKM);
            ownerQuery.limit(searchParams.pageMax);
            parcoursQuery.matchesQuery(Parcours.prototype.col.parcoursOwnerID, ownerQuery);
            parcoursQuery.ascending(Parcours.prototype.col.sortIndex + "," + Parcours.prototype.col.onlineID);
        } else {
            parcoursQuery.matchesQuery(Parcours.prototype.col.parcoursOwnerID, ownerQuery);
            // normal query
            parcoursQuery.ascending(Parcours.prototype.col.sortIndex + "," + Parcours.prototype.col.onlineID);
            parcoursQuery.limit(searchParams.pageMax);
        }
        return parcoursQuery.find();
    }

    searchSimilar(searchParams) {
        const parcoursQuery = new Parse.Query(Parcours);
        parcoursQuery.include(Parcours.prototype.col.parcoursOwnerID);
        const ownerQuery = new Parse.Query(ParcoursOwner);
        this.applyAndParcoursFilter(searchParams, parcoursQuery, ownerQuery);
        let zip = parseInt(searchParams.searchText);
        if (zip > 0) {
            // search by zip in parcoursowner
            ownerQuery.matches("zip", "(" + zip + ")", "i");
        }
        parcoursQuery.matchesQuery(Parcours.prototype.col.parcoursOwnerID, ownerQuery);
        // normal query
        parcoursQuery.ascending(Parcours.prototype.col.sortIndex + "," + Parcours.prototype.col.onlineID);
        parcoursQuery.limit(searchParams.pageMax);
        return parcoursQuery.find();
    }


    applyAndParcoursFilter(searchParams, parcoursQuery, ownerQuery) {
        if (searchParams.payment) {
            parcoursQuery.equalTo(Parcours.prototype.col.payStatus, Parcours.prototype.payStatus.enabled);
        }
        if (searchParams.mainGroupPlaceID != null) {
            const mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            parcoursQuery.equalTo(Parcours.prototype.col.mainGroupPlaceID, mainGroupPlace);
            ownerQuery.equalTo(ParcoursOwner.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            const regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            parcoursQuery.equalTo(Parcours.prototype.col.groupPlaceID, regionGroupPlace);
            ownerQuery.equalTo(ParcoursOwner.prototype.col.groupPlaceID, regionGroupPlace);
        }
        parcoursQuery.equalTo(Parcours.prototype.col.status, "A");
        ownerQuery.equalTo(ParcoursOwner.prototype.col.status, "A");
    }

    queryParcours(onlineID) {
        const query = new Parse.Query(Parcours);
        query.notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted);
        query.equalTo(Parcours.prototype.col.onlineID, onlineID);
        query.include(Parcours.prototype.col.articles);
        query.include(Parcours.prototype.col.parcoursOwnerID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.managementID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.bannerImageID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.ruleImageID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.articles);
        return query.first();
    }

    queryParcoursForGroupPlace(mainGroupPlace, regionGroupPlace) {
        const query = new Parse.Query(Parcours);
        query.notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted);
        query.equalTo(Parcours.prototype.col.mainGroupPlaceID, mainGroupPlace);
        query.equalTo(Parcours.prototype.col.groupPlaceID, regionGroupPlace);
        query.include(Parcours.prototype.col.parcoursOwnerID);
        query.ascending(Parcours.prototype.col.onlineID);
        query.limit(1000)
        return query.find();
    }

    queryParcoursRatings(parcours, user){
        const query = new Parse.Query(ParcoursRating);
        query.notEqualTo(ParcoursRating.prototype.col.status, Parcours.prototype.status.deleted);
        query.equalTo(ParcoursRating.prototype.col.parcoursID, parcours);
        query.include(ParcoursRating.prototype.col.editor);
        query.descending(ParcoursRating.prototype.col.updatedAt);
        if (user != null) {
            query.equalTo(ParcoursRating.prototype.col.editor, user);
            return query.first();
        } else {
            return query.find();
        }
    }

    queryParcoursCheckout(onlineID) {
        const query = new Parse.Query(Parcours);
        query.notEqualTo(ParcoursRating.prototype.col.status, Parcours.prototype.status.deleted);
        query.equalTo(Parcours.prototype.col.onlineID, onlineID);
        query.include(Parcours.prototype.col.articles);
        query.include(Parcours.prototype.col.parcoursOwnerID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.ruleImageID);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.articles);
        query.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.bannerImageID);
        return query.first();
    }

    queryParcoursOwnerTeaser() {
        const query = new Parse.Query(ParcoursOwner);
        query.exists(ParcoursOwner.prototype.col.managementID);
        query.equalTo(ParcoursOwner.prototype.col.manageStatus, ParcoursOwner.prototype.mgmtStatus.active);
        query.notEqualTo(ParcoursOwner.prototype.col.status, Parcours.prototype.status.deleted);
        query.notEqualTo(ParcoursOwner.prototype.col.hideFront, true);
        query.include(ParcoursOwner.prototype.col.bannerImageID);
        query.ascending(ParcoursOwner.prototype.col.sortIndex);
        return query.find();
    }

    queryParcoursOwnerSorting() {
        const query = new Parse.Query(ParcoursOwner);
        query.exists(ParcoursOwner.prototype.col.managementID);
        query.notEqualTo(ParcoursOwner.prototype.col.status, Parcours.prototype.status.deleted);
        query.include(ParcoursOwner.prototype.col.managementID);
        return query.find();
    }

    getParcoursOwner(parcoursOwnerId) {
        const query = new Parse.Query(ParcoursOwner);
        query.include(ParcoursOwner.prototype.col.managementID);
        query.include(ParcoursOwner.prototype.col.bannerImageID);
        return query.get(parcoursOwnerId);
    }

    getParcoursForParcoursOwner(parcoursOwnerID) {
        return new Parse.Query(Parcours)
            .equalTo(Parcours.prototype.col.parcoursOwnerID, parcoursOwnerID)
            .find();
    }

    getParcoursHome(parcoursOwnerId) {
        const parcoursOwner = new ParcoursOwner();
        parcoursOwner.setObjectId(parcoursOwnerId);
        return new Parse.Query(Parcours)
            .notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted)
            .equalTo(Parcours.prototype.col.parcoursOwnerID, parcoursOwner)
            .include(Parcours.prototype.col.parcoursOwnerID)
            .include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.bannerImageID)
            .find()
    }

    getParcours(parcoursOwner) {
        return new Parse.Query(Parcours)
            .notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted)
            .equalTo(Parcours.prototype.col.parcoursOwnerID, parcoursOwner)
            .find()
    }

    getParcoursWithArticles(parcoursOwnerId) {
        const parcoursOwner = new ParcoursOwner();
        parcoursOwner.setObjectId(parcoursOwnerId);
        return new Parse.Query(Parcours)
            .notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted)
            .equalTo(Parcours.prototype.col.parcoursOwnerID, parcoursOwner)
            .include(Parcours.prototype.col.parcoursOwnerID)
            .include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.articles)
            .include(Parcours.prototype.col.articles)
            .find()
    }

    getParcourOwnersWithMail() {
        const query = new Parse.Query(ParcoursOwner);
        query.notEqualTo(ParcoursOwner.prototype.col.status, Parcours.prototype.status.deleted);
        query.include(ParcoursOwner.prototype.col.groupPlaceID);
        query.include(ParcoursOwner.prototype.col.mainGroupPlaceID);
        query.include(ParcoursOwner.prototype.col.editor);
        query.exists(ParcoursOwner.prototype.col.contactMail);
        query.descending(ParcoursOwner.prototype.col.createdAt);
        query.limit(2000);
        return query.find();
    }

    getParcoursForPOwnerList(pOwnerList) {
        const pQuery = new Parse.Query(Parcours);
        pQuery.notEqualTo(Parcours.prototype.col.status, Parcours.prototype.status.deleted);
        pQuery.include(Parcours.prototype.col.parcoursOwnerID);
        pQuery.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.managementID);
        pQuery.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.clubID);
        pQuery.include(Parcours.prototype.col.parcoursOwnerID + "." + ParcoursOwner.prototype.col.ruleImageID);
        pQuery.containedIn(Parcours.prototype.col.parcoursOwnerID, pOwnerList);
        return pQuery.find();
    }

    parseEditParcoursCC(object, colName, pNewValue) {
        // run cloud code change
        return Parse.Cloud.run('editParcours', { objectId: object.id, objectClass: object.className, dirtyKey: colName, newValue: pNewValue});
    };

    createParcoursCC(name, zip, ownerID, type, regionGroupPlace, mainGroupPlace) {
        return Parse.Cloud.run('createParcours', {
            name: name,
            zip: zip,
            ownerID: ownerID,
            type: type,
            groupPlaceID: regionGroupPlace,
            mainGroupPlaceID: mainGroupPlace
        });
    }

    loadParcoursVisit(visitID) {
        const query = new Parse.Query(ParcoursVisit);
        return query.get(visitID);
    }

    loadLiveParcoursVisit(parcours) {
        const today = new Date();
        const searchParams = {
            startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
            endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(),23,59,59,0),
            ownerID: parcours.getParcoursOwnerID().id,
            parcoursID: parcours.id,
            searchText: null,
            status: ParcoursVisit.prototype.status.started
        };
        return Parse.Cloud.run('queryParcoursVisit',searchParams);
    }

    queryParcoursVisit(searchParams) {
        return Parse.Cloud.run('queryParcoursVisit', {ownerID: searchParams.ownerID, parcoursID: searchParams.parcoursID, startDate: searchParams.startDate, endDate:searchParams.endDate, searchText: searchParams.searchText});
    }

    updateParcoursVisit(visitID, changeObject) {
        return Parse.Cloud.run('updateParcoursVisit', {visitID: visitID, changeObject: changeObject});
    }

    registerParcoursVisit(visitObject) {
        return Parse.Cloud.run('registerParcoursVisit', {visitObject: visitObject});
    }

    checkParcoursFeatures(parcoursOnlineID) {
        return Parse.Cloud.run('hasParcoursFeatures', {parcoursOnlineID: parcoursOnlineID});
    }

    isUserParcoursEditor(parcours) {
        return Parse.Cloud.run('isUserParcoursEditor', {parcoursObjectId: parcours.id});
    }

    deleteParcours(parcours) {
        return Parse.Cloud.run('delParcours', {parcoursObjectId: parcours.id});
    }
}

module.exports = new ParcoursDao();
