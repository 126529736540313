const React = require("react"),
    linkUtil = require("linkUtil");
const {Image} = require("react-bootstrap");
const {Link} = require ('react-router-dom');

module.exports = ({imgStyle}) => {
    return <Link to={linkUtil.getLink("skillboard/premium")}>
        <Image style={imgStyle} rounded responsive src="/img/premium/premium_banner.jpg"/>
    </Link>
};
