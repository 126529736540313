const alt = require("AltInit"),
    TournamentUserCupResultDic = require("util/TournamentUserCupResultDic"),
    TournamentUserGroupingDic = require("util/TournamentUserGroupingDic"),
    PLUtil = require("util/ParseListUtility"),
    FileDownloadUtility =  require("util/FileDownloadUtility"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility");

const parseDao = require("actdao/ParseBaseDao");
const eventDao = require("actdao/EventDao");
const tournamentUserDao = require("actdao/TournamentUserDao");
const tournamentManagerDao = require("actdao/TournamentManagerDao");
const notificationActions = require("components/notification/NotificationActions");
const tableActions = require("actions/TableActions");

const { TournamentUser, TournamentUserGroup, TournamentUserTeam, TournamentRegSlot} = require("parse/_Domain");
const GROUP_CHARS = require("components/tournamentmanage/TournamentUtility").GROUP_CHARS()
const POS_KEY_INDEX = require("components/tournamentmanage/TournamentUtility").POS_KEY_INDEX()

class TournamentUserManagerActions {

    loadTournamentUsers(tournament, hideDeleted) {
        tableActions.setSearching(true);
        tournamentUserDao.loadAllTournamentUsers(tournament, hideDeleted)
            .then(tournamentUser => {
                tableActions.updateObjectList(tournamentUser);
                this.updateTournamentUsers(tournamentUser);
            })
            .catch(error => {
                error.trace = "TUMA.loadTUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentUserGroups(tournament, hideDeleted) {
        tournamentUserDao.loadAllTournamentUserGroups(tournament, hideDeleted)
            .then(groups => {
                this.updateTournamentUserGroups(groups);
            })
            .catch(error => {
                error.trace = "TUMA.loadTUserGroup";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentUserTeams(tournament, hideDeleted) {
        tournamentUserDao.loadTournamentUserTeams(tournament, hideDeleted)
            .then(pTournamentUserTeams => {
                this.updateTournamentUserTeams(pTournamentUserTeams);
            })
            .catch(error => {
                error.trace = "TUMA.loadTUserTeam";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentUserGroupsResults(tournament, tRound) {
        tournamentUserDao.loadAllTournamentUserGroupsResult(tournament, tRound)
            .then(groupsResult => {
                this.updateTournamentUserGroupsResults(groupsResult);
            })
            .catch(error => {
                error.trace = "TUMA.loadTUserGroupResult";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadTournamentGroupLogs(tUserGroup, resultCallback) {
        tournamentUserDao.loadTournamentGroupCCLog(tUserGroup)
            .then(result => {
                if (resultCallback) {
                    resultCallback(result);
                }
            })
            .catch(error => {
                error.trace = "TUMA.loadCCLog";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteTournamentUserGroupsResult(groupResult) {
        groupResult.setStatus("D")
        parseDao.saveObject(groupResult)
            .then(sGroupResult => {
                this.updateTournamentUserGroupsResult(sGroupResult);
            })
            .catch(error => {
                error.trace = "TUMA.updTUserGroupResult";
                notificationActions.parseError(error);
            });
        return {};
    }

    findEventsToGroup(tUserGroup, tRound, user, callbackEvents) {
        eventDao.findEventToGroup(tUserGroup, tRound, user)
            .then(events => {
                callbackEvents(events);
            })
            .catch(error => {
                error.trace = "TUMA.findEv";
                notificationActions.parseError(error);
            });
        return {};
    }


    loadTournamentUsersAddress(tournament, callback) {
        tournamentUserDao.loadTournamentUsersAddress(tournament)
            .then(tUsers => {
                callback(tUsers);
            })
            .catch(error => {
                callback([])
                error.trace = "TUMA.findTUAddr";
                notificationActions.parseError(error);
            });
        return {}
    }
    removeFromTournamentGroup(tournament, selTournamentUsers) {
        selTournamentUsers.map(tUser => {
            this.removeTUserFromTUserGroup(tUser.getTournamentGroupID(), tUser);
        })
    }

    createTournamentGroup(tournament, selTournamentUsers, actTRound, existingTournamentUserGroups) {
        const tGroupUsers = this.filterTournamentUserList(selTournamentUsers, null,false);
        let grpNumber = existingTournamentUserGroups.length + 1;
        // create group
        const tuGroup = new TournamentUserGroup();
        tuGroup.setStatus(TournamentUserGroup.prototype.status.present);
        tuGroup.setName("Gruppe" + grpNumber);   // "tournament.table.column.group"
        tuGroup.setStartPosition(1);
        tuGroup.setTournamentID(tournament);
        tuGroup.setTournamentStringID( tournament.id);
        tuGroup.setTournamentUserIDs(tGroupUsers);
        if (actTRound) {
            tuGroup.setTournamentRoundID(actTRound);
        }
        if (tournament.isFixedTUInGroup()) {
            // handle fixedTUsers
            tuGroup.setFixedTournamentUserIDs(this.buildFixedTournamentUserIDObject(selTournamentUsers));
        }
        parseDao.saveObject(tuGroup)
            .then(sTuGroup => {
                // add new group to first place on the list
                existingTournamentUserGroups.splice(0, 0, sTuGroup);
                this.updateTournamentUserGroups(existingTournamentUserGroups);
                // group have been saved - set user reference to group
                this.saveAllTUserGroupTUsers(tGroupUsers, sTuGroup, null, success => {
                    if (success == false) {
                        notificationActions.warning("Gruppe wurde erzeugt aber die Teilnehmer nicht rückverlinkt")
                        // TODO - what else - ??
                    }
                });
            })
            .catch(error => {
                error.trace = "TUMA.createTUG";
                notificationActions.parseError(error);
            })
        return {};
    }

    createTournamentTeam(tournament, selTournamentUsers, actTRound, existingTournamentUserTeams) {
        const tuTeam = new TournamentUserTeam();
        tuTeam.setStatus(TournamentUserTeam.prototype.status.present);
        tuTeam.setName("0_NONAME");
        tuTeam.setTournamentID(tournament);
        tuTeam.setTournamentUserIDs(selTournamentUsers);
        if (actTRound) {
            tuTeam.setTournamentRoundID(actTRound);
        }
        parseDao.saveObject(tuTeam)
            .then(sTuTeam => {
                // add new group to first place on the list
                existingTournamentUserTeams.splice(0, 0, sTuTeam);
                this.updateTournamentUserTeams(existingTournamentUserTeams);
            })
            .catch(error => {
                error.trace = "TUMA.createTUT";
                notificationActions.parseError(error);
            });
        return {};
    }

    addTUserToTournamentGroup(tUserGroup, selTournamentUsers) {
        const existGroupUsers = tUserGroup.getTournamentUserIDs();
        const newGroupUsers = this.filterTournamentUserList(selTournamentUsers, existGroupUsers, false);
        if (newGroupUsers.length > 0) {
            // to combine with existing one
            const tGroupUsers = existGroupUsers.concat(newGroupUsers);
            tUserGroup.setTournamentUserIDs(tGroupUsers);
            // add users to next free position or append
            const fixedTUserObject = tUserGroup.getFixedTournamentUserIDs()
            if (fixedTUserObject != null) {
                newGroupUsers.map(newGroupUser => {
                    let fixed = false
                    Object.keys(fixedTUserObject).map(posKey => {
                        let fUser = fixedTUserObject[posKey];
                        if (fUser == null && !fixed) {
                            // update empty place
                            fixedTUserObject[posKey] = newGroupUser
                            fixed = true
                        }
                    })
                    if (!fixed) {
                        // append as last
                        let nPosKey = GROUP_CHARS[Object.keys(fixedTUserObject).length]
                        fixedTUserObject[nPosKey] = newGroupUser
                    }
                })
                tUserGroup.setFixedTournamentUserIDs(fixedTUserObject)
            }

            parseDao.saveObject(tUserGroup)
                .then(sTuGroup => {
                    // update sTuGroup in store
                    this.updateTournamentUserGroup(sTuGroup);
                    // group have been saved - set user reference to group
                    this.saveAllTUserGroupTUsers(tGroupUsers, sTuGroup, null, success => {
                        if (success == false) {
                            notificationActions.warning("Der Gruppe wurde der Teilnehmer hinzugefügt, aber dem Teilnehmer die Gruppe nicht rückverlinkt")
                            // TODO - what else??
                        }
                    });
                })
                .catch(error => {
                    error.trace = "TUMA.addTU2TUG";
                    notificationActions.parseError(error);
                })
        }
        return {};
    }

    addTUserToTournamentTeam(tUserTeam, selTournamentUsers) {
        const existGroupUsers = tUserTeam.getTournamentUserIDs();
        const newGroupUsers = this.filterTournamentUserList(selTournamentUsers, existGroupUsers, true);
        if (newGroupUsers.length > 0) {
            // to combine with existing one
            const tGroupUsers = existGroupUsers.concat(newGroupUsers);
            tUserTeam.setTournamentUserIDs(tGroupUsers);
            parseDao.saveObject(tUserTeam)
                .then(sTuTeam => {
                    // update sTuTeam in store
                    this.updateTournamentUserTeam(sTuTeam);
                })
                .catch(error => {
                    error.trace = "TUMA.addTU2TUT";
                    notificationActions.parseError(error);
                })
        }
        return {};
    }

    filterTournamentUserList(tournamentUsers, existingTournamentUsers, isTeamFilter) {
        const tGroupUsers = [];
        if (tournamentUsers != null) {
            // check if users are already in other group
            tournamentUsers.map(tUser => {
                let existsGrp = isTeamFilter ? null : tUser.getTournamentGroupID();
                if (existsGrp != null && existsGrp.isStarted()) {
                    notificationActions.info(tUser.getFullName() + " ist bereits in gestarteter " + existsGrp.getName())
                } else if (existsGrp != null && existsGrp.isPresent()) {
                    notificationActions.info(tUser.getFullName() + " ist bereits in anwesender " + existsGrp.getName())
                } else if (existingTournamentUsers != null) {
                    // check not already in group - else push it
                    if (!PLUtil.contains(existingTournamentUsers, tUser)) {
                        tGroupUsers.push(tUser)
                    }
                } else {
                    tGroupUsers.push(tUser)
                }
            })
        }
        return tGroupUsers;
    }

    deleteTournamentUserGroup(tUserGroup) {
        // unset user reference to group
        this.saveAllTUserGroupTUsers(tUserGroup.getTournamentUserIDs(), null, null, success => {
            if (success) {
                tUserGroup.setStatus(TournamentUserGroup.prototype.status.deleted);
                tUserGroup.unset(TournamentUserGroup.prototype.col.tournamentRoundID);
                parseDao.saveObject(tUserGroup)
                    .then(sTuGroup => {
                        // update sTuGroup in store
                        this.updateTournamentUserGroup(sTuGroup);
                    })
                    .catch(error => {
                        error.trace = "TUMA.delTUG";
                        notificationActions.parseError(error);
                    });
            }
        });
        return {};
    }

    deleteTournamentUserTeam(tUserTeam) {
        tUserTeam.setStatus(TournamentUserTeam.prototype.status.deleted);
        tUserTeam.unset(TournamentUserTeam.prototype.col.tournamentRoundID);
        parseDao.saveObject(tUserTeam)
            .then(sTuTeam => {
                // update sTuTeam in store
                this.updateTournamentUserTeam(sTuTeam);
            })
            .catch(error => {
                error.trace = "TUMA.delTUT";
                notificationActions.parseError(error);
            });
        return {};
    }
    moveTUsersInGroup(tUserGroup, sourcePosKey, targetPosKey) {
        const fixedTUserObject = tUserGroup.getFixedTournamentUserIDs()
        if (sourcePosKey !== targetPosKey && fixedTUserObject != null) {
            if (POS_KEY_INDEX[sourcePosKey] > POS_KEY_INDEX[targetPosKey]) {
                console.log("Move Up", sourcePosKey, targetPosKey)
                // eg move D to B
                const startPosKeyIndex = POS_KEY_INDEX[targetPosKey]
                const endPosKeyIndex = POS_KEY_INDEX[sourcePosKey]
                const endTUser = fixedTUserObject[sourcePosKey]
                // iterate through key and update posKeys
                Object.keys(fixedTUserObject).reverse().map(posKey => {
                    let posKeyIndex = POS_KEY_INDEX[posKey]
                    if (posKeyIndex > startPosKeyIndex && posKeyIndex <= endPosKeyIndex) {
                        let nextPosKey = GROUP_CHARS[posKeyIndex - 1]
                        let fUser = fixedTUserObject[nextPosKey]
                        fixedTUserObject[posKey] = fUser
                    }
                })
                fixedTUserObject[targetPosKey] = endTUser
            } else {
                console.log("Move Down", targetPosKey, sourcePosKey)
                // eg move B to D
                const startPosKeyIndex = POS_KEY_INDEX[sourcePosKey]
                const startTUser = fixedTUserObject[sourcePosKey]
                const endPosKeyIndex = POS_KEY_INDEX[targetPosKey]
                // iterate through key and update posKeys
                Object.keys(fixedTUserObject).map(posKey => {
                    let posKeyIndex = POS_KEY_INDEX[posKey]
                    if (posKeyIndex > startPosKeyIndex && posKeyIndex <= endPosKeyIndex) {
                        let fUser = fixedTUserObject[posKey];
                        let newPosKey = GROUP_CHARS[posKeyIndex - 1]
                        fixedTUserObject[newPosKey] = fUser
                    }
                })
                fixedTUserObject[targetPosKey] = startTUser
            }
            // update and save
            tUserGroup.setFixedTournamentUserIDs(fixedTUserObject)
            // save changed group
            parseDao.saveObject(tUserGroup)
                .then(sTuGroup => {
                    // update sTuGroup in store
                    this.updateTournamentUserGroup(sTuGroup);
                })
                .catch(error => {
                    error.trace = "TUMA.remTUser";
                    notificationActions.parseError(error);
                });
        }
        return {}
    }

    removePlaceholderFromTUserGroup(tUserGroup, posKey) {
        const startPosKeyIndex = POS_KEY_INDEX[posKey]
        const fixedTUserObject = tUserGroup.getFixedTournamentUserIDs()
        if (fixedTUserObject != null) {
            let lastPosKey = null
            // iterate through key and update posKeys
            Object.keys(fixedTUserObject).map(posKey => {
                lastPosKey = posKey
                let posKeyIndex = POS_KEY_INDEX[posKey]
                if (posKeyIndex > startPosKeyIndex) {
                    let fUser = fixedTUserObject[posKey];
                    let newPosKey = GROUP_CHARS[posKeyIndex - 1]
                    fixedTUserObject[newPosKey] = fUser
                }
            })
            // remove last entry
            if (lastPosKey != null) {
                delete fixedTUserObject[lastPosKey]
            }
            tUserGroup.setFixedTournamentUserIDs(fixedTUserObject)
            // save changed group
            parseDao.saveObject(tUserGroup)
                .then(sTuGroup => {
                    // update sTuGroup in store
                    this.updateTournamentUserGroup(sTuGroup);
                })
                .catch(error => {
                    error.trace = "TUMA.remTUser";
                    notificationActions.parseError(error);
                });
        }
        return {}
    }

    removeTUserFromTUserGroup(tUserGroup, tUser) {
        // unset user reference to group
        this.saveAllTUserGroupTUsers([tUser], null, null, success => {
            if (success) {
                const existGroupUsers = tUserGroup.getTournamentUserIDs();
                const tGroupUsers = existGroupUsers.filter(fUser => {
                    return fUser.id != tUser.id;
                });
                tUserGroup.setTournamentUserIDs(tGroupUsers);
                const fixedTUserObject = tUserGroup.getFixedTournamentUserIDs()
                if (fixedTUserObject != null) {
                    // find tUser in Object and unset him
                    Object.keys(fixedTUserObject).map(posKey => {
                        let fUser = fixedTUserObject[posKey];
                        if (fUser != null && fUser.id === tUser.id) {
                            fixedTUserObject[posKey] = null
                        }
                    })
                    tUserGroup.setFixedTournamentUserIDs(fixedTUserObject)
                }
                parseDao.saveObject(tUserGroup)
                    .then(sTuGroup => {
                        // update sTuGroup in store
                        this.updateTournamentUserGroup(sTuGroup);
                    })
                    .catch(error => {
                        error.trace = "TUMA.remTUser";
                        notificationActions.parseError(error);
                    });
            }
        });
        return {};
    }

    removeTUserFromTUserTeam(tUserTeam, tUser) {
        const existGroupUsers = tUserTeam.getTournamentUserIDs();
        const tGroupUsers = existGroupUsers.filter(fUser => {
            return fUser.id != tUser.id;
        });
        tUserTeam.setTournamentUserIDs(tGroupUsers);
        parseDao.saveObject(tUserTeam)
            .then(sTUserTeam => {
                // update sTUserTeam in store
                this.updateTournamentUserTeam(sTUserTeam);
            })
            .catch(error => {
                error.trace = "TUMA.remTUserTeam";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentUserGroupStatus(tUserGroup, status) {
        // set user reference to group
        this.saveAllTUserGroupTUsers(tUserGroup.getTournamentUserIDs(), tUserGroup, null, success => {
            if (success) {
                tUserGroup.setStatus(status);
                parseDao.saveObject(tUserGroup)
                    .then(sTuGroup => {
                        // update sTuGroup in store
                        this.updateTournamentUserGroup(sTuGroup);
                    })
                    .catch(error => {
                        error.trace = "TUMA.delTUG";
                        notificationActions.parseError(error);
                    });
            }
        });
        return {};
    }

    updateTournamentUserTeamStatus(tUserTeam, tRound, status, needUpdateTUserStatus) {
        tUserTeam.setStatus(status);
        if (tRound != null) {
            tUserTeam.setTournamentRoundID(tRound)
        }
        parseDao.saveObject(tUserTeam)
            .then(sTUserTeam => {
                // update sTUserTeam in store
                this.updateTournamentUserTeam(sTUserTeam);
                // update tournamentUser Status if necessary
                if (needUpdateTUserStatus) {
                    const tUsers = tUserTeam.getTournamentUserIDs();
                    if (tUsers != null && tUsers.length > 0) {
                        for (let i = 0; i < tUsers.length; i++) {
                            tUsers[i].setStatus(status);
                        }
                        parseDao.saveAll(tUsers)
                            .then(sTUsers => {
                                // update users
                                tUserTeam.setTournamentUserIDs(tUsers);
                                this.updateTournamentUserTeam(tUserTeam);
                                for (let i = 0; i < sTUsers.length; i++) {
                                    tableActions.updateObject(sTUsers[i]);
                                }
                            })
                            .catch(error => {
                                error.trace = "TUMA.createTUTupdTU";
                                notificationActions.parseError(error);
                            });
                    }
                }
            })
            .catch(error => {
                error.trace = "TUMA.delTUG";
                notificationActions.parseError(error);
            });
        return {};
    }

    setTournamentRoundToGroups(tUserGroup, tRound) {
        tUserGroup.setTournamentRoundID(tRound);
        parseDao.saveObject(tUserGroup)
            .then(sTuGroup => {
                // update sTuGroup in store
                this.updateTournamentUserGroup(sTuGroup);
            })
            .catch(error => {
                error.trace = "TUMA.tRoundToGroup";
                notificationActions.parseError(error);
            });
        return {}
    }

    async validateTournamentGroupUsersWithTRound(tUserGroup, startTRound) {
        if (startTRound.isSimple()) {
            // check if no tUser has result on the round
            const tUsers = tUserGroup.getTournamentUserIDs()
            for (let i = 0; i < tUsers.length; i++) {
                let calcTUsersRounds = tUsers[i].getCalcTournamentRoundUserIDs();
                if (calcTUsersRounds != null) {
                    // fetch tUsersRounds and compare with startTRound
                    calcTUsersRounds = await parseDao.fetchAll(calcTUsersRounds)
                    for (let j = 0; j < calcTUsersRounds.length; j++) {
                        if (calcTUsersRounds[j].isStatusFinished() && calcTUsersRounds[j].getTournamentRoundID() != null
                            && calcTUsersRounds[j].getTournamentRoundID().id === startTRound.id) {
                            // has active result on this round - but can only have one!
                            notificationActions.warning(tUsers[i].getFullName() + " has already result for round " + startTRound.getName())
                            return false
                        }
                    }
                }
            }
        }
        return true
    }

    startTournamentGroups(tournament, tUserGroups, singleStartedRound) {
        if (singleStartedRound != null && tUserGroups != null && tUserGroups.length > 0) {
            tUserGroups.map(tUserGroup => {
                tUserGroup.setStatus(TournamentUserGroup.prototype.status.started);
                tUserGroup.resetActualGroupResult()
                tUserGroup.setStartStmp(new Date());
                tUserGroup.setTournamentRoundID(singleStartedRound);
                const existGroupUsers = tUserGroup.getTournamentUserIDs();
                if (existGroupUsers != null && existGroupUsers.length > 0) {
                    // remove deleted tournamentUsers
                    const tUserList = existGroupUsers.filter(tUser => {
                        return !tUser.isDeleted();
                    });
                    tUserGroup.setTournamentUserIDs(tUserList);
                }
            });
            parseDao.saveAll(tUserGroups)
                .then(sTUserGroups => {
                    if (tournament != null) {
                        // reload all groups
                        this.loadTournamentUserGroups(tournament, true)
                    } else if (sTUserGroups.length === 1){
                        // only one group was started
                        this.updateTournamentUserGroup(sTUserGroups[0]);
                    }
                    // update status of users of each group
                    tUserGroups.map(tUserGroup => {
                        this.saveAllTUserGroupTUsers(tUserGroup.getTournamentUserIDs(), tUserGroup, TournamentUserGroup.prototype.status.started, null);
                    });
                })
                .catch(error => {
                    error.trace = "TUMA.startTUG";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    updateFixedTournamentUserIDObject(tUserGroups) {
        if (tUserGroups != null && tUserGroups.length > 0) {
            tUserGroups.map(tUserGroup => {
                const existGroupUsers = tUserGroup.getTournamentUserIDs();
                tUserGroup.setFixedTournamentUserIDs(this.buildFixedTournamentUserIDObject(existGroupUsers));
            })
            // save groups and update store
            this.saveAndUpdateUserGroups(tUserGroups, null);
        }
        return {}
    }

    buildFixedTournamentUserIDObject(existGroupUsers) {
        if (existGroupUsers != null) {
            // build object with ABCD as key with tUser as value
            const fixedTournamentUserIDObject = {}
            for (let i = 0; i < existGroupUsers.length; i++) {
                let key = GROUP_CHARS[i];
                fixedTournamentUserIDObject[key] = existGroupUsers[i]
            }
            return fixedTournamentUserIDObject
        }
        return null
    }

    unsetFixedTournamentUserIDObject(tUserGroups) {
        if (tUserGroups != null && tUserGroups.length > 0) {
            tUserGroups.map(tUserGroup => {
                tUserGroup.unset(TournamentUserGroup.prototype.col.fixedTournamentUserIDs);
            })
            // save groups and update store
            this.saveAndUpdateUserGroups(tUserGroups, null);
        }
        return {}
    }

    saveAndUpdateUserGroups(tUserGroups) {
        parseDao.saveAll(tUserGroups)
            .then(sTGroupUsers => {
                // update users
                this.updateTournamentUserGroups(sTGroupUsers)
            })
            .catch(error => {
                error.trace = "TUMA.fixedTUGupdTU";
                notificationActions.parseError(error);
            });
        return {}
    }

    pauseTournamentGroupsForRound(tUserGroups) {
        // similar to tournamentManagerActions.closeTournamentGroupsForRound
        // reset all groups to Status A
        if (tUserGroups.length > 0) {
            tUserGroups.map(tuGroup => {
                tuGroup.set("status", "A");
                const tuList = tuGroup.getTournamentUserIDs();
                tuList.map(tUser => {
                    tUser.set("status", "W");
                });
            });
            parseDao.saveAll(tUserGroups)
                .then(savedTuGroups => {
                    savedTuGroups.forEach(tuGroup => {
                        this.updateTournamentUserGroup(tuGroup)
                    })
                })
                .catch(error => {
                    error.trace = "TUMA.saveTGrps";
                    notificationActions.parseError(error);
                });
        } else {
            console.log("No Groups to save")
        }
        return {};
    }

    loadTournamentRoundResult(tournamentRound, callback) {
        tournamentUserDao.loadTournamentRoundResult(tournamentRound)
            .then(result => {
                callback(result)
            })
            .catch(error => {
                error.trace = "TUMA.loadTUGRes";
                notificationActions.parseError(error);
                callback([])
            });
        return {};
    }


    validateTournamentUserGroup(tUserGroup, startedRound, callback) {
        tournamentManagerDao.validateTournamentUsers(tUserGroup, startedRound)
            .then(result => {
                callback(result)
            })
            .catch(error => {
                error.trace = "TUMA.validateTUG";
                notificationActions.parseError(error);
                callback(null)
            });
        return {};
    }

    saveTournamentUserRound(actTRoundUser, callback) {
        parseDao.saveObject(actTRoundUser)
            .then(sTRoundUser => {
                if (callback) {
                    callback(true)
                }
            })
            .catch(error => {
                error.trace = "TUMA.saveScore";
                notificationActions.parseError(error);
                if (callback) {
                    callback(false)
                }
            });
        return {};
    }

    saveAllTUserGroupTUsers(tGroupUsers, tGroup, status, callback) {
        // update tUser with group or unset group
        if (tGroupUsers.length > 0) {
            for (let i = 0; i < tGroupUsers.length; i++) {
                if (tGroup != null) {
                    tGroupUsers[i].setTournamentGroupID(tGroup);
                } else {
                    tGroupUsers[i].unset(TournamentUser.prototype.col.tournamentGroupID);
                }
                if (status != null) {
                    tGroupUsers[i].setStatus(status);
                }
            }
            parseDao.saveAll(tGroupUsers)
                .then(sTGroupUsers => {
                    // update users
                    for (let i = 0; i < sTGroupUsers.length; i++) {
                        tableActions.updateObject(sTGroupUsers[i]);
                    }
                    if (callback) {
                        callback(true)
                    }
                })
                .catch(error => {
                    error.trace = "TUMA.createTUGupdTU";
                    notificationActions.parseError(error);
                    if (callback) {
                        callback(false)
                    }
                });
        } else {
            callback(true)
        }
        return {};
    }

    saveTournamentUserGroup(tUserGroup) {
        parseDao.saveObject(tUserGroup)
            .then(sTUserGroup => {
                this.updateTournamentUserGroup(sTUserGroup);
            })
            .catch(error => {
                error.trace = "TUMA.saveTUG";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveTournamentUserTeam(tUserTeam) {
        parseDao.saveObject(tUserTeam)
            .then(sTUserTeam => {
                this.updateTournamentUserTeam(sTUserTeam);
            })
            .catch(error => {
                error.trace = "TUMA.saveTUT";
                notificationActions.parseError(error);
            });
        return {};
    }

    reloadTournamentUser(tUser) {
        tournamentUserDao.reloadTournamentUser(tUser)
            .then(sTUser => {
                tableActions.updateObject(sTUser);
            })
            .catch(error => {
                error.trace = "TUMA.loadTUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    handleTournamentUserRegistration(tournament, editTUser, regTUserObject, callback) {
        if (editTUser != null) {
            editTUser.setName(regTUserObject.name);
            editTUser.setSurname(regTUserObject.surname);
            editTUser.setSex(regTUserObject.sex);
            editTUser.setPlayerEmail(regTUserObject.tuMail);
            if (!editTUser.isPaid()) {
                // only change price if not paid
                editTUser.setPayAmount(regTUserObject.price);
            }
            editTUser.setLicenseNumber(regTUserObject.licNumber);
            if (regTUserObject.licUnionCode != null) {
                // set also licUnionCode
                editTUser.setLicenseUnionCode(regTUserObject.licUnionCode)
            }
            editTUser.setUnion(regTUserObject.union);
            editTUser.setOption(regTUserObject.option);
            if (regTUserObject.regSlotId) {
                const slot = new TournamentRegSlot();
                slot.id = regTUserObject.regSlotId
                editTUser.setTournamentRegSlotID(slot)
            } else {
                editTUser.setTournamentRegSlotID(null)
            }
            // age bow config already set in form
            parseDao.saveObject(editTUser)
                .then(sEditTUser => {
                    tableActions.updateObject(sEditTUser);
                    if (regTUserObject.regSlotId && regTUserObject.regSlotId !== regTUserObject.startSlotId) {
                       //decrement -> regTUserObject.startSlotId
                        tournamentManagerDao.recountTournamentRegSlot(regTUserObject.startSlotId)
                        // increment -> regTUserObject.regSlotId
                        tournamentManagerDao.recountTournamentRegSlot(regTUserObject.regSlotId)
                    }
                    if (callback) {
                        callback(null)
                    }
                })
                .catch(error => {
                    error.trace = "TRMA.saveTURegistrate";
                    if (callback) {
                        callback(error)
                    } else {
                        notificationActions.parseError(error);
                    }
                });
        } else {
            tournamentUserDao.registerTournamentUser(regTUserObject)
                .then(tournamentUser => {
                    console.log("created " + tournamentUser.id + " name:" + tournamentUser.get("name"));
                    // TODO send info ??
                    //tournamentUserActions.sentRegistrationConfirmEmail(tournament, tournamentUser, regTUserObject.selfRegistration);
                    // show in registration list
                    tableActions.addObject(tournamentUser);
                    if (callback) {
                        callback(null)
                    }
                })
                .catch(error => {
                    error.trace = "TRMA.createTURegistrate";
                    if (callback) {
                        callback(error)
                    } else {
                        notificationActions.parseError(error);
                    }
                });
        }
        return {};
    }

    getNextBowUnionNumber(BUCode, callback) {
        tournamentManagerDao.getNextBowUnionNumber(BUCode)
            .then(buNumber => {
                callback(buNumber.toString());
            })
            .catch(error => {
                error.trace = "TRMA.loadBUNumber";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryMMTournamentUser(searchParam, buCode, tuUsersCallback) {
        tournamentUserDao.queryMMTournamentUser(searchParam, buCode)
            .then(tournamentUsers => {
                let searchRes = [];
                let foundMails = [];
                tournamentUsers.map(tUser => {
                    let email = tUser.getPlayerEmail().trim().toLocaleLowerCase();
                    if (email != null && email.length > 0) {
                        if (foundMails.indexOf(email) == -1) {
                            // new user
                            foundMails.push(email);
                            searchRes.push(tUser);
                        }
                    }
                });
                tuUsersCallback(searchRes);
            })
            .catch(error => {
                error.trace = "TRMA.searchMMTUsers";
                notificationActions.parseError(error);
            });
        return {};
    }


    fetchTRoundCalcUsers(tRoundCalcUsers, callback) {
        parseDao.fetchAll(tRoundCalcUsers)
            .then(fTRoundCalcUsers => {
                callback(fTRoundCalcUsers)
            })
            .catch(error => {
                error.trace = "TRMA.fetchTRoundCalcUsers";
                notificationActions.parseError(error);
            });
        return {};
    }

    exportTUser(tournamentUsers) {
        let csvData = [];
        let header = [];
        header.push("objectId");
        header.push(messages.get("tournament.table.column.status"));
        header.push(messages.get("tournament.register.name"));
        header.push(messages.get("tournament.register.surname"));
        header.push(messages.get("tournament.register.email"));
        header.push(messages.get("address.sex"));
        header.push(messages.get("tournament.table.column.union"));
        header.push(messages.get("tournament.table.column.licenseNumber"));
        header.push("ID" + messages.get("tournament.class.bow"));
        header.push(messages.get("tournament.class.bow"));
        header.push("ID" + messages.get("tournament.class.age"));
        header.push(messages.get("tournament.class.age"));
        header.push("Option");
        header.push("ID" + messages.get("tournament.table.column.group"));
        header.push(messages.get("tournament.table.column.group") + messages.get("tournament.register.name"));
        header.push(messages.get("Frag_Prep_startposition"));
        header.push("ABCD");
        header.push("extRefID");
        header.push("Anmeldedatum");
        header.push(messages.get("club.detail.comment"));
        header.push("Position");
        header.push(messages.get("tournament.table.column.points"));
        header.push("killValue");
        header.push("killCounts");
        header.push("killStrings");
        header.push(messages.get("tournament.table.column.points") + "Finale");
        header.push("killValueFinale");
        header.push("killCountsFinale");
        header.push("killStringsFinale");
        header.push(messages.get("tournament.register.label.payReference"));
        header.push(messages.get("tournament.table.column.inout"));
        header.push(messages.get("tournament.attendee.paid"));
        header.push("bezahltype");
        header.push("bezahlDatum");
        csvData.push(header.join(';'));

        tournamentUsers.map(tUser => {
            let row = [];
            row.push(tUser.id);
            row.push(messages.get(Utility.getTUserStatusMessageCode(tUser))); // TODO -> change to translated status
            row.push(tUser.getName());
            row.push(tUser.getSurname());
            row.push(tUser.getPlayerEmail());
            row.push(tUser.getSex() == 0 ? messages.get("tournament.register.sex.male") : messages.get("tournament.register.sex.female"));
            row.push(tUser.getUnion());
            row.push(tUser.getLicenseNumber());
            row.push(tUser.getTournamentConfigBow().id);
            row.push(tUser.getTournamentConfigBow().getName());
            row.push(tUser.getTournamentConfigAge().id);
            row.push(tUser.getTournamentConfigAge().getName());
            row.push(tUser.getOption());
            if (tUser.getTournamentGroupID()) {
                row.push(tUser.getTournamentGroupID().id);
                row.push(tUser.getTournamentGroupID().getName());
                row.push(tUser.getTournamentGroupID().getStartPosition());
                // first check if
                const fixedTUserObject = tUser.getTournamentGroupID().getFixedTournamentUserIDs()
                if (fixedTUserObject != null && Object.keys(fixedTUserObject).length > 0) {
                    // find tUser in Object and unset him
                    let groupKey = "";
                    Object.keys(fixedTUserObject).map(posKey => {
                        let fUser = fixedTUserObject[posKey];
                        if (fUser != null && fUser.id === tUser.id) {
                            // found user
                            groupKey = posKey;
                        }
                    })
                    row.push(groupKey);
                } else {
                    // old ABCD handling
                    // find position in group and add A;B;C;D;E;F
                    let tGroupTUsers = tUser.getTournamentGroupID().getTournamentUserIDs()
                    if (tGroupTUsers != null) {
                        let groupABCD = "";
                        for (let i = 0; i< tGroupTUsers.length;i++) {
                            if (tGroupTUsers[i] != null && tGroupTUsers[i].id === tUser.id) {
                                if (i < GROUP_CHARS.length) {
                                    groupABCD = GROUP_CHARS[i]
                                }
                                break;
                            }
                        }
                        row.push(groupABCD);
                    } else {
                        row.push("");
                    }
                }
            } else {
                row.push("");
                row.push("");
                row.push("");
                row.push("");
            }
            row.push(tUser.getExtRefID());
            let created = tUser.getCreatedAt();
            row.push(created.getDate() + "." + (created.getMonth() + 1) + "." + created.getFullYear() + " " + created.getHours()+":"+created.getMinutes());
            let note = tUser.getNote(); // to avoid issues in export
            if (note) {
                note = note.replace(/(?:\r\n|\r|\n)/g, "");
                note = note.replace(";", ",");
                row.push(note);
            } else {
                row.push("");
            }
            row.push(tUser.getResultPosition());
            row.push(tUser.getSumPoints());
            row.push(tUser.getKillValue());
            row.push(tUser.getKillCounts());
            row.push("\'" + tUser.getKillCounts2String());
            row.push(tUser.getFinalSumPoints());
            row.push(tUser.getFinalKillValue());
            row.push(tUser.getFinalKillCounts());
            row.push("\'" + tUser.getFinalAddKillCount2String());
            row.push(tUser.getPayRefID());
            row.push(tUser.getPayAmount());
            if (tUser.isPaid()) {
                row.push(messages.get("tournament.attendee.paid"));
            } else {
                row.push(messages.get("tournament.attendee.notpaid"));
            }
            row.push(tUser.getPayType());
            var payDate = tUser.getPayDate();
            if (payDate) {
                row.push(payDate.getDate() + "." + (payDate.getMonth() + 1) + "." + payDate.getFullYear() + " " + payDate.getHours()+":"+payDate.getMinutes());
            } else {
                row.push("");
            }
            csvData.push(row.join(';'));
        });
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "Teilnehmer_export.csv" );
        return {};
    }

    async generateGroups(tournament, params, callback) {
        let cupTUsers = null
        if (params.cupMaster != null) {
            cupTUsers = await tournamentUserDao.loadTournamentUsersGrouping(params.cupMaster, params)
        }
        tournamentUserDao.loadTournamentUsersGrouping(tournament, params)
            .then(tUsers => {
                const groupDic = new TournamentUserGroupingDic(params);
                if (cupTUsers != null) {
                    cupTUsers.forEach(tUser => {
                        groupDic.addRankingUser(tUser)
                    })
                }
                tUsers.forEach(tUser => {
                    groupDic.addTournamentUser(tUser)
                })
                const tuGroups = groupDic.suggestGroups()
                callback(tuGroups)
            })
            .catch(error => {
                error.trace = "TUMA.crUserGroup";
                notificationActions.parseError(error);
            });
        return {};
    }

    recountTournamentRegSlot(slotId) {
        tournamentManagerDao.recountTournamentRegSlot(slotId)
        return {}
    }

    saveTournamentUser(tournamentUser, tuCallback) {
        parseDao.saveObject(tournamentUser)
            .then(sTournamentUser => {
                if (tuCallback) {
                    tuCallback(sTournamentUser)
                }
                tableActions.updateObject(sTournamentUser);
            })
            .catch(error => {
                error.trace = "TUMA.tUserStat";
                notificationActions.parseError(error);
            });
        return {};
    }

    setTournamentUserConfig(tournamentUser, tConfigAge, tConfigBow, tuCallback) {
        if (tConfigBow) {
            tournamentUser.setTournamentConfigBow(tConfigBow)
        }
        if (tConfigAge) {
            tournamentUser.setTournamentConfigAge(tConfigAge);
        }
        parseDao.saveObject(tournamentUser)
            .then(sTournamentUser => {
                if (tuCallback) {
                    tuCallback(sTournamentUser)
                }
                //this.updateTournamentUser(sTournamentUser);
            })
            .catch(error => {
                error.trace = "TUMA.tUserTConfig";
                notificationActions.parseError(error);
            });
        return {};
    }

    calcTournamentCupResult(cupMaster, childTournaments, finalResult, callback) {
        this.updateTournamentUserCupDic(null);
        let tournamentIds = [];
        for (let i = 0; i < childTournaments.length; i++) {
            tournamentIds.push(childTournaments[i].id);
        }
        let minCupRes = 4;
        let attendFinal = false;
        if (cupMaster.getTournamentCupOptionID()) {
            minCupRes = cupMaster.getTournamentCupOptionID().getMinCupResults();
            attendFinal = cupMaster.getTournamentCupOptionID().hasAttendFinal();
        } else {
            notificationActions.warning("Cup Master has no tournamentCupOptions");
        }
        const userResultDic = new TournamentUserCupResultDic(minCupRes, attendFinal, finalResult);
        userResultDic.setTournamentSecondGroup(cupMaster.getTournamentConfigAge());
        userResultDic.setChildTournamentsIDs(tournamentIds);
        userResultDic.setCupGroups(cupMaster.getTournamentCupOptionID().getCupGroups())
        tournamentUserDao.loadCupResultTournamentUsers(tournamentIds, tournamentCupUsers => {
            tournamentCupUsers.forEach(function (tUser) {
                userResultDic.addCupSortTournamentUser(tUser);
            });
            userResultDic.addLastTournamentUserToDic();
            // this
            this.updateTournamentUserCupDic(userResultDic);
            callback(true)
        });
        return {};
    }
    saveTournamentCupResult(cupMaster, tournamentUserCupResult, callback) {
        // get list of tournamentUser
        const tournamentUserList = tournamentUserCupResult.getTournamentUserSavingList(cupMaster);
        if (tournamentUserList && tournamentUserList.length > 0) {
            // delete existing ones
            this.deleteCupResult(cupMaster, result => {
                console.log("Deleted result: ", result);
                parseDao.saveAll(tournamentUserList)
                    .then(sTournamentUserList => {
                        // insert new tournamentUsers
                        callback(true);
                    })
                    .catch(error => {
                        error.trace = "TUMA.saveCupTU";
                        notificationActions.parseError(error);
                    });
            });
        }
        return {};
    }
    updateWrongTUsers(correctTUser, childTournaments) {
        tournamentUserDao.querySimilarTUser(correctTUser, childTournaments)
            .then(simTUsers => {
                simTUsers.map(mapTUser => {
                    mapTUser.setLicenseNumber(correctTUser.getLicenseNumber())
                });
                if (simTUsers.length > 0) {
                    parseDao.saveAll(simTUsers)
                        .then(sSimTUser => {
                            notificationActions.success(correctTUser.getFullName() + " updated " +  sSimTUser.length + " entries");
                        })
                } else {
                    notificationActions.success(correctTUser.getFullName() + " No auto-update-able entries found - Name, bowClass, ageClass need to be the same ");
                }

            })
            .catch(error => {
                error.trace = "TUMA.updWCupTU";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteCupResult(cupMaster, callback) {
        tournamentUserDao.deleteTournamentUsers(cupMaster)
            .then(result => {
                // insert new
                console.log("Deleted entries: ", result);
                callback(result);
            })
            .catch(error => {
                error.trace = "TUMA.delCupTU";
                notificationActions.parseError(error);
                callback(0)
            });
    return {};
    }
// https://shancarter.github.io/mr-data-converter/
// {
//     "surname": "Peterseil",
//     "name": "Daniel",
//     "sex": "0", {0 = male || 1 = femal}
//     "union": "Verein",
//     "price": "15",
//     "bowCode":"",
//     "ageCode":"",
//      nullable
//     "mail": "office@3dturnier.com", {null -> name.surname@noemail.com}
//     "paid": "1", {1 == paid}
//     "option": ""
//     "note": ""/
//     "licenseNumber":"",
//     "licenseUnionCode":""
// }
    handleImportTournamentUser(tournament, user, objectArray) {
        const length = objectArray.length;
        const tournamentUserList = [];
        const tcBow = tournament.getTournamentConfigBow();
        const tcAge = tournament.getTournamentConfigAge();
        for (let i = 0; i < length; i++) {
            let impObject =  objectArray[i];
            let valueDict = {};
            let selectedBowType = null;
            let selectedAgeType = null;
            valueDict.surname = impObject.surname;
            valueDict.name = impObject.name;
            valueDict.sex = parseInt(impObject.sex);
            if (impObject.mail && impObject.mail.length > 0) {
                valueDict.mail = impObject.mail.toLocaleLowerCase();
            } else {
                valueDict.mail = Utility.buildNoEmail(valueDict.name, valueDict.surname)
            }

            for (let ib = 0; ib < tcBow.length; ib++) {
                if (tcBow[ib].getCode() == impObject.bowCode) {
                    selectedBowType = tcBow[ib];
                    break;
                }
            }
            for (let ia = 0; ia < tcAge.length; ia++) {
                if (tcAge[ia].getCode() == impObject.ageCode) {
                    selectedAgeType = tcAge[ia];
                    break;
                }
            }
            valueDict.price = parseFloat(impObject.price);
            valueDict.union = this.nullOrValue(impObject.union);
            valueDict.option = this.nullOrValue(impObject.option);
            valueDict.licenseNumber = this.nullOrValue(impObject.licenseNumber);
            valueDict.licenseUnionCode = this.nullOrValue(impObject.licenseUnionCode);
            valueDict.note = this.nullOrValue(impObject.note);
            console.log("USER: " + valueDict.name, valueDict);
            let tUser = TournamentUser.prototype.buildTournamentUser(tournament, user, valueDict, selectedBowType, selectedAgeType);
            if (impObject.paid != null) {
                if (impObject.paid === 1 || impObject.paid === "1") {
                    // set as paid
                    tUser.setPayment(true);
                }
            }
            tournamentUserList.push(tUser);
        }
        if (tournamentUserList.length > 0) {
            notificationActions.info("Create TournamentUsers  - saving all " + tournamentUserList.length);
            parseDao.saveAll(tournamentUserList)
                .then(uTournamentUserList => {
                    notificationActions.success("Created " + uTournamentUserList.length + " TournamentUsers");
                })
                .catch(error => {
                    error.trace="TUMA.impCU";
                    notificationActions.parseError(error);
                });
        } else {
            notificationActions.warning("Created 0 TournamentUsers from " + length + " lines");
        }
        return {};
    }
    nullOrValue(value) {
        if (typeof(value) == "string" && value.length > 0) {
            return value;
        } else if (typeof(value) == "number") {
            return value.toString()
        } else {
            return null;
        }
    }


    updateTournamentUsers(tournamentUsers) {
        return tournamentUsers;
    }

    updateTournamentUserGroup(group) {
        return group;
    }

    updateTournamentUserTeam(team) {
        return team;
    }

    updateTournamentUserGroups(groups) {
        return groups;
    }

    updateTournamentGroupFilterStatus(status) {
        return status
    }

    updateTUGroupSortParams(sortKey, direction) {
        return {sortKey: sortKey, direction:direction};
    }

    updateTournamentUserTeams(teams) {
        return teams;
    }

    updateTournamentUserGroupsResults(groupsResults) {
        return groupsResults;
    }

    updateTournamentUserGroupsResult(groupsResult) {
        return groupsResult;
    }

    updateTournamentUserCupDic(userResultDic) {
        return userResultDic
    }

    updateHideDeleted(hide) {
        return hide;
    }

    resetTournamentUsers() {
        return {};
    }

    setSelectedTournament(tournament) {
        return tournament
    }
}
module.exports = alt.createActions(TournamentUserManagerActions);
