const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, Panel} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    StarRating = require("components/widgets/StarRating"),
    FontAwesome = require("components/widgets/FontAwesome");

const ParcoursLink = require("components/parcours/ParcoursLink"),
    ParcoursActionButtons = require("components/parcours/ParcoursActionButtons"),
    ParcoursCategory = require("components/parcours/ParcoursCategory");

const messages = require("i18n/messages");

const ParcoursItem = ({parcours, userLoc}) =>  {
    const parcoursOwner = parcours.getParcoursOwnerID();
    let targetAmount = "";
    if (parcours.getTargetAmounts() != null) {
        targetAmount = parcours.getTargetAmounts() + " " + messages.get("parcours.details.targets");
    }
    let parcoursName = null;
    if (parcours.hasParcoursManager()) {
        parcoursName = <b>{parcours.buildNames()}</b>
    } else {
        parcoursName = parcours.buildNames()
    }
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <h4>
                            <ParcoursLink parcours={parcours}
                                          label={parcoursName}/>
                        </h4>
                    </Col>
                    <Col xs={12} md={3}>
                        <h4>
                            <ParcoursStartRating parcours={parcours}/>
                        </h4>
                    </Col>
                    <Col xs={9}>
                        {parcours.getZipPlace()}
                    </Col>
                    <Col xs={3}>
                        <p>{targetAmount}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} sm={9}>
                        {
                            parcoursOwner != null && parcoursOwner.getCoordinates() != null ?
                                <Button bsStyle="primary"
                                        href={"https://maps.google.com/maps?hl=de&ie=UTF8&q="+ parcoursOwner.getCoordinates()._latitude + "," + parcoursOwner.getCoordinates()._longitude +"&t=&z=15"}
                                        target="_blank">
                                    <FontAwesome icon="map-marker-alt"/><I18n code="home.parcours.navBtn"/>
                                </Button> : ""
                        }
                        <br/><br/>
                    </Col>
                    <ParcoursConstraints parcours={parcours} />
                    <UserParcoursDistance parcours={parcours} userLoc={userLoc}/>
                </Row>
                <hr/>
                <ParcoursActionButtons parcours={parcours} />
            </Panel.Body>
        </Panel>)
};
ParcoursItem.propTypes = {
    parcours:PropTypes.object.isRequired,
    userLoc:PropTypes.object
};
const UserParcoursDistance = ({parcours, userLoc}) =>  {
    if (userLoc != null && parcours.getOwnerCoordinates() != null) {
        return <Col xs={3}><strong>
            <FontAwesome icon="walking"/>
            {Math.round(userLoc.kilometersTo(parcours.getOwnerCoordinates()) * 100) / 100 + " km"}
        </strong></Col>
    }
    return null;
};
UserParcoursDistance.propTypes = {
    parcours:PropTypes.object.isRequired,
    userLoc:PropTypes.object
};
const ParcoursConstraints = ({parcours}) =>  {
    if (parcours.getConstraints() && parcours.getConstraints().length > 0) {
        return <Col xs={8} sm={3}>
            <ParcoursCategory list={parcours.getConstraints()} faIcon="exclamation-circle"/>
        </Col>
    }
    return null;
};
ParcoursConstraints.propTypes = {
    parcours:PropTypes.object.isRequired
};
const ParcoursStartRating = ({parcours}) =>  {
    if (parcours.getRatingAv() != null) {
        return <StarRating rating={parcours.getRatingAv()}/>
    }
    return null;

};
ParcoursStartRating.propTypes = {
    parcours:PropTypes.object.isRequired
};
module.exports = ParcoursItem;
