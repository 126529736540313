const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, ResponsiveEmbed} = require("react-bootstrap");

const videoList = [
    {title: "3D SKill Board - Get Your Free APP NOW!", link:"https://www.youtube.com/embed/wVxu0uyK-_4?ecver=1"},
    {title: "3D SKill Board Android - How to", link:"https://www.youtube.com/embed/Fhw6mMBDe0k?ecver=1"},
    {title: "3D SKill Board Android - Scheibenwertung und Passen", link:"https://www.youtube.com/embed/8AVmG9k9q7o?ecver=1"},
];

const YTVideos = () => {
    return <React.Fragment>
        <Row className="text-center">

                {
                    videoList.map(entry => {
                        return <YTVideo title={entry.title} link={entry.link} />
                    })
                }
                <br/>

        </Row>
    </React.Fragment>
};

const YTVideo = ({title, link}) => {
    return <Col xs={12} smOffset={2} sm={8}>
        <br/>
        <h3>{title}</h3>
        <ResponsiveEmbed a16by9>
            <iframe src={link} frameBorder="0" allowFullScreen></iframe>
        </ResponsiveEmbed>
    </Col>
};
module.exports = YTVideos;
