const React = require("react"),
    PropTypes = require('prop-types');
const {Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");

const TrainingInFilterStats = ({hitsInFilterDict}) => {
    console.log("hitsInFilterDict", hitsInFilterDict)
    return <React.Fragment>
        <h3>Hit Details: {hitsInFilterDict.amount}</h3>
        {
            Object.keys(hitsInFilterDict).map(paramKey => {
                if (paramKey !== "amount") {
                    const valueDic = hitsInFilterDict[paramKey]
                    if (valueDic != null && Object.keys(valueDic).length > 0) {
                        const noTranslation = (paramKey === "dist_est" || paramKey === "dist_meter" || paramKey === "target_group")
                        return <React.Fragment key={paramKey}>
                            <h4><I18n code={"profile.training." + paramKey}/></h4>
                            {
                                Object.keys(valueDic).map((valueKey, index) => {
                                    let valueKeyI18n = noTranslation ? valueKey : "profile.training." + paramKey + "." + valueKey
                                    const lbl = <Label key={paramKey + valueKey}><strong><I18n code={valueKeyI18n}/>:</strong> {valueDic[valueKey]}</Label>
                                    if (index > 0 && index % 8 === 0) {
                                        return <React.Fragment>
                                            {lbl}<br/>
                                        </React.Fragment>
                                    }
                                    return lbl
                                })
                            }
                            <hr/>
                        </React.Fragment>
                    }
                }

            })
        }
    </React.Fragment>
}
module.exports = TrainingInFilterStats;
