var Parse = require("ParseInit");

var ParcoursTarget = Parse.Object.extend("parcoursTarget", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        index: "index",
        onlineID: "onlineID",
        group: "group",
        size: "size",
        level: "level",
        distance: "distance",
        coordinates: "coordinates",
        coordPegs: "coordPegs",
        image: "image",
        imageUrl: "imageUrl",
        targetCatID: "targetCatID",
        editor: "editor",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt"
    },
    status: {
        active: "A",
        inactive: "I",
        deleted: "D"
    },
    isActive() {
        return this.getStatus() == this.status.active
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    setParcoursTargetLog(parcoursTargetLog) {
        this.setName(parcoursTargetLog.getName());
        if (parcoursTargetLog.getGroup()) {
            this.setGroup(parcoursTargetLog.getGroup());
        } else {
            this.unset(this.col.group)
        }
        if (parcoursTargetLog.getCoordinates()) {
            this.setCoordinates(parcoursTargetLog.getCoordinates());
        } else {
            this.unset(this.col.coordinates)
        }
        if (parcoursTargetLog.getCoordinatesPegs()) {
            this.setCoordinatesPegs(parcoursTargetLog.getCoordinatesPegs());
        } else {
            this.unset(this.col.coordPegs)
        }
        if (parcoursTargetLog.getImageUrl()) {
            this.setImageUrl(parcoursTargetLog.getImageUrl());
        } else {
            this.unset(this.col.imageUrl)
        }
        if (parcoursTargetLog.getTargetCatalogID()) {
            this.setTargetCatalogID(parcoursTargetLog.getTargetCatalogID());
        } else {
            this.unset(this.col.targetCatID)
        }
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getGroup() {
        return this.get(this.col.group)
    },
    setGroup(group) {
        return this.set(this.col.group, group)
    },
    getSize() {
        return this.get(this.col.size)
    },
    setSize(size) {
        return this.set(this.col.size, size)
    },
    getDistance() {
        return this.get(this.col.distance)
    },
    setDistance(distance) {
        return this.set(this.col.distance, distance)
    },
    getLevel() {
        return this.get(this.col.level)
    },
    setLevel(level) {
        return this.set(this.col.level, level)
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getIndex() {
        return this.get(this.col.index)
    },
    setIndex(index) {
        return this.set(this.col.index, index)
    },
    getOnlineID() {
        return this.get(this.col.onlineID)
    },
    setOnlineID(onlineID) {
        return this.set(this.col.onlineID, onlineID)
    },
    getTargetCatalogID() {
        return this.get(this.col.targetCatID)
    },
    setTargetCatalogID(targetCatID) {
        return this.set(this.col.targetCatID, targetCatID)
    },
    unsetTargetCatalog() {
        this.unset(this.col.targetCatID);
        this.unset(this.col.group);
        this.unset(this.col.imageUrl)
    },
    getCoordinates() {
        return this.get(this.col.coordinates)
    },
    getReadableCoordinates() {
        const coords = this.getCoordinates();
        if (coords) {
            return coords._latitude + "," + coords._longitude;
        } else {
            return null;
        }
    },
    setCoordinateReadable(coordcsv) {
        const coordinates = coordcsv.trim().split(",");
        this.setCoordinates(new Parse.GeoPoint({latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}));
    },
    setCoordinates(coordinates) {
        return this.set(this.col.coordinates, coordinates)
    },
    getCoordinatesPegs() {
        return this.get(this.col.coordPegs)
    },
    getReadableCoordinatesPegs() {
        let coordArray = null;
        const coords = this.getCoordinatesPegs();
        if (coords) {
            coordArray = [];
            for (let i = 0; i < coords.length; i++) {
                coordArray.push(coords[i]._latitude + "," + coords[i]._longitude)
            }
        }
        return coordArray;
    },
    setCoordinateReadablePegs(coordsArrayCSV) {
        if (coordsArrayCSV && coordsArrayCSV.length > 0) {
            let coordArray =[];
            for (let i = 0; i < coordsArrayCSV.length; i++) {
                const coordinates = coordsArrayCSV[i].trim().split(",");
                coordArray.push(new Parse.GeoPoint({latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}));
            }
            this.setCoordinatesPegs(coordArray);
        }
    },
    setCoordinatesPegs(coordinatesArray) {
        return this.set(this.col.coordPegs, coordinatesArray)
    },
    getImageUrl() {
        return this.get(this.col.imageUrl)
    },
    setImageUrl(url) {
        return this.set(this.col.imageUrl, url)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ParcoursTarget;
