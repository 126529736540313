var alt = require("AltInit");

class LayoutActions {

    displayTypeChanged(displayType) {
        return displayType;
    }
}

module.exports = alt.createActions(LayoutActions);
