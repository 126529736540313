const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const linkUtil = require("linkUtil");

const ParcoursTransactionButton = ({management, bsStyle, disabled, block}) => {
        return (
            <LinkButton
                block={block}
                disabled={disabled}
                bsStyle={bsStyle}
                label="profile.managedParcours.transaction"
                href={linkUtil.getLink("mgmtparcours/" + management.id + "/transactions")}/>
        )
};
ParcoursTransactionButton.propTypes = {
    management:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string
};
module.exports = ParcoursTransactionButton;