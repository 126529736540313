const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const DecisionModal= require("components/modals/DecisionModal"),
    FontAwesome = require("components/widgets/FontAwesome");

const userActions = require("actions/UserActions");

class DecisionButton extends React.Component {
    constructor(props) {
        super(props);
        this.refDecModal = React.createRef();
        this.handleAsk = this.handleAsk.bind(this);
        this.pressedOK = this.pressedOK.bind(this);
        this.state = {}
    }
    handleAsk() {
        if (this.props.user) {
            if (this.props.objectName) {
                this.refDecModal.current.openNamedHeader(this.props.objectName);
            } else {
                this.refDecModal.current.open();
            }
        } else {
            userActions.showLoginModal();
        }
    }
    pressedOK() {
        if (this.props.handleOK) {
            this.props.handleOK()
        }
    }
    render(){
        return (
            <React.Fragment>
                <Button key="del"
                        block={this.props.block}
                        bsSize={this.props.bsSize}
                        bsStyle={this.props.bsStyle}
                        onClick={this.handleAsk}>
                    {
                        this.props.buttonIcon ? <FontAwesome icon={this.props.buttonIcon}/>
                            : null
                    }
                    {this.props.buttonText}
                </Button>
                <DecisionModal ref={this.refDecModal}
                               titleCode={this.props.titleCode}
                               messageCode={this.props.messageCode}
                               okButtonCode={this.props.okButtonCode}
                               cancelButtonCode={this.props.cancelButtonCode}
                               handleOK={this.pressedOK}/>
            </React.Fragment>);
    }
}
DecisionButton.propTypes = {
    user:PropTypes.object.isRequired,
    buttonText:PropTypes.string,
    buttonIcon:PropTypes.string,
    titleCode:PropTypes.string,
    messageCode:PropTypes.string,
    objectName:PropTypes.string,
    okButtonCode:PropTypes.string,
    cancelButtonCode:PropTypes.string,
    handleOK:PropTypes.func
};
module.exports = DecisionButton;
