var Parse = require("ParseInit");

var TargetBrand = Parse.Object.extend("targetBrand", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        addressID: "addressID",
        weblink: "weblink",
        affilLink: "affilLink",
        imgBaseDir: "imgBaseDir",
        imgBaseType: "imgBaseType",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt"
    },
    buildImgLink: function (imageName) {
        return this.getImageBaseDirectory() + "/" + imageName + "." + this.getImageBaseType();
    },
    getSelectString: function () {
        return this.getName();
    },
    getACL: function() {
        return this.get(this.col.ACL)
    },
    setACL: function(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt: function() {
        return this.get(this.col.createdAt)
    },
    getName: function() {
        return this.get(this.col.name)
    },
    setName: function(name) {
        return this.set(this.col.name, name)
    },
    getObjectId: function() {
        return this.get(this.col.objectId)
    },
    setObjectId: function(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getAddressID: function () {
        return this.get(this.col.addressID)
    },
    setAddressID: function (addressID) {
        return this.set(this.col.addressID, addressID)
    },
    getWeblink: function() {
        return this.get(this.col.weblink)
    },
    setWeblink: function(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getAffilLink: function() {
        return this.get(this.col.affilLink)
    },
    setAffilLink: function(link) {
        return this.set(this.col.affilLink, link)
    },
    getImageBaseDirectory: function () {
        return this.get(this.col.imgBaseDir)
    },
    getImageBaseType: function () {
        return this.get(this.col.imgBaseType)
    },
    getStatus: function() {
        return this.get(this.col.status)
    },
    setStatus: function(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt: function() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TargetBrand;
