const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Panel, Alert, Button} = require("react-bootstrap");

const ExistingBusinessAccountInfo = require("components/business/ExistingBusinessAccountInfo").ExistingBusinessAccountInfo;

const I18n = require("components/widgets/I18n"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages");

const accountStore = require("stores/AccountStore"),
    accountActions = require("actions/AccountActions"),
    tournamentActions = require("actions/TournamentManagerActions");

class TournamentMMAddBusinessAccount extends React.Component {
    constructor(props) {
        super(props);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleLink2Business = this.handleLink2Business.bind(this);

        this.state = {
            accountStore: accountStore.getState(),
        }
    }
    componentDidMount() {
        accountStore.listen(this.handleAccountChange);
        if (!this.props.tournament.hasManagementID()) {
            accountActions.queryInvolvedManagement.defer(this.props.allBAccounts ? null : this.props.user);
        }
    }
    componentWillUnmount() {
        accountStore.unlisten(this.handleAccountChange);
    }
    handleLink2Business(management) {
        tournamentActions.link2BusinessAccount(this.props.tournament, management, this.props.user);
    }
    handleAccountChange(state) {
        this.setState({accountStore: state});
    }
    render() {
        const parcours = this.props.tournament.getParcoursID();
        return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col lg={12}>
                            <h3><I18n code="profile.account.business.header"/></h3>
                            <p>{messages.get("tournament.manager.newAccount.desc1") + " "}</p>
                            <Alert bsStyle="info"><I18n code="tournament.manager.link2BusinessAccount.desc"/></Alert>
                        </Col>
                    </Row>
                    {
                        this.state.accountStore.managements.length <= 0 && parcours == null ?
                            <p><strong>{messages.get("tournament.manager.newAccount.desc2")}</strong></p> : null
                    }
                    {
                        this.state.accountStore.managements.length <= 0 && parcours != null ?
                            <Button bsStyle="success" href={linkUtil.getLink("parcours/" +  parcours.getParcoursOwnerID().id + "/manage")}>
                            {messages.get("tournament.manager.newAccount.button")}</Button> : null
                    }
                    {this.state.accountStore.managements.length > 0 ?
                    <ExistingBusinessAccountInfo manageCallback={this.handleLink2Business} managements={this.state.accountStore.managements}/> : null}
                </Panel.Body>
            </Panel>)
    }
}
TournamentMMAddBusinessAccount.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    allBAccounts: PropTypes.bool
};
module.exports = TournamentMMAddBusinessAccount;
