const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const tournamentActions = require("actions/TournamentManagerActions");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentConfigExtraModal = ({tournament, tOption, tConfig}) =>  {
    if (tOption) {
        // config modal for option surcharges
        return (
            <ModalBasic
                title={<TextCenter><I18n code="tournament.manager.tconfig.option.surcharge"/>{": " + tOption.getOptionLabel()}</TextCenter>}
                buttonStyle={"default"}
                buttonChildren={<FontAwesome icon="euro-sign">Opt</FontAwesome>}>
                <TournamentConfigExtraForm tournament={tournament} options={tOption.getOptions()} tConfig={tConfig}/>
            </ModalBasic>
        )
    } else {
        // config modal for single cup tournament surcharge
        return (
            <ModalBasic
                title={<TextCenter>{tConfig.getName() + " - "}<I18n code="tournament.manager.tconfig.cup.surcharge"/></TextCenter>}
                buttonStyle={"default"}
                buttonChildren={<FontAwesome icon="euro-sign">Cup</FontAwesome>}>
                <TournamentConfigExtraForm tournament={tournament} options={[tournament.id]} tConfig={tConfig}/>
            </ModalBasic>
        )
    }

};
TournamentConfigExtraModal.propTypes = {
    tournament:PropTypes.object.isRequired,
    tOption: PropTypes.object.isRequired,
    tConfig: PropTypes.object.isRequired
};
class TournamentConfigExtraForm extends React.Component {
    constructor(props) {
        super(props);
        this.refDic = {};
        this.props.options.map(option => {
            this.refDic[option] = React.createRef();
        });
        this.clickedSave = this.clickedSave.bind(this);
        this.getOptionValue = this.getOptionValue.bind(this);
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        disableSubmitFct(true);
        //  build object
        const isBowEntry = false;
        let tOptionSurchargeDic = this.props.tConfig.getTOptionSurcharge();
        if (tOptionSurchargeDic == null) {
            tOptionSurchargeDic = {};
        }
        this.props.options.map(option => {
            let field = this.refDic[option];
            if (field != null && field.current != null) {
                let value = field.current.getValue();
                if (option !== this.props.tournament.id) {
                    // save tournamentId to option for distinguish different options in cups
                    tOptionSurchargeDic[this.props.tournament.id + "_" + option] = parseFloat(value);
                } else {
                    tOptionSurchargeDic[option] = parseFloat(value);
                }
            }
        });
        console.log("Result", tOptionSurchargeDic);
        this.props.tConfig.setTOptionSurcharge(tOptionSurchargeDic);
        // save
        tournamentActions.saveTournamenConfig(this.props.tournament, isBowEntry, this.props.tConfig, () => {
            closeFct();
        });
    }
    getOptionValue(option) {
        let tOptionSurchargeDic = this.props.tConfig.getTOptionSurcharge();
        if (tOptionSurchargeDic) {
            if (option !== this.props.tournament.id) {
                return tOptionSurchargeDic[this.props.tournament.id + "_" +option];
            }
            return tOptionSurchargeDic[option];
        }
        return null;
    }
    render() {
        const {error, submitDisabled} = this.context;
        const validateNow = error && error.length > 0;
        const {tournament, options} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <Table style={{margin: "0"}}>
                            <tbody>
                            {
                                options.map(option => {
                                    return (<tr key={option}>
                                                <th>{option !== tournament.id ? option : tournament.getName()}</th>
                                                <td><ValidInput ref={this.refDic[option] }
                                                                default={this.getOptionValue(option)}
                                                                valid={{maxLength: 5, check:['isRequired', 'isNumber', 'point4comma']}}
                                                                validateNow={validateNow}/>
                                                </td>
                                            </tr>)
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentConfigExtraForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    options: PropTypes.array.isRequried,
    tConfig: PropTypes.object.isRequired
};
TournamentConfigExtraForm.contextType = ModalBasicContext;
module.exports = TournamentConfigExtraModal;
