const React = require("react"),
    PropTypes = require('prop-types');

const AddressInfo = ({address}) => {
    if (address.getCompany()) {
        return <p><strong>{address.getCompany()}</strong><br/>
            {address.getFullName()}<br/>
            {address.getStreet()}<br/>
            {address.getZipPlace()}</p>
    } else {
        return <p>{address.getFullName()}<br/>
            {address.getStreet()}<br/>
            {address.getZipPlace()}</p>
    }
};
AddressInfo.propTypes = {
    address: PropTypes.object.isRequired
};
module.exports = AddressInfo;
