const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require ('react-router-dom');
const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    linkUtil = require("linkUtil"),
    ExtLink = require("components/links/ExtLink");

const TournamentDetailBowUnion = ({bowUnion, addRules}) => {
    function getRulesLink() {
        if (bowUnion.getCode() === "WA") {
            return "tournament/rules/WA"
        } else if (bowUnion.getCode() === "IFAA") {
            return "tournament/rules/IFAA"
        } else if (bowUnion.getCode() === "AAA") {
            return "tournament/rules/AAA"
        }
        return null
    }
    if (bowUnion != null) {
        let rulesLink = getRulesLink();
        return <React.Fragment>
            {
                bowUnion.getWeblink() ?
                    <p><FontAwesome icon="book"/><ExtLink href={bowUnion.getWeblink()}>{bowUnion.getName()}</ExtLink></p>
                    :
                    <p><FontAwesome icon="book"/>{bowUnion.getName()}</p>
            }
            {
                rulesLink ?
                    <p><FontAwesome icon="book"/><Link to={linkUtil.getLink(rulesLink)}><I18n code="header.nav.tournamentRules"/></Link></p> : null
            }
            {
                addRules && bowUnion.getDescription() != null ? <I18nHtml code={bowUnion.getDescription()} /> : null
            }
        </React.Fragment>
    }
    return null
}
TournamentDetailBowUnion.propTypes = {
    bowUnion: PropTypes.object,
    addRules: PropTypes.bool
}
module.exports = TournamentDetailBowUnion;
