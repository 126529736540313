const ParseListUtility = require("util/ParseListUtility"),
    ParseListCompareUtility = require("util/ParseListCompareUtility");

function TournamentUserResultDic() {
    this.attendeeAmount = {};
    this.groupedResults = [];
    this.primeGroup = [];
    this.secondGroup = null;
    this.combineGroups = null;
    this.roundSize = [];
    // final
    this.finalGroupedResults = [];
    this.finalBowGroups = [];
    this.finalRoundSize = [];
    this.addSortTournamentUser = function (tournamentUser, needFinalAdd) {
        const bowGroup = tournamentUser.getTournamentConfigBow();
        let ageGroup = tournamentUser.getTournamentConfigAge();
        if (this.combineGroups && this.secondGroup) {
            if (ParseListUtility.contains(this.combineGroups, ageGroup)) {
                ageGroup = this.secondGroup[0]
            } else {
                // no need to check deactivated groups
                return
            }
        }
        const tuSex = ageGroup.hasNoSexSplit() ? "x" : tournamentUser.getSex();
        const key = this.generateDicKey(bowGroup, ageGroup, tuSex);
        // amounts
        this.updateGroupAmountCounter(this.generateDicKey(bowGroup, ageGroup, "_"));
        this.updateGroupAmountCounter(bowGroup.id);
        if (ageGroup) {
            this.updateGroupAmountCounter(ageGroup.id);
        }
        // check for bow
        this.checkForGroup(this.primeGroup, bowGroup);
        this.addUserToGroup(this.groupedResults, key, tournamentUser, false, false);
        this.checkGroupedRoundSize(this.roundSize, key, tournamentUser.getTournamentRoundUserIDs());
        if (tournamentUser.getFinalTournamentRoundUserIDs() != null) {
            // has also final values
            this.checkForGroup(this.finalBowGroups, bowGroup);
            this.addUserToGroup(this.finalGroupedResults, key, tournamentUser, true, needFinalAdd);
            this.checkGroupedRoundSize(this.finalRoundSize, key, tournamentUser.getFinalTournamentRoundUserIDs());
        }
    };
    this.addSortTournamentUserLippek = function (tournamentUser) {
        tournamentUser.setSumPoints(Math.round(tournamentUser.getSortableSumPoints() * 100) / 100);
        const ageGroup = tournamentUser.getTournamentConfigAge();
        const tuSex = ageGroup.hasNoSexSplit() ? "x" : tournamentUser.getSex();
        const key = this.generateDicKey(ageGroup, null , tuSex);
        // check for age
        this.checkForGroup(this.primeGroup, ageGroup);
        this.addUserToGroup(this.groupedResults, key, tournamentUser, false, false);
        this.checkGroupedRoundSize(this.roundSize, key, tournamentUser.getTournamentRoundUserIDs());
    };
    this.addLiveTournamentGroupUser = function (tournamentGroup) {
        var that = this;
        const tUsers = tournamentGroup.getTournamentUserIDs();
        tUsers.map(function(tournamentUser){
            //console.log(tournamentGroup.id + " usr " + tournamentUser.id + " pkt "+ tournamentGroup.getTournamentUserPoints(tournamentUser.id))
            tournamentUser.setSumPoints(tournamentGroup.getTournamentUserPoints(tournamentUser.id));
            tournamentUser.setKillValue(tournamentGroup.getTournamentUserKillValue(tournamentUser.id));
            tournamentUser.setKillCounts(tournamentGroup.getTournamentUserKillCounts(tournamentUser.id));

            const bowGroup = tournamentUser.getTournamentConfigBow();
            const ageGroup = tournamentUser.getTournamentConfigAge();
            const tuSex = ageGroup.hasNoSexSplit() ? "x" : tournamentUser.getSex();
            const key = that.generateDicKey(bowGroup, ageGroup, tuSex);
            // check for bow
            that.checkForGroup(that.primeGroup, bowGroup);
            that.addLiveUserToGroup(that.groupedResults, key, tournamentUser);
            that.checkGroupedRoundSize(that.roundSize, key, tournamentUser.getTournamentRoundUserIDs());
        });
    };
    this.getTournamentPrimeGroup = function() {
        return this.primeGroup
    };
    this.getTournamentSecondGroup = function() {
        return this.secondGroup
    };
    this.setTournamentSecondGroup = function(configGroups) {
        this.secondGroup = configGroups;
    };
    this.setTournamentCombineGroups = function(configGroups) {
        this.combineGroups = configGroups;
    };
    this.getFinalTournamentConfigBow = function() {
        return this.finalBowGroups
    };
    this.getAttendeeAmount = function (key) {
        return this.attendeeAmount[key];
    };
    this.generateDicKey = function(primeConfig, secConfig, tuSex) {
        let sec = "";
        if (secConfig != null) {
            sec = secConfig.id;
        }
        return primeConfig.id + "z" + tuSex + "z" + sec;
    };
    this.updateGroupAmountCounter = function(key) {
        let counter = this.attendeeAmount[key];
        if (counter == null) {
            counter = 0;
        }
        counter++;
        this.attendeeAmount[key] = counter;
    };
    this.getList = function(key) {
        const groupedTUList = this.groupedResults[key];
        if (groupedTUList && groupedTUList.length > 0) {
            return groupedTUList;
        } else {
            return null;
        }

    };
    this.getFinalList = function(key) {
        const groupedTUList = this.finalGroupedResults[key];
        if (groupedTUList && groupedTUList.length > 0) {
            return groupedTUList;
        } else {
            return null;
        }
    };
    this.addLiveUserToGroup = function(aGroupedResults, key, tournamentUser) {
        const groupedTUList = aGroupedResults[key];
        if (groupedTUList) {
            // sort by DPS
            ParseListUtility.pushObjSortedDESC(groupedTUList, tournamentUser, ParseListCompareUtility.compareLiveTUserDPSFct);
        } else {
            var newList = [];
            newList.push(tournamentUser);
            aGroupedResults[key] = newList;
        }
    };
    this.addUserToGroup = function(aGroupedResults, key, tournamentUser, needFinalSort, needFinalAdd) {
        const groupedTUList = aGroupedResults[key];
        if (groupedTUList) {
            this.pushUserSorted(groupedTUList, tournamentUser, needFinalSort, needFinalAdd);
        } else {
            const newList = [];
            newList.push(tournamentUser);
            aGroupedResults[key] = newList;
        }
    };
    this.pushUserSorted = function(groupedTUList, tournamentUser, isFinal, addFinale) {
        let added = false;
        let index = groupedTUList.length - 1;
        while (index >= 0 && !added) {
            if (tournamentUser.getUserDictSortSum(isFinal, addFinale) < groupedTUList[index].getUserDictSortSum(isFinal, addFinale)) {
                // add last
                groupedTUList.splice(index + 1, 0, tournamentUser);
                added = true;
            } else if (tournamentUser.getUserDictSortSum(isFinal, addFinale) == groupedTUList[index].getUserDictSortSum(isFinal, addFinale)) {
                if (tournamentUser.getUserDictKillValue(isFinal, addFinale) <= groupedTUList[index].getUserDictKillValue(isFinal, addFinale)) {
                    groupedTUList.splice(index + 1, 0, tournamentUser);
                    // console.log("add " + index);
                    added = true;
                }
            }
            index--;
        }
        if (!added) {
            groupedTUList.splice(0, 0, tournamentUser);
        }
    };
    this.checkForGroup = function (aGroups, group) {
        let found = false;
        for(let i = 0; i < aGroups.length; i++) {
            if (aGroups[i].id == group.id) {
                found = true;
                break;
            }
        }
        if (!found) {
            aGroups.push(group);
        }
    };
    this.checkGroupedRoundSize = function (aRoundsSize, key, tournamentRoundUserIDs) {
        let maxGroupRoundSize = 1;
        if (tournamentRoundUserIDs) {
            maxGroupRoundSize = tournamentRoundUserIDs.length;
        }
        const groupedRoundSize = aRoundsSize[key];
        if (groupedRoundSize) {
            if (maxGroupRoundSize > groupedRoundSize) {
                aRoundsSize[key] = maxGroupRoundSize;
            }
        } else {
            aRoundsSize[key] = maxGroupRoundSize;
        }
    };
    this.getGroupRoundAmount = function (key) {
        const groupedRoundSize = this.roundSize[key];
        if (groupedRoundSize) {
            return groupedRoundSize;
        }
        return 1;
    };
    this.getFinalGroupRoundAmount = function (key) {
        const groupedRoundSize = this.finalRoundSize[key];
        if (groupedRoundSize) {
            return groupedRoundSize;
        }
        return 0;
    };
    this.calcMedalAmount = function() {
        const medals = {
            gold: 0,
            silber: 0,
            bronze: 0
        };
        const updateMedalCount = function (medals, list) {
            if (list) {
                const length = list.length;
                if (length >= 1) {
                    medals.gold++
                }
                if (length >= 2) {
                    medals.silber++
                }
                if (length >= 3) {
                    medals.bronze++
                }
            }
        };
        const primeTypes = this.getTournamentPrimeGroup();
        const secTypes = this.getTournamentSecondGroup();
        primeTypes.map(primeType => {
            secTypes.map(secType => {
                if (secType.hasNoSexSplit()) {
                    // male and female in one table
                    const key = this.generateDicKey(primeType, secType, "x");
                    const groupedList = this.getList(key);
                    updateMedalCount(medals, groupedList);
                } else {
                    // male and female extra
                    const maleKey = this.generateDicKey(primeType, secType, "0");
                    const mGroupedList = this.getList(maleKey);
                    updateMedalCount(medals, mGroupedList);
                    const femaleKey = this.generateDicKey(primeType, secType, "1");
                    const fGroupedList = this.getList(femaleKey);
                    updateMedalCount(medals, fGroupedList);
                }
            });
        });
        return medals
    }
}

module.exports = TournamentUserResultDic;