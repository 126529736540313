/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, ListGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    EntryRemovable = require("components/form/EntryRemovable"),
    TextCenter = require("components/widgets/TextCenter"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput"),
    Utility = require("util/Utility");

const accountActions = require("actions/AccountActions");
const notificationActions = require("components/notification/NotificationActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const AdditionalEditorModal = ({object, objectType, titleCode, buttonChildren, allowEditMainEditor}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code={titleCode} /></TextCenter>}
            buttonStyle={"success"}
            buttonChildren={buttonChildren ? buttonChildren : <FontAwesome icon="users" >{messages.get("profile.account.business.editors")}</FontAwesome>}>
            <AdditionalEditorForm object={object} objectType={objectType} allowEditMainEditor={allowEditMainEditor}/>
        </ModalBasic>
    )
};
AdditionalEditorModal.propTypes = {
    object:PropTypes.object.isRequired,
    objectType: PropTypes.string.isRequired,
    titleCode: PropTypes.string.isRequired,
    buttonChildren: PropTypes.any,
    allowEditMainEditor: PropTypes.bool
};
class AdditionalEditorForm extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.refMainEditorMail = React.createRef();
        this.clickedMainEditor = this.clickedMainEditor.bind(this);
        this.addedEditor = this.addedEditor.bind(this);
        this.removedEditor = this.removedEditor.bind(this);
        this.clickedRemove = this.clickedRemove.bind(this);
        this.clickedAdd = this.clickedAdd.bind(this);
        this.state = {
            editorVersion: 0
        }
    }
    clickedMainEditor(e) {
        e.preventDefault();
        const {setErrorFct} = this.context;
        const {object, objectType} = this.props;
        const mail = this.refMainEditorMail.current.getValue().trim();
        accountActions.addToAddEditor("MAIN"+objectType, object.id, mail, mainEditor => {
            console.log("New main editor", mainEditor)
            object.setMainEditor(mainEditor);
            // upcount version to refresh GUI
            let newV = this.state.editorVersion + 1;
            this.setState({editorVersion: newV})
        }, error => {
            setErrorFct({message: Utility.parseErrorInfo(error)})
        });
    }
    clickedAdd(e) {
        e.preventDefault();
        const {setErrorFct} = this.context;
        const {object, objectType} = this.props;
        setErrorFct(null)
        const mail = this.refMail.current.getValue().trim();
        if (mail != null  && mail.length > 0 ) {
            if (object != null && objectType != null) {
                accountActions.addToAddEditor(objectType, object.id, mail, editor => {
                    this.addedEditor(editor);
                }, error => {
                    setErrorFct({message: Utility.parseErrorInfo(error)})
                });
            }
        } else {
            setErrorFct({message: messages.get("form.valid.not.email")})
        }
    }
    addedEditor(newEditor) {
        const {object} = this.props;
        let addEditors = object.getAddEditor();
        if (addEditors == null) {
            addEditors = [];
        }
        addEditors.push(newEditor);
        object.setAddEditor(addEditors);
        // upcount version to refresh GUI
        let newV = this.state.editorVersion + 1;
        this.setState({editorVersion: newV})
    }

    clickedRemove(editor) {
        const {object, objectType} = this.props;
        if (object != null && objectType != null) {
            accountActions.removeFromAddEditor(objectType, object.id, editor, editorID => {
                this.removedEditor(editorID);
            });
        }

    }
    removedEditor(removeEditorId) {
        const {object} = this.props;
        let addEditors = object.getAddEditor();
        addEditors = addEditors.filter(function (u) {
            return u.id !== removeEditorId;
        });
        object.setAddEditor(addEditors);
        // upcount version to refresh GUI
        let newV = this.state.editorVersion + 1;
        this.setState({editorVersion: newV})
    }
    render() {
        const {error, submitDisabled, closeFct} = this.context;
        const {object, allowEditMainEditor} = this.props;
        const saveButton = (<Button disabled={submitDisabled} type="submit"><FontAwesome icon="save"/></Button>);
        const mainEditor = object.getMainEditor != null ? object.getMainEditor() : null;
        const addEditors = object.getAddEditor != null ? object.getAddEditor() : null;
        return (<React.Fragment>
            {
                mainEditor ? <form action="#" onSubmit={this.clickedMainEditor}><Row>
                    <Col xs={12}>
                        <h4>{messages.get("modal.addEditor.mainEditor")}</h4>
                        <strong>{mainEditor.getUsername()}</strong><br/>
                        { allowEditMainEditor ?
                            <ValidInput ref={this.refMainEditorMail}
                                        valid={{maxLength: 50, check:['isRequired','isMail']}}
                                        placeholder={messages.get("modal.addEditor.addMail")}
                                        buttonAfter={saveButton}/> : <hr/>
                        }
                    </Col></Row></form> : null
            }
            <form action="#" onSubmit={this.clickedAdd}>
                <Row>
                    <Col sm={12}>
                        <h4><I18n code={"modal.addEditor.editorlist"}/></h4>
                        <ValidInput ref={this.refMail}
                                    valid={{maxLength: 50, check:['isRequired','isMail']}}
                                    placeholder={messages.get("modal.addEditor.addMail")}
                                    buttonAfter={saveButton}/>
                        <ListGroup>
                            {
                                addEditors != null ?
                                    addEditors.map(editor => {
                                        return <EditorEntry key={"EDT" + editor.id} editor={editor} onRemoveEditor={this.clickedRemove}/>
                                    }) : null
                            }</ListGroup>
                    </Col>
                    <Col sm={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={closeFct} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form></React.Fragment>
        );
    }
}
AdditionalEditorForm.propTypes = {
    object:PropTypes.object.isRequired,
    objectType: PropTypes.string.isRequired,
    allowEditMainEditor: PropTypes.bool
};
AdditionalEditorForm.contextType = ModalBasicContext;
const EditorEntry = ({editor, onRemoveEditor}) => {
    function remove(e) {
        onRemoveEditor(editor);
    }
    return <EntryRemovable group={editor.getUsername()} onRemove={remove}/>;
};
EditorEntry.propTypes = {
    editor:PropTypes.object.isRequired,
    onRemoveEditor:PropTypes.func.isRequired
};
module.exports = AdditionalEditorModal;