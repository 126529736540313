const React = require("react"),
    PropTypes = require('prop-types');
const I18n = require("components/widgets/I18n");

const ManagementDetail = ({management}) => {
    const businessPartner = management.getBusinessPartnerID();
    if (businessPartner == null) {
        return (
            <dl>
                <dt className="size-140"><I18n code="address.union"/>:&nbsp;</dt>
                <dd>{management.getName()}</dd>
            </dl>)
    }
    const address = businessPartner.getAddressID();
    return (
        <dl>
            <dt className="size-140"><I18n code="address.union"/>:&nbsp;</dt>
            <dd>{address.getCompany()}</dd>
            <dt className="size-140"><I18n code="address.street"/>:&nbsp;</dt>
            <dd>{address.getStreet()}</dd>
            <dt className="size-140"><I18n code="address.place"/>:&nbsp;</dt>
            <dd>{address.getZip() + " " + address.getPlace()}</dd>
            <dt className="size-140"><I18n code="address.contactName"/>:&nbsp;</dt>
            <dd>{address.getFullName()}</dd>
            <dt className="size-140"><I18n code="address.phone"/>:&nbsp;</dt>
            <dd>{address.getPhone()}</dd>
            <dt className="size-140"><I18n code="address.mail"/>:&nbsp;</dt>
            <dd>{address.getMail()}</dd>
        </dl>)
};
ManagementDetail.propTypes = {
    management: PropTypes.object.isRequired
};
module.exports = ManagementDetail;