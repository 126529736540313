const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    ValidInput = require("components/form/ValidInput");

const messages = require("i18n/messages");

const tournamentActions = require("actions/TournamentManagerActions"),
    TournamentRegSlot = require("parse/TournamentRegSlot");

class TournamentRegSlotModal extends React.Component {
    constructor(props) {
        super(props);
        this.refName = React.createRef();
        this.refMaxAmount = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getDefaultName = this.getDefaultName.bind(this);
        this.getDefaultAmount = this.getDefaultAmount.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    open() {
        this.setState({showModal: true});
    }
    valid() {
        let valid = true;
        let required = [
            this.refName.current.getValue(),
            this.refMaxAmount.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let tournamentSlot = this.props.tournamentSlot;
        if (tournamentSlot == null) {
            tournamentSlot = new TournamentRegSlot();
            tournamentSlot.setStatus(TournamentRegSlot.prototype.status.published);
            tournamentSlot.setTournamentID(this.props.tournament);
            tournamentSlot.setActualAmount(0);
        }
        tournamentSlot.setName(this.refName.current.getValue());
        tournamentSlot.setMaxAmount(parseInt(this.refMaxAmount.current.getValue()));
        tournamentActions.saveTournamentSlot(tournamentSlot);
        this.close();
    }
    getDefaultName() {
        if (this.props.tournamentSlot != null) {
            return this.props.tournamentSlot.getName();
        }
    }
    getDefaultAmount() {
       if (this.props.tournamentSlot != null) {
            return this.props.tournamentSlot.getMaxAmount();
        }
        return "0";
    }
    render() {
        const {type, submitDisabled} = this.state;
        const validateNow = this.state.error && this.state.error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.slot.modal.title"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            {
                                this.state.showModal ?
                                    <Col sm={12}>
                                        <p>{messages.get("tournament.manager.slot.modal.desc")}</p>
                                        <br/>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 20, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultName()}
                                                    label={messages.get("tournament.table.column.name")}/>
                                        <ValidInput ref={this.refMaxAmount}
                                                    valid={{maxLength: 3, check:['isRequired', 'isNumber']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultAmount()}
                                                    label={messages.get("tournament.table.column.amount")}/>
                                    </Col> : null
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={submitDisabled} bsStyle="primary">{messages.get("modal.button.save")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
TournamentRegSlotModal.propTypes = {
    tournament:PropTypes.object,
    tournamentSlot:PropTypes.object,
};
module.exports = TournamentRegSlotModal;
