const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Link} = require ('react-router-dom');

function ClubLink({club, label}) {
    return <Link to={linkUtil.getLink("club/" + club.id)}>{label}</Link>
}
ClubLink.propTypes = {
    club: PropTypes.object.isRequired,
    label: PropTypes.any
};
module.exports = ClubLink;
