const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Label, Well, Table, ButtonGroup, Button} = require("react-bootstrap");

const {areIntervalsOverlapping} = require('date-fns');

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MonthPager = require("components/widgets/MonthPager"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    CalendarMonthView = require("components/form/CalendarMonthView"),
    Utility = require("util/Utility");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const calenderStore = require("stores/CalendarStore"),
    calActions = require("actions/CalendarActions"),
    notificationActions = require("components/notification/NotificationActions");

class CalendarMonth extends React.Component {
    constructor(props) {
        super(props);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);
        this.loadingEvents = this.loadingEvents.bind(this);
        this.handleCalendarType = this.handleCalendarType.bind(this);
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.state = {
            calenderStore: calenderStore.getState(),
            calType: "calendar"
        }
    }
    componentDidMount() {
        calenderStore.listen(this.handleCalendarChange);
        let today = new Date();
        let searchParams = {
            year: today.getFullYear(),
            text: ""
        };
        this.loadingEvents(searchParams);
    }
    componentWillUnmount() {
        calenderStore.unlisten(this.handleCalendarChange);
    }
    loadingEvents(searchParams) {
        if (this.props.loadingEventFct) {
            calActions.updateSearchParams.defer(searchParams);
            calActions.updateEventLoading.defer(true);
            this.props.loadingEventFct(searchParams);
        } else {
            notificationActions.error.defer("No loadingEventFct defined for calendar")
        }
    }
    handleCalendarChange(state) {
        this.setState({calenderStore: state});
    }
    handleCalendarType(type) {
        this.setState({calType: type})
    }
    openDeleteModal(clubEvent) {
        this.refDelClubEventModal.current.openNamedHeader(clubEvent.getName());
    }
    handleChangedDate(month, year, yearChanged) {
        calActions.updateCalendarBoundaries(new Date(year, month - 1, 1));
        if (yearChanged) {
            let searchParams = {
                year: year,
                text: ""
            };
            this.loadingEvents(searchParams);
        }
    }
    render() {
        const {calType, calenderStore} = this.state;
        const {user, colName, colAdditional, topTableArea, fctWeekSumElement, denyEdit, customArea, customAreaIcon} = this.props;
        const {calendarBoundaries, calEvents,  eventLoading, searchParams} = calenderStore;
        return (<React.Fragment>
            <CalendarAreaButtonGroup handleCalendarType={this.handleCalendarType} calType={calType} customAreaIcon={customAreaIcon} />
            { calType !== "custom" ? <React.Fragment>
                    <MonthPager headerText="" handleChangedDate={this.handleChangedDate} allowFuture={true}/>
                    {topTableArea}
                </React.Fragment> : null}
            { eventLoading ? <Loading /> : null}
            {
                calType === 'calendar' ? <Row>
                    <Col xs={12}>
                        <CalendarMonthView eventLoading={eventLoading} denyEdit={denyEdit}
                                           calEvents={calEvents} fctWeekSumElement={fctWeekSumElement}
                                           calendarBoundaries={calendarBoundaries}/>
                    </Col>
                </Row> : null
            }
            {
                calType === 'list' ? <Row>
                    <Col lg={12}>
                        <br/>
                        <Table responsive striped hover>
                            <thead>
                            <tr>
                                <th><I18n code="tournament.table.column.date"/></th>
                                <th>{colName}</th>
                                <th>{colAdditional}</th>
                                <th><I18n code="club.detail.comment"/></th>
                                <th><I18n code="parcours.details.button.actions"/></th>
                            </tr>
                            </thead>
                            <CalenderTableBody calEvents={calEvents} denyEdit={denyEdit}
                                               calendarBoundaries={calendarBoundaries}/>
                        </Table>
                    </Col>
                </Row> : null
            }
            {
                calType === "custom" ? <React.Fragment>{customArea}</React.Fragment> : null
            }
        </React.Fragment>);
    }
}
CalendarMonth.propTypes = {
    user:PropTypes.object.isRequired,
    loadingEventFct: PropTypes.func.isRequired,
    colName: PropTypes.any,
    colAdditional: PropTypes.any,
    topTableArea: PropTypes.any,
    fctWeekSumElement: PropTypes.func,
    denyEdit: PropTypes.bool
};
const CalendarAreaButtonGroup = ({calType, handleCalendarType, customAreaIcon}) => {
    return <Row>
        <Col sm={6}>
            <br/>
            <ButtonGroup>
                <Button onClick={()=> {handleCalendarType("calendar")}}
                        className={calType === "calendar" ? "active" : ""}><FontAwesome icon="calendar-alt"/>
                </Button>
                <Button onClick={()=> {handleCalendarType("list")}}
                        className={calType === "list" ? "active" : ""}><FontAwesome icon="list"/>
                </Button>
                {
                    customAreaIcon != null ? <Button
                        onClick={()=> {handleCalendarType("custom")}}
                        className={calType === "list" ? "active" : ""}><FontAwesome icon={customAreaIcon}/>
                    </Button> : null
                }

            </ButtonGroup>
        </Col>
    </Row>
}
const CalenderTableBody = ({calEvents, denyEdit, calendarBoundaries}) => {
    if (calEvents.length == 0) {
        return (
            <tbody>
            <tr>
                <td></td>
                <td><I18n code="list.entry.empty"/></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            </tbody>
        );
    } else {
        // TODO -> Stunden/Pfeile Trainingstypen in table mit Hierachy (Trainingstype mit Anzeigen)
        // TODO -> 4 Hauptype icons
        // TODO -> check yearstat for training types
        // TODO -> Kommentare "Privat"
        // TODO -> how to "reload" Arrows&Duration after changing or deleting?
        let month = 0;
        return (
            <tbody>
            {
                calEvents.map(calEvent => {
                    if (areIntervalsOverlapping({start: calEvent.getCalStartDate(), end: calEvent.getCalEndDate()},
                        {start: calendarBoundaries.monthStart, end:calendarBoundaries.monthEnd})) {
                        return (<CalendarTableEntry key={calEvent.id} calEvent={calEvent} denyEdit={denyEdit} />)
                    }
                })
            }
            </tbody>
        );
    }
};
CalenderTableBody.propTypes = {
    calEvents: PropTypes.array,
    denyEdit: PropTypes.bool
};
const CalendarTableEntry = ({calEvent, denyEdit}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function handleClickSelect() {
        modalBasics.openObjectFct(calEvent, "create")
    }
    function handleClickDelete() {
        modalBasics.openObjectFct(calEvent, "delete")
    }
    return (<tr>
        <td>{Utility.getReadableDateRange(calEvent.getCalStartDate(), calEvent.getCalEndDate())}</td>
        <td>
            <p>
                <Well style={{backgroundColor: calEvent.getCalBackground(), padding: "5px"}}>
                {
                    calEvent.getCalLink() ? <a href={linkUtil.getLink(calEvent.getCalLink())} target="_blank">
                            <StrikeOutText strikeOut={calEvent.isDeleted()}>{calEvent.getCalName()}</StrikeOutText>
                    </a>
                    : <StrikeOutText strikeOut={calEvent.isDeleted()}>{calEvent.getCalName()}</StrikeOutText>
                }
                </Well>
            </p>
        </td>
        <td>{calEvent.getCalAdditional()}</td>
        <td>{calEvent.getCalDescription()}</td>
        <td>
            {
                calEvent.getCalcAllowEdit()  && !denyEdit ? <ButtonGroup >
                    <Button onClick={handleClickSelect}>
                        <FontAwesome icon="pencil-alt"/>
                    </Button>
                    <Button onClick={handleClickDelete}>
                        <FontAwesome icon="trash"/>
                    </Button>
                </ButtonGroup> : null
            }
        </td>
    </tr>)
};
CalendarTableEntry.propTypes = {
    calEvent:PropTypes.object.isRequired,
    denyEdit:PropTypes.bool
};
module.exports = CalendarMonth;
