const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Image} = require("react-bootstrap");
const Parse = require("ParseInit"),
    {ImageGallery, Tournament} = require("parse/_Domain");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading");

var messages = require("i18n/messages");

class ImageGalleryPage extends React.Component {
    constructor(props) {
        super(props);
        this.queryImages = this.queryImages.bind(this);
        this.updateImageGallery = this.updateImageGallery.bind(this);
        this.state = {
            loading: true,
            imageGalleryList: []
        }
    }
    componentDidMount() {
        if (this.props.type === "Tournament") {
            let tournament = new Tournament();
            tournament.id =  this.props.id;
            this.queryImages(null, tournament);
        } else if (this.props.type === "Parcours") {
            this.queryImages(this.props.id, null);
        }

    }
    queryImages(parcoursID, tournament) {
        var that = this;
        var query = new Parse.Query(ImageGallery);
        if (parcoursID != null) {
            query.equalTo(ImageGallery.prototype.col.parcoursOnlineID, parcoursID);
        }
        if (tournament != null) {
            query.equalTo(ImageGallery.prototype.col.tournamentID, tournament);
        }
        query.equalTo(ImageGallery.prototype.col.status,"A");
        query.include(ImageGallery.prototype.col.creator);
        query.descending("createdAt");
        query.find()
            .then(list => {
                that.updateImageGallery(list);
            })
            .catch(error => {
                console.log("PROF_qEPM" + error.message);
                that.updateImageGallery([]);
            });
    }
    updateImageGallery(list) {
        this.setState({
            loading: false,
            imageGalleryList: list
        });
    }
    render() {
        return (
            <div>
                <Grid>
                    {this.state.loading === true ?
                        <Loading/> : ""}
                    {this.state.loading === false && this.state.imageGalleryList.length <= 0 ?
                        <Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert> : ""
                    }

                    {  this.state.loading === false && this.state.imageGalleryList.length > 0 ?
                        <ImgGallery imageGalleryList={this.state.imageGalleryList}/> : ""
                    }
                </Grid>
            </div>
        )
    }
}
const ImgGallery = ({imageGalleryList}) => {
    var month = 0;
    var index = -1;
    return (
        <Row>
            {imageGalleryList.map(imageGallery => {
                index++;
                var date = imageGallery.get("createdAt");
                if (month != (date.getMonth()+1)) {
                    month = date.getMonth()+1;
                    return (
                        <div>
                            <Col xs={12}><h3>{messages.get("montPager.month." + month) + " " + date.getFullYear()}</h3><br/></Col>
                            <GalleryEntry key={imageGallery.id} imageGallery={imageGallery} index={index}/>
                        </div>);
                } else {
                    return (<GalleryEntry key={imageGallery.id} imageGallery={imageGallery} index={index}/>);
                }
            })}
        </Row>

    )
};
ImgGallery.propTypes = {
    imageGalleryList:PropTypes.array.isRequired
};
const GalleryEntry = ({imageGallery}) => {
    return (
        <Col xs={12} sm={6}  md={4} lg={3}>
            <Image responsive thumbnail
                 src={imageGallery.getThumbnailUrl()} />
            <br/>
        </Col>)
};
GalleryEntry.propTypes = {
    imageGallery:PropTypes.object.isRequired
};
module.exports = ImageGalleryPage;
