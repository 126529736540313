const React = require("react"),
    PropTypes = require('prop-types');
const {Alert, Button} = require("react-bootstrap");
const {Link} = require("react-router-dom");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const TrainingFeatureMissing = () => {
        return <Alert bsStyle="warning">
            <FontAwesome icon="exclamation-circle"/>
            <I18n code="profile.training.noAbo"/><br/><br/>
            <Link to={linkUtil.getLink("skillboard/premium/buy")}>
                <Button bsStyle="primary"><I18n code="skillboard.premium.training"/></Button>
            </Link>
        </Alert>
};
module.exports = TrainingFeatureMissing;
