const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Image} = require("react-bootstrap");
const ImageGalleryModal = require("components/modals/ImageGalleryModal");

class ImageGrid extends React.Component {
    constructor(props) {
        super(props);
        this.onSelectImage = this.onSelectImage.bind(this);
        this.refGalleryModal = React.createRef();
        this.state = {}
    }
    onSelectImage (image) {
        this.refGalleryModal.current.openModal(image);
    }
    render() {
        const {user, parcours, imageGalleryList, adminPage} = this.props;
        console.log("Render ImageGallery: " + imageGalleryList.length);
        return (
            <Row>
                <ImageGalleryModal ref={this.refGalleryModal} user={user} parcours={parcours} adminPage={adminPage}/>
                {imageGalleryList.map(imageGallery => {
                    return <GalleryEntry key={imageGallery.id} imageGallery={imageGallery} onImageClick={this.onSelectImage}/>
                })}
            </Row>
        )
    }
}
ImageGrid.propTypes = {
    imageGalleryList:PropTypes.array.isRequired,
    user: PropTypes.object,
    parcours: PropTypes.object,
};
const GalleryEntry = ({imageGallery, onImageClick}) => {
    function handleImageClick() {
        onImageClick(imageGallery);
    }
    return (<Col xs={12} sm={6}  md={3} lg={2}>
        <div style={{position: "relative", overflow: "hidden" }}>
            <Image style={{cursor: "pointer", maxWidth:"100%", height:"auto", borderRadius:"5px", marginTop:"5px", marginBottom: "5px"}}
                   onClick={handleImageClick}
                   src={imageGallery.getThumbnailUrl()} />
        </div>
    </Col>)
};
GalleryEntry.propTypes = {
    imageGallery:PropTypes.object.isRequired,
    onImageClick: PropTypes.func.isRequired
};
module.exports = ImageGrid;
