const React = require("react"),
    PropTypes = require('prop-types');

const { Marker, OverlayView, Polyline  } = require ('@react-google-maps/api');
const MapUtil = require("components/map/MapUtil");

class TargetMapMarker extends React.Component {
    constructor(props) {
        super(props)
        this.toggleInfoVisible = this.toggleInfoVisible.bind(this);
        this.generateMarkerContent = this.generateMarkerContent.bind(this);
        this.state = {
            infoVisible: false,
        }
    }
    toggleInfoVisible() {
        console.log("TargetMapMarker.toggleInfoVisible",this.props.parcoursTarget.getIndex(),this.props.parcoursTarget.getName())
        let visible = this.state.infoVisible;
        this.setState({infoVisible: !visible})
    }
    generateMarkerContent() {
        let mapUtil = new MapUtil();
        return mapUtil.generateTargetMarker(this.props.parcoursTarget)
    }
    render() {
        const {infoVisible} = this.state;
        const {parcoursTarget} = this.props;
        const tCoord = parcoursTarget.getCoordinates();
        const posMarker = {lat:  tCoord.latitude, lng:  tCoord.longitude};
        let pCoordPath = null;
        if (parcoursTarget.getCoordinatesPegs() && parcoursTarget.getCoordinatesPegs().length > 0) {
            const pCoord = parcoursTarget.getCoordinatesPegs()[0];
            if (pCoord) {
                pCoordPath = [
                    {lat: pCoord._latitude, lng: pCoord._longitude},
                    {lat:  tCoord.latitude, lng:  tCoord.longitude}
                ];
            }
        }
        return <React.Fragment>
            <Marker position={posMarker} icon={"/img/ico/frame.png"} onClick={this.toggleInfoVisible}
                    label={{
                text: parcoursTarget.getIndex().toString(),
                fontWeight: 'bold',
                color: 'white'
            }}/>
            {
                infoVisible ?
                    <OverlayView position={posMarker} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                        <div style={{
                            background: '#FFFFFF',
                            padding: 10,
                            fontSize: 12,
                            borderRadius: 5
                        }}
                             onClick={this.toggleInfoVisible}
                             dangerouslySetInnerHTML={{__html:this.generateMarkerContent()}}/>
                    </OverlayView> : null
            }
            {
                pCoordPath ? <Polyline path={pCoordPath}
                                       options={{
                                           strokeColor:"#0000FF",
                                           strokeOpacity:1,
                                           strokeWeight:3,
                                           icons: [{
                                               icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW},
                                               offset: '100%'
                                           }]}}
                /> : null
            }
        </React.Fragment>
    }
}
TargetMapMarker.propTypes = {
    parcoursTarget: PropTypes.object.isRequired
};
module.exports = TargetMapMarker;