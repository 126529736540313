const alt = require("AltInit");
const PLUtil = require("util/ParseListUtility");
const tournamentUserActions = require("actions/TournamentUserActions");

class TournamentUserStore {
    constructor() {
        this.displayName = "TournamentUserStore";
        this.tournamentUsers = [];
        this.childTournamentUsers = [];
        this.allTournamentUsers = [];
        this.tournamentUserTeams = [];
        this.allTournamentUserTeams = [];
        this.paidTournamentUserAmount = 0;
        this.searchParams = {
            text: "",
            team:false
        };
        this.loading = true;
        this.parseError = null;
        this.bindListeners({
            updateTournamentUsers: tournamentUserActions.updateTournamentUsers,
            updateTournamentUser: tournamentUserActions.updateTournamentUser,
            updateChildTournamentUsers: tournamentUserActions.updateChildTournamentUsers,
            searchTournamentUser: tournamentUserActions.searchTournamentUser,
            removeTournamentUser: tournamentUserActions.removeTournamentUser,
            updateTournamentUserTeams: tournamentUserActions.updateTournamentUserTeams,
            removeTournamentUserTeam: tournamentUserActions.removeTournamentUserTeam,
            addTournamentUserTeam: tournamentUserActions.addTournamentUserTeam,
            addTournamentUser: tournamentUserActions.addTournamentUser,
            resetTournamentUsers: tournamentUserActions.resetTournamentUsers
        });
    }


    //---------------------

    updateTournamentUsers(tournamentUsers) {
        this.loading = false;
        let startNumber = 1;
        let paidAmount = 0;
        this.allTournamentUsers = tournamentUsers.map(function (tournamentUser) {
            tournamentUser.startNumber = startNumber;
            startNumber++;
            if (tournamentUser.isPaid()) {
                paidAmount++;
            }
            return tournamentUser;
        });
        this.paidTournamentUserAmount = paidAmount;
        this.filterTournamentUsers();
    }
    updateChildTournamentUsers(tournamentUsers) {
        this.childTournamentUsers = tournamentUsers;
    }
    filterTournamentUsers() {
        let preFilteredList = this.allTournamentUsers;
        if (this.searchParams.moreFilter) {
            // advanced filtering
            if (this.searchParams.selectedSex == 0 || this.searchParams.selectedSex == 1) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getSex() == this.searchParams.selectedSex;
                })
            }
            if (this.searchParams.selectedAgeType) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getTournamentConfigAge().id == this.searchParams.selectedAgeType.id;
                })
            }
            if (this.searchParams.selectedBowType) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getTournamentConfigBow().id == this.searchParams.selectedBowType.id;
                })
            }
            if (this.searchParams.selectedOption) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getOption() == this.searchParams.selectedOption;
                })
            }
        }
        if (this.searchParams.text.length > 0) {
            const searchString = this.searchParams.text.toLowerCase();
            this.tournamentUsers = preFilteredList.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            this.tournamentUsers = preFilteredList;
        }
    }

    updateTournamentUserTeams(pTournamentUserTeams) {
        this.loading = false;
        let startNumber = 1;
        this.allTournamentUserTeams = pTournamentUserTeams.map(function (tournamentUserTeam) {
            tournamentUserTeam.startNumber = startNumber;
            startNumber++;
            return tournamentUserTeam;
        });
        this.filterTournamentUserTeams();
    }

    filterTournamentUserTeams() {
        if (this.searchParams.text.length > 0) {
            var that = this;
            var searchString = that.searchParams.text.toLowerCase();
            this.tournamentUserTeams = this.allTournamentUserTeams.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            this.tournamentUserTeams = this.allTournamentUserTeams;
        }
    }

    searchTournamentUser(searchParams) {
        this.searchParams = searchParams;
        if (searchParams.team === true) {
            this.filterTournamentUserTeams();
        } else {
            this.filterTournamentUsers();
        }

    }

    removeTournamentUser(tournamentUser) {
        this.updateTournamentUsers(
            this.allTournamentUsers.filter(function (u) {
                return u.id != tournamentUser.id;
            })
        );
    }

    removeTournamentUserTeam(pTournamentUserTeam) {
        this.updateTournamentUserTeams(
            this.allTournamentUserTeams.filter(function (uTeam) {
                return uTeam.id != pTournamentUserTeam.id;
            })
        );
    }

    addTournamentUser(tournamentUser) {
        this.updateTournamentUsers(this.allTournamentUsers.concat(tournamentUser));
    }

    updateTournamentUser(tournamentUser) {
        PLUtil.updateOrPush(this.allTournamentUsers, tournamentUser)
        this.updateTournamentUsers(this.allTournamentUsers);
    }

    addTournamentUserTeam(tournamentUserTeam) {
        this.updateTournamentUserTeams(this.allTournamentUserTeams.concat(tournamentUserTeam));
    }

    resetTournamentUsers() {
        this.tournamentUsers = [];
        this.allTournamentUsers = [];
        this.tournamentUserTeams = [];
        this.allTournamentUserTeams = [];
        this.searchParams = {
            text: "",
            team: false
        };
        this.loading = true;
    }
}
module.exports = alt.createStore(TournamentUserStore);