var cookie = require("cookie");

var cookieUtil = {
    read: function () {
        try {
            return JSON.parse(cookie.parse(document.cookie).data)
        } catch (e) {
            return {};
        }
    },
    update: function (key, value) {
        var data = this.read();
        data[key] = value;
        document.cookie = cookie.serialize('data', JSON.stringify(data), {path: "/"});
    }
};

module.exports = cookieUtil;
