const React = require("react"),
    PropTypes = require('prop-types');
const {Col} = require("react-bootstrap");
const ValidInput = require("components/form/ValidInput"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead");

const messages = require("i18n/messages");

const parcoursTargetActions = require("actions/ParcoursTargetActions");

class AddParcoursTarget extends React.Component {
    constructor(props) {
        super(props);
        this.refIndex = React.createRef();
        this.refInputName = React.createRef();

        this.handleTargetSelected = this.handleTargetSelected.bind(this);
        this.handleNoTargetSelected = this.handleNoTargetSelected.bind(this);
        this.handleChangedIndex = this.handleChangedIndex.bind(this);
        this.nextTarget = this.nextTarget.bind(this);

        this.state = {}
    }
    handleTargetSelected(target) {
        const index = parseInt(this.refIndex.current.getValue());
        if (target && !isNaN(index)) {
            console.log("add " + this.props.parcours.getOnlineID() + " station " + index + " target: " + target.id + " / " + target.getName());
            if (this.props.editTarget) {
                parcoursTargetActions.saveTargetLog(this.props.editTarget)
                parcoursTargetActions.updateTarget(this.props.editTarget, target, null, index, this.props.user);
            } else {
                parcoursTargetActions.createParcoursTargetCatalog(index, this.props.parcours.getOnlineID(), target, this.props.user);
                this.nextTarget(index);
            }
        }

    }
    handleNoTargetSelected(name) {
        const index = parseInt(this.refIndex.current.getValue());
        if (name && name.length > 0 && !isNaN(index)) {
            console.log("add " + this.props.parcours.getOnlineID() + " station " + index + " target: " + name);
            if (this.props.editTarget) {
                parcoursTargetActions.updateTarget(this.props.editTarget, null, name, index, this.props.user);
            } else {
                parcoursTargetActions.createParcoursTarget(index, this.props.parcours.getOnlineID(), name, this.props.user);
                this.nextTarget(index);
            }
        }
    }
    handleChangedIndex(event) {
        const index = parseInt(event.currentTarget.value);
        if (this.props.editTarget && !isNaN(index)) {
            parcoursTargetActions.updateTarget(this.props.editTarget, null, null, index, this.props.user);
        }
    }
    nextTarget(index) {
        this.refIndex.current.setValue(index + 1);
        this.refInputName.current.clearInput();
    }
    render() {
        const {editTarget} = this.props;
        let nextIndex = this.props.parcoursTargets.length + 1;
        let lblMessage = messages.get("parcours.targets.input.name");
        if (editTarget) {
            nextIndex = editTarget.getIndex();
            lblMessage = editTarget.getName();
        }
        return (<React.Fragment>
            <Col xs={3} sm={2} key="input.index">
                <p>{messages.get("parcours.targets.input.peg")}</p>
                <ValidInput ref={this.refIndex}
                            type="number"
                            onChange={this.handleChangedIndex}
                            valid={{check: ['isNumber','isRequired']}}
                            default={nextIndex}/>
            </Col>
            <Col xs={9} sm={8} key={"input.name"}>
                <p>{lblMessage}</p>
                <SelectInputTypeAhead ref={this.refInputName}
                                      disabled={false}
                                      objectList={this.props.targetCatalog}
                                      handleNoObjectFound={this.handleNoTargetSelected}
                                      handleSelectedObject={this.handleTargetSelected}
                                      placeholder={messages.get("parcours.targets.input.name.target") + " / " + messages.get("parcours.targets.input.name.search")}/>
            </Col></React.Fragment>)
    }
}
AddParcoursTarget.propTypes = {
    parcours:PropTypes.object.isRequired,
    targetCatalog: PropTypes.array.isRequired,
    parcoursTargets: PropTypes.array.isRequired,
    loadingCatalog: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    closeEdit: PropTypes.func,
    editTarget: PropTypes.object
};
module.exports = AddParcoursTarget;