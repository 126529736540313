const Parse = require("ParseInit");

const {TargetCatalog, TargetBrand, ParcoursTarget, ParcoursTargetLog} = require("parse/_Domain");

class ParcoursTargetDao {
    queryTargetCatalog() {
        const query = new Parse.Query(TargetCatalog);
        query.notEqualTo(TargetCatalog.prototype.col.status, "D");
        query.include(TargetCatalog.prototype.col.brandID);
        query.limit(1000);
        return query.find();
    }

    queryTargetBrands() {
        const query = new Parse.Query(TargetBrand);
        query.notEqualTo(TargetBrand.prototype.col.status, "D");
        //query.include(TargetBrand.prototype.col.addressID);
        query.ascending(TargetBrand.prototype.col.name);
        return query.find();
    }

    queryParcoursTargets(onlineID) {
        const query = new Parse.Query(ParcoursTarget);
        query.notEqualTo(ParcoursTarget.prototype.col.status, "D");
        query.equalTo(ParcoursTarget.prototype.col.onlineID, onlineID);
        query.ascending(ParcoursTarget.prototype.col.index);
        return query.find();
    }

    queryParcoursTargetLogs(onlineID, index) {
        const query = new Parse.Query(ParcoursTargetLog);
        query.notEqualTo(ParcoursTargetLog.prototype.col.status, "D");
        query.equalTo(ParcoursTargetLog.prototype.col.onlineID, onlineID);
        query.equalTo(ParcoursTargetLog.prototype.col.index, index);
        query.include(ParcoursTargetLog.prototype.col.editor);
        query.descending(ParcoursTargetLog.prototype.col.createdAt);
        return query.find();
    }

    queryTargetExtRef(extRefID) {
        const query = new Parse.Query(TargetCatalog);
        query.notEqualTo(TargetCatalog.prototype.col.status, "D");
        query.equalTo(TargetCatalog.prototype.col.extRefID, extRefID);
        return query.first();
    }

    saveTargetLog(target) {
        return Parse.Cloud.run("saveParcoursTargetLog", {parcoursTargetId: target.id});
    }


}

module.exports = new ParcoursTargetDao();
