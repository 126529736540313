const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Well, Alert} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    CheckboxInput = require("components/form/CheckboxInput"),
    SubmitButton = require("components/form/SubmitButton"),
    DropDownString = require("components/form/DropDownString"),
    messages = require("i18n/messages");

const TournamentOptionSelection = require("components/tournamentmodal/TournamentOptionSelection");

const {Tournament} = require("parse/_Domain");

const tournamentActions = require("actions/TournamentManagerActions");

class TournamentMMConfigAdvanced extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refPairReg = React.createRef();
        
        this.setChanged = this.setChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.tOptionChanged = this.tOptionChanged.bind(this);
        this.handleCupGroupChange = this.handleCupGroupChange.bind(this);
        this.state = {
            hasChanged: false,
            tOptionID: this.props.tournament.getTournamentOptionID(),
            cupGroup: this.props.tournament.getCupGroup(),
            changedCupGroup: false,
            open: true
        }
    }
    setChanged(changed) {
        this.setState({hasChanged: changed});
    }
    handleSubmit(e) {
        e.preventDefault();
        // save changes
        let tournament = this.props.tournament;
        if (this.refPairReg.current.getValue() == true) {
            tournament.setRegistrationType(Tournament.prototype.regTypes.pair)
        } else {
            tournament.setRegistrationType(Tournament.prototype.regTypes.single)
        }
        tournament.setTournamentOptionID(this.state.tOptionID);
        tournament.setCupGroup(this.state.cupGroup)
        if (this.state.changedCupGroup) {
            // update group of all tournamentUsers of this tournament
            tournamentActions.updateTournamentUserCupGroup(tournament, this.state.cupGroup)
        }
        tournamentActions.saveTournamentCB(tournament, ()=> {
            this.refSubmitButton.current.resetLoading();
            this.setState({error: null, hasChanged: false, changedCupGroup: false});
        });
    }
    handleCupGroupChange(group) {
        this.setState({
            hasChanged: true,
            changedCupGroup: true,
            cupGroup: group
        });
    }
    tOptionChanged(optionID) {
        this.setState({
            hasChanged: true,
            tOptionID: optionID
        });
    }
    render() {
        const {hasChanged, tOptionID, cupGroup, error} = this.state;
        const {tournament} = this.props;
        const cupOption = tournament.getTournamentCupOptionID();
        return (<Col xs={12}>
            <Panel  bsStyle={hasChanged ? "warning" : "default" }>
                <Panel.Heading>
                    <h2><FontAwesome icon="bars"><I18n code="tournament.manager.config.advanced"/></FontAwesome></h2>
                </Panel.Heading>
                <Panel.Body>
                    {error ? <Alert bsStyle="warning">{error}</Alert> : ""}
                    <Row>
                        <Col xs={6}>
                            <CheckboxInput ref={this.refPairReg}
                                           default={tournament.isTypePairRegistration()}
                                           onAfterChange={() => this.setChanged(true)}
                                           message={messages.get("tournament.manager.tConfig.pairReg")} />
                        </Col>
                        <Col xs={6}>
                            <TournamentOptionSelection tournamentOption={tOptionID} onAfterChange={this.tOptionChanged}/>
                            {
                                tOptionID ? <Well>{tOptionID.getDescription()}</Well> : null
                            }
                        </Col>
                        <Col xs={6}>
                            {
                                cupOption != null && cupOption.getCupGroups() != null ?
                                    <CupGroupSetting cupOption={cupOption} cupGroup={cupGroup}
                                                     handleCupGroupChange={this.handleCupGroupChange} /> : null
                            }
                        </Col>
                    </Row>
                </Panel.Body>
                <Panel.Footer>
                    <Row><Col xs={12} className="text-center">
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmit} disabled={!hasChanged}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Col></Row>
                </Panel.Footer>
            </Panel>
        </Col>);
    }
}
TournamentMMConfigAdvanced.propTypes = {
    tournament:PropTypes.object.isRequired
};
const CupGroupSetting = ({cupOption, cupGroup, handleCupGroupChange}) => {
    return <React.Fragment>
        <DropDownString placeholder={<option key="nullkey" value={"null"}>None</option>}
                        label={<I18n code="tournament.manager.tConfig.selCupGroup"/> }
                        default={cupGroup}
                        objectList={cupOption.getCupGroups()}
                        onAfterChange={handleCupGroupChange}/>
        {
            cupGroup == null ? <Alert bsStyle="danger"><I18n code="tournament.manager.tConfig.selCupGroup"/></Alert> : null
        }
    </React.Fragment>
}
module.exports = TournamentMMConfigAdvanced;
