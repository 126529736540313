/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Button} = require("react-bootstrap");

const { FontAwesomeIcon } = require ('@fortawesome/react-fontawesome');

class NumPadModal extends React.Component {
    constructor(props) {
        super(props);
        this.refBgSize = React.createRef();
        this.refBgRepeat = React.createRef();
        this.refBgPosition = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.addNumber = this.addNumber.bind(this);
        this.clickedBack = this.clickedBack.bind(this);
        this.clickedSave = this.clickedSave.bind(this);

        this.state = {
            showModal: false,
            numberString: ""
        }
    }
    open() {
        this.setState({
            showModal: true,
        });
    }
    close() {
        this.setState({showModal: false,numberString: ""});
    }
    addNumber(digit) {
        let tmp = this.state.numberString;
        this.setState({numberString: tmp += digit})
    }
    clickedBack() {
        let tmp = this.state.numberString;
        this.setState({numberString: tmp.slice(0, -1)})
    }
    clickedSave(e) {
        if (this.props.numberCallback) {
            this.props.numberCallback(this.state.numberString);
        }
        this.close();
    }
    render() {
        return (<React.Fragment>
            <Button onClick={this.open}><FontAwesomeIcon icon="calculator"/></Button>
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    <Col xs={12}>
                                        <h3>{"#" + this.state.numberString}</h3>
                                    </Col>
                                    <Col xs={12}>
                                        <NumPadButton number={7} addNumber={this.addNumber}/>
                                        <NumPadButton number={8} addNumber={this.addNumber}/>
                                        <NumPadButton number={9} addNumber={this.addNumber}/>
                                    </Col>
                                    <Col xs={12}>
                                        <NumPadButton number={4} addNumber={this.addNumber}/>
                                        <NumPadButton number={5} addNumber={this.addNumber}/>
                                        <NumPadButton number={6} addNumber={this.addNumber}/>
                                    </Col>
                                    <Col xs={12}>
                                        <NumPadButton number={1} addNumber={this.addNumber}/>
                                        <NumPadButton number={2} addNumber={this.addNumber}/>
                                        <NumPadButton number={3} addNumber={this.addNumber}/>
                                    </Col>
                                    <Col xs={12}>
                                        <Button onClick={this.clickedBack}>
                                            <span className="fa-layers fa-2x">
                                                <FontAwesomeIcon icon="" color="white" border />
                                                <FontAwesomeIcon icon="caret-left"/>
                                            </span>
                                        </Button>
                                        <NumPadButton number={0} addNumber={this.addNumber}/>
                                        <Button onClick={this.clickedSave}>
                                            <span className="fa-layers fa-2x">
                                                <FontAwesomeIcon icon="" color="white" border />
                                                <FontAwesomeIcon icon="check"/>
                                            </span>
                                        </Button>
                                    </Col>
                                </Row>

                            </form> : null
                    }
                </Modal.Body>
            </Modal></React.Fragment>
        );
    }
}
NumPadModal.propTypes = {
    numberCallback:PropTypes.func.isRequired
};
const NumPadButton = ({number, addNumber}) => {
    return <Button onClick={() => addNumber(number)}>
        <span className="fa-layers fa-2x">
            <FontAwesomeIcon icon="" color="white" border />
            <strong >{number}</strong>
        </span>
    </Button>
};
NumPadButton.propTypes = {
    number:PropTypes.number.isRequired,
    addNumber:PropTypes.func.isRequired
};
module.exports = NumPadModal;