var Parse = require("ParseInit");
var Utility = require("util/Utility");
var ClubEvent = Parse.Object.extend("clubEvent", {
    status: {
        published: "A",
        draft: "P",
        deleted: "D"
    },
    vis: {
        club: "C",
        publi: "P",
    },
    col: {
        name: "name",
        description: "description",
        clubID: "clubID",
        creator: "creator",
        groups: "groups",
        place: "place",
        coordinates: "coordinates",
        document: "document",
        date: "date",
        endDate: "endDate",
        status: "status",
        visibility: "visibility",
        options: "options",
        optionResult: "optionResult",
        weblink: "weblink",
        objectId: "objectId",
        linkSecret: "linksecret",
        updatedAt: "updatedAt",
        createdAt: "createdAt"
    },
    //
    // custom
    //
    getEventStartDate() {
        var tDate = this.getDate();
        return Utility.getReadableDate(tDate) + "  " + Utility.getReadableTime(tDate);
    },
    getEventEndDate() {
        var tDate = this.getEndDate();
        return Utility.getReadableDate(tDate) + "  " + Utility.getReadableTime(tDate);
    },
    isDeleted() {
        return this.status.deleted === this.get('status');
    },
    checkVisibilityGroup(ownClubUser) {
        if (ownClubUser == null) {
            // dao only loads public events for foreign user - no check needed
            return true;
        } else {
            if (ownClubUser.hasClubAddressOnly() || ownClubUser.isExMember()) {
                // address only and exMembers not needed in events
                return false;
            }
            if (ownClubUser.getUserID() != null && this.getCreator().id === ownClubUser.getUserID().id) {
                // creator sees it always
                return true;
            }
            if (this.status.published === this.getStatus()) {
                // status is published
                if (this.isPublic()) {
                    // active public shown
                    return true;
                } else {
                    // check group via ownClubUser
                    let groups = this.getGroups();
                    if (groups != null && groups.length > 0) {
                        for (let eg = 0; eg < groups.length; eg++) {
                            // iterate through event groups and check if ownClubUser has it
                            if (ownClubUser.hasGroup(groups[eg]) || ownClubUser.isArticle(groups[eg])) {
                                return true;
                            }
                        }
                    } else {
                        // not filtered to groups
                        return true;
                    }
                }
            }
        }
        return false;

    },
    isDraft() {
        return this.status.draft === this.getStatus();
    },
    isPublic() {
        return this.vis.publi === this.getVisibility();
    },
    hasOptions() {
         return this.getOptions() != null && this.getOptions().length > 0;
    },
    hasOptionResult(ownClubUser) {
        var optResult = this.getOptionResult();
        if (optResult != null) {
            return optResult["cuIDList"].indexOf(ownClubUser.id) > -1
        }
        return false;
    },
    getOptionResultList(option) {
        var optResult = this.getOptionResult();
        if (optResult != null) {
            var objectList = optResult[option];
            if (objectList == null) {
                objectList = []
            }
            return objectList;
        }
        return [];
    },
    getOptionResultAmount(option) {
        var optResult = this.getOptionResultList(option);
        if (optResult != null) {
            return optResult.length;
        }
        return 0;
    },
    //
    // getter/setter
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getGroups() {
        return this.get(this.col.groups)
    },
    setGroups(groups) {
        return this.set(this.col.groups, groups)
    },
    getOptions() {
        return this.get(this.col.options)
    },
    setOptions(options) {
        return this.set(this.col.options, options)
    },
    addGroup(group) {
        var groups = this.getGroups();
        if (groups == null) {
            groups = [];
        }
        groups.push(group);
        return this.setGroups(groups);
    },
    removeGroup(group) {
        var groups = this.getGroups();
        if (groups != null) {
            return this.setGroups(groups.filter(function (u) {
                return u != group;
            }));
        }
        return null;
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getClubID() {
        return this.get(this.col.clubID)
    },
    setClubID(clubID) {
        return this.set(this.col.clubID, clubID)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
        return this.set(this.col.place, place)
    },
    getCoordinates() {
        return this.get(this.col.coordinates)
    },
    getReadableCoordinates() {
        var coords = this.getCoordinates();
        if (coords) {
            return coords._latitude + "," + coords._longitude;
        } else {
            return null;
        }
    },
    setCoordinateReadable(coordcsv) {
        const coordinates = coordcsv.trim().split(",");
        this.setCoordinates(new Parse.GeoPoint({latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}));
    },
    setCoordinates(coordinates) {
        return this.set(this.col.coordinates, coordinates)
    },
    getDocument() {
        return this.get(this.col.document)
    },
    getDocumentName() {
        var res = "";
        var doc = this.getDocument();
        if (doc != null) {
            var name = doc.name();
            var nameparts = name.split("_");
            for (var i = 1; i < nameparts.length; i++) {
                res += nameparts[i];
            }
        }
        return res;
    },
    setDocument(document) {
        return this.set(this.col.document, document)
    },
    getDate() {
        return this.get(this.col.date)
    },
    setDate(date) {
        return this.set(this.col.date, date)
    },
    getEndDate() {
        return this.get(this.col.endDate)
    },
    setEndDate(endDate) {
        return this.set(this.col.endDate, endDate)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getVisibility() {
        return this.get(this.col.visibility)
    },
    setVisibility(visibility) {
        return this.set(this.col.visibility, visibility)
    },
    getOptionResult() {
        return this.get(this.col.optionResult)
    },
    setOptionResult(optionResult) {
        return this.set(this.col.optionResult, optionResult)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    setLinkSecret(secret) {
        return this.set(this.col.linkSecret, secret)
    },
    getLinkSecret() {
        return this.get(this.col.linkSecret)
    },
    hasLinkSecret(secret) {
        if (secret != null && secret.length > 0) {
            let evSecret = this.getLinkSecret();
            if (evSecret != null && evSecret.length > 0) {
                return evSecret == secret;
            }
        }
        return false;
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ClubEvent;
