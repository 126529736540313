const alt = require("AltInit");
const actions = require("actions/TrainingActions");

const searchYear = new Date().getFullYear()

class TrainingFilterStore {
    constructor() {
        this.displayName = "TrainingFilterStore";
        this.eventTrainingFilter = null;
        this.hitsInFilterDict = null;
        this.filterChanged = false;
        this.eventSearchParams = this.getDefaultEventSearchParams()
        this.targetSearchParams = this.getDefaultTargetSearchParams()
        this.bindListeners({
            selectEventFilter: actions.selectEventFilter,
            updateEventSearchParams: actions.updateEventSearchParams,
            updateTargetSearchParams: actions.updateTargetSearchParams,
            updateFilterChanged: actions.updateFilterChanged,
            updateInFilterList: actions.updateInFilterList,
            resetFilter: actions.resetFilter
        });

    }

    getDefaultEventSearchParams() {
        return {
            startDate: new Date(searchYear, 0, 1, 2 ,0 ,0,0 ),
            endDate: new Date(searchYear, 11, 31,23,59,59,0),
            countTypes: null,
            countType: null,
            parcours: null,
            baProfile: null,
            eventType: null,
            tag: null,
            events: null
        }
    }
    getDefaultTargetSearchParams() {
        return {
            enabled: false,
            killImageFilter: false,
            killImage: 1,
            potPoints: false,
            dist_est: null,
            dist_meter: null,
            target_size: null,
            level: null,
            level_num: null,
            posture: null,
            postureHill: null,
            target_group: null,
            arrowNr: null,
            zones: null,
            resTargetIndex: null,
            ecTag: null
        }
    }

    selectEventFilter(eventFilter) {
        this.filterChanged = false;
        this.eventTrainingFilter = eventFilter
    }

    updateEventSearchParams(searchParams) {
        this.eventSearchParams = searchParams
    }

    updateTargetSearchParams(searchParams) {
        this.targetSearchParams = searchParams
    }
    updateFilterChanged(changed) {
        this.filterChanged =  changed
    }
    updateInFilterList(inList) {
        if (inList != null) {
            let tmpHitsInFilterDict = {
                amount: inList.length,
                dist_est: {},
                dist_meter: {},
                level: {},
                posture: {},
                postureHill: {},
                target_group: {},
                target_size: {}
            }
            inList.forEach(evResObject => {
                Object.keys(evResObject).forEach(param => {
                    if (param in tmpHitsInFilterDict) {
                        let value = evResObject[param]
                        if (value != null && value !== -1) {
                            value = parseInt(value) // to full numbers for distances
                            // add value to the dict
                            if (tmpHitsInFilterDict[param][value] != null) {
                                tmpHitsInFilterDict[param][value]++
                            } else {
                                tmpHitsInFilterDict[param][value] = 1
                            }
                        }
                    }
                })
            })
            this.hitsInFilterDict = tmpHitsInFilterDict;
        } else {
            this.hitsInFilterDict = null;
        }
    }
    resetFilter() {
        this.filterChanged = false;
        this.eventTrainingFilter = null;
        this.hitsInFilterDict = null;
        this.eventSearchParams = this.getDefaultEventSearchParams()
        this.targetSearchParams = this.getDefaultTargetSearchParams()
    }
}
module.exports = alt.createStore(TrainingFilterStore);
