const React = require("react"),
    PropTypes = require('prop-types');
const {Panel, Image} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");

const FileDownloadArea = ({image}) => {
    if (image && image.get("image")) {
        return (
            <Panel>
                <Panel.Body>
                    <label><I18n code="tournament.invitation"/></label>
                    <br/>
                    <br/>
                    <a target="_BLANK" href={image.get("image")._url}>
                        <Image rounded src="/img/ico/icon-download.png" width="48" height="48"/>
                    </a>
                </Panel.Body>
            </Panel>
        )
    }
    return (<div></div>)
};
FileDownloadArea.propTypes = {
    image:PropTypes.object.isRequired
};

module.exports = FileDownloadArea;