const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");
const {Link} = require ('react-router-dom');

const PremiumBanner = require("components/widgets/PremiumBanner"),
    PoIItem = require("components/parcours/PoIItem"),
    util = require("util/Utility");
const poiStore = require("stores/PointInterestStore"),
    poiActions = require("actions/PointInterestActions");

let updateTimerTimout;
let imgStyle = {height: 150, marginLeft: "auto", marginRight: "auto", marginTop: 15, objectFit: "contain"};

class PointInterestBanner extends React.Component {
    constructor(props) {
        super(props);
        this.handlePoiChange = this.handlePoiChange.bind(this);
        this.updateTimer = this.updateTimer.bind(this);
        this.state = {
            poiStore: poiStore.getState(),
            timer: 0
        }
    }
    componentDidMount() {
        poiStore.listen(this.handlePoiChange);
        if (this.state.poiStore.poiList == null || this.state.poiStore.poiList.length <= 0 ) {
            poiActions.queryPOI.defer(true);
        }
        this.updateTimer()
    }
    componentWillUnmount() {
        poiStore.unlisten(this.handlePoiChange);
        clearTimeout(updateTimerTimout);
    }
    handlePoiChange(state) {
        this.setState({poiStore: state});
    }
    updateTimer() {
        updateTimerTimout = setTimeout(() => {
            // update timer to force reRender and therefore show new banner element
            let nTimer = this.state.timer + 1;
            this.setState({timer: nTimer});
            this.updateTimer()
        }, 20000);
    }
    render() {
        const {poiStore} = this.state;
        const {xs, md} = this.props;
        return <Row>
            <Col xs={xs} md={md}>
                {
                    poiStore.poiList != null && poiStore.poiList.length > 0 ?
                        <POIViewer poiList={poiStore.poiList} />
                        :
                        <PremiumBanner imgStyle={imgStyle} />
                }
            </Col>
        </Row>
    }

}
PointInterestBanner.propTypes = {
    xs: PropTypes.number.isRequired,
    md: PropTypes.number.isRequired
}
PointInterestBanner.defaultProps = {
    xs: 12,
    md: 12
};
const POIViewer = ({poiList}) => {
    const index = util.getRandomInt(poiList.length);
    return <PoIItem poi={poiList[index]} userLoc={null} imgStyle={imgStyle} />
}
module.exports = PointInterestBanner;