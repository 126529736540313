const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Panel, Button} = require("react-bootstrap");
const messages = require("i18n/messages"),
    I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");


const notificationActions = require("components/notification/NotificationActions");

const TournamentUserStatTable = ({tournamentUserResult, secTypes, primeTypes}) => {
    function calcMedalAmount() {
        const medals = tournamentUserResult.calcMedalAmount();
        notificationActions.success(messages.get("tournament.result.panel.medalcount") +  ": GOLD=" + medals.gold + " , SILBER=" + medals.silber + " , BRONZE:" + medals.bronze)
    }
    if (secTypes.length == 1) {
        if (secTypes[0] == null) {
            // no stats for invalid secTypes
            // TODO think about showing stats with primeTypes also
            return null;
        }
    }
    const showSum = secTypes.length > 1;
    let amountAll = 0;
    return <Panel>
        <Panel.Heading>
            <Row>
                <Col xs={12}>
                    <h3>{messages.get("tournament.result.panel.title.stat")}</h3>
                </Col>
            </Row>
        </Panel.Heading>
        <Panel.Body>
            <Table id="statTbl">
                <thead className="bowage-head">
                <tr>
                    <th></th>
                    {
                        secTypes.map(function(secType) {
                            return <th key={"ST_" + secType.id}>{secType.getCodeOrShortName()}</th>
                        })
                    }
                    {   showSum ? <th>{messages.get("tournament.table.column.sum")}</th> : null}
                </tr>
                </thead>
                <tbody className="bowage-table">
                {
                    primeTypes.map(function(primeType) {
                        const pTAmount = tournamentUserResult.getAttendeeAmount(primeType.id);
                        return (
                            <tr key={"PT_" + primeType.id}>
                                <td>{primeType.getCodeOrShortName()}</td>
                                {
                                    secTypes.map(function(secType) {
                                        const key = tournamentUserResult.generateDicKey(primeType, secType, "_");
                                        const amount = tournamentUserResult.getAttendeeAmount(key);
                                        return <td key={key}>{amount}</td>
                                    })
                                }
                                {   showSum ? <td>{pTAmount}</td> : null}
                            </tr>)

                    })
                }
                <tr>
                    <td>{messages.get("tournament.table.column.sum")}</td>
                    {
                        secTypes.map(function(secType) {
                            const amount = tournamentUserResult.getAttendeeAmount(secType.id);
                            if (amount > 0) {
                                amountAll += amount;
                            }
                            return <td key={"ST_" + secType.id}>{amount}</td>
                        })
                    }
                    {   showSum ? <td><strong>{amountAll}</strong></td> : null}
                </tr>
                </tbody>
            </Table>
            <Button onClick={calcMedalAmount}> <FontAwesome icon="trophy"/><I18n code="tournament.result.panel.medalcount"/></Button>
        </Panel.Body>
    </Panel>
};
TournamentUserStatTable.propTypes = {
    tournamentUserResult:PropTypes.object.isRequired,
    secTypes:PropTypes.array.isRequired,
    primeTypes:PropTypes.array.isRequired
};
module.exports = TournamentUserStatTable;