const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const ExtLink = require("components/links/ExtLink"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MapSmall = require("components/widgets/MapSmall");

const ParcoursContact = ({parcoursOwner}) =>  {
    return <React.Fragment>
        <h3><FontAwesome icon="info-circle"/>{messages.get("parcours.details.area.contact")}</h3>
        <p>{parcoursOwner.getName()}</p>
        <p>{parcoursOwner.getStreet()}</p>
        <p>{parcoursOwner.getZipPlace()}</p>
        <br/><br/>
        {
            parcoursOwner.getContactName() == null ? "" :
                <p><FontAwesome icon="user"/>{parcoursOwner.getContactName()}</p>
        }
        {
            parcoursOwner.getContactPhone() == null ? "" :
                <p><FontAwesome icon="phone"/>
                    <a href={"tel:" + parcoursOwner.getContactPhone()}>{parcoursOwner.getContactPhone()}</a>
                </p>
        }
        {
            parcoursOwner.getContactMail() == null ? "" :
                <p><FontAwesome icon="envelope"/>
                    <a href={"mailto:" + parcoursOwner.getContactMail()}>{parcoursOwner.getContactMail()}</a>
                </p>
        }
        {
            parcoursOwner.getWeblink() == null ? "" :
                <p><FontAwesome icon="globe"/>
                    <ExtLink href={linkUtil.getExternalLink(parcoursOwner.getWeblink())}>
                        {parcoursOwner.getWeblink()}
                    </ExtLink></p>

        }
        {
            parcoursOwner.getCoordinates() == null ? "" :
                <MapSmall mapCoordinates={parcoursOwner.getReadableCoordinates()}/>
        }
    </React.Fragment>
};
ParcoursContact.propTypes = {
    parcoursOwner: PropTypes.object.isRequired
};
module.exports = ParcoursContact;
