const React = require("react"),
    PropTypes = require('prop-types'),
    Parse = require("ParseInit");
const {Well, Alert, Button} = require("react-bootstrap");

const messages = require("i18n/messages"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    FileUploadInput = require("components/form/FileUploadInput"),
    ImageSelectionModal = require("components/modals/ImageSelectionModal"),
    ImageBackgroundParamsModal = require("components/modals/ImageBackgroundParamsModal");

const {Images} = require("parse/_Domain");

class FileUploadArea extends React.Component{
    constructor(props) {
        super(props);
        this.refGalleryModal = React.createRef();
        this.refBgParamModal = React.createRef();

        this.queryMyImages = this.queryMyImages.bind(this);
        this.openBGParam = this.openBGParam.bind(this);
        this.saveBGParams = this.saveBGParams.bind(this);
        this.selectedImage = this.selectedImage.bind(this);
        this.handleParseFile = this.handleParseFile.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.checkFileExtenstion = this.checkFileExtenstion.bind(this);
        this.state = {
            help: null,
            loading: false,
            style: "success"
        }
    }
    queryMyImages() {
        this.setState({
            loading: true
        });
        if (this.props.user != null) {
            var query = new Parse.Query(Images);
            query.equalTo(Images.prototype.col.editor, this.props.user);
            query.notEqualTo(Images.prototype.col.status, "D");
            if (this.props.allowedFileTypes) {
                query.containedIn(Images.prototype.col.type, this.props.allowedFileTypes);
            }
            query.descending(Images.prototype.col.createdAt);
            query.find()
                .then(images => {
                    console.log("Found images " + images.length);
                    this.setState({
                        loading: false
                    });
                    if (images.length > 0) {
                        this.refGalleryModal.current.openModal(images, 0);
                    } else {
                        this.showHelpMessage(messages.get("list.entry.empty"), "danger");
                    }
                })
                .catch(error => {
                    console.log("SearchImages" +  error.message);
                });
        } else {
            console.log("No user given to load images from");
        }
    }
    openBGParam() {
        this.refBgParamModal.current.open();
    }
    saveBGParams(bgParams) {
        var image = this.props.object.get(this.props.colName);
        if (image != null) {
            if (bgParams != null) {
                image.setBackgroundParams(bgParams)
            } else {
                image.unset(Images.prototype.col.bgParams);
            }
        }
    }
    selectedImage(image) {
        this.props.savedImageCallback(image);
    }
    handleParseFile(parseFile, imageType) {
        // no image saved yet - create new
        let image = new Images();
        image.setType(imageType);
        image.set("status","A");
        image.set("editor", Parse.User.current());
        image.set("image", parseFile);
        image.save()
            .then(savedImage => {
                this.showHelpMessage(messages.get("parcours.edit.message.saved"),"success");
                this.selectedImage(savedImage);
            })
            .catch(error => {
                console.log("BASE_sImage "+ error.message);
                this.showHelpMessage("Could not store Element " + error.message, "danger");
            });
    }
    removeImage() {
        this.props.savedImageCallback(null);
    }
    showHelpMessage(message, style) {
        var that = this;
        this.setState({
            help: message,
            loading: false,
            style: style
        });
        setTimeout(function () {
            that.showHelpMessage(null);
        }, 5000);
    }
    checkFileExtenstion(validFileExtensions, sFileName) {
        for (var j = 0; j < validFileExtensions.length; j++) {
            var sCurExtension = validFileExtensions[j];
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                return true;
            }
        }
        return false;
    }
    render() {
        const {header, object, colName, allowedFileTypes, showBGParams} = this.props;
            return (
                <Well>
                    {
                        header != null ?
                            <label><strong>{messages.get(header)}</strong></label> : null
                    }
                    <br/>
                    {
                        this.state.loading === true ?
                            <Loading />
                            :
                            <Button onClick={this.queryMyImages} >
                                <FontAwesome icon="search"/>{messages.get("profile.fromMyImages")}
                            </Button>
                    }
                    {
                        object.get(colName) != null &&  showBGParams === true ?
                            <Button onClick={this.openBGParam} ><FontAwesome icon="pencil-alt"/>{messages.get("fileupload.edit.bgParams")}</Button>
                            : null
                    }
                    {
                        object.get(colName) != null ?
                            <Button onClick={this.removeImage}><FontAwesome icon="times"/>{messages.get("modal.button.remove")}</Button>
                            : null
                    }
                    <br/><br/>
                    <FileUploadInput parseFile={this.state.parseFile}
                                     allowedFileTypes={allowedFileTypes}
                                     label={messages.get("profile.addToMyImages")}
                                     savedParseFileCallback={this.handleParseFile}/>

                    <ImageSelectionModal callbackSelectedImage={this.selectedImage} ref={this.refGalleryModal}/>
                    <ImageBackgroundParamsModal image={object.get(colName)} callbackBGParamsSave={this.saveBGParams} ref={this.refBgParamModal}/>
                    <br/>
                    {
                        this.state.help == null ? "" :
                            <Alert bsStyle={this.state.style}>
                                {this.state.help}
                            </Alert>
                    }
                </Well>
            )
    }
}
FileUploadArea.propTypes = {
    object:PropTypes.object.isRequired,
    user:PropTypes.object,
    header: PropTypes.string,
    colName: PropTypes.string,
    allowedFileTypes: PropTypes.array.isRequired,
    showBGParams: PropTypes.bool,
    savedImageCallback: PropTypes.func.isRequired
};
module.exports = FileUploadArea;