const Parse = require("ParseInit");

const {ChangeLog, ParseLog} = require("parse/_Domain");

class ChangeLogDao {

    queryChangeLog(parcours, searchParams) {
        const startDate = new Date(searchParams.year, searchParams.month - 1, 1);
        const endDate = new Date(searchParams.year, searchParams.month, 0,23,59,59,0);
        const queries = [];
        if (searchParams.searchText != null && searchParams.searchText.length > 0) {
            // searchText should check "column", "newValue"
            const colQuery = new Parse.Query(ChangeLog);
            colQuery.greaterThan(ChangeLog.prototype.col.createdAt, startDate);
            colQuery.lessThan(ChangeLog.prototype.col.createdAt, endDate);
            colQuery.matches(ChangeLog.prototype.col.columnName, "(" + searchParams.searchText + ")", "i");
            queries.push(colQuery);
            const newValQuery = new Parse.Query(ChangeLog);
            newValQuery.greaterThan(ChangeLog.prototype.col.createdAt, startDate);
            newValQuery.lessThan(ChangeLog.prototype.col.createdAt, endDate);
            newValQuery.matches(ChangeLog.prototype.col.newValue, "(" + searchParams.searchText + ")", "i");
            queries.push(newValQuery);
            const oldValQuery = new Parse.Query(ChangeLog);
            oldValQuery.greaterThan(ChangeLog.prototype.col.createdAt, startDate);
            oldValQuery.lessThan(ChangeLog.prototype.col.createdAt, endDate);
            oldValQuery.matches(ChangeLog.prototype.col.oldValue, "(" + searchParams.searchText + ")", "i");
            queries.push(oldValQuery);
            const acQuery= new Parse.Query(ChangeLog);
            acQuery.greaterThan(ChangeLog.prototype.col.createdAt, startDate);
            acQuery.lessThan(ChangeLog.prototype.col.createdAt, endDate);
            acQuery.matches(ChangeLog.prototype.col.adminComment, "(" + searchParams.searchText + ")", "i");
            queries.push(acQuery);
        } else {
            const query = new Parse.Query(ChangeLog);
            if (parcours) {
                query.equalTo(ChangeLog.prototype.col.parcoursID, parcours);
                const ownQuery = new Parse.Query(ChangeLog);
                ownQuery.equalTo(ChangeLog.prototype.col.parcoursOwnerID, parcours.getParcoursOwnerID());
                queries.push(ownQuery);
            } else {
                query.greaterThan(ChangeLog.prototype.col.createdAt, startDate);
                query.lessThan(ChangeLog.prototype.col.createdAt, endDate);
            }
            queries.push(query);
        }
        const superQuery = Parse.Query.or.apply(Parse.Query, queries);
        superQuery.include(ChangeLog.prototype.col.editor);
        superQuery.include(ChangeLog.prototype.col.parcoursOwnerID);
        superQuery.include(ChangeLog.prototype.col.parcoursID);
        superQuery.include(ChangeLog.prototype.col.grpPlaceID);
        superQuery.include(ChangeLog.prototype.col.clubID);
        superQuery.include(ChangeLog.prototype.col.tournamentID);
        superQuery.include(ChangeLog.prototype.col.tournamentUserID);
        superQuery.include(ChangeLog.prototype.col.tournamentUserID + "." + ChangeLog.prototype.col.tournamentID);
        superQuery.include(ChangeLog.prototype.col.tournamentRoundID);
        superQuery.include(ChangeLog.prototype.col.tournamentRoundID + "." + ChangeLog.prototype.col.tournamentID);
        superQuery.include(ChangeLog.prototype.col.countTypeID);
        superQuery.include(ChangeLog.prototype.col.imageGalleryID);
        superQuery.include(ChangeLog.prototype.col.managementID);
        if (searchParams.objectType) {
            superQuery.exists(searchParams.objectType)
        }
        if (searchParams.newObjectOnly) {
            superQuery.equalTo(ChangeLog.prototype.col.columnName, "status")
            superQuery.doesNotExist(ChangeLog.prototype.col.oldValue)
        }
        superQuery.limit(1000);
        superQuery.descending(ChangeLog.prototype.col.createdAt);
         return superQuery.find();
    }

    queryTournamentChangeLogs(tournamentID) {
        const query = new Parse.Query(ChangeLog);
        query.equalTo(ChangeLog.prototype.col.tournamentID, tournamentID);
        query.include(ChangeLog.prototype.col.editor);
        query.include(ChangeLog.prototype.col.tournamentUserID);
        query.descending(ChangeLog.prototype.col.createdAt);
        return query.find();
    }

    queryParseLogs(searchParams) {
        return Parse.Cloud.run('queryParseLog', searchParams);
    }

    findChangeLog(object, colName) {
        const query = new Parse.Query(ChangeLog);
        query.equalTo(colName, object);
        return query.first();
    }
}

module.exports = new ChangeLogDao();
