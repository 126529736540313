const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Well, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    DelayedTextField = require("components/form/DelayedTextField"),
    messages = require("i18n/messages");

const Utility = require("util/Utility");

const TournamentConfigAgeCalculator = ({tournament, children, selectCallback}) =>{
    const [tournamentYears, setTournamentYears] = React.useState(-1);
    const [ageClass, setAgeClass] = React.useState(null);
    let yearOnly = tournament.getBowUnionID() != null && tournament.getBowUnionID().showAgeConfigCalcYearOnly();
    function handleChange(value) {
        console.log("value", value)
        if (yearOnly) {
            // check for 4 digits
            const yearReg = /^\d{4}$/;
            if (value.match(yearReg)) {
                const compareDate = new Date(value, 0, 1, 4, 0, 0, 0);
                calcTournamentAge(compareDate)
            }
        } else {
            // check for date
            let compareDate = Utility.validateDate(value, 4);
            if (compareDate != null) {
                calcTournamentAge(compareDate)
            }
        }
    }
    function calcTournamentAge(compareDate) {
        let years = Utility.calcDifferenceInYears(tournament.getDate(), compareDate)
        setTournamentYears(years)
        console.log("Years", years)
        const configAgeTypes = tournament.getTournamentConfigAge();
        if (configAgeTypes) {
            for (let i = 0 ; i < configAgeTypes.length; i++) {
                if (configAgeTypes[i].isWithinAges(years)) {
                    setAgeClass(configAgeTypes[i])
                    if (selectCallback) {
                        selectCallback(configAgeTypes[i]);
                    }
                    return;
                }
            }
            setAgeClass(null)
        }
    }
    return <Well>
        <Row>
            <Col lg={12}>
                <h4><I18n code="tournament.register.description.ageCalc"/></h4>
            </Col>
            {children}
            <Col lg={3}>
                <DelayedTextField label={yearOnly ? messages.get("address.birthYear") : messages.get("address.birthday")}
                                  custIcon="calendar-alt"
                                  placeholder={yearOnly ? "YYYY" : "DD.MM.YYYY"}
                                  onChange={handleChange}/>
            </Col>
            {
                tournamentYears >= 0 ? <Col lg={12}>
                    {
                        ageClass != null ? <Alert bsStyle="success">
                                <I18n code="tournament.class.age"/>: {ageClass.getName()}
                        </Alert>
                        :
                        <Alert bsStyle="warning">
                            <I18n code="tournament.register.description.ageClassNull"/>: {tournamentYears} <I18n code="yearPager.years"/>
                        </Alert>
                    }
                </Col>: null
            }

        </Row>
    </Well>
};
TournamentConfigAgeCalculator.propTypes = {
    tournament: PropTypes.object.isRequired,
    selectCallback: PropTypes.func
};
module.exports = TournamentConfigAgeCalculator;
