const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Label, Button, ButtonGroup, FormGroup, FormControl, DropdownButton, MenuItem, Well, PageHeader, Table} = require("react-bootstrap");
const {Parcours} = require("parse/_Domain");
const I18n = require("components/widgets/I18n"),
    ParcoursLoader = require("components/parcours/ParcoursLoader"),
    AddParcoursTarget = require("components/parcoursmanage/AddParcoursTarget"),
    FontAwesome = require("components/widgets/FontAwesome"),
    LinkButton = require("components/links/LinkButton"),
    CoordinateMultipleInput = require("components/form/CoordinateMultipleInput"),
    TooltipButton  = require("components/form/TooltipButton"),
    ImageUrlInput = require("components/form/ImageUrlInput"),
    Utility = require("util/Utility"),
    Loading = require("components/widgets/Loading"),
    linkUtil = require("linkUtil");
const messages = require("i18n/messages");

const parcoursActions = require("actions/ParcoursActions"),
    parcoursTargetActions = require("actions/ParcoursTargetActions"),
    parcoursTargetStore = require("stores/ParcoursTargetStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursTargetEditPage = ({onlineID, trackID, tstat}) => {
    return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"T"} loginMandatory={true} tstat={tstat}
                           invalidUserDesc={<I18n code="parcours.invalidUser.desc"/>}>
        <ParcoursDetails trackID={trackID}/>
    </ParcoursLoader>
};
ParcoursTargetEditPage.propTypes = {
    onlineID: PropTypes.string.isRequired,
    trackID: PropTypes.string
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursTargetChange = this.handleParcoursTargetChange.bind(this);
        this.updateEventTrack = this.updateEventTrack.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);

        this.state = {
            parcoursTargetStore: parcoursTargetStore.getState(),
            eventTrack: null,
            help: null
        }
    }
    
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
        parcoursTargetStore.listen(this.handleParcoursTargetChange);
        parcoursTargetActions.loadTargetBrandCatalog.defer();
        if (this.props.trackID && this.props.trackID !== "admin") {
            console.log("Load Track " + this.props.trackID);
            parcoursTargetActions.loadEventTrack.defer(this.props.trackID, eventTrack => {
                this.updateEventTrack(eventTrack);
                parcoursTargetActions.loadParcoursTargets.defer(this.props.parcours.getOnlineID());
            })
        } else {
            parcoursTargetActions.loadParcoursTargets.defer(this.props.parcours.getOnlineID());
        }
    }
    componentWillUnmount() {
        parcoursTargetStore.unlisten(this.handleParcoursTargetChange);
    }
    updateEventTrack(eventTrack) {
        this.setState({eventTrack: eventTrack});
    }
    handleParcoursTargetChange(state) {
        this.setState({parcoursTargetStore: state});
    }
    showHelpMessage(message) {
        this.setState({
            help: message
        });
        setTimeout(() => {
            this.showHelpMessage(null);
        }, 5000);
    }
    render() {
        const {user, parcours} = this.props;
        const {parcoursTargetStore, eventTrack} = this.state;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                            <Breadcrumb link={"/parcours/" + parcours.getOnlineID() +"/targets" } code="parcours.targets.header"/>
                            <Breadcrumb code="modal.button.edit" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{parcours.buildNames()} </PageHeader>
                        {
                            eventTrack ? <h3><Label bsStyle="success"><FontAwesome icon="map"/>{"Track " + eventTrack.getTrackDuration() + " / " + eventTrack.getTrackDistance()}</Label></h3> : null
                        }
                        {
                            parcours.getOwnerCoordinates() === null ?
                                <Alert bsStyle="warning">Keine Koordinaten beim Parcours hinterlegt&nbsp;&nbsp;
                                    <LinkButton label="parcours.details.button.editParcours"
                                                href={linkUtil.getLink("parcours/" + parcours.getOnlineID() + "/edit")}/>
                            </Alert> : null
                        }

                    </Col>
                    {
                        this.state.help == null ? "" :
                            <Col lg={12}>
                                <Alert bsStyle="info">
                                    {this.state.help}
                                </Alert>
                            </Col>
                    }
                </Row>
                {
                    parcoursTargetStore.loading === true ?
                        <Row><Loading/></Row>
                        :
                        <ParcoursTargetList parcours={parcours} eventTrack={eventTrack} user={user}
                                         parcoursTargets={parcoursTargetStore.parcoursTargets}
                                         loadingCatalog={parcoursTargetStore.loadingCatalog}
                                         targetCatalog={parcoursTargetStore.targetCatalog}
                        />
                }
                <hr/>
                <Row>
                    <Col lg={12}>
                        <Well>
                            Hier können sie die Ziele ihres Parcours definieren.
                            Im Suchfeld werden Ziele aus unserem Katalog vorgeschlagen, bei denen ist auch bereits ein Bild hinterlegt.
                            Alternativ nur den Zielnamen eingeben und optional einen Link zum Bild (am besten von der Hersteller Seite)
                            <ul>
                                <li>Nur aktivierte Ziele werden in die App snychronisiert</li>
                                <li>Ziele können deaktiviert werden zB wenn sie nur bei Turniere aufgestellt werden</li>
                                <li>Achten sie auf eine durchgängige Nummerierung der aktivierten Ziele</li>
                                <li>Koordinaten des Ziel: Den Marker mit der Zielscheibe auf die Position des Ziel setzten.
                                Den Marker mit dem Schützen ungefähr auf die Abschlusspflöcke. </li>
                                <li>Wenn die Ziele fertig eingegeben sind, nicht vergessen die "Ziele Sync/Map" aktiv zu schalten</li>
                            </ul>
                        </Well>
                    </Col>
                </Row>
            </Grid>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    user:PropTypes.object
};
const ParcoursTargetList = ({parcours, eventTrack, user, targetCatalog, parcoursTargets, loadingCatalog}) => {
    function reNumberTargets() {
        parcoursTargetActions.reNumberTargets(parcoursTargets, parcours.getOnlineID());
    }
    function invertTargets() {
        parcoursTargetActions.invertTargets(parcoursTargets);
    }
    function handleRankStatusChanged(event) {
        parcoursActions.updateParcoursCC(parcours, Parcours.prototype.col.targetStatus, event.target.value);
    }
    return (<Row>
        <Grid>
            <Row>
                <Col xs={12} sm={6}>
                    <ButtonGroup>
                        <Button onClick={reNumberTargets}><FontAwesome icon="list-ol"/><I18n code="parcours.targets.action.renumber"/></Button>
                        <Button onClick={invertTargets}><FontAwesome icon="exchange-alt" rotation="90"/><I18n code="parcours.targets.action.invert"/></Button>
                    </ButtonGroup>
                </Col>
                <Col xs={12} sm={6}>
                    <FormGroup  validationState={parcours.getTargetStatus() == "A" || parcours.getTargetStatus() == "E" ? "success" : "warning"}>
                        <FormControl componentClass="select"
                                     defaultValue={parcours.getTargetStatus()}
                                     onChange={handleRankStatusChanged}>
                            {["I", "P", "A", "E" ].map(function (payStatus) {
                                return <option key={payStatus}
                                               value={payStatus}>{messages.get("parcours.targetStatus." + payStatus)}</option>
                            })}
                        </FormControl>
                    </FormGroup>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs={6} sm={3}>
                    <strong><I18n code="parcours.targets.input.peg"/></strong>&nbsp;
                    <strong><I18n code="parcours.targets.input.name.target"/></strong>
                </Col>
                <Col xs={6} sm={2}>
                    <strong><I18n code="parcours.details.button.actions"/></strong>
                </Col>
            </Row>
            <br/>
            {
                parcoursTargets.map(target => {
                    return <React.Fragment>
                        <ParcoursTargetRow key={target.id} target={target} user={user} eventTrack={eventTrack}
                                               parcours={parcours}
                                               targetCatalog={targetCatalog}
                                               parcoursTargets={parcoursTargets}
                                               loadingCatalog={loadingCatalog}/>
                        <hr/>
                    </React.Fragment>
                })
            }
        </Grid>
        <hr/>
        <AddParcoursTarget targetCatalog={targetCatalog} user={user}
                           parcoursTargets={parcoursTargets}
                           parcours={parcours}
                           loadingCatalog={loadingCatalog}/>
        <Col xs={12}>
            {
                loadingCatalog ?
                    <Label bsStyle="warning"><FontAwesome icon="spinner" spin={true}/><I18n code="parcours.targets.catalog.loading"/></Label>
                    :
                    <Label bsStyle="success"><I18n code="parcours.targets.catalog.done"/>{targetCatalog.length}</Label>
            }
        </Col>

    </Row>)
};
ParcoursTargetList.propTypes = {
    parcours:PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    targetCatalog: PropTypes.array.isRequired,
    parcoursTargets: PropTypes.array.isRequired,
    loadingCatalog: PropTypes.bool.isRequired,
    eventTrack: PropTypes.object
};
class ParcoursTargetRow extends React.Component {
    constructor(props) {
        super(props);
        this.refImgUrl = React.createRef();
        this.refCoords = React.createRef();

        this.setMoreField = this.setMoreField.bind(this);
        this.setEditMode = this.setEditMode.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.saveTargetUrl = this.saveTargetUrl.bind(this);
        this.saveTargetCoords = this.saveTargetCoords.bind(this);
        this.loadParcoursTargetLogs = this.loadParcoursTargetLogs.bind(this);
        this.replaceTargetFromLog = this.replaceTargetFromLog.bind(this);
        this.state = {
            more: null,
            editMode: false,
            parcoursTargetLogs: null
        }
    }
    setMoreField(type) {
        if (this.state.more == type) {
            this.setState({more: null});
        } else {
            if (type === "log") {
                this.loadParcoursTargetLogs()
            }
            this.setState({more: type});
        }
    }
    setEditMode(editMode) {
        this.setState({editMode: editMode});
    }
    loadParcoursTargetLogs() {
        parcoursTargetActions.loadParcoursTargetLogs(this.props.parcours, this.props.target, result => {
            this.setState({parcoursTargetLogs: result })
        })
    }
    replaceTargetFromLog(parcoursTargetLog) {
        parcoursTargetActions.replaceTarget(this.props.user, this.props.target, parcoursTargetLog)
    }
    changeStatus(status) {
        parcoursTargetActions.setTargetStatus(this.props.target, status);
    }
    saveTargetUrl() {
        const url = this.refImgUrl.current.getValue();
        if (url && url.length > 0) {
            this.props.target.setImageUrl(url);
            parcoursTargetActions.saveParcoursTarget(this.props.target, false);
        }
    }
    saveTargetCoords() {
        const coordBeans = this.refCoords.current.getValue();
        if (coordBeans) {
            if (coordBeans.mainCoord && coordBeans.mainCoord.trim().length > 0) {
                this.props.target.setCoordinateReadable(coordBeans.mainCoord);
            }
            if (coordBeans.addCoord1 && coordBeans.addCoord1.trim().length > 0) {
                this.props.target.setCoordinateReadablePegs([coordBeans.addCoord1]);
            }
            parcoursTargetActions.saveParcoursTarget(this.props.target, false);
        }
    }
    render() {
        const {target, user, parcours, eventTrack}  = this.props;
        const {more, editMode, parcoursTargetLogs} = this.state;
        if (editMode) {
            // change name mode
            return (<Row>
                <Button pullRight onClick={() => this.setEditMode(false)}>X</Button>
                <AddParcoursTarget targetCatalog={this.props.targetCatalog} user={user}
                                   parcoursTargets={this.props.parcoursTargets}
                                   parcours={this.props.parcours}
                                   loadingCatalog={this.props.loadingCatalog}
                                   closeEdit={() => this.setEditMode(false)}
                                   editTarget={target}/>
            </Row>)

        }
        let style = "default";
        if (target.isActive()) {
            style = "success"
        } else if (target.isDeleted()) {
            style = "danger"
        }
        return (<Row>
            <Col xs={6} sm={3}>
                <Label bsStyle={style}>{target.getIndex()}</Label>
                &nbsp;
                {target.getName()}
            </Col>
            <Col xs={6} sm={2}>
                {
                    target.isDeleted() ?
                        <TooltipButton tooltip={messages.get("modal.button.activate")} icon="check-circle" onClick={() => this.changeStatus("A")}/>
                        :
                        <ButtonGroup>
                            <DropdownButton bsStyle="primary" id="act" title="">
                                {
                                    target.isActive() ?
                                        <MenuItem onClick={() => this.changeStatus("I")}>
                                            <FontAwesome icon="times-circle"/>{messages.get("modal.button.deactivate")}
                                        </MenuItem>
                                        :
                                        <MenuItem onClick={() => this.changeStatus("A")}>
                                            <FontAwesome icon="check-circle"/>{messages.get("modal.button.activate")}
                                        </MenuItem>
                                }
                                <MenuItem onClick={() => this.setEditMode(true)}>
                                    <FontAwesome icon="pencil-alt"/>{messages.get("parcours.targets.input.edit")}
                                </MenuItem>
                                <MenuItem onClick={() => this.changeStatus("D")}>
                                    <FontAwesome icon="trash"/>{messages.get("modal.button.delete")}
                                </MenuItem>
                            </DropdownButton>
                            <TooltipButton tooltip={messages.get("parcours.details.area.coordinates")} icon="map-pin"
                                           bsStyle={target.getCoordinates() != null ? "success" : "warning"}
                                           onClick={() => this.setMoreField("map")}/>
                            <TooltipButton tooltip={messages.get("parcours.targets.input.image")} icon="image"
                                           bsStyle={target.getImageUrl() != null ? "success" : "warning"}
                                           onClick={() => this.setMoreField("img")}/>
                            <TooltipButton tooltip={messages.get("parcours.targets.input.log")} icon="history"
                                           bsStyle="info"
                                           onClick={() => this.setMoreField("log")}/>
                        </ButtonGroup>

                }
            </Col>
            {
                more === "img" ?
                    <Col xs={12} sm={6}>
                        <ImageUrlInput ref={this.refImgUrl} url={target.getImageUrl()} onSubmit={this.saveTargetUrl} />
                    </Col> : null
            }
            {
                more === "map" ?
                    <Col xs={12} sm={6}>
                        <CoordinateMultipleInput ref={this.refCoords} objectWithCoords={target} eventTrack={eventTrack} startPoint={parcours.getParcoursOwnerID()} onSubmit={this.saveTargetCoords} />
                    </Col> : null
            }
            {
                more === "log" ?
                    <Col xs={12} sm={12}><p>{target.getEditor() != null ? "Editor: " + target.getEditor().getUsername() : null}</p>
                        <ParcoursTargetLogTable parcoursTargetLogs={parcoursTargetLogs} replaceTarget={this.replaceTargetFromLog}/>
                    </Col> : null
            }
        </Row>)
    }
}
ParcoursTargetRow.propTypes = {
    target:PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    parcours: PropTypes.object.isRequired,
    eventTrack: PropTypes.object
};
const ParcoursTargetLogTable = ({parcoursTargetLogs, replaceTarget}) => {
    if (parcoursTargetLogs) {
        return <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.name"/></th>
                <th><I18n code="parcours.details.area.coordinates"/></th>
                <th><I18n code="parcours.targets.input.peg"/></th>
                <th><I18n code="tournament.table.column.image"/></th>
                <th><I18n code="User"/></th>
                <th><I18n code="Aktion"/></th>
            </tr>
            </thead>
            <tbody>
                {parcoursTargetLogs.map(parcoursTargetLog => {
                    return (<ParcoursTargetLogRow key={parcoursTargetLog.id} parcoursTargetLog={parcoursTargetLog} replaceTarget={replaceTarget}/>);
                })}
            </tbody>
        </Table>
    }
    return <Loading/>
};
ParcoursTargetLogTable.propTypes = {
    parcoursTargetLogs: PropTypes.array.isRequired,
    replaceTarget: PropTypes.func.isRequired
};
const ParcoursTargetLogRow = ({parcoursTargetLog, replaceTarget}) => {
    function handleClick() {
        replaceTarget(parcoursTargetLog);
    }
    return (
        <tr>
            <td>{Utility.getReadableDate(parcoursTargetLog.getCreatedAt())}</td>
            <td>{parcoursTargetLog.getName()}</td>
            <td>{parcoursTargetLog.getReadableCoordinates()}</td>
            <td>{parcoursTargetLog.getReadableCoordinatesPegs()}</td>
            <td>{parcoursTargetLog.getImageUrl() != null ? <a target="_blank" href={parcoursTargetLog.getImageUrl()}><I18n code="tournament.table.column.image"/></a> : null}</td>
            <td>{parcoursTargetLog.getEditor() != null ? parcoursTargetLog.getEditor().getUsername() : ""}</td>
            <td><TooltipButton tooltip={messages.get("parcours.targets.action.replace")} icon=""
                               bsStyle="default"
                               onClick={handleClick}><I18n code="modal.button.take"/></TooltipButton></td>
        </tr>)
};
ParcoursTargetLogRow.propTypes = {
    parcoursTargetLog: PropTypes.object.isRequired,
    replaceTarget: PropTypes.func.isRequired
};
module.exports = ParcoursTargetEditPage;
