const React = require("react"),
    PropTypes = require('prop-types');

const NumberRangeInput = require("components/form/NumberRangeInput");
const TrainingFilterEntity = require("components/training/TrainingFilterEntity");

const TrainingFilterRangeSelect = ({filterState, stateKey, text, disabled, updateSearchParams, min, max, step, defRangeGroups}) => {
    return <TrainingFilterEntity filterState={filterState} stateKey={stateKey} text={text} disabled={disabled}
                                 needReloadEvents={false} updateSearchParams={updateSearchParams}>
        <NumberRangeInput min={min} max={max} step={step} defaultValue={filterState} defRangeGroups={defRangeGroups}
                          onAfterChange={(value) => updateSearchParams(stateKey, value, false)}/>
    </TrainingFilterEntity>
}
TrainingFilterRangeSelect.propTypes = {
    filterState: PropTypes.array,
    stateKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
    step: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
}
module.exports = TrainingFilterRangeSelect;
