const React = require("react"),
    PropTypes = require('prop-types');
const {Grid, Alert} = require("react-bootstrap");
var {RoutedTabNav, RoutedTabs, RoutedTab} = require("components/widgets/RoutedTabNav");


var I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    YearPager= require("components/widgets/YearPager");

var userStore = require("stores/UserStore"),
    eventStore = require("stores/EventStore");

var messages = require("i18n/messages");

class ProfileTabPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.handleTabSelect = this.handleTabSelect.bind(this);

        // start with year from url this year
        var year = this.props.year;
        if (year == null) {
            var today = new Date();
            year = today.getFullYear();
        }
        // find selected tab from url
        var tabLink = "/profile";
        if (this.props.path != null) {
            if (this.props.path.indexOf("profile/tournaments") !== -1) {
                tabLink = "/profile/tournaments/";
            } else if (this.props.path.indexOf("profile/events") !== -1) {
                tabLink = "/profile/events/";
            } else if (this.props.path.indexOf("profile/scatter") !== -1) {
                tabLink = "/profile/scatter/";
            } else if (this.props.path.indexOf("profile/statistics") !== -1) {
                tabLink = "/profile/statistics/";
            } else if (this.props.path.indexOf("profile/invoices") !== -1) {
                tabLink = "/profile/invoices/";
            }
        }
        this.state = {
            userStore: userStore.getState(),
            eventStore: eventStore.getState(),
            year: year,
            tabLink: tabLink
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.profile");
        userStore.listen(this.handleUserChange);
        eventStore.listen(this.handleEventChange);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        eventStore.unlisten(this.handleEventChange);
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTabSelect(tabLink) {
        this.setState({
            tabLink: tabLink
        });
    }
    render() {
        return (
            <Grid>
                {
                    this.state.tabLink === "/profile" ? <div><br/><br/><br/></div> :
                        <YearPager year={this.state.year} href={this.state.tabLink} headerText=""/>
                }
                {this.state.userStore.user == null ?
                    <Alert bsStyle="danger">
                        <div style={{display: "inline-block"}}>
                            <FontAwesome icon="exclamation-circle"/>
                        </div>
                        <div style={{display: "inline-block"}}>
                            <I18n code="tournament.register.description.noaccount"/>
                        </div>
                    </Alert>
                    :
                    <RoutedTabNav>
                        <RoutedTabs tabs pathname={this.props.path}>
                            <RoutedTab linkPath="/profile" exact
                                       linkCode="club.detail.actual"
                                       icon="newspaper"
                                       onClick={_ => this.handleTabSelect("/profile")}/>
                            <RoutedTab linkPath={"/profile/tournaments/" + this.state.year}
                                       linkCode="header.nav.tournaments"
                                       icon="trophy"
                                       onClick={_ => this.handleTabSelect("/profile/tournaments/")}/>
                            <RoutedTab linkPath={"/profile/events/" + this.state.year}
                                       linkCode="header.nav.events"
                                       icon="list"
                                       onClick={_ => this.handleTabSelect("/profile/events/")}/>
                            <RoutedTab linkPath={"/profile/scatter/" + this.state.year}
                                       linkCode="header.nav.scatter"
                                       icon="list"
                                       onClick={_ => this.handleTabSelect("/profile/scatter/")}/>
                            <RoutedTab linkPath={"/profile/statistics/" + this.state.year}
                                       linkCode="statistics.header"
                                       icon="star"
                                       onClick={_ => this.handleTabSelect("/profile/statistics/")}/>
                            <RoutedTab linkPath={"/profile/invoices/" + this.state.year}
                                       linkCode="header.nav.invoices"
                                       icon="euro-sign"
                                       onClick={_ => this.handleTabSelect("/profile/invoices/")}/>
                        </RoutedTabs>
                        <br/>
                        {this.props.children}
                    </RoutedTabNav>
                }
            </Grid>
        )
    }
}
module.exports = ProfileTabPage;
