const React = require("react"),
    PropTypes = require('prop-types');
const DropDownParseObject = require("components/form/DropDownParseObject"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    Loading = require("components/widgets/Loading"),
    ValidInput = require("components/form/ValidInput");
const messages = require("i18n/messages");
const countTypeStore = require("stores/CountTypeStore"),
    countTypeActions = require("actions/CountTypeActions");

class CountTypeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.refArrPasse = React.createRef();
        this.handleCountTypeStoreChange = this.handleCountTypeStoreChange.bind(this);
        this.handleCountTypeGrpChange = this.handleCountTypeGrpChange.bind(this);
        this.handleCountTypeChange = this.handleCountTypeChange.bind(this);
        this.filterCountTypes = this.filterCountTypes.bind(this);
        this.state = {
            countTypeStore: countTypeStore.getState(),
            countTypeGrp: null,
            selCountType: this.props.countType
        }
    }
    componentDidMount() {
        countTypeStore.listen(this.handleCountTypeStoreChange);
        countTypeActions.loadAllCountTypes.defer();
    }
    componentWillUnmount() {
        countTypeStore.unlisten(this.handleCountTypeStoreChange);
    }
    handleCountTypeStoreChange(state) {
        this.setState({
            countTypeStore: state,
            countTypeGrp: this.filterGrp(state.countTypeGroups, this.props.countType)
        });
    }
    filterGrp(countTypeGroups, countType) {
        // preselect countTypeGroup after loaded from database
        if (countType != null && countTypeGroups != null) {
            const typeId = countType.getTypeID();
            for (let i = 0; i < countTypeGroups.length; i++) {
                if (countTypeGroups[i].getTypeID() === typeId) {
                    return countTypeGroups[i]
                }
            }
        }
        return null;
    }
    handleCountTypeGrpChange(countTypeGrp) {
        this.setState({
            countTypeGrp: countTypeGrp,
        });
    }
    handleCountTypeChange(countType, nArrPasse) {
        this.setState({ selCountType: countType});
        let arrPasse = nArrPasse;
        if (arrPasse === null && this.refArrPasse != null && this.refArrPasse.current != null) {
            arrPasse = this.refArrPasse.current.getValue();
        }
        if (this.props.onAfterChange) {
            this.props.onAfterChange(countType, arrPasse)
        }
    }
    filterCountTypes() {
        if (this.state.countTypeGrp) {
            return this.state.countTypeStore.countTypes.filter(countType => {
                return countType.getTypeID() == this.state.countTypeGrp.getTypeID();
            })
        } else {
            return this.state.countTypeStore.countTypes;
        }

    }
    render() {
        const {countType, arrowPasse} = this.props;
        if (this.state.countTypeStore.countTypeGroups == null || this.state.countTypeStore.countTypes == null) {
            return <Loading />
        } else {
            return <ErrorBoundary>
            <p>
                <DropDownParseObject block default={this.state.countTypeGrp}
                                     onAfterChange={this.handleCountTypeGrpChange}
                                     objectList={this.state.countTypeStore.countTypeGroups}/>

                <DropDownParseObject default={countType}
                                     placeholder={<option key={"nullkey"}
                                                          value={"null"}>{messages.get("modal.license.chooseCountType")}</option>}
                                     onAfterChange={(value) => this.handleCountTypeChange(value, null)}
                                     objectList={this.filterCountTypes()}/>
                {
                    this.state.selCountType ?
                        <CountTypePointsInfo countType={this.state.selCountType}/> : null
                }
                {
                    this.state.countTypeGrp && this.state.countTypeGrp.isTypeDisc() ?
                        <ValidInput ref={this.refArrPasse}
                                    onAfterChange={value => this.handleCountTypeChange(this.state.selCountType, parseInt(value))}
                                    valid={{maxLength: 5, check:['isNumber']}}
                                    default={arrowPasse ? arrowPasse : 3}
                                    label={messages.get("tournament.table.column.arrPasse")}/>
                        : null
                }

            </p></ErrorBoundary>
        }
    }
}
CountTypeSelection.propTypes = {
    onAfterChange:PropTypes.func.isRequired,
    countType: PropTypes.object,
    arrowPasse: PropTypes.number
};
const CountTypePointsInfo = ({countType}) => {
    let arrows = [];
    let textToAppend = "";
    let arrowIdx = 0;
    countType.getCountTypeDtls().map(countTypeDtl => {
        const tArrow = parseInt(countTypeDtl.getArrowNr());
        if (arrowIdx != tArrow) {
            if (textToAppend.length > 0) {
                arrows.push(textToAppend);
                textToAppend = "";
            }
            textToAppend += messages.get("ResultStatBean_arrow") + " " + tArrow + ": ";
            arrowIdx = tArrow;
        }
        textToAppend += countTypeDtl.getPoints() + "/";
    });
    arrows.push(textToAppend);
    return (<ul>
        {
            arrows.map(arrow => {
                return <li>{arrow}</li>
            })
        }
    </ul>)
};
CountTypePointsInfo.propTypes = {
    countType: PropTypes.object.isRequired
};
module.exports = CountTypeSelection;
