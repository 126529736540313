const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");
var ListLayout = {
    Wrapper: ({children}) =>  {
        return (
            <Row>
                {children}
            </Row>
        )
    },
    Filter: ({children}) =>  {
        return (
            <Col xs={12} lg={4} className="pull-right">
                {children}
                <hr className="hidden-lg"/>
            </Col>
        )
    },
    List: ({children}) =>  {
        return (
            <Col xs={12} lg={8} className="pull-left">
                {children}
            </Col>
        )

    }
};

module.exports = ListLayout;
