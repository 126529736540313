const alt = require("AltInit");

class TableActions {

    setSearching(searching) {
        return searching;
    }
    searchObject(searchParams) {
        return searchParams;
    }
    addObject(object) {
        return object;
    }
    updateObject(object) {
        return object
    }
    finishSearch(objectList, searchParams) {
        searchParams.searching = false;
        return {objectList: objectList, sParams: searchParams};
    }
    updateObjectList(objectList) {
        return objectList;
    }

    updateSelectedObjectList(objectList) {
        return objectList;
    }
    resetSelectedObjectList() {
        this.updateSelectedObjectList([]);
    }

    updateSearchField(type) {
        return type;
    }

    updateSearchParams(params) {
        return params;
    }

    updateSortParams(sortKey, direction) {
        return {sortKey: sortKey, direction:direction};
    }

    updateTablePage(pageOffset) {
        return pageOffset;
    }

    selectTablePage(pageNumber) {
        return pageNumber;
    }

    updateTablePageSize(pageSize) {
        return pageSize;
    }
}

module.exports = alt.createActions(TableActions);
