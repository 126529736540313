const Parse = require("ParseInit");
const Utility = require("util/Utility");
const EventTraining = Parse.Object.extend("EventTraining", {
    col: {
        description: "description",
        userID: "userID",
        date: "date",
        arrows: "arrowCount",
        duration: "duration",
        countTypeID: "countTypeID",
        countTypeOnlineID: "countTypeOnlineID",
        sumPoints: "sumPoints",
        status: "status",
        weblink: "weblink",
        mainTrainingType: "mainTrainingType",
        subTrainingType: "subTrainingType",
        createdAt: "createdAt",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    getCalName() {
        const subType = this.getSubTrainingType();
        if (subType != null) {
            return subType.getName();
        }
        return "-";
    },
    getEventID() {
        return null
    },
    getTournamentUserID() {
        return null
    },
    getEventResults() {
        // needed for handling in Tab Scatter
        return [];
    },
    getCountTypeOnlineID() {
        return null
    },
    isTrainingParcours() {
        return this.getMainTrainingType().isTypParcours()
    },
    isTrainingTournament() {
        return this.getMainTrainingType().isTypTournament()
    },
    getCalLink() {
        return this.getWeblink()
    },
    getCalPlace() {
        return null;
    },
    getCalAdditional() {
        const mainType = this.getMainTrainingType();
        if (mainType != null && mainType.showDetails()) {
            return "(" + this.getArrows() + ", " + this.getDuration() + " min)"
        }
        return null
    },
    getCalDescription() {
        return this.getDescription()
    },
    getCalStartDate() {
        return this.getDate()
    },
    getCalEndDate() {
        return this.getDate()
    },
    getCalBackground() {
        const mainType = this.getMainTrainingType();
        if (mainType != null) {
            return mainType.getColor();
        }
        return "transparent";
    },
    getCalcAllowEdit() {
        return this.getStatus() === this.status.active
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    getDate() {
        return this.get(this.col.date)
    },
    setDate(date) {
        return this.set(this.col.date, date)
    },
    getReadableDate() {
        let tDate = this.getDate();
        if (tDate != null) {
            return Utility.getReadableDate(tDate);
        }
        return "";
    },
    getArrows() {
        const val = this.get(this.col.arrows);
        if (val) {
            return val
        }
        return 0
    },
    setArrows(arrowAmount) {
        return this.set(this.col.arrows, arrowAmount)
    },
    getShootCounter() {
        return this.getArrows()
    },
    getDuration() {
        const val = this.get(this.col.duration);
        if (val) {
            return val
        }
        return 0
    },
    setDuration(duration) {
        return this.set(this.col.duration, duration)
    },
    getCountTypeID() {
        return this.get(this.col.countTypeID)
    },
    setCountTypeID(countTypeID) {
        return this.set(this.col.countTypeID, countTypeID)
    },
    getCountTypeOnlineID() {
        return this.get(this.col.countTypeOnlineID)
    },
    setCountTypeOnlineID(countTypeOnlineID) {
        return this.set(this.col.countTypeOnlineID, countTypeOnlineID)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getSumPoints() {
        return this.get(this.col.sumPoints)
    },
    setSumPoints(sumPoints) {
        return this.set(this.col.sumPoints, sumPoints)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getMainTrainingType() {
        return this.get(this.col.mainTrainingType)
    },
    setMainTrainingType(type) {
        return this.set(this.col.mainTrainingType, type)
    },
    getSubTrainingType() {
        return this.get(this.col.subTrainingType)
    },
    setSubTrainingType(type) {
        return this.set(this.col.subTrainingType, type)
    }
});

module.exports = EventTraining;
