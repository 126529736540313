var Parse = require("ParseInit");

const TournamentUserGroupResult = Parse.Object.extend("TournamentUserGroupResult", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        tournamentID: "tournamentID",
        tournamentRoundID : "tournamentRoundID",
        tournamentGroupID: "tournamentGroupID",
        userID: "userID",
        status: "status",
        actResult: "actResults",
        changeLogs: "changeLogs",
        compareErrors: "compareErrors",
        updatedAt: "updatedAt"
    },
    status: {
        present: "A",
        started: "S",
        finished: "F",
        deleted: "D"
    },
    getTournamentUserPoints(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["points"];
            }
        }
        return 0;
    },
    getTournamentUserKillValue(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killValue"];
            }
        }
        return 0;
    },
    getTournamentUserKillCounts(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killCounts"];
            }
        }
        return "";
    },
    isStarted() {
        return this.getStatus() === this.status.started;
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    getTournamentGroupID() {
        return this.get(this.col.tournamentGroupID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    getActResult() {
        return this.get(this.col.actResult)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getChangeLogs() {
        return this.get(this.col.changeLogs)
    },
    getCompareErrors() {
        return this.get(this.col.compareErrors)
    }

});

module.exports = TournamentUserGroupResult;
