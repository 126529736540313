const React = require("react"),
    PropTypes = require('prop-types');
const Utility = require("util/Utility"),
    ValidInput = require("components/form/ValidInput");
const TrainingFilterEntity = require("components/training/TrainingFilterEntity");

const TrainingFilterNumberInput = ({filterState, stateKey, text, disabled, updateSearchParams}) => {
    function onChange(text) {
        let selectedList = Utility.stringCSV2NumberArray(text)
        updateSearchParams(stateKey, selectedList)
    }
    return <TrainingFilterEntity filterState={filterState} stateKey={stateKey} text={text} disabled={disabled}
                                 needReloadEvents={false} updateSearchParams={updateSearchParams}>
        <ValidInput label={null} default={filterState != null ? filterState.join(",") : null} onAfterChange={onChange} placeholder="1,4,8,9"/>
    </TrainingFilterEntity>
}
TrainingFilterNumberInput.propTypes = {
    filterState: PropTypes.array,
    stateKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
}
module.exports = TrainingFilterNumberInput;
