/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    CheckboxInput = require("components/form/CheckboxInput"),
    RadioGroupInput = require("components/form/RadioGroupInput");
const messages = require("i18n/messages");

const ClubUtility = require("components/club/ClubUtility");

const clubActions = require("actions/ClubActions");

class ClubMessageModal extends React.Component {
    constructor(props) {
        super(props);
        this.refMessage = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.clickChangeType = this.clickChangeType.bind(this);
        this.clickChangeGroup = this.clickChangeGroup.bind(this);
        this.hasGroup = this.hasGroup.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            msgType: "msg.type.mail",
            groups: null,
            error: null
        }
    }
    open() {
        this.setState({
            showModal: true
        });
    }
    close() {
        this.setState({submitDisabled: false,showModal: false, error:null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    clickChangeType(value) {
        this.setState({msgType: value});
    }
    clickChangeGroup(group, value) {
        console.log("Changed " + group + " to " + value);
        let groups = this.state.groups;
        if (groups == null) {
            groups = [];
        }
        if (value == true) {
            groups.push(group);
        } else {
            groups = groups.filter(u => {
                return u != group;
            })
        }
        this.setState({groups: groups});
    }
    hasGroup(group) {
        let groupList = this.state.groups;
        if (groupList != null) {
            return groupList.indexOf(group) > -1
        }
        return false;
    }
    valid() {
        let valid = true;
        let required = [
            this.refMessage.current.getValue().trim()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        let message = this.refMessage.current.getValue().trim();
        let mailAddresses = ClubUtility.getEmailListForGroups(this.props.clubUsers, this.state.groups, this.state.msgType == "msg.type.mail");
        if (this.state.msgType == "msg.type.mail") {
            if (mailAddresses != null && mailAddresses.length > 0) {
                clubActions.sentEmailMessage(this.props.club, mailAddresses, message);
                this.close();
            } else {
                this.setState({error: messages.get("modal.message.notarget")});
            }

        } else {
            if (mailAddresses == null) {
                // no limitation sent to whole club
                clubActions.sentPushMessage(this.props.club, null, message);
                this.close();
            } else if (mailAddresses.length > 0){
                clubActions.sentPushMessage(this.props.club, mailAddresses, message);
                this.close();
            } else {
                this.setState({error: messages.get("modal.message.notarget")});
            }

        }
    }
    render() {
        const {showModal, error, cuMails, msgType, submitDisabled} = this.state;
        const {club} = this.props;
        const groups = club.getFilterGroups();
        let maxMsg = 2000;
        if (msgType == "msg.type.notify") {
            maxMsg = 150;
        }
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code="club.button.newMessage"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    <Col sm={5}>
                                        <p><I18n code={"modal.message.type"}/></p>
                                        <RadioGroupInput default={msgType}
                                                         list={["msg.type.mail", "msg.type.notify"]}
                                                         onAfterChange={this.clickChangeType}/>
                                    </Col>
                                    {
                                        groups != null && groups.length > 0 ?
                                            <Col sm={7}>
                                                <p><I18n code={"club.event.create.visibility.group.desc"}/></p>
                                                {
                                                    groups.map(group => {
                                                        return <GroupEntry key={"EDT" + group} group={group}
                                                                           defaultValue={this.hasGroup(group)}
                                                                           onChange={this.clickChangeGroup}/>
                                                    })
                                                }
                                            </Col>
                                            : null
                                    }
                                    <Col xs={12}>

                                        <ValidInput ref={this.refMessage}
                                                    componentClass="textarea"
                                                    rows={5}
                                                    valid={{maxLength: maxMsg, check:['isRequired']}}
                                                    placeholder={messages.get("modal.message.placeholder.message") + " (" + maxMsg + ")"}/>
                                        {error == null ? null : <Alert bsStyle="danger">{error}</Alert>}
                                    </Col>
                                    {
                                        cuMails == null ?
                                            <Col className="text-center" xs={12}>
                                                <Button onClick={this.clickedSave} disabled={submitDisabled || !club.isEmailVerified()} block bsStyle="success"><I18n code={"contact.form.send"}/></Button>
                                            </Col> : null
                                    }
                                </Row>

                            </form> : null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
ClubMessageModal.propTypes = {
    club:PropTypes.object.isRequired,
    clubUsers:PropTypes.array.isRequired
};
const GroupEntry = ({defaultValue, group, onChange}) => {
    function onAfterChange(value) {
        onChange(group, value)
    }
    return (
        <CheckboxInput default={defaultValue} onAfterChange={onAfterChange} message={group} />);
};
GroupEntry.propTypes = {
    defaultValue:PropTypes.bool.isRequired,
    onChange:PropTypes.func.isRequired,
    group:PropTypes.string.isRequired
};
module.exports = ClubMessageModal;