const Parse = require("ParseInit");

const Tournament = Parse.Object.extend("Tournament", {
    registrationStatus: {
        published: "A",
        open: "R",
        closed: "C",
        full: "F",
        activatedCupMaster: "S"
    },
    status: {
        published: "A",
        running: "S",
        closed: "C",
        deleted: "D",
        canceled: "X"
    },
    regTypes: {
        single : "S",
        pair : "P"
    },
    col: {
        addEditor: "addEditor",
        allowManualPoints: "allowManualPoints",
        bannerImageID: "bannerImageID",
        bowUnionID: "bowUnionID",
        createdAt: "createdAt",
        creator: "creator",
        cupMasterTournamentID: "cupMasterTournamentID",
        cupGroup: "cupGroup",
        date: "date",
        description: "description",
        duration: "duration",
        endDate: "endDate",
        extRefID: "extRefID",
        groupPlaceID: "groupPlaceID",
        imageID: "imageID",
        mainEditor: "mainEditor",
        mainGroupPlaceID: "mainGroupPlaceID",
        noTUserGroups: "noTUserGroups",
        chooseTUserGrp: "chooseTUserGrp",
        linkSecret: "linksecret",
        managementID: "managementID",
        manageStatus: "manageStatus",
        sponsorImageID:"sponsorImageID",
        name: "name",
        objectId: "objectId",
        parcoursID: "parcoursID",
        place: "place",
        regType: "regType",
        regEndDate: "regEndDate",
        regMaxUser: "regMaxUser",
        regStartDate: "regStartDate",
        regStatus: "regStatus",
        resultImageID: "resultImageID",
        status: "status",
        subTournamentIDs: "subTournamentIDs",
        showTUGroups: "showTUGroups",
        fixTUInGroup: "fixTUInGroup",
        selectableTRounds: "selTURounds",
        tournamentConfigAge: "tournamentConfigAge",
        tournamentConfigBow: "tournamentConfigBow",
        tournamentPriceID: "tournamentPriceID",
        tournamentOptionID: "tournamentOptionID",
        tournamentCupOptionID: "tournamentCupOptionID",
        type: "type",
        union: "union",
        unionLink: "unionLink",
        updatedAt: "updatedAt",
        weblink: "weblink",
        zip: "zip"
    },
    // search
    getLabels() {
        return [this.getName(), this.getZipPlace()].join(" ");
    },
    getDatalistText() {
        return this.getName() + ", " +  this.getZipPlace();
    },
    getDatalistImage() {
        return null;
    },
    getDatalistDate() {
        return this.getDate();
    },
    //
    // custom
    //
    showResults() {
        return this.getMainEditor() && (this.isClosed() || this.getStatus() === this.status.running);
    },
    showTournamentAttendees() {
        if (this.getStatus() === this.status.published) {
            if (this.getRegistrationStatus() == this.registrationStatus.open || this.getRegistrationStatus() == this.registrationStatus.closed
                || this.getRegistrationStatus() == this.registrationStatus.full) {
                // show list if published tournament with open or already closed registration
                return true;
            }
        } 
        return false;
    },
    showTUGroupButton() {
        return (this.isShowTUGroups() || this.getNoTuserGroups()) && (this.isRunning() || this.isPublished())
    },
    isClosed() {
        return this.getStatus() === this.status.closed || this.getStatus() === this.status.deleted;
    },
    isPublished() {
        return this.getStatus() === this.status.published;
    },
    hasInitialRegistration() {
        return this.getRegistrationStatus() != this.registrationStatus.published
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isCanceled() {
        return this.status.canceled === this.getStatus();
    },
    isRunning() {
        return this.getMainEditor() && this.getStatus() === this.status.running;
    },
    isManaged() {
        return this.getMainEditor() != null;
    },
    isTypePairRegistration() {
        return this.getRegistrationType() === this.regTypes.pair;
    },
    isTypeSingle() {
        if (this.getBowUnionID()) {
            return !this.getBowUnionID().isTypeCup();
        }
        return true;
    },
    isTypeCupChild() {
        if (this.getBowUnionID()) {
            return this.getBowUnionID().isTypeCup() && this.getCupMasterTournamentID() != null;
        }
        return false;
    },
    isTypeCupMaster() {
        if (this.getBowUnionID()) {
            return this.getBowUnionID().isTypeCup() && this.getCupMasterTournamentID() == null;
        }
        return false;
    },
    hasManagementID() {
        return this.get(this.col.manageStatus) === "A";
    },
    hasAnyTournamentFeature() {
        let management = this.getManagementID();
        if (management) {
            return management.hasTournamentFeature() || management.hasCupTrophyFeature();
        }
        return false;
    },
    hasPaymentFeature() {
        let management = this.getManagementID();
        if (management) {
            return management.hasPaymentFeature();
        }
        return false;
    },
    isLicenseMandatory() {
        return  this.getBowUnionID() && this.getBowUnionID().isLicenseNumberMandatory();
    },
    getManagementID() {
        return this.get(this.col.managementID);
    },
    setManagementID(managementID) {
        this.set(this.col.managementID, managementID);
        this.set(this.col.manageStatus, "A")
    },
    unsetManagementID() {
        this.unset(this.col.managementID);
        this.unset(this.col.manageStatus);
    },
    getBannerImageUrl() {
        var bannerImageID = this.get(this.col.bannerImageID);
        if (bannerImageID) {
            return bannerImageID.get("image").url();
        }
        return "";
    },
    getSponsorImageID() {
        return this.get(this.col.sponsorImageID);
    },
    getImageUrlFromColumn(colName) {
        var imageID = this.get(colName);
        if (imageID) {
            return imageID.getImageUrl();
        }
        return "";
    },
    isOnlinePaymentEnabled() {
        var tPrice = this.getTournamentPriceID();
        if (tPrice) {
            return tPrice.isOnlinePaymentEnabled();
        }
        return false;
    },
    getTournamentDate() {
        let result = "";
        var startDate = this.getDate();
        result = startDate.getDate() + "." + (startDate.getMonth() + 1) ;
        var endDate = this.getEndDate();
        if (endDate && this.getDuration() > 1) {
            result += " - " + endDate.getDate() + "." + (endDate.getMonth() + 1) + "." + endDate.getFullYear();
        } else {
            result += "." + startDate.getFullYear();
        }
        return result;
    },
    isTodayInTournamentDateRange() {
        // return true if today is between start and end date of tournament
        const today = new Date();
        const start = this.getDate();
        const end = this.getEndDate();
        return today >= start && today <= end;
    },
    isPay4Start() {
        return (this.getTournamentPriceID() !== null && this.getTournamentPriceID().getPay4Start());
    },
    isRegistrationPublished() {
        return this.getRegistrationStatus() === this.registrationStatus.published;
    },
    isRegistrationClosed() {
        return this.getRegistrationStatus() === this.registrationStatus.closed;
    },
    isRegistrationOpened() {
    	if (this.getStatus() === this.status.published || this.getStatus() === this.status.running) {
    		if (this.getRegistrationStatus() == this.registrationStatus.open) {
    			return true;
    		}
    	}
    	return false;
    },
    isRegistrationFull() {
        if (this.getStatus() === this.status.published || this.getStatus() === this.status.running) {
            if (this.getRegistrationStatus() == this.registrationStatus.full) {
                return true;
            }
        }
        return false;
    },
    getLinkSecret() {
        return this.get(this.col.linkSecret)
    },
    checkSecret(secret) {
        let evSecret = this.getLinkSecret();
        if (evSecret != null && evSecret.length > 0) {
            // need secret
            if (secret != null && secret.length > 0) {
                return evSecret === secret;
            }
            return false;
        }
        // no secret at all
        return true;
    },
    isEditor(user) {
        let result = false;
        if (this.getMainEditor() && user) {
            if (user.id == this.getMainEditor().id) {
                result = true;
            } else {
                var addEditors = this.getAddEditor();
                if (addEditors) {
                    addEditors.map(editor =>{
                        if (user.id == editor.id) {
                            result = true;
                        }
                    });
                }
            }
        }
        return result;
    },
    //
    // getter/setter
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAddEditor() {
        return this.get(this.col.addEditor)
    },
    setAddEditor(addEditor) {
        return this.set(this.col.addEditor, addEditor)
    },
    getAllowManualPoints() {
        return this.get(this.col.allowManualPoints)
    },
    setAllowManualPoints(allowManualPoints) {
        return this.set(this.col.allowManualPoints, allowManualPoints)
    },
    getBannerImageID() {
        return this.get(this.col.bannerImageID)
    },
    setBannerImageID(bannerImageID) {
        return this.set(this.col.bannerImageID, bannerImageID)
    },
    getBowUnionID() {
        return this.get(this.col.bowUnionID)
    },
    setBowUnionID(bowUnionID) {
        return this.set(this.col.bowUnionID, bowUnionID)
    },
    getCupMasterTournamentID() {
        return this.get(this.col.cupMasterTournamentID)
    },
    setCupMasterTournamentID(tournamentID) {
        return this.set(this.col.cupMasterTournamentID, tournamentID)
    },
    getCupGroup() {
        return this.get(this.col.cupGroup)
    },
    setCupGroup(cupGroup) {
        return this.set(this.col.cupGroup, cupGroup)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    getDate() {
        return this.get(this.col.date)
    },
    setDate(date) {
        return this.set(this.col.date, date)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getDuration() {
        return this.get(this.col.duration)
    },
    setDuration(duration) {
        return this.set(this.col.duration, duration)
    },
    getEndDate() {
        return this.get(this.col.endDate)
    },
    setEndDate(endDate) {
        return this.set(this.col.endDate, endDate)
    },
    getExtRefID() {
        return this.get(this.col.extRefID)
    },
    setExtRefID(extRefID) {
        return this.set(this.col.extRefID, extRefID)
    },
    getGroupPlaceID() {
        return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID(groupPlaceID) {
        return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getImageID() {
        return this.get(this.col.imageID)
    },
    setImageID(imageID) {
        return this.set(this.col.imageID, imageID)
    },
    getMainEditor() {
        return this.get(this.col.mainEditor)
    },
    setMainEditor(mainEditor) {
        return this.set(this.col.mainEditor, mainEditor)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getNoTuserGroups() {
        return this.get(this.col.noTUserGroups) === true
    },
    setNoTuserGroups(enable) {
        return this.set(this.col.noTUserGroups, enable)
    },
    getChooseTUserGroup() {
        return this.get(this.col.chooseTUserGrp)
    },
    setChooseTUserGroup(enable) {
        return this.set(this.col.chooseTUserGrp, enable)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getParcoursID() {
        return this.get(this.col.parcoursID)
    },
    setParcoursID(parcoursID) {
        return this.set(this.col.parcoursID, parcoursID)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
        return this.set(this.col.place, place)
    },
    getRegEndDate() {
        return this.get(this.col.regEndDate)
    },
    setRegEndDate(regEndDate) {
        return this.set(this.col.regEndDate, regEndDate)
    },
    getRegMaxUser() {
        return this.get(this.col.regMaxUser)
    },
    setRegMaxUser(regMaxUser) {
        return this.set(this.col.regMaxUser, regMaxUser)
    },
    getRegStartDate() {
        return this.get(this.col.regStartDate)
    },
    setRegStartDate(regStartDate) {
        return this.set(this.col.regStartDate, regStartDate)
    },
    getRegistrationStatus() {
        return this.get(this.col.regStatus)
    },
    setRegistrationStatus(regStatus) {
        return this.set(this.col.regStatus, regStatus)
    },
    getResultImageID() {
        return this.get(this.col.resultImageID)
    },
    setResultImageID(resultImageID) {
        return this.set(this.col.resultImageID, resultImageID)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    isShowTUGroups() {
        return this.get(this.col.showTUGroups);
    },
    setShowTUGroups(showTUGroups) {
        return this.set(this.col.showTUGroups, showTUGroups);
    },
    isFixedTUInGroup() {
        return this.get(this.col.fixTUInGroup);
    },
    setFixedTUInGroup(fixTUInGroup) {
        return this.set(this.col.fixTUInGroup, fixTUInGroup);
    },
    isSelectableTRoundsForGroup() {
        return this.get(this.col.selectableTRounds);
    },
    setSelectableTRoundsForGroup(selectable) {
        return this.set(this.col.selectableTRounds, selectable);
    },
    getSubTournamentIDs() {
        return this.get(this.col.subTournamentIDs)
    },
    setSubTournamentIDs(subTournamentIDs) {
        return this.set(this.col.subTournamentIDs, subTournamentIDs)
    },
    getTargetAmounts() {
        return this.get(this.col.targetAmounts)
    },
    setTargetAmounts(targetAmounts) {
        return this.set(this.col.targetAmounts, targetAmounts)
    },
    getTournamentConfigAge() {
        const config = this.get(this.col.tournamentConfigAge);
        if (config) {
            return config.filter(
                function (tournamentConfig) {
                    if (tournamentConfig != null) {
                        return !tournamentConfig.isDeleted();
                    }
                    return false;
                });
        }
        return null;
    },
    setTournamentConfigAge(tournamentConfigAge) {
        return this.set(this.col.tournamentConfigAge, tournamentConfigAge)
    },
    findFittingTournamentConfigAge(tUser) {
        let configAgeTypes = this.getTournamentConfigAge();
        if (configAgeTypes != null && tUser.getTournamentConfigAge() != null) {
            for (let ca = 0; ca < configAgeTypes.length; ca++) {
                if (configAgeTypes[ca].getTypeID() === tUser.getTournamentConfigAge().getTypeID()) {
                    return configAgeTypes[ca];
                }
            }
        }
        return null;
    },
    hasTournamentConfigAgeWithPrices() {
        let configAgeTypes = this.getTournamentConfigAge();
        if (configAgeTypes != null && configAgeTypes.length > 0) {
            for (let ca = 0; ca < configAgeTypes.length; ca++) {
                // find entry with price
                if (configAgeTypes[ca].getPrice() > 0) {
                    return true;
                }
            }
            // no entry with price found
            return false;
        }
        return true;
    },
    getTournamentConfigBow() {
        const config = this.get(this.col.tournamentConfigBow);
        if (config) {
            return config.filter(
                function(tournamentConfig) {
                    if (tournamentConfig != null) {
                        return !tournamentConfig.isDeleted();
                    }
                    return false;
            });
        }
        return null;
    },
    setTournamentConfigBow(tournamentConfigBow) {
        return this.set(this.col.tournamentConfigBow, tournamentConfigBow)
    },
    findFittingTournamentConfigBow(tUser) {
        let configBowTypes = this.getTournamentConfigBow();
        if (configBowTypes != null && tUser.getTournamentConfigBow() != null) {
            for (let cb = 0; cb < configBowTypes.length; cb++) {
                if (configBowTypes[cb].getTypeID() ===  tUser.getTournamentConfigBow().getTypeID()) {
                    return configBowTypes[cb];
                }
            }
        }
        return null;
    },
    getTournamentPriceID() {
        return this.get(this.col.tournamentPriceID)
    },
    setTournamentPriceID(tournamentPriceID) {
        return this.set(this.col.tournamentPriceID, tournamentPriceID)
    },
    getTournamentOptionID() {
        return this.get(this.col.tournamentOptionID)
    },
    setTournamentOptionID(tournamentOptionID) {
        return this.set(this.col.tournamentOptionID, tournamentOptionID)
    },
    getTournamentCupOptionID() {
        return this.get(this.col.tournamentCupOptionID)
    },
    setTournamentCupOptionID(tournamentCupOptionID) {
        return this.set(this.col.tournamentCupOptionID, tournamentCupOptionID)
    },
    getType() {
        return this.get(this.col.type)
    },
    setType(type) {
        return this.set(this.col.type, type)
    },
    getRegistrationType() {
        return this.get(this.col.regType)
    },
    setRegistrationType(regType) {
        return this.set(this.col.regType, regType)
    },
    getUnion() {
        return this.get(this.col.union)
    },
    setUnion(union) {
        return this.set(this.col.union, union)
    },
    getUnionLink() {
        return this.get(this.col.unionLink)
    },
    setUnionLink(unionLink) {
        return this.set(this.col.unionLink, unionLink)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getWeblink() {
        return this.get(this.col.weblink)
    },
    setWeblink(weblink) {
        return this.set(this.col.weblink, weblink)
    },
    getZip() {
        return this.get(this.col.zip)
    },
    setZip(zip) {
        return this.set(this.col.zip, zip)
    },
    getZipPlace() {
        return this.getZip() + " " + this.getPlace();
    }
});

module.exports = Tournament;
