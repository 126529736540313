const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Panel, Button, ButtonGroup} = require("react-bootstrap");
const Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    TooltipButton  = require("components/form/TooltipButton"),
    EventRankingVisibility  = require("components/profile/EventRankingVisibility"),
    BowTypeIcon = require("components/widgets/BowTypeIcon"),
    EventPlayerBAProfileChange = require("components/profile/EventPlayerBAProfileChange"),
    EventPlayersTable = require("components/profile/EventPlayersTable");

const EventDetailLink = require("components/links/EventDetailLink");
const {Event, EventPlayer} = require("parse/_Domain");

const eventActions = require("actions/EventActions");
const messages = require("i18n/messages");

const EventPlayerList = ({eventPlayers, user, adminPage}) => {
    let month = 0;
    return (
        <Row>
            {eventPlayers.map(eventPlayer => {
                if (eventPlayer.getEventID()) {
                    const date = new Date(eventPlayer.getEventID().getInsstmp());
                    if (month != (date.getMonth() + 1)) {
                        month = date.getMonth() + 1;
                        return (
                            <React.Fragment key={"div" + eventPlayer.id}>
                                <Col xs={12}>
                                    <h3>{messages.get("montPager.month." + month) + " " + date.getFullYear()}</h3><br/></Col>
                                <EventListEntry key={eventPlayer.id} eventPlayer={eventPlayer} adminPage={adminPage}/>
                            </React.Fragment>);
                    } else {
                        return (<EventListEntry key={eventPlayer.id} eventPlayer={eventPlayer} adminPage={adminPage} user={user}/>);
                    }
                 } else {
                    return <p>{"WTF - No Event for PlayerID: " + eventPlayer.id}</p>;
                }
            })}
        </Row>
    )
};
EventPlayerList.propTypes = {
    eventPlayers:PropTypes.array.isRequired,
    user: PropTypes.object,
    adminPage:PropTypes.bool
};
class EventListEntry extends React.Component {
    constructor(props) {
        super(props);
        this.updateEventPlayerDetail = this.updateEventPlayerDetail.bind(this);
        this.restoreEvent = this.restoreEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.hideDetails = this.hideDetails.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.saveEvent = this.saveEvent.bind(this);

        this.state = {
            loadingDetail: false,
            eventPlayers: {}
        }
    }
    updateEventPlayerDetail(list) {
        this.setState({
            eventPlayers: list,
            loadingDetail: false
        });
    }
    showDetails() {
        if (this.props.eventPlayer instanceof EventPlayer) {
            eventActions.queryEventPlayerDetail(this.props.eventPlayer.getEventID(), eventPlayers => {
                this.updateEventPlayerDetail(eventPlayers);
            });
        }

    }
    hideDetails() {
        this.updateEventPlayerDetail([]);
    }
    deleteEvent() {
        const event = this.props.eventPlayer.getEventID();
        eventActions.updateEventStatus(event, Event.prototype.status.deleted, () => {
            event.setStatus(Event.prototype.status.deleted);
            this.hideDetails();
        });
    }
    restoreEvent() {
        const event = this.props.eventPlayer.getEventID();
        eventActions.updateEventStatus(event, Event.prototype.status.closed, () => {
            event.setStatus(Event.prototype.status.closed);
            this.hideDetails();
        });
    }
    saveEvent(event) {
        console.log("save event",event)
        eventActions.saveEvent(event, (sEvent) => {
            this.hideDetails();
        })
    }
    saveEventPlayer(eventPlayer) {
        eventActions.saveEventPlayer(eventPlayer);
    }
    render() {
        const {eventPlayer, user, adminPage} = this.props
        const {loadingDetail, eventPlayers} = this.state
        const event = eventPlayer.getEventID();
        let resultText = "";
        let extendButton = null;
        if (loadingDetail === true) {
            resultText = <Loading/>;
        } else if (loadingDetail === false && eventPlayers.length > 0) {
            extendButton = <Button onClick={this.hideDetails}><FontAwesome icon="caret-up"/></Button>;
            resultText = (<EventPlayersTable eventPlayers={eventPlayers} adminPage={adminPage}/>);
        } else {
            extendButton =  <Button onClick={this.showDetails}><FontAwesome icon="caret-down"/></Button>;
            const sumPoints = eventPlayer.getSumPoints();
            if (sumPoints >= 0) {
                resultText = <MyResultText eventPlayer={eventPlayer} user={user} saveEventPlayer={this.saveEventPlayer}/>
            } else {
                if (!event.isDeleted() && (eventPlayer.getEventResults() == null || eventPlayer.getEventResults().length <= 0)) {
                    resultText = (<Alert bsStyle="warning">{messages.get("profile.events.noEventDetails")}</Alert>);
                }
            }
        }
        return (
            <Col md={6} xs={12}>
                <Panel>
                    <Panel.Heading>
                        <Row>
                            <Col md={8} xs={9}>
                                <h4><StrikeOutText strikeOut={event.isDeleted()}>{event.getDate() + " - " + event.getName()}</StrikeOutText></h4>
                                {adminPage === true ? <p>{event.id + " / " + event.getInsstmp()}</p>: null}
                            </Col>
                            <Col md={4} xs={3}>
                                <ButtonGroup className="pull-right-md">
                                    {
                                        event.isDeleted() ?
                                            <TooltipButton  icon="undo"
                                                            onClick={this.restoreEvent} tooltip={messages.get("profile.events.restore")} />
                                            :
                                            <TooltipButton  icon="trash"
                                                            onClick={this.deleteEvent} tooltip={messages.get("profile.events.delete")} />

                                    }
                                    <EventRankingVisibility event={event} saveEvent={this.saveEvent}/>
                                    {extendButton}
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Panel.Heading>
                    <Panel.Body>
                        {resultText}
                        <EventDetailLink eventID={event.id} isAdmin={adminPage}/>
                    </Panel.Body>
                </Panel>
            </Col>
        )
    }
}
EventListEntry.propTypes = {
    eventPlayer:PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    adminPage:PropTypes.bool
};
const MyResultText = ({eventPlayer, user, saveEventPlayer}) => {
    return <Row>
        <Col md={6} xs={12}>
            {messages.get("tournament.table.column.points")  + ": " + eventPlayer.getPointPercentDPSString()}
        </Col>
        {
            eventPlayer.getBAProfileID() != null ? <Col md={6} xs={12}>
                <span><BowTypeIcon bowTypeId={eventPlayer.getBowTypeId()} />{eventPlayer.getBAProfileID().getName()}</span>
            </Col>: null
        }
        {
            eventPlayer.getBAProfileID() == null && user != null ?
                <EventPlayerBAProfileChange user={user} eventPlayer={eventPlayer} onAfterChange={saveEventPlayer}/>: null
        }
    </Row>
}
module.exports = EventPlayerList;
