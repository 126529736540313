const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel, Alert, Button, ButtonGroup, ListGroupItem, ListGroup, Label} = require("react-bootstrap");
const { DragDropContext, Droppable, Draggable } = require("react-beautiful-dnd");
const {TournamentUserGroup} = require("parse/_Domain");

const GROUP_CHARS = require("components/tournamentmanage/TournamentUtility").GROUP_CHARS()
const POS_KEY_INDEX = require("components/tournamentmanage/TournamentUtility").POS_KEY_INDEX()

const LoadingCircle = require("components/widgets/LoadingCircle"),
    TournamentUserGroupStatusSelect = require("components/tournamentregister/TournamentUserGroupStatusSelect"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    TournamentUserGroupModal = require("components/tournamentmodal/TournamentUserGroupModal"),
    I18n = require("components/widgets/I18n"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    DelayedTextField = require("components/form/DelayedTextField"),
    FontAwesome = require("components/widgets/FontAwesome"),
    CheckboxInput= require("components/form/CheckboxInput"),
    ValidInput = require("components/form/ValidInput"),
    TooltipButton  = require("components/form/TooltipButton"),
    TooltipConfirmButton  = require("components/form/TooltipConfirmButton"),
    SortTableText = require("components/form/SortTableText"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const TournamentUserManagerStatus = require("components/tournamentmanage/TournamentUserManagerStatus");
const changeLogActions = require("actions/ChangeLogActions");
const tournamentManagerActions = require("actions/TournamentManagerActions"),
    tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    tournamentActions = require("actions/TournamentManagerActions")

const tableAction = require("actions/TableActions"),
    tableStore = require("stores/TableStore");

function getGroupFromArray(index, tournamentUserGroups) {
    if (index < tournamentUserGroups.length) {
        return tournamentUserGroups[index];
    }
    return null;
}

class TournamentUserGroupPanel extends React.Component {
    constructor(props) {
        super(props);
        this.addSelectedTUserToGroup = this.addSelectedTUserToGroup.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.state = {
            tableStore: tableStore.state,
        }
    }
    componentDidMount() {
        tableStore.listen(this.handleTableChange);
    }
    componentWillUnmount() {
        tableStore.unlisten(this.handleTableChange);
    }
    handleTableChange(state) {
        this.setState({tableStore: state});
    }
    addSelectedTUserToGroup(tUserGroup) {
        const {selectedObjectList} = this.state.tableStore;
        //console.log("Add " + selectedObjectList.length +" TUsers to group " + tUserGroup.getName());
        tournamentUserManagerActions.addTUserToTournamentGroup(tUserGroup, selectedObjectList);
        tableAction.resetSelectedObjectList()
    }
    render() {
        const {tournament, tournamentUserStore, groupingMode, singleStartedTRound, runningTRounds, user} = this.props;
        const {tournamentUserGroupStatus, tournamentUserGroups, filteredTournamentUserGroups} = tournamentUserStore
        const selectableTRounds = tournament.isSelectableTRoundsForGroup() ? this.props.tournamentRoundsAll : []
        const pnlStyle = groupingMode === true ? {maxHeight: "1200px", overflow: "auto"} :  {};
        const colStyle = groupingMode === true ? {paddingLeft: "0"} :  {};
        const tUserGroupAmount = filteredTournamentUserGroups.length + "/" + tournamentUserGroups.length
        return <Col xs={12} md={groupingMode ? 4 : 12} style={colStyle}>
                <Panel style={pnlStyle}>
                    <Panel.Heading>
                        <TopPanelArea tournament={tournament} tournamentUserStore={tournamentUserStore} user={user}
                                      sortParams={tournamentUserStore.tuGroupSortParams}
                                      runningTRounds={runningTRounds} singleStartedTRound={singleStartedTRound}
                                      selectedGroupStatus={tournamentUserGroupStatus} groupingMode={groupingMode}
                                      tUserGroupAmount={tUserGroupAmount} />
                    </Panel.Heading>
                    <Panel.Body>
                        <TournamentGroupPanelBody tournamentUserGroups={filteredTournamentUserGroups}
                                                  selectableTRounds={selectableTRounds}
                                                  runningTRounds={runningTRounds}
                                                  singleStartedTRound={singleStartedTRound}
                                                  groupChangeId={tournamentUserStore.groupChangeId}
                                                  allowGroupCreation={tournament.getNoTuserGroups()}
                                                  add2Group={this.addSelectedTUserToGroup} groupingMode={groupingMode} />
                    </Panel.Body>
                </Panel>
            </Col>
    }
}
TournamentUserGroupPanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    tournamentRoundsAll: PropTypes.array,
    singleStartedTRound :PropTypes.object,
    user: PropTypes.object.isRequired,
    groupingMode: PropTypes.bool.isRequired
};
const TopPanelArea = ({tournament, user, tournamentUserStore, tUserGroupAmount, groupingMode, singleStartedTRound,
                          runningTRounds, selectedGroupStatus, sortParams}) => {
    async function handleStartingGroups(tRound, filterForRound) {
        //console.log("Start groups for " + tRound.getName() + " with filter:", filterForRound)
        let startingGroups = [];
        if (filterForRound) {
            for (let i = 0; i < tournamentUserStore.tournamentUserGroups.length; i++) {
                const tUserGroup = tournamentUserStore.tournamentUserGroups[i]
                if (tUserGroup.isInThisRound(tRound) && tUserGroup.isPresent()
                    // check for tUsers Result on Group
                    && await tournamentUserManagerActions.validateTournamentGroupUsersWithTRound(tUserGroup, tRound)) {
                    // group is okay - allow to start it
                    startingGroups.push(tUserGroup)
                }
            }
        } else {
            startingGroups = tournamentUserStore.tournamentUserGroups.filter(tUserGroup => {
                return tUserGroup.isPresent()
            })
        }
        // add tournament for reload all groups
        tournamentUserManagerActions.startTournamentGroups(tournament, startingGroups, tRound)
    }
    function handlePauseGroups(tRound) {
        let pauseGroups = tournamentUserStore.tournamentUserGroups.filter(tUserGroup => {
            return tUserGroup.isInThisRound(tRound)
        })
        tournamentUserManagerActions.pauseTournamentGroupsForRound(pauseGroups)
    }
    function reloadTUGroups() {
        tournamentUserManagerActions.loadTournamentUserGroups(tournament, tournamentUserStore.hideDeleted);
    }
    function setShowTuGroups(show) {
        tournament.setShowTUGroups(show);
        tournamentActions.saveTournament(tournament, null, null);
    }
    function setFixedTUInGroup(fixedInGroup) {
        tournament.setFixedTUInGroup(fixedInGroup);
        tournamentActions.saveTournament(tournament, null, null);
        if (fixedInGroup) {
            tournamentUserManagerActions.updateFixedTournamentUserIDObject(tournamentUserStore.tournamentUserGroups)
        } else {
            tournamentUserManagerActions.unsetFixedTournamentUserIDObject(tournamentUserStore.tournamentUserGroups)
        }
    }
    function setSelectableTRounds(selectable) {
        tournament.setSelectableTRoundsForGroup(selectable)
        tournamentActions.saveTournament(tournament, null, null);
    }
    function handleTableSort(sortKey, direction) {
        tournamentUserManagerActions.updateTUGroupSortParams(sortKey, direction)
    }
    function changeTuGroupStatus(status) {
        tournamentUserManagerActions.updateTournamentGroupFilterStatus(status)
    }
    function handleStartGroupWithSingleStartedTRound() {
        handleStartingGroups(singleStartedTRound, false)
    }
    function handleStartGroupForRound(tRound) {
        handleStartingGroups(tRound, true)
    }
    return (<React.Fragment>
        <Row>
            <Col xs={9} md={groupingMode ? 7 : 5}>
                <h4><strong><FontAwesome icon={"users"}/><I18n code="tournament.live.groups"/></strong></h4>
                <CheckboxInput default={tournament.isShowTUGroups()}
                               onAfterChange={(value) => setShowTuGroups(value)}
                               faIcon={"users"}
                               message={messages.get("tournament.manager.public.groups")} />
                <CheckboxInput default={tournament.isFixedTUInGroup()}
                               onAfterChange={(value) => setFixedTUInGroup(value)}
                               faIcon={"list-ol"}
                               message={messages.get("tournament.manager.fixedABCD.groups")} />
                <CheckboxInput default={tournament.isSelectableTRoundsForGroup()}
                               onAfterChange={setSelectableTRounds}
                               faIcon={"users-cog"}
                               message={messages.get("tournament.manager.group.round.selection")} />
                <br/>
                <p><strong><FontAwesome icon="sort"><I18n code="tournament.manager.sortBy"/>:</FontAwesome></strong><br/>
                    <SortTableText sortKey="startPosition" sortParams={sortParams} handleSort={handleTableSort}><I18n code="Frag_Prep_startposition"/></SortTableText>
                    &nbsp;-&nbsp;
                    <SortTableText sortKey="name" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.name"/></SortTableText>
                    &nbsp;-&nbsp;
                    <SortTableText sortKey="updatedAt" sortParams={sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.updatedAt"/></SortTableText>
                </p>

            </Col>
            <Col xs={3} md={groupingMode ? 5 : 2}>
                {
                    tournamentUserStore.loadingGroup ? <LoadingCircle/> : <p>{"Gruppen: " + tUserGroupAmount}</p>
                }
            </Col>
            <Col xs={12} md={groupingMode ? 12 : 5}>
                <TournamentUserGroupStatusSelect defaultValue={selectedGroupStatus}
                                                 selectCallback={changeTuGroupStatus} />
                {
                    runningTRounds.length > 1 ? runningTRounds.map(tRound => {
                        return <CheckTournamentRound key={tRound.id} tRound={tRound} user={user}
                                                     handlePauseGroups={handlePauseGroups}
                                                     handleStartGroups={tournament.isSelectableTRoundsForGroup() ? handleStartGroupForRound : null}/>
                    }) : <CheckTournamentRound tRound={singleStartedTRound} user={user}
                                               handlePauseGroups={false} handleStartGroups={null}/>
                }
            </Col>
        </Row>

        <Row>
            <Col xs={12} md={groupingMode ? 12 : 7}>
                <ButtonGroup>
                    {
                        singleStartedTRound != null ? <TooltipConfirmButton disabled={singleStartedTRound.getConfirmUserID() == null}
                                                                     onClick={handleStartGroupWithSingleStartedTRound} icon="play-circle"
                                                                     tooltip={messages.get("tournament.mmtuser.tooltip.group.startAll") + singleStartedTRound.getName()} /> : null
                    }
                    <TooltipButton onClick={reloadTUGroups} icon="sync" tooltip={messages.get("tournament.mmtuser.tooltip.group.sync")} />
                    <TournamentUserGroupModal tournament={tournament} actTournamentRound={singleStartedTRound} tournamentUserGroups={tournamentUserStore.tournamentUserGroups}/>
                </ButtonGroup>
            </Col>
        </Row>
    </React.Fragment>)
};
TopPanelArea.propTypes = {
    tournament: PropTypes.object.isRequired,
    tUserGroupAmount: PropTypes.string.isRequired,
    tournamentUserStore: PropTypes.object.isRequired,
    groupingMode: PropTypes.bool.isRequired,
    selectedGroupStatus: PropTypes.string.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object,
    sortParams: PropTypes.object.isRequired
};
const CheckTournamentRound = ({tRound, user, handleStartGroups, handlePauseGroups}) => {
    function confirmTargetAmount() {
        tRound.setConfirmUserID(user);
        tournamentManagerActions.saveTournamentRound(tRound);
        changeLogActions.addTournamentChange(tRound.getTournamentID(), user, "Confirmed: " + tRound.getName(), null, tRound.getTargetAmounts() + "")
    }
    function handleStartTRoundsGroups() {
        // start all groups in this round
        handleStartGroups(tRound)
    }
    function handleTRoundPauseGroups() {
        // reset groups of this round to present
        handlePauseGroups(tRound)
    }
    if (tRound === null) {
        return <Alert style={{marginBottom:"0"}} bsStyle="warning">{messages.get("tournament.mmtuser.group.noStartedRound")}</Alert>
    } else if (tRound.getConfirmUserID() == null) {
        return <Alert style={{marginBottom:"0"}} bsStyle="warning">{tRound.getName()+ ": " + tRound.getTargetAmounts() + " " + messages.get("parcours.details.targets")}
            <br/><Button bsStyle="warning" onClick={confirmTargetAmount}>{messages.get("tournament.mmtuser.group.round.confirm")}</Button>
        </Alert>
    } else {
        return <Alert style={{marginBottom:"0"}} bsStyle="info"><Row>
            <Col xs={handlePauseGroups != null ? 8 : 12}>
                {tRound.getName() + ": " + tRound.getTargetAmounts() + " " + messages.get("parcours.details.targets")}
            </Col>
            {
                handlePauseGroups != null ? <Col xs={4}>
                    <ButtonGroup>
                        {
                            handleStartGroups ? <TooltipButton disabled={tRound.getConfirmUserID() == null} onClick={handleStartTRoundsGroups} icon="play-circle"
                                                             tooltip={messages.get("tournament.mmtuser.tooltip.group.startRound") + " " + tRound.getName()} /> : null
                        }
                        <TooltipButton onClick={handleTRoundPauseGroups} icon="pause"
                                       tooltip={messages.get("tournament.mmtuser.tooltip.group.pause")} />
                    </ButtonGroup>
                </Col> : null
            }
        </Row></Alert>
    }
}
CheckTournamentRound.propTypes = {
    tRound: PropTypes.object,
    user: PropTypes.object,
    handleStartGroups: PropTypes.func,
    handlePauseGroups: PropTypes.func
}

const TournamentGroupPanelBody = React.memo(
    function TournamentGroupPanelBody({tournamentUserGroups, groupChangeId, groupingMode, add2Group, allowGroupCreation,
                                          selectableTRounds, singleStartedTRound, runningTRounds}) {
        // only rerenders if props change
        const grpStyle = groupingMode ? {paddingLeft: "0", paddingRight: "0"} :  {};
        const modCol = 3;
        let rowArray = Utility.buildModArray(tournamentUserGroups.length, modCol);
        // groupChangeId -> Used to trigger reRender
        console.log("Render TournamentGroupPanelBody ID: " + groupChangeId + " /rows: " + rowArray) ;

        return <React.Fragment>
            {
                rowArray.map(index => {
                    return <TournamentGroupPanelGrpRow key={"TGPB_" + index} index={index * modCol}
                                                       runningTRounds={runningTRounds}
                                                       selectableTRounds={selectableTRounds}
                                                       singleStartedTRound={singleStartedTRound}
                                                       groupingMode={groupingMode}
                                                       tournamentUserGroups={tournamentUserGroups}
                                                       add2Group={add2Group} allowGroupCreation={allowGroupCreation}
                                                       grpStyle={grpStyle}/>
                })
            }
        </React.Fragment>
});
TournamentGroupPanelBody.propTypes = {
    tournamentUserGroups: PropTypes.array.isRequired,
    groupChangeId: PropTypes.number.isRequired,
    groupingMode: PropTypes.bool.isRequired,
    allowGroupCreation: PropTypes.bool.isRequired,
    add2Group: PropTypes.func.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object
};
const TournamentGroupPanelGrpRow = ({index, groupingMode, grpStyle, tournamentUserGroups, selectableTRounds,
                                        singleStartedTRound, runningTRounds, add2Group, allowGroupCreation}) => {
    const tUserGroup1 = getGroupFromArray(index, tournamentUserGroups);
    const tUserGroup2 = getGroupFromArray(index + 1, tournamentUserGroups);
    const tUserGroup3 = getGroupFromArray(index + 2, tournamentUserGroups);
    return <Row >
        {
            tUserGroup1 ?
                <Col key={tUserGroup1.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentGroupPanel tUserGroup={tUserGroup1} singleStartedTRound={singleStartedTRound}
                                          selectableTRounds={selectableTRounds}
                                          runningTRounds={runningTRounds}
                                          add2Group={add2Group} allowGroupCreation={allowGroupCreation}/>
                </Col> : null
        }
        {
            tUserGroup2 ?
                <Col key={tUserGroup2.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentGroupPanel tUserGroup={tUserGroup2} singleStartedTRound={singleStartedTRound}
                                          selectableTRounds={selectableTRounds}
                                          runningTRounds={runningTRounds}
                                          add2Group={add2Group} allowGroupCreation={allowGroupCreation}/>
                </Col> : null
        }
        {
            tUserGroup3 ?
                <Col key={tUserGroup3.id}  xs={12} md={groupingMode ? 12 : 4} style={grpStyle}>
                    <TournamentGroupPanel tUserGroup={tUserGroup3} singleStartedTRound={singleStartedTRound}
                                          selectableTRounds={selectableTRounds}
                                          runningTRounds={runningTRounds}
                                          add2Group={add2Group} allowGroupCreation={allowGroupCreation}/>
                </Col> : null
        }
    </Row>
};


const TournamentGroupPanel = ({tUserGroup, allowGroupCreation, add2Group, selectableTRounds,
                                  singleStartedTRound, runningTRounds}) => {
    const startGroupForRound = (tRound) => {
        tournamentUserManagerActions.startTournamentGroups(null,[tUserGroup], tRound)
    };
    const deleteGroup = (doDelete) => {
        if (doDelete) {
            tournamentUserManagerActions.deleteTournamentUserGroup(tUserGroup);
        }
    };
    const clickedAddTUser = () => {
        add2Group(tUserGroup);
    };
    const changeGroupName = (name) => {
        tUserGroup.setName(name);
        tournamentUserManagerActions.saveTournamentUserGroup(tUserGroup)
    };
    const changeGroupStartPosition = (value) => {
        let startPosition = parseInt(value);
        if (startPosition > 0) {
            tUserGroup.setStartPosition(startPosition);
            tournamentUserManagerActions.saveTournamentUserGroup(tUserGroup)
        }
    };
    const reUseGroup = () => {
        tournamentUserManagerActions.updateTournamentUserGroupStatus(tUserGroup, TournamentUserGroup.prototype.status.present);
    };
    const removeFromGroup = (tUser) => {
        tournamentUserManagerActions.removeTUserFromTUserGroup(tUserGroup, tUser);
    };
    const removePlaceholder = (posKey) => {
        tournamentUserManagerActions.removePlaceholderFromTUserGroup(tUserGroup, posKey);
    }
    const switchDraft = (isDraft) => {
        tUserGroup.setDraft(isDraft);
        tournamentUserManagerActions.saveTournamentUserGroup(tUserGroup)
    }
    const handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        let tUserIdArr = droppedItem.draggableId.split("_")
        console.log("Move", tUserIdArr[1], GROUP_CHARS[droppedItem.destination.index], droppedItem)
        tournamentUserManagerActions.moveTUsersInGroup(tUserGroup, tUserIdArr[1], GROUP_CHARS[droppedItem.destination.index])
    }
    const fixedTUsersInGroup = tUserGroup.getFixedTournamentUserIDs() != null
    return <ListGroup>
            <ListGroupItem bsStyle={tUserGroup.getStatusStyle()}>
                <Row>
                <Col xs={6}>
                    <DelayedTextField custIcon="NONE" defaultValue={tUserGroup.getName()} onChange={changeGroupName}/>
                </Col>
                <Col xs={6}>
                    {
                        tUserGroup.isDeleted() ?
                            <TooltipButton onClick={reUseGroup} icon="registered" tooltip={messages.get("tournament.mmtuser.tooltip.group.reUse")} />
                            :
                            <ButtonGroup>
                                <TooltipButton disabled={singleStartedTRound == null || singleStartedTRound.getConfirmUserID() == null}
                                               onClick={() => startGroupForRound(singleStartedTRound)} icon="play-circle" tooltip={messages.get("tournament.mmtuser.tooltip.group.start")} />
                                <TooltipButton onClick={clickedAddTUser} icon="user-plus" tooltip={messages.get("tournament.mmtuser.tooltip.group.addTuser")} />
                                <DeleteDecisionModal messageCode="list.entry.delete" tooltip={messages.get("tournament.mmtuser.tooltip.group.del")}
                                                     entryName={tUserGroup.getName()} handleDelete={deleteGroup}/>

                            </ButtonGroup>
                    }
                </Col>
                </Row>
            </ListGroupItem >
            <ListGroupItem  style={{paddingBottom :"0"}}>
                <ValidInput type="number" addonBefore={<div>{messages.get("Frag_Prep_startposition")}</div>}
                            onAfterChange={changeGroupStartPosition}
                            valid={{maxLength: 3, check:['isNumber', 'greater0']}}
                            default={tUserGroup.getStartPosition()}/>
            </ListGroupItem>
            <TRoundSelector tUserGroup={tUserGroup} selectableTRounds={selectableTRounds} runningTRounds={runningTRounds}/>
            {
                (allowGroupCreation && tUserGroup.getCreator() != null) || tUserGroup.isDraft() ? <ListGroupItem>
                    <CheckboxInput default={tUserGroup.isDraft()}
                                   onAfterChange={switchDraft}
                                   message={messages.get("tournament.mmtuser.tooltip.group.draft")} />
                </ListGroupItem> : null
            }
            {
                fixedTUsersInGroup ? <DragDropContext onDragEnd={handleDrop}><Droppable droppableId="list-container">
                    {(provided) => (<div ref={provided.innerRef} className="list-container"
                            {...provided.droppableProps}>
                        {
                            Object.keys(tUserGroup.getFixedTournamentUserIDs()).map(posKey => {
                                let tUser = tUserGroup.getFixedTournamentUserIDs()[posKey]
                                if (tUser != null) {
                                    return <Draggable key={"LGI_"+ posKey + "_" + tUser.id} draggableId={"LGI_"+ posKey + "_" + tUser.id} index={POS_KEY_INDEX[posKey]}>
                                        {(provided) => (<div ref={provided.innerRef} className="item-container"
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}>
                                            <ListGroupItemTUser tUser={tUser}
                                                                removeFromGroup={removeFromGroup} posKey={posKey}/>
                                        </div>)}
                                    </Draggable>
                                } else {
                                    return <Draggable key={"LGI_" + posKey + "_" + null} draggableId={"LGI_" + posKey + "_" + null} index={POS_KEY_INDEX[posKey]}>
                                        {(provided) => (<div ref={provided.innerRef} className="item-container"
                                                             {...provided.dragHandleProps}
                                                             {...provided.draggableProps}>
                                            <ListGroupItemEmpty posKey={posKey} removePlaceholder={removePlaceholder}/>
                                        </div>)}
                                    </Draggable>
                                }
                            })
                        }
                        {provided.placeholder}
                    </div>)}
                    </Droppable></DragDropContext>
                    :
                    <React.Fragment>
                        {
                            tUserGroup.getTournamentUserIDs().map(tUser => {
                                return <ListGroupItemTUser key={"LGI_" + tUser.id} tUser={tUser} removeFromGroup={removeFromGroup}/>
                            })
                        }
                    </React.Fragment>
            }
        </ListGroup>
};
TournamentGroupPanel.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    allowGroupCreation: PropTypes.bool.isRequired,
    add2Group: PropTypes.func.isRequired,
    runningTRounds: PropTypes.array.isRequired,
    singleStartedTRound :PropTypes.object
};
const ListGroupItemTUser = ({tUser, removeFromGroup, posKey}) => {
    return <ListGroupItem>
        <Row>
            <Col xs={2}>
                <TournamentUserManagerStatus tournamentUser={tUser} addText={false}/>
                {
                    posKey != null ? <strong><br/>&nbsp;&nbsp;{posKey}</strong> : null
                }
            </Col>
            <Col xs={8} style={{paddingLeft: "0px"}}>
                {tUser.getNote() ? <FontAwesome icon="info-circle" title={tUser.getNote()}/> : null }
                {tUser.getFullName() + ", " + tUser.getTournamentConfigBowCode() + ", " + tUser.getTournamentConfigAgeCode()}
                <br/>
                {tUser.getUnion()}
            </Col>
            <Col xs={2}>
                <TooltipButton onClick={() => removeFromGroup(tUser)} icon="minus"
                               bsSize="small"
                               tooltip={tUser.getFullName() + messages.get("tournament.mmtuser.tooltip.group.delUser")} />
            </Col>
        </Row>
    </ListGroupItem>
}
ListGroupItemTUser.propTypes = {
    tUser: PropTypes.object.isRequired,
    removeFromGroup: PropTypes.func.isRequired,
};
const ListGroupItemEmpty = ({posKey, removePlaceholder}) => {
    return <ListGroupItem>
        <Row>
            <Col xs={2}>
                <Label bsStyle="warning"><FontAwesome icon="registered"/></Label>
                <strong><br/>&nbsp;&nbsp;{posKey}</strong>
            </Col>
            <Col xs={8} style={{paddingLeft: "0px"}}>-x-<br/>-x-</Col>
            <Col xs={2}>
                <TooltipButton onClick={() => removePlaceholder(posKey)} icon="minus"
                               bsSize="small"
                               tooltip={messages.get("tournament.mmtuser.tooltip.group.delEmpty")} />
            </Col>
        </Row>
    </ListGroupItem>
}

const TRoundSelector=({tUserGroup, selectableTRounds, runningTRounds}) => {
    function afterGroupChanged(tRound) {
        tournamentUserManagerActions.setTournamentRoundToGroups(tUserGroup, tRound)
    }
    const startGroupForRound = async (tRound) => {
        if (await tournamentUserManagerActions.validateTournamentGroupUsersWithTRound(tUserGroup, tRound)) {
            // validated
            tournamentUserManagerActions.startTournamentGroups(null, [tUserGroup], tRound)
        }
    };
    if (selectableTRounds != null && selectableTRounds.length > 0) {
        return <ListGroupItem>
            <Row>
                <Col xs={10}>
                    <DropDownParseObject default={tUserGroup.getTournamentRoundID()}
                                         label={<I18n code="tournament.manager.group.round.selection" />}
                                         placeholder={<option key={"nullkey"}
                                                              value={"null"}>{messages.get("tournament.modal.score.tround")}</option>}
                                         onAfterChange={afterGroupChanged}
                                         objectList={selectableTRounds}/>
                </Col>
                <Col xs={2}>
                    <TooltipButton disabled={tUserGroup.getTournamentRoundID() == null || tUserGroup.getTournamentRoundID().getConfirmUserID() == null}
                                   onClick={() => startGroupForRound(tUserGroup.getTournamentRoundID())}
                                   icon="play-circle" bsStyle="primary" bsSize="small"
                                   tooltip={messages.get("tournament.mmtuser.tooltip.group.start")} />
                </Col>
            </Row>

        </ListGroupItem>
    }
    if (runningTRounds.length > 1 ) {
        return <ListGroupItem>
            <p><I18n code="tournament.manager.group.round.start" /></p>
            {
                runningTRounds.map(tRound => {
                    return <TooltipButton key={tUserGroup.id + "_" + tRound.id}
                                          disabled={tRound.getConfirmUserID() == null || tUserGroup.isInThisRound(tRound)}
                                          onClick={() => startGroupForRound(tRound)} icon="play-circle" bsStyle="primary"
                                          tooltip={messages.get("tournament.mmtuser.tooltip.group.start")} >
                        {tRound.getName()}
                    </TooltipButton>
                })
            }
        </ListGroupItem>
    }
    return null
}
module.exports = TournamentUserGroupPanel;
