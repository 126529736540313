const React = require("react"),
    PropTypes = require('prop-types');
const messages = require("i18n/messages");

const countTypeActions = require("actions/CountTypeActions");

class I18nCountType extends React.Component {
    constructor(props) {
        super(props);
        this.queryForCountType = this.queryForCountType.bind(this);
        this.state = {
            label: ""
        }
    }
    componentDidMount() {
        let message = messages.getCountTypeTranslation(this.props.countTypeOnlineID);
        if (message != null) {
            this.setState({label: message})
        } else {
            this.queryForCountType(this.props.countTypeOnlineID);
        }
    }
    queryForCountType(countTypeOnlineID) {
        // load count type from database and set name
        countTypeActions.fetchCountTypeByOnlineID.defer(countTypeOnlineID, countTypeName => {
            this.setState({label: countTypeName})
        })
    }
    render() {
        const {label} = this.state;
        const {children} = this.props;
        return (<span>{label}{children}</span>);
    }
}
I18nCountType.propTypes = {
    countTypeOnlineID: PropTypes.string.isRequired
};
module.exports = I18nCountType;
