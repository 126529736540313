const React = require("react"),
    PropTypes = require('prop-types');
const linkUtil = require("linkUtil");
const I18n = require("components/widgets/I18n");
const {Link} = require ('react-router-dom');

const Breadcrumb = ({active, code, link}) => {
    if (active) {
        return (
            <li className="active">
                <span>
                    <I18n code={code}/>
                </span>
            </li>
        )
    } else {
        return (
            <li>
                <Link to={linkUtil.getLink(link)}>
                    <I18n code={code}/>
                </Link>
            </li>
        )
    }
};
const Breadcrumbs = ({children}) => {
    return (<ol className="breadcrumb">
            {children}
        </ol>)
};

module.exports = {Breadcrumbs, Breadcrumb};