const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Nav, Navbar, NavDropdown, NavItem, MenuItem, Image} = require("react-bootstrap");
const MenuNavItemWrap = require('components/MenuNavItemWrap');

const {MenuContext} = require("context/MenuContext");
const {BaseContext} = require("context/BaseContext");

const NotFound = require("components/NotFound"),
    MenuFooter = require("components/MenuFooter"),
    LoginModal = require("components/modals/LoginModal"),
    I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

const Notification = require("components/notification/Notification");

const {User} = require("parse/_Domain");

const userActions = require("actions/UserActions"),
    languageActions = require("actions/LanguageActions");

const messages = require("i18n/messages"),
    linkUtil = require("linkUtil");

const bundle = require('../../../package.json');

class MenuWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.setTournamentID = this.setTournamentID.bind(this);
        this.state = {
            tournamentIDMenu: null,
            setTournamentIDFct: this.setTournamentID,
            languageValid: messages.isLanguageSupported(this.props.language)
        };
    }
    componentDidMount() {
        if (!messages.isLanguageSupported(this.props.language)) {
            window.location = linkUtil.getLink("404");
            return;
        }
        languageActions.switchLanguage(this.props.language);
        userActions.verifyVersion(bundle.version);
    }
    setTournamentID(tournamentId) {
        this.setState({tournamentIDMenu: tournamentId});
    }
    render() {
        const {languageValid, tournamentIDMenu} = this.state;
        const {pathname, children} = this.props;
        if (!languageValid) {
            return <NotFound path={pathname}/>
        }
        return (
            <React.Fragment>
                <TopNavBar needTMenu={tournamentIDMenu != null}
                           objectID={tournamentIDMenu}/>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <Notification/>
                        </Col>
                    </Row>
                </Grid>
                    <MenuContext.Provider value={this.state}>
                        {children}
                    </MenuContext.Provider>
                <MenuFooter/>
            </React.Fragment>);
    }
}
MenuWrapper.propTypes = {
    children: PropTypes.any.isRequired,
    language: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
}

const TopNavBar = ({needTMenu, objectID}) => {
    const { user, userFeatures } = React.useContext(BaseContext);
    const [navExpanded, setNavExpanded] = React.useState(false);
    const [bodyType, setBodyType] = React.useState("M");
    function closeNav() {
        setNavExpanded(false)
    }
    function toggleClicked(type) {
        const typeChanged = type != bodyType;
        const closed = !navExpanded;
        setBodyType(type)
        setNavExpanded(typeChanged || closed)
    }
    function openLoginDialog() {
        userActions.showLoginModal();
    }
    let userStyle = userFeatures.isFeatureEndNear ? {color:"red"} : {color:"white"}
    return (<Navbar inverse fixedTop collapseOnSelect expanded={navExpanded} onToggle={() => null}>
        <Navbar.Header>
            <Navbar.Toggle style={{marginLeft:"10px"}} className="pull-left" onClick={() => toggleClicked("M")}/>
            <Navbar.Brand>
                {
                    needTMenu ?
                        <a href={linkUtil.getLink("/tournament/" + objectID)}>TurnierView</a>
                        :
                        <a href={linkUtil.getLink("")}>
                            <Image style={{float: "left", margin:"-7px 0 0 0", padding: "0 0 0 0", width:"35px", height:"35px"}}
                                   src="/img/ico/ic_launcher_green.png"/>&nbsp;3DTurnier
                        </a>
                }
            </Navbar.Brand>
            {
                user ?
                    <Navbar.Toggle  style={userStyle} onClick={() => toggleClicked("U")}>
                        <FontAwesome icon="user">{user.get(User.prototype.col.username)}</FontAwesome>
                    </Navbar.Toggle>
                    :
                    <Navbar.Toggle>
                        <a href="#" onClick={openLoginDialog} style={{color:"white"}}>
                            <FontAwesome icon="sign-in-alt">
                                <I18n code="header.nav.login"/>
                            </FontAwesome>
                        </a>
                    </Navbar.Toggle>
            }
        </Navbar.Header>
        <Navbar.Collapse>
            <div className="hidden-xs">
                <NavBarUserDesktop user={user} userFeatures={userFeatures} onSelect={closeNav}/>
            </div>
            {
                bodyType === "M" ?
                    <Nav pullRight>
                        {
                            needTMenu ?
                                <TopTournamentMenu tournamentID={objectID} onSelect={closeNav}/>
                                :
                                <TopMainMenu user={user} onSelect={closeNav}/>
                        }
                    </Nav>
                    :
                    <Nav onSelect={closeNav}>
                        <UserSubMenu user={user} userFeatures={userFeatures} onSelect={closeNav}/>
                    </Nav>
            }
        </Navbar.Collapse>
        <LoginModal/>
    </Navbar>)
}
TopNavBar.propTypes = {
    needTMenu: PropTypes.bool.isRequired,
    objectID: PropTypes.string
};
const TopMainMenu = ({onSelect}) => {
    return (
        <React.Fragment>
            <MenuNavItemWrap eventKey={1} onSelect={onSelect}
                         route="solutions" label="header.nav.solutions"/>
            <MenuNavItemWrap eventKey={2} onSelect={onSelect}
                         route="skillboard" label="header.nav.3dskillboard"/>
            <MenuNavItemWrap eventKey={3} onSelect={onSelect}
                         route="skillboard/premium" label="header.nav.premium"/>
            <MenuNavItemWrap eventKey={4} onSelect={onSelect}
                         route="tournament" label="header.nav.tournaments"/>
            <MenuNavItemWrap eventKey={6} onSelect={onSelect}
                         route="parcours" label="header.nav.parcours"/>
            <MenuNavItemWrap eventKey={7} onSelect={onSelect}
                         route="club" label="header.nav.clubs"/>
            <MenuNavItemWrap eventKey={8} onSelect={onSelect}
                         route="contact" label="header.nav.contact"/>
        </React.Fragment>);
};
TopMainMenu.propTypes = {
    onSelect: PropTypes.func.isRequired
};
const TopTournamentMenu = ({tournamentID, onSelect}) => {
    return (
        <React.Fragment>
            <MenuNavItemWrap eventKey={1} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/manager"}
                         label="tournament.button.menu.manager"/>
            <MenuNavItemWrap eventKey={2} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/mmfile"}
                         label="tournament.button.menu.files"/>
            <MenuNavItemWrap eventKey={3} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/mmconfig"}
                         label="tournament.button.menu.mmc"/>
            <MenuNavItemWrap eventKey={4} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/mmround"}
                         label="tournament.button.menu.rounds"/>
            <MenuNavItemWrap eventKey={5} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/mmtuser"}
                         label="tournament.button.menu.mmtu"/>
            <MenuNavItemWrap eventKey={6} onSelect={onSelect}
                         route={"/tournament/" + tournamentID + "/mmscan"}
                         label="tournament.button.menu.scan"/>
        </React.Fragment>);
};
TopTournamentMenu.propTypes = {
    tournamentID: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};
const NavBarUserDesktop = ({user, userFeatures, onSelect}) => {
    const openLoginDialog = () => {
        userActions.showLoginModal();
    };
    if (user == null) {
        return (
            <Nav style={{marginLeft:"10px"}} pullRight >
                <NavItem  href="#" onClick={openLoginDialog}>
                    <FontAwesome icon="sign-in-alt">
                        <I18n code="header.nav.login"/>
                    </FontAwesome>
                </NavItem>
            </Nav>
        );
    } else {
        let userStyle = userFeatures.isFeatureEndNear ? {color:"red"} : {color:"#9d9d9d"}
        return (
            <Nav style={{marginLeft:"10px"}} pullRight>
                <NavDropdown id="UserDropDown" eventKey={8} title={<span style={userStyle} ><FontAwesome icon="user">{user.get(User.prototype.col.username)}</FontAwesome></span>}>
                    <UserSubMenu user={user} userFeatures={userFeatures} onSelect={onSelect}/>
                </NavDropdown>
            </Nav>
        )
    }
};
NavBarUserDesktop.propTypes = {
    onSelect: PropTypes.func.isRequired,
    user:PropTypes.object
};
const UserSubMenu =({user, userFeatures, onSelect}) => {
    const logout = () => {
        userActions.logout();
    };
    return (
        <React.Fragment>
            <MenuNavItemWrap eventKey={8.1} onSelect={onSelect} icon="cogs"
                         route="account" label="header.nav.account"/>
            {
                userFeatures.isFeatureEndNear ? <MenuNavItemWrap eventKey={3} onSelect={onSelect}
                                                                 style={{color:"red"}}
                                                                 icon="exclamation"
                                                                 route="skillboard/premium/buy"
                                                                 label="header.nav.premium.next"/> : null
            }

            <MenuNavItemWrap eventKey={8.2} onSelect={onSelect} icon="user"
                         route="profile" label="header.nav.profile"/>
            <MenuNavItemWrap eventKey={8.3} onSelect={onSelect} icon="user-clock"
                         route="training" label="header.nav.training"/>
            <MenuNavItemWrap eventKey={8.4} onSelect={onSelect} icon="inbox"
                         route="mgmtparcours" label="profile.managedParcours.header"/>
            {
                user && user.get(User.prototype.col.trlLang) ?
                    <MenuNavItemWrap eventKey={8.5} onSelect={onSelect} icon="language"
                                 route="translate" label="header.nav.translator"/>
                    : null
            }
            <MenuItem divider />
            <MenuItem id="logoutUser" eventKey={8.6} target="_blank" onClick={logout}>
                <FontAwesome icon="sign-out-alt"/><I18n code="header.nav.logout"/>
            </MenuItem>
        </React.Fragment>);
};
UserSubMenu.propTypes = {
    onSelect: PropTypes.func.isRequired,
    userFeatures: PropTypes.object,
    user:PropTypes.object
};

module.exports = MenuWrapper;
