const alt = require("AltInit"),
    Parse = require("ParseInit"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages");

const parseDao = require("actdao/ParseBaseDao");
const tournamentUserDao = require("actdao/TournamentUserDao");
const notificationActions = require("components/notification/NotificationActions");
const changeLogActions = require("actions/ChangeLogActions");
const msgActions = require("actions/MessageActions");

class TournamentUserActions {

    loadTournamentUsers(tournament) {
        tournamentUserDao.loadPaidTournamentUsers(tournament)
            .then(pPaidTournamentUser => {
                tournamentUserDao.loadUnPaidTournamentUsers(tournament)
                    .then(pUnPaidTournamentUser => {
                        if (pPaidTournamentUser != null) {
                            this.updateTournamentUsers(pPaidTournamentUser.concat(pUnPaidTournamentUser));
                        } else {
                            this.updateTournamentUsers(pUnPaidTournamentUser);
                        }

                    });
            })
            .catch(error => {
                error.trace = "TUSA.loadTUser";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadCupTournamentUsers(buCode, licNumber, tournamentIds) {
        tournamentUserDao.loadCupTournamentUsers(buCode, licNumber, tournamentIds)
            .then(tournamentCupUsers => {
                // update
                this.updateChildTournamentUsers(tournamentCupUsers);
            })
            .catch(error => {
                error.trace = "TUSA.loadCupTUser";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadTournamentUserTeams(tournament) {
        tournamentUserDao.loadTournamentUserTeams(tournament, true)
            .then(pTournamentUserTeams => {
                this.updateTournamentUserTeams(pTournamentUserTeams);
            })
            .catch(error => {
                error.trace = "TUSA.loadTUTeam";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadTournamentUsersUnpaid(tournament) {
        tournamentUserDao.loadUnPaidTournamentUsers(tournament)
            .then(pTournamentUser => {
                this.updateTournamentUsers(pTournamentUser);
            })
            .catch(error => {
                error.trace = "TUSA.loadTUserUnpaid";
                notificationActions.parseError(error);
            });
        return {};
    }

    createTournamentUserTeamRegistration(tournament, tournamentUserTeam) {
        parseDao.saveObject(tournamentUserTeam)
            .then(sTournamentUserTeam => {
                console.log("created " + sTournamentUserTeam.id + " name:" + sTournamentUserTeam.getName());
                this.sentRegistrationConfirmTeamEmail(tournament, sTournamentUserTeam);
                this.addTournamentUserTeam(sTournamentUserTeam);
            })
            .catch(error => {
                error.trace = "TUSA.saveTUTeamRegistrate";
                notificationActions.parseError(error);
            });
        return {};
    };

    deRegisterFromTournament(tournament, tournamentUser, user) {
        var oldValue = tournamentUser.getStatus();
        tournamentUser.set("status", "D");
        parseDao.saveObject(tournamentUser)
            .then(pTournamentUser => {
                this.sentDeRegistrationEmail(tournament, pTournamentUser, user);
                changeLogActions.addTournamentUserDeleteCL(tournament, pTournamentUser, user, oldValue);
                this.removeTournamentUser(tournamentUser);
            })
            .catch(error => {
                error.trace = "TUSA.deregisterRU";
                notificationActions.parseError(error);
            });
        return {};
    };
    deRegisterTeamFromTournament(tournament, tournamentUserTeam, user) {
        tournamentUserTeam.set("status", "D");
        parseDao.saveObject(tournamentUserTeam)
            .then(sTournamentUserTeam => {
                // deregister User also
                var tUsers = sTournamentUserTeam.getTournamentUserIDs();
                for (var i = 0; i < tUsers.length; i++) {
                    tUsers[i].set("status", "D");
                }
                parseDao.saveAll(tUsers)
                    .then(sTUsers => {
                        console.log("updated tUsers for team " + tournamentUserTeam.id);
                    })
                    .catch(error => {
                        error.trace = "TUSA.deregisterRUTeamMembers";
                        notificationActions.parseError(error);
                    });
                this.removeTournamentUserTeam(sTournamentUserTeam);
            })
            .catch(error => {
                error.trace = "TUSA.deregisterRUTeam";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentUser(tournamentUser) {
        return tournamentUser;
    }

    updateTournamentUsers(tournamentUsers) {
        return tournamentUsers;
    }
    updateChildTournamentUsers(tournamentUsers) {
        return tournamentUsers;
    }
    updateTournamentUserTeams(tournamentUserTeams) {
        return tournamentUserTeams;
    }

    searchTournamentUser(searchParams) {
        return searchParams;
    }

    removeTournamentUser(tournamentUser) {
        return tournamentUser;
    }

    removeTournamentUserTeam(tournamentUserTeam) {
        return tournamentUserTeam;
    }

    addTournamentUser(tournamentUser) {
        return tournamentUser;
    }

    addTournamentUserTeam(tournamentUserTeam) {
        return tournamentUserTeam
    }

    //----------------

    sentDeRegistrationEmail(tournament, tournamentUser, user) {
        var tPrice = tournament.getTournamentPriceID();
        var sender = user.get("email");
        var tDate = tournament.getDate();
        var subject = messages.get("tournament.register.mail.deRegistrationSubject") + ": " + tournament.getName() + " - " + tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
        var receiver = tPrice.getEmail();
        var tournamentLink = linkUtil.getFullLink('/tournament/' + tournament.id);
        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<a href="' + tournamentLink + '">' + messages.get("tournament.register.mail.tournamentLink") + '</a>';
        htmlMessage += '<h3>' + messages.get("tournament.register.mail.deRegistrationConfirm") + ':</h3>';
        htmlMessage += '<p><strong>' + messages.get("tournament.table.column.name") + ': </strong>' + tournamentUser.getFullName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.class.bow") + ': </strong>' + tournamentUser.getTournamentConfigBowCodeName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.class.age") + ': </strong>' + tournamentUser.getTournamentConfigAgeCodeName() + '</p>';
        if (tournamentUser.getTournamentRegSlotID() != null && tournamentUser.getTournamentRegSlotID().isDataAvailable()) {
            htmlMessage += '<p><strong>' + messages.get("tournament.manager.config.slots") + ': </strong>' + tournamentUser.getTournamentRegSlotID().getName() + '</p>';
        }
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, sender, htmlMessage, null);
        return {};
    }

    sentRegistrationConfirmEmail(tournament, tournamentUser, selfRegistration) {
        var tPrice = tournament.getTournamentPriceID();
        var sender = tPrice.getEmail();
        var regMaillCC = tPrice.getRegistrationMailCC();
        var tDate = tournament.getDate();
        var subject = tournament.getName() + " - " + tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
        var receiver = "";
        var tournamentLink = linkUtil.getFullLink('/tournament/' + tournament.id);
        var calendarLink = linkUtil.getFullLink('/calender.html?tID=' + tournament.id+ "&amp;calType=");
        if (selfRegistration) {
            receiver = tournamentUser.getPlayerEmail();
        } else {
            var currentUser = Parse.User.current();
            if (currentUser && "usr_read_only" != currentUser.get("username")) {
                receiver = currentUser.get("email");
            }
            if (tournamentUser.hasValidPlayerEmail()) {
                receiver += ", " + tournamentUser.getPlayerEmail();
            }
        }
        //' + messages.get("") + '
        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<a href="' + tournamentLink + '">' + messages.get("tournament.register.mail.tournamentLink") + '</a>';
        htmlMessage += '<h3>' + messages.get("tournament.register.mail.registrationConfirm") + ':</h3>';
        htmlMessage += '<p><strong>' + messages.get("tournament.table.column.name") + ': </strong>' + tournamentUser.getFullName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.class.bow") + ': </strong>' + tournamentUser.getTournamentConfigBowCodeName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.class.age") + ': </strong>' + tournamentUser.getTournamentConfigAgeCodeName() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.register.label.price") + ': </strong>' +  tPrice.getCurrency() + ' ' + tournamentUser.getPayAmount() + '</p>';
        htmlMessage += '<p><strong>' + messages.get("tournament.register.label.payReference") + ': </strong>' + tournamentUser.getPayRefID() + '</p>';
        if (tournament.isLicenseMandatory()) {
            htmlMessage += '<p><strong>' + tournament.getBowUnionID().getName() + ' #: </strong>' + tournamentUser.getLicenseNumber() + '</p>';
        }
        if (tournament.getTournamentOptionID() != null && tournamentUser.getOption() != null) {
            htmlMessage += '<p><strong>' + tournament.getTournamentOptionID().getOptionLabel() + ': </strong>' + tournamentUser.getOption() + '</p>';
        }
        if (tournamentUser.getTournamentRegSlotID() != null && tournamentUser.getTournamentRegSlotID().isDataAvailable()) {
            htmlMessage += '<p><strong>' + messages.get("tournament.manager.config.slots") + ': </strong>' + tournamentUser.getTournamentRegSlotID().getName() + '</p>';
        }
        htmlMessage += '<p>' + tPrice.getPaymentCondition() + '</p>';
        htmlMessage += '<br><br><a href="' + tournamentLink + '/checkout">' + messages.get("tournament.register.panel.info.payDesc") + '</a>';
        htmlMessage += '<br><br> <a href="' + linkUtil.getFullLink('/skillboard/tab/jointournament') + '">' + messages.get("tournament.info.app") + '</a>';
        htmlMessage += '<br><br><p>' + messages.get("tournament.register.mail.bestWished") + '</p>';
        htmlMessage += '<img src="https://www.3dturnier.com/img/ico/ic_launcher_green.png" title="3D SKill Board" alt="3D SKill Board">';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.anyQuestions") + '</h3>';
        htmlMessage += '<p>' + messages.get("tournament.register.mail.askHost") + ': <a href="mailto:' + sender + '?subject='+ subject +'">' + tournament.getUnion() + '</a></p>';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.addCalendar") + ':</h3>';
        htmlMessage += '<a href="' + calendarLink + 'google">Google</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'ical">iCal</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'outlook">Outlook</a>';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.findTournaments") + ':</h3>';
        htmlMessage += '<p>' + messages.get("tournament.register.mail.features") + '<br><a href="' + linkUtil.getFullLink('/tournament') + '">' + messages.get("home.panel.tournament.link") + '</a></p>';
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, regMaillCC === true ? sender : null, htmlMessage, null);
        return {};
    };

    sentRegistrationConfirmTeamEmail(tournament, tournamentUserTeam) {
        var tPrice = tournament.getTournamentPriceID();
        var sender = tPrice.getEmail();
        var regMaillCC = tPrice.getRegistrationMailCC();
        var tDate = tournament.getDate();
        var subject = tournament.getName() + " - " + tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
        var receiver = "";
        var tournamentLink = linkUtil.getFullLink('/tournament/' + tournament.id);
        var calendarLink = linkUtil.getFullLink('/calender.html?tID=' + tournament.id+ "&amp;calType=");

        var htmlMessage = '<html><body>';
        htmlMessage += '<h1>'+subject+'</h1>';
        htmlMessage += '<a href="' + tournamentLink + '">' + messages.get("tournament.register.mail.tournamentLink") + '</a>';
        htmlMessage += '<h3>' + messages.get("tournament.register.mail.registrationConfirm") + ':</h3>';
        htmlMessage += '<p><strong>' + messages.get("tournament.table.column.teamname") + ': </strong>' + tournamentUserTeam.getName() + '</p>';
        var tUsers = tournamentUserTeam.getTournamentUserIDs();
        for (var i = 0; i < tUsers.length; i++) {
            if (tUsers[i].hasValidPlayerEmail()) {
                receiver += tUsers[i].getPlayerEmail() + ", ";
            }
            htmlMessage += '<p><strong>' + messages.get("tournament.table.column.archer") + ': ' + tUsers[i].getFullName() + '</strong></p>';
            htmlMessage += '<p><strong>' + messages.get("tournament.class.bow") + ': </strong>' + tUsers[i].getTournamentConfigBowCodeName() + '</p>';
            htmlMessage += '<p><strong>' + messages.get("tournament.register.label.price") + ': </strong>' +  tPrice.getCurrency() + ' ' + tUsers[i].getPayAmount() + '</p>';
            htmlMessage += '<p><strong>' + messages.get("tournament.register.label.payReference") + ': </strong>' + tUsers[i].getPayRefID() + '</p>';
        }
        htmlMessage += '<p>' + tPrice.getPaymentCondition() + '</p>';
        htmlMessage += '<br><br><a href="' + tournamentLink + '/checkout">' + messages.get("tournament.register.panel.info.payDesc") + '</a>';
        htmlMessage += '<br><br> <a href="' + linkUtil.getFullLink('/skillboard/tab/jointournament') + '">' + messages.get("tournament.info.app") + '</a>';
        htmlMessage += '<br><br><p>' + messages.get("tournament.register.mail.bestWished") + '</p>';
        htmlMessage += '<img src="https://www.3dturnier.com/img/ico/ic_launcher_green.png" title="3D SKill Board" alt="3D SKill Board">';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.anyQuestions") + '</h3>';
        htmlMessage += '<p>' + messages.get("tournament.register.mail.askHost") + ': <a href="mailto:' + sender + '?subject='+ subject +'">' + tournament.getUnion() + '</a></p>';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.addCalendar") + ':</h3>';
        htmlMessage += '<a href="' + calendarLink + 'google">Google</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'ical">iCal</a> - ';
        htmlMessage += '<a href="' + calendarLink + 'outlook">Outlook</a>';
        htmlMessage += '<hr><h3>' + messages.get("tournament.register.mail.findTournaments") + ':</h3>';
        htmlMessage += '<p>' + messages.get("tournament.register.mail.features") + '<br><a href="' + linkUtil.getFullLink('/tournament') + '">' + messages.get("home.panel.tournament.link") + '</a></p>';
        htmlMessage += '</body></html>';
        msgActions.sentEmail(subject, sender, receiver, regMaillCC === true ? sender : null, htmlMessage, null);
        return {};
    };

    resetTournamentUsers() {
        return {};
    }
}
module.exports = alt.createActions(TournamentUserActions);
