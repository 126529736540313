const Parse = require("ParseInit");

const Address = Parse.Object.extend("address", {
    col: {
        ACL: "ACL",
        objectId: "objectId",
        company: "company",
        name: "name",
        surname: "surname",
        phone: "phone",
        phone2: "phone2",
        mail: "mail",
        mail2: "mail2",
        street: "street",
        zip: "zip",
        place: "place",
        coords: "coords",
        status: "status",
        uid: "uid",
        extRefID: "extRefID",
        hpIdentUniqueID: "hpIdentUniqueID",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        A: "A"
    },
    getZipPlace() {
        let tPlace = "";
            if (this.getZip()) {
                tPlace = this.getZip() + " ";
            }
            if (this.getPlace()) {
                tPlace += this.getPlace();
            }
        return tPlace;
    },
    buildACL(user, readRole, writeRole) {
        let acl = new Parse.ACL();
        acl.setPublicReadAccess(false);
        acl.setPublicWriteAccess(false);
        if (user) {
            acl.setWriteAccess(user, true);
            acl.setReadAccess(user, true);
        }
        if (readRole) {
            acl.setRoleReadAccess(readRole, true);
        }
        if (writeRole) {
            acl.setRoleWriteAccess(writeRole, true);
        }
        this.setACL(acl);
    },
    fillObject(addressDict) {
        this.setFullName(addressDict[this.col.name], addressDict[this.col.surname]);
        this.setStreet(addressDict[this.col.street]);
        this.setZip(addressDict[this.col.zip]);
        this.setPlace(addressDict[this.col.place]);
        if (addressDict[this.col.phone]) {
            this.setPhone(addressDict[this.col.phone])
        }
    },
    buildTransactionAddress() {
        const address = {};
        if (this.getCompany()) {
            address[Address.prototype.col.company] = this.getCompany().trim();
        }
        address[Address.prototype.col.name] = this.getName().trim();
        address[Address.prototype.col.surname] = this.getSurname().trim();
        address[Address.prototype.col.street] = this.getStreet().trim();
        address[Address.prototype.col.zip] = this.getZip().toString().trim();
        address[Address.prototype.col.place] = this.getPlace().trim();
        address[Address.prototype.col.mail] = this.getMail().trim();
        if (this.getExtRefID()) {
            address[Address.prototype.col.extRefID] = this.getExtRefID().trim();
        }
        if (this.getUID()) {
            address[Address.prototype.col.uid] = this.getUID().trim();
        }
        return address;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCompany() {
        return this.get(this.col.company)
    },
    setCompany(company) {
        return this.set(this.col.company, company)
    },
    getCoords() {
        return this.get(this.col.coords)
    },
    setCoords(coords) {
        return this.set(this.col.coords, coords)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getMail() {
        return this.get(this.col.mail)
    },
    setMail(mail) {
        return this.set(this.col.mail, mail)
    },
    getMail2() {
        return this.get(this.col.mail2)
    },
    setMail2(mail) {
        return this.set(this.col.mail2, mail)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getSurname() {
        return this.get(this.col.surname)
    },
    setSurname(surname) {
        return this.set(this.col.surname, surname)
    },
    getFullName() {
        let surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return this.getName() + " " + surname;
        } else {
            return this.getName();
        }
    },
    getFullSurName() {
        let surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return  surname + " " + this.getName();
        } else {
            return this.getName();
        }
    },
    setFullName(name, surname) {
        this.set(this.col.name, name);
        this.set(this.col.surname, surname);
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPhone() {
        return this.get(this.col.phone)
    },
    setPhone(phone) {
        return this.set(this.col.phone, phone)
    },
    getPhone2() {
        return this.get(this.col.phone2)
    },
    setPhone2(phone) {
        return this.set(this.col.phone2, phone)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
        return this.set(this.col.place, place)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUID() {
        return this.get(this.col.uid)
    },
    setUID(uid) {
        return this.set(this.col.uid, uid)
    },
    getStreet() {
        return this.get(this.col.street)
    },
    setStreet(street) {
        return this.set(this.col.street, street)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getZip() {
        return this.get(this.col.zip)
    },
    setZip(zip) {
        return this.set(this.col.zip, zip)
    },
    getExtRefID() {
        return this.get(this.col.extRefID)
    },
    setExtRefID(extRefID) {
        return this.set(this.col.extRefID, extRefID)
    },
    getHPIdentUniqueID() {
        return this.get(this.col.hpIdentUniqueID)
    }

});

module.exports = Address;
