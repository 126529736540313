var Parse = require("ParseInit");

var ImageGallery = Parse.Object.extend("imageGallery", {
    col: {
        creator: "creator",
        comment: "comment",
        image: "image",
        thumbnail: "thumbnail",
        parcoursID: "parcoursID",
        parcoursOnlineID: "parcoursOnlineID",
        status: "status",
        reportCounter: "reportCounter",
        tournamentID: "tournamentID",
        createdAt: "createdAt",
    },
    getCreator: function () {
        return this.get(this.col.creator)
    },
    setCreator: function (creator) {
        return this.set(this.col.creator, creator)
    },
    getImageDate: function() {
        var startDate = this.get("createdAt");
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    getImage: function () {
        return this.get(this.col.image)
    },
    getReportCounter: function() {
        return this.get(this.col.reportCounter);
    },
    incrementReportCounter: function () {
        this.increment(this.col.reportCounter);
    },
    getImageUrl: function () {
        var image = this.getImage();
        if (image) {
            return image.url();
        }
        return "";
    },
    getThumbnail: function () {
        return this.get(this.col.thumbnail)
    },
    getThumbnailUrl: function () {
        var thumbnail = this.get(this.col.thumbnail);
        if (thumbnail) {
            return thumbnail.url();
        }
        var image = this.getImage();
        if (image) {
            return image.url();
        }
        return "";
    },
    setImage: function (image) {
        return this.set(this.col.image, image)
    },
    getComment: function () {
        return this.get(this.col.comment)
    },
    setComment: function (adminComment) {
        this.set(this.col.comment, adminComment)
    },
    setParcoursID: function (parcoursID) {
        this.get(this.col.parcoursID, parcoursID);
        if (parcoursID) {
            this.setParcoursOnlineID(parcoursID.getOnlineID());
        }
    },
    getTournamentID: function () {
        return this.get(this.col.tournamentID)
    },
    setTournamentID: function (tournamentID) {
        this.get(this.col.tournamentID, tournamentID)
    },
    getParcoursOnlineID: function () {
        return this.get(this.col.parcoursOnlineID)
    },
    setParcoursOnlineID: function (onlineID) {
        return this.set(this.col.parcoursOnlineID, onlineID)
    },
    setStatus: function (status) {
        return this.set(this.col.status, status)
    }
});

module.exports = ImageGallery;
