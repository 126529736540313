const alt = require("AltInit");
const tournamentLiveActions = require("actions/TournamentLiveActions");
const PLUtil = require("util/ParseListUtility");

class TournamentLiveStore {
    constructor() {
        this.displayName = "TournamentLiveStore";
        this.tournamentRounds = [];
        this.tournamentDetail = null;
        this.allTournamentGroups = null;
        this.tournamentGroups = null;
        this.ownTournamentGroup = null;
        this.ownTournamentUser = null;
        this.tournamentUserResult = {};
        this.tournamentLiveGroups = {};
        this.loading = true;
        this.searchParams = {
            text: "",
        };
        this.bindListeners({
            updateTournamentDetail : tournamentLiveActions.updateTournamentDetail,
            resetTournamentLiveResults: tournamentLiveActions.resetTournamentLiveResults,
            updateTournamentGroups : tournamentLiveActions.updateTournamentGroups,
            updateTournamentLiveGroups : tournamentLiveActions.updateTournamentLiveGroups,
            updateTournamentGroup: tournamentLiveActions.updateTournamentGroup,
            updateOwnTournamentGroup: tournamentLiveActions.updateOwnTournamentGroup,
            updateTournamentUserResult : tournamentLiveActions.updateTournamentUserResult,
            updateTournamentRounds : tournamentLiveActions.updateTournamentRounds,
            searchTournamentUserInGroups: tournamentLiveActions.searchTournamentUserInGroups
        });
    }


    updateTournamentDetail(pTournamentDetail) {
        this.tournamentDetail = pTournamentDetail;
    }
    updateTournamentRounds(tRounds) {
        this.tournamentRounds = tRounds;
        this.loading = false;
    };

    updateTournamentGroups(pTournamentGroups) {
        this.allTournamentGroups = pTournamentGroups;
        this.filterTournamentGroups()
    }
    updateTournamentGroup(tGroup) {
        if (tGroup.isDeleted()) {
            this.updateTournamentGroups(PLUtil.removeObj(this.allTournamentGroups, tGroup))
        } else {
            this.updateTournamentGroups(PLUtil.updateOrPush(this.allTournamentGroups, tGroup));
        }
    }

    updateTournamentLiveGroups({tournamentRoundId, tGroups}) {
        this.tournamentLiveGroups[tournamentRoundId] = tGroups;
    }

    updateTournamentUserResult({tournamentRoundId, userResultDic}) {
        this.tournamentUserResult[tournamentRoundId] = userResultDic;
    };
    updateOwnTournamentGroup({tGroup, tUser}) {
        this.ownTournamentGroup = tGroup
        this.ownTournamentUser = tUser
    }

    searchTournamentUserInGroups(searchParams) {
        this.searchParams = searchParams;
        this.filterTournamentGroups();
    }

    filterTournamentGroups() {
        let preFilteredList = this.allTournamentGroups;
        if (this.searchParams.text.length > 0) {
            const searchString = this.searchParams.text.toLowerCase();
            this.tournamentGroups = preFilteredList.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            this.tournamentGroups = preFilteredList;
        }
    }

    resetTournamentLiveResults() {
        this.tournamentRounds = [];
        this.tournamentDetail = null;
        this.allTournamentGroups = null;
        this.tournamentGroups = null;
        this.ownTournamentGroup = null;
        this.ownTournamentUser = null;
        this.tournamentUserResult = {};
        this.tournamentLiveGroups = {};
        this.loading = true;
    }
}
module.exports = alt.createStore(TournamentLiveStore);