const ParseListUtility = {
    updateOrPush: function(list, object) {
        let updated = false;
        const length = list.length;
        for (let i=0; i < length; i++) {
            if (list[i].id === object.id) {
                list[i] = object;
                updated = true;
                break;
            }
        }
        if (!updated) {
            list.push(object);
        }
        return list;
    },
    contains: function(list, object) {
        if (list != null && object != null) {
            const length = list.length;
            for (let i=0; i < length; i++) {
                if (list[i].id === object.id) {
                    return true;
                }
            }
        }
        return false;
    },
    containsList: function (list, list2) {
        if (list != null && list2 != null) {
            const length = list.length;
            for (let i=0; i < length; i++) {
                for (let j=0; j < list2.length; j++) {
                    if (list[i].id === list2[j]) {
                        return true;
                    }
                }
            }
        }
        return false;
    },
    containsID: function(list, objectID) {
        if (list != null) {
            const length = list.length;
            for (let i=0; i < length; i++) {
                if (list[i].id === objectID) {
                    return true;
                }
            }
        }
        return false;
    },
    getObjectbyID: function (list, objectID) {
        if (list != null) {
            const length = list.length;
            for (let i=0; i < length; i++) {
                if (list[i].id === objectID) {
                    return list[i];
                }
            }
        }
        return null;
    },
    getObjectbyCol: function (list, col, value) {
        if (list != null) {
            const length = list.length;
            for (let i=0; i < length; i++) {
                if (list[i].get(col) === value) {
                    return list[i];
                }
            }
        }
        return null;
    },
    removeObj: function(list, object) {
        return list.filter(function (u) {
            return u.id != object.id;
        })
    },
    newList: function(list) {
        let nList = [];
        const length = list.length;
        for (let i=0; i < length; i++) {
            nList.push(list[i]);
        }
        return nList;
    },
    pushObjSortedASC: function (list, newEntry, compareFct) {
        const length = list.length;
        let added = false;
        let index = 0;
        while (index < length && !added) {
            const listEntry = list[index];
            if (compareFct(listEntry, newEntry)) {
                // enter
                list.splice(index, 0, newEntry);
                added = true;
            }
            index++;
        }
        if (!added) {
            // add last
            list.push(newEntry);
        }
    },
    pushObjSortedDESC: function(list, newEntry, compareFct) {
        let added = false;
        let index = list.length - 1;
        while (index >= 0 && !added) {
            const listEntry = list[index];
            if (compareFct(listEntry, newEntry)) {
                // endter
                list.splice(index + 1, 0, newEntry);
                added = true;
            }
            index--;
        }
        if (!added) {
            list.splice(0, 0, newEntry);
        }
    },
    pushFirst: function (list, newEntry) {
        list.splice(0,0, newEntry);
        return list;
    },
    toLower: function (value) {
        if (value != null) {
            if (value.toLowerCase != null) {
                return value.toLowerCase();
            }
            return value;
        }
        return "";
    },
    sortList: function(list, sortParams) {
        if (sortParams != null && sortParams.sortKey != null) {
            // return 1 for b before a
            // return -1 for a before b
            let sortAsc = sortParams.sortDir === "asc";
            return list.sort((a,b) => {
                let aValue = this.toLower(a.getSortColumn(sortParams.sortKey));
                let bValue = this.toLower(b.getSortColumn(sortParams.sortKey));
                if (aValue < bValue) {
                    return sortAsc ? -1 : 1;
                }
                return sortAsc ? 1 : -1;
            })
        }
        return list
    },
    getTUserFromTUserGroup(tUserGroup, user) {
        if (tUserGroup != null && user != null && user.isRealUser()) {
            // find group with own registration
            let tUsers = tUserGroup.getTournamentUserIDs()
            if (tUsers != null) {
                for (let i = 0; i < tUsers.length; i++) {
                    if (tUsers[i].getPlayerEmail() === user.getEmail()) {
                        return tUsers[i]
                    }
                }
            }
        }
        return null
    }
};

module.exports = ParseListUtility;
