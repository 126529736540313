/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Button, Alert} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput");

const userActions = require("actions/UserActions"),
    adminUserActions = require("actions/AdminUserActions");

class ChangePasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.refPassword = React.createRef();
        this.refPassword2 = React.createRef();
        
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.setError = this.setError.bind(this);
        this.state = {
            showModal: this.props.open ? this.props.open : false,
            submitDisabled: false,
            error: null,
        }
    }
    open() {
        this.setState({
            showModal: true,
        });
    }
    close() {
        this.setState({showModal: false, error: null, submitDisabled: false});
    }
    clickedSave() {
        // verify password
        this.setState({submitDisabled: true});
        const pwd = this.refPassword.current.getValue().trim();
        const pwd2 = this.refPassword2.current.getValue().trim();
        if (pwd2.length == 0 ||  pwd.length == 0) {
            this.setError(messages.get("modal.login.error.missing.required"));
            return;
        }
        if (pwd !== pwd2) {
            this.setError(messages.get("modal.login.error.password.match"));
            return;
        }
        if (this.props.isAdmin) {
            // save other user
            adminUserActions.updateUserPassword(this.props.user, pwd, (success) => {
                // save success
                if (this.props.successPasswordChanged) {
                    this.props.successPasswordChanged(true)
                }
                this.close();
            });
        } else {
            // save own user
            userActions.updateUserPassword(this.props.user, pwd, (success) => {
                // save success
                if (this.props.successPasswordChanged) {
                    this.props.successPasswordChanged(true)
                }
                this.close();
            });
        }

    }
    setError(errorMsg) {
        this.setState({
            error: errorMsg,
            submitDisabled: false
        })
    }
    render() {
        return (<React.Fragment>
            <Button bsStyle="success" onClick={this.open}><FontAwesome icon="unlock"/><I18n code="modal.login.password.change"/></Button>
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <TextCenter><I18n code="modal.login.password.change"/></TextCenter>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    <Col xs={12}>
                                        <p><I18n code="modal.login.password.enterNew"/>:</p>
                                        <ValidInput ref={this.refPassword}
                                                    type="password"
                                                    placeholder={messages.get("modal.login.password")}/>
                                        <ValidInput ref={this.refPassword2}
                                                    type="password"
                                                    placeholder={messages.get("modal.login.password.repeat")}/>
                                    </Col>
                                </Row>
                                <Row>
                                    {this.state.error == null ? null : <Col xs={12}>
                                            <Alert bsStyle="danger">{this.state.error}</Alert>
                                        </Col>
                                    }
                                    <Col xs={12}>
                                        <Button onClick={this.clickedSave} disabled={this.state.submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                </Row>

                            </form> : null
                    }
                </Modal.Body>
            </Modal></React.Fragment>
        );
    }
}
ChangePasswordModal.propTypes = {
    user:PropTypes.object.isRequired,
    successPasswordChanged: PropTypes.func,
    open: PropTypes.bool,
    isAdmin: PropTypes.bool
};
module.exports = ChangePasswordModal;