const React = require("react"),
    PropTypes = require('prop-types');
const { Row, Col, Alert, Modal, Button, InputGroup, FormGroup, FormControl} = require("react-bootstrap");
const DropDownSearchParseObject = require("components/form/DropDownSearchParseObject"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    TextCenter = require("components/widgets/TextCenter"),
    I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading");
const messages = require("i18n/messages");
const parcoursActions = require("actions/ParcoursActions");

class ParcoursSelection extends React.Component {
    constructor(props) {
        super(props);
        this.refParcoursSelModal = React.createRef();

        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.state = {
            optionList: null,
            mainGroupPlace: this.props.mainGroupPlace,
            regionGroupPlace: this.props.regionGroupPlace
        }
    }
    getDefaultValue() {
        if (this.props.parcours) {
            return this.props.parcours.buildNames() + "," + this.props.parcours.getZipPlace();
        }
        return messages.get("tournament.manager.config.parcours");
    }
    onSearch() {
        if (this.state.optionList == null) {
            parcoursActions.queryParcoursForGroupPlace(this.props.mainGroupPlace, this.props.regionGroupPlace, (parcoursList) => {
                this.setState({optionList: parcoursList})
            });
            this.state.mainGroupPlace.fetch()
                .then(fGroupPlace => {
                    this.setState({mainGroupPlace: fGroupPlace})
                });
            this.state.regionGroupPlace.fetch()
                .then(fGroupPlace => {
                    this.setState({regionGroupPlace: fGroupPlace})
                });
        }
        this.refParcoursSelModal.current.open();
    }
    handleParcoursChange(parcours) {
        if (parcours && this.props.onAfterChange) {
            console.log("SEL " + parcours.getName());
            this.props.onAfterChange(parcours);
        }
    }
    render() {
        return (
            <ErrorBoundary>
                <FormGroup>
                    <InputGroup>
                        <ParcoursSelectionModal ref={this.refParcoursSelModal}
                                                desc={this.props.desc}
                                                parcours={this.props.parcours}
                                                onAfterChange={this.props.onAfterChange}
                                                optionList={this.state.optionList}
                                                mainGroupPlace={this.state.mainGroupPlace}
                                                regionGroupPlace={this.state.regionGroupPlace}/>
                        <FormControl disabled={true} value={this.getDefaultValue()}/>
                        <InputGroup.Button>
                            <Button onClick={this.onSearch}>
                                <FontAwesome icon="search"/>
                            </Button>
                        </InputGroup.Button>
                    </InputGroup>
                </FormGroup>
            </ErrorBoundary>)

    }
}
ParcoursSelection.propTypes = {
    onAfterChange:PropTypes.func.isRequired,
    mainGroupPlace: PropTypes.object.isRequired,
    regionGroupPlace: PropTypes.object.isRequired,
    desc: PropTypes.object.isRequired,
    parcours: PropTypes.object
};
class ParcoursSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.state = {
            showModal: false,
            submitDisabled: false,
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error:null});
    }
    open() {
        this.setState({showModal: true});
    }
    handleParcoursChange(parcours) {
        if (parcours && this.props.onAfterChange) {
            console.log("SEL " + parcours.getSelectString());
            this.props.onAfterChange(parcours);
            this.close();
        }
    }
    render() {
        const {parcours, desc, optionList, mainGroupPlace, regionGroupPlace} = this.props;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.config.parcours"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            {
                                this.state.showModal ?
                                    <Col sm={12}>
                                        {desc}
                                        <br/><br/>
                                        {
                                            optionList == null ?
                                                <Loading/>
                                                :
                                                <React.Fragment>
                                                    <h4 key="grpPlc"><strong>{mainGroupPlace.getName()}</strong>{ " - " + regionGroupPlace.getName() + ": "}</h4>
                                                    <DropDownSearchParseObject key="parcourssel"
                                                                               default={parcours}
                                                                               placeholder={messages.get("tournament.manager.config.parcours")}
                                                                               onAfterChange={this.handleParcoursChange}
                                                                               objectList={optionList}/>
                                                </React.Fragment>

                                        }
                                    </Col> : null
                            }
                        </Row>
                    </Modal.Body>
                </form>
            </Modal>
        )
    }
}
ParcoursSelectionModal.propTypes = {
    onAfterChange:PropTypes.func.isRequired,
    mainGroupPlace: PropTypes.object.isRequired,
    regionGroupPlace: PropTypes.object.isRequired,
    desc: PropTypes.object.isRequired,
    parcours: PropTypes.object,
    optionList: PropTypes.array
};
module.exports = ParcoursSelection;
