const alt = require("AltInit");
const actions = require("actions/TransactionActions");

class TransactionStore {
    constructor() {
        this.displayName = "TransactionStore";
        this.management = null;
        this.transactionStat = null;
        this.transactionSettlement = null;
        this.ccLogs = null;
        this.transactions = null;
        this.transactionsPositions = {};
        this.transaction = null;
        this.loadingT = true;
        this.loadingS = true;
        this.bindListeners({
            queryTransactions: actions.queryTransactions,
            queryTransactionSettlements: actions.queryTransactionSettlements,
            updateCCLogs: actions.updateCCLogs,
            updateTransactions: actions.updateTransactions,
            updateTransactionPositions: actions.updateTransactionPositions,
            updateTransaction: actions.updateTransaction,
            updateManagement: actions.updateManagement,
            updateSettlement: actions.updateSettlement
        });
    }
    queryTransactions() {
        this.loadingT = true;
    }
    queryTransactionSettlements() {
        this.loadingS = true;
    }

    updateTransaction(pTransaction) {
        this.loadingT = false;
        this.transaction = pTransaction;
    };
    updateCCLogs(ccLogs) {
        this.ccLogs =  ccLogs;
    };
    updateTransactions(transResult) {
        this.loadingT = false;
        this.transactionStat = transResult.stat;
        this.transactions = transResult.transactions;
        this.transactionsPositions = {}
    };
    updateTransactionPositions({transactionID, positions}) {
        this.transactionsPositions[transactionID] = positions
    }
    updateManagement(management) {
        this.management =  management;
    };
    updateSettlement(settlement) {
        this.loadingS = false;
        this.transactionSettlement = settlement;
    };
}
module.exports = alt.createStore(TransactionStore);
