const React = require("react"),
    PropTypes = require('prop-types');
const Icons = require("components/widgets/Icons");

function TournamentAttendeeStatus ({tournament, tournamentUser}) {
    if (tournament.isPay4Start()) {
        if (tournamentUser.isPaid()) {
            // paid ones
            return <Icons.attendee.paid/>
        } else {
            // not paid
            if (tournamentUser.startNumber > tournament.getRegMaxUser()) {
                // max reached - show exceed
                return <Icons.attendee.exceed/>
            } else {
                // waiting
                return <Icons.attendee.pending/>
            }
        }
    } else {
        return null
    }
}
TournamentAttendeeStatus.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUser:PropTypes.object.isRequired
};
module.exports = TournamentAttendeeStatus;
