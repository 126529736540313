const React = require("react"),
    PropTypes = require('prop-types');
const {Alert, Row, Col, Label, Image} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    PanelCollapse = require("components/widgets/PanelCollapse"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    SubmitButton = require("components/form/SubmitButton"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    DelayedTextField = require("components/form/DelayedTextField"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    Loading = require("components/widgets/Loading");

const CountTypeCreate= require("components/skillboard/CountTypeCreate");

const sKillBoardStore = require("stores/SKillBoardStore"),
    sKillBoardActions = require("actions/SKillBoardActions"),
    changeLogActions = require("actions/ChangeLogActions");



class CountTypesTab extends React.Component {
    constructor(props) {
        super(props);
        this.handleSKillBoardChange = this.handleSKillBoardChange.bind(this);
        this.state = {
            sKillBoardStore: sKillBoardStore.getState()
        }
    }
    componentDidMount() {
        sKillBoardStore.listen(this.handleSKillBoardChange);
        sKillBoardActions.showCountTypes();
    }
    componentWillUnmount() {
        sKillBoardStore.unlisten(this.handleSKillBoardChange);
    }
    handleSKillBoardChange(state) {
        this.setState({sKillBoardStore: state});
    }
    render() {
        const {countTypes, countTypeGroups} = this.state.sKillBoardStore;
        const {tstat} = this.props;
        if (countTypes == null || countTypeGroups == null) {
            return <Loading/>
        }
        return <CountTypeList countTypes={countTypes} allCountTypeGroups={countTypeGroups} isAdmin={tstat == "admin"}/>
    }
}

const CountTypeList = ({countTypes, allCountTypeGroups, isAdmin}) => {
    const searchForCountTypes = (searchText) => {
        sKillBoardActions.searchCountTypes({text: searchText});
    };
    console.log("CountTypeList", isAdmin)
    return (
        <React.Fragment>
            <br/><br/>
            <Row>
                <Col sm={6}>
                    <DelayedTextField placeholder={messages.get("countType.search.placeholder")}
                                      onChange={searchForCountTypes}/>
                </Col>
                <CountTypeCreate/>
            </Row>
            <br/>
            {
                allCountTypeGroups.length == 0 ?
                    <Alert bsStyle="warning"><I18n code="list.entry.empty"/></Alert>
                    :
                    allCountTypeGroups.map(countTypeGroup => {
                        return <CountTypeGroupItem
                            key={countTypeGroup.id}
                            editable={isAdmin && countTypeGroup.isEditable()}
                            countTypes={countTypes}
                            allCountTypeGroups={allCountTypeGroups}
                            countTypeGroup={countTypeGroup}/>
                    })
            }
        </React.Fragment>)
};
CountTypeList.propTypes = {
    countTypes:PropTypes.array.isRequired,
    countTypeGroups:PropTypes.array.isRequired
};
const CountTypeGroupItem = ({countTypes, countTypeGroup, allCountTypeGroups, editable}) => {
        const groupID = countTypeGroup.getTypeID();
        const countTypeGroupList = countTypes.filter(countType => {
            return countType.getTypeID() == groupID;
        });
        return (
            <Row>
                <Col xs={12}>
                    <PanelCollapse  header={<h2><I18n code={countTypeGroup.getName()}/></h2>}>
                        {
                            countTypeGroupList.map(countType => (<CountTypeItem key={countType.id} countType={countType}
                                                                                allCountTypeGroups={allCountTypeGroups}
                                                                                editable={editable}/>))
                        }
                    </PanelCollapse>
                </Col>
            </Row>)
};
CountTypeGroupItem.propTypes = {
    countTypeGroup:PropTypes.object.isRequired,
    countTypes:PropTypes.array.isRequired
};
const CountTypeItem = ({countType, allCountTypeGroups, editable}) => {
    function deleteCountType() {
        const creator = countType.isDraft() ? countType.getCreator() : null;
        changeLogActions.handleDeleteParseObjectForCL(countType, null, creator, objCallback => {
            // changed countTypeObject
            sKillBoardActions.addCountType(objCallback)
        })
    }
    function acceptCountType() {
        changeLogActions.handleAcceptParseObjectForCL(countType, null, countType.getCreator(), objCallback => {
            // changed countTypeObject
            sKillBoardActions.addCountType(objCallback)
        });
    }
    function handleCountTypeGroup(group) {
        countType.setTypeID(group.getTypeID());
        sKillBoardActions.saveCountType(countType)
    }
    const arrow1 = countType.getZonePoints(1);
    const arrow2 = countType.getZonePoints(2);
    const arrow3 = countType.getZonePoints(3);
    const src = countType.getScorepicUrl();
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <h3>
                        <StrikeOutText strikeOut={countType.isDeleted()}><I18n code={countType.getName()}/></StrikeOutText>
                        &nbsp;&nbsp;
                        {
                            countType.isDraft() ? <Label><I18n code="tournament.live.draft"/></Label> : null
                        }
                    </h3>
                    {countType.getDescription() ? <p>{countType.getDescription()}</p> : null}

                </Col>
            </Row>
            {
                editable ? <Row xs={12}>
                    <Col xs={6} lg={4}>
                        { !countType.isDeleted() ? <SubmitButton onClick={deleteCountType} confirm={true}
                                                                 icon={<FontAwesome icon="times"/>}
                                                                 text={messages.get("modal.button.delete")}
                                                                 bsStyle="default" bsSize="small"/> : null}
                        { countType.isDraft() ? <SubmitButton onClick={acceptCountType} confirm={true}
                                                              icon={<FontAwesome icon="check"/>}
                                                              text={messages.get("modal.button.activate")}
                                                              bsStyle="default" bsSize="small"/> : null}
                    </Col>
                    <Col xs={6} lg={8}>
                        <DropDownParseObject placeholder={<option key={"nullkey"} value={"null"}>{"Change group to ..."}</option>}
                                             onAfterChange={handleCountTypeGroup} label={countType.id}
                                             objectList={allCountTypeGroups}/>
                    </Col>
                </Row> : null
            }
            <Row>
                <Col sm={6}>
                    {
                        arrow1 != null && arrow1.length > 0 ?
                            <p><strong>{"1. " + messages.get("ResultStatBean_arrow") + ": "}</strong>{arrow1}</p>
                            : null
                    }
                    {
                        arrow2 != null && arrow2.length > 0 ?
                            <p><strong>{"2. " + messages.get("ResultStatBean_arrow") + ": "}</strong>{arrow2}</p>
                            : null
                    }
                    {
                        arrow3 != null && arrow3.length > 0 ?
                            <p><strong>{"3. " + messages.get("ResultStatBean_arrow") + ": "}</strong>{arrow3}</p>
                            : null
                    }
                    <br/>
                    {
                        countType.getMissPoints() != 0 ?
                            <p><FontAwesome
                                icon="exclamation"/><strong>{countType.getMissPoints()}</strong>&nbsp;{messages.get("Count_type_miss_points")}
                            </p>
                            : null
                    }
                    {
                        arrow2 != null && arrow2.length > 1 ?
                            <p><FontAwesome
                                icon="exclamation"/>{messages.get("countType.arrowSumUp." + countType.getArrowSumUp())}
                            </p>
                            : null

                    }

                </Col>
                <Col sm={6}>
                    {
                        src != null ? <Image src={src} rounded responsive/> : null
                    }
                </Col>
            </Row>
            <hr/>
        </React.Fragment>)
};
CountTypeItem.propTypes = {
    countType:PropTypes.object.isRequired
};
module.exports = CountTypesTab;
