const Parse = require("ParseInit");

const {Tournament, TournamentRound, TournamentUser, TournamentUserGroup, TournamentUserGroupLive} = require("parse/_Domain");

class TournamentLiveDao {


    loadTournamentLiveDetails(id) {
        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.tournamentConfigAge);
        return query.get(id);
    };

    loadTournamentRounds(tournament) {
        const query = new Parse.Query(TournamentRound);
        query.notContainedIn(TournamentRound.prototype.col.status,[TournamentRound.prototype.status.deleted, TournamentRound.prototype.status.inactive]);
        query.equalTo(TournamentRound.prototype.col.tournamentID, tournament);
        query.ascending(TournamentRound.prototype.col.sortIndex);
        return query.find();
    };

    loadTournamentGroupResult(tournament, tournamentRound) {
        const query = new Parse.Query(TournamentUserGroup);
        query.containedIn(TournamentUserGroup.prototype.col.status, [TournamentUser.prototype.status.finishedRound, TournamentUser.prototype.status.proofSingleResult, TournamentUser.prototype.status.started]);
        query.equalTo(TournamentUserGroup.prototype.col.tournamentID, tournament);
        query.equalTo(TournamentUserGroup.prototype.col.tournamentRoundID, tournamentRound);
        const now = new Date();
        query.greaterThan("updatedAt", new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
        query.lessThan("updatedAt", new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0));
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs);
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigBow + ".countTypeID");
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigBow + ".countTypeID.countTypeDtls");
        query.descending(TournamentUserGroup.prototype.col.actTargetAmount);
        return query.find();
    }

    loadTournamentGroups(tournament, tournamentRound) {
        const query = new Parse.Query(TournamentUserGroup);
        query.notEqualTo(TournamentUserGroup.prototype.col.status, "D");
        query.equalTo(TournamentUserGroup.prototype.col.tournamentID, tournament);
        if (tournamentRound != null) {
            query.equalTo(TournamentUserGroup.prototype.col.tournamentRoundID, tournamentRound);
        }
        query.exists(TournamentUserGroup.prototype.col.tournamentUserIDs);
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs);
        query.include(TournamentUserGroup.prototype.col.tournamentUserIDs + "." + TournamentUser.prototype.col.tournamentConfigBow);
        query.addAscending(TournamentUserGroup.prototype.col.startPosition);
        query.addAscending(TournamentUserGroup.prototype.col.name);
        query.addDescending(TournamentUserGroup.prototype.col.createdAt);

        return query.find();
    }

    loadTUGroupLive(tournamentGroup, tournamentRound) {
        const query = new Parse.Query(TournamentUserGroupLive);
        query.equalTo(TournamentUserGroupLive.prototype.col.tournamentGroupID, tournamentGroup);
        query.equalTo(TournamentUserGroupLive.prototype.col.tournamentRoundID, tournamentRound);
        query.addAscending(TournamentUserGroupLive.prototype.col.actTargetNumber);
        query.notEqualTo(TournamentUserGroupLive.prototype.col.status, "D");
        return query.find();
    }
}

module.exports = new TournamentLiveDao();
