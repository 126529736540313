const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel} = require("react-bootstrap");

const TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentLink = require("components/tournament/TournamentLink"),
    StrikeOutText = require("components/widgets/StrikeOutText");

const TournamentTeaserItem = ({tournament}) => {
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={8} md={10} lg={4}>
                        <h4>
                            <TournamentLink tournament={tournament}
                                            label={<StrikeOutText strikeOut={tournament.isCanceled()}>{tournament.getName()}</StrikeOutText>}/>
                        </h4>
                    </Col>
                    <Col xs={4} md={2} lg={2}>
                        <div className="text-right">
                            <TournamentStatus tournament={tournament}/>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={3}>
                        <a target="_blank" href={tournament.getUnionLink()}>{tournament.getUnion()}</a>
                    </Col>
                    <Col xs={6} md={3} lg={1}>
                        {tournament.getTournamentDate()}
                    </Col>
                    <Col xs={6} md={3} lg={2}>
                        <div className="text-right">
                            {tournament.getZip()} {tournament.getPlace()}
                        </div>
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
TournamentTeaserItem.propTypes = {
    tournament: PropTypes.object.isRequired
};
module.exports = TournamentTeaserItem;
