const alt = require("AltInit");
const calActions = require("actions/CalendarActions");
const PLUtil = require("util/ParseListUtility");
const CalendarUtility = require("util/CalendarUtility")

class CalendarStore {
    constructor() {
        this.displayName = "CalendarStore";
        this.calEvents = [];
        this.searchParams = {
            searchText: "",
            sortParams : {
                sortKey: null,
                sortDir: "asc"
            }
        };
        this.invalidUser = false;
        this.eventLoading = false;
        this.selectedCalEvent = null;
        this.calendarBoundaries = CalendarUtility.calcCalendarBoundaries(new Date());
        this.bindListeners({
            updateCalendarEvents: calActions.updateCalendarEvents,
            updateSearchParams: calActions.updateSearchParams,
            updateCalendarEvent: calActions.updateCalendarEvent,
            removeCalendarEvent: calActions.removeCalendarEvent,
            updateEventLoading: calActions.updateEventLoading,
            updateCalendarBoundaries: calActions.updateCalendarBoundaries,
            setSelectedCalEvent: calActions.setSelectedCalEvent,
            setInvalidUser: calActions.setInvalidUser
        });
    }

    updateEventLoading(loading) {
        this.eventLoading = loading;
    }
    updateCalendarEvents(pCalEvents) {
        this.eventLoading = false;
        this.calEvents = pCalEvents;
    }
    updateCalendarEvent(clubEvent) {
        this.updateCalendarEvents(PLUtil.updateOrPush(this.calEvents, clubEvent));
    }

    removeCalendarEvent(clubEvent) {
        this.updateCalendarEvents(
            this.calEvents.filter(function (u) {
                return u.id != clubEvent.id;
            })
        );
    }

    updateSearchParams(params) {
        this.searchParams = params;
    }

    setSelectedCalEvent(calEvent) {
        if (this.selectedCalEvent != null && calEvent != null
            && this.selectedCalEvent.id === calEvent.id) {
            // unselected if already select
                this.selectedCalEvent = null;
        } else {
            this.selectedCalEvent = calEvent
        }
    }

    setInvalidUser() {
        this.eventLoading = false;
        this.invalidUser = true;
        this.selectedCalEvent = null;
    }
    updateCalendarBoundaries(date) {
        this.calendarBoundaries = CalendarUtility.calcCalendarBoundaries(date);
    }
}
module.exports = alt.createStore(CalendarStore);
