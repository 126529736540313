const Parse = require("ParseInit");

const EventTrainingMember = Parse.Object.extend("EventTrainingMember", {
    col: {
        name: "name",
        mail: "mail",
        trainingTeamID: "trainingTeamID",
        userID: "userID",
        status: "status",
    },
    status: {
        active: "A",
        invited: "I",
        deleted: "D"
    },
    arrow: 0,
    duration: 0,
    //
    //
    //
    getName() {
        return this.get(this.col.name)
    },
    getTeamName() {
        const team = this.getTrainingTeam();
        if (team) {
            return team.getName();
        }
        return "-"
    },
    getLabels() {
        return this.getName()
    },
    getSortColumn(colName) {
        if (colName === "arrow") {
            return this.arrow
        } else if (colName === "dur") {
            return this.duration
        }
        return this.get(colName)
    },
    getUserID() {
        return this.get(this.col.userID)
    },
    getMail() {
        return this.get(this.col.mail)
    },
    getTrainingTeam() {
        return this.get(this.col.trainingTeamID)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    isInvited() {
        return this.status.invited === this.getStatus()
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        this.set(this.col.status, status)
    }
});

module.exports = EventTrainingMember;
