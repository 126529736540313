const React = require("react"),
    PropTypes = require('prop-types');
const {InputGroup, Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const Typeahead = require('react-bootstrap-typeahead').Typeahead;

class SelectInputTypeAhead extends React.Component {
    constructor(props) {
        super(props);
        this.searchElement = null;
        this.setSearchElementRef = element => {
            this.searchElement = element;
        };
        this.handleChange = this.handleChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.handleSubmitE = this.handleSubmitE.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.state = {
            value: null
        };
    }

    handleChange(selObjectsArr) {
        if (selObjectsArr.length == 1) {
            this.props.handleSelectedObject(selObjectsArr[0]);
        }
    }
    onInputChange(event) {
        // TODO to know on enter was has been entered
        this.setState({value: event})
    }
    handleSubmitE(event) {
        event.preventDefault();
        if (this.props.handleNoObjectFound) {
            console.log("Didnt found " + this.state.value);
            if (this.state.value) {
                this.props.handleNoObjectFound(this.state.value.trim());
            } else {
                this.props.handleNoObjectFound("");
            }

        }
    }
    clearInput() {
        if (this.searchElement) {
            this.searchElement.instanceRef.clear();
        }
    }
    render() {
        const {objectList, defaultValue, placeholder, disabled, handleNoObjectFound} = this.props;
        return  (
            <form onSubmit={this.handleSubmitE}>
                <InputGroup>
                    <InputGroup.Addon>
                        <FontAwesome icon="search"/>
                    </InputGroup.Addon>
                    <Typeahead ref={this.setSearchElementRef}
                               defaultInputValue={defaultValue}
                               disabled={disabled || objectList == null}
                               minLength={2}
                               labelKey={object => object.getDatalistText ? object.getDatalistText() : ""}
                               renderMenuItemChildren={(option, props, index) => {
                                   /* Render custom contents here. */
                                   let imgUrl = option.getDatalistImage();
                                   return <span>{option.getDatalistText()}&nbsp;&nbsp;&nbsp;{imgUrl != null ? <img src={imgUrl}  height="60"/> : null} </span>
                               }}
                               emptyLabel={false}
                               multiple={false}
                               options={objectList}
                               placeholder={placeholder}
                               onChange={this.handleChange}
                               onInputChange={this.onInputChange}
                               submitFormOnEnter={handleNoObjectFound != null}
                    />
                    {
                        handleNoObjectFound != null ?
                            <InputGroup.Button>
                                <Button bsStyle="primary" type="submit">
                                    <FontAwesome icon="level-down-alt" rotation={90}/>
                                </Button>
                            </InputGroup.Button> : null
                    }

                </InputGroup>
            </form>)
    }
}
SelectInputTypeAhead.propTypes = {
    placeholder:PropTypes.string,
    defaultValue: PropTypes.string,
    disabled:PropTypes.bool,
    handleNoObjectFound:PropTypes.func.isRequired,
    handleSelectedObject:PropTypes.func.isRequired,
    objectList:PropTypes.array.isRequired
};
module.exports = SelectInputTypeAhead;
