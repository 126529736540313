const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Panel, Label} = require("react-bootstrap");
const messages = require("i18n/messages");

const Title = require("components/widgets/Title"),
    GroupPlaces = require("components/widgets/GroupPlaces"),
    Loading = require("components/widgets/Loading"),
    DelayedTextField = require("components/form/DelayedTextField"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    I18n = require("components/widgets/I18n");

const ParcoursLink = require("components/parcours/ParcoursLink"),
    TournamentLink = require("components/tournament/TournamentLink");

const groupPlaceActions = require("actions/GroupPlaceActions"),
    newsStore = require("stores/NewsStore"),
    newsActions = require("actions/NewsActions");

const ListLayout = require("components/widgets/ListLayout");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleNewsChange = this.handleNewsChange.bind(this);
        this.noResults = this.noResults.bind(this);
        this.state = {
            newsStore: newsStore.getState(),
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.news");
        groupPlaceActions.reset();
        newsStore.listen(this.handleNewsChange);
        newsActions.searchNews(this.state.newsStore.searchParams);
    }
    componentWillUnmount() {
        newsStore.unlisten(this.handleNewsChange);
    }
    handleNewsChange(state) {
        this.setState({newsStore: state});
    }
    noResults() {
        return !(this.state.newsList != null && this.state.newsList.length == 0);
    }
    render() {
        return (
            <div>
                <Grid>
                    <Row>
                        <Col xs={12} xsHidden>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/news" code="header.nav.news" active/>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                    <Title code="news.title"/>
                    <ListLayout.Wrapper>
                        <ListLayout.Filter>
                            <NewsFilter  newsStore={this.state.newsStore}/>
                        </ListLayout.Filter>
                        <ListLayout.List>
                            {this.state.newsStore.newsList == null ?
                                <Loading/> : ""}
                            {this.state.newsStore.newsList != null && this.state.newsStore.newsList.length == 0 ?
                                <Alert bsStyle="warning"><I18n code="news.empty.result"/></Alert> : ""
                            }
                            {this.state.newsStore.newsList != null && this.state.newsStore.newsList.length > 0 ?
                                this.state.newsStore.newsList.map(news => {
                                    return <ErrorBoundary><NewsItem key={news.id} news={news}/></ErrorBoundary>
                                }) : ""
                            }
                        </ListLayout.List>
                    </ListLayout.Wrapper>
                </Grid>
            </div>)
    }
}
const NewsFilter = ({newsStore}) => {
    function handleGroupPlaceChange(mainGroupPlaceId, regionGroupPlaceId) {
        newsActions.showProgress();
        newsStore.searchParams.mainGroupPlaceID = mainGroupPlaceId;
        newsStore.searchParams.regionGroupPlaceID = regionGroupPlaceId;
        newsActions.searchNews(newsStore.searchParams);

    }
    function handleSearchTextChange(searchText) {
        const newSearchParams = newsStore.searchParams;
        newSearchParams.searchText = searchText;
        newsActions.showProgress();
        newsActions.searchNews(newSearchParams);
    }
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h4><strong><I18n code="news.button.search"/></strong></h4>
                </Col>
                <Col xs={12} sm={6} md={6} lg={12}>
                    <DelayedTextField defaultValue={newsStore.searchParams.searchText}
                                      placeholder={messages.get("news.button.search.placeholder")}
                                      onChange={handleSearchTextChange}/>
                </Col>
                <Col xsHidden  sm={6} md={6} lgHidden>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6} md={6} lg={12}>
                    <h4><I18n code="tournament.filter.region"/></h4>
                    <GroupPlaces handleChange={handleGroupPlaceChange}/>
                    <br/>
                </Col>
            </Row>
            <br/>
        </div>)
};
NewsFilter.propTypes = {
    newsStore: PropTypes.object.isRequired
};
const NewsItem = ({news}) => {
    function getObjectLink() {
        if (news.getTournamentID()) {
            return <TournamentLink tournament={news.getTournamentID()}
                                   label={news.getTournamentID().getName() + " - "
                                   + news.getTournamentID().getTournamentDate()}/>
        } else if (news.getParcoursID()) {
            return <ParcoursLink parcours={news.getParcoursID()}
                                 label={news.getParcoursID().buildNames()}/>
        } else if (news.getWeblink()) {
            return <a href={news.getWeblink()}>{ news.getMessage()}</a>
        } else {
            return news.getMessage();
        }
    }
    let label = null;
    if (news.getTranslationCode()) {
        label = <Label><I18n code={"news." + news.getTranslationCode()}/></Label>
    }
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <h4>{getObjectLink()}</h4>
                    </Col>
                    <Col xs={12} md={3}>
                        <h4>
                            {news.getNewsDate()}
                        </h4>
                    </Col>
                    <Col xs={12}>
                        {label != null ? (<span>{label} | </span>) : ""}
                        {news.getZip()} {news.getPlace()}
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
NewsItem.propTypes = {
    news: PropTypes.object.isRequired
};
module.exports = NewsPage;




