const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const managementDao = require("actdao/ManagementDao");
const parcoursDao = require("actdao/ParcoursDao");
const tournamentDao = require("actdao/TournamentDao");

class ParcoursMgmtActions {

    queryManagedParcours (user) {
        if (user != null) {
            managementDao.queryManagementsForEditor(user)
                .then(managements => {
                    this.updateManagements(managements);
                    var pOwners = [];
                    for (var i = 0; i < managements.length; i++) {
                        var mpOwners = managements[i].getParcoursOwnerID();
                        if (mpOwners != null) {
                            pOwners = pOwners.concat(mpOwners);
                        }
                    }
                    if (pOwners.length > 0) {
                        parcoursDao.getParcoursForPOwnerList(pOwners)
                            .then(parcours => {
                                this.groupManagedParcours(parcours);
                            })
                            .catch(error => {
                                error.trace = "PAMA.loadParc4OwnerList";
                                notificationActions.parseError(error);
                            });
                    } else {
                        this.updateParcoursDic(null);
                    }


                })
                .catch(error => {
                    error.trace = "PAMA.loadMgmt4Editor";
                    notificationActions.parseError(error);
                });
        } else {
            this.updateParcoursDic(null);
        }
        return {};
    }

    getParcoursHome(parcoursOwnerId, callbackParcours) {
        parcoursDao.getParcoursHome(parcoursOwnerId)
            .then(parcoursList => {
                if (parcoursList && parcoursList.length > 0) {
                    let parcoursOwner = parcoursList[0].getParcoursOwnerID();
                    callbackParcours({parcoursList, parcoursOwner});
                } else {
                    callbackParcours(null);
                }
            })
            .catch(error => {
                error.trace = "PAMA.loadParHome";
                notificationActions.parseError(error);
                callbackParcours(null)
            });
        return {};
    }

    getTournamentsHome(parcoursOwner, parcoursList, callbackTournaments) {
        tournamentDao.getTournamentsForParcoursHome(parcoursOwner, parcoursList)
            .then(tournaments => {
                callbackTournaments(tournaments)
            })
            .catch(error => {
                error.trace = "PAMA.loadTournamentsHome";
                notificationActions.parseError(error);
                callbackTournaments(null);
            });
        return {};
    }

    getParcoursWithArticles(parcoursOwnerId, callback) {
        parcoursDao.getParcoursWithArticles(parcoursOwnerId)
            .then(parcours => {
                callback(parcours);
            })
            .catch(error => {
                error.trace = "PAMA.loadParWArt";
                notificationActions.parseError(error);
                callback(null)
            });
        return {};
    }

    groupManagedParcours(pParcours) {
        var groupedParcours = {};
        for (var p = 0; p < pParcours.length; p++) {
            var key = pParcours[p].getParcoursOwnerID().id;
            var groupedList = groupedParcours[key];
            if (groupedList) {
                groupedList.push(pParcours[p]);
            } else {
                var newList = [];
                newList.push(pParcours[p]);
                groupedParcours[key] = newList;
            }
        }
        this.updateParcoursDic(groupedParcours);
        return {};
    }

    updateParcoursCC(object, dirtyKey, newValue) {
        parcoursDao.parseEditParcoursCC(object, dirtyKey, newValue)
            .then(savedObject => {
                if (newValue == null) {
                    // pointers seems not to be cleared if were unset only via cloud code
                    savedObject.unset(dirtyKey)
                }
                this.updateSavedObject(savedObject);
            })
            .catch(error => {
                error.trace = "PAMA.updateParcours";
                notificationActions.parseError(error);
            });
        return {};
    }


    updateParcoursDic(ownerParcoursDic) {
        return ownerParcoursDic;
    }

    updateManagements(managements) {
        return managements;
    }

    updateSavedObject(savedObject) {
        return savedObject;
    }
}

module.exports = alt.createActions(ParcoursMgmtActions);
