/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Alert, Checkbox} = require("react-bootstrap");
const messages = require("i18n/messages");
const dayCodes = ["day_mo","day_tu","day_we","day_th","day_fr","day_sa","day_su","day_ph"];

class ParcoursOpenDays extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.isOpen = this.isOpen.bind(this);
        this.state = {
            help: null
        }
    }
    handleChange(e) {
        if (this.props.parseSaveParcours) {
            var skipped = false;
            var changedKey = e.target.id;
            var oldValue = this.props.object.get(this.props.colName);
            var newValue = [];
            if (oldValue) {
                for (var i = 0; i < oldValue.length; i++) {
                    if (oldValue[i] === changedKey) {
                        // is in list - check if need to be skipped
                        if(!e.target.checked) {
                            // was checked - add key
                            skipped = true;
                        }
                    } else {
                        newValue.push(oldValue[i])
                    }
                }
            }
            if (!skipped) {
                newValue.push(changedKey);
            }
            this.props.parseSaveParcours(this.props.object, this.props.colName, oldValue, newValue, this);
        }
    }
    showHelpMessage(message) {
        this.setState({
            help: message
        });
        setTimeout(() => {
            this.showHelpMessage(null);
        }, 5000);
    }
    isOpen(dayCode) {
        if (this.props.items) {
            return (this.props.items.indexOf(dayCode) > -1)
        }
        return false;
    }
    render() {
        const {help} = this.state;
        const {parseSaveParcours} = this.props;
        return (<React.Fragment>
            <strong>{messages.get("ParcoursInfo_parown_openDays")}</strong>
            {
                dayCodes.map(dayCode=> {
                    return (<Checkbox key={dayCode} id={dayCode}
                                      defaultChecked={this.isOpen(dayCode)}
                                      onChange={parseSaveParcours ? this.handleChange : null}>
                        {messages.get(dayCode)}
                    </Checkbox>)
                })
            }
            {
                help == null ? null : <Alert bsStyle="success">{help}</Alert>
            }
        </React.Fragment>)
    }
}
module.exports = ParcoursOpenDays;