const DatePicker = require("components/zextern/DatePicker");
const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, ControlLabel} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const ValidInput = require("components/form/ValidInput");
const Utility = require("util/Utility");

class DateInput extends React.Component {
    constructor(props) {
        super(props);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);

        let date = null;
        if (this.props.default instanceof Date) {
            date = this.props.default.toISOString();
        } else {
            date = this.props.default
        }
        this.state = {
            fallback: false,
            value: date,
            style: null,
            help: ""
        };
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        });
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(value);

        }
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ fallback: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
    render() {
        let addonBefore = null;
        if (this.props.addonBefore != null) {
            addonBefore = <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
        } else {
            addonBefore = <InputGroup.Addon><FontAwesome icon="calendar-alt"/></InputGroup.Addon>
        }
        return (
            <FormGroup>
                {
                    this.props.label != null ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }
                <InputGroup>
                    {addonBefore}
                    {
                        this.state.fallback ?
                            <DateInputFallback defaultValue={this.props.default}
                                               disabled={this.props.disabled}
                                               handleChange={this.handleChange}
                                               addonBefore={this.props.addonBefore}/>
                            :
                            <DatePicker id="datepicker" dateFormat="DD.MM.YYYY" showClearButton={false}
                                        weekStartsOn={1}
                                        disabled={this.props.disabled}
                                        value={this.state.value}
                                        onChange={this.handleChange} />
                    }

                </InputGroup>
            </FormGroup>);
    }
}
DateInput.propTypes = {
    placeholder:PropTypes.string,
    default:PropTypes.string,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object
};
const DateInputFallback = ({defaultValue, disabled, handleChange, addonBefore}) => {
    function onAfterChange(value) {
        let startDate = Utility.validateDate(value);
        if (startDate != null) {
            handleChange(startDate, startDate.toISOString());
        }
    }
    let date = null;
    if (defaultValue instanceof Date) {
        date = Utility.getReadableDate(defaultValue);
    } else {
        date = defaultValue
    }
    return <ValidInput disabled={disabled}
                       default={date}
                       type="text"
                       onAfterChange={onAfterChange}
                       valid={{check:['isDate', 'isRequired']}}
                       addonBefore={addonBefore}/>
};
DateInputFallback.propTypes = {
    defaultValue:PropTypes.string,
    handleChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object
};
module.exports = DateInput;
