const alt = require("AltInit");
const PLUtil = require("util/ParseListUtility");
const action = require("actions/ParcoursTargetActions");

class ParcoursTargetStore {
    constructor() {
        this.parcoursTargets = [];
        this.loading = true;
        // target brand and catalog
        this.targetBrands = [];
        this.targetCatalog = [];
        this.allTargetCatalog = [];
        this.searchParams = {
            text: "",
            brandID: null
        };
        this.loadingCatalog = true;
        this.loadingEventTracks = true;
        this.eventTracks = [];
        this.mapEventTracks = [];
        this.newImportTargets=null;
        this.updateImportTarget=null;
        this.bindListeners({
            updateTargetBrands: action.updateTargetBrands,
            updateParcoursTargets:action.updateParcoursTargets,
            addParcoursTarget: action.addParcoursTarget,
            updateParcoursTarget:action.updateParcoursTarget,
            updateImportTargetCatalog:action.updateImportTargetCatalog,
            updateTargetCatalog:action.updateTargetCatalog,
            updateTargetInCatalog: action.updateTargetInCatalog,
            searchTargetCatalog: action.searchTargetCatalog,
            updateLoading: action.updateLoading,
            updateLoadingEventTracks: action.updateLoadingEventTracks,
            updateEventTracks: action.updateEventTracks,
            updateMapEventTracks: action.updateMapEventTracks
        });
    }

    updateLoading(loading) {
        this.loading = loading;
    }
    updateParcoursTargets(targetList) {
        this.parcoursTargets = targetList;
        this.loading = false;
    }


    addParcoursTarget(target) {
        let targetList = Object.assign([], this.parcoursTargets);
        PLUtil.pushObjSortedASC(targetList, target, this.compareTargetIndex);
        this.parcoursTargets = targetList;
        //const insIndex = this.findInsertIndex(target);
        //this.parcoursTargets.splice(insIndex, 0, target);
    }

    updateParcoursTarget(target) {
        this.updateParcoursTargets(PLUtil.updateOrPush(this.parcoursTargets, target));
    }

    compareTargetIndex(listEntry, newEntry) {
        return listEntry.getIndex() >= newEntry.getIndex()
    }

    findInsertIndex(target) {
        const length = this.parcoursTargets.length;
        for (let i = 0; i < length; i++) {
            if (this.parcoursTargets[i].getIndex() >= target.getIndex()) {
                return i;
            }
        }
        return length;
    }

    updateTargetBrands(brands) {
        this.targetBrands = brands;
    }

    updateLoadingEventTracks(loading) {
        this.loadingEventTracks = loading;
    }

    updateEventTracks(events) {
        this.eventTracks = events;
        this.loadingEventTracks = false;
    }

    searchTargetCatalog(searchParams) {
        this.searchParams = searchParams;
        this.filterTargetCatalog();
    }

    updateTargetCatalog(targetList) {
        console.log("update updateTargetCatalog");
        this.allTargetCatalog = targetList.map(function (target) {
            return target;
        });
        this.filterTargetCatalog();
        this.loadingCatalog = false;
    }

    filterTargetCatalog() {
        let preFilteredList = this.allTargetCatalog;
        if (this.searchParams.brandID) {
            preFilteredList = preFilteredList.filter(u => {
                return u.getBrandID().id == this.searchParams.brandID.id;
            })
        }
        if (this.searchParams.text.length > 0) {
            const searchString = this.searchParams.text.toLowerCase();
            this.targetCatalog = preFilteredList.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            this.targetCatalog = preFilteredList;
        }
    }

    updateTargetInCatalog(target) {
        this.updateTargetCatalog(PLUtil.updateOrPush(this.allTargetCatalog, target));
    }

    updateMapEventTracks({value, eventTrack}) {
        if (value === true) {
            this.mapEventTracks.push(eventTrack)
        } else {
            this.mapEventTracks = PLUtil.removeObj(this.mapEventTracks, eventTrack)
        }
    }

    updateImportTargetCatalog({newTargets, updateTargets}) {
        this.newImportTargets=newTargets;
        this.updateImportTarget=updateTargets;
    }
}

module.exports = alt.createStore(ParcoursTargetStore);
