const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button,Modal} = require("react-bootstrap");
const {Tournament, TournamentPrice, GroupPlace} = require("parse/_Domain");

const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DateInput = require("components/form/DateInput"),
    GroupPlaces = require("components/widgets/GroupPlaces");

const {isBefore} = require('date-fns');

const messages = require("i18n/messages");
const tournamentActions = require("actions/TournamentActions"),
    tournamentManagerActions = require("actions/TournamentManagerActions");

class TournamentCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.refUnion = React.createRef();
        this.refName = React.createRef();
        this.refDate = React.createRef();
        this.refEndDate = React.createRef();
        this.refDays = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();
        this.refUnionLink = React.createRef();

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.edit = this.edit.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.getDefaultUnion = this.getDefaultUnion.bind(this);
        this.getDefaultWebLink = this.getDefaultWebLink.bind(this);
        this.getDefaultValueWithParcours = this.getDefaultValueWithParcours.bind(this);
        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.getDefaultDate = this.getDefaultDate.bind(this);

        let tMainGroupPlace= null;
        let tRegionGroupPlace= null;
        if (this.props.parcours) {
            tMainGroupPlace = this.props.parcours.getMainGroupPlaceID();
            tRegionGroupPlace = this.props.parcours.getGroupPlaceID();
        }
        this.state = {
            mainGroupPlace: tMainGroupPlace,
            regionGroupPlace: tRegionGroupPlace,
            showModal: false,
            submitDisabled: false,
            missingRegion: false
        };
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    open() {
        this.setState({
            showModal: true,
            tournament: null
        });
    }
    edit(tournament) {
        this.setState({
            showModal: true,
            tournament: tournament,
            mainGroupPlace: tournament.getMainGroupPlaceID(),
            regionGroupPlace: tournament.getGroupPlaceID()
        });
    }
    valid() {
        let valid = true;
        if (this.state.mainGroupPlace == null || this.state.regionGroupPlace == null) {
            this.setState({missingRegion: true});
            valid = false;
        }
        const required = [
            this.refName.current.getValue(),
            this.refUnion.current.getValue(),
            this.refDate.current.getValue(),
            this.refEndDate.current.getValue(),
            this.refZip.current.getValue(),
            this.refPlace.current.getValue(),
            this.refDays.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        const startDateParts = new Date(this.refDate.current.getValue());
        const startDate = new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0);

        const endDateParts = new Date(this.refEndDate.current.getValue());
        const endDate = new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), 23, 59, 0, 0);
        if (isBefore(endDate, startDate)) {
            this.setState({error: messages.get("tournament.create.date.before")});
            return false;
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let tournament = this.state.tournament;
        if (!tournament) {
            tournament = new Tournament();
            tournament.setCreator(this.props.user);
            tournament.setStatus(Tournament.prototype.status.published);
            tournament.setRegistrationStatus(Tournament.prototype.registrationStatus.published);
        }

        tournament.setName(this.refName.current.getValue());
        tournament.setUnion(this.refUnion.current.getValue());

        const url = this.refUnionLink.current.getValue();
        if (url) {
            tournament.setUnionLink(url);
        }
        const startDateParts = new Date(this.refDate.current.getValue());
        tournament.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), 4, 0, 0, 0));

        const endDateParts = new Date(this.refEndDate.current.getValue());
        tournament.setEndDate(new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), 23, 59, 0, 0));

        if (typeof this.state.mainGroupPlace === 'string') {
            const mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = this.state.mainGroupPlace;
            tournament.setMainGroupPlaceID(mainGroupPlace);
        } else {
            tournament.setMainGroupPlaceID(this.state.mainGroupPlace);
        }
        if (typeof this.state.regionGroupPlace === 'string') {
            const regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = this.state.regionGroupPlace;
            tournament.setGroupPlaceID(regionGroupPlace);
        } else {
            tournament.setGroupPlaceID(this.state.regionGroupPlace);
        }

        tournament.setZip(this.refZip.current.getValue());
        tournament.setPlace(this.refPlace.current.getValue());
        tournament.setDuration(parseInt(this.refDays.current.getValue()));
        if (this.props.parcours && this.props.parcours.getParcoursOwnerID()) {
            // parcours and owner ok
            tournament.setParcoursID(this.props.parcours);
            const owner = this.props.parcours.getParcoursOwnerID();
            if (owner.getBannerImageID()) {
                // use banner
                tournament.setBannerImageID(owner.getBannerImageID());
            }
            const mgmt = owner.getManagementID();
            if (mgmt) {
                // set values from management
                tournament.setManagementID(mgmt);
                tournament.setMainEditor(mgmt.getMainEditor());
                tournament.setAddEditor(mgmt.getAddEditor());
            }
            if (owner.getContactMail()) {
                let tPrice = tournament.getTournamentPriceID();
                if (tPrice == null) {
                    tPrice = new TournamentPrice();
                    tPrice.setStatus("A");
                    tournament.setTournamentPriceID(tPrice)
                }
                tPrice.setEmail(owner.getContactMail())
            }

        }
        if (tournament.isNew()) {
            // create and forward to detail page
            tournamentActions.createTournament(tournament);
        } else {
            // update and close modal
            tournamentManagerActions.saveTournamentCB(tournament, () => {
                this.close();
            })
        }

    }
    handleGroupPlaceChange(mainGroupPlace, regionGroupPlace) {
        this.setState({
            mainGroupPlace: mainGroupPlace,
            regionGroupPlace: regionGroupPlace
        });
    }
    handleStartDateChange(value) {
        this.refEndDate.current.setValue(value);
    }
    handleEndDateChange(value) {
        const startDate= new Date(this.refDate.current.getValue());
        const endDate= new Date(value);
        // calc duration in days
        const diff = parseInt((endDate - startDate) / 86400000);
        if (diff >= 0) {
            this.refDays.current.setValue(diff + 1);
        } else {
            this.refEndDate.current.setValue(this.refDate.current.getValue());
            this.refDays.current.setValue(1);
        }

    }
    getDefaultUnion() {
        let value = this.getDefaultValue(Tournament.prototype.col.union, null);
        if (!value && this.props.parcours && this.props.parcours.getParcoursOwnerID()) {
            value = this.props.parcours.getParcoursOwnerID().getName();
        }
        return value;
    }
    getDefaultWebLink() {
        let value = this.getDefaultValue(Tournament.prototype.col.unionLink, null);
        if (value) {
            return value;
        } else if (this.props.parcours && this.props.parcours.getParcoursOwnerID()) {
            return this.props.parcours.getParcoursOwnerID().getWeblink();
        }
        return "http://";
    }
    getDefaultValueWithParcours(column) {
        let value = this.getDefaultValue(column, null);
        if (!value && this.props.parcours && this.props.parcours.getParcoursOwnerID()) {
            value = this.props.parcours.getParcoursOwnerID().get(column);
        }
        return value;
    }
    getDefaultValue(column, defValue) {
        if (this.state.tournament != null && this.state.tournament.get(column) != null) {
            return this.state.tournament.get(column) + "";
        }
        return defValue;
    }
    getDefaultDate(column) {
        if (this.state.tournament != null) {
            return this.state.tournament.get(column);
        }
        return new Date();
    }
    render() {
        const validateNow = this.state.error && this.state.error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter>
                            {
                                this.state.tournament ?
                                    <I18n code="tournament.manager.editMaster"/>
                                    :
                                    <I18n code="tournament.create.title"/>
                            }
                            </TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        {
                            this.state.showModal ?
                                <Row>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refName}
                                                    default={this.getDefaultValue(Tournament.prototype.col.name, null)}
                                                    valid={{maxLength: 500, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.create.name")}/>
                                        <ValidInput ref={this.refUnion}
                                                    valid={{maxLength: 500, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultUnion()}
                                                    placeholder={messages.get("tournament.create.union")}/>
                                        <ValidInput ref={this.refUnionLink}
                                                    valid={{maxLength: 500, check: ['isUrl']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultWebLink()}
                                                    label={messages.get("tournament.create.union.link")}
                                                    addonBefore={<FontAwesome icon="globe"/>}/>
                                        <GroupPlaces handleChange={this.handleGroupPlaceChange}
                                                     mainGroupPlace={this.state.mainGroupPlace}
                                                     regionGroupPlace={this.state.regionGroupPlace}
                                                     bsStyle={this.state.missingRegion?"warning":undefined}/>
                                        <br/>
                                    </Col>
                                    <Col sm={4}>
                                        <ValidInput ref={this.refZip}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultValueWithParcours(Tournament.prototype.col.zip)}
                                                    placeholder={messages.get("tournament.create.zip")}/>
                                    </Col>
                                    <Col sm={8}>
                                        <ValidInput ref={this.refPlace}
                                                    valid={{maxLength: 200, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultValueWithParcours(Tournament.prototype.col.place)}
                                                    placeholder={messages.get("tournament.create.place")}/>
                                    </Col>
                                    <Col sm={5}>
                                        <DateInput ref={this.refDate}
                                                   default={this.getDefaultDate(Tournament.prototype.col.date)}
                                                   label={messages.get("tournament.create.date")}
                                                   onAfterChange={this.handleStartDateChange}/>
                                    </Col>
                                    <Col sm={5}>
                                        <DateInput ref={this.refEndDate}
                                                   default={this.getDefaultDate(Tournament.prototype.col.endDate)}
                                                   label={messages.get("tournament.create.enddate")}
                                                   onAfterChange={this.handleEndDateChange}/>
                                    </Col>
                                    <Col sm={2}>
                                        <ValidInput ref={this.refDays}
                                                    type="number"
                                                    valid={{check: ['isNumber','isRequired']}}
                                                    validateNow={validateNow}
                                                    label={messages.get("tournament.create.days")}
                                                    default={this.getDefaultValue(Tournament.prototype.col.duration, 1)}/>
                                    </Col>
                                </Row> : null

                        }

                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={this.state.submitDisabled} bsStyle="primary">
                            {
                                this.state.tournament ?
                                    <FontAwesome icon="save">{messages.get("modal.button.save")}</FontAwesome>
                                    :
                                    <FontAwesome icon="plus">{messages.get("tournament.create.submit")}</FontAwesome>
                            }
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
TournamentCreateModal.propTypes = {
    user: PropTypes.object.isRequired,
    parcours:PropTypes.object
};
module.exports = TournamentCreateModal;
