const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Label} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    NumPadModal = require("components/modals/NumPadModal"),
    messages = require("i18n/messages");

const TournamentUser = require("parse/TournamentUser");

const QrReader = require('react-qr-reader');

const TournamentUserManagerStatus = require("components/tournamentmanage/TournamentUserManagerStatus");
const TournamentUserDetailManagePanel = require("components/tournamentmanage/TournamentUserDetailManagePanel");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

class TournamentUserScanner extends React.Component {
    constructor(props) {
        super(props);
        this.setTournamentUserPresent = this.setTournamentUserPresent.bind(this);
        this.setTournamentUserConfig = this.setTournamentUserConfig.bind(this);
        this.searchTournamentUser = this.searchTournamentUser.bind(this);
        this.handleNumPad = this.handleNumPad.bind(this);
        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);

        this.state = {
            scanResult: null,
            tournamentUser: null,
            updatingUser: false,
            message: null,
            delay: 300
        }
    }
    setTournamentUserPresent(tournamentUser) {
        tournamentUser.setStatus(TournamentUser.prototype.status.present);
        tournamentUserManagerActions.saveTournamentUser(tournamentUser, (uTournamentUser => {
            this.setState({tournamentUser:  uTournamentUser});
        }))
    }
    setTournamentUserConfig(tournamentUser, tConfigAge, tConfigBow) {
        this.setState({updatingUser: true});
        tournamentUserManagerActions.setTournamentUserConfig(tournamentUser,tConfigAge, tConfigBow, (uTournamentUser => {
            this.setState({tournamentUser:  uTournamentUser, updatingUser: false});
        }))
    }
    searchTournamentUser(licNumber) {
        for (let i = 0; i< this.props.tournamentUsers.length; i++) {
            if (licNumber == this.props.tournamentUsers[i].getLicenseNumber()) {
                this.setState({tournamentUser:  this.props.tournamentUsers[i]});
                if (this.props.tournamentUsers[i].getStatus() == TournamentUser.prototype.status.registered) {
                    this.setTournamentUserPresent(this.props.tournamentUsers[i])
                }
                return;
            }
        }
        // no tournament registration
        this.setState({tournamentUser: null, message: messages.get("tournament.register.panel.info.noregistration")});
    }
    handleNumPad(licNumber) {
        this.setState({scanResult: licNumber, message: null, tournamentUser: null});
        this.searchTournamentUser(licNumber)
    }
    handleScan(data){
        if (data){
            let oldData = this.state.scanResult;
            if (oldData != data) {
                this.setState({scanResult: data, message: null, tournamentUser: null});
                let elements = data.split("/");
                this.searchTournamentUser(elements[elements.length - 1]);
            }
        }
    }
    handleError(err){
        console.error(err)
    }
    render() {
        const {tournamentUser, scanResult, delay, message, updatingUser} = this.state;
        const {tournament} = this.props;
        return <Row>
            <Col xs={12} sm={6}>
                <QrReader
                    delay={delay}
                    onError={this.handleError}
                    onScan={this.handleScan}
                />

            </Col>
            <Col xs={12} sm={6}>
                {
                    tournamentUser ? <div>
                        <h3>
                            <TournamentUserManagerStatus addText={true} tournamentUser={tournamentUser} />
                            {
                                tournamentUser.isPaid() ?
                                    <Label bsStyle="success"><I18n code="tournament.attendee.paid"/></Label> :
                                    <Label bsStyle="danger"><I18n code="tournament.attendee.notpaid"/></Label>
                            }
                            <Label bsStyle="info"><FontAwesome icon={tournamentUser.getSexIcon()}/></Label>

                        </h3>
                        <TournamentUserDetailManagePanel key={tournamentUser.id} tournamentUser={tournamentUser} tournament={tournament} updatingUser={updatingUser}
                                                    setTournamentUserConfig={(tConfigAge, tConfigBow) => this.setTournamentUserConfig(tournamentUser, tConfigAge, tConfigBow)}/>
                    </div> : null
                }

                <h4>
                    {message ?  <Label bsStyle="warning">{message}</Label> : null}
                    <br/>
                    {scanResult ?  <Label>
                        {scanResult.startsWith("http") ?
                            <a style={{color:"black"}} href={scanResult} target="_blank">{scanResult}</a> : scanResult
                        }</Label>

                        : <I18n code="parcours.ranking.empty"/>}
                </h4>
                <NumPadModal numberCallback={this.handleNumPad}/>
            </Col>
        </Row>
    }
}
TournamentUserScanner.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUsers: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};
module.exports = TournamentUserScanner;
