const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, ButtonGroup, Panel, Table} = require("react-bootstrap");
const {Tournament} = require("parse/_Domain");

const {differenceInMinutes} = require('date-fns');

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DecisionButton = require("components/form/DecisionButton"),
    SubmitButton = require("components/form/SubmitButton"),
    JSONFileImporter = require("components/form/JSONFileImporter"),
    messages = require("i18n/messages"),
    AddressInfo = require("components/widgets/AddressInfo"),
    BusinessManagementInfo = require("components/tournamentmanage/BusinessManagementInfo"),
    TournamentDashboardButton = require("components/tournamentmanage/TournamentDashboardButton"),
    TournamentMMAddBusinessAccount = require("components/tournamentmanage/TournamentMMAddBusinessAccount"),
    TournamentCreateModal = require("components/tournamentmodal/TournamentCreateModal"),
    AdditionalEditorModal = require("components/modals/AdditionalEditorModal");

const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");

const notificationActions = require("components/notification/NotificationActions"),
    accountActions = require("actions/AccountActions"),
    tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    tournamentMgmtActions = require("actions/TournamentManagerActions"),
    tournamentActions = require("actions/TournamentManagerActions");

const Utility = require("util/Utility");

const TournamentAdminPage = ({tournamentId}) => {
    return <TournamentMMLoader tournamentId={tournamentId} adminCheck={true}>
        <TournamentMangeDetails />
    </TournamentMMLoader>
};

const TournamentMangeDetails  = ({tournament, tournamentRoundMap, user}) => {
    document.title = "Manage " + tournament.getName();
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <h1>{tournament.getName() +", " + tournament.getTournamentDate()}</h1>
                </Col>
            </Row>
            <Row>
                <TournamentMainStatus user={user} tournament={tournament}/>
            </Row>
            <Row>
                {
                    tournament.hasManagementID() ?
                        <BusinessManagementInfo management={tournament.getManagementID()} tournament={tournament} user={user}/>
                        :
                        <TournamentMMaddAllBusinessAccounts user={user} tournament={tournament} />
                }
            </Row>
            <Row>
            {
                tournamentRoundMap != null ? <TournamentRoundUserChecker tournamentRounds={tournamentRoundMap.qualify} /> : null
            }
            </Row>
            <Row>
                <TournamentUserAddressList tournament={tournament}/>
            </Row>
            <Row>
                <TournamentUserImport tournament={tournament} user={user}/>
            </Row>
        </React.Fragment>)
};
TournamentMangeDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
};
const TournamentMainStatus  = ({tournament, user}) => {
    const setTournamentDeleted = () => {
        tournamentActions.updateTournamentStatus(tournament, user, Tournament.prototype.status.deleted);
    };
    const addAdminUsers = () => {
        const mails = ["office@3dturnier.com","bernhard.haba@haba.at","patrick.haruksteiner@gmail.com", "ehrengruber10@gmail.com"];
        for (let i=0; i < mails.length; i++) {
            setTimeout(function () {
                accountActions.addToAddEditor("Tournament", tournament.id, mails[i], editor => {
                    notificationActions.info("Added " + editor.getUsername());
                }, null);
            }, 1000 * i);

        }
    };
    const removeBowUnionID = () => {
        tournament.setBowUnionID(null)
        tournamentActions.saveTournament(tournament, null, null)
    }
    return (
        <Panel bsStyle="default">
            <Panel.Body>
                <Row>
                    <Col lg={4}>
                        <Alert bsStyle="warning">
                            <DecisionButton
                                user={user}
                                buttonText={messages.get("tournament.manager.delete")}
                                buttonIcon={"trash"}
                                titleCode={"tournament.manager.delete"}
                                messageCode={"club.member.modal.delete.ask"}
                                okButtonCode={"modal.button.delete"}
                                cancelButtonCode={"modal.button.cancel"}
                                handleOK={setTournamentDeleted}/>
                        </Alert>
                    </Col>
                    <Col lg={3}>
                        <Button bsStyle="primary" bsSize="large" onClick={addAdminUsers}>Add admin users</Button>
                    </Col>
                    {
                        tournament.getBowUnionID() != null ? <Col lg={3}>
                            <Button bsStyle="primary" onClick={removeBowUnionID}>Remove union: {tournament.getBowUnionID().getName()}</Button>
                        </Col> : null
                    }

                </Row>
            </Panel.Body>
            <Panel.Footer>
                <TournamentEditModals tournament={tournament} user={user}/>
            </Panel.Footer>
        </Panel>)
};
TournamentMainStatus.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
};
class TournamentRoundUserChecker extends React.Component {
    constructor(props) {
        super(props);
        this.loadTRoundUser = this.loadTRoundUser.bind(this);
        this.state = {
            tRound: null,
            tRoundUsers: []
        }
    }
    loadTRoundUser(tRound) {
        tournamentUserManagerActions.loadTournamentRoundResult(tRound, result => {
            this.setState({
                tRound: tRound,
                tRoundUsers: result
            })
        })
    }
    render() {
        const {tournamentRounds} = this.props;
        const {tRound, tRoundUsers} = this.state;
        return <Panel bsStyle="default">
            <Panel.Heading>
                <h3>Compare creation of tUserGroup with creation of tournamentRoundUser</h3>
                <ButtonGroup>
                    {
                        tournamentRounds.map(tRound => {
                            return <Button key={tRound.id} onClick={() => this.loadTRoundUser(tRound)}>
                                Validate: {tRound.getName()}
                            </Button>
                        })
                    }
                </ButtonGroup>
            </Panel.Heading>
            <Panel.Body>
                <Row>
                    <Col xs={12}>
                        {
                            tRound != null ? <Table>
                                <thead>
                                    <tr>
                                        <th>Group created</th>
                                        <th>RoundUser created</th>
                                        <th>duration</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    tRoundUsers.map(tRoundUser => {
                                        const startStmp = tRoundUser.getGroupStartTime()
                                        const dur = differenceInMinutes(tRoundUser.getCreatedAt(), startStmp)
                                        return <tr key={tRoundUser.id}>
                                            <td>{Utility.getReadableDate(startStmp)} - {Utility.getReadableTime(startStmp)}</td>
                                            <td>{Utility.getReadableDate(tRoundUser.getCreatedAt())} -
                                                {Utility.getReadableTime(tRoundUser.getCreatedAt())}</td>
                                            {
                                                dur < 60 ? <td><strong>{Utility.convertMinutesToHours(dur)}</strong></td>:
                                                    <td>{Utility.convertMinutesToHours(dur)}</td>
                                            }

                                            <td>{tRoundUser.getTournamentUserID().getFullName()}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </Table> : null
                        }

                    </Col>
                </Row>
            </Panel.Body>
        </Panel>
    }
}
class TournamentEditModals extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentCreateModal = React.createRef();

        this.openMainModal = this.openMainModal.bind(this);

        this.state = {}
    }
    openMainModal() {
        this.refTournamentCreateModal.current.edit(this.props.tournament);
    }
    render() {
        const {tournament, user} = this.props;
        return (
            <Row>
                <Col xs={6} sm={4}>
                    <Button bsStyle="success" onClick={this.openMainModal}>
                        <FontAwesome icon="pencil-alt"/>{messages.get("tournament.manager.editMaster")}
                    </Button>
                    <TournamentCreateModal ref={this.refTournamentCreateModal} user={user}/>
                </Col>
                <Col xs={6} sm={4}>
                    <AdditionalEditorModal objectType={"Tournament"} object={this.props.tournament} titleCode={"tournament.manager.editors"}/>
                </Col>
                <Col sm={4}>
                    {
                        tournament.isManaged() ?
                            <TournamentEditorButtons user={user} tournament={tournament}/> : null
                    }
                </Col>
            </Row>

        );
    }
}
TournamentEditModals.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired
};
const TournamentEditorButtons = ({user, tournament}) => {
    return (<React.Fragment>
        {!tournament.isEditor(user) ? null :
            <TournamentDashboardButton bsSize="large" tournament={tournament}/>
        }
    </React.Fragment>)
};
TournamentEditorButtons.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired
};
const TournamentUserImport = ({tournament, user}) => {
    const receivedDicArray = (objectArray) => {
        tournamentUserManagerActions.handleImportTournamentUser(tournament, user, objectArray);
    };
    return ( <Panel bsStyle="default">
            <Panel.Body>
                <Row>
                    <Col xs={12}>
                        <JSONFileImporter startImportArray={receivedDicArray}
                                          mandJsonKeys={["name","surname", "sex", "bowCode", "ageCode", "price"]}
                                          title={messages.get("tournament.button.menu.mmtu")}/>
                    </Col>
                    <Col xs={12}>
                        <h3>For each entry one row in brace with following fields, full dataset not covered in []</h3>
                        <p><a href="https://shancarter.github.io/mr-data-converter/">Convert xls to json file</a></p>
                        <p><strong>Mandatory fields</strong></p>
                        <p>"surname": "Peterseil",</p>
                        <p>"name": "Daniel",</p>
                        <p>"sex": "0", (0 = male || 1 = female)</p>
                        <p>"union": "Verein",</p>
                        <p>"price": "15",</p>
                        <p>"bowCode":"BB",</p>
                        <p>"ageCode":"Allg",</p>
                        <p><strong>nullable fields</strong></p>
                        <p>"mail": "office@3dturnier.com", (null -> name.surname@noemail.com)</p>
                        <p>"paid": "1", (1 == paid)</p>
                        <p>"option": "",</p>
                        <p>"note": "",</p>
                        <p>"option: "",</p>
                        <p>"licenseNumber":"",</p>
                        <p>"licenseUnionCode":""</p>
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
TournamentUserImport.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
};
class TournamentUserAddressList  extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.loadTournamentUsers = this.loadTournamentUsers.bind(this);
        this.state = {
            tournamentUsers: []
        }
    }

    loadTournamentUsers() {
        tournamentUserManagerActions.loadTournamentUsersAddress(this.props.tournament, tournamentUsers => {
            this.setState({tournamentUsers: tournamentUsers})
            this.refSubmitButton.current.resetLoading();
        })
    }
    render() {
        const {tournamentUsers} = this.state;
        return <Panel bsStyle="default">
            <Panel.Body>
        <Row>
            <Col xs={12}>
                <SubmitButton ref={this.refSubmitButton} bsSize="large" onClick={this.loadTournamentUsers}
                              text="Load TournamentUsers with Address"/>
            </Col>
            <Col xs={12}>
                <Table>
                    <thead>
                    <tr>
                        <th>Teilnehmername</th>
                        <th>E-Mail</th>
                        <th>Address User</th>
                        <th>Address Transaction</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        tournamentUsers.map(tournamentUser => {
                            return <TournamentUserAddressListRow key={"TUALR_" + tournamentUser.id} tournamentUser={tournamentUser} />
                        })
                    }
                    </tbody>
                </Table>
            </Col>
        </Row>
            </Panel.Body>
        </Panel>
    }
}
const TournamentUserAddressListRow = ({tournamentUser}) => {
    let userAddress = null;
    let transactionAddress = tournamentUser.getTransactionID();
    if (tournamentUser.getUserID() != null && tournamentUser.getUserID().getUserDetailID() != null) {
        userAddress = tournamentUser.getUserID().getUserDetailID().getAddressID();
    }
    return (
        <tr>
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getPlayerEmail()}</td>
            <td>{userAddress != null ? <AddressInfo address={userAddress}/> : "-"}</td>
            <td>{transactionAddress != null ? <AddressInfo address={transactionAddress}/> : "-"}</td>
        </tr>
    )
}
class TournamentMMaddAllBusinessAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.switchLoad = this.switchLoad.bind(this);
        this.state = {
            load: false
        }
    }
    switchLoad() {
        const val = this.state.load
        this.setState({load: !val})
    }
    render() {
        const {tournament, user} = this.props
        const {load} = this.state
        if (load) {
            return <TournamentMMAddBusinessAccount user={user} tournament={tournament} allBAccounts={true}/>
        } else {
            return (<Panel bsStyle="default">
                <Panel.Body>
                    <h3><I18n code="profile.account.business.header"/></h3>
                    <Button bsStyle="primary" onClick={this.switchLoad}>Load All Business Accounts</Button>
                </Panel.Body>
            </Panel>)
        }
    }

}
module.exports = TournamentAdminPage;
