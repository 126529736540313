/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Col, Row, Table, Button, Alert} = require("react-bootstrap");
const BaseMap = require("components/map/BaseMap");
const TargetTrackLine = require("components/map/TargetTrackLine");
const MapUtil = require("components/map/MapUtil");
const I18n = require("components/widgets/I18n"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const EventMapFrame = ({eventTrack, eventPlayers, eventResults, showTrackTable, exportClicked}) => {
    const trackArr = eventTrack.getTrack();
    if (trackArr == null) {
        return <p></p>
    }
    let mapUtil = new MapUtil();
    const bounds = []
    const trackElements = mapUtil.buildTrackObject(trackArr, bounds, eventPlayers, eventResults, 0)
    return (
        <Row>
            <Col sm={9}>
                <BaseMap mapOptions={{ center: {lat: 48.249863, lng: 14.635051}, mapID:"EMF_Map",
                        containerStyle: {width: "100%", height: '500px'},
                        zoom: 8, mapTypeId:"satellite"
                    }} elements={bounds}>
                    {
                        trackElements.map(trackElement => {
                            return <ErrorBoundary key={"EvMaFr" + trackElement.targetNumber} identifier={"Target:" + trackElement.targetNumber}>
                                <TargetTrackLine coordArray={trackElement.coordArray}
                                                 targetName={trackElement.targetName}
                                                 targetPoints={trackElement.targetPoints}
                                                 targetNumber={trackElement.targetNumber}
                                                 posMarker={trackElement.posMarker}/>
                            </ErrorBoundary>
                        })
                    }
                </BaseMap>
            </Col>
            <Col sm={3}>
                {
                    showTrackTable ?
                        <ErrorBoundary><EventSubTracks eventTrack={eventTrack} exportClicked={exportClicked}/></ErrorBoundary>:
                        <NoGPSFeature distance={eventTrack.getTrackDistance()} duration={eventTrack.getTrackDuration()}/>
                }
            </Col>
        </Row>
    )
};
EventMapFrame.propTypes = {
    eventTrack:PropTypes.object.isRequired,
    showTrackTable: PropTypes.bool,
    exportClicked: PropTypes.bool
};
const EventSubTracks = ({eventTrack, exportClicked}) => {
    return <div  style={{height:500, width:"100%", overflowY: "scroll", paddingRight: "5px"}}>
        <Table striped condensed hover responsive>
            <thead>
            <tr>
                <th>{"#"}</th>
                <th style={{"textAlign": "center"}}><FontAwesome icon="clock"/><br/>{eventTrack.getTrackDuration()}</th>
                <th style={{"textAlign": "center"}}><FontAwesome icon="walking"/><br/>{eventTrack.getTrackDistance()}</th>
            </tr>
            </thead>
            <tbody>
            {
                eventTrack.getTrackSum() ? eventTrack.getTrackSum().map(trackSum => {
                    const jsTrackSum = JSON.parse(trackSum);
                    const targetNumber = Object.keys(jsTrackSum)[0];
                    return <EventSubTrackRow jsTrackSum={jsTrackSum[targetNumber]} targetNumber={targetNumber}/>
                }) : null
            }

            </tbody>
        </Table>
        <Button onClick={exportClicked}>Download GPX</Button>
    </div>
};
EventSubTracks.propTypes = {
    eventTrack: PropTypes.object.isRequired
};
const EventSubTrackRow = ({jsTrackSum, targetNumber}) => {
    const distance = jsTrackSum && jsTrackSum["di"] ? jsTrackSum["di"].toFixed(0) : " ?";
    const duration = jsTrackSum && jsTrackSum["du"] ?  Math.round((jsTrackSum["du"] % 3600) / 60) : " ?";
    return <tr>
        <td>{targetNumber}</td>
        <td style={{"textAlign": "right"}}>{duration + " min"}</td>
        <td style={{"textAlign": "right"}}> {distance + " m"}</td>
    </tr>
};
EventSubTrackRow.propTypes = {
    jsTrackSum: PropTypes.object.isRequired,
    targetNumber: PropTypes.number.isRequired
};
const NoGPSFeature = ({duration, distance}) => {
    return (<Row>
            <Col xs={6}>
                <h4><FontAwesome icon="clock">{duration}</FontAwesome></h4>
            </Col>
            <Col xs={6}>
                <h4><FontAwesome icon="walking">{distance}</FontAwesome></h4>
            </Col>
            <Col xs={12}>
                <Alert bsStyle="info">
                    <I18n code="Dialog_Body_premium_needed_for_gps"/><br/>
                    <a href={linkUtil.getLink("skillboard/premium")}>...<I18n code="header.nav.3dskillboard.premium"/></a>
                </Alert>
            </Col>
        </Row>
    )
};
NoGPSFeature.propTypes = {
    duration:PropTypes.string,
    distance:PropTypes.string
};
module.exports = EventMapFrame;