const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Table, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    TransactionTable = require("components/parcoursmanage/TransactionTable"),
    SettlementDetailLink = require("components/links/SettlementDetailLink"),
    SubmitButton = require("components/form/SubmitButton"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    MonthPagerBasic= require("components/widgets/MonthPagerBasic");

const Utility = require("util/Utility");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const messages = require("i18n/messages");

const userStore = require("stores/UserStore"),
    transactionStore = require("stores/TransactionStore"),
    transactionActions = require("actions/TransactionActions");


class TransactionSettlementPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTransactionChange = this.handleTransactionChange.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);
        let adminPage = this.props.path != null && Utility.contains(this.props.path, "business");
        let year = this.props.year ? parseInt(this.props.year) : null;
        let month = this.props.month? parseInt(this.props.month) : null;
        if (month == null || year == null) {
            let today = new Date();
            month= today.getMonth() + 1;
            year= today.getFullYear()
        }
        this.state = {
            userStore: userStore.getState(),
            transactionStore: transactionStore.getState(),
            adminPage: adminPage,
            month: month,
            year: year
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        transactionStore.listen(this.handleTransactionChange);
        transactionActions.showTransactionPage.defer(this.props.managementID, this.state.month, this.state.year);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        transactionStore.unlisten(this.handleTransactionChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state
        });
    }
    handleTransactionChange(state) {
        this.setState({
            transactionStore: state,
        });
    }
    handleChangedDate(month, year, yearChanged) {
        this.setState({
            month: month,
            year: year
        });
        transactionActions.showTransactionMonth(this.props.managementID, month, year);
    }
    render() {
        return (
            <div>
                <Grid>
                    <Row>
                        <Col xs={12} xsHidden>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb link="/mgmtparcours" code="profile.managedParcours.header" />
                                <Breadcrumb code="parcours.transactions.header" active/>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                    <MonthPagerBasic headerText={messages.get("parcours.transactions.header")} year={this.state.year} month={this.state.month} handleChangedDate={this.handleChangedDate}/>
                    {this.state.transactionStore.transactions == null || this.state.transactionStore.management == null ?
                        <Loading/> : ""}
                    {this.state.transactionStore.transactions != null && this.state.transactionStore.transactions.length <= 0 ?
                        <Alert bsStyle="warning"><I18n code="parcours.transaction.empty"/></Alert> : ""
                    }
                    {  this.state.transactionStore.transactions != null && this.state.transactionStore.transactions.length > 0 ?
                        <div>
                            <TransactionSettlementEle
                                transactionSettlement={this.state.transactionStore.transactionSettlement}
                                management={this.state.transactionStore.management}
                                loading={this.state.transactionStore.loadingS}/>
                            <TransactionOverview
                                loading={this.state.transactionStore.loadingT}
                                transactions={this.state.transactionStore.transactions}
                                transactionsPositions={this.state.transactionStore.transactionsPositions}
                                transactionSettlement={this.state.transactionStore.transactionSettlement}
                                transactionStat={this.state.transactionStore.transactionStat}
                                adminPage={this.state.adminPage}/>
                        </div>: ""
                    }


                </Grid>
            </div>
        )
    }
}
TransactionSettlementPage.propTypes = {
    path: PropTypes.string.isRequired,
    managementID: PropTypes.string.isRequired,
    month: PropTypes.number,
    year: PropTypes.number
};
const TransactionSettlementEle = ({management, transactionSettlement, loading}) =>  {
    if (loading) {
        return <Row>
            <Col xs={12}>
                <Loading/>
            </Col>
        </Row>
    } else {
        return (<ErrorBoundary>
                <Row>
                    <Col xs={12}>
                        <h3>{messages.get("parcours.transactions.settlement.header")}</h3>
                    </Col>
                    {
                        transactionSettlement == null ?
                            <Col xs={12}>
                                <Alert bsStyle="warning"><I18n code="parcours.transactions.settlement.empty"/></Alert>
                            </Col> :
                            <SettlementHeader transactionSettlement={transactionSettlement}
                                              management={management}/>
                    }
                </Row></ErrorBoundary>)
    }
};
TransactionSettlementEle.propTypes = {
    loading:PropTypes.bool.isRequired,
    transactionSettlement:PropTypes.object,
    management:PropTypes.object.isRequired
};
const SettlementHeader = ({management, transactionSettlement}) =>  {
    const getInvoice =() => {
        if (transactionSettlement.getInvoiceNumber()) {
            let pre = messages.get("tournament.table.column.invoice") + " ";
            if (transactionSettlement.getInvoice()) {
                return (<a target="_BLANK" href={transactionSettlement.getInvoice()._url}>
                    <FontAwesome icon="download"/>{pre + transactionSettlement.getInvoiceNumber()}
                </a>);
            } else {
                return pre + transactionSettlement.getInvoiceNumber()
            }
        }
        return messages.get("parcours.transactions.settlement.invoice.missing");
    };
    return (
        <React.Fragment>
            <Col xs={6}>
                <SettlementDetailLink settlementID={transactionSettlement.id}
                                      status={transactionSettlement.getStatus()}
                                      label={<strong>{messages.get("parcours.transactions.settlement.createdAt") + " " +  transactionSettlement.getSettlementDate()}</strong>}/>

            </Col>
            <Col xs={6}>
                {getInvoice()}
            </Col>
        </React.Fragment>
    )
};
TransactionSettlementEle.propTypes = {
    transactionSettlement:PropTypes.object,
    management:PropTypes.object.isRequired
};
const TransactionOverview = ({transactions, transactionsPositions, transactionStat, transactionSettlement, loading, adminPage}) =>  {
    if (loading) {
        return <Row>
            <Col xs={12}>
                <Loading/>
            </Col>
        </Row>
    } else {
        return (
            <ErrorBoundary>
                <Row>
                    <Col xs={12}>
                        <TransactionStatTable
                            transactionStat={transactionStat}
                            transactionSettlement={transactionSettlement}/>
                    </Col>
                    <br/><hr/>
                    <Col xs={12}>
                        <TransactionTable transactions={transactions} transactionsPositions={transactionsPositions} adminPage={adminPage}/>
                    </Col>
                </Row>
            </ErrorBoundary>)
    }
};
TransactionOverview.propTypes = {
    adminPage:PropTypes.bool,
    loading:PropTypes.bool.isRequired,
    transactions:PropTypes.array,
    transactionsPositions: PropTypes.object,
    transactionSettlement:PropTypes.object,
    transactionStat:PropTypes.object.isRequired
};
const TransactionStatTable = ({transactionStat, transactionSettlement}) =>  {
    return (
        <React.Fragment>
            <h3>{messages.get("parcours.transactions.stats.header")}</h3>
            <Row>
                <Col xs={12}>
                    <Table responsive striped>
                        <thead>
                        <tr>
                            <th><I18n code="parcours.articles.form.brand"/></th>
                            <th><I18n code="tournament.table.column.amount"/></th>
                            <th><I18n code="tournament.table.column.sum"/></th>
                            <th><I18n code="tournament.table.column.provision"/></th>
                            <th><I18n code="tournament.table.column.income"/></th>
                        </tr>
                        </thead>
                        {
                            transactionSettlement == null ?
                                <TransactionStatRowBody transactionStat={transactionStat}/>
                                :
                                <TransactionSettlementBody transactionSettlement={transactionSettlement}/>
                        }
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};
TransactionStatTable.propTypes = {
    transactionSettlement:PropTypes.object,
    transactionStat:PropTypes.object.isRequired
};
const TransactionStatRowBody = ({transactionStat}) =>  {
    let monthSum = 0;
    let monthCount = 0;
    return (
        <tbody>
        { Object.keys(transactionStat).map(brand => {
            const transStat = transactionStat[brand];
            monthSum += (transStat["SUM"] - transStat["PROV"]);
            monthCount += transStat["CNT"];
            return (<TransactionStatRow key={"TSRB"+brand} brand={brand} transactionStat={transStat} />);
        })
        }
        <tr>
            <td> </td>
            <td><strong>{monthCount}</strong></td>
            <td> </td>
            <td> </td>
            <td><strong>{"EUR " + monthSum.toFixed(2)}</strong></td>
        </tr>
        </tbody>
    )
};
TransactionStatRowBody.propTypes = {
    transactionStat:PropTypes.object.isRequired
};
const TransactionStatRow = ({transactionStat, brand}) =>  {
    return (
        <tr id={brand}>
            <td>{brand}</td>
            <td>{transactionStat["CNT"]}</td>
            <ErrorBoundary><td>{"EUR " + transactionStat["SUM"].toFixed(2)}</td></ErrorBoundary>
            <ErrorBoundary><td>{"EUR " + transactionStat["PROV"].toFixed(2)}</td></ErrorBoundary>
            <ErrorBoundary><td>{"EUR " + (transactionStat["SUM"] - transactionStat["PROV"]).toFixed(2)}</td></ErrorBoundary>
        </tr>)
};
TransactionStatRow.propTypes = {
    transactionStat:PropTypes.object.isRequired,
    brand:PropTypes.string.isRequired
};
const TransactionSettlementBody = ({transactionSettlement}) =>  {
    let monthCount = 0;
    return (
        <tbody>
        {
            transactionSettlement.getSettlementPositions().map(position => {
                monthCount += position.getAmount();
                return (<TransactionSettlementRow key={"TSB" + position.id} position={position} />);
            })
        }
        {
            transactionSettlement.getDiscount() != null ?
                <tr>
                    <td><I18n code="tournament.table.column.correction"/></td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td><strong>{"EUR -" + transactionSettlement.getDiscount().toFixed(2)}</strong></td>
                </tr> : null
        }
        <tr>
            <td> </td>
            <td><strong>{monthCount}</strong></td>
            <td> </td>
            <td> </td>
            <td><strong>{"EUR " + transactionSettlement.getIncome().toFixed(2)}</strong></td>
        </tr>
        </tbody>
    )
};
TransactionSettlementBody.propTypes = {
    transactionSettlement:PropTypes.object.isRequired
};
const TransactionSettlementRow = ({position}) =>  {
    return (
        <tr id={position.getBrand()}>
            <td>{position.getBrand()}</td>
            <td>{position.getAmount()}</td>
            <td>{"EUR " + position.getSum().toFixed(2)}</td>
            <td>{"EUR " + position.getProvision().toFixed(2)}</td>
            <td>{"EUR " + position.getIncome().toFixed(2)}</td>
        </tr>)
};
TransactionSettlementRow.propTypes = {
    position:PropTypes.object.isRequired
};
module.exports = TransactionSettlementPage;
