const Parse = require("ParseInit");
const {ImageGallery} = require("parse/_Domain");

class ImageGalleryDao {

    queryImages(parcoursID, tournament) {
        const query = new Parse.Query(ImageGallery);
        if (parcoursID != null) {
            query.equalTo(ImageGallery.prototype.col.parcoursID, parcoursID);
        }
        if (tournament != null) {
            query.equalTo(ImageGallery.prototype.col.tournamentID, tournament);
        }
        query.equalTo(ImageGallery.prototype.col.status,"A");
        query.include(ImageGallery.prototype.col.creator);
        query.descending(ImageGallery.prototype.col.createdAt);
        return query.find();
    }
}

module.exports = new ImageGalleryDao();
