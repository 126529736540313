const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, ButtonGroup, Modal} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    SubTitle = require("components/widgets/SubTitle"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput");

const Parcours = require("parse/Parcours");
const parcoursActions = require("actions/ParcoursActions");

class ParcoursAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.refName = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.valid = this.valid.bind(this);
        this.setTypeTarget = this.setTypeTarget.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            type: "P",
            showModal: false,
            submitDisabled: false
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false});
    }
    open() {
        this.setState({showModal: true});
    }
    valid() {
        let valid = true;
        const required = [
            this.refName.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    setTypeTarget(type) {
        this.setState({
            type: type
        });
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        parcoursActions.createParcours(this.refName.current.getValue().trim(), null, this.props.parcoursOwner.id, this.state.type, this.props.parcoursOwner.getGroupPlaceID().id, this.props.parcoursOwner.getMainGroupPlaceID().id);
    }
    render() {
        const validateNow = this.state.error && this.state.error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <SubTitle><I18n code="parcours.add.title"/></SubTitle>
                            <SubTitle>{this.props.parcoursOwner.getName()}</SubTitle>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            <Col sm={12}>
                                <p>{messages.get("parcours.add.description")}</p>
                                <ValidInput ref={this.refName}
                                            valid={{maxLength: 500, check:['isRequired']}}
                                            validateNow={validateNow}
                                            placeholder={messages.get("parcours.add.name")}/>
                                <br/>
                                <ButtonGroup>
                                    <TypeButton type={Parcours.prototype.type.parcours} label={messages.get("ParcoursInfo_type_P_parcours")} selType={this.state.type}  setTypeTarget={this.setTypeTarget}/>
                                    <TypeButton type={Parcours.prototype.type.field} label={messages.get("ParcoursInfo_type_F_field")} selType={this.state.type}  setTypeTarget={this.setTypeTarget}/>
                                    <TypeButton type={Parcours.prototype.type.target} label={messages.get("ParcoursInfo_type_T_target")} selType={this.state.type}  setTypeTarget={this.setTypeTarget}/>
                                    <TypeButton type={Parcours.prototype.type.cinema4D} label="BowSim 4D" selType={this.state.type}  setTypeTarget={this.setTypeTarget}/>
                                </ButtonGroup>
                                <br/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={this.state.submitDisabled} bsStyle="primary"><FontAwesome icon="plus"/>&nbsp;{messages.get("parcours.create.submit")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
ParcoursAddModal.propTypes = {
    parcoursOwner: PropTypes.object.isRequired
};
const TypeButton = ({selType, type, label, setTypeTarget}) => {
    function handleClick() {
        setTypeTarget(type)
    }
    return <Button onClick={handleClick} active={selType === type}>{label}</Button>
};
module.exports = ParcoursAddModal;
