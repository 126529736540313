var Parse = require("ParseInit");

var Releases = Parse.Object.extend("releases", {
    col: {
        ACL: "ACL",
        binary: "binary",
        createdAt: "createdAt",
        date: "date",
        description_de: "description_de",
        description_en: "description_en",
        objectId: "objectId",
        onlineID: "onlineID",
        status: "status",
        updatedAt: "updatedAt",
        version: "version",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    type: {
        Android: "3DArcheryDPS",
        IOS: "3D SKill Board",
        Web: "3DTurnier"
    },
    buildACL: function () {
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(false);
        this.setACL(acl);
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getVersion: function () {
        return this.get(this.col.version);
    },
    setVersion: function (version) {
        return this.set(this.col.version, version);
    },
    isLatestVersion: function (bundleVersion) {
        let version = this.getVersion();
        if (version != null && bundleVersion != null) {
            version = version.substring(8, version.length);
            let bV = bundleVersion.trim().split(".");
            let dbV = version.trim().split(".");
            for (let i = 0; i < dbV.length; i++) {
                let bVi = -1;
                if (i < bV.length ) {
                    // bundle also has version
                    bVi =  bV[i];
                }
                if (dbV[i] > bVi) {
                    return false;
                } else if (dbV[i] < bVi) {
                    // db version not updated
                    return true;
                }
            }
        }
        return true;
    },
    setTypeOnlineID: function (type) {
        return this.set(this.col.onlineID, type);
    },
    getDate: function () {
        return this.get(this.col.date);
    },
    setDate: function (date) {
        return this.set(this.col.date, date);
    },
    getReleaseDate: function () {
        var tDate = this.getDate();
        return tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
    },
    getDescriptionDE: function () {
        return this.get(this.col.description_de);
    },
    getDescriptionEN: function () {
        return this.get(this.col.description_en);
    },
    getTranslatedDescription: function(language) {
        if ("de" == language) {
            return this.getDescriptionDE();
        } else {
            return this.getDescriptionEN();
        }
    },
    setStatus: function (status) {
        return this.set(this.col.status, status);
    }
});

module.exports = Releases;