const React = require("react");
const {Row, Col} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    PanelCollapse = require("components/widgets/PanelCollapse");

const FAQs = () => {
    return (
        <Row>
            <Col lg={12}>
                <h3><I18n code="skillboard.faq.archers"/></h3>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q1.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q1.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q2.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q2.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q3.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q3.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q4.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q4.content"/>
                </PanelCollapse>

                <h3><I18n code="skillboard.faq.parcour"/></h3>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q12.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q12.content"/>
                </PanelCollapse>

                <h3><I18n code="skillboard.faq.event"/></h3>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q7.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q7.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q8.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q8.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q15.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q15.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q9.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q9.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q10.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q10.content"/>
                </PanelCollapse>

                <h3><I18n code="skillboard.faq.synchronization"/></h3>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q13.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q13.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q11.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q11.content"/>
                </PanelCollapse>
                <PanelCollapse header={<h3><I18n code="skillboard.faq.q14.title"/></h3>}>
                    <I18nHtml code="skillboard.faq.q14.content"/>
                </PanelCollapse>
            </Col>
        </Row>)
};
module.exports = FAQs;
