const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const ParcoursTargetButton  = ({parcours, eventTrack, block, disabled, bsStyle, edit}) => {
    let link = "parcours/" + parcours.getOnlineID() + "/targets";
    let code = "parcours.targets.header";
    if (edit) {
        link += "/edit";
        code = "parcours.targets.edit.header";
        if (eventTrack) {
            link += "/" + eventTrack.id;
        }
    }
    return (
        <Button block={block}
                style={{whiteSpace: "normal"}}
                disabled={disabled}
                bsStyle={bsStyle ? bsStyle : "primary"}
                href={linkUtil.getLink(link)}>
            <FontAwesome icon="map"/><I18n code={code}/>
    </Button>)
};
ParcoursTargetButton.propTypes = {
    parcours:PropTypes.object.isRequired,
    eventTrack:PropTypes.object,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
    edit: PropTypes.bool
};
module.exports = ParcoursTargetButton;
