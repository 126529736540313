const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const ParcoursRankingButton = ({parcours, block, disabled, bsStyle}) => {
    return (
        <Button block={block}
                style={{whiteSpace: "normal"}}
                disabled={disabled}
                bsStyle={bsStyle ? bsStyle : "primary"}
                href={linkUtil.getLink("parcours/" + parcours.getOnlineID() + "/ranking")}>
            <FontAwesome icon="cubes"/><I18n code="parcours.ranking.header"/>
    </Button>)
};
ParcoursRankingButton.propTypes = {
    parcours:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
};
module.exports = ParcoursRankingButton;
