var Parse = require("ParseInit");

var TournamentConfig = Parse.Object.extend("TournamentConfig", {
    col: {
        code: "code",
        name: "name",
        countTypeID: "countTypeID",
        arrowPasse: "arrowPasse",
        price: "price",
        noSexSplit: "noSexSplit",
        typeID: "typeID",
        status: "status",
        ages:"ages", // array with [minAge,maxAge]
        tOptionSurcharge: "tOptionSurcharge", //Object to store string of selected option and surcharge for that
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        A: "A"
    },
    //
    //
    //
    isDeleted() {
        return "D" == this.getStatus();
    },
    getSelectString() {
        return this.getCodeName();
    },
    getCodeName() {
        var text = "";
        if (this.getCode()) {
            text = this.getCode() + " - ";
        }
        text += this.getName();
        return text;
    },
    getCodeOrShortName() {
        if (this.getCode()) {
            return this.getCode();
        } else {
            if (this.getName().length >= 4) {
                return this.getName().substring(0,4);
            } else {
                return this.getName();
            }
            
        }
    },
    generateKillTextHeader() {
        var countType = this.getCountTypeID();
        if (countType && countType.get("countTypeDtls")) {
            var hdrText = "";
            countType.get("countTypeDtls").forEach(countTypeDtl =>  {
                var tArrow = parseInt(countTypeDtl.get("arrowNr"));
                var tKill = parseInt(countTypeDtl.get("killNr"));
                if (tArrow == 1 && tKill < 4) {
                    hdrText += (hdrText == "") ? "": "/";
                    hdrText += countTypeDtl.get("points");
                }
            });
            return hdrText;
        }
        return "Kills";
    },
    getCode() {
        return this.get(this.col.code)
    },
    setCode(code) {
        return this.set(this.col.code, code)
    },
    getTypeID() {
        return this.get(this.col.typeID)
    },
    setTypeID(typeID) {
        return this.set(this.col.typeID, typeID)
    },
    getAges() {
        return this.get(this.col.ages)
    },
    setAges(ages) {
        return this.set(this.col.ages, ages)
    },
    isWithinAges(tournamentAge) {
        const ages = this.getAges();
        if (ages != null && ages.length > 1) {
            return tournamentAge >= ages[0] && tournamentAge <= ages[1]
        }
        return false
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getTOptionSurcharge() {
        return this.get(this.col.tOptionSurcharge)
    },
    getTOptionCupSurchargeString(tournament) {
        let tOptionObject = this.getTOptionSurcharge();
        if (tOptionObject) {
            let surcharge = tOptionObject[tournament.id];
            if (surcharge) {
                return ", Cup " + parseFloat(surcharge)
            }
        }
        return null;
    },
    getTOptionSurchargeString(tournament, tOption) {
        let tOptionObject = this.getTOptionSurcharge();
        let optionList = tOption != null ? tOption.getOptions(): [];
        if (tOptionObject != null) {
            return " Opt:" + Object.keys(tOptionObject).map(key => {
                if (key !== tournament.id && key.startsWith(tournament.id)) {
                    // check for key without tournamentId in tournament option list
                    let nKeyArray = key.split("_");
                    if (nKeyArray.length > 1) {
                        if (optionList.indexOf(nKeyArray[1]) !== -1) {
                            // if key exists - take the amount configured for the tConfig
                            return tOptionObject[key];
                        }
                    }
                }
            })
        }
        return null;
    },
    setTOptionSurcharge(object) {
        return this.set(this.col.tOptionSurcharge, object)
    },
    getPrice() {
        return parseFloat(this.get(this.col.price))
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    getCountTypeID() {
        return this.get(this.col.countTypeID)
    },
    setCountTypeID(countTypeID) {
        return this.set(this.col.countTypeID, countTypeID)
    },
    setArrowPasse(arrPasse) {
        return this.set(this.col.arrowPasse, arrPasse)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    hasNoSexSplit() {
        return this.get(this.col.noSexSplit)
    },
    setNoSexSplit(noSexSplit) {
        return this.set(this.col.noSexSplit, noSexSplit)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = TournamentConfig;
