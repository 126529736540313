const React = require("react"),
    PropTypes = require('prop-types');
const { NavItem} = require("react-bootstrap");
const { LinkContainer } = require('react-router-bootstrap');
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const NavItemWrap = ({route, label, icon, eventKey, currentPath, onSelect, style}) => {
    return <LinkContainer to={linkUtil.getLink(route)} onSelect={onSelect}>
        <NavItem eventKey={eventKey} >
            <span style={style}>
                {
                    icon ? <FontAwesome icon={icon}/> : null
                }
                <I18n code={label}/>
            </span>
        </NavItem>
    </LinkContainer>
};
NavItemWrap.propTypes = {
    route:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired,
    icon: PropTypes.string,
    eventKey:PropTypes.number.isRequired,
    onSelect: PropTypes.func
};
module.exports = NavItemWrap;
