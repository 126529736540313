const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Panel, ProgressBar} = require("react-bootstrap");
const TournamentUserResultDic = require("util/TournamentUserResultDic");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TournamentUserFilterModal = require("components/tournamentmodal/TournamentUserFilterModal"),
    DelayedTextField = require("components/form/DelayedTextField"),
    TournamentAttendeeStatus = require("components/tournament/TournamentAttendeeStatus"),
    TournamentUserStatTable = require("components/tournament/TournamentUserStatTable"),
    Icons = require("components/widgets/Icons");

const tournamentUserActions = require("actions/TournamentUserActions");

const TournamentAttendees = ({tournament, tournamentUsers, allTournamentUsers, paidTournamentUserAmount, tournamentUserSearchParams}) => {
    console.log("Render TournamentAttendees page");
    const maxRegistrations = tournament.getRegMaxUser();
    const registrations = allTournamentUsers.length;
    const base = registrations > maxRegistrations ? registrations : maxRegistrations;
    let percentPaid = 0;
    let percentUnpaid = 0;
    let percentExceed = 0;
    if (maxRegistrations > 0) {
        percentPaid = 100 * paidTournamentUserAmount / base;
        if (registrations > maxRegistrations) {
            percentUnpaid = 100 * (maxRegistrations - paidTournamentUserAmount) / base;
            percentExceed = 100 * (registrations - maxRegistrations) / base;
        } else {
            percentUnpaid = 100 * (registrations - paidTournamentUserAmount) / base;
        }
    }
    return (<React.Fragment>
        <Panel>
            <Panel.Heading>
                <Row>
                    <Col xs={12}>
                        <h3><I18n code="tournament.attendees.panel.title"/></h3>
                        <p>{messages.get("tournament.attendees.panel.subtitle") + ": " + registrations + " " + messages.get("tournament.table.column.of") + " " + maxRegistrations}</p>
                        <ProgressBar>
                            <ProgressBar bsStyle="success" now={percentPaid} key={1}/>
                            <ProgressBar bsStyle={ tournament.isPay4Start() ? "info" : "success"}  now={percentUnpaid} key={2} />
                            <ProgressBar bsStyle={ tournament.isPay4Start() ? "warning" : "success"} now={percentExceed} key={3} />
                        </ProgressBar>
                    </Col>
                </Row>
            </Panel.Heading>
            <Panel.Body>
                <TournamentAttendeeSearch tournament={tournament}
                                          tournamentUserSearchParams={tournamentUserSearchParams}/>
                <TournamentAttendeeList tournament={tournament}
                                        tournamentUsers={tournamentUsers}/>
            </Panel.Body>
        </Panel>
        <TournamentAttendeeStat tournament={tournament}
                                allTournamentUsers={allTournamentUsers}/>
    </React.Fragment>)
};
TournamentAttendees.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired,
    allTournamentUsers: PropTypes.array.isRequired,
    tournamentUsers: PropTypes.array.isRequired,
    paidTournamentUserAmount: PropTypes.number,
    tournamentUserSearchParams: PropTypes.object
};
const TournamentAttendeeStat = ({tournament, allTournamentUsers}) => {
    if (tournament.getBowUnionID() != null && tournament.getBowUnionID().isTypeLippek()) {
        return null;
    }
    const userResultDic = new TournamentUserResultDic();
    userResultDic.setTournamentSecondGroup(tournament.getTournamentConfigAge());
    allTournamentUsers.forEach(tUser => {
        userResultDic.addSortTournamentUser(tUser, false);
    });
    return <TournamentUserStatTable
        primeTypes={userResultDic.getTournamentPrimeGroup()}
        secTypes={userResultDic.getTournamentSecondGroup()}
        tournamentUserResult={userResultDic}/>
};
const TournamentAttendeeSearch = ({tournament, tournamentUserSearchParams }) => {
    function handleChange(searchText) {
        let searchParams = tournamentUserSearchParams;
        searchParams.text = searchText;
        tournamentUserActions.searchTournamentUser(searchParams);
    }
    function updateSearchParams(searchParams) {
        tournamentUserActions.searchTournamentUser(searchParams);
    }
    return (
        <Row>
            {
                tournament.isPay4Start() ?
                    <Col xs={12} sm={6}>
                        <p><I18n code="tournament.attendee.status.legend"/>:</p>
                        <Icons.attendee.paid/>&nbsp;<I18n code="tournament.attendee.status.legend.paid"/>
                        <br/>
                        <Icons.attendee.pending/>&nbsp;<I18n code="tournament.attendee.status.legend.pending"/>
                        <br/>
                        <Icons.attendee.exceed/>&nbsp;<I18n code="tournament.attendee.status.legend.exceed"/>
                    </Col> : <Col xsHidden sm={6}> </Col>
            }

            <Col xs={12} sm={6}>
                <DelayedTextField placeholder={messages.get("tournamentUser.button.search")}
                                  onChange={handleChange}/>
                <TournamentUserFilterModal tournament={tournament} searchParams={tournamentUserSearchParams} searchParamCallback={updateSearchParams} showStatus={false}/>
            </Col>
        </Row>
    )
};
TournamentAttendeeSearch.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserSearchParams:PropTypes.object.isRequired
}
const TournamentAttendeeList = ({tournament, tournamentUsers}) => {
    const needLicense = tournament.isLicenseMandatory();
    const needStatus = tournament.isPay4Start();
    return (
        <React.Fragment>
            <Table striped className="hidden-xs">
                <thead>
                <tr>
                    <th>#</th>
                    {
                        needStatus ? <th><I18n code="tournament.table.column.status"/></th> : null
                    }
                    {
                        needLicense ? <th><I18n code="modal.license"/></th> : null
                    }
                    <th><I18n code="tournament.table.column.name"/></th>
                    <th><I18n code="tournament.table.column.union"/></th>
                    <th><I18n code="tournament.class.bow"/></th>
                    <th><I18n code="tournament.class.age"/></th>
                </tr>
                </thead>
                <tbody>
                {tournamentUsers.map(tournamentUser => {
                    return (<TournamentAttendeeTableRow key={"TATR_" + tournamentUser.id}
                                                        needStatus={needStatus}
                                                        needLicense={needLicense}
                                                        tournament={tournament}
                                                        tournamentUser={tournamentUser}/>);
                })}
                </tbody>
            </Table>

            <div className="hidden-sm hidden-md hidden-lg">
                {tournamentUsers.map(tournamentUser => {
                    return (<TournamentAttendeeListRow key={"TALR_" + tournamentUser.id}
                                                       needStatus={needStatus}
                                                       needLicense={needLicense}
                                                       tournament={tournament}
                                                       tournamentUser={tournamentUser}/>);
                })}
            </div>
        </React.Fragment>)
};
TournamentAttendeeList.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUsers:PropTypes.array.isRequired
}
const TournamentAttendeeListRow = ({tournament, tournamentUser, needLicense, needStatus}) => {
    return (
        <Row>
            <Col xs={12}>
                <Panel>
                    <Panel.Body>
                        <p className="pull-left"
                           style={{lineHeight: "2.2em"}}>
                            {
                                needStatus ? <TournamentAttendeeStatus tournament={tournament}
                                                                       tournamentUser={tournamentUser}/> : null
                            }
                            <span> #{tournamentUser.startNumber} </span>
                            <b>{tournamentUser.getFullName()}</b>
                            {
                                needLicense ? "  -  #" + tournamentUser.getLicenseNumber() : null
                            }
                        </p>
                        <Table style={{margin: "0"}}>
                            <tbody>
                            {tournamentUser.getUnion() ?
                                <tr>
                                    <th></th>
                                    <td>{tournamentUser.getUnion()}</td>
                                </tr>
                                : null
                            }
                            <tr>
                                <th></th>
                                <td>{tournamentUser.getTournamentConfigBowCodeName()}/ {tournamentUser.getTournamentConfigAgeCodeName()}</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
};
TournamentAttendeeListRow.propTypes = {
    needLicense: PropTypes.bool.isRequired,
    needStatus: PropTypes.bool.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentUser:PropTypes.object.isRequired
};
const TournamentAttendeeTableRow = ({tournament, tournamentUser, needLicense, needStatus}) => {
    return (
        <tr>
            <td>{tournamentUser.startNumber}</td>
            {
                needStatus ? <td><TournamentAttendeeStatus tournament={tournament}
                                                  tournamentUser={tournamentUser}/></td> : null
            }
            {
                needLicense ? <td>{tournamentUser.getLicenseNumber()}</td> : null
            }
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getUnion()}</td>
            <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            <td>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
        </tr>)
};
TournamentAttendeeTableRow.propTypes = {
    needLicense: PropTypes.bool.isRequired,
    needStatus: PropTypes.bool.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentUser:PropTypes.object.isRequired
};
module.exports = TournamentAttendees;
