const React = require("react"),
    linkUtil = require("linkUtil");
const {Row, Col, ListGroup, ListGroupItem} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TextCenter = require("components/widgets/TextCenter");

const featureList = ["skillboard.feature.desc1","skillboard.feature.desc2","skillboard.feature.desc3",
    "skillboard.feature.desc4","skillboard.feature.desc5","skillboard.feature.desc6","skillboard.feature.desc7",
    "skillboard.feature.desc8","skillboard.feature.desc9","skillboard.feature.desc10","skillboard.feature.desc11",
    "skillboard.feature.desc12","skillboard.feature.desc13","skillboard.feature.desc14"];

const SkillBoardFeatureCompare = () => {
        return <Row>
            <FeaturePanel header={"3D SKill Board"} desc={"free"} inclIndex={5}/>
            <FeaturePanel header={"Premium"} desc={"€ 24"} inclIndex={10}/>
            <FeaturePanel header={"Training"} desc={"€ 49"} inclIndex={14}/>
        </Row>
};
const FeaturePanel = ({header, desc, inclIndex}) => {
    return <Col sm={4}>
        <ListGroup style={{boxShadow: "3px 3px lightgrey"}}>
            <ListGroupItem bsStyle="info">
                <TextCenter><h2>{header}</h2></TextCenter>
                <TextCenter><h1>{desc}</h1></TextCenter>
            </ListGroupItem>
            {
                featureList.map((feature, index) => {
                    let icon = index < inclIndex ? "check-circle" :"times-circle"
                    let iconColor= index < inclIndex ? "black" :"lightgrey"
                    return <ListGroupItem key={header+feature} style={{padding: "12px"}}>
                            <span style={{color:iconColor, fontSize:"large"}}>
                                <FontAwesome icon={icon}><I18n code={feature}/></FontAwesome>
                            </span>


                    </ListGroupItem>
                })
            }

        </ListGroup>
    </Col>
};
module.exports = SkillBoardFeatureCompare;
