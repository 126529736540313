var Parse = require("ParseInit");

var BowType = Parse.Object.extend("bowType", {
    col: {
        ACL: "ACL",
        code: "code",
        countTypeID: "countTypeID",
        createdAt: "createdAt",
        description: "description",
        editor: "editor",
        mainGroupPlaceID: "mainGroupPlaceID",
        name: "name",
        objectId: "objectId",
        sortIndex: "sortIndex",
        status: "status",
        unions: "unions",
        updatedAt: "updatedAt",
    },
    getSelectString() {
        return this.getCode() + ", " + this.getName()
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCode() {
        return this.get(this.col.code)
    },
    setCode(code) {
        return this.set(this.col.code, code)
    },
    getCountTypeID() {
        return this.get(this.col.countTypeID)
    },
    setCountTypeID(countTypeID) {
        return this.set(this.col.countTypeID, countTypeID)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getSortIndex() {
        return this.get(this.col.sortIndex)
    },
    setSortIndex(sortIndex) {
        return this.set(this.col.sortIndex, sortIndex)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUnions() {
        return this.get(this.col.unions)
    },
    setUnions(unions) {
        return this.set(this.col.unions, unions)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = BowType;
