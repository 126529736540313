const React = require("react"),
    PropTypes = require('prop-types');
const {SplitButton, MenuItem, OverlayTrigger, Tooltip} = require("react-bootstrap");
const  FontAwesome = require("components/widgets/FontAwesome");

class SplitButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.onClickButton = this.onClickButton.bind(this);
        this.onClickMenuItem = this.onClickMenuItem.bind(this);
        this.state = {
            icon: this.props.default.icon,
            tooltip: this.props.default.tooltip,
            status: this.props.default.status
        }
    }
    onClickButton() {
        this.props.onClickButton(this.state.status)
    }
    onClickMenuItem(entry) {
        let newStatus = entry.status;
        if (this.state.status !== newStatus) {
            this.setState({
                status: newStatus,
                icon: entry.icon,
                tooltip: entry.tooltip
            })
        }
        this.props.onClickButton(newStatus)
    }
    render() {
        const {icon, tooltip} = this.state;
        const {dropDownEntries} = this.props;
        return (<SplitButton  id="bg-nested-dropdown" onClick={this.onClickButton}
                              title={
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                                        <FontAwesome icon={icon}/>
                                    </OverlayTrigger>} >
                {
                    dropDownEntries.map(entry => {
                        return <MenuItem key={entry.status} eventKey={entry.status} onClick={() => this.onClickMenuItem(entry)}>
                            <FontAwesome icon={entry.icon}/>{entry.tooltip}</MenuItem>
                    })
                }
            </SplitButton>)
    }
}
SplitButtonGroup.propTypes = {
    default: PropTypes.object.isRequired,
    dropDownEntries: PropTypes.array.isRequired,
    onClickButton: PropTypes.func.isRequired
};
module.exports = SplitButtonGroup;
