const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table,  Button, ButtonToolbar, Panel, Alert} = require("react-bootstrap");
const linkUtil = require("linkUtil"),
    I18n = require("components/widgets/I18n"),
    LoginButton = require("components/widgets/LoginButton"),
    Loading = require("components/widgets/Loading"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages");

const TournamentAttendeeStatus = require("components/tournament/TournamentAttendeeStatus"),
    TournamentRegistrationUserModal = require("components/tournamentmodal/TournamentRegistrationUserModal");
const TournamentRegistrationFormSingle = require("components/tournamentregister/TournamentRegistrationFormSingle"),
    TournamentRegistrationFormCupChild = require("components/tournamentregister/TournamentRegistrationFormCupChild");

const tournamentRegisterModalStore = require("stores/TournamentRegisterModalStore"),
    tournamentUserStore = require("stores/TournamentUserStore"),
    tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");

class TournamentUserRegistration extends React.Component {
    constructor(props) {
        super(props);
        this.refEditUserModal = React.createRef();
        this.handleModalStoreChange = this.handleModalStoreChange.bind(this);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);
        this.toggleWithoutAccount = this.toggleWithoutAccount.bind(this);
        this.cancelRegistration = this.cancelRegistration.bind(this);
        this.hasValidUser = this.hasValidUser.bind(this);
        this.onEditRegistration = this.onEditRegistration.bind(this);
        this.state = {
            woAccount: false,
            submitDisabled: false,
            tournamentRegisterModalStore: tournamentRegisterModalStore.getState(),
            tournamentUserStore: tournamentUserStore.getState(),
        }
    }
    componentDidMount() {
        tournamentRegisterModalStore.listen(this.handleModalStoreChange);
        tournamentUserStore.listen(this.handleTournamentUserChange);
        tournamentRegisterModalActions.startStepSelfRegistration.defer(this.props.user, this.props.tournament);
        tournamentRegisterModalActions.loadTournamentRegSlots.defer(this.props.tournament);
    }
    componentWillUnmount() {
        tournamentRegisterModalStore.unlisten(this.handleModalStoreChange);
        tournamentUserStore.unlisten(this.handleTournamentUserChange);
    }
    onEditRegistration(tUser) {
        this.refEditUserModal.current.editTUser(tUser);
    }
    handleModalStoreChange(state) {
        this.setState({
            tournamentRegisterModalStore: state,
            error: null
        });
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserStore: state});
    }
    toggleWithoutAccount() {
        const woAcc = this.state.woAccount;
        this.setState({
            woAccount: !woAcc,
        })
    }
    cancelRegistration() {
        tournamentRegisterModalActions.updateUseCupChildRegistration(this.props.tournament.isTypeCupChild());
        if (this.state.woAccount) {
            if (this.state.tournamentRegisterModalStore.tournamentUsers != null && this.state.tournamentRegisterModalStore.tournamentUsers.length > 0) {
                tournamentRegisterModalActions.showOverview();
            } else {
                this.toggleWithoutAccount();
            }
        } else {
            tournamentRegisterModalActions.showOverview();
        }
    }
    hasValidUser() {
        return this.props.user != null;
    }
    render() {
        const {user, tournament} = this.props;
        const isRegistrationFull = tournament.isRegistrationFull();
        let editRegistration = tournament.isPublished() ? this.onEditRegistration : null
        if (this.state.tournamentRegisterModalStore.loading || this.state.tournamentUserStore.loading) {
            return <Row>
                <Col lg={12}><Loading/></Col>
            </Row>
        }
        const isValidUser = this.hasValidUser();
        if (this.state.tournamentRegisterModalStore.step == 1 && !isValidUser && !this.state.woAccount) {
            if (isRegistrationFull) {
                return null;
            } else {
                return <RegistrationStepNoUserInfo toggleWithoutAccount={this.toggleWithoutAccount}/>
            }
        }
        if (this.state.tournamentRegisterModalStore.step == 2) {
            return <React.Fragment>
                <TournamentRegistrationUserModal ref={this.refEditUserModal} tournament={tournament}/>
                <RegistrationStepNext user={user}
                                      tournament={tournament}
                                      woAccount={this.state.woAccount}
                                      editRegistration={editRegistration}
                                      showRegisterButtons={!isRegistrationFull}
                                      allTournamentUsers={this.state.tournamentUserStore.allTournamentUsers}
                                      tournamentRegisterModalStore={this.state.tournamentRegisterModalStore}/>
            </React.Fragment>
        }
        if (isRegistrationFull) {
            return null;
        } else if (this.state.tournamentRegisterModalStore.cupLicenseRegister) {
            // handle child cup tournament - login with cup number
            return <TournamentRegistrationFormCupChild user={user}
                                                     tournament={tournament}
                                                     tournamentRegisterModalStore={this.state.tournamentRegisterModalStore}/>
        } else {
            // handle login for single user
            return <TournamentRegistrationFormSingle user={user}
                                                     tournament={tournament}
                                                     tournamentRegisterModalStore={this.state.tournamentRegisterModalStore}
                                                     cancelRegistration={this.cancelRegistration}/>
        }
    }
}
TournamentUserRegistration.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object
};
const RegistrationStepNoUserInfo = ({toggleWithoutAccount}) =>{
    return (<Row>
        <Col xs={12}>
            <h4>{messages.get("tournament.register.description.noaccount")}</h4>
            <p>{messages.get("tournament.register.description.getAccount")}
                <ul>
                    <li>{messages.get("tournament.register.description.fastRegister")}</li>
                    <li>{messages.get("tournament.register.description.reUse")}</li>
                    <li>{messages.get("tournament.register.description.useApp")}</li>
                </ul>
            </p>
            <br/>
        </Col>
        <Col sm={6} style={{"textAlign": "center"}}>
            <LoginButton/>&nbsp;&nbsp;<LoginButton create={true}/>
        </Col>
        <Col sm={6} style={{"textAlign": "center"}}>
            <a href="#" onClick={toggleWithoutAccount}><I18n code="tournament.register.description.without"/></a>
        </Col>
    </Row>)
};
RegistrationStepNoUserInfo.propTypes = {
    toggleWithoutAccount: PropTypes.func.isRequired
};
const RegistrationStepNext = ({user, tournament, woAccount, tournamentRegisterModalStore, showRegisterButtons, allTournamentUsers, editRegistration}) =>{
    const registerSelf = () => {
        tournamentRegisterModalActions.startStepSelfRegistration(user, tournamentRegisterModalStore.tournament);
        tournamentRegisterModalActions.filterOwnRegistrations.defer(allTournamentUsers);
    };
    const registerMore = () => {
        tournamentRegisterModalActions.startStepForeignRegistration(user, tournament);
    };
    const isCurrentUserAlreadyRegisterd = () => {
        if (woAccount) {
            return true;
        }
        if (tournamentRegisterModalStore.tournamentUsers && tournamentRegisterModalStore.tournamentUsers.length > 0 && user) {
            for (let i = 0; i < tournamentRegisterModalStore.tournamentUsers.length; i++) {
                if (tournamentRegisterModalStore.tournamentUsers[i].isSelfRegisteredUsers(user)) {
                    return true;
                }
            }
        }
        return false;
    };
    return (<Row>
        <Col xs={12} lg={8}>
            <TournamentUserRegistreeList
                user={user}
                editRegistration={editRegistration}
                tournament={tournament}
                tournamentUsers={tournamentRegisterModalStore.tournamentUsers}
            />
        </Col>
        {
            showRegisterButtons ?
                <Col  xs={12} lg={4}>
                    {isCurrentUserAlreadyRegisterd() ? null :
                        <Button block bsSize="large" bsStyle="primary" onClick={registerSelf}>
                            <FontAwesome icon="plus"/><I18n code="tournament.button.register"/>
                        </Button>
                    }
                    <Button block bsSize="large" onClick={registerMore} bsStyle="primary">
                        <I18n code="tournament.button.register.more"/>
                    </Button>
                    {
                        tournament.getNoTuserGroups() ? <Button block bsSize="large" bsStyle="primary"
                                                                href={linkUtil.getLink("/tournament/" + tournament.id + "/groups")}>
                            <FontAwesome icon="users"><I18n code="tournament.button.register.groups"/></FontAwesome>
                        </Button> : null
                    }
                    {
                        tournament.isOnlinePaymentEnabled() ?
                            <Button block bsSize="large" bsStyle="primary"
                                    href={linkUtil.getLink("tournament/" + tournament.id+"/checkout")}>
                                <FontAwesome icon="euro-sign"/><I18n code="tournament.register.panel.pay"/>
                            </Button> : null
                    }
                </Col> : null
        }
    </Row>)
};
RegistrationStepNext.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired,
    woAccount: PropTypes.bool.isRequired,
    tournamentRegisterModalStore: PropTypes.object.isRequired,
    showRegisterButtons: PropTypes.bool.isRequired,
    editRegistration: PropTypes.func,
    allTournamentUsers: PropTypes.array
};

const TournamentUserRegistreeList = ({user, tournament, tournamentUsers, editRegistration}) =>{
    if (tournamentUsers.length == 0) {
        return (<div><I18n code="tournament.register.panel.info.noregistration"/></div>);
    }
    return (
        <React.Fragment>
            {tournamentUsers.map(tournamentUser => {
                return (<TournamentRegistreeListRow key={"TRLR_" + tournamentUser.id}
                                                    user={user}
                                                    editRegistration={editRegistration}
                                                    tournament={tournament}
                                                    tournamentUser={tournamentUser}/>);
            })}
        </React.Fragment>
    );
};
TournamentUserRegistreeList.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentUsers:PropTypes.array.isRequired,
    editRegistration:PropTypes.func
};
const TournamentRegistreeListRow = ({user, tournament, tournamentUser, editRegistration}) =>{
    return (
        <Row key={tournamentUser.id}>
            <Col xs={12}>
                <Panel style={{lineHeight: "2.5em", fontSize: "130%"}}>
                    <Panel.Heading>
                        <TournamentListRowHeaderObj user={user} editRegistration={editRegistration}
                                                    tournament={tournament} tournamentUser={tournamentUser} />
                    </Panel.Heading>
                    <Panel.Body>
                        <Table style={{margin: "0"}}>
                            <tbody>
                            {tournamentUser.getUnion() ?
                                <tr>
                                    <th><I18n code="tournament.table.column.union"/></th>
                                    <td>{tournamentUser.getUnion()}</td>
                                </tr>
                                : null
                            }
                            {
                                tournament.isLicenseMandatory() ?
                                    <tr>
                                        <th>{tournament.getBowUnionID().getName() + " #"}</th>
                                        <td>{tournamentUser.getLicenseNumber()}</td>
                                    </tr> : null
                            }
                            <tr>
                                <th><I18n code="tournament.class.bow"/></th>
                                <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
                            </tr>
                            <tr>
                                <th><I18n code="tournament.class.age"/></th>
                                <td>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
                            </tr>
                            {
                                tournamentUser.getOption() != null && tournament.getTournamentOptionID() != null ?
                                    <tr>
                                        <th>{tournament.getTournamentOptionID().getOptionLabel()}</th>
                                        <td>{tournamentUser.getOption()}</td>
                                    </tr> : null
                            }
                            {
                                tournamentUser.getTournamentRegSlotID() != null ?
                                    <tr>
                                        <th><I18n code="tournament.manager.config.slots"/></th>
                                        <td>{tournamentUser.getTournamentRegSlotName()}</td>
                                    </tr> : null
                            }
                            {
                                tournamentUser.isPaid() || tournamentUser.getPayAmount() <= 0 ? null :
                                    <React.Fragment>
                                        <tr>
                                            <th><I18n code="tournament.register.label.price"/></th>
                                            <td>{tournamentUser.getPayAmount() + " " + tournament.getTournamentPriceID().getCurrency()}</td>
                                        </tr>
                                        <tr>
                                            <th><I18n code="tournament.register.label.payReference"/></th>
                                            <td>{tournamentUser.getPayRefID()}</td>
                                        </tr>
                                    </React.Fragment>
                            }
                            </tbody>
                        </Table>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
};
TournamentRegistreeListRow.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentUser:PropTypes.object.isRequired,
    editRegistration: PropTypes.func
};
const TournamentListRowHeaderObj = ({user, tournament, tournamentUser, editRegistration}) =>{
    const deRegister =() => {
        tournamentRegisterModalActions.deRegisterFromTournament(tournament, tournamentUser, user);
    };
    const editTUser= () => {
        editRegistration(tournamentUser)
    }
    return (<React.Fragment>
        <TournamentAttendeeStatus tournament={tournament} tournamentUser={tournamentUser}/>
        &nbsp;&nbsp;#{tournamentUser.startNumber}&nbsp;&nbsp;
        <b>{tournamentUser.getFullName()}</b>
        {
            tournamentUser.isRegistered() ? <ButtonToolbar className="pull-right">
                {
                    editRegistration != null ? <Button bsStyle="default" bsSize="small" onClick={editTUser}>
                        <FontAwesome icon="pencil-alt"><I18n code="modal.button.edit"/></FontAwesome>
                    </Button> : null
                }
                <SubmitButton onClick={deRegister} confirm={true}
                              icon={<FontAwesome icon="times"/>}
                              text={messages.get("tournament.button.unregister")}
                              bsStyle="default" bsSize="small"/>
            </ButtonToolbar> : null
        }
    </React.Fragment>)
};
TournamentListRowHeaderObj.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentUser:PropTypes.object.isRequired,
    editRegistration:PropTypes.func
};
module.exports = TournamentUserRegistration;
