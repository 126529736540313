const Parse = require("ParseInit");

const EventParcoursStat = Parse.Object.extend("EventParcoursStat", {
  col: {
    objectId: "objectId",
    ACL: "ACL",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
    parcoursID: "parcoursID",
    parcoursOnlineID: "parcoursOnlineID",
    parcoursOwnerID: "parcoursOwnerID",
    groupPlaceID: "groupPlaceID",
    mainGroupPlaceID: "mainGroupPlaceID",
    eventAmount: "eventAmount",
    weekDate: "weekDate"
  },
  //
  // getter / setter
  //
  getACL: function() {
    return this.get(this.col.ACL)
  },
  setACL: function(ACL) {
    return this.set(this.col.ACL, ACL)
  },
  getParcoursID: function () {
    return this.get(this.col.parcoursID)
  },
  setParcoursID: function (parcoursID) {
    return this.set(this.col.parcoursID, parcoursID)
  },
  getParcoursOnlineID: function () {
    return this.get(this.col.parcoursOnlineID)
  },
  setParcoursOnlineID: function (parcoursOnlineID) {
    return this.set(this.col.parcoursOnlineID, parcoursOnlineID)
  },
  getGroupPlaceID: function() {
    return this.get(this.col.groupPlaceID)
  },
  setGroupPlaceID: function(groupPlaceID) {
    return this.set(this.col.groupPlaceID, groupPlaceID)
  },
  getMainGroupPlaceID: function() {
    return this.get(this.col.mainGroupPlaceID)
  },
  setMainGroupPlaceID: function(mainGroupPlaceID) {
    return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
  },
  getObjectId: function() {
    return this.get(this.col.objectId)
  },
  setObjectId: function(objectId) {
    return this.set(this.col.objectId, objectId)
  },
  getEventAmount: function() {
    return this.get(this.col.eventAmount)
  },
  setEventAmount: function(amount) {
    return this.set(this.col.eventAmount, amount)
  },
  getWeekDate: function () {
    return this.get(this.col.weekDate)
  },
  setWeekDate: function (date) {
    return this.set(this.col.weekDate, date)
  },
  getUpdatedAt: function() {
    return this.get(this.col.updatedAt)
  }
});

module.exports = EventParcoursStat;
