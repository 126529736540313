const Parse = require("parse");
const ReactGA = require("react-ga");
const ConfigKeys = require("ConfigKeys");

Parse.initialize(
    ConfigKeys.appId,
    ConfigKeys.appKey
);
if (ConfigKeys.backendUrl) {
    Parse.serverURL = ConfigKeys.backendUrl;
}
ReactGA.initialize('UA-46740663-4');
module.exports = Parse;