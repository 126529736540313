const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip, Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    BowTypeIcon = require("components/widgets/BowTypeIcon"),
    EventRankingVisibility  = require("components/profile/EventRankingVisibility"),
    messages = require("i18n/messages");

const eventDetailActions = require("actions/EventDetailActions");


const EventPlayersTable = ({allowEdit, adminPage, eventPlayers, ownEventPlayer}) => {
    let index = 0;
    let showAction = (allowEdit === true  || adminPage || ownEventPlayer != null)
    return (
            <Table fill striped responsive>
                <thead>
                <tr>
                    <th>Pos</th>
                    <th><I18n code="tournament.table.column.bow"/></th>
                    <th><I18n code="tournament.table.column.name"/></th>
                    <th><I18n code="tournament.table.column.points"/></th>
                    <th>Kills</th>
                    {
                        showAction ? <th><I18n code="parcours.details.button.actions"/></th> : null
                    }
                    {
                        adminPage === true ? <th>ID/Mail</th> : null
                    }
                    {
                        adminPage === true ? <th>CountType ID / Online ID</th> : null
                    }
                </tr>
                </thead>
                <tbody>
                {
                    eventPlayers == null ? "" :
                        eventPlayers.map(evPlayer => {
                            if (!evPlayer.isDeleted()) {
                                index++;
                            }
                            return <EventDetailRow key={evPlayer.id} eventPlayer={evPlayer} index={index}
                                                   adminPage={adminPage} showAction={showAction}
                                                   allowEdit={allowEdit || (ownEventPlayer != null && ownEventPlayer.id === evPlayer.id)}/>
                        })
                }
                </tbody>
            </Table>
    )
};
EventPlayersTable.propTypes = {
    eventPlayers:PropTypes.array.isRequired,
    adminPage:PropTypes.bool.isRequired,
    allowEdit:PropTypes.bool
};
const EventDetailRow = ({index, eventPlayer, allowEdit, showAction, adminPage}) => {
    function handleDeleteEventPlayer() {
        eventDetailActions.changeEventPlayerStatus(eventPlayer, "D");
    }
    function handleRestoreEventPlayer() {
        eventDetailActions.changeEventPlayerStatus(eventPlayer, "A");
    }
    function getCountTypeInfo() {
        let message = messages.getCountTypeTranslation(eventPlayer.getCountTypeOnlineID());
        let countTypeId = "NULL"
        if (eventPlayer.getCountTypeID() != null) {
            countTypeId = eventPlayer.getCountTypeID().id
            if (message == null) {
                message = eventPlayer.getCountTypeID().getName();
            }
        }
        return <td>{countTypeId}&nbsp;/&nbsp;{eventPlayer.getCountTypeOnlineID()}<br/>{message}</td>
    }
    function saveEvent(event) {
        eventDetailActions.saveEvent(event, (sEvent) => {
            // this.hideDetails();
        })
    }
    const sumPoints = eventPlayer.getSumPoints();
    const isDeleted = eventPlayer.isDeleted();
    let tooltip = <Tooltip id="tooltip">{messages.get("modal.button.delete")}</Tooltip>;
    let actionButton = <Button onClick={handleDeleteEventPlayer}><FontAwesome icon="trash"/></Button>;
    if (isDeleted) {
        tooltip = <Tooltip id="tooltip">{messages.get("modal.button.restore")}</Tooltip>;
        actionButton = <Button onClick={handleRestoreEventPlayer}><FontAwesome icon="undo"/></Button>;
    }

    return (
        <tr id={eventPlayer.id}>
            <td>{sumPoints >= 0 && !isDeleted? index : ""}</td>
            <td><BowTypeIcon bowTypeId={eventPlayer.getBowTypeId()} /></td>
            <td><StrikeOutText strikeOut={isDeleted}>{eventPlayer.getPlayerNameWithBowArrow()}</StrikeOutText></td>
            <td>{sumPoints >= 0 ? eventPlayer.getPointPercentDPSString() : "" }</td>
            <td>{eventPlayer.getKillCounts2String()}</td>
            {
                showAction ?
                    <td>
                        {
                            allowEdit || adminPage ? <React.Fragment>
                                <OverlayTrigger placement="bottom" overlay={tooltip}>
                                    {actionButton}
                                </OverlayTrigger>
                                <EventRankingVisibility event={eventPlayer.getEventID()} saveEvent={saveEvent}/>
                            </React.Fragment> : null
                        }

                    </td>
                    : null
            }
            {
                adminPage === true ? <td>{eventPlayer.id}<br/>{eventPlayer.getPlayerEmail()}</td> : null
            }
            {
                adminPage === true ? getCountTypeInfo() : null
            }
        </tr>
    )
};
EventDetailRow.propTypes = {
    index:PropTypes.number.isRequired,
    eventPlayer:PropTypes.object.isRequired,
    adminPage:PropTypes.bool.isRequired,
    allowEdit:PropTypes.bool
};
module.exports = EventPlayersTable;
