const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Tabs,Tab, Button, ListGroup, ListGroupItem} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TextCenter = require("components/widgets/TextCenter"),
    CheckboxInput = require("components/form/CheckboxInput"),
    CoordinateInput = require("components/form/CoordinateInput"),
    RadioGroupInput = require("components/form/RadioGroupInput"),
    FileUploadInput = require("components/form/FileUploadInput"),
    DateInput = require("components/form/DateInput"),
    ValidInput = require("components/form/ValidInput");

const {isBefore} = require('date-fns');

const EntryRemovable  = require("components/form/EntryRemovable");

const messages = require("i18n/messages");

const Utility = require("util/Utility");
const ClubUtility = require("components/club/ClubUtility");

const ClubEvent = require("parse/ClubEvent");

const clubActions = require("actions/ClubActions");

class ClubEventCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.sendMail = null;
        this.refCoord = React.createRef();
        this.refName = React.createRef();
        this.refNote = React.createRef();
        this.refLink = React.createRef();
        this.refDate = React.createRef();
        this.refTime = React.createRef();
        this.refEndDate = React.createRef();
        this.refEndTime = React.createRef();
        this.refPlace = React.createRef();
        this.refOptionName = React.createRef();
        this.refSecret = React.createRef();

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.editEvent = this.editEvent.bind(this);
        this.newEvent = this.newEvent.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clickChangeEventVisibility = this.clickChangeEventVisibility.bind(this);
        this.clickChangeGroup = this.clickChangeGroup.bind(this);
        this.hasGroup = this.hasGroup.bind(this);
        this.handleParseFile = this.handleParseFile.bind(this);
        this.clickedOptionAdd = this.clickedOptionAdd.bind(this);
        this.clickedOptionRemove = this.clickedOptionRemove.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.getDefault = this.getDefault.bind(this);
        this.getDefaultDate = this.getDefaultDate.bind(this);
        this.getDefaultTime = this.getDefaultTime.bind(this);
        this.changeMail = this.changeMail.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            vis: "event.vis.club",
            editClubEvent: null,
            weekday: null,
            groups: null,
            options: null,
            parseFile: null,
        }
    }
    close() {
        this.sendMail = null;
        this.setState({submitDisabled: false, showModal: false, error:null, weekday: null});
    }
    open() {
        this.setState({
            showModal: true,
            submitDisabled: false,
            vis: "event.vis.club",
            editClubEvent: null,
            weekday: null,
            parseFile: null,
            groups: null,
            options: null,
        });
    }
    editEvent(editClubEvent) {
        let visi = "event.vis.club";
        if (editClubEvent.getVisibility() === ClubEvent.prototype.vis.publi) {
            visi = "event.vis.public";
        }
        this.setState({
            showModal: true,
            submitDisabled: false,
            editClubEvent: editClubEvent,
            weekday: null,
            vis: visi,
            parseFile: editClubEvent.getDocument(),
            groups: editClubEvent.getGroups(),
            options: editClubEvent.getOptions()
        });
    }
    newEvent(weekday) {
        this.setState({
            showModal: true,
            submitDisabled: false,
            vis: "event.vis.club",
            editClubEvent: null,
            weekday: weekday,
            parseFile: null,
            groups: null,
            options: null,
        });
    }
    valid() {
        const required = [
            this.refName.current.getValue(),
            this.refDate.current.getValue(),
            this.refEndDate.current.getValue(),
            this.refTime.current.getValue(),
            this.refEndTime.current.getValue()
        ];
        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        const startDateParts = new Date(this.refDate.current.getValue());
        const startTimeParts = this.refTime.current.getValue().split(":");
        const startDate = new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), startTimeParts[0], startTimeParts[1], 0, 0);

        const endDateParts = new Date(this.refEndDate.current.getValue());
        const endTimeParts = this.refEndTime.current.getValue().split(":");
        const endDate =new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), endTimeParts[0], endTimeParts[1], 1, 0);
        if (isBefore(endDate, startDate)) {
            this.setState({error: messages.get("tournament.create.date.before")});
            return false;
        }
        return true;
    }
    handleSubmit(status) {
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        let clubEvent = this.state.editClubEvent;
        if (clubEvent == null) {
            clubEvent = new ClubEvent();
            clubEvent.setClubID(this.props.club);
            clubEvent.setCreator(this.props.ownClubUser.getUserID());
        }
        clubEvent.setName(this.refName.current.getValue());
        if (this.refNote.current.getValue()) {
            clubEvent.setDescription(this.refNote.current.getValue());
        } else {
            clubEvent.unset(ClubEvent.prototype.col.description);
        }
        const startDateParts = new Date(this.refDate.current.getValue());
        const startTimeParts = this.refTime.current.getValue().split(":");
        clubEvent.setDate(new Date(startDateParts.getFullYear(), startDateParts.getMonth(), startDateParts.getDate(), startTimeParts[0], startTimeParts[1], 0, 0));

        const endDateParts = new Date(this.refEndDate.current.getValue());
        const endTimeParts = this.refEndTime.current.getValue().split(":");
        clubEvent.setEndDate(new Date(endDateParts.getFullYear(), endDateParts.getMonth(), endDateParts.getDate(), endTimeParts[0], endTimeParts[1], 1, 0));
        clubEvent.setStatus(status);
        let visibility = ClubEvent.prototype.vis.club;
        if (this.state.vis == "event.vis.public") {
            visibility = ClubEvent.prototype.vis.publi;
            clubEvent.unset(ClubEvent.prototype.col.groups);
        }
        clubEvent.setVisibility(visibility);
        if (this.state.groups != null && this.state.groups.length > 0) {
            clubEvent.setGroups(this.state.groups);
        } else {
            clubEvent.unset(ClubEvent.prototype.col.groups);
        }
        if (this.state.options != null && this.state.options.length > 0) {
            clubEvent.setOptions(this.state.options);
        } else {
            clubEvent.unset(ClubEvent.prototype.col.options);
        }
        if (this.refSecret.current.getValue()) {
            clubEvent.setLinkSecret(this.refSecret.current.getValue());
        } else {
            clubEvent.unset(ClubEvent.prototype.col.linkSecret);
        }
        if (this.refPlace.current.getValue()) {
            clubEvent.setPlace(this.refPlace.current.getValue());
        } else {
            clubEvent.unset(ClubEvent.prototype.col.place);
        }
        if (this.refLink.current.getValue()) {
            clubEvent.setWeblink(this.refLink.current.getValue());
        } else {
            clubEvent.unset(ClubEvent.prototype.col.weblink);
        }
        if (this.refCoord.current.getValue()) {
            clubEvent.setCoordinateReadable(this.refCoord.current.getValue());
        } else {
            clubEvent.unset(ClubEvent.prototype.col.coordinates);
        }
        if (this.state.parseFile != null) {
            clubEvent.setDocument(this.state.parseFile);
        }
        let mailAddresses = null;
        if ("A" === status && this.sendMail === true) {
            mailAddresses = ClubUtility.getEmailListForGroups(this.props.clubUsers, this.state.groups, true);
        }
        clubActions.updateEventLoading(true);
        clubActions.saveClubEvent(clubEvent, mailAddresses);
        this.close();
    }
    clickChangeEventVisibility(value) {
        this.setState({vis: value});
    }
    clickChangeGroup(group, value) {
        let groups = this.state.groups;
        if (groups == null) {
            groups = [];
        }
        if (value == true) {
            groups.push(group);
        } else {
            groups = groups.filter(u => {
                return u != group;
            })
        }
        this.setState({groups: groups});
    }
    hasGroup(group) {
        const groupList = this.state.groups;
        if (groupList != null) {
            return groupList.indexOf(group) > -1
        }
        return false;
    }
    handleParseFile(parseFile, imageType) {
        this.setState({parseFile: parseFile});
    }
    clickedOptionAdd(e) {
        e.preventDefault();
        const optName = this.refOptionName.current.getValue().trim();
        if (optName != null && optName.length > 0) {
            // add options
            let tmpOptions = this.state.options;
            if (tmpOptions == null) {
                tmpOptions = [];
            }
            tmpOptions.push(optName);
            this.setState({options: tmpOptions});
        }
    }
    clickedOptionRemove(optName) {
        let tmpOptions = this.state.options;
        if (tmpOptions == null) {
            return;
        } else {
            tmpOptions = tmpOptions.filter(u => {
                return u != optName;
            });
            this.setState({options: tmpOptions});
        }
    }
    handleStartDateChange(value) {
        this.refEndDate.current.setValue(value);
    }
    getDefault(column) {
        if (this.state.editClubEvent != null) {
            return this.state.editClubEvent.get(column);
        }
        return "";
    }
    getDefaultDate(column) {
        if (this.state.editClubEvent != null) {
            return this.state.editClubEvent.get(column);
        } else if (this.state.weekday != null) {
            return this.state.weekday;
        } else {
            return new Date();
        }
    }
    getDefaultTime(column) {
        if (this.state.editClubEvent != null) {
            return Utility.getReadableTime(this.state.editClubEvent.get(column));
        } else {
            return Utility.getReadableTime(new Date());
        }
    }
    changeMail(value) {
        this.sendMail = value;
    }
    render() {
        const {showModal, editClubEvent, options, vis, parseFile, submitDisabled, error} = this.state;
        const {club} = this.props;
        const validateNow = error && (error.message != null || error.length > 0);
        const groups = club.getFilterGroups();
        let hasOptionResult = false;
        if (editClubEvent != null) {
            hasOptionResult = editClubEvent.getOptionResult() != null;
        }
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <form action="#">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="club.event.create.title"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            showModal ?
                                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                    <Tab eventKey={1} title={messages.get("club.event.create.message.title")}>
                                        <Row>
                                            <Col sm={12}>
                                                <br/>
                                                <ValidInput ref={this.refName}
                                                            valid={{maxLength: 50, check:['isRequired']}}
                                                            validateNow={validateNow}
                                                            default={this.getDefault(ClubEvent.prototype.col.name)}
                                                            placeholder={messages.get("club.event.create.message.name")}/>

                                                <ValidInput ref={this.refNote}
                                                            componentClass="textarea"
                                                            valid={{maxLength: 1500}}
                                                            validateNow={validateNow}
                                                            default={this.getDefault(ClubEvent.prototype.col.description)}
                                                            placeholder={messages.get("club.event.create.message.note") + " - " + messages.get("modal.entry.optional")}/>

                                                <ValidInput ref={this.refLink}
                                                            valid={{maxLength: 50, check: ['isUrl']}}
                                                            validateNow={validateNow}
                                                            default={this.getDefault(ClubEvent.prototype.col.weblink)}
                                                            placeholder={messages.get("tournament.create.union.link")}
                                                            addonBefore={<FontAwesome icon="globe"/>}/>

                                            </Col>
                                            <Col sm={4}>

                                                <DateInput ref={this.refDate} default={this.getDefaultDate(ClubEvent.prototype.col.date)}
                                                           label={messages.get("tournament.create.date")}
                                                           onAfterChange={this.handleStartDateChange}/>

                                            </Col>
                                            <Col sm={2}>
                                                <ValidInput ref={this.refTime} default={this.getDefaultTime(ClubEvent.prototype.col.date)}
                                                            type="text"
                                                            label={messages.get("tournament.create.time")}
                                                            valid={{check:['isTime', 'isRequired']}}
                                                            validateNow={validateNow}/>
                                            </Col>
                                            <Col sm={4}>

                                                <DateInput ref={this.refEndDate} default={this.getDefaultDate(ClubEvent.prototype.col.endDate)}
                                                           label={messages.get("tournament.create.enddate")}/>
                                            </Col>
                                            <Col sm={2}>
                                                <ValidInput ref={this.refEndTime} default={this.getDefaultTime(ClubEvent.prototype.col.endDate)}
                                                            type="text"
                                                            label={messages.get("tournament.create.endtime")}
                                                            valid={{check:['isTime', 'isRequired']}}
                                                            validateNow={validateNow}/>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={2} title={messages.get("club.event.create.place.title")}>
                                        <Row>
                                            <br/>
                                            <Col sm={12}>
                                                <ValidInput ref={this.refPlace}
                                                            valid={{maxLength: 200}}
                                                            default={this.getDefault(ClubEvent.prototype.col.place)}
                                                            placeholder={messages.get("club.event.create.place.place")}/>
                                            </Col>
                                            <Col sm={12}>
                                                <CoordinateInput ref={this.refCoord} objectWithCoords={editClubEvent} />
                                            </Col>

                                        </Row>

                                    </Tab>
                                    <Tab eventKey={3} title={messages.get("club.event.create.visibility.title")}>
                                        <Row>
                                            <br/>
                                            <Col xs={12}>
                                                <p><I18n code={"club.event.create.visibility.desc"}/></p>
                                                <RadioGroupInput default={vis}
                                                                 list={["event.vis.club", "event.vis.public"]}
                                                                 onAfterChange={this.clickChangeEventVisibility}/>
                                            </Col>
                                            {
                                                groups != null && groups.length > 0  && vis == "event.vis.club" ?
                                                    <Col sm={12}>
                                                        <p><I18n code={"club.event.create.visibility.group.desc"}/></p>
                                                        {
                                                            groups.map(group => {
                                                                return <GroupEntry key={"EDT" + group} group={group}
                                                                                   defaultValue={this.hasGroup(group)}
                                                                                   onChange={this.clickChangeGroup}/>
                                                            })
                                                        }
                                                    </Col>
                                                    : null
                                            }
                                        </Row>

                                    </Tab>
                                    <Tab eventKey={4} title={messages.get("club.event.create.interaction.title")}>
                                        <Row>
                                            <br/>
                                            <Col xs={12}>
                                                <ValidInput ref={this.refSecret}
                                                            valid={{maxLength: 10}}
                                                            default={this.getDefault(ClubEvent.prototype.col.linkSecret)}
                                                            placeholder="skb"
                                                            label={messages.get("club.event.create.opt.linksecret")}/>
                                            </Col>
                                            {
                                                hasOptionResult == true ? null :
                                                    <Col xs={12}>
                                                        <ValidInput ref={this.refOptionName}
                                                                    valid={{maxLength: 20, check:['isRequired']}}
                                                                    placeholder={messages.get("club.event.create.opt.new")}
                                                                    buttonAfter={
                                                                        <Button onClick={this.clickedOptionAdd}>
                                                                            <FontAwesome icon="save"/>
                                                                        </Button>}/>
                                                    </Col>
                                            }

                                            <Col xs={12}>
                                                <h4><I18n code={"club.event.create.opt.existing"}/></h4>
                                                <ListGroup>
                                                    {
                                                        options != null ? options.map(option => {
                                                            if (hasOptionResult) {
                                                                return (
                                                                    <ListGroupItem>
                                                                        {option + ": " + editClubEvent.getOptionResultAmount(option)}
                                                                    </ListGroupItem>)
                                                            } else {
                                                                return <EntryRemovable key={"OPT" + option} group={option}
                                                                                       onRemove={this.clickedOptionRemove}/>
                                                            }
                                                        }) : null
                                                    }</ListGroup>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={5} title={messages.get("modal.entry.attachment")}>
                                        <Row>
                                            <Col sm={12}>
                                                <br/>
                                                <FileUploadInput parseFile={parseFile}
                                                                 allowedFileTypes={["P","D","I"]}
                                                                 savedParseFileCallback={this.handleParseFile}/>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs> : null
                        }
                        {error ?
                            <Alert bsStyle="warning">{error}</Alert>
                            : ""}
                    </Modal.Body>
                    <Modal.Footer>
                        <Row>
                            <Col xs={4}>
                                {
                                    editClubEvent == null || editClubEvent.getStatus() === ClubEvent.prototype.status.draft ?
                                        <Button disabled={submitDisabled} bsStyle="primary"
                                                onClick={() => this.handleSubmit(ClubEvent.prototype.status.draft)}>
                                            <FontAwesome icon="save"/>{messages.get("club.event.create.draft")}
                                        </Button> : null
                                }
                            </Col>
                            <Col xs={8}  style={{"textAlign": "left"}}>
                                <Button disabled={submitDisabled} bsStyle="primary"
                                        onClick={() => this.handleSubmit(ClubEvent.prototype.status.published)}>
                                    <FontAwesome icon="bullhorn"/>&nbsp;{messages.get("club.event.create.publish")}
                                </Button>
                                <CheckboxInput  message={messages.get("club.event.create.mail")}
                                                disabled={!club.isEmailVerified()}
                                                onAfterChange={this.changeMail} />
                            </Col>
                        </Row>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
const GroupEntry = ({defaultValue, group, onChange}) => {
    function onAfterChange(value) {
        onChange(group, value)
    }
    return (
        <CheckboxInput default={defaultValue} onAfterChange={onAfterChange} message={group} />);
};
GroupEntry.propTypes = {
    defaultValue:PropTypes.bool.isRequired,
    onChange:PropTypes.func.isRequired,
    group:PropTypes.string.isRequired
};
module.exports = ClubEventCreateModal;
