const React = require("react"),
    PropTypes = require('prop-types'),
    Parse = require("ParseInit"),
    linkUtil = require("linkUtil");
const {Row, Col, Grid, Alert, Button, ButtonGroup, Panel} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Title = require("components/widgets/Title"),
    GroupPlaces = require("components/widgets/GroupPlaces"),
    Loading = require("components/widgets/Loading"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ParcoursCreateModal = require("components/parcours/ParcoursCreateModal"),
    MapFrame = require("components/map/MapFrame"),
    ListLayout = require("components/widgets/ListLayout"),
    Utility = require("util/Utility");

const PoIItem = require("components/parcours/PoIItem"),
    ParcoursItem = require("components/parcours/ParcoursItem");

const messages = require("i18n/messages");

const groupPlaceActions = require("actions/GroupPlaceActions"),
    userStore = require("stores/UserStore"),
    UserActions = require("actions/UserActions"),
    notificationActions = require("components/notification/NotificationActions"),
    layoutActions = require("actions/LayoutActions"),
    layoutStore = require("stores/LayoutStore"),
    parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

const analyticsActions = require("actions/AnalyticsActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ParcoursPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.state = {
            parcoursStore: parcoursStore.getState(),
            userStore: userStore.getState(),
            layoutStore: layoutStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.parcours");
        userStore.listen(this.handleUserChange);
        parcoursStore.listen(this.handleParcoursChange);
        layoutStore.listen(this.handleLayoutChange);
        // init
        if (this.props.display != null) {
            layoutActions.displayTypeChanged(this.props.display);
        } else {
            layoutActions.displayTypeChanged("MAP");
        }
        notificationActions.reset();
        groupPlaceActions.reset();
        const searchParams = this.state.parcoursStore.searchParams;
        searchParams.payment = false;
        parcoursActions.startUp(searchParams);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        parcoursStore.unlisten(this.handleParcoursChange);
        layoutStore.unlisten(this.handleLayoutChange)
    }
    handleLayoutChange(state) {
        this.setState({layoutStore:state});
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    handleUserChange(user) {
        this.setState({userStore: user});
    }
    render() {
        const {displayType} = this.state.layoutStore;
        const {parcoursList, searchParams, listRenderAmount, allParcoursList} = this.state.parcoursStore;
        return (
            <Grid>
                <Row>
                    <Col xs={12} xsHidden>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="parcours.title"/>
                <ListLayout.Wrapper>
                    <ListLayout.Filter>
                        <ParcoursFilter displayType={displayType} searchParams={searchParams} allParcoursList={allParcoursList} user={this.state.userStore.user}/>
                        {
                            displayType === "LIST" ? null :  <PointInterestBanner />
                        }
                    </ListLayout.Filter>
                    {
                        displayType === "MAP" ?
                            <ListLayout.List>
                                <MapFrame parcours={parcoursList} ownPosition={searchParams.coords}/>
                            </ListLayout.List> : null
                    }
                    {
                        displayType === "LIST" ?
                            <ListLayout.List>
                                <ParcoursList list={parcoursList} ownPosition={searchParams.coords} listRenderAmount={listRenderAmount}/>
                            </ListLayout.List> : null
                    }
                </ListLayout.Wrapper>
            </Grid>)
    }
}

class ParcoursFilter extends React.Component {
    constructor(props) {
        super(props);
        this.refParcoursCreateModal = React.createRef();

        this.openCreateParcoursDialog = this.openCreateParcoursDialog.bind(this);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSearchTextFoundElement = this.handleSearchTextFoundElement.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleCoordsSearch = this.handleCoordsSearch.bind(this);

        this.state = {}
    }
    openCreateParcoursDialog() {
        if (this.props.user !== null) {
            if (!Utility.hasWriteLock(this.props.user, false)) {
                this.refParcoursCreateModal.current.open();
            }
        } else {
            UserActions.showLoginModal();
        }
    }
    handleGroupPlaceChange(mainGroupPlaceId, regionGroupPlaceId) {
        const params = this.props.searchParams;
        params.mainGroupPlaceID = mainGroupPlaceId;
        params.regionGroupPlaceID = regionGroupPlaceId;
        params.coords = null;
        params.withinKM = 0;
        parcoursActions.search(params);
    }
    handleFilterChange(displayType) {
        if (this.props.displayType != displayType) {
            layoutActions.displayTypeChanged(displayType);
        }
    }
    handleSearchTextFoundElement(element) {
        // forward to selected parcours
        if (element.className === 'parcours') {
            window.location = linkUtil.getLink("parcours/" + element.getOnlineID());
        } else {
            window.location = linkUtil.getExternalLink(element.getWeblink());
        }

    }
    handleSearchTextChange(searchText) {
        analyticsActions.trackParcoursSearch(searchText);
        const params = this.props.searchParams;
        params.searchText = searchText;
        parcoursActions.searchParcoursList(params);
    }
    handleCoordsSearch(lat, long, withinKM) {
        const params = this.props.searchParams;
        params.coords = {
            latitude: Math.round(lat * 1000000) / 1000000,
            longitude: Math.round(long * 1000000) / 1000000
        };
        params.withinKM = withinKM;
        params.mainGroupPlaceID = null;
        params.regionGroupPlaceID = null;
        parcoursActions.search(params);
        groupPlaceActions.reset();
    }
    render() {
        const {displayType, searchParams, allParcoursList} = this.props;
        const hasGeo = "geolocation" in navigator;
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <h4><strong><I18n code="parcours.button.search"/></strong></h4>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={12}>
                        <SelectInputTypeAhead
                            defaultValue={searchParams.searchText}
                            objectList={allParcoursList}
                            handleSelectedObject={this.handleSearchTextFoundElement}
                            handleNoObjectFound={this.handleSearchTextChange}
                            placeholder={messages.get("parcours.button.search.placeholder")}/>
                    </Col>
                    <Col xsHidden  sm={6} md={6} lgHidden>
                        <Button block bsStyle="primary" onClick={this.openCreateParcoursDialog}>
                            <FontAwesome icon="plus"/><I18n code="parcours.button.create"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} smHidden mdHidden lgHidden>
                        <Panel id="collapsible-panel-filter">
                            <Panel.Heading>
                                <Panel.Title toggle>
                                    <FontAwesome icon="sort"/>
                                    <I18n code="parcours.filter.header"/>
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Collapse>
                                <Panel.Body>
                                    <Row>
                                        <Col xs={6}>
                                            <h4><I18n code="filter.header.display"/></h4>
                                            <ButtonGroup>
                                                <Button onClick={() => this.handleFilterChange("LIST")}
                                                        className={displayType === "LIST" ? "active" : ""}>
                                                    <FontAwesome icon="list"/><I18n code="filter.button.list"/>
                                                </Button>
                                                <Button onClick={() => this.handleFilterChange("MAP")}
                                                        className={displayType === "MAP" ? "active" : ""}>
                                                    <FontAwesome icon="globe"/><I18n code="filter.button.map"/>
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                        {
                                            hasGeo === true ?
                                                <Col xs={6}>
                                                    <UserCoords handleCoords={this.handleCoordsSearch} searchParams={searchParams}/>
                                                </Col> : null
                                        }
                                        <Col xs={12}>
                                            <h4><I18n code="tournament.filter.region"/></h4>
                                            <GroupPlaces showParcoursAmount={true} handleChange={this.handleGroupPlaceChange}/>
                                            <br/>
                                        </Col>
                                        <Col xs={12}>
                                            <Button block bsSize="large" bsStyle="primary" onClick={this.openCreateParcoursDialog}>
                                                <FontAwesome icon="plus"/><I18n code="parcours.button.create"/>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Panel.Body>
                            </Panel.Collapse>
                        </Panel>
                    </Col>
                </Row>
                <Row>
                    <Col xsHidden sm={3} md={3} lg={12}>
                        <h4><I18n code="filter.header.display"/></h4>
                        <ButtonGroup>
                            <Button onClick={() => this.handleFilterChange("LIST")}
                                    className={displayType === "LIST" ? "active" : ""}>
                                <FontAwesome icon="list"/><I18n code="filter.button.list"/>
                            </Button>
                            <Button onClick={() => this.handleFilterChange("MAP")}
                                    className={displayType === "MAP" ? "active" : ""}>
                                <FontAwesome icon="globe"/><I18n code="filter.button.map"/>
                            </Button>
                        </ButtonGroup>
                    </Col>
                    {
                        hasGeo === true ?
                            <Col xsHidden sm={3} md={3} lg={12}>
                                <UserCoords handleCoords={this.handleCoordsSearch} searchParams={searchParams}/>
                            </Col> : null
                    }
                    <Col xsHidden sm={6} md={6} lg={12}>
                        <h4><I18n code="tournament.filter.region"/></h4>
                        <GroupPlaces showParcoursAmount={true} handleChange={this.handleGroupPlaceChange}/>
                        <br/>
                    </Col>
                    <Col xsHidden smHidden mdHidden lg={12}>
                        <Button block bsSize="large" bsStyle="primary" onClick={this.openCreateParcoursDialog}>
                            <FontAwesome icon="plus"/><I18n code="parcours.button.create"/>
                        </Button>
                        <br/>
                    </Col>
                    <ParcoursCreateModal ref={this.refParcoursCreateModal}/>
                </Row>
            </div>)
    }
}
ParcoursFilter.propTypes = {
    displayType: PropTypes.string.isRequired,
    searchParams: PropTypes.object.isRequired,
    user: PropTypes.object,
    allParcoursList: PropTypes.array
};
const UserCoords = ({handleCoords, searchParams}) =>  {
    const handle = (withinKM) => {
        navigator.geolocation.getCurrentPosition(
            position => {
                if (position != null) {
                    handleCoords(position.coords.latitude, position.coords.longitude, withinKM)
                } else {
                    notificationActions.warning("parcours.search.noPosition");
                }
            }, error=> {
                console.log("GEO ERROR: " + error.message);
                if (error.code === 1) {
                    notificationActions.warning("parcours.search.noHttps");
                } else if (error.code === 3) {
                    notificationActions.warning("parcours.search.noPosition");
                } else {
                    notificationActions.warning('ERROR(' + error.code + '): ' + error.message);
                }
            }, {timeout:5000}

        )
    };
    const isEnabled = () => {
        return "geolocation" in navigator;
    };
    if (!isEnabled()) {
        return <Alert bsStyle="warning"><I18n code="not.available.geolocation"/></Alert>;
    }
    return (
        <React.Fragment>
            <h4>{messages.get("parcours.search.nearby.label")}</h4>
            <ButtonGroup>
                <Button bsStyle={searchParams.withinKM == 25 ? "success" : "default"} onClick={() => {handle(25)}}>25 km</Button>
                <Button bsStyle={searchParams.withinKM == 50 ? "success" : "default"} onClick={() => {handle(50)}}>50 km</Button>
            </ButtonGroup>
        </React.Fragment>
    )
};
const ParcoursList = ({list, listRenderAmount, ownPosition}) =>  {
    const addRenderAmount = 50;
    const handleMore = () => {
        parcoursActions.updateListRenderAmount(listRenderAmount + addRenderAmount)
    };
    let userLoc = null;
    let index = 0;
    if (ownPosition!= null) {
        userLoc =  new Parse.GeoPoint({latitude: ownPosition.latitude, longitude: ownPosition.longitude});
    }
    if (list == null) {
        return (<Loading/>)
    } else if (list.length == 0) {
        return (<Alert bsStyle="warning"><I18n code="parcours.result.empty"/></Alert>)
    }
    return (
        <React.Fragment>
            {list.map(item => {
                if (index < listRenderAmount) {
                    index++;
                    if (item.className === 'parcours') {
                        return <ParcoursItem key={item.id || item.objectId} parcours={item} userLoc={userLoc}/>
                    } else {
                        return <PoIItem key={item.id || item.objectId} poi={item} userLoc={userLoc}/>
                    }
                }
            })}
            {
                list.length > listRenderAmount ?
                    <Button onClick={handleMore}>{addRenderAmount}&nbsp;<I18n code="tournament.button.show.more"/></Button> : null
            }
        </React.Fragment>
    )
};
module.exports = ParcoursPage;
