const React = require("react"),
    PropTypes = require('prop-types');

const TextBlur = ({blur, text, children}) => {
    if (!blur) {
        return <span>{text}{children}</span>
    }
    let blurred = "";
    if (text != null) {
        blurred = new Array(("" + text).length + 1).join( '0' );
    }
    return <span className="textBlur">{blurred}
        {children}
    </span>;
};
TextBlur.propTypes = {
    blur: PropTypes.bool.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};
module.exports = TextBlur;
