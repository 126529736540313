const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Table} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    Title = require("components/widgets/Title");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

module.exports = () => {
    return (
        <div>
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/impressum" code="footer.impressum" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Title code="impressum.title"/>
                <Row>
                    <Col lg={6}>
                        <h3>Bowsport Software Solutions GmbH</h3>

                        <p><FontAwesome icon="home"/>Anschrift:</p>

                        <address>
                            <strong>Bowsport Software Solutions GmbH</strong><br/>
                            Plattnerstraße 8<br/>
                            A-4400 Steyr<br/>
                        </address>

                        <p>Geschäftsführung: Bernhard Haba, Daniel Peterseil</p>

                        <p>
                            <span><FontAwesome icon="envelope"/>E-Mail: <a href="mailto:office@3dturnier.com">office@3dturnier.com</a></span><br/>
                            <span><FontAwesome icon="globe"/>Web: <a href="https://www.3dturnier.com">www.3dturnier.com</a></span>
                        </p>

                        <Table>
                            <tr>
                                <td>UID</td>
                                <th>ATU 70303307</th>
                            </tr>
                            <tr>
                                <td>Firmenbuchnummer</td>
                                <th>Fn445525i</th>
                            </tr>
                            <tr>
                                <td>Bank</td>
                                <th>Sparkasse</th>
                            </tr>
                            <tr>
                                <td>IBAN</td>
                                <th>AT072032032100345375</th>
                            </tr>
                            <tr>
                                <td>BIC</td>
                                <th>ASPKAT2LXXX</th>
                            </tr>
                        </Table>

                        <small>
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                            dem österreichischem Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und
                            jede
                            Art
                            der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                            Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
                            nur für
                            den
                            privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
                            nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Sollten
                            Sie
                            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                            Inhalte
                            umgehend
                            entfernen.
                        </small>
                    </Col>
                </Row>
            </Grid>
        </div>)
}
