const fileDownload  = require('js-file-download');
const FileDownloadUtility = {
    download: function (data, filename) {
        fileDownload(data, filename);
    },
    downloadCSV: function (data, filename) {
        let universalBOM = "\uFEFF";
        fileDownload(universalBOM+data, filename);
    },
    downloadPDFfromUrl: function (url, filename) {
        let httpRequest = new XMLHttpRequest();
        httpRequest.open("GET", url);
        httpRequest.responseType = "arraybuffer";
        httpRequest.onload = function () {
            if (this.status === 200) {
                let blob = new Blob([httpRequest.response], {type: "application/pdf"});
                fileDownload(blob, filename);
            }
        };
        httpRequest.send();
    }
};

module.exports = FileDownloadUtility;
