const React = require("react"),
    PropTypes = require('prop-types');
const {ButtonToolbar, DropdownButton, MenuItem} = require("react-bootstrap");
const groupPlaceStore = require("stores/GroupPlaceStore"),
    groupPlaceActions = require("actions/GroupPlaceActions"),
    messages = require("i18n/messages"),
    I18n = require("components/widgets/I18n");


class GroupPlaces extends React.Component {
    constructor(props) {
        super(props);
        this.handleStoreUpdate = this.handleStoreUpdate.bind(this);
        this.handleMainGroupPlace = this.handleMainGroupPlace.bind(this);
        this.handleRegionGroupPlace = this.handleRegionGroupPlace.bind(this);
        this.getGroupPlaceEntryString = this.getGroupPlaceEntryString.bind(this);
        this.state = {
            groupPlaceStore: groupPlaceStore.getState()
        };
    }
    componentDidMount() {
        groupPlaceStore.listen(this.handleStoreUpdate);
        if (this.state.groupPlaceStore.mainGroupPlace == null) {
            groupPlaceActions.initMainGroupPlaces.defer(this.props.mainGroupPlace, this.props.regionGroupPlace);
        }
    }
    componentWillUnmount() {
        groupPlaceStore.unlisten(this.handleStoreUpdate);
    }
    handleStoreUpdate(state) {
        this.setState({
            groupPlaceStore: state
        });
    }
    handleMainGroupPlace(mainGroupPlaceId) {
        groupPlaceActions.selectMainGroupPlace(mainGroupPlaceId);
        this.props.handleChange(mainGroupPlaceId, null);
    }
    handleRegionGroupPlace(regionGroupPlaceId) {
        groupPlaceActions.selectRegionGroupPlace(regionGroupPlaceId);
        let mainGroupPlace = this.state.groupPlaceStore.mainGroupPlace;
        this.props.handleChange(
            mainGroupPlace ? mainGroupPlace.id : null,
            regionGroupPlaceId);
    }
    getGroupPlaceEntryString(groupPlace) {
        if (this.props.showParcoursAmount) {
            return groupPlace.getName() + " (" + groupPlace.getParcoursAmount() + ")";
        } else {
            return groupPlace.getName();
        }
    }
    render() {
        const {mainGroupPlaceList, regionGroupPlaceList, mainGroupPlace, regionGroupPlace} = this.state.groupPlaceStore;
        var bsStyleProp = {};
        if (this.props.bsStyle && this.props.bsStyle.length > 0) {
            bsStyleProp = {bsStyle: this.props.bsStyle};
        }
        return (
            <ButtonToolbar>
                <DropdownButton title={mainGroupPlace ? mainGroupPlace.getName() :  messages.get("group.place.main.title")}
                                id="tournament-filter-country" {...bsStyleProp}>
                    <MenuItem eventKey={null}
                              onSelect={this.handleMainGroupPlace}>
                        <I18n code="group.place.main.all"/>
                    </MenuItem>
                    {mainGroupPlaceList.map(mainGroupPlace => {
                        return (
                            <MenuItem eventKey={mainGroupPlace.id}
                                      onSelect={this.handleMainGroupPlace}
                                      key={mainGroupPlace.id}>
                                {this.getGroupPlaceEntryString(mainGroupPlace)}
                            </MenuItem>)
                    })}
                </DropdownButton>
                <DropdownButton title={regionGroupPlace ? regionGroupPlace.getName() :  messages.get("group.place.region.title")}
                                id="tournament-filter-region" {...bsStyleProp}>
                    <MenuItem eventKey={null}
                              onSelect={this.handleRegionGroupPlace}>
                        <I18n code="group.place.main.all"/>
                    </MenuItem>
                    {regionGroupPlaceList.map(regionGroupPlace => {
                        return (
                            <MenuItem eventKey={regionGroupPlace.id}
                                      onSelect={this.handleRegionGroupPlace}
                                      key={regionGroupPlace.id}>
                                {this.getGroupPlaceEntryString(regionGroupPlace)}
                            </MenuItem>)
                    })}

                </DropdownButton>
            </ButtonToolbar>
        )
    }
}
GroupPlaces.propTypes = {
    handleChange:PropTypes.func.isRequired,
    bsStyle:PropTypes.string,
    mainGroupPlace: PropTypes.object,
    regionGroupPlace: PropTypes.object
};
module.exports = GroupPlaces;
