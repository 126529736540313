const Parse = require("ParseInit");
const {Voucher} = require("parse/_Domain");

class VoucherDao {

    queryVouchers(status) {
        const query = new Parse.Query(Voucher);
        query.equalTo(Voucher.prototype.col.status, status);
        query.include(Voucher.prototype.col.redeemUser);
        query.include(Voucher.prototype.col.managementID);
        query.descending(Voucher.prototype.col.updatedAt);
        query.limit(1000);
        return query.find();
    }

    createVoucher(articleID, email) {
        return Parse.Cloud.run("createVoucher",{articleID: articleID, email: email});
    }

    createVoucherBundle(articleID, amount) {
        return Parse.Cloud.run("createVoucherBundle",{articleID: articleID, amount: amount});
    }

    redeemVoucher(voucherID) {
        return Parse.Cloud.run("redeemVoucher",{voucherID: voucherID});
    }

    activateVoucher(voucherID, managementID) {
        return Parse.Cloud.run("activateVoucher",{voucherID: voucherID, managementID: managementID});
    }
}

module.exports = new VoucherDao();
