const Parse = require("ParseInit");

const Parcours = Parse.Object.extend("parcours", {
    col: {
        objectId: "objectId",
        ACL: "ACL",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        coordinates: "coordinates",
        parcoursOwnerID: "parcoursOwnerID",
        groupPlaceID: "groupPlaceID",
        mainGroupPlaceID: "mainGroupPlaceID",
        articles: "articles",
        category: "category",
        constraints: "constraints",
        dayprice: "dayprice",
        description: "description",
        editor: "editor",
        name: "name",
        onlineID: "onlineID",
        paroucsplan: "paroucsplan",
        ratingAv: "ratingAv",
        ratingCo: "ratingCo",
        sortIndex: "sortIndex",
        specialprice: "specialprice",
        status: "status",
        targetAmounts: "targetAmounts",
        type: "type",
        payStatus: "payStatus",
        visitStatus: "visitStatus",
        rankStatus: "rankStatus",
        targetStatus: "targetStatus"
    },
    status: {
        active: "A",
        deleted: "D"
    },
    payStatus: {
        enabled: "A",
        disabled: "I",
        premiumOnly: "P",
        editorOnly: "E" // only parcours editor is allowed to change targets
    },
    visitStatus: {
        enabled: "A",
        disabled: "I",
        enablePayment: "P",
        enabledAddress: "F"
    },
    type: {
        parcours: "P",
        target: "T",
        field: "F",
        cinema4D: "C"
    },
    //
    // custom
    //
    getDatalistText() {
        return this.buildNames() + ", " +  this.getZipPlace();
    },
    getDatalistImage() {
        return null;
    },
    getSelectString() {
        return this.buildNames();
    },
    getLabels() {
        return [this.buildNames(), this.getZipPlace()].join(" ");
    },
    buildNames() {
        let name = this.getName();
        if (name && name.length > 0) {
            name += " - ";
        } else {
            name = "";
        }
        if (this.getParcoursOwnerID()) {
            name += this.getParcoursOwnerID().getName();
        }
        return name;
    },
    getZipPlace() {
        let tPlace = "";
        const owner = this.getParcoursOwnerID();
        if (owner) {
            if (owner.getZip()) {
                tPlace = owner.getZip() + " ";
            }
            if (owner.getPlace()) {
                tPlace += owner.getPlace() + " ";
            }
        }
        return tPlace;
    },
    isFeatureStatus(col, status) {
        if (this.payStatus.disabled == status) {
            return this.get(col) != this.payStatus.enabled
        } else {
            return this.get(col) == status;
        }
    },
    getLastUpdatedAt() {
        const owner = this.getParcoursOwnerID();
        if (owner && owner.getUpdatedAt() > this.getUpdatedAt()) {
            return owner.getUpdatedAt();
        }
        return this.getUpdatedAt();
    },
    hasPaymentEnabled() {
        return !this.isFeatureStatus(this.col.payStatus, this.payStatus.disabled);
    },
    hasRankingEnabled() {
        return !this.isFeatureStatus(this.col.rankStatus, this.payStatus.disabled);
    },
    hasVisitEnabled() {
        let status = this.getVisitStatus();
        return status === this.visitStatus.enabled || status === this.visitStatus.enabledAddress || status === this.visitStatus.enablePayment
    },
    hasVisitAddressEnabled() {
        return this.visitStatus.enabledAddress ===  this.getVisitStatus();
    },
    hasVisitPaymentAmounts() {
        return this.visitStatus.enablePayment ===  this.getVisitStatus() || this.hasVisitAddressEnabled();
    },
    hasTargetEnabled(hasPremiumTarget) {
        if (this.isFeatureStatus(this.col.targetStatus, this.payStatus.enabled) || this.isFeatureStatus(this.col.targetStatus, this.payStatus.editorOnly)) {
            return true;
        } else if (this.isFeatureStatus(this.col.targetStatus, this.payStatus.premiumOnly) && hasPremiumTarget === true) {
            return true;
        } else {
            return false;
        }
    },
    hasTargetEditorOnly() {
        return this.isFeatureStatus(this.col.targetStatus, this.payStatus.editorOnly)
    },
    isType(typeConst) {
        return typeConst === this.getType();
    },
    getNonNullPayStatus() {
        let status = this.getPayStatus();
        if (status != this.payStatus.enabled) {
            status = this.payStatus.disabled
        }
        return status;
    },
    getNonNullVisitStatus() {
        let status = this.getVisitStatus();
        if (status == null) {
            status = this.visitStatus.disabled
        }
        return status;
    },
    hasArticles() {
        if (this.get("articles")) {
            return true;
        } else if (this.get("parcoursOwnerID").get("articles")) {
            return true;
        }
        return false;
    },
    isParcoursManager(currUserId) {
        let result = false;
        const owner = this.getParcoursOwnerID();
        if (owner) {
            const management = owner.get('managementID');
            if (management) {
                if (management.get('mainEditor') && currUserId == management.get('mainEditor').id) {
                    result = true;
                } else {
                    const addEditors = management.get('addEditor');
                    if (addEditors) {
                        addEditors.forEach(function (editor) {
                            if (currUserId == editor.id) {
                                result = true;
                            }
                        });

                    }
                }
            }
        }
        return result;
    },
    hasParcoursManager() {
        const owner = this.getParcoursOwnerID();
        if (owner) {
            return owner.hasManagementID();
        }
        return false;
    },
    isConstraintClosed() {
        return this.hasConstraint("type_closed");
    },
    isConstraintPrivate() {
        return this.hasConstraint("type_private");
    },
    isConstraintOneHitOnly() {
        return this.hasConstraint("type_oneHitOnly_PP");
    },
    hasConstraint(typeConst) {
        const constraints = this.getConstraints();
        if (constraints) {
            // check closed flag
            for (let i = 0; i < constraints.length; i++) {
                if (typeConst == constraints[i]) {
                    return true;
                }
            }
            return false;
        }
    },
    getOwnerCoordinates() {
        const owner = this.getParcoursOwnerID();
        if (owner) {
            return owner.getCoordinates();
        }
        return null;
    },
    getCoordinates() {
        return this.get(this.col.coordinates)
    },
    setCoordinates(coordinates) {
        return this.set(this.col.coordinates, coordinates)
    },
    //
    // getter / setter
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getArticles() {
        return this.get(this.col.articles)
    },
    setArticles(articles) {
        return this.set(this.col.articles, articles)
    },

    getCategory() {
        return this.get(this.col.category)
    },
    setCategory(category) {
        return this.set(this.col.category, category)
    },
    getConstraints() {
        return this.get(this.col.constraints)
    },
    setConstraints(constraints) {
        return this.set(this.col.constraints, constraints)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getDayprice() {
        return this.get(this.col.dayprice)
    },
    setDayprice(dayprice) {
        return this.set(this.col.dayprice, dayprice)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getEditor() {
        return this.get(this.col.editor)
    },
    setEditor(editor) {
        return this.set(this.col.editor, editor)
    },
    getGroupPlaceID() {
        return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID(groupPlaceID) {
        return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getMainGroupPlaceID() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getOnlineID() {
        return this.get(this.col.onlineID)
    },
    setOnlineID(onlineID) {
        return this.set(this.col.onlineID, onlineID)
    },
    getParcoursOwnerID() {
        return this.get(this.col.parcoursOwnerID)
    },
    setParcoursOwnerID(parcoursOwnerID) {
        return this.set(this.col.parcoursOwnerID, parcoursOwnerID)
    },
    getParcoursplan() {
        return this.get(this.col.paroucsplan)
    },
    getPayStatus() {
        return this.get(this.col.payStatus)
    },
    getVisitStatus() {
        return this.get(this.col.visitStatus)
    },
    getRankingStatus() {
        return this.get(this.col.rankStatus)
    },
    setRankingStatus(status) {
        return this.set(this.col.rankStatus, status)
    },
    getTargetStatus() {
        return this.get(this.col.targetStatus)
    },
    setPayStatus(payStatus) {
        return this.set(this.col.payStatus, payStatus)
    },
    setParoucsplan(paroucsplan) {
        return this.set(this.col.paroucsplan, paroucsplan)
    },
    getRatingAv() {
        return this.get(this.col.ratingAv)
    },
    setRatingAv(ratingAv) {
        return this.set(this.col.ratingAv, ratingAv)
    },
    getRatingCo() {
        return this.get(this.col.ratingCo)
    },
    setRatingCo(ratingCo) {
        return this.set(this.col.ratingCo, ratingCo)
    },
    getSortIndex() {
        return this.get(this.col.sortIndex)
    },
    setSortIndex(sortIndex) {
        return this.set(this.col.sortIndex, sortIndex)
    },
    getSpecialprice() {
        return this.get(this.col.specialprice)
    },
    setSpecialprice(specialprice) {
        return this.set(this.col.specialprice, specialprice)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getType() {
        return this.get(this.col.type)
    },
    setType(type) {
        return this.set(this.col.type, type)
    },
    getTargetAmounts() {
        return this.get(this.col.targetAmounts)
    },
    setTargetAmounts(targetAmounts) {
        return this.set(this.col.targetAmounts, targetAmounts)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    }
});

module.exports = Parcours;
