const React = require("react");
const {BaseContext} = require("context/BaseContext");
const {User} = require("parse/_Domain");
const UserFeatures = require("beans/UserFeatures");

const notificationActions = require("components/notification/NotificationActions");
const userDao = require("actdao/UserDao");
const userActions = require("actions/UserActions");

const BaseContextProvider = ({children}) =>  {
    const [user, setUser] = React.useState(null);
    const [userDetail, setUserDetail] = React.useState(null);
    const [userAbos, setUserAbos] = React.useState(null);
    const [userFeatures, setUserFeatures] = React.useState(new UserFeatures());
    // const [userIsAdmin, setUserAdmin] = React.useState(null);

    function updateUser(user) {
        // console.log("Provider.updateUser", user)
        setUser(user)
        fetchUserInfos(user).then()
        // TODO handle Abo pause
        // Todo handle change any of user or userDetail
        userActions.updateUser(user);
        // TODO remove legacy user by dropping userStore
    }
    async function fetchUserInfos(user) {
        let fUserAbos = null;
        let fUserDetail = null;
        if (user && user.get(User.prototype.col.userAbos)) {
            try {
                fUserAbos = await userDao.fetchUserAbos(user)
            } catch (error) {
                error.trace = "USRA.fetchUserAbo";
                notificationActions.parseError(error);
            }
        }
        if (user && user.get(User.prototype.col.userDetailID)) {
            try {
                fUserDetail = await userDao.fetchUserDetail(user)
            } catch (error) {
                error.trace = "USRA.fetchUserDetail";
                notificationActions.parseError(error);
            }

        }
        // console.log("fetchUserInfos", fUserAbos, fUserDetail)
        setUserAbos(fUserAbos);
        setUserDetail(fUserDetail);
        userActions.updateUserInfos(fUserAbos, fUserDetail);
        let nFeat = new UserFeatures();
        nFeat.setUserFeatures(fUserAbos);
        setUserFeatures(nFeat)
    }
    return <BaseContext.Provider value={{ user, userDetail, userAbos, userFeatures, updateUser }}>
        {children}
    </BaseContext.Provider>
};
module.exports = BaseContextProvider;