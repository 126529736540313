const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");

const LinkButton = ({block, bsStyle, bsSize, style, disabled, href, symbol, label}) => {
        return <Button block={block}
                       style={style}
                       disabled={disabled}
                       bsStyle={bsStyle ? bsStyle : "primary"}
                       bsSize={bsSize}
                       href={href}>
            {symbol}<I18n code={label}/>
        </Button>
};
LinkButton.propTypes = {
    href:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired,
    symbol:PropTypes.object,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
    bsSize:PropTypes.string,
    style:PropTypes.object
};
module.exports = LinkButton;