var alt = require("AltInit");

var action = require("actions/PointInterestActions");
var PLUtil = require("util/ParseListUtility");

class PointInterestStore {
    constructor() {
        this.displayName = "PointInterestStore";
        this.poiList = null;
        this.editPoi = null;
        this.bindListeners({
            updatePoiList: action.updatePoiList,
            updateEditPoi: action.updateEditPoi,
            updatePoi: action.updatePoi
        });
    }

    updatePoiList(poiList) {
        this.editPoi= null;
        this.poiList= poiList;
    };
    updatePoi(poi) {
        this.updatePoiList(PLUtil.updateOrPush(this.poiList, poi));
    };
    updateEditPoi(poi) {
        this.editPoi= poi;
    };
}

module.exports = alt.createStore(PointInterestStore);
