const React = require("react"),
    PropTypes = require('prop-types');

const {Multiselect} = require ('multiselect-react-dropdown');
const TrainingFilterEntity = require("components/training/TrainingFilterEntity");

const TrainingFilterDropDown = ({filterState, objectList, stateKey, text, disabled, needReloadEvents, eventTrainingList, updateSearchParams}) => {
    function onSelect(selectedList, selectedItem) {
        updateSearchParams(stateKey, selectedList)
    }
    function onRemove(selectedList, removedItem) {
        updateSearchParams(stateKey, selectedList)
    }
    function selectionList() {
        return eventTrainingList != null ? eventTrainingList.getFilterList(stateKey, objectList) : []
    }
    return <TrainingFilterEntity filterState={filterState} stateKey={stateKey} text={text} disabled={disabled}
                                 needReloadEvents={needReloadEvents} updateSearchParams={updateSearchParams}>
        <Multiselect
            disabled={disabled}
            options={filterState != null ? selectionList() : []} // Options to display in the dropdown
            selectedValues={filterState} // Preselected value to persist in dropdown
            onSelect={onSelect} // Function will trigger on select event
            onRemove={onRemove} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            hidePlaceholder={true}
            placeholder={"Select " + text}
        />
    </TrainingFilterEntity>
}
TrainingFilterDropDown.propTypes = {
    filterState: PropTypes.array,
    stateKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    needReloadEvents: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
}
module.exports = TrainingFilterDropDown;
