const React = require("react"),
    PropTypes = require('prop-types');
const {Col, Well} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    SubmitButton = require("components/form/SubmitButton");


const eventDetailActions = require("actions/EventDetailActions");

const messages = require("i18n/messages");

class EventDurationChange extends React.Component {
    constructor(props) {
        super(props);
        this.refDuration = React.createRef();
        this.saveEvent = this.saveEvent.bind(this);
    }
    saveEvent() {
        if (this.refDuration.current != null && this.refDuration.current.getValue() != null) {
            let duration = parseInt(this.refDuration.current.getValue());
            if (duration > 0) {
                this.props.event.setDuration(duration);
                eventDetailActions.saveEvent(this.props.event);
            }
        }
    }

    render() {
        return <Col xs={12} sm={6}>
            <Well>
                <ValidInput ref={this.refDuration}
                            placeholder={messages.get("profile.event.duration.placeholder")}
                            label={messages.get("profile.event.duration.label")}
                            buttonAfter={<SubmitButton bsStyle="primary" onClick={this.saveEvent}
                                                       icon={<FontAwesome icon="save"/>}/>}
                            valid={{maxLength: 4, check:['isRequired, isNumber']}}/>
            </Well>
        </Col>
    }
}
EventDurationChange.propTypes = {
    event:PropTypes.object.isRequired,
};
module.exports = EventDurationChange;