var Parse = require("ParseInit");

var ParcoursStat = Parse.Object.extend("parcoursstat", {
    col: {
        objectId: "objectId",
        ACL: "ACL",
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        parcoursOnlineID: "onlineID",
        pointInterestID: "pointInterestID",
        deviceType: "deviceType",
        action: "action",
        number1: "number1",
        number2: "number2",
    },
        actions: {
        clicked: "C",
        edit: "E",
        pay: "P",
        finishEvent: "F"
    },
    type: {
        android: "ANDR",
        iOS: "iOS",
        web: "WEB"
    },
  //
  // custom
  //
    setPOIActionClick: function(interestID) {
        this.set(this.col.pointInterestID, interestID);
        this.set(this.col.deviceType, this.type.web);
        this.set(this.col.action, this.actions.clicked);
    },
    setParcoursActionClick: function(parcoursOnlineID, edit) {
        this.set(this.col.parcoursOnlineID, parcoursOnlineID);
        this.set(this.col.deviceType, this.type.web);
        if (edit) {
            this.set(this.col.action, this.actions.edit);
        } else {
            this.set(this.col.action, this.actions.clicked);
        }
    },
    setParcoursActionPayment: function(parcoursOnlineID) {
        this.set(this.col.parcoursOnlineID, parcoursOnlineID);
        this.set(this.col.deviceType, this.type.web);
        this.set(this.col.action, this.actions.pay);
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getDeviceType: function () {
        return this.get(this.col.deviceType)
    },
    isActionClicked: function (actionType) {
      return this.getAction() === actionType;
    },
    getAction: function() {
        return this.get(this.col.action)
    },
    getNumber1: function () {
        return this.get(this.col.number1)
    },
    getNumber2: function () {
        return this.get(this.col.number2)
    }
});

module.exports = ParcoursStat;
