const React = require("react"),
    PropTypes = require('prop-types');
const {Table} = require("react-bootstrap");

const I18n = require("components/widgets/I18n");

const ClubInvoiceArticleTable = ({articlesList, currency, discount, sum}) => {
    return (<Table>
        <thead>
        <tr>
            <th><I18n code="tournament.table.column.article"/></th>
            <th><I18n code="parcours.articles.form.price"/></th>
            <th><I18n code="tournament.table.column.amount"/></th>
            <th><I18n code="tournament.table.column.sum"/></th>
        </tr>
        </thead>
        <tbody>
            {
                articlesList.map(article => {
                    return <TransactionPositionRow key={article.id} position={article} currency={currency}/>
                })
            }
            {
                discount != null ?

                    <React.Fragment>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><I18n code="tournament.table.column.article.sum"/></td>
                            <td>{currency + " " + (sum + discount * -1).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><I18n code="tournament.table.column.discount"/></td>
                            <td>{currency + " " + discount.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td><I18n code="tournament.table.column.sum"/></td>
                            <td>{currency + " " + sum.toFixed(2)}</td>
                        </tr>
                    </React.Fragment>
                    :
                    <tr>
                        <td></td>
                        <td></td>
                        <td><I18n code="tournament.table.column.sum"/></td>
                        <td>{currency + " " + sum.toFixed(2)}</td>
                    </tr>
            }

        </tbody>
    </Table>)
};
ClubInvoiceArticleTable.propTypes = {
    articlesList:PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
    discount: PropTypes.number,
    sum: PropTypes.number
};
const TransactionPositionRow = ({position, currency})=> {
    return (
        <tr>
            <td>{position.getName()}</td>
            <td>{position.getSinglePrice()}</td>
            <td>{position.getAmount()}</td>
            <td>{position.getFormattedSum(currency)}</td>
        </tr>
    )
};
TransactionPositionRow.propTypes = {
    position:PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired
};
module.exports = ClubInvoiceArticleTable;
