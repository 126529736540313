const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentDashboardButton = require("components/tournamentmanage/TournamentDashboardButton"),
    TournamentLink = require("components/tournament/TournamentLink");

const userStore = require("stores/UserStore"),
    eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");


class TabTournament extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);
        this.state = {
            userStore: userStore.getState(),
            eventStore: eventStore.getState(),
        };
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        eventStore.listen(this.handleEventChange);
        notificationActions.reset();
        eventActions.reset();
        eventActions.queryTournaments(this.state.userStore.user, this.props.year);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        eventStore.unlisten(this.handleEventChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        const {loading, waitingTournament, finishedTournament, editorTournament} = this.state.eventStore;
        return (
            <Row>
                {
                    loading === true ?
                        <Col xs={12}><Loading/></Col>
                        :
                        <Col xs={12}>
                            {editorTournament == null || editorTournament.length <= 0 ? null : <TournamentEditorTable  tournaments={editorTournament}/>}
                            {waitingTournament == null || waitingTournament.length <= 0 ? null : <TournamentWaitingTable tournamentUsers={waitingTournament}/>}
                            {finishedTournament == null || finishedTournament.length <= 0 ? null : <TournamentFinishedTable tournamentUsers={finishedTournament}/>}
                        </Col>
                }
            </Row>
        )
    }
}
TabTournament.propTypes = {
    year: PropTypes.number.isRequired
};
const TournamentWaitingTable = ({tournamentUsers}) => {
    return <React.Fragment>
        <h3><FontAwesome icon="calendar-alt"/>{messages.get("profile.tournament.waiting.header")}</h3>
        <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.name"/></th>
                <th><I18n code="tournament.class.bow"/></th>
                <th><I18n code="tournament.class.age"/></th>
                <th><I18n code="tournament.table.column.status"/></th>
            </tr>
            </thead>
            <tbody>
                {tournamentUsers.map(tournamentUser => {
                    return (<TournamentWaitingRow key={"TWR_" + tournamentUser.id}
                                                  tournament={tournamentUser.get('tournamentID')}
                                                  tournamentUser={tournamentUser}/>);
                })}
            </tbody>
        </Table>
    </React.Fragment>
};
TournamentWaitingTable.propTypes = {
    tournamentUsers: PropTypes.array.isRequired
};
const TournamentWaitingRow = ({tournament, tournamentUser}) => {
    return (
        <tr>
            <td>{tournament.getTournamentDate()}</td>
            <td><TournamentLink tournament={tournament} label={tournament.getName()}/></td>
            <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            <td>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
            <td>{tournamentUser.isPaid() ? messages.get("tournament.attendee.status.legend.paid") : messages.get("tournament.attendee.status.legend.pending") }</td>
        </tr>)
};
TournamentWaitingRow.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUsers: PropTypes.object.isRequired
};
const TournamentFinishedTable = ({tournamentUsers}) => {
        return <React.Fragment>
                <h3><FontAwesome icon="trophy"/>{messages.get("profile.tournament.finished.header")}</h3>
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th><I18n code="tournament.table.column.date"/></th>
                        <th><I18n code="tournament.table.column.name"/></th>
                        <th><I18n code="tournament.class.bow"/></th>
                        <th><I18n code="tournament.class.age"/></th>
                        <th>Pos</th>
                        <th><I18n code="tournament.table.column.points"/></th>
                        <th>Kills</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tournamentUsers.map(tournamentUser => {
                        return (<TournamentFinishedRow key={"TFR_" + tournamentUser.id}
                                                       tournament={tournamentUser.get('tournamentID')}
                                                       tournamentUser={tournamentUser}/>);
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
};
TournamentFinishedTable.propTypes = {
    tournamentUsers: PropTypes.array.isRequired
};
const TournamentFinishedRow = ({tournament, tournamentUser}) => {
    let resultPosition = tournamentUser.getResultPosition();
    let sumPoints = tournamentUser.getSumPoints();
    let killCounts = tournamentUser.getKillCounts2String();
    if (tournamentUser.getFinalTournamentRoundUserIDs() != null && tournamentUser.getFinalTournamentRoundUserIDs().length > 0) {
        resultPosition = tournamentUser.getFinalResultPosition();
        sumPoints = tournamentUser.getFinalSumPoints();
        killCounts = tournamentUser.getFinalKillCounts2String();
    }
    return (
        <tr id={tournament.id}>
            <td>{tournament.getTournamentDate()}</td>
            <td><TournamentLink tournament={tournament} label={tournament.getName()}/></td>
            <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            <td>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
            <td>{resultPosition > 0 ? resultPosition : "?"}</td>
            <td>{sumPoints > 0 ? sumPoints : "?"}</td>
            <td>{sumPoints > 0 ? killCounts : "-"}</td>
        </tr>)
};
TournamentFinishedRow.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUsers: PropTypes.object.isRequired
};
const TournamentEditorTable = ({tournaments}) => {
    return <React.Fragment>
        <h3><FontAwesome icon="cog"/>{messages.get("tournament.button.gotoTournamentDashboard")}</h3>
        <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.date"/></th>
                <th><I18n code="tournament.table.column.name"/></th>
                <th><I18n code="tournament.table.column.status"/></th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            {tournaments.map(tournament => {
                return (<tr key={tournament.id}>
                    <td>{tournament.getTournamentDate()}</td>
                    <td><TournamentLink tournament={tournament} label={tournament.getName()}/></td>
                    <td><TournamentStatus tournament={tournament}/></td>
                    <td><TournamentDashboardButton tournament={tournament} /></td>
                </tr>)
            })}
            </tbody>
        </Table>
    </React.Fragment>
};
TournamentEditorTable.propTypes = {
    tournaments: PropTypes.array.isRequired
};
module.exports = TabTournament;
