const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Image, PageHeader} = require("react-bootstrap");
const I18nHtml = require("components/widgets/I18nHtml");

const Title = ({code, children}) => {
    return (<Row>
                <Col xsHidden lg={12}>
                    <PageHeader>
                        <Image rounded src="/img/ico/ic_launcher_green.png"/>&nbsp;
                        <I18nHtml inline code={code}/>{children}
                    </PageHeader>
                </Col>
                <br/>
            </Row>)
};
Title.propTypes = {
    code: PropTypes.string.isRequired
};
module.exports = Title;
