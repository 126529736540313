const React = require("react"),
    PropTypes = require('prop-types');
const {ButtonGroup, Button, Well} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const languageActions = require("actions/LanguageActions");

const DeviceTypeSwitch = ({label}) => {
    function updateDevice(type) {
        languageActions.updateImageDeviceType(type)
    }
    return <Well>
        {label}
        <ButtonGroup>
            <Button bsStyle="primary" bsSize="large" onClick={() => updateDevice("A")}><FontAwesome icon={["fab","android"]}/>Android App</Button>
            <Button bsStyle="primary" bsSize="large" onClick={() => updateDevice("I")}><FontAwesome icon={["fab","apple"]}/>iOS App</Button>
        </ButtonGroup>
    </Well>
};
DeviceTypeSwitch.propTypes = {
    label: PropTypes.object.isRequired
};
module.exports = DeviceTypeSwitch;
