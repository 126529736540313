const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,  Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    Loading = require("components/widgets/Loading"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SelectInputAsyncTypeAhead = require("components/form/SelectInputAsyncTypeAhead"),
    TournamentConfigAgeCalculator = require("components/tournamentregister/TournamentConfigAgeCalculator"),
    TournamentSexSelect = require("components/tournamentregister/TournamentSexSelect"),
    TournamentConfigAgeSelect = require("components/tournamentregister/TournamentConfigAgeSelect"),
    TournamentConfigBowSelect = require("components/tournamentregister/TournamentConfigBowSelect"),
    TournamentOptionSelect = require("components/tournamentregister/TournamentOptionSelect"),
    TournamentRegSlotSelect = require("components/tournamentregister/TournamentRegSlotSelect"),
    Utility = require("util/Utility"),
    messages = require("i18n/messages");

const TournamentUser = require("parse/TournamentUser"),
    ParseError = require("parse/ParseError");
const notificationActions = require("components/notification/NotificationActions");
const tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");
const ReactDOM = require("react-dom");

class TournamentRegistrationFormCupChild extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refSex = React.createRef();
        this.refNote = React.createRef();
        this.refUnion = React.createRef();
        this.refMail = React.createRef();
        this.refLicense = React.createRef();
        this.setError = this.setError.bind(this);
        this.validRegistration = this.validRegistration.bind(this);
        this.handleSubmitRegistration = this.handleSubmitRegistration.bind(this)
        this.noEmail = this.noEmail.bind(this);
        this.buildTournamentUserInit = this.buildTournamentUserInit.bind(this);
        this.showPaymentCondition = this.showPaymentCondition.bind(this);
        this.getCupTournamentUserDefault = this.getCupTournamentUserDefault.bind(this);
        this.queryCupTournamentUser = this.queryCupTournamentUser.bind(this);
        this.selectedCupTournamentUser = this.selectedCupTournamentUser.bind(this);
        this.renderTUserOptionItems = this.renderTUserOptionItems.bind(this);
        this.handleNoCupNumber = this.handleNoCupNumber.bind(this);
        this.cancelRegistration = this.cancelRegistration.bind(this);
        this.configBowSelected = this.configBowSelected.bind(this);
        this.configAgeSelected = this.configAgeSelected.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.setEmailEdit = this.setEmailEdit.bind(this);
        this.allowChangeRegistrationDetails = this.allowChangeRegistrationDetails.bind(this);
        this.state = {
            submitDisabled: false,
            allowEmailEdit: false,
            error: null,
        }
    }
    setError(message) {
        if (this.refSubmitButton.current != null) {
            this.refSubmitButton.current.resetLoading();
        }
        this.setState({
            submitDisabled: false,
            error: message
        });
    }
    setEmailEdit(edit) {
        this.setState({
            allowEmailEdit: edit
        });
    }
    validRegistration() {
        const {tournamentRegisterModalStore} = this.props;
        let valid = true;
        if (tournamentRegisterModalStore.selectedBowType === null || tournamentRegisterModalStore.selectedAgeType === null) {
            this.setState({error: messages.get("tournament.create.error.required")});
            valid = false;
        }
        if (tournamentRegisterModalStore.tournamentRegSlots != null && tournamentRegisterModalStore.tournamentRegSlots.length > 0) {
            // check if slot was selected
            if (tournamentRegisterModalStore.selectedTRegSlotId == null) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        if (tournamentRegisterModalStore.tOption) {
            if (tournamentRegisterModalStore.selectedOption == null) {
                this.setState({error: messages.get("tournament.create.error.required")});
                return false;
            }
        }
        return valid;
    }
    handleSubmitRegistration(e) {
        e.preventDefault();
        if (!this.validRegistration()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        this.setState({submitDisabled: true});
        // check license number already used
        const licenseNumber = this.refLicense.current.getValue().trim();
        tournamentRegisterModalActions.validateTUserLicense(this.props.tournament, licenseNumber, (lResult) => {
            if (lResult.valid || licenseNumber === "0000") {
                let tuMail = this.getCupTournamentUserDefault(TournamentUser.prototype.col.playerEmail);
                if (tuMail != null) {
                    tuMail = tuMail.trim().toLowerCase()
                }
                if (this.refMail.current != null) {
                    tuMail = this.refMail.current.getValue().trim().toLowerCase();
                }
                // validate email
                tournamentRegisterModalActions.validateTUserEmail(this.props.tournament, tuMail, (mResult) => {
                    if (mResult.valid) {
                        let regTUserObject = this.buildTournamentUserInit(tuMail, licenseNumber);
                        tournamentRegisterModalActions.handleTournamentUserRegistration(this.props.tournament, regTUserObject, error => {
                            this.setError(messages.get(error.message));
                        });
                    } else if (mResult.error) {
                        console.log("mError", mResult.error)
                        this.setError(messages.get("parse.error.unexpected") + ParseError.getTranslatedErrorMessage(mResult.error));
                    } else {
                        this.setError(messages.get("tournament.error.emailInUse") + " " + tuMail + ". " + messages.get("tournament.register.placeHolder.noemail") + this.noEmail());
                        this.setEmailEdit(true)

                    }
                });
            } else if (lResult.error) {
                console.log("lError", lResult.error)
                this.setError(messages.get("parse.error.unexpected") + ParseError.getTranslatedErrorMessage(lResult.error));
            } else {
                this.setError(messages.get("tournament.error.licenseInUse") + " " + licenseNumber + ". ");
            }
        });




    }
    noEmail() {
        return Utility.buildNoEmail(this.refName.current.getValue(), this.refSurname.current.getValue());
    }
    buildTournamentUserInit(tuMail, licenseNumber) {
        console.log("handleTournamentRegistration:" + tuMail);
        let regTUserObject = {};
        regTUserObject.name = this.refName.current.getValue().trim();
        regTUserObject.surname = this.refSurname.current.getValue().trim();
        regTUserObject.tuMail = tuMail;
        regTUserObject.sex = parseInt(ReactDOM.findDOMNode(this.refSex.current).value);
        regTUserObject.tConfigAge = this.props.tournamentRegisterModalStore.selectedAgeType.id;
        regTUserObject.tConfigBow = this.props.tournamentRegisterModalStore.selectedBowType.id;
        if (this.refUnion.current.getValue() && this.refUnion.current.getValue().length > 0) {
            regTUserObject.union = this.refUnion.current.getValue().trim();
        }
        if (this.props.tournamentRegisterModalStore.selectedOption != null) {
            regTUserObject.option = this.props.tournamentRegisterModalStore.selectedOption;
        }
        if (this.props.tournamentRegisterModalStore.selectedTRegSlotId != null) {
            regTUserObject.regSlotId = this.props.tournamentRegisterModalStore.selectedTRegSlotId;
        }
        regTUserObject.licNumber = licenseNumber;
        regTUserObject.licUnionCode = this.props.tournamentRegisterModalStore.bowUnion.getCode();
        if (this.refNote.current.getValue() && this.refNote.current.getValue().length > 0) {
            regTUserObject.note = this.refNote.current.getValue().trim();
        }
        regTUserObject.price = this.props.tournamentRegisterModalStore.price;
        regTUserObject.parcoursID = this.props.tournament.getParcoursID().id;
        if (this.props.tournament.getCupGroup()) {
            regTUserObject.cupGroup = this.props.tournament.getCupGroup();
        }
        regTUserObject.tournamentID = this.props.tournament.id;
        regTUserObject.status = TournamentUser.prototype.status.registered;
        regTUserObject.selfRegistration = this.props.tournamentRegisterModalStore.selfRegistration;
        return regTUserObject;
    }
    showPaymentCondition() {
        const tPrice = this.props.tournament.getTournamentPriceID();
        if (tPrice) {
            return tPrice.getPaymentCondition();
        }
    }
    getCupTournamentUserDefault(key) {
        return this.props.tournamentRegisterModalStore.cupTournamenUser.get(key);
    }
    allowChangeRegistrationDetails() {
        if (this.props.tournamentRegisterModalStore.cupTournamenUser != null) {
            let created = this.props.tournamentRegisterModalStore.cupTournamenUser.getCreatedAt();
            if (created != null) {
                return created.getFullYear() < new Date().getFullYear();
            } else {
                // this is probably no real registration - allow change
                return true;
            }
        }
        return false;
    }
    queryCupTournamentUser(searchTxt, callback) {
        this.setError(null);
        tournamentRegisterModalActions.queryCupTournamentUser(searchTxt, this.props.tournamentRegisterModalStore.bowUnion, callback, error => {
            notificationActions.parseError(error);
            this.setError("Fehler in Abfrage")
        });
    }
    selectedCupTournamentUser(tournamentUser) {
        tournamentRegisterModalActions.updateCupTournamenUser(tournamentUser)
    }
    renderTUserOptionItems(tUser) {
        return tUser.getDatalistTextNoMail();
    }
    handleNoCupNumber() {
        tournamentRegisterModalActions.startNoCupNumber(this.props.tournamentRegisterModalStore.bowUnion);
    }
    cancelRegistration() {
        // reset found cup tournament user
        this.setError(null);
        tournamentRegisterModalActions.updateCupTournamenUser(null);
    }
    configBowSelected(configBowType) {
        tournamentRegisterModalActions.updateSelectedConfigBow(configBowType);
    }
    configAgeSelected(configAgeType) {
        tournamentRegisterModalActions.updateSelectedConfigAge(configAgeType);
    }
    optionSelected(option) {
        tournamentRegisterModalActions.updateSelectedOption(option);
    }
    slotSelected(slotId) {
        tournamentRegisterModalActions.updateSelectedRegSlot(slotId);
    }
    render() {
        const {tournament, tournamentRegisterModalStore} = this.props;
        const {submitDisabled, allowEmailEdit, error} = this.state;
        const validateNow = error && error.length > 0;
        let curr = "E";
        if (tournament != null) {
            const tPrice = tournament.getTournamentPriceID();
            if (tPrice) {
                curr = tPrice.getCurrency();
            }
        }
        if (tournamentRegisterModalStore.cupTournamenUser == null) {
            // search for cupNumber
            return <Row>
                <Col xs={12}>
                    <h3>{messages.get("tournament.register.cupNo.with") + " " + tournamentRegisterModalStore.bowUnion.getName() + " #"}</h3>
                    <p>{messages.get("tournament.register.cupNo.where") + " " + messages.get("tournament.register.cupNo.why")}</p>
                </Col>
                <Col xs={12}>
                    {
                        tournamentRegisterModalStore.loadingUserDetail === true ?
                            <Loading/>
                            :
                            <SelectInputAsyncTypeAhead
                                label={tournamentRegisterModalStore.bowUnion ? tournamentRegisterModalStore.bowUnion.getLicenseLabel() + " #" : null}
                                onSearch={this.queryCupTournamentUser}
                                renderOptionItems={this.renderTUserOptionItems}
                                handleSelectedObject={this.selectedCupTournamentUser}
                                placeholder={messages.get("tournament.mmtuser.search4name")}/>
                    }

                </Col>
                <Col xs={12}> {error ? <Alert bsStyle="warning">{error}</Alert> : ""}
                </Col>
                <Col sm={6} xs={12}>
                    <Button block onClick={this.handleNoCupNumber} disabled={submitDisabled}
                            bsStyle="default">{messages.get("tournament.button.noCupLicense")}</Button>

                </Col>
            </Row>


        } else {
            const allowChange = this.allowChangeRegistrationDetails();
            return <Row>
                <form action="#">
                    <Col sm={6}>
                        <ValidInput disabled={!allowChange} ref={this.refName}
                                    placeholder={messages.get("tournament.register.name")}
                                    default={this.getCupTournamentUserDefault(TournamentUser.prototype.col.name)}/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput disabled={!allowChange} ref={this.refSurname}
                                    placeholder={messages.get("tournament.register.surname")}
                                    default={this.getCupTournamentUserDefault(TournamentUser.prototype.col.surname)}/>
                    </Col>
                    <Col sm={12}>
                        {
                            allowChange || allowEmailEdit ? <ValidInput ref={this.refMail}
                                           valid={{maxLength: 50, check: ['isRequired','isMail']}}
                                           validateNow={validateNow}
                                           placeholder={messages.get("tournament.register.email.unique")}
                                           addonBefore={<FontAwesome icon="envelope"/>}
                                           default={this.getCupTournamentUserDefault(TournamentUser.prototype.col.playerEmail)}/> : null
                        }
                        <TournamentSexSelect inputRef={this.refSex} disabled={!allowChange}
                                             defaultValue={this.getCupTournamentUserDefault(TournamentUser.prototype.col.sex)}
                                             selectCallback={() => null} />

                        <TournamentConfigBowSelect label={messages.get("tournament.class.bow")}
                                                   defaultValue={tournamentRegisterModalStore.selectedBowTypeID}
                                                   tournament={tournament}  selectCallback={this.configBowSelected}/>

                        {
                            tournamentRegisterModalStore.bowUnion && tournamentRegisterModalStore.bowUnion.showAgeConfigCalc() ?
                                <TournamentConfigAgeCalculator tournament={tournament} selectCallback={this.configAgeSelected}/> :null
                        }

                        <TournamentConfigAgeSelect label={messages.get("tournament.class.age")}
                                                   value={tournamentRegisterModalStore.selectedAgeTypeID}
                                                   tournament={tournament}  selectCallback={this.configAgeSelected}/>
                        {
                            tournamentRegisterModalStore.tOption != null ?
                                <TournamentOptionSelect tOption={tournamentRegisterModalStore.tOption}
                                                        tournament={tournament} preSelect={false}
                                                        selectedAgeType={tournamentRegisterModalStore.selectedAgeType}
                                                        selectCallback={this.optionSelected} /> : null
                        }
                        {
                            tournamentRegisterModalStore.tournamentRegSlots != null && tournamentRegisterModalStore.tournamentRegSlots.length > 0 ?
                                <TournamentRegSlotSelect tournamentRegSlots={tournamentRegisterModalStore.tournamentRegSlots}  showAll={false}
                                                         preSelect={false} selectCallback={this.slotSelected}/> : null
                        }
                        <ValidInput ref={this.refUnion}
                                    placeholder={messages.get("tournament.register.union")}
                                    addonBefore={<FontAwesome icon="university"/>}
                                    default={this.getCupTournamentUserDefault(TournamentUser.prototype.col.union)}/>

                        <ValidInput disabled={!allowChange} ref={this.refLicense}
                                    label={tournamentRegisterModalStore.bowUnion.getLicenseLabel() + " #"}
                                    addonBefore={<FontAwesome icon="id-card"/>}
                                    placeholder={messages.get("tournament.register.mandatory.license")}
                                    default={this.getCupTournamentUserDefault(TournamentUser.prototype.col.licenseNumber)}/>

                        <ValidInput ref={this.refNote}
                                    componentClass="textarea"
                                    valid={{maxLength: 1500}}
                                    validateNow={validateNow}
                                    placeholder={messages.get("tournament.register.note")}/>

                        <br/><label>{messages.get("tournament.register.label.price")}: </label>{curr + " " + tournamentRegisterModalStore.price}
                        <br/><label>{messages.get("tournament.register.label.payCondition")}: </label>{this.showPaymentCondition()}
                        {error ? <Alert bsStyle="warning">{error}</Alert> : ""}
                        <br/>
                    </Col>
                    <Col xs={6}>
                        <SubmitButton ref={this.refSubmitButton} block
                                      onClick={this.handleSubmitRegistration} disabled={submitDisabled}
                                      icon={<FontAwesome icon="check"/>}
                                      text={messages.get("tournament.register.submit")}
                                      bsStyle="primary"/>
                    </Col>
                    <Col xs={6}>
                        <Button block onClick={this.cancelRegistration } disabled={submitDisabled}
                                bsStyle="default"><FontAwesome icon="times"/>{messages.get("modal.button.cancel")}</Button>
                    </Col>
                </form>

            </Row>
        }
    }
}
TournamentRegistrationFormCupChild.propTypes = {
    user: PropTypes.object.isRequired,
    tournament: PropTypes.object.isRequired,
    tournamentRegisterModalStore: PropTypes.object.isRequired
};
module.exports = TournamentRegistrationFormCupChild;
