const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const userActions = require("actions/UserActions"),
    userStore = require("stores/UserStore"),
    FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    linkUtil = require("linkUtil"),
    CheckboxInput = require("components/form/CheckboxInput"),
    ValidInput = require("components/form/ValidInput");

const Parse = require("parse");

class LoginModal extends React.Component {
    constructor(props) {
        super(props);
        this.refUsername = React.createRef();
        this.refMail = React.createRef();
        this.refPassword = React.createRef();
        this.refPassword2 = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.close = this.close.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.switchToLogin = this.switchToLogin.bind(this);
        this.switchToRegister = this.switchToRegister.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);

        const userState = userStore.getState();
        this.state = {
            showModal: userState.isLoginModalVisible,
            messageCode: userState.messageCode,
            kind: userState.loginKind,
            rSwitch: false,
        }
    }
    componentDidMount() {
        userStore.listen(this.handleChange);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleChange);
    }
    handleChange(userState) {
        this.setState({
            messageCode: userState.messageCode,
            showModal: userState.isLoginModalVisible,
            kind: userState.loginKind
        });
    }
    close() {
        userActions.hideLoginModal();
    }
    handleLogin(e) {
        e.preventDefault();
        let username = null;
        let pwd = null;
        if (this.state.kind === "register") {
            username = this.refUsername.current.getValue().trim();
            username = username.replace(/\s+/g,"");
            pwd = this.refPassword.current.getValue().trim();
            const email = this.refMail.current.getValue().trim();
            const pwd2 = this.refPassword2.current.getValue().trim();

            if (pwd !== pwd2) {
                userActions.showLoginError(messages.get("modal.login.error.password.match"));
                return;
            }
            if (username.length == 0 || email.length == 0 || pwd.length == 0) {
                userActions.showLoginError(messages.get("modal.login.error.missing.required"));
                return;
            }

            const user = new Parse.User();
            user.set("username", username);
            user.set("password", pwd);
            user.set("email", email.toLocaleLowerCase());
            user.set("privacyAccDate", new Date());
            userActions.register(user);
        } else {
            username = this.refUsername.current.getValue().trim();
            username = username.replace(/\s+/g,"");
            pwd = this.refPassword.current.getValue().trim();
            if (username.length == 0) {
                userActions.showLoginError("modal.login.error.missing.username");
                return;
            }
            if (pwd.length == 0) {
                userActions.showLoginError("modal.login.error.missing.password");
                return;
            }
            userActions.loginUsernameOrEmail(username, pwd);
        }
    }
    resetError() {
        userActions.removeLoginError();
    }
    switchToLogin() {
        userActions.showLoginModal();
    }
    switchToRegister() {
        userActions.showLoginCreateModal();
    }
    handleSwitch(value) {
        this.setState({rSwitch: value});
    }
    render() {
        const {showModal, messageCode, rSwitch, kind} = this.state;
        let validateNow = messageCode != null && messageCode.length > 0;
        const isRegister = kind === "register";
        const userCheck = ['isRequired'];
        if (isRegister) {
            userCheck.push('noSpecSign')
        }
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleLogin}>
                    <input type="submit" style={{display:"none"}}/>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <I18n code="modal.login.title"/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <ValidInput ref={this.refUsername}
                                            validateNow={validateNow}
                                            valid={{check: userCheck}}
                                            placeholder={messages.get("modal.login.username")}/>
                                {isRegister ?
                                    <ValidInput ref={this.refMail}
                                                type="text"
                                                validateNow={validateNow}
                                                valid={{maxLength: 50, check: ['isRequired', 'isMail']}}
                                                placeholder={messages.get("modal.login.email")}/> : null}
                                <ValidInput ref={this.refPassword}
                                            type="password"
                                            valid={{maxLength: 50, check: ['isRequired']}}
                                            onChange={this.resetError}
                                            validateNow={validateNow}
                                            placeholder={messages.get("modal.login.password")}/>
                                {isRegister ?
                                    <React.Fragment>
                                        <ValidInput ref={this.refPassword2}
                                                    type="password"
                                                    valid={{maxLength: 50, check: ['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("modal.login.password.repeat")}/>
                                        <CheckboxInput default={rSwitch}
                                                       onAfterChange={this.handleSwitch}
                                                       message={messages.get("checkout.info.accept.rules")}
                                                       addonAfter={<a href={linkUtil.getLink("policy")} target="_blank">{" " + messages.get("header.nav.policy")}</a>}/>
                                    </React.Fragment>: null}

                            </Col>
                        </Row>
                        <Row className="text-center">
                            {messageCode ?
                                <Col xs={12}>
                                    <Alert bsStyle="danger">
                                        <I18n code={messageCode}/>
                                    </Alert>
                                </Col>
                                : ""}
                            <Col xs={12}>
                                {
                                    isRegister ?
                                        <Button onClick={this.handleLogin} disabled={!rSwitch} block bsStyle="success"><FontAwesome icon="user-plus"/>
                                            <I18n code="modal.login.button.register"/>
                                        </Button>
                                        :
                                        <Button onClick={this.handleLogin} block bsStyle="success"><FontAwesome icon="sign-in-alt"/>
                                            <I18n code="modal.login.button.login"/>
                                        </Button>
                                }
                            </Col>
                            <Col xs={6}>
                                <FontAwesome icon="unlock"/><a href={linkUtil.getLink("resetpass")}>&nbsp;<I18n code="modal.login.button.forgot"/></a>
                            </Col>
                            <Col xs={6}>
                                { isRegister ?
                                    <span><FontAwesome icon="sign-in-alt"/><a onClick={this.switchToLogin} href="#">&nbsp;<I18n code="modal.login.button.login.back"/></a></span>
                                    :
                                    <span><FontAwesome icon="user-plus"/><a onClick={this.switchToRegister} href="#">&nbsp;<I18n code="modal.login.button.register"/></a></span>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                </form>
            </Modal>
        );
    }
}
module.exports = LoginModal;
