const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n");

const InActiveButton = ({active,bsStyle, bsSize, onClick}) => {
    if (active) {
        return <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip"><I18n code="modal.button.deactivate"/></Tooltip>}>
            <Button bsStyle={bsStyle} bsSize={bsSize} onClick={() => onClick(false)}><FontAwesome icon="times-circle"/></Button>
        </OverlayTrigger>
    } else {
        return <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip"><I18n code="modal.button.activate"/></Tooltip>}>
            <Button bsStyle={bsStyle} bsSize={bsSize} onClick={() => onClick(true)}><FontAwesome icon="check-circle"/></Button>
        </OverlayTrigger>
    }
};
InActiveButton.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick:PropTypes.func.isRequired,
    bsStyle: PropTypes.string,
    bsSize: PropTypes.string,
};
module.exports = InActiveButton;
