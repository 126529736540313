const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Image} = require("react-bootstrap");
const analyticsActions = require("actions/AnalyticsActions");

const ExtLink = require("components/links/ExtLink");

const PanelBackgroundImage = require("components/widgets/PanelBackgroundImage");

const PoIItem = ({poi, userLoc, imgStyle}) =>  {
    const linkClicked = () => {
        analyticsActions.trackPointInterest(poi.id);
    };
    if (poi.isImageOnly()&& poi.getBannerImageID()) {
        return (
            <Row>
                <Col xs={12}>
                    <ExtLink href={linkUtil.getExternalLink(poi.getWeblink())}
                             onClick={linkClicked}>
                        <Image style={imgStyle} src={poi.getBannerImageID().getImageUrl()} rounded responsive/>
                    </ExtLink><br/></Col>
            </Row>)
    } else {
        return (
            <PanelBackgroundImage image={poi.getBannerImageID()} imgStyle={imgStyle}>
                <Row>
                    <Col xs={12} md={9}>
                        <h4><b>
                            <a className="background-white-transparent" onClick={linkClicked}
                               href={linkUtil.getExternalLink(poi.getWeblink())} target="_blank" rel="noopener">{poi.getName()}</a>
                        </b></h4>
                        { poi.getDescription() == null ? "" :
                            <b><span className="background-white-transparent">{poi.getDescription()}</span></b>
                        }
                    </Col>
                    <Col xs={9}>
                        <span className="background-white-transparent">{poi.getZipPlaceStreet()}</span>
                    </Col>
                    <Col xs={6}>

                    </Col>
                    <UserPoiDistance poi={poi} userLoc={userLoc}/>
                </Row>
            </PanelBackgroundImage>)
    }
};
PoIItem.propTypes = {
    poi:PropTypes.object.isRequired,
    userLoc:PropTypes.object
};
const UserPoiDistance = ({poi, userLoc}) =>  {
    if (userLoc != null) {
        return <Col xs={6}>
            <span className="background-white-transparent">{Math.round( userLoc.kilometersTo(poi.getCoordinates()) * 100) / 100 + " km"}</span>
        </Col>
    }
    return null;
};
UserPoiDistance.propTypes = {
    poi:PropTypes.object.isRequired,
    userLoc:PropTypes.object
};
module.exports = PoIItem;
