const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, PageHeader, Table, Button, Label, Alert, FormControl} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ClubArticlesButton = require("components/links/ClubArticlesButton"),
    PaymentTargetModal = require("components/modals/PaymentTargetModal"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead"),
    AddressInfo = require("components/widgets/AddressInfo"),
    AddressModal = require("components/modals/AddressModal"),
    ObjectConfigModal = require("components/modals/ObjectConfigModal"),
    SubmitButton = require("components/form/SubmitButton"),
    ValidInput = require("components/form/ValidInput"),
    messages = require("i18n/messages"),
    ClubLoader = require("components/club/ClubLoader");

const TargetCatalogArticles = require("components/clubaccounting/TargetCatalogArticles");
const { ClubUser, ClubInvoice, TransactionPosition, Management } = require("parse/_Domain");

const clubActions = require("actions/ClubActions"),
    notificationActions = require("components/notification/NotificationActions"),
    clubInvoiceActions = require("actions/ClubInvoiceActions"),
    paymentElementsStore = require("stores/PaymentElementsStore"),
    paymentElementsActions = require("actions/PaymentElementsActions");


const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubInvoicePage extends React.Component {
    render() {
        // Club details will be loaded as soon as club and clubUsers will be loaded
        return <ClubLoader clubID={this.props.clubID} clubUserRole={ClubUser.prototype.role.userHandling} loginMandatory={true}>
            <ClubInvoiceDetails />
        </ClubLoader>
    }
}
ClubInvoicePage.propTypes = {
    clubID: PropTypes.string.isRequired
};

class ClubInvoiceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refNote = React.createRef();
        this.handlePayEleStoreChange = this.handlePayEleStoreChange.bind(this);
        this.handleManagementChange = this.handleManagementChange.bind(this);
        this.createClubInvoice = this.createClubInvoice.bind(this);
        this.handleSavePaymentTarget = this.handleSavePaymentTarget.bind(this);
        this.handleSelectClubUser = this.handleSelectClubUser.bind(this);
        this.handleSelectBusinessAccount = this.handleSelectBusinessAccount.bind(this);
        this.saveAddressToClub = this.saveAddressToClub.bind(this);
        this.handleInvoiceTypeChange = this.handleInvoiceTypeChange.bind(this);
        this.addTargetToArticles = this.addTargetToArticles.bind(this);
        this.handleInvTaxConfig = this.handleInvTaxConfig.bind(this);
        this.state = {
            paymentElementsStore: paymentElementsStore.getState(),
            targetArticles: [],
            managements: null,
            management: null,
            invType: null,
            clubUser: null,
            address: null,
            invTaxConfig: null
        };
    }
    componentDidMount() {
        document.title = this.props.club.getName();
        paymentElementsStore.listen(this.handlePayEleStoreChange);
        paymentElementsActions.initBasket.defer(null);
        if (this.props.isSKillBoardClub) {
            clubInvoiceActions.queryAllManagments.defer(cbManagements=> {
                this.handleManagementChange(cbManagements)
            })
        }
    }
    componentWillUnmount() {
        paymentElementsStore.unlisten(this.handlePayEleStoreChange);
    }
    handlePayEleStoreChange(state) {
        this.setState({paymentElementsStore: state});
    }
    handleSavePaymentTarget(paymentTarget) {
        clubActions.updatePaymentTarget(paymentTarget, this.props.club);
    }
    handleManagementChange(cbManagements) {
        this.setState({managements: cbManagements})
    }
    saveAddressToClub(address) {
        // create clubUser for this Address
        const newClubUser = ClubUser.prototype.buildClubMember(null, this.props.club);
        newClubUser.setRole(ClubUser.prototype.role.address);
        newClubUser.setAddressTwoID(address);
        clubActions.saveClubUser(newClubUser);
    }
    handleInvoiceTypeChange(e) {
        const selectedType = e.target.value;
        this.setState({invType: selectedType})
    }
    handleSelectClubUser(clubUser) {
        if (clubUser.getUserDetailID() && clubUser.getUserDetailID().getAddressID()) {
            this.setState({clubUser: clubUser, address: clubUser.getUserDetailID().getAddressID()})
        } else if (clubUser.getAddressTwoID()) {
            this.setState({clubUser: clubUser, address: clubUser.getAddressTwoID()})
        } else {
            this.setState({clubUser: null, address: null})
        }
    }
    handleSelectBusinessAccount(managament) {
        this.setState({management: managament, address: managament.getBusinessPartnerID().getAddressID()})
    }
    addTargetToArticles(target) {
        let tmpArticles = this.state.targetArticles;
        tmpArticles.push(target)
        this.setState({targetArticles: tmpArticles},
            () => {
                // add first amount
                paymentElementsActions.updateTransactionArticle(target.id, 1);
                paymentElementsActions.updateSum(target.getPrice());
            })
    }
    createClubInvoice() {
        const {club, user} = this.props;
        const { paymentElementsStore, targetArticles, clubUser, management, address, invType, invTaxConfig} = this.state;
        const clubArticles = club.getArticles();

        let editInvoice = new ClubInvoice();
        editInvoice.setStatus(ClubInvoice.prototype.status.created);
        editInvoice.setCreator(user);
        editInvoice.setClubID(club);
        // edit the elements
        if (paymentElementsStore.maxPaymentPrice != null) {
            editInvoice.setPrice(paymentElementsStore.maxPaymentPrice);
            editInvoice.setDiscount(paymentElementsStore.maxPaymentPrice - paymentElementsStore.sum);
        } else {
            editInvoice.setPrice(paymentElementsStore.sum)
        }
        editInvoice.setInOrOut(true);
        editInvoice.setCurrency("EUR");
        editInvoice.setAmount(0);
        // only set price -> amount is for already paid sum
        // editInvoice.setInvoiceNumber() - > will be set from beforeSave
        editInvoice.setDate(new Date());
        let payCondition = club.getPaymentTargetID() ? club.getPaymentTargetID().getInvoicePaymentCondition() : null
        if (clubUser != null) {
            editInvoice.setClubUserID(clubUser);
            if (clubUser.getPaymentTargetID() != null && clubUser.getPaymentTargetID().getInvoicePaymentCondition() != null) {
                // overwrite payment condition from clubUser payment target
                payCondition = clubUser.getPaymentTargetID().getInvoicePaymentCondition()
            }
        }
        editInvoice.setName(address.getFullName());
        editInvoice.setAddressID(address);
        if (this.refNote.current.getValue()) {
            editInvoice.setDescription(this.refNote.current.getValue());
        } else {
            editInvoice.unset(ClubInvoice.prototype.col.description);
        }
        if (invType != null) {
            editInvoice.setType(invType)
        }
        if (invTaxConfig != null) {
            editInvoice.setTaxConfig(invTaxConfig)
        }
        // build articlePositions
        let articlePositions = [];
        Object.keys(paymentElementsStore.articles).map(articleId => {
            let amount = paymentElementsStore.articles[articleId];
            let pos = new TransactionPosition();
            pos.setStatus(ClubInvoice.prototype.status.created);
            pos.setAmount(amount);
            // get parcoursArticleElement
            for (let i = 0; i < clubArticles.length; i++) {
                if (clubArticles[i].id === articleId) {
                    // found fitting article
                    pos.setName(clubArticles[i].getName());
                    pos.setSinglePrice(clubArticles[i].getPrice());
                    pos.setSum(amount * clubArticles[i].getPrice());
                    pos.setArticle(clubArticles[i]);
                    break;
                }
            }
            for (let i = 0; i < targetArticles.length; i++) {
                if (targetArticles[i].id === articleId) {
                    // found fitting article
                    pos.setName(targetArticles[i].getName());
                    pos.setSinglePrice(targetArticles[i].getPrice());
                    pos.setSum(amount * targetArticles[i].getPrice());
                    pos.setTarget(targetArticles[i]);
                    break;
                }
            }
            articlePositions.push(pos);
        });
        editInvoice.setParcoursArticles(articlePositions);
        editInvoice.setTax(0);
        editInvoice.setAfterPayment({
            clubAbo: false,
            sendMail: false,
            payCondition: payCondition
        });
        clubInvoiceActions.createClubInvoice(editInvoice, management);
    }
    handleInvTaxConfig(invTaxConfig) {
        this.setState({invTaxConfig: invTaxConfig})
        if (invTaxConfig[Management.prototype.invTaxConfig.igErwerb] === true) {
            this.refNote.current.setValue("Innergemeinschaftliche steuerfreie Lieferung");
        }
    }
    render() {
        const { club, clubUsers, isSKillBoardClub} = this.props;
        const { paymentElementsStore, targetArticles, address, managements, invTaxConfig} = this.state;
        const currency = "EUR";
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/club" code="header.nav.clubs"/>
                            <Breadcrumb link={"/club/" + club.id} code={club.getName()} />
                            <Breadcrumb link={"/club/" + club.id + "/accounting"}code="cl_role_invoice"/>
                            <Breadcrumb code="cl_role_invcreation" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{club.getName()}</PageHeader>
                    </Col>
                    <Col lg={12}>
                        <ClubArticlesButton bsStyle={"primary"} club={club} group={"invoice"} />
                        <PaymentTargetModal paymentTarget={club.getPaymentTargetID()}  handleSave={this.handleSavePaymentTarget}
                                            buttonStyle={club.getPaymentTargetID() ? "primary" : "warning"}
                                            desc={<p><I18n code="club.invoice.paymentTarget.hint"/></p>}/>
                        <AddressModal address={null} businessMode={true} buttonStyle="primary" clubWriteRole={true}
                                      handleAddressSaved={this.saveAddressToClub}/>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col lg={12}>
                        <h1><I18n code="club.invoice.creation"/></h1>

                    </Col>
                    <Col lg={6}>
                        {
                            clubUsers != null ? <React.Fragment>
                                <h3><FontAwesome icon="user-circle"/><I18n code="club.invoice.customer.club"/></h3>
                                <SelectInputTypeAhead
                                    objectList={clubUsers}
                                    handleNoObjectFound={() => notificationActions.info("Sie müssen einen Kunden aus der Liste auswählen. Eventuell müssen sie die Adresse erst hinzufügen!")}
                                    handleSelectedObject={this.handleSelectClubUser}
                                    placeholder={messages.get("parcours.button.search.placeholder")}/>
                            </React.Fragment>: null
                        }
                    </Col>
                    {
                        managements != null ? <Col lg={6}>
                            <React.Fragment>
                                <h3><FontAwesome icon="user-circle"/><I18n code="club.invoice.customer.business"/></h3>
                                <SelectInputTypeAhead
                                    objectList={managements}
                                    handleNoObjectFound={() => notificationActions.info("Sie müssen einen Kunden aus der Liste auswählen. Eventuell müssen sie die Adresse erst hinzufügen!")}
                                    handleSelectedObject={this.handleSelectBusinessAccount}
                                    placeholder={messages.get("parcours.button.search.placeholder")}/>
                                </React.Fragment>
                        </Col> : null
                    }
                </Row>
                <Row>
                    <Col lg={6}>
                        <br/>
                        {
                            address == null ? <Alert bsStyle="warning"><I18n code="club.invoice.noCustomer"/></Alert> :
                                <React.Fragment>
                                    <AddressInfo address={address}/>
                                    {isSKillBoardClub ?
                                        <ObjectConfigModal pObject={null} configType="mgmt_invTaxConfig"
                                                           cbUpdatedObject={this.handleInvTaxConfig}/> : null
                                    }
                                </React.Fragment>

                        }

                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col lg={6}>
                        <h3><FontAwesome icon="hashtag"/><I18n code="club.invoice.addNote"/></h3>
                        <ValidInput ref={this.refNote}
                                    componentClass="textarea"
                                    valid={{maxLength: 1500}}
                                    placeholder={messages.get("club.event.create.message.note")}/>
                        {
                            club.getInvoiceTypes() != null ?
                                    <FormControl componentClass="select" placeholder="select"
                                                 onChange={this.handleInvoiceTypeChange}>
                                        <option key={"nullkey"}
                                                value={"null"}>{messages.get("club.invoice.modal.chooseInvType")}</option>
                                        {club.getInvoiceTypes().map(invType => {
                                            return <option key={invType} value={invType}>{invType}</option>
                                        })}

                                    </FormControl> : null
                        }
                    </Col>

                </Row>
                <hr/>
                <Row>
                    <Col lg={12}>
                        <h3><FontAwesome icon="shopping-cart"/><I18n code="club.member.type.invoice"/></h3>
                    </Col>
                </Row>
                {
                    club.getArticles() != null ?
                        <ClubClubInvoiceArticles club={club} sum={paymentElementsStore.sum} currency={currency} allowTargetCatalog={isSKillBoardClub}
                                                 addTargetToArticles={this.addTargetToArticles} targetArticles={targetArticles} invTaxConfig={invTaxConfig}
                                                 articles={paymentElementsStore.articles} maxPaymentPrice={paymentElementsStore.maxPaymentPrice}/>: null
                }
                <Row>
                    <Col xs={8} xsOffset={2} style={{"textAlign": "center"}}>
                        <SubmitButton ref={this.refSubmitButton} block bsSize="large"
                                      disabled={paymentElementsStore.sum === 0 || address == null}
                                      onClick={this.createClubInvoice}
                                      text={messages.get("modal.button.next")}/>
                    </Col>
                </Row>
            </Grid>)
    }
}
ClubInvoiceDetails.propTypes = {
    club: PropTypes.object.isRequired,
    clubUsers: PropTypes.array.isRequired,
    isSKillBoardClub: PropTypes.bool.isRequired
};
const ClubClubInvoiceArticles = ({club, sum, articles, currency, invTaxConfig, maxPaymentPrice, allowTargetCatalog, targetArticles, addTargetToArticles}) => {
    function setDiscount(value) {
        paymentElementsActions.updateMaxPaymentPrice(sum + parseFloat(value))
    }
    let articlesList = club.getArticles().filter(article => {
        return article.isGroupInvoice() && article.isActive()
    });
    let maxPay = maxPaymentPrice != null ? maxPaymentPrice : sum;
    let taxPercent = 0;
    if (invTaxConfig != null) {
        // take manual override tax config
        taxPercent = invTaxConfig[Management.prototype.invTaxConfig.positionTax]
    } else if (club.getManagementID() != null) {
        // take tax config from managment entry
        taxPercent =club.getManagementID().getInvoicePositionTax()
    }
    return <Row>
        {
            allowTargetCatalog != null ? <TargetCatalogArticles addTargetToArticles={addTargetToArticles}/> : null
        }
        <Col xs={12}>
            <Table>
                <thead>
                <tr>
                    <th className="col-xs-6 col-md-6"><I18n code="tournament.table.column.article"/></th>
                    <th className="col-xs-3 col-md-3"><I18n code="parcours.articles.form.price"/></th>
                    <th className="col-xs-3 col-md-3"><I18n code="tournament.table.column.amount"/></th>
                </tr>
                </thead>
                <tbody>
                {
                    articlesList.map(article => {
                        let amount = articles[article.id];
                        return <ParcoursArticle key={article.id} article={article} amount={amount ? amount : 0}/>
                    })
                }
                {
                    targetArticles.map(article => {
                        let amount = articles[article.id];
                        return <ParcoursArticle key={article.id} article={article} amount={amount ? amount : 0}/>
                    })
                }
                <tr>
                    <td></td>
                    <td><I18n code="tournament.table.column.article.sum"/></td>
                    <td>{currency + " " + sum.toFixed(2)}</td>
                </tr>
                <tr>
                    <td></td>
                    <td><I18n code="tournament.table.column.discount"/></td>
                    <td><ValidInput type="number"
                                    valid={{maxLength: 10, check: ['point4comma']}}
                                    default={"-0"}
                                    onAfterChange={setDiscount}/></td>
                </tr>
                <tr>
                    <td></td>
                    <td><strong><I18n code="tournament.table.column.sum"/>(brt)</strong></td>
                    <td><strong>{currency + " " + maxPay.toFixed(2)}</strong></td>
                </tr>
                {
                    taxPercent > 0 ?
                        <tr>
                            <td></td>
                            <td><strong><I18n code="tournament.table.column.tax"/> {" " + taxPercent + "%"}
                            </strong></td>
                            <td>
                                <strong>{currency + " " + (maxPay * taxPercent / (100 + taxPercent)).toFixed(2)}</strong>
                            </td>
                        </tr> : null
                }
                </tbody>
            </Table>
        </Col>
    </Row>
}
ClubClubInvoiceArticles.propTypes = {
    club:PropTypes.object.isRequired
};
const ParcoursArticle = ({article, amount})=> {
    const onButtonDecr = (event) => {
        let delta = 1;
        if (event.shiftKey === true) {
            delta = 10
        } else if(event.ctrlKey === true || event.metaKey === true) {
            //strg or cmd pressed
            delta = 0.5;
        }
        paymentElementsActions.updateTransactionArticle(article.id, amount - delta);
        paymentElementsActions.updateSum(article.getPrice()  * (-delta));
    };
    const onButtonIncr = (event) => {
        let delta = 1;
        if (event.shiftKey === true) {
            delta = 10
        } else if (event.ctrlKey === true || event.metaKey === true) {
            //strg or cmd pressed
            delta = 0.5;
        }
        paymentElementsActions.updateTransactionArticle(article.id, amount + delta);
        paymentElementsActions.updateSum(article.getPrice() * delta);
    };
    return (
        <tr>
            <td>{article.getName()}</td>
            <td>{article.getPrice().toFixed(2) + ' ' + article.getCurrency()}</td>
            <td>
                <div style={{"display":"inline-block", "fontSize": "25px"}}>
                    <Button bsStyle="primary" onClick={onButtonDecr}>
                        <FontAwesome icon="minus"/>
                    </Button>
                    <Label bsStyle="default">{amount}</Label>
                    <Button bsStyle="primary"onClick={onButtonIncr}>
                        <FontAwesome icon="plus"/>
                    </Button>
                </div>
            </td>
        </tr>
    )
};
ParcoursArticle.propTypes = {
    article:PropTypes.object.isRequired,
}
module.exports = ClubInvoicePage;
