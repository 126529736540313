/**
 * Created by Daniel on 02.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Pager} = require("react-bootstrap");
const messages = require("i18n/messages");

const MonthPagerBasic = ({headerText, month, year, handleChangedDate, allowFuture}) => {
    const updateDate = (nMonth, nYear) => {
        if (handleChangedDate) {
            handleChangedDate(nMonth, nYear, nYear != year);
        }
    };
    const nextMonth = () => {
        let today = new Date();
        if (!allowFuture && today.getMonth() + 1 == month && today.getFullYear() == year) {
            // can't go further than today :-)
            return;
        } else {
            if (month == 12) {
                updateDate(1, year + 1);
            } else {
                updateDate(month + 1, year);
            }
        }
    };
    const previousMonth =() => {
        if (month == 1) {
            updateDate(12, year - 1);
        } else {
            updateDate(month - 1, year);
        }
    }
    return (
        <React.Fragment>
            <h1 className="text-center">{headerText ? headerText : "" }{" " + messages.get("montPager.month." + month) + " " + year}</h1>
            <Pager>
                <Pager.Item previous onClick={previousMonth} >&larr; {messages.get("monthPager.previous")}</Pager.Item>
                <Pager.Item next onClick={nextMonth} >{messages.get("monthPager.next")} &rarr;</Pager.Item>
            </Pager>
        </React.Fragment>)
};
MonthPagerBasic.propTypes = {
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    headerText:PropTypes.string,
    handleChangedDate:PropTypes.func,
    allowFuture: PropTypes.bool
};
module.exports = MonthPagerBasic;