const React = require("react"),
    PropTypes = require('prop-types');
const {Table} = require("react-bootstrap");
const  messages = require("i18n/messages");

const TournamentUserDetailPanel = ({tournamentUser}) => {
    return (<Table style={{margin: "0"}}>
            <tbody>
            <tr>
                <th style={{borderTop:"0"}}>{messages.get("tournament.table.column.name")}</th>
                <td style={{borderTop:"0"}}>{tournamentUser.getFullName() + "   #" + tournamentUser.getLicenseNumber()}</td>
            </tr>
            {tournamentUser.getUnion() ?
                <tr>
                    <th>{messages.get("tournament.register.union")}</th>
                    <td>{tournamentUser.getUnion()}</td>
                </tr>
                : null
            }
            <tr>
                <th>{messages.get("tournament.class.bow")}</th>
                <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            </tr>
            <tr>
                <th>{messages.get("tournament.class.age")}</th>
                <td>{tournamentUser.getTournamentConfigAgeCodeName()}</td>
            </tr>
            </tbody>
        </Table>);
};
TournamentUserDetailPanel.propTypes = {
    tournamentUser: PropTypes.object.isRequired
};
module.exports = TournamentUserDetailPanel;