function ClubInvoiceYearStat() {
    this.months = [0,1,2,3,4,5,6,7,8,9,10,11];
    this.monthList = new Array(12);
    this.yearIn = 0;
    this.yearOut = 0;
    this.monthIn = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.monthOut = [0,0,0,0,0,0,0,0,0,0,0,0];
    this.typeAmountDict = {};

    this.addInvoice = function (invoice) {
        var date = invoice.getDate();
        var month = date.getMonth();
        // add to list
        var invList = this.monthList[month];
        if (invList == null) {
            invList = [];
        }
        invList.push(invoice);
        var amount = invoice.getAmount();
        var type = invoice.getType();
        if (type == null) {
            type = "---"
        }
        var typeAmount = this.typeAmountDict[type];
        if (typeAmount == null) {
            typeAmount = {};
            typeAmount["IN"] = 0;
            typeAmount["OUT"] = 0;
        }
        // add to in or out
        if (invoice.getInOrOut() == true) {
            // add to type
            typeAmount["IN"] += amount;
            this.monthIn[month] += amount;
            this.yearIn += amount;
        } else {
            // add to type
            typeAmount["OUT"] += amount;
            this.monthOut[month] += amount;
            this.yearOut += amount;
        }
        this.typeAmountDict[type] = typeAmount;
    };
    this.getMonths = function() {
        return this.months;
    };
    this.getMonthInvoices = function(month) {
        return this.monthList[month];
    };
    this.getMonthIn = function(month) {
        return this.monthIn[month];
    };
    this.getMonthOut = function(month) {
        return this.monthOut[month];
    };
    this.getMonthBalance = function(month) {
        return this.monthIn[month] - this.monthOut[month];
    };
}
module.exports = ClubInvoiceYearStat;