const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const TournamentCupLicLink = ({tournamentUser}) => {
        return <a href={linkUtil.getLink("cup/" + tournamentUser.getLicenseUnionCode() + "/" + tournamentUser.getLicenseNumber())} target="_blank">
            {tournamentUser.getLicenseNumber()}
            </a>
};
TournamentCupLicLink.propTypes = {
    tournamentUser: PropTypes.object.isRequired
};
module.exports = TournamentCupLicLink;
