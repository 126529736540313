/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Alert, Checkbox} = require("react-bootstrap");
const messages = require("i18n/messages");

const ExtLink = require("components/links/ExtLink"),
    FontAwesome = require("components/widgets/FontAwesome");

class ParcoursCategoryEditor extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.state = {
            help: null
        }
    }
    handleChange(e) {
        var skipped = false;
        var changedKey = e.target.id;
        var oldValue = this.props.object.get(this.props.colName);
        var newValue = [];
        if (oldValue) {
            for (var i = 0; i < oldValue.length; i++) {
                if (oldValue[i] === changedKey) {
                    // is in list - check if need to be skipped
                    if(!e.target.checked) {
                        // was checked - add key
                        skipped = true;
                    }
                } else {
                    newValue.push(oldValue[i])
                }
            }
        }
        if (!skipped) {
            newValue.push(changedKey);
        }
        this.props.parseSaveParcours(this.props.object, this.props.colName, oldValue, newValue, this);
    }
    showHelpMessage(message) {
        this.setState({
            help: message
        });
        setTimeout(() => {
            this.showHelpMessage(null);
        }, 5000);
    }
    render() {
        const {help} = this.state;
        const {object, colName, header, array, ppCheckObject} = this.props;
        const list = object.get(colName);
        let helpElement = null;
        return (<React.Fragment>
            {
                header == null ? "" : <strong>{header}</strong>
            }
            <ul>
                {
                    array.map(category => {
                        let checked = "";
                        let disabled = false;
                        if (list != null) {
                            for (let i = 0; i < list.length; i++) {
                                if (list[i] == category) {
                                    checked = "checked";
                                }
                            }
                        }
                        if (ppCheckObject != null && category.endsWith(ppCheckObject.suffix)) {
                            // enable checkbox only if ranking is enabled
                            disabled = !ppCheckObject.ranking
                            helpElement = <ExtLink
                                href={"https://3dturnier.freshdesk.com/support/solutions/articles/42000107231"}><FontAwesome icon="question-circle"/></ExtLink>
                        }
                        return (<Checkbox id={category}
                                      key={category}
                                      checked={checked}
                                      disabled={disabled}
                                      onChange={this.handleChange}>
                                {messages.get(category)}&nbsp;&nbsp;
                                {helpElement}
                            </Checkbox>)
                    })
                }
            </ul>
            {
                help == null ? null : <Alert bsStyle="success">{help}</Alert>
            }
        </React.Fragment>)
    }
}
module.exports = ParcoursCategoryEditor;