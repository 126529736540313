const ParseListCompareUtility = {
    compareLiveTUserDPSFct : function(listEntry, newEntry) {
        // sorts tournamentUser first by DPS than points, than kill Value
        const newDPS = newEntry.getUserDictTUserDPS(false, false);
        const listDPS = listEntry.getUserDictTUserDPS(false, false);
        if (newDPS < listDPS) {
            // add last
            return true;
        } else if (newDPS == listDPS) {
            if (newEntry.getUserDictSortSum(false, false) < listEntry.getUserDictSortSum(false, false)) {
                return true;
            } else if (newEntry.getUserDictSortSum(false, false) == listEntry.getUserDictSortSum(false, false)) {
                if (newEntry.getUserDictKillValue(false, false) <= listEntry.getUserDictKillValue(false, false)) {
                    return true;
                }
            }
        }
        return false;
    },
    compareLiveTUserPointsFct: function(listEntry, newEntry) {
        // sorts tournamentUser first by points, than kill Value
        if (newEntry.getUserDictSortSum(false, false) < listEntry.getUserDictSortSum(false, false)) {
            // add last
            return true;
        } else if (newEntry.getUserDictSortSum(false, false) == listEntry.getUserDictSortSum(false, false)) {
            if (newEntry.getUserDictKillValue(false, false) <= listEntry.getUserDictKillValue(false, false)) {
                return true;
            }
        }
        return false;
    },
    compareEventPlayerDPSFct : function(listEntry, newEntry) {
        // sorts tournamentUser first by DPS than points, than kill Value
        const newDPS = newEntry.getPlayerDPS();
        const listDPS = listEntry.getPlayerDPS();
        if (newDPS < listDPS) {
            // add last
            return true;
        } else if (newDPS == listDPS) {
            if (newEntry.getSumPoints() < listEntry.getSumPoints()) {
                return true;
            } else if (newEntry.getSumPoints() == listEntry.getSumPoints()) {
                if (newEntry.getKillValue() <= listEntry.getKillValue()) {
                    return true;
                }
            }
        }
        return false;
    },
    compareUserTeamFct: function(listEntry, newEntry) {
        if (newEntry.getSortableSumPoints() < listEntry.getSortableSumPoints()) {
            // add last
            return true;
        } else if (newEntry.getSortableSumPoints() == listEntry.getSortableSumPoints()) {
            if (newEntry.getKillValue() <= listEntry.getKillValue()) {
                return true;
            }
        }
        return false;
    }
};
module.exports = ParseListCompareUtility;
