const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Panel, FormControl, ProgressBar} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

const tournamentUserActions = require("actions/TournamentUserActions");

const TournamentAttendeesPairs = ({tournament, tournamentUserTeams, allTournamentUserTeams, tournamentUserSearchParams}) => {
        console.log("Render TournamentAttendees page");
        var maxRegistrations = tournament.getRegMaxUser();
        var registrations = allTournamentUserTeams.length;
        var percent = 0;
        if (maxRegistrations > 0) {
            percent = 100 * registrations / maxRegistrations;
        }
        return (
            <Panel>
                <Panel.Heading>
                    <Row>
                        <Col xs={12}>
                            <h3><I18n code="tournament.attendees.panel.title"/></h3>
                            <ProgressBar now={percent} label={registrations + " / " + maxRegistrations} bsStyle="success"/>
                        </Col>
                    </Row>
                </Panel.Heading>
                <Panel.Body>
                    <TournamentAttendeeSearch tournamentUserSearchParams={tournamentUserSearchParams}/>
                    <TournamentAttendeeList tournamentUserTeams={tournamentUserTeams}/>
                </Panel.Body>
            </Panel>)
};
TournamentAttendeesPairs.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserSearchParams:PropTypes.object.isRequired,
    allTournamentUserTeams:PropTypes.array.isRequired,
    tournamentUserTeams:PropTypes.array.isRequired
};
var timeoutLock = {
    num: 0,
    getNext: function () {
        this.num++;
        return this.num;
    }
};

const TournamentAttendeeSearch = () => {
    function handleChange(e) {
        let search = e.target.value;
        let num = timeoutLock.getNext();
        setTimeout(function () {
            if (num == timeoutLock.num) {
                tournamentUserActions.searchTournamentUser({text: search, team:true});
            }
        }, 300);
    }
    return (
        <Row>
            <Col xs={12} sm={6} smOffset={6}>
                <FormControl type="text"
                       placeholder={messages.get("tournamentUser.button.search")}
                       onChange={handleChange}/><br/>
            </Col>
        </Row>
    )
};
const TournamentAttendeeList = ({tournamentUserTeams}) => {
        return (
            <React.Fragment>
                <Table striped className="hidden-xs">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th><I18n code="tournament.table.column.teamname"/></th>
                        <th><I18n code="tournament.table.column.archer"/>&nbsp;1</th>
                        <th><I18n code="tournament.class.bow"/></th>
                        <th><I18n code="tournament.table.column.archer"/>&nbsp;2</th>
                        <th><I18n code="tournament.class.bow"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tournamentUserTeams.map(tournamentUserTeam => {
                        return (<TournamentTeamTableRow key={tournamentUserTeam.id} tournamentUserTeam={tournamentUserTeam}/>);
                    })}
                    </tbody>
                </Table>

                <div className="hidden-sm hidden-md hidden-lg">
                    {tournamentUserTeams.map(tournamentUserTeam => {
                        return (<TournamentTeamListRow key={tournamentUserTeam.id} tournamentUserTeam={tournamentUserTeam}/>);
                    })}
                </div>
            </React.Fragment>)
};
TournamentAttendeeList.propTypes = {
    tournamentUserTeams:PropTypes.array.isRequired
};
const TournamentTeamListRow = ({tournamentUserTeam}) => {
    return (
        <Row>
            <Col xs={12}>
                <Panel>
                    <Panel.Body>
                        <p className="pull-left"
                           style={{lineHeight: "2.2em"}}>
                            <span> #{tournamentUserTeam.startNumber} </span>
                            <b>{tournamentUserTeam.getName()}</b>
                        </p>
                        <Table style={{margin: "0"}}>
                            {
                                tournamentUserTeam.getTournamentUserIDs() != null ?
                                    tournamentUserTeam.getTournamentUserIDs().map(tournamentUser => {
                                        return <TournamentTeamRegistree key={"TTR_" + tournamentUser.id} tournamentUser={tournamentUser}/>
                                    }) : null
                            }
                        </Table>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
};
TournamentTeamListRow.propTypes = {
    tournamentUserTeam:PropTypes.object.isRequired
};
const TournamentTeamRegistree = ({tournamentUser}) => {
    return (
        <tbody>
            <tr>
                <th><I18n code="tournament.table.column.archer"/></th>
                <td>{tournamentUser.getFullName()}</td>
            </tr>
            <tr>
                <th><I18n code="tournament.class.bow"/></th>
                <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            </tr>
        </tbody>
    )
};
TournamentTeamRegistree.propTypes = {
    tournamentUser:PropTypes.object.isRequired
};
const TournamentTeamTableRow = ({tournamentUserTeam}) => {
    var tu1 = null;
    var tu2 = null;
    var tuList = tournamentUserTeam.getTournamentUserIDs();
    if (tuList != null && tuList.length >= 2) {
        tu1 = tuList[0];
        tu2 = tuList[1];
    }
    return (
        <tr>
            <td>{tournamentUserTeam.startNumber}</td>
            <td>{tournamentUserTeam.getName()}</td>
            <td>{tu1 == null ? null : tu1.getFullName()}</td>
            <td>{tu1 == null ? null : tu1.getTournamentConfigBowCodeName()}</td>
            <td>{tu2 == null ? null : tu2.getFullName()}</td>
            <td>{tu2 == null ? null : tu2.getTournamentConfigBowCodeName()}</td>
        </tr>)
};
TournamentTeamTableRow.propTypes = {
    tournamentUserTeam:PropTypes.object.isRequired
};
module.exports = TournamentAttendeesPairs;
