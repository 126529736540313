const React = require("react"),
    PropTypes = require('prop-types');
const {ButtonToolbar, DropdownButton, MenuItem} = require("react-bootstrap");
const messages = require("i18n/messages");


class TrainingTypeSelector extends React.Component {
    constructor(props) {
        super(props);
        this.handleMainType = this.handleMainType.bind(this);
        this.handleSubType = this.handleSubType.bind(this);
        this.getTypeObject = this.getTypeObject.bind(this);
        this.state = {
            mainType: this.props.mainTypeID,
            subType: this.props.subTypeID
        };
    }
    handleMainType(objID) {
        const type = this.getTypeObject(objID);
        this.setState({mainType: type, subType: null});
        if (this.props.handleChange) {
            this.props.handleChange(type, null);
        }
    }
    handleSubType(objID) {
        const type = this.getTypeObject(objID);
        this.setState({subType: type});
        if (this.props.handleChange) {
            this.props.handleChange(this.state.mainType, type);
        }
    }
    getTypeObject(objID) {
        const length = this.props.trainingTypes.length;
        let selObject = null;
        for (let i = 0; i < length; i++) {
            if (objID === this.props.trainingTypes[i].id) {
                return this.props.trainingTypes[i];
            }
        }
    }
    render() {
        const {mainType, subType} = this.state;
        const {trainingTypes} = this.props;
        const subTrainingTypes = trainingTypes.filter(type => {
            return type.isParent(mainType)
        });
        return (
            <ButtonToolbar>
                <DropdownButton title={mainType ? mainType.getName() :  messages.get("profile.training.main.type")}
                                id="mainType-filter">
                    {trainingTypes.map(trainingType => {
                        if (!trainingType.hasParent()) {
                            return (
                                <MenuItem key={trainingType.id} eventKey={trainingType.id} onSelect={this.handleMainType}>
                                    {trainingType.getName()}
                                </MenuItem>)
                        }
                    })}
                </DropdownButton>
                <DropdownButton title={subType ? subType.getName() :  messages.get("profile.training.sub.type")}
                                id="subType-filter" disabled={subTrainingTypes.length <= 0}>
                    {subTrainingTypes.map(trainingType => {
                        return (
                            <MenuItem key={trainingType.id} eventKey={trainingType.id} onSelect={this.handleSubType}>
                                {trainingType.getName()}
                            </MenuItem>)
                    })}
                </DropdownButton>
            </ButtonToolbar>
        )
    }
}
TrainingTypeSelector.propTypes = {
    handleChange:PropTypes.func.isRequired,
    trainingTypes:PropTypes.array.isRequired,
    mainTypeID: PropTypes.object,
    subTypeID: PropTypes.object,
};
module.exports = TrainingTypeSelector;
