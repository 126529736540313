const Parse = require("ParseInit");
const EventComment = Parse.Object.extend("EventComment", {
    col: {
        eventID: "eventID",
        playerEmail: "playerEmail",
        status: "status",
        emoji: "emoji",
        targetIndex: "targetIndex",
        comment: "comment",
        // comment target details
        dist_estimated: "distEst",
        dist_measured: "distMeter",
        size:"size",
        level:"level",
        posture:"posture",
        postureHill:"postureHill",
        discSize:"discSize",
        tag: "tag"
    },
    status: {
        active: "A",
        deleted: "D"
    },
    isDeleted() {
        return "D" === this.get('status');
    },
    getEventID() {
        return this.get(this.col.eventID)
    },
    setEventID(eventID) {
        return this.set(this.col.eventID, eventID)
    },
    getPlayerEmail() {
        return this.get(this.col.playerEmail)
    },
    setPlayerEmail(playerEmail) {
        return this.set(this.col.playerEmail, playerEmail)
    },
    isCommentFromUser(user) {
        let mail = user.get("email");
        return mail != null && mail.toLowerCase() === this.getPlayerEmail().toLowerCase();
    },
    commentEmojiText() {
        let emoji = this.getEmoji();
        let comment = this.getComment();
        if (emoji != null) {
            if (comment != null) {
                return String.fromCodePoint(emoji) + " " + comment
            } else {
                return String.fromCodePoint(emoji);
            }
        }
        return comment;
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getComment() {
        return this.get(this.col.comment)
    },
    setComment(comment) {
        return this.set(this.col.comment, comment)
    },
    getEmoji() {
        return this.get(this.col.emoji)
    },
    setEmoji(emoji) {
        return this.set(this.col.emoji, emoji)
    },
    getTargetIndex() {
        return this.get(this.col.targetIndex)
    },
    setTargetIndex(targetIndex) {
        return this.set(this.col.targetIndex, targetIndex)
    },
    isDistanceEstimatedWithin(min, max) {
        return min < this.getDistanceEstimated() && max >= this.getDistanceEstimated()
    },
    getDistanceEstimated() {
        return this.get(this.col.dist_estimated)
    },
    isDistanceMeasuredWithin(min, max) {
        return min < this.getDistanceMeasured() && max >= this.getDistanceMeasured()
    },
    isLevelWithin(min, max) {
        return min <= this.getLevel() && max >= this.getLevel()
    },
    getDistanceMeasured() {
        return this.get(this.col.dist_measured)
    },
    getSize() {
        return this.get(this.col.size)
    },
    getLevel() {
        return this.get(this.col.level)
    },
    getPosture() {
        return this.get(this.col.posture)
    },
    getPostureHill() {
        return this.get(this.col.postureHill)
    },
    getDiscSize() {
        return this.get(this.col.discSize)
    },
    getTagArray() {
        return this.get(this.col.tag)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = EventComment;
