const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome");

const TrainingFilterEntity = ({filterState, children, stateKey, text, disabled, needReloadEvents, updateSearchParams}) => {
    if (filterState == null) {
        return <Button disabled={disabled} onClick={() => updateSearchParams(stateKey, [], needReloadEvents)}>
            <FontAwesome icon="plus"/>Filter: {text}
        </Button>
    } else {
        return <React.Fragment>
            <h3><Button disabled={disabled} onClick={() => updateSearchParams(stateKey, null, false)}>X</Button> {text}</h3>
            <Row>
                <Col xs={12}>
                    {children}
                </Col>
            </Row>
        </React.Fragment>
    }
}
TrainingFilterEntity.propTypes = {
    filterState: PropTypes.array,
    stateKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    needReloadEvents: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
}
module.exports = TrainingFilterEntity;
