const Alt = require("AltInit");
const actions = require("actions/LanguageActions"),
    cookieUtil = require("util/cookieUtil");

let defaultLanguage = "de";
let defaultType = "A";

function LanguageStore() {
    var cookie = cookieUtil.read();
    if (cookie.language) {
        defaultLanguage = cookie.language;
    }
    if (cookie.imageDeviceType) {
        defaultType = cookie.imageDeviceType
    }
    this.displayName = "LanguageStore";
    this.language = defaultLanguage;
    this.imageDirPrefix = "";
    this.imageDeviceType = defaultType;
    this.supportedLanguages = ['en', 'de'];
    this.userParams = null;
    this.bindListeners({
        updateLanguage: actions.switchLanguage,
        updateImageDeviceType: actions.updateImageDeviceType,
        updateUserParams: actions.updateUserParams
    });
}
LanguageStore.prototype.updateUserParams = function(params) {
    this.userParams = params;
};

LanguageStore.prototype.updateLanguage = function (language) {
    if (this.supportedLanguages.indexOf(language) > -1) {
        let prefix = language !== "de" ? "/" + language : "";
        this.setState({
            language: language,
            imageDirPrefix: prefix
        });
        cookieUtil.update("language", language);
    }
};

LanguageStore.prototype.updateImageDeviceType = function(type) {
    cookieUtil.update("imageDeviceType", type);
    this.imageDeviceType = type;
};
module.exports = Alt.createStore(LanguageStore);
