const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TextCenter = require("components/widgets/TextCenter"),
    CountTypeSelection = require("components/form/CountTypeSelection"),
    CheckboxInput = require("components/form/CheckboxInput"),
    SubmitButton = require("components/form/SubmitButton"),
    Utility = require("util/Utility"),
    ValidInput = require("components/form/ValidInput");

const messages = require("i18n/messages");

const tournamentActions = require("actions/TournamentManagerActions"),
    TournamentConfig = require("parse/TournamentConfig");

class TournamentConfigModal extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refName = React.createRef();
        this.refCode = React.createRef();
        this.refSurprice = React.createRef();
        this.refNoSexSplit = React.createRef();
        this.refAges = React.createRef()

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.edit = this.edit.bind(this);
        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.getDefaultNoSexSplit = this.getDefaultNoSexSplit.bind(this);
        this.handleCountTypeChange = this.handleCountTypeChange.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            tConfig: null,
            selCountType: null,
            selArrPasse: null
        }
    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ error: error });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error:null});
    }
    open() {
        this.setState({
            showModal: true,
            tConfig: null,
            selCountType: null
        });
    }
    edit(tConfig) {
        this.setState({
            showModal: true,
            tConfig: tConfig,
            selCountType: tConfig.getCountTypeID()
        });
    }
    valid() {
        let valid = true;
        let required = [
            this.refName.current.getValue()
        ];

        if (this.props.isBowEntry && this.state.selCountType === null) {
            this.setState({error: messages.get("modal.license.chooseCountType")});
            valid = false;
        }

        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            this.refSubmitButton.current.resetLoading();
            return;
        }
        let tConfig = this.state.tConfig;
        if (tConfig == null) {
            tConfig = new TournamentConfig();
            tConfig.setStatus(TournamentConfig.prototype.status.A);
        }
        tConfig.setName(this.refName.current.getValue().trim());
        if (this.state.selCountType) {
            tConfig.setCountTypeID(this.state.selCountType);
        }
        if (this.state.selArrPasse) {
            tConfig.setArrowPasse(this.state.selArrPasse);
        }
        const code = this.refCode.current.getValue();
        if (code) {
            tConfig.setCode(code);
        }
        if (this.refSurprice.current != null) {
            const surprice = this.refSurprice.current.getValue();
            if (surprice) {
                tConfig.setPrice(parseFloat(surprice));
            }
        }
        if (this.refNoSexSplit.current) {
            tConfig.setNoSexSplit(this.refNoSexSplit.current.getValue() != true);
        }
        if (this.refAges.current) {
            let agesString = this.refAges.current.getValue();
            if (agesString != null && agesString.length > 2) {
                tConfig.setAges(Utility.stringCSV2NumberArray(agesString))
            }
        }
        if (this.props.configCallback) {
            this.props.configCallback(tConfig);
            this.close()
        } else {
            tournamentActions.saveTournamenConfig(this.props.tournament, this.props.isBowEntry, tConfig, () => {
                this.close();
            });
        }

    }
    getDefaultValue(column) {
        if (this.state.tConfig != null && this.state.tConfig.get(column) != null) {
            return this.state.tConfig.get(column);
        }
        return null;
    }
    getDefaultNoSexSplit() {
        if (this.state.tConfig != null && this.state.tConfig.hasNoSexSplit() != null) {
            return !this.state.tConfig.hasNoSexSplit()
        }
        return true;
    }
    handleCountTypeChange(countType, arrPasse) {
        console.log("Changed countype", arrPasse, countType)
        this.setState({selCountType: countType, selArrPasse: arrPasse});
    }
    render() {
        const validateNow = this.state.error && this.state.error.length > 0;
        const {showPrice} = this.props;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.tConfig.agebowmodal.desc"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""
                        }
                        {
                            this.state.showModal ?
                                <Row>
                                    <Col sm={3}>
                                        <ValidInput ref={this.refCode}
                                                    valid={{maxLength: 5}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultValue(TournamentConfig.prototype.col.code)}
                                                    label={messages.get("tournament.table.column.grpCode")}/>
                                    </Col>
                                    <Col sm={9}>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultValue(TournamentConfig.prototype.col.name)}
                                                    label={messages.get("tournament.table.column.name")}/>
                                    </Col>
                                    {
                                        showPrice ? <Col sm={6}>
                                            <ValidInput ref={this.refSurprice}
                                                        valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                                        default={this.getDefaultValue(TournamentConfig.prototype.col.price)}
                                                        label={messages.get("parcours.articles.form.price")}/>
                                        </Col> : null
                                    }


                                    {
                                        this.props.isBowEntry ?
                                            <Col sm={6}>
                                                <p><strong><I18n code="tournament.manager.tConfig.agebowmodal.countType"/></strong></p>
                                                <CountTypeSelection countType={this.getDefaultValue(TournamentConfig.prototype.col.countTypeID)}
                                                                    arrowPasse={this.getDefaultValue(TournamentConfig.prototype.col.arrowPasse)}
                                                                    onAfterChange={this.handleCountTypeChange}/>
                                            </Col>
                                            :
                                            <React.Fragment>
                                                <Col sm={6}>
                                                    <p><strong><I18n code="tournament.manager.tConfig.agebowmodal.sexSplitHead"/></strong></p>
                                                    <CheckboxInput ref={this.refNoSexSplit} default={this.getDefaultNoSexSplit()}
                                                                message={messages.get("tournament.manager.tConfig.agebowmodal.sexSplitBody")}/>
                                                </Col>
                                                {
                                                    this.getDefaultValue(TournamentConfig.prototype.col.ages) != null ?
                                                        <Col sm={6}>
                                                            <ValidInput ref={this.refAges}
                                                                        valid={{maxLength: 6, check:['csv']}}
                                                                        default={this.getDefaultValue(TournamentConfig.prototype.col.ages)}
                                                                        label={messages.get("tournament.manager.tConfig.agebowmodal.ages")}/>
                                                        </Col> : null
                                                }
                                            </React.Fragment>

                                    }

                                </Row> : null
                        }
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <SubmitButton ref={this.refSubmitButton}
                                      onClick={this.handleSubmit} disabled={this.state.submitDisabled}
                                      icon={<FontAwesome icon="save"/>}
                                      text={messages.get("modal.button.save")}
                                      bsStyle="primary"/>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
TournamentConfigModal.propTypes = {
    tournament:PropTypes.object,
    isBowEntry:PropTypes.bool.isRequired,
    configCallback: PropTypes.func
};
module.exports = TournamentConfigModal;
