const {ParcoursStat} = require("parse/_Domain");
const Utility = require("util/Utility");

function ParcoursStatDic() {

    // actions: {
    //     clicked: "C",
    //         edit: "E",
    //         pay: "P",
    //         finishEvent: "F"
    // }
    // type: {
    //     android: "ANDR",
    //         iOS: "IOS",
    //         web: "WEB"
    // }

    this.wikiClicked = {};
    this.wikiEditClicked = {};
    this.payClicked = {};
    this.finishedEvents = {};
    this.finishedArchers = {};
    this.finishedTargets = {};

    this.addStat= function(parcoursStat) {
        if (parcoursStat.isActionClicked(ParcoursStat.prototype.actions.clicked)) {
            // wiki clicked
            this.handleDicTypeCounter(this.wikiClicked, parcoursStat, 1);
        } else if (parcoursStat.isActionClicked(ParcoursStat.prototype.actions.pay)) {
            this.handleDicTypeCounter(this.payClicked, parcoursStat, 1);
        } else if (parcoursStat.isActionClicked(ParcoursStat.prototype.actions.edit)) {
            this.handleDicTypeCounter(this.wikiEditClicked, parcoursStat, 1);
        } else if (parcoursStat.isActionClicked(ParcoursStat.prototype.actions.finishEvent)) {
            this.handleDicTypeCounter(this.finishedEvents, parcoursStat, 1);
            this.handleDicTypeCounter(this.finishedArchers, parcoursStat, parcoursStat.getNumber1());
            this.handleDicTypeCounter(this.finishedTargets, parcoursStat, parcoursStat.getNumber2());
        }

    };

    this.handleDicTypeCounter = function(dic, parcoursStat, addValue) {
        const type = parcoursStat.getDeviceType();
        const weekNumber = Utility.getWeek(parcoursStat.getCreatedAt());
        let weekCounter = dic[weekNumber];
        if (weekCounter == null) {
            weekCounter = {};
            weekCounter[ParcoursStat.prototype.type.android] = 0;
            weekCounter[ParcoursStat.prototype.type.iOS] = 0;
            weekCounter[ParcoursStat.prototype.type.web] = 0;
        }
        let deviceCounter = weekCounter[type];
        deviceCounter += addValue;
        weekCounter[type] = deviceCounter;
        dic[weekNumber] = weekCounter;
    };

    this.buildClickArray = function (clickDic) {
        const click = [];
        for (let i = 0; i < 52; i++) {
            let weekCounter = clickDic[i];
            if (weekCounter) {
                weekCounter.index = i+1;
                click[i] = weekCounter;
            } else {
                weekCounter = {};
                weekCounter.index = i+1;
                weekCounter[ParcoursStat.prototype.type.android] = 0;
                weekCounter[ParcoursStat.prototype.type.iOS] = 0;
                weekCounter[ParcoursStat.prototype.type.web] = 0;
                click[i] = weekCounter;
            }
        }
        return click;
    };


    this.getWikiClickArray = function() {
        return this.buildClickArray(this.wikiClicked);
    };
    this.getPayClickArray = function() {
        return this.buildClickArray(this.payClicked);
    };
    this.getWikiEditClickArray = function() {
        return this.buildClickArray(this.wikiEditClicked);
    };
    this.getFinishedEventsArray = function() {
        return this.buildClickArray(this.finishedEvents);
    };
    this.getFinishedEventsArchersArray = function() {
        return this.buildClickArray(this.finishedArchers);
    };
    this.getFinishedEventsTargetsArray = function() {
        return this.buildClickArray(this.finishedTargets);
    };
}
module.exports = ParcoursStatDic;