const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Modal, Alert, Button,Table} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    ValidInput = require("components/form/ValidInput");

const messages = require("i18n/messages");

const tournamentActions = require("actions/TournamentManagerActions");

class TournamentConfigManipulatorModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false
        }
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error:null});
    }
    open() {
        this.setState({showModal: true});
    }
    valid() {
        let valid = true;
        if (this.props.tConfigs != null) {
            const length = this.props.tConfigs.length;
            for (let i = 0; i < length; i++) {
                const required = this.refs[this.props.tConfigs[i].id];
                if (required == null || required.getValue() == null || required.getValue().length == 0) {
                    this.setState({error: messages.get("tournament.create.error.required")});
                    valid = false;
                    break;
                }
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        if (this.props.tConfigs != null) {
            const tConfigManipulatorDic = {};
            const length = this.props.tConfigs.length;
            for (let i = 0; i < length; i++) {
                const required = this.refs[this.props.tConfigs[i].id];
                if (required != null) {
                    tConfigManipulatorDic[this.props.tConfigs[i].id] = parseFloat(required.getValue().trim());
                }
            }
            this.props.tournamentRound.setBowConfigManipulator(tConfigManipulatorDic);
            tournamentActions.saveTournamentRound(this.props.tournamentRound);
        }
        this.close();
    }
    render() {
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter><I18n code="tournament.manager.tConfig.Manipulator.modal.title"/></TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        <Row>
                            {
                                this.state.showModal ?
                                    <Col sm={12}>
                                        <p>{messages.get("tournament.manager.tConfig.Manipulator.modal.desc")}</p>
                                        <br/>
                                        <Table style={{margin: "0"}}>
                                            <tbody>
                                                {
                                                    this.props.tConfigs != null ?
                                                        this.props.tConfigs.map(tConfig => {
                                                            return (<TConfigBowFactor ref={tConfig.id}
                                                                                      key={tConfig.id}
                                                                                      error={this.state.error}
                                                                                      tConfig={tConfig}
                                                                                      tournamentRound={this.props.tournamentRound}/>)
                                                        }) : null
                                                }
                                            </tbody>
                                        </Table>
                                    </Col> : null
                            }
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={this.state.submitDisabled} bsStyle="primary">{messages.get("modal.button.save")}</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
TournamentConfigManipulatorModal.propTypes = {
    tournamentRound:PropTypes.object.isRequired,
    tConfigs:PropTypes.array.isRequired
};
class TConfigBowFactor extends React.Component {
    constructor(props) {
        super(props);
        this.refFct = React.createRef();

        this.getDefaultFactor = this.getDefaultFactor.bind(this);
        this.getValue = this.getValue.bind(this);

        this.state = {}
    }
    getDefaultFactor() {
        let result = "1";
        const tConfigManipulatorDic = this.props.tournamentRound.getBowConfigManipulator();
        if (tConfigManipulatorDic != null) {
            result = tConfigManipulatorDic[this.props.tConfig.id].toString();
        }
        return result;
    }
    getValue() {
        return this.refFct.current.getValue()
    }
    render() {
        const validateNow = this.props.error && this.props.error.length > 0;
        return (
            <tr>
                <th>{this.props.tConfig.getCodeName()}</th>
                <td><ValidInput ref={this.refFct}
                                valid={{maxLength: 5, check:['isRequired', 'isNumber', 'point4comma']}}
                                validateNow={validateNow}
                                default={this.getDefaultFactor()}/>
                </td>
            </tr>
        )
    }
}
TConfigBowFactor.propTypes = {
    tConfig:PropTypes.object.isRequired,
    tournamentRound:PropTypes.object.isRequired
};
module.exports = TournamentConfigManipulatorModal;
