const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    SubmitButton = require("components/form/SubmitButton"),
    Loading = require("components/widgets/Loading"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    CCLogTable= require("components/payment/CCLogTable");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions");
const notificationActions = require("components/notification/NotificationActions");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentGroupLogModal = ({tUserGroup, selRound, buttonStyle}) =>  {
    return (
        <ModalBasic
            title={<TextCenter>Group log</TextCenter>}
            buttonStyle={buttonStyle}
            buttonChildren={<FontAwesome icon="file-alt"/>}>
            <TournamentGroup tUserGroup={tUserGroup} selRound={selRound}/>
        </ModalBasic>
    )
};
TournamentGroupLogModal.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    selRound: PropTypes.object.isRequired,
    buttonStyle: PropTypes.string.isRequired
};
class TournamentGroup extends React.Component {
    constructor(props) {
        super(props);
        this.clickedSave = this.clickedSave.bind(this);
        this.updateCCLogList = this.updateCCLogList.bind(this);
        this.reValidate = this.reValidate.bind(this);
        this.state = {
            loading: true,
            ccLogs: null
        }
    }
    componentDidMount() {
        //load email cclogs for sender
        tournamentUserManagerActions.loadTournamentGroupLogs(this.props.tUserGroup, ccLogs => {
            this.updateCCLogList(ccLogs)
        })
    }
    updateCCLogList(ccLogs) {
        this.setState({
            loading: false,
            ccLogs: ccLogs
        });
    }
    reValidate() {
        const {closeFct} = this.context;
        tournamentUserManagerActions.validateTournamentUserGroup(this.props.tUserGroup, this.props.selRound, result => {
            closeFct();
            console.log("validateTournamentUserGroup", result);
            notificationActions.warning("Function called - see log for result")
        })
    }
    clickedSave(e) {
        const {closeFct} = this.context;
        e.preventDefault();
        closeFct();
    }
    render() {
        const {loading, ccLogs} = this.state;
        const {selRound} = this.props
        return (
            <form action="#">
                <Row>
                    {
                        loading ? <Col xs={12}><Loading/></Col> : null
                    }
                    {
                        ccLogs != null ? <CCLogTable showTrace={true} ccLogs={ccLogs} showError={true}/> : null
                    }
                    {
                        selRound != null ? <Col xs={12}>
                            <SubmitButton bsStyle="danger" icon={<FontAwesome icon="exclamation-triangle"/>}
                                          onClick={this.reValidate}
                                          text={"GroupResult ReConfirmPoints: " + selRound.getName()}/>
                        </Col> : null
                    }

                    <Col xs={12}>
                        <Button onClick={this.clickedSave} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentGroup.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    selRound: PropTypes.object.isRequired
};
TournamentGroup.contextType = ModalBasicContext;
module.exports = TournamentGroupLogModal;
