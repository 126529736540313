const alt = require("AltInit");
const EventTrainingSortedList = require("util/EventTrainingSortedList");
const actions = require("actions/TrainingActions");
const calActions = require("actions/CalendarActions");
const PLUtil = require("util/ParseListUtility");

class TrainingStore {
    constructor() {
        this.displayName = "TrainingStore";
        this.eventTrainingList = null;
        this.eventTrainingComment = null;
        this.eventTrainingTarget = null;
        this.eventTrainingLoading = false;
        this.trainingTypes = null;
        this.trainingCountTypes = null;
        this.eventTrainingFilters = [];
        this.trainingTeams = [];
        this.trainingTeam = null;
        this.allTrainingTeamMembers = [];
        this.trainingTeamMembers = [];
        this.isTeamMember = false;
        this.remoteMember = null;
        this.trainingTeamResult = {};
        this.memberInvitations = [];
        this.loading = true;
        this.searchParams = {
            text: "",
            sortParams: {
                sortKey: null,
                sortDir: "asc"
            }
        }
        this.bindListeners({
            createEventList: actions.createEventList,
            addEventTrainings: actions.addEventTrainings,
            updateTrainingTypes: actions.updateTrainingTypes,
            updateTrainingCountTypes: actions.updateTrainingCountTypes,
            updateTrainingTeams: actions.updateTrainingTeams,
            updateTrainingTeam: actions.updateTrainingTeam,
            updateTeamInvitation: actions.updateTeamInvitation,
            updateTrainingTeamResult: actions.updateTrainingTeamResult,
            updateSearchParams: actions.updateSearchParams,
            removeTeamInvitation: actions.removeTeamInvitation,
            updateTeamMembers: actions.updateTeamMembers,
            updateTeamMember: actions.updateTeamMember,
            updateIsTeamMember: actions.updateIsTeamMember,
            updateRemoteMember: actions.updateRemoteMember,
            addTeamMember: actions.addTeamMember,
            updateTrainingEventComment: actions.updateTrainingEventComment,
            updateTrainingEventTarget: actions.updateTrainingEventTarget,
            updateTrainingDetailLoading: actions.updateTrainingDetailLoading,
            updateEventFilter: actions.updateEventFilter,
            updateTrainingFilters: actions.updateTrainingFilters,
            reset: actions.reset,
            resetTeam: actions.resetTeam
        });
    }

    createEventList(searchParams) {
        let year = searchParams.year != null ? searchParams.year : null
        this.eventTrainingList = new EventTrainingSortedList(year);
        if (searchParams.startDate != null && searchParams.endDate != null) {
            this.eventTrainingList.setTimeFrame(searchParams.startDate, searchParams.endDate)
        }
    }

    addEventTrainings(fEventTrainings) {
        if (fEventTrainings != null) {
            fEventTrainings.map(fEventTraining => {
                this.eventTrainingList.pushTrainingSorted(fEventTraining);
            });
        }
        this.eventTrainingLoading = false
        calActions.updateCalendarEvents.defer(this.eventTrainingList.getEventTrainingList())
    };

    updateTrainingEventComment(eventCommentDic) {
        this.eventTrainingComment = eventCommentDic;
        console.log("updateTrainingEventComment ", eventCommentDic)
        if (this.eventTrainingComment != null && this.eventTrainingTarget != null) {
            this.eventTrainingLoading = false
        }
    }

    updateTrainingEventTarget(eventTargetDic) {
        this.eventTrainingTarget = eventTargetDic;
        console.log("updateTrainingEventTarget ", eventTargetDic)
        if (this.eventTrainingComment != null && this.eventTrainingTarget != null) {
            this.eventTrainingLoading = false
        }
    }

    updateTrainingDetailLoading(loading) {
        this.eventTrainingLoading = loading
    }

    updateTrainingTeamResult({member, eventPlayerList}) {
        //console.log("Found Result " + member.id, eventPlayerList);
        this.trainingTeamResult[member.id] = eventPlayerList;
        member.arrow = eventPlayerList.getYearArrows();
        member.duration = eventPlayerList.getYearDuration();
    }

    updateTrainingTypes(types) {
        this.trainingTypes = types;
    };

    updateTrainingCountTypes(countTypes) {
        this.trainingCountTypes = countTypes
    }

    updateTrainingTeams(teams) {
        this.trainingTeams = teams;
    };

    updateTrainingTeam(team) {
        this.trainingTeam = team;
    };

    updateTeamMembers(members) {
        this.allTrainingTeamMembers = members;
        this.filterObjects();
    }

    updateSearchParams(searchParams) {
        this.searchParams = searchParams;
        this.filterObjects()
    }

    filterObjects() {
        let preFilteredList = this.allTrainingTeamMembers;
        let filteredList;
        if (this.searchParams.text.length > 0) {
            const searchString = this.searchParams.text.toLowerCase();
            filteredList = preFilteredList.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            filteredList = preFilteredList;
        }
        this.trainingTeamMembers = PLUtil.sortList(filteredList, this.searchParams.sortParams);
    }

    updateTeamMember(member) {
        this.updateTeamMembers(PLUtil.updateOrPush(this.allTrainingTeamMembers, member))
    }

    updateIsTeamMember(isMember) {
        this.isTeamMember = isMember
    }

    addTeamMember(member) {
        this.updateTeamMembers(this.allTrainingTeamMembers.concat(member));
    }

    updateRemoteMember(member) {
        this.remoteMember = member
    }

    updateTeamInvitation(invitations) {
        this.memberInvitations = invitations
    }

    removeTeamInvitation(memberInvitations) {
        this.updateTeamInvitation(
            this.memberInvitations.filter(function (ti) {
                return ti.id != memberInvitations.id;
            })
        );
    }

    updateEventFilter(eventFilter) {
        this.updateTrainingFilters(PLUtil.updateOrPush(this.eventTrainingFilters, eventFilter))
    }

    updateTrainingFilters(trainingFilters) {
        this.eventTrainingFilters = trainingFilters
    }

    reset() {
        this.loading = true;
        this.eventTrainingList = null;
        this.trainingTypes = null;
    }

    resetTeam() {
        this.trainingTeam = null;
        this.allTrainingTeamMembers = [];
        this.trainingTeamMembers = [];
        this.isTeamMember = false;
        this.remoteMember = null;
    }
}
module.exports = alt.createStore(TrainingStore);
