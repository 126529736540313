const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility"),
    ParseError = require("parse/ParseError"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TooltipButton  = require("components/form/TooltipButton"),
    ModalBasic = require ("components/modals/ModalBasic"),
    TournamentSexSelect = require("components/tournamentregister/TournamentSexSelect"),
    TournamentConfigAgeSelect = require("components/tournamentregister/TournamentConfigAgeSelect"),
    TournamentConfigBowSelect = require("components/tournamentregister/TournamentConfigBowSelect"),
    TournamentOptionSelect = require("components/tournamentregister/TournamentOptionSelect"),
    ValidInput = require("components/form/ValidInput");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");

class TournamentRegistrationUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.editTUser = this.editTUser.bind(this);
        this.refModal = React.createRef();
        this.state = {
            editTUser: null
        }
    }
    editTUser(tUser) {
        this.setState({editTUser: tUser});
        this.refModal.current.open();
    }
    render() {
        const {tournament} = this.props;
        const {editTUser} = this.state;
        return <ModalBasic ref={this.refModal} customButton={" "}
                           title={<TextCenter>{messages.get("tournament.mmtuser.tooltip.edit")}</TextCenter>}>
                <TournamentRegistrationUserForm tournament={tournament} bowUnion={tournament.getBowUnionID()}
                                                editTUser={editTUser}/>
            </ModalBasic>
    }
}
TournamentRegistrationUserModal.propTypes = {
    tournament: PropTypes.object.isRequired
};
class TournamentRegistrationUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.refSubmitButton = React.createRef();
        this.refUnion = React.createRef();
        this.refSex = React.createRef();
        this.refLicense = React.createRef();
        this.refBowSelect = React.createRef();
        this.refAgeSelect = React.createRef();

        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.configBowSelected = this.configBowSelected.bind(this);
        this.configAgeSelected = this.configAgeSelected.bind(this);
        this.configSexSelected = this.configSexSelected.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.updateEditTUser = this.updateEditTUser.bind(this);
        this.fillFormWithTournamentUser = this.fillFormWithTournamentUser.bind(this);
        let configBow = null;
        let configAge = null;
        let option = null;
        let sex = 0;
        if (this.props.editTUser) {
            // preSelect from user
            configBow = this.props.editTUser.getTournamentConfigBow();
            configAge = this.props.editTUser.getTournamentConfigAge();
            option = this.props.editTUser.getOption();
            sex = this.props.editTUser.getSex();
        } else {
            // preSelect first
            if (this.props.tournament.getTournamentConfigBow() != null && this.props.tournament.getTournamentConfigBow().length > 0) {
                configBow = this.props.tournament.getTournamentConfigBow()[0]
            }
            if (this.props.tournament.getTournamentConfigAge() != null && this.props.tournament.getTournamentConfigAge().length > 0) {
                configAge = this.props.tournament.getTournamentConfigAge()[0]
            }
            if (this.props.tournament.getTournamentOptionID() && this.props.tournament.getTournamentOptionID().hasOptions()) {
                option = this.props.tournament.getTournamentOptionID().getOptions()[0];
            }
        }
        this.state = {
            selectedBowType: configBow,
            selectedAgeType: configAge,
            selectedOption: option,
            sex: sex,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), configAge, configBow, option)
        }
    }
    componentDidMount() {
        if (this.props.editTUser != null) {
            this.fillFormWithTournamentUser(this.props.editTUser);
        }
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [];
        if (this.props.bowUnion != null && this.props.bowUnion.isLicenseNumberMandatory()) {
            required.push(this.refLicense.current.getValue())
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        if (this.state.selectedBowType === null || this.state.selectedAgeType === null) {
            setErrorFct({message: messages.get("tournament.create.error.required")});
            valid = false;
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, setErrorFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);

        this.updateEditTUser();
        tournamentRegisterModalActions.saveTournamentUser(this.props.editTUser, cbError => {
                if (cbError) {
                    setErrorFct({message: messages.get("parse.error.unexpected")  + ParseError.getTranslatedErrorMessage(cbError)});
                } else {
                    closeFct();
                }
        });
    }
    updateEditTUser() {
        const {editTUser} =  this.props
        editTUser.setSex(this.state.sex)
        editTUser.setTournamentConfigBow(this.state.selectedBowType);
        editTUser.setTournamentConfigAge(this.state.selectedAgeType);
        if (this.refUnion.current.getValue() && this.refUnion.current.getValue().length > 0) {
            editTUser.setUnion(this.refUnion.current.getValue().trim())
        }
        if (this.state.selectedOption != null) {
            editTUser.setOption(this.state.selectedOption)
        }
        if (this.refLicense.current && this.refLicense.current.getValue()) {
            editTUser.setLicenseNumber(this.refLicense.current.getValue().trim())
        }
        editTUser.setPayAmount(this.state.price)
    }
    fillFormWithTournamentUser(tUser) {
        console.log("fill form with tUser" + tUser.id);
        ReactDOM.findDOMNode(this.refSex.current).value = tUser.getSex();
        if (tUser.getUnion() != null) {
            this.refUnion.current.setValue(tUser.getUnion());
        }
        if (this.props.bowUnion != null && this.props.bowUnion.isLicenseNumberMandatory()) {
            if (tUser.getLicenseUnionCode() == this.props.bowUnion.getCode()) {
                this.refLicense.current.setValue(tUser.getLicenseNumber());
            }
        }
    }
    configSexSelected(sex) {
        this.setState({
            sex: sex
        })
    }
    configBowSelected(configBowType) {
        this.setState({
            selectedBowType: configBowType,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), this.state.selectedAgeType, configBowType,  this.state.selectedOption)})
    }
    configAgeSelected(configAgeType) {
        this.setState({
            selectedAgeType: configAgeType,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), configAgeType, this.state.selectedBowType, this.state.selectedOption)
        })
    }
    optionSelected(option) {
        this.setState({
            selectedOption: option,
            price: this.calcPrice(this.props.tournament.getTournamentPriceID(), this.state.selectedAgeType, this.state.selectedBowType, option)
        })
    }
    calcPrice(tPrice, ageType, bowType, option) {
        let sum = 0;
        if (tPrice != null && parseInt(tPrice.getPrice())) {
            sum += parseInt(tPrice.getPrice());
        }
        if (ageType) {
            if ( ageType.getPrice()) {
                sum += ageType.getPrice();
            }
            let tOptionObject = ageType.getTOptionSurcharge();
            if (tOptionObject && option) {
                if (tOptionObject[this.props.tournament.id + "_" + option]) {
                    sum += parseInt(tOptionObject[this.props.tournament.id + "_" + option])
                } else if (tOptionObject[option]) {
                    sum += parseInt(tOptionObject[option]) // for options without tournamentId
                }
            }
            if (tOptionObject && this.props.tournament.isTypeCupChild() && tOptionObject[this.props.tournament.id]) {
                sum += parseInt(tOptionObject[this.props.tournament.id])
            }
        }
        if (bowType && bowType.getPrice()) {
            sum += bowType.getPrice();
        }
        return sum;
    }
    render() {
        const {error, submitDisabled} = this.context;
        const validateNow = error && (error.message != null || error.length > 0);
        const {selectedBowType, selectedAgeType, selectedOption, price, sex} = this.state;
        const {tournament, bowUnion, editTUser} = this.props;
        const denyEditAgeClass = editTUser.isPaid();
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <b>{editTUser.getFullName()}</b>
                        <TournamentSexSelect inputRef={this.refSex} defaultValue={sex}
                                             selectCallback={this.configSexSelected} />

                        <TournamentConfigBowSelect label={messages.get("tournament.class.bow")} inputRef={this.refBowSelect}
                                                   defaultValue={selectedBowType ? selectedBowType.id : null}
                                                   tournament={tournament} selectCallback={this.configBowSelected}/>
                        <TournamentConfigAgeSelect label={messages.get("tournament.class.age")} inputRef={this.refAgeSelect}
                                                   defaultValue={selectedAgeType ? selectedAgeType.id : null} disabled={denyEditAgeClass}
                                                   tournament={tournament}  selectCallback={this.configAgeSelected}/>
                        {
                            tournament.getTournamentOptionID() != null ?
                                <TournamentOptionSelect tOption={tournament.getTournamentOptionID()}
                                                        tournament={tournament}
                                                        selectedAgeType={selectedAgeType}
                                                        defaultValue={selectedOption}
                                                        selectCallback={this.optionSelected} /> : null
                        }
                        <ValidInput ref={this.refUnion}
                                    valid={{maxLength: 40}}
                                    placeholder={messages.get("tournament.register.union")}
                                    addonBefore={<FontAwesome icon="university"/>}/>

                        {
                            bowUnion && bowUnion.isLicenseNumberMandatory() ?
                                <ValidInput ref={this.refLicense}
                                            valid={{maxLength: 30, check: ['isRequired']}}
                                            label={bowUnion.getName() + " #"}
                                            validateNow={validateNow}
                                            placeholder={messages.get("tournament.register.mandatory.license")}
                                            buttonAfter={bowUnion.isAutoLicenseNumber() ? <Button onClick={this.genCupNumber}><FontAwesome icon="plus"/>NEU</Button> : null}
                                            addonBefore={<FontAwesome icon="id-card"/>}
                                /> : null
                        }
                        <br/><label>{messages.get("tournament.register.label.price") + ": "} </label>{price}
                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"tournament.register.submit"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentRegistrationUserForm.propTypes = {
    tournament:PropTypes.object.isRequired,
    bowUnion:PropTypes.object.isRequired,
    editTUser: PropTypes.object
};
TournamentRegistrationUserForm.contextType = ModalBasicContext;
module.exports = TournamentRegistrationUserModal;
