const React = require("react"),
    linkUtil = require("linkUtil");
const {Row, Col,Grid, Image} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    Title = require("components/widgets/Title");

const messages = require("i18n/messages");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ContactPage = () => {
    document.title = messages.get("header.nav.contact");
    return (
        <Grid>
            <Row>
                <Col xs={12} xsHidden>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/contact" code="header.nav.contact" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Title code="header.nav.contact"/>
            <Row>
                <Col md={6}>


                    <address>
                        <strong>Bowsport Software Solutions GmbH</strong><br/>
                        Plattnerstraße 8<br/>
                        A-4400 Steyr<br/>
                    </address>

                    <h3>Team</h3>
                    <p> Daniel Peterseil, <br/>
                        Bernhard Haba, <br/>
                        Patrick Haruksteiner<br/>
                    </p>
                    <p><FontAwesome icon="envelope" size={"2x"}/><a href="mailto:office@3dturnier.com">office@3dturnier.com</a><br/>
                        <a href="https://t.me/SKillBoardSupport"><FontAwesome icon={["fab", "telegram"]} size={"2x"}/>Telegram Support Group</a><br/>
                        <a href="https://www.linkedin.com/pub/peterseil-daniel/2/50a/a85"><FontAwesome icon={["fab", "linkedin"]} size={"2x"}/>Facebook</a>
                    </p>
                </Col>
                <Col md={6}>
                    <Image responsive src="/img/slide/SKillBoardTeam.jpg" rounded />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col xs={12}>
                    <h3>{messages.get("contact.form.header")}</h3>
                    <iframe title="Feedback Form" className="freshwidget-embedded-form"
                            id="freshwidget-embedded-form"
                            src="https://3dturnier.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=&submitTitle=Senden&screenshot=Nein"
                            scrolling="no" height="400px" width="100%" frameBorder="0">
                    </iframe>
                </Col>
            </Row>
        </Grid>)
};
module.exports = ContactPage;