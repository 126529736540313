//const Keys = require("./../key.js");

const ConfigKeys = {
    payVersion: "2.0.0",
    tournamentVersion: "3.0",
    isProd:  Keys.env == "P" || Keys.env == "S",
    SKillBoardClubId: Keys.env == "P" || Keys.env == "S" ? "peEuaFGXXi" : "jyXh75daa3",
    SKillBoardOwnerId: Keys.env == "P" || Keys.env == "S" ? "XTOXsz9dW8" : "ksjptSnIkM",
    BSSMgmtId: Keys.env == "P" || Keys.env == "S" ? "Y7dnjBhfHk" : "EH2usOFiJl",
    // backendUrl:'http://localhost:1337/1/',
    backendUrl:  Keys.backendUrl,
    appId: Keys.appId,
    appKey: Keys.appKey,
    domainUrl: Keys.domainUrl,
    env: Keys.env
};
module.exports = ConfigKeys;