/**
 * Created by Daniel on 25.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Table,Panel, PageHeader, Tabs, Tab} = require("react-bootstrap");
const Loading = require("components/widgets/Loading"),
    AutoScrollButton = require("components/widgets/AutoScrollButton"),
    TextBlur = require("components/widgets/TextBlur"),
    messages = require("i18n/messages");

const TournamentResultGrouping = require("components/tournament/TournamentResultGrouping");
const tournamentLiveStore = require("stores/TournamentLiveStore"),
    tournamentLiveActions= require("actions/TournamentLiveActions"),
    tournamentUserActions = require("actions/TournamentUserActions"),
    tournamentResultActions = require("actions/TournamentResultActions");
const notificationActions = require("components/notification/NotificationActions");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class TournamentLivePage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);

        this.state = {
            tournamentLiveStore: tournamentLiveStore.getState()
        }
    }
    componentDidMount() {
        tournamentLiveStore.listen(this.handleTournamentChange);
        notificationActions.reset();
        tournamentLiveActions.showLiveDetails(this.props.tournamentId);
    }
    componentWillUnmount() {
        console.log("componentWillUnmount")
        tournamentLiveActions.resetTournamentLiveResults();
        tournamentResultActions.resetTournamentResults();
        tournamentLiveStore.unlisten(this.handleTournamentChange);
    }
    handleTournamentChange(state) {
        this.setState({tournamentLiveStore: state});
        if (state.tournamentDetail) {
            document.title = "LIVE " + state.tournamentDetail.getName();
        }
    }
    render() {
        if (this.state.tournamentLiveStore.tournamentDetail == null || this.state.tournamentLiveStore.loading) {
            return (<Grid>
                <Row>
                    <Col lg={12}><Loading/></Col>
                </Row>
            </Grid>)
        }
        return (<TournamentLiveDetails
            tournament={this.state.tournamentLiveStore.tournamentDetail}
            tournamentRounds={this.state.tournamentLiveStore.tournamentRounds}
            tournamentGroups= {this.state.tournamentLiveStore.tournamentLiveGroups}
            tournamentUserResult= {this.state.tournamentLiveStore.tournamentUserResult}
        />)

    }
}
const TournamentLiveDetails = ({tournament, tournamentRounds, tournamentGroups, tournamentUserResult}) => {
    let foundRound = 0;
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                        <Breadcrumb link={"/tournament/" + tournament.id} code={tournament.getName()}/>
                        <Breadcrumb link="#" code="Live" active/>
                    </Breadcrumbs>
                </Col>
                <Col lg={12}>
                    <PageHeader>{tournament.getName()}</PageHeader>
                </Col>
            </Row>
            <Tabs defaultActiveKey={tournamentRounds != null ? tournamentRounds[0].id : 1} id="LiveTRounds">
            {
                tournamentRounds.map(tRound => {
                    if (tRound.isRunning()) {
                        foundRound++;
                        return <Tab eventKey={tRound.id} key={tRound.id} title={tRound.getName()}>
                            <TournamentLiveRound tournament={tournament}
                                                 tournamentRound={tRound}
                                                 tournamentGroups={tournamentGroups[tRound.id]}
                                                 tournamentUserResult= {tournamentUserResult[tRound.id]}/>
                        </Tab>
                    }
                })
            }
            </Tabs>
            {
                foundRound > 0 ? "" : <Alert>{messages.get("tournament.live.noStartedRounds")}</Alert>
            }
        </Grid>)
};
TournamentLiveDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentGroups: PropTypes.object,
    tournamentRounds: PropTypes.array,
    tournamentUserResult: PropTypes.object
};
class TournamentLiveRound extends React.Component {
    constructor(props) {
        super(props);
        this.reload = this.reload.bind(this);

        this.state = {}
    }
    componentDidMount() {
        tournamentLiveActions.loadTournamentGroupResult.defer(this.props.tournament, this.props.tournamentRound);
    }
    reload() {
        tournamentLiveActions.loadTournamentGroupResult(this.props.tournament, this.props.tournamentRound);
    }
    render() {
        const {tournament, tournamentRound, tournamentGroups, tournamentUserResult} = this.props;
        return (
            <React.Fragment>
                <AutoScrollButton scrollInterval={5000} xScroll={0} yScroll={100} reloadCallback={this.reload}/>
                <Row>
                    <Col lg={12}>
                        <h3>{messages.get("tournament.live.tuserin") + " " + tournamentRound.getName()}</h3>
                        {
                            tournamentUserResult == null ? "" :
                                <TournamentResultGrouping showHighestZoneCounter={false}
                                    liveModus={true}
                                    tournament={tournament}
                                    tournamentRoundMap={{qualify: [tournamentRound], finale: [], group: [], extra: []}}
                                    tournamentUserResult={tournamentUserResult}
                                />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <h3>{messages.get("tournament.live.groupin") + " " + tournamentRound.getName()}</h3>
                    </Col>
                    {
                        tournamentGroups && tournamentGroups.length > 0 ?
                            tournamentGroups.map(group => {
                                return <TournamentLiveGroup key={group.id} group={group} hidePoints={tournamentRound.isHidePoints()}/>
                            })
                            :
                            <Col lg={12}>
                                <Alert>{messages.get("tournament.live.empty") + " " + tournamentRound.getName()}</Alert>
                            </Col>
                    }
                </Row>
            </React.Fragment>
        )
    }
}
TournamentLiveRound.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentRound :PropTypes.object.isRequired,
    tournamentGroups: PropTypes.array,
    tournamentUserResult: PropTypes.array
};
const TournamentLiveGroup = ({group, hidePoints}) => {
    let headerText;
    if (group.getActTargetPosition() != null && group.getActTargetNumber() != null) {
        headerText = messages.get("Frag_Prep_startposition") + ": " + group.getStartPosition() + "; " +
            messages.get("tournament.table.column.target") + ": " + group.getActTargetPosition() + " - " +
            messages.get("tournament.table.column.amount") + ": " + group.getActTargetNumber();
    } else if (group.isStarted()) {
        headerText = messages.get("tournament.live.groupStarted") + " " + group.getStartPosition();
    } else {
        headerText = messages.get("tournament.live.noGroupInfo")  + " " + group.getStartPosition();
    }
    return (
        <Col sm={3}>
            <Panel>
                <Panel.Heading>{headerText}</Panel.Heading>
                <Panel.Body>
                    <Table fill>
                        <tbody>
                        {
                            group.getTournamentUserIDs().map(tUser => {
                                return (<tr key={"TLGR_" + tUser.id}>
                                    <td>{tUser.getFullName()}</td>
                                    <td><TextBlur blur={hidePoints} text={group.getTournamentUserPoints(tUser.id)}/></td>
                                </tr>)
                            })
                        }
                        </tbody>
                    </Table>
                </Panel.Body>
            </Panel>
        </Col>
    )
};
TournamentLiveGroup.propTypes = {
    group:PropTypes.object.isRequired,
    hidePoints: PropTypes.bool
};
module.exports = TournamentLivePage;