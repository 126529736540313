const Parse = require("ParseInit");

const {Event, EventPlayer, EventTrack, EventComment, EventTarget, Parcours, UserBowArrowProfile,
    EventTraining, EventTrainingMember, EventTrainingTypes, EventTrainingTeams, EventTrainingFilter} = require("parse/_Domain");

class EventDao {

    queryEvent(eventId) {
        const epQuery = new Parse.Query(Event);
        epQuery.equalTo(Event.prototype.col.objectId, eventId);
        return epQuery.first();
    }

    queryEventList(userMail, countTypeOnlineID) {
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.equalTo(EventPlayer.prototype.col.playerEmail, userMail);
        if (countTypeOnlineID) {
            epQuery.equalTo(EventPlayer.prototype.col.countTypeOnlineID, countTypeOnlineID);
        }
        epQuery.exists(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.bowArrowProfileID)
        epQuery.limit(1000);
        epQuery.descending(EventPlayer.prototype.col.createdAt);
        return epQuery.find();
    }

    queryEventPlayerForTrainingList(userMail, year) {
        const startDate = new Date(year, 0 ,1, 0, 0, 0, 0);
        const endDate = new Date(year, 11 ,31,23,59,59,0);
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.greaterThan(EventPlayer.prototype.col.createdAt, startDate);
        epQuery.lessThan(EventPlayer.prototype.col.createdAt, endDate);
        epQuery.notEqualTo(EventPlayer.prototype.col.status, EventPlayer.prototype.status.deleted);
        epQuery.equalTo(EventPlayer.prototype.col.playerEmail, userMail);
        epQuery.exists(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.bowArrowProfileID);
        epQuery.limit(1000);
        epQuery.descending(EventPlayer.prototype.col.createdAt);
        return epQuery.find();
    }

    queryEventPlayerForTrainingListSearchParams(userMail, searchParams) {
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.greaterThan(EventPlayer.prototype.col.createdAt, searchParams.startDate);
        epQuery.lessThan(EventPlayer.prototype.col.createdAt, searchParams. endDate);
        epQuery.notEqualTo(EventPlayer.prototype.col.status, EventPlayer.prototype.status.deleted);
        epQuery.equalTo(EventPlayer.prototype.col.playerEmail, userMail);
        epQuery.exists(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.eventID);
        if (searchParams.parcours != null) {
            epQuery.include(EventPlayer.prototype.col.eventID + "." + Event.prototype.col.parcoursID);
            epQuery.include(EventPlayer.prototype.col.eventID + "." + Event.prototype.col.parcoursID + "." + Parcours.prototype.col.parcoursOwnerID);
        }
        epQuery.include(EventPlayer.prototype.col.bowArrowProfileID);
        epQuery.include(EventPlayer.prototype.col.eventResults);
        epQuery.limit(1000);
        epQuery.descending(EventPlayer.prototype.col.createdAt);
        return epQuery.find();
    }

    queryEventTrainingList(user, year, hidePrivate) {
        const startDate = new Date(year, 0 ,1, 0, 0, 0, 0);
        const endDate = new Date(year, 11 ,31,23,59,59,0);
        const etQuery = new Parse.Query(EventTraining);
        etQuery.greaterThan(EventTraining.prototype.col.date, startDate);
        etQuery.lessThan(EventTraining.prototype.col.date, endDate);
        etQuery.notEqualTo(EventTraining.prototype.col.status, EventTraining.prototype.status.deleted);
        etQuery.equalTo(EventTraining.prototype.col.userID, user);
        etQuery.include(EventTraining.prototype.col.mainTrainingType);
        etQuery.include(EventTraining.prototype.col.subTrainingType);
        etQuery.limit(2000);
        etQuery.descending(EventTraining.prototype.col.createdAt);
        if (hidePrivate) {
            // do not add events where private main type is used
            const ettQuery = new Parse.Query(EventTrainingTypes);
            ettQuery.notEqualTo(EventTrainingTypes.prototype.col.status, EventTrainingTypes.prototype.status.deleted);
            ettQuery.equalTo(EventTrainingTypes.prototype.col.isPrivate, true);
            etQuery.doesNotMatchQuery(EventTraining.prototype.col.mainTrainingType, ettQuery)
        }
        return etQuery.find();
    }

    queryEventForParcoursList(parcoursOnlineID) {
        const epQuery = new Parse.Query(Event);
        epQuery.equalTo(Event.prototype.col.parcoursOnlineID, parcoursOnlineID);
        epQuery.limit(1000);
        epQuery.descending(Event.prototype.col.createdAt);
        return epQuery.find();
    }


    queryEventAmount(parcoursOnlineID) {
        const epQuery = new Parse.Query(Event);
        epQuery.equalTo(Event.prototype.col.parcoursOnlineID, parcoursOnlineID);
        epQuery.notEqualTo(Event.prototype.col.status, Event.prototype.status.deleted);
        return epQuery.count();
    }

    findEventToGroup(tUserGroup, tRound, user) {
        const evQuery = new Parse.Query(Event);
        evQuery.equalTo(Event.prototype.col.tournamentGroupID, tUserGroup);
        evQuery.equalTo(Event.prototype.col.tournamentRoundID, tRound);
        evQuery.equalTo(Event.prototype.col.editor, user);
        return evQuery.find();
    }

    queryYearEventList(userMail) {
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.equalTo(EventPlayer.prototype.col.playerEmail, userMail);
        epQuery.notEqualTo(EventPlayer.prototype.col.status, EventPlayer.prototype.status.deleted);
        epQuery.greaterThanOrEqualTo(EventPlayer.prototype.col.sumPoints, 0);
        epQuery.exists(EventPlayer.prototype.col.eventResults);
        epQuery.exists(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.eventID);
        epQuery.limit(1000);
        epQuery.descending(EventPlayer.prototype.col.createdAt);
        return epQuery.find();
    }

    queryRankingOfParcours(parcoursOnlineID, month, year) {
        const startDate = new Date(year, month-1 ,1);
        const endDate = new Date(year, month ,0,23,59,59,0);
        const evQuery = new Parse.Query(Event);
        evQuery.equalTo(Event.prototype.col.parcoursOnlineID, parcoursOnlineID);
        evQuery.notEqualTo(Event.prototype.col.status, Event.prototype.status.deleted);
        evQuery.containedIn(Event.prototype.col.type, [Event.prototype.eventType.normal, Event.prototype.eventType.smart]);
        evQuery.greaterThan(Event.prototype.col.insstmp, startDate.getTime());
        evQuery.lessThan(Event.prototype.col.insstmp, endDate.getTime());
        evQuery.limit(1000);
        const query = new Parse.Query(EventPlayer);
        query.exists(EventPlayer.prototype.col.eventID);
        query.include(EventPlayer.prototype.col.eventID);
            // TODO greater zero
        query.exists(EventPlayer.prototype.col.sumPoints);
        query.exists(EventPlayer.prototype.col.countTypeOnlineID);
        query.exists(EventPlayer.prototype.col.playerEmail);
        query.notEqualTo(EventPlayer.prototype.col.status, EventPlayer.prototype.status.deleted);
        query.notEqualTo(EventPlayer.prototype.col.visStatus,EventPlayer.prototype.visible.invisible);
        query.descending(EventPlayer.prototype.col.sumPoints + "," + EventPlayer.prototype.col.killValue);
        query.matchesQuery(EventPlayer.prototype.col.eventID, evQuery);
        query.limit(1000);
        return query.find();
    }

    queryEventTracksParcours(parcoursOnlineID, month, year) {
        const startDate = new Date(year, month-1 ,1);
        const endDate = new Date(year, month ,0,23,59,59,0);
        const evQuery = new Parse.Query(Event);
        evQuery.equalTo(Event.prototype.col.parcoursOnlineID, parcoursOnlineID);
        evQuery.notEqualTo(Event.prototype.col.status, Event.prototype.status.deleted);
        evQuery.greaterThan(Event.prototype.col.insstmp, startDate.getTime());
        evQuery.lessThan(Event.prototype.col.insstmp, endDate.getTime());
        evQuery.exists(Event.prototype.col.trackID);
        evQuery.include(Event.prototype.col.trackID);
        evQuery.limit(1000);
        evQuery.descending(Event.prototype.col.insstmp);
        return evQuery.find();
    }

    queryEventPlayerDetail(event) {
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.equalTo(EventPlayer.prototype.col.eventID, event);
        epQuery.include(EventPlayer.prototype.col.eventResults);
        epQuery.include(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.countTypeID);
        epQuery.include(EventPlayer.prototype.col.bowArrowProfileID)
        epQuery.limit(100);
        epQuery.descending(EventPlayer.prototype.col.sumPoints + "," + EventPlayer.prototype.col.killValue);
        return epQuery.find();
    }

    queryEventComment(event, mail) {
        const ecQuery = new Parse.Query(EventComment);
        ecQuery.equalTo(EventComment.prototype.col.eventID, event);
        if (mail) {
            ecQuery.equalTo(EventComment.prototype.col.playerEmail, mail);
        }
        ecQuery.notEqualTo(EventComment.prototype.col.status, EventComment.prototype.status.deleted);
        ecQuery.ascending(EventComment.prototype.col.targetIndex);
        return ecQuery.find();
    }

    queryEventTarget(event) {
        const ecQuery = new Parse.Query(EventTarget);
        ecQuery.equalTo(EventTarget.prototype.col.eventID, event);
        ecQuery.notEqualTo(EventTarget.prototype.col.status, EventTarget.prototype.status.deleted);
        ecQuery.ascending(EventTarget.prototype.col.targetIndex);
        return ecQuery.find();
    }

    queryBowArrowProfiles(user) {
        const baQuery = new Parse.Query(UserBowArrowProfile)
        baQuery.notEqualTo(UserBowArrowProfile.prototype.col.status, "D");
        baQuery.equalTo(UserBowArrowProfile.prototype.col.userID, user);
        baQuery.ascending(UserBowArrowProfile.prototype.col.name);
        return baQuery.find();
    }

    queryEventTrainingTypes() {
        const ettQuery = new Parse.Query(EventTrainingTypes);
        ettQuery.notEqualTo(EventTrainingTypes.prototype.col.status, EventTrainingTypes.prototype.status.deleted);
        ettQuery.ascending(EventTrainingTypes.prototype.col.index);
        return ettQuery.find();
    }

    queryMyTrainingFilters(user) {
        const queries = [];
        const mainEditorQuery = new Parse.Query(EventTrainingFilter);
        mainEditorQuery.equalTo(EventTrainingFilter.prototype.col.mainEditor, user);
        mainEditorQuery.notEqualTo(EventTrainingFilter.prototype.col.status, "D");
        queries.push(mainEditorQuery);
        const addEditor = new Parse.Query(EventTrainingFilter);
        addEditor.equalTo(EventTrainingFilter.prototype.col.addEditor, user);
        addEditor.notEqualTo(EventTrainingFilter.prototype.col.status, "D");
        queries.push(addEditor);

        let superQuery = Parse.Query.or.apply(Parse.Query, queries);
        superQuery.ascending(EventTrainingFilter.prototype.col.name);
        return superQuery.find()
    }

    queryMyTrainingTeams(user) {
        const queries = [];
        const mainEditorQuery = new Parse.Query(EventTrainingTeams);
        mainEditorQuery.equalTo(EventTrainingTeams.prototype.col.mainEditor, user);
        mainEditorQuery.notEqualTo(EventTrainingTeams.prototype.col.status, "D");
        queries.push(mainEditorQuery);
        const addEditor = new Parse.Query(EventTrainingTeams);
        addEditor.equalTo(EventTrainingTeams.prototype.col.addEditor, user);
        addEditor.notEqualTo(EventTrainingTeams.prototype.col.status, "D");
        queries.push(addEditor);

        // all user visible Teams where my user has accepted
        const etmQuery = new Parse.Query(EventTrainingMember);
        etmQuery.notEqualTo(EventTrainingMember.prototype.col.status, "D");
        etmQuery.equalTo(EventTrainingMember.prototype.col.userID, user);
        const memberQuery = new Parse.Query(EventTrainingTeams);
        memberQuery.equalTo(EventTrainingTeams.prototype.col.userVisible, true);
        memberQuery.notEqualTo(EventTrainingTeams.prototype.col.status, "D");
        memberQuery.matchesKeyInQuery("objectId", "trainingTeamID.objectId", etmQuery);

        queries.push(memberQuery);

        let superQuery = Parse.Query.or.apply(Parse.Query, queries);
        superQuery.ascending(EventTrainingTeams.prototype.col.name);
        return superQuery.find()
    }

    queryMyTrainingTeamInvitations(user) {
        const etmQuery = new Parse.Query(EventTrainingMember);
        etmQuery.equalTo(EventTrainingMember.prototype.col.status, EventTrainingMember.prototype.status.invited);
        etmQuery.equalTo(EventTrainingMember.prototype.col.userID, user);
        etmQuery.include(EventTrainingMember.prototype.col.trainingTeamID);
        return etmQuery.find()
    }

    queryTrainingTeam(teamID) {
        const ettQuery = new Parse.Query(EventTrainingTeams);
        ettQuery.include(EventTrainingTeams.prototype.col.mainEditor);
        ettQuery.include(EventTrainingTeams.prototype.col.addEditor);
        return ettQuery.get(teamID)
    }

    queryTrainingTeamMembers(trainingTeam) {
        const etmQuery = new Parse.Query(EventTrainingMember);
        etmQuery.equalTo(EventTrainingMember.prototype.col.trainingTeamID, trainingTeam);
        etmQuery.notEqualTo(EventTrainingMember.prototype.col.status, "D");
        etmQuery.ascending(EventTrainingMember.prototype.col.name);
        return etmQuery.find()
    }

    queryTrainingTeamMember(memberID) {
        const etmQuery = new Parse.Query(EventTrainingMember);
        etmQuery.include(EventTrainingMember.prototype.col.trainingTeamID);
        return etmQuery.get(memberID)
    }

    queryEventPlayerMailValidation(event, userMail) {
        const epQuery = new Parse.Query(EventPlayer);
        epQuery.equalTo(EventPlayer.prototype.col.eventID, event);
        epQuery.equalTo(EventPlayer.prototype.col.playerEmail, userMail);
        return epQuery.find();
    }

    queryEventPlayer4SmartTournament(eventID) {
        const query = new Parse.Query(EventPlayer);
        query.notEqualTo(EventPlayer.prototype.col.status, EventPlayer.prototype.status.deleted);
        query.equalTo(EventPlayer.prototype.col.eventStringID, eventID);
        query.include(EventPlayer.prototype.col.eventID);
        query.descending(EventPlayer.prototype.col.countTypeOnlineID + "," + EventPlayer.prototype.col.sumPoints + "," + EventPlayer.prototype.col.killValue);
        return query.find();
    }

    queryEventTrack(trackID) {
        const etQuery = new Parse.Query(EventTrack);
        return etQuery.get(trackID);
    }

    reduceTrack(eventTrackId, limit) {
        return Parse.Cloud.run('reduceEventTrack', {objectId: eventTrackId , limit: limit});
    }

    updateEventStatus(event, status) {
        return Parse.Cloud.run('updateEventStatus', {eventID: event.id, status: status});
    }

    updateEventPlayerStatus(eventPlayer, status) {
        return Parse.Cloud.run('updateEventPlayerStatus', {eventPlayerID: eventPlayer.id, status: status});
    }

}

module.exports = new EventDao();
