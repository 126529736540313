const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require ('react-router-dom');
const linkUtil = require("linkUtil");

const TransactionDetailLink = ({transactionID, status, label}) => {
    let stat = "";
    if (status == "ACK") {
        stat = "/checkoutSuccess"
    } else if (status == "NOK") {
        stat = "/checkoutError"
    }
    return <Link to={linkUtil.getLink("transaction/" + transactionID + stat)}>{label ? label : transactionID} </Link>
};
TransactionDetailLink.propTypes = {
    transactionID:PropTypes.string.isRequired,
    status:PropTypes.string,
    label:PropTypes.string
};
module.exports = TransactionDetailLink;
