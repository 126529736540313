const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Table, Panel, Button, FormGroup, FormControl} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    LoadingCircle = require("components/widgets/LoadingCircle"),
    DelayedTextField = require("components/form/DelayedTextField"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    ParseListUtility = require("util/ParseListUtility"),
    SelectInputTypeAhead  = require("components/form/SelectInputTypeAhead");

const messages = require("i18n/messages");

const tableStore = require("stores/TableStore"),
    tableAction = require("actions/TableActions");

class SearchTable extends React.Component {
    constructor(props) {
        super(props);
        this.handleTableChange = this.handleTableChange.bind(this);
        this.startSearching = this.startSearching.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.state = {
            tableStore: tableStore.getState
        }
    }
    componentDidMount() {
        tableStore.listen(this.handleTableChange);
        if (this.props.searchFieldTypeAhead != null) {
            tableAction.updateSearchField.defer(this.props.searchFieldTypeAhead)
        }
    }
    componentWillUnmount() {
        tableStore.unlisten(this.handleTableChange);
    }
    handleTableChange(state) {
        this.setState({tableStore: state});
    }
    startSearching(searchParams) {
        tableAction.setSearching(true);
        if (this.props.custSearchFunction) {
            this.props.custSearchFunction(searchParams, this.state.tableStore.allObjectList);
        } else {
            tableAction.searchObject.defer(searchParams)
        }
    }
    onClickRow(event, entry) {
        // handle selected entries
        let selArray;
        if ( event.ctrlKey === true  || event.metaKey === true) {
            // add
            selArray = this.state.tableStore.selectedObjectList;
            // if entry is not in list - add; else remove
            if (ParseListUtility.contains(selArray, entry)) {
                // remove
                selArray = ParseListUtility.removeObj(selArray, entry);
            } else {
                // add it
                selArray.push(entry);
            }
        } else {
            selArray = [];
            selArray.push(entry);
        }
        tableAction.updateSelectedObjectList(selArray);
        // optional cust handling
        if (this.props.tRowClicked) {
            this.props.tRowClicked(event, entry, selArray);
        }
    }
    render() {
        const {allObjectList, objectList, selectedObjectList, searchParams, pageStart, pageSize, pageSizeArray} = this.state.tableStore;
        const {custHeaderLeftFunction, custFilterFunction, tHead, tRow, topTableArea} = this.props;
        if (objectList && searchParams) {
            let index = 0;
            const minIndex = pageStart * pageSize;
            const maxIndex = (pageStart + 1 ) * pageSize;
            const maxPageAmount = parseInt(objectList.length / pageSize);
            console.log("Show from " + minIndex +  " to  " + maxIndex +  " page " + pageStart + " of " + maxPageAmount);
            return (
                <Row>
                    <Col xs={12}>
                        <Panel>
                            <Panel.Heading>
                                <TableHeader allObjectList={allObjectList}
                                             custHeaderLeftFunction={custHeaderLeftFunction}
                                             custFilterFunction={custFilterFunction}
                                             searchParams={searchParams}
                                             startSearching={this.startSearching}/>
                            </Panel.Heading>
                            <Panel.Body style={{paddingTop: "0px"}}>
                                {topTableArea}
                                <Table striped responsive hover>
                                    {
                                        tHead ? <ErrorBoundary>{tHead}</ErrorBoundary> : <p>THEAD PROP MISSING</p>
                                    }
                                    <tbody>
                                    {
                                        objectList.map(listEntry => {
                                            const entry = tRow(listEntry);
                                            if (entry != null) {
                                                if (index >= minIndex && index < maxIndex) {
                                                    index++;
                                                    let bgColor = ParseListUtility.contains(selectedObjectList, listEntry) === true ? "lightgrey" : "transparent";
                                                    return <ErrorBoundary identifier={"SearchTable objectId:" + listEntry.id} key={listEntry.id}>
                                                        <tr style={{backgroundColor: bgColor}}
                                                            onClick={(event) => this.onClickRow(event, listEntry)}>
                                                            {entry}
                                                        </tr>
                                                    </ErrorBoundary>
                                                } else {
                                                    index++;
                                                }
                                            }
                                        })
                                    }
                                    </tbody>
                                </Table>
                            </Panel.Body>
                            <Panel.Footer>
                                <TablePaging actPage={pageStart}
                                             pageSizeArray={pageSizeArray}
                                             maxPageAmount={maxPageAmount}/>
                            </Panel.Footer>
                        </Panel>
                    </Col>
                </Row>)
        } else {
            return <Loading/>
        }
    }
}
SearchTable.propTypes = {
    tHead:PropTypes.object.isRequired,
    tRow:PropTypes.func.isRequired,
    tRowClicked: PropTypes.func,
    custHeaderLeftFunction:PropTypes.func,
    custSearchFunction:PropTypes.func,
    custFilterFunction: PropTypes.func,
    searchFieldTypeAhead: PropTypes.bool,
    topTableArea: PropTypes.any
};

const TablePaging = ({actPage, pageSizeArray, maxPageAmount}) =>{
    const handlePreviousPage = () =>{
        tableAction.updateTablePage(-1);
    };
    const handleNextPage = () =>{
        tableAction.updateTablePage(1);
    };
    const selectPage = (pageNumber) => {
        tableAction.selectTablePage(pageNumber);
    };
    const handleChange = (event) => {
        const pageSize = event.currentTarget.selectedOptions[0].value;
        tableAction.updateTablePageSize(pageSize)
    };
    const buildPageNumberArray = () => {
        if (maxPageAmount > 4 && maxPageAmount < 10) {
            let pnArray = [];
            for (let i = 0; i < maxPageAmount; i++) {
                pnArray.push(i);
            }
            pnArray.push(maxPageAmount);
            return pnArray;
        }
        if (maxPageAmount > 9) {
            let pnArray = [];
            let start = actPage - 3;
            let end = actPage + 4;
            for (let i = start; i < end; i++) {
                if (i > 0 && i < maxPageAmount) {
                    pnArray.push(i);
                } else if (i <= 0) {
                    // add first not shown page numbers to the end
                    end++;
                } else if (i >= maxPageAmount) {
                    // add last not shown page to the beginning
                    pnArray.splice(0,0, i - 7);
                }
            }
            // add first element
            pnArray.splice(0,0, 0);
            // add last element
            pnArray.push(maxPageAmount);
            return pnArray;
        }
        return null;
    };
    const pageNumbers = buildPageNumberArray();
    return (<Row>
        <Col xs={2} sm={1}>
            <Button bsStyle="primary"
                disabled={actPage <= 0}
                onClick={handlePreviousPage}><FontAwesome icon="arrow-left" />

            </Button>
        </Col>
        <Col xs={4} sm={1}>
            <FormGroup controlId="fcPageSize">
                <FormControl componentClass="select"  onChange={handleChange}>
                    {
                        pageSizeArray.map(pageSize => {
                            return <option key={"ps" + pageSize} value={pageSize}>{pageSize}</option>
                        })
                    }
                </FormControl>
            </FormGroup>
        </Col>
        <Col xs={2} xsOffset={4} sm={1} smOffset={1} smPush={8}>
            <Button bsStyle="primary"
                    disabled={actPage >= maxPageAmount}
                    onClick={handleNextPage}>
                <FontAwesome icon="arrow-right" /></Button>
        </Col>
        <Col xs={12} sm={8} smPull={2} className="text-center">
            {
                pageNumbers ?
                    <p>
                        {
                            pageNumbers.map(number => {
                                return <TablePageNumber key={"TPN" + number} number={number} actPage={actPage} selectPage={selectPage}/>
                            })
                        }
                    </p> : null
            }
        </Col>
    </Row>)
};
TablePaging.propTypes = {
    actPage:PropTypes.number.isRequired,
    pageSizeArray:PropTypes.array.isRequired,
    maxPageAmount:PropTypes.number.isRequired
};
const TablePageNumber = ({number, actPage, selectPage}) =>{
    const handleClick = (event) => {
        selectPage(number);
    };
    return <Button bsStyle={actPage == number ? "info" : "default"}
                   onClick={handleClick}>{number + 1}</Button>
};
TablePageNumber.propTypes = {
    number:PropTypes.number.isRequired,
    actPage:PropTypes.number.isRequired,
    selectPage:PropTypes.func.isRequired
};
const TableHeader = ({allObjectList, searchParams, startSearching, custHeaderLeftFunction, custFilterFunction}) => {
    const handleSearchTextChange = (searchText) => {
        const sParam = searchParams;
        sParam.text = searchText;
        sParam.searching = true;
        startSearching(sParam)
    };
    return (
        <Row>
            <Col xs={9} md={5}>
                {
                    custHeaderLeftFunction ? custHeaderLeftFunction(searchParams) : null
                }
            </Col>
            <Col xs={3} md={2}>
                {
                    searchParams.searching == true ?
                        <LoadingCircle/>
                        :
                        <p>{messages.get("modal.typelist.entries") + " " + searchParams.filterInfo}</p>
                }
            </Col>
            <Col xs={12} md={5}>
                {
                    searchParams.typeAhead === true ?
                        <SelectInputTypeAhead
                            disabled={searchParams.disableSearch}
                            objectList={allObjectList}
                            handleNoObjectFound={handleSearchTextChange}
                            handleSelectedObject={(translObject) => handleSearchTextChange(translObject.getKey())}
                            placeholder={messages.get("translator.admin.search")}/>
                        :
                        <DelayedTextField disabled={searchParams.disableSearch}
                                          placeholder="Name, Verein, Gruppe"
                                          onChange={handleSearchTextChange}/>

                }
                {
                    custFilterFunction ? custFilterFunction(searchParams) : null
                }
            </Col>
        </Row>
    )
};
TableHeader.propTypes = {
    allObjectList:PropTypes.array.isRequired,
    searchParams:PropTypes.object.isRequired,
    startSearching:PropTypes.func.isRequired,
    custHeaderLeftFunction:PropTypes.func,
    custFilterFunction:PropTypes.func
};

module.exports = SearchTable;
