const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const  FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const ClubArticlesButton = ({club, bsStyle, disabled, block, group}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            symbol={<FontAwesome icon="pencil-alt"/>}
            bsStyle={bsStyle}
            label={"club.member.type." + group}
            href={linkUtil.getLink("club/" + club.id + "/articles/" + group)}/>
    )
};
ClubArticlesButton.propTypes = {
    club:PropTypes.object.isRequired,
    group: PropTypes.string.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
};
module.exports = ClubArticlesButton;