const React = require("react"),
    PropTypes = require('prop-types');

const FontAwesome = require("components/widgets/FontAwesome");

const StarRating = ({rating}) => {
    let fullStar = Math.floor(rating);
    let stars = [];
    for (let i = 0; i < fullStar; i++) {
        stars.push(<FontAwesome key={"st" + i} icon="star"/>);
    }
    let avMod = rating % 1;
    if (avMod > 0.25) {
        if (avMod < 0.76) {
            stars.push(<FontAwesome key={"st4"} icon="star-half"/>);
        } else {
            stars.push(<FontAwesome key={"st4"} icon="star"/>);
        }
    }
    return (<span>
        {stars}
    </span>)
};
StarRating.propTypes = {
    rating: PropTypes.number.isRequired,
};
module.exports = StarRating;