const {getISOWeek} = require('date-fns');
const PLUtil = require("util/ParseListUtility"),
    messages = require("i18n/messages");

const eventTypes = {
    "N": "training_mode_no",
    "T": "training_mode_tournament",
    "D": "training_mode_3d",
    "F": "training_mode_field",
    "O": "training_mode_outdoor",
    "I": "training_mode_indoor"
}

function EventTrainingSortedList(year) {
    this.eventTrainingList = [];
    if (year != null) {
        this.startDate = new Date(year,0,1).getTime();
        this.endDate = new Date(year,11,31,23,59,59,0).getTime();
    } else {
        this.startDate = null;
        this.endDate = null;
    }
    this.sumArrows = 0;
    this.sumDuration = 0;
    this.monthCounter = {};
    this.weekCounter = {};
    this.lastEventID = "a"; // doubled events
    this.lastTUserId = "a"; // doubled tournaments
    this.filterListsDict = {}

    this.pushTrainingSorted= function(eventTraining) {
        let sortTime = eventTraining.getCalStartDate();
        if (this.startDate == null || (sortTime > this.startDate && sortTime < this.endDate)) {
            if (eventTraining.getEventID() != null) {
                // is eventPlayer entry
                const evId = eventTraining.getEventID().id;
                if (evId === this.lastEventID) {
                    console.log("doubled event: " + evId);
                    return;
                }
                this.lastEventID = evId;
                if (eventTraining.getTournamentUserID() != null) {
                    if (eventTraining.getTournamentUserID().id === this.lastTUserId) {
                        console.log("doubled tournamentUser " + this.lastTUserId);
                        return;
                    }
                    this.lastTUserId = eventTraining.getTournamentUserID().id
                }
            }
            let added = false;
            let index = 0;
            let length = this.eventTrainingList.length;
            let trainingMonth = eventTraining.getCalStartDate().getMonth();
            let trainingWeek = getISOWeek(eventTraining.getCalStartDate());
            while (index < length && !added) {
                let oldEvent = this.eventTrainingList[index];
                if (eventTraining.id === oldEvent.id) {
                    // do not add doubled events in list
                    added = true;
                } else if (sortTime > oldEvent.getCalStartDate()) {
                    this.eventTrainingList.splice(index, 0, eventTraining);
                    this.addArrowDurationCounter(trainingMonth, trainingWeek, eventTraining.getArrows(), eventTraining.getDuration());
                    added = true;
                }
                index++;
            }
            if (!added) {
                this.eventTrainingList.push(eventTraining);
                this.addArrowDurationCounter(trainingMonth, trainingWeek, eventTraining.getArrows(), eventTraining.getDuration());
            }
        }
        return {};
    };
    this.addArrowDurationCounter = function(month, week, arrows, duration) {
        this.sumArrows += arrows;
        this.sumDuration += duration;
        this.handleCounterObject(this.monthCounter, month, arrows, duration);
        this.handleCounterObject(this.weekCounter, week, arrows, duration);
    };
    this.handleCounterObject = function(counterObject, key, arrows, duration) {
        let cObj = counterObject[key];
        if (cObj == null) {
            cObj = {
                arrows: 0,
                duration: 0
            }
        }
        cObj.arrows += arrows;
        cObj.duration += duration;
        counterObject[key] = cObj
    };
    this.setTimeFrame = function (startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    };
    this.getEventTrainingList = function() {
        return this.eventTrainingList;
    };
    this.getYearArrows = function() {
        return this.sumArrows;
    };
    this.getYearDuration = function () {
        return this.sumDuration
    };
    this.getMonthCounter = function (month) {
        if (month in this.monthCounter) {
            return this.monthCounter[month]
        } else {
            return {arrows: 0, duration: 0}
        }
    };
    this.getWeekCounter = function (week) {
        if (week in this.weekCounter) {
            return this.weekCounter[week]
        } else {
            return {arrows: 0, duration: 0}
        }
    };
    this.resetEventList = function () {
        this.filterListsDict["events"] = null
    };
    this.getFilterList = function (stateKey, objectList) {
        if (this.filterListsDict[stateKey] != null && this.filterListsDict[stateKey].length > 0) {
            return this.filterListsDict[stateKey]
        }
        if ("parcours" === stateKey) {
            this.filterListsDict[stateKey] = this.getParcoursList()
        } else if ("baProfile" === stateKey) {
            this.filterListsDict[stateKey] = this.getBAProfileList()
        } else if ("eventType" === stateKey) {
            this.filterListsDict[stateKey] = this.getEventTypeList()
        } else if ("countTypes" === stateKey) {
            this.filterListsDict[stateKey] = this.getCountTypeList(objectList)
        } else if ("tag" === stateKey) {
            this.filterListsDict[stateKey] = this.getEventTagList()
        } else if ("ecTag" === stateKey) {
            this.filterListsDict[stateKey] = this.getEventCommentTagList(objectList)
        } else if ("zones" === stateKey) {
            this.filterListsDict[stateKey] = this.getCountTypeDetailList(objectList)
        } else if ("events" === stateKey) {
            this.filterListsDict[stateKey] = this.getEventList(objectList)
        } else {
            // invalid stateKey
            console.log("WTF invalid stateKey: " + stateKey)
            return []
        }
        return this.filterListsDict[stateKey]
    };
    this.getParcoursList = function () {
        let resultList = []
        this.eventTrainingList.map(evp => {
            let event = evp.getEventID();
            let parcoursID = event.getParcoursID();
            if (!PLUtil.containsID(resultList, parcoursID.id)) {
                let ele = {id: parcoursID.id, name: parcoursID.buildNames()}
                PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
            }
        })
        return resultList
    };
    this.getBAProfileList = function () {
        let resultList = []
        this.eventTrainingList.map(evp => {
            let baProfile = evp.getBAProfileID();
            if (baProfile != null && !PLUtil.containsID(resultList, baProfile.id)) {
                let ele = {id: baProfile.id, name: baProfile.getName()}
                PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
            }
        })
        return resultList
    };
    this.getEventTypeList = function () {
        let resultList = []
        this.eventTrainingList.map(evp => {
            let event = evp.getEventID();
            let ev_type = event.getType();
            if (ev_type != null && !PLUtil.containsID(resultList, ev_type)) {
                let ele = {id: ev_type, name: messages.get(eventTypes[ev_type])}
                PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
            }
        })
        return resultList
    }
    this.getEventTagList = function () {
        let resultList = []
        this.eventTrainingList.map(evp => {
            let event = evp.getEventID();
            let tagArray = event.getTagArray();
            if (tagArray != null) {
                tagArray.map(tag => {
                    if (tag != null && tag.length > 0 && !PLUtil.containsID(resultList, tag)) {
                        let ele = {id: tag, name: tag}
                        PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
                    }
                })
            }

        })
        return resultList
    }
    this.getEventCommentTagList = function (eventsCommentsDic) {
        let resultList = []
        if (eventsCommentsDic != null) {
            this.eventTrainingList.map(evp => {
                let event = evp.getEventID();
                const evCommentsDic = eventsCommentsDic[event.id]
                Object.keys(evCommentsDic).map(targetIndex => {
                    const evComment = evCommentsDic[targetIndex]
                    if (evComment != null && evComment.getTagArray() != null) {
                        evComment.getTagArray().map(tag => {
                            if (tag != null && tag.length > 0 && !PLUtil.containsID(resultList, tag)) {
                                let ele = {id: tag, name: tag}
                                PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
                            }
                        })
                    }
                })
            })
        }
        return resultList
    }
    this.getEventList = function (eventList) {
        let resultList = []
        eventList.map(evp => {
            let ele = {name: evp.getReadableDate() + " -" + evp.getCalName(), id: evp.id}
            PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
        })
        return resultList
    };
    this.getCountTypeList = function (countTypes) {
        let resultList = []
        countTypes.map(countType => {
            if (countType != null && !PLUtil.contains(resultList, countType)) {
                let ele = {objectid: countType.id, name: messages.get(countType.getName()), id: countType.getOnlineID(), isDisc: countType.isTypeDisc()}
                PLUtil.pushObjSortedASC(resultList, ele, this.compareParcoursFct)
            }
        })
        return resultList
    };
    this.getCountTypeDetailList = function (countTypeDetails) {
        let resultList = []
        countTypeDetails.map(countTypeDtl => {
            let ele = {name: messages.get(countTypeDtl.getZoneName()), id: countTypeDtl.getKillNr()}
            if (!PLUtil.contains(resultList, ele)) {
                resultList.push(ele)
            }
        })
        return resultList
    };
    this.compareParcoursFct = function(listEntry, newEntry) {
        return newEntry["name"] < listEntry["name"];
    };
}
module.exports = EventTrainingSortedList;