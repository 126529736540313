const alt = require("AltInit");
const actions = require("actions/PaymentElementsActions");

class PaymentElementsStore {
    constructor() {
        this.displayName = "PaymentElementsStore";
        this.sum = 0;
        this.surcharge = 0;
        this.discount = 0;
        this.payCode = null;
        this.paymentName= null;
        this.articles = {};
        this.afterPaymentObject = null;
        this.premiumPriceAlert = null;
        this.paymentBrandConfig = {"VA.DB": 0.03} ;
        this.management = null;
        this.packed = false; //element should not be changed
        this.maxPaymentPrice = null;
        this.invalidPayments = ["alipay","giropay","wechatpay","invoice","przelewy24","ideal","giropay","prepayment","eps"];
        this.hideInAppPayMethod = false;
        this.bindListeners({
            updatePaymentBrandConfig: actions.updatePaymentBrandConfig,
            updateSum: actions.updateSum,
            updatePayCode: actions.updatePayCode,
            updateDiscount: actions.updateDiscount,
            updateTransactionArticle: actions.updateTransactionArticle,
            setSingleTransactionArticle: actions.setSingleTransactionArticle,
            updateAfterPayment: actions.updateAfterPayment,
            updatePremiumAlert: actions.updatePremiumAlert,
            resetBasket: actions.resetBasket,
            updateManagement: actions.updateManagement,
            updatePacked: actions.updatePacked,
            updateExcludePayTypes: actions.updateExcludePayTypes,
            updateHideInAppPayMethod: actions.updateHideInAppPayMethod,
            updateMaxPaymentPrice: actions.updateMaxPaymentPrice
        });
    }

    updateSum(price) {
        let surchargeAmount = 0;
        const newSum = this.sum + price;
        let surchargePercent = this.paymentBrandConfig[this.payCode];
        if (surchargePercent != null && surchargePercent > 0) {
            // calc surcharge from config
            surchargeAmount = parseFloat(newSum * surchargePercent);
            surchargeAmount = parseFloat(surchargeAmount.toFixed(2))
        }
        this.sum = newSum;
        this.surcharge = surchargeAmount;
    }

    updateDiscount(discount) {
        this.discount = discount
        if (this.sum > 0) {
            this.sum = this.sum - discount;
        }
    }

    updatePacked(packed) {
        this.packed = packed;
    }

    updateMaxPaymentPrice(price) {
        this.maxPaymentPrice = price;
    }

    updatePayCode(pPayCode) {
        let surchargeAmount = 0;
        let name = null;
        let surchargePercent = this.paymentBrandConfig[pPayCode];
        if (surchargePercent != null && surchargePercent > 0) {
            // calc surcharge from config
            surchargeAmount = parseFloat(this.sum * surchargePercent);
            surchargeAmount = parseFloat(surchargeAmount.toFixed(2))
        }
        if (pPayCode === "VA." || pPayCode === "VA.DB") {
            name = "paypal"
        }
        this.payCode = pPayCode;
        this.paymentName = name;
        this.surcharge = surchargeAmount;
    }

    updateTransactionArticle({articleID, amount}) {
        this.articles[articleID] = amount;
    }

    setSingleTransactionArticle({articleID, price}) {
        let newArticles = {};
        newArticles[articleID] = 1;
        this.articles = newArticles;
        this.sum = price - this.discount
    }

    updatePaymentBrandConfig(config) {
        this.paymentBrandConfig = JSON.parse(config);
    }

    updateExcludePayTypes(exclArray) {
        this.invalidPayments = exclArray;
    }

    updateHideInAppPayMethod(hide) {
        this.hideInAppPayMethod = hide
    }

    updatePremiumAlert(textObject) {
        this.premiumPriceAlert = textObject;
    }

    updateAfterPayment(pAfterPaymentObject) {
        this.afterPaymentObject = pAfterPaymentObject
    }

    updateManagement(management) {
        this.management = management;
    }

    resetBasket() {
        this.sum = 0;
        this.surcharge = 0;
        this.discount = 0;
        this.payCode = null;
        this.paymentName= null;
        this.afterPaymentObject = null;
        this.articles = {};
    }
}
module.exports = alt.createStore(PaymentElementsStore);
