const React = require("react"),
    PropTypes = require('prop-types'),
    messages = require("i18n/messages");
const {Alert, Button, ButtonGroup, Image, Col} = require("react-bootstrap");
const PaymentBrandSelect = ({payCode, handleBrandButton, invalidPayments}) => {
    return (
        <React.Fragment>
            <ButtonGroup>
                <Col xs={8} sm={6} xsOffset={2} smOffset={3} style={{marginBottom:"10px"}}>
                    <PayTypeButton selPayCode={payCode} payCode={"VA.DB"} imgSrc={"/img/logo/paypal.png"} invalidPayments={invalidPayments} handleBrandButton={handleBrandButton} />
                </Col>
                <Col xs={8} sm={6} xsOffset={2} smOffset={3}>
                    <PayTypeButton selPayCode={payCode} payCode={"STRIPE"} imgSrc={"/img/logo/stripe_block.jpg"} invalidPayments={invalidPayments} handleBrandButton={handleBrandButton} />
                </Col>
            </ButtonGroup>
            {
                payCode == "VA.DB" || payCode == "BB.DB" ?
                    <Alert bsStyle="info">
                        <span>
                            {messages.get("checkout.info.need.account." + payCode)}&nbsp;
                            <a href={messages.get("checkout.info.weblink." + payCode)} >
                            {messages.get("checkout.info.weblink." + payCode)}</a>
                        </span>
                    </Alert> : null
            }
        </React.Fragment>
    )
};
const PayTypeButton = ({invalidPayments, selPayCode, payCode, imgSrc, handleBrandButton}) => {
    if (invalidPayments.includes(payCode)) {
        return null;
    }
    return <Button key={"PTB:" + payCode} active={selPayCode === payCode} onClick={() => handleBrandButton(payCode)}>
        <Image thumbnail responsive src={imgSrc}/>
    </Button>
};
PaymentBrandSelect.propTypes = {
    payCode:PropTypes.string,
    handleBrandButton:PropTypes.func.isRequired,
    invalidPayments: PropTypes.array.isRequired
};
module.exports = PaymentBrandSelect;
