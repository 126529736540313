const Parse = require("ParseInit");
const {Releases} = require("parse/_Domain");

class ReleasesDao {

    queryReleases() {
        const query = new Parse.Query(Releases);
        query.notEqualTo(Releases.prototype.col.status, Releases.prototype.status.deleted);
        query.descending(Releases.prototype.col.date);
        return query.find();
    }

    queryLastWebReleases() {
        const query = new Parse.Query(Releases);
        query.notEqualTo(Releases.prototype.col.status, Releases.prototype.status.deleted);
        query.equalTo(Releases.prototype.col.onlineID, Releases.prototype.type.Web);
        query.descending(Releases.prototype.col.date);
        return query.first();
    }
}

module.exports = new ReleasesDao();
