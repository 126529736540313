const React = require("react"),
    PropTypes = require('prop-types');
const {Grid, Row, Col} = require("react-bootstrap");
const messages = require("i18n/messages");
const Loading = require("components/widgets/Loading"),
    FileUploadInput = require("components/form/FileUploadInput"),
    ImageGrid  = require("components/widgets/ImageGrid"),
    Utility = require("util/Utility");

const imageGalleryStore  = require("stores/ImageGalleryStore"),
    imageGalleryActions = require("actions/ImageGalleryActions");

class ImageGalleryTeaser extends React.Component{
    constructor(props) {
        super(props);
        this.handleGalleryChange = this.handleGalleryChange.bind(this);
        this.state = {
            imageGalleryStore: imageGalleryStore.getState()
        }
    }
    componentDidMount() {
        imageGalleryStore.listen(this.handleGalleryChange);
        if (this.props.parcours == null && this.props.tournament == null) {
            imageGalleryActions.updateImageGalleryList.defer([]);
        } else {
            imageGalleryActions.queryImages.defer(this.props.parcours, this.props.tournament);
        }
    }
    componentWillUnmount() {
        imageGalleryStore.unlisten(this.handleGalleryChange);
    }
    handleGalleryChange(state) {
        this.setState({imageGalleryStore: state});
    }
    render() {
        const {loading, uploading, imageGalleryList} = this.state.imageGalleryStore;
        const {user, parcours, tournament, adminPage} = this.props;
        return (
            <Grid>
                {loading === true ?
                    <Loading/> : ""}
                {  loading === false && parcours ?
                    <ImageGalleryUpload uploading={uploading} user={user} parcours={parcours} tournament={tournament}/> : ""
                }
                {  loading === false && imageGalleryList.length > 0 ?
                    <ImageGrid imageGalleryList={imageGalleryList} adminPage={adminPage}
                               user={user} parcours={parcours}/> : ""
                }
            </Grid>
        )
    }
}
ImageGalleryTeaser.propTypes = {
    user: PropTypes.object,
    parcours: PropTypes.object,
    tournament: PropTypes.object,
};
const ImageGalleryUpload = ({user, parcours, tournament, uploading}) => {
    function handleParseFile(parseFile, imageType) {
        imageGalleryActions.createImageGallery(parseFile, parcours, tournament, user)
    }
    if (Utility.hasWriteLock(user, false)) {
        return null;
    }
    return (
        <Row>
            <Col sm={12}>
                <h2>{messages.get("gallery.title")}</h2>
                <br/>
                {
                    uploading ?
                        <Loading/> :
                            user && parcours ?
                            <FileUploadInput label={messages.get("parcours.details.imageGallery.add")}
                                             allowedFileTypes={["I"]}
                                             savedParseFileCallback={handleParseFile}/> : null
                }
            </Col>
        </Row>
    )
}
ImageGalleryUpload.propTypes = {
    uploading: PropTypes.bool.isRequired,
    user: PropTypes.object,
    parcours: PropTypes.object,
    tournament: PropTypes.object,
};
module.exports = ImageGalleryTeaser;
