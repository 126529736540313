const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Button, Well, Table, FormControl, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    InActiveButton = require("components/form/InActiveButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    StrikeOutText = require("components/widgets/StrikeOutText"),
    ValidInput = require("components/form/ValidInput"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    Utility = require("util/Utility");

const ArticleConfigList = ({user, articles, articleTypes, handleArticleStatusChange, handleSubmit}) => {
    return (<Well>
            <Table>
                <thead>
                <tr>
                    <th><I18n code={"parcours.articles.form.name"}/></th>
                    <th><I18n code={"parcours.articles.form.group"}/></th>
                    <th><I18n code={"parcours.articles.form.price"}/></th>
                    <th><I18n code={"parcours.articles.form.currency"}/></th>
                    <th><I18n code={"parcours.details.button.actions"}/></th>
                </tr>
                </thead>
                <tbody>
                    {Utility.isSet(articles) ?
                        articles.map(article => {
                            if (article != null && Utility.containsArray(articleTypes, article.getGroup()) ) {
                                return <ArticlesRow id={article.id} user={user} article={article} articleTypes={articleTypes}
                                                    handleSubmit={handleSubmit}
                                                    handleArticleStatusChange={handleArticleStatusChange}/>
                            }

                        })
                        : null}
                    <tr>
                        <td><h4>{messages.get("parcours.articles.form.create")}</h4></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <ArticlesForm handleSubmit={handleSubmit} articleTypes={articleTypes}/>
                </tbody>
            </Table>
        </Well>
    )
};
ArticleConfigList.propTypes = {
    user: PropTypes.object.isRequired,
    articles:PropTypes.array,
    handleArticleStatusChange:PropTypes.func.isRequired,
    handleSubmit:PropTypes.func.isRequired,
    articleTypes: PropTypes.array.isRequired
};
class ArticlesRow extends React.Component {
    constructor(props) {
        super(props);
        this.onDeleteClicked = this.onDeleteClicked.bind(this);
        this.onEditClicked = this.onEditClicked.bind(this);
        this.closeEditMode = this.closeEditMode.bind(this);
        this.onInActiveClicked = this.onInActiveClicked.bind(this);
        this.state = {
            editMode: false
        }
    }
    onDeleteClicked(doDelete) {
        if (doDelete) {
            this.props.handleArticleStatusChange(this.props.article, "D")
        }
    }
    onInActiveClicked(active) {
        this.props.handleArticleStatusChange(this.props.article, active ? "A" : "I")
    }
    onEditClicked() {
        this.setState({editMode: true});
    }
    closeEditMode() {
        this.setState({editMode: false});
    }
    render() {
        const {article, articleTypes, handleSubmit} = this.props;
        if (this.state.editMode) {
            return <ArticlesForm handleSubmit={handleSubmit} closeEditMode={this.closeEditMode} articleTypes={articleTypes}  article={article}/>
        } else {
            return <tr>
                <td><StrikeOutText strikeOut={!article.isActive()}>{article.getName()}</StrikeOutText></td>
                <td>{messages.get("ParcoursArticle_Group_" + article.getGroup())}</td>
                <td>{article.getPrice().toFixed(2)}</td>
                <td>{article.getCurrency()}</td>
                <td>
                    <Button onClick={this.onEditClicked}><FontAwesome icon="pencil-alt"/></Button>
                    <InActiveButton active={article.isActive()} onClick={this.onInActiveClicked}/>
                    <DeleteDecisionModal messageCode="list.entry.delete"
                                         entryName={article.getName()} handleDelete={this.onDeleteClicked}/>
                </td>
            </tr>
        }
    }
}
ArticlesRow.propTypes = {
    article:PropTypes.object.isRequired,
    handleArticleStatusChange:PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    articleTypes: PropTypes.array.isRequired,
};
class ArticlesForm extends React.Component {
    constructor(props) {
        super(props);
        this.refName = React.createRef();
        this.refPrice = React.createRef();
        this.refCurrency = React.createRef();
        this.refGroup = React.createRef();
        
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            errorCode: null
        }
    }
    validate(values) {
        var price = parseFloat(values.price);
        if (Utility.isEmpty(values.name)
            || Utility.isEmpty(values.currency)
            || price !== price
            || price < 0) {
            this.setState({errorCode: "parcours.article.input.invalid"});
            return false;
        }
        values.price = parseFloat(values.price);
        return true;
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({errorCode: ""});

        // get values
        //
        var values = {
            name: this.refName.current.getValue().trim(),
            price: parseFloat(this.refPrice.current.getValue().trim()),
            currency: ReactDOM.findDOMNode(this.refCurrency.current).value.trim(),
            group: ReactDOM.findDOMNode(this.refGroup.current).value.trim()
        };

        // call validate
        if (this.validate(values)) {
            // if true -> call handleSubmit
            this.props.handleSubmit(this.props.article, values);
            if (this.props.closeEditMode) {
                this.props.closeEditMode()
            }
        }

    }
    render() {
        const {article, articleTypes, closeEditMode} = this.props;
        return (
            <React.Fragment>
                {!Utility.isEmpty(this.state.errorCode) ?
                    <Alert bsStyle="danger"><I18n code={this.state.errorCode}/></Alert> : ""
                }

                    <tr>
                        <td>
                            <ValidInput
                                ref={this.refName}
                                default={article ? article.getName() : null}
                                valid={{maxLength: 500, check:['isRequired']}}
                                placeholder={messages.get("parcours.articles.form.name")}/>
                        </td>
                        <td>
                            <FormControl ref={this.refGroup} componentClass="select" placeholder="select"
                                         defaultValue={article ? article.getGroup() : null}>
                                {articleTypes.map(function (inputGroup) {
                                    return <option key={inputGroup}
                                                   value={inputGroup}>{messages.get("ParcoursArticle_Group_" + inputGroup)}</option>
                                })}
                            </FormControl>
                        </td>
                        <td>
                            <ValidInput
                                ref={this.refPrice}
                                type="string"
                                default={article ? article.getPrice().toString() : null}
                                valid={{check:['isRequired','point4comma']}}
                                placeholder={messages.get("parcours.articles.form.price")}/>
                        </td>
                        <td>
                            <FormControl ref={this.refCurrency} componentClass="select">
                                <option value="EUR">EUR</option>
                            </FormControl>
                        </td>
                        <td>
                            { closeEditMode ? <Button bsStyle="primary" onClick={closeEditMode}><FontAwesome icon="times"/></Button> : null }
                            <Button bsStyle="primary" onClick={this.handleSubmit}><FontAwesome icon="check"/></Button>
                        </td>
                    </tr>

            </React.Fragment>)
    }
}
ArticlesForm.propTypes = {
    article:PropTypes.object,
    articleTypes: PropTypes.array.isRequired,
    handleSubmit:PropTypes.func.isRequired,
    closeEditMode: PropTypes.func
};
module.exports = ArticleConfigList;
