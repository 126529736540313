const alt = require("AltInit");
const dao = require("actdao/EventDao"),
    tournamentUserDao = require("actdao/TournamentUserDao"),
    parseDao = require("actdao/ParseBaseDao"),
    tournamentDao = require("actdao/TournamentDao"),
    transactionDao  = require("actdao/TransactionDao");
const notificationActions = require("components/notification/NotificationActions");
const EventPlayerYearStat = require("util/EventPlayerYearStat");

class EventActions {

    queryEventList(user, year) {
        this.createEventList(year);
        dao.queryEventList(user.getEmail(), null)
            .then(fEventPlayers => {
                this.updateEventPlayerList(fEventPlayers);
            })
            .catch(error => {
                error.trace = "EVEA.eventList.load";
                notificationActions.parseError(error);
            });
    }

    queryYearEventList(user, year) {
        dao.queryYearEventList(user.getEmail())
            .then(fEventPlayers => {
                var epYearStat = new EventPlayerYearStat(year);
                fEventPlayers.map(evPlayer => {
                    epYearStat.addEventPlayer(evPlayer);
                });
                this.updateEventPlayerYearStat(epYearStat);
            })
            .catch(error => {
                error.trace = "EVEA.yearEventList.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    pushSorted(eventPlayerList, eventPlayer) {
        var newEvent = eventPlayer.getEventID();
        if (newEvent) {
            var added = false;
            var index = 0;
            var length = eventPlayerList.length;
            while (index < length && !added) {
                var oldEvent = eventPlayerList[index].getEventID();
                if (newEvent.getInsstmp() > oldEvent.getInsstmp()) {
                    eventPlayerList.splice(index, 0, eventPlayer);
                    added = true;
                } else if (newEvent.getInsstmp() == oldEvent.getInsstmp()) {
                    // do not add doubled events in list
                    added = true;
                }
                index++;
            }
            if (!added) {
                eventPlayerList.push(eventPlayer);
            }
        }
        return {};
    }

    queryTournaments(user, year) {
        tournamentUserDao.loadOwnTournamentUsers(user, year)
            .then(tUsers => {
                this.updateTournamentUsers(tUsers);
            })
            .catch(error => {
                error.trace = "EVEA.tournamentUser.load";
                notificationActions.parseError(error);
            });
        tournamentDao.loadTournamentsByEditors(user, year)
            .then(tournaments => {
                this.updateTournamentEditors(tournaments);
            })
            .catch(error => {
                error.trace = "EVEA.tournamentEditor.load";
                notificationActions.parseError(error);
            });
        return {};
    };

    queryTransactions(user, year) {
        transactionDao.queryOwnTransactions(user, year)
            .then(transactions => {
                this.updateTransactions(transactions);
            })
            .catch(error => {
                error.trace = "EVEA.transaction.load";
                notificationActions.parseError(error);
            });
        return {};
    };

    queryEventPlayer4SmartTournament(eventID, resultCallback) {
        dao.queryEventPlayer4SmartTournament(eventID)
            .then(eventPlayers => {
                resultCallback(eventPlayers)
            })
            .catch(error => {
                error.trace = "EVEA.smarttournament.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryEventPlayerDetail(event, callback) {
        dao.queryEventPlayerDetail(event)
            .then (eventPlayers => {
                callback(eventPlayers);
            })
            .catch(error => {
                error.trace = "EVEA.eventPlayerDetail.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveEventPlayer(eventPlayer) {
        parseDao.saveObject(eventPlayer)
            .then(sEventPlayer => {
                this.updateEventPlayerList([sEventPlayer]);
            })
            .catch(error => {
                error.trace = "EVEA.upd.baprof";
                notificationActions.parseError(error);
            });
        return {}
    }

    updateEventStatus(event, status, callback) {
        dao.updateEventStatus(event, status)
            .then(result => {
                callback()
            })
            .catch(error => {
                error.trace = "EVEA.updStatus";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveEvent(event, callback) {
        parseDao.saveObject(event)
            .then(sEvent => {
                callback(sEvent)
            })
            .catch(error => {
                error.trace = "EVEA.updVisStatus";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentUsers(tUsers) {
        return tUsers;
    };
    updateTournamentEditors(tournaments) {
        return tournaments
    }

    updateTransactions(transactions){
        return transactions;
    };

    createEventList(year) {
        return year;
    }
    updateEventPlayerList(pEventPlayers) {
        return pEventPlayers;
    };
    updateEventPlayerYearStat(epYearStat) {
        return epYearStat;
    }

    reset() {
        return {};
    }

}

module.exports = alt.createActions(EventActions);
