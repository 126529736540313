const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    UserActions = require("actions/UserActions");

const LoginButton = ({create}) => {
    const openLoginDialog = () => {
        UserActions.showLoginModal();
    };
    const openCreateDialog = () => {
        UserActions.showLoginCreateModal();
    };
    if (create) {
        return (
            <Button bsStyle="success" onClick={openCreateDialog}>
                <FontAwesome icon="user-plus"/><I18n code="modal.login.button.register"/>
            </Button>)
    } else {
        return (
            <Button bsStyle="success" onClick={openLoginDialog}>
                <FontAwesome icon="sign-in-alt"/><I18n code="modal.login.button.login"/>
            </Button>)
    }
};
LoginButton.propTypes = {
    create: PropTypes.bool
};
module.exports = LoginButton;
