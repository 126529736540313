const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const { Alert} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

const TournamentConfigAgePriceWarning = ({tournament}) => {

    if (tournament.hasTournamentConfigAgeWithPrices()) {
        return null;
    }
    return <Alert bsStyle="warning"><FontAwesome icon={["fab","creative-commons-nc-eu"]}/> <I18n code="tournament.manager.tconfig.warning.noPrice"/></Alert>
};
TournamentConfigAgePriceWarning.propTypes = {
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentConfigAgePriceWarning;
