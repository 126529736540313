const React = require("react"),
    PropTypes = require('prop-types');
const I18n = require("components/widgets/I18n"),
    PaymentTargetModal = require("components/modals/PaymentTargetModal");

class PaymentTargetDetail extends React.Component {
    constructor(props) {
        super(props);
        this.updatePayment = this.updatePayment.bind(this);
        this.state = {
            paymentTarget: props.paymentTarget
        }
    }
    updatePayment(paymentTarget) {
        let isNew = paymentTarget.isNew();
        paymentTarget.save(null)
            .then(sPaymentTarget => {
                if (isNew && this.props.handleSaveNewObject) {
                    this.props.handleSaveNewObject(sPaymentTarget);
                }
                this.setState({paymentTarget: sPaymentTarget});
            })
            .catch(error => {
                console.log("Cloud not save", error)
            })

    }
    render() {
        const {paymentTarget} = this.state;
        return (<React.Fragment>
            {paymentTarget == null ? "" : <PaymentDetail paymentTarget={paymentTarget}/>}
            <PaymentTargetModal paymentTarget={paymentTarget} buttonStyle={paymentTarget ? "success" : "warning"}
                                handleSave={this.updatePayment}/>
        </React.Fragment>)
    }
};
PaymentTargetDetail.propTypes = {
    paymentTarget:PropTypes.object.isRequired,
    handleSaveNewObject: PropTypes.func
};
const PaymentDetail = ({paymentTarget}) => {
    return (
        <dl>
            <dt className="size-140"><I18n code="payTarget.cardName"/>: </dt>
            <dd>{paymentTarget.getCardName()}</dd>
            <dt className="size-140"><I18n code="payTarget.cardIBAN"/>: </dt>
            <dd>{paymentTarget.getCardIBAN()}</dd>
            <dt className="size-140"><I18n code="payTarget.cardBIC"/>: </dt>
            <dd>{paymentTarget.getCardBIC()}</dd>
        </dl>)
};
PaymentDetail.propTypes = {
    paymentTarget:PropTypes.object.isRequired
};
module.exports = PaymentTargetDetail;