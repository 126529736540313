var Parse = require("ParseInit");
var Utility = require("util/Utility");
const ClubInvoice = Parse.Object.extend("clubInvoice", {
    status: {
        published: "A",
        created: "C",
        openInvoice: "O",
        deleted: "D"
    },
    col: {
        ACL: "ACL",
        addressID:"addressID",
        parcoursArticles: "articlePositions",
        afterPayment: "afterPayment",
        name: "name",
        description: "description",
        clubID: "clubID",
        clubUserID: "clubUserID",
        creator: "creator",
        date: "date",
        discount: "discount",
        invNumber: "invNumber",
        type: "type",
        inORout: "inout",
        amount: "amount", // already paid
        price: "price", // set price if not paid yet
        currency: "currency",
        document: "document",
        status: "status",
        tax: "tax",
        taxConfig: "taxConfig",
        objectId: "objectId",
        updatedAt: "updatedAt",
        createdAt: "createdAt"
    },
    colTransaction: {
        managementID: "managementID", //needed?
        invoiceDate: "invoiceDate", // has already date - needs to be different??
        invoice: "invoice", // has already document
    },
    //
    // custom
    //
    getInvoiceDate() {
        var tDate = this.getDate();
        return Utility.getReadableDate(tDate);
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isPaid() {
        return this.status.published === this.getStatus();
    },
    isOpenInvoice() {
        return this.status.openInvoice === this.getStatus();
    },
    getLabels() {
        return [this.getName(), this.getInvoiceNumber(), this.getDescription(), this.getAmount()].join(" ");
    },
    isType(type) {
        return this.getType() === type;
    },
    //
    // getter/setter
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAddressID() {
        return this.get(this.col.addressID)
    },
    setAddressID(addressID) {
        return this.set(this.col.addressID, addressID)
    },
    getParcoursArticles() {
        return this.get(this.col.parcoursArticles)
    },
    setParcoursArticles(parcoursArticles) {
        return this.set(this.col.parcoursArticles, parcoursArticles)
    },
    getAfterPayment() {
        return this.get(this.col.afterPayment);
    },
    setAfterPayment(afterPayment) {
        return this.set(this.col.afterPayment, afterPayment);
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    getDiscount() {
        return this.get(this.col.discount)
    },
    setDiscount(discount) {
        return this.set(this.col.discount, discount)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getClubID() {
        return this.get(this.col.clubID)
    },
    setClubID(clubID) {
        return this.set(this.col.clubID, clubID)
    },
    getClubUserID() {
        return this.get(this.col.clubUserID)
    },
    setClubUserID(clubUser) {
        return this.set(this.col.clubUserID, clubUser)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getInvoiceNumber() {
        return this.get(this.col.invNumber)
    },
    setInvoiceNumber(invNumber) {
        return this.set(this.col.invNumber, invNumber)
    },
    getType() {
        return this.get(this.col.type)
    },
    setType(type) {
        return this.set(this.col.type, type)
    },
    getInOrOut() {
        return this.get(this.col.inORout)
    },
    setInOrOut(inORout) {
        return this.set(this.col.inORout, inORout)
    },
    getAmount() {
        return this.get(this.col.amount)
    },
    setAmount(amount) {
        return this.set(this.col.amount, amount)
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    getPrice() {
        return this.get(this.col.price)
    },
    getFormattedPrice() {
        return this.getCurrency() + " " + parseFloat(this.getPrice()).toFixed(2);
    },
    getOpenAmount() {
        return this.getPrice() - this.getAmount();
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    getDocument() {
        return this.get(this.col.document)
    },
    setDocument(document) {
        return this.set(this.col.document, document)
    },
    getDate() {
        return this.get(this.col.date)
    },
    setDate(date) {
        return this.set(this.col.date, date)
    },
    getTax() {
        return this.get(this.col.tax)
    },
    getTaxConfig() {
        return this.get(this.col.taxConfig)
    },
    setTax(tax) {
        return this.set(this.col.tax, tax);
    },
    setTaxConfig(taxConfig) {
        return this.set(this.col.taxConfig, taxConfig)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ClubInvoice;
