const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Button, ButtonGroup, Panel} = require("react-bootstrap");

const SolutionHelpLink = require("components/links/SolutionHelpLink");

const Loading = require("components/widgets/Loading"),
    TournamentMMConfigButton = require("components/links/TournamentMMConfigButton"),
    TournamentMMRoundButton = require("components/links/TournamentMMRoundButton"),
    TournamentManagerButton = require("components/links/TournamentManagerButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    ExtLink = require("components/links/ExtLink"),
    messages = require("i18n/messages");

const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader"),
    TournamentMMCupChildTable = require("components/tournamentmanage/TournamentMMCupChildTable"),
    TournamentUserGroupResultPanel = require("components/tournamentmanage/TournamentUserGroupResultPanel"),
    TournamentUserManagerTable = require("components/tournamentmanage/TournamentUserManagerTable"),
    TournamentUserTeamPanel = require("components/tournamentmanage/TournamentUserTeamPanel");
    TournamentUserGroupPanel = require("components/tournamentmanage/TournamentUserGroupPanel");

const tournamentUserManagerStore = require("stores/TournamentUserManagerStore"),
    tournamentUserManagerActions = require("actions/TournamentUserManagerActions");

const TournamentMMTUserPage = ({tournamentId}) =>{
        return <TournamentMMLoader tournamentId={tournamentId}>
            <TournamentTUserHierarchy />
        </TournamentMMLoader>
};
TournamentMMTUserPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
class TournamentTUserHierarchy extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);
        this.setGroupMode = this.setGroupMode.bind(this);
        this.state = {
            tournamentUserManagerStore: tournamentUserManagerStore.getState(),
            mmtuMode: "R"
        }
    }
    componentDidMount() {
        tournamentUserManagerStore.listen(this.handleTournamentUserChange);
        if (!this.props.tournament.isTypeCupMaster()) {
            tournamentUserManagerActions.loadTournamentUsers.defer(this.props.tournament, this.state.tournamentUserManagerStore.hideDeleted);
            tournamentUserManagerActions.loadTournamentUserGroups.defer(this.props.tournament, this.state.tournamentUserManagerStore.hideDeleted);
            tournamentUserManagerActions.loadTournamentUserTeams.defer(this.props.tournament, this.state.tournamentUserManagerStore.hideDeleted)
        }
    }
    componentWillUnmount() {
        tournamentUserManagerStore.unlisten(this.handleTournamentUserChange);
        // tournamentUserManagerActions.resetTournamentUsers();
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserManagerStore: state});
    }
    setGroupMode(mode) {
        this.setState({mmtuMode: mode});
    }
    buildTRoundList(tournamentRoundMap) {
        let list = []
        list = list.concat(tournamentRoundMap.qualify)
        list = list.concat(tournamentRoundMap.finale)
        list = list.concat(tournamentRoundMap.group)
        return list;
    }
    render() {
        const {tournament, tournamentRoundMap, childTournaments, runningTRounds, user, tournamentSlots} = this.props;
        const singleStartedTRound = runningTRounds.length === 1 ? runningTRounds[0] : null
        const {mmtuMode, tournamentUserManagerStore} = this.state;
        const needTeamPanel = tournament.isTypePairRegistration() || tournamentRoundMap.group.length > 0;
        const tournamentRoundsAll = this.buildTRoundList(tournamentRoundMap);
        if (tournament.isTypeCupMaster()) {
            // show elements for a cup master
            const selTournament = tournamentUserManagerStore.selTournament != null ? tournamentUserManagerStore.selTournament : tournament
            return (<React.Fragment>
                {
                    childTournaments != null && childTournaments.length > 0 ?
                        <Panel>
                            <Panel.Heading>
                                <Row><Col xs={12}>
                                    <h3>{tournament.getName() + ", " + tournament.getTournamentDate()}</h3>
                                </Col></Row>
                            </Panel.Heading>
                            <Panel.Body>
                                <TournamentMMCupChildTable tournament={tournament}
                                    hideDeleted={tournamentUserManagerStore.hideDeleted}
                                    childTournaments={childTournaments}/>
                            </Panel.Body>
                        </Panel>
                        : null
                }
                <Row>
                    <Col lg={12}>
                        <h1>{selTournament.getName() + ", " + selTournament.getTournamentDate()}</h1>
                    </Col>
                </Row>
                {
                    tournamentUserManagerStore.selTournament != null ? <ModeButtonGroup mmtuMode={mmtuMode} setGroupMode={this.setGroupMode}/> : null
                }

                <Row>
                    <TournamentUserManagerTable user={user} tournament={selTournament} mmtuMode={mmtuMode}
                                                tournamentRegSlots={tournamentSlots}
                                                tournamentRoundsAll={tournamentRoundsAll} needTeamButton={needTeamPanel}
                                                singleStartedTRound={null}
                                                tournamentUserStore={tournamentUserManagerStore}/>
                </Row>
            </React.Fragment>)
        } else {
            // show elements for a normal tournament
            return (<React.Fragment>
                <Row>
                    <Col lg={12}>
                        <h1>{tournament.getName() + ", " + tournament.getTournamentDate()}</h1>
                        <SolutionHelpLink articleID="42000037355" />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        {
                            tournament.getTournamentPriceID() == null ?
                                <Alert bsStyle="danger"><FontAwesome icon="exclamation-triangle"/><I18n code="tournament.mmtuser.config.warning"/>&nbsp;<I18n code="tournament.register.label.payCondition"/>&nbsp;<TournamentMMConfigButton tournament={tournament} /> </Alert> : null
                        }
                        {
                            tournament.hasManagementID() ? null :
                                <Alert bsStyle="warning"><FontAwesome icon="exclamation-triangle"/><I18n code="tournament.mmtuser.config.warning"/>&nbsp;<I18n code="profile.account.business.header"/>&nbsp;<TournamentManagerButton tournament={tournament} /> </Alert>
                        }
                        {
                            tournamentRoundsAll.length === 0 ?
                                <Alert bsStyle="warning"><FontAwesome icon="exclamation-triangle"/><I18n code="tournament.mmtuser.config.warning"/>&nbsp;<I18n code="tournament.round.type.S.defRoundName"/>&nbsp;<TournamentMMRoundButton tournament={tournament} /> </Alert> : null
                        }
                        {
                            runningTRounds.length > 0 && tournament.isPublished() && tournament.isTodayInTournamentDateRange()?
                                <Alert bsStyle="warning"><FontAwesome icon="exclamation-triangle"/><I18n code="tournament.live.noStartedRounds"/>&nbsp;&nbsp;<TournamentManagerButton tournament={tournament} /> </Alert> : null
                        }
                    </Col>
                </Row>
                <ModeButtonGroup mmtuMode={mmtuMode} setGroupMode={this.setGroupMode}/>
                <Row>
                    <TournamentUserManagerTable user={user} tournament={tournament} mmtuMode={mmtuMode}
                                                tournamentRegSlots={tournamentSlots}
                                                tournamentRoundsAll={tournamentRoundsAll} needTeamButton={needTeamPanel}
                                                singleStartedTRound={singleStartedTRound}
                                                tournamentUserStore={tournamentUserManagerStore}/>
                    {
                        mmtuMode === "A" ?
                            <TournamentUserGroupResultPanel user={user} tournament={tournament}
                                                            tournamentRoundsAll={tournamentRoundsAll}
                                                            runningTRounds={runningTRounds}
                                                            tournamentUserStore={tournamentUserManagerStore}/> :
                            <TournamentUserGroupPanel user={user} tournament={tournament} groupingMode={mmtuMode == "G"}
                                                      tournamentRoundsAll={tournamentRoundsAll}
                                                      runningTRounds={runningTRounds}
                                                      singleStartedTRound={singleStartedTRound}
                                                      tournamentUserStore={tournamentUserManagerStore}/>
                    }
                    {
                        needTeamPanel ?
                            <TournamentUserTeamPanel user={user} tournament={tournament} groupingMode={mmtuMode == "G"}
                                                     runningTRounds={runningTRounds}
                                                     singleStartedTRound={singleStartedTRound}
                                                     tournamentUserStore={tournamentUserManagerStore}/> : null
                    }
                </Row>
            </React.Fragment>)
        }
    }
}
const ModeButtonGroup = React.memo(
    function ModeButtonGroup({mmtuMode, setGroupMode}) {
        const modes = ["R", "G" , "A"];
        return <Row>
            <Col sm={12}>
                <ButtonGroup>
                    {
                        modes.map(mode => {
                            return <Button key={"ModeButton_" + mode} bsStyle={mmtuMode === mode ? "success": "default"} onClick={() => setGroupMode(mode)}>{messages.get("tournament.mmtuser.mode." + mode)}</Button>
                        })
                    }
                </ButtonGroup>
            </Col>
        </Row>
    });
TournamentTUserHierarchy.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object,
    runningTRounds: PropTypes.array
};
module.exports = TournamentMMTUserPage;
