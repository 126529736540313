const alt = require("AltInit");
const action = require("actions/AnalyticsActions");
const PLUtil = require("util/ParseListUtility");

class AnalyticsStore {
    constructor() {
        this.displayName = "AnalyticsStore";
        this.parcoursStatDic = null;
        this.parcoursVisits = null;
        this.loading = true;
        this.invalidUser = false;
        this.parcours = null;
        this.parcoursList = null;
        this.parcoursOwner = null;
        this.bindListeners({
            updateLoading: action.updateLoading,
            updateParcours: action.updateParcours,
            updateParcoursList: action.updateParcoursList,
            updateParcourVisits: action.updateParcourVisits,
            updateParcourVisit: action.updateParcourVisit,
            updateParcoursOwner: action.updateParcoursOwner,
            updateParcoursStatDic: action.updateParcoursStatDic,
            removeParcoursVisit: action.removeParcoursVisit,
            addParcoursVisit: action.addParcoursVisit,
            setInvalidUser: action.setInvalidUser,
        });
    }

    updateLoading(loading) {
        this.loading = loading;
    }

    updateParcoursStatDic(pParcoursStatDic) {
        this.loading = false;
        this.parcoursStatDic = pParcoursStatDic;
    }

    updateParcours(parcours) {
        this.parcours = parcours;
    }

    updateParcoursList(parcoursList) {
        this.parcoursList = parcoursList;
    }

    updateParcoursOwner(parcoursOwner) {
        this.parcoursOwner = parcoursOwner;
    }

    updateParcourVisits(pParcoursVisits) {
        this.loading = false;
        this.parcoursVisits = pParcoursVisits;
    }

    removeParcoursVisit(visit) {
        this.updateParcourVisits(
            this.parcoursVisits.filter(function (u) {
                return u.id != visit.id;
            })
        );
    }

    updateParcourVisit(parcoursVisit) {
        this.updateParcourVisits(PLUtil.updateOrPush(this.parcoursVisits, parcoursVisit));
    }

    addParcoursVisit(visit) {
        // add at first position
        this.parcoursVisits.unshift(visit);
    }

    setInvalidUser() {
        this.parcoursStatDic = null;
        this.parcoursVisits = null;
        this.parcoursList = null;
        this.loading = false;
        this.invalidUser = true;
    }
}

module.exports = alt.createStore(AnalyticsStore);
