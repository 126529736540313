const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require ('react-router-dom');
const linkUtil = require("linkUtil");

const SettlementDetailLink = ({settlementID, status, label}) => {
    let stat = "";
    if (status == "A") {
        stat = "/checkoutSuccess"
    } else {
        stat = "/checkoutError"
    }
    return <Link to={linkUtil.getLink("settlement/" + settlementID + stat)}>
        {label ? label : settlementID}
    </Link>
};
SettlementDetailLink.propTypes = {
    settlementID:PropTypes.string.isRequired,
    status:PropTypes.string,
    label:PropTypes.object
};
module.exports = SettlementDetailLink;
