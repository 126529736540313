const React = require("react"),
    PropTypes = require('prop-types');
const {Button, ListGroupItem} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");

const EntryRemovable = ({group, denyRemove, onRemove}) => {
    function remove(e) {
        onRemove(group);
    }
    return (<ListGroupItem>
        {group}
        {
            denyRemove ? null :
                <Button className="button-right" onClick={remove}>
                    <FontAwesome icon="minus"/>
                </Button>
        }
    </ListGroupItem>);
};
EntryRemovable.propTypes = {
    group: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    denyRemove: PropTypes.bool
};
module.exports = EntryRemovable;
