const React = require("react"),
    PropTypes = require('prop-types');
const {Col, Well} = require("react-bootstrap");

const FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    SubmitButton = require("components/form/SubmitButton");

const eventDetailActions = require("actions/EventDetailActions");

const messages = require("i18n/messages");

class EventPlayerBAProfileChange extends React.Component {
    constructor(props) {
        super(props);
        this.refDuration = React.createRef();
        this.updateEventPlayer = this.updateEventPlayer.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.state = {
            loading: true,
            baProfiles: null,
            baProfile: null
        }
    }
    componentDidMount() {
        eventDetailActions.loadBAProfile.defer(this.props.user, result => {
            let baProfile = result != null && result.length > 0 ? result[0] :null
            this.setState({baProfiles: result, loading: false, baProfile: baProfile})
        });
    }
    updateEventPlayer(baProfile) {
        this.setState({baProfile: baProfile})
    }
    saveEvent() {
        if (this.props.eventPlayer != null && this.props.onAfterChange && this.state.baProfile != null) {
            this.props.eventPlayer.setBAProfileID(this.state.baProfile);
            this.props.onAfterChange(this.props.eventPlayer)
        }
    }

    render() {
        const {loading, baProfiles, baProfile} = this.state;
        if (loading) {
            return <Col xs={12} sm={6}>
                <Loading/>
            </Col>
        } else if (baProfiles == null || baProfiles.length == 0) {
            return null
        }
        return <Col xs={12} sm={6}>
            <Well>
                <DropDownParseObject label={messages.get("profile.event.baProfile.label")}
                                     default={baProfiles[0]}
                                     onAfterChange={this.updateEventPlayer}
                                     objectList={baProfiles}
                                     buttonAfter={<SubmitButton bsStyle="primary"
                                                               onClick={this.saveEvent}
                                                               disabled={baProfile == null}
                                                               icon={<FontAwesome icon="save"/>}/>}/>

            </Well>
        </Col>
    }
}
EventPlayerBAProfileChange.propTypes = {
    user: PropTypes.object.isRequired,
    eventPlayer:PropTypes.object.isRequired,
    onAfterChange: PropTypes.func.isRequired
};
module.exports = EventPlayerBAProfileChange;