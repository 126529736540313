const React = require("react"),
    PropTypes = require('prop-types');
const {Col,Table} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TournamentCupLicLink = require("components/links/TournamentCupLicLink"),
    NumberInputModal  = require("components/modals/NumberInputModal"),
	messages = require("i18n/messages");

const TournamentCupResultTable = ({tournament, tournamentUserDic, showHighestZoneCounter, bowType, ageType, tuSex}) => {
    function getTableName(tRounds) {
        let header = "";
        if (tRounds != null && tRounds.length == 1) {
            header = tRounds[0].getName() + ": ";
        }
        if (bowType.get("code")) {
            header += bowType.get("code") + " - ";
        }
        if (ageType != null) {
            header += ageType.getCodeName();
        }
        if ("0" === tuSex) {
            header += " - " + messages.get("domain.user.detail.sex.0");
        } else if ("1" === tuSex) {
            header += " - " + messages.get("domain.user.detail.sex.1");
        }
        return header;
    }
    const key = tournamentUserDic.generateDicKey(bowType, ageType, tuSex);
    const groupedList = tournamentUserDic.getList(key);
    if (!groupedList) {
        // nothing to show
        return null;
    }
    let tableName = getTableName();
    let colSize = 12;
    return (<Col md={colSize} id={key}>
                <br/>
                <h4 className="ageTitle" >{tableName}</h4>
                <CupResultTable groupedTournamentUserList={groupedList}
                                showHighestZoneCounter={showHighestZoneCounter}
                                licColName={tournament.getBowUnionID().getCode() + "#"}/>
            </Col>)
};
TournamentCupResultTable.propTypes = {
    tournamentUserDic:PropTypes.object.isRequired,
    showHighestZoneCounter: PropTypes.bool.isRequired,
    ageType:PropTypes.object.isRequired,
    bowType:PropTypes.object,
    tuSex:PropTypes.string.isRequired
};
const CupResultTable = ({groupedTournamentUserList, showHighestZoneCounter, licColName}) => {
    let counter = 0;
    return (
        <Table striped>
            <TableHeaderCup showHighestZoneCounter={showHighestZoneCounter} licColName={licColName}/>
            <tbody className="bowage-table">
            {
                groupedTournamentUserList.map(tournamentUser => {
                    counter++;
                    if (showHighestZoneCounter) {
                        return (<TournamentCupManagerTableRow key={"TCTR_" + tournamentUser.id} position = {counter} tournamentUser={tournamentUser}/> );
                    } else {
                        return (<TournamentCupTableRow key={"TCTR_" + tournamentUser.id} position = {counter} tournamentUser={tournamentUser}/> );
                    }

                })}
            </tbody>
        </Table>
    )
};
CupResultTable.propTypes = {
    groupedTournamentUserList:PropTypes.array.isRequired,
    showHighestZoneCounter: PropTypes.bool.isRequired,
    licColName: PropTypes.string.isRequired
};
const TableHeaderCup = ({showHighestZoneCounter, licColName}) => {
    return (
        <thead className="bowage-head">
        <tr>
            <th className="col-md-1"><I18n code="tournament.table.column.position"/></th>
            <th className="col-md-1 printCol-40">{licColName}</th>
            <th className="col-md-3 printCol-100"><I18n code="tournament.table.column.name"/></th>
            <th className="col-md-3 printCol-100"><I18n code="tournament.table.column.union"/></th>
            <th className="col-md-3 printCol-100"><I18n code="tournament.info.cupChilds"/></th>
            {
                showHighestZoneCounter ? <React.Fragment>
                        <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.cupPoints"/></th>
                        <th>20/11er</th>
                        <th><I18n code="tournament.table.column.points"/></th>
                    </React.Fragment> :
                    <th className="col-md-1 printCol-45"><I18n code="tournament.table.column.points"/></th>
            }

        </tr>
        </thead>
    )
};
TableHeaderCup.propTypes = {
    showHighestZoneCounter: PropTypes.bool.isRequired,
    licColName: PropTypes.string.isRequired
};
const TournamentCupTableRow = ({tournamentUser, position}) => {
    return (
        <tr>
            <td>{position}</td>
            <td><TournamentCupLicLink tournamentUser={tournamentUser}/></td>
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getUnion()}</td>
            <td>{tournamentUser.getAllCupPoints() ? tournamentUser.getAllCupPoints().join(" | ") : ""}</td>
            <td>{tournamentUser.getCupPoints()}</td>
        </tr>)
};
TournamentCupTableRow.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    position:PropTypes.number.isRequired,
};
const TournamentCupManagerTableRow = ({tournamentUser, position}) => {
    const [cupPoints, setCupPoints] =  React.useState(tournamentUser.getCupPoints());
    function changeCupPoints(intPoints, floatPoints) {
        tournamentUser.setCupPoints(floatPoints)
        setCupPoints(floatPoints)
    }
    return (
        <tr>
            <td>{position}</td>
            <td><TournamentCupLicLink tournamentUser={tournamentUser}/></td>
            <td>{tournamentUser.getFullName()}</td>
            <td>{tournamentUser.getUnion()}</td>
            <td>{tournamentUser.getAllCupPoints() ? tournamentUser.getAllCupPoints().join(" | ") : ""}</td>
            <td>{cupPoints}&nbsp;&nbsp;<NumberInputModal number={cupPoints}
                                                         header={"Change cup points for " + tournamentUser.getFullName()}
                                                         saveChange={changeCupPoints}/>
            </td>
            <td>{tournamentUser.getFinalKillValue()}</td>
            <td>{tournamentUser.getSumPoints()}</td>
        </tr>)
};
TournamentCupManagerTableRow.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    position:PropTypes.number.isRequired,
};
module.exports = TournamentCupResultTable;