const alt = require("AltInit");
const managementActions = require("actions/ManagementActions");

class ManagementStore {
    constructor() {
        this.allManagements = null;
        this.management = null;
        this.parcoursOwner = null;
        this.showSpinner = false;
        this.club = null;
        this.bindListeners({
            onRegister: managementActions.register,
            onRegisterSuccess: managementActions.registerSuccess,
            onInitRequestParcoursManagePage: managementActions.initRequestParcoursManagePage,
            updateParcoursOwnerManager: managementActions.updateParcoursOwnerManager,
            onInitBusinessRegisterPage: managementActions.initBusinessRegisterPage,
            onInitBusinessRegisterPageSuccess: managementActions.initBusinessRegisterPageSuccess,
            onRequestParcoursManagement: managementActions.requestParcoursManagement,
            requestParcoursManagementSuccess: managementActions.requestParcoursManagementSuccess,
            onCancelManagementRequest: managementActions.cancelManagementRequest,
            cancelManagementRequestSuccess: managementActions.cancelManagementRequestSuccess,
            updateAllManagements: managementActions.updateAllManagements
        });
    }

    updateAllManagements(allManagements) {
        this.allManagements = allManagements
    }

    onRegister(management) {
        this.management = null;
    }

    onRegisterSuccess(management) {
        this.management = management;
    }

    onInitRequestParcoursManagePage(data) {
        this.management = null;
    }

    updateParcoursOwnerManager(data) {
        this.management = data.management;
        this.parcoursOwner = data.parcoursOwner;
    }

    onInitBusinessRegisterPage() {
        this.showSpinner = true;
        this.management = null;
    }

    onInitBusinessRegisterPageSuccess(data) {
        this.showSpinner = false;
        this.management = data.management;
        this.club = data.club;
    }

    onRequestParcoursManagement() {
        this.showSpinner = true;
    }

    requestParcoursManagementSuccess(management) {
        this.showSpinner = false;
        this.management = management;
        if (this.allManagements == null) {
            this.allManagements = [];
        }
        this.allManagements.unshift(management)
    }

    onCancelManagementRequest() {
        this.showSpinner = true;
    }

    cancelManagementRequestSuccess() {
        this.showSpinner = false;
        this.management = null;
    }

}

module.exports = alt.createStore(ManagementStore);
