const alt = require("AltInit");
const releaseDao = require("actdao/ReleasesDao");
const countTypeDao  = require("actdao/CountTypeDao");
const parseDao = require("actdao/ParseBaseDao");
const notificationActions = require("components/notification/NotificationActions");
const messages = require("i18n/messages");
class SKillBoardActions {

    queryReleases() {
        releaseDao.queryReleases()
            .then(releases => {
                this.updateReleases(releases);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    showCountTypes() {
        this.queryCountTypesDetails();
        this.queryZoneNames();
        this.queryCountTypesGroups();
        this.queryCountTypes();
        return {};
    }

    queryZoneNames() {
        countTypeDao.queryZoneNames()
            .then(zoneNames => {
                this.updateZoneNames(zoneNames);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    queryCountTypesGroups() {
        countTypeDao.queryCountTypesGroups()
            .then(countTypeGroups => {
                this.updateCountTypeGroups(countTypeGroups);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    queryCountTypes() {
        countTypeDao.queryCountTypes()
            .then(countTypes => {
                this.updateCountTypes(countTypes);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    queryCountTypesDetails() {
        countTypeDao.queryCountTypesDetails()
            .then(countTypeDetails => {
                this.updateCountTypeDetails(countTypeDetails);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    validateUniqueCountType(name, missPoints, countTypeDetails, countTypes, cbValidationError) {
        const length = countTypes.length;
        for (let i = 0; i < length; i++) {
            const countType = countTypes[i];
            if (countType.getName() == name || messages.get(countType.getName()) ==  name) {
                cbValidationError("countType.error.duplicateName", countType);
                return false;
            }
            if (countType.equalsCountTypeDetailsID(countTypeDetails) && countType.getMissPoints() == missPoints) {
                cbValidationError("countType.error.duplicate", countType);
                return false;
            }
        }
        return true;
    }

    createCountType(countType) {
        parseDao.saveObject(countType)
            .then(sCountType => {
                this.addCountType(sCountType);
                notificationActions.success("countType.save.success");
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    saveCountType(countType) {
        parseDao.saveObject(countType)
            .then(sCountType => {
                this.addCountType(sCountType);
            })
            .catch(error => {
                notificationActions.parseError(error);
            });
        return {};
    }

    updateReleases(pReleases) {
        return pReleases;
    }

    updateCountTypes(pCountTypes) {
        return pCountTypes;
    }

    addCountType(pCountType) {
        return pCountType;
    }

    updateCountTypeDetails(countTypeDetails) {
        return countTypeDetails
    }

    updateCountTypeGroups(countTypeGroups) {
        return countTypeGroups;
    }

    updateZoneNames(zoneNames) {
        return zoneNames;
    }

    updateShowForm(pShowForm) {
        return pShowForm;
    }

    searchCountTypes(searchParams) {
        return searchParams
    }
}

module.exports = alt.createActions(SKillBoardActions);
