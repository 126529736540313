const React = require("react"),
    PropTypes = require('prop-types'),
    ReactDOM = require("react-dom");
const {Row, Col, Alert, Button, Modal, FormGroup, InputGroup, FormControl} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ValidInput = require("components/form/ValidInput"),
    SubTitle = require("components/widgets/SubTitle"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SelectInputTypeAhead = require("components/form/SelectInputTypeAhead"),
    Utility = require("util/Utility");

var messages = require("i18n/messages");

var Parse = require("ParseInit"),
    Tournament = require("parse/Tournament"),
    TournamentConfig = require("parse/TournamentConfig"),
    TournamentUser = require("parse/TournamentUser"),
    TournamentUserTeam = require("parse/TournamentUserTeam"),
    ParseError = require("parse/ParseError");

var tournamentUserActions = require("actions/TournamentUserActions"),
    userStore = require("stores/UserStore"),
    tournamentRegisterModalStore = require("stores/TournamentRegisterModalStore"),
    tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions");

class TournamentRegisterModalPairs extends React.Component {
    constructor(props) {
        super(props);
        this.refTeam = React.createRef();
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refMail2 = React.createRef();
        this.refName2 = React.createRef();
        this.refSurname2 = React.createRef();
        this.refSex = React.createRef();
        this.refSex2 = React.createRef();

        this.handleUserStoreChange = this.handleUserStoreChange.bind(this);
        this.handleModalStoreChange = this.handleModalStoreChange.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.noEmail = this.noEmail.bind(this);
        this.handleTournamentUserInit = this.handleTournamentUserInit.bind(this);
        this.fillFormWithTournamentUser = this.fillFormWithTournamentUser.bind(this);
        this.hasValidUser = this.hasValidUser.bind(this);
        this.getPaymentCondition = this.getPaymentCondition.bind(this);
        this.getUserDetailDefault = this.getUserDetailDefault.bind(this);
        this.getUserMailDefault = this.getUserMailDefault.bind(this);
        this.getSexSelectValue = this.getSexSelectValue.bind(this);
        this.closeDialog = this.closeDialog.bind(this);

        this.state = {
            submitDisabled: false,
            tournamentRegisterModalStore: tournamentRegisterModalStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserStoreChange);
        tournamentRegisterModalStore.listen(this.handleModalStoreChange);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserStoreChange);
        tournamentRegisterModalStore.unlisten(this.handleModalStoreChange);
    }
    handleUserStoreChange(state) {
        this.setState({
            userStore: state,
            error: null
        });
    }
    handleModalStoreChange(state) {
        this.setState({
            tournamentRegisterModalStore: state,
            error: null
        });
    }
    valid() {
        var that = this;
        var valid = true;
        var required = [
            this.refTeam.current.getValue(),
            this.refName.current.getValue(),
            this.refSurname.current.getValue(),
            this.refMail.current.getValue(),
            this.refName2.current.getValue(),
            this.refSurname2.current.getValue(),
            this.refMail2.current.getValue()
        ];

        for (var i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        if (this.refMail.current.getValue() ===  this.refMail2.current.getValue()) {
            this.setState({error: messages.get("tournament.error.emaildoubled") + " " + this.refMail.current.getValue() + ". " + messages.get("tournament.register.placeHolder.noemail") + this.noEmail()});
            valid = false;
        }
        if (this.state.tournamentRegisterModalStore.selectedBowType === null || this.state.tournamentRegisterModalStore.selectedBowType2nd === null
            || this.state.tournamentRegisterModalStore.selectedAgeType === null) {
            this.setState({error: messages.get("tournament.create.error.required")});
            valid = false;
        }
        return valid;
    }
    noEmail() {
        return Utility.buildNoEmail(this.refName.current.getValue(), this.refSurname.current.getValue());
    }
    handleSubmit(e) {
        var that = this;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        // check email unique
        // TODO this is a slow query and takes approx 1,6 sec (find does not help)
        // TODO obviously check email immediately after entering it
        var tuMail = this.refMail.current.getValue().trim().toLowerCase();
        var tuMail2 = this.refMail2.current.getValue().trim().toLowerCase();
        var query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.containedIn(TournamentUser.prototype.col.playerEmail, [tuMail, tuMail2]);
        query.equalTo(TournamentUser.prototype.col.tournamentID, this.state.tournamentRegisterModalStore.tournament);
        query.count()
            .then(count => {
                if (count > 0) {
                    // already player with this email for this tournament!
                    that.setState({
                        submitDisabled: false,
                        error: messages.get("tournament.error.emailInUse") + " " + tuMail+ " oder " + tuMail2 + ". " + messages.get("tournament.register.placeHolder.noemail")
                    });
                    return;
                } else {
                    // horray email not used yet
                    that.handleTournamentUserInit(tuMail, tuMail2);
                }
            })
            .catch(error => {
                that.setState({submitDisabled: false, error: messages.get("parse.error.unexpected") + ParseError.getTranslatedErrorMessage(error)});
                return;
            });
    }
    handleTournamentUserInit(tuMail, tuMail2) {
        console.log("handleTournamentUserInit:" + tuMail + "," + tuMail2);
        var that = this;
        var payRef = new Date().getTime();
        // archer 1
        var tournamentUser = new TournamentUser();
        // tournament values
        tournamentUser.set("parcoursID", this.state.tournamentRegisterModalStore.tournament.getParcoursID());
        tournamentUser.set("tournamentID", this.state.tournamentRegisterModalStore.tournament);
        tournamentUser.set("tournamentStringID", this.state.tournamentRegisterModalStore.tournament.id);
        tournamentUser.set("status", "R");
        // form values
        tournamentUser.setFullName(this.refName.current.getValue().trim(), this.refSurname.current.getValue().trim());
        tournamentUser.set("playerEmail", tuMail);
        tournamentUser.set("sex", parseInt(ReactDOM.findDOMNode(this.refSex.current).value));
        tournamentUser.set("tournamentConfigBow", this.state.tournamentRegisterModalStore.selectedBowType);
        tournamentUser.set("tournamentConfigAge", this.state.tournamentRegisterModalStore.selectedAgeType);
        tournamentUser.set("payAmount", (this.state.tournamentRegisterModalStore.price / 2));
        tournamentUser.set("payRefID", payRef.toString());
        // archer2
        var tournamentUser2 = new TournamentUser();
        // tournament values
        tournamentUser2.set("parcoursID", this.state.tournamentRegisterModalStore.tournament.getParcoursID());
        tournamentUser2.set("tournamentID", this.state.tournamentRegisterModalStore.tournament);
        tournamentUser2.set("tournamentStringID", this.state.tournamentRegisterModalStore.tournament.id);
        tournamentUser2.set("status", "R");
        // form values
        tournamentUser2.setFullName(this.refName2.current.getValue().trim(), this.refSurname2.current.getValue().trim());
        tournamentUser2.set("playerEmail", tuMail2);
        tournamentUser2.set("sex", parseInt(ReactDOM.findDOMNode(this.refSex2.current).value));
        tournamentUser2.set("tournamentConfigBow", this.state.tournamentRegisterModalStore.selectedBowType2nd);
        tournamentUser2.set("tournamentConfigAge", this.state.tournamentRegisterModalStore.selectedAgeType);
        tournamentUser2.set("payAmount", (this.state.tournamentRegisterModalStore.price / 2));
        tournamentUser2.set("payRefID", (payRef + 1).toString());
        if (this.props.user != null) {
            tournamentUser.set("editor", this.props.user);
            tournamentUser2.set("editor", this.props.user);
            tournamentUser.set("userID", this.props.user);
        } else {
            tournamentUser.set("editor", Parse.User.current());
            tournamentUser2.set("editor", Parse.User.current());
        }
        // team
        var tuTeam = new TournamentUserTeam();
        tuTeam.setTournamentID(this.state.tournamentRegisterModalStore.tournament);
        tuTeam.setName(this.refTeam.current.getValue().trim());
        tuTeam.setStatus(TournamentUserTeam.prototype.status.registered);
        tuTeam.setTournamentConfigAge(this.state.tournamentRegisterModalStore.selectedAgeType);
        var tuList = [];
        tuList.push(tournamentUser);
        tuList.push(tournamentUser2);
        tuTeam.setTournamentUserIDs(tuList);
        tournamentUserActions.createTournamentUserTeamRegistration(that.state.tournamentRegisterModalStore.tournament, tuTeam);
        this.closeDialog();
    }
    fillFormWithTournamentUser(tUser) {
        console.log("fill form with tUser" + tUser.id);
        if (tUser.getSurname() != null) {
            this.refName2.current.setValue(tUser.getName());
            this.refSurname2.current.setValue(tUser.getSurname());
        } else {
            // handling for older entries without one name column
            var fullname = tUser.getName().trim();
            var fullNames = fullname.split(' ');
            if (fullNames != null && fullNames.length > 1) {
                var name = fullNames[0];
                var surname = null;
                if (fullNames.length > 2) {
                    fullNames.shift();
                    surname = fullNames.join(' ');
                } else {
                    surname = fullNames[1];
                }
                this.refName2.current.setValue(name);
                this.refSurname2.current.setValue(surname);
            } else {
                // very unlikely
                this.refName2.current.setValue(fullname);
            }
        }
        this.refMail2.current.setValue(tUser.getPlayerEmail());
    }
    hasValidUser() {
        return this.props.user != null;
    }
    getPaymentCondition() {
        var tPrice = this.state.tournamentRegisterModalStore.tournament.getTournamentPriceID();
        if (tPrice) {
            return tPrice.getPaymentCondition();
        }
    }
    getUserDetailDefault(key) {
        if (this.state.tournamentRegisterModalStore.selfRegistration) {
            if (this.state.tournamentRegisterModalStore.userDetail != null) {
                return this.state.tournamentRegisterModalStore.userDetail.get(key);
            }
        }
        return "";
    }
    getUserMailDefault() {
        if (this.state.tournamentRegisterModalStore.selfRegistration) {
            if (this.hasValidUser()) {
                return this.props.user.get("email");
            }
        }
        return "";
    }
    getSexSelectValue() {
        if (this.state.tournamentRegisterModalStore.selfRegistration) {
            if (this.state.tournamentRegisterModalStore.userDetail != null) {
                return this.state.tournamentRegisterModalStore.userDetail.getSex();
            }
        }
        // return male
        return 0;
    }
    closeDialog() {
        this.setState({submitDisabled: false});
        tournamentRegisterModalActions.closeDialog();
    }
    render() {
        var curr = "E";
        var validateNow = this.state.error && this.state.error.length > 0;
        var that = this;
        if (this.state.tournamentRegisterModalStore.tournament != null) {
            var tPrice = this.state.tournamentRegisterModalStore.tournament.getTournamentPriceID();
            if (tPrice) {
                curr = tPrice.getCurrency();
            }
        }
        return (
            <Modal backdrop="static" show={this.state.tournamentRegisterModalStore.show} onHide={this.closeDialog}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <SubTitle><I18n code="tournament.register.title"/></SubTitle>
                            <p>{"Sie benötigen unterschiedliche E-Mails für die beiden Schützen/innen!"}</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.tournamentRegisterModalStore.loading === true || this.state.tournamentRegisterModalStore.tournament == null?
                                <Loading/>
                                :
                                <Row>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refTeam}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.table.column.teamname")}/>
                                        <TournamentConfigAgeSelectPair defaultValue={this.state.tournamentRegisterModalStore.selectedAgeTypeID}
                                                                   tournament={this.state.tournamentRegisterModalStore.tournament}/>
                                        <p><b>{messages.get("tournament.table.column.archer") + " 1"}</b></p>
                                    </Col>
                                    <Col sm={6}>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 30, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.name")}
                                                    default={this.getUserDetailDefault("name")}/>
                                    </Col>
                                    <Col sm={6}>
                                        <ValidInput ref={this.refSurname}
                                                    valid={{maxLength: 30, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.surname")}
                                                    default={this.getUserDetailDefault("surname")}/>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroup.Addon><FontAwesome icon="transgender"/></InputGroup.Addon>
                                                <FormControl ref={this.refSex} componentClass="select" placeholder="select" defaultValue={that.getSexSelectValue()}>
                                                    {[0, 1].map(function (sex) {
                                                        return <option key={sex} value={sex}>{messages.get("domain.user.detail.sex." + sex)}</option>
                                                    })}
                                                </FormControl>
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>
                                    <Col sm={6}>
                                        <TournamentConfigBowSelectPair defaultValue={this.state.tournamentRegisterModalStore.selectedBowTypeID}
                                                                   tournament={this.state.tournamentRegisterModalStore.tournament}/>
                                    </Col>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refMail}
                                                    valid={{maxLength: 50, check:['isRequired','isMail']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.email")}
                                                    addonBefore={<FontAwesome icon="envelope"/>}
                                                    default={this.getUserMailDefault()}/>
                                    </Col>
                                    <Col  sm={12}>
                                        <p><b>{messages.get("tournament.table.column.archer") + " 2"}</b></p>
                                        {
                                            this.state.tournamentRegisterModalStore.registeredTournamentUsers != null ?
                                                <SelectInputTypeAhead
                                                    objectList={this.state.tournamentRegisterModalStore.registeredTournamentUsers}
                                                    handleSelectedObject={this.fillFormWithTournamentUser}
                                                    placeholder={messages.get("tournament.register.search4name")}/> : null
                                        }
                                        <br/>
                                    </Col>
                                    <Col sm={6}>
                                        <ValidInput ref={this.refName2}
                                                    valid={{maxLength: 30, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.name")}/>
                                    </Col>
                                    <Col sm={6}>
                                        <ValidInput ref={this.refSurname2}
                                                    valid={{maxLength: 30, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.surname")}/>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <InputGroup>
                                                <InputGroup.Addon><FontAwesome icon="transgender"/></InputGroup.Addon>
                                                <FormControl ref={this.refSex2} componentClass="select" placeholder="select">
                                                    {[0, 1].map(function (sex) {
                                                        return <option key={sex} value={sex}>{messages.get("domain.user.detail.sex." + sex)}</option>
                                                    })}
                                                </FormControl>
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>
                                    <Col sm={6}>
                                        <TournamentConfigBowSelectPair secondArcher={true} defaultValue={this.state.tournamentRegisterModalStore.selectedBowTypeID}
                                                                   tournament={this.state.tournamentRegisterModalStore.tournament}/>
                                    </Col>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refMail2}
                                                    valid={{maxLength: 50, check:['isRequired','isMail']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("tournament.register.email")}
                                                    addonBefore={<FontAwesome icon="envelope"/>}/>
                                    </Col>
                                    <Col sm={12}>
                                        <br/><label>{messages.get("tournament.register.label.price")}: </label>{curr + " " + this.state.tournamentRegisterModalStore.price}
                                        <br/><label>{messages.get("tournament.register.label.payCondition")}: </label>{this.getPaymentCondition()}
                                    </Col>
                                </Row>
                        }
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert> : ""}
                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        {
                            this.state.tournamentRegisterModalStore.loading ? null :
                                <Button type="submit" disabled={this.state.submitDisabled} bsStyle="primary">{messages.get("tournament.register.submit")}</Button>
                        }
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
const TournamentConfigBowSelectPair  = ({tournament, defaultValue, secondArcher}) => {
    function changeTournamentConfig(event) {
        var configBowTypes = tournament.getTournamentConfigBow();
        if (configBowTypes) {
            configBowTypes.map(configBowType => {
                if (configBowType.id == event.target.value) {
                    if (secondArcher === true) {
                        tournamentRegisterModalActions.updateSelectedConfigBow2nd(configBowType);
                    } else {
                        tournamentRegisterModalActions.updateSelectedConfigBow(configBowType);
                    }

                }
            })
        }
    }
    function addTournamentConfigOptions() {
        var configTypes = tournament.getTournamentConfigBow();
        if (!configTypes) {
            return (<option>Ups, keine Klassen gefunden</option>);
        } else {
            return configTypes.map(configType => {
                return <option key={configType.id} id={configType.id} value={configType.id}>{configType.getCodeName()}</option>;
            })
        }
    }
    return (<FormGroup>
                <InputGroup>
                    <InputGroup.Addon><FontAwesome icon="bullseye"/></InputGroup.Addon>
                        <FormControl componentClass="select" placeholder="select"
                                     defaultValue={defaultValue}
                                     onChange={changeTournamentConfig}>
                            {addTournamentConfigOptions()}
                        </FormControl>
                </InputGroup>
            </FormGroup>);
};
TournamentConfigBowSelectPair.propTypes = {
    tournament: PropTypes.object.isRequired,
    defaultValue: PropTypes.object,
    secondArcher: PropTypes.bool
};
const TournamentConfigAgeSelectPair = ({tournament, defaultValue}) => {
    function changeTournamentConfig(event) {
        var configAgeTypes = tournament.getTournamentConfigAge();
        if (configAgeTypes) {
            configAgeTypes.map(configAgeType => {
                if (configAgeType.id == event.target.value) {
                    tournamentRegisterModalActions.updateSelectedConfigAge(configAgeType);
                }
            })
        }
    }
    function addTournamentConfigOptions() {
        var configTypes = tournament.getTournamentConfigAge();
        if (!configTypes) {
            return (<option>Ups, keine Klassen gefunden</option>);
        } else {
            return configTypes.map(configType => {
                return <option key={configType.id} id={configType.id} value={configType.id}>{configType.getCodeName()}</option>;
            })
        }
    }
    return (<FormGroup>
                <InputGroup>
                    <InputGroup.Addon><FontAwesome icon="book"/></InputGroup.Addon>
                        <FormControl componentClass="select" placeholder="select"
                                     defaultValue={defaultValue}
                                     onChange={changeTournamentConfig}>
                            {addTournamentConfigOptions()}
                        </FormControl>
                </InputGroup>
            </FormGroup>);
};
TournamentConfigAgeSelectPair.propTypes = {
    tournament: PropTypes.object.isRequired,
    defaultValue: PropTypes.object
};
module.exports = TournamentRegisterModalPairs;
