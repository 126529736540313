const React = require("react"),
    PropTypes = require('prop-types');
const {Row} = require("react-bootstrap");
const TournamentTeamResultTable = require("components/tournament/TournamentTeamResultTable"),
    PanelCollapse = require("components/widgets/PanelCollapse");

const TournamentTeamResultGrouping  = ({tournament, tournamentUserResult, tournamentRoundMap}) => {
    const primeTypes = tournamentUserResult.getTournamentPrimeGroup();
    const secTypes = tournamentUserResult.getTournamentSecondGroup();
    let killTextHeader = "Kills";
    if (primeTypes && primeTypes.length > 0) {
        // sortedTeams
        return (
            <div>
                {
                    primeTypes.map(primeType => {
                        killTextHeader = primeType.generateKillTextHeader();
                        return (
                            <PanelCollapse key={primeType.id} id={primeType.id} header={<h3 className="bowTitle">{primeType.getCodeName()}</h3>}>
                                <Row>
                                    {
                                        secTypes.map(secType =>{
                                            if (secType == null) {
                                                // no second type - team table
                                                const key = tournamentUserResult.generateDicKey(primeType, secType, "x");
                                                return (
                                                    <TournamentTeamResultTable key={key} killColumnName={killTextHeader}
                                                                               tournamentUserDic={tournamentUserResult}
                                                                               secType={secType} primeType={primeType} tuSex="x"/>
                                                )
                                            }
                                        })}
                                </Row>
                            </PanelCollapse>
                        );
                    })
                }
            </div>
        )
    } else {
        // only one group
        const key = tournamentUserResult.generateDicKey(null, null, "x");
        return (
            <PanelCollapse header={<h3 className="bowTitle">{tournamentRoundMap.qualify != null && tournamentRoundMap.qualify.length > 0 ? tournamentRoundMap.qualify[0].getName() : ""}</h3>}>
                <Row>
                    <TournamentTeamResultTable key={key} killColumnName={killTextHeader}
                                               tournamentUserDic={tournamentUserResult}
                                               secType={null} primeType={null} tuSex="x"/>
                </Row>
            </PanelCollapse>
        );
    }
};
TournamentTeamResultGrouping.propTypes = {
    tournament:PropTypes.object.isRequired,
    tournamentUserResult:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object,
    user:PropTypes.object
};
module.exports = TournamentTeamResultGrouping;