const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Button, Table} = require("react-bootstrap");

const Loading = require("components/widgets/Loading"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    ValidInput = require("components/form/ValidInput"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SubmitButton = require("components/form/SubmitButton"),
    I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");
const Utility = require("util/Utility"),
    ParseListUtility = require("util/ParseListUtility");
const TournamentMMLoader = require("components/tournamentmanage/TournamentMMLoader");

const tournamentUserManagerStore = require("stores/TournamentUserManagerStore"),
    tournamentResultActions = require("actions/TournamentResultActions");


const TournamentMMTCorrectPage = ({tournamentId}) =>{
        return <TournamentMMLoader tournamentId={tournamentId}>
            <TournamentTCorrection />
        </TournamentMMLoader>
};
TournamentMMTCorrectPage.propTypes = {
    tournamentId: PropTypes.string.isRequired
};
class TournamentTCorrection extends React.Component {
    constructor(props) {
        super(props);
        this.refTargets = React.createRef();
        this.refSwitchPointsButton = React.createRef();
        this.handleTournamentUserChange = this.handleTournamentUserChange.bind(this);
        this.changeSelectedRound = this.changeSelectedRound.bind(this);
        //this.handleRemoveTargets = this.handleRemoveTargets.bind(this);
        this.loadRoundResults = this.loadRoundResults.bind(this);
        this.switchPoints = this.switchPoints.bind(this);
        this.removeTarget = this.removeTarget.bind(this);
        this.resetCorrection = this.resetCorrection.bind(this);
        this.state = {
            selRound: null,
            tRoundUserResults: null
        }
    }
    componentDidMount() {
        tournamentUserManagerStore.listen(this.handleTournamentUserChange);
    }
    loadRoundResults(selRound) {
        tournamentResultActions.loadTournamentRoundResult.defer(selRound, cbResult => {
            this.setState({tRoundUserResults: cbResult});
            this.refSwitchPointsButton.current.resetLoading();
        })
    }
    componentWillUnmount() {
        tournamentUserManagerStore.unlisten(this.handleTournamentUserChange);
    }
    handleTournamentUserChange(state) {
        this.setState({tournamentUserManagerStore: state});
    }
    changeSelectedRound(round) {
        this.setState({selRound: round});
        this.loadRoundResults(round)
    }
    resetCorrection(tUserRoundResult) {
        tUserRoundResult.resetCorrectionPoints();
        tUserRoundResult.save(null).then(tUpdTUserRoundResult => {
            let tRUsers = this.state.tRoundUserResults;
            let newTRUsers = ParseListUtility.updateOrPush(tRUsers, tUpdTUserRoundResult);
            this.setState({tRoundUserResults:newTRUsers })
        })
    }
    removeTarget(tUserRoundResult) {
        if (this.refTargets.current != null && this.refTargets.current.getValue()) {
            let targetsCSV = this.refTargets.current.getValue();
            const targetNrArr = [];
            targetsCSV.split(",").map(targetNr =>  {
                targetNrArr.push(parseInt(targetNr))
            });
            tournamentResultActions.removeTargetsFromResult(targetNrArr, this.state.selRound, tUserRoundResult, tUpdTUserRoundResult => {
                let tRUsers = this.state.tRoundUserResults;
                let newTRUsers = ParseListUtility.updateOrPush(tRUsers, tUpdTUserRoundResult);
                this.setState({tRoundUserResults:newTRUsers })
            })
        }
    }
    switchPoints() {
        const {tRoundUserResults, selRound} = this.state;
        tournamentResultActions.switchWithCorrPoints(tRoundUserResults, callback => {
            this.loadRoundResults(selRound)
        })
    }
    buildTRoundList(tournamentRoundMap) {
        let list = []
        list = list.concat(tournamentRoundMap.qualify)
        list = list.concat(tournamentRoundMap.finale)
        list = list.concat(tournamentRoundMap.group)
        return list;
    }
    render() {
        const {tournament, tournamentRoundMap} = this.props;
        const {tRoundUserResults, selRound} = this.state;
        const tournamentRoundsAll = this.buildTRoundList(tournamentRoundMap);
        return (<React.Fragment>
            <Row>
                <Col lg={12}>
                    <h1>{tournament.getName() + ", " + tournament.getTournamentDate()}</h1>
                </Col>
                <Col xs={12}>
                    <h3>Remove targets from result for a round</h3>
                    <ol>
                        <li>Select round</li>
                        <li>Click "Remove Targets" for the entries where the points need to be calculated without the targets from input field</li>
                        <li>Last -> Click "Switch Points" to replace round points with corr points for all entries at once</li>
                    </ol>
                    <p>
                    {
                        tRoundUserResults != null ? <span>Loaded: {tRoundUserResults.length} round results</span> : null
                    }
                    {
                        selRound != null ? <span> for selected round: {selRound.getName()}</span> : null
                    }
                    </p>
                </Col>
                <Col xs={4}>
                    <DropDownParseObject label={messages.get("tournament.modal.score.tround")}
                                         placeholder={<option key={"nullkey"} value={"null"}>Select round to load results</option>}
                                         default={selRound}  onAfterChange={this.changeSelectedRound}
                                         objectList={tournamentRoundsAll}/>
                </Col>
                <Col xs={4}>
                    <ValidInput ref={this.refTargets}
                                label="Target numbers to remove (csv)"
                                onAfterChange={this.setChanged}
                                valid={{maxLength: 1500, check:['isRequired']}}/>
                </Col>
                <Col xs={4}><br/>
                    <SubmitButton ref={this.refSwitchPointsButton}
                                  disabled={tRoundUserResults == null || selRound == null}
                                  onClick={() => this.switchPoints()}
                                  icon={<FontAwesome icon="cogs"/>}
                                  text="SWITCH POINTS"
                                  bsStyle="warning"/>
                </Col>
            </Row>
            <hr/>
            <Row>
                <Table>
                    <thead>
                    <tr>
                        <th><I18n code={"parcours.articles.form.name"}/></th>
                        <th>Classes</th>
                        <th>Round points</th>
                        <th>Kills</th>
                        <th>Corr points</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Utility.isSet(tRoundUserResults) ?
                        tRoundUserResults.map(tRoundUserResult => {
                                return <TournamentUserRow id={tRoundUserResult.id}  tRoundUserResult={tRoundUserResult}
                                                          removeTarget={this.removeTarget}
                                                          resetCorrection={this.resetCorrection}/>

                        })
                        : null}
                    </tbody>
                </Table>
            </Row>
        </React.Fragment>)
    }
}
TournamentTCorrection.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired,
    tournamentRoundMap:PropTypes.object
};
const TournamentUserRow = ({tRoundUserResult, removeTarget, resetCorrection}) => {
    function handleRemove() {
        removeTarget(tRoundUserResult)
    }
    function handleReset() {
        resetCorrection(tRoundUserResult)
    }
    const tournamentUser= tRoundUserResult.getTournamentUserID();
    return <tr>
        <td>{tournamentUser.getFullName()} ({tournamentUser.id})</td>
        <td>{tournamentUser.getTournamentConfigBowCode()}, {tournamentUser.getTournamentConfigAgeCode()}</td>
        <td>{tRoundUserResult.getRoundPoints()}</td>
        <td>{tRoundUserResult.getRoundKillCounts2String()}</td>
        <td>{tRoundUserResult.getCorrectionPoints()}</td>
        <td><Button onClick={handleRemove}>Remove Targets </Button><Button onClick={handleReset}> X </Button></td>
    </tr>
}
module.exports = TournamentMMTCorrectPage;
