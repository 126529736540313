const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, ButtonGroup, Button } = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ImageBanner = require("components/widgets/ImageBanner"),
    TournamentTeaserItem= require("components/tournament/TournamentTeaserItem"),
    ParcoursContact = require("components/parcours/ParcoursContact"),
    ParcoursHomeItem = require("components/parcours/ParcoursHomeItem");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const parcoursMgmtActions = require("actions/ParcoursMgmtActions");
/**
 * Home of parcoursOwner - shows all parcours and tournaments.
 * start with id of parcoursOwner.
 */
class ParcoursLandingPage extends React.Component{
    constructor(props) {
        super(props);
        this.updateParcours = this.updateParcours.bind(this);
        this.queryTournaments = this.queryTournaments.bind(this);
        this.updateTournamentMode = this.updateTournamentMode.bind(this);
        this.state = {
            loadingParcours: true,
            loadingTournament: true,
            parcoursOwner: null,
            parcoursList: null,
            actualTournaments: null,
            archiveTournaments: null,
            tournamentMode: "current"
        }
    }
    componentDidMount() {
        parcoursMgmtActions.getParcoursHome(this.props.ownerID, (parcoursCallback) => {
            this.updateParcours(parcoursCallback);
        });
    }
    queryTournaments(parcoursOwner, parcoursList) {
        if (parcoursOwner.hasManagementID()) {
            parcoursMgmtActions.getTournamentsHome.defer(parcoursOwner, parcoursList, (tournaments) => {
                let actTournaments = [];
                let archTournaments = [];
                const dateFilter = new Date();
                dateFilter.setMonth(dateFilter.getMonth() - 1);
                tournaments.map(tournament => {
                    if (tournament.getEndDate() > dateFilter) {
                        actTournaments.push(tournament)
                    } else {
                        archTournaments.push(tournament);
                    }

                });

                this.setState({
                    loadingTournament: false,
                    actualTournaments: actTournaments,
                    archiveTournaments: archTournaments
                })
            });
        } else {
            this.setState({
                loadingTournament: false,
                actualTournaments: null,
                archiveTournaments: null
            })
        }

    }
    updateParcours(parcoursCallback) {
        if (parcoursCallback != null) {
            this.setState({
                loadingParcours: false,
                parcoursOwner: parcoursCallback.parcoursOwner,
                parcoursList: parcoursCallback.parcoursList
            });
            this.queryTournaments(parcoursCallback.parcoursOwner, parcoursCallback.parcoursList)
        } else {
            this.setState({
                loadingParcours: false,
                parcoursOwner: null,
                parcoursList: null
            });
        }
    }
    updateTournamentMode(mode) {
        this.setState({tournamentMode: mode})
    }
    render() {
        const {loadingParcours, loadingTournament, parcoursOwner, parcoursList, actualTournaments, archiveTournaments, tournamentMode} = this.state;
        if (loadingParcours) {
            return <Grid>
                <Row>
                    <Col xs={12}>
                        <Loading/>
                    </Col>
                </Row>
            </Grid>
        } else if (parcoursOwner == null) {
            return <Grid>
                <Row>
                    <Col xs={12}>
                        <Alert bsStyle="warning"><I18n code="list.entry.deleted"/></Alert>
                    </Col>
                </Row>
            </Grid>
        } else {
            return <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb code={parcoursOwner.getName()} active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <ImageBanner imageObject={parcoursOwner.getBannerImageID()}/>
                </Row>
                <ParcoursLanding parcoursOwner={parcoursOwner} parcoursList={parcoursList} />
                <hr/>
                <TournamentLanding loading={loadingTournament} mode={tournamentMode}
                                   typeSwitch={<TournamentTypeSwitch handleModeChange={this.updateTournamentMode} mode={tournamentMode} />}
                                   actualTournaments={actualTournaments} archiveTournaments={archiveTournaments}/>

            </Grid>
        }
    }
}
ParcoursLandingPage.propTypes = {
    ownerID:PropTypes.string.isRequired,
};
function ParcoursLanding({parcoursOwner, parcoursList}) {
    return <Row>
        <Col sm={8}>
            <h3>{messages.get("Parcours")}</h3>
            {
                parcoursList.map(parcours => {
                    return <ParcoursHomeItem key={parcours.id} parcours={parcours} />
                })
            }
        </Col>
        <Col sm={4}>
            <ParcoursContact parcoursOwner={parcoursOwner}/>
        </Col>
    </Row>
}
ParcoursLanding.propTypes = {
    parcoursOwner:PropTypes.object.isRequired,
    parcoursList:PropTypes.array.isRequired
};
function TournamentTypeSwitch({mode, handleModeChange}) {
    return <ButtonGroup>
        <Button onClick={() => handleModeChange("current")} className={mode === "current" ? "active" : ""}>
            <FontAwesome icon="calendar-alt"/><I18n code="tournament.filter.current"/>
        </Button>
        <Button onClick={() => handleModeChange("past")} className={mode === "past" ? "active" : ""}>
            <FontAwesome icon="trophy"/><I18n code="tournament.filter.results"/>
        </Button>
    </ButtonGroup>
}
TournamentTypeSwitch.propTypes = {
    handleModeChange: PropTypes.func.isRequired,
    mode: PropTypes.string.isRequired
};
function TournamentLanding({loading, mode, typeSwitch, actualTournaments, archiveTournaments}) {
    const current = mode == "current";
    const tournaments = current ? actualTournaments : archiveTournaments;
    if (tournaments == null && loading === false) {
        return null;
    }
    return <Row>
        <Col xs={12}>
            <h3>{messages.get("header.nav.tournaments")}</h3>
            {typeSwitch}
            <br/><br/>
            {
                loading ?
                    <Loading />
                    :
                    tournaments != null && tournaments.length > 0 ?
                        tournaments.map(tournament => {
                            return <TournamentTeaserItem key={tournament.id} tournament={tournament} />
                        })
                        : <Alert bsStyle="warning"><I18n code="tournament.empty.result"/></Alert>
            }
        </Col>
    </Row>
}
TournamentLanding.propTypes = {
    loading: PropTypes.bool.isRequired,
    actualTournaments:PropTypes.array,
    archiveTournaments:PropTypes.array
};
module.exports = ParcoursLandingPage;
