/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Button,ButtonGroup} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");

class StarRater extends React.Component {
    constructor(props) {
        super(props);
        this.mouseOver = this.mouseOver.bind(this);
        this.leftGroup = this.leftGroup.bind(this);
        this.updateRating = this.updateRating.bind(this);
        this.clicked = this.clicked.bind(this);

        let defRating = 0;
        if (this.props.actRating != null) {
            defRating = this.props.actRating;
        }
        this.state = {
            rating: defRating
        }
    }
    mouseOver(number) {
        if (this.state.clicked != true) {
            let actRating = this.state.rating;
            if (number != actRating) {
                this.updateRating(number);
            }
        }
    }
    leftGroup(event) {
        if (this.state.clicked != true) {
            if (this.props.actRating != null) {
                this.updateRating(this.props.actRating);
            } else {
                this.updateRating(0);
            }
        }

    }
    updateRating(newRating) {
        this.setState({
            rating: newRating
        })
    }
    clicked(number) {
        this.setState({
            clicked: true,
            rating: number
        });
        if (this.props.handleStarsSelected != null) {
            this.props.handleStarsSelected(number);
        }
    }
    render() {
        const {rating} = this.state;
        return (
        <ButtonGroup onMouseLeave={this.leftGroup}>
            <Button onMouseEnter={() => this.mouseOver(1)} onClick={() => this.clicked(1)}>
                {rating > 0 ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
                </Button>
            <Button onMouseEnter={() => this.mouseOver(2)} onClick={() => this.clicked(2)}>
                {rating > 1 ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
            </Button>
            <Button onMouseEnter={() => this.mouseOver(3)} onClick={() => this.clicked(3)}>
                {rating > 2 ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
            </Button>
            <Button onMouseEnter={() => this.mouseOver(4)} onClick={() => this.clicked(4)}>
                {rating > 3 ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
            </Button>
            <Button onMouseEnter={() => this.mouseOver(5)} onClick={() => this.clicked(5)}>
                {rating > 4 ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
            </Button>
        </ButtonGroup>)
    }
}
StarRater.propTypes = {
    actRating: PropTypes.number.isRequired,
    handleStarsSelected: PropTypes.func
};
module.exports = StarRater;