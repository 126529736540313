const Parse = require("ParseInit");
const Utility = require("util/Utility");
const TournamentRoundUser = Parse.Object.extend("TournamentRoundUser", {
    col: {
        createdAt: "createdAt",
        updatedAt: "updatedAt",
        tournamentRoundID: "tournamentRoundID",
        tournamentUserID: "tournamentUserID",
        tournamentGroupID: "tournamentGroupID",
        countTypeID: "countTypeID",
        killCounts: "killCounts",
        killValue: "killValue",
        startStmp: "startStmp",
        status: "status",
        sumPoints: "sumPoints",
        corrPoints: "corrPoints",
        corrKillValue: "corrKillValue",
        corrKillCounts: "corrKillCounts"

    },
    status: {
        finished: "C",
        inactive: "I"
    },
    getSelectString() {
        const created = this.get(this.col.createdAt)
        return Utility.getReadableDate(created) + " - " + Utility.getReadableTime(created)
    },
    getKillCounts() {
        return this.get(this.col.killCounts)
    },
    setKillCounts(killCounts) {
        return this.set(this.col.killCounts, killCounts)
    },
    getKillValue() {
        return this.get(this.col.killValue)
    },
    setKillValue(killValue) {
        return this.set(this.col.killValue, killValue)
    },
    getCountTypeID() {
        return this.get(this.col.countTypeID)
    },
    setCountTypeID(countTypeID) {
        return this.set(this.col.countTypeID, countTypeID)
    },
    getRoundKillCounts2String() {
        return Utility.buildKillCounts2String(this.getKillCounts());
    },
    getRoundPoints() {
        let points = this.get(this.col.sumPoints);
        if (!points) {
            // disqualified
            points = "---"
        }
        return points;
    },
    setRoundPoints(points) {
        this.set(this.col.sumPoints, points);
    },
    getCorrectionPoints() {
        return this.get(this.col.corrPoints);
    },
    getCorrectionKillValue() {
        return this.get(this.col.corrKillValue);
    },
    getCorrectionKillCounts() {
        return this.get(this.col.corrKillCounts);
    },
    setCorrectionPoints(points, killValue) {
        this.set(this.col.corrPoints, points);
        this.set(this.col.corrKillValue, killValue);
    },
    resetCorrectionPoints() {
        this.unset(this.col.corrPoints);
        this.unset(this.col.corrKillValue);
        this.unset(this.col.corrKillCounts);
    },
    getTournamentUserID() {
        return this.get(this.col.tournamentUserID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    setTournamentUserID(tournamentUserID) {
        return this.set(this.col.tournamentUserID, tournamentUserID)
    },
    getTournamentGroupID() {
        return this.get(this.col.tournamentGroupID)
    },
    setStatusFinished() {
        return this.setStatus(this.status.finished);
    },
    isStatusFinished() {
        return this.getStatus() === this.status.finished;
    },
    getGroupStartTime() {
        if (this.getStartStmp() != null) {
            return this.getStartStmp()
        } else {
            const tGroup = this.getTournamentGroupID();
            if (tGroup != null) {
                return tGroup.getCreatedAt()
            }
        }
        return null
    },
    getStartStmp() {
        return this.get(this.col.startStmp)
    },
    setStatus: function (status) {
        return this.set(this.col.status, status);
    },
    getStatus() {
        return this.get(this.col.status)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
});

module.exports = TournamentRoundUser;
