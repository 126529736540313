const Parse = require("ParseInit");

const TournamentRegSlot = Parse.Object.extend("TournamentRegSlot", {
    col: {
        createdAt: "createdAt",
        status: "status",
        name: "name",
        tournamentID: "tournamentID",
        maxAmount: "maxAmount",
        amount: "amount",

    },
    status: {
        published: "A",
        closed: "C",
        deleted: "D",
    },
    getSelectString() {
        return this.getName();
    },
    getName() {
        return this.get(this.col.name);
    },
    setName(name) {
        return this.set(this.col.name, name);
    },
    getActualAmount() {
        return this.get(this.col.amount);
    },
    setActualAmount(amount) {
        return this.set(this.col.amount, amount);
    },
    decrAmount() {
        this.decrement(this.col.amount);
    },
    incrAmount() {
        this.increment(this.col.amount)
    },
    getMaxAmount() {
        return this.get(this.col.maxAmount);
    },
    setMaxAmount(amount) {
        return this.set(this.col.maxAmount, amount);
    },
    isClosed() {
        return this.getStatus() === this.status.closed || this.getStatus() === this.status.deleted;
    },
    isNotFull() {
      return this.getActualAmount() < this.getMaxAmount()
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getStatusTranslKey() {
        if (this.isPresent()) {
            return "tournament.status.present";
        } else if (this.isRunning()) {
            return "tournament.status.running";
        } else if (this.isClosed()) {
            return "tournament.status.finished";
        }
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
});

module.exports = TournamentRegSlot;
