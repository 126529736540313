const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Table, Button, ButtonToolbar, Panel} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    PanelCollapse = require("components/widgets/PanelCollapse"),
    FontAwesome = require("components/widgets/FontAwesome");

const UserActions = require("actions/UserActions"),
    tournamentUserActions = require("actions/TournamentUserActions"),
    tournamentRegisterModalActions = require("actions/TournamentRegisterModalActions"),
    TournamentRegisterModalPairs = require("components/tournamentmodal/TournamentRegisterModalPairs");

class TournamentRegistrationPairs extends React.Component {
    constructor(props) {
        super(props);
        this.refTournamentPairRegistrateModal = React.createRef();
        this.handleClickLogin = this.handleClickLogin.bind(this);

    }
    handleClickLogin() {
        this.refTournamentPairRegistrateModal.current.closeDialog();
        UserActions.showLoginModal();
    }
    render() {
        const {user, tournament, tournamentUserTeams} = this.props;
        return (
            <PanelCollapse header={<h3><I18n code="tournament.register.panel.title"/></h3>}>
                <Row>
                    <Col xs={12} lg={8}>
                        <TournamentRegistreeList tournament={tournament} user={user} tournamentUserTeams={tournamentUserTeams}/>
                    </Col>
                    <Col xs={12} lg={4}>
                        <TournamentRegisterButton tournament={tournament} user={user}/>
                        <TournamentRegisterModalPairs ref={this.refTournamentPairRegistrateModal}
                                                      clickLogin={this.handleClickLogin}
                                                      user={user}/>
                    </Col>
                </Row>
            </PanelCollapse>
        )
    }
}
TournamentRegistrationPairs.propTypes = {
    user:PropTypes.object,
    tournament:PropTypes.object.isRequired,
    tournamentUserTeams:PropTypes.array.isRequired
};
const TournamentRegistreeList = ({user, tournament, tournamentUserTeams }) => {
    if (!user) {
        return (<div><I18n code="tournament.register.panel.info.nologin"/></div>);
    }
    function filterSelfRegisteredTeams(tournamentUserTeams) {
        return tournamentUserTeams.filter(tournamentUserTeam => {
                return tournamentUserTeam.isSelfRegisteredUsers(user);
            });
    }
    let selfRegisteredTeam = filterSelfRegisteredTeams(tournamentUserTeams);
    if (selfRegisteredTeam.length === 0) {
        return (<div><I18n code="tournament.register.panel.info.noregistration"/></div>);
    }
    return (
        <React.Fragment>
            {selfRegisteredTeam.map(tournamentUserTeam => {
                return (<TournamentTeamRow key={tournamentUserTeam.id}
                                                    user={user}
                                                    tournament={tournament}
                                                    tournamentUserTeam={tournamentUserTeam}/>);
            })}
        </React.Fragment>
    );
};
TournamentRegistreeList.propTypes = {
    user:PropTypes.object,
    tournament:PropTypes.object.isRequired,
    tournamentUserTeams:PropTypes.array.isRequired
};
const TournamentTeamRow = ({user, tournament, tournamentUserTeam}) => {
    function derigster() {
        tournamentUserActions.deRegisterTeamFromTournament(tournament, tournamentUserTeam, user);
    }
    console.log("render " + tournamentUserTeam.getName());
    return (
        <Row>
            <Col xs={12}>
                <Panel style={{lineHeight: "2.5em", fontSize: "130%"}}>
                    <Panel.Heading>
                        <React.Fragment>#{tournamentUserTeam.startNumber}&nbsp;&nbsp;<b>{tournamentUserTeam.getName()}</b>
                            <ButtonToolbar className="pull-right">
                                <Button bsSize="small" onClick={derigster}>
                                    <FontAwesome icon="times"/><I18n code="tournament.button.unregister"/>
                                </Button>
                            </ButtonToolbar>
                        </React.Fragment>
                    </Panel.Heading>
                    <Panel.Body>
                        <Table style={{margin: "0"}}>
                            {
                                tournamentUserTeam.getTournamentUserIDs() != null ?
                                    tournamentUserTeam.getTournamentUserIDs().map(tournamentUser => {
                                        return <TournamentTeamRegistree key={"TTR_" + tournamentUser.id}
                                                                        tournament={tournament}
                                                                        tournamentUser={tournamentUser}/>
                                    }) : null
                            }
                        </Table>
                    </Panel.Body>
                </Panel>
            </Col>
        </Row>
    )
};
TournamentTeamRow.propTypes = {
    tournament:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    tournamentUserTeam:PropTypes.object.isRequired
};

const TournamentTeamRegistree= ({tournamentUser, tournament}) => {
    return (
        <tbody>
            <tr>
                <th><I18n code="tournament.table.column.archer"/></th>
                <td><b>{tournamentUser.getFullName()}</b></td>
            </tr>
            <tr>
                <th><I18n code="tournament.class.bow"/></th>
                <td>{tournamentUser.getTournamentConfigBowCodeName()}</td>
            </tr>
            {
                tournamentUser.isPaid() ? null :
                    <tr>
                        <th><I18n code="tournament.register.label.price"/></th>
                        <td>{tournamentUser.getPayAmount() + " " + tournament.getTournamentPriceID().get("currency")}</td>
                    </tr>
            }
            {
                tournamentUser.isPaid() ? null :
                    <tr>
                        <th><I18n code="tournament.register.label.payReference"/></th>
                        <td>{tournamentUser.getPayRefID()}</td>
                    </tr>
            }
        </tbody>
    )
};
TournamentTeamRegistree.propTypes = {
    tournamentUser:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired
};
const TournamentRegisterButton = ({user, tournament}) => {
    function openTournamentSelfRegisterModal() {
        tournamentRegisterModalActions.openDialogPairRegister(user, tournament);
    }
    return (
        <div>
            <Button block bsSize="large" bsStyle="primary" onClick={openTournamentSelfRegisterModal}>
                <FontAwesome icon="plus"/><I18n code="tournament.button.register"/>
            </Button>
        </div>)
};
TournamentRegisterButton.propTypes = {
    user:PropTypes.object.isRequired,
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentRegistrationPairs;
