var Parse = require("ParseInit");

var News = Parse.Object.extend("news", {
    col: {
        creator: "creator",
        status: "status",
        message: "message",
        translCode: "translCode",
        parcoursID: "parcoursID",
        tournamentID: "tournamentID",
        attachmend: "attachment",
        weblink: "weblink",
        groupPlaceID: "groupPlaceID",
        mainGroupPlaceID: "mainGroupPlaceID",
        place: "place",
        zip: "zip",
        createdAt: "createdAt"
    },
    status: {
        published: "A",
        deleted: "D"
    },
    getNewsDate: function() {
        var startDate = this.get("createdAt")
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    getCreator: function () {
        return this.get(this.col.creator)
    },
    setCreator: function (creator) {
        return this.set(this.col.creator, creator)
    },
    getStatus: function() {
        return this.get(this.col.status)
    },
    setStatus: function(status) {
        return this.set(this.col.status, status)
    },
    getMessage: function () {
        return this.get(this.col.message)
    },
    getTranslationCode: function () {
        return this.get(this.col.translCode)
    },
    getWeblink: function () {
        return this.get(this.col.weblink)
    },
    getAttachment: function () {
        return this.get(this.col.attachmend)
    },
    getParcoursID: function () {
        return this.get(this.col.parcoursID)
    },
    setParcoursID: function (parcoursID) {
        this.get(this.col.parcoursID, parcoursID)
    },
    getTournamentID: function () {
        return this.get(this.col.tournamentID)
    },
    setTournamentID: function (tournamentID) {
        this.get(this.col.tournamentID, tournamentID)
    },
    getGroupPlaceID: function() {
        return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID: function(groupPlaceID) {
        return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getMainGroupPlaceID: function() {
        return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID: function(mainGroupPlaceID) {
        return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getPlace: function () {
        return this.get(this.col.place)
    },
    setPlace: function (place) {
        return this.set(this.col.place, place)
    },
    getZip: function () {
        return this.get(this.col.zip)
    },
    setZip: function (zip) {
        return this.set(this.col.zip, zip)
    }
});

module.exports = News;
