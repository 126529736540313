const React = require("react"),
    PropTypes = require('prop-types');
const {Button, Image} = require("react-bootstrap");
const messages = require("i18n/messages");
const ValidInput = require("components/form/ValidInput");
const FontAwesome = require("components/widgets/FontAwesome");

class ImageUrlInput extends React.Component {
    constructor(props) {
        super(props);
        this.refImageUrl = React.createRef();
        
        this.onImagePreview = this.onImagePreview.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.getValue = this.getValue.bind(this);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.state = {
            url: this.props.url,
            viewURL: this.props.url
        }
    }
    onImagePreview(e) {
        e.preventDefault();
        this.setState({viewURL: this.getValue()});
    }
    onFormSubmit(e) {
        e.preventDefault();
        if (this.props.onSubmit) {
            this.props.onSubmit(e);
        }
    }
    getValue() {
        return this.refImageUrl.current.getValue().trim();
    }
    showHelpMessage(message, style) {
        this.refImageUrl.current.showHelpMessage(message, style)
    }
    render() {
        let saveButton = null;
        if (this.props.onSubmit) {
            saveButton = <Button type="submit"><FontAwesome icon="save"/></Button>
        }
        return (
            <form id="imageUrl" action="#" onSubmit={this.onFormSubmit}>
                <ValidInput ref={this.refImageUrl}
                            label={messages.get("parcours.targets.input.image")}
                            valid={{maxLength: 500, check:['isUrl']}}
                            default={this.state.url}
                            buttonAfter={[<Button onClick={this.onImagePreview}><FontAwesome icon="eye"/></Button>,saveButton]}/>
                {this.state.viewURL != null ?
                    <Image src={this.state.viewURL} responsive />
                    : ""}
            </form>
        )
    }
}
ImageUrlInput.propTypes = {
    url: PropTypes.string,
    onSubmit:PropTypes.func,
};
module.exports = ImageUrlInput;
