const languageStore = require("stores/LanguageStore");
const ConfigKeys = require("ConfigKeys");

var language = languageStore.getState().language;

languageStore.listen(function (state) {
    language = state.language;
});

const legacyLinks = [
    "calender.html",
    "ic_launcher_green.png"
];

const isLegacyLink = function (href) {
    for (var i = 0; i < legacyLinks.length; i++) {
        if (href.indexOf(legacyLinks[i]) > -1) {
            return true;
        }
    }
    return false;
};

const linkUtil = {
    getLink: function (href) {
        if (href != null && !href.startsWith("/")) {
            href = "/" + href;
        }
        if (isLegacyLink(href)) {
            return href;
        }
        // end: todo
        return "/" + language + href;
    },
    getFullLink: function (href) {
        return ConfigKeys.domainUrl + this.getLink(href);
    },
    getFullLinkNoLanguage: function (href) {
        return ConfigKeys.domainUrl + "/" + href;
    },
    getExternalLink: function (link) {
        if (link != null) {
            if (link.startsWith("http://") || link.startsWith("https://")) {
                return link;
            }
            return "http://" + link;
        }
        return null
    }
};

module.exports = linkUtil;
