const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Label, PageHeader, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ParcoursLoader = require("components/parcours/ParcoursLoader"),
    TargetMapFrame = require("components/map/TargetMapFrame"),
    AddParcoursTarget = require("components/parcoursmanage/AddParcoursTarget"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading");

const parcoursTargetActions = require("actions/ParcoursTargetActions"),
    parcoursTargetStore = require("stores/ParcoursTargetStore");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const ParcoursTargetPage = ({onlineID}) =>{
     return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"F"} loginMandatory={false}>
        <ParcoursDetails />
    </ParcoursLoader>
};
ParcoursTargetPage.propTypes = {
    onlineID: PropTypes.string.isRequired
};
class ParcoursDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleParcoursTargetChange = this.handleParcoursTargetChange.bind(this);
        this.state = {
            parcoursTargetStore: parcoursTargetStore.getState()
        }
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
        parcoursTargetStore.listen(this.handleParcoursTargetChange);
        // load target if status is active or premium only
        if (this.props.parcours.hasTargetEnabled(true)) {
            parcoursTargetActions.loadParcoursTargets.defer(this.props.parcours.getOnlineID());
        }
    }
    componentWillUnmount() {
        parcoursTargetStore.unlisten(this.handleParcoursTargetChange);
    }
    handleParcoursTargetChange(state) {
        this.setState({parcoursTargetStore: state});
    }
    render() {
        const {user, parcours, userFeatures} = this.props;
        const {parcoursTargetStore} = this.state;
        const hasTargetPremium = userFeatures != null ? userFeatures.isTargetFeatureActive() : false;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <Breadcrumbs>
                            <Breadcrumb link="/" code="header.nav.home"/>
                            <Breadcrumb link="/parcours" code="header.nav.parcours"/>
                            <Breadcrumb link={"/parcours/" + parcours.getOnlineID() } code={parcours.buildNames()}/>
                            <Breadcrumb code="parcours.targets.header" active/>
                        </Breadcrumbs>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{parcours.buildNames()} </PageHeader>
                    </Col>
                </Row>
                {
                    parcours.hasTargetEnabled(hasTargetPremium) ?
                        parcoursTargetStore.loading === true ?
                            <Row><Loading/></Row>
                            :
                            <React.Fragment>
                                <ParcoursTargetList user={user} parcours={parcours}
                                                    parcoursTargets={parcoursTargetStore.parcoursTargets}
                                                    targetCatalog={parcoursTargetStore.targetCatalog}/>
                                <hr/>
                                <ParcoursTargetEdit user={user} parcours={parcours} hasTargetPremium={hasTargetPremium}
                                                    parcoursTargets={parcoursTargetStore.parcoursTargets}
                                                    targetCatalog={parcoursTargetStore.targetCatalog}/>
                            </React.Fragment>

                        :
                        <Alert bsStyle="info"><I18n code="parcours.ranking.noParcoursFeature"/></Alert>

                }
            </Grid>)
    }
}
ParcoursDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    userFeatures: PropTypes.object,
    user:PropTypes.object
};

const ParcoursTargetList = ({user, parcours, parcoursTargets, targetCatalog}) =>{
    const showEdit = targetCatalog != null && targetCatalog.length > 0;
    return (<Row>
        <Col xs={12}>
            <TargetMapFrame targets={parcoursTargets} parcours={parcours}/>
            <br/><br/>
        </Col>
        {
            parcoursTargets.map(target => {
                if (target.isActive()) {
                    return <TargetElement key={target.id} target={target} user={user}
                                       parcours={parcours} showEdit={showEdit}
                                       targetCatalog={targetCatalog}
                                       parcoursTargets={parcoursTargets}/>

                }
            })
        }
    </Row>)
};
ParcoursTargetList.propTypes = {
    user:PropTypes.object,
    targetCatalog: PropTypes.array.isRequired,
    parcours:PropTypes.object.isRequired,
    parcoursTargets: PropTypes.array.isRequired
};
const ParcoursTargetEdit = ({user, parcours, parcoursTargets, targetCatalog, hasTargetPremium}) =>{
    function loadTargetCatalog() {
        parcoursTargetActions.loadTargetBrandCatalog.defer();
    }
    // check for premium and parcours not admin only
    if (hasTargetPremium && !parcours.hasTargetEditorOnly()) {
        if (targetCatalog != null && targetCatalog.length > 0) {
            // catalog loaded
            return <Row>
                <Col xs={12}>
                    <h3><I18n code="parcours.targets.map.edit.header"/></h3>
                </Col>
                    <AddParcoursTarget targetCatalog={targetCatalog}
                                       parcoursTargets={parcoursTargets}
                                       parcours={parcours} user={user}
                                       loadingCatalog={false}/>
            </Row>
        } else {
            // Show button to load catalog
            return <Row><Col xs={12}>
                <SubmitButton onClick={loadTargetCatalog} bsStyle="default" icon={<FontAwesome icon="pencil-alt"/>}
                              text={<I18n code="parcours.targets.edit.header"/>} />
            </Col></Row>
        }
    }
    return null;
}
ParcoursTargetEdit.propTypes = {
    user:PropTypes.object,
    parcours:PropTypes.object.isRequired,
    parcoursTargets: PropTypes.array.isRequired,
    targetCatalog: PropTypes.array.isRequired,
    hasTargetPremium: PropTypes.bool.isRequired
};
class TargetElement extends React.Component {
    constructor(props) {
        super(props);
        this.setEditMode = this.setEditMode.bind(this);
        this.state = {
            editMode: false
        }
    }
    setEditMode(editMode) {
        this.setState({editMode: editMode});
    }
    render() {
        const {editMode} = this.state;
        const {user, showEdit, parcours, parcoursTargets, target, targetCatalog} = this.props;
        if (editMode) {
            return (<Col xs={12}><hr/>
                <Button pullRight onClick={() => this.setEditMode(false)}>X</Button>
                <AddParcoursTarget targetCatalog={targetCatalog} user={user}
                                   parcoursTargets={parcoursTargets}
                                   parcours={parcours}
                                   loadingCatalog={false}
                                   closeEdit={() => this.setEditMode(false)}
                                   editTarget={target}/><br/><br/><br/><hr/>

            </Col>)
        }
        return <TargetElementInfo target={target} onClickEdit={showEdit ? () => this.setEditMode(true) : null}/>
    }
}
TargetElement.propTypes = {
    user:PropTypes.object,
    showEdit: PropTypes.bool,
    target:PropTypes.object.isRequired,
    targetCatalog: PropTypes.array.isRequired,
    parcours:PropTypes.object.isRequired,
    parcoursTargets: PropTypes.array.isRequired
};
const TargetElementInfo = ({target, onClickEdit}) => {
    let imgUrl = target.getImageUrl();
    return <Col xs={12} sm={6} md={4}  style={{height:"75px"}}>
        <Col xs={8}>
            <Label bsStyle="success">{target.getIndex()}</Label>&nbsp;{target.getName()}
            {
                onClickEdit ? <Button onClick={onClickEdit}>
                    <FontAwesome icon="pencil-alt"/><I18n code="parcours.targets.input.edit"/>
                </Button> : null
            }
        </Col>
        <Col xs={4}>
            {imgUrl != null ? <img src={imgUrl} height="60"/> : null}
        </Col>
    </Col>
};
TargetElementInfo.propTypes = {
    target:PropTypes.object.isRequired,
    onClickEdit: PropTypes.func,
};
module.exports = ParcoursTargetPage;
