const React = require("react"),
    PropTypes = require('prop-types');

const ExtLink = ({href, onClick, children}) => {
    return (<a href={href} target="_blank" rel="noopener" onClick={onClick}>
        {children}
    </a>)
};
ExtLink.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.any,
    onClick: PropTypes.func
};
module.exports = ExtLink;
