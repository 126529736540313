const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    DropDownString = require("components/form/DropDownString"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const {TargetCatalog} = require("parse/_Domain");

const parcoursTargetActions = require("actions/ParcoursTargetActions");

const groups = ["0", "1", "2", "3", "4"];
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TargetCatalogModal = ({children, targetBrands}) =>  {
    return (
        <ModalBasic
            contextChildren={children}
            title={<TextCenter>Ziel Daten eingeben</TextCenter>}
            buttonStyle={"default"}
            buttonChildren={<FontAwesome icon="plus">"Neues Ziel"</FontAwesome>}>
            <TargetCatalogForm targetBrands={targetBrands}/>
        </ModalBasic>
    )
};
TargetCatalogModal.propTypes = {
    targetBrands: PropTypes.array.isRequired
};
class TargetCatalogForm extends React.Component {
    constructor(props) {
        super(props);
        this.refName = React.createRef();
        this.refBrandArtNo = React.createRef();
        this.refGroup = React.createRef();
        this.refBrand = React.createRef();
        this.refImageUrl = React.createRef();
        this.refLength = React.createRef();
        this.refWidth = React.createRef();
        this.refHeight = React.createRef();
        this.refWeight = React.createRef();
        this.refPrice = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        // this.handleGroupChange = this.handleGroupChange.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refName.current.getValue(),
            this.refBrandArtNo.current.getValue(),
            this.refGroup.current.getValue(),
            this.refBrand.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, object, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);

        let pTarget = object;
        if (pTarget == null) {
            pTarget = new TargetCatalog();
            pTarget.setStatus("A");
        }
        pTarget.setName(this.refName.current.getValue());
        pTarget.setBrandArtNo(this.refBrandArtNo.current.getValue());
        pTarget.setGroup(parseInt(this.refGroup.current.getValue()));
        pTarget.setBrandID(this.refBrand.current.getValue());
        // optional
        if (this.refImageUrl.current.getValue().length > 0) {
            pTarget.setImageUrl(this.refImageUrl.current.getValue())
        } else {
            pTarget.unset(TargetCatalog.prototype.col.imageUrl)
        }
        if (this.refLength.current.getValue() > 0) {
            pTarget.setLength(parseFloat(this.refLength.current.getValue()))
        } else {
            pTarget.unset(TargetCatalog.prototype.col.length)
        }
        if (this.refWidth.current.getValue() > 0) {
            pTarget.setWidth(parseFloat(this.refWidth.current.getValue()))
        } else {
            pTarget.unset(TargetCatalog.prototype.col.width)
        }
        if (this.refHeight.current.getValue() > 0) {
            pTarget.setHeight(parseFloat(this.refHeight.current.getValue()))
        } else {
            pTarget.unset(TargetCatalog.prototype.col.height)
        }
        if (this.refWeight.current.getValue() > 0) {
            pTarget.setWeight(parseFloat(this.refWeight.current.getValue()))
        } else {
            pTarget.unset(TargetCatalog.prototype.col.weight)
        }
        if (this.refPrice.current.getValue() > 0) {
            pTarget.setPrice(parseFloat(this.refPrice.current.getValue()))
        } else {
            pTarget.unset(TargetCatalog.prototype.col.price)
        }

        parcoursTargetActions.saveTargetCatalog(pTarget);

        closeFct();
    }
    handleGroupChange(group) {
    }
    handleBrandChange(brandID) {
    }
    render() {
        const {error, object, submitDisabled} = this.context;
        const {targetBrands} = this.props;
        // TODO add length, width, height, weight, price
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refName} default={object ? object.getName() : null}
                                   label={messages.get("tournament.table.column.name")}/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refBrandArtNo} default={object ? object.getBrandArtNo() : null}
                                    label={messages.get("tournament.table.column.article") + "#"}/>
                    </Col>
                    <Col sm={6}>
                        <DropDownString ref={this.refGroup} label="Gruppe" objectList={groups}
                                        default={object && object.getGroup() ? object.getGroup().toString() : "0"}
                                        onAfterChange={this.handleGroupChange}/>
                    </Col>
                    <Col sm={6}>
                        <DropDownParseObject ref={this.refBrand} label="Hersteller"
                                             default={object ? object.getBrandID() : null}
                                             placeholder={<option key={"nullkey"}
                                                                  value={"null"}>{"Wähle Hersteller"}</option>}
                                             onAfterChange={this.handleBrandChange}
                                             objectList={targetBrands}/>
                    </Col>
                    <Col sm={12}>
                        <h3>Optional</h3>
                    </Col>
                    <Col sm={12}>
                        <ValidInput ref={this.refImageUrl} default={object ? object.getImageUrl() : null}
                                    valid={{check:['isUrl']}}
                                    label={messages.get("parcours.targets.input.image")}/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refLength} default={object ? object.getLength() : null}
                                    valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                    label={messages.get("length(m)")} type="number"/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refWidth} default={object ? object.getWidth() : null}
                                    valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                    label={messages.get("width(m)")} type="number"/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refHeight} default={object ? object.getHeight() : null}
                                    valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                    label={messages.get("height(m)")} type="number"/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refWeight} default={object ? object.getWeight() : null}
                                    valid={{maxLength: 5, check:['isNumber', 'point4comma']}}
                                    label={messages.get("weight(kg)")} type="number"/>
                    </Col>
                    <Col sm={6}>
                        <ValidInput ref={this.refPrice} default={object ? object.getPrice() : null}
                                    valid={{maxLength: 7, check:['isNumber', 'point4comma']}}
                                    label={messages.get("parcours.articles.form.price")} type="number"/>
                    </Col>
                    <Col sm={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TargetCatalogForm.propTypes = {
    targetBrands: PropTypes.array.isRequired
};
TargetCatalogForm.contextType = ModalBasicContext;
module.exports = TargetCatalogModal;
