const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput");

class InlineEditorWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.enableEditMode = this.enableEditMode.bind(this);
        this.disableEditMode = this.disableEditMode.bind(this);
        this.buttonAfterCallback = this.buttonAfterCallback.bind(this);
        this.state = {
            editMode: false
        };
    }
    enableEditMode() {
        this.setState({editMode: true});
    }
    disableEditMode() {
        this.setState({editMode: false});
    }
    buttonAfterCallback(value) {
        this.props.buttonAfterCallback(value, (close) => {
            if (close) {
                this.disableEditMode();
            }
        });
    }
    render() {
        const {editMode} = this.state;
        const {childrenValue, defEditValue, type, label, addonBefore, buttonAfterContent, valid} = this.props;
        const tooltip ="Double click to edit";
        if (editMode) {
            return <React.Fragment>
                <Button className="pull-right" onClick={this.disableEditMode}>x</Button>
                <ValidInput default={defEditValue} type={type}
                            label={label}
                            buttonAfterCallback={this.buttonAfterCallback}
                            buttonAfterContent={buttonAfterContent}
                            addonBefore={addonBefore}
                            valid={valid}/>
            </React.Fragment>
        }
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom"
                            overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                <div onDoubleClick={this.enableEditMode}>{childrenValue}</div>
            </OverlayTrigger>
        )
    }
}
InlineEditorWrapper.propTypes = {
    childrenValue: PropTypes.any.isRequired,
    defEditValue: PropTypes.any,
    type: PropTypes.string,
    addonBefore: PropTypes.any,
    label: PropTypes.object,
    buttonAfterContent: PropTypes.any,
    buttonAfterCallback: PropTypes.func,
    valid: PropTypes.any
};
module.exports = InlineEditorWrapper;
