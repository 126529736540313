const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const linkUtil = require("linkUtil");

const ClubInvoiceButton = ({club, bsStyle, disabled, block}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            label="cl_role_invcreation"
            href={linkUtil.getLink("club/" + club.id + "/invoice")}/>
    )
};
ClubInvoiceButton.propTypes = {
    club:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
};
module.exports = ClubInvoiceButton;