const Parse = require("ParseInit");

const {PointOfInterest, GroupPlace} = require("parse/_Domain");

class PointInterestDao {


    /**
     * @param searchParams
     * @returns Promise
     */
    search(searchParams) {
        let poiQuery = new Parse.Query(PointOfInterest);
        poiQuery.include(PointOfInterest.prototype.col.bannerImageID);
        poiQuery.ascending(PointOfInterest.prototype.col.sortIndex + "," + PointOfInterest.prototype.col.createdAt);
        this.applyAndParcoursFilter(searchParams, poiQuery);
        if (searchParams.coords) {
            let userLocation =  new Parse.GeoPoint({latitude: searchParams.coords.latitude, longitude: searchParams.coords.longitude});
            poiQuery.withinKilometers(PointOfInterest.prototype.col.coordinates, userLocation, searchParams.withinKM);
            poiQuery.limit(1000);
        }
        return poiQuery.find();
    }


    applyAndParcoursFilter(searchParams, poiQuery) {
        if (searchParams.mainGroupPlaceID != null) {
            var mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            poiQuery.equalTo(PointOfInterest.prototype.col.mainGroupPlaceIDs, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            var regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            poiQuery.equalTo(PointOfInterest.prototype.col.groupPlaceIDs, regionGroupPlace);
        }
        poiQuery.equalTo(PointOfInterest.prototype.col.status, "A");
    }

    query(activeOnly) {
        const query = new Parse.Query(PointOfInterest);
        query.include(PointOfInterest.prototype.col.bannerImageID);
        query.ascending(PointOfInterest.prototype.col.sortIndex + "," + PointOfInterest.prototype.col.createdAt);
        if (activeOnly) {
            query.equalTo(PointOfInterest.prototype.col.status, "A");
        }
        return query.find();
    }
}

module.exports = new PointInterestDao();
