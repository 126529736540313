const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button, ButtonGroup, ButtonToolbar, ToggleButtonGroup, ToggleButton} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
	Loading = require("components/widgets/Loading");


const { TournamentConfig} = require("parse/_Domain");
// const TournamentResultPrint = require("components/tournament/TournamentResultPrint");
const TournamentResultPrint = React.lazy(() =>
	import(/* webpackChunkName: "TournamentResultPrint" */ "components/tournament/TournamentResultPrint")
);

const DropDownString = require("components/form/DropDownString");

const TournamentLiveButton = require("components/links/TournamentLiveButton");

const TournamentResultGrouping = require("components/tournament/TournamentResultGrouping"),
    TournamentTeamResultGrouping = require("components/tournament/TournamentTeamResultGrouping");
const tournamentResultStore = require("stores/TournamentResultStore"),
	tournamentResultActions = require("actions/TournamentResultActions");

class TournamentResult extends React.Component {
	constructor(props) {
		super(props);
		this.handleTournamentResultChange = this.handleTournamentResultChange.bind(this);
		this.showResultPDF = this.showResultPDF.bind(this);
		this.hasPDFResult = this.hasPDFResult.bind(this);
		this.reload = this.reload.bind(this);
		this.handleOptionChanged = this.handleOptionChanged.bind(this);
		this.handleCupResultChange= this.handleCupResultChange.bind(this);
		this.generatePDF = this.generatePDF.bind(this);
		this.calcMedalAmount = this.calcMedalAmount.bind(this);

		this.state = {
			tournamentResultStore: tournamentResultStore.getState()
		}
	}
	componentDidMount() {
		tournamentResultStore.listen(this.handleTournamentResultChange);
		tournamentResultActions.showResultDetails.defer(this.props.tournament);
	}
	componentWillUnmount() {
		tournamentResultActions.resetTournamentResults.defer();
		tournamentResultStore.unlisten(this.handleTournamentResultChange);
	}
	handleTournamentResultChange(state) {
		this.setState({tournamentResultStore: state});
	}
	showResultPDF() {
		if (this.props.tournament.getResultImageID()) {
			// download stored result
			window.open(this.props.tournament.getResultImageID().get("image")._url);
		}
	}
	hasPDFResult() {
		return this.props.tournament.getResultImageID() != null;
	}
    reload() {
        tournamentResultActions.loadTournamentResult.defer(this.props.tournament, this.state.tournamentResultStore.displayState,
			this.state.tournamentResultStore.params);
    }
	handleOptionChanged(optionString) {
        const params = {
            needFinalAdd: this.state.tournamentResultStore.needFinalAdd(),
            optionString: optionString,
			tConfigCombinedAge: null
        };
        tournamentResultActions.loadTournamentResult.defer(this.props.tournament, this.state.tournamentResultStore.displayState, params);
	}
	handleCupResultChange(tConfig) {
		const params = {
			needFinalAdd: this.state.tournamentResultStore.needFinalAdd(),
			optionString: null,
			tConfigCombinedAge: tConfig
		};
		tournamentResultActions.loadTournamentResult.defer(this.props.tournament, this.state.tournamentResultStore.displayState, params);
	}
    generatePDF(maxRows) {
        tournamentResultActions.generatePDF(this.props.tournament, this.state.tournamentResultStore.tournamentUserResult, maxRows)
	}
    calcMedalAmount() {
        tournamentResultActions.calcMedalAmount(this.state.tournamentResultStore.tournamentUserResult);
	}
	render() {
    	const {tournament, user} = this.props;
    	const {tournamentResultStore} = this.state;
		return (<React.Fragment>
				<Row>
					<Col sm={4} xs={12}>
						<h1>{ tournament.isClosed() ?
							<I18n code="tournament.result.panel.title.endresult"/>
							:
							<I18n code="tournament.result.panel.title.intermediateresult"/>
						}</h1>
					</Col>
					<Col sm={4} xs={6}>
						{	tournament.isEditor(user) && tournamentResultStore.tournamentUserResult ?
                            <ErrorSuspenseBoundary>
                                <TournamentResultPrint tournament={tournament}
                                                       tournamentUserResult={tournamentResultStore.tournamentUserResult}
                                                       reload={this.reload} />
                            </ErrorSuspenseBoundary>
							: ""
						}
						{ this.hasPDFResult() ?
							<Button block bsSize="large" bsStyle="primary" onClick={this.showResultPDF}>
								<FontAwesome icon="print"/>PDF
							</Button>
							:  ""
						}
					</Col>
					<Col sm={4} xs={6}>
						{ tournament.isClosed() || tournament.isTypeCupMaster() ? null :
							<TournamentLiveButton block bsSize="large" tournament={tournament}/>
						}
					</Col>
				</Row>
				{
                    tournamentResultStore.loading === false &&
					(tournamentResultStore.tournamentRoundMap.extra.length > 0 || tournamentResultStore.tournamentRoundMap.group.length > 0) ?
					<Row>
						<Col xs={12}>
							<TournamentRoundFilterButtons displayState={tournamentResultStore.displayState}
                                                          tournament={tournament} tournamentRoundMap={tournamentResultStore.tournamentRoundMap}/>
						</Col>
					</Row> : null
				}
				{
					tournament.getTournamentOptionID() && tournament.getTournamentOptionID().showResultFilter()
						&& tournamentResultStore.displayState === "S" ?
						<Row className="tOptionRow"><Col sm={6}>
							<DropDownString objectList={tournament.getTournamentOptionID().getOptions()}
											placeholder={<option key="nullkey" value={"null"}>{tournament.getTournamentOptionID().getOptionLabel()}</option>}
											addonOption={tournament.getTournamentOptionID().getExclOption() != null ?
												<option key="addonkey" value={"(excl)" + tournament.getTournamentOptionID().getExclOption()}>{tournament.getTournamentOptionID().getExclOptionLabel()}</option> : null}
											onAfterChange={this.handleOptionChanged}/>
						</Col></Row>
						: null
				}
				{
					tournament.isTypeCupMaster() && tournament.getTournamentCupOptionID().getTConfigCombinedAgeID() != null ? <Row>
						<Col xs={12}>
							<ButtonGroup>
								<Button onClick={() => this.handleCupResultChange(null)}
										className={tournamentResultStore.params.tConfigCombinedAge == null ? "active" : ""}>
									<I18n code="tournament.manager.cup.result"/>
								</Button>
								<Button  onClick={() => this.handleCupResultChange(tournament.getTournamentCupOptionID().getTConfigCombinedAgeID())}
										 className={tournamentResultStore.params.tConfigCombinedAge != null ? "active" : ""}>
									{tournament.getTournamentCupOptionID().getTConfigCombinedAgeID().getName()}
								</Button>
							</ButtonGroup>
						</Col>
					</Row> : null
				}
                <hr/>
				{
					tournamentResultStore.loading ?
						<Row>
							<Col lg={12}><Loading/></Col>
						</Row>
						:
						<Row>
							<Col xs={12}>
								{
                                    tournamentResultStore.displayState[0] === "G" ?
										<TournamentTeamResultGrouping
											tournament={tournament}
											user={user}
											tournamentRoundMap={tournamentResultStore.tournamentRoundMap}
											tournamentUserResult={tournamentResultStore.tournamentUserResult}/>
										:
                                        <TournamentResultGrouping showHighestZoneCounter={false}
                                            liveModus={false}
                                            tournament={tournament}
                                            user={user}
											tournamentRoundMap={tournamentResultStore.tournamentRoundMap}
                                            tournamentUserResult={tournamentResultStore.tournamentUserResult}/>
								}
							</Col>
						</Row>

				}

			</React.Fragment>
		)
	}
}
TournamentResult.propTypes = {
	tournament:PropTypes.object.isRequired,
	user:PropTypes.object
};
const TournamentRoundFilterButtons = ({tournament, displayState, tournamentRoundMap}) => {
	function reloadNow(displayState, tRound) {
		const params = {
			needFinalAdd: false,
			optionString: null,
			tConfigCombinedAge: null
		};
		if (tRound != null && tRound.isCombineAge()) {
			let tConfigInclusion = new TournamentConfig();
			tConfigInclusion.setName(tRound.getName())
			tConfigInclusion.setNoSexSplit(true)
			tConfigInclusion.setStatus("A")
			params.tConfigCombinedAge = tConfigInclusion
		}
		tournamentResultActions.loadTournamentResult.defer(tournament, displayState, params);
	}
	function handleDisplayStateChanged(e, pDisplayState, tRound) {
		e != null ? e.preventDefault() : null;
        const needReload = displayState[0] !== pDisplayState[0];
        tournamentResultActions.updateDisplayState(pDisplayState, needReload);
        if (needReload) {
            reloadNow(pDisplayState, tRound);
        }
    }
	return (<ButtonToolbar>
		<ToggleButtonGroup type="radio" name="displayStateSel" defaultValue={displayState}>
			{
				tournamentRoundMap.qualify.length > 0 ?  <ToggleButton value={"S"} onClick={(e) => handleDisplayStateChanged(e,"S", null)}>
					<I18n code="training_mode_tournament"/>
				</ToggleButton> : null
			}
			{
				tournamentRoundMap.extra.length > 0 ? <ToggleButton value={"E"} onClick={(e) => handleDisplayStateChanged(e,"E", tournamentRoundMap.extra[0])}>
					{tournamentRoundMap.extra[0].getName()}
				</ToggleButton> : null
			}
			{
				tournamentRoundMap.group.length > 0 ? <ToggleButton value={"G"} onClick={(e) => handleDisplayStateChanged(e,"G", null)}>
					{tournamentRoundMap.group[0].getName()}
				</ToggleButton> : null
			}

		</ToggleButtonGroup>
		<Button onClick={() => reloadNow(displayState)}><FontAwesome icon="sync"/></Button>
	</ButtonToolbar>)
};
TournamentRoundFilterButtons.propTypes = {
	tournament:PropTypes.object.isRequired,
	displayState:PropTypes.string.isRequired,
	tournamentRoundMap: PropTypes.object.isRequired
};
module.exports = TournamentResult;