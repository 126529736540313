const React = require("react"),
    PropTypes = require('prop-types');
const {Panel} = require("react-bootstrap");
const Images = require("parse/Images");

const PanelBackgroundImage = ({image, children, imgStyle}) => {
    let panelStyle = imgStyle ? imgStyle : {};
    panelStyle.backgroundImage = "";
    panelStyle.backgroundSize = "contain";
    panelStyle.backgroundRepeat = "no-repeat";
    panelStyle.backgroundPosition = "center center";
    if (image != null) {
        panelStyle.backgroundImage = "url(" + image.getImageUrl() + ")";
        let bgParams = image.getBackgroundParams();
        if (bgParams != null) {
            panelStyle.backgroundSize = bgParams[Images.prototype.bgParams.backgroundSize];
            panelStyle.backgroundRepeat = bgParams[Images.prototype.bgParams.backgroundRepeat];
            panelStyle.backgroundPosition = bgParams[Images.prototype.bgParams.backgroundPosition];
        }
    }
    return (
        <Panel style={panelStyle}>
            <Panel.Body>{children}</Panel.Body>
        </Panel>)
};

module.exports = PanelBackgroundImage;
