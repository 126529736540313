const Parse = require("ParseInit");

const EventTrainingTypes = Parse.Object.extend("EventTrainingTypes", {
    col: {
        name: "name",
        translCode: "translCode",
        index: "index",
        parentTypeID: "parentTypeID",
        status: "status",
        showArrow: "bArr",
        showDuration: "bDur",
        isParcours: "bParcours",
        isTournament:"bTournament",
        isPrivate: "bPriv",
        color: "color"
    },
    status: {
        active: "A",
        deleted: "D"
    },
    //
    //
    //
    getIndex() {
        return this.get(this.col.index)
    },
    getName() {
        return this.get(this.col.name)
    },
    getSelectString() {
        return this.getName()
    },
    getParentTypeID() {
        return this.get(this.col.parentTypeID)
    },
    hasParent() {
        return this.getParentTypeID() != null;
    },
    isParent(mainTypeID) {
        if (mainTypeID != null && this.getParentTypeID() != null) {
            return mainTypeID.id === this.getParentTypeID().id
        }
        return false
    },
    isTypParcours() {
        return this.get(this.col.isParcours)
    },
    isTypTournament() {
        return this.get(this.col.isTournament)
    },
    showArrow() {
        return this.get(this.col.showArrow)
    },
    showDetails() {
        return this.showArrow() || this.showDuration()
    },
    getColor() {
        return this.get(this.col.color)
    },
    showDuration() {
        return this.get(this.col.showDuration)
    },
    getStatus() {
        return this.get(this.col.status)
    }
});

module.exports = EventTrainingTypes;
