const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput");

const { Address} = require("parse/_Domain");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const AddressModal = ({address, handleAddressSaved, businessMode, hideName, clubWriteRole, buttonStyle}) =>  {
    const buttonContent = address != null ? <FontAwesome icon="pencil-alt"><I18n code="profile.account.user.edit" /></FontAwesome>
        : <FontAwesome icon="plus"><I18n code="profile.account.user.createDetail" /></FontAwesome>
    return (
        <ModalBasic
            title={<TextCenter><I18n code={"modal.address.header"}/></TextCenter>}
            buttonStyle={buttonStyle}
            buttonChildren={buttonContent}>
            <AddressForm address={address} businessMode={businessMode} hideName={hideName} clubWriteRole={clubWriteRole}
                         handleAddressSaved={handleAddressSaved}/>
        </ModalBasic>
    )
};
AddressModal.propTypes = {
    handleAddressSaved: PropTypes.func.isRequired,
    businessMode: PropTypes.bool.isRequired,
    hideName: PropTypes.bool,
    clubWriteRole: PropTypes.bool,
    address: PropTypes.object,
    buttonStyle: PropTypes.string.isRequired
};
class AddressForm extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.refMail2 = React.createRef();
        this.refCompany = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refStreet = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();
        this.refPhone = React.createRef();
        this.refExtRef = React.createRef();
        this.refUID = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.getDefault = this.getDefault.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        let required = [
            this.refStreet.current.getValue().trim(),
            this.refZip.current.getValue().trim(),
            this.refPlace.current.getValue().trim()
        ];
        if (!this.props.hideName) {
            required.push(this.refName.current.getValue().trim());
            required.push(this.refSurname.current.getValue().trim());
        }
        if (this.props.businessMode) {
            required.push(this.refCompany.current.getValue().trim());
            required.push(this.refMail.current.getValue().trim());
            required.push(this.refPhone.current.getValue().trim());
        }
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct, setErrorFct} = this.context;
        const {address, hideName, businessMode, clubWriteRole, handleAddressSaved} = this.props;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        let editAddress = address;
        if (editAddress == null) {
            editAddress = new Address();
            editAddress.setStatus(Address.prototype.status.A);
            editAddress.buildACL(null, "club", clubWriteRole === true ? "club" : null);
        }
        if (hideName == null || hideName === false) {
            editAddress.setFullName(this.refName.current.getValue().trim() ,this.refSurname.current.getValue().trim());
        }
        editAddress.setStreet(this.refStreet.current.getValue().trim());
        editAddress.setZip(this.refZip.current.getValue().toString().trim());
        editAddress.setPlace(this.refPlace.current.getValue().trim());
        editAddress.setPhone(this.refPhone.current.getValue().trim());
        if (businessMode) {
            editAddress.setCompany(this.refCompany.current.getValue().trim());
            editAddress.setMail(this.refMail.current.getValue().trim());
            if (this.refMail2.current.getValue().length > 0) {
                editAddress.setMail2(this.refMail2.current.getValue().trim())
            }
            editAddress.setExtRefID(this.refExtRef.current.getValue().trim());
            editAddress.setUID(this.refUID.current.getValue().trim())
        }
        // if address is changed - unset HP Customer ID
        editAddress.unset(Address.prototype.col.hpIdentUniqueID)
        // save here
        editAddress.save(null)
            .then(sAddress => {
                if (handleAddressSaved) {
                    handleAddressSaved(sAddress);
                }
                disableSubmitFct(true);
                closeFct();
            })
            .catch(error => {
                setErrorFct({message: error.message});
                disableSubmitFct(true);
            });
    }
    getDefault(column) {
        if (this.props.address != null) {
            return this.props.address.get(column);
        }
        return "";
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {hideName, businessMode} = this.props;
        const validateNow = error && (error.message != null || error.length > 0);
        return (
            <form action="#">
                { businessMode ?
                    <Row>
                        <Col sm={12}>
                            <h4><I18n code="management.request.form.header.Adress"/></h4>
                            <ValidInput ref={this.refCompany}  validateNow={validateNow}
                                        valid={{maxLength: 500, check:['isRequired']}}
                                        placeholder={messages.get("address.union")}
                                        default={this.getDefault(Address.prototype.col.company)}/>
                        </Col>
                    </Row>
                    :
                    hideName ? null :
                        <Row>
                            <Col sm={6}>
                                <ValidInput ref={this.refName} validateNow={validateNow}
                                            autoComplete="fname"
                                            valid={{maxLength: 50, check:['isRequired']}}
                                            placeholder={messages.get("tournament.register.name")}
                                            default={this.getDefault(Address.prototype.col.name)}/>
                            </Col>
                            <Col sm={6}>
                                <ValidInput ref={this.refSurname} validateNow={validateNow}
                                            autoComplete="lname"
                                            valid={{maxLength: 50, check:['isRequired']}}
                                            placeholder={messages.get("tournament.register.surname")}
                                            default={this.getDefault(Address.prototype.col.surname)}/>
                            </Col>
                        </Row>
                }
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refStreet} validateNow={validateNow}
                                    autoComplete="street-address"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("address.street")}
                                    default={this.getDefault(Address.prototype.col.street)}/>
                    </Col>
                    <Col sm={4}>
                        <ValidInput ref={this.refZip} validateNow={validateNow}
                                    autoComplete="postal-code"
                                    valid={{maxLength: 6, check:['isRequired']}}
                                    placeholder={messages.get("address.zip")}
                                    default={this.getDefault(Address.prototype.col.zip)}/>
                    </Col>
                    <Col sm={8}>
                        <ValidInput ref={this.refPlace} validateNow={validateNow}
                                    autoComplete="locality"
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("address.place")}
                                    default={this.getDefault(Address.prototype.col.place)}/>
                    </Col>
                </Row>
                { businessMode ?
                    <Row>
                        <Col sm={12}>
                            <h4><I18n code="management.request.form.header.Contact"/></h4>
                        </Col>
                        <Col sm={6}>
                            <ValidInput ref={this.refName} validateNow={validateNow}
                                        autoComplete="fname"
                                        valid={{maxLength: 50, check:['isRequired']}}
                                        placeholder={messages.get("tournament.register.name")}
                                        default={this.getDefault(Address.prototype.col.name)}/>
                        </Col>
                        <Col sm={6}>
                            <ValidInput ref={this.refSurname} validateNow={validateNow}
                                        autoComplete="lname"
                                        valid={{maxLength: 50, check:['isRequired']}}
                                        placeholder={messages.get("tournament.register.surname")}
                                        default={this.getDefault(Address.prototype.col.surname)}/>
                        </Col>
                        <Col sm={12}>
                            <ValidInput ref={this.refMail} validateNow={validateNow}
                                        autoComplete="email"
                                        valid={{maxLength: 50, check:['isRequired', 'isMail']}}
                                        placeholder={messages.get("address.mail")}
                                        addonBefore={<FontAwesome icon="envelope"/>}
                                        default={this.getDefault(Address.prototype.col.mail)}/>
                            <ValidInput ref={this.refMail2}
                                        valid={{maxLength: 50, check:['isMail']}}
                                        placeholder={messages.get("address.mail") + " 2"}
                                        addonBefore={<FontAwesome icon="envelope"/>}
                                        default={this.getDefault(Address.prototype.col.mail2)}/>


                            <ValidInput ref={this.refPhone} validateNow={validateNow}
                                        autoComplete="tel"
                                        valid={{maxLength: 50, check:['isRequired','isPhone']}}
                                        placeholder={messages.get("address.phone")}
                                        addonBefore={<FontAwesome icon="phone"/>}
                                        default={this.getDefault(Address.prototype.col.phone)}/>
                            <ValidInput ref={this.refExtRef}
                                        valid={{maxLength: 50}}
                                        placeholder={messages.get("address.extRefID")}
                                        default={this.getDefault(Address.prototype.col.extRefID)}/>
                            <ValidInput ref={this.refUID}
                                        valid={{maxLength: 12}}
                                        placeholder={messages.get("address.uid")}
                                        default={this.getDefault(Address.prototype.col.uid)}/>

                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col sm={12}>
                            <ValidInput ref={this.refPhone}
                                        autoComplete="tel"
                                        valid={{maxLength: 50, check:['isPhone']}}
                                        placeholder={messages.get("address.phone")}
                                        addonBefore={<FontAwesome icon="phone"/>}
                                        default={this.getDefault(Address.prototype.col.phone)}/>
                        </Col>
                    </Row>
                }
                <Row>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.save"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
AddressForm.propTypes = {
    handleAddressSaved: PropTypes.func.isRequired,
    businessMode: PropTypes.bool.isRequired,
    hideName: PropTypes.bool,
    clubWriteRole: PropTypes.bool,
    address:PropTypes.object
};
AddressForm.contextType = ModalBasicContext;
module.exports = AddressModal;
