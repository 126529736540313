const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, Table,ButtonGroup, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    SolutionHelpLink = require("components/links/SolutionHelpLink"),
    TrainingUser = require("components/training/TrainingUser"),
    TrainingMenu = require("components/training/TrainingMenu")
    TrainingTeamDetail = require("components/training/TrainingTeamDetail");

const userStore = require("stores/UserStore"),
    calenderStore = require("stores/CalendarStore"),
    trainingActions = require("actions/TrainingActions"),
    trainingStore = require("stores/TrainingStore"),
    notificationActions = require("components/notification/NotificationActions");

class TrainingMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTrainingChange = this.handleTrainingChange.bind(this);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.state = {
            userStore: userStore.getState(),
            trainingStore: trainingStore.getState(),
            calenderStore: calenderStore.getState(),
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.training");
        userStore.listen(this.handleUserChange);
        trainingStore.listen(this.handleTrainingChange);
        calenderStore.listen(this.handleCalendarChange);
        notificationActions.reset();
        trainingActions.queryTrainingTypes();
        trainingActions.queryTrainingCountTypes();
        trainingActions.queryMyTrainingFilters(this.state.userStore.user, null)
        trainingActions.queryMyTrainingTeams(this.state.userStore.user, this.props.teamID);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        trainingStore.unlisten(this.handleTrainingChange);
        calenderStore.unlisten(this.handleCalendarChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTrainingChange(state) {
        this.setState({trainingStore: state});
    }
    handleCalendarChange(state) {
        this.setState({calenderStore: state});
    }
    render() {
        const {teamID} = this.props;
        return (
            <Grid>
                {this.state.userStore.user == null ?
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="danger">
                                <FontAwesome icon="exclamation-circle"/>
                                <I18n code="tournament.register.description.noaccount"/>
                            </Alert>
                        </Col>
                    </Row>
                    :
                    <React.Fragment>
                        {
                            this.state.userStore.userDetailLoading === true || this.state.userStore.userAboLoading?
                                <Loading />
                                :
                                <React.Fragment>
                                    <TrainingMenu user={this.state.userStore.user}
                                                  trainingFilters={this.state.trainingStore.eventTrainingFilters}
                                                  trainingTeams={this.state.trainingStore.trainingTeams}
                                                  trainingTeam={this.state.trainingStore.trainingTeam}/>
                                    <SolutionHelpLink folderID="42000109770" />
                                    <TeamInvitations memberInvitations={this.state.trainingStore.memberInvitations} />
                                    {
                                        teamID ?
                                            <TrainingTeamDetail trainingTeam={this.state.trainingStore.trainingTeam}
                                                                trainingTeamMembers={this.state.trainingStore.trainingTeamMembers}
                                                                trainingTeamResult={this.state.trainingStore.trainingTeamResult}
                                                                searchParams={this.state.trainingStore.searchParams}
                                                                isTeamMember={this.state.trainingStore.isTeamMember}
                                                                user={this.state.userStore.user}/>
                                            :
                                            <TrainingUser
                                                user={this.state.userStore.user}
                                                userFeatures={this.state.userStore.userFeatures}
                                                userDetail={this.state.userStore.userDetail}
                                                trainingTypes={this.state.trainingStore.trainingTypes}
                                                trainingCountTypes={this.state.trainingStore.trainingCountTypes}
                                                eventTrainingList={this.state.trainingStore.eventTrainingList}
                                                calendarBoundaries={this.state.calenderStore.calendarBoundaries}
                                                selectedCalEvent={this.state.calenderStore.selectedCalEvent}
                                            />
                                    }
                                </React.Fragment>

                        }
                    </React.Fragment>
                }
            </Grid>

        )
    }
}
const TeamInvitations = ({memberInvitations}) => {
    if (memberInvitations != null && memberInvitations.length > 0) {
        return <Row>
            <Col xs={6}>
                <h3><I18n code="profile.training.team.invitation"/></h3>
                <Table>
                    <thead>
                    <tr>
                        <th><I18n code="tournament.table.column.name"/></th>
                        <th><I18n code="parcours.details.button.actions"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        memberInvitations.map(member => {
                            return (<TeamInvitationsEntry key={member.id} member={member} />)
                        })
                    }
                    </tbody>
                </Table>
                <br/><br/>
            </Col>
        </Row>
    }
    return null;
}
const TeamInvitationsEntry = ({member}) => {
    function handleAcceptRequest() {
        trainingActions.setTeamMemberStatus(member, "A")
    }
    function handleDeclineRequest() {
        trainingActions.setTeamMemberStatus(member, "D")
    }
    return (<tr>
        <td>{member.getTeamName()}</td>
        <td><ButtonGroup>
            <Button bsStyle="primary" onClick={handleAcceptRequest}><FontAwesome icon="check"/></Button>
            <Button bsStyle="primary" onClick={handleDeclineRequest}><FontAwesome icon="times"/></Button>
        </ButtonGroup></td>
    </tr>)
};
module.exports = TrainingMainPage;
