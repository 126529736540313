var Parse = require("ParseInit");

var TargetCatalog = Parse.Object.extend("targetCatalog", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        extRefID: "extRefID",
        name: "name",
        desc: "desc",
        brandID: "brandID",
        brandArtNo : "brandArtNo",
        imageUrl: "imageUrl",
        affilLink: "affilLink",
        size: "size",
        group: "group",
        weight: "weight",
        height: "height",
        length: "length",
        price: "price",
        width:"width",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt"
    },
    getLabels() {
        return [this.getName(), this.getBrandArtNo(), this.getBrandID().getName(), "Gruppe" + this.getGroup()].join(" ");
    },
    getDatalistText() {
        return this.getName() + " , " + this.getBrandID().getName() + " , " + this.getBrandArtNo();
    },
    getDatalistImage() {
        return this.getImageUrl();
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getExtRefID() {
        return this.get(this.col.extRefID)
    },
    setExtRefID(extRefID) {
        return this.set(this.col.extRefID, extRefID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getDescription() {
        return this.get(this.col.desc)
    },
    setDescription(desc) {
        return this.set(this.col.desc, desc)
    },
    getBrandArtNo() {
        return this.get(this.col.brandArtNo)
    },
    setBrandArtNo(brandArtNo) {
        return this.set(this.col.brandArtNo, brandArtNo)
    },
    getSize() {
        return this.get(this.col.size)
    },
    setSize(size) {
        return this.set(this.col.size, size)
    },
    getGroup() {
        return this.get(this.col.group)
    },
    setGroup(group) {
        return this.set(this.col.group, group)
    },
    getPrice() {
        return this.get(this.col.price)
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    getWeight() {
        return this.get(this.col.weight)
    },
    setWeight(weight) {
        return this.set(this.col.weight, weight)
    },
    getHeight() {
        return this.get(this.col.height)
    },
    setHeight(height) {
        return this.set(this.col.height, height)
    },
    getLength() {
        return this.get(this.col.length)
    },
    setLength(length) {
        return this.set(this.col.length, length)
    },
    getWidth() {
        return this.get(this.col.width)
    },
    setWidth(width) {
        return this.set(this.col.width, width)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getBrandID() {
        return this.get(this.col.brandID)
    },
    setBrandID(brandID) {
        return this.set(this.col.brandID, brandID)
    },
    getImageUrl() {
        return this.get(this.col.imageUrl)
    },
    setImageUrl(url) {
        return this.set(this.col.imageUrl, url)
    },
    getAffilLink() {
        return this.get(this.col.affilLink)
    },
    setAffilLink(link) {
        return this.set(this.col.affilLink, link)
    },
    getCurrency() {
        return "EUR"
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TargetCatalog;
