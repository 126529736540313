const React = require("react"),
    PropTypes = require('prop-types');
const messages = require("i18n/messages");

const I18n = ({code, children}) => {
    return (<span>{messages.get(code)}{children}</span>);
};
I18n.propTypes = {
    code: PropTypes.string.isRequired
};
module.exports = I18n;
