/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const messages = require("i18n/messages"),
    ListUnstyled = require("components/widgets/ListUnstyled");

const { MarkerClusterer  } = require ('@react-google-maps/api');
const BaseMap = require("components/map/BaseMap");
const ParcoursMapMarker = require("components/map/ParcoursMapMarker");
const BasicMapMarker = require("components/map/BasicMapMarker");
const MapUtil = require("components/map/MapUtil");
const ErrorBoundary = require("components/widgets/ErrorBoundary");
const linkUtil = require("linkUtil");
const cookieUtil = require("util/cookieUtil");

const MapFrame = ({parcours, ownPosition}) => {
    function onCenterChanged(coordinate) {
        cookieUtil.update("wikiCenter", coordinate);
    }
    function getCenter() {
        let cookie = cookieUtil.read();
        if (cookie.wikiCenter) {
            return  cookie.wikiCenter;
        }
        return {lat: 48.249863, lng: 14.635051}
    }
    let mapUtil = new MapUtil();
    const parcoursOwnerDic = mapUtil.buildOwnerDic(parcours);
    const parcoursOwnerList = Object.keys(parcoursOwnerDic).map(function(key){
        return parcoursOwnerDic[key];
    });
    const clusterImagePath = linkUtil.getFullLinkNoLanguage('img/markercluster/m');

    return (
        <div>
            <BaseMap
                mapOptions={{ center: getCenter(),
                    containerStyle: {width: "100%", height: '500px'},
                    zoom: 7, mapTypeId:"roadmap"
                }} elements={parcoursOwnerList}
                onCenterChanged={onCenterChanged}>
                <MarkerClusterer minimumClusterSize={4} imagePath={clusterImagePath}>
                    {(clusterer) =>
                        Object.keys(parcoursOwnerDic).map(ownerId => {
                            if (clusterer != null) {
                                let mapElement = parcoursOwnerDic[ownerId];

                                return <ErrorBoundary key={ownerId} identifier={"OwnerId:" + ownerId}>
                                    <ParcoursMapMarker mapElement={mapElement} clusterer={clusterer}/>
                                </ErrorBoundary>
                            }
                        })
                    }
                </MarkerClusterer>
                {
                    ownPosition != null ? <BasicMapMarker coordinate={ownPosition} /> : null
                }
            </BaseMap>
            <h3>{messages.get("tournament.attendee.status.legend")}</h3>
            <table>
                <tbody>
                <tr>
                    <td className="col-md-3 col-sm-6">
                        <ListUnstyled>
                            <li><img alt="" src="/img/ico/archery_partner.png"/>&nbsp;{messages.get("home.parcours.title")}</li>
                            <li><img alt="" src="/img/ico/archery.png"/>&nbsp;{messages.get("ParcoursInfo_type_P_parcours")}</li>
                            <li><img alt="" src="/img/ico/field.png"/>&nbsp;{messages.get("ParcoursInfo_type_F_field")}</li>
                            <li><img alt="" src="/img/ico/target.png"/>&nbsp;{messages.get("ParcoursInfo_type_T_target")}</li>
                            <li><img alt="" src="/img/ico/4d_kino.png"/>&nbsp;{messages.get("ParcoursInfo_type_C_cinema")}</li>
                            <li><img alt="" src="/img/ico/archery_private.png"/>&nbsp;{messages.get("ParcoursInfo_private_parcours")}</li>
                            <li><img alt="" src="/img/ico/shop.png"/>&nbsp;{messages.get("cat_shop")}</li>
                            <li><img alt="" src="/img/ico/hotel.png"/>&nbsp;{messages.get("cat_hotel")}</li>
                            <li><img alt="" src="/img/ico/webshop.png"/>&nbsp;{messages.get("cat_webshop")}</li>
                        </ListUnstyled>
                    </td>
                    <td className="col-md-3 col-sm-6">
                        <ListUnstyled>
                            <li><img alt="" src="/img/ico/recurve.png"/>&nbsp;{messages.get("cat_type_traditional")}</li>
                            <li><img alt="" src="/img/ico/compound.png"/>&nbsp;{messages.get("cat_type_compound")}</li>
                            <li><img alt="" src="/img/ico/crossbow.png"/>&nbsp;{messages.get("cat_type_crossbow")}</li>
                            <li><img alt="" src="/img/ico/blowgun.png"/>&nbsp;{messages.get("cat_type_blowgun")}</li>
                            <li><img alt="" src="/img/ico/summer.png"/>&nbsp;{messages.get("type_summer")}</li>
                            <li><img alt="" src="/img/ico/winter.png"/>&nbsp;{messages.get("type_winter")}</li>
                        </ListUnstyled>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
};
MapFrame.propTypes = {
    parcours:PropTypes.array.isRequired,
    ownPosition:PropTypes.object
};
module.exports = MapFrame;