const alt = require("AltInit");

const parseDao = require("actdao/ParseBaseDao");
const dao = require("actdao/TournamentManagerDao");
const tournamentUserDao = require("actdao/TournamentUserDao");
const notificationActions = require("components/notification/NotificationActions");
const changeLogActions = require("actions/ChangeLogActions");
const changeLogDao = require("actdao/ChangeLogDao");
const msgActions = require("actions/MessageActions");
const {Tournament, TournamentUser, TournamentUserGroup,TournamentPrice,TournamentRound,
    TournamentConfig, TournamentCupOption, CountType} = require("parse/_Domain");
const ParseListUtility = require("util/ParseListUtility"),
    TournamentUserTeamResultDic = require("util/TournamentUserTeamResultDic");

class TournamentManagerActions {
    showDetails(id, user, adminCheck) {
        this.loadTournamentDetails(id, user, adminCheck);
        return {};
    };

    showProgress() {
        return {};
    };

    loadTournamentDetails(id, user, adminCheck) {
        if (user == null) {
            this.setInvalidUser();
            return {};
        }
        dao.loadTournamentDetails(id)
            .then(tournament => {
                if (!tournament.isEditor(user)) {
                    if (adminCheck) {
                        parseDao.isUserAdmin()
                            .then(response => {
                                console.log("AdminOverride")
                            })
                            .catch(error => {
                                this.setInvalidUser();
                                return {};
                            });
                    } else {
                        this.setInvalidUser();
                        return {};
                    }
                }
                if (tournament.isTypeCupMaster()) {
                    // load child tournaments
                    this.loadTournamentCupChilds(tournament);
                    this.updateTournamentRounds([]);
                } else {
                    // normal tournament
                    this.loadTournamentRounds(tournament);
                    this.loadTournamentRegSlots(tournament);
                }
                this.updateTournamentDetail(tournament);
            })
            .catch(error => {
                error.trace = "TMAA.loadTourDetail";
                notificationActions.parseError(error);
            });
        return {};
    };
    loadTournamentRegSlots(tournament) {
        dao.loadTournamentRegSlots(tournament)
            .then(tRegSlots => {
                this.updateTournamentSlots(tRegSlots);
            }).catch(error => {
            error.trace = "TMAA.loadSlots"
            notificationActions.parseError(error);
        });
        return {};
    }
    loadTournamentRounds(tournament) {
        dao.loadTournamentRounds(tournament)
            .then(tRounds => {
                this.updateTournamentRounds(tRounds);
            }).catch(error => {
            error.trace = "TMAA.loadRounds";
                notificationActions.parseError(error);
        });
        return {};
    };
    findTournamentCupMaster(bowUnion, callback) {
        dao.findTournamentCupMaster(bowUnion)
            .then(tournamentCupMaster => {
                if (tournamentCupMaster != null && !tournamentCupMaster.isDeleted()) {
                    if (tournamentCupMaster.hasInitialRegistration()) {
                        callback(tournamentCupMaster)
                    } else {
                        notificationActions.warning("tournament.manager.config.cupMaster.notActive");
                    }

                } else {
                    notificationActions.warning("tournament.manager.NotFound");
                }
            })
            .catch(error => {
                error.trace = "TMAA.findCupMaster";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadTournamentCupMaster(tournamentID, bowUnion, callback) {
        dao.loadTournamentCupMaster(tournamentID)
            .then(tournamentCupMaster => {
                if (tournamentCupMaster != null && !tournamentCupMaster.isDeleted()) {
                    if (tournamentCupMaster.hasInitialRegistration()) {
                        const cmBowUnion = tournamentCupMaster.getBowUnionID();
                        if (cmBowUnion && cmBowUnion.id == bowUnion.id) {
                            // check for "CupMaster"
                            if (tournamentCupMaster.getCupMasterTournamentID()) {
                                notificationActions.warning("tournament.manager.config.cupMaster.NotMaster");
                            } else {
                                callback(tournamentCupMaster)
                            }
                        } else {
                            notificationActions.warning("tournament.manager.config.cupMaster.wrongBowUnion");
                        }
                    } else {
                        notificationActions.warning("tournament.manager.config.cupMaster.notActive");
                    }

                } else {
                    notificationActions.warning("tournament.manager.NotFound");
                }
            })
            .catch(error => {
                error.trace = "TMAA.loadCupMaster";
                notificationActions.parseError(error);
            });
        return {};
    };
    loadTournamentCupChilds(cupMaster) {
        dao.loadTournamentCupChilds(cupMaster)
            .then(tTournaments => {
                this.updateCupChildTournaments(tTournaments);
            })
            .catch(error => {
                error.trace = "TMAA.loadCupChilds";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTournamentOptions(callback) {
        dao.queryTournamentOptions()
            .then(tournamentOptions => {
                callback(tournamentOptions);
            })
            .catch(error => {
                error.trace = "TMAA.qTOpts";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentImage(tournament, colName, image) {
        tournament.set(colName, image);
        this.saveTournament(tournament, null, null);
        return {};
    };

    updateTournamentRoundStatus(tournamentRound, status) {
        tournamentRound.set(TournamentRound.prototype.col.status, status);
        if (TournamentRound.prototype.status.running === status) {
            // also hide points on start round
            tournamentRound.setHidePoints(true);
        }
        this.saveTournamentRound(tournamentRound);
        if (status == TournamentRound.prototype.status.running) {
            // set this round to all waiting groups
            this.prepareTournamentGroupsForRound(tournamentRound);
        }
        return {};
    };

    updateBowUnionwithTypes(tournament, bowUnion, selBowTypes, selAgeTypes) {
        // single tournament (subtype == null; selBowTypes & selAgeTypes filled)
        // cup master (subtype == "CM"; selBowTypes & selAgeTypes filled)
        this.saveConfigList(tournament, selBowTypes, selAgeTypes, (eTournament => {
            eTournament.setBowUnionID(bowUnion);
            // check for Cup
            if (bowUnion.isTypeCup()) {
                let tCupOption = new TournamentCupOption();
                tCupOption.setStatus("A");
                tCupOption.setMinCupResults(bowUnion.getMinCupResults());
                tCupOption.setBaseTargets(bowUnion.getBaseTargets());
                tCupOption.setCupPoints(bowUnion.getCupPoints());
                tCupOption.setCupGroups(bowUnion.getCupGroups());
                parseDao.saveObject(tCupOption)
                    .then(sTCupOption => {
                        tournament.setTournamentCupOptionID(sTCupOption);
                        this.saveTournamentCB(eTournament, null);
                    })
                    .catch(error => {
                        error.trace = "TMAA.sTCupOpt";
                        notificationActions.parseError(error);
                    })
            } else {
                // save tournament
                this.saveTournamentCB(eTournament, null);
            }
        }));
    }
    updateBowUnionForCupMaster(tournament, bowUnion, tournamentCupMaster) {
        // cup child (subType == "CC"; ; selBowTypes & selAgeTypes null, but cuMaster)
        tournament.setBowUnionID(bowUnion);
        tournament.setCupMasterTournamentID(tournamentCupMaster);
        tournament.setTournamentCupOptionID(tournamentCupMaster.getTournamentCupOptionID());
        // save tournament
        tournament.setTournamentConfigBow(tournamentCupMaster.getTournamentConfigBow());
        tournament.setTournamentConfigAge(tournamentCupMaster.getTournamentConfigAge());
        this.saveTournamentCB(tournament, () => {
            this.loadTournamentDetails(tournament.id, tournamentCupMaster.getMainEditor(), null);
        });
    }

    saveTournamenConfig(tournament,isBowEntry, tConfig, callback) {
        const isNew = tConfig.isNew();
        parseDao.saveObject(tConfig)
            .then(sTConfig => {
                if (isNew) {
                    let configList = isBowEntry ? tournament.getTournamentConfigBow() : tournament.getTournamentConfigAge();
                    if (configList == null) {
                        configList = [];
                    }
                    configList.push(sTConfig);
                    if (isBowEntry) {
                        tournament.setTournamentConfigBow(configList);
                    } else {
                        tournament.setTournamentConfigAge(configList);
                    }
                    this.saveTournamentCB(tournament, callback);
                } else {
                    this.updateTournamentDetail(tournament);
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch(error => {
                error.trace = "TMAA.sTConfig";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveTournamentSubObject(tournament, subObject, column, callback) {
        const isNew = subObject.isNew();
        parseDao.saveObject(subObject)
            .then(sSubObject => {
                if (isNew) {
                    tournament.set(column, sSubObject);
                    this.saveTournamentCB(tournament, callback);
                } else {
                    this.updateTournamentDetail(tournament);
                    if (callback) {
                        callback();
                    }
                }

            })
            .catch(error => {
                error.trace = "TMAA.sSubObj";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveConfigList(tournament, selBowTypes, selAgeTypes, callback) {
        const tConfigBow = this.createConfigType(selBowTypes, "bowType");
        const tConfigAge = this.createConfigType(selAgeTypes, "ageType");
        // SAVE ObjectList before store pointer
        if (tConfigBow) {
            // first save bow
            parseDao.saveAll(tConfigBow)
                .then(sTConfigBow => {
                    tournament.setTournamentConfigBow(sTConfigBow);
                    if (tConfigAge) {
                        // second save age
                        parseDao.saveAll(tConfigAge)
                            .then(sT1ConfigAge => {
                                // saved bow and age
                                tournament.setTournamentConfigAge(sT1ConfigAge);
                                callback(tournament);
                            })
                            .catch(error => {
                                error.trace = "TMAA.sTCAgeSec";
                                notificationActions.parseError(error);
                            })
                    } else {
                        // saved only bow
                        callback(tournament);
                    }
                })
                .catch(error => {
                    error.trace = "TMAA.sTCBowFirst";
                    notificationActions.parseError(error);
                })

        } else if (tConfigAge) {
                // save only age
                parseDao.saveAll(tConfigAge)
                    .then(sTConfigAge => {
                        tournament.setTournamentConfigAge(sTConfigAge);
                        callback(tournament);
                    })
                    .catch(error => {
                        error.trace = "TMAA.sTCAgeSingle";
                        notificationActions.parseError(error);
                    })
        } else {
            // no config selected
            callback(tournament);
        }
        return {};
    }

    createConfigType(typeList, configType) {
        let tConfigArray = null;
        if (typeList && typeList.length > 0) {
            tConfigArray = [];
            typeList.map(typeElement=> {
                let tConfig = new TournamentConfig();
                tConfig.setStatus("A");
                tConfig.setCode(typeElement.getCode());
                tConfig.setTypeID(typeElement.id);
                if ("bowType" === configType) {
                    // new bow group
                    tConfig.setName(typeElement.getName());
                    let ctBean = typeElement.getCountTypeID();
                    if (ctBean == null) {
                        ctBean = new CountType();
                        ctBean.id = "7jYdrI2KWE";
                    }
                    tConfig.setCountTypeID(ctBean);
                } else {
                    // new age group
                    tConfig.setName(typeElement.getName() + ", " + typeElement.getDescription());
                    // update ages
                    let ages = typeElement.getAges();
                    if (ages != null) {
                        tConfig.setAges(ages)
                    }
                }
                tConfigArray.push(tConfig);
            })
        }
        return tConfigArray;
    }

    closeTournamentFinalRound(tournament, tournamentRound, isLastRound) {
        tournamentRound.set(TournamentRound.prototype.col.status, TournamentRound.prototype.status.closed);
        // store result for this round
        if (isLastRound) {
            this.saveTournamentUserResult(tournament, tournamentRound.getTargetAmounts(), true, tournamentRound.isFinalAdd());
        } else {
            // close all groups and set tournamentUser to wait for next round
            this.closeTournamentGroupsForRound(tournamentRound);
        }

        this.saveTournamentRound(tournamentRound);
        return {};
    }

    closeTournamentSimpleRound(tournament, tournamentRound, isLastRound) {
        tournamentRound.set(TournamentRound.prototype.col.status, TournamentRound.prototype.status.closed);
        if (isLastRound) {
            // store result for this round
            this.saveTournamentUserResult(tournament, tournamentRound.getTargetAmounts(), false, false);
        } else {
            // close all groups and set tournamentUser to wait for next round
            this.closeTournamentGroupsForRound(tournamentRound);
        }
        this.saveTournamentRound(tournamentRound);
        return {};
    }

    closeTournamentGroupRound(tournament, tournamentRound) {
        tournamentRound.set(TournamentRound.prototype.col.status, TournamentRound.prototype.status.closed);
        // store result for this round
        if (tournament.isTypePairRegistration()) {
            this.saveTournamentUserTeamResult(tournament, true);
        } else {
            // TODO handle other group types
        }
        this.saveTournamentRound(tournamentRound);
        return {};
    }

    closeTournamentGroupsForRound(tRound) {
        // reset all started groups to Status A and remove RoundID
        dao.queryTournamentGroupsForRound(tRound, [TournamentRound.prototype.status.deleted, TournamentRound.prototype.status.present])
            .then(tuGroups => {
                if (tuGroups.length > 0) {
                    tuGroups.map(tuGroup => {
                        tuGroup.unset(TournamentUserGroup.prototype.col.tournamentRoundID);
                        tuGroup.set("status", "A");
                    });
                    parseDao.saveAll(tuGroups)
                        .then(savedTuGroups => {
                            savedTuGroups.map(sTuGroup => {
                                const tuList = sTuGroup.get(TournamentUserGroup.prototype.col.tournamentUserIDs);
                                tuList.map(tUser => {
                                    tUser.set("status", "W");
                                });
                                parseDao.saveAll(tuList)
                                    .then(sTuList => {
                                        console.log("saved tournament users");
                                    })
                                    .catch(error => {
                                        error.trace = "TMAA.saveTUList";
                                        notificationActions.parseError(error);
                                    });
                            })

                        })
                        .catch(error => {
                            error.trace = "TMAA.saveTGrps";
                            notificationActions.parseError(error);
                        });
                } else {
                    console.log("No Groups to save")
                }
            })
            .catch(error => {
                error.trace = "TMAA.queryGrpsRnd";
                notificationActions.parseError(error);
            });
        return {};
    }

    prepareTournamentGroupsForRound(tRound) {
        // take all for the tournament which are not deleted on not running
        dao.queryTournamentGroupsForUnsetRound(tRound.getTournamentID(), [TournamentRound.prototype.status.deleted, TournamentRound.prototype.status.running])
            .then(tuGroups => {
                if (tuGroups.length > 0) {
                    tuGroups.map(tuGroup => {
                        // add to new round and unset result
                        tuGroup.setTournamentRoundID(tRound);
                        tuGroup.unset(TournamentUserGroup.prototype.col.actTargetNumber);
                        tuGroup.unset(TournamentUserGroup.prototype.col.actTargetAmount);
                        tuGroup.unset(TournamentUserGroup.prototype.col.actResult);
                    });
                    parseDao.saveAll(tuGroups)
                        .then(savedTuGroups => {
                            console.log("saved tournamentGroups");
                        })
                        .catch(error => {
                            error.trace = "TMAA.saveTGrps";
                            notificationActions.parseError(error);
                        });
                } else {
                    console.log("No Groups to save")
                }
            })
            .catch(error => {
                error.trace = "TMAA.queryGrpsRnd";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveTournamentSlot(tournamentSlot) {
        parseDao.saveObject(tournamentSlot)
            .then(sTournamentSlot => {
                // recount slot after status update
                dao.recountTournamentRegSlot(tournamentSlot.id)
                    .then(rTournamentSlot => {
                        this.updateTournamentSlot(rTournamentSlot)
                })
            })
            .catch(error => {
                error.trace = "TMAA.saveTSlot";
                notificationActions.parseError(error);
            });
        return {};
    }


    saveTournamentRound(tournamentRound) {
        parseDao.saveObject(tournamentRound)
            .then(sTournamentRound => {
                this.updateTournamentRound(sTournamentRound)
            })
            .catch(error => {
                error.trace = "TMAA.saveTRoud";
                notificationActions.parseError(error);
            });
        return {};
    };
    prepareTournament(tournament, user) {
        tournament.set(Tournament.prototype.col.status, Tournament.prototype.status.published);
        this.saveTournament(tournament, "status", Tournament.prototype.status.published);
        changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, null, Tournament.prototype.status.published)
        return {};
    }
    cancelTournament(tournament, user, value) {
        if (value) {
            // canceled
            let tPrice = tournament.getTournamentPriceID();
            if (tPrice != null) {
                tPrice.setPayStatus(TournamentPrice.prototype.payStatus.inactive);
                this.saveTournamentPrice(tournament, tPrice);
            }
            tournament.set(Tournament.prototype.col.status, Tournament.prototype.status.canceled);
            tournament.set(Tournament.prototype.col.regStatus, Tournament.prototype.registrationStatus.closed);
            this.saveTournament(tournament, "status", Tournament.prototype.status.canceled);
            changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, null, Tournament.prototype.status.canceled)
        } else {
            // not canceled
            tournament.set(Tournament.prototype.col.status, Tournament.prototype.status.published);
            this.saveTournament(tournament, null, null);
            changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, null, Tournament.prototype.status.published)
        }

        return {};
    }
    startTournament(tournament, user, tSimpleRounds) {
        tournament.set(Tournament.prototype.col.status, Tournament.prototype.status.running);
        for (let i = 0; i < tSimpleRounds.length; i++) {
            if (tSimpleRounds[i].isRunning()) {
                // round is already running - no change
                break;
            } else if (tSimpleRounds[i].isPresent()) {
                // start first waiting round
                tSimpleRounds[i].set(TournamentRound.prototype.col.status, TournamentRound.prototype.status.running);
                tSimpleRounds[i].setHidePoints(true)
                parseDao.saveObject(tSimpleRounds[i])
                    .then(sTournamentRound => {
                        this.saveTournament(tournament, "status", Tournament.prototype.status.running);
                        this.updateTournamentRound(sTournamentRound);
                    })
                    .catch(error => {
                        error.trace = "TMAA.saveSimpleRound";
                        notificationActions.parseError(error);
                    });
                return {};
            }
        }
        this.saveTournament(tournament, "status", Tournament.prototype.status.running);
        changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, null, Tournament.prototype.status.running)
        return {};
    }
    finishTournament(tournament, user) {
        let oldStatus = tournament.getName() + ":" + tournament.getStatus();
        if (tournament.isRunning()) {
            // close tournament
            tournament.setStatus(Tournament.prototype.status.closed);
            this.saveTournament(tournament, Tournament.prototype.col.status, Tournament.prototype.status.closed);
            changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, oldStatus, Tournament.prototype.status.closed)
        } else {
            // change status to A/A if tournament was never started
            tournament.setRegistrationStatus(Tournament.prototype.registrationStatus.published);
            tournament.setStatus(Tournament.prototype.status.published);
            this.saveTournament(tournament, Tournament.prototype.col.status, "FA");
            changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, oldStatus, Tournament.prototype.status.published)
        }
        return {};
    }

    saveTournamentUserTeamResult(tournament, isPair) {
        tournament.getTournamentConfigAge().map(tcAge => {
            this.saveTournamentUserTeamResultEachGroup(tournament, tcAge, isPair);
        });
        return {};
    }
    saveTournamentUserTeamResultEachGroup(tournament, tcAge, isPair) {
        // query for current user registration:
        if (tcAge) {
            dao.queryTournamentUserTeamForConfig(tournament, null, tcAge)
                .then(tUserTeams => {
                    // handle found users for special group
                    let resultPositionMale = 1;
                    if (tUserTeams.length > 0) {
                        const userResultDic = new TournamentUserTeamResultDic();
                        tUserTeams.map(tUserTeam => {
                            // sort depending
                            userResultDic.addSortTournamentUserTeam(tUserTeam);
                        });
                        const sortedUser = userResultDic.getList(userResultDic.generateDicKey(tcAge, null, "x"));
                        sortedUser.map(tUserTeam => {
                            const tmpPos = resultPositionMale;
                            resultPositionMale++;
                            tUserTeam.setResultPosition(tmpPos);
                            tUserTeam.setStatus("C");
                            if (isPair) {
                                // also update TUser
                                const tUsers = tUserTeam.getTournamentUserIDs();
                                tUsers.map(tournamentUser => {
                                    tournamentUser.setResultPosition(tmpPos);
                                    tournamentUser.setStatus("C");
                                });
                            }
                        });
                        parseDao.saveAll(sortedUser)
                            .then(savedTUser => {
                                // notify success
                                notificationActions.success(tcAge.getName()  + " Teilnehmerpositionen erfolgreich gespeichert", "success", 5000);
                            })
                            .catch(error => {
                                error.trace = "TMAA.saveTUResult4Grp";
                                notificationActions.parseError(error);
                            });
                    }
                })
                .catch(error => {
                    error.trace = "TMAA.queryTUResultRound";
                    notificationActions.parseError(error);
                });
        } else {
            console.log("WTF - no bow or age group")
        }
        return {};
    }
    saveTournamentUserResult(tournament, actTargetAmount, isFinal, addFinale) {
        // lippek handling no bows grouping
        const bowTypes = tournament.getBowUnionID() && tournament.getBowUnionID().isTypeLippek() ? [null] : tournament.getTournamentConfigBow();
        tournament.getTournamentConfigAge().map(tcAge => {
            bowTypes.map(tcBow => {
                this.saveTournamentUserResultEachGroup(tournament, actTargetAmount, tcBow, tcAge, isFinal, addFinale);
            });
        });
        return{};
    }
    saveTournamentUserResultEachGroup(tournament, actTargetAmount, tcBow, tcAge, isFinal, addFinale) {
        // query for current user registration - tcBow may be null:
        if (tcAge) {
            const noSexSplit = tcAge.get("noSexSplit");
            let resultColName = TournamentUser.prototype.col.resultPosition;
            if (isFinal) {
                resultColName = TournamentUser.prototype.col.finalResultPosition;
            }
            dao.queryTournamentUserForConfig(tournament, tcBow, tcAge, isFinal)
                .then(tUsers => {
                    // handle found users for special group
                    const cupPointsArray = tournament.getTournamentCupOptionID() ? tournament.getTournamentCupOptionID().getCupPoints() : null;
                    const cupScoreBaseTarget = tournament.getTournamentCupOptionID() ? tournament.getTournamentCupOptionID().getBaseTargets() : null;
                    let resultPositionMale = 1;
                    let resultPositionFemale = 1;
                    if (tUsers.length > 0) {
                        //TODO verify for what is sortedUser array
                        const sortedUser = [];
                        tUsers.map(tUser => {
                            // sort depending on final yes or no and zero or add
                            this.pushUserSorted(sortedUser, tUser, isFinal, addFinale)
                        });
                        sortedUser.map(tUser => {
                            if (noSexSplit) {
                                tUser.set(resultColName, resultPositionMale);
                                this.setCupPoints(tUser, cupPointsArray, cupScoreBaseTarget, actTargetAmount, resultPositionMale)
                                resultPositionMale++;
                            } else {
                                if (tUser.getSex() == 0) {
                                    tUser.set(resultColName, resultPositionMale);
                                    this.setCupPoints(tUser, cupPointsArray, cupScoreBaseTarget, actTargetAmount, resultPositionMale)
                                    resultPositionMale++;
                                } else {
                                    tUser.set(resultColName, resultPositionFemale);
                                    this.setCupPoints(tUser, cupPointsArray, cupScoreBaseTarget, actTargetAmount, resultPositionFemale)
                                    resultPositionFemale++
                                }
                            }
                            tUser.setStatus("C");
                        });
                        parseDao.saveAll(sortedUser)
                            .then(savedTUser => {
                                // notify success
                                if (tcBow) {
                                    notificationActions.success(tcBow.getName() + "/" + tcAge.getName()  + " Teilnehmerpositionen erfolgreich gespeichert", "success", 5000);
                                } else {
                                    notificationActions.success(tcAge.getName()  + " Teilnehmerpositionen erfolgreich gespeichert", "success", 5000);
                                }

                            })
                            .catch(error => {
                                error.trace = "TMAA.saveTUResult4Grp";
                                notificationActions.parseError(error);
                            });
                    }
                })
                .catch(error => {
                    error.trace = "TMAA.queryTUResultRound";
                    notificationActions.parseError(error);
                });
        } else {
            console.log("WTF - no bow or age group")
        }
        return{};
    }
    setCupPoints(tUser, cupPointsArray, cupScoreBaseTarget, actTargetAmount, position) {
        let index = position - 1;
        let points = 0;
        if (cupPointsArray != null) {
            if (cupPointsArray.length > index) {
                points = parseInt(cupPointsArray[index]);
                if (isNaN(points)) {
                    points = 0;
                }
            }
        } else if (cupScoreBaseTarget != null && cupScoreBaseTarget > 0 && actTargetAmount > 0) {
            // take score and recalc them to baseTarget amount
            let subTournamentPoints = tUser.getSortableSumPoints();
            if (subTournamentPoints > 0) {
                points += Math.round(subTournamentPoints / actTargetAmount * cupScoreBaseTarget);
            }
        } else {
            // use real points if nothing is set
            points = tUser.getSortableSumPoints();
        }
        // console.log("CupPoints: " + points + " for " + tUser.id + " at index: " + index)
        tUser.setCupPoints(points);
        return tUser
    }
    pushUserSorted(groupedTUList, tournamentUser, isFinal, addFinale) {
        if (tournamentUser.isDisqualified()) {
            return {};
        }
        let added = false;
        let index = groupedTUList.length - 1;
        while (index >= 0 && !added) {
            if (tournamentUser.getUserDictSortSum(isFinal, addFinale) < groupedTUList[index].getUserDictSortSum(isFinal, addFinale)) {
                // add last
                groupedTUList.splice(index + 1, 0, tournamentUser);
                added = true;
            } else if (tournamentUser.getUserDictSortSum(isFinal, addFinale) == groupedTUList[index].getUserDictSortSum(isFinal, addFinale)) {
                if (tournamentUser.getUserDictKillValue(isFinal, addFinale) <= groupedTUList[index].getUserDictKillValue(isFinal, addFinale)) {
                    groupedTUList.splice(index + 1, 0, tournamentUser);
                    // console.log("add " + index);
                    added = true;
                }
            }
            index--;
        }
        if (!added) {
            groupedTUList.splice(0, 0, tournamentUser);
        }
        return{};
    };
    updateTournamentRegStatus(tournament, user, status) {
        tournament.set(Tournament.prototype.col.regStatus, status);
        this.saveTournament(tournament, Tournament.prototype.col.regStatus, status);
        changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.regStatus, null, status)
        return {};
    }

    updateTournamentStatus(tournament, user, status) {
        tournament.set(Tournament.prototype.col.status, status);
        this.saveTournament(tournament, Tournament.prototype.col.status, status);
        changeLogActions.addTournamentChange(tournament, user, Tournament.prototype.col.status, Tournament.prototype.status.published, status)
        return {};
    }

    updateTournamentUserCupGroup(tournament, cupGroup) {
        tournamentUserDao.loadAllTournamentUsers(tournament, true)
            .then(tournamentUsers => {
                if (tournamentUsers.length > 0) {
                    tournamentUsers.forEach(tUser => {
                        tUser.setCupGroup(cupGroup)
                    })
                    parseDao.saveAll(tournamentUsers)
                        .then(sUsers => {
                            notificationActions.success("Updated " + sUsers.length + " archers to " + cupGroup + " successfully")
                        })
                }
            })
            .catch(error => {
                error.trace = "TMAA.updTUserCupGroup";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentPayStatus(tournament, user, payStatus) {
        let tPrice = tournament.getTournamentPriceID();
        if (tPrice != null) {
            tPrice.setPayStatus(payStatus);
            this.saveTournamentPrice(tournament, tPrice);
        } else {
            tPrice = new TournamentPrice();
            tPrice.setPayStatus(payStatus);
            tournament.setTournamentPriceID(tPrice);
            this.saveTournament(tournament, null, null);
        }
        changeLogActions.addTournamentChange(tournament, user, TournamentPrice.prototype.col.payStatus, null, payStatus)
        this.reportTournamentPayStatusChange(tournament, payStatus);
        return {};
    }
    saveTournamentCB(tournament, callback) {
        parseDao.saveObject(tournament)
            .then(tournament => {
                if (callback) {
                    callback()
                }
                this.updateTournamentDetail(tournament);
            })
            .catch(error => {
                error.trace = "TMAA.saveCBT";
                notificationActions.parseError(error);
            });
        return {};
    }
    saveTournament(tournament, column, status) {
        parseDao.saveObject(tournament)
            .then(tournament => {
                if (column != null) {
                    this.reportTournamentStatusChange(tournament, column, status);
                }
                this.updateTournamentDetail(tournament);
            })
            .catch(error => {
                error.trace = "TMAA.saveT";
                notificationActions.parseError(error);
            });
        return {};
    }
    saveTournamentPrice(tournament, tPrice) {
        parseDao.saveObject(tPrice)
            .then(price => {
                this.updateTournamentDetail(tournament);
            })
            .catch(error => {
                error.trace = "TMAA.saveTPrice";
                notificationActions.parseError(error);
            });
        return {};
    }

    reportTournamentPayStatusChange(tournament, payStatus) {
        let status = "aktiviert";
        if (payStatus !== "A") {
            status = "deaktivert";
        }
        let message = tournament.getName() + ": Mobiles Bezahlsystem wurde " + status;
        msgActions.slackNotify(message, "de/tournament/" + tournament.id, null);
        return {};
    }
    reportTournamentStatusChange(tournament, column, status) {
        let info = "";
        if ("status" == column) {
            info = "Status: " + status;
            switch (status) {
                case "A":
                    info = "in Vorbereitung";
                    break;
                case "FA":
                    info = "Beendet->Vorbereitung";
                    break;
                case "S":
                    info = "gestartet";
                    break;
                case "C":
                    info = "beendet";
                    break;
                case "X":
                    info = "abgesagt";
                    break;
            }
        } else if ("regStatus" == column) {
            info = "RegStatus: " + status;
            switch (status) {
                case "A":
                    info = "Anmeldung in Vorbereitung";
                    break;
                case "R":
                    info = "Anmeldung geöffnet";
                    break;
                case "F":
                    info = "Anmeldung voll";
                    break;
                case "C":
                    info = "Anmeldung geschlossen";
                    break;
                case "S":
                    info = "Cup Master aktiviert";
                    break;
            }
        }
        msgActions.slackNotify(tournament.getName() + ": " + info, "de/tournament/" + tournament.id, null);
        return {};
    }

    unlinkBusinessAccount(tournament, management, user) {
        tournament.unsetManagementID();
        this.updateTournamentPayStatus(tournament, user, TournamentPrice.prototype.payStatus.inactive);
        this.saveTournament(tournament, null, null);
        changeLogActions.unlinkBusinessAccount(tournament, management, user);
        return {};
    }

    link2BusinessAccount(tournament, management, user) {
        tournament.setManagementID(management);
        const addEditor = [];
        const mainEditorID = management.getMainEditor().id;
        if (tournament.getMainEditor() != null) {
            if (tournament.getMainEditor().id != mainEditorID) {
                // other mainEditor - push to addEditor list
                addEditor.push(tournament.getMainEditor());
                tournament.setMainEditor(management.getMainEditor());
            }
        } else {
            tournament.setMainEditor(management.getMainEditor());
        }
        // existing unique
        if (tournament.getAddEditor() != null) {
            tournament.getAddEditor().map(function(editor) {
                if (editor.id != mainEditorID) {
                    ParseListUtility.updateOrPush(addEditor, editor);
                }
            });
        }
        if (management.getAddEditor() != null) {
            management.getAddEditor().map(function(editor) {
                if (editor.id != mainEditorID) {
                    ParseListUtility.updateOrPush(addEditor, editor);
                }
            });
        }
        tournament.setAddEditor(addEditor);
        if (management.hasPaymentFeature()) {
            // enable payment
            this.updateTournamentPayStatus(tournament, user, TournamentPrice.prototype.payStatus.active)
        } else {
            // just save changes
            this.saveTournament(tournament, null, null);
        }
        changeLogActions.addLinkTournament2BusinessCL(tournament, management, user);
        return {};
    }
    queryTournamentChangeLogs(tournamentID) {
        changeLogDao.queryTournamentChangeLogs(tournamentID)
            .then(changeLogs => {
                this.updateTournamentChangeLogs(changeLogs);
            })
            .catch(error => {
                error.trace = "TOUA.cl";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateTournamentChangeLogs(changeLogs) {
        return changeLogs;
    }
    updateTournamentDetail(tournament) {
        return tournament;
    };
    updateTournamentRound(tRound) {
        return tRound;
    };
    updateTournamentRounds(tRounds) {
        return tRounds;
    };
    updateTournamentSlot(tSlot) {
        return tSlot;
    };
    updateTournamentSlots(tSlots) {
        return tSlots;
    }
    updateCupChildTournaments(childTournaments) {
        return childTournaments;
    };
    setInvalidUser() {
        console.log("INVALID USER ACTION");
        return{};
    };
}

module.exports = alt.createActions(TournamentManagerActions);
