const React = require("react"),
    PropTypes = require('prop-types');
const FontAwesome = require("components/widgets/FontAwesome");

const SortTableText = ({children, sortKey, sortParams, handleSort}) => {
    function onSortClicked(event) {
        event.preventDefault();
        handleSort(sortKey, sortParams.sortKey === sortKey && sortParams.sortDir === "asc" ? "desc" : "asc")
    }
    return <a href="#" onClick={onSortClicked} style={{color: "black"}}>
        {children}&nbsp;
        {sortParams != null && sortParams.sortKey === sortKey && sortParams.sortDir === "asc" ? <FontAwesome icon="caret-down"/> : null}
        {sortParams != null && sortParams.sortKey === sortKey && sortParams.sortDir === "desc" ? <FontAwesome icon="caret-up"/> : null}
    </a>
}
SortTableText.propTypes = {
    sortKey: PropTypes.string.isRequired,
    handleSort: PropTypes.func.isRequired,
    sortDirection: PropTypes.string,
};
module.exports = SortTableText;
