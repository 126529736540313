var messages = require("i18n/messages");
var ParseError = {
    getTranslatedErrorMessage: function(error) {
    	var text = messages.get("parse.error." + error.code);
    	if (!text) {
    		text = error.message; 
    	}
    	return "CODE("  +  error.code + "): " + text;
    },
    isUserNameTaken: function(error) {
    	return 202 == error.code;
    }, 
    isEMailTaken: function(error) {
    	return 203 == error.code;
    }, 
    isEMailValid: function(error) {
    	return 125 == error.code;
    },
    isEMailValidForReset: function(error) {
    	return 205 == error.code;
    },
    isMissingUserAccessRights: function(error) {
    	return 119 == error.code;
    }
 };
module.exports = ParseError;
