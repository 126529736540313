const alt = require("AltInit"),
    Parse = require("ParseInit");

const {Event, EventPlayer, TournamentUser, TournamentUserTeam, TournamentRound, TournamentRoundUser} = require("parse/_Domain");

class TournamentResultDao {

    loadTournamentUserResult(tournament, tournamentConfigAge, tournamentConfigBow, params) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        if (tournamentConfigBow) {
            query.equalTo(TournamentUser.prototype.col.tournamentConfigBow, tournamentConfigBow);
        }
        if (tournamentConfigAge) {
            query.equalTo(TournamentUser.prototype.col.tournamentConfigAge, tournamentConfigAge);
        }
        if (params && params.optionString) {
            if (params.optionString.indexOf("(excl)") === 0) {
                query.notEqualTo(TournamentUser.prototype.col.option, params.optionString.substring(6));
            } else {
                query.equalTo(TournamentUser.prototype.col.option, params.optionString);
            }

        }
        query.include(TournamentUser.prototype.col.tournamentConfigBow);
        query.include(TournamentUser.prototype.col.tournamentRoundUserIDs);
        query.include(TournamentUser.prototype.col.finalTournamentRoundUserIDs);
        query.include(TournamentUser.prototype.col.tournamentConfigBow + ".countTypeID");
        query.include(TournamentUser.prototype.col.tournamentConfigBow + ".countTypeID.countTypeDtls");
        query.descending(TournamentUser.prototype.col.finalSumPoints + "," + TournamentUser.prototype.col.finalKillValue + "," + TournamentUser.prototype.col.sumPoints + "," + TournamentUser.prototype.col.killValue);
        query.limit(500);
        return query.find();
    };

    loadTournamentRoundResult(tournamentRound) {
        const query = new Parse.Query(TournamentRoundUser);
        query.equalTo(TournamentRoundUser.prototype.col.tournamentRoundID, tournamentRound);
        query.include(TournamentRoundUser.prototype.col.tournamentUserID)
        return query.find();

    }

    loadTournamentEventPlayer(tournamentRound, tournamentUser) {
        const evQuery = new Parse.Query(Event)
        evQuery.equalTo(Event.prototype.col.tournamentRoundID, tournamentRound);
        evQuery.notEqualTo(Event.prototype.col.status, Event.prototype.status.deleted);

        const epQuery = new Parse.Query(EventPlayer);
        epQuery.equalTo(EventPlayer.prototype.col.tournamentUserID, tournamentUser);
        epQuery.exists(EventPlayer.prototype.col.eventID);
        epQuery.include(EventPlayer.prototype.col.eventResults);
        epQuery.descending(EventPlayer.prototype.col.createdAt);
        epQuery.matchesQuery(EventPlayer.prototype.col.eventID, evQuery);
        return epQuery.find();
    }

    loadTournamenUserTeam(tournament) {
        const query = new Parse.Query(TournamentUserTeam);
        query.notEqualTo(TournamentUserTeam.prototype.col.status, TournamentUserTeam.prototype.status.deleted);
        query.equalTo(TournamentUserTeam.prototype.col.tournamentID, tournament);
        query.include(TournamentUserTeam.prototype.col.tournamentConfigBow);
        query.include(TournamentUserTeam.prototype.col.tournamentConfigAge);
        query.include(TournamentUserTeam.prototype.col.tournamentUserIDs);
        query.limit(500);
        return query.find();
    }

    loadTournamentRounds(tournament) {
        const query = new Parse.Query(TournamentRound);
        query.notContainedIn(TournamentRound.prototype.col.status,[TournamentRound.prototype.status.deleted, TournamentRound.prototype.status.inactive]);
        query.equalTo(TournamentRound.prototype.col.tournamentID, tournament);
        query.ascending(TournamentRound.prototype.col.sortIndex);
        return query.find();
    };

    deleteContender(contenderTUser, tournament) {
        console.log("del cont actdao");
        const tuMail = contenderTUser.getChallengeContenderMail();
        const query = new Parse.Query(TournamentUser);
        query.equalTo(TournamentUser.prototype.col.playerEmail, tuMail);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        return query.find();

    };
    deleteCompetitor(competitorTUser, tournament) {
        console.log("del comp actdao");
        const tuMail = competitorTUser.getChallengeCompetitorMail();
        const query = new Parse.Query(TournamentUser);
        query.equalTo(TournamentUser.prototype.col.playerEmail, tuMail);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        return query.find();
    };
}

module.exports = alt.createActions(TournamentResultDao);
