const React = require("react"),
    PropTypes = require('prop-types');
const {Col, Image} = require("react-bootstrap");
const ExtLink = require("components/links/ExtLink");

const ImageBanner = ({imageObject, height}) => {
    const lUrl = imageObject != null && imageObject.getImageUrl();
    if (lUrl && lUrl.length > 0) {
        const imgStyle = height > 0 ? {"height": height} : {};
        if (imageObject.getWeblink() != null) {
            return <Col xs={12}>
                <ExtLink href={imageObject.getWeblink()}>
                    <Image style={imgStyle} responsive className="center-block" src={lUrl}/><br/>
                </ExtLink>
            </Col>
        }
        return (
            <Col xs={12}>
                <Image style={imgStyle} responsive className="center-block" src={lUrl}/><br/>
            </Col>
        )
    }
    return (<div></div>)

};
ImageBanner.propTypes = {
    imageObject: PropTypes.object,
    height: PropTypes.number
};
module.exports = ImageBanner;
