/**
 * Created by Daniel on 25.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert, ListGroup, ListGroupItem, Label, PageHeader} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    FontAwesome = require("components/widgets/FontAwesome"),
    SelectInputAsyncTypeAhead = require("components/form/SelectInputAsyncTypeAhead"),
    DelayedTextField = require("components/form/DelayedTextField"),
    SubmitButton = require("components/form/SubmitButton"),
    DeleteDecisionModal = require("components/modals/DeleteDecisionModal"),
    TextBlur = require("components/widgets/TextBlur"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility");

const tournamentLiveStore = require("stores/TournamentLiveStore"),
    tournamentLiveActions= require("actions/TournamentLiveActions"),
    userStore = require("stores/UserStore");
const notificationActions = require("components/notification/NotificationActions");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const GROUP_CHARS = require("components/tournamentmanage/TournamentUtility").GROUP_CHARS()

function getGroupFromArray(index, tournamentUserGroups) {
    if (index < tournamentUserGroups.length) {
        return tournamentUserGroups[index];
    }
    return null;
}

class TournamentLiveGroupPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleTournamentChange = this.handleTournamentChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.state = {
            tournamentLiveStore: tournamentLiveStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        tournamentLiveStore.listen(this.handleTournamentChange);
        userStore.listen(this.handleUserChange);
        notificationActions.reset();
        tournamentLiveActions.showLiveGroups.defer(this.props.tournamentId, this.state.userStore.user);
    }
    componentWillUnmount() {
        tournamentLiveStore.unlisten(this.handleTournamentChange);
        userStore.unlisten(this.handleUserChange);
    }
    handleTournamentChange(state) {
        this.setState({tournamentLiveStore: state});
        if (state.tournamentDetail) {
            document.title = "LIVE " + state.tournamentDetail.getName();
        }
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleSearch(searchText) {
        let searchParams = this.state.tournamentLiveStore.searchParams;
        searchParams.text = searchText;
        tournamentLiveActions.searchTournamentUserInGroups(searchParams);
    }
    render() {
        const {userStore, tournamentLiveStore} = this.state
        if (tournamentLiveStore.tournamentDetail == null || tournamentLiveStore.tournamentGroups == null) {
            return (<Grid>
                <Row>
                    <Col lg={12}><Loading/></Col>
                </Row>
            </Grid>)
        }

        let isEditor = tournamentLiveStore.tournamentDetail.isEditor(userStore.user)
        let showGroups = isEditor ? true : tournamentLiveStore.tournamentDetail.showTUGroupButton();
        return (<TournamentLiveDetails
            handleSearch={this.handleSearch}
            user={userStore.user}
            hideTUser={!showGroups}
            allowGroupCreation={tournamentLiveStore.tournamentDetail.getNoTuserGroups()}
            ownTournamentGroup={tournamentLiveStore.ownTournamentGroup}
            // only filled if tournament allows to maintain your own groups
            ownTournamentUser={tournamentLiveStore.ownTournamentUser}
            tournament={tournamentLiveStore.tournamentDetail}
            tournamentRounds={tournamentLiveStore.tournamentRounds}
            tournamentGroups={tournamentLiveStore.tournamentGroups}
        />)

    }
}
const TournamentLiveDetails = ({tournament, allowGroupCreation, ownTournamentGroup, ownTournamentUser, hideTUser,
                                   tournamentRounds, tournamentGroups, user, handleSearch}) => {
    const createGroupSuggestion = function() {
        // find tUser for User and create group with it
        tournamentLiveActions.createGroupSuggestion(tournament, user, null, tournamentGroups)
    }
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/tournament" code="header.nav.tournaments"/>
                        <Breadcrumb link={"/tournament/" + tournament.id}
                                    code={tournament.getName()}/>
                        <Breadcrumb link="#" code="tournament.live.groups" active/>
                    </Breadcrumbs>
                </Col>
                <Col lg={12}>
                    <PageHeader>{tournament.getName()}</PageHeader>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <DelayedTextField placeholder={messages.get("tournamentUser.button.search")}
                                      disabled={tournamentGroups == null || hideTUser}
                                      onChange={handleSearch}/>
                </Col>
                {
                    ownTournamentGroup == null && allowGroupCreation && user != null && user.isRealUser() ? <Col xs={12} sm={6}>
                        <SubmitButton onClick={createGroupSuggestion} bsStyle="primary"
                                      icon={<FontAwesome icon="plus-circle"/>}
                                      text={<I18n code="tournament.live.createGroup"/> } />
                    </Col> : null
                }

            </Row>
            <br/><br/>
            <TournamentGroupPanelBody tournament={tournament} user={user} hideTUser={hideTUser}
                                      tournamentUserGroups={tournamentGroups}
                                      ownTournamentGroup={ownTournamentGroup}
                                      ownTournamentUser={ownTournamentUser} />
        </Grid>)
};
TournamentLiveDetails.propTypes = {
    tournament:PropTypes.object.isRequired,
    allowGroupCreation: PropTypes.bool.isRequired,
    user:PropTypes.object,
    ownTournamentGroup: PropTypes.object,
    ownTournamentUser: PropTypes.object,
    tournamentGroups:PropTypes.array
};
const TournamentGroupPanelBody = ({tournament, user, tournamentUserGroups, ownTournamentGroup, ownTournamentUser, hideTUser}) => {
    if (tournamentUserGroups == null || tournamentUserGroups.length <= 0) {
        return (
            <Row>
                <Alert>{messages.get("list.entry.empty")}</Alert>
            </Row>
        );
    }
    const modCol = 3;
    let rowArray = Utility.buildModArray(tournamentUserGroups.length, modCol);
    return <React.Fragment>
        {
            rowArray.map(index => {
                return <TournamentGroupPanelGrpRow key={"TGPB_" + index} index={index * modCol} user={user} hideTUser={hideTUser}
                                                   tournamentUserGroups={tournamentUserGroups} tournament={tournament}
                                                   ownTournamentUser={ownTournamentUser} ownTournamentGroup={ownTournamentGroup}
                />
            })
        }
    </React.Fragment>
};
TournamentGroupPanelBody.propTypes = {
    tournamentUserGroups: PropTypes.array.isRequired,
    tournament:PropTypes.object.isRequired,
    user: PropTypes.object,
    ownTournamentGroup: PropTypes.object,
    ownTournamentUser: PropTypes.object,
};
const TournamentGroupPanelGrpRow = ({index, tournament, user, tournamentUserGroups, ownTournamentGroup, ownTournamentUser, hideTUser}) => {
    const tUserGroup1 = getGroupFromArray(index, tournamentUserGroups);
    const tUserGroup2 = getGroupFromArray(index + 1, tournamentUserGroups);
    const tUserGroup3 = getGroupFromArray(index + 2, tournamentUserGroups);
    return <Row >
        {
            tUserGroup1 ?
                <Col key={tUserGroup1.id}  xs={12} md={4}>
                    <TournamentLiveGroup tournament={tournament} group={tUserGroup1}
                                         isOwnTUGroup={ownTournamentGroup != null && ownTournamentGroup.id === tUserGroup1.id
                                         && tUserGroup1.isCreator(user)} hideTUser={hideTUser}
                                         ownTournamentUser={ownTournamentUser} />
                </Col> : null
        }
        {
            tUserGroup2 ?
                <Col key={tUserGroup2.id}  xs={12} md={4}>
                    <TournamentLiveGroup tournament={tournament} group={tUserGroup2}
                                         isOwnTUGroup={ownTournamentGroup != null && ownTournamentGroup.id === tUserGroup2.id
                                         && tUserGroup2.isCreator(user)} hideTUser={hideTUser}
                                         ownTournamentUser={ownTournamentUser} />
                </Col> : null
        }
        {
            tUserGroup3 ?
                <Col key={tUserGroup3.id}  xs={12} md={4}>
                    <TournamentLiveGroup tournament={tournament} group={tUserGroup3}
                                         isOwnTUGroup={ownTournamentGroup != null && ownTournamentGroup.id === tUserGroup3.id
                                         && tUserGroup3.isCreator(user)} hideTUser={hideTUser}
                                         ownTournamentUser={ownTournamentUser} />
                </Col> : null
        }
    </Row>
};
TournamentGroupPanelGrpRow.propTypes = {
    tournamentUserGroups: PropTypes.array.isRequired,
    tournament:PropTypes.object.isRequired,
    user: PropTypes.object,
    ownTournamentGroup: PropTypes.object,
    ownTournamentUser: PropTypes.object
};


const TournamentLiveGroup = ({tournament, group, isOwnTUGroup, ownTournamentUser, hideTUser}) => {
    // isOwnTUGroup - only if user is creator and also in part of the group
    function queryTournamentUser(searchTxt, callback) {
        // this.setError(null);
        tournamentLiveActions.queryTournamentUserForGroups(searchTxt, tournament, callback, error => {
            notificationActions.parseError(error);
            // this.setError("Fehler in Abfrage")
        });
    }
    function renderTUserOptionItems(tUser) {
        return tUser.getDatalistTextNoMail();
    }
    function selectedTournamentUser(tournamentUser) {
        tournamentLiveActions.addTUserToGroup(tournamentUser, group)
    }
    const allowRemove = group.isDraft() && group.isPresent()
    const fixedTUsersInGroup = group.getFixedTournamentUserIDs() != null
    return <ListGroup>
        <ListGroupItem bsStyle={group.getStatusStyle()}>
            <p>
            {
                group.isDraft() ? <span>{group.getName()}</span> :
                    <span>{group.getName() + " - " + messages.get("Frag_Prep_startposition") + ": " + group.getStartPosition()}</span>
            }
            {
                allowRemove ? <span className="pull-right"><Label>
                        <FontAwesome icon="pencil-alt"><I18n code="tournament.live.draft"/></FontAwesome>
                    </Label></span> : null
            }
            </p>
        </ListGroupItem>
        {
            tournament.isSelectableTRoundsForGroup() && group.getTournamentRoundID() ? <ListGroupItem>
                <p>{group.getTournamentRoundID().getName()}</p>
            </ListGroupItem> : null
        }
        {
            allowRemove && isOwnTUGroup ? <ListGroupItem>
                <SelectInputAsyncTypeAhead
                    label={<I18n code="tournament.live.addTuser" />}
                    disabled={group.getTUserAmount() >= 6}
                    onSearch={queryTournamentUser}
                    renderOptionItems={renderTUserOptionItems}
                    handleSelectedObject={selectedTournamentUser}
                    placeholder={messages.get("tournament.mmtuser.search4name")}/>
            </ListGroupItem> : null
        }
        {
            fixedTUsersInGroup ? <React.Fragment>
                    {
                        Object.keys(group.getFixedTournamentUserIDs()).map(posKey => {
                            let tUser = group.getFixedTournamentUserIDs()[posKey]
                            if (tUser != null) {
                                const isOwnTUser = ownTournamentUser != null && ownTournamentUser.id === tUser.id
                                return <TournamentLiveGroupUser key={"TLGU_" + tUser.id} posKey={posKey} tUserGroup={group} tUser={tUser}
                                                                allowRemove={allowRemove && (isOwnTUser || isOwnTUGroup)}
                                                                isOwnTUser={isOwnTUser} hideTUser={hideTUser}/>
                            } else {
                                return <ListGroupItemEmpty key={"LGI_" + group.id + posKey} posKey={posKey}/>
                            }
                        })
                    }
                </React.Fragment>
                :
                <React.Fragment>
                    {
                        group.getTournamentUserIDs().map((tUser, index) => {
                            const isOwnTUser = ownTournamentUser != null && ownTournamentUser.id === tUser.id
                            return <TournamentLiveGroupUser key={"TLGU_" + tUser.id} posKey={GROUP_CHARS[index]} tUserGroup={group} tUser={tUser}
                                                            allowRemove={allowRemove && (isOwnTUser || isOwnTUGroup)}
                                                            isOwnTUser={isOwnTUser} hideTUser={hideTUser} />
                        })
                    }
                </React.Fragment>
        }
    </ListGroup>
};
TournamentLiveGroup.propTypes = {
    tournament:PropTypes.object.isRequired,
    group:PropTypes.object.isRequired,
    isOwnTUGroup:PropTypes.bool.isRequired,
    ownTournamentUser: PropTypes.object
};
const TournamentLiveGroupUser = ({posKey, tUserGroup, allowRemove, tUser, isOwnTUser, hideTUser}) => {
    const removeFromGroup = (del) => {
        if (del) {
            tournamentLiveActions.removeTUserFromTUserGroup(tUserGroup, tUser, isOwnTUser);
        }
    };
    return <ListGroupItem><Row>
        <Col xs={2}><strong>{posKey}</strong></Col>
        <Col xs={8} style={{paddingLeft: "0px"}}>
            {
                isOwnTUser ? <span><strong>{tUser.getFullName()}</strong>{", " + tUser.getTournamentConfigBowCode()}</span> :
                    <span><TextBlur blur={hideTUser} text={tUser.getFullName() + ", " + tUser.getTournamentConfigBowCode()}/></span>
            }
            <br/>
            <TextBlur blur={hideTUser} text={tUser.getUnion()}/>
        </Col>
        <Col xs={2}>
            {
                allowRemove ? <DeleteDecisionModal titleCode="modal.button.remove" delButtonCode="modal.button.remove"
                                                   messageCode="tournament.button.menu.mmtu"
                                                   tooltip={tUser.getFullName() + messages.get("tournament.mmtuser.tooltip.group.delUser")}
                                                   entryName={tUser.getFullName() + messages.get("tournament.mmtuser.tooltip.group.delUser")}
                                                   handleDelete={removeFromGroup}/> :  null
            }
        </Col>
    </Row></ListGroupItem>
}
const ListGroupItemEmpty = ({posKey}) => {
    return <ListGroupItem><Row>
        <Col xs={2}><strong>{posKey}</strong></Col>
        <Col xs={8} style={{paddingLeft: "0px"}}>-x-<br/>-x-</Col>
    </Row></ListGroupItem>
}
module.exports = TournamentLiveGroupPage;