const Parse = require("ParseInit");

const { CCLog} = require("parse/_Domain");

class MessageDao {


    sendMail(subject, sender, receiver, bcc, htmlText, needLog) {
        return Parse.Cloud.run('sendMail', {sender: sender, receiver: receiver, bcc: bcc, subject: subject, htmlText: htmlText, needLog: needLog});
    }


    pushMessageToClub(clubID, mailsArray, title, message, link) {
         return Parse.Cloud.run('pushMsg', {clubID: clubID, mailList: mailsArray, installationIds: null, onlineID: null, title: title, msg: message, link: link});
    };

    pushMessageToFavoriteParcours(onlineID, title, message, link) {
        return Parse.Cloud.run('pushMsg', {clubID: null, mailList: null, installationIds: null, onlineID: onlineID, title: title, msg: message, link: link});
    };

    pushMessageToInstallations(installationIds, title, message, link) {
        return Parse.Cloud.run('pushMsg', {clubID: null, mailList: null, installationIds: installationIds, onlineID: null, title: title, msg: message, link: link});
    };

    slackNotify(message, link) {
        return Parse.Cloud.run('slackNotify', {message: message, link: link});
    };

    slackCustomerNotify(message, link) {
        return Parse.Cloud.run('slackCustomerNotify', {message: message, link: link});
    };

    loadMailCClogs(sender) {
        const query = new Parse.Query(CCLog);
        query.equalTo(CCLog.prototype.col.text, sender);
        query.equalTo(CCLog.prototype.col.trace, "MAIL");
        query.descending(CCLog.prototype.col.createdAt);
        return query.find();
    }

}

module.exports = new MessageDao();
