const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, ControlLabel, Button, Label} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");

class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.onButtonDecr = this.onButtonDecr.bind(this);
        this.onButtonIncr = this.onButtonIncr.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.state = {
            fallback: false,
            minAmount: this.props.minAmout ? this.props.minAmout : 0,
            value: this.props.default,
            style: null,
            help: ""
        };
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ fallback: true });
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
    onButtonDecr() {
        let eVal = this.state.value;
        if (eVal > this.state.minAmount) {
            eVal--;
            this.setState({value: eVal});
            if (this.props.onAfterChange != null) {
                this.props.onAfterChange(eVal);
            }
        }
    }
    onButtonIncr() {
        let eVal = this.state.value;
        if (eVal < this.props.maxAmount) {
            eVal++;
            this.setState({value: eVal});
            if (this.props.onAfterChange != null) {
                this.props.onAfterChange(eVal);
            }
        }
    }
    render() {
        const {value} = this.state;
        let addonBefore = null;
        if (this.props.addonBefore != null) {
            addonBefore = <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
        }
        return (
            <FormGroup>
                {
                    this.props.label != null ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }
                <InputGroup>
                    {addonBefore}
                    <div style={{"display":"inline-block", "fontSize": "25px"}}>
                        <Button bsStyle="primary" onClick={this.onButtonDecr}>
                            <FontAwesome icon="minus"/>
                        </Button>
                        <Label bsStyle="default">{value}</Label>
                        <Button bsStyle="primary"onClick={this.onButtonIncr}>
                            <FontAwesome icon="plus"/>
                        </Button>
                    </div>

                </InputGroup>
            </FormGroup>);
    }
}
NumberInput.propTypes = {
    default:PropTypes.number,
    label:PropTypes.string,
    minAmout: PropTypes.number,
    maxAmount: PropTypes.number,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object
};
module.exports = NumberInput;
