const alt = require("AltInit");
const anaDao = require("actdao/AnalyticsDao"),
    {ParcoursStat, ParcoursVisit} = require("parse/_Domain");

const parseDao = require("actdao/ParseBaseDao");
const parcoursDao = require("actdao/ParcoursDao");
const notificationActions = require("components/notification/NotificationActions");
const ParcoursStatDic = require("util/ParcoursStatDic");
const FileDownloadUtility =  require("util/FileDownloadUtility");
const PointInterestYearStat = require("util/PointInterestYearStat");
const messages = require("i18n/messages");

class AnalyticsActions {

    trackPointInterest(interestID) {
        const stat = new ParcoursStat();
        stat.setPOIActionClick(interestID);
        parseDao.saveObject(stat);
        return {};
    }

    trackParcoursClick(parcoursOnlineID, edit) {
        const stat = new ParcoursStat();
        stat.setParcoursActionClick(parcoursOnlineID, edit);
        parseDao.saveObject(stat);
        return {};
    }

    trackParcoursPayment(parcoursOnlineID) {
        const stat = new ParcoursStat();
        stat.setParcoursActionPayment(parcoursOnlineID);
        parseDao.saveObject(stat);
        return {};
    }

    trackParcoursSearch(searchText) {
        anaDao.gaEvent('parcours', 'search', searchText);
        return {};
    }

    trackTournamentSearch(searchText) {
        anaDao.gaEvent('tournament', 'search', searchText);
        return {};
    }

    queryPointInterest(interestID) {
        anaDao.queryPointInterest(interestID, null, parcoursStats => {
            const yearStat = new PointInterestYearStat();
            const result = {};
            parcoursStats.map(eventStat => {
                yearStat.addVisit(eventStat)
            });
            let resString = yearStat.getYearStat()
            console.log(result);
            alert(interestID + " clickStat last year: \n" + resString);
            this.exportPointInterestVisit(yearStat)
        })
        return{};
    }

    exportPointInterestVisit(yearStat) {
        let csvData = [];
        let header = [];
        header.push("month");
        header.push("Android");
        header.push("iOS");
        header.push("Web");
        csvData.push(header.join(';'));

        yearStat.getMonths().map(month => {
            let deviceDict = yearStat.getMonthStats(month);
            if (deviceDict != null) {
                let row = [];
                row.push(messages.get("montPager.month." + (month + 1))); // "montPager.month.1"
                row.push(deviceDict.ANDR);
                row.push(deviceDict.iOS);
                row.push(deviceDict.WEB);
                csvData.push(row.join(';'));
            }
        });
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "point_interest_export.csv" );
        return {};
    }

    checkParcoursEditor(onlineID, user, callbackParcours) {
        parcoursDao.queryParcours(onlineID)
            .then(parcours => {
                if (parcours) {
                    // found parcours
                    const management = parcours.getParcoursOwnerID().getManagementID();
                    if (management != null) {
                        // is managed
                        if (management.hasParcoursFeature() && management.isEditor(user)) {
                            // has parcours feature and user is allowed
                            callbackParcours(parcours);
                            return;
                        }
                    }
                }
                this.setInvalidUser()
            })
            .catch(error => {
                error.trace = "PASA.qParcours";
                notificationActions.parseError(error);
            });
        return {};
    }

    startUpParcoursVisitList(user, searchParams, tstat) {
        this.updateLoading(true);
        if (user != null) {
            if (tstat === "admin") {
                parseDao.isUserAdmin()
                    .then(response => {
                        // admin logged in - query parcours
                        this.queryParcoursOwnerCheckUser(searchParams, null);
                    })
            } else {
                this.queryParcoursOwnerCheckUser(searchParams, user);
            }
        } else {
            this.setInvalidUser();
        }
        return {};
    }

    queryParcoursVisits(searchParams) {
        this.updateLoading(true);
        parcoursDao.queryParcoursVisit(searchParams)
            .then(parcoursVisits => {
                this.updateParcourVisits(parcoursVisits)
            })
            .catch(error => {
                error.trace = "PASA.qParVis";
                notificationActions.parseError(error);
            });
        return {};
    }

    deleteParcoursVisit(parcoursVisit) {
        const changeObject = {};
        changeObject[ParcoursVisit.prototype.col.status] = ParcoursVisit.prototype.status.deleted;
        parcoursDao.updateParcoursVisit(parcoursVisit.id, changeObject)
            .then(sParcoursVisit => {
                // return object
                this.removeParcoursVisit(parcoursVisit)
            })
            .catch(error => {
                error.trace = "PARA.savePaViStat";
                notificationActions.parseError(error)
            });
        return {};
    }

    exportParcoursVisit(parcoursVisits) {
        let csvData = [];
        let header = [];
        header.push("objectId");
        header.push(messages.get("address.contactName"));
        header.push(messages.get("address.mail"));
        header.push(messages.get("address.street"));
        header.push(messages.get("address.zip"));
        header.push(messages.get("address.place"));
        header.push(messages.get("address.phone"));
        header.push(messages.get("tournament.table.column.date"));
        header.push(messages.get("tournament.create.time"));
        header.push(messages.get("tournament.create.endtime"));
        header.push(messages.get("tournament.table.column.amount"));
        header.push(messages.get("tournament.table.column.note"));
        header.push("Parcours");
        header.push(messages.get("tournament.table.column.group"));
        csvData.push(header.join(';'));

        parcoursVisits.map(parcoursVisit => {
            let row = [];
            row.push(parcoursVisit.id);
            row.push(parcoursVisit.getFullName());
            row.push(parcoursVisit.getMail());
            row.push(parcoursVisit.getStreet());
            row.push(parcoursVisit.getZip());
            row.push(parcoursVisit.getPlace());
            row.push(parcoursVisit.getPhone());
            row.push(parcoursVisit.getVisitDate());
            row.push(parcoursVisit.getVisitStart());
            row.push(parcoursVisit.getVisitEnd());
            row.push(parcoursVisit.getVisitorAmount());
            row.push(parcoursVisit.getNote());
            row.push(parcoursVisit.getParcoursOnlineID());
            const addArcherNames = parcoursVisit.getAddVisitorNames();
            if (addArcherNames != null) {
                let names = "";
                Object.keys(addArcherNames).map(id => {
                    names += addArcherNames[id] + ", "
                });
                row.push(names);
            } else {
                row.push("");
            }
            csvData.push(row.join(';'));
        });
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "parcours_buch_export.csv" );
        return {};
    }

    queryParcoursOwnerCheckUser(searchParams, checkUser) {
        parcoursDao.getParcoursOwner(searchParams.ownerID)
            .then(parcoursOwner => {
                this.updateParcoursOwner(parcoursOwner);
                if (checkUser) {
                    const management = parcoursOwner.getManagementID();
                    if (management != null) {
                        // is managed
                        if (management.hasParcoursFeature() && management.isEditor(checkUser)) {
                            // has parcours feature and user is allowed
                            this.getParcoursForOwner(parcoursOwner);
                            this.queryParcoursVisits(searchParams);
                            return {};
                        }
                    }
                    this.setInvalidUser();
                } else {
                    this.getParcoursForOwner(parcoursOwner);
                    this.queryParcoursVisits(searchParams);
                }
            })
            .catch(error => {
                error.trace = "PASA.qParOwn";
                notificationActions.parseError(error);
            });
        return {};
    }

    getParcoursForOwner(parcoursOwner) {
        parcoursDao.getParcours(parcoursOwner)
            .then(parcours => {
                // take only the parcours with visit enabled
                this.updateParcoursList(parcours.filter(p => {
                    return p.hasVisitEnabled();
                }));
            })
            .catch(error => {
                error.trace = "PASA.loadParLi";
                notificationActions.parseError(error);
            });
        return {};
    }


    startUpParcoursStats(user, parcours, onlineID, year, tstat) {
        this.updateLoading(true);
        if (user != null) {
            if (parcours) {
                this.queryParcoursStats(parcours, year);
            } else {
                if (tstat == "admin") {
                    parseDao.isUserAdmin()
                        .then(response => {
                            // admin logged in - query parcours
                            parcoursDao.queryParcours(onlineID)
                                .then(parcours => {
                                    // parcours found yeah
                                    this.updateParcours(parcours);
                                    this.queryParcoursStats(parcours, year);
                                });
                        })
                } else {
                    // check if user is allowed before query stats
                    this.checkParcoursEditor(onlineID, user, (parcours) => {
                        // check success
                        this.updateParcours(parcours);
                        this.queryParcoursStats(parcours, year);
                    })
                }

            }
        } else {
            this.setInvalidUser();
        }
        return {};
    }

    queryParcoursStats(parcours, year) {
        anaDao.queryParcoursStats(parcours.getOnlineID(), year, parcoursStats => {
            const statDic = new ParcoursStatDic();
            parcoursStats.forEach(function (parcoursStat) {
                statDic.addStat(parcoursStat);
            });
            this.updateParcoursStatDic(statDic);
        })
        return {};
    }

    updateLoading(loading) {
        return loading;
    }

    updateParcours(parcours) {
        return parcours;
    }

    updateParcoursList(parcoursList) {
        return parcoursList;
    }

    removeParcoursVisit(visit) {
        return visit;
    }
    addParcoursVisit(visit) {
        return visit;
    }

    updateParcoursOwner(parcoursOwner) {
        return parcoursOwner;
    }

    updateParcoursStatDic(parcoursStatDic) {
        return parcoursStatDic;
    }

    updateParcourVisits(parcoursVisits) {
        return parcoursVisits;
    }

    updateParcourVisit(parcoursVisit) {
        return parcoursVisit;
    }

    setInvalidUser() {
        return {};
    }

}

module.exports = alt.createActions(AnalyticsActions);
