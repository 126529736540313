const alt = require("AltInit");
const actions = require("actions/ClubInvoiceActions");
const PLUtil = require("util/ParseListUtility");
const ClubInvoiceYearStat = require("util/ClubInvoiceYearStat");

class ClubInvoiceStore {
    constructor() {
        let today = new Date();
        this.displayName = "ClubInvoiceStore";
        this.loading = true;
        this.clubInvoices = [];
        this.allClubInvoices = null;
        this.clubInvoice = null;
        this.invoiceStat = null;
        this.year = today.getFullYear();
        this.searchParams = {
            text: ""
        };
        this.bindListeners({
            updateClubInvoices: actions.updateClubInvoices,
            updateClubInvoice: actions.updateClubInvoice,
            updateCheckoutInvoice: actions.updateCheckoutInvoice,
            reloadInvoices: actions.reloadInvoices,
            updateYear: actions.updateYear,
            removeClubInvoice: actions.removeClubInvoice,
            searchClubInvoices: actions.searchClubInvoices
        });
    }

    updateClubInvoices(invoices) {
        this.clubInvoices = invoices;
        this.allClubInvoices = invoices;
        let clubInvoiceStat = new ClubInvoiceYearStat();
        invoices.map(invoice => {
            clubInvoiceStat.addInvoice(invoice);
        });
        this.updateClubInvoiceStat(clubInvoiceStat);
        this.loading = false;
    }

    updateCheckoutInvoice(invoice) {
        this.clubInvoice= invoice;
    }

    updateClubInvoiceStat(pInvoiceStat) {
        this.invoiceStat = pInvoiceStat;
    }

    updateClubInvoice(invoice) {
        this.updateClubInvoices(PLUtil.updateOrPush(this.clubInvoices, invoice));
    }

    reloadInvoices() {
        this.loading = true;
        this.clubInvoices = [];
        this.allClubInvoices = null;
    }

    updateYear(year) {
        this.year = year;
    }

    removeClubInvoice(clubInvoice) {
        this.updateClubInvoices(
            this.clubInvoices.filter(function (u) {
                return u.id != clubInvoice.id;
            })
        );
    }

    searchClubInvoices(searchParam) {
        var foundInvoices;
        // has text to search for
        if (searchParam.searchText.length > 0) {
            var that = this;
            var searchString = searchParam.searchText.toLowerCase();
            foundInvoices = this.allClubInvoices.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            foundInvoices = this.allClubInvoices;
        }
        // group filter
        if (searchParam.searchType != null) {
            foundInvoices = foundInvoices.filter(function (cu) {
                return cu.isType(searchParam.searchType);
            });
        }
        this.clubInvoices = foundInvoices;

    }
}
module.exports = alt.createStore(ClubInvoiceStore);
