const React = require("react"),
    PropTypes = require('prop-types');
const CheckboxInput = require("components/form/CheckboxInput");

const CheckboxArray = ({cbEntryDic, onAfterChange}) => {
    return (
        <ul>
            {
                cbEntryDic ? Object.keys(cbEntryDic).map(elementKey => {
                    const element = cbEntryDic[elementKey];
                    return <CheckboxInput key={elementKey} default={element.selected} message={element.name} onAfterChange={(checked) => onAfterChange(checked, elementKey)}/>
                }) : null
            }
        </ul>

    )
};
CheckboxArray.propTypes = {
    cbEntryDic:PropTypes.object.isRequired,
    onAfterChange:PropTypes.func.isRequired
};
module.exports = CheckboxArray;
