const React = require("react"),
    PropTypes = require('prop-types');
const {Checkbox} = require("react-bootstrap");
const  FontAwesome = require("components/widgets/FontAwesome");

class CheckboxInput extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            value: this.props.default
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(e) {
        this.setValue(e.target.checked);
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(e.target.checked);

        }
    }
    render() {
        return (<Checkbox
            checked={this.state.value}
            disabled={this.props.disabled}
            onChange={this.handleChange}>
            {
                this.props.faIcon ? <FontAwesome icon={this.props.faIcon}/> : null
            }
            {this.props.message}{this.props.addonAfter}
        </Checkbox>)
    }
}
CheckboxInput.propTypes = {
    message:PropTypes.any.isRequired,
    default:PropTypes.bool,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonAfter:PropTypes.object,
    faIcon:PropTypes.string
};

module.exports = CheckboxInput;
