const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid} = require("react-bootstrap");
const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");


module.exports = () =>{
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/agb" code="header.nav.agb" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="text-center">
                    <h1>Allgemeine Geschäftsbedingungen</h1>
                    <h3>der Bowsport Software Solutions GmbH</h3>
                </Col>
                <Col lg={12}>
                    <h3>Präambel</h3>
                    <small>
                        Die Bowsport Software Solutions GmbH (im Folgenden kurz BSS genannt) bietet eine Plattform
                        zur Durchführung
                        bargeldloser Zahlungen über das Internet zwischen dem Kunden und Parcoursbetreiber an.

                        Die BSS benutzt existierende Zahlungsverfahren, wie z.B. die Kreditkartenzahlung, Paypal und
                        Sofortüberweisung.
                        BSS bietet dem Kunden ein Bezahlservice für Parcoursrunden, Jahresbeiträgen oder
                        Turniernenngelds über die Homepage
                        www.3dturnier.com bzw. auf mobilen Geräten mittels der jeweiligen 3D SKill Board-
                        Applikation an. Die Zahlungsabwicklung
                        erfolgt durch Finanzdienstleister, die zur Durchführung der entsprechenden Zahlungsverfahren
                        zugelassen sind.
                        Für einen Vertrag zwischen einem Kunden und der BSS gelten die nachstehend angeführten
                        „Allgemeinen Geschäftsbedingungen der BSS“, kurz AGB genannt.
                    </small>
                    <h3>1. Änderungsvorbehalt</h3>
                    <small>
                        Nachträgliche Änderungen oder Ergänzungen dieser AGB werden zum Vertragsbestandteil,
                        wenn der Kunde nicht innerhalb von 30 Tagen ab Zustellung der geänderten
                        Geschäftsbestimmungen
                        an die angegebene E-Mail-Adresse des Kunden widerspricht.
                    </small>
                    <h3>2. Vertragsabschluss</h3>
                    <small>
                        <ol>
                            <li>Der Vertragsabschluss zwischen dem Kunden und der BSS kommt mit der Zusendung der
                                Rechnung an die vom Kunden angegebene E-Mail- Adresse zustande.
                            </li>
                            <li>Der Kunde erklärt ausdrücklich, dass er gegenüber der BSS im eigenen Namen und auf
                                eigene Rechnung handelt. Er verpflichtet sich diesbezügliche Änderungen von sich aus
                                unverzüglich bekannt zu geben.
                            </li>
                            <li>Die Nutzung des Bezahlservice kann durch die BSS ohne Angabe von Gründen abgelehnt
                                werden
                            </li>
                            <li>Der Abschluss dieses Vertrages wie auch jede weitere Kommunikation zwischen dem
                                Kunden und der BSS während der Dauer des Vertragsverhältnisses erfolgen in deutscher
                                Sprache.
                                Während der Dauer des Vertragsverhältnisses ist die Kommunikation zwischen dem
                                Kunden und der BSS via E-Mail oder Brief and die unter
                                https://www.3dturnier.com/de/impressum angegebenen Kontaktinformationen zu führen.
                            </li>
                        </ol>
                    </small>
                    <h3>3. Nutzung des Bezahlservice</h3>
                    <small>
                        Für die Nutzung muss der Kunde auf der Hompage bzw der 3D SKill Board Applikation ein
                        Kundenkonto mit verifierter E-Mail Adresse verfügen.
                        Oder während des Bezahlsvorgangs eine gültige E-Mail Adresse angeben.
                        <ol>
                            <li>Zahlungen
                                <p>Im Rahmen von BSS kann der Kunde bei daran teilnehmenden Parcoursbetreibern
                                    bargeldlos Parcoursrunden, Jahresbeiträge oder Turniernenngeld bezahlen.
                                    Mit der Bestätigung der Zahlung autorisiert der Kunde den jeweiligen
                                    Zahlungsvorgang und weist BSS unwiderruflich an den Rechnungsbetrag an den
                                    jeweiligen Parcoursbetreiber zu bezahlen.
                                    Die Bestätigung einer BSS-Zahlung durch den Kunden erfolgt durch den Klick auf
                                    den Button "Bezahlen" auf der www.3dturnier.com Seite oder der 3D SKill Board
                                    Applikation und ist dann unwiderruflich.
                                    Nach Autorisierung gilt der Zahlungsauftrag bei der BSS als eingegangen.
                                    Der Kunde muss den Transaktionsbetrag erstatten und hat für ausreichende Deckung
                                    auf seinem Bankkonto zu sorgen.
                                </p></li>
                            <li> Die Zustimmung des Kunden zur Weitergabe oben genannter Daten gilt auch für die
                                Weitergabe an den Zahlungsempfänger einer Überweisung
                            </li>
                        </ol>
                    </small>
                    <h3>4. Sorgfaltspflichten und Haftung des Kunden</h3>
                    <small>
                        <ol>
                            <li>Der Kunde hat den Bezahlservice in Entsprechung dieser AGB zu nutzen und jede
                                missbräuchliche Verwendung oder Beteiligung an einem Missbrauch durch Dritte zu
                                unterlassen.
                            </li>
                            <li>Der Kunde hat alle zumutbaren Vorkehrungen zu treffen, um die personalisierten
                                Sicherheitsmerkmale vor unbefugtem Zugriff zu schützen.
                            </li>
                            <li>Verwendet der Kunde den Bezahlservice missbräuchlich oder ist er an der
                                missbräuchlichen Verwendung vom Bezahlsservice durch einen Dritten beteiligt, so
                                haftet der Kunde unbeschränkt.
                            </li>
                            <li>Bei missbräuchlicher Benutzung des Bezahlservice durch Dritte entfällt die Haftung
                                des Kunden für Schäden, die nach dem nachweislichen Eingehen der Anzeige über den
                                Missbrauch an die BSS entstehen.
                                Gemäß § 35 Abs. 3 ZaDiG stellt die BSS die Beweismittel über das Eingehen der
                                Missbrauchsanzeige bis zu 18 Monate nach deren Eingehen dem Kunden zur Verfügung. Im
                                Übrigen gelten die Haftungsbestimmungen des § 44 ZaDiG.
                            </li>
                            <li>Meldungen der Kunden an die BSS sind, unter Verwendung der in Punkt 2. Absatz (4)
                                angeführten Kommunikationsmittel, vorzunehmen.
                            </li>
                            <li>Die Frist des Kunden zur Unterrichtung der BSS zur Erwirkung einer Berichtigung der
                                von ihm nicht autorisierten Transaktion endet spätestens 13 Monate nach dem Tag der
                                Belastung oder der Gutschrift.
                                Andere Ansprüche des Kunden bleiben unberührt.
                            </li>
                        </ol>
                    </small>
                    <h3>5. Sperre</h3>
                    <small>
                        <ol>
                            <li>Stellt der Kunde missbräuchliche Verfügungen über sein BSS-Konto fest, muss er die
                                BSS sofort verständigen und eine Sperre seines Kontos veranlassen.
                                Eine Sperre kann der Kunde jederzeit, unter den unter
                                https://www.3dturnier.com/de/impressum angegebenen Kontaktinformationen,
                                veranlassen.
                            </li>
                            <li>Die BSS ist berechtigt dem Kunden dem Zungang zum Bezahlservice jederzeit zu
                                entziehen, wenn die Meldung eines Parcoursbetreibers über eine missbräuchliche
                                Verwendung des Bezahlsystems eingeht.
                            </li>
                        </ol>
                    </small>
                    <h3>6. Einwendungen aus dem Grundgeschäft</h3>
                    <small>
                        Der Kunde klärt Reklamationen oder sonstige Meinungsverschiedenheiten aus dem
                        Rechtsverhältnis zwischen ihm und dem Parcoursbetreiber direkt mit dem Parcoursbetreiber.
                        Eine Haftung der BSS in diesem Zusammenhang ist ausgeschlossen.
                    </small>
                    <h3>7. Informationspflichten des Kunden bei Änderung seiner persönlichen Daten</h3>
                    <small>
                        Der Kunde ist verpflichtet, der BSS unverzüglich Änderungen seiner persönlichen,
                        vertragswesentlichen Daten wie Name, E-Mail Adresse mitzuteilen. Gibt der Kunde solche
                        Änderungen nicht bekannt und erreichen ihn deshalb bedeutsame Erklärungen der BSS nicht, so
                        gelten diese Erklärungen dennoch als zugegangen, wenn sie an die letzte der BSS bekannt
                        gegebenen E-Mail Adresse gesendet wurden.
                    </small>
                    <h3>8. Gebühren für Auslandstelefonate bzw. Roaming</h3>
                    <small>
                        Benutzt ein Kunde den Bezahlservice außerhalb seines Mobilfunknetzes und es entstehen ihm im
                        Zuge einer Zahlungsautorisierung durch einen Anruf der BSS Roamingkosten auf seiner
                        Telefonrechnung, so trägt der Kunde diese Kosten selbst.
                    </small>
                    <h3>9. Inaktive Konten</h3>
                    <small>
                        Wenn Ihr BSS-Konto nicht mehr aktiv ist, also innerhalb mindestens eines Jahres keine Gelder
                        mehr eingezahlt oder abgebucht wurden und sich noch Guthaben in Ihrem Kundenkonto befindet,
                        werden wir Sie darüber via Email an die bei uns hinterlegte Email-Adresse benachrichtigen
                        und Ihnen die Möglichkeit geben Ihr BSS-Konto weiterhin aktiv zu halten oder die Auszahlung
                        des Guthabens auf ein Bankkonto Ihrer Wahl zu veranlassen.
                        Sollten wir innerhalb von 2 Monaten nach dem Tag der Benachrichtigung keine Antwort von
                        Ihnen erhalten haben, werden wir Ihr Benutzerkonto automatisch schließen. Alle Guthaben
                        Ihres Kundenkontos werden vorbehaltlich des anwendbaren Rechts und in seinem zulässigen
                        Umfang an uns fließen.

                    </small>
                    <h3>10. Rückzahlungen und Rücknahme</h3>
                    <small>
                        <ol>
                            <li>Sie können Rückzahlungen an Karteninhaber, im Rahmen der nachfolgenden Regelungen
                                dieses Paragraphen, veranlassen.
                                Allerdings werden wir Ihnen nicht die Gebühren zurückerstatten die von uns bezüglich
                                der ursprünglichen Transaktion erhoben wurden.
                            </li>
                            <li>Rückerstattungen über Ihr BSS-Konto sind nur erlaubt wenn die volle
                                Transaktionssumme rückerstattet wird;
                                die Rückerstattung binnen 14 Tage seit dem Tag der Transaktion, auf den sich die
                                Rückerstattug bezieht, eingeleitet wurde;
                                und die Seitens des Karteninhabers für die Transaktion genutzte Karte noch
                                Gültigkeit besitzt.
                            </li>
                            <li>Wenn Sie eine Rückerstattung beauftragen, erhält das Finanzinstitut des Empfängers
                                (zum Beispiel das Kartenunternehmen)
                                den Rückzahlungsbetrag spätestens am Ende des Werktages, an dem wir den
                                Rückerstattungsauftrag erhalten haben.
                                “Werktage” definieren wir die Tage von Montag bis Freitag (inklusive) ausschließlich
                                gesetzlicher Feiertage in Österreich.
                                Rückerstattungsaufträge, die nicht an einem Werktag eingegangen sind, gelten als am
                                nächsten Werktag eingegangen.
                            </li>
                        </ol>
                    </small>
                </Col>
            </Row>
        </Grid>)
};
