const React = require("react"),
    PropTypes = require('prop-types');
const {Label} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    ExtLink = require("components/links/ExtLink"),
    FontAwesome = require("components/widgets/FontAwesome");

const SolutionHelpLink = ({articleID, folderID}) => {
    if (articleID) {
        return <h4><Label className="button-right" bsStyle="info"><FontAwesome icon="question-circle"/><ExtLink
            href={"https://3dturnier.freshdesk.com/support/solutions/articles/" + articleID}><I18n code={"label.help"}/></ExtLink></Label>
        </h4>
    } else if (folderID) {
        return <h4><Label className="button-right" bsStyle="info"><FontAwesome icon="question-circle"/><ExtLink
            href={"https://3dturnier.freshdesk.com/support/solutions/folders/" + folderID}><I18n code={"label.help"}/></ExtLink></Label>
        </h4>
    }
    return  null
};
SolutionHelpLink.propTypes = {
    articleID: PropTypes.string,
    folderID: PropTypes.string,
};
module.exports = SolutionHelpLink;