const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TextCenter = require("components/widgets/TextCenter"),
    ValidInput = require("components/form/ValidInput"),
    GroupPlaces = require("components/widgets/GroupPlaces");

const messages = require("i18n/messages");

const Club = require("parse/Club"),
    ClubUser = require("parse/ClubUser"),
    GroupPlace = require("parse/GroupPlace");

const clubActions = require("actions/ClubActions");

class ClubCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.refName = React.createRef();
        this.refUnionLink = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.valid = this.valid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.getDefaultUnion = this.getDefaultUnion.bind(this);
        this.getDefaultWebLink = this.getDefaultWebLink.bind(this);
        this.getDefaultZip = this.getDefaultZip.bind(this);
        this.getDefaultPlace = this.getDefaultPlace.bind(this);
        this.getDefaultMail = this.getDefaultMail.bind(this);

        let tMainGroupPlace= null;
        let tRegionGroupPlace= null;
        if (this.props.parcoursOwner) {
            tMainGroupPlace = this.props.parcoursOwner.getMainGroupPlaceID();
            tRegionGroupPlace = this.props.parcoursOwner.getGroupPlaceID();
        }
        this.state = {
            mainGroupPlace: tMainGroupPlace,
            regionGroupPlace: tRegionGroupPlace,
            showModal: false,
            submitDisabled: false,
            missingRegion: false
        };
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, error: null});
    }
    open() {
        this.setState({
            showModal: true,
            club: null
        });
    }
    edit(club) {
        this.setState({
            showModal: true,
            club: club,
            mainGroupPlace: club.getMainGroupPlaceID(),
            regionGroupPlace: club.getGroupPlaceID()
        });
    }
    valid() {
        let valid = true;
        if (this.state.mainGroupPlace == null || this.state.regionGroupPlace == null) {
            this.setState({missingRegion: true});
            valid = false;
        }
        let required = [
            this.refName.current.getValue(),
            this.refZip.current.getValue(),
            this.refPlace.current.getValue(),
            this.refMail.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    handleSubmit(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        let mainGroupPlace = null;
        if (typeof this.state.mainGroupPlace === 'string') {
            mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = this.state.mainGroupPlace;
        } else {
            mainGroupPlace = this.state.mainGroupPlace;
        }
        let regionGroupPlace = null;
        if (typeof this.state.regionGroupPlace === 'string') {
            regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = this.state.regionGroupPlace;
        } else {
            regionGroupPlace = this.state.regionGroupPlace;
        }
        let club = this.state.club;
        if (!club) {
            club =  Club.prototype.buildClub(this.refName.current.getValue(), this.refUnionLink.current.getValue(), this.refMail.current.getValue(), mainGroupPlace, regionGroupPlace, this.refZip.current.getValue(), this.refPlace.current.getValue(), this.props.user, this.props.parcoursOwner);
            let clubUser = ClubUser.prototype.buildClubOwner(this.props.user);
            clubActions.createClub(club, clubUser, this.props.parcoursOwner);
        } else {
            club.setName(this.refName.current.getValue());
            if (this.refUnionLink.current.getValue()) {
                club.setWeblink(this.refUnionLink.current.getValue());
            }
            if (club.getEmail() !== this.refMail.current.getValue()) {
                // email changed - reset mail verification
                club.setEmail(this.refMail.current.getValue());
                club.setEmailVerified(false)
            }
            club.setMainGroupPlaceID(mainGroupPlace);
            club.setGroupPlaceID(regionGroupPlace);
            club.setZip(this.refZip.current.getValue());
            club.setPlace(this.refPlace.current.getValue());
            clubActions.saveClubCB(club, () => {
                this.close();
            })
        }
    }
    handleGroupPlaceChange(mainGroupPlace, regionGroupPlace) {
        this.setState({
            mainGroupPlace: mainGroupPlace,
            regionGroupPlace: regionGroupPlace
        });
    }
    getDefaultUnion() {
        if (this.state.club != null) {
            return this.state.club.getName();
        }
        if (this.props.parcoursOwner) {
            return this.props.parcoursOwner.getName();
        }
        return null;
    }
    getDefaultWebLink() {
        if (this.state.club != null && this.state.club.getWeblink() != null) {
            return this.state.club.getWeblink();
        }
        if (this.props.parcoursOwner) {
            return this.props.parcoursOwner.getWeblink();
        }
        return "http://";
    }
    getDefaultZip() {
        if (this.state.club != null) {
            return this.state.club.getZip();
        }
        if (this.props.parcoursOwner) {
            return this.props.parcoursOwner.getZip();
        }
        return null;
    }
    getDefaultPlace() {
        if (this.state.club != null) {
            return this.state.club.getPlace();
        }
        if (this.props.parcoursOwner) {
            return this.props.parcoursOwner.getPlace();
        }
        return null;
    }
    getDefaultMail() {
        if (this.state.club != null) {
            return this.state.club.getEmail();
        }
        if (this.props.parcoursOwner != null ) {
            return this.props.parcoursOwner.getContactMail();
        }
        return null;
    }
    render() {
        let validateNow = this.state.error && this.state.error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter>
                                {
                                    this.state.club ?
                                        <I18n code="club.edit.title"/>
                                        :
                                        <I18n code="club.create.title"/>
                                }
                            </TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.error ?
                            <Alert bsStyle="warning">{this.state.error}</Alert>
                            : ""}
                        {
                            this.state.showModal ?
                                <Row>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 500, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultUnion()}
                                                    placeholder={messages.get("club.create.name")}/>
                                        <ValidInput ref={this.refUnionLink}
                                                    valid={{maxLength: 500, check: ['isUrl']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultWebLink()}
                                                    label={messages.get("tournament.create.union.link")}
                                                    addonBefore={<FontAwesome icon="globe"/>}/>
                                        <ValidInput ref={this.refMail}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    placeholder={messages.get("address.mail")}
                                                    default={this.getDefaultMail()}/>
                                        <GroupPlaces handleChange={this.handleGroupPlaceChange}
                                                     mainGroupPlace={this.state.mainGroupPlace}
                                                     regionGroupPlace={this.state.regionGroupPlace}
                                                     bsStyle={this.state.missingRegion?"warning":undefined}/>
                                        <br/>
                                    </Col>
                                    <Col sm={4}>
                                        <ValidInput ref={this.refZip}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultZip()}
                                                    placeholder={messages.get("tournament.create.zip")}/>
                                    </Col>
                                    <Col sm={8}>
                                        <ValidInput ref={this.refPlace}
                                                    valid={{maxLength: 200, check:['isRequired']}}
                                                    validateNow={validateNow}
                                                    default={this.getDefaultPlace()}
                                                    placeholder={messages.get("tournament.create.place")}/>
                                    </Col>
                                </Row> : null

                        }

                    </Modal.Body>
                    <Modal.Footer style={{"textAlign": "center"}}>
                        <Button type="submit" disabled={this.state.submitDisabled} bsStyle="primary">
                            {
                                this.state.club ?
                                    <FontAwesome icon="save">{messages.get("modal.button.save")}</FontAwesome>
                                    :
                                    <FontAwesome icon="plus">{messages.get("club.button.create")}</FontAwesome>
                            }
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}
ClubCreateModal.propTypes = {
    user:PropTypes.object.isRequired,
    parcoursOwner:PropTypes.object
};
module.exports = ClubCreateModal;
