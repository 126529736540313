const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Table, Label, PageHeader, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    MonthPager = require("components/widgets/MonthPager"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    DelayedTextField = require("components/form/DelayedTextField"),
    SearchInputField = require("components/form/SearchInputField"),
    TextInputModal = require("components/modals/TextInputModal"),
    SortTableText = require("components/form/SortTableText"),
    Utility = require("util/Utility"),
    AdditionalEditorModal = require("components/modals/AdditionalEditorModal");

const CalendarUtility = require("util/CalendarUtility");
const trainingActions = require("actions/TrainingActions");
const notificationActions = require("components/notification/NotificationActions");

class TrainingTeamDetail extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangedDate = this.handleChangedDate.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.state = {
            calendarBoundaries: CalendarUtility.calcCalendarBoundaries(new Date()),
        }
    }
    handleSearchTextChange(searchText) {
        const sParams = this.props.searchParams;
        sParams.text = searchText;
        trainingActions.updateSearchParams(sParams);
    };
    handleChangedDate(month, year, yearChanged) {
        this.setState({
            calendarBoundaries: CalendarUtility.calcCalendarBoundaries(new Date(year, month - 1, 1))
        });
        if (yearChanged) {
            this.props.trainingTeamMembers.forEach(member => {
                let searchParams = {
                    year: year
                };
                trainingActions.queryTrainingTeamResult(searchParams, member)
            })
        }
    }
    render() {
        const {trainingTeam, trainingTeamMembers, trainingTeamResult, searchParams, isTeamMember, user} = this.props;
        const {calendarBoundaries} = this.state;
        if (trainingTeam) {
            const isEditor = trainingTeam.isEditor(user);
            return <React.Fragment>
                <PageHeader>
                    {trainingTeam.getName()}
                </PageHeader>
                {
                    isEditor ? <AdditionalEditorModal objectType={"EventTrainingTeams"} object={trainingTeam}
                                                      titleCode={"profile.training.team.trainer"} allowEditMainEditor={false}
                                                      buttonChildren={<FontAwesome icon="users" >{messages.get("profile.training.button.trainer")}</FontAwesome>}/>
                        : null
                }
                <Row>
                    <br/><br/>
                    <MonthPager headerText="" handleChangedDate={this.handleChangedDate} allowFuture={true}/>
                    <Col xs={12} mdOffset={8} md={4}>
                        <DelayedTextField placeholder="Name" onChange={this.handleSearchTextChange}/>
                    </Col>
                    <Col xs={12}>
                        <TeamTable trainingTeam={trainingTeam} weekDict={calendarBoundaries.weekDict}
                                   trainingTeamMembers={trainingTeamMembers}
                                   trainingTeamResult={trainingTeamResult}
                                   isEditor={isEditor} searchParams={searchParams}/>
                    </Col>
                </Row>

            </React.Fragment>
        } else if (!isTeamMember) {
            return <Alert bsStyle="warning">
                    <FontAwesome icon="exclamation-circle"/>
                    <I18n code="profile.training.team.noMember"/>
                </Alert>
        } else {
            return <Loading/>
        }
    }
}
TrainingTeamDetail.propTypes = {
    trainingTeam:PropTypes.object,
    trainingTeamResult: PropTypes.object,
    searchParams: PropTypes.object,
    isTeamMember: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired
};
const TeamTable = ({trainingTeam, trainingTeamMembers, trainingTeamResult, weekDict, searchParams, isEditor}) => {
    function handleTableSort(sortKey, direction) {
        //console.log("Clicked sort by " + sortKey + " dir: " + direction)
        let sParams = searchParams;
        sParams.sortParams.sortKey = sortKey;
        sParams.sortParams.sortDir = direction;
        trainingActions.updateSearchParams(sParams);
    }
    return <Table responsive striped hover>
        <thead>
        <tr>
            <th><SortTableText sortKey="name" sortParams={searchParams.sortParams} handleSort={handleTableSort}><I18n code="tournament.table.column.name"/></SortTableText></th>
            <th colSpan={2} className="text-center"><I18n code="yearPager.year"/><br/>
                <SortTableText sortKey="arrow" sortParams={searchParams.sortParams} handleSort={handleTableSort}><I18n code="statistics.column.arrows"/></SortTableText>,&nbsp;
                <SortTableText sortKey="dur" sortParams={searchParams.sortParams} handleSort={handleTableSort}><I18n code="statistics.column.duration"/></SortTableText>
            </th>
            {
                Object.keys(weekDict).map(weekNumber => {
                    return <th colSpan={2} className="text-center">KW {weekNumber}</th>
                })
            }
        </tr>
        </thead>
        <tbody>
        {
            trainingTeamMembers != null && trainingTeamResult != null ? trainingTeamMembers.map(ttMember => {
                return (<TeamTableEntry key={ttMember.id} ttMember={ttMember} weekDict={weekDict}
                                        ttResult={trainingTeamResult[ttMember.id]} isEditor={isEditor}/>)
            }): <tr key="NewMember">
                <td><I18n code="list.entry.empty"/></td>
            </tr>
        }
        <TeamTableAddUser trainingTeam={trainingTeam} trainingTeamMembers={trainingTeamMembers} isEditor={isEditor} weekDict={weekDict}/>
        </tbody>
    </Table>
}
TeamTable.propTypes = {
    trainingTeam:PropTypes.object.isRequired,
    trainingTeamResult: PropTypes.object,
    isEditor: PropTypes.bool.isRequired
};
const TeamTableAddUser = ({trainingTeam, trainingTeamMembers, isEditor, weekDict}) => {
    function clickedAdd(mail) {
        // check email already in Team
        if (trainingTeamMembers != null) {
            for (let i = 0; i < trainingTeamMembers.length; i++) {
                if (trainingTeamMembers[i].getMail() === mail) {
                    // member already in team
                    notificationActions.info(messages.get("profile.training.team.alreadyMember") +  ": " + trainingTeamMembers[i].getName())
                    return
                }
            }
            trainingActions.addToTeamUsers(trainingTeam, mail)
        } else {
            trainingActions.addToTeamUsers(trainingTeam, mail)
        }

    }
    return (<tr>
        <td colSpan={2}>
            <SearchInputField placeholder={messages.get("profile.training.team.add.user")} type="email"
                              addonBefore={<FontAwesome icon="envelope"/>}
                              startSearch={clickedAdd} disabled={!isEditor} />
        </td>
        <td colSpan={Object.keys(weekDict).length} className="text-center">-</td>
    </tr>)
};
TeamTableAddUser.propTypes = {
    trainingTeam:PropTypes.object.isRequired,
    isEditor: PropTypes.bool.isRequired
};
const TeamTableEntry = ({ttMember, ttResult, weekDict, isEditor}) => {
    function changeName(value) {
        ttMember.setName(value);
        trainingActions.saveEventMember(ttMember)
    }
    return (<tr>
        {
            ttMember.isInvited() ? <td>{ttMember.getName()} <Label><I18n code="cl_req_status.I"/></Label></td> :
            <td>
                <a href={linkUtil.getLink("trainingmember/" + ttMember.id)} target="_blank">
                    {ttMember.getName()}</a>&nbsp;&nbsp;
                {
                    isEditor ? <TextInputModal text={ttMember.getName()}
                                               header={messages.get("profile.training.button.change.name")}
                                               saveChange={changeName}/> : null
                }
            </td>
        }
        {
            ttResult ? <ArrowDurationCellStats arrows={ttResult.getYearArrows()} duration={ttResult.getYearDuration()} />
            : <td colSpan={2} className="text-center">-</td>
        }
        {
            Object.keys(weekDict).map(weekNumber => {
                if (ttResult != null) {
                    let weekResult = ttResult.getWeekCounter(weekNumber);
                    return <ArrowDurationCellStats arrows={weekResult.arrows} duration={weekResult.duration} />
                }
                return <td colSpan={2} className="text-center">-</td>
            })
        }
    </tr>)
};
TeamTableEntry.propTypes = {
    ttMember: PropTypes.object.isRequired,
    ttResult: PropTypes.object
};
const ArrowDurationCellStats = ({arrows, duration}) => {
    return <React.Fragment>
        <td style={{"textAlign": "right"}}>{arrows}</td>
        <td style={{"textAlign": "right"}}>{Utility.convertMinutesToHours(duration)}</td>
    </React.Fragment>
}
module.exports = TrainingTeamDetail;
