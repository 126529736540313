const alt = require("AltInit");
const countTypeDao  = require("actdao/CountTypeDao");
const notificationActions = require("components/notification/NotificationActions");
const msgActions = require("actions/MessageActions");
const {CountType} = require("parse/_Domain");

class CountTypeActions {

    loadAllCountTypes() {
        this.queryCountTypesGroups();
        this.queryCountTypes();
        return {};
    }

    queryCountTypesGroups() {
        countTypeDao.queryCountTypesGroups()
            .then(countTypeGroups => {
                this.updateCountTypeGrp(countTypeGroups);
            })
            .catch(error => {
                error.trace= "CTA.qCTG";
                notificationActions.parseError(error);
            });
        return {};
    }

    fetchCountType(countType, result) {
        countTypeDao.queryCountType(countType)
            .then(fCountType => {
                this.updateCountType(fCountType);
                result(fCountType)
            })
            .catch(error => {
                error.trace= "CTA.qCTG";
                notificationActions.parseError(error);
            });
        return {};
    }

    fetchCountTypeByOnlineID(onlineID, result) {
        countTypeDao.queryCountTypeByOnlineID(onlineID, CountType.prototype.status.active)
            .then(countTypes => {
                if (countTypes.length === 1) {
                    result(countTypes[0].getName())
                } else if (countTypes.length > 1) {
                    result(countTypes[0].getName())
                    msgActions.slackNotify("Unexpected amount of count types (" + countTypes.length + ") found for onlineID: " + onlineID , "", null)
                } else {
                    result("---")
                }

            })
            .catch(error => {
                error.trace= "CTA.qCTONLINE";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryCountTypes() {
        countTypeDao.queryCountTypes()
            .then(countTypes => {
                this.updateCountTypes(countTypes);
            })
            .catch(error => {
                error.trace= "CTA.qCTL";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateLoading(loading) {
        return loading;
    }

    updateLoading(loading) {
        return loading;
    }

    updateCountTypes(pCountTypes) {
        return pCountTypes;
    }

    updateCountType(pCountType) {
        return pCountType;
    }

    updateCountTypeGrp(pCountTypeGrp) {
        return pCountTypeGrp;
    }
}

module.exports = alt.createActions(CountTypeActions);
