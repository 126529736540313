var Parse = require("ParseInit");

var TournamentUserGroup = Parse.Object.extend("TournamentUserGroup", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        creator: "creator",
        name: "name",
        draft:"draft",
        objectId: "objectId",
        startPosition: "startPosition",
        startStmp: "startStmp",
        status: "status",
        tournamentID: "tournamentID",
        tournamentRoundID : "tournamentRoundID",
        tournamentStringID: "tournamentStringID",
        tournamentUserIDs: "tournamentUserIDs",
        fixedTournamentUserIDs: "fixedTournamentUserIDs",
        actTargetNumber: "actTargetNumber",
        actTargetAmount: "actTargetAmount",
        actResultEditor:"actResultEditor",
        actResult: "actResults",
        updatedAt: "updatedAt"
    },
    status: {
        present: "A",
        started: "S",
        finished: "F",
        deleted: "D"
    },
    getLabels() {
        let lblArray = [this.getName(), this.getStartPosition()];
        let tUsers = this.getTournamentUserIDs();
        if (tUsers != null) {
            for (let i = 0; i < tUsers.length; i++) {
                lblArray.push(tUsers[i].getFullName());
                lblArray.push(tUsers[i].getUnion());
                lblArray.push(tUsers[i].getLicenseNumber());
            }
        }
        return lblArray.join(" ");
    },
    getSortColumn(colName) {
        return this.get(colName)
    },
    getTournamentUserPoints(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["points"];
            }
        }
        return 0;
    },
    getTournamentUserKillValue(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killValue"];
            }
        }
        return 0;
    },
    getTournamentUserKillCounts(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killCounts"];
            }
        }
        return "";
    },
    getStatusStyle() {
        if (this.isDeleted()) {
            return "danger"
        } else if (this.isDraft() && this.isPresent()) {
            return "warning"
        }
        return "info"
    },
    isStarted() {
        return this.getStatus() === this.status.started;
    },
    isInThisRound(tRound) {
        const tGroupRound = this.getTournamentRoundID();
        if (tGroupRound != null && tRound != null) {
            return tGroupRound.id === tRound.id
        }
        return false;
    },
    isPresent() {
        return this.status.present === this.getStatus();
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isFinished() {
        return this.status.finished === this.getStatus();
    },
    isTUserAllFinished() {
        let tUsers = this.getTournamentUserIDs();
        if (tUsers != null) {
            for (let i = 0; i < tUsers.length; i++) {
                if (!tUsers[i].isFinished()) {
                    return false
                }
            }
            return true
        }
        return false
    },
    isJoinAble() {
        return this.getStatus() != this.status.deleted && this.getStatus() != this.status.finished;
    },
    resetActualGroupResult() {
        this.unset(this.col.actResult)
        this.unset(this.col.actTargetAmount)
        this.unset(this.col.actTargetNumber)
        this.unset(this.col.actResultEditor)
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getCreator() {
        return this.get(this.col.creator)
    },
    setCreator(creator) {
        return this.set(this.col.creator, creator)
    },
    isCreator(user) {
        const creator = this.getCreator();
        if (creator != null) {
            return creator.id === user.id
        }
        return false
    },
    isDraft() {
        return this.get(this.col.draft)
    },
    setDraft(isDraft) {
        return this.set(this.col.draft, isDraft)
    },
    getTUserAmount() {
        let tUsers = this.getTournamentUserIDs();
        if (tUsers != null) {
            return tUsers.length
        }
        return 0
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getGroupColText() {
        return this.getName() + "; SP:" + this.getStartPosition() + "#";
    },
    getGroupKeyForUser(tUserId) {
        const fixedTUserObject = this.getFixedTournamentUserIDs()
        if (fixedTUserObject != null) {
            // find tUser in Object and return ABCD....
            for (let i = 0; i < Object.keys(fixedTUserObject).length; i++) {
                let posKey = Object.keys(fixedTUserObject)[i];
                let fUser = fixedTUserObject[posKey];
                if (fUser != null && fUser.id === tUserId) {
                    return " _" + posKey;
                }
            }
        }
        return "";

    },
    getStartPosition() {
        return this.get(this.col.startPosition)
    },
    setStartPosition(startPosition) {
        return this.set(this.col.startPosition, startPosition)
    },
    getStartStmp() {
        return this.get(this.col.startStmp)
    },
    setStartStmp(startStmp) {
        return this.set(this.col.startStmp, startStmp)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    setStatusFinished() {
        this.setStatus(this.status.finished)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getTournamentStringID() {
        return this.get(this.col.tournamentStringID)
    },
    setTournamentStringID(tournamentStringID) {
        return this.set(this.col.tournamentStringID, tournamentStringID)
    },
    getFixedTournamentUserIDs() {
        return this.get(this.col.fixedTournamentUserIDs)
    },
    setFixedTournamentUserIDs(fixedTournamentUserIDsObj) {
        return this.set(this.col.fixedTournamentUserIDs, fixedTournamentUserIDsObj)
    },
    getTournamentUserIDs() {
        return this.get(this.col.tournamentUserIDs)
    },
    setTournamentUserIDs(tournamentUserIDs) {
        return this.set(this.col.tournamentUserIDs, tournamentUserIDs)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    getActTargetPosition() {
        return this.get(this.col.actTargetNumber)
    },
    getActTargetNumber() {
        return this.get(this.col.actTargetAmount)
    },
    getActResult() {
        return this.get(this.col.actResult)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TournamentUserGroup;
