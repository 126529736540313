const alt = require("AltInit");

const actions = require("actions/EventActions");
const EventPlayerSortedList = require("util/EventPlayerSortedList");

class EventStore {
    constructor() {
        this.displayName = "EventStore";
        this.eventPlayerList = null;
        this.eventPlayers = null;
        this.countTypeEventPlayers = null;
        this.epYearStat = null;
        this.countTypes = null;
        this.finishedTournament = null;
        this.waitingTournament = null;
        this.editorTournament = null;
        this.transactions = null;
        this.clubEvents = [];
        this.loading = true;
        this.bindListeners({
            createEventList: actions.createEventList,
            updateEventPlayerList: actions.updateEventPlayerList,
            updateEventPlayerYearStat: actions.updateEventPlayerYearStat,
            updateTournamentUsers: actions.updateTournamentUsers,
            updateTournamentEditors: actions.updateTournamentEditors,
            updateTransactions: actions.updateTransactions,
            reset: actions.reset
        });
    }

    createEventList(year) {
        this.eventPlayerList = new EventPlayerSortedList(year);
    }

    updateEventPlayerList(fEventPlayers) {
        if (fEventPlayers != null) {
            fEventPlayers.map(fEventPlayer => {
                this.eventPlayerList.pushSorted(fEventPlayer);
            });
        }
        this.loading = false;
        this.eventPlayers = this.eventPlayerList.getEventPlayerList()
    }

    updateEventPlayerYearStat(pEpYearStat) {
        this.loading = false;
        this.epYearStat = pEpYearStat;
        this.countTypes = pEpYearStat.getCountTypes();

    }
    updateTournamentUsers(tournamentUsers) {
        let fTournaments = [];
        let wTournaments = [];
        tournamentUsers.forEach(tournamentUser => {
            if (tournamentUser.getTournamentID().isClosed()) {
                fTournaments.push(tournamentUser)
            } else {
                wTournaments.push(tournamentUser)
            }
        });
        this.loading = false;
        this.finishedTournament = fTournaments;
        this.waitingTournament = wTournaments;
    };

    updateTournamentEditors(tournaments) {
        this.loading = false;
        this.editorTournament = tournaments;
    }

    updateTransactions(pTransactions){
        this.loading = false;
        this.transactions = pTransactions;
    };

    reset() {
        this.loading = true;
        this.eventPlayers = null;
        this.countTypes = null;
        this.epYearStat = null;
        this.finishedTournament = null;
        this.waitingTournament = null;
        this.editorTournament = null;
        this.countTypeEventPlayers = null;
        this.transactions = null;
    }
}
module.exports = alt.createStore(EventStore);
