const alt = require("AltInit");
const actions = require("actions/TableActions");
const PLUtil = require("util/ParseListUtility");


class TableStore {
    constructor() {
        this.displayName = "TableStore";
        this.pageSizeArray= [10,25,50,100];
        this.allObjectList= [];
        this.objectList= [];
        this.selectedObjectList = [];
        this.pageSize= 10;
        this.pageStart= 0;
        this.searchParams= {
            text: "",
            searching: false,
            disableSearch: false,
            typeAhead: true
        };
        this.sortParams= {
            sortKey: null,
            sortDir: "asc"
        }
        this.bindListeners({
            updateObjectList: actions.updateObjectList,
            updateSelectedObjectList: actions.updateSelectedObjectList,
            addObject: actions.addObject,
            updateObject: actions.updateObject,
            updateSearchParams: actions.updateSearchParams,
            updateSortParams: actions.updateSortParams,
            updateSearchField: actions.updateSearchField,
            searchObject: actions.searchObject,
            setSearching: actions.setSearching,
            finishSearch: actions.finishSearch,
            updateTablePage: actions.updateTablePage,
            selectTablePage: actions.selectTablePage,
            updateTablePageSize: actions.updateTablePageSize
        });
    }

    updateObjectList(objectList) {
        this.allObjectList = objectList;
        this.filterObjects();
    }

    updateSelectedObjectList(objectList) {
        this.selectedObjectList = objectList;
    }

    searchObject(pSearchParams) {
        pSearchParams.searching = true;
        this.updateSearchParams(pSearchParams);
        this.filterObjects();
    }

    setSearching(searching) {
        const sParam = this.searchParams;
        sParam.searching = searching;
        this.searchParams = sParam;
        this.pageStart = 0;
    }

    updateSearchField(type) {
        const sParam = this.searchParams;
        sParam.typeAhead = type;
        this.searchParams = sParam;
    }

    updateSearchParams(params) {
        this.searchParams = params;
    }

    updateSortParams({sortKey, direction}) {
        console.log("Clicked sort by " + sortKey + " dir: " + direction);
        let sSortParams =this.sortParams;
        sSortParams.sortKey = sortKey;
        sSortParams.sortDir = direction;
        this.sortParams = sSortParams;
        this.filterObjects()
    }

    updateObject(object) {
        this.updateObjectList(PLUtil.updateOrPush(this.allObjectList, object));
    }

    addObject(entryObject) {
        this.updateObjectList(PLUtil.pushFirst(this.allObjectList, entryObject));
    }
    filterObjects() {
        let preFilteredList = this.allObjectList;
        if (this.searchParams.moreFilter) {
            // advanced filtering
            if (this.searchParams.selectedStatus) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getStatus() == this.searchParams.selectedStatus;
                })
            }
            if (this.searchParams.selectedSex == 0 || this.searchParams.selectedSex == 1) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getSex() == this.searchParams.selectedSex;
                })
            }
            if (this.searchParams.selectedAgeType) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getTournamentConfigAge().id == this.searchParams.selectedAgeType.id;
                })
            }
            if (this.searchParams.selectedBowType) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getTournamentConfigBow().id == this.searchParams.selectedBowType.id;
                })
            }
            if (this.searchParams.selectedOption) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getOption() == this.searchParams.selectedOption;
                })
            }
            if (this.searchParams.selectedSlotId) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.getTournamentRegSlotID() != null &&
                        u.getTournamentRegSlotID().id === this.searchParams.selectedSlotId;
                })
            }
            if (this.searchParams.selectedPaid != null) {
                preFilteredList = preFilteredList.filter(u => {
                    return u.isPaid() == this.searchParams.selectedPaid;
                })
            }
        }
        let filteredList;
        if (this.searchParams.text.length > 0) {
            const searchString = this.searchParams.text.toLowerCase();
            filteredList = preFilteredList.filter(function (u) {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
            this.searchParams.filterInfo = this.objectList.length + "/" + this.allObjectList.length;
        } else {
            filteredList = preFilteredList;
            this.searchParams.filterInfo = preFilteredList.length + "/" + this.allObjectList.length;
        }
        this.objectList = PLUtil.sortList(filteredList, this.sortParams);
        this.searchParams.searching = false;
    }

    finishSearch(custSearchResult) {
        this.objectList = custSearchResult.objectList;
        this.searchParams = custSearchResult.sParams;
    }

    updateTablePage(pageOffset) {
        this.pageStart = this.pageStart + pageOffset
    }

    selectTablePage(pageNumber) {
        this.pageStart = pageNumber;
    }

    updateTablePageSize(pageSize) {
        this.pageSize = pageSize;
        this.pageStart = 0;
    }
}
module.exports = alt.createStore(TableStore);