const Parse = require("ParseInit");
const Utility = require("util/Utility");
const UserAbo = Parse.Object.extend("userAbo", {
    col: {
        ACL: "ACL",
        userID: "userID",
        status: "status",
        features: "features", //array
        aboStartDate: "aboStart",
        aboEndDate: "aboEnd",
        pauseStart: "pauseStart",
        pauseEnd: "pauseEnd",
        type: "type",
        transactionID: "transactionID",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        active: "A",
        inactive: "I",
        deleted: "D"
    },
    feature: {
        statistics: "S",
        ranking: "R",
        gpsTrack: "G",
        targets: "Z",
        training: "T",
        all: ["S","R","G","Z","T"]
    },
    type: {
        tester: "T",
        donator: "D",
        paid: "P"
    },
    //
    // customn
    hasFeature: function (feature) {
        const featureList = this.getFeatures();
        if (featureList != null) {
            return featureList.indexOf(feature) > -1
        }
        return false;
    },
    isFeatureActive: function (feature) {
        if (this.getStatus() === this.status.active) {
            return this.hasFeature(feature);
        }
        return false;
    },
    isTypePaid() {
        return this.type.paid == this.getType();
    },
    hasTraining() {
        return this.hasFeature(this.feature.training)
    },
    buildACL: function (user) {
        const acl = new Parse.ACL();
        acl.setPublicReadAccess(false);
        acl.setPublicWriteAccess(false);
        if (user) {
            acl.setWriteAccess(user, false);
            acl.setReadAccess(user, true);
        }
        acl.setRoleReadAccess("3DSKBOadmin", true);
        acl.setRoleWriteAccess("3DSKBOadmin", true);
        this.setACL(acl);
    },
    getAboStartDateReadable: function () {
        const date = this.get(this.col.aboStartDate);
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getTransaction() {
        return this.get(this.col.transactionID);
    },
    getAboEndDateReadable: function () {
        const date =  this.get(this.col.aboEndDate);
        if (date) {
            return Utility.getReadableDate(date);
        }
        return "-";
    },
    getAboEndDateWithPause: function(now) {
        let pauseMS = now.getTime() - this.getPauseStartDate().getTime();
        return new Date(this.getAboEndDate().getTime() + pauseMS);
    },
    //
    //
    //
    //
    getUserID: function () {
        return this.get(this.col.userID)
    },
    setUserID: function (userID) {
        return this.set(this.col.userID, userID)
    },
    getAboStartDate: function () {
        return this.get(this.col.aboStartDate);
    },
    getAboEndDate: function () {
        return this.get(this.col.aboEndDate);
    },
    setAboStartDate: function (date) {
        return this.set(this.col.aboStartDate, date);
    },
    setAboEndDate: function (date) {
        return this.set(this.col.aboEndDate, date);
    },
    getPauseStartDate: function () {
        return this.get(this.col.pauseStart);
    },
    getPauseEndDate: function () {
        return this.get(this.col.pauseEnd);
    },
    setPauseStartDate: function (date) {
        return this.set(this.col.pauseStart, date);
    },
    setPauseEndDate: function (date) {
        return this.set(this.col.pauseEnd, date);
    },
    isAboEndDateValid: function() {
        return this.getAboEndDate() >= new Date();
    },
    getFeatures: function () {
        return this.get(this.col.features)
    },
    setFeatures: function (features) {
        return this.set(this.col.features, features)
    },
    getStatus: function() {
        return this.get(this.col.status)
    },
    setStatus: function(status) {
        return this.set(this.col.status, status)
    },
    isDeleted: function () {
        return this.status.deleted === this.getStatus();
    },
    isInactive: function () {
        return this.status.inactive === this.getStatus();
    },
    getType: function() {
        return this.get(this.col.type)
    },
    setType: function(type) {
        return this.set(this.col.type, type)
    },
    getUpdatedAt: function() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt: function() {
        return this.get(this.col.createdAt)
    }
});

module.exports = UserAbo;
