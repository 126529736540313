const alt = require("AltInit");
const notificationActions = require("components/notification/NotificationActions");
const parseDao = require("actdao/ParseBaseDao");
const voucherDao = require("actdao/VoucherDao");
const parcoursDao = require("actdao/ParcoursDao");
const FileDownloadUtility =  require("util/FileDownloadUtility");
const {Voucher} = require("parse/_Domain");

class VoucherActions {
    queryVouchers(status, callback) {
        voucherDao.queryVouchers(status)
            .then(voucherList => {
                callback(voucherList)
            })
            .catch(error => {
                error.trace = "VOUA.queryVouchers";
                notificationActions.parseError(error);
            });
        return {};
    };

    queryArticles(callback) {
        parcoursDao.queryParcoursCheckout("AT_3DSB_01")
            .then(parcours => {
                let articles=[];
                if (parcours.getArticles()) {
                    articles = articles.concat(parcours.getArticles());
                }
                if (parcours.getParcoursOwnerID().getArticles()) {
                    articles = articles.concat(parcours.getParcoursOwnerID().getArticles());
                }
                callback(articles);
            })
            .catch(error => {
                error.trace = "PARA.query.Checkout";
                notificationActions.parseError(error);
            });
        return {};
    }

    createVoucher(articleID, email, callback) {
        voucherDao.createVoucher(articleID, email)
            .then(sVoucher => {
                callback(sVoucher)
            })
            .catch(error => {
                error.trace = "VOUA.createV";
                notificationActions.parseError(error);
            });
        return {};
    };

    createVoucherBundle(articleID, amount, callback) {
        voucherDao.createVoucherBundle(articleID, amount)
            .then(voucherList => {
                this.exportVouchers(voucherList);
                callback(voucherList)
            })
            .catch(error => {
                error.trace = "VOUA.createVBu";
                notificationActions.parseError(error);
            });
        return {};

    }

    exportVouchers(voucherList) {
        const csvData = [];
        const header = [];
        header.push("ID");
        header.push("Datum");
        header.push("Link");
        csvData.push(header.join(';'));
        const link = "https://www.3dturnier.com/de/skillboard/premium/buy/";
        voucherList.map(voucher => {
            // build row
            const row = [];
            row.push(voucher.id);
            row.push(voucher.getExpireDateReadable());
            row.push(link + voucher.id);
            csvData.push(row.join(';'));
        });
        const output = csvData.join('\n');
        let filename = "voucher_export.csv";
        FileDownloadUtility.downloadCSV(output, filename );
        return {};
    }

    fetchVoucher(voucherId, callback, errorCB) {
        let voucher = new Voucher();
        voucher.id = voucherId.trim();
        parseDao.fetchObject(voucher)
            .then(fVoucher => {
                callback(fVoucher)
            })
            .catch(error => {
                if (error.code = 101) {
                    errorCB(error);
                } else {
                    error.trace = "VOUA.getV";
                    notificationActions.parseError(error);
                }

            });
        return {};
    }

    redeemVoucher(voucher, resultCallback) {
        voucherDao.redeemVoucher(voucher.id)
            .then(result => {
                resultCallback(result)
            })
            .catch(error => {
                error.trace = "VOUA.redVouchers";
                notificationActions.parseError(error);
            });
        return {};
    };

    activateVoucher(voucher, management, resultCallback) {
        voucherDao.activateVoucher(voucher.id, management.id)
            .then(result => {
                resultCallback(result)
            })
            .catch(error => {
                error.trace = "VOUA.actVouchers";
                notificationActions.parseError(error);
            });
        return {};
    };
}

module.exports = alt.createActions(VoucherActions);
