const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,  Image, Label, Carousel, CarouselItem} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ExtLink = require("components/links/ExtLink"),
    AboFeatureLabel = require("components/widgets/AboFeatureLabel");


const AboFeatureDesc = ({ type, feature, price, imgSrc, descArray, apple, android, web, dir, links}) => {
    let appleObj = null;
    if ( apple === "true") {
        appleObj = <FontAwesome icon={["fab","apple"]}/>
    } else if (apple != null) {
        appleObj = <span> - <I18n code="skillboard.premium.planned"/>: <FontAwesome icon={["fab","apple"]}/> {apple} </span>
    }
    let androidObj = null;
    if ( android === "true") {
        androidObj = <FontAwesome icon={["fab","android"]}/>
    } else if (android != null) {
        androidObj = <span> - <I18n code="skillboard.premium.planned"/>: <FontAwesome icon={["fab","android"]}/> {android} </span>
    }
    let webObj = web === "true" ? <FontAwesome icon={["fab","html5"]}/> : null;
    let title = null;
    let desc = null;
    if (type) {
        title = <AboFeatureLabel type={type}/>
        desc = <strong>{messages.get("userAbo.feature."+ type + ".desc")}</strong>
    } else if (feature) {
        title = <I18n code={"management.feature." + feature}/>
        desc = messages.get("management.feature."+ feature + ".desc")
    }
    return (
            <Row>
                <Col sm={6}>
                    <h2>{title}</h2>
                    <p>{desc}</p>
                    {
                        androidObj != null || appleObj != null ? <h4><I18n code="skillboard.premium.available"/> {webObj} {androidObj} {appleObj}</h4> : null
                    }
                    <ul>
                        {
                            descArray.map(desc => {
                                return <li><I18n code={desc}/></li>
                            })
                        }
                        {
                            links ? links.map(entry => {
                                return <li><ExtLink href={linkUtil.getExternalLink(entry.url)}><I18n code={entry.desc}/></ExtLink></li>
                            }) : null
                        }
                    </ul>
                    {
                        price ? <h2 style={{"textAlign": "center"}}><Label bsStyle="info">{price}</Label></h2>: null
                    }
                </Col>
                <Col  xsOffset={dir == "V" ? 2 : 0} xs={dir == "V" ? 8 : 12}  smOffset={0} sm={5}>
                    {
                        imgSrc != null ?
                            <Carousel>
                                {
                                    imgSrc.map(image => {
                                        return <CarouselItem>
                                            {/*<img width={900} height={500} alt="900x500" src={imgUrl} />*/}
                                            <div style={{textAlign: "center"}}>
                                                <Image style={{margin: "auto"}}responsive src={image.url}/>
                                            </div>

                                            <Carousel.Caption><p style={{color:"black", backgroundColor: "#FFFFFF"}}><I18n code={image.desc}/></p></Carousel.Caption>
                                        </CarouselItem>
                                    })
                                }

                            </Carousel> : null
                    }
                </Col>
            </Row>)
};
AboFeatureDesc.propTypes = {
    type:PropTypes.string,
    feature:PropTypes.string,
    price: PropTypes.string,
    apple:PropTypes.string,
    android:PropTypes.string,
    web:PropTypes.string,
    dir:PropTypes.string.isRequired,
    descArray:PropTypes.array.isRequired,
    links:PropTypes.array,
    imgSrc:PropTypes.array
};
module.exports = AboFeatureDesc;
