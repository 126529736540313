const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    PremiumBanner = require("components/widgets/PremiumBanner"),
    FontAwesome = require("components/widgets/FontAwesome"),
    TrainingScatterFilter  = require("components/training/TrainingScatterFilter");

const userStore = require("stores/UserStore"),
    trainingActions = require("actions/TrainingActions"),
    trainingStore = require("stores/TrainingStore"),
    notificationActions = require("components/notification/NotificationActions");

class TabEventScatter extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTrainingChange = this.handleTrainingChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            trainingStore: trainingStore.getState(),
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        trainingStore.listen(this.handleTrainingChange);
        notificationActions.reset();
        trainingActions.queryTrainingCountTypes();
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        trainingStore.unlisten(this.handleTrainingChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTrainingChange(state) {
        this.setState({trainingStore: state});
    }
    render() {
        const {user, userFeatures, userAboLoading} = this.state.userStore
        const {eventTrainingList, eventTrainingLoading, trainingCountTypes} = this.state.trainingStore
        if (userAboLoading) {
            return (<Row><Col xs={12}><Loading/></Col></Row>)
        } else {
            if (userFeatures != null && userFeatures.isStatisticFeatureActive()) {
                return <TrainingScatterFilter user={user} year={this.props.year} allowEventTargets={false}
                                              trainingCountTypes={trainingCountTypes}
                                              eventTrainingList={eventTrainingList} eventTrainingLoading={eventTrainingLoading}/>
            } else {
                return <Alert bsStyle="warning">
                    <FontAwesome icon="exclamation-circle"/>
                    <I18n code="skillboard.premium.noAbo"/><br/><br/>
                    <PremiumBanner />
                </Alert>
            }
        }
    }
}
TabEventScatter.propTypes = {
    year: PropTypes.number.isRequired
};
module.exports = TabEventScatter;
