const React = require("react"),
    PropTypes = require('prop-types');
const {Button, ButtonGroup} = require("react-bootstrap");
const messages = require("i18n/messages");

const Parcours = require("parse/Parcours");


const ParcoursTypeGroup = ({parcoursType, setTypeTarget}) => {
    return <ButtonGroup>
        <TypeButton type={Parcours.prototype.type.parcours} label={messages.get("ParcoursInfo_type_P_parcours")} selType={parcoursType}  setTypeTarget={setTypeTarget}/>
        <TypeButton type={Parcours.prototype.type.field} label={messages.get("ParcoursInfo_type_F_field")} selType={parcoursType}  setTypeTarget={setTypeTarget}/>
        <TypeButton type={Parcours.prototype.type.target} label={messages.get("ParcoursInfo_type_T_target")} selType={parcoursType}  setTypeTarget={setTypeTarget}/>
        <TypeButton type={Parcours.prototype.type.cinema4D} label="BowSim 4D" selType={parcoursType}  setTypeTarget={setTypeTarget}/>
    </ButtonGroup>
}
ParcoursTypeGroup.propTypes = {
    parcoursType:PropTypes.string.isRequired,
    setTypeTarget:PropTypes.func.isRequired
};
const TypeButton = ({selType, type, label, setTypeTarget}) => {
    function handleClick() {
        setTypeTarget(type)
    }
    return <Button onClick={handleClick} active={selType === type}>{label}</Button>
};
module.exports = ParcoursTypeGroup;
