var Parse = require("ParseInit");

var {Management} = require("parse/_Domain");

class AccountDao {


    queryMainEditorManagement(user) {
        var poQuery = new Parse.Query(Management);
        poQuery.equalTo(Management.prototype.col.mainEditor, user);
        poQuery.equalTo(Management.prototype.col.status, "A");
        poQuery.include(Management.prototype.col.businessPartnerID);
        poQuery.include(Management.prototype.col.businessPartnerID + ".paymentTargetID");
        poQuery.include(Management.prototype.col.businessPartnerID + ".addressID");
        poQuery.include(Management.prototype.col.parcoursOwnerID);
        poQuery.include(Management.prototype.col.clubInvoiceIDs);
        poQuery.include(Management.prototype.col.addEditor);
        return poQuery.find();
    }

    queryInvolvedManagement(user) {
        const mEQuery = new Parse.Query(Management);
        mEQuery.equalTo(Management.prototype.col.status, "A");

        const aEQuery = new Parse.Query(Management);
        aEQuery.equalTo(Management.prototype.col.status, "A");

        if (user != null) {
            mEQuery.equalTo(Management.prototype.col.mainEditor, user);
            aEQuery.equalTo(Management.prototype.col.addEditor, user);
        }

        const poQuery = Parse.Query.or(mEQuery, aEQuery);
        poQuery.include(Management.prototype.col.businessPartnerID);
        poQuery.include(Management.prototype.col.businessPartnerID + ".paymentTargetID");
        poQuery.include(Management.prototype.col.businessPartnerID + ".addressID");
        poQuery.include(Management.prototype.col.addEditor);
        poQuery.include(Management.prototype.col.mainEditor);
        return poQuery.find();
    }

    addToAddEditor(objectType, objectID, mail, asUser) {
        return Parse.Cloud.run("addEditorTo" + objectType, { email: mail, objectId :objectID, asUser: asUser });
    }
    removeFromAddEditor(objectType, objectID, editor, asUser) {
        return Parse.Cloud.run("removeEditorFrom" + objectType, { editorId: editor.id, objectId :objectID, asUser: asUser });
    }

}

module.exports = new AccountDao();
