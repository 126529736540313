/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,FormControl, Modal, Alert, Tabs,Tab, Button} = require("react-bootstrap");
const ClubInvoice = require("parse/ClubInvoice");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    FileUploadInput = require("components/form/FileUploadInput"),
    DateInput = require("components/form/DateInput"),
    ValidInput = require("components/form/ValidInput"),
    RadioGroupInput = require("components/form/RadioGroupInput");

const clubInvoiceActions = require("actions/ClubInvoiceActions");

class ClubInvoiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.refNumber = React.createRef();
        this.refName = React.createRef();
        this.refNote = React.createRef();
        this.refAmount = React.createRef();
        this.refDate = React.createRef();

        this.openCreate = this.openCreate.bind(this);
        this.openInvoice = this.openInvoice.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.clickChangedInOROut = this.clickChangedInOROut.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleParseFile = this.handleParseFile.bind(this);
        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.getDefaultDate = this.getDefaultDate.bind(this);

        this.state = {
            clubInvoice: null,
            submitDisabled: false,
            inout: "true",
            selType: null,
            parseFile: null,
            showModal: false,
            error: null
        }
    }
    openCreate() {
        this.setState({
            clubUser: null,
            inout: "true",
            selType: null,
            parseFile: null,
            showModal: true
        });
    }
    openInvoice(invoice) {
        this.setState({
            clubInvoice: invoice,
            inout: invoice.getInOrOut().toString(),
            selType: invoice.getType(),
            parseFile: invoice.getDocument(),
            showModal: true
        });
    }
    close() {
        this.setState({submitDisabled: false, showModal: false,clubInvoice: null, error:null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    valid() {
        let valid = true;
        const required = [
            this.refNumber.current.getValue(),
            this.refDate.current.getValue(),
            this.refName.current.getValue(),
            this.refAmount.current.getValue(),
            this.state.inout
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});

        let editInvoice = this.state.clubInvoice;
        if (editInvoice == null) {
            editInvoice = new ClubInvoice();
            editInvoice.setStatus(ClubInvoice.prototype.status.published);
            editInvoice.setCreator(this.props.user);
            editInvoice.setClubID(this.props.club);
        }
        // edit the elements
        editInvoice.setInvoiceNumber(this.refNumber.current.getValue().trim());
        editInvoice.setDate(new Date(this.refDate.current.getValue()));
        editInvoice.setName(this.refName.current.getValue().trim());
        if (this.refNote.current.getValue()) {
            editInvoice.setDescription(this.refNote.current.getValue());
        } else {
            editInvoice.unset(ClubInvoice.prototype.col.description);
        }
        if (this.state.selType != null) {
            editInvoice.setType(this.state.selType);
        }else {
            editInvoice.unset(ClubInvoice.prototype.col.type);
        }
        editInvoice.setAmount(parseFloat(this.refAmount.current.getValue()));
        editInvoice.setInOrOut(this.state.inout === "true");
        if (this.state.parseFile != null) {
            editInvoice.setDocument(this.state.parseFile);
        }
        clubInvoiceActions.saveClubInvoice(editInvoice);
        this.close();
    }
    clickChangedInOROut(selected) {
        this.state.inout = selected.split(".")[4];
    }
    handleTypeChange(e) {
        const selectedType = e.target.value;
        if (selectedType != "null") {
            this.setState({selType: selectedType});
        } else {
            this.setState({selType: null});
        }
    }
    handleParseFile(parseFile, imageType) {
        this.setState({parseFile: parseFile});
    }
    getDefaultValue(column) {
        if (this.state.clubInvoice != null && this.state.clubInvoice.get(column) != null) {
            return this.state.clubInvoice.get(column) + "";
        } else if (column === ClubInvoice.prototype.col.invNumber) {
            return "" + this.props.nextNewNumber + "";
        }
        return null;
    }
    getDefaultDate(column) {
        if (this.state.clubInvoice != null) {
            return this.state.clubInvoice.get(column);
        }
        return new Date();
    }
    render() {
        const {showModal, error, clubUser, inout, selType, submitDisabled} = this.state;
        const validateNow = error && error.length > 0;
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            clubUser != null ?
                                <I18n code={"club.invoice.modal.edit.header"}/>
                                :
                                <I18n code={"club.invoice.modal.create.header"}/>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                                    <Tab eventKey={1} title={messages.get("modal.entry.mandatory")}>
                                        <Row>
                                            <br/>
                                            <Col sm={6}>
                                                <ValidInput ref={this.refNumber}
                                                            valid={{maxLength: 10, check:['isRequired']}}
                                                            label={messages.get("tournament.table.column.number")}
                                                            default={this.getDefaultValue(ClubInvoice.prototype.col.invNumber)}
                                                            validateNow={validateNow}/>
                                            </Col>
                                            <Col sm={6}>
                                                <DateInput ref={this.refDate} default={this.getDefaultDate(ClubInvoice.prototype.col.date)}
                                                           label={messages.get("tournament.table.column.date")}/>
                                            </Col>

                                            <Col sm={12}>
                                                <ValidInput ref={this.refName}
                                                            valid={{maxLength: 50, check:['isRequired']}}
                                                            validateNow={validateNow}
                                                            default={this.getDefaultValue(ClubInvoice.prototype.col.name)}
                                                            placeholder={messages.get("tournament.table.column.text")}/>
                                            </Col>
                                            <Col sm={6}>
                                                <RadioGroupInput default={"club.invoice.amount.in." + inout}
                                                                 list={["club.invoice.amount.in.true", "club.invoice.amount.in.false"]}
                                                                 validateNow={validateNow}
                                                                 onAfterChange={this.clickChangedInOROut}/>
                                            </Col>
                                            <Col sm={6}>
                                                <ValidInput ref={this.refAmount} type="number"
                                                            valid={{maxLength: 10, check:['isRequired', 'isNumber', 'point4comma']}}
                                                            placeholder={messages.get("tournament.table.column.inout")}
                                                            default={this.getDefaultValue(ClubInvoice.prototype.col.amount)}
                                                            addonBefore={<FontAwesome icon="euro-sign"/>}
                                                            validateNow={validateNow}/>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={2} title={messages.get("modal.entry.optional")}>
                                        <Row>
                                            <br/>
                                            <Col sm={12}>
                                                <ValidInput ref={this.refNote}
                                                            componentClass="textarea"
                                                            valid={{maxLength: 1500}}
                                                            validateNow={validateNow}
                                                            default={this.getDefaultValue(ClubInvoice.prototype.col.description)}
                                                            placeholder={messages.get("club.event.create.message.note")}/>
                                            </Col>
                                            {
                                                this.props.club.getInvoiceTypes() != null ?
                                                    <Col sm={12}>
                                                        <FormControl componentClass="select" placeholder="select"
                                                                     defaultValue={selType}
                                                                     onChange={this.handleTypeChange}>
                                                            <option key={"nullkey"}
                                                                    value={"null"}>{messages.get("club.invoice.modal.chooseInvType")}</option>
                                                            {this.props.club.getInvoiceTypes().map(invType => {
                                                                return <option key={invType} value={invType}>{invType}</option>
                                                            })}

                                                        </FormControl>
                                                    </Col>: null
                                            }
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={3} title={messages.get("modal.entry.attachment")}>
                                        <Row>
                                            <Col sm={12}>
                                                <br/>
                                                    <FileUploadInput parseFile={this.state.parseFile}
                                                                     allowedFileTypes={["P","D","I"]}
                                                                     label={messages.get("fileupload.description")}
                                                                     savedParseFileCallback={this.handleParseFile}/>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                                <hr/>
                                <Row>
                                    {error == null ? null : <Alert bsStyle="danger">{error}</Alert>}
                                    <Col xs={12}>
                                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                </Row>

                            </form> : null
                    }

                </Modal.Body>
            </Modal>
        );
    }
}
ClubInvoiceModal.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    nextNewNumber:PropTypes.number.isRequired
};
module.exports = ClubInvoiceModal;