const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Well, Label, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

function ExistingBusinessAccountInfo({managements, manageCallback}) {
        return (<React.Fragment>
            <h3><I18n code="tournament.manager.link2BusinessAccount"/></h3>
            <br/>
            {
                managements.map(management => {
                    return (<ExistingBusinessPartner key={management.id}
                                                     manageCallback={manageCallback}
                                                     management={management}
                                                     showFeatures={true}
                                                     buttonText="tournament.button.manage"/>)
                })
            }
        </React.Fragment>);
}
ExistingBusinessAccountInfo.propTypes = {
    managements: PropTypes.array.isRequired,
    manageCallback: PropTypes.func.isRequired
};
function ExistingBusinessPartner({management, manageCallback, showFeatures, icon, buttonText}) {
    function handleLink2BusinessAccount() {
        manageCallback(management);
    }
    return (
        <Well>
            <Row>
                <Col sm={8} xs={12}>
                    <h3>{management.getName()}</h3>
                    {
                        showFeatures ?
                            <h4>
                            {management.getFeatures().map(f => {
                                return<Label key={"mf" + f} ><I18n code={"management.feature." + f}/></Label>
                            })}
                            </h4> : null
                    }
                </Col>
                <Col sm={4} xs={12}>
                    <Button block bsSize="large" bsStyle="primary" onClick={handleLink2BusinessAccount}>
                        <FontAwesome icon="check-square"/><I18n code={buttonText}/>
                    </Button>
                </Col>
            </Row>
        </Well>
    )
}
ExistingBusinessPartner.propTypes = {
    management: PropTypes.object.isRequired,
    manageCallback: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    showFeatures: PropTypes.bool.isRequired
};
module.exports = {
    ExistingBusinessAccountInfo, ExistingBusinessPartner
};
