const Parse = require("ParseInit");

const UserFavorite = Parse.Object.extend("userFavorite", {
    col: {
        userID: "userID",
        parcours: "parcours",
        users: "users",
        status: "status",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        A: "A"
    },
    //
    // customn
    //
    isInFavorites(onlineID) {
        var favParArr = this.getParcoursOnlineIDs();
        if (favParArr != null) {
            return favParArr.indexOf(onlineID) !== -1;
        }
        return false;
    },
    hasUsersMail(searchMail) {
        let usersArray = this.getUsers();
        if (usersArray != null) {
            for (let i = 0; i < usersArray.length; i++) {
                if (searchMail == usersArray[i].mail) {
                    return true;
                }
            }
        }
        return false;
    },
    getUsersEntry(searchMail) {
        let usersArray = this.getUsers();
        if (usersArray != null) {
            for (let i = 0; i < usersArray.length; i++) {
                if (searchMail == usersArray[i].mail) {
                    return usersArray[i];
                }
            }
        }
        return null;
    },
    replaceUsersEntryMail(newMail, searchMail) {
        let usersArray = this.getUsers();
        if (usersArray != null) {
            for (let i = 0; i < usersArray.length; i++) {
                if (searchMail == usersArray[i].mail) {
                    usersArray[i].mail = newMail;
                    return usersArray[i];
                }
            }
        }
    },

    //
    //
    //
    getUserID() {
        return this.get(this.col.userID)
    },
    setUserID(userID) {
        return this.set(this.col.userID, userID)
    },
    getUsers() {
        return this.get(this.col.users)
    },
    getParcoursOnlineIDs() {
        return this.get(this.col.parcours)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = UserFavorite;
