const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");

class AutoScrollButton extends React.Component{
    constructor(props) {
        super(props);
        this.intervalScroll = this.intervalScroll.bind(this);
        this.state = {
            interval: null
        }
    }
    intervalScroll(event) {
        const that = this;
        const start = event.currentTarget.getBoundingClientRect().top;
        let iv = null;
        if (this.state.interval == null) {
            iv = setInterval(function(){
                //console.log((window.scrollY + window.innerHeight + 30).toString() + " >= " + document.body.scrollHeight);
                if ( window.scrollY + window.innerHeight + 30 >= document.body.scrollHeight) {
                    console.log("bottom - restart - reload");
                    if (that.props.reloadCallback) {
                        that.props.reloadCallback();
                    }
                    window.scrollTo(that.props.xStart, start);
                } else {
                    window.scrollBy(that.props.xScroll, that.props.yScroll);
                }
            }, that.props.scrollInterval);
        } else {
            clearInterval(this.state.interval);
        }
        this.setState({interval: iv});
    }
    render() {
        return (
            <Button bsStyle={this.state.interval == null ? "primary" : "danger"} onClick={this.intervalScroll}>
                <FontAwesome icon="retweet"/>AUTO Scroll
            </Button>
        )
    }
}
AutoScrollButton.propTypes = {
    scrollInterval:PropTypes.number.isRequired,
    xScroll:PropTypes.number.isRequired,
    yScroll:PropTypes.number.isRequired,
    reloadCallback:PropTypes.func
};
module.exports = AutoScrollButton;
