const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Grid, Panel, Alert, Button} = require("react-bootstrap");
const messages = require("i18n/messages");

const Title = require("components/widgets/Title"),
    FontAwesome = require("components/widgets/FontAwesome"),
    GroupPlaces = require("components/widgets/GroupPlaces"),
    Loading = require("components/widgets/Loading"),
    I18n = require("components/widgets/I18n"),
    PointInterestBanner = require("components/widgets/PointInterestBanner"),
    ListLayout = require("components/widgets/ListLayout"),
    Utility = require("util/Utility");

const DelayedTextField = require("components/form/DelayedTextField");

const ClubCreateModal = require("components/clubmodal/ClubCreateModal"),
    ClubLink = require("components/club/ClubLink");

const groupPlaceActions = require("actions/GroupPlaceActions"),
    clubStore = require("stores/ClubStore"),
    clubActions = require("actions/ClubActions"),
    notificationActions = require("components/notification/NotificationActions"),
    userStore = require("stores/UserStore"),
    UserActions = require("actions/UserActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

class ClubPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleClubChange = this.handleClubChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.state = {
            clubStore: clubStore.getState(),
            userStore: userStore.getState()
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.clubs");
        userStore.listen(this.handleUserChange);
        clubStore.listen(this.handleClubChange);
        notificationActions.reset();
        groupPlaceActions.reset();
        if (this.state.userStore.user != null && this.state.userStore.user.isRealUser()) {
            clubActions.searchOwnClubs(this.state.clubStore.searchParams, this.state.userStore.user);
        } else {
            clubActions.searchClubs(this.state.clubStore.searchParams)
        }
    }
    componentWillUnmount() {
        clubStore.unlisten(this.handleClubChange);
        userStore.unlisten(this.handleUserChange);
    }
    handleClubChange(state) {
        this.setState({clubStore: state});
    }
    handleUserChange(user) {
        this.setState({userStore: user});
    }
    noResults() {
        return !this.state.clubStore.loading &&
            this.state.clubStore.clubs.length == 0
            && this.state.parseError == null;
    }
    render() {
        return (
            <div>
                <Grid>
                    <Row>
                        <Col xs={12} xsHidden>
                            <Breadcrumbs>
                                <Breadcrumb link="/" code="header.nav.home"/>
                                <Breadcrumb code="header.nav.clubs" active/>
                            </Breadcrumbs>
                        </Col>
                    </Row>
                    <Title code="header.nav.clubs"/>
                    <ListLayout.Wrapper>
                        <ListLayout.Filter>
                            <ClubFilter clubStore={this.state.clubStore} userStore={this.state.userStore}/>
                            <PointInterestBanner />
                        </ListLayout.Filter>
                        <ListLayout.List>
                            {this.noResults() ?
                                <Alert bsStyle="warning"><I18n code="club.empty.result"/></Alert> : ""
                            }
                            {this.state.clubStore.loading ?
                                <Loading/>
                                : ""
                            }
                            {!this.state.clubStore.loading ? <ClubList clubs={this.state.clubStore.clubs}
                                                                       searchParams={this.state.clubStore.searchParams}/> : ""
                            }
                        </ListLayout.List>
                    </ListLayout.Wrapper>
                </Grid>
            </div>)
    }
};
const ClubList = ({clubs, searchParams}) => {
    function searchAllClubs() {
        clubActions.searchClubs(searchParams)
    }
    return <React.Fragment>
        {
            clubs.map(club => {
                return (<ClubItem key={club.id} club={club}/>)
            })
        }
        {
            clubs.length < 20 ? <React.Fragment>
                <hr/>
                <p><Button onClick={searchAllClubs}><I18n code="tournament.button.show.more"/></Button></p>
            </React.Fragment> : null
        }
    </React.Fragment>
}
class ClubFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.openClubCreateModal = this.openClubCreateModal.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);

        this.refClubCreateModal = React.createRef();
        this.state = {}
    }
    handleGroupPlaceChange(mainGroupPlaceId, regionGroupPlaceId) {
        clubActions.showProgress();
        this.props.clubStore.searchParams.mainGroupPlaceID = mainGroupPlaceId;
        this.props.clubStore.searchParams.regionGroupPlaceID = regionGroupPlaceId;
        clubActions.searchClubs(this.props.clubStore.searchParams);
    }
    openClubCreateModal() {
        if (this.props.userStore.user !== null) {
            if (!Utility.hasWriteLock(this.props.userStore.user, false)) {
                this.refClubCreateModal.current.open();
            }
        } else {
            UserActions.showLoginModal();
        }
    }
    handleSearchTextChange(searchText) {
        const newSearchParams = this.props.clubStore.searchParams;
        newSearchParams.searchText = searchText;
        clubActions.showProgress();
        clubActions.searchClubs(newSearchParams);
    }
    render() {
        const that = this;
        return (
            <div>
                <Row>
                    <Col xs={12}>
                        <h4><strong><I18n code="club.button.search"/></strong></h4>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={12}>
                        <DelayedTextField defaultValue={this.props.clubStore.searchParams.searchText}
                                          placeholder={messages.get("parcours.button.search.placeholder")}
                                          onChange={this.handleSearchTextChange}/>
                    </Col>
                    <Col xsHidden  sm={6} md={6} lgHidden>
                        <Button block bsStyle="primary" onClick={this.openClubCreateModal}>
                            <FontAwesome icon="plus"/><I18n code="club.button.create"/>
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={6} md={6} lg={12}>
                        <h4><I18n code="tournament.filter.region"/></h4>
                        <GroupPlaces handleChange={this.handleGroupPlaceChange}/>
                        <br/>
                    </Col>
                    <Col xs={12}  smHidden mdHidden lg={12}>
                        <Button block bsSize="large" bsStyle="primary" onClick={this.openClubCreateModal}>
                            <FontAwesome icon="plus"/><I18n code="club.button.create"/>
                        </Button>
                    </Col>
                    <ClubCreateModal ref={this.refClubCreateModal} user={this.props.userStore.user}/>
                </Row>
                <br/>
            </div>)
    }
}
ClubFilter.propTypes = {
    clubStore:PropTypes.object.isRequired,
    userStore:PropTypes.object.isRequired
};
const ClubItem = ({club}) => {
    function highlight(club) {
        return (club.hasManagementID());
    }
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <h4>
                            <ClubLink club={club}
                                      label={highlight(club) ? <b>{club.getName()}</b> : club.getName()}
                            />
                        </h4>
                    </Col>
                    <Col xs={12}>
                        {club.getZip()} {club.getPlace()}
                    </Col>
                </Row>
            </Panel.Body>
        </Panel>)
};
ClubItem.propTypes = {
    club: PropTypes.object.isRequired
};

module.exports = ClubPage;




