var alt = require("AltInit");
var actions = require("actions/LicenseActions");

class LicenseStore {
    constructor() {
        this.displayName = "LicenseStore";
        this.bowUnions = null;
        this.bowTypes = null;
        this.ageTypes = null;
        this.loading = true;
        this.showModal = false;
        this.searchParams = {
            text: ""
        };
        this.bindListeners({
            updateBowUnion: actions.updateBowUnion,
            updateBowTypes: actions.updateBowTypes,
            updateAgeTypes: actions.updateAgeTypes,
            updateShowModal: actions.showModal
        });
    }

    updateBowUnion(pBowUnions) {
        this.bowUnions = pBowUnions;
        this.checkLoadingFinished();
    }
    updateBowTypes(pBowTypes) {
        this.bowTypes = pBowTypes;
        this.checkLoadingFinished();
    }
    updateAgeTypes(pAgeTypes) {
        this.ageTypes = pAgeTypes;
        this.checkLoadingFinished();
    }
    updateShowModal(show) {
        this.showModal = show;
    }
    checkLoadingFinished() {
        if (this.bowUnions != null && this.bowTypes != null && this.ageTypes != null) {
            this.loading = false;
        }
    }
}
module.exports = alt.createStore(LicenseStore);
