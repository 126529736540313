const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Image, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    SubTitle = require("components/widgets/SubTitle"),
    languageStore = require("stores/LanguageStore");

class JoinTournament extends React.Component {
    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.state = {
            languageStore: languageStore.getState()
        }
    }
    componentDidMount() {
        languageStore.listen(this.handleLanguageChange);
    }
    componentWillUnmount() {
        languageStore.unlisten(this.handleLanguageChange);
    }
    handleLanguageChange(state) {
        this.setState({languageStore: state});
    }
    render() {
        const {imageDirPrefix, imageDeviceType} = this.state.languageStore;
        const imgDirDevice = imageDirPrefix + "/img/tournament/" + (imageDeviceType === "I" ? "ios_" : "");
        return (
            <div>
                <br/><Alert bsStyle="warning">
                    <I18n code="skillboard.jointournament.info"/>
                </Alert>
                <JoinTournamentRow imageDirPrefix={imgDirDevice}
                    title={<I18n code="skillboard.jointournament.join.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.jointournament.join.left"/>}
                                   contentRight={<I18nHtml code="skillboard.jointournament.join.right"/>}
                    content={<I18nHtml code="skillboard.jointournament.join.content"/>}
                    leftImage={"tournament_join.png"}
                    rightImage={"tournament_join_start.png"}/>
                <hr/>
                <JoinTournamentRow imageDirPrefix={imgDirDevice}
                    title={<I18n code="skillboard.jointournament.enter.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.jointournament.enter.left"/>}
                                   contentRight={<I18nHtml code="skillboard.jointournament.enter.right"/>}
                    leftImage={"tournament_points.png"}
                    rightImage={"tournament_result.png"}/>
                <hr/>
                <JoinTournamentRow imageDirPrefix={imgDirDevice}
                    title={<I18n code="skillboard.jointournament.commit.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.jointournament.commit.left"/>}
                                   contentRight={null}
                    leftImage={"tournament_confirm.png"}
                />
            </div >

        )
    }
};
const JoinTournamentRow = ({title, contentLeft, contentRight, leftImage, rightImage, imageDirPrefix}) => {
    return (<Row>
        <SubTitle>{title}</SubTitle>

        <Col sm={3}>
            <Image responsive src={imageDirPrefix+ leftImage}/>
        </Col>
        <Col sm={6} xsHidden>
            {contentLeft}<hr/>{contentRight}
        </Col>
        <Col xs={12} smHidden mdHidden lgHidden>
            {contentLeft}<hr/>
        </Col>
        <Col sm={3}>
            {rightImage ? <Image responsive src={imageDirPrefix + rightImage}/> : null}
        </Col>
        <Col xs={12} smHidden mdHidden lgHidden>
            {contentRight}
        </Col>
    </Row>)
};
module.exports = JoinTournament;
