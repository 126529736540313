var Parse = require("ParseInit");

var User = Parse.Object.extend("_User", {
    col: {
        ACL: "ACL",
        authData: "authData",
        createdAt: "createdAt",
        email: "email",
        emailVerified: "emailVerified",
        lockLevel: "lockLevel",
        lockMessage: "lockMessage",
        newsletter: "newsletter",
        objectId: "objectId",
        password: "password",
        tournamentDetailID: "tournamentDetailID",
        updatedAt: "updatedAt",
        trlLang: "trlLang",
        userDetailID: "userDetailID",
        privacyAccDate: "privacyAccDate",
        userAbos: "userAboIDs",//userAbo Pointer array
        username: "username"
    },
    isRealUser() {
        return this.getUsername() !== "usr_read_only"
    },
    getCreatedDate() {
        var startDate = this.get("createdAt");
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAuthData() {
        return this.get(this.col.authData)
    },
    setAuthData(authData) {
        return this.set(this.col.authData, authData)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getEmail() {
        return this.get(this.col.email)
    },
    getEmailVerified() {
        return this.get(this.col.emailVerified)
    },
    setEmailVerified(emailVerified) {
        return this.set(this.col.emailVerified, emailVerified)
    },
    hasWriteLock() {
        return this.getLockLevel() >= 3 || this.getEmailVerified() !== true;
    },
    getLockLevel() {
        return this.get(this.col.lockLevel)
    },
    setLockLevel(lockLevel) {
        return this.set(this.col.lockLevel, lockLevel)
    },
    getLockMessage() {
        return this.get(this.col.lockMessage)
    },
    setLockMessage(lockMessage) {
        return this.set(this.col.lockMessage, lockMessage)
    },
    getNewsletter() {
        return this.get(this.col.newsletter)
    },
    setNewsletter(newsletter) {
        return this.set(this.col.newsletter, newsletter)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPassword() {
        return this.get(this.col.password)
    },
    setPassword(password) {
        return this.set(this.col.password, password)
    },
    getTournamentDetailID() {
        return this.get(this.col.tournamentDetailID)
    },
    setTournamentDetailID(tournamentDetailID) {
        return this.set(this.col.tournamentDetailID, tournamentDetailID)
    },
    getTranslatorLanguages() {
        return this.get(this.col.trlLang);
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getUserDetailID() {
        return this.get(this.col.userDetailID)
    },
    setUserDetailID(userDetailID) {
        return this.set(this.col.userDetailID, userDetailID)
    },
    getUsername() {
        return this.get(this.col.username)
    },
    setUsername(username) {
        return this.set(this.col.username, username)
    },
    getUserAbos() {
        return this.get(this.col.userAbos);
    },
    setUserAbos(userAbos) {
        return this.set(this.col.userAbos, userAbos);
    }
});

module.exports = User;
