const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, ControlLabel} = require("react-bootstrap");
const messages = require("i18n/messages");

const _tuStatusList = ["R", "A", "S", "P", "F", "W", "X" , "D"];
// const _tuStatusIconList = ["R", "A", "&#xe029;", "P", "F", "X" , "&#xe020;"];

const TournamentUserStatusSelect = ({defaultValue, preSelect, selectCallback}) =>{
    const changeOption = (event) => {
        selectCallback(event.target.value)
    };
    return (<FormGroup>
        <ControlLabel>{messages.get("tournament.table.column.status")}</ControlLabel>
        <FormControl componentClass="select" placeholder="select"
                     defaultValue={defaultValue}
                     onChange={changeOption}>
            {
                preSelect == false ? <option value={null}>{messages.get("modal.license.choose")}</option> : null
            }
            {
                _tuStatusList.map(status => {
                    // let icon = "fas fa-" + messages.get("tournament.mmtuser.icon." + status);
                    return <option key={"tusStat" + status} value={status}>
                            {messages.get("tournament.mmtuser.tooltip." + status)}
                        </option>
                })
            }
        </FormControl>
    </FormGroup>);
};
TournamentUserStatusSelect.propTypes = {
    defaultValue: PropTypes.string,
    preSelect: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentUserStatusSelect;
