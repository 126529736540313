const React = require("react"),
    PropTypes = require('prop-types');
const {Table} = require("react-bootstrap");

const DropDownParseObject = require("components/form/DropDownParseObject");
const  messages = require("i18n/messages");

const TournamentUserDetailManagePanel = ({tournamentUser, tournament, updatingUser, setTournamentUserConfig}) => {
    function handleTConfigBowChange(tConfigBow) {
        if (setTournamentUserConfig) {
            setTournamentUserConfig(null, tConfigBow)
        }
    }
    function handleTConfigAgeChange(tConfigAge) {
        if (setTournamentUserConfig) {
            setTournamentUserConfig(tConfigAge, null)
        }
    }
    return (<Table style={{margin: "0"}}>
            <tbody>
            <tr>
                <th style={{borderTop:"0"}}>{messages.get("tournament.table.column.name")}</th>
                <td style={{borderTop:"0"}}>{tournamentUser.getFullName() + "   #" + tournamentUser.getLicenseNumber()}</td>
            </tr>
            {tournamentUser.getUnion() ?
                <tr>
                    <th>{messages.get("tournament.register.union")}</th>
                    <td>{tournamentUser.getUnion()}</td>
                </tr>
                : null
            }
            <tr>
                <th>{messages.get("tournament.class.bow")}</th>
                <td><DropDownParseObject default={tournamentUser.getTournamentConfigBow()}
                                         disabled={updatingUser}
                                         onAfterChange={handleTConfigBowChange}
                                         objectList={tournament.getTournamentConfigBow()}/>
                </td>
            </tr>
            <tr>
                <th>{messages.get("tournament.class.age")}</th>
                <td><DropDownParseObject default={tournamentUser.getTournamentConfigAge()}
                                         disabled={updatingUser}
                                         onAfterChange={handleTConfigAgeChange}
                                         objectList={tournament.getTournamentConfigAge()}/>
                </td>
            </tr>
            {
                tournamentUser.getTournamentGroupID() ? <tr>
                    <th>{messages.get("Frag_Prep_startposition")}</th>
                    <td>{tournamentUser.getTournamentGroupID().getStartPosition() + " (" + tournamentUser.getTournamentGroupID().getName()+ ")" }</td>
                </tr> : null
            }
            </tbody>
        </Table>);
};
TournamentUserDetailManagePanel.propTypes = {
    tournament: PropTypes.object.isRequired,
    tournamentUser: PropTypes.object.isRequired,
    updatingUser: PropTypes.bool.isRequired,
    setTournamentUserConfig: PropTypes.func
};
module.exports = TournamentUserDetailManagePanel;