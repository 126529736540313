const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel} = require("react-bootstrap");

const StarRating = require("components/widgets/StarRating");

const ParcoursLink = require("components/parcours/ParcoursLink"),
    ParcoursActionButtons = require("components/parcours/ParcoursActionButtons");

const ParcoursHomeItem = ({parcours}) => {
    return (
        <Panel>
            <Panel.Body>
                <Row>
                    <Col xs={12} md={9}>
                        <h4>
                            <ParcoursLink parcours={parcours} label={parcours.buildNames()}/>
                        </h4>
                    </Col>
                    <Col xs={12} md={3}>
                        {
                            parcours.getRatingAv() != null ? <h4>
                                <StarRating rating={parcours.getRatingAv()}/>
                            </h4> : null
                        }
                    </Col>
                </Row>
                <hr/>
                <ParcoursActionButtons parcours={parcours} />
            </Panel.Body>
        </Panel>)
};
ParcoursHomeItem.propTypes = {
    parcours: PropTypes.object.isRequired,
    userFeatures: PropTypes.object,
};
module.exports = ParcoursHomeItem;
