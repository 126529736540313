var alt = require("AltInit");

var layoutActions = require("actions/LayoutActions");

class LayoutStore {
    constructor() {
        this.displayType = null;
        this.bindListeners({
            displayTypeChanged: layoutActions.displayTypeChanged
        });
    }

    displayTypeChanged(displayType) {
        this.displayType = displayType;
        this.emitChange();
    }
}

module.exports = alt.createStore(LayoutStore);
