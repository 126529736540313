const Parse = require("ParseInit");

class ParseBaseDao {

    isUserAdmin() {
        return Parse.Cloud.run('isAdmin');
    }
    hasRole(user, roleID) {
        return Parse.Cloud.run('hasRole', {userID: user.id, roleID: roleID});
    }

    addRole(user, roleID) {
        return Parse.Cloud.run('addRole', {userID: user.id, roleID: roleID});
    }

    removeRole(user, roleID) {
        return Parse.Cloud.run('removeRole', {userID: user.id, roleID: roleID});
    }

    deleteObject(object) {
        object.set("status", "D")
        return object.save(null)
    };

    saveObject(object) {
        return object.save(null)
    };

    fetchObject(object) {
        return object.fetch()
    };

    deleteObject(object) {
        object.set("status","D");
        return object.save(null)
    };

    deleteAll(objectList) {
        objectList.forEach(object => {
            object.set("status","D");
        });
        return Parse.Object.saveAll(objectList);
    }

    saveAll(objectList) {
        return Parse.Object.saveAll(objectList);
    }

    fetchAll(objectList) {
        return Parse.Object.fetchAll(objectList);
    }

    getParseConfig() {
        return Parse.Config.get();
    }

    queryMoreEntryLists(query,notify, callbackError, callbackSuccess) {
        const that = this;
        let runner = 1;
        let resultList = [];
        let totalEntries = 0;
        const listCallback = function(statList) {
            runner++;
            let length = statList.length;
            if (length > 0) {
                console.log("Found " + length + " entries: #"+ runner);
                resultList = resultList.concat(statList);
                totalEntries += length;
                if (length === 1000) {
                    // need to query again
                    if (notify) {
                        notify("Query for the next entries: #" + runner);
                    }
                    that.queryListSteps(query, 1000, totalEntries, listCallback, callbackError)
                } else {
                    // query full list finished
                    if (notify) {
                        notify("Query finished with " + totalEntries + " entries ");
                    }
                    callbackSuccess(resultList);
                }
            } else {
                // no result found
                if (notify) {
                    notify("Query found no entries");
                }
                callbackSuccess(resultList);
            }
        };
        if (notify) {
            notify("Query for entries: #" + runner);
        }
        this.queryListSteps(query, 1000, totalEntries, listCallback, callbackError)
    }

    queryListSteps(query, limit, skip, callback, callbackError) {
        query.limit(limit);
        query.skip(skip);
        query.find()
            .then(statList => {
                callback(statList);
            })
            .catch(error => {
                if (callbackError) {
                    error.trace="queryListStep Limit:" + limit + " ,skip: " + skip;
                    callbackError(error);
                }
            })
    }

}
module.exports = new ParseBaseDao();
