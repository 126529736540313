const React = require("react"),
    PropTypes = require('prop-types');
const {Grid, Alert} = require("react-bootstrap");
var I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

var userStore = require("stores/UserStore"),
    eventStore = require("stores/EventStore"),
    eventActions = require("actions/EventActions"),
    notificationActions = require("components/notification/NotificationActions");

class TabInformation extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleEventChange = this.handleEventChange.bind(this);

        this.state = {
            userStore: userStore.getState(),
            eventStore: eventStore.getState(),
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        eventStore.listen(this.handleEventChange);
        notificationActions.reset.defer();
        eventActions.reset.defer();
        //eventActions.queryTransactions(this.state.userStore.user, this.props.year);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        eventStore.unlisten(this.handleEventChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleEventChange(state) {
        this.setState({eventStore: state});
    }
    render() {
        if (this.state.eventStore.loading === true || this.state.eventStore.clubEvents == null) {
            // TODO change to Loading
            return (<Grid><Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert></Grid>)
        } else if (this.state.eventStore.loading === false && this.state.eventStore.clubEvents.length <= 0){
            return (<Grid><Alert bsStyle="warning"><I18n code="parcours.ranking.empty"/></Alert></Grid>);
        } else if (this.state.eventStore.loading === false) {
            return (<Grid></Grid>);
        } else {
            console.log("WTF - never should be here");
            return null;
        }
    }
}
module.exports = TabInformation;
