const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Alert, Button, ButtonGroup, Dropdown, Well, MenuItem, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ImageGalleryTeaser = require("components/widgets/ImageGalleryTeaser"),
    ParcoursAllRatingsModal = require("components/modals/ParcoursAllRatingsModal"),
    ErrorBoundary = require("components/widgets/ErrorBoundary"),
    StarRating = require("components/widgets/StarRating"),
    GroupPlaces = require("components/widgets/GroupPlaces");
    ImageBanner = require("components/widgets/ImageBanner"),
    SubmitButton = require("components/form/SubmitButton"),
    ValidInput = require("components/form/ValidInput");

const {GroupPlace} = require("parse/_Domain");

const Utility = require("util/Utility");

const ParcoursLoader = require("components/parcours/ParcoursLoader"),
    ParcoursTypeGroup = require("components/parcours/ParcoursTypeGroup");
const messages = require("i18n/messages");

const userActions = require("actions/UserActions"),
    parcoursActions = require("actions/ParcoursActions");


const ParcoursAdminPage = ({onlineID}) => {
    return <ParcoursLoader onlineID={onlineID} rating={false} editCheck={"F"} loginMandatory={true}>
        <ParcoursAdminDetails />
    </ParcoursLoader>
};
ParcoursAdminPage.propTypes = {
    onlineID: PropTypes.string.isRequired
};
class ParcoursAdminDetails extends React.Component {
    constructor(props) {
        super(props);
        this.handleGroupPlaceChange = this.handleGroupPlaceChange.bind(this);
        this.updateParcoursWithGroupPlace = this.updateParcoursWithGroupPlace.bind(this);
        this.handleCountEvent = this.handleCountEvent.bind(this);
        this.setTypeTarget = this.setTypeTarget.bind(this);
        this.state = {
            mainGroupPlace: null,
            regionGroupPlace: null,
            evAmount: null,
        }
    }
    componentDidMount() {
        document.title = this.props.parcours.buildNames();
        userActions.getIsAdmin.defer();
        this.handleCountEvent();
    }
    componentWillUnmount() {
        parcoursActions.resetParcours.defer();
    }
    handleCountEvent() {
        parcoursActions.countEvents.defer(this.props.parcours.getOnlineID(), callbackAmount => {
            this.setState({evAmount: callbackAmount})
        })
    }
    handleGroupPlaceChange(mainGroupPlace, regionGroupPlace) {
        let mainGroupPlaceObj = new GroupPlace();
        mainGroupPlaceObj.id = mainGroupPlace;
        let regionGroupPlaceObj = new GroupPlace();
        regionGroupPlaceObj.id = regionGroupPlace;
        this.setState({
            mainGroupPlace: mainGroupPlaceObj,
            regionGroupPlace: regionGroupPlaceObj
        });
    }
    updateParcoursWithGroupPlace() {
        const {parcours} = this.props;
        const {mainGroupPlace, regionGroupPlace} = this.state;
        const owner = parcours.getParcoursOwnerID();
        owner.setMainGroupPlaceID(mainGroupPlace)
        owner.setGroupPlaceID(regionGroupPlace)
        parcours.setMainGroupPlaceID(mainGroupPlace)
        parcours.setGroupPlaceID(regionGroupPlace)
        parcoursActions.saveParcours(parcours);
        this.setState({
            mainGroupPlace: null, regionGroupPlace: null
        });
    }
    setTypeTarget(type) {
        const {parcours} = this.props;
        parcours.setType(type);
        parcoursActions.saveParcours(parcours);
    }
    render() {
        const {parcours, user, showEventLink} = this.props;
        const {evAmount, regionGroupPlace} = this.state;
        const owner = parcours.getParcoursOwnerID();
        if (!showEventLink) {
            return <Alert bsStyle="danger">Du kommst da ned rein. Nur für Admins!</Alert>;
        }
        return (
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <PageHeader>ADMIN AREA: {parcours.buildNames()}</PageHeader>
                        <p>{parcours.getZipPlace()}</p>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col sm={4}>
                        <h3>Change parcours GroupPlace</h3>
                        <GroupPlaces handleChange={this.handleGroupPlaceChange}
                                     mainGroupPlace={owner.getMainGroupPlaceID()}
                                     regionGroupPlace={owner.getGroupPlaceID()}
                                     bsStyle={"info"}/>
                        <br/>
                        {
                            regionGroupPlace ? <Button bsStyle="primary"
                                                       onClick={this.updateParcoursWithGroupPlace}>Update Parcours</Button> : null
                        }
                    </Col>
                    <Col sm={4}>
                        <h3>Event Count: {evAmount}</h3>
                        <Button bsStyle="danger" onClick={() => parcoursActions.deleteParcours(parcours)}>Delete Parcours</Button>
                    </Col>
                    <Col sm={4}>
                        <ParcoursRatingElement parcours={parcours} user={user}/>
                    </Col>

                </Row>
                <Row>
                    <Col sm={6}>
                        <h3>Parcours-Type</h3>
                        <ParcoursTypeGroup parcoursType={parcours.getType()} setTypeTarget={this.setTypeTarget}/>
                    </Col>
                </Row>
                <ErrorBoundary>
                    <ImageGalleryTeaser parcours={parcours} user={user} adminPage={true}/>
                </ErrorBoundary>
            </React.Fragment>)
    }
}
ParcoursAdminDetails.propTypes = {
    parcours:PropTypes.object.isRequired,
    ownRating:PropTypes.object,
    user:PropTypes.object
};
class ParcoursRatingElement extends React.Component {
    constructor(props) {
        super(props);
        this.refParcoursAllRatingsModal = React.createRef();
        this.onClickAllRatings = this.onClickAllRatings.bind(this);
        this.state = {}
    }
    onClickAllRatings() {
        this.refParcoursAllRatingsModal.current.open();
    }
    render() {
        const {parcours, user} = this.props;
        let ratingAv = null;
        if (parcours.getRatingAv() != null) {
            ratingAv = parcours.getRatingAv().toFixed(1);
        }
        return (
            <div>
                <h3>TODO Show/Delete Rating</h3>
                {
                    ratingAv != null ?
                        <div><h3>{ratingAv} <StarRating rating={parseInt(ratingAv)}/></h3>
                            <FontAwesome icon="user"/>{parcours.getRatingCo()}&nbsp;&nbsp;&nbsp;
                            <Button onClick={this.onClickAllRatings}>
                                {messages.get("parcours.details.area.allratings")}&nbsp;<FontAwesome icon="caret-down"/>
                            </Button>
                            <ParcoursAllRatingsModal ref={this.refParcoursAllRatingsModal}/>
                            <br/><br/>
                        </div> : null
                }
            </div>)
    }
}
ParcoursRatingElement.propTypes = {
    parcours:PropTypes.object.isRequired,
    user:PropTypes.object
};
module.exports = ParcoursAdminPage;
