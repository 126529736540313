const ParseListUtility = require("util/ParseListUtility");

function TournamentUserGroupingDic(params) {
    this.params = params
    this.primeGrouped = {};
    this.primeRankedGrouped = {};
    this.tUserGroups = [];
    this.addTournamentUser = function (tournamentUser) {
        const bowGroup = tournamentUser.getTournamentConfigBow();
        const key = this.generateTUserDicKey(tournamentUser)
        // check for bow
        this.addUserToGroup(bowGroup.id, key, tournamentUser);
    };
    this.addRankingUser = function (tournamentUser) {
        const bowGroup = tournamentUser.getTournamentConfigBow();
        const key = this.generateTUserDicKey(tournamentUser)
        this.addUserToRankingGroup(bowGroup.id, key, tournamentUser, this.compareFctTUserResultPosition);
    };
    this.suggestGroups = function () {
        if (this.params.ranking) {
            return this.suggestRankingGroups()
        } else {
            return this.suggestUnionGroups()
        }

    }
    this.suggestUnionGroups = function () {
        this.tUserGroups = [];
        const maxGroupSize = this.params.maxGroupSize
        const maxTUserUnion = this.params.maxTUserUnion
        let tugCounter = 1;
        // map through primeGroup (bow classes)
        Object.keys(this.primeGrouped).map(primeKey => {
            const primeGroup = this.primeGrouped[primeKey]
            let grouping = true
            let tugList = []
            while (grouping) {
                let remainTUsers = 0
                Object.keys(primeGroup).map(key => {
                    let tuList = primeGroup[key]
                    let tLL = tuList.length
                    if (tLL > 0) {
                        let freeSlots = Math.min(maxGroupSize - tugList.length, maxTUserUnion)
                        // console.log("freeSlots", freeSlots)
                        if (freeSlots <= 0) {
                            this.tUserGroups.push({
                                id: tugCounter,
                                tuList: [...tugList]
                            })
                            // console.log("Group Added", this.tUserGroups)
                            tugCounter++;
                            tugList = []
                        }
                        for (let i = 0; i < Math.min(freeSlots, tLL); i++) {
                            let tUser = tuList.pop();
                            // console.log("PopTuser", tUser.getName())
                            tugList.push(tUser)
                        }
                    }
                    // console.log("Remain", tuList)
                    remainTUsers += tuList.length
                })
                // console.log("remainTUsers", remainTUsers)
                grouping = remainTUsers !== 0
                if (!grouping) {
                    this.tUserGroups.push({
                        id: tugCounter,
                        tuList: [...tugList]
                    })
                    // console.log("Last Group Added", this.tUserGroups)
                    tugCounter++;
                    tugList = []
                }
            }
        })
        return this.tUserGroups
    };
    this.suggestRankingGroups=function () {
        this.tUserGroups = [];
        const maxGroupSize = this.params.maxGroupSize
        const rankingTUser = this.params.rankingTUser
        let tugCounter = 1;
        // map through primeGroup (bow classes)
        Object.keys(this.primeRankedGrouped).map(primeKey => {
            const primeRankGroup = this.primeRankedGrouped[primeKey]
            const primeTuGroup = this.primeGrouped[primeKey]
            if (primeTuGroup != null && primeRankGroup != null) {
                Object.keys(primeRankGroup).map(key => {
                    let tuRankList = primeRankGroup[key]
                    let tuList = primeTuGroup[key]
                    if (tuRankList != null && tuList != null && tuList.length > 0) {
                        let tLL = tuRankList.length
                        let maxGroupingTries = rankingTUser > 0 ? Math.min(rankingTUser, tLL) : tLL
                        // iterate to rank group and try to find fitting registration in tuGroup
                        let tugList = []
                        let groupingCounter = 0
                        while(groupingCounter < maxGroupingTries) {
                            let freeSlots = maxGroupSize - tugList.length
                            if (freeSlots <= 0) {
                                this.tUserGroups.push({
                                    id: tugCounter,
                                    tuList: [...tugList]
                                })
                                // console.log("Group Added", this.tUserGroups)
                                tugCounter++;
                                tugList = []
                            }
                            // find tUser to ranked one
                            let tRankingUser = tuRankList.pop();
                            if (tRankingUser != null) {
                                // console.log("PopTUser", tRankingUser.getName())
                                let tUser = this.findTUserWithCupNumber(tRankingUser, tuList)
                                if (tUser != null) {
                                    // console.log("PushTUser", tUser.getName())
                                    tugList.push(tUser)
                                    groupingCounter++;
                                }
                            } else {
                                // no ranking user any more -> break loop
                                groupingCounter = maxGroupingTries
                            }
                        } // grouping loop
                        if (tugList.length > 0) {
                            this.tUserGroups.push({
                                id: tugCounter,
                                tuList: [...tugList]
                            })
                            // console.log("Last Group Added", this.tUserGroups)
                            tugCounter++;
                        }
                    }
                })
            }
        })
        return this.tUserGroups
    }
    this.findTUserWithCupNumber = function (tRankUser, tuList) {
        const length = tuList.length;
        for (let i=0; i < length; i++) {
            if (tuList[i].getLicenseNumber() === tRankUser.getLicenseNumber()) {
                return tuList.splice(i,1)[0];
            }
        }
        return null;
    }
    this.generateTUserDicKey = function (tournamentUser) {
        const ageGroup = tournamentUser.getTournamentConfigAge();
        const tuSex = ageGroup.hasNoSexSplit() || this.params.mixSexes ? "x" : tournamentUser.getSex();
        return this.generateDicKey(this.params.mixAgeGroups ? null : ageGroup, tuSex, this.params.separateUnions ? tournamentUser.getSortUnion() : "");
    }
    this.generateDicKey = function(secConfig, tuSex, union) {
        let sec = "xx";
        if (secConfig != null) {
            sec = secConfig.id;
        }
        return "z" + tuSex + "z" + sec + "z" + union;
    };
    this.addUserToGroup = function(primeKey, key, tournamentUser) {
        // build a dictionary with one prime group for each primeKey (bow class)
        const primeGroup = this.primeGrouped[primeKey]
        if (primeGroup) {
            // for each key there is a list in the primeGroup
            const tuList = primeGroup[key];
            if (tuList) {
                tuList.push(tournamentUser)
            } else {
                primeGroup[key] = [tournamentUser];
            }
        } else {
            // no entry in primeGroup - create it with dic for second key
            const newPrimeGroup = {};
            newPrimeGroup[key] = [tournamentUser]
            this.primeGrouped[primeKey] = newPrimeGroup
        }
    };
    this.addUserToRankingGroup = function (primeKey, key, tournamentUser, compareFct) {
        const primeGroup = this.primeRankedGrouped[primeKey]
        if (primeGroup) {
            // for each key there is a list in the primeGroup
            const tuList = primeGroup[key];
            if (tuList) {
                // add sorted to List
                ParseListUtility.pushObjSortedASC(tuList, tournamentUser, compareFct);
            } else {
                primeGroup[key] = [tournamentUser];
            }
        } else {
            // no entry in primeGroup - create it with dic for second key
            const newPrimeGroup = {};
            newPrimeGroup[key] = [tournamentUser]
            this.primeRankedGrouped[primeKey] = newPrimeGroup
        }
    };
    this.compareFctTUserResultPosition = function(listEntry, newEntry) {
        return newEntry.get("resultPosition") > listEntry.get("resultPosition");
    };
}

module.exports = TournamentUserGroupingDic;