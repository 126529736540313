const React = require("react"),
    PropTypes = require('prop-types');
const { ControlLabel, HelpBlock, InputGroup, FormGroup, FormControl, Button} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const messages = require("i18n/messages");

/**
 * valid={maxLength: 500, check:['isRequired', 'isUrl', 'isNumber','isMail',point4comma]}
 * validateNow=true|false
 */
class ValidInput extends React.Component {
    constructor(props) {
        super(props);
        this.refInput = React.createRef();

        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.validate = this.validate.bind(this);
        this.isValid = this.isValid.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);

        let fixedValue = false;
        if (this.props.constDefault) {
            // fixed value if default is set
            fixedValue = this.props.default;
        }
        this.state = {
            value: this.props.default || "",
            hasChanged: false,
            fixedValue: fixedValue,
            style: null,
            help: null
        };
    }


    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    isValid() {
        return this.state.help == null;
    }
    validate(value) {
        if (!this.props.valid) {
            return {
                value: value,
                help: null,
                style: null
            };
        }
        let help = null;
        let style = "success";
        if (this.props.valid.maxLength) {
            if (value.length > this.props.valid.maxLength) {
                help = "max " + this.props.valid.maxLength;
            }
        }
        if (this.props.valid.check) {
            for (let i = 0; i < this.props.valid.check.length; i++) {
                switch (this.props.valid.check[i]) {
                    case 'isNumber':
                        if (isNaN(parseInt(value))) {
                            help = messages.get("form.valid.not.number");
                        }
                        break;
                    case 'posNumber': {
                        if (parseInt(value) < 0 ) {
                            help = messages.get("form.valid.not.negNumber");
                        }
                        break;
                    }
                    case 'greater0': {
                        if (parseInt(value) < 1 ) {
                            help = messages.get("form.valid.not.greater0");
                        }
                        break;
                    }
                    case 'isUrl':
                        if (value.length > 0 && !value.startsWith("http")) {
                            help = messages.get("form.valid.not.url")
                        }
                        break;
                    case 'isMail':
                        const mailReg = /^\S+@\S+\.\S+$/; // checks only @ ATM
                        value = value.replace(/ /g,'');
                        if (!value.match(mailReg)) {
                            help = messages.get("form.valid.not.email");
                        }
                        break;
                    case 'isDate':
                        const dateReg = /^\d{2}\.\d{2}\.\d{4}$/;
                        if (!value.match(dateReg)) {
                            help = messages.get("form.valid.not.date");
                        }
                        break;
                    case 'isTime':
                        const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
                        if (!value.match(timeReg)) {
                            help = messages.get("form.valid.not.time");
                        }
                        break;
                    case 'isPhone':
                        const phoneReg = /^\+?\d+([- .]\d+)*$/;
                        if (!value.match(phoneReg)) {
                            help = messages.get("form.valid.not.phone");
                        }
                        break;
                    case 'isCoordinate':
                        const coordReg = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
                        if (!value.match(coordReg)) {
                            help = messages.get("form.valid.not.coordinates");
                        }
                        break;
                    case 'point4comma':
                        if (typeof(value) == "string" && value.indexOf(",") > -1) {
                            help = messages.get("form.valid.not.point4comma");
                        }
                        break;
                    case 'noSpecSign'://[a-zA-Z0-9]*
                        const specReg = /^[A-zÀ-ÿ0-9._-]*$/;
                        if (!value.match(specReg)) {
                            help = messages.get("form.valid.not.noSpecSign");
                        }
                        break;
                    case 'isRequired':
                        if (value == null || ("" + value).length == 0) {
                            style = "error";
                        }
                        break;
                }
            }
        }
        if (help != null && help.length > 0) {
            style = "error";
        }
        return {
            value: value,
            help: help,
            style: style
        };
    }
    handleChange(e) {
        const value = e.target.value;
        const validationResult = this.validate(value);
        this.setState({
            hasChanged: true,
            value: validationResult.value,
            style: validationResult.style,
            help: validationResult.help
        });
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(validationResult.value);
        }
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    handleSave() {
        const {savePressed} = this.props;
        if (savePressed != null) {
            this.setState({hasChanged: false})
            savePressed(this.state.value, this)

        }
    }
    render() {
        const {hasChanged, help, style, value, fixedValue} = this.state;
        const {savePressed, addonBefore, buttonAfterContent, buttonAfterCallback, buttonAfter} = this.props;
        let validationResult = null;
        if (this.props.validateNow) {
            validationResult = this.validate(value);
        } else {
            validationResult = {
                style: style,
                help: help
            };
        }
        let bsStyleProp = {};
        if (validationResult.style != null && validationResult.style.length > 0) {
            bsStyleProp = {bsStyle: validationResult.style};
        }
        let needInputGroup = savePressed != null;
        let buttonAfterElement = null;
        if (savePressed != null && hasChanged) {
            buttonAfterElement = <Button disabled={help != null} onClick={this.handleSave}><FontAwesome icon="save"/></Button>
        } else {
            if (buttonAfter != null) {
                needInputGroup = true;
                buttonAfterElement = buttonAfter
            }
            if (buttonAfterContent != null && buttonAfterCallback) {
                // instead of buttonAfter -> value get delivered by buttonAfterCallback
                needInputGroup = true;
                buttonAfterElement = <Button onClick={() => buttonAfterCallback(value)}
                            disabled={ validationResult.help != null}>{buttonAfterContent}</Button>
            }
        }
        let addonBeforeElement = null;
        if (addonBefore != null) {
            needInputGroup = true;
            addonBeforeElement = <InputGroup.Addon>{addonBefore}</InputGroup.Addon>
        }
        // display block to give input full width
        let igDisplayStyle = needInputGroup && buttonAfterElement == null && addonBeforeElement == null ? "block" : "table";
        return (
            <FormGroup validationState={validationResult.style}>
                {
                    this.props.label != null ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }

                {
                    needInputGroup ?
                        <InputGroup style={{display: igDisplayStyle}}>
                            {addonBeforeElement}
                            <FormControl ref={this.refInput} type={this.props.type}
                                         autoComplete={this.props.autoComplete}
                                         disabled={this.props.disabled || fixedValue}
                                         componentClass={this.props.componentClass}
                                         value={value}
                                         placeholder={this.props.placeholder}
                                         onChange={this.handleChange}
                                         list={this.props.list}
                                         {...bsStyleProp} />
                            <InputGroup.Button>{buttonAfterElement}</InputGroup.Button>
                        </InputGroup>
                        :
                        <FormControl ref={this.refInput} type={this.props.type}
                                     autoComplete={this.props.autoComplete}
                                     disabled={this.props.disabled || fixedValue}
                                     componentClass={this.props.componentClass}
                                     value={value}
                                     placeholder={this.props.placeholder}
                                     onChange={this.handleChange}
                                     list={this.props.list}
                                     {...bsStyleProp}/>
                }
                {
                    validationResult.help != null ? <HelpBlock>{validationResult.help}</HelpBlock> : ""
                }
            </FormGroup>)
    }
}
ValidInput.propTypes = {
    savePressed: PropTypes.func,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    default: PropTypes.any,
    constDefault: PropTypes.bool,
    onAfterChange: PropTypes.func,
    onChange: PropTypes.func,
    buttonAfter: PropTypes.any,
    buttonAfterContent: PropTypes.any,
    buttonAfterCallback: PropTypes.func,
    addonBefore: PropTypes.any,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    componentClass: PropTypes.string,
    list: PropTypes.array,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    validateNow: PropTypes.any,
    valid: PropTypes.any
};

module.exports = ValidInput;
