var Parse = require("ParseInit");

var TournamentUserGroupLive = Parse.Object.extend("TournamentUserGroupLive", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        creator: "creator",
        objectId: "objectId",

        tournamentID: "tournamentID",
        tournamentGroupID: "tournamentGroupID",
        tournamentRoundID : "tournamentRoundID",
        tournamentUserIDs: "tournamentUserIDs",

        actTargetNumber: "actTargetNumber",
        actTargetAmount: "actTargetAmount",
        actResultEditor:"actResultEditor",
        actResult: "actResults",

        status: "status",
        updatedAt: "updatedAt"
    },
    status: {
        present: "A",
        started: "S",
        finished: "F",
        deleted: "D"
    },
    getLabels() {
        let lblArray = [this.getName(), this.getStartPosition()];
        let tUsers = this.getTournamentUserIDs();
        if (tUsers != null) {
            for (let i = 0; i < tUsers.length; i++) {
                lblArray.push(tUsers[i].getFullName());
                lblArray.push(tUsers[i].getUnion());
                lblArray.push(tUsers[i].getLicenseNumber());
            }
        }
        return lblArray.join(" ");
    },
    getSortColumn(colName) {
        return this.get(colName)
    },
    getTournamentUserPoints(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["points"];
            }
        }
        return 0;
    },
    getTournamentUserKillValue(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killValue"];
            }
        }
        return 0;
    },
    getTournamentUserKillCounts(tUserID) {
        var actResult = this.getActResult();
        if (actResult) {
            var userResult = actResult[tUserID];
            if (userResult) {
                return userResult["killCounts"];
            }
        }
        return "";
    },
    getStatusStyle() {
        if (this.isDeleted()) {
            return "danger"
        } else if (this.isDraft() && this.isPresent()) {
            return "warning"
        }
        return "info"
    },
    isStarted() {
        return this.getStatus() === this.status.started;
    },
    isInThisRound(tRound) {
        const tGroupRound = this.getTournamentRoundID();
        if (tGroupRound != null && tRound != null) {
            return tGroupRound.id === tRound.id
        }
        return false;
    },
    isPresent() {
        return this.status.present === this.getStatus();
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isFinished() {
        return this.status.finished === this.getStatus();
    },

    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getTUserAmount() {
        let tUsers = this.getTournamentUserIDs();
        if (tUsers != null) {
            return tUsers.length
        }
        return 0
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    setStatusFinished() {
        this.setStatus(this.status.finished)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getTournamentGroupID() {
        return this.get(this.col.tournamentGroupID)
    },
    getTournamentUserIDs() {
        return this.get(this.col.tournamentUserIDs)
    },
    setTournamentUserIDs(tournamentUserIDs) {
        return this.set(this.col.tournamentUserIDs, tournamentUserIDs)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    getActTargetPosition() {
        return this.get(this.col.actTargetNumber)
    },
    getActTargetNumber() {
        return this.get(this.col.actTargetAmount)
    },
    getActResult() {
        return this.get(this.col.actResult)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TournamentUserGroupLive;
