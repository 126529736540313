const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading"),
    TrainingScatterFilter  = require("components/training/TrainingScatterFilter"),
    TrainingFeatureMissing = require("components/training/TrainingFeatureMissing"),
    TrainingMenu = require("components/training/TrainingMenu");

const userStore = require("stores/UserStore"),
    trainingActions = require("actions/TrainingActions"),
    trainingStore = require("stores/TrainingStore"),
    notificationActions = require("components/notification/NotificationActions");

class TrainingScatterPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleTrainingChange = this.handleTrainingChange.bind(this);
        this.state = {
            userStore: userStore.getState(),
            trainingStore: trainingStore.getState(),
        }
    }
    componentDidMount() {
        document.title = messages.get("header.nav.training");
        userStore.listen(this.handleUserChange);
        trainingStore.listen(this.handleTrainingChange);
        notificationActions.reset();
        trainingActions.queryMyTrainingFilters(this.state.userStore.user, this.props.filterID)
        trainingActions.queryTrainingTypes();
        trainingActions.queryTrainingCountTypes();
        trainingActions.queryMyTrainingTeams(this.state.userStore.user, null);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        trainingStore.unlisten(this.handleTrainingChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleTrainingChange(state) {
        this.setState({trainingStore: state});
    }
    render() {
        const {trainingCountTypes, eventTrainingList, eventTrainingComment, eventTrainingTarget, eventTrainingLoading} = this.state.trainingStore
        return (
            <Grid>
                {this.state.userStore.user == null ?
                    <Row>
                        <Col xs={12}>
                            <Alert bsStyle="danger">
                                <FontAwesome icon="exclamation-circle"/>
                                <I18n code="tournament.register.description.noaccount"/>
                            </Alert>
                        </Col>
                    </Row>
                    :
                    <React.Fragment>
                        {
                            this.state.userStore.userDetailLoading === true || this.state.userStore.userAboLoading?
                                <Loading />
                                :
                                <React.Fragment>
                                    <TrainingMenu user={this.state.userStore.user}
                                                  trainingFilters={this.state.trainingStore.eventTrainingFilters}
                                                  trainingTeams={this.state.trainingStore.trainingTeams}
                                                  trainingTeam={this.state.trainingStore.trainingTeam}/>
                                    <TrainingUserAboCheck userFeatures={this.state.userStore.userFeatures}>
                                        <TrainingScatterFilter user={this.state.userStore.user}
                                                               allowEventTargets={true} filterID={this.props.filterID}
                                                               trainingCountTypes={trainingCountTypes}
                                                               eventTrainingList={eventTrainingList}
                                                               eventTrainingLoading={eventTrainingLoading}
                                                               eventCommentsDic={eventTrainingComment}
                                                               eventTargetsDic={eventTrainingTarget}/>
                                    </TrainingUserAboCheck>
                                </React.Fragment>

                        }
                    </React.Fragment>
                }
            </Grid>

        )
    }
}
const TrainingUserAboCheck = ({userFeatures, children}) => {
    if (userFeatures != null && userFeatures.isTrainingFeatureActive()) {
        return children
    } else {
        return <TrainingFeatureMissing/>
    }
};
TrainingUserAboCheck.propTypes = {
    userFeatures:PropTypes.object.isRequired
};
module.exports = TrainingScatterPage;
