const Parse = require("ParseInit");
const alt = require("AltInit"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages"),
    {ClubUser, User, ParcoursArticle, PaymentTarget, UserDetail, Address, UserBowUnionLic} = require("parse/_Domain");

const parseDao = require("actdao/ParseBaseDao");
const clubDao = require("actdao/ClubDao");
const userDao = require("actdao/UserDao");
const notificationActions = require("components/notification/NotificationActions");
const msgActions = require("actions/MessageActions");
const Utility = require("util/Utility"),
    FileDownloadUtility =  require("util/FileDownloadUtility");
const {BowUnion} = require("../parse/_Domain");


class ClubUserActions {

    fillUserDetail(usrDetail, clubUserDict) {
        console.log("name", usrDetail.getName())
        if (!(usrDetail.getName() != null)) {
            usrDetail.setName(clubUserDict.name);
        }
        if (!(usrDetail.getSurname() != null)) {
            usrDetail.setSurname(clubUserDict.surname);
        }

        if (usrDetail.getSex() !== clubUserDict.sex) {
            usrDetail.setSex(clubUserDict.sex);
        }
        // handle optional birthday
        if (usrDetail.getBirthday() === null && clubUserDict.birthday!= null) {
            // birthday entered
            usrDetail.setBirthday(clubUserDict.birthday);
        }
        return {};
    }
    async createLicense(usrDetail, bowUnion, licClub, licNumber) {
        let usrLicenses = usrDetail.getLicenseIDs();
        if (usrLicenses != null) {
            for (let i = 0; i < usrLicenses.length; i++) {
                if (usrLicenses[i].getBowUnionID().id === bowUnion.id) {
                    // license found - update it
                    console.log("Update License " + licNumber)
                    usrLicenses[i].setLicenseNumber(licNumber.trim());
                    return {};
                }
            }
        }
        let usrLic = new UserBowUnionLic();
        usrLic.setStatus("A");
        usrLic.setBowUnionID(bowUnion);
        usrLic.setLicenseNumber(licNumber.trim());
        if (licClub != null && licClub.length > 0) {
            usrLic.setUnion(licClub.trim())
        }
        // add to detail
        usrDetail.addLicenseID(usrLic)
        await usrDetail.save(null)
        console.log("Saved usrDetail add License", licNumber, usrDetail)
        return {};
    }
    fillAddress(address, clubUserDict) {
        address.setName(clubUserDict.name);
        address.setSurname(clubUserDict.surname);
        if (clubUserDict.street) {
            address.setStreet(clubUserDict.street);
        }
        if (clubUserDict.zip) {
            address.setZip(clubUserDict.zip.toString());
        }
        if (clubUserDict.place) {
            address.setPlace(clubUserDict.place);
        }
        if (clubUserDict.mobil) {
            address.setPhone(clubUserDict.mobil.toString());
        }
        if (clubUserDict.mail) {
            address.setMail(clubUserDict.mail);
        }
        if (clubUserDict.mail2) {
            address.setMail2(clubUserDict.mail2);
        }
        if (clubUserDict.tel) {
            address.setPhone2(clubUserDict.tel.toString());
        }
        return {};
    }
    // https://shancarter.github.io/mr-data-converter/
    // {
    //     "fullname": "Ahamer Thomas",
    //     "street": "Ziegelstadl 24",
    //     "place": "Weissenkirchen",
    //     "zip": "4890",
    //     "entrydate": "01-12-18",
    //     "mail": "t.ahamer@aon.at",
    //     "tel": "",
    //     "mobil": "6642700212",
    //     "birthday": "08-11-70"
    // }
    async handleImportClubUserLicense(club, clubUsers, objectArray) {
        const length = objectArray.length;
        const clubUserList = [];
        for (let i = 0; i < length; i++) {
            let impObject = objectArray[i];
            let valueDict = {};
            let iUser = null;
            if (impObject.number && impObject.number.length > 0) {
                valueDict.cuNumber = impObject.number.trim();
            } else if (impObject.licNumber && impObject.licNumber.length > 0) {
                valueDict.cuNumber = impObject.licNumber.trim(); // clubUser
            }
            if (!this.isAlreadyClubUser(valueDict.cuNumber, clubUsers)) {
                valueDict.surname = impObject.surname.trim(); //userDetail.surname or userDetail.address.surname
                valueDict.name = impObject.name.trim(); //userDetail.name or userDetail.address.name
                if (impObject.sex && impObject.mail.length > 0) {
                    valueDict.sex = impObject.sex; //userDetail.sex
                }
                if (impObject.mail && impObject.mail.length > 0) {
                    valueDict.mail = impObject.mail.toLowerCase().trim(); // user.mail or userDetail.address.mail
                    iUser = await userDao.queryFindUser(impObject.mail)
                    if (iUser) {
                        console.log(impObject.surname + " Found user", iUser, iUser.getUserDetailID())
                    }
                }
                if (impObject.birthday && impObject.birthday.length > 0) {
                    let birthday = Utility.buildDate(impObject.birthday);
                    // add some hours to ensure correct date
                    birthday.setHours(birthday.getHours() + 4);
                    valueDict.birthday = birthday; // userDetail.birthday
                }
                console.log(impObject.surname + " cUser ", valueDict)
                let clubUser = ClubUser.prototype.buildClubMember(iUser, club);
                if (valueDict.cuNumber) {
                    clubUser.setUserNumber(valueDict.cuNumber)
                }
                if (impObject.articleId && impObject.articleId.length > 0) {
                    let article = new ParcoursArticle();
                    article.id = impObject.articleId.trim()
                    clubUser.setArticleID(article)
                }
                let usrDetail = clubUser.getUserDetailID();
                if (usrDetail == null) {
                    console.log(impObject.surname + " Create UserDetail ")
                    usrDetail = new UserDetail();
                    usrDetail.setStatus(UserDetail.prototype.status.A);
                    usrDetail.buildACL(clubUser.getUserID());
                    this.fillUserDetail(usrDetail, valueDict);
                    clubUser.setUserDetailID(usrDetail);
                } else {
                    this.fillUserDetail(usrDetail, valueDict);
                    console.log(impObject.surname + " existed UserDetail ")
                }

                if (impObject.bowUnionId != null && impObject.bowUnionId.length > 0
                    && impObject.licNumber != null && impObject.licNumber.length > 0) {
                    // licClub, licNumber
                    let bowUnion = new BowUnion();
                    bowUnion.id = impObject.bowUnionId.trim()
                    await this.createLicense(usrDetail, bowUnion, impObject.licClub, impObject.licNumber.trim())
                }
                try {
                    await clubUser.save(null)
                    clubUserList.push(clubUser);
                } catch(error) {
                    error.trace="CUA.impCU";
                    console.log(impObject.surname + " save error ", error)
                    notificationActions.parseError(error);
                }
            }
        }
        if (clubUserList.length > 0) {
            notificationActions.info("Created clubUser  - " + clubUserList.length);
        } else {
            notificationActions.warning("Created 0 clubUsers from " + length + " lines");
        }
        return {};
    }
    isAlreadyClubUser(cuNumber, clubUsers) {
        if (cuNumber && clubUsers) {
            // search if clubUser already in clubUsers
            for (let i = 0; i < clubUsers.length; i++) {
                if (cuNumber === clubUsers[i].getUserNumber()) {
                    console.log(clubUsers[i].getSurname() + " Already clubUser");
                    return true;
                }
            }
        }
        return false
    }

    handleImportClubUser(club, objectArray) {
        // TODO - add group importing
        const length = objectArray.length;
        const clubUserList = [];
        for (let i = 0; i < length; i++) {
            let impObject = objectArray[i];
            let valueDict = {};
            if (impObject.fullname && impObject.fullname.length > 0) {
                let fullName = impObject.fullname.split(" ");
                if (fullName.length == 2) {
                    valueDict.surname = fullName[0];
                    valueDict.name = fullName[1];
                } else if (fullName.length == 3) {
                    valueDict.surname = fullName[0];
                    valueDict.name = fullName[1] + " " + fullName[2];
                }
            } else {
                valueDict.surname = impObject.surname;
                valueDict.name = impObject.name;
            }
            valueDict.street = impObject.street;
            valueDict.place = impObject.place;
            valueDict.zip = impObject.zip;
            if (impObject.mail && impObject.mail.length > 0) {
                valueDict.mail = impObject.mail;
            }
            if (impObject.mail2 && impObject.mail2.length > 0) {
                valueDict.mail2 = impObject.mail2;
            }
            if (impObject.tel && impObject.tel.length > 0) {
                valueDict.tel = impObject.tel;
            }
            if (impObject.mobil && impObject.mobil.length > 0) {
                if (impObject.mobil.startsWith("0")) {
                    valueDict.mobil = impObject.mobil;
                } else {
                    valueDict.mobil = "0" + impObject.mobil;
                }
            }
            if (impObject.entrydate && impObject.entrydate.length > 0) {
                let enryDate = Utility.buildDate(impObject.entrydate);
                // add some hours to ensure correct date
                enryDate.setHours(enryDate.getHours() + 4);
                valueDict.entrydate = enryDate;
            }
            if (impObject.birthday && impObject.birthday.length > 0) {
                let birthday = Utility.buildDate(impObject.birthday);
                // add some hours to ensure correct date
                birthday.setHours(birthday.getHours() + 4);
                valueDict.birthday = birthday;
            }
            if (impObject.number && impObject.number.length > 0) {
                // add some hours to ensure correct date
                valueDict.cuNumber = impObject.number;
            }
            if (impObject.iban && impObject.iban.length > 0) {
                valueDict.iban = impObject.iban;
            }
            if (impObject.bic && impObject.bic.length > 0) {
                valueDict.bic = impObject.bic;
            }
            console.log("USER: " + valueDict.name, valueDict);

            let clubUser = ClubUser.prototype.buildClubMember(null, club);
            if (valueDict.entrydate) {
                clubUser.setEntryDate(valueDict.entrydate);
            }
            if (valueDict.cuNumber) {
                clubUser.setUserNumber(valueDict.cuNumber)
            }
            const usrDetail = new UserDetail();
            usrDetail.setStatus(UserDetail.prototype.status.A);
            usrDetail.buildACL(clubUser.getUserID());
            usrDetail.setUnion(club.getName());
            this.fillUserDetail(usrDetail, valueDict);
            // address
            const address = new Address();
            address.setStatus(Address.prototype.status.A);
            address.buildACL(clubUser.getUserID(), "club", null);
            this.fillAddress(address, valueDict);
            usrDetail.setAddressID(address);
            clubUser.setUserDetailID(usrDetail);
            // iban bic
            if (valueDict.iban) {
                const payTarget = new PaymentTarget();
                payTarget.setStatus("A");
                payTarget.setCardBIC(valueDict.bic);
                payTarget.setCardIBAN(valueDict.iban);
                clubUser.setPaymentTargetID(payTarget)
            }

            clubUserList.push(clubUser);

        }
        if (clubUserList.length > 0) {
            notificationActions.info("Create clubUser  - save all " + clubUserList.length);
            parseDao.saveAll(clubUserList)
                .then(uClubUserList => {
                    notificationActions.success("Created " + uClubUserList.length + " clubUsers");
                })
                .catch(error => {
                    error.trace="CUA.impCU";
                    notificationActions.parseError(error);
                });
        } else {
            notificationActions.warning("Created 0 clubUsers from " + length + " lines");
        }
        return {};
    }

    exportAllClubUsers(clubUsers) {
        var csvData = [];
        var header = [];
        header.push("objectId");
        header.push(messages.get("address.userNr"));
        header.push(messages.get("tournament.register.name"));
        header.push(messages.get("tournament.register.surname"));
        header.push(messages.get("tournament.register.email"));
        header.push(messages.get("club.group.modal.role"));
        header.push(messages.get("club.group.modal.functions"));
        header.push(messages.get("club.group.modal.groups"));
        header.push(messages.get("address.entryDate"));
        // address
        header.push(messages.get("address.sex"));
        header.push(messages.get("address.birthday"));
        header.push(messages.get("address.street"));
        header.push(messages.get("address.zip"));
        header.push(messages.get("address.place"));
        header.push(messages.get("address.phone"));
        header.push(messages.get("tournament.register.email") + "2");
        header.push(messages.get("address.phone") + "2");
        // license
        header.push(messages.get("tournament.class.union"));
        header.push(messages.get("tournament.class.bow"));
        header.push(messages.get("tournament.class.age"));
        header.push(messages.get("modal.license"));
        header.push(messages.get("modal.license.validUntil"));
        // address TWO
        header.push(messages.get("address.street")+"_Addr2");
        header.push(messages.get("address.zip")+"_Addr2");
        header.push(messages.get("address.place")+"_Addr2");
        header.push(messages.get("address.phone")+"_Addr2");
        // payment
        header.push("iban");
        header.push("bic");
        csvData.push(header.join(';'));

        clubUsers.map(clubUser => {
            var row = [];
            row.push(clubUser.id);
            row.push(clubUser.getUserNumber());
            row.push(clubUser.getName());
            row.push(clubUser.getSurname());
            row.push(clubUser.getEmail());
            row.push(clubUser.getRole());
            row.push(clubUser.getFunctions());
            row.push(clubUser.getGroups());
            row.push(clubUser.getReadableEntryDate());
            // address
            if (clubUser.getUserDetailID() != null) {
                row.push(clubUser.getUserDetailID().getSex());
                row.push(clubUser.getUserDetailID().getReadableBirthday());
                var address = clubUser.getUserDetailID().getAddressID();
                if (address != null) {
                    row.push(address.getStreet());
                    row.push(address.getZip());
                    row.push(address.getPlace());
                    row.push(address.getPhone());
                    row.push(address.getMail2());
                    row.push(address.getPhone2())
                } else {
                    row.push("");
                    row.push("");
                    row.push("");
                    row.push("");
                    row.push("");
                    row.push("");
                }
                // license
                var licenses = clubUser.getUserDetailID().getLicenseIDs();
                if (licenses != null && licenses.length > 0) {
                    var union = [];
                    var bow = [];
                    var age = [];
                    var licNr = [];
                    var validUntil = [];
                    licenses.map(license => {
                        union.push(license.getBowUnionCode());
                        bow.push(license.getBowTypeName());
                        age.push(license.getAgeTypeName());
                        licNr.push(license.getLicenseNumber());
                        validUntil.push(license.getReadableValidEndDate());
                    });
                    row.push(union.join("/"));
                    row.push(bow.join("/"));
                    row.push(age.join("/"));
                    row.push(licNr.join("/"));
                    row.push(validUntil.join("/"));
                } else {
                    // no license
                    row.push("");
                    row.push("");
                    row.push("");
                    row.push("");
                    row.push("");
                }
            } else {
                // no address
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                // no license
                row.push("");
                row.push("");
                row.push("");
                row.push("");
                row.push("");
            }
            // address two
            if (clubUser.getAddressTwoID()) {
                row.push(clubUser.getAddressTwoID().getStreet());
                row.push(clubUser.getAddressTwoID().getZip());
                row.push(clubUser.getAddressTwoID().getPlace());
                row.push(clubUser.getAddressTwoID().getPhone());
            } else {
                row.push("");
                row.push("");
                row.push("");
                row.push("");
            }
            // payment
            if (clubUser.getPaymentTargetID()) {
                row.push(clubUser.getPaymentTargetID().getCardIBAN());
                row.push(clubUser.getPaymentTargetID().getCardBIC());
            }
            csvData.push(row.join(';'));
        });
        let output = csvData.join('\n');
        FileDownloadUtility.downloadCSV(output, "member_export.csv" );
        return {};
    }
}

module.exports = alt.createActions(ClubUserActions);
