const React = require("react"),
    PropTypes = require('prop-types');
const {Table, Col} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

const TransactionDetailTable = ({transaction, positions}) => {
    const currency = transaction.getCurrency();
    return (<Col xs={12} md={8} mdOffset={2}>
        <h3>{messages.get("tournament.table.column.invoice") + ": " + transaction.getInvoiceNumber()}</h3>
        {
            transaction.isStornoBrand() ? <h4>STORNO</h4> : null
        }
        <Table responsive striped>
            <thead>
            <tr>
                <th><I18n code="tournament.table.column.article"/></th>
                <th><I18n code="parcours.articles.form.price"/></th>
                <th><I18n code="tournament.table.column.amount"/></th>
                <th><I18n code="tournament.table.column.sum"/></th>
            </tr>
            </thead>
            <tbody>
                {positions.map(function (transactionPosition) {
                    return (<TransactionPositionRow key={transactionPosition.id}
                                                    position={transactionPosition}
                                                    currency={currency}/>);
                })}
                {
                    transaction.getSurcharge() ?
                        <tr>
                            <td><I18n code="tournament.table.column.surcharge"/></td>
                            <td></td>
                            <td>1</td>
                            <td>{transaction.getFormattedSurcharge()}</td>
                        </tr> : null
                }
                {
                    transaction.getDiscount() ?
                        <tr>
                            <td></td>
                            <td></td>
                            <td><strong><I18n code="tournament.table.column.discount"/></strong></td>
                            <td>{transaction.getFormattedDiscount()}</td>
                        </tr> : null
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td><strong><I18n code="tournament.table.column.sum"/></strong></td>
                    <td><strong>{transaction.getFormattedPrice()}</strong></td>
                </tr>
            </tbody>
        </Table>
    </Col>)
}
TransactionDetailTable.propTypes = {
    transaction:PropTypes.object.isRequired,
    positions:PropTypes.array.isRequired
};
const TransactionPositionRow = ({position, currency})=> {
    return (
        <tr>
            <td>{position.getName()}</td>
            <td>{position.getSinglePrice()}</td>
            <td>{position.getAmount()}</td>
            <td>{position.getFormattedSum(currency)}</td>
        </tr>
    )
};
TransactionPositionRow.propTypes = {
    position:PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired
};
module.exports = TransactionDetailTable;
