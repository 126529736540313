const React = require("react"),
    PropTypes = require('prop-types');
const {DropdownButton} = require("react-bootstrap");
const messages = require("i18n/messages");

const TournamentGroupsButton = require("components/links/TournamentGroupsButton"),
    TournamentManagerButton = require("components/links/TournamentManagerButton"),
    TournamentMMTUserButton = require("components/links/TournamentMMTUserButton"),
    TournamentMMScanButton = require("components/links/TournamentMMScanButton"),
    TournamentMMConfigButton = require("components/links/TournamentMMConfigButton");

const TournamentDashboardButton = ({tournament, bsSize}) => {
    return (<DropdownButton block bsSize={bsSize} bsStyle="primary" title={messages.get("tournament.button.gotoTournamentDashboard")} id="tournament-dashboard">
                <TournamentManagerButton style={{textAlign: "left"}} block bsStyle="default" tournament={tournament}/>
                <TournamentMMConfigButton style={{textAlign: "left"}} block bsStyle="default" tournament={tournament}/>
                <TournamentMMTUserButton style={{textAlign: "left"}} block bsStyle="default" tournament={tournament}/>
                <TournamentGroupsButton style={{textAlign: "left"}} block bsStyle="default"  tournament={tournament}/>
                <TournamentMMScanButton style={{textAlign: "left"}} block bsStyle="default"  tournament={tournament}/>
            </DropdownButton>)
};
TournamentDashboardButton.propTypes = {
    tournament:PropTypes.object.isRequired,
    bsSize: PropTypes.string
};
module.exports = TournamentDashboardButton;
