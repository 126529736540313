const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Loading = require("components/widgets/Loading");

const sKillBoardStore = require("stores/SKillBoardStore"),
    languageStore = require("stores/LanguageStore"),
    sKillBoardActions = require("actions/SKillBoardActions");

class ReleaseNotes extends React.Component {
    constructor(props) {
        super(props);
        this.handleSKillBoardChange = this.handleSKillBoardChange.bind(this);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.state = {
            sKillBoardStore: sKillBoardStore.getState(),
            languageStore: languageStore.getState(),
        }
    }
    componentDidMount() {
        sKillBoardStore.listen(this.handleSKillBoardChange);
        languageStore.listen(this.handleLanguageChange);
        sKillBoardActions.queryReleases();
    }
    componentWillUnmount() {
        sKillBoardStore.unlisten(this.handleSKillBoardChange);
        languageStore.unlisten(this.handleLanguageChange);
    }
    handleSKillBoardChange(state) {
        this.setState({sKillBoardStore: state});
    }
    handleLanguageChange(state) {
        this.setState({languageStore: state});
    }
    render() {
        const {releases} = this.state.sKillBoardStore;
        const {language} = this.state.languageStore;
        if (releases == null || language == null) {
            return <Loading/>
        }
        if (releases.length === 0) {
            return <Alert bsStyle="warning"><I18n code="releasenotes.result.empty"/></Alert>
        }
        return <ReleaseNotesList releases={releases} language={language}/>
    }
}
const ReleaseNotesList = ({releases, language}) => {
    const getOSIcon = (release) => {
        if (release.type.Android === release.get(release.col.onlineID)) {
            return <FontAwesome icon={["fab","android"]} />;
        } else  if (release.type.IOS === release.get(release.col.onlineID)) {
            return <FontAwesome icon={["fab","apple"]} />;
        } else if (release.type.Web === release.get(release.col.onlineID)) {
            return <FontAwesome icon={["fab","html5"]} />;
        }
    };
    return (
        <React.Fragment>
            {
                releases.map(release => (<ReleaseItem key={release.id} release={release} language={language} icon={getOSIcon(release)}/>))
            }
        </React.Fragment>)
};
ReleaseNotesList.propTypes = {
    releases:PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
};
const ReleaseItem = ({release, language, icon}) => {
        return (
            <Row>
                <Col lg={12}>
                    <h2>{icon} {release.getVersion()}
                        &nbsp;
                        <small><FontAwesome icon="clock" />{release.getReleaseDate()}</small>
                    </h2>
                    <ReleaseDescription html release={release} language={language}/>
                </Col>
            </Row>)
};
ReleaseItem.propTypes = {
    release:PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired
};
const ReleaseDescription = ({release, inline, html, language}) => {
    const label = release.getTranslatedDescription(language);
    if (html) {
        return (<div style={inline? {display:"inline"}:{}}
                     dangerouslySetInnerHTML={{__html:label}}></div>);
    }
    return (<span>{label}</span>);
}
ReleaseDescription.propTypes = {
    release:PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    inline:PropTypes.bool,
    html:PropTypes.bool
};
module.exports = ReleaseNotes;
