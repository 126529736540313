const alt = require("AltInit");
const ptDao = require("actdao/ParcoursTargetDao");
const parseDao = require("actdao/ParseBaseDao");
const eventDao = require("actdao/EventDao");
const notificationActions = require("components/notification/NotificationActions");
const {ParcoursTarget} = require("parse/_Domain");
const PLUtil = require("util/ParseListUtility");
const {TargetCatalog} = require("parse/_Domain");

class ParcoursTargetActions {

    loadTargetBrandCatalog() {
        ptDao.queryTargetBrands()
            .then(brands => {
                this.updateTargetBrands(brands)
            })
            .catch(error => {
                error.trace = "PATAA.qTarBra";
                notificationActions.parseError(error);
            });
        ptDao.queryTargetCatalog()
            .then(targetList => {
                this.updateTargetCatalog(targetList)
            })
            .catch(error => {
                error.trace = "PATAA.qTarCat";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadParcoursTargets(onlineID) {
        ptDao.queryParcoursTargets(onlineID)
            .then(targetList => {
                this.updateParcoursTargets(targetList)
            })
            .catch(error => {
                error.trace = "PATAA.qParTar";
                notificationActions.parseError(error);
            });
        return {}
    }

    loadEventTracks(parcoursOnlineID, month, year) {
        this.updateLoadingEventTracks(true);
        eventDao.queryEventTracksParcours(parcoursOnlineID, month, year)
            .then(fEvents => {
                this.updateEventTracks(fEvents);
            })
            .catch(error => {
                error.trace = "PATAA.evtracks.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    loadEventTrack(trackID, callback) {
        eventDao.queryEventTrack(trackID)
            .then(eventTrack => {
                callback(eventTrack);
            })
            .catch(error => {
                error.trace = "PATAA.evtrack.load";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateLoadingEventTracks(loading) {
        return loading;
    }

    updateEventTracks(events) {
        return events;
    }

    createParcoursTargetCatalog(index, onlineID, catalogTarget, user) {
        const target = new ParcoursTarget();
        target.setStatus("A");
        target.setIndex(index);
        target.setOnlineID(onlineID);
        target.setName(catalogTarget.getName());
        target.setGroup(catalogTarget.getGroup());
        target.setImageUrl(catalogTarget.getImageUrl());
        target.setTargetCatalogID(catalogTarget);
        target.setEditor(user);
        this.saveParcoursTarget(target, false);
        return {}
    }

    createParcoursTarget(index, onlineID, name, user) {
        const target = new ParcoursTarget();
        target.setStatus("A");
        target.setIndex(index);
        target.setOnlineID(onlineID);
        target.setName(name);
        target.setEditor(user);
        this.saveParcoursTarget(target, false);
        return {}
    }

    saveTargetLog(target) {
        ptDao.saveTargetLog(target)
            .then(result => {
                console.log("saveTargetLog:" + target.id + ":" + result)
            })
            .catch(error => {
                error.trace = "PATAA.sTarLog";
                notificationActions.parseError(error);
            });
        return {}
    }

    updateTarget(target, catalogTarget, name, index, user) {
        if (catalogTarget) {
            // new catalogTarget choosen
            target.setName(catalogTarget.getName());
            target.setGroup(catalogTarget.getGroup());
            target.setImageUrl(catalogTarget.getImageUrl());
            target.setTargetCatalogID(catalogTarget);
        } else if (name) {
            // new name set
            target.setName(name);
            if (target.getTargetCatalogID()) {
                target.unsetTargetCatalog();
            }
        }
        target.setIndex(index);
        target.setEditor(user);
        this.saveParcoursTarget(target, true);
        return {};
    }

    loadParcoursTargetLogs(parcours, target, callback) {
        ptDao.queryParcoursTargetLogs(parcours.getOnlineID(), target.getIndex())
            .then(targetList => {
                callback(targetList)
            })
            .catch(error => {
                error.trace = "PATAA.qParTarLog";
                notificationActions.parseError(error);
            });
        return {}
    }
    replaceTarget(user, target, parcoursTargetLog) {
        target.setParcoursTargetLog(parcoursTargetLog);
        target.setEditor(user)
        this.saveParcoursTarget(target, true);
        return {}

    }

    setTargetStatus(target, status) {
        target.setStatus(status);
        this.saveParcoursTarget(target, false);
        return {}
    }

    reNumberTargets(parcoursTargets, onlineID) {
        const targetSaveArry = [];
        let index = 1;
        let length = parcoursTargets.length;
        for (let i = 0; i < length; i++) {
            let target = parcoursTargets[i];
            if (target.isActive()) {
                // only handle active targets
                if (target.getIndex() != index) {
                    target.setIndex(index);
                    targetSaveArry.push(target);
                }
                index++;
            }
        }
        if (targetSaveArry.length > 0) {
            this.updateLoading(true);
            parseDao.saveAll(targetSaveArry)
                .then(sTargetList => {
                    this.loadParcoursTargets(onlineID)
                })
                .catch(error => {
                    error.trace = "PATAA.sReNuTa";
                    notificationActions.parseError(error);
                });
        } else {
            notificationActions.info("parcours.targets.action.renumber.notneeded");
        }
        return {};
    }
    invertTargets(parcoursTargets) {
        this.updateLoading(true);
        const targetSaveArry = [];
        let index = 1;
        let length = parcoursTargets.length - 1;
        for (let i = length; i >= 0; i--) {
            let target = parcoursTargets[i];
            if (target.isActive()) {
                // only handle active targets
                target.setIndex(index);
                targetSaveArry.push(target);
                index++;
            }
        }
        if (targetSaveArry.length > 0) {
            parseDao.saveAll(targetSaveArry)
                .then(sTargetList => {
                    this.updateParcoursTargets(sTargetList)
                })
                .catch(error => {
                    error.trace = "PATAA.sInvTa";
                    notificationActions.parseError(error);
                });
        }
        return {};
    }

    saveParcoursTarget(target, reload) {
        const isNew = target.isNew();
        parseDao.saveObject(target)
            .then(sTarget => {
                if (reload) {
                    this.updateLoading(true);
                    this.loadParcoursTargets(target.getOnlineID());
                } else {
                    if (isNew) {
                        // add to list in correct position
                        this.addParcoursTarget(sTarget);
                    } else {
                        this.updateParcoursTarget(sTarget);
                    }
                }
            })
            .catch(error => {
                error.trace = "PATAA.sTarget";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveTargetCatalog(target) {
        parseDao.saveObject(target)
            .then(sTarget => {
                this.updateTargetInCatalog(sTarget)
            })
            .catch(error => {
                error.trace = "PATAA.sTargetCat";
                notificationActions.parseError(error);
            });
        return {};
    }

    handleImportTargetCatalog(jsonTargets, targetBrand, targetCatalog) {
        const updateTargets = [];
        const newTargets = []
        for (let i = 0; i < jsonTargets.length; i++) {
            const jsonTarget = jsonTargets[i];
            const brandArtNo = jsonTarget.id
            const price = jsonTarget.price != null ? parseInt(jsonTarget.price) : null
            let target = PLUtil.getObjectbyCol(targetCatalog, TargetCatalog.prototype.col.brandArtNo, brandArtNo)
            if (target != null) {
                // existing target
                console.log("Exists ",target.getBrandArtNo(), target.getName())
                // TODO update element ??
            } else {
                // new target
                target = new TargetCatalog();
                target.setStatus("A");
                target.setName(jsonTarget.name)
                target.setBrandID(targetBrand);
                target.setBrandArtNo(brandArtNo);
                if (price) {
                    target.setPrice(price)
                }
                if (jsonTarget.image) {
                    target.setImageUrl(jsonTarget.image)
                }
                newTargets.push(target)
            }

        }
        this.updateImportTargetCatalog(newTargets, updateTargets)
        return {};
    }


    updateLoading(loading) {
        return loading;
    }
    updateTargetBrands(brands) {
        return brands;
    }

    updateTargetCatalog(targetList) {
        return targetList;
    }

    updateParcoursTargets(targetList) {
        return targetList;
    }

    addParcoursTarget(target) {
        return target;
    }

    updateParcoursTarget(target) {
        return target;
    }

    searchTargetCatalog(searchParams) {
        return searchParams;
    }

    updateMapEventTracks(value, eventTrack) {
        return {value, eventTrack}
    }

    updateTargetInCatalog(target) {
        return target
    }

    updateImportTargetCatalog(newTargets, updateTargets) {
        return {newTargets, updateTargets}
    }
}

module.exports = alt.createActions(ParcoursTargetActions);
