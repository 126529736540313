const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Button} = require("react-bootstrap");
var I18n = require("components/widgets/I18n");

const DecisionForm =({name, messageCode, okButtonCode, handleOK, cancelButtonCode, handleCancel}) => {
    return (<form>
        <Row>

            <Col xs={12}>
                <I18n code={messageCode}/><br/><br/>
                {name != null ? <p><strong>{name}</strong></p> : null}
            </Col>
        </Row>
        <br/>
        <Row>
            <Col xs={6}>
                <Button onClick={handleOK} block bsStyle="primary"><I18n code={okButtonCode}/></Button>
            </Col>
            <Col xs={6}>
                <Button onClick={handleCancel} block bsStyle="default"><I18n code={cancelButtonCode}/></Button>
            </Col>
        </Row>
    </form>)
}
DecisionForm.propTypes = {
    name:PropTypes.string,
    messageCode:PropTypes.string.isRequired,
    okButtonCode:PropTypes.string.isRequired,
    cancelButtonCode:PropTypes.string.isRequired,
    handleOK:PropTypes.func.isRequired,
    handleCancel:PropTypes.func.isRequired
};
module.exports = DecisionForm;