const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col,  ButtonGroup, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    ErrorSuspenseBoundary = require("components/widgets/ErrorSuspenseBoundary"),
    messages = require("i18n/messages"),
    Utility = require("util/Utility");


const calenderStore = require("stores/CalendarStore");

const DateLineChart = React.lazy(() =>
    import(/* webpackChunkName: "ChartComponents" */ "components/charts/DateLineChart")
);

function buildDataWeekDict(maxWeeks) {
    let dataDict = {}
    for (let i = 1; i <= maxWeeks; i++) {
        dataDict[i] = {week: i, EventArrow:0, EventDuration:0, TArrow: 0, TDuration: 0, TrainingArrow:0, TrainingDuration:0}
    }
    return dataDict
}
function buildDPSDict(maxWeeks) {
    let dataDict = {}
    for (let i = 1; i <= maxWeeks; i++) {
        dataDict[i] = {week: i, EvArrow:0, EvPoints:0, EvDPS: null, TArrow:0, TPoints:0, TDPS: null}
    }
    return dataDict
}


class TrainingUserCalendarChartArea extends React.Component {
    constructor(props) {
        super(props);
        this.handleCalendarChange = this.handleCalendarChange.bind(this);
        this.handleKeyType = this.handleKeyType.bind(this);
        this.updateSearchParams = this.updateSearchParams.bind(this);
        this.state = {
            dpsArray:[],
            calenderStore: calenderStore.getState(),
            dataArray: [],
            lblType: "arrows",
            keyType: ["EventArrow", "TArrow", "TrainingArrow"]
        }
    }
    componentDidMount() {
        calenderStore.listen(this.handleCalendarChange);
        this.setState({dataArray: this.buildDataArray(this.state.calenderStore.calEvents)});
    }
    componentWillUnmount() {
        calenderStore.unlisten(this.handleCalendarChange);
    }
    updateSearchParams(value) {
        this.setState({
            dpsArray: this.buildDPSArray(value.getOnlineID(), this.state.calenderStore.calEvents)
        })
    }
    buildDataArray(calEvents) {
        let dataArray = []
        if (calEvents != null) {
            let dataDict = buildDataWeekDict(52)
            calEvents.forEach(calEvent => {
                const isParcours = calEvent.isTrainingParcours()
                const isTournament = calEvent.isTrainingTournament()
                const weekNumber = Utility.getWeek(calEvent.getCalStartDate());
                if (dataDict[weekNumber] != null) {
                    if (isParcours) {
                        dataDict[weekNumber].EventArrow += calEvent.getArrows()
                        dataDict[weekNumber].EventDuration += calEvent.getDuration()
                    } else if (isTournament) {
                        dataDict[weekNumber].TArrow += calEvent.getArrows()
                        dataDict[weekNumber].TDuration += calEvent.getDuration()
                    } else {
                        dataDict[weekNumber].TrainingArrow += calEvent.getArrows()
                        dataDict[weekNumber].TrainingDuration += calEvent.getDuration()
                    }
                } else {
                    console.log("WTF - unknown week", weekNumber);
                }
            })
            Object.keys(dataDict).forEach(dateKey => {
                dataArray.push(dataDict[dateKey])
            })
            //console.log("ARRAY", dataArray)
        }
        return dataArray
    }
    buildDPSArray(countTypeOnlineID, events) {
        let dpsArray = []
        // filter count type
        if (countTypeOnlineID != null) {
            let dpsDict = buildDPSDict(52)
            // filter to parcours
            events.forEach(evp => {
                const evpOnlineID = evp.getCountTypeOnlineID();
                if (evpOnlineID != null && evpOnlineID === countTypeOnlineID) {
                    // add to array
                    const isParcours = evp.isTrainingParcours()
                    const isTournament = evp.isTrainingTournament()
                    const weekNumber = Utility.getWeek(evp.getCalStartDate());
                    let weekObject = dpsDict[weekNumber]
                    if (isParcours) {
                        weekObject["EvArrow"] += evp.getShootCounter()
                        weekObject["EvPoints"] += evp.getSumPoints()
                        if (weekObject["EvArrow"] > 0 && weekObject["EvPoints"] > 0) {
                            weekObject["EvDPS"] = (weekObject["EvPoints"] / weekObject["EvArrow"]).toFixed(1)
                        }
                    } else if (isTournament){
                        weekObject["TArrow"] += evp.getShootCounter()
                        weekObject["TPoints"] += evp.getSumPoints()
                        if (weekObject["TArrow"] > 0 && weekObject["TPoints"] > 0) {
                            weekObject["TDPS"] = (weekObject["TPoints"] / weekObject["TArrow"]).toFixed(1)
                        }
                    }
                }
            })
            Object.keys(dpsDict).forEach(dateKey => {
                dpsArray.push(dpsDict[dateKey])
            })
        }
        return dpsArray
    }
    handleCalendarChange(state) {
        this.setState({calenderStore: state, dataArray: this.buildDataArray(state.calEvents)});
    }
    handleKeyType(type) {
        if (type === "arrows") {
            this.setState({lblType: type, keyType: ["EventArrow", "TArrow", "TrainingArrow"]})
        } else {
            this.setState({lblType: type, keyType: ["EventDuration", "TDuration", "TrainingDuration"]})
        }
    }
    render() {
        const {calenderStore, dataArray, lblType, keyType, dpsArray} = this.state;
        const {trainingCountTypes} = this.props;
        const {calendarBoundaries} = calenderStore;
        return <Row>
            <Col xs={12}>
                <h3>Parcours, Turnier und Training {calendarBoundaries.year}</h3>
                <DataKeyButtonGroup handleKeyType={this.handleKeyType} keyType={lblType} />
                <ErrorSuspenseBoundary>
                    <DateLineChart type="bar" dataArray={dataArray} dataKeys={keyType} dateKey="week" stacked={true}/>
                </ErrorSuspenseBoundary>
            </Col>
            <Col xs={12}>
                <h3>Parcours und Turnier DPS {calendarBoundaries.year}</h3>
            </Col>
            <Col sm={6}>
                <DropDownParseObject onAfterChange={this.updateSearchParams} label={messages.get("skillboard.tab.scores")}
                                     placeholder={<option key={"nullkey"}
                                                          value={"null"}>{messages.get("modal.license.chooseCountType")}</option>}
                                     objectList={trainingCountTypes}/>
            </Col>
            <Col xs={12}>
                <ErrorSuspenseBoundary>
                    <DateLineChart type="line" dataArray={dpsArray} dataKeys={["EvDPS", "TDPS"]} dateKey="week" />
                </ErrorSuspenseBoundary>
            </Col>
        </Row>
    }
}
const DataKeyButtonGroup = ({keyType, handleKeyType}) => {
    return <Row>
        <Col sm={6}>
            <br/>
            <ButtonGroup>
                <Button onClick={()=> {handleKeyType("arrows")}}
                        className={keyType === "arrows" ? "active" : ""}><I18n code="statistics.column.arrows"/>
                </Button>
                <Button onClick={()=> {handleKeyType("minutes")}}
                        className={keyType === "minutes" ? "active" : ""}><I18n code="statistics.column.duration"/> (min)
                </Button>
            </ButtonGroup>
        </Col>
    </Row>
}
module.exports = TrainingUserCalendarChartArea;
