/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, ButtonGroup, Button, Image} = require("react-bootstrap");
const messages = require("i18n/messages"),
    SubmitButton = require("components/form/SubmitButton"),
    FontAwesome = require("components/widgets/FontAwesome");

const imageGalleryActions = require("actions/ImageGalleryActions");

class ImageGalleryModal extends React.Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.close = this.close.bind(this);
        this.markImage = this.markImage.bind(this);
        this.allowDelete = this.allowDelete.bind(this);
        this.deleteImage = this.deleteImage.bind(this);

        this.state = {
            showModal: false,
            submitDisabled: false,
            imageGallery: null
        }
    }
    openModal(imageGallery) {
        this.setState({
            showModal: true,
            imageGallery: imageGallery
        });
    }
    close() {
        this.setState({ showModal: false, submitDisabled: false, imageGallery: null});
    }
    markImage() {
        this.setState({submitDisabled: true});
        imageGalleryActions.markImage(this.state.imageGallery);
    }
    allowDelete() {
        if (this.state.imageGallery.getCreator() && this.props.user) {
            return this.state.imageGallery.getCreator().id === this.props.user.id || this.props.adminPage;
        } else if (this.props.parcours) {
            return this.props.parcours.isParcoursManager();
        }
        return false;
    }
    deleteImage() {
        imageGalleryActions.deleteImage(this.state.imageGallery);
        this.close();
    }
    render() {
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header>
                    {
                        this.state.showModal ?
                            <Modal.Title>
                                <Col sm={11} xs={10}>
                                    <ButtonGroup>
                                        <Button disabled={this.state.submitDisabled} onClick={this.markImage}>
                                            <FontAwesome icon="ban"/>
                                            {
                                                this.state.submitDisabled ?
                                                    messages.get("gallery.picture.reported")
                                                    :
                                                    messages.get("gallery.picture.report")
                                            }
                                        </Button>
                                        {
                                            this.allowDelete() ?
                                                <SubmitButton onClick={this.deleteImage} confirm={true}
                                                              icon={<FontAwesome icon="times"/>}
                                                              text={messages.get("modal.button.delete")}
                                                              bsStyle="default" />
                                                : null
                                        }
                                    </ButtonGroup>
                                </Col>
                                <Col sm={1} xs={2} >
                                    <Button onClick={this.close}><FontAwesome icon="times"/></Button>
                                </Col>
                                <br/>
                            </Modal.Title> : null
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <Row>
                                <GalleryImage imageGallery={this.state.imageGallery}/>
                            </Row> : null
                    }

                </Modal.Body>
            </Modal>
        );
    }
}
ImageGalleryModal.propTypes = {
    user: PropTypes.object,
    parcours: PropTypes.object
};
const GalleryImage = ({imageGallery}) => {
    return (
        <Col xs={12}>
            <p><strong>{imageGallery.getImageDate() + ": "}</strong>
                {imageGallery.getComment()}
            </p>
            <Image responsive rounded
                 src={imageGallery.getImageUrl()} />
            <br/>
        </Col>)
};
GalleryImage.propTypes = {
    imageGallery:PropTypes.object.isRequired
};
module.exports = ImageGalleryModal;