/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");

const ClubUser = require("parse/ClubUser"),
    UserDetail = require("parse/UserDetail"),
    Address = require("parse/Address");

const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    messages = require("i18n/messages"),
    DateInput = require("components/form/DateInput"),
    ValidInput = require("components/form/ValidInput"),
    RadioGroupInput = require("components/form/RadioGroupInput");

const clubActions = require("actions/ClubActions");

class ClubUserAddressModal extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.refMail2 = React.createRef();
        this.refNumber = React.createRef();
        this.refName = React.createRef();
        this.refSurname = React.createRef();
        this.refStreet = React.createRef();
        this.refZip = React.createRef();
        this.refPlace = React.createRef();
        this.refBirthday = React.createRef();
        this.refPhone = React.createRef();
        this.refPhone2 = React.createRef();

        this.openCreate = this.openCreate.bind(this);
        this.openUser = this.openUser.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.createNewUserDetailAdress = this.createNewUserDetailAdress.bind(this);
        this.fillUserDetail = this.fillUserDetail.bind(this);
        this.fillAddress = this.fillAddress.bind(this);
        this.getDefaultValue = this.getDefaultValue.bind(this);
        this.getDefaultDate = this.getDefaultDate.bind(this);
        this.clickChangedUserSex = this.clickChangedUserSex.bind(this);
        this.getAddressDefaultValue = this.getAddressDefaultValue.bind(this);

        this.state = {
            clubUser: null,
            sex: null,
            nextCUNumber: null,
            submitDisabled: false,
            showModal: false,
            error: null
        }
    }
    openCreate(nextCUNumber) {
        this.setState({
            clubUser: null,
            nextCUNumber: nextCUNumber,
            showModal: true
        });
    }
    openUser(clubUser) {
        this.setState({
            clubUser: clubUser,
            nextCUNumber: null,
            showModal: true
        });
    }
    close() {
        this.setState({submitDisabled: false, showModal: false, nextCUNumber: null, error:null, sex: null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    valid() {
        let valid = true;
        const required = [
            this.refName.current.getValue().trim(),
            this.refSurname.current.getValue().trim(),
            this.refStreet.current.getValue().trim(),
            this.refZip.current.getValue().trim(),
            this.refPlace.current.getValue().trim()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        if (this.state.clubUser == null) {
            // create new clubUser without any references
            const newClubUser = ClubUser.prototype.buildClubMember(null, this.props.club);
            if (this.refNumber.current && this.refNumber.current.getValue()) {
                newClubUser.setUserNumber(this.refNumber.current.getValue().trim());
            }
            this.createNewUserDetailAdress(newClubUser);
            return;
        }
        const editClubUser = this.state.clubUser;
        // edit the elements
        let cuUsrDetail = editClubUser.getUserDetailID();
        if (cuUsrDetail == null) {
            // create new userDetail and Address for existing clubUser
            this.createNewUserDetailAdress(editClubUser);
            return;
        } else {
            let address = editClubUser.getUserDetailID().getAddressID();
            if (address == null) {
                // just for keeping values until reaching cloud code
                address = new Address();
            }
            this.fillUserDetail(cuUsrDetail);
            this.fillAddress(address);
            clubActions.updateClubUserAddress(editClubUser, cuUsrDetail, address);
        }
        this.close();
    }
    createNewUserDetailAdress(editClubUser) {
        // userDetail
        const usrDetail = new UserDetail();
        usrDetail.setStatus(UserDetail.prototype.status.A);
        usrDetail.buildACL(editClubUser.getUserID());
        usrDetail.setUnion(this.props.club.getName());
        this.fillUserDetail(usrDetail);
        // address
        const address = new Address();
        address.setStatus(Address.prototype.status.A);
        address.buildACL(editClubUser.getUserID(), "club", null);
        this.fillAddress(address);
        clubActions.createClubUserAddress(editClubUser, usrDetail, address);
        this.close();
    }
    fillUserDetail(usrDetail) {
        if (usrDetail.getName() != this.refName.current.getValue().trim()) {
            usrDetail.setName(this.refName.current.getValue().trim());
        }
        if (usrDetail.getSurname() != this.refSurname.current.getValue().trim()) {
            usrDetail.setSurname(this.refSurname.current.getValue().trim());
        }
        if (this.state.sex != null) {
            if (usrDetail.getSex() != this.state.sex) {
                usrDetail.setSex(this.state.sex);
            }
        }
        // handle optional birthday
        const actBirthDate = usrDetail.getBirthday();
        if (this.refBirthday.current.getValue() != null && this.refBirthday.current.getValue().trim().length > 0) {
            // birthday entered
            let newBirthDay = new Date(this.refBirthday.current.getValue());
            if (actBirthDate != null) {
                // compare them
                if (newBirthDay.getTime() != actBirthDate.getTime()) {
                    // changed update it
                    usrDetail.setBirthday(newBirthDay);
                }
            } else {
                usrDetail.setBirthday(newBirthDay);
            }
        } else {
            // unset?
            if (actBirthDate != null) {
                usrDetail.unset(UserDetail.prototype.col.birthday);
            }
        }
    }
    fillAddress(address) {
        if (address.getName() != this.refName.current.getValue().trim()) {
            address.setName(this.refName.current.getValue().trim());
        }
        if (address.getSurname() != this.refSurname.current.getValue().trim()) {
            address.setSurname(this.refSurname.current.getValue().trim());
        }
        if (address.getStreet() != this.refStreet.current.getValue().trim()) {
            address.setStreet(this.refStreet.current.getValue().trim());
        }
        if (address.getZip() != this.refZip.current.getValue().toString().trim()) {
            address.setZip(this.refZip.current.getValue().toString().trim());
        }
        if (address.getPlace() != this.refPlace.current.getValue().trim()) {
            address.setPlace(this.refPlace.current.getValue().trim());
        }

        if (address.getPhone() != this.refPhone.current.getValue().trim()) {
            address.setPhone(this.refPhone.current.getValue().trim());
        }
        if (address.getMail() != this.refMail.current.getValue().trim()) {
            address.setMail(this.refMail.current.getValue().trim());
        }

        if (address.getPhone2() != this.refPhone2.current.getValue().trim()) {
            address.setPhone2(this.refPhone2.current.getValue().trim());
        }
        if (address.getMail2() != this.refMail2.current.getValue().trim()) {
            address.setMail2(this.refMail2.current.getValue().trim());
        }
    }
    clickChangedUserSex(sex) {
        this.setState({sex: parseInt(sex.split(".")[4])});
    }
    getDefaultValue(column) {
        if (this.state.clubUser != null) {
            const usrDetail = this.state.clubUser.getUserDetailID();
            if (usrDetail != null) {
                return usrDetail.get(column);
            }
        }
        return null;
    }
    getDefaultDate(column) {
        if (this.state.clubUser != null && this.state.clubUser.getUserDetailID() != null) {
            return this.state.clubUser.getUserDetailID().get(column);
        }
        return null;
    }
    getAddressDefaultValue(column) {
        if (this.state.clubUser != null &&  this.state.clubUser.getUserDetailID() != null) {
            const address = this.state.clubUser.getUserDetailID().getAddressID();
            if (address != null) {
                return address.get(column);
            }
        }
        return null;
    }
    render() {
        const validateNow = this.state.error && this.state.error.length > 0;
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {
                            this.state.clubUser != null ?
                                <I18n code={"club.member.modal.edit.header"}/>
                                :
                                <I18n code={"club.member.modal.create.header"}/>
                        }
                    </Modal.Title>
                    {
                        this.state.clubUser != null ? null :
                            <p>{messages.get("club.member.modal.create.description")}</p>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    {
                                        this.state.nextCUNumber ? <Col sm={12}>
                                            <ValidInput ref={this.refNumber}
                                                        valid={{maxLength: 10, check:['isRequired']}}
                                                        label={messages.get("address.userNr")}
                                                        default={this.state.nextCUNumber}
                                                        validateNow={validateNow}/>
                                        </Col> : null
                                    }
                                    <Col sm={6}>
                                        <ValidInput ref={this.refName}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    placeholder={messages.get("tournament.register.name")}
                                                    default={this.getDefaultValue("name")}
                                                    validateNow={validateNow}/>
                                    </Col>
                                    <Col sm={6}>
                                        <ValidInput ref={this.refSurname}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    placeholder={messages.get("tournament.register.surname")}
                                                    default={this.getDefaultValue("surname")}
                                                    validateNow={validateNow}/>
                                    </Col>
                                    <Col sm={12}>
                                        <ValidInput ref={this.refStreet}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    placeholder={messages.get("address.street")}
                                                    default={this.getAddressDefaultValue("street")}
                                                    validateNow={validateNow}/>
                                    </Col>
                                    <Col sm={4}>
                                        <ValidInput ref={this.refZip}
                                                    valid={{maxLength: 6, check:['isRequired']}}
                                                    placeholder={messages.get("address.zip")}
                                                    default={this.getAddressDefaultValue("zip")}
                                                    validateNow={validateNow}/>
                                    </Col>
                                    <Col sm={8}>
                                        <ValidInput ref={this.refPlace}
                                                    valid={{maxLength: 50, check:['isRequired']}}
                                                    placeholder={messages.get("address.place")}
                                                    default={this.getAddressDefaultValue("place")}
                                                    validateNow={validateNow}/>
                                    </Col>
                                    <Col sm={6}>
                                        <RadioGroupInput default={"domain.user.detail.sex." + this.getDefaultValue("sex")}
                                                         list={["domain.user.detail.sex.0", "domain.user.detail.sex.1"]}
                                                         onAfterChange={this.clickChangedUserSex}/>
                                    </Col>
                                    <Col sm={6}>

                                        <DateInput ref={this.refBirthday} default={this.getDefaultDate("birthday")}
                                                   placeholder={messages.get("address.birthday")}
                                                   addonBefore={<FontAwesome icon="birthday-cake"/>}/>
                                    </Col>
                                    <Col sm={12}>
                                        <div><strong><I18n code="address.contact.primary"/></strong></div>
                                        <ValidInput ref={this.refMail}
                                                    valid={{maxLength: 50, check:['isMail']}}
                                                    placeholder={messages.get("address.mail")}
                                                    addonBefore={<FontAwesome icon="envelope"/>}
                                                    default={this.getAddressDefaultValue("mail")}/>
                                        <ValidInput ref={this.refPhone}
                                                    valid={{maxLength: 50, check:['isPhone']}}
                                                    placeholder={messages.get("address.phone")}
                                                    addonBefore={<FontAwesome icon="phone"/>}
                                                    default={this.getAddressDefaultValue("phone")}/>
                                        <div><strong><I18n code="address.contact.secondary"/></strong></div>
                                        <ValidInput ref={this.refMail2}
                                                    valid={{maxLength: 50, check:['isMail']}}
                                                    placeholder={messages.get("address.mail") + " 2"}
                                                    addonBefore={<FontAwesome icon="envelope"/>}
                                                    default={this.getAddressDefaultValue("mail2")}/>
                                        <ValidInput ref={this.refPhone2}
                                                    valid={{maxLength: 50, check:['isPhone']}}
                                                    placeholder={messages.get("address.phone") + " 2"}
                                                    addonBefore={<FontAwesome icon="phone"/>}
                                                    default={this.getAddressDefaultValue("phone2")}/>

                                    </Col>
                                </Row>
                                <Row>
                                    {this.state.error == null ? null : <Alert bsStyle="danger">{this.state.error}</Alert>}
                                    <Col xs={12}>
                                        <Button onClick={this.clickedSave} disabled={this.state.submitDisabled} block bsStyle="success"><I18n code={"modal.button.save"}/></Button>
                                    </Col>
                                </Row>

                            </form> : null
                    }

                </Modal.Body>
            </Modal>
        );
    }
}
ClubUserAddressModal.propTypes = {
    club:PropTypes.object.isRequired
};
module.exports = ClubUserAddressModal;