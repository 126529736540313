var Parse = require("ParseInit");
var Utility = require("util/Utility");

var UserDetail = Parse.Object.extend("userDetail", {
    col: {
        ACL: "ACL",
        addressID: "addressID",
        birthday: "birthday",
        contactPhone: "contactPhone", // toAddress
        licenseIDs: "licenseIDs",
        name: "name",
        surname: "surname",
        place: "place", // toAddress
        street: "street", // toAddress
        sex: "sex",
        union: "union",
        usrImage: "usrImage",
        zip: "zip", // toAddress
        status: "status",
        createdAt: "createdAt",
        updatedAt: "updatedAt"
    },
    status: {
        A: "A"
    },
    //
    // customn
    //
    getSexCode() {
        return "domain.user.detail.sex." + this.get(this.col.sex);
    },
    getZipPlace() {
        var tPlace = "";
        if (this.getZip()) {
            tPlace = this.getZip() + " ";
        }
        if (this.getPlace()) {
            tPlace += this.getPlace();
        }
        return tPlace;
    },
    buildACL(user) {
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(false);
        acl.setPublicWriteAccess(false);
        if (user) {
            acl.setWriteAccess(user, true);
            acl.setReadAccess(user, true);
        }
        acl.setRoleReadAccess("tournament", true);
        acl.setRoleReadAccess("club", true);
        this.setACL(acl);
    },
    //
    //
    //
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getAddressID() {
        return this.get(this.col.addressID)
    },
    setAddressID(addressID) {
        return this.set(this.col.addressID, addressID)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getSurname() {
        return this.get(this.col.surname)
    },
    setSurname(surname) {
        return this.set(this.col.surname, surname)
    },
    getFullName() {
        var surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return  this.getName() + " " + surname;
        } else {
            return  this.getName();
        }
    },
    getFullSurName() {
        let surname = this.getSurname();
        if (surname != null && surname.length > 0) {
            return  surname + " " + this.getName();
        } else {
            return this.getName();
        }
    },
    setFullName(name, surname) {
        this.set(this.col.name, name);
        this.set(this.col.surname, surname);
    },
    getUnion() {
        return this.get(this.col.union)
    },
    setUnion(union) {
        return this.set(this.col.union, union)
    },
    getUserImage() {
        return this.get(this.col.usrImage)
    },
    getUserImageUrl() {
        const image = this.getUserImage();
        if (image) {
            return image.url();
        }
        return null;
    },
    setUserImage(usrImage) {
        return this.set(this.col.usrImage, usrImage)
    },
    addLicenseID(usrLicense) {
        let licenses = this.getLicenseIDs();
        if (licenses == null) {
            licenses = [];
        }
        licenses.push(usrLicense);
        this.setLicenseIDs(licenses)
    },
    getLicenseIDs() {
        return this.get(this.col.licenseIDs)
    },
    getUserBowUnionLicense(bowUnionID) {
        var licenses = this.getLicenseIDs();
        if (licenses != null) {
            for (var i = 0; i < licenses.length; i++) {
                if (licenses[i].getBowUnionID().id === bowUnionID.id) {
                    return licenses[i];
                }
            }
        }
        return null;
    },
    getLicenseArrayIDs() {
        var array = [];
        var pointers = this.getLicenseIDs();
        if (pointers != null) {
            for (let i = 0; i < pointers.length; i++) {
                array.push(pointers[i].id);
            }
        }
        return array;
    },
    getLicenseArrayIDsExclude(excludeLicense) {
        var array = [];
        var pointers = this.getLicenseIDs();
        if (pointers != null) {
            for (let i = 0; i < pointers.length; i++) {
                if (excludeLicense.id !== pointers[i].id) {
                    array.push(pointers[i].id);
                }

            }
        }
        return array;
    },
    setLicenseIDs(licenseIDs) {
        return this.set(this.col.licenseIDs, licenseIDs)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getBirthday() {
        return this.get(this.col.birthday)
    },
    getReadableBirthday() {
        let tDate = this.getBirthday();
        if (tDate != null) {
            return Utility.getReadableDate(tDate);
        }
        return null;
    },
    setBirthday(birthday) {
        return this.set(this.col.birthday, birthday)
    },
    getContactPhone() {
        return this.get(this.col.contactPhone)
    },
    setContactPhone(contactPhone) {
        return this.set(this.col.contactPhone, contactPhone)
    },
    getPlace() {
        return this.get(this.col.place)
    },
    setPlace(place) {
        return this.set(this.col.place, place)
    },
    getSex() {
        return this.get(this.col.sex)
    },
    setSex(sex) {
        return this.set(this.col.sex, sex)
    },
    getStreet() {
        return this.get(this.col.street)
    },
    setStreet(street) {
        return this.set(this.col.street, street)
    },
    getZip() {
        return this.get(this.col.zip)
    },
    setZip(zip) {
        return this.set(this.col.zip, zip)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    }
});

module.exports = UserDetail;
