const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Button} = require("react-bootstrap");
var I18n = require("components/widgets/I18n");

class DecisionModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.openNamedHeader = this.openNamedHeader.bind(this);
        this.close = this.close.bind(this);
        this.clickedOK = this.clickedOK.bind(this);
        this.clickedCancel = this.clickedCancel.bind(this);

        this.state = {
            showModal: false,
            name: null,
            titleCode: "modal.decision.title.default",
            messageCode: "code.unknown",
            okButtonCode: "modal.button.ok",
            cancelButtonCode: "modal.button.cancel"
        }
    }
    open() {
    	this.setState({showModal: true, name: null});
    }
    openNamedHeader(name) {
        this.setState({showModal: true, name: name});
    }
    close() {
        this.setState({showModal: false});
    }
    clickedOK() {
    	this.close();
    	if (this.props.handleOK) {
     		this.props.handleOK();   		
    	} else {
    		console.log("No handleOK prop definied");
    	}
    }
    clickedCancel() {
    	this.close();
    	if (this.props.handleCancel) {
    		this.props.handleCancel();
    	} else {
    		console.log("No handleCancel prop definied");
    	}
    }
    render() {
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <I18n code={this.props.titleCode}/>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>

                            <Col xs={12}>
                                <I18n code={this.props.messageCode}/><br/><br/>
                                {this.state.name != null ? <p><strong>{this.state.name}</strong></p> : null}
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col xs={6}>
                            	<Button onClick={this.clickedOK} block bsStyle="primary"><I18n code={this.props.okButtonCode}/></Button>
                            </Col>
                            <Col xs={6}>
                            	<Button onClick={this.clickedCancel} block bsStyle="default"><I18n code={this.props.cancelButtonCode}/></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
            </Modal>
        );
    }
}
DecisionModal.propTypes = {
    titleCode:PropTypes.string,
    messageCode:PropTypes.string,
    okButtonCode:PropTypes.string,
    cancelButtonCode:PropTypes.string,
    handleOK:PropTypes.func
};
module.exports = DecisionModal;