const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, FormControl, ControlLabel, Alert} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

const TournamentRegSlotSelect = ({tournamentRegSlots, preSelect, selectedSlotId, showAll, selectCallback}) =>{
    const changeOption = (event) => {
        selectCallback(event.target.value)
    };
    let openTRegSlots = tournamentRegSlots.filter(slot => {
        if (showAll) {
            return true
        }
        return slot.isNotFull() && !slot.isClosed()
    });
    let openSlots = openTRegSlots.length;
    return (<FormGroup>
        <ControlLabel><I18n code="tournament.manager.config.slots"/></ControlLabel>
        <FormControl componentClass="select" placeholder="select" disabled={openSlots === 0}
                     defaultValue={selectedSlotId}
                     onChange={changeOption}>
            {
                preSelect == false ? <option value={null}>{messages.get("modal.license.choose")}</option> : null
            }
            {
                openTRegSlots.map(slot => {
                    return <option key={slot.id} value={slot.id}>{slot.getName()} {slot.getActualAmount()}/{slot.getMaxAmount()}</option>
                })
            }
        </FormControl>
        {
            openSlots > 0 ? null : <Alert bsStyle="danger"><I18n code="tournament.manager.config.slots.full"/></Alert>
        }
    </FormGroup>);
};
TournamentRegSlotSelect.propTypes = {
    tournamentRegSlots: PropTypes.array.isRequired,
    selectedSlotId: PropTypes.string,
    preSelect: PropTypes.bool,
    showAll: PropTypes.bool,
    selectCallback: PropTypes.func.isRequired
};
module.exports = TournamentRegSlotSelect;
