const Parse = require("ParseInit");

const CountTypeGrp = Parse.Object.extend("countTypeGrp", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        objectId: "objectId",
        status: "status",
        typeID: "typeID",
        sortIndex: "sortIndex",
        edit: "edit",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    typeIDs : {
        system: 0,
        private: 1,
        disc: 2,
        fiarc: 3,
        custom: 4,
        bowSim: 5,
        blowGun: 6
    },
    isTypeDisc() {
        return this.typeIDs.disc === this.getTypeID();
    },
    isEditable() {
        return this.get(this.col.edit);
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getSelectString: function () {
        return this.getName()
    },
    getName: function () {
        return this.get(this.col.name)
    },
    setName: function (name) {
        return this.set(this.col.name, name)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getTypeID: function () {
        return this.get(this.col.typeID)
    },
    setTypeID: function (typeID) {
        return this.set(this.col.typeID, typeID)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
});

module.exports = CountTypeGrp;
