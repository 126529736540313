const Parse = require("ParseInit");

const ParseLog = Parse.Object.extend("_Log", {
    col: {
        timestamp: "timestamp",
        level: "level",
        message: "message",
        meta: "meta"
    },
    getTimestampDate() {
        let startDate = this.getTimestamp();
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    getTimestamp() {
        return this.get(this.col.timestamp)
    },
    getLevel() {
        let level = this.get(this.col.level);
        if (level === "error") {
            return "danger"
        }
        return level;
    },
    getMessage() {
        return this.get(this.col.message)
    },
    getMetaInfo() {
        return this.get(this.col.meta)
    }
});

module.exports = ParseLog;
