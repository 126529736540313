const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");
const parcoursActions = require("actions/ParcoursActions");

const ParcoursPaymentButton = ({parcours, block, disabled, bsStyle, bsSize}) => {
    const handleClick = ()  => {
        parcoursActions.checkOnlinePayment(parcours);
    };
    return (
        <Button block={block} bsSize={bsSize}
                style={{whiteSpace: "normal"}}
                disabled={disabled}
                bsStyle={bsStyle ? bsStyle : "primary"}
                onClick={handleClick}>
            <FontAwesome icon="euro-sign"/><I18n code="home.parcours.payBtn"/>
        </Button>
    )
};
ParcoursPaymentButton.propTypes = {
    parcours:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
};
module.exports = ParcoursPaymentButton;