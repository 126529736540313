const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, ListGroup, ListGroupItem, Label, Table,Popover, Overlay, ButtonGroup, Button} = require("react-bootstrap");

const I18n = require("components/widgets/I18n"),
    Loading = require("components/widgets/Loading"),
    messages = require("i18n/messages"),
    MonthPager = require("components/widgets/MonthPager"),
    FontAwesome = require("components/widgets/FontAwesome");

const memoize = require("memoize-one").default;

const {isToday, isBefore, getDay, addDays, startOfMonth, endOfMonth, getISOWeek, addWeeks, startOfWeek, endOfWeek, areIntervalsOverlapping} = require('date-fns');

const clubActions = require("actions/ClubActions");

const filterEvents = memoize((clubEvents, calendarBoundaries) => {
        let eventWeekIl = {};
        // eventLoading - used to ensure correct redraw of table
        if (clubEvents) {
            //console.log("Filter Events " + clubEvents.length);
            for (let i = 0; i < clubEvents.length; i++) {
                // check if eventStart or  eventEnd is in this month
                let clubEvent = clubEvents[i];
                if (areIntervalsOverlapping({start: clubEvent.getDate(), end: clubEvent.getEndDate()},
                    {start: calendarBoundaries.firstDay, end:calendarBoundaries.lastDay})) {
                    // event is in the week range - set the event to start week!
                    let observeEventDate = clubEvent.getDate();
                    // loop until last day is reached
                    while (observeEventDate <= clubEvent.getEndDate()) {
                        let startWeekNumberEV = getISOWeek(observeEventDate);
                        if (startWeekNumberEV in calendarBoundaries.weekDict) {
                            // event starts in the shown range
                            addObjectToWeekDay(eventWeekIl, startWeekNumberEV, observeEventDate, clubEvent);
                        }
                        // go to next day
                        observeEventDate = addDays(observeEventDate, 1);
                    }

                }
            }
        }
        return eventWeekIl;
});
const filterClubUserBirthday = memoize((clubUsers, calendarBoundaries) => {
    let birthdayWeekIt = {};
    if (clubUsers) {
        for (let i = 0; i < clubUsers.length; i++) {
            let clubUser = clubUsers[i];
            let cuBirthday = clubUser.getBirthday();
            if (cuBirthday) {
                let currentBirthDay = new Date(calendarBoundaries.year, cuBirthday.getMonth(), cuBirthday.getDate());
                let startWeekNumberBD = getISOWeek(currentBirthDay);
                if (startWeekNumberBD in calendarBoundaries.weekDict) {
                    // event starts in the shown range
                    addObjectToWeekDay(birthdayWeekIt, startWeekNumberBD, currentBirthDay, clubUser);

                }
            }

        }
    }
    return birthdayWeekIt;
});
const addObjectToWeekDay = function(weekIt, startWeekNumberEV, observeDate, object) {
    let startDayEv = getDay(observeDate);
    let weekKey = "wd" + startDayEv;
    if (startWeekNumberEV in weekIt) {
        // add to existing week object
        let extWeekDaysEvDict = weekIt[startWeekNumberEV];
        if (weekKey in extWeekDaysEvDict) {
            // day has already event
            extWeekDaysEvDict[weekKey].push(object);
        } else {
            // day has no event
            extWeekDaysEvDict[weekKey] = [object];
        }
    } else {
        let weekDaysEvDict = {};
        weekDaysEvDict[weekKey] = [object];
        weekIt[startWeekNumberEV] = weekDaysEvDict;
    }
};
class ClubMonthCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.calcCalendarBoundaries = this.calcCalendarBoundaries.bind(this);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        const calBound = this.calcCalendarBoundaries(new Date());
        this.state = {
            calendarBoundaries: calBound
        }
    }
    calcCalendarBoundaries(date) {
        let weekIt = {};
        let weekItKeys = [];
        let monthStart = startOfMonth(date);
        let monthEnd = endOfMonth(date);
        let weekRunner = startOfMonth(date);
        //let weeks = differenceInCalendarWeeks(monthStart, monthEnd);
        //let firstWeekStart = startOfWeek(monthStart);
        let lastWeekEnd =  endOfWeek(monthEnd, {weekStartsOn: 1});
        // run through the weeks as long as last weekday of this month is reached
        while (isBefore(weekRunner,lastWeekEnd)) {
            let weekStart = startOfWeek(weekRunner, {weekStartsOn: 1});
            weekItKeys.push(getISOWeek(weekStart));
            weekIt[getISOWeek(weekStart)] = {
                wd1: weekStart,
                wd2: addDays(weekStart, 1),
                wd3: addDays(weekStart, 2),
                wd4: addDays(weekStart, 3),
                wd5: addDays(weekStart, 4),
                wd6: addDays(weekStart, 5),
                wd0: addDays(weekStart, 6)};
            // check next week
            weekRunner = addWeeks(weekRunner, 1)
        }
        return {
            year: date.getFullYear(),
            firstDay: startOfWeek(monthStart, {weekStartsOn: 1}),
            lastDay: lastWeekEnd,
            weekKeys: weekItKeys,
            weekDict: weekIt
        };
    }
    handleChangedDate(month, year, yearChanged) {
        let nCalcB = this.calcCalendarBoundaries(new Date(year, month - 1, 1));
        if (yearChanged) {
            let searchParams = {
                year: year,
                text: ""
            };
            clubActions.loadClubEvents.defer(this.props.club, this.props.ownClubUser, searchParams);
        }
        this.setState({
            calendarBoundaries: nCalcB
        })
    }
    render() {
        const {calendarBoundaries} = this.state;
        const {weekKeys, weekDict} = calendarBoundaries;
        const {club, clubEvents, ownClubUser, clubUsers, eventLoading, handleNewEvent, handleEditEvent, handleDeleteEvent} = this.props;
        const hasMessaging = ownClubUser != null && ownClubUser.hasMessaging();
        const filteredEvents = filterEvents(clubEvents, calendarBoundaries);
        const filteredUsers = hasMessaging === true ? filterClubUserBirthday(clubUsers, calendarBoundaries) : [];
        return <React.Fragment>
            <MonthPager headerText="" handleChangedDate={this.handleChangedDate} allowFuture={true}/>
            {
                eventLoading ? <Loading /> : null
            }
            <Table responsive striped>
                <thead>
                <tr>
                    <th><I18n code="KW"/></th>
                    <th><I18n code="MO"/></th>
                    <th><I18n code="DI"/></th>
                    <th><I18n code="MI"/></th>
                    <th><I18n code="DO"/></th>
                    <th><I18n code="FR"/></th>
                    <th><I18n code="SA"/></th>
                    <th><I18n code="SO"/></th>
                </tr>
                </thead>
                <tbody>
                {
                    weekKeys.map(weekNumber => {
                        return <CalendarWeek key={"KW_" + weekNumber} weekNumber={weekNumber}
                                             handleNewEvent={handleNewEvent} handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent}
                                             dayList={ weekDict[weekNumber]} hasMessaging={hasMessaging}
                                             weekClubUsers={filteredUsers[weekNumber]}
                                             weekClubEvents={filteredEvents[weekNumber]}/>
                    })
                }
                </tbody>
            </Table>
        </React.Fragment>
    }
}
ClubMonthCalendar.propTypes = {
    club:PropTypes.object.isRequired,
    ownClubUser:PropTypes.object,
    clubUsers: PropTypes.array,
    clubEvents: PropTypes.array,
    eventLoading: PropTypes.bool.isRequired,
    handleNewEvent: PropTypes.func.isRequired,
    handleEditEvent: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired
};
const CalendarWeek = ({dayList, weekNumber, weekClubEvents, weekClubUsers, hasMessaging, handleNewEvent, handleEditEvent, handleDeleteEvent}) => {
    return (
        <tr>
            <td style={{width: "40px"}}>({weekNumber})</td>
            {
                Object.keys(dayList).map(dayKey => {
                    let weekday = dayList[dayKey];
                    return <CalendarWeekDay key={"KW_" + weekNumber + "_day_" + weekday.getDate()} weekday={weekday} hasMessaging={hasMessaging}
                                            handleNewEvent={handleNewEvent} handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent}
                                            weekDayClubEvents={weekClubEvents ? weekClubEvents[dayKey] : null}
                                            weekDayClubUsers={weekClubUsers ? weekClubUsers[dayKey] : null}/>
                })
            }
        </tr>
    );
};
CalendarWeek.propTypes = {
    dayList: PropTypes.object.isRequired,
    weekNumber: PropTypes.string.isRequired,
    weekClubUsers: PropTypes.object,
    weekClubEvents: PropTypes.object,
    hasMessaging: PropTypes.bool.isRequired,
    handleNewEvent: PropTypes.func.isRequired,
    handleEditEvent: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired
};
const CalendarWeekDay = ({weekday, weekDayClubEvents, weekDayClubUsers, hasMessaging, handleNewEvent, handleEditEvent, handleDeleteEvent}) => {
    let backgroundC = isToday(weekday) ? "grey" : "transparent";
    return (<td style={{minHeight:"180px" }}><ListGroup>
                <ListGroupItem style={{backgroundColor: backgroundC, width:"100%", textAlign: "left"}}
                               onClick={() => handleNewEvent(new Date(weekday.getFullYear(), weekday.getMonth(),  weekday.getDate(), 2, 0, 0))}>
                    <strong>{weekday.getDate()}</strong>
                </ListGroupItem>
            {
                weekDayClubEvents != null ? <CalendarWeekDayEvents clubEvents={weekDayClubEvents} hasMessaging={hasMessaging}
                                                                   handleEditEvent={handleEditEvent} handleDeleteEvent={handleDeleteEvent}/> : null
            }
            {
                weekDayClubUsers != null ?  <CalendarWeekDayUsers clubUsers={weekDayClubUsers}/>: null
            }
            </ListGroup></td>
    );
};
CalendarWeekDay.propTypes = {
    weekday: PropTypes.object.isRequired,
    weekDayClubUsers: PropTypes.array,
    weekDayClubEvents: PropTypes.array,
    hasMessaging: PropTypes.bool.isRequired,
    handleNewEvent: PropTypes.func.isRequired,
    handleEditEvent: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired
};
class CalendarWeekDayEvents extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.hideOverlay = this.hideOverlay.bind(this);
        this.handleClickEdit = this.handleClickEdit.bind(this);
        this.handleClickDelete = this.handleClickDelete.bind(this);

        this.state = {
            show: false,
            target: null
        }
    }
    handleClick(event) {
        if (event.target != this.state.target) {
            // new element on same day clicked
            this.setState({ target: event.target, show: true });
        } else {
            this.setState({ target: event.target, show: !this.state.show });
        }
    }
    hideOverlay() {
        this.setState({ show: false });
    }
    handleClickEdit(clubEvent) {
        this.props.handleEditEvent(clubEvent);
        this.hideOverlay()
    }
    handleClickDelete(clubEvent) {
        this.props.handleDeleteEvent(clubEvent);
        this.hideOverlay()
    }
    render() {
        const {clubEvents} = this.props;
        return <React.Fragment>
            {
                clubEvents.map(clubEvent => {
                    return <React.Fragment key={clubEvent.id}>
                            <ListGroupItem  style={{backgroundColor: "transparent", padding: "5px"}} key={clubEvent.id}>
                                <a onClick={this.handleClick}>{clubEvent.getName()}</a>
                                &nbsp;{clubEvent.isDraft()? <Label>{messages.get("club.event.create.draft")}</Label> : null}
                                &nbsp;{clubEvent.isPublic()? <Label>{messages.get("event.vis.public")}</Label> : null}
                            </ListGroupItem>
                            <Overlay bsClass="popover"
                                     show={this.state.show}
                                     target={this.state.target}
                                     placement="bottom"
                                     container={this}>
                                <Popover id="popover-positioned-bottom"
                                         title={<Row>
                                                    <Col xs={9}>
                                                        <h4><a href={linkUtil.getLink("/club/event/" + clubEvent.id)} target="_blank">{clubEvent.getName()}</a></h4>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Button bsStyle="link" onClick={this.hideOverlay}>x</Button>
                                                    </Col>
                                                </Row>}>
                                    <FontAwesome icon="calendar-alt"/>{clubEvent.getEventStartDate()} - {clubEvent.getEventEndDate()}<br/>
                                    {
                                        clubEvent.getPlace() ? <FontAwesome icon="map-marker-alt">{clubEvent.getPlace()}</FontAwesome>: null
                                    }
                                    <br/>
                                    {
                                        this.props.hasMessaging ?
                                            <ButtonGroup>
                                                <Button onClick={() => this.handleClickEdit(clubEvent)}><FontAwesome icon="pencil-alt"/></Button>
                                                <Button onClick={() => this.handleClickDelete(clubEvent)}><FontAwesome icon="trash"/></Button>
                                            </ButtonGroup> : null
                                    }
                                </Popover>
                            </Overlay>
                        </React.Fragment>
                })
            }
        </React.Fragment>;

    }
}
CalendarWeekDayEvents.propTypes = {
    clubEvents: PropTypes.array.isRequired,
    hasMessaging: PropTypes.bool.isRequired,
    handleEditEvent: PropTypes.func.isRequired,
    handleDeleteEvent: PropTypes.func.isRequired
};
const CalendarWeekDayUsers = ({clubUsers}) =>  {
    return (<React.Fragment>
        {
            clubUsers.map(clubUser => {
                return <ListGroupItem style={{backgroundColor: "transparent", padding: "5px"}} key={clubUser.id}>
                    <FontAwesome icon="birthday-cake"/>{clubUser.getFullName()}
                </ListGroupItem>
            })
        }

    </React.Fragment>);
};
CalendarWeekDayUsers.propTypes = {
    clubUsers: PropTypes.array.isRequired
};
module.exports = ClubMonthCalendar;
