var Parse = require("ParseInit");

var ChangeLog = Parse.Object.extend("changelog", {
    col: {
        editor: "editor",
        adminComment: "adminComment",
        columnName: "columnName",
        newValue: "newValue",
        oldValue: "oldValue",
        grpPlaceID: "grpPlaceID",
        parcoursID: "parcoursID",
        parcoursOwnerID: "parcoursOwnerID",
        tournamentUserID: "tournamentUserID",
        tournamentID: "tournamentID",
        countTypeID: "countTypeID",
        managementID: "managementID",
        imageGalleryID: "imageGalleryID",
        objectId: "objectId",
        updatedAt: "updatedAt",
        createdAt: "createdAt"
    },
    status: {
        deleted: "D"
    },
    getEditorName: function() {
        var editor = this.get("editor");
        if (editor) {
            return editor.get("username");
        } else {
            return "unknown";
        }
    },
    getEditorEmail: function() {
        var editor = this.get("editor");
        if (editor) {
            return editor.get("email");
        } else {
            return "unknown";
        }
    },
    getChangeDate: function() {
        var startDate = this.getCreatedAt();
        return startDate.getDate() + "." + (startDate.getMonth() + 1) + "." + startDate.getFullYear();
    },
    isDeregistration() {
        return this.getTournamentID() != null && this.getColumnName() === "TUstatus" && this.getNewValue() === this.status.deleted;
    },
    isDeregistrationTUser() {
        return this.getTournamentID() != null && this.getTournamentUserID() != null
            && this.getColumnName() === "status" && this.getNewValue() === this.status.deleted;
    },
    isPayChange() {
        return this.getTournamentID() != null && this.getColumnName() === "payStatus"
    },
    isTournamentStatusChange() {
        return this.getTournamentID() != null && this.getTournamentUserID() == null
            && this.getColumnName() === "status"
    },
    isTournamentRegStatusChange() {
        return this.getTournamentID() != null && this.getTournamentUserID() == null
            && this.getColumnName() === "regStatus"
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getEditor: function () {
        return this.get(this.col.editor)
    },
    setEditor: function (editor) {
        this.set(this.col.editor, editor)
    },
    getAdminComment: function () {
        return this.get(this.col.adminComment)
    },
    setAdminComment: function (adminComment) {
        this.set(this.col.adminComment, adminComment)
    },
    getColumnName: function () {
        return this.get(this.col.columnName)
    },
    setColumnName: function (columnName) {
        this.set(this.col.columnName, columnName)
    },
    getNewValue: function () {
        return this.get(this.col.newValue)
    },
    setNewValue: function (newValue) {
        this.set(this.col.newValue, newValue)
    },
    getOldValue: function () {
        return this.get(this.col.oldValue)
    },
    setOldValue: function (oldValue) {
        this.set(this.col.oldValue, oldValue)
    },
    getGrpPlaceID: function () {
        return this.get(this.col.grpPlaceID)
    },
    setGrpPlaceID: function (grpPlaceID) {
        this.set(this.col.grpPlaceID, grpPlaceID)
    },
    getParcoursID: function () {
        return this.get(this.col.parcoursID)
    },
    setParcoursID: function (parcoursID) {
        this.set(this.col.parcoursID, parcoursID)
    },
    getParcoursOwnerID: function () {
        return this.get(this.col.parcoursOwnerID)
    },
    getManagementID: function () {
        return this.get(this.col.managementID)
    },
    setManagementID: function (managementID) {
        this.set(this.col.managementID, managementID)
    },
    setParcoursOwnerID: function (parcoursOwnerID) {
        this.set(this.col.parcoursOwnerID, parcoursOwnerID)
    },
    getTournamentUserID: function () {
        return this.get(this.col.tournamentUserID)
    },
    setTournamentUserID: function (tournamentUserID) {
        return this.set(this.col.tournamentUserID, tournamentUserID)
    },
    getTournamentID: function () {
        return this.get(this.col.tournamentID)
    },
    setTournamentID: function (tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getCountTypeID: function () {
        return this.get(this.col.countTypeID)
    },
    getImageGalleryID: function () {
        return this.get(this.col.imageGalleryID)
    }
});

module.exports = ChangeLog;
