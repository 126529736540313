const React = require("react"),
    PropTypes = require('prop-types');

const FontAwesome = require("components/widgets/FontAwesome"),
    LoadingCircle = require("components/widgets/LoadingCircle");

class ErrorSuspenseBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null
        }
    }
    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        console.log("ComponentDidCatch ErrorSuspenseBoundary: ", error, errorInfo);
    }
    render() {
        const {error} = this.state;
        if (error) {
            // You can render any custom fallback UI
            return <FontAwesome icon="bug">{error.toString()}</FontAwesome>;
        }
        return <React.Suspense fallback={<div><LoadingCircle /></div>}>
            {this.props.children}
        </React.Suspense>
    }
}
ErrorSuspenseBoundary.propTypes = {
    children:PropTypes.any.isRequired
};
module.exports = ErrorSuspenseBoundary;
