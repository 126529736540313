const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,Panel, Alert, Button} = require("react-bootstrap");

const ExistingBusinessAccountInfo = require("components/business/ExistingBusinessAccountInfo").ExistingBusinessAccountInfo;

const I18n = require("components/widgets/I18n"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages");

const accountStore = require("stores/AccountStore"),
    accountActions = require("actions/AccountActions"),
    clubActions = require("actions/ClubActions");

class ClubAddBusinessAccount extends React.Component {
    constructor(props) {
        super(props);
        this.handleAccountChange = this.handleAccountChange.bind(this);
        this.handleLink2Business = this.handleLink2Business.bind(this);

        this.state = {
            accountStore: accountStore.getState(),
        }
    }
    componentDidMount() {
        accountStore.listen(this.handleAccountChange);
        accountActions.queryInvolvedManagement.defer(this.props.user);
    }
    componentWillUnmount() {
        accountStore.unlisten(this.handleAccountChange);
    }
    handleAccountChange(state) {
        this.setState({accountStore: state});
    }
    handleLink2Business(management) {
        clubActions.link2BusinessAccount(this.props.club, management);
    }
    render() {
            return (
            <Panel>
                <Panel.Body>
                    <Row>
                        <Col lg={12}>
                            <h3><I18n code="profile.account.business.header"/></h3>
                            <p>{messages.get("club.business.newAccount.desc1") + " "}</p>
                            <Alert bsStyle="info"><I18n code="club.business.link2BusinessAccount.desc"/></Alert>
                        </Col>
                    </Row>
                    {
                        this.state.accountStore.managements.length <= 0 ?
                            <Button bsStyle="success" href={linkUtil.getLink("customer/register/club/" + this.props.club.id)}>
                                {messages.get("tournament.manager.newAccount.button")}</Button> : null
                    }
                    {this.state.accountStore.managements.length > 0 ?
                        <ExistingBusinessAccountInfo manageCallback={this.handleLink2Business} managements={this.state.accountStore.managements}/> : null}
                </Panel.Body>
            </Panel>)
    }
}
ClubAddBusinessAccount.propTypes = {
    club:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired
};
module.exports = ClubAddBusinessAccount;
