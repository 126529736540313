const Parse = require("ParseInit");
const {Club, ClubUser, ClubEvent, ClubInvoice, GroupPlace} = require("parse/_Domain");

class ClubDao {

    searchOwnClubs(user) {
        let cuQuery = new Parse.Query(ClubUser)
        cuQuery.equalTo(ClubUser.prototype.col.userID, user)
        cuQuery.notEqualTo(ClubUser.prototype.col.status, ClubUser.prototype.status.deleted);
        cuQuery.exists(ClubUser.prototype.col.clubID)
        cuQuery.include(ClubUser.prototype.col.clubID)
        return cuQuery.find();
    }

    searchClubs(searchParams) {
        let queries = [];
        let query = new Parse.Query(Club);
        this.applyAndFilter(searchParams, query);
        queries.push(query);
        if (searchParams.searchText != null) {
            // search for name
            query.matches("name", "(" + searchParams.searchText + ")", "i");
            let zip = parseInt(searchParams.searchText);
            if (zip > 0) {
                // search by zip
                let orZipQuery = new Parse.Query(Club);
                this.applyAndFilter(searchParams, orZipQuery);
                orZipQuery.matches("zip", "(" + zip + ")", "i");
                queries.push(orZipQuery);
            } else {
                // search by place
                let orPlaceQuery = new Parse.Query(Club);
                this.applyAndFilter(searchParams, orPlaceQuery);
                orPlaceQuery.matches("place", "(" + searchParams.searchText + ")", "i");
                queries.push(orPlaceQuery);
            }
        }
        let superQuery = Parse.Query.or.apply(Parse.Query, queries);
        superQuery.ascending(Club.prototype.col.name);
        return superQuery.find();
    }

    applyAndFilter(searchParams, query) {
        if (searchParams.mainGroupPlaceID != null) {
            let mainGroupPlace = new GroupPlace();
            mainGroupPlace.id = searchParams.mainGroupPlaceID;
            query.equalTo(Club.prototype.col.mainGroupPlaceID, mainGroupPlace);
        }
        if (searchParams.regionGroupPlaceID != null) {
            let regionGroupPlace = new GroupPlace();
            regionGroupPlace.id = searchParams.regionGroupPlaceID;
            query.equalTo(Club.prototype.col.groupPlaceID, regionGroupPlace);
        }
        query.notEqualTo(Club.prototype.col.status, Club.prototype.status.deleted);
    }

    loadClubDetails(id) {
        let query = new Parse.Query(Club);
        query.include(Club.prototype.col.bannerImageID);
        query.include(Club.prototype.col.mainEditor);
        query.include(Club.prototype.col.managementID);
        query.include(Club.prototype.col.articles);
        query.include(Club.prototype.col.paymentTargetID);
        return query.get(id);
    }

    loadClubUserList(club, neededClubUserRole, neededUser) {
        let query = new Parse.Query(ClubUser);
        query.equalTo(ClubUser.prototype.col.clubID, club);
        query.notEqualTo(ClubUser.prototype.col.status, ClubUser.prototype.status.deleted);
        query.notEqualTo(ClubUser.prototype.col.reqStatus, ClubUser.prototype.reqStati.declined);
        if (neededUser) {
            query.equalTo(ClubUser.prototype.col.userID, neededUser);
        }
        query.include(ClubUser.prototype.col.userID);
        query.include(ClubUser.prototype.col.articleID);
        query.include(ClubUser.prototype.col.addressTwoID);
        query.include(ClubUser.prototype.col.userDetailID);
        query.include(ClubUser.prototype.col.paymentTargetID);
        query.include(ClubUser.prototype.col.userDetailID + ".addressID");
        query.include(ClubUser.prototype.col.userDetailID + ".licenseIDs");
        query.include(ClubUser.prototype.col.userDetailID + ".licenseIDs.bowUnionID");
        query.include(ClubUser.prototype.col.userDetailID + ".licenseIDs.ageTypeID");
        query.include(ClubUser.prototype.col.userDetailID + ".licenseIDs.bowTypeID");
        query.ascending(ClubUser.prototype.col.userNr);
        query.limit(1000);
        return query.find();
    }

    loadClubUserListCC(clubEvent, secret) {
        return Parse.Cloud.run('loadClubUserEventList', {clubEventID: clubEvent.id, secret:secret});
    }

    loadClubEvents(club, ownClubUser, searchParams) {
        let query = new Parse.Query(ClubEvent);
        query.equalTo(ClubEvent.prototype.col.clubID, club);
        if (ownClubUser == null || ownClubUser.isExMember()) {
            // foreign user or exMember - only load public and active events
            query.equalTo(ClubEvent.prototype.col.visibility,  ClubEvent.prototype.vis.publi);
            query.equalTo(ClubEvent.prototype.col.status, ClubEvent.prototype.status.published);
        } else {
            // club member - load all but deleted one
            query.notEqualTo(ClubEvent.prototype.col.status, ClubUser.prototype.status.deleted);
        }
        if (searchParams != null) {

            if (searchParams.year) {
                const startDate = new Date(searchParams.year,0,1);
                const endDate = new Date(searchParams.year,12,0,23,59,59,0);
                query.greaterThanOrEqualTo(ClubEvent.prototype.col.endDate, startDate);
                query.lessThanOrEqualTo(ClubEvent.prototype.col.date, endDate);
            } else {
                throw "missing arg: dateFilter";
            }
        }
        return query.find();
    }

    loadClubInvoices(club, year) {
        let startDate = new Date(year,0,1);
        let endDate = new Date(year,12,0,23,59,59,0);
        let query = new Parse.Query(ClubInvoice);
        query.equalTo(ClubInvoice.prototype.col.clubID, club);
        query.notEqualTo(ClubInvoice.prototype.col.status, ClubInvoice.prototype.status.deleted);
        query.greaterThanOrEqualTo(ClubInvoice.prototype.col.date, startDate);
        query.lessThanOrEqualTo(ClubInvoice.prototype.col.date, endDate);
        query.ascending(ClubInvoice.prototype.col.date +  "," + ClubInvoice.prototype.col.invNumber );
        query.limit(1000);
        return query.find();
    }

    loadClubInvoice(clubInvoiceID) {
        let query = new Parse.Query(ClubInvoice);
        query.include(ClubInvoice.prototype.col.clubID);
        query.include(ClubInvoice.prototype.col.clubID + "." + Club.prototype.col.paymentTargetID);
        query.include(ClubInvoice.prototype.col.addressID);
        query.include(ClubInvoice.prototype.col.parcoursArticles);
        return query.get(clubInvoiceID);
    }

    loadClubEventDetails(id) {
        let query = new Parse.Query(ClubEvent);
        query.include(ClubEvent.prototype.col.clubID);
        return query.get(id);
    }

    updateClubUserFavorite(club, add2Club, user) {
        if (user != null) {
            return Parse.Cloud.run('userFavoriteClub', {clubID: club.id, add2Club: add2Club, userID: user.id});
        }
    };

    updateClubEventOption(clubEvent, clubUser, option) {
        return Parse.Cloud.run('setClubEventOption', {clubEventID: clubEvent.id, option: option, cuID: clubUser.id, cuName: clubUser.getFullName()});
    }

    removeClubEventOption(clubEvent, clubUserID, option) {
        return Parse.Cloud.run('removeClubEventOption', {clubEventID: clubEvent.id, option: option, cuID: clubUserID});
    }
    markClubInvoiceAsPaid(clubInvoiceID) {
        return Parse.Cloud.run('markClubInvoiceAsPaid', {clubInvoiceID: clubInvoiceID.id});
    }

    updateClubUserAddress(usrDetail, address) {
        return Parse.Cloud.run('updateUserDetail', {udID: usrDetail.id,
            name: usrDetail.getName(),
            surname: usrDetail.getSurname(),
            sex: usrDetail.getSex(),
            birthday: usrDetail.getBirthday(),
            street: address.getStreet(),
            zip: address.getZip(),
            place: address.getPlace(),
            mail: address.getMail(),
            phone: address.getPhone(),
            mail2: address.getMail2(),
            phone2: address.getPhone2()
        });
    };

    deleteUserDetail(usrDetail) {
        return Parse.Cloud.run('deleteUserDetail', {udID: usrDetail.id});
    }

}
module.exports = new ClubDao();
