const Parse = require("ParseInit");

const ParcoursArticle = Parse.Object.extend("parcoursArticle", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        currency: "currency",
        group: "group",
        name: "name",
        description: "desc",
        objectId: "objectId",
        price: "price",
        oldPrice: "oldPrice",
        minAmount:"minAmount",
        shortCode: "shortCode",
        status: "status",
        extRefID: "extRefID",
        afterPayment: "afterPayment",
        updatedAt: "updatedAt"
    },
    status: {
        active: "A",
        inactive: "I",
        deleted: "D"
    },
    groupType : {
        member: "member",
        invoice: "invoice"
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    isActive() {
        return this.status.active === this.getStatus();
    },
    setArticleACL(user) {
        var newACL = new Parse.ACL(user);
        newACL.setPublicReadAccess(true);
        this.setACL(newACL);
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getSelectString() {
        return this.getName() + " (" + this.getPrice() + " " + this.getCurrency() + ")";
    },
    getCurrency() {
        return this.get(this.col.currency)
    },
    setCurrency(currency) {
        return this.set(this.col.currency, currency)
    },
    isGroupMember() {
        return this.getGroup() == this.groupType.member
    },
    isGroupInvoice() {
        return this.getGroup() == this.groupType.invoice
    },
    getGroup() {
        return this.get(this.col.group)
    },
    setGroup(group) {
        return this.set(this.col.group, group)
    },
    getShortCode() {
        return this.get(this.col.shortCode)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getDescription() {
        return this.get(this.col.description)
    },
    setDescription(description) {
        return this.set(this.col.description, description)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getPrice() {
        return this.get(this.col.price)
    },
    setPrice(price) {
        return this.set(this.col.price, price)
    },
    getOldPrice() {
        return this.get(this.col.oldPrice)
    },
    getMinAmount() {
        if (this.get(this.col.minAmount)) {
            return this.get(this.col.minAmount);
        }
        return 0;
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ParcoursArticle;
