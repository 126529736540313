var Parse = require("ParseInit");

var TransactionSettlementPosition = Parse.Object.extend("transactionSettlementPosition", {
    col: {
          ACL: "ACL",
          createdAt: "createdAt",
          brand: "brand",
          objectId: "objectId",
          amount: "amount",
          sum: "sum",
          income: "income",
          provision: "provision",
          status: "status",
          updatedAt: "updatedAt"
    },
    getTransactionDate: function() {
        var tDate = this.getCreatedAt();
        return tDate.getDate() + "." + (tDate.getMonth() + 1) + " " + tDate.getFullYear();
    },
    getTransactionTime: function() {
        // TODO check timezone maybe hours are not correct
        var tDate = this.getCreatedAt();
        return tDate.getHours() + ":" + tDate.getMinutes() + ":" + tDate.getSeconds();
    },
    getName:function () {
        return this.getBrand();
    },
    getSinglePrice: function () {
        return parseFloat(this.getSum()).toFixed(2);
    },
    getFormattedSum: function (currency) {
        return currency + " " + parseFloat(this.getIncome()).toFixed(2);
    },
    getACL: function() {
        return this.get(this.col.ACL)
    },
    setACL: function(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt: function() {
        return this.get(this.col.createdAt)
    },
    getBrand: function() {
        return this.get(this.col.brand)
    },
    setBrand: function(brand) {
        return this.set(this.col.brand, brand)
    },
    getObjectId: function() {
        return this.get(this.col.objectId)
    },
    setObjectId: function(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getAmount: function() {
        return this.get(this.col.amount)
    },
    setAmount: function(amount) {
        return this.set(this.col.amount, amount)
    },
    getSum: function() {
        return this.get(this.col.sum)
    },
    setSum: function(sum) {
        return this.set(this.col.sum, sum)
    },
    getIncome: function() {
        return this.get(this.col.income)
    },
    setIncome: function(income) {
        return this.set(this.col.income, income)
    },
    getProvision: function() {
        return this.get(this.col.provision)
    },
    setProvision: function(provision) {
        return this.set(this.col.provision, provision)
    },
    getStatus: function() {
        return this.get(this.col.status)
    },
    setStatus: function(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt: function() {
        return this.get(this.col.updatedAt)
    }
});

module.exports = TransactionSettlementPosition;
