const React = require("react"),
    PropTypes = require('prop-types');
const  MultiButtonGroup = require("components/form/MultiButtonGroup");
const TrainingFilterEntity = require("components/training/TrainingFilterEntity");

const TrainingFilterButtonGroup = ({filterState, objectList, stateKey, text, disabled, updateSearchParams}) => {
    function onChange(selectedList) {
        updateSearchParams(stateKey, selectedList)
    }
    return <TrainingFilterEntity filterState={filterState} stateKey={stateKey} text={text} disabled={disabled}
                                 needReloadEvents={false} updateSearchParams={updateSearchParams}>
        <MultiButtonGroup disabled={disabled} onAfterChange={onChange} objectList={objectList} defaultValue={filterState}/>

    </TrainingFilterEntity>
}
TrainingFilterButtonGroup.propTypes = {
    filterState: PropTypes.array,
    stateKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    updateSearchParams: PropTypes.func.isRequired,
}
module.exports = TrainingFilterButtonGroup;
