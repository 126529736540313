const Parse = require("ParseInit");

const EventTrainingTeams = Parse.Object.extend("EventTrainingTeams", {
    col: {
        name: "name",
        addEditor: "addEditor",
        mainEditor: "mainEditor",
        userVisible: "userVisible",
        status: "status",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    //
    //
    //
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getMainEditor() {
        return this.get(this.col.mainEditor)
    },
    setMainEditor(mainEditor) {
        return this.set(this.col.mainEditor, mainEditor)
    },
    getAddEditor() {
        return this.get(this.col.addEditor)
    },
    setAddEditor(addEditor) {
        return this.set(this.col.addEditor, addEditor)
    },
    isEditor(user) {
        let result = false;
        if (this.getMainEditor() && user) {
            if (user.id == this.getMainEditor().id) {
                result = true;
            } else {
                var addEditors = this.getAddEditor();
                if (addEditors) {
                    addEditors.map(editor =>{
                        if (user.id == editor.id) {
                            result = true;
                        }
                    });
                }
            }
        }
        return result;
    },
    isUserVisibleToEachOther() {
        return this.get(this.col.userVisible)
    },
    setUserVisible(visible) {
        this.set(this.col.userVisible, visible)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    }
});

module.exports = EventTrainingTeams;
