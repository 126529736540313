const React = require("react"),
    PropTypes = require('prop-types'),
    linkUtil = require("linkUtil");
const {Row, Col, Grid,  PageHeader, Badge, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    DropDownParseObject = require("components/form/DropDownParseObject"),
    JSONFileImporter = require("components/form/JSONFileImporter"),
    TargetCatalogModal = require("components/modals/TargetCatalogModal"),
    DelayedTextField = require("components/form/DelayedTextField"),
    Loading = require("components/widgets/Loading");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const messages = require("i18n/messages");

const userStore = require("stores/UserStore"),
    userAction = require("actions/UserActions")

    parcoursTargetActions = require("actions/ParcoursTargetActions"),
    parcoursTargetStore = require("stores/ParcoursTargetStore");

class TargetCatalogPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleNoTargetSelected = this.handleNoTargetSelected.bind(this);
        this.handleParcoursTargetChange = this.handleParcoursTargetChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.state = {
            brandID: null,
            userStore: userStore.getState(),
            parcoursTargetStore: parcoursTargetStore.getState()
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
        parcoursTargetStore.listen(this.handleParcoursTargetChange);
        parcoursTargetActions.loadTargetBrandCatalog.defer();
        if (this.props.tstat == "admin") {
            userAction.getIsAdmin();
        }
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
        parcoursTargetStore.unlisten(this.handleParcoursTargetChange);
    }
    handleParcoursTargetChange(state) {
        this.setState({parcoursTargetStore: state});
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    handleNoTargetSelected(name) {
        const sParam = this.state.parcoursTargetStore.searchParams;
        sParam.text = name;
        parcoursTargetActions.searchTargetCatalog(sParam)
    }
    handleBrandChange(brandID) {
        const sParam = this.state.parcoursTargetStore.searchParams;
        sParam.brandID = brandID;
        parcoursTargetActions.searchTargetCatalog(sParam);
        this.setState({brandID: brandID})
    }
    render() {
        const {userStore, parcoursTargetStore, brandID} = this.state;
        const {loadingCatalog, targetCatalog, targetBrands, newImportTargets, updateImportTarget} = parcoursTargetStore;
        return (
            <Grid>
                <Row>
                    <Col lg={12}>
                        <PageHeader>{"ZielKatalog"}</PageHeader>
                    </Col>
                </Row>
                {
                    loadingCatalog === true ?
                        <Row><Col xs={12}><Loading/></Col></Row>
                        :
                        <React.Fragment>
                            <Row>
                                <Col xs={6}>
                                    <DelayedTextField placeholder={messages.get("parcours.targets.input.name.search")}
                                                      onChange={this.handleNoTargetSelected}/>
                                </Col>
                                <Col xs={2}>
                                    <DropDownParseObject placeholder={<option key={"nullkey"} value={"null"}>{"Wähle Hersteller"}</option>}
                                                         onAfterChange={this.handleBrandChange}
                                                         objectList={targetBrands}/>
                                </Col>
                            </Row>

                            <TargetCatalogModal targetBrands={targetBrands} >
                            {
                                userStore.isAdmin === true ? <AdminRow brandID={brandID} targetCatalog={targetCatalog}/> :null
                            }
                            <hr/>
                            {
                                newImportTargets != null ? <NewTargetList newImportTargets={newImportTargets}/> : null
                            }
                            <TargetList targetCatalog={targetCatalog} />
                            </TargetCatalogModal>
                        </React.Fragment>
                }

            </Grid>)
    }
}
TargetCatalogPage.propTypes = {
    tstat: PropTypes.string
};
const AdminRow = ({brandID, targetCatalog}) => {
    function receivedLineArray(objectArray) {
        parcoursTargetActions.handleImportTargetCatalog(objectArray, brandID, targetCatalog)
    }
    return <Row>
        <Col xs={6}>
            {
                brandID != null ? <JSONFileImporter startImportArray={receivedLineArray}
                                                    optJsonKeys={["image", "price"]}
                                                    mandJsonKeys={["name","id"]}
                                                    title={brandID.getName() + ".json zu importieren"}/> :
                    <h3>Hersteller auswählen für Zielimport!</h3>
            }

        </Col>
    </Row>
}
const NewTargetList = ({newImportTargets}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function clickedEditTarget(target) {
        modalBasics.openObjectFct(target, "create")
    }
    function createTarget(target) {
        parcoursTargetActions.saveTargetCatalog(target);
    }
    return <Row>
        <h3>{newImportTargets.length} neue Ziele aus Import Datei</h3>
        {
            newImportTargets.map(target => {
                return <NewTargetElement key={target.getBrandArtNo} target={target}
                                         createTarget={createTarget} editTarget={clickedEditTarget}/>
            })
        }
        <hr/>
    </Row>
};
const NewTargetElement = ({target, createTarget, editTarget}) => {
    function onClick(event) {
        event.preventDefault();
        editTarget(target);
    }
    let imgUrl = target.getDatalistImage();
    return <Col xs={12} sm={6} md={4}  style={{height:"100px"}}>
        <Col xs={4}>
            {imgUrl != null ? <img src={imgUrl}  height="60"/> : null}
        </Col>
        <Col xs={8}>
            <strong><a href="#" onClick={onClick}>{target.getName()}</a>&nbsp;&nbsp;<Badge>{target.getGroup()}</Badge></strong>
            &nbsp;&nbsp;€{target.getPrice()}<br/>
            {
                target.id != null ? null :
                    <Button bsStyle="primary" onClick={() => createTarget(target)}>Create {target.getBrandArtNo()}</Button>
            }

        </Col>
    </Col>
};
NewTargetElement.propTypes = {
    target: PropTypes.object.isRequired,
    createTarget: PropTypes.func.isRequired
};
const TargetList = ({targetCatalog}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function clickedEditTarget(target) {
        modalBasics.openObjectFct(target, "edit")
    }
    return <Row>
        {
            targetCatalog.map(target => {
                return <TargetElement key={target.id} target={target} editTarget={clickedEditTarget}/>
            })
        }
    </Row>
};
TargetList.propTypes = {
    targetCatalog: PropTypes.array.isRequired,
    editTarget: PropTypes.func.isRequired,
};
const TargetElement = ({target, editTarget}) => {
    function onClick(event) {
        event.preventDefault();
        editTarget(target);
    }
    let imgUrl = target.getDatalistImage();
    return <Col xs={12} sm={6} md={4}  style={{height:"75px"}}>
        <Col xs={4}>
            {imgUrl != null ? <img src={imgUrl}  height="60"/> : null}
        </Col>
        <Col xs={8}>
            <strong><a href="#" onClick={onClick}>{target.getName()}</a>
            &nbsp;&nbsp;<Badge>{target.getGroup()}</Badge></strong><br/>
            {target.getBrandArtNo() + " , " + target.getBrandID().getName() }
        </Col>
    </Col>
};
TargetElement.propTypes = {
    target: PropTypes.object.isRequired,
    editTarget: PropTypes.func.isRequired
};
module.exports = TargetCatalogPage;
