const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Panel} = require("react-bootstrap");

const PanelCollapse = ({header, expand, children}) => {
    return <Panel defaultExpanded={expand == null ? true : expand}>
        <Panel.Heading>
            <Panel.Title toggle>
                <Row>
                    <Col xs={12}>{header}</Col>
                </Row>
            </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>{children}</Panel.Body>
    </Panel>
};
PanelCollapse.propTypes = {
    header: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    expand: PropTypes.bool
};
module.exports = PanelCollapse;
