const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col} = require("react-bootstrap");

const ParcoursRankingButton = require("components/links/ParcoursRankingButton"),
    ParcoursTargetButton = require("components/links/ParcoursTargetButton"),
    ParcoursVisitButton = require("components/links/ParcoursVisitButton"),
    ParcoursPaymentButton = require("components/links/ParcoursPaymentButton");

const ParcoursActionButtons = ({parcours}) => {
    const bPay = parcours.hasPaymentEnabled();
    const bVisit = parcours.hasVisitEnabled();
    const bRank = parcours.hasRankingEnabled();
    const bTarget = parcours.hasTargetEnabled(false);
    return (
        <React.Fragment>
            {
                bPay || bVisit ?
                    <Row>
                        <Col xs={6} sm={4} smOffset={1}>
                            {
                                bPay ? <ParcoursPaymentButton block parcours={parcours}/> : null
                            }
                        </Col>
                        <Col xs={6} sm={4} smOffset={2}>
                            {
                                bVisit ? <ParcoursVisitButton block parcours={parcours}/> : null
                            }
                        </Col>
                    </Row> : null
            }
            {
                (bPay || bVisit) && (bRank || bTarget) ? <br/> : null
            }
            {
                bRank || bTarget ?
                    <Row>
                        <Col xs={6} sm={4} smOffset={1}>
                            {
                                bRank ? <ParcoursRankingButton block parcours={parcours}/> : null
                            }
                        </Col>
                        <Col xs={6} sm={4} smOffset={2}>
                            {
                                bTarget ? <ParcoursTargetButton block parcours={parcours}/> : null
                            }
                        </Col>
                    </Row> : null
            }

        </React.Fragment>)
};
ParcoursActionButtons.propTypes = {
    parcours: PropTypes.object.isRequired,
};
module.exports = ParcoursActionButtons;
