const React = require("react");
const {Label} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");
const AttendeePaid = () => {
    return (<Label bsStyle="success"><FontAwesome icon="check"/></Label>)
};
const AttendeePending = () => {
    return (<Label bsStyle="info"><FontAwesome icon="clock"/></Label>)
};
const AttendeeExceed = () => {
    return (<Label bsStyle="warning"><FontAwesome icon="exclamation-circle"/></Label>)
};
module.exports = {
    attendee: {
        paid: AttendeePaid,
        pending: AttendeePending,
        exceed: AttendeeExceed
    }
};