const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, Radio} = require("react-bootstrap");
const I18n = require("components/widgets/I18n");
    
class RadioGroupInput extends React.Component {
    constructor(props) {
        super(props);
        this.getValue = this.getValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        
        this.state = {
            value: this.props.default,
            list: this.props.list
        }
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(option) {
        this.setValue(option);
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(option);
        }
    }
    isChecked(option) {
        return this.state.value === option;
    }
    render() {
        if (this.state.list == null) {
            return null;
        } else {
            var that = this;
            return (<FormGroup>
                {
                    this.state.list.map(option => {
                        return (<Radio key={"opt_" + option} inline
                                       disabled={this.props.disabled}
                                       onChange={function() {
                                           that.handleChange(option);
                                       }}
                                       checked={that.state.value === option}>
                            <I18n code={option}/>
                        </Radio>)
                    })
                }
            </FormGroup>)
        }
    }
}
RadioGroupInput.propTypes = {
    list: PropTypes.array,
    disabled: PropTypes.bool,
    default: PropTypes.string,
    onAfterChange: PropTypes.func
};

module.exports = RadioGroupInput;
