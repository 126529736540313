const Parse = require("ParseInit");
const EventTarget = Parse.Object.extend("EventTarget", {
    col: {
        eventID: "eventID",
        name: "name",
        targetIndex: "index",
        status: "status",
        group: "group"
    },
    status: {
        active: "A",
        deleted: "D"
    },
    isDeleted() {
        return "D" === this.get('status');
    },
    getEventID() {
        return this.get(this.col.eventID)
    },
    getName() {
        return this.get(this.col.name)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    getGroup() {
        return this.get(this.col.group)
    },
    getTargetIndex() {
        return this.get(this.col.targetIndex)
    },
    setTargetIndex(targetIndex) {
        return this.set(this.col.targetIndex, targetIndex)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = EventTarget;
