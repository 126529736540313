const React = require("react");
export const ModalBasicContext = React.createContext({
    showModal: false,
    submitDisabled: false,
    submitLoading: false,
    error: null,
    openFct: () => {},
    openObjectFct: () => {},
    closeFct: () => {},
    setErrorFct: () => {},
    disableSubmitFct: () => {},
    setSubmitLoadingFct: () => {}
});