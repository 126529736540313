const React = require("react"),
    PropTypes = require('prop-types');
const {Panel} = require("react-bootstrap");

const PanelCollapsed = ({header, children}) => {
    return <Panel>
        <Panel.Heading>
            <Panel.Title toggle>
                {header}
            </Panel.Title>
        </Panel.Heading>
        <Panel.Body collapsible>{children}</Panel.Body>
    </Panel>
};
PanelCollapsed.propTypes = {
    header: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired
};
module.exports = PanelCollapsed;
