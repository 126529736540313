const TournamentUtility = {
    GROUP_CHARS: function () {
        return ["A","B","C","D","E","F","G","H","I","J","K","L"];

    },
    POS_KEY_INDEX: function () {
        return {"A":0,"B":1,"C":2,"D":3,"E":4,"F":5,"G":6,"H":7,"I":8,"J":9, "L": 10}

    }
};
module.exports = TournamentUtility;
