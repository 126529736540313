const Parse = require("ParseInit");

const PointOfInterest = Parse.Object.extend("pointInterest", {
    col: {
        ACL: "ACL",
        bannerImageID: "bannerImageID",
        type: "type",
        coordinates: "coordinates",
        createdAt: "createdAt",
        description: "description",
        groupPlaceID: "groupPlaceID",
        groupPlaceIDs: "groupPlaceIDs",
        mainGroupPlaceID: "mainGroupPlaceID",
        mainGroupPlaceIDs: "mainGroupPlaceIDs",
        name: "name",
        objectId: "objectId",
        place: "place",
        status: "status",
        street: "street",
        sortIndex: "sortIndex",
        updatedAt: "updatedAt",
        imageOnly: "imageonly",
        weblink: "weblink",
        zip: "zip"
    },
    type: {
        shop: "S",
        hotel: "H",
        webshop: "W"
    },
    getDatalistText: function () {
        return this.getName() + ", " +  this.getZipPlace();
    },
    getDatalistImage() {
        return null;
    },
    getLabels: function() {
        return [this.getName(), this.getZipPlace()].join(" ");
    },
    getZipPlace: function() {
      let tPlace = "";
      if (this.getZip()) {
        tPlace = this.getZip() + " ";
      }
      if (this.getPlace()) {
        tPlace += this.getPlace();
      }
      return tPlace;
    },
    getZipPlaceStreet: function () {
        let res = this.getZipPlace();
        if (this.getStreet()) {
            res += ", " + this.getStreet();
        }
        return res;
    },
    isImageOnly: function () {
        return this.get(this.col.imageOnly);
    },
    setImageOnly: function (imageOnly) {
        return this.set(this.col.imageOnly, imageOnly);
    },
    isType: function (typeConst) {
       return typeConst === this.getType();
    },
    hasPaymentEnabled: function() {
        return false;
    },
    hasVisitEnabled() {
        return false;
    },
    getACL: function() {
        return this.get(this.col.ACL)
    },
    setACL: function(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    buildACL: function () {
        let acl = new Parse.ACL();
        acl.setPublicReadAccess(true);
        acl.setPublicWriteAccess(false);
        acl.setRoleReadAccess("3DSKBOadmin", true);
        acl.setRoleWriteAccess("3DSKBOadmin", true);
        this.setACL(acl);
    },
    getBannerImageID: function() {
      return this.get(this.col.bannerImageID)
    },
    setBannerImageID: function(bannerImageID) {
      return this.set(this.col.bannerImageID, bannerImageID)
    },
    getBannerImageUrl: function () {
        let bannerImageID = this.getBannerImageID();
        if (bannerImageID) {
            return bannerImageID.get("image").url();
        }
        return "";
    },
    getCoordinates: function() {
      return this.get(this.col.coordinates)
    },
    getReadableCoordinates: function() {
        let coords = this.getCoordinates();
        if (coords) {
            return coords._latitude + "," + coords._longitude;
        } else {
            return null;
        }
    },
    setCoordinateReadable:function (coordcsv) {
        const coordinates = coordcsv.trim().split(",");
        this.setCoordinates(new Parse.GeoPoint({latitude: parseFloat(coordinates[0]), longitude: parseFloat(coordinates[1])}));
    },
    setCoordinates: function(coordinates) {
      return this.set(this.col.coordinates, coordinates)
    },
    getCreatedAt: function() {
      return this.get(this.col.createdAt)
    },
    getDescription: function() {
      return this.get(this.col.description)
    },
    setDescription: function(description) {
      return this.set(this.col.description, description)
    },
    getGroupPlaceID: function() {
      return this.get(this.col.groupPlaceID)
    },
    setGroupPlaceID: function(groupPlaceID) {
      return this.set(this.col.groupPlaceID, groupPlaceID)
    },
    getGroupPlaceIDs: function() {
        return this.get(this.col.groupPlaceIDs)
    },
    setGroupPlaceIDs: function(groupPlaceIDs) {
        return this.set(this.col.groupPlaceIDs, groupPlaceIDs)
    },
    getMainGroupPlaceID: function() {
      return this.get(this.col.mainGroupPlaceID)
    },
    setMainGroupPlaceID: function(mainGroupPlaceID) {
      return this.set(this.col.mainGroupPlaceID, mainGroupPlaceID)
    },
    getMainGroupPlaceIDs: function() {
        return this.get(this.col.mainGroupPlaceIDs)
    },
    setMainGroupPlaceIDs: function(mainGroupPlaceIDs) {
        return this.set(this.col.mainGroupPlaceIDs, mainGroupPlaceIDs)
    },
    getName: function() {
      return this.get(this.col.name)
    },
    setName: function(name) {
      return this.set(this.col.name, name)
    },
    getObjectId: function() {
      return this.get(this.col.objectId)
    },
    setObjectId: function(objectId) {
      return this.set(this.col.objectId, objectId)
    },
    getPlace: function() {
      return this.get(this.col.place)
    },
    setPlace: function(place) {
      return this.set(this.col.place, place)
    },
    getStatus: function() {
      return this.get(this.col.status)
    },
    setStatus: function(status) {
      return this.set(this.col.status, status)
    },
    getType: function() {
        return this.get(this.col.type)
    },
    setType: function(type) {
        return this.set(this.col.type, type)
    },
    getStreet: function() {
      return this.get(this.col.street)
    },
    setStreet: function(street) {
      return this.set(this.col.street, street)
    },
    getSortIndex: function() {
        return this.get(this.col.sortIndex)
    },
    setSortIndex: function(sortIndex) {
        return this.set(this.col.sortIndex, sortIndex)
    },
    getUpdatedAt: function() {
      return this.get(this.col.updatedAt)
    },
    getWeblink: function() {
      return this.get(this.col.weblink)
    },
    setWeblink: function(weblink) {
      return this.set(this.col.weblink, weblink)
    },
    getZip: function() {
      return this.get(this.col.zip)
    },
    setZip: function(zip) {
      return this.set(this.col.zip, zip)
    }
});

module.exports = PointOfInterest;
