const Parse = require("ParseInit");

const PaymentTarget = Parse.Object.extend("paymentTarget", {
    col: {
        ACL: "ACL",
        cardBIC: "cardBIC",
        cardIBAN: "cardIBAN",
        cardName: "cardName",
        invPayCondition:"invPayCondition",
        createdAt: "createdAt",
        objectId: "objectId",
        status: "status",
        updatedAt: "updatedAt",
    },
    isValid() {
        return this.getCardIBAN() != null && this.getCardBIC() != null && this.getCardName() != null
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCardBIC() {
        return this.get(this.col.cardBIC)
    },
    setCardBIC(cardBIC) {
        return this.set(this.col.cardBIC, cardBIC)
    },
    getCardIBAN() {
        return this.get(this.col.cardIBAN)
    },
    setCardIBAN(cardIBAN) {
        return this.set(this.col.cardIBAN, cardIBAN)
    },
    getCardName() {
        return this.get(this.col.cardName)
    },
    setCardName(cardName) {
        return this.set(this.col.cardName, cardName)
    },
    getInvoicePaymentCondition() {
        return this.get(this.col.invPayCondition)
    },
    setInvoicePaymentCondition(condition) {
        return this.set(this.col.invPayCondition, condition)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = PaymentTarget;
