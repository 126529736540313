const React = require("react"),
    PropTypes = require('prop-types');
const {Button, OverlayTrigger, Tooltip} = require("react-bootstrap");
const FontAwesome = require("components/widgets/FontAwesome");

const TooltipButton = ({icon, bsStyle, bsSize, onClick, disabled, tooltip, children, custClassName}) => {
        return (
            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                <Button bsStyle={bsStyle} bsSize={bsSize} onClick={onClick} disabled={disabled}
                    className={custClassName}>
                    { icon ? <FontAwesome icon={icon}/> : null}
                    { children }
                </Button>
            </OverlayTrigger>
        )
};
TooltipButton.propTypes = {
    tooltip: PropTypes.string.isRequired,
    onClick:PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    bsStyle: PropTypes.string,
    bsSize: PropTypes.string,
    icon: PropTypes.string,
    custClassName: PropTypes.string,
    children: PropTypes.any
};
module.exports = TooltipButton;
