/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput");
const messages = require("i18n/messages");

class ClubMailModal extends React.Component {
    constructor(props) {
        super(props);
        this.refMails = React.createRef();

        this.openInvitationMode = this.openInvitationMode.bind(this);
        this.openShowMailsMode = this.openShowMailsMode.bind(this);
        this.close = this.close.bind(this);
        this.showError = this.showError.bind(this);
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);

        this.state = {
            showModal: false,
            cuMails: null,
            submitDisabled: false,
            error: null
        }
    }
    openInvitationMode() {
        this.setState({
            showModal: true,
            cuMails: null
        });
    }
    openShowMailsMode(mails) {
        this.setState({
            showModal: true,
            cuMails: mails
        });
    }
    close() {
        this.setState({submitDisabled: false,showModal: false, error:null});
    }
    showError(error) {
        this.setState({submitDisabled: false, error: error});
    }
    valid() {
        let valid = true;
        let required = [
            this.refMails.current.getValue().trim()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].toString().trim().length == 0) {
                this.setState({error: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        this.setState({submitDisabled: true});
        if (this.props.handleAction) {
            let mails = this.refMails.current.getValue().trim();
            this.props.handleAction(mails);
        }
        this.close();
    }
    render() {
        const {showModal, error,  cuMails, submitDisabled} = this.state;
        let titleCode = "modal.mail.invitation.header";
        if (cuMails != null) {
            titleCode = "modal.mail.mailList.header";
        }
        return (
            <Modal backdrop="static" show={showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <I18n code={titleCode}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                       showModal ?
                            <form action="#" onSubmit={this.clickedSave}>
                                <Row>
                                    <Col xs={12}>

                                        <ValidInput ref={this.refMails}
                                                    componentClass="textarea"
                                                    rows={5}
                                                    valid={{maxLength: 500, check:['isRequired']}}
                                                    placeholder={messages.get("modal.mail.enterMailList")}
                                                    default={cuMails}/>
                                        {error == null ? null : <Alert bsStyle="danger">{error}</Alert>}
                                    </Col>
                                    {
                                        cuMails == null ?
                                            <Col className="text-center" xs={12}>
                                                <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                                                    <FontAwesome icon="envelope"/><I18n code={"contact.form.send"}/>
                                                </Button>
                                            </Col> : null
                                    }
                                </Row>

                            </form> : null
                    }
                </Modal.Body>
            </Modal>
        );
    }
}
ClubMailModal.propTypes = {
    handleAction:PropTypes.func,
};
module.exports = ClubMailModal;