const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Image} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    SubTitle = require("components/widgets/SubTitle");

const languageStore = require("stores/LanguageStore");

class GettingStarted extends React.Component {
    constructor(props) {
        super(props);
        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.state = {
            languageStore: languageStore.getState()
        }
    }
    componentDidMount() {
        languageStore.listen(this.handleLanguageChange);
    }
    componentWillUnmount() {
        languageStore.unlisten(this.handleLanguageChange);
    }
    handleLanguageChange(state) {
        this.setState({languageStore: state});
    }
    render() {
        const {imageDirPrefix, imageDeviceType} = this.state.languageStore;
        const imgDirDevice = imageDirPrefix + "/img/skillboard/" + (imageDeviceType === "I" ? "ios_" : "");
        return (
            <div>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.newevent.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.newevent.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.newevent.right"/>}
                    leftImage={"event_start.png"}
                    rightImage={"event_parcours.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.wiki.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.wiki.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.wiki.right"/>}
                   leftImage={"event_wiki_search.png"}
                   rightImage={"event_wiki_detail.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.newevent2.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.newevent2.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.newevent2.right"/>}
                    leftImage={"event_archers.png"}
                    rightImage={"event_options.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.enter1.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.enter1.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.enter1.right"/>}
                    leftImage={"event_points.png"}
                    rightImage={"event_points_1arrow.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.enter2.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.enter2.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.enter2.right"/>}
                    leftImage={"event_points_2arrow.png"}
                    rightImage={"event_points_manual.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.enter3.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.enter3.left"/>}
                                   contentRight={null}
	                leftImage={"event_points_disc.png"}
	                rightImage={"event_points_field.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.result.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.result.left"/>}
                                   contentRight={<I18nHtml code="skillboard.gettingstarted.result.right"/>}
                    leftImage={"event_result.png"}
                    rightImage={"event_history.png"}/>
                <hr/>
                <GettingStartedRow imageDirPrefix={imgDirDevice}
                                   title={<I18n code="skillboard.gettingstarted.scores.title"/>}
                                   contentLeft={<I18nHtml code="skillboard.gettingstarted.scores.left"/>}
                                   contentRight={null}
                    leftImage={"counttype_list.png"}
                    rightImage={null}/>
            </div >

        )
    }
};
const GettingStartedRow = ({title, contentLeft, contentRight, leftImage, rightImage, imageDirPrefix}) => {
    return (<Row>
        <SubTitle>{title}</SubTitle>

        <Col sm={3}>
            <Image responsive src={imageDirPrefix+ leftImage}/>
        </Col>
        <Col sm={6} xsHidden>
            {contentLeft}<hr/>{contentRight}
        </Col>
        <Col xs={12} smHidden mdHidden lgHidden>
            {contentLeft}<hr/>
        </Col>
        <Col sm={3}>
            {rightImage ? <Image responsive src={imageDirPrefix + rightImage}/> : null}
        </Col>
        <Col xs={12} smHidden mdHidden lgHidden>
            {contentRight}
        </Col>
    </Row>)
};

module.exports = GettingStarted;
