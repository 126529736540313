const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require ('react-router-dom');
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    linkUtil = require("linkUtil");

const RoutedTabNav = ({children}) => {
    return (<div>{children}</div>)
};

const RoutedTabs = ({pathname, children, tabs, pills, stacked}) => {
    const renderChildren = (pChildren) => {
        return pChildren.map(child => React.cloneElement(child, {
            key: child.props.linkPath,
            pathname: pathname
        }))
    };
    let className = "nav";
    if (tabs) {
        className += " nav-tabs";
    }
    if (pills) {
        className += " nav-pills";
    }
    if (stacked) {
        className += " nav-stacked";
    }
    return (
        <ul className={className}>
            {renderChildren(children)}
        </ul>)
};

const RoutedTab = ({icon, linkCode, linkPath, pathname, onClick, exact}) => {
    let active;
    if (exact) {
        active = linkUtil.getLink(linkPath) === pathname;
    } else {
        active = pathname.indexOf(linkPath) > -1;
    }
    return (
        <li role="presentation" onClick={onClick}
            className={active ? "active" : ""}>
            <Link  to={linkUtil.getLink(linkPath)}>
                {icon != null ? <FontAwesome icon={icon}/> : null }
                <I18n code={linkCode}/>
            </Link>
        </li>)
};
RoutedTab.propTypes = {
    linkCode: PropTypes.string.isRequired,
    linkPath: PropTypes.string.isRequired,
    pathname: PropTypes.string,
    icon:PropTypes.string,
    onClick: PropTypes.func
};
const ExtTab = ({linkCode, url}) => {
    return (
        <li role="presentation">
            <a href={url} target="_blank" rel="noopener">
                <I18n code={linkCode}/>
            </a>
        </li>)
};
ExtTab.propTypes = {
    url: PropTypes.string.isRequired,
    linkCode: PropTypes.string.isRequired,
};
module.exports = {
    RoutedTabNav: RoutedTabNav,
    RoutedTabs: RoutedTabs,
    RoutedTab: RoutedTab,
    ExtTab: ExtTab
};
