const React = require("react"),
    PropTypes = require('prop-types');
const LinkButton = require("components/links/LinkButton");
const linkUtil = require("linkUtil");

const ParcoursStatisticButton = ({parcours, block, disabled, bsStyle}) => {
    return (
        <LinkButton
            block={block}
            disabled={disabled}
            bsStyle={bsStyle}
            label="statistics.header"
            href={linkUtil.getLink("parcours/" + parcours.getOnlineID() + "/stats")}/>
    )
};
ParcoursStatisticButton.propTypes = {
    parcours:PropTypes.object.isRequired,
    block:PropTypes.bool,
    disabled:PropTypes.bool,
    bsStyle:PropTypes.string,
};
module.exports = ParcoursStatisticButton;