var Parse = require("ParseInit");

var ZoneNames = Parse.Object.extend("zoneNames", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        status: "status",
        sortIndex: "sortIndex",
        objectId: "objectId",
        updatedAt: "updatedAt",
    },
    status: {
        active: "A",
        deleted: "D"
    },
    getSelectString: function () {
        return this.getName();
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getName: function () {
        return this.get(this.col.name)
    },
    setName: function (name) {
        return this.set(this.col.name, name)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ZoneNames;
