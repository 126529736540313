const React = require("react"),
    PropTypes = require('prop-types'),
    I18n = require("components/widgets/I18n");
const {Label} = require("react-bootstrap");
const Utility = require("util/Utility");
const TournamentRegStatus = ({tournament}) => {
    const registrationStatus = tournament.registrationStatus;
    let code = "code.unknown";
    let info = null;
    let style = "default";
    switch (tournament.getRegistrationStatus()) {
        case registrationStatus.published:
        {
            if (tournament.getRegStartDate()) {
                code = "tournament.status.regStart";
                style = "info";
                info = " " + Utility.getReadableDate(tournament.getRegStartDate());
            } else {
                code = "tournament.status.published";
            }
            break;
        }
        case registrationStatus.activatedCupMaster:
        {
            code = "tournament.status.cupMasterActive";
            style = "info";
            break;
        }
        case registrationStatus.open:
        {
            code = "tournament.status.register.open";
            style = "info";
            break;
        }
        case registrationStatus.full:
        {
            code = "tournament.status.register.full";
            style = "warning";
            break;
        }
        case registrationStatus.closed:
        {
            code = "tournament.status.register.closed";
            break;
        }
    }
    return (<Label bsStyle={style}><I18n code={code}/>{info}</Label>)
};
TournamentRegStatus.propTypes = {
    tournament:PropTypes.object.isRequired
};
module.exports = TournamentRegStatus;
