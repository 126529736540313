const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    linkUtil = require("linkUtil"),
    messages = require("i18n/messages"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic"),
    ValidInput = require("components/form/ValidInput"),
    Utility = require("util/Utility");

const msgActions = require("actions/MessageActions");

const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const MailAdvancedModal = ({senderMail, tournamentUsers, tournament, tooltip}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="club.button.newMessage"/></TextCenter>}
            tooltip={tooltip}
            buttonStyle={"default"}
            buttonChildren={<FontAwesome icon="envelope" />}>
            <MailForm tournamentUsers={tournamentUsers} senderMail={senderMail} tournament={tournament}/>
        </ModalBasic>
    )
};
MailAdvancedModal.propTypes = {
    tournament: PropTypes.object,
    tooltip: PropTypes.string,
    tournamentUsers: PropTypes.array,
    senderMail: PropTypes.string.isRequired
};
class MailForm extends React.Component {
    constructor(props) {
        super(props);
        this.refText = React.createRef();
        this.refSubject = React.createRef();
        this.refReceiver = React.createRef();
        this.valid = this.valid.bind(this);
        this.clickedSave = this.clickedSave.bind(this);
        this.buildMails = this.buildMails.bind(this);
        this.getDefaultSubject = this.getDefaultSubject.bind(this);
    }
    valid() {
        const { setErrorFct } = this.context;
        let valid = true;
        const required = [
            this.refText.current.getValue(),
            this.refSubject.current.getValue()
        ];
        for (let i = 0; i < required.length; i++) {
            if (required[i] == null || required[i].length == 0) {
                setErrorFct({message: messages.get("tournament.create.error.required")});
                valid = false;
                break;
            }
        }
        return valid;
    }
    clickedSave(e) {
        const {closeFct, disableSubmitFct} = this.context;
        e.preventDefault();
        if (!this.valid()) {
            return;
        }
        disableSubmitFct(true);
        let text = this.refText.current.getValue();
        console.log("Sent mail text " + text);
        var htmlMessage = '<html><body><p>';
        htmlMessage += text.replace(/\n/g, "<br/>");
        htmlMessage += '</p>';
        if (this.props.tournament) {
            let tournamentLink = linkUtil.getFullLink('/tournament/' + this.props.tournament.id);
            htmlMessage += '<br><a href="' + tournamentLink + '">' + messages.get("tournament.register.mail.tournamentLink") + '</a>';
        }
        htmlMessage += '</body></html>';
        // send mail
        let receivers = this.refReceiver.current.getValue();
        let recArray = receivers.split(",");
        const mailsArrayJunks = Utility.chunkArray(recArray, 50);
        mailsArrayJunks.map(mailsArrayJunk => {
            let receiver = mailsArrayJunk.join(',');
            msgActions.sentMassEmail(this.refSubject.current.getValue(), this.props.senderMail, receiver, htmlMessage, msgActions.mailCallbackNotification);
        });
        closeFct();
    }
    getDefaultSubject() {
        if (this.props.tournament) {
            return this.props.tournament.getName();
        }
    }
    buildMails() {
        let mailArray = [];
        if (this.props.tournamentUsers) {
            this.props.tournamentUsers.map(tUser => {
                let email = tUser.getPlayerEmail().trim();
                if (Utility.isValidEmail(email)) {
                    mailArray.push(email);
                }
            })
        }
        return mailArray.join(", ")
    }
    render() {
        const {error, submitDisabled} = this.context;
        const {senderMail} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <p><strong><I18n code="modal.message.sender"/></strong>&nbsp;{senderMail}</p>
                        <ValidInput ref={this.refReceiver} componentClass="textarea"
                                    default={this.buildMails()}
                                    label={messages.get("modal.message.receiver")}
                                    rows={5}
                                    valid={{check:['isRequired']}}/>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col sm={12}>
                        <ValidInput ref={this.refSubject} default={this.getDefaultSubject()}
                                    label={messages.get("modal.message.subject")}
                                    valid={{maxLength: 50, check:['isRequired']}}
                                    placeholder={messages.get("modal.message.subject") + " (" + 50 + ")"}/>

                        <ValidInput ref={this.refText} componentClass="textarea"
                                    rows={5}
                                    valid={{maxLength: 2000, check:['isRequired']}}
                                    placeholder={messages.get("modal.message.placeholder.message") + " (" + 2000 + ")"}/>

                    </Col>
                    <Col xs={12}>
                        {error == null ? null : <Alert bsStyle="danger">{error.message}</Alert>}
                        <Button onClick={this.clickedSave} disabled={submitDisabled} block bsStyle="success">
                            <I18n code={"modal.button.send"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
MailForm.propTypes = {
    tournament: PropTypes.object,
    tournamentUsers: PropTypes.array,
    senderMail: PropTypes.string.isRequired
};
MailForm.contextType = ModalBasicContext;
module.exports = MailAdvancedModal;
