const alt = require("AltInit"),
    TournamentUserResultDic = require("util/TournamentUserResultDic");

const { TournamentUser, TournamentUserGroup } = require("parse/_Domain");

const ParseListUtility = require("util/ParseListUtility");
const parseDao = require("actdao/ParseBaseDao");
const dao = require("actdao/TournamentLiveDao");
const tournamentUserDao = require("actdao/TournamentUserDao");
const notificationActions = require("components/notification/NotificationActions");

const GROUP_CHARS = require("components/tournamentmanage/TournamentUtility").GROUP_CHARS()

class TournamentLiveActions {

    showLiveDetails(id) {
        dao.loadTournamentLiveDetails(id)
            .then(pTournamentDetail => {
                this.loadTournamentRounds(pTournamentDetail);
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TLVA.showLiveDetail";
                notificationActions.parseError(error);
            });
        return {};
    }

    showLiveGroups(id, user) {
        dao.loadTournamentLiveDetails(id)
            .then(pTournamentDetail => {
                this.loadTournamentRounds(pTournamentDetail);
                // only if tournament allows to maintain your own groups
                const pUser = pTournamentDetail.getNoTuserGroups() ? user : null;
                this.loadTournamentGroups(pTournamentDetail, null, pUser);
                this.updateTournamentDetail(pTournamentDetail);
            })
            .catch(error => {
                error.trace = "TLVA.showLiveDetail";
                notificationActions.parseError(error);
            });
        return {};
    }
    loadTournamentRounds(tournament) {
        dao.loadTournamentRounds(tournament)
            .then(pTournamentRounds => {
                this.updateTournamentRounds(pTournamentRounds);
            })
            .catch(error => {
                error.trace = "TLVA.loadRounds";
                notificationActions.parseError(error);
                this.updateTournamentRounds([]);
            });
        return {};
    }

    loadTournamentGroupResult(tournament, tournamentRound) {
        let userResultDic = new TournamentUserResultDic();
        userResultDic.setTournamentSecondGroup(tournament.getTournamentConfigAge());
        dao.loadTournamentGroupResult(tournament, tournamentRound)
            .then(tGroups => {
                tGroups.forEach(function (tGroup) {
                    userResultDic.addLiveTournamentGroupUser(tGroup);
                });
                this.updateTournamentLiveGroups(tournamentRound.id, tGroups);
                this.updateTournamentUserResult(tournamentRound.id, userResultDic);
            })
            .catch(error => {
                error.trace = "TLVA.loadGroupResult";
                notificationActions.parseError(error);
            });
        return {};
    }

    queryTournamentUserForGroups(searchText, tournament, callback, errorCallback) {
        tournamentUserDao.queryForCupTournamentUser(searchText, tournament, null, true)
            .then(tournamentUsers => {
                callback(tournamentUsers)
            })
            .catch(error => {
                error.trace = "TLVA.searchTUser";
                errorCallback(error);
            });
        return {};
    }

    loadTournamentGroups(tournament, tournamentRound, user) {
        dao.loadTournamentGroups(tournament, tournamentRound)
            .then(tGroups => {
                this.updateTournamentGroups(tGroups);
                if (tGroups != null && user != null && user.isRealUser()) {
                    // find group with own registration
                    for (let i = 0; i < tGroups.length; i++) {
                        let tUser = ParseListUtility.getTUserFromTUserGroup(tGroups[i], user);
                        if (tUser) {
                            this.updateOwnTournamentGroup(tGroups[i], tUser)
                            return {}
                        }
                    }
                }
                this.updateOwnTournamentGroup(null, null)
            })
            .catch(error => {
                error.trace = "TLVA.loadGroupResult";
                notificationActions.parseError(error);
            });
        return {};
    }
    createGroupSuggestion(tournament, user, actTRound, existingTournamentUserGroups) {
        // find tUser for User
        tournamentUserDao.queryTournamentUser(tournament, user.getEmail())
            .then(tUser => {
                if (tUser != null) {
                    // create group with
                    this.createTournamentGroup(tournament, tUser, user, actTRound, existingTournamentUserGroups)
                } else {
                    notificationActions.warning("tournament.error.noRegForEmail")
                }
            })
            .catch(error => {
                error.trace = "TLVA.loadTUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    async createTournamentGroup(tournament, tUser, user, actTRound, existingTournamentUserGroups) {
        // create group
        const tuGroup = new TournamentUserGroup();
        tuGroup.setStatus(TournamentUserGroup.prototype.status.present);
        tuGroup.setCreator(user)
        tuGroup.setName("Gruppe " + tUser.getSurname() + tUser.getName().charAt(0));
        tuGroup.setStartPosition(1);
        tuGroup.setTournamentID(tournament);
        tuGroup.setTournamentStringID(tournament.id);
        tuGroup.setTournamentUserIDs([tUser]);
        tuGroup.setDraft(true)
        if (actTRound) {
            tuGroup.setTournamentRoundID(actTRound);
        }
        if (tournament.isFixedTUInGroup()) {
            // handle fixedTUsers
            const fixedTournamentUserIDObject = {}
            let key = GROUP_CHARS[0];
            fixedTournamentUserIDObject[key] = tUser
            tuGroup.setFixedTournamentUserIDs(fixedTournamentUserIDObject)
        }
        try {
            const sTuGroup = await parseDao.saveObject(tuGroup)
            // add new group to first place on the list
            existingTournamentUserGroups.splice(0, 0, sTuGroup);
            this.updateTournamentGroups(existingTournamentUserGroups);
            // group have been saved - set user reference to group
            tUser.set(TournamentUser.prototype.col.tournamentGroupID, sTuGroup);
            const sTUser = await parseDao.saveObject(tUser)
            this.updateOwnTournamentGroup(sTuGroup, sTUser);
        }
        catch(error) {
            error.trace = "TLVA.createTUG";
            notificationActions.parseError(error);
        }
        return {};
    }

    async addTUserToGroup(tournamentUser, group) {
        const tUsers = group.getTournamentUserIDs();
        if (!ParseListUtility.contains(tUsers, tournamentUser)) {
            tournamentUser.set(TournamentUser.prototype.col.tournamentGroupID, group);
            const sTUser = await parseDao.saveObject(tournamentUser)
            tUsers.push(sTUser)
            group.setTournamentUserIDs(tUsers)
            // append user with next free group char
            const fixedTUserObject = group.getFixedTournamentUserIDs()
            if (fixedTUserObject != null) {
                // append as last
                let nPosKey = GROUP_CHARS[Object.keys(fixedTUserObject).length]
                fixedTUserObject[nPosKey] = sTUser
                group.setFixedTournamentUserIDs(fixedTUserObject)
            }
            const sTUGroup = await parseDao.saveObject(group)
            this.updateTournamentGroup(sTUGroup)
        }
    }

    async removeTUserFromTUserGroup(tUserGroup, tUser, isOwnTUser) {
        // unset user reference to group
        tUser.unset(TournamentUser.prototype.col.tournamentGroupID);
        const sTUser = await parseDao.saveObject(tUser)

        const existGroupUsers = tUserGroup.getTournamentUserIDs();
        const tGroupUsers = existGroupUsers.filter(fUser => {
            return fUser.id !== tUser.id;
        });
        tUserGroup.setTournamentUserIDs(tGroupUsers);
        const fixedTUserObject = tUserGroup.getFixedTournamentUserIDs()
        if (fixedTUserObject != null) {
            // find tUser in Object and unset him
            Object.keys(fixedTUserObject).map(posKey => {
                let fUser = fixedTUserObject[posKey];
                if (fUser != null && fUser.id === tUser.id) {
                    delete fixedTUserObject[posKey]
                }
            })
            tUserGroup.setFixedTournamentUserIDs(fixedTUserObject)
        }
        if (tGroupUsers.length <= 0) {
            tUserGroup.setStatus(TournamentUserGroup.prototype.status.deleted)
        }
        parseDao.saveObject(tUserGroup)
            .then(sTuGroup => {
                // update sTuGroup in store
                this.updateTournamentGroup(sTuGroup)
                if (isOwnTUser) {
                    this.updateOwnTournamentGroup(null, sTUser);
                }
            })
            .catch(error => {
                error.trace = "TLVA.remTUser";
                notificationActions.parseError(error);
            });
        return {};
    }

    searchTournamentUserInGroups(searchParams) {
        return searchParams;
    }

    updateTournamentDetail(pTournamentDetail) {
        return pTournamentDetail;
    }
    updateTournamentRounds(tRounds) {
        return tRounds;
    };
    updateOwnTournamentGroup(tGroup, tUser) {
        // this may allow user to remove from group
        return {tGroup, tUser}
    }
    updateTournamentGroup(tGroup) {
        return tGroup
    }
    updateTournamentGroups(tournamentGroups) {
        return tournamentGroups;
    }

    updateTournamentLiveGroups(tournamentRoundId, tGroups) {
        return {tournamentRoundId, tGroups};
    }

    updateTournamentUserResult(tournamentRoundId, userResultDic) {
        return {tournamentRoundId, userResultDic};
    };

    resetTournamentLiveResults() {
        return {};
    }
}

module.exports = alt.createActions(TournamentLiveActions);
