const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col,  Table, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    TextCenter = require("components/widgets/TextCenter"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ModalBasic = require ("components/modals/ModalBasic");

const Utility = require("util/Utility");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

const TournamentGroupResultChangesModal = ({tUserGroup, changeLogs}) =>  {
    return (
        <ModalBasic
            title={<TextCenter><I18n code="tournament.modal.score.changes"/></TextCenter>}
            buttonStyle="warning"
            buttonChildren={<FontAwesome icon="exclamation-triangle"><I18n code="tournament.modal.score.changes"/></FontAwesome>}>
            <TournamentGroupResultList changeLogs={changeLogs} tUserGroup={tUserGroup}/>
        </ModalBasic>
    )
};
TournamentGroupResultChangesModal.propTypes = {
    tUserGroup: PropTypes.object.isRequired,
    changeLogs: PropTypes.array,
};
class TournamentGroupResultList extends React.Component {
    constructor(props) {
        super(props);
    }
    clickedSave(e) {
        const {closeFct} = this.context;
        e.preventDefault();
        // check result parseInt able
        closeFct();
    }
    getTUserName(tUserId, tUserGroup) {
        let tUsers = tUserGroup.getTournamentUserIDs();
        for (let i = 0; i < tUsers.length; i++) {
            if (tUserId === tUsers[i].id) {
                return tUsers[i].getFullName()
            }
        }
        return tUserId
    }
    render() {
        const {tUserGroup, changeLogs,} = this.props;
        return (
            <form action="#">
                <Row>
                    <Col sm={12}>
                        <p><I18n code="tournament.modal.score.changes.desc"/></p>
                        <Table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th><I18n code="tournament.table.column.name" /></th>
                                <th><I18n code="tournament.table.column.target" /></th>
                                <th><I18n code="tournament.table.column.points" /></th>
                                <th><I18n code="tournament.table.column.time" /></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(changeLogs).map(tUserId => {
                                    const changeUserLog = changeLogs[tUserId];
                                    const tUserName = this.getTUserName(tUserId, tUserGroup);
                                    return Object.keys(changeUserLog).map(changeId => {
                                        const change = changeUserLog[changeId];
                                        return <tr key={"Change" + changeId + "_" + tUserId}>
                                            <td>{changeId}</td>
                                            <td>{tUserName}</td>
                                            <td>{change.targetIdx + "(" + change.actTargetIdx + ")"}</td>
                                            <td>{change.oldPoints + " -> " + change.points}</td>
                                            <td>{Utility.getReadableTime(new Date(change.changeStmp))}</td>
                                        </tr>
                                    })
                                })
                            }
                            </tbody>
                        </Table>
                    </Col>
                    <Col xs={12}>
                        <Button onClick={this.clickedSave} block bsStyle="success">
                            <I18n code={"modal.button.close"}/>
                        </Button>
                    </Col>
                </Row>
            </form>
        );
    }
}
TournamentGroupResultList.propTypes = {
    tUserGroup:PropTypes.object.isRequired,
    changeLogs:PropTypes.array.isRequired
};
TournamentGroupResultList.contextType = ModalBasicContext;
module.exports = TournamentGroupResultChangesModal;
