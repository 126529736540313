const alt = require("AltInit");
const dao = require("actdao/AccountDao");
const parseDao = require("actdao/ParseBaseDao");
const notificationActions = require("components/notification/NotificationActions");
class AccountActions {
    queryMainEditorManagement(user) {
        dao.queryMainEditorManagement(user)
            .then(pManagements => {
                this.updateManagements(pManagements);
            })
            .catch(error => {
                error.trace = "ACAC.queryMainMGM";
                notificationActions.parseError(error);
            });
        return {};
    };

    queryInvolvedManagement(user) {
        this.queryInvolvedManagementCallback(user, pManagements => {
            this.updateManagements(pManagements);
        });
        return {};
    }

    queryInvolvedManagementCallback(user, callback) {
        dao.queryInvolvedManagement(user)
            .then(pManagements => {
                callback(pManagements);
            })
            .catch(error => {
                error.trace = "ACAC.queryInvMGM";
                notificationActions.parseError(error);
            });
        return {};
    }

    saveManagement(updManagement) {
        parseDao.saveObject(updManagement)
            .then(sManagement => {
                this.updateManagement(sManagement)
            })
            .catch(error => {
                error.trace = "ACAC.saveMgmt";
                notificationActions.parseError(error);
            });
        return {};
    }

    updateManagements(managements) {
        return managements;
    };

    addToAddEditor(objectType, objectID, mail, callback, callbackError) {
        if (objectID != null && objectType != null && mail != null && mail.length > 0) {
            dao.addToAddEditor(objectType, objectID, mail, false)
                .then(editor => {
                    callback(editor);
                })
                .catch(error => {
                    error.trace = "ACAC.addEditor";
                    if (callbackError) {
                        callbackError(error)
                    } else {
                        notificationActions.parseError(error);
                    }
                });
        } else {
            console.error("parameter missing", objectType, objectID, mail)
        }
        return {};
    }
    removeFromAddEditor(objectType, objectID, editor, callback) {
        dao.removeFromAddEditor(objectType, objectID, editor, false)
            .then(editorID => {
                callback(editorID);
            })
            .catch(error => {
                error.trace = "ACAC.removeEditor";
                notificationActions.parseError(error);
            });
        return {};
    }
    updateManagement(management) {
        return management
    }
}

module.exports = alt.createActions(AccountActions);
