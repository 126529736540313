const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Grid, Panel, Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    ValidInput = require("components/form/ValidInput"),
    messages = require("i18n/messages");

const userActions = require("actions/UserActions"),
    userStore = require("stores/UserStore"),
    notificationActions = require("components/notification/NotificationActions");

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.refMail = React.createRef();
        this.handleUserChange = this.handleUserChange.bind(this);
        this.getMail = this.getMail.bind(this);
        this.sentResetMail = this.sentResetMail.bind(this);
        this.state = {
            userStore: userStore.getState(),
            showMailField: true,
            showPasswordField: false
        };
    }
    componentDidMount() {
        document.title = "3D Turnier - " + messages.get("modal.password.reset.header");
        userStore.listen(this.handleUserChange);
        userActions.refreshUser(this.state.userStore.user);
        notificationActions.reset();
        if (this.props.action) {
            if (this.props.action.startsWith("invalidLINK")) {
                notificationActions.error("modal.password.reset.invalidLink", "ResPWiL")
            } else if (this.props.action.startsWith("resetPassSuccess")) {
                notificationActions.success("modal.password.reset.success")
            } else if (this.props.action.startsWith("mailACK")) {
                notificationActions.success("modal.password.reset.mailACK");
                this.setState({showMailField: false});
            }
        }
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
    }
    handleUserChange(state) {
        this.setState({userStore: state});
    }
    getMail() {
        if (this.state.userStore.user) {
            return this.state.userStore.user.get('email');
        }
        return null;
    }
    sentResetMail(e) {
        e.preventDefault();
        if (this.refMail.current.isValid()) {
            let mail = this.refMail.current.getValue();
            if (mail != null && mail.length > 4) {
                userActions.resetPasswordMail(mail.trim().toLowerCase())
            }
        }
    }
    render() {
        const {showMailField} = this.state;
        return (
            <Grid>
                {
                    showMailField ?
                        <Row>
                            <Col xs={12} md={4} mdOffset={4}>
                                <Panel>
                                    <Panel.Heading>
                                        <I18n code="modal.password.reset.header"/>
                                    </Panel.Heading>
                                    <Panel.Body>
                                        <form action="#" onSubmit={this.sentResetMail}>
                                            <Row>
                                                <Col xs={12}>
                                                    <ValidInput ref={this.refMail}
                                                                label={<I18n code="modal.password.reset.body"/>}
                                                                autoComplete="email"
                                                                valid={{maxLength: 50, check: ['isRequired', 'isMail']}}
                                                                placeholder={messages.get("address.mail")}
                                                                addonBefore={<FontAwesome icon="envelope"/>}
                                                                default={this.getMail()}/>
                                                    <Button block bsStyle="primary" onClick={this.sentResetMail}>
                                                        {messages.get("modal.button.send")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Panel.Body>
                                </Panel>
                            </Col>
                        </Row> : null
                }
            </Grid>

        )
    }
}
ResetPasswordPage.propTypes = {
    action: PropTypes.string
};
module.exports = ResetPasswordPage;
