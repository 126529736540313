const React = require("react"),
    PropTypes = require('prop-types');
const {FormGroup, InputGroup, ControlLabel, Dropdown, MenuItem} = require("react-bootstrap");

class TimeInput extends React.Component {
    constructor(props) {
        super(props);
        this.showHelpMessage = this.showHelpMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.state = {
            fallback: false,
            value: this.props.default,
            style: null,
            help: ""
        };
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleChange(value) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
        });
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(value);

        }
    }
    showHelpMessage(message, style) {
        const that = this;
        this.setState({
            style: style,
            help: message
        });
        setTimeout(function () {
            that.showHelpMessage("", "");
        }, 5000);
    }
    render() {
        let addonBefore = null;
        if (this.props.addonBefore != null) {
            addonBefore = <InputGroup.Addon>{this.props.addonBefore}</InputGroup.Addon>
        }
        let times = []
        for (let h = 0; h < 24; h++) {
            for (let m = 0; m < 60; m = m + 10) {
                let hours = h < 10 ? "0" + h : h;
                let min = m < 10 ? "0" + m : m;
                times.push(hours + ":" + min)
            }
        }
        return (
            <FormGroup>
                {
                    this.props.label != null ? <ControlLabel>{this.props.label}</ControlLabel> : ""
                }
                <InputGroup>
                    {addonBefore}
                    <Dropdown id="time-filter" disabled={this.props.disabled}>
                        <Dropdown.Toggle>
                            {this.state.value}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{height: "150px", widows: "100px", overflowY: "scroll"}}>
                        {times.map(time => {
                            return (
                                <MenuItem key={time} eventKey={time} onSelect={this.handleChange}>
                                    {time}
                                </MenuItem>)
                        })}
                        </Dropdown.Menu>
                    </Dropdown>
                </InputGroup>
            </FormGroup>);
    }
}
TimeInput.propTypes = {
    placeholder:PropTypes.string,
    default:PropTypes.string,
    onAfterChange:PropTypes.func,
    disabled:PropTypes.bool,
    addonBefore:PropTypes.object
};
module.exports = TimeInput;
