
function EventPlayerSortedList(year) {
    this.eventPlayerList = [];
    this.startDate = new Date(year,0,1).getTime();
    this.endDate = new Date(year,11,31,23,59,59,0).getTime();
    // TODO think who to fix time problem via query

    this.pushSorted= function(eventPlayer) {
        var newEvent = eventPlayer.getEventID();
        if (newEvent != null && newEvent.getInsstmp() > this.startDate && newEvent.getInsstmp() < this.endDate) {
            var added = false;
            var index = 0;
            var length = this.eventPlayerList.length;
            while (index < length && !added) {
                var oldEvent = this.eventPlayerList[index].getEventID();
                if (newEvent.getInsstmp() > oldEvent.getInsstmp()) {
                    this.eventPlayerList.splice(index, 0, eventPlayer);
                    added = true;
                } else if (newEvent.getInsstmp() == oldEvent.getInsstmp()) {
                    // do not add doubled events in list
                    added = true;
                }
                index++;
            }
            if (!added) {
                this.eventPlayerList.push(eventPlayer);
            }
        }
        return {};
    };

    this.getEventPlayerList = function() {
        return this.eventPlayerList;
    };
}
module.exports = EventPlayerSortedList;