var Parse = require("ParseInit");

var ManagementRequest = Parse.Object.extend("managementRequest", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        objectId: "objectId",
        clubID: "clubID",
        parcoursOwnerID: "parcoursOwnerID",
        status: "status",
        updatedAt: "updatedAt"
    },
    getACL: function () {
        return this.get(this.col.ACL)
    },
    setACL: function (ACL) {
        return this.set(this.col.ACL, ACL)
    },
    buildACL: function (user) {
        var acl = new Parse.ACL();
        acl.setPublicReadAccess(false);
        acl.setPublicWriteAccess(false);
        if (user) {
            acl.setWriteAccess(user, true);
            acl.setReadAccess(user, true);
        }
        acl.setRoleReadAccess("3DSKBOadmin", true);
        acl.setRoleWriteAccess("3DSKBOadmin", true);
        this.setACL(acl);
    },
    getCreatedAt: function () {
        return this.get(this.col.createdAt)
    },
    getObjectId: function () {
        return this.get(this.col.objectId)
    },
    setObjectId: function (objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getParcoursOwnerID: function () {
        return this.get(this.col.parcoursOwnerID)
    },
    setParcoursOwnerID: function (parcoursOwnerID) {
        return this.set(this.col.parcoursOwnerID, parcoursOwnerID)
    },
    getStatus: function () {
        return this.get(this.col.status)
    },
    setStatus: function (status) {
        return this.set(this.col.status, status)
    },
    getClubID: function () {
        return this.get(this.col.clubID)
    },
    setClubID: function (clubID) {
        return this.set(this.col.clubID, clubID)
    },
    getUpdatedAt: function () {
        return this.get(this.col.updatedAt)
    },
});

module.exports = ManagementRequest;
