/**
 * Created by Daniel on 02.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const MonthPagerBasic = require("components/widgets/MonthPagerBasic");

class MonthPager extends React.Component {
    constructor(props) {
        super(props);
        this.handleChangedDate = this.handleChangedDate.bind(this);

        let year = parseInt(this.props.year);
        let month = parseInt(this.props.month);
        if (!(year > 0)) {
            let today = new Date();
            year = today.getFullYear();
            month = today.getMonth() + 1
        }
        this.state = {
            month: month,
            year: year
        }
    }
    handleChangedDate(month, year, yearChanged) {
        this.setState({
            month: month,
            year: year
        });
        if (this.props.handleChangedDate) {
            this.props.handleChangedDate(month, year, yearChanged);
        }
    }
    render() {
        const {year, month} = this.state;
        const {headerText, allowFuture} = this.props;
        return <MonthPagerBasic headerText={headerText} year={year} month={month} allowFuture={allowFuture}
                         handleChangedDate={this.handleChangedDate}/>
    }
}
MonthPager.propTypes = {
    headerText:PropTypes.string,
    handleChangedDate:PropTypes.func,
    allowFuture: PropTypes.bool
};
module.exports = MonthPager;