const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Modal, Alert, Button} = require("react-bootstrap");
const Loading = require("components/widgets/Loading"),
    TextCenter = require("components/widgets/TextCenter"),
    StarRating = require("components/widgets/StarRating");

const messages = require("i18n/messages");

const parcoursActions = require("actions/ParcoursActions"),
    parcoursStore = require("stores/ParcoursStore");

class ParcoursAllRatingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleParcoursChange = this.handleParcoursChange.bind(this);

        this.state = {
            parcoursStore: parcoursStore.getState(),
            showModal: false,
            error: null
        }
    }
    componentDidMount() {
        parcoursStore.listen(this.handleParcoursChange);
    }
    componentWillUnmount() {
        parcoursStore.unlisten(this.handleParcoursChange);
    }
    handleParcoursChange(state) {
        this.setState({parcoursStore: state});
    }
    close() {
        this.setState({showModal: false});
    }
    open() {
        this.setState({showModal: true});
        parcoursActions.queryParcoursRatings(this.state.parcoursStore.parcours);
    }
    render() {
        return (
            <Modal backdrop="static" show={this.state.showModal} onHide={this.close}>
                <form action="#" onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <TextCenter>{messages.get("parcours.details.area.allratings")}</TextCenter>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.showModal ?
                                <Row>
                                    {this.state.error ?
                                        <Alert bsStyle="warning">{this.state.error}</Alert>
                                        : ""}
                                    <Col sm={12}>
                                        {
                                            this.state.parcoursStore.ratings == null ? <Loading/> :
                                                this.state.parcoursStore.ratings.map(rating => {
                                                    return <RatingEntry key={rating.id} rating={rating}/>
                                                })
                                        }
                                    </Col>
                                </Row> : null
                        }
                    </Modal.Body>
                </form>
            </Modal>
        )
    }
}
const RatingEntry = ({rating}) =>{
    function getName() {
        if (rating.getEditor()) {
            return rating.getEditor().getUsername();
        } else {
            return messages.get("code.unknown");
        }
    }
    return <p><h4>{getName()}</h4>
        <StarRating rating={rating.getRating()}/>&nbsp;&nbsp;{rating.getRatingDate()}<br/>
        <h5>{rating.getTitle()}</h5>
        {rating.getComment()}
        <hr/>
        </p>
};
RatingEntry.propTypes = {
    rating: PropTypes.object.isRequired
};
module.exports = ParcoursAllRatingsModal;
