const React = require("react"),
    PropTypes = require('prop-types');
const languageStore = require("stores/LanguageStore");


const I18nObject = ({i18nObject}) => {
    let text = i18nObject ? i18nObject[languageStore.state.language] : null;
    return <React.Fragment>{text}</React.Fragment>

};
I18nObject.propTypes = {
    i18nObject: PropTypes.object
};
module.exports = I18nObject;
