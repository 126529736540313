const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Image, ButtonGroup, Button, PageHeader} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome"),
    Utility = require("util/Utility");


const AddTrainingModal= require("components/training/AddTrainingModal");
const TrainingFeatureMissing = require("components/training/TrainingFeatureMissing")
const TrainingUserCalendarChartArea = require("components/training/TrainingUserCalendarChartArea")
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");
const CalendarMonth = require("components/form/CalendarMonth");

const trainingActions = require("actions/TrainingActions");

const TrainingUser = ({user, remoteMember, userFeatures, userDetail, trainingTypes, eventTrainingList, trainingCountTypes, calendarBoundaries, selectedCalEvent}) => {
    function loadingEventFct(searchParams) {
        // load events
        trainingActions.queryTrainingEventList.defer(user, remoteMember, searchParams)
    }
    function buildWeekSumElement(weekNumber) {
        const weekStatCounter = eventTrainingList != null ? eventTrainingList.getWeekCounter(weekNumber) : {arrows: 0, duration: 0}
        return <WeekSumElement weekNumber={weekNumber} weekStatCounter={weekStatCounter}/>
    }
    let usrImgUrl = null;
    if (userDetail != null) {
        usrImgUrl = userDetail.getUserImageUrl();
    }
    if (userFeatures != null && userFeatures.isTrainingFeatureActive()) {
        const monthStatCounter = eventTrainingList != null ? eventTrainingList.getMonthCounter(calendarBoundaries.monthStart.getMonth()) : {arrows: 0, duration: 0}
        return <React.Fragment>
            <PageHeader>
                <Row>
                    <Col xs={4} sm={2}>
                        <Image src={usrImgUrl} responsive thumbnail  />
                    </Col>
                    <Col xs={8} sm={10}>
                        {
                            remoteMember ? <span>{remoteMember.getName()}</span> : <span>{user.getUsername()}</span>
                        }
                        <br/>
                        {
                            eventTrainingList != null ? <ArrowDurationStats arrows={eventTrainingList.getYearArrows()} duration={eventTrainingList.getYearDuration()} />
                                : null
                        }
                    </Col>
                </Row>
            </PageHeader>
            <Row>
                <Col xs={12}>

                </Col>
            </Row>
            <AddTrainingModal trainingTypes={trainingTypes} trainingCountTypes={trainingCountTypes} eventTraining={null} user={user} >
                <CalendarMonth user={user} loadingEventFct={loadingEventFct} selectedObject={selectedCalEvent}
                               customAreaIcon="chart-line"
                               customArea={<TrainingUserCalendarChartArea trainingCountTypes={trainingCountTypes} />}
                               colName={<I18n code="header.nav.training"/>}
                               colAdditional={<I18n code="statistics.column.arrows">,&nbsp;<I18n code="statistics.column.duration"/></I18n>}
                               fctWeekSumElement={buildWeekSumElement} denyEdit={remoteMember != null}
                               topTableArea={<TopTableArea trainingTypes={trainingTypes}  user={user} remoteMember={remoteMember}
                                                           monthStatCounter={monthStatCounter} />}/>
            </AddTrainingModal>
        </React.Fragment>
    } else {
        return <TrainingFeatureMissing/>
    }
};
TrainingUser.propTypes = {
    user:PropTypes.object.isRequired,
    userDetail:PropTypes.object,
    selectedCalEvent:PropTypes.object,
    trainingCountTypes: PropTypes.array,
};
const WeekSumElement = ({weekNumber, weekStatCounter}) => {
    return <React.Fragment>
        ({weekNumber})<br/><br/>
        {
            weekStatCounter.arrows > 0 || weekStatCounter.duration > 0 ?
                <ArrowDurationStats arrows={weekStatCounter.arrows} duration={weekStatCounter.duration} /> : null
        }

    </React.Fragment>
}
const TopTableArea = ({trainingTypes, monthStatCounter, user, remoteMember}) => {
    const modalBasics = React.useContext(ModalBasicContext);
    function handleClickSelect() {
        modalBasics.openObjectFct(null, "create")
    }
    return (<React.Fragment>
        <Row style={{position:"sticky", top: "55px", padding:"10px", background: "aliceblue", zIndex: 1}}>
            <Col xs={12} sm={6}><br/>
                <ButtonGroup>
                    <Button bsStyle="primary" bsSize="large" disabled={trainingTypes == null || remoteMember != null} onClick={handleClickSelect}>
                        <FontAwesome icon="calendar-plus"><I18n code="profile.training.button.enter" /></FontAwesome>
                    </Button>
                </ButtonGroup>
            </Col>
            <Col xs={12} sm={6} >
                <h3><ArrowDurationStats arrows={monthStatCounter.arrows} duration={monthStatCounter.duration} /></h3>
            </Col>
        </Row>
    </React.Fragment>)
};
const ArrowDurationStats = ({arrows, duration}) => {
    return <span><I18n code="statistics.column.arrows"/>: {arrows} <br/>
        <I18n code="statistics.column.duration"/>: {Utility.convertMinutesToHours(duration)}h</span>
}
module.exports = TrainingUser;
