const React = require("react"),
    PropTypes = require('prop-types');
const Utility = require("util/Utility");
const { Marker  } = require ('@react-google-maps/api');

const BasicMapMarker = ({coordinate, icon, dragEndCallback}) => {
    function dragEnd(event) {
        let tLat = Utility.reduceDecimals(event.latLng.lat(), 100000);
        let tLong = Utility.reduceDecimals(event.latLng.lng(), 100000);
        if (dragEndCallback) {
            dragEndCallback(tLat, tLong)
        }

    }
    const posMarker = {lat:  coordinate.latitude, lng: coordinate.longitude};
    return  <Marker position={posMarker} icon={icon}
                    draggable={dragEndCallback != null} onDragEnd={dragEnd}
    />
}
BasicMapMarker.propTypes = {
    coordinate: PropTypes.object.isRequired,
    dragEndCallback:PropTypes.func,
    icon: PropTypes.object
};
module.exports = BasicMapMarker;