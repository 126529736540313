const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Alert, Button, ButtonGroup, Panel, Table} = require("react-bootstrap");

const SolutionHelpLink = require("components/links/SolutionHelpLink");

const Loading = require("components/widgets/Loading"),
    TooltipButton  = require("components/form/TooltipButton"),
    FontAwesome = require("components/widgets/FontAwesome"),
    I18n = require("components/widgets/I18n"),
    messages = require("i18n/messages");

const TournamentStatus = require("components/tournament/TournamentStatus"),
    TournamentLink = require("components/tournament/TournamentLink"),
    TournamentDashboardButton = require("components/tournamentmanage/TournamentDashboardButton");

const tournamentUserManagerActions = require("actions/TournamentUserManagerActions"),
    tournamentMgmtActions = require("actions/TournamentManagerActions");

const TournamentMMCupChildTable = ({tournament, childTournaments, hideDeleted}) => {
    return (<Row>
        <Col xs={12}>
            <Table responsive striped hover>
                <thead>
                <tr>
                    <th><I18n code="tournament.table.column.name"/></th>
                    <th><I18n code="tournament.table.column.status"/></th>
                    <th>Actions</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <TournamentCupChildChild key={tournament.id} childTournament={tournament} hideDeleted={hideDeleted}/>
                {
                    childTournaments.map(childTournament => {
                        return <TournamentCupChildChild key={childTournament.id} childTournament={childTournament} hideDeleted={hideDeleted}/>
                    })
                }
                </tbody>
            </Table>
        </Col>
    </Row>)
};
TournamentMMCupChildTable.propTypes = {
    tournament: PropTypes.object.isRequired,
    childTournaments: PropTypes.array.isRequired,
    hideDeleted: PropTypes.bool.isRequired
};
const TournamentCupChildChild = ({childTournament, hideDeleted}) => {
    function loadTournamentUsers() {
        tournamentUserManagerActions.loadTournamentUsers.defer(childTournament, hideDeleted);
        tournamentMgmtActions.loadTournamentRounds.defer(childTournament);
        tournamentUserManagerActions.setSelectedTournament.defer(childTournament)
    }
    return (<tr>
        <td><TournamentLink tournament={childTournament} label={childTournament.getName()}/><br/>
            {childTournament.getTournamentDate()},&nbsp;{childTournament.getZipPlace()}
        </td>
        <td><TournamentStatus tournament={childTournament}/></td>
        <td><TournamentDashboardButton tournament={childTournament} /></td>
        <td><TooltipButton onClick={loadTournamentUsers} icon="sync" tooltip={messages.get("tournament.mmtuser.tooltip.sync")} /></td>
    </tr>);
};
TournamentCupChildChild.propTypes = {
    childTournament: PropTypes.object.isRequired,
    hideDeleted: PropTypes.bool.isRequired
};
module.exports = TournamentMMCupChildTable;
