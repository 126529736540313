const React = require("react"),
    PropTypes = require('prop-types');
const {Link} = require ('react-router-dom');
const messages = require("i18n/messages"),
    linkUtil = require("linkUtil");

const EventDetailLink = ({eventID, isAdmin, label}) => {
    let admin = "";
    if (isAdmin === true) {
        admin = "/admin"
    }
    return <Link to={linkUtil.getLink("profile/eventdetail/" + eventID + admin)}>
        {label ? label : messages.get("profile.event.eventDetails")}
    </Link>
};
EventDetailLink.propTypes = {
    eventID:PropTypes.string.isRequired,
    label:PropTypes.string,
    isAdmin: PropTypes.bool
};
module.exports = EventDetailLink;
