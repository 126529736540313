const alt = require("AltInit");
const parseDao = require("actdao/ParseBaseDao");
const managementDao = require("actdao/ManagementDao");
const { Transaction} = require("parse/_Domain");

class PaymentElementsActions {
    initBasket(parcoursOnlineID) {
        // load Parse.Config.get() "PaymentBrandSurcharge"
        parseDao.getParseConfig()
            .then(config => {
                if (config) {
                    let customConfig = config.get("PaymentPBS_" + parcoursOnlineID);
                    if (customConfig) {
                        this.updatePaymentBrandConfig(customConfig);
                    } else {
                        this.updatePaymentBrandConfig(config.get("PaymentBrandSurcharge"));
                    }
                    let excludePayTypes = config.get("excludePayTypes");
                    if (excludePayTypes != null) {
                        this.updateExcludePayTypes(excludePayTypes)
                    }
                    this.updateHideInAppPayMethod(config.get("PayHideInAppPayMethod") === true)
                    this.updatePremiumAlert(config.get("app_premium_price_alert"));
                }
            });
        this.resetBasket();
        return {};
    };

    loadManagement(mgmtId, clubArticles) {
        managementDao.loadManagementAdmin(mgmtId)
            .then(management => {
                this.updateManagement(management);
                if (management.getFeatures() != null && clubArticles != null) {
                    // add Grundgebühr and
                    let features = ["GG"].concat(management.getFeatures());
                    features.forEach(featureCode => {
                        for (let i = 0; i < clubArticles.length; i++) {
                            if (clubArticles[i].getShortCode() === featureCode) {
                                // found fitting article
                                this.updateTransactionArticle(clubArticles[i].id, 1);
                                this.updateSum(clubArticles[i].getPrice());
                                break;
                            }
                        }
                    });
                    this.updatePacked(true);
                    this.updateMaxPaymentPrice(management.getAboPrice());
                }

            })
            .catch(error => {
                console.log("ERROR ", error)
            });
        return {};
    }

    updateCheckoutInvoice(invoice) {
        const articles = invoice.getParcoursArticles();
        for (let i = 0; i < articles.length; i++) {
            this.updateTransactionArticle(articles[i].id, articles[i].getAmount());
            //this.updateSum(articles[i].getSinglePrice());
        }
        this.updateSum(invoice.getPrice());
        return {};
    }

    updateSum(price) {
        return price;
    }

    updatePayCode(pPayCode) {
        return pPayCode;
    }

    updateTransactionArticle(articleID, amount) {
        return {articleID, amount}
    }

    setSingleTransactionArticle(articleID, price, articleGroup) {
        if (articleGroup) {
            this.updateAfterPayment(articleGroup)
        }
        return {articleID, price}
    }

    resetBasket() {
        return {};
    }

    updatePaymentBrandConfig(config) {
        return config;
    }

    updateExcludePayTypes(exclArray) {
        return exclArray
    }

    updateHideInAppPayMethod(hide) {
        return hide
    }

    updatePremiumAlert(textObject) {
        return textObject
    }

    updateAfterPayment(articleGroup) {
        const featObj = { };
        featObj[Transaction.prototype.afterPay.userAbo] = true;
        featObj.aboGroup = articleGroup;
        return featObj;
    }

    updateManagement(management) {
        return management;
    }

    updatePacked(packed) {
        return packed
    }

    updateDiscount(discount) {
        return discount
    }

    updateMaxPaymentPrice(price) {
        return price
    }
}

module.exports = alt.createActions(PaymentElementsActions);
