const alt = require("AltInit");
const actions = require("actions/TournamentActions");

class TournamentStore {
    constructor() {
        this.displayName = "TournamentStore";
        this.tournaments = null;
        this.allTournaments = null;
        this.tournamentDetail = null;
        this.childTournaments = [];
        this.listRenderAmount = 50;
        this.bowUnion = null;
        this.searchParams = {
            dateFilter: "current",
            needCupMaster: false,
            allowMap: false,
            text: ""
        };
        this.loading = false;
        this.bindListeners({
            showProgress: actions.showProgress,
            updateTournamentDetail: actions.updateTournamentDetail,
            updateTournamentList: actions.updateTournamentList,
            updateCupChildTournaments: actions.updateCupChildTournaments,
            updateBowUnion: actions.updateBowUnion,
            updateDateFilter: actions.updateDateFilter,
            resetTournamentDetails: actions.resetTournamentDetails,
            searchTournamentList: actions.searchTournamentList,
            updateListRenderAmount: actions.updateListRenderAmount
        });
    }

    searchTournamentList(searchParam) {
        let foundTournaments;
        // has text to search for
        if (searchParam.searchText.length > 0) {
            let searchString = searchParam.searchText.toLowerCase();
            foundTournaments = this.allTournaments.filter(u => {
                return u.getLabels().toLowerCase().indexOf(searchString) > -1;
            });
        } else {
            foundTournaments = this.allTournaments;
        }
        this.tournaments = foundTournaments;
    }

    showProgress() {
        this.loading = true;
    }
    updateListRenderAmount(amount) {
        this.listRenderAmount = amount
    }
    updateTournamentDetail(pTournamentDetail) {
        this.loading = false;
        this.tournamentDetail = pTournamentDetail;
    }
    updateTournamentList(pTournamentList) {
        this.loading = false;
        this.tournaments = pTournamentList;
        this.allTournaments = pTournamentList;
    }
    updateBowUnion(bowUnion) {
        this.bowUnion = bowUnion
    }
    updateDateFilter(dateFilter) {
        this.searchParams.dateFilter = dateFilter;
    }
    updateCupChildTournaments(pChildTournaments) {
        this.childTournaments = pChildTournaments;
    };
    resetTournamentDetails() {
        this.loading = true;
        this.tournamentDetail = null;
        this.childTournaments = [];
    }

}
module.exports = alt.createStore(TournamentStore);
