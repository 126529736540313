/**
 * Created by Daniel on 10.05.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const {Button} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

var userStore = require("stores/UserStore"),
    userActions = require("actions/UserActions");

class ParcoursFavorit extends React.Component {
    constructor(props) {
        super(props);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.clicked = this.clicked.bind(this);
        this.isFavorite = this.isFavorite.bind(this);

        this.state = {
            userStore: userStore.getState(),
            isFavorite: this.isFavorite(userStore.getState())
        }
    }
    componentDidMount() {
        userStore.listen(this.handleUserChange);
    }
    componentWillUnmount() {
        userStore.unlisten(this.handleUserChange);
    }
    handleUserChange(state) {
        this.setState({
            userStore: state,
            isFavorite: this.isFavorite(userStore.getState())
        });
    }
    clicked() {
        if (this.props.user == null) {
            userActions.showLoginModal();
        } else {
            var newFavorite = !this.state.isFavorite;
            userActions.updateParcoursFavorite(this.props.parcours.getOnlineID(), newFavorite, this.props.user);
        }
    }
    isFavorite(state) {
        if (state != null && state.userFavorite != null && this.props.parcours != null) {
            return state.userFavorite.isInFavorites(this.props.parcours.getOnlineID());
        }
        return false;
    }
    render() {
        var bsStyle = "default";
        if (this.state.isFavorite) {
            bsStyle = "success";
        }
        return (
            <Button bsStyle={bsStyle} onClick={this.clicked}>
                {this.state.isFavorite ?  <FontAwesome icon="star"/> :  <FontAwesome icon="star" color="lightgrey"/>}
                &nbsp;<I18n code="Favoriten" />
            </Button>)
    }
}
ParcoursFavorit.propTypes = {
    user: PropTypes.object,
    parcours: PropTypes.object.isRequired
};
module.exports = ParcoursFavorit;