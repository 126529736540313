const Parse = require("ParseInit");
const Utility = require("util/Utility");

const TournamentUserTeam = Parse.Object.extend("TournamentUserTeam", {
    col: {
        ACL: "ACL",
        createdAt: "createdAt",
        name: "name",
        objectId: "objectId",
        status: "status",
        tournamentID: "tournamentID",
        tournamentRoundID : "tournamentRoundID",
        tournamentUserIDs: "tournamentUserIDs",
        tournamentConfigAge: "tournamentConfigAge",
        tournamentConfigBow: "tournamentConfigBow",
        resultPosition: "resultPosition",
        sumPoints: "sumPoints",
        killCounts: "killCounts",
        killValue: "killValue",
        updatedAt: "updatedAt"
    },
    status: {
        registered: "R",
        present: "A",
        started: "S",
        disqualified: "X",
        scoreSubmitted: "L",
        finished: "C",
        deleted: "D"
    },
    isDeleted() {
        return this.status.deleted === this.getStatus();
    },
    isPresent() {
        return this.status.present === this.getStatus();
    },
    getSumPoints() {
        let points = this.get(this.col.sumPoints);
        if (!points || "X" === this.getStatus()) {
            // disqualified
            points = "---"
        }
        return points;
    },
    getSortableSumPoints() {
        let points = this.get(this.col.sumPoints);
        if (!points || "X" === this.get("status")) {
            // disqualified
            points = 0;
        }
        return points;
    },
    getFullName() {
        return  this.getName();
    },
    getUnion() {
        return "";
    },
    isSelfRegisteredUsers(user) {
        if (user != null) {
            const tuList = this.getTournamentUserIDs();
            if (tuList != null) {
                for (let i = 0; i < tuList.length; i++) {
                    if (tuList[i].isSelfRegisteredUsers(user)) {
                        return true;
                    }
                }
            }
        }
        return false;
    },
    getLabels() {
        const result = [this.getName()];
        const tUsers = this.getTournamentUserIDs();
        for (let i = 0; i < tUsers.length; i++) {
            result.push(tUsers[i].getFullName());
        }
        return result.join(" ");
    },
    setSumPoints(sumPoints) {
        return this.set(this.col.sumPoints, sumPoints)
    },
    isStarted() {
        return this.getStatus() === this.status.started;
    },
    getACL() {
        return this.get(this.col.ACL)
    },
    setACL(ACL) {
        return this.set(this.col.ACL, ACL)
    },
    getCreatedAt() {
        return this.get(this.col.createdAt)
    },
    getName() {
        return this.get(this.col.name)
    },
    setName(name) {
        return this.set(this.col.name, name)
    },
    getObjectId() {
        return this.get(this.col.objectId)
    },
    setObjectId(objectId) {
        return this.set(this.col.objectId, objectId)
    },
    getResultPositionForCupSorting() {
        return this.getResultPosition()
    },
    getResultPosition() {
        return this.get(this.col.resultPosition)
    },
    setResultPosition(resultPosition) {
        return this.set(this.col.resultPosition, resultPosition)
    },
    getStatus() {
        return this.get(this.col.status)
    },
    setStatus(status) {
        return this.set(this.col.status, status)
    },
    getKillValue() {
        return this.get(this.col.killValue)
    },
    setKillValue(killValue) {
        return this.set(this.col.killValue, killValue)
    },
    getKillCounts() {
        return this.get(this.col.killCounts)
    },
    getKillCounts2String() {
        return Utility.buildKillCounts2String(this.getKillCounts());
    },
    setKillCounts(killCounts) {
        return this.set(this.col.killCounts, killCounts)
    },
    getTournamentID() {
        return this.get(this.col.tournamentID)
    },
    setTournamentID(tournamentID) {
        return this.set(this.col.tournamentID, tournamentID)
    },
    getTournamentRoundID() {
        return this.get(this.col.tournamentRoundID)
    },
    setTournamentRoundID(tournamentRoundID) {
        return this.set(this.col.tournamentRoundID, tournamentRoundID)
    },
    getTournamentUserIDs() {
        return this.get(this.col.tournamentUserIDs)
    },
    setTournamentUserIDs(tournamentUserIDs) {
        return this.set(this.col.tournamentUserIDs, tournamentUserIDs)
    },
    getTournamentConfigAge() {
        return this.get(this.col.tournamentConfigAge);
    },
    setTournamentConfigAge(tournamentConfigAge) {
        return this.set(this.col.tournamentConfigAge, tournamentConfigAge)
    },
    getTournamentConfigAgeCodeName() {
        const tConfig = this.getTournamentConfigAge();
        if (tConfig != null) {
            return tConfig.getCodeName();
        }
        return "";
    },
    getTournamentConfigBow() {
        return this.get(this.col.tournamentConfigBow);
    },
    setTournamentConfigBow(tournamentConfigBow) {
        return this.set(this.col.tournamentConfigBow, tournamentConfigBow)
    },
    getTournamentConfigBowCodeName() {
        const tConfig = this.getTournamentConfigBow();
        if (tConfig != null) {
            return tConfig.getCodeName();
        }
        return "";
    },
    getUpdatedAt() {
        return this.get(this.col.updatedAt)
    },
});

module.exports = TournamentUserTeam;
