const React = require("react"),
    PropTypes = require('prop-types');
const {Row, Col, Well, Grid, Image} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    I18nHtml = require("components/widgets/I18nHtml"),
    messages = require("i18n/messages"),
    ExtLink = require("components/links/ExtLink"),
    PremiumBanner = require("components/widgets/PremiumBanner"),
    DeviceTypeSwitch = require("components/widgets/DeviceTypeSwitch"),
    Title = require("components/widgets/Title"),
    {RoutedTabNav, RoutedTabs, RoutedTab, ExtTab} = require("components/widgets/RoutedTabNav");

const languageActions = require("actions/LanguageActions");

const {Breadcrumbs, Breadcrumb} = require("components/widgets/Breadcrumbs");

const SkillBoardPage = ({children, path}) => {
    React.useEffect(() => {
        document.title = messages.get("header.nav.3dskillboard");
        // update images depending on path
        if (path.endsWith("ios")) {
            languageActions.updateImageDeviceType("I")
        } else if (path.endsWith("android")) {
            languageActions.updateImageDeviceType("A")
        }
    }, [path]);
    return (
        <Grid>
            <Row>
                <Col lg={12}>
                    <Breadcrumbs>
                        <Breadcrumb link="/" code="header.nav.home"/>
                        <Breadcrumb link="/skillboard" code="header.nav.3dskillboard" active/>
                    </Breadcrumbs>
                </Col>
            </Row>
            <Title code="skillboard.title"/>
            <Row>
                <Col sm={4}>
                    <PremiumBanner />
                    <br/>
                </Col>
            </Row>
            <Well>
                <Row>
                    <Col md={10}><h3><I18nHtml code="skillboard.download"/></h3></Col>
                    <Col md={2} xsHidden smHidden>
                        <ExtLink href="https://play.google.com/store/apps/details?id=dpe.archDPS"><Image responsive src="/img/ico/ico_android.png" alt=""/></ExtLink>
                        <ExtLink href="https://apps.apple.com/app/id995831555"><Image responsive src="/img/ico/ico_apple.png" alt=""/></ExtLink>
                    </Col>
                </Row>
                <Row className="hidden-md hidden-lg">
                    <Col xs={6}>
                        <ExtLink href="https://play.google.com/store/apps/details?id=dpe.archDPS"><Image responsive src="/img/ico/ico_android.png" alt=""/></ExtLink>
                    </Col>
                    <Col xs={6}>
                        <ExtLink href="https://apps.apple.com/app/id995831555"><Image responsive src="/img/ico/ico_apple.png" alt=""/></ExtLink>
                    </Col>
                </Row>
            </Well>
            <Row>
                <Col xs={12}>
                    <DeviceTypeSwitch label={<h3><I18n code="skillboard.screenshot"/></h3>}/>
                </Col>
            </Row>
            <RoutedTabNav>
                <RoutedTabs tabs pathname={path}>
                    <RoutedTab linkPath="/skillboard" exact
                               linkCode="skillboard.tab.gettingstarted"/>
                    <RoutedTab linkPath="/skillboard/tab/jointournament"
                               linkCode="skillboard.tab.joinTournament"/>
                    <RoutedTab linkPath="/skillboard/tab/faq"
                               linkCode="skillboard.tab.faq"/>
                    <RoutedTab linkPath="/skillboard/tab/videos"
                               linkCode="skillboard.tab.videos"/>
                    <RoutedTab linkPath="/skillboard/tab/scores"
                               linkCode="skillboard.tab.scores"/>
                    <RoutedTab linkPath="/skillboard/tab/releasenotes"
                               linkCode="skillboard.tab.releasenotes"/>
                    <ExtTab url="https://3dturnier.freshdesk.com/support/home"
                            linkCode="skillboard.knowledge.base"/>
                </RoutedTabs>
                {children}
            </RoutedTabNav>
        </Grid>)
};
SkillBoardPage.propTypes = {
    path: PropTypes.string.isRequired
};
module.exports = SkillBoardPage;
