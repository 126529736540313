const Parse = require("ParseInit");
const ConfigKeys = require("ConfigKeys");
const {Tournament, TournamentRound, TournamentRegSlot, TournamentUser, TournamentUserGroup, TournamentUserTeam, TournamentConfig, TournamentOption, TournamentCupOption, Parcours} = require("parse/_Domain");

class TournamentManagerDao {

    loadTournamentDetails(id) {
        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.imageID);
        query.include(Tournament.prototype.col.resultImageID);
        query.include(Tournament.prototype.col.bannerImageID);
        query.include(Tournament.prototype.col.mainEditor);
        query.include(Tournament.prototype.col.addEditor);
        query.include(Tournament.prototype.col.parcoursID);
        query.include(Tournament.prototype.col.parcoursID + "." + Parcours.prototype.col.parcoursOwnerID);
        query.include(Tournament.prototype.col.managementID);
        query.include(Tournament.prototype.col.tournamentPriceID);
        query.include(Tournament.prototype.col.tournamentOptionID);
        query.include(Tournament.prototype.col.bowUnionID);
        query.include(Tournament.prototype.col.tournamentConfigAge);
        query.include(Tournament.prototype.col.tournamentConfigBow);
        query.include(Tournament.prototype.col.tournamentConfigBow + "." + TournamentConfig.prototype.col.countTypeID);
        query.include(Tournament.prototype.col.tournamentCupOptionID);
        query.include(Tournament.prototype.col.tournamentCupOptionID+ "." + TournamentCupOption.prototype.col.tConfigCombinedAgeID);
        query.include(Tournament.prototype.col.sponsorImageID);
        return query.get(id);
    };

    loadTournamentCupMaster(id) {
        const query = new Parse.Query(Tournament);
        query.include(Tournament.prototype.col.bowUnionID);
        return query.get(id);
    }

    findTournamentCupMaster(bowUnion) {
        const query = new Parse.Query(Tournament);
        query.equalTo(Tournament.prototype.col.bowUnionID, bowUnion);
        query.doesNotExist(Tournament.prototype.col.cupMasterTournamentID);
        query.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        query.descending(Tournament.prototype.col.createdAt);
        return query.first()
    }

    loadTournamentCupChilds(cupMaster) {
        const query = new Parse.Query(Tournament);
        query.equalTo(Tournament.prototype.col.cupMasterTournamentID, cupMaster);
        query.notEqualTo(Tournament.prototype.col.status, Tournament.prototype.status.deleted);
        query.include(Tournament.prototype.col.tournamentOptionID)
        query.include(Tournament.prototype.col.parcoursID)
        query.include(Tournament.prototype.col.parcoursID + "." + Parcours.prototype.col.parcoursOwnerID)
        query.ascending(Tournament.prototype.col.date);
        return query.find();
    }

    loadTournamentRegSlots(tournament) {
        const query = new Parse.Query(TournamentRegSlot);
        query.notEqualTo(TournamentRegSlot.prototype.col.status, TournamentRegSlot.prototype.status.deleted);
        query.equalTo(TournamentRegSlot.prototype.col.tournamentID, tournament);
        query.ascending(TournamentRegSlot.prototype.col.createdAt);
        return query.find();
    }

    recountTournamentRegSlot(regSlotId) {
        return Parse.Cloud.run("recountTournamentRegSlot", {regSlotId: regSlotId});
    }

    loadTournamentRounds(tournament) {
        const query = new Parse.Query(TournamentRound);
        query.notContainedIn(TournamentRound.prototype.col.status,[TournamentRound.prototype.status.deleted, TournamentRound.prototype.status.inactive]);
        query.equalTo(TournamentRound.prototype.col.tournamentID, tournament);
        query.include(TournamentRound.prototype.col.parcoursID)
        query.include(TournamentRound.prototype.col.parcoursID + "." + Parcours.prototype.col.parcoursOwnerID)
        query.ascending(TournamentRound.prototype.col.sortIndex);
        return query.find();
    };
    queryTournamentUserForConfig(tournament, tcBow, tcAge, isFinal) {
        const query = new Parse.Query(TournamentUser);
        query.notEqualTo(TournamentUser.prototype.col.status, TournamentUser.prototype.status.deleted);
        query.equalTo(TournamentUser.prototype.col.tournamentID, tournament);
        if (tcBow) {
            query.equalTo(TournamentUser.prototype.col.tournamentConfigBow, tcBow);
        }
        if (tcAge) {
            query.equalTo(TournamentUser.prototype.col.tournamentConfigAge, tcAge);
        }
        if (isFinal) {
            query.descending(TournamentUser.prototype.col.finalSumPoints + "," + TournamentUser.prototype.col.finalKillValue + "," + TournamentUser.prototype.col.sumPoints + "," + TournamentUser.prototype.col.killValue);
            query.exists(TournamentUser.prototype.col.finalTournamentRoundUserIDs);
        } else {
            query.descending(TournamentUser.prototype.col.sumPoints + "," + TournamentUser.prototype.col.killValue);
        }
        query.limit(500);
        return query.find();
    }
    queryTournamentOptions() {
        const query = new Parse.Query(TournamentOption);
        query.notEqualTo(TournamentOption.prototype.col.status, TournamentOption.prototype.status.inactive);
        return query.find();
    }
    queryTournamentUserTeamForConfig(tournament, tcBow, tcAge) {
        const query = new Parse.Query(TournamentUserTeam);
        query.include(TournamentUserTeam.prototype.col.tournamentUserIDs);
        query.notEqualTo(TournamentUserTeam.prototype.col.status, TournamentUserTeam.prototype.status.deleted);
        query.equalTo(TournamentUserTeam.prototype.col.tournamentID, tournament);
        if (tcBow) {
            query.equalTo(TournamentUserTeam.prototype.col.tournamentConfigBow, tcBow);
        }
        if (tcAge) {
            query.equalTo(TournamentUserTeam.prototype.col.tournamentConfigAge, tcAge);
        }
        query.descending(TournamentUserTeam.prototype.col.sumPoints + "," + TournamentUserTeam.prototype.col.killValue);
        query.limit(500);
        return query.find();
    }
    queryTournamentGroupsForRound(tRound, notContainedStatusArr) {
        const query = new Parse.Query(TournamentUserGroup);
        query.notContainedIn(TournamentUserGroup.prototype.col.status, notContainedStatusArr);
        query.equalTo(TournamentUserGroup.prototype.col.tournamentRoundID, tRound);
        return query.find();
    }

    queryTournamentGroupsForUnsetRound(tournament, notContainedStatusArr) {
        const query = new Parse.Query(TournamentUserGroup);
        query.notContainedIn(TournamentUserGroup.prototype.col.status, notContainedStatusArr);
        query.equalTo(TournamentUserGroup.prototype.col.tournamentID, tournament);
        return query.find();
    }

    getNextBowUnionNumber(BUCode) {
        return Parse.Cloud.run("getNextBowUnionNumber", {BUCode: BUCode});
    }


    validateTournamentUsers(tUserGroup, startedRound) {
        return Parse.Cloud.run("validateTournamentUsers", {syncVers: ConfigKeys.tournamentVersion, tournamentGroupID: tUserGroup.id, tournamentRoundID: startedRound.id});
    }
}

module.exports = new TournamentManagerDao();
