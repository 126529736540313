const React = require("react"),
    PropTypes = require('prop-types');
const LoadingCircle = require("components/widgets/LoadingCircle"),
    Loading = require("components/widgets/Loading");

const LoadingWrapper = ({children, loading, circle}) => {
    if (loading) {
        if (circle) {
            return <p className="text-center"><LoadingCircle /></p>;
        } else {
            return <Loading/>;
        }
    } else {
        return <React.Fragment>{children}</React.Fragment>
    }
    return ""
};
LoadingWrapper.propTypes = {
    loading:PropTypes.bool.isRequired,
    circle:PropTypes.bool,
};
module.exports = LoadingWrapper;
