const alt = require("AltInit");

class NotificationActions {
    reset() {
        return {};
    }
    parseError(error) {
        return error;
    }

    notifyNewVersion() {
        return {};
    }

    notifyInvalidSession() {
        return {};
    }

    notifyParseOffline() {
       return  {}
    }

    success(code) {
        return {code: code, style: "success"};
    }

    info(code) {
        return {code: code, style: "info"};
    }

    warning(code) {
        return {code: code, style: "warning"};
    }

    error(code, trace) {
        return {code: code, style: "danger", trace: trace};
    }
}
module.exports = alt.createActions(NotificationActions);
