const React = require("react"),
    PropTypes = require('prop-types');
const {Button, ButtonGroup} = require("react-bootstrap");
const I18n = require("components/widgets/I18n"),
    FontAwesome = require("components/widgets/FontAwesome");

class MultiButtonGroup extends React.Component {
    constructor(props) {
        super(props);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleButtonClicked = this.handleButtonClicked.bind(this)
        this.state = {
            value: this.props.defaultValue != null ? this.props.defaultValue : [],
        };
    }
    getValue() {
        return this.state.value;
    }
    setValue(value) {
        this.setState({
            value: value
        });
    }
    handleButtonClicked(newValue) {
        let nValue = this.state.value;
        if (nValue.includes(newValue)) {
            // remove
            nValue = nValue.filter(function (u) {
                return u != newValue;
            })
        } else {
            // add
            nValue.push(newValue)
        }
        this.setValue(nValue)
        if (this.props.onAfterChange != null) {
            this.props.onAfterChange(nValue);

        }
    }
    render() {
        const {value} = this.state;
        const {objectList, disabled} = this.props
        return (<ButtonGroup>
                {
                    objectList.map(object => {
                        // {value: low, text: "klein", icon:null}
                        return <Button key={object.value} disabled={disabled} active={value.includes(object.value)}
                                       onClick={() => this.handleButtonClicked(object.value)}>
                            {object.icon != null ? <FontAwesome icon={object.icon} /> : null}
                            <I18n code={object.text} />
                        </Button>
                    })
                }
            </ButtonGroup>);
    }
}
MultiButtonGroup.propTypes = {
    onAfterChange:PropTypes.func,
    objectList: PropTypes.array,
    disabled:PropTypes.bool,
    defaultValue: PropTypes.array
};
module.exports = MultiButtonGroup;
