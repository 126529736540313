const React = require("react"),
    PropTypes = require('prop-types');
const Loading = require("components/widgets/Loading");
const { GoogleMap, useJsApiLoader  } = require ('@react-google-maps/api');

const BaseMap = ({mapOptions, elements, children, onCenterChanged, geoSearchString, geoCallback}) => {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyB_TWuP1qLuj9sQ7lLai1E8BCj-MUvcFho",
        region: 'AT'
    });

    const [map, setMap] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        if (geoSearchString != null && geoSearchString.length > 0) {
            geoCode(geoCallback)
        } else {
            setMap(map)
        }
    }, []);

    React.useEffect(() => {
        if (map && elements) {
            let bounds = new window.google.maps.LatLngBounds();
            let maxBounds = Math.min(30, elements.length)
            for(let i = 0; i < maxBounds; i++) {
                bounds.extend(new window.google.maps.LatLng({lat:  elements[i].coordinate._latitude, lng: elements[i].coordinate._longitude}));
            }
            map.fitBounds(bounds)
        }
    }, [map])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);
    function onDragEnd() {
        if (onCenterChanged) {
            onCenterChanged({lat: map.getCenter().lat(), lng: map.getCenter().lng()})
        }
    }
    function geoCode(callback) {
        const geocoder =  new window.google.maps.Geocoder();
        geocoder.geocode( { 'address': geoSearchString}, function(results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                callback(results[0].geometry.location.lat(), results[0].geometry.location.lng());
            } else {
                callback(48.249863, 14.635051);
            }
        });
    }

    if (isLoaded) {
        return <GoogleMap id={mapOptions.mapID}
                          onDragEnd={onDragEnd}
                          mapContainerStyle={mapOptions.containerStyle}
                          mapTypeId={mapOptions.mapTypeId}
                          center={mapOptions.center}
                          zoom={mapOptions.zoom}
                          onLoad={onLoad}
                          onUnmount={onUnmount}>
            {children}
        </GoogleMap>
    }
    return <Loading/>
};

BaseMap.propTypes = {
    children:PropTypes.any,
    mapOptions: PropTypes.object,
    onCenterChanged: PropTypes.func,
    geoSearchString: PropTypes.string,
    geoCallback: PropTypes.func
};
module.exports = React.memo(BaseMap);