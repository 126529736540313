/**
 * Created by Daniel on 19.04.2016.
 */
const React = require("react"),
    PropTypes = require('prop-types');
const { Modal, Button, OverlayTrigger, Tooltip } = require("react-bootstrap");
const {ModalBasicContext}  = require("components/modals/ModalBasicContext");

class ModalBasic extends React.Component {
    constructor(props) {
        super(props);
        this.open = this.open.bind(this);
        this.openObject = this.openObject.bind(this);
        this.close = this.close.bind(this);
        this.setError = this.setError.bind(this);
        this.disableSubmit = this.disableSubmit.bind(this);
        this.setSubmitLoading = this.setSubmitLoading.bind(this);
        this.state = {
            object: null,
            objectType: null,
            showModal: false,
            submitDisabled: false,
            submitLoading: false,
            error: null,
            openFct: this.open,
            openObjectFct: this.openObject,
            closeFct: this.close,
            setErrorFct: this.setError,
            disableSubmitFct: this.disableSubmit,
            setSubmitLoadingFct: this.setSubmitLoading
        };
    }
    open() {
        this.setState({showModal: true});
    }
    openObject(object, objectType) {
        this.setState({showModal: true, object: object, objectType: objectType});
    }
    close() {
        this.setState({showModal: false, submitDisabled: false, error: null, object: null, objectType: null});
    }
    setError(error) {
        this.setState({error: error, submitDisabled: false});
    }
    disableSubmit(disable) {
        this.setState({submitDisabled: disable});
    }
    setSubmitLoading(loading) {
        this.setState({submitLoading: loading});
    }
    render() {
        const {title, desc, children, contextChildren, customButton, buttonChildren, buttonStyle, buttonDisabled, tooltip} = this.props;
        const {showModal} = this.state;
        return (<React.Fragment>
                    {
                        customButton ? customButton :
                            <OverlayTrigger trigger={ tooltip != null ? ['hover', 'focus'] : []} placement="bottom" overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
                                <Button disabled={buttonDisabled} bsStyle={buttonStyle} onClick={this.open}>{buttonChildren}</Button>
                            </OverlayTrigger>
                    }
                    <Modal backdrop="static" show={showModal} onHide={this.close}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {title}
                            </Modal.Title>
                            {desc}
                        </Modal.Header>
                        <Modal.Body>
                            {
                                showModal ?
                                    <ModalBasicContext.Provider value={this.state}>{children}</ModalBasicContext.Provider>
                                     : null
                            }
                        </Modal.Body>
                    </Modal>
                <ModalBasicContext.Provider value={this.state}>{contextChildren}</ModalBasicContext.Provider>
                </React.Fragment>

        );
    }
}
ModalBasic.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    desc: PropTypes.any,
    tooltip: PropTypes.string,
    buttonChildren: PropTypes.any,
    buttonStyle: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    customButton: PropTypes.any
};
module.exports = ModalBasic;